export const GET_PLAYER_COMMUNICATION = 'GET_PLAYER_COMMUNICATION';
export const GET_PLAYER_COMMUNICATION_SUCCESS =
    'GET_PLAYER_COMMUNICATION_SUCCESS';
export const GET_PLAYER_COMMUNICATION_FAIL =
    'GET_PLAYER_COMMUNICATION_FAIL';
export const RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS =
    'RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS';
export const SET_EDIT_PLAYER_COMMUNICATION =
    'SET_EDIT_PLAYER_COMMUNICATION';
export const UPDATE_PLAYER_COMMUNICATION = 'UPDATE_PLAYER_COMMUNICATION';
export const UPDATE_PLAYER_COMMUNICATION_SUCCESS =
    'UPDATE_PLAYER_COMMUNICATION_SUCCESS';
export const UPDATE_PLAYER_COMMUNICATION_FAIL =
    'UPDATE_PLAYER_COMMUNICATION_FAIL';

export const GET_PLAYER_COMMUNICATION_PHONE_LIST =
    'GET_PLAYER_COMMUNICATION_PHONE_LIST';
export const GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS =
    'GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS';
export const GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL =
    'GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL';
export const RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS =
    'RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS';

export const GET_PLAYER_COMMUNICATION_EMAIL_LIST =
    'GET_PLAYER_COMMUNICATION_EMAIL_LIST';
export const GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS =
    'GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS';
export const GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL =
    'GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL';
export const RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS =
    'RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS';

export const SEND_CODE_PHONE_PLAYER_COMMUNICATION =
    'SEND_CODE_PHONE_PLAYER_COMMUNICATION';
export const SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS =
    'SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS';
export const SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL =
    'SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL';

export const SEND_CODE_EMAIL_PLAYER_COMMUNICATION =
    'SEND_CODE_EMAIL_PLAYER_COMMUNICATION';
export const SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS =
    'SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS';
export const SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL =
    'SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL';
