import React from 'react';
import i18n from "i18next";
import magnifiying_glass from "../../assets/images/common/magnifiying-glass 1.svg";
import clock_icon from "../../assets/images/common/clock-icon.svg";
import MarkedText from "../MarkedText";
import {Link} from "react-router-dom";

const ListOfResultSearch = (
    {
        setShowSearchMenu,
        searchText,
        isLoading,
        resultList,
        recentList,
        getUrlAddress,
        setToRecentList
    }
) => {

    const handleClickLink = (item) => {
        setToRecentList(item)
        setShowSearchMenu(false);
    }

    return (
        <div className='app-search-bar__search-menu'>
            {resultList?.length > 0 && !isLoading  && (
                <div className="search-result-list">
                    {resultList.map(item => {
                        return (
                            <Link
                                key={item.id}
                                to={getUrlAddress(item)}
                                onClick={() => handleClickLink(item)}
                                className="search-result-list__item"
                            >
                                <img
                                    className="item-icon"
                                    src={magnifiying_glass}
                                    alt="vector"
                                />
                                <MarkedText
                                    text={item.label}
                                    mark={searchText}
                                />
                            </Link>
                        );
                    })}
                </div>
            )}

                {!resultList?.length && !isLoading && (
                    <div className="search-result-list no-results">
                                <span className='no-results-search'>
                                    {i18n.t('crm.noResults')}
                                </span>
                    </div>
                )}

                {resultList?.recent?.length > 0 && recentList &&
                recentList?.results?.length > 0 && (<div className="separator-line" />)
                }

                {recentList?.length > 0 && recentList && (
                    <>
                        <div className="search-recent-title">
                            {i18n.t('common.recent')}
                        </div>

                    <div className="search-result-list">
                        {recentList.map(item => {
                            return (
                                <a
                                    key={item.id}
                                    href={getUrlAddress(item)}
                                    onClick={() => handleClickLink(item)}
                                    className="search-result-list__item"
                                >
                                    <img
                                        className="item-icon"
                                        src={clock_icon}
                                        alt="vector"
                                    />
                                    {item.label}
                                </a>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    )
};

export default ListOfResultSearch;