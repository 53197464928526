import React, {Component} from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import GameTable from "../../common/tables/GameTable"

import "./styles.scss"
import close from "../../assets/images/layout/close-btn.svg"
import {useRouteMatch, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import {
	getGameProviders,
	getGames,
	getGameVendors,
	getSiteAvailableGames,
	putCategoryGames,
	putSiteGames,
} from "../../store/games/actions";
import { DIRECTION } from "../../constants/layout";
import Loader from "../../common/components/Loader";
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { FILTER_CATEGORY, FILTER_MENU_CATEGORY_GAME_LIST } from "../../constants/Filter";
import TableFilter from "../../components/TableFilter";
import { getFilteredGames } from "../../common/utils/filter";
import SelectTableView from "./components/SelectTableView";
import { uniq } from "lodash";
import { clearCurrentFilter } from "../../store/filters/action";
import CategoryTableFooter from "./components/CategoryTableFooter";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const rowHeight = 40
const headerHeight = 38

const CloseBtn = ({onToggle}) => (
	<div
		className="close"
		onClick={onToggle}
		style={{
			background: `transparent url(${close}) center`,
		}}
	/>
)

const checkGame = (currSearchValue) => (game) => currSearchValue === ''
	|| game?.friendlyName?.toLowerCase().indexOf(currSearchValue?.toLowerCase()) >= 0;

const isPromoPage = location.pathname.includes('promotions');

class TableBigModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			games: [],
			sortedGames: [],
			initialGamesIdsCategory: new Set(),
			checkedAll: false,
			withPagination: true,
			activePage: 1,
			pageCount: 1,
			pageSize: 20,
			currentRowHeight: rowHeight,
			uniqueGameTypes: [],
			gameVendorsArr: [],
			gameProviders: [],
			filters: {
				modalSearchValue: '',
				isShownSelectedGames: false,
				provider: this.props.provider?.length ? this.props.provider : null,
			},
			isChanges: false,
		}

		this.boxRef = null
		this.calculatedRowCount = false
	}

	componentDidMount() {
		window.addEventListener('resize', this.calculateRowsCounts);
		const { siteMode } = this.props;
		if (siteMode) {
			this.props.getSiteAvailableGames();
		}
		else {
			this.props.getGames();
		}
		this.props.getGameVendors();
		this.props.getGameProviders();
		this.prepareGames();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { availableSiteGames, gameVendors, gameProviders } = this.props;
		const { games } = this.state;

		if (this.props.games.result !== prevProps.games.result || availableSiteGames !== prevProps.availableSiteGames) {
			this.prepareGames();
		}

		if (this.props.gameProviders !== prevProps.gameProviders) {
			this.setState({
				gameProviders
			})
		}

		if (prevState.sortedGames.length !== this.state.sortedGames.length) {
			this.calculateRowsCounts();
		}

		if (gameVendors !== prevProps.gameVendors || games !== prevState.games) {
			if (gameVendors && games) {
				const directVendors = gameVendors.filter((vendor) => vendor.configuration?.direct);
				const uniqVendorIds = uniq(games.map((game) => game.vendor));
				const siteGameVendors = gameVendors.filter((vendor) => uniqVendorIds.includes(vendor.id));
				const siteGameVendorNames = siteGameVendors.map((vendor) => vendor.name);

				for (let directVendor of directVendors) {
					if (siteGameVendorNames.includes(directVendor.name) && !siteGameVendors.includes(directVendor)) {
						siteGameVendors.push(directVendor);
					}
				}

				const gameVendorsArr = siteGameVendors.map((vendor) => {
					const { id, name, configuration } = vendor;
					return {
						id,
						name: configuration.direct ? `${name} (direct)` : name,
					}
				});

				gameVendorsArr.sort((a1, a2) => a1.name < a2.name ? -1 : 1);

				this.setState({ gameVendorsArr });
			}
		}

		if (prevProps.provider !== this.props.provider) {
			this.setState(prevState => ({
				...prevState,
				filters: {
					...prevState.filters,
					provider: this.props.provider
				}
			}));
		}

		if (this.props.currentFilter !== prevProps.currentFilter) {
			const { games, gameVendorsArr } = this.state;
			const { currentFilter } = this.props;

			const filteredGames =  getFilteredGames(currentFilter, games, gameVendorsArr);
			const gamesCount = filteredGames.length ?? 0;
			const { pageSize } = this.state;

			this.setState({
				activePage: 1,
				pageCount: Math.ceil(gamesCount / pageSize),
				sortedGames: [...filteredGames],
				gamesCount,
			});
		}

		if (
			prevState.filters.modalSearchValue !== this.state.filters.modalSearchValue ||
			prevState.filters.isShownSelectedGames !== this.state.filters.isShownSelectedGames ||
			prevState.filters.provider !== this.state.filters.provider ||
			prevState.games !== this.state.games
		) {
			this.filterGames();
		}

		if (!this.calculatedRowCount && this.boxRef) {
			this.calculatedRowCount = true
			this.calculateRowsCounts()
		}

		if (this.state.sortedGames !== prevState.sortedGames) {
			this.getUniqueGameTypes();
			const gamesCount = this.state.sortedGames.length;

			const sizeLimit = Math.min(gamesCount, this.state.pageSize);
			const pageCount = Math.ceil(gamesCount / sizeLimit);
			const hasChanges = this.checkForChanges(this.state.games, this.state.sortedGames);
			const newPageSize = hasChanges ? sizeLimit : prevState.pageSize;

			this.setState({
				pageSize: newPageSize,
				pageCount,
				isChanges: hasChanges
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.calculateRowsCounts);
		this.props.clearCurrentFilter();
	}

	checkForChanges = (initialGames, currentSortedGames) => {
		const initialGamesIds = new Set(initialGames.filter(game => game.checked).map(game => game.id));
		const currentCheckedGameIds = new Set(currentSortedGames.filter(game => game.checked).map(game => game.id));

		if (initialGamesIds.size !== currentCheckedGameIds.size) {
			return true;
		}

		for (const gameId of initialGamesIds) {
			if (!currentCheckedGameIds.has(gameId)) {
				return true;
			}
		}
		return false;
	}

	prepareGames = () => {
		const { availableSiteGames, gameCategory = null, siteMode, checkedGames } = this.props;
		const games = siteMode ? availableSiteGames : this.props.games;
		const allGames = games.result;

		if (location.pathname.includes('promotions')) {
			allGames.forEach((game) => game.checked = checkedGames?.includes(game.id))
		}
		else if (gameCategory) {
			const {games: categoryGames} = gameCategory;
			if (!categoryGames?.length) {
				const initialGamesChecked = allGames
					.filter(game => game.checked && game.id);
				allGames.forEach((game) => game.checked = initialGamesChecked?.includes(game.id))
			} else {
				allGames.forEach((game) => game.checked = categoryGames?.includes(game.id))
			}
		}

		this.setState(
			{
				games: games.result,
				sortedGames: [...games.result],
				initialGamesIdsCategory: new Set(gameCategory?.games?.map(g => g)),
				activePage: 1,
			}
		)
	}

	filterGames = () => {
		const { games, filters, gameVendorsArr } = this.state;
		const { currentFilter } = this.props;
		const checkedCondition = filters.isShownSelectedGames ? false : null;

		const filteredGames = games.filter(game => {
			const isMatchedSearch = game.friendlyName.toLowerCase().includes(filters.modalSearchValue.toLowerCase());
			const isMatchedProvider = !filters.provider || game.providerName === filters.provider;
			return isMatchedSearch && isMatchedProvider && game.checked !== checkedCondition;
		});

		const sortedGames = currentFilter ? getFilteredGames(currentFilter, filteredGames, gameVendorsArr) : filteredGames;
		this.setState({
			sortedGames
		}, () => this.calculateRowsCounts());
	}

	defaultGameSorting (games) {
		return games.sort((a, b) => b.priority - a.priority);
	}

	sortGames() {
		const { currentFilter } = this.props;
		const { gameVendorsArr, sorting, games } = this.state;

		const currentGames = (sorting?.column && sorting?.direction)
			? this.props.gameSortingWithValues(games, sorting?.column, sorting?.direction)
			: this.defaultGameSorting(games)

		const filteredGames =  getFilteredGames(currentFilter, currentGames, gameVendorsArr);
		this.setState({games: [...filteredGames]})
	}

	handleCheckCategoryGame = (gameId) => {
		const {setPromotionStateGames, isPromoPage, setIsEditPromotion} = this.props;

		if (isPromoPage) {
			setPromotionStateGames((prevState) => ({
				...prevState,
				model: {
					...prevState.model,
					listOfGames: prevState.model.listOfGames.includes(gameId)
						? prevState.model.listOfGames.filter((id) => id !== gameId)
						: [...prevState.model.listOfGames, gameId],
				},
			}));
			setIsEditPromotion(true);
		}

		this.setState(prevState => {
			const updatedGames = prevState.sortedGames.map(game => {
				if (game.id === gameId) {
					const checked = !game.checked;
					if (checked) {
						prevState.initialGamesIdsCategory.add(gameId);
					} else {
						prevState.initialGamesIdsCategory.delete(gameId);
					}

					return {
						...game,
						checked,
					};
				}
				return game;
			});

			return {
				sortedGames: updatedGames,
				initialGamesIdsCategory: prevState.initialGamesIdsCategory,
			};
		});
	}

	getUniqueGameTypes = () => {
		const { games } = this.state;

		const uniqueGameTypes = games.reduce((acc, game) => {
			const gameType = game.gameType || "No type";
			if (!acc.includes(gameType)) {
				acc.push(gameType);
			}
			return acc;
		}, []);

		this.setState({uniqueGameTypes});
	}

	calculateRowsCounts = (currSearchValue = '') => {
		const {sortedGames} = this.state;
		const gamesCount = sortedGames?.filter(checkGame(currSearchValue)).length
		if (this.boxRef) {
			const {offsetHeight} = this.boxRef
			const bodyHeight = offsetHeight - headerHeight
			const pageSize = Math.min(gamesCount, 30);
			const pageCount = Math.ceil(gamesCount / pageSize)
			const diffHeight = bodyHeight - pageSize * rowHeight
			const currentRowHeight = Math.floor(diffHeight / pageSize) + rowHeight

			this.setState({
				pageSize,
				pageCount,
				currentRowHeight,
				activePage: 1,
			});
		}
	}

	handleChangePage = (activePage) => {
		this.setState({activePage})
	}

	handleShowSelectedGames = () => {
		this.setState(prevState => {
			const sortedGamesObj = {};
			let games = [...prevState.games];
			if (!prevState.filters.isShownSelectedGames) {
				prevState.sortedGames.forEach((game) => sortedGamesObj[game.id] = game);

				games
					.filter((game) => game.id in sortedGamesObj)
					.forEach((game) => game.checked = sortedGamesObj[game.id].checked)
			}

			return {
				...prevState,
				filters: {
					...prevState.filters,
					isShownSelectedGames: !prevState.filters.isShownSelectedGames,
				},
				games,
			};
		}, () => {
			this.filterGames();
			this.calculateRowsCounts();
		});
	}

	handleCheckAll = () => {
		const { modalSearchValue, gameCategory, onChangeGames } = this.props;
		const { checkedAll, filters, IsShownSelectedGames, sortedGames, initialGamesIdsCategory } = this.state;

		const currSearchValue = gameCategory ? filters.modalSearchValue : modalSearchValue;
		const updatedCheckedAll = !checkedAll;

		let updatedGamesCategory = new Set(initialGamesIdsCategory);

		const updatedGames = sortedGames.map(game => {
			const shouldCheck = (
				game.friendlyName.toLowerCase().indexOf(currSearchValue.toLowerCase()) >= 0 &&
				(!IsShownSelectedGames || (IsShownSelectedGames && game.checked))
			);
			if (shouldCheck) {
				game.checked = updatedCheckedAll;

				if (updatedCheckedAll) {
					updatedGamesCategory.add(game.id);
				} else {
					updatedGamesCategory.delete(game.id);
				}
			}
			return game;
		});

		this.setState({
			sortedGames: updatedGames,
			checkedAll: updatedCheckedAll,
			initialGamesIdsCategory: updatedGamesCategory,
		}, () => {
			onChangeGames(updatedGames);
		});
	}

	handleChangeCategoryGames = () => {
		const {sortedGames, initialGamesIdsCategory} = this.state;
		const {gameCategory, siteMode} = this.props;

		const categoryNewGames = sortedGames
			.filter((game) => game.checked)
			.map((game) => game.id)

		if (siteMode) {
			this.props.putSiteGames({games: categoryNewGames})
		}
		else if (gameCategory?.id) {
			this.props.putCategoryGames({
				id: gameCategory.id,
				games: Array.from(initialGamesIdsCategory)
			})
		}
		return categoryNewGames;
	}

	handleSortGames = (column, direction = DIRECTION.ASC) => {
		const {sortedGames} = this.state;
		const fieldName = column.source || column.id;
		if (fieldName) {
			sortedGames.sort((a, b) => {
				if (a[fieldName] < b[fieldName]) {
					return direction === DIRECTION.ASC ? -1 : 1;
				}
				else if (a[fieldName] === b[fieldName]) {
					return 0;
				}
				return direction === DIRECTION.ASC ? 1 : -1;
			})
		}
		this.setState({sortedGames: [...sortedGames]});
	}

	handleChangeModalSearchValue = (e) => {
		this.setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				modalSearchValue: e.target.value,
			}
		}), () => {
			this.filterGames();
		});
	}

	handleSetPageSize = (pageSize) => {
		const { games } = this.state;
		const gamesCount = games.length;

		if (pageSize === 'All') {
			pageSize = gamesCount;
		}

		this.setState({
			pageCount: Math.ceil(gamesCount / pageSize),
			pageSize,
		});
	}

	render() {
		const {
			isOpen,
			isLoadingGames,
			onToggle,
			className,
			columns,
			renderHeader,
			withFilter,
			match,
		} = this.props

		const {
			withPagination,
			activePage,
			pageCount,
			pageSize,
			currentRowHeight,
			modalSearchValue: stateSearchValue,
			sortedGames,
			uniqueGameTypes,
			gameVendorsArr,
			isChanges,
		} = this.state;

		const isGameCategoryView = match.path === '/game-categories/:id';

		return (
			<Modal
				isOpen={isOpen}
				toggle={onToggle}
				className={`big-modal ${className}`}
				centered={true}
				size="lg"
				onOpened={setUnScrollBody}
            	onExit={removeUnScrollBody}
			>
				<ModalHeader
					toggle={onToggle}
					close={
					<CloseBtn
						onToggle={onToggle}
					/>
				}
				>
					{renderHeader({
						handleChangeCategoryGames: this.handleChangeCategoryGames
					})}
				</ModalHeader>
				<ModalBody>
					{!isLoadingGames ? (
						<div className="table-title d-flex justify-content-end">
							<div className='d-flex align-items-center'>
								<div className="app-search-for-modal">
									<div className="position-relative">
										<input
											type="text"
											className="form-control"
											placeholder={i18n.t("Search")}
											value={this.state.filters.modalSearchValue}
											onChange={this.handleChangeModalSearchValue}
										/>
										<span className="bx bx-search-alt"/>
									</div>
								</div>
								<button
									onClick={this.handleShowSelectedGames}
									className='btn btn-rounded btn-primary mr-10 ml-10 header-search__btn'
								>
									{this.state.filters.isShownSelectedGames
										? i18n.t('crm.showAllGames')
										: i18n.t('crm.showSelectedGames')
									}
								</button>
							</div>
						</div>
					) : (
						<div>
							<Loader size={'sm'}/>
						</div>
					)}
					{withFilter &&
						<div className='filter-game-modal'>
							<TableFilter
								filterListOptions={FILTER_MENU_CATEGORY_GAME_LIST(gameVendorsArr, uniqueGameTypes)}
								isDisabled={isLoadingGames}
								category={FILTER_CATEGORY.GAME_CATEGORY}
							/>
						</div>
					}
					<div
						className="game-table-wrapper game-table-modal-wrapper"
						ref={ref => this.boxRef = ref}
					>
						{isChanges && isGameCategoryView &&
							<CategoryTableFooter
								changeCategoryGames={() => {
									this.handleChangeCategoryGames();
									this.props.onToggle();
								}}
								onCancel={this.prepareGames}
							/>
						}
						<GameTable
							columns={columns}
							games={sortedGames}
							activePage={activePage}
							pageSize={pageSize}
							currentRowHeight={currentRowHeight}
							onCheckAll={this.handleCheckAll}
							increaseOrDecreaseGames={this.props.increaseOrDecreaseGames}
							handleGameCheckboxChange={this.handleCheckCategoryGame}
							onSortGames={this.handleSortGames}
							modalMode={true}
							isGamePriorityUpdateModal={true}
							isUseColumns={true}
							isPromoPage={isPromoPage}
						/>
					</div>
					<div style={{ padding: '15px 10px 0 0' }}>
						{withPagination &&
							<SelectTableView
								recordsCount={sortedGames.length}
								pageSize={pageSize}
								activePage={activePage}
								pageCount={pageCount}
								onChange={this.handleSetPageSize}
								onChangePage={this.handleChangePage}
							/>
						}
					</div>
				</ModalBody>
			</Modal>
		)
	}
}

TableBigModal.propTypes = {
	tableTitleText: PropTypes.string,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	columns: PropTypes.array,
	games: PropTypes.object,
	categoryGames: PropTypes.array,
	withSearch: PropTypes.bool,
	onToggle: PropTypes.func,
	onChangeGames: PropTypes.func,
	renderHeader: PropTypes.func,
	gameCategory: PropTypes.object,
	modalSearchValue: PropTypes.string,
	siteMode: PropTypes.bool,
}

TableBigModal.defaultProps = {
	isOpen: false,
	className: '',
	tableTitleText: '',
	withSearch: false,
	columns: [],
	games: {
		result: [],
		total: 0,
	},
	checkedGames: [],
	categoryGames: [],
	gameVendorsArr: [],
	onToggle: () => {},
	onChangeGames: () => {},
	renderHeader: () => {},
	gameCategory: null,
	modalSearchValue: '',
	siteMode: false,
}

const mapStateToProps = state => {
	const {
		games,
		availableSiteGames,
		isLoadingGames,
		errorGames,
		gameCategory,
		gameVendors,
		gameProviders,
	} = state.Games;
	const {
		currentFilter,
	} = state.Filter;
	return {
		games,
		availableSiteGames,
		isLoadingGames,
		errorGames,
		gameCategory,
		gameVendors,
		currentFilter,
		gameProviders
	};
};

export default React.memo(
	withRouter(
		connect(mapStateToProps, {
			getGames,
			getSiteAvailableGames,
			getGameVendors,
			putCategoryGames,
			putSiteGames,
			clearCurrentFilter,
			getGameProviders,
		})(withTranslation()(TableBigModal))
	)
);
