import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/inputs/common/SelectInput';
import i18n from '../../../i18n';

const OPTIONS = [
    'Attribution №1',
    'Attribution №2',
    'Attribution №3',
    'Attribution №4',
    'Attribution №5',
    'Attribution №6',
    'Attribution №7',
    'Attribution №8'
];

function AttributionSelect({ onChange, value, ...props }) {
    const options = useMemo(
        () => OPTIONS.map(item => ({ label: item, value: item })),
        []
    );

    return (
        <SelectInput
            {...props}
            onChange={onChange}
            value={value}
            options={options}
            placeholder={i18n.t('workflow.selectAttribution')}
        />
    );
}

AttributionSelect.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(AttributionSelect);
