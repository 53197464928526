import {
    GET_AGENTS_LIST,
    GET_AGENTS_LIST_FAIL,
    GET_AGENTS_LIST_SUCCESS,

    GET_ROLES_LIST,
    GET_ROLES_LIST_SUCCESS,
    GET_ROLES_LIST_FAIL,

    CREATE_AGENT,
    CREATE_AGENT_SUCCESS,
    CREATE_AGENT_FAIL,

    CHANGE_AGENT_PASSWORD,
    CHANGE_AGENT_PASSWORD_SUCCESS,
    CHANGE_AGENT_PASSWORD_FAIL,

    RESET_AGENTS_ERROR,
} from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    isLoadingRolesList: false,
    isCreating: false,
    isPasswordUpdating: false,
    error: null,
    agentsList: null,
    rolesList: null,
    successAlert: '',
};

const AgentList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AGENTS_LIST:
            return { ...state, isLoading: true, error: null };

        case GET_AGENTS_LIST_SUCCESS:
            return { ...state, agentsList: action.payload, isLoading: false };

        case GET_AGENTS_LIST_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case GET_ROLES_LIST:
            return { ...state, isLoadingRolesList: true, error: null };

        case GET_ROLES_LIST_SUCCESS:
            return { ...state, rolesList: action.payload, isLoadingRolesList: false };

        case GET_ROLES_LIST_FAIL:
            return { ...state, error: action.payload, isLoadingRolesList: false };

        case CREATE_AGENT:
            return { ...state, isCreating: true, error: null };

        case CREATE_AGENT_SUCCESS:
            return { ...state, isCreating: false, error: null };

        case CREATE_AGENT_FAIL:
            return { ...state, isCreating: false, error: action.payload };

        case CHANGE_AGENT_PASSWORD:
            return { ...state, isPasswordUpdating: true, error: null };

        case CHANGE_AGENT_PASSWORD_SUCCESS:
            return { ...state, isPasswordUpdating: false, error: null };

        case CHANGE_AGENT_PASSWORD_FAIL:
            return { ...state, isPasswordUpdating: false, error: action.payload };

        case RESET_AGENTS_ERROR:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default AgentList;
