import axios from 'axios';
import {REFRESH_TOKEN} from './url_helper';
import FileSaver from 'file-saver';
import i18n from '../i18n';
import {getRotatedBlobImage} from './document';
import {getStorageXSiteId, isPdf, readFileAsDataUrl} from '../common/utils/common';
import {_setCookie} from "./cookieSetter";

const getLsAuthUser = key => {
    const authUserJson = localStorage.getItem(key);
    if (authUserJson) {
        try {
            return JSON.parse(authUserJson);
        } catch (err) {
            console.log(err);
            return {};
        }
    }
    return {};
};

export const getAccessToken = () => {
    const authUser = getLsAuthUser('authUser');
    const { accessToken } = authUser;
    return accessToken || '';
};

export const getRefreshToken = () => localStorage.getItem('refreshToken') || '';

const prepareRequestConfig = config => {
    const accessToken = getAccessToken();

    if (accessToken === '') {
        return { ...config };
    }

    const xSiteId = getStorageXSiteId();


    return {
        ...config,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-Site-Id': xSiteId,
            ...(config?.headers || {})
        }
    };
};

const prepareRequestConfigRefreshToken = config => {
    const refreshToken = getRefreshToken();

    if (refreshToken === '') {
        return { ...config };
    }

    const xSiteId = getStorageXSiteId();

    return {
        ...config,
        headers: {
            authorization: `Bearer ${refreshToken}`,
            'X-Site-Id': xSiteId,
            ...(config?.headers || {})
        }
    };
}

// Base url for axios
export const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const axiosApi = axios.create({
    withCredentials: true,
    baseURL: API_URL
});

/**
 * Config must be in Axios format (example):
 *     let config = {
 *         method: 'get',
 *         maxBodyLength: Infinity,
 *         url: 'https://wiztech.squaretalk.com:8443/Integration/api_call.php?Mode=Call&Extension=510&Destination=',
 *         headers: { },
 *         timeout: 30000
 *     };
 * @param config
 */
export const customApiCall = async (config) => {
    return axios.request(config);
}

// TODO: Need to change this header,
// because it causes an error: 'Refused to set unsafe header "referer"'
// axiosApi.defaults.headers.common["referer"] = process.env.REACT_APP_API_URL

axiosApi.interceptors.response.use(
    response => response,
    error => {
        try {
            const errorData = error?.response?.data;
            const status = error?.response?.status;

            if (
                (errorData?.statusCode === 403 &&
                    errorData.message === 'Forbidden resource') ||
                (errorData?.statusCode === 401 &&
                    ["Session has expired", "Not token provided", "Agent not found"].includes(errorData.message))
            ) {
                reloadAccessToken();
            }

            if (
                errorData.statusCode === 401 &&
                errorData.message === 'Session has expired'
            ) {
                if (config.url === REFRESH_TOKEN) {
                    window.location = '/login';
                } else {
                    return new Promise((resolve, reject) => {
                        const refreshToken = getRefreshToken();
                        if (refreshToken !== '') {
                            reloadAccessToken()
                                .then(accessToken => {
                                    const {data, headers, method, url} = config;

                                    return {
                                        data,
                                        headers: {
                                            ...(headers || {}),
                                            Authorization: `Bearer ${accessToken}`
                                        },
                                        method,
                                        url
                                    };
                                })
                                .then(axiosApi)
                                .then(resp => resolve(resp))
                                .catch(error => {
                                    localStorage.setItem('LocalStorageFilter', JSON.stringify([]));
                                    reject(error);
                                });
                        }
                    });
                }
            }

            return Promise.reject(errorData);
        } catch (err) {
            return Promise.reject('Unexpected server error');
        }
    }
);

axiosApi.interceptors.request.use(
    response => response,
    error => {
        const errorData = error.response.data;
        return Promise.reject(errorData.message);
    }
);

const currentRequestsMap = new Map();
const requestQueue = new Map();

export async function get(url, config = {}) {
    const preparedConfig = prepareRequestConfig(config);
    const key = `${url}${JSON.stringify(preparedConfig)}`;

    if (requestQueue.has(key)) {
        await requestQueue.get(key);
        return requestQueue.get(key + "_response");
    }
    let resolveFn;

    const requestPromise = new Promise(resolve => {
        resolveFn = resolve;
    });
    requestQueue.set(key, requestPromise);

    try {
        const response = await axiosApi.get(url, preparedConfig);
        requestQueue.set(key + "_response", response);
        requestQueue.delete(key);
        resolveFn();

        return response;
    } catch (error) {
        requestQueue.delete(key);
        resolveFn();
        throw error;
    }
}

//  OLD INON CODE commit f9d58459bb167204c66e1bee69d81c986bd69e02
// export async function get(url, config = {}) {
//     // return axiosApi.get(url, prepareRequestConfig(config));
//     const key = `${url}${JSON.stringify(prepareRequestConfig(config))}`;
//     if(currentRequestsMap.get(key) === 'pending') {
//         while(currentRequestsMap.get(key) === 'pending') {
//             await new Promise(resolve => setTimeout(resolve, 100));
//         }
//     }
//     if(currentRequestsMap.get(key) && currentRequestsMap.get(key) !== 'pending') {
//       return currentRequestsMap.get(key);
//     }
//     currentRequestsMap.set(key, 'pending');
//     const response = await axiosApi.get(url, prepareRequestConfig(config));
//     currentRequestsMap.set(key, response);
//     setTimeout(() => {
//         currentRequestsMap.delete(key);
//     }, 10 * 1000);
//     return response;
// }

export async function getFile(url, config = {}, setIsLoading) {
    // I think it would be better to leave the function 'setIsLoading' outside
    // and not pass it here, checking is needed to avoid errors
    if (!setIsLoading) {
        setIsLoading = () => {}
    }

    setIsLoading(true);
    return axiosApi.get(url, {
        responseType: 'blob',
        ...prepareRequestConfig(config)
    }).
    catch(() => {
        setIsLoading(false);
        throw new Error('Unexpected server error');
    }).
    then((res) => {
        setIsLoading(false);
        const filename = res.headers['content-disposition']?.split('filename=')[1].replaceAll('"', '');
        FileSaver.saveAs(res.data, filename);
    });
}

export async function getDocFile(url, rotateDegree, fileName,  config = {}, setIsLoading) {
    // I think it would be better to leave the function 'setIsLoading' outside
    // and not pass it here, checking is needed to avoid errors
    if (!setIsLoading) {
        setIsLoading = () => {}
    }

    setIsLoading(true);
    return axiosApi.get(url, {
        responseType: 'blob',
        ...prepareRequestConfig(config)
    }).
    catch(() => {
        setIsLoading(false);
        throw new Error('Unexpected server error');
    }).
    then((res) => {
        setIsLoading(false);

        if (isPdf(res.data.type)) {
            FileSaver.saveAs(res.data, fileName)
        } else {
            readFileAsDataUrl(res.data).then((res) => {
                getRotatedBlobImage(res, rotateDegree)
                  .then((rotatedBlobImage) => {
                    FileSaver.saveAs(rotatedBlobImage, fileName)
                })
                .catch((err) => {
                    throw new Error(err);
                });
            })
        }
    });
}

export async function getSync(url, config = {}) {
    return new Promise(async (resolve, reject) => {
        return await axiosApi
            .get(url, prepareRequestConfig(config))
            .then(v => resolve(v))
            .catch(e => reject(e));
    });
}

export async function post(url, data = {}, config = {}) {
    return axiosApi
        .post(url, data, prepareRequestConfig(config))
        .then(response => response.data);
}

export async function postSync(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        return axiosApi
            .post(url, data, prepareRequestConfig(config))
            .then(response => resolve(response.data))
            .catch(err => reject(err));
    });
}

export async function delSync(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        return axiosApi
            .delete(url, prepareRequestConfig(config))
            .then(response => resolve(response.data))
            .catch(err => reject(err));
    });
}

export async function postAttachedSync(url, file) {
    return new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);

        return post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.attachmentId) {
                resolve(res.attachmentId);
            }
            reject(res);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function postFile(url, data, config = {}, setIsLoading) {
    if (!setIsLoading) {
        setIsLoading = () => {}
    }

    setIsLoading(true);
    return axiosApi
        .post(url, data, {
            responseType: 'blob',
            ...prepareRequestConfig(config),
        })
        .catch(() => {
            setIsLoading(false);
            throw new Error('Unexpected server error');
        })
        .then((res) => {
            setIsLoading(false);
            const filename = res.headers['content-disposition']?.split('filename=')[1];
            FileSaver.saveAs(res.data, filename);
        });
}

export async function put(url, data = {}, config = {}) {
    return axiosApi
        .put(url, data, prepareRequestConfig(config))
        .then(response => response.data);
}

export async function patch(url, data = {}, config = {}) {
    return axiosApi
        .patch(url, data, prepareRequestConfig(config))
        .then(response => response.data);
}

export async function patchSync(alertService, url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        return axiosApi
            .patch(url, data, prepareRequestConfig(config))
            .then(response => {
                if (!url.includes('/complete-wire') && url.includes('/withdrawals')) {
                    alertService.showSuccess(i18n.t('crm.alerts.communicationEdited'));
                }
                resolve(response.data);
            })
            .catch(e => {
                console.log(e)
                if(e?.message === "Invalid phone number") {
                    alertService.showError(i18n.t(`crm.alerts.invalidPhone`));
                } else if (e?.message === "Changed amount must be lower than the total amount") {
                    alertService.showError(i18n.t(`crm.alerts.amount.must.be.lower`));
                } else if (
                    e?.message === "The string supplied is too short to be a phone number" ||
                    e?.message === "The string supplied did not seem to be a phone number"
                ) {
                    alertService.showError(i18n.t(`crm.shortPhoneNumber`));
                } else {
                    alertService.showError(e);
                }
                reject(e);
            });
    });
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, prepareRequestConfig(config))
        .then(response => response.data);
}

// this is necessary to avoid errors when choosing a default x-site-id
export const getRootUrlToRedirect = () => {
    const pathname = window.location.pathname;
    const arrPathname = pathname.split('/');

    if (arrPathname.length > 1) {
        return `/${arrPathname[1]}`;
    }

    return '/';
}

export async function reloadAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken === '') {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('rememberMe');
        window.location = '/login';
        return Promise.reject();
    }

    _setCookie('refreshToken', refreshToken, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN
    })

    return axiosApi
        .post(REFRESH_TOKEN, {}, prepareRequestConfigRefreshToken({}))
        .then(response => {
            const oldAuthUser = getLsAuthUser('authUser');
            const { email, sites } = oldAuthUser;

            const {
                accessToken,
                refreshToken: refresh,
                ...props
            } = response.data;
            const responseData = {
                email,
                sites,
                accessToken,
                ...props
            };

            if (refresh) localStorage.setItem('refreshToken', refresh);
            localStorage.setItem('authUser', JSON.stringify(responseData));
            // window.location.reload();
            return accessToken;
        })
        .catch(_ => {
            // saved current url to redirect_url
            // check if user is already in /login?redirect_url=/login
            if(window.location.pathname === '/login') {
                return;
            }

            window.location = `/login${
                window.location.pathname !== '/'
                    ? `?redirect_url=${encodeURI(getRootUrlToRedirect())}`
                    : ''
            }`;
        });
}

export async function uploadFileToServer(url, file, type = null, commentType = null) {
    const formData = new FormData();
    formData.append('file', file);

    if (type !== null) {
        formData.append('type', type);
    }
    if (commentType !== null) {
        formData.append('commentType', commentType);
    }

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    };

    try {
        const preparedConfig = prepareRequestConfig(config);
        preparedConfig.responseType = 'blob';
        const response = await axiosApi.post(url, formData, preparedConfig);

        if (response.data) {
            const contentDisposition = response.headers['content-disposition'];
            const matches = contentDisposition.match(/filename="(.*?)"/);
            const filename = matches && matches[1] ? matches[1] : 'default_filename.xlsx';

            FileSaver.saveAs(response.data, filename);
        }

        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        FileSaver.saveAs(error, `Error_${file?.name}`);
        throw error;
    }
}

export async function uploadMassCashback (url, file, comment){
    const formData = new FormData();
    formData.append('file', file);
    formData.append('comment', comment);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        }
    };

    try {
        const preparedConfig = prepareRequestConfig(config);
        preparedConfig.responseType = 'blob';
        const response = await axiosApi.patch(url, formData, preparedConfig);

        if (response.data) {
            const contentDisposition = response.headers['content-disposition'];
            const matches = contentDisposition.match(/filename="(.*?)"/);
            const filename = matches && matches[1] ? matches[1] : 'CashbackResult.xlsx';

            FileSaver.saveAs(response.data, filename);
        }

        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }

}
