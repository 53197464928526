import { takeEvery, put, call } from 'redux-saga/effects';

import {
    DOWNLOAD_DEPOSIT_LIST,
    GET_DEPOSIT_ACTION_LIST,
    GET_DEPOSIT_LIST,
    GET_CARD_NETWORKS,
    GET_PAYMENT_PROVIDERS,
} from './actionTypes';
import {
    getDepositListSuccess,
    getDepositListFail,
    downloadDepositListSuccess,
    downloadDepositListFail,
    getCardNetworksSuccess,
    getCardNetworksFail,
    getPaymentProvidersSuccess,
    getPaymentProvidersFail,
} from './actions';
import {
    downloadPaymentDepositListApi,
    getPaymentDepositListApi,
    getCardNetworksApi,
    getPaymentProvidersApi, getPaymentActionDepositListApi,
} from '../../../helpers/paymentApi';


function* fetchDepositListSaga({ payload }) {
    try {
        const response = yield call(getPaymentDepositListApi, payload);

        yield put(getDepositListSuccess(response.data));
    } catch (error) {
        yield put(getDepositListFail(error));
    }
}

function* fetchDepositActionListSaga({ payload }) {
    try {
        const response = yield call(getPaymentActionDepositListApi, payload);

        yield put(getDepositListSuccess(response.data));
    } catch (error) {
        yield put(getDepositListFail(error));
    }
}

function* downloadDepositListSaga({ payload }) {
    try {
        yield call(downloadPaymentDepositListApi, payload);

        yield put(downloadDepositListSuccess());
    } catch (error) {
        yield put(downloadDepositListFail(error));
    }
}

function* fetchCardNetworksSaga() {
    try {
        const response = yield call(getCardNetworksApi);

        yield put(getCardNetworksSuccess(response.data));
    } catch (error) {
        yield put(getCardNetworksFail(error));
    }
}

function* fetchPaymentProvidersSaga() {
    try {
        const response = yield call(getPaymentProvidersApi);

        yield put(getPaymentProvidersSuccess(response.data));
    } catch (error) {
        yield put(getPaymentProvidersFail(error));
    }
}

function* depositsSaga() {
    yield takeEvery(GET_DEPOSIT_ACTION_LIST, fetchDepositActionListSaga);
    yield takeEvery(GET_DEPOSIT_LIST, fetchDepositListSaga);
    yield takeEvery(DOWNLOAD_DEPOSIT_LIST, downloadDepositListSaga);
    yield takeEvery(GET_CARD_NETWORKS, fetchCardNetworksSaga);
    yield takeEvery(GET_PAYMENT_PROVIDERS, fetchPaymentProvidersSaga);
}

export default depositsSaga;
