export function converter() {}
export function api() {}
export function extend() {
    var i = 0;
    var result = {};
    for (; i < arguments.length; i++) {
        var attributes = arguments[ i ];
        for (var key in attributes) {
            result[key] = attributes[key];
        }
    }
    return result;
}

export function _setCookie(key, value, attributes) {
    if (typeof document === 'undefined') {
        return;
    }

    attributes = extend({
        path: '/'
    }, api.defaults, attributes);

    if (typeof attributes.expires === 'number') {
        attributes.expires = new Date(new Date() * 1 + attributes.expires * 864e+5);
    }

    // We're using "expires" because "max-age" is not supported by IE
    attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

    try {
        var result = JSON.stringify(value);
        if (/^[\{\[]/.test(result)) {
            value = result;
        }
    } catch (e) {}

    value = converter.write ?
        converter.write(value, key) :
        encodeURIComponent(String(value))
            .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

    key = encodeURIComponent(String(key))
        .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
        .replace(/[\(\)]/g, escape);

    var stringifiedAttributes = '';
    for (var attributeName in attributes) {
        if (!attributes[attributeName]) {
            continue;
        }
        stringifiedAttributes += '; ' + attributeName;
        if (attributes[attributeName] === true) {
            continue;
        }
        stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
    }

    return (document.cookie = key + '=' + value + stringifiedAttributes);
}
