import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Row, Col } from "reactstrap"
import MetaTags from 'react-meta-tags'
import i18n from "../../i18n"
import TableBigModal from './TableBigModal'
import CategoryBox from './components/CategoryBox'
import CategoryGamesModalHeader from './components/CategoryGamesModalHeader'
import { clearPageNumber, handleChangePath } from "../../common/utils/common"
import { withAlert } from 'react-alert'

import {
  getGameCategories,
  removeCategory,
  createGameCategory,
  resetGameCategory,
  purgeCacheGameCategory,
} from "../../store/actions";

import "./styles.scss"
import "../../assets/scss/pages/common.scss"

// Images
import addItem from "../../assets/images/layout/big-add-item.svg"
import categories from "../../assets/images/layout/game-categories-section.svg"
import CustomModal from "../../common/components/CustomModal"
import modalTableColumns from "./components/ModalTableColumns"
import { connect } from "react-redux";
import FormSearch from "../../UI/formSearch/FormSearch";
import { SITE_PERMISSION } from "../../common/constants/common";
import clsx from "clsx";
import purgeCache from "../../assets/images/common/purgeCache.svg";
import SelectInput from "../../common/inputs/common/SelectInput";
import { categoriesSortOptions } from "./common/constants";
import SelectTableView from "./components/SelectTableView";
import Loader from "../../common/components/Loader";

const titleHeight = 40;
const boxWidth = 290;
const boxHeight = 46;
const verticalSpace = 8;
const horizontalSpace = 16;
const padding = 32;
const paddingBottom = 16;
const baseCategoryWidth = 290;
const baseCategoryHeight = 46;


class GameCategories extends Component {
  constructor(props) {
    super(props)

    clearPageNumber('/game-categories/:id')
    handleChangePath(props)

    const xBoxCount = 0
    const yBoxCount = 0

    this.state = {
      activePage: 1,
      pageCount: 1,
      tooltipOpen: false,
      xBoxCount,
      yBoxCount,
      pageCategoriesCount: xBoxCount * yBoxCount, // categories count on one page
      categoryWidth: baseCategoryWidth,
      categoryHeight: baseCategoryHeight,
      contentWidth: 0,
      contentHeight: 0,
      addCategoryModalVisibility: false,
      removeCategoryModalVisibility: false,
      currentCategory: null,
      dropAreaHighlighted: false,
      gameCategories: [],
      searchValue: '',
      modalSearchValue: '',
      agentPermissions: props.agentSelf?.permissions || [],
      sortOption: 'Latest_created',
      isPurgeCacheDisabled: false,
    }

    this.fileInputRef = null
    this.boxRef = null
  }

  calculateBoxCounts = () => {
    const {gameCategories} = this.props
    const {offsetHeight, offsetWidth} = this.boxRef
    const contentWidth = offsetWidth - padding * 2
    const contentHeight = offsetHeight - titleHeight - padding - paddingBottom

    let xBoxCount = Math.floor(contentWidth / (boxWidth + horizontalSpace))
    let yBoxCount = Math.floor(contentHeight / (boxHeight + verticalSpace))

    if ((boxWidth + horizontalSpace) * xBoxCount + boxWidth <= contentWidth) {
      xBoxCount++
    }

    while ((boxHeight + verticalSpace) * yBoxCount + boxHeight <= contentHeight) {
      yBoxCount++
    }

    const categoryWidth = Math.floor(contentWidth - horizontalSpace * (xBoxCount - 1)) / xBoxCount

    let categoryHeight = (Math.floor(contentHeight - (verticalSpace + 2) * (yBoxCount - 1)) / yBoxCount) - 5
    categoryHeight = categoryHeight < baseCategoryHeight ? baseCategoryHeight : categoryHeight


    const pageCategoriesCount = xBoxCount * yBoxCount
    const pageCount = Math.ceil(gameCategories.length / pageCategoriesCount)
    const activePage = this.state.activePage >= pageCount
      ? pageCount
      : this.state.activePage

    this.setState({
      xBoxCount,
      yBoxCount,
      categoryWidth,
      categoryHeight,
      pageCategoriesCount,
      pageCount,
      activePage,
      contentWidth,
      contentHeight,
    })
  }

  handleResizeWindow = () => {
    this.calculateBoxCounts()
  }

  componentDidMount() {
    this.props.getGameCategories();
    window.addEventListener('resize', this.handleResizeWindow)
    window.addEventListener('load', this.handleResizeWindow)

    this.calculateBoxCounts()
    this.props.resetGameCategory()
    this.setState({ agentPermissions: this.props.agentSelf?.permissions || [] });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.verticalCollapsed !== prevProps.verticalCollapsed
    || this.props.gameCategories.length !== prevProps.gameCategories.length) {
      this.calculateBoxCounts()
    }
    if (prevProps.agentSelf !== this.props.agentSelf) {
      this.setState({agentPermissions: this.props.agentSelf?.permissions || []});
    }

    if (this.props.gameCategories !== prevProps.gameCategories) {
      const { gameCategories } = this.props;
      const {xBoxCount, yBoxCount, pageCategoriesCount} = this.state;
      const withoutPagination = gameCategories.length <= xBoxCount * yBoxCount;

      const currentLength = withoutPagination ? gameCategories.length : 30;
      const categoriesLimit = Math.min(gameCategories.length, currentLength);
      this.setState({
        pageCategoriesCount: categoriesLimit,
        gameCategories,
        activePage: 1,
      });
    }

    if (!this.props.isDeletingCategory && this.props.isDeletingCategory !== prevProps.isDeletingCategory
      || !this.props.isCreatingCategory && this.props.isCreatingCategory !== prevProps.isCreatingCategory) {
      this.props.getGameCategories();
    }

    if (!this.props.isCreatingCategory
        && this.props.isCreatingCategory !== prevProps.isCreatingCategory
        && !this.props.isCreatingCategory) {
      this.props.alert.success(i18n.t('crm.alerts.categoryCreated'));
    }
    if (!this.props.isDeletingCategory
        && this.props.isDeletingCategory !== prevProps.isDeletingCategory
        && !this.props.deletingError) {
      this.props.alert.error(i18n.t('crm.alerts.categoryRemoved'));
    }
    if (this.props.errorPurgeCache
        && this.props.errorPurgeCache !== prevProps.errorPurgeCache
    )
    {
      this.props.alert.error(i18n.t('crm.errorPurgeCache'));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeWindow)
    window.removeEventListener('load', this.handleResizeWindow)
  }

  handleChangePage = (activePage) => {
    this.setState({activePage})
  }

  handleAddCategory = () => {
    this.setState({addCategoryModalVisibility: true})
  }

  handleSendCategoryData = ({
    categoryName,
    categoryEnName,
    categoryEsName,
    validFormData,
    handleChangeCategoryGames=()=>{},
  }) => {
    if (validFormData) {
      // TODO: Send category data - need to implement
      this.setState({
        addCategoryModalVisibility: false,
        currentCategory: null,
      })
      const categoryGames = handleChangeCategoryGames()

      const categoryData = {
        label: categoryName,
        name: categoryEnName,
        nameEs: categoryEsName,
        games: categoryGames,
      }

      this.props.createGameCategory(categoryData);
    }
  }

  handleChangeCategory = (category) => {
    this.setState({
      addCategoryModalVisibility: true,
      currentCategory: category,
    })
  }

  handleClickDecline = () => {
    // TODO: Remove category on th server

    this.setState({
      currentCategory: null,
      removeCategoryModalVisibility: false,
    })
  }

  handleClickRemoveCategory = (category) => {
    this.props.removeCategory(category.id)
    this.setState({
      currentCategory: null,
      removeCategoryModalVisibility: false,
    })
  }

  handleRemoveCategory = (category) => {
    this.setState({
      currentCategory: category,
      removeCategoryModalVisibility: true,
    })
  }

  handleOpenCategory = (event, categoryId) => {
    event.preventDefault()
    event.stopPropagation()

    const {history} = this.props
    history.push(`/game-categories/${categoryId}`)
  }

  handleSearch = (event) => {
    this.setState({
      searchValue: event.target.value,
    })
  }

  handleChangeModalSearchValue = (e) => {
    const modalSearchValue = e.target.value;
    this.setState({modalSearchValue});
  }

  renderCategoriesRow = (gameCategories, rowNum) => {
    const {xBoxCount, categoryWidth, categoryHeight} = this.state
    const firstBoxNumber = xBoxCount * rowNum
    return (
      <div
        key={`category-row-${rowNum}`}
        className="category-row"
      >
        {gameCategories
          .slice(firstBoxNumber, firstBoxNumber + xBoxCount)
          .map((category, ind) => (
            <CategoryBox
              key={`category-${firstBoxNumber + ind}`}
              category={category}
              categoryWidth={categoryWidth}
              categoryHeight={categoryHeight}
              onChangeCategory={this.handleChangeCategory}
              onRemoveCategory={this.handleRemoveCategory}
              onOpenCategory={(event) => this.handleOpenCategory(event, category.id)}
            />
          ))
        }
      </div>
    )
  }

  renderCategories = (gameCategories) => {
    const {
      xBoxCount,
      categoryWidth,
      categoryHeight,
      activePage,
      pageCategoriesCount,
    } = this.state;

    const firstCategoryNumber = (activePage - 1) * pageCategoriesCount;
    const lastCategoryNumber =
        Math.min(firstCategoryNumber + pageCategoriesCount, gameCategories.length);

    const categoriesToRender = gameCategories.slice(firstCategoryNumber, lastCategoryNumber);

    const rows = [];
    for (let i = 0; i < categoriesToRender.length; i += xBoxCount) {
      const rowCategories = categoriesToRender.slice(i, i + xBoxCount);
      rows.push(
          <div key={`category-row-${i / xBoxCount}`} className="category-row">
            {rowCategories.map((category, index) => (
                <CategoryBox
                    key={index}
                    category={category}
                    categoryWidth={categoryWidth}
                    categoryHeight={categoryHeight}
                    onRemoveCategory={this.handleRemoveCategory}
                    onOpenCategory={(event) => this.handleOpenCategory(event, category.id)}
                />
            ))}
          </div>
      );
    }

    return rows;
  };

  purgeCache = () => {
    this.props.purgeCacheGameCategory();

    this.setState({isPurgeCacheDisabled: true});

    setTimeout(() => {
      this.setState({ isPurgeCacheDisabled: false });
    }, 60 * 1000);
  };

  handleSortChange = (sortOption) => {
    if (sortOption) {
      this.setState({ sortOption }, () => {
        this.sortCategories(sortOption);
      });
    }

    this.sortCategories();
  };

  sortCategories = () => {
    const { gameCategories, sortOption } = this.state;
    let sortedCategories;

    switch (sortOption) {
      case 'Latest_created':
        sortedCategories = gameCategories.slice().sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        break;

      case 'Latest_updated':
        sortedCategories = gameCategories.slice().sort((a, b) => {
          return new Date(b.lastChangedAt) - new Date(a.lastChangedAt);
        });
        break;

      case 'Games_amount':
        sortedCategories = gameCategories.slice().sort((a, b) => {
          return b.gameCount - a.gameCount;
        });
        break;

      case 'aZ':
        sortedCategories = gameCategories.slice().sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        break;

      case 'Za':
        sortedCategories = gameCategories.slice().sort((a, b) => {
          return b.name.localeCompare(a.name);
        });
        break;

      default:
        return gameCategories;
    }

    this.setState(p => ({
      ...p,
      gameCategories: sortedCategories
    }));
  }

  onChangeRecords = (recordsPerPage) => {
    let pageCategoriesCount = recordsPerPage;

    if (recordsPerPage === 'All') {
      pageCategoriesCount = this.props.gameCategories.length;
    }

    const pageCount = Math.ceil(this.props.gameCategories.length / pageCategoriesCount);

    this.setState({
      pageCategoriesCount,
      pageCount,
      activePage: 1,
    });
  }

  render() {
    const {
      xBoxCount,
      yBoxCount,
      activePage,
      pageCount,
      pageCategoriesCount,
      addCategoryModalVisibility,
      removeCategoryModalVisibility,
      currentCategory,
      gameCategories,
      searchValue,
      modalSearchValue,
      agentPermissions,
      isPurgeCacheDisabled,
      sortOption,
    } = this.state;
    const {
      isLoadingGames,
    } = this.props;

    const canEditGameCategories = agentPermissions.includes(SITE_PERMISSION.Game__Edit_Game_Categories);
    const canEditSiteGames = agentPermissions.includes(SITE_PERMISSION.Game__Edit_Game_Site);
    const canPurgeCash = canEditGameCategories || canEditSiteGames;

    const firstCategoryNumber = (activePage - 1) * pageCategoriesCount;
    const isLastPage = activePage === pageCount;
    const gameCategoriesFiltered = gameCategories
        .filter(category => searchValue === ''
            || category.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
    const withoutPagination = gameCategories.length <= xBoxCount * yBoxCount
                              || gameCategoriesFiltered.length < pageCategoriesCount;

    if (gameCategoriesFiltered) {
        this.state.activePage = 1
    }

    const sortOptions = categoriesSortOptions.map(option => ({
      value: option,
      label: i18n.t(`crm.${option}`)
    }));

    return (
      <>
        <div className="game-categories">
          <MetaTags>
            <title>{i18n.t('content.gameCategoriesList')} - {process.env.REACT_APP_APP_NAME}</title>
          </MetaTags>
          <div className="">
            <Row>
              <Col xs="12">
                <div className="page-title-box"></div>
              </Col>
            </Row>

            <div className="row game-categories__wrapper">
              <div
                className={`col col-12 game-categories__content ${
                  withoutPagination ? 'without-pagination' : ''
                } ${isLastPage ? 'last-page' : ''}`}
                ref={ref => this.boxRef = ref}
              >
                <div className='col col-12 game-categories__title align-items-center'>
                  <div className='d-flex align-items-center'>
                    <img className="categories" src={categories} alt="" width={24} height={24}/>
                    <span className="categories-title">
                        {i18n.t('content.сategories')}
                      </span>
                    <img
                        className={clsx("add-game-category mr-10", {
                          'cannot-create-game': !canEditGameCategories,
                        })}
                        src={addItem}
                        alt=""
                        width={40}
                        height={40}
                        onClick={this.handleAddCategory}
                    />
                  </div>

                  <div className='d-flex category-title__right-part align-items-center'>
                    <FormSearch
                        onChangeSearchValue={this.handleSearch}
                        showIconSearch
                    />
                    <div className='d-flex ml-10'>
                      <SelectInput
                          options={sortOptions || []}
                          value={sortOption}
                          onChange={this.handleSortChange}
                          className='sort-select-game-category'
                          isSearchable={false}
                      />
                    </div>
                    <button
                        className="btn btn-primary ml-10 purge-cash-category"
                        onClick={this.purgeCache}
                        disabled={isLoadingGames || !canPurgeCash || isPurgeCacheDisabled}
                    >
                      <span>{i18n.t('crm.purgeCache')}</span>
                      <img style={{margin: '0 0 0 5px'}} src={purgeCache} alt=""/>
                    </button>
                  </div>

                </div>

                {isLoadingGames ? (<Loader size='lg'/>) : (
                    this.renderCategories(
                        gameCategoriesFiltered
                            .slice(firstCategoryNumber, firstCategoryNumber + pageCategoriesCount)
                    )
                )}
              </div>
              <div className='select-count-game-categories'>
                <div className='mt-4'>
                  {!withoutPagination ? (
                      <SelectTableView
                          pageCount={pageCount}
                          activePage={activePage}
                          recordsCount={gameCategories?.length}
                          onChangePage={this.handleChangePage}
                          pageSize={pageCategoriesCount}
                          onChange={this.onChangeRecords}
                      />
                  ) : <span className='ml-10'>
                        {gameCategoriesFiltered.length} {i18n.t('content.categories')}
                      </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {addCategoryModalVisibility &&
            <TableBigModal
                onToggle={() => this.setState({
                  addCategoryModalVisibility: !addCategoryModalVisibility,
                  currentCategory: !this.state.addCategoryModalVisibility && this.state.currentCategory || null,
                })}
                renderHeader={({
                                 handleChangeCategoryGames = () => {
                                 }
                               }) => (
                    <CategoryGamesModalHeader
                        currentCategory={!this.state.addCategoryModalVisibility && this.state.currentCategory || null}
                        onSendCategoryData={(params) => this.handleSendCategoryData({
                        ...params,
                        handleChangeCategoryGames,
                    })}
                onChangeSearchValue={this.handleChangeModalSearchValue}
              />
            )}
            tableTitleText={i18n.t("content.chooseGames")}
            isOpen={addCategoryModalVisibility}
            columns={modalTableColumns}
            modalSearchValue={modalSearchValue}
            currentFilter={this.props.currentFilter}
            withFilter
          />
        }

        {removeCategoryModalVisibility &&
          <CustomModal
            titleText={i18n.t('crm.removeCategory')}
            isOpen={removeCategoryModalVisibility}
            onToggle={() => this.setState({
              removeCategoryModalVisibility: !removeCategoryModalVisibility,
              currentCategory: !this.state.addCategoryModalVisibility && this.state.currentCategory || null,
            })}
            onClick={() => this.handleClickRemoveCategory(currentCategory)}
            onClickCancelButton={this.handleClickDecline}
            btnText={'remove'}
            cancelBtnText={'decline'}
            withCancelButton={true}
            bodyRender={() => (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {i18n.t("crm.sure.want.remove.category")} '{currentCategory.name}'?
              </div>
            )}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  const { agentSelf } = state.AgentSelf;
  const {
    gameCategories,
    isLoadingGames,
    isCreatingCategory,
    isDeletingCategory,
    errorPurgeCache,
    errorGames,
  } = state.Games;
  const {
    currentFilter,
  } = state.Filter;
  return {
    gameCategories,
    isLoadingGames,
    isCreatingCategory,
    isDeletingCategory,
    errorPurgeCache,
    errorGames,
    agentSelf,
    currentFilter,
  };
};

export default React.memo(
  withRouter(
    connect(mapStateToProps, {
      getGameCategories,
      createGameCategory,
      removeCategory,
      resetGameCategory,
      purgeCacheGameCategory,
    })(withAlert()(GameCategories))
  )
);
