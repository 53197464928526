import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { TextField } from '@material-ui/core';

import { COUNTRIES } from '../../utils/countries';
import { CURRENCIES } from '../../utils/currencies';
import { GMT_LIST } from '../../utils/timezone';
import { LIMIT_LEVEL_TYPE_SELECT } from '../../utils/limitLevelTypeSelect';
import { SELECT_CATEGORIES } from '../../constants/selectCategories';
import { PAYMENT_AGENT_TYPE } from '../../utils/paymentAgentType';
import {
    EXPERIENCE_LEVEL,
    IDENTIFICATION_TYPES_VALUES, MEXICO_BANKS,
    VIP_LEVEL,
} from '../../common/constants/common';
import { Autocomplete } from '@material-ui/lab';
import { LANGUAGES } from '../../utils/languages';
import {BOOLEAN, CASHIER_BOOLEAN, CATEGORISATION, KYC_REQUIRED} from '../../utils/categorisation';
import {PROMOTION_STATUS_ARR} from "../../pages/Promotions/common/promotionConstants";
import { PRODUCTS } from "../../utils/products";
import { DOCUMENTS_TYPES, DOCUMENTS_SIDES } from '../../utils/KYC';
import {useDispatch, useSelector} from "react-redux";
import occupationOptions from "../../constants/occupationOptions";
import { useTranslation } from "react-i18next";
import {defaultAgent} from "../../constants/layout";
import {isSpecificBrandDoc, isWinpotMXSpecificDoc} from "../../utils/isSpecificBrandDoc";
import {SITE_BRAND} from "../../constants/siteBrands";
import {SELECT_PAYMENT_SUPPLIERS} from "../../utils/payment-suppliers";
import {SELECT_REQUIRE_FIELDS} from "../../utils/require-fields";
import {paymentMethods} from "../../utils/payment-methods";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";

/**
 *
 * @param category can be "gender", "countries", "currencies"
 * @param id
 * @param fulWidth
 * @param label
 * @param value
 * @param error
 * @param handleChange
 * @param disabled
 * @param options
 * @param hasClear
 * @param style
 * @param getOptionsDisabled
 * @param autoSelect boolean
 * @returns {JSX.Element}
 * @constructor
 * @className
 */
function ASelectField({
                          id,
                          filterSelection = [],
                          category = 'gender',
                          fulWidth = false,
                          label = true,
                          value,
                          error,
                          handleChange,
                          disabled = false,
                          options,
                          hasClear = true,
                          style,
                          className,
                          getOptionsDisabled,
                          autoSelect = false
                      }) {
    const currentStyle = {
        style: {
            ...(disabled && { color: '#20262d' }),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            fontWeight: '600',
            minWidth: fulWidth ? 'auto' : '250px',
            borderRadius: '3px',
            paddingTop: '8px',
            color: '#495057',
            width: fulWidth ? '100%' : '250px',
            ...(style ? style : {})
        }
    };

    const inputRef = useRef(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!value && inputRef.current?.value &&
            (category === SELECT_CATEGORIES.STATES || category === SELECT_CATEGORIES.CITIES)) {
                handleChange(inputRef.current?.value)
        }
    }, [inputRef.current?.value])

    const { states, currentCities } = useSelector(state => state.StatesAndCities);
    const { getVipLevelBrandName, state: stateVipBrand } = useVipLevelMapping();
    const { agentsList } = useSelector(state => state.AgentList);
    const dispatch = useDispatch();

    const { active: activeBrand } = useSelector(state => state.Brand);

    const optionsByCategory = {
        [SELECT_CATEGORIES.GENDER]: [
            { value: 'male', content: i18n.t(`common.gender.male`) },
            { value: 'female', content: i18n.t(`common.gender.female`) }
        ],
        [SELECT_CATEGORIES.PROFESSION]: occupationOptions.map(key => ({
            value: key,
            content: i18n.t(`retail.customer.KYC.profession.${key}`)
        })),
        [SELECT_CATEGORIES.COUNTRIES]: COUNTRIES.reduce((acc, key) => {
            acc[key] = { value: key, content: i18n.t(key) };
            return acc;
        }, {}),
        [SELECT_CATEGORIES.STATES]: states.map(({ text, state_code }) => ({
            value: text,
            content: i18n.t(state_code)
        })),
        [SELECT_CATEGORIES.CITIES]: currentCities.map(({ text }) => ({
            value: text,
            content: text
        })),
        [SELECT_CATEGORIES.SELECT_PAYMENT_METHODS]: paymentMethods.map(key => ({
            value: key,
            content: key
        })),
        [SELECT_CATEGORIES.LANGUAGES]: LANGUAGES.map(key => ({
            value: key,
            content: i18n.t(`language.${key}`)
        })),
        [SELECT_CATEGORIES.CURRENCIES]: CURRENCIES.map(key => ({
            value: key,
            content: key
        })),
        [SELECT_CATEGORIES.SELECT_REQUIRE_FIELDS]: SELECT_REQUIRE_FIELDS.map(({key,value}) => ({
            value: key,
            content: value
        })),
        [SELECT_CATEGORIES.SELECT_PAYMENT_SUPPLIERS]: SELECT_PAYMENT_SUPPLIERS.map(key => ({
            value: key,
            content: key
        })),
        [SELECT_CATEGORIES.TIMEZONE]: GMT_LIST.map(key => ({
            value: key,
            content: key
        })),
        [SELECT_CATEGORIES.LIMIT_LEVEL_TYPE_SELECT]: LIMIT_LEVEL_TYPE_SELECT.map(key => ({
            value: key,
            content: i18n.t(`admin.constants.${key}`)
        })),
        [SELECT_CATEGORIES.PAYMENT_AGENT_TYPE]: PAYMENT_AGENT_TYPE.map(key => ({
            value: key,
            content: i18n.t(`admin.callCenter.paymentAgentType.${key}`)
        })),
        [SELECT_CATEGORIES.IDENTIFICATION_TYPE]: IDENTIFICATION_TYPES_VALUES.map(key => ({
            value: key,
            content: i18n.t(`admin.identificationType.${key}`)
        })),
        [SELECT_CATEGORIES.VIP_LEVEL]: stateVipBrand && Object.entries(stateVipBrand).map(([key, value]) => ({
            value: key,
            content: value
        })),
        [SELECT_CATEGORIES.AGENT_OWNER]: agentsList?.map(agent => ({
            value: agent.id,
            content: agent.agentName
        })).concat(defaultAgent),
        [SELECT_CATEGORIES.EXPERIENCE_LEVEL]: EXPERIENCE_LEVEL.map(key => ({
            value: key,
            content: i18n.t(`admin.experienceLevel.${key}`)
        })),
        [SELECT_CATEGORIES.CATEGORISATION]: CATEGORISATION.map(key => ({
            value: key,
            content: i18n.t(`admin.constants.categorisation.${key}`)
        })),
        [SELECT_CATEGORIES.KYC_REQUIRED]: Object.entries(KYC_REQUIRED).map(([key, value]) => ({
            value: value,
            content: i18n.t(`admin.constants.${key}`)
        })),
        [SELECT_CATEGORIES.BOOLEAN]: Object.entries(BOOLEAN).map(([key, value]) => ({
            value: value,
            content: i18n.t(`admin.constants.${key}`)
        })),
        [SELECT_CATEGORIES.SELECT_CASHIER_BOOLEAN]: Object.entries(CASHIER_BOOLEAN).map(([key, value]) => ({
            value: value,
            content: i18n.t(`admin.constants.${key}`)
        })),
        [SELECT_CATEGORIES.BANK]: MEXICO_BANKS.map(bank => ({
            value: bank.value,
            content: `${bank.text} (${bank.value})`,
        })),
        [SELECT_CATEGORIES.PROMOTION_STATUSES]: PROMOTION_STATUS_ARR.map(key => ({
            value: key,
            content: key,
        })),
        [SELECT_CATEGORIES.DEFAULT]: options ? options : [],
        [SELECT_CATEGORIES.PRODUCTS]: PRODUCTS.map((key, ind) => ({
            value: ind + 1,
            content: key,
        })),
        [SELECT_CATEGORIES.DOCUMENT_TYPES]: DOCUMENTS_TYPES
            .filter(key => {
                if (isSpecificBrandDoc(key)) {
                    return isWinpotMXSpecificDoc(key) && activeBrand?.siteName === SITE_BRAND.WINPOTMX;
                } else {
                    return true;
                }
            })
            .map(key => ({
                value: key,
                content: i18n.t(`crm.document.type.${key}`),
            })),
        [SELECT_CATEGORIES.DOCUMENT_SIDES]: DOCUMENTS_SIDES.map(key => ({
            value: key,
            content: i18n.t(`crm.document.side.${key}`),
        })),
    };

    const getOptions = category => optionsByCategory[category] || [];

    const [valueObj, setValueObj] = useState({ content: '' });
    const [optionsList, setOptionsList] = useState([]);

    const originalList = useMemo(() => getOptions(category), [  category,  options,  states,  currentCities, agentsList,  i18n.language,]);

    useEffect(() => {
        if (originalList) {
            const _optionsList = Object.values(originalList).map(val => val.content);
            if (filterSelection.length > 0) {
                // show only the fields which in filterSelection and exist in the original list
                const filteredList = _optionsList.filter(val => filterSelection.includes(val));
                setOptionsList(filteredList);
            }
            else {
                setOptionsList(_optionsList);
            }

        }
    }, [originalList]);

    useEffect(() => {
        const obj = Object.values(originalList).find(val => val.value === value) || {};

        const isStateOrCityOfMexico =
            category === SELECT_CATEGORIES.STATES ||
            category === SELECT_CATEGORIES.CITIES;

        if (isStateOrCityOfMexico && !obj.value) {
            obj.value = value;
            obj.content = value;
        }

        if (category === SELECT_CATEGORIES.VIP_LEVEL) {
            obj.value = value
            obj.content = getVipLevelBrandName(value) || ''
        }

        setValueObj(obj);
    }, [value, originalList, category]);

    const handleChange_ = useCallback(
        (_, value) => {
            const selectedObj = Object.values(originalList).find(elem => elem.content === value);

            if (!selectedObj) {
                handleChange(value || '');
            } else {
                handleChange(selectedObj.value);
            }
        },
        [originalList, handleChange]
    );

    return (
        <div className="content-row position-relative mb-0" style={{width: '100%'}}>
            <Autocomplete
                disablePortal
                id={id}
                className={`${className} fieldContainer selectField ${
                    disabled ? 'disabledField' : ''
                }`}
                freeSolo={autoSelect}
                autoSelect={!value && autoSelect}
                options={optionsList}
                getOptionDisabled={getOptionsDisabled}
                style={currentStyle.style}
                onChange={handleChange_}
                value={valueObj?.content}
                disableClearable={!hasClear}
                disabled={disabled}
                noOptionsText={i18n.t('crm.select.no.options')}
                renderInput={params => (
                    <TextField
                        InputProps={{
                            style: {
                                color: disabled ? '#20262d' : undefined,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '14px',
                                background: 'none',
                                width: style?.width ? style.width : fulWidth ? '100%' : '200px',
                            },
                        }}
                        {...params}
                        inputRef={autoSelect ? inputRef : null}
                        onBlur={(e) => {
                            handleChange_(null, e.target?.value)
                        }}
                        error={error}
                        label={label}
                    />
                )}
            />
            {error && (
                <span style={{ color: '#d32f2f', marginLeft: '3px' }}>{error}</span>
            )}
        </div>
    );
}

export default React.memo(ASelectField);
