import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../i18n';
import TextareaInput from '../../common/inputs/TextareaInput';
import StatusRadio from './StatusRadio';
import {
    prepareUpdatePlayerStatusDto,
    prepareUpdatePlayerStatusFieldErrors
} from '../../pages/CRM/general/model/playerEditModel';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';
import InputWrapper from '../../common/wrappers/InputWrapper';
import i_51 from '../../assets/images/common/Frame 176 (1).svg';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import {
    CLOSED_REASONS_OPTIONS,
    FROZEN_REASONS_OPTIONS,
    SELF_CLOSED_REASONS_OPTIONS,
    SOFTBLOCK_REASONS_OPTIONS
} from "./ChangeReasonOptions/ChangeReasonOptions";
import {A_COMMON_DATA_FIELD} from "../../constants/aCommonDataField";
import ACommonDataField from "../GenericComponents/CommonDataField";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {getCoolOffData} from "../../store/brand/brandPage/actions";

function StatusChangeModal({ status, error, isLoading, onHide, onConfirm }) {
    useHandleEsc(onHide);
    const [selectedStatus, setSelectedStatus] = useState(status);
    const [reasonDropdownValue, setReasonDropdownValue] = useState('');
    const [coolOffValue, setCoolOffValue] = useState('');
    const [reasonText, setReasonText] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [isShowCoolOffOptions, setIsShowCoolOffOptions] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState(SOFTBLOCK_REASONS_OPTIONS);
    const { coolOffData } = useSelector(state => state.BrandSettingsPage);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCoolOffData());
    }, [])

    const coolOffOptions = useMemo(() => {
        return coolOffData.map(option => ({
            content: option.coolOffId,
            value: option.timestamp,
        }))
    }, [coolOffData])


    useEffect(() => {
        if (error && error.statusCode === 400) {
            setFieldErrors(prepareUpdatePlayerStatusFieldErrors(error?.data));
        } else if (error) {
            setFieldErrors({});
        }
    }, [error]);

    useEffect(() => {
        switch (selectedStatus) {
            case 'SoftBlock':
                setDropdownOptions(SOFTBLOCK_REASONS_OPTIONS)
                break;
            case 'Frozen':
                setDropdownOptions(FROZEN_REASONS_OPTIONS)
                break;
            case 'Closed':
                setDropdownOptions(CLOSED_REASONS_OPTIONS)
                break;
            case 'SelfClosed':
                setDropdownOptions(SELF_CLOSED_REASONS_OPTIONS)
                break;
            default:
                setDropdownOptions(null);
                break;
        }

        if (selectedStatus === 'SoftBlock') {
            setIsShowCoolOffOptions(true)
        }
    }, [selectedStatus]);


    const handleStatusChange = useCallback(value => {
        setSelectedStatus(value);
        setReasonDropdownValue('');
        setReasonText('');
    }, []);

    const handleDropdownReasonChange = useCallback(value => {
        setReasonDropdownValue(value);
    }, []);

    const handleCoolOffChange = useCallback(value => {
        setCoolOffValue(value);
    }, []);

    const handleTextChange = useCallback(value => {
        setReasonText(value);
    }, []);

    const handleSave = useCallback(() => {
        const data = {
            status: selectedStatus,
            reason: reasonText ? reasonText : reasonDropdownValue
        };

        if (reasonDropdownValue === 'Cool Off') {
            data.reason = 'COOL_OFF_START';
            const selectedCoolOffOption = coolOffOptions.find(option => option.value === coolOffValue);
            data.coolOffId = selectedCoolOffOption ? selectedCoolOffOption.content : '';
        }

        onConfirm(prepareUpdatePlayerStatusDto(data));
    }, [selectedStatus, reasonDropdownValue, reasonText, coolOffValue]);


    const isOtherSelected = reasonDropdownValue === 'Other';
    const isCoolOffSelected = reasonDropdownValue === 'Cool Off'

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="app-status-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.changeStatus')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <InputWrapper
                    errorMessage={fieldErrors.status}
                    className="d-block"
                >
                    <div className="mb-4">
                        <StatusRadio
                            onChange={handleStatusChange}
                            value={selectedStatus}
                        />
                    </div>
                </InputWrapper>

                <div className="d-flex flex-column">
                    <Label htmlFor="formFileLg" className="form-label text-center">
                        {i18n.t('crm.indicateTheReason')}*
                    </Label>

                    <div className='dropdown-reason w-100'>
                        {dropdownOptions ? (
                            <div className="d-flex justify-content-center">
                                <ACommonDataField
                                    label={'Select Reason'}
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    value={reasonDropdownValue || ''}
                                    handleChange={handleDropdownReasonChange}
                                    options={dropdownOptions.map(e => ({
                                        value: e,
                                        content: e
                                    }))}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div className={clsx('reason-area-wrapper', {
                        'mt-3': isOtherSelected
                    })}>
                        {isOtherSelected || !dropdownOptions ? (
                            <InputWrapper errorMessage={fieldErrors.reason}>
                                <TextareaInput
                                    className="form-control form-control-gray"
                                    rows="5"
                                    value={reasonText}
                                    onChange={handleTextChange}
                                    placeholder={i18n.t('crm.writeSomething')}
                                />
                            </InputWrapper>
                        ) : null}
                    </div>

                    {isShowCoolOffOptions && isCoolOffSelected && (
                        <div className='dropdown-reason w-100'>
                            <div className="d-flex justify-content-center">
                                <ACommonDataField
                                    label={'Select Cool Off'}
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    value={coolOffValue || ''}
                                    handleChange={handleCoolOffChange}
                                    options={coolOffOptions}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-rounded m-auto"
                    onClick={handleSave}
                    disabled={isLoading || !(reasonDropdownValue || reasonText).trim()}
                >
                    <img src={i_51} alt=""/>
                    {i18n.t('crm.submit')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

StatusChangeModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};

export default React.memo(StatusChangeModal);
