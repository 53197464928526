const EMAIL_REG_EXP = /^([a-zA-Z0-9]+([._+-]?[a-zA-Z0-9])+)@([a-zA-Z0-9]+([.-]?[a-zA-Z0-9])+\.[a-zA-Z]{2,})$/;
const NAME_REG_EXP =
    /^([a-zA-ZáéíñóúüÁÉÍÑÓÚÜ][\w'\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ'\-. ])[^А-Яа-яЁё0-9\\[\]_!¡?÷¿,№"\\/+=@^#$%ˆ\\&*(){}|~<>;:`]{1,}$/;
const MIDDLE_NAME_REG_EXP =
    /^(([a-zA-ZáéíñóúüÁÉÍÑÓÚÜ][\w'\-,.]|[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ'\-. ])[^А-Яа-яЁё0-9\\[\]_!¡?÷¿,№"\\/+=@^#$%ˆ\\&*(){}|~<>;:`])*$/;
const USERNAME_REG_EXP = /^[a-zA-Z0-9._]+$/;
const PASSWORD_REG_EXP =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=^\S+$)(?=.[^А-Яа-яЁёáéíñóúüÁÉÍÑÓÚÜ]*$)(?=.{8,30}$)/;

const PROMOTION_MAX_MONEY_VALUE_REG_EXP = /^(\d+\.\d{0,2}).*$/;
const PASSWORD_REG_EXP_GLOBAL = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
const PHONE_REG_EXP = /[^0-9\+]+/;
const SPANISH_AND_LATIN_REG_EXP = /^([a-zA-ZáéíóúñÁÉÍÓÚÑüÜ]+)$/;

const LATIN_REG_EXP = /^[a-zA-Z]+$/;
const NUMBERS_WITH_PLUS_REG_EXP = /^[0-9+]+$/;
const LATIN_WITHOUT_SPACES_REG_EXP = /^[a-zA-Z0-9@\-_.]+$/;

const NUMBERS_REG_EXP = /^[0-9+]+$/;
const SHORT_CARD_NUMBER = /^\d{4}$/;

const UPPERCASE_REG_EXP = /[A-Z]/;
const WITH_PLUS_SIGN_REG_EXP = /\+\d+@/;
const SPECIAL_SYMBOLS = /[!@№#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~\d]/g;
const NUMBERS_AND_LETTERS_REG_EXP = /[^a-zA-Z0-9]/g;
const ONLY_DIGITS_REG_EXP = /\D/g;
const ONLY_DIGITS_AND_COMMA_REG_EXP = /[^\d,]/g;
const COMMA_DOT_DIGITS_REG_EXP = /[^0-9.,]/g;


export  {
    EMAIL_REG_EXP,
    NAME_REG_EXP,
    MIDDLE_NAME_REG_EXP,
    USERNAME_REG_EXP,
    PASSWORD_REG_EXP,
    PHONE_REG_EXP,
    SPANISH_AND_LATIN_REG_EXP,
    PASSWORD_REG_EXP_GLOBAL,
    PROMOTION_MAX_MONEY_VALUE_REG_EXP,
    UPPERCASE_REG_EXP,
    WITH_PLUS_SIGN_REG_EXP,
    LATIN_REG_EXP,
    NUMBERS_REG_EXP,
    NUMBERS_WITH_PLUS_REG_EXP,
    LATIN_WITHOUT_SPACES_REG_EXP,
    SPECIAL_SYMBOLS,
    SHORT_CARD_NUMBER,
    NUMBERS_AND_LETTERS_REG_EXP,
    ONLY_DIGITS_REG_EXP,
    ONLY_DIGITS_AND_COMMA_REG_EXP,
    COMMA_DOT_DIGITS_REG_EXP
}
