import React, { useEffect } from 'react';
// import "./styles.scss";
import close from "../../assets/images/common/close 1.svg";
import i18n from "i18next";
import useHandleEsc from "../../common/hooks/useHandleEsc";
import i_51 from "../../assets/images/common/Frame 176 (1).svg";
import PropTypes from "prop-types";


const DeclineSubmitBtnGroup = (props) => {
    const {
        saveMsg = i18n.t('admin.save'),
        cancelMsg = i18n.t('admin.cancel'),
        onSave,
        onCancel,
        isDisabled,
        isDisabledSaveButton
    } = props;
    useHandleEsc(onCancel);

    return (
        <div className="btns__wrapper">
            <button
                className="btn btn-rounded btn-light"
                style={{ marginRight: '12px'}}
                onClick={onCancel}
                disabled={isDisabled}
            >
                <img className="me-2" src={close} alt="" />
                {cancelMsg}
            </button>

            <button
                className="btn btn-rounded btn-primary"
                onClick={onSave}
                disabled={isDisabled || isDisabledSaveButton}
            >
                <img src={i_51} alt="" />
                {saveMsg}
            </button>
        </div>
    )
};

DeclineSubmitBtnGroup.propTypes = {
    saveMsg: PropTypes.string,
    cancelMsg: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    isDisabled: PropTypes.bool,
};

DeclineSubmitBtnGroup.defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    isDisabled: false,
};

export default DeclineSubmitBtnGroup;
