import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { isArray } from 'lodash';
import Formatter from '../../utils/Formatter';
import group_11800_1 from '../../assets/images/common/Group 11800_1.svg';
import { getGuidShortFormat } from '../../common/utils/common';
import ClipboardButton from '../../common/cellData/CopyButton';
import NewCustomTable from '../../components/NewCustomTable';
import { getType } from '../Admin/site/dataAdapters';
import group from '../../assets/images/common/Tooltip.svg';
import LoadingScreen from '../../components/LoadingScreen';
import {useTranslation} from "react-i18next";

const TABS = {
    AFFILIATE: 'AFFILIATE',
    AGENT: 'AGENT',
    PLAYER: 'PLAYER',
    DOCUMENT: 'DOCUMENT',
    VERIFICATION: 'VERIFICATION'
    // AGENCY_MANAGER: 'AGENCY_MANAGER',
};

function AgentProfileTabHeader({ activeTab, onChange }) {
    const { i18n } = useTranslation();

    return (
        <ul className="d-flex card-body__tabs">
            {Object.keys(TABS).map(key => {
                return (
                    <li
                        key={key}
                        className={`px-0 ${activeTab === key ? 'active' : ''}`}
                        onClick={() => onChange(key)}
                    >
                        {i18n.t(`agent.constants.${key}`)}
                    </li>
                );
            })}
        </ul>
    );
}

function AgentPlayerAuditTable({ list }) {
    const { i18n } = useTranslation();

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }
    const columns = [
        { label: i18n.t('agent.time') },
        { label: i18n.t('agent.action') },
        { label: i18n.t('agent.property') },
        { label: i18n.t('agent.from') },
        { label: i18n.t('agent.to') },
        { label: i18n.t('agent.player') },
        { label: i18n.t('agent.site') }
    ];

    const filteredList = list.filter(item => {
        const info = item.info;
        if (
            !info ||
            !info.property ||
            !info.to ||
            !info.from ||
            !isArray(item.info)
        )
            return true;
    });

    const findInfo = (info, property) => {
        if (!info || !isArray(info) || info.length === 0) return '';
        if (getType(info[0][property]) === 'object') return '';
        if (info[0][property]) return info[0][property];
        return '';
    };

    return (
        <NewCustomTable columns={columns}>
            {filteredList.map((item, index) => (
                <tr key={index}>
                    <td>{Formatter.displayDateTime(item.createdAt)}</td>
                    <td>{item.action || ''}</td>
                    <td>{findInfo(item.info, 'property')}</td>
                    <td>{findInfo(item.info, 'from')}</td>
                    <td>{findInfo(item.info, 'to')}</td>
                    <td>{item.player}</td>
                    <td>{item.site}</td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function AgentAuditTable({ list }) {
    const { i18n } = useTranslation();

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }

    const columns = [
        { label: i18n.t('agent.time') },
        { label: i18n.t('agent.action') },
        { label: i18n.t('agent.property') },
        { label: i18n.t('agent.from') },
        { label: i18n.t('agent.to') },
        { label: i18n.t('agent.id') }
    ];

    const filteredList = list.filter(item => {
        const info = item.info;
        if (
            !info ||
            !info.property ||
            !info.to ||
            !info.from ||
            !isArray(item.info)
        )
            return true;
    });

    const findInfo = (info, property) => {
        if (!info || !isArray(info) || info.length === 0) return '';
        const infoData = info[0] || {};
        const propertyData = infoData[property];
        if (getType(propertyData) === 'object') return '';
        if (typeof propertyData === 'object') return JSON.stringify(propertyData);
        if (propertyData) return propertyData;
        return '';
    };

    return (
        <NewCustomTable columns={columns}>
            {filteredList.map((item, index) => (
                <tr key={index}>
                    <td>{Formatter.displayDateTime(item?.createdAt)}</td>
                    <td>{item?.action || ''}</td>
                    <td>{findInfo(item?.info, 'property')}</td>
                    <td>{findInfo(item?.info, 'from')}</td>
                    <td>{findInfo(item?.info, 'to')}</td>
                    <td>{findInfo(item?.info, 'id')}</td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function AgentAffiliateAuditTable({ list }) {
    const { i18n } = useTranslation();

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }

    const columns = [
        { label: i18n.t('agent.time') },
        { label: i18n.t('agent.action') },
        { label: i18n.t('agent.property') },
        { label: i18n.t('agent.from') },
        { label: i18n.t('agent.to') },
        { label: i18n.t('agent.affiliate') },
        { label: i18n.t('agent.site') }
    ];

    const filteredList = list.filter(item => {
        const info = item.info;
        if (
            !info ||
            !info.property ||
            !info.to ||
            !info.from ||
            !isArray(item.info)
        )
            return true;
    });

    const findInfo = (info, property) => {
        if (!info || !isArray(info) || info.length === 0) return '';
        if (getType(info[0][property]) === 'object') return '';
        if (info[0][property]) return info[0][property];
        return '';
    };

    return (
        <NewCustomTable columns={columns}>
            {filteredList.map((item, index) => (
                <tr key={index}>
                    <td>{Formatter.displayDateTime(item.createdAt)}</td>
                    <td>{item.action || ''}</td>
                    <td>{findInfo(item.info, 'property')}</td>
                    <td>{findInfo(item.info, 'from')}</td>
                    <td>{findInfo(item.info, 'to')}</td>
                    <td>{item.affiliate}</td>
                    <td>{item.site}</td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function AgentVerificationAuditTable({ list }) {
    const { i18n } = useTranslation();

    if (list.length === 0) {
        return (
            <div>{i18n.t('tabs.NoAudit')}</div>
        )
    }

    const columns = [
        { label: i18n.t('agent.time') },
        { label: i18n.t('agent.action') },
        { label: i18n.t('agent.method') },
        { label: i18n.t('agent.code') },
        { label: i18n.t('agent.site') },
        { label: i18n.t('agent.player') }
    ];

    const filteredList = list.filter(item => {
        const info = item.info;
        if (
            !info ||
            !info.property ||
            !info.to ||
            !info.from ||
            !isArray(item.info)
        )
            return true;
    });

    const findInfo = (info, property) => {
        if (!info || !isArray(info) || info.length === 0) return '';
        if (getType(info[0][property]) === 'object') return '';
        if (info[0][property]) return info[0][property];
        return '';
    };

    return (
        <NewCustomTable columns={columns}>
            {filteredList.map((item, index) => (
                <tr key={index}>
                    <td>{Formatter.displayDateTime(item.createdAt)}</td>
                    <td>{item.action || 'Code sent'}</td>
                    <td>{item.method}</td>
                    <td>{item.code}</td>
                    <td>{item.site}</td>
                    <td>{item.player}</td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function AgencyManagerTable({ list }) {
    const { i18n } = useTranslation();

    const columns = [
        { label: i18n.t('agent.agentName') },
        { label: 'ID' },
        { label: i18n.t('agent.email') },
        { label: i18n.t('agent.lastLogin') },
        { label: i18n.t('agent.createdTime') },
        { label: i18n.t('agent.sites') }
    ];

    return (
        <NewCustomTable columns={columns}>
            {list.map((item, index) => (
                <tr key={index}>
                    <td>{item.agentName}</td>
                    <td>
                        <span className="me-2 app-text-primary">
                            {getGuidShortFormat(item.id)}
                        </span>
                        <ClipboardButton text={item.id} />
                    </td>
                    <td>{item.email}</td>
                    <td>{Formatter.displayDateTime(item.lastLogin)}</td>
                    <td>{Formatter.displayDateTime(item.createdTime)}</td>
                    <td>
                        <span className="me-2">
                            {item.sites}&nbsp;{i18n.t('agent.addresses')}
                        </span>
                        <button className="btn btn-rounded btn-light app-btn-only-img-xs">
                            <img src={group_11800_1} alt="" />
                        </button>
                    </td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

function AgentViewTablesCard({ agent }) {
    const { i18n } = useTranslation();
    const [activeTab, setActiveTab] = useState(TABS.AFFILIATE);

    if (!agent) {
        return <LoadingScreen />;
    }

    return (
        <Card>
            <div className="card-body__header pt-0 d-flex align-items-center">
                <img className="me-3" src={group} alt="" />

                <span className="font-w-500 text-uppercase">
                    {i18n.t('tabs.AUDIT')}
                </span>
            </div>

            <CardBody>
                <AgentProfileTabHeader
                    activeTab={activeTab}
                    onChange={setActiveTab}
                />

                <div className="position-relative">
                    <div className={TABS.AGENT === activeTab ? '' : 'd-none'}>
                        <AgentAuditTable list={agent?.audit?.agent || []} />
                    </div>

                    <div
                        className={TABS.AFFILIATE === activeTab ? '' : 'd-none'}
                    >
                        <AgentAffiliateAuditTable
                            list={agent?.audit?.affiliate || []}
                        />
                    </div>

                    <div
                        className={TABS.PLAYER === activeTab ? '' : 'd-none'}
                    >
                        <AgentPlayerAuditTable
                            list={agent?.audit?.player || []}
                        />
                    </div>

                    <div
                        className={TABS.DOCUMENT === activeTab ? '' : 'd-none'}
                    >
                        <AgentAuditTable list={agent?.audit?.document || []} />
                    </div>

                    <div
                        className={
                            TABS.VERIFICATION === activeTab ? '' : 'd-none'
                        }
                    >
                        <AgentVerificationAuditTable
                            list={agent?.audit?.verification || []}
                        />
                    </div>
                </div>

                {/*{TABS.AGENCY_MANAGER === activeTab &&*/}
                {/*  <div className="position-relative">*/}
                {/*    <SearchInput*/}
                {/*      onChange={setSearchText}*/}
                {/*      value={searchText}*/}
                {/*      placeholder={i18n.t('common.search')}*/}
                {/*      className="form-search-lg"*/}
                {/*    />*/}

                {/*    /!*<AgencyManagerTable list={data.agencyManager} />*!/*/}
                {/*  </div>*/}
                {/*}*/}
            </CardBody>
        </Card>
    );
}

AgentViewTablesCard.propTypes = {
    agent: PropTypes.object
};

export default React.memo(AgentViewTablesCard);
