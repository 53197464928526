import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import header_icon from '../../../assets/images/common/player (1) 1.svg';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { useSelector } from "react-redux";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { safeGet } from "../../../common/utils/common";
import { useTranslation } from "react-i18next";
import MuiSelect from "../../../components/GenericComponents/MuiSelect";
import { SITE_BRAND } from "../../../constants/siteBrands";

function AffiliateSubBlock(props) {
    const {
        player,
        onChange,
        isEdit,
        onEdit,
        className = '',
        canEdit: canEditPlayerInfo,
    } = props;
    const { t } = useTranslation();
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const { active: activeBrand } = useSelector(state => state.Brand);
    const agentPermissions = agentSelf?.permissions;
    const canEditAffiliateProduct = agentPermissions?.includes(SITE_PERMISSION.Player__Edit_Player_Info);
    const canEditAffiliateSection = agentPermissions?.includes(SITE_PERMISSION.Player__Edit_Affiliate_Info);

    const varOptions = Object.entries(player?.affiliateInformation || {})
        .filter(([key, value]) => key.startsWith('var'));

    const options = useMemo(() => {
        return varOptions.map(([key, value]) => ({
            value: value,
            content: `${t(`crm.${key}`)}: ${value}`,
        }));
    }, [t, varOptions]);

  const config = useMemo(() => [
    { stateKey: 'affiliateInformation.affiliateId', canEdit: canEditAffiliateSection && canEditPlayerInfo },
    { stateKey: 'affiliateInformation.sourceId', canEdit: canEditAffiliateSection && canEditPlayerInfo },
    { stateKey: 'affiliateInformation.clickId',  canEdit: canEditAffiliateSection && canEditPlayerInfo },
    {
        stateKey: 'affiliateInformation.qualifiedFTD',
        component: A_COMMON_DATA_FIELD.CHECK_BOX,
        canEdit: canEditAffiliateSection && canEditPlayerInfo
    },
    { stateKey: 'affiliateInformation.fullUrl', canEdit: canEditAffiliateSection && canEditPlayerInfo },
    {
      stateKey: 'affiliateInformation.product',
      component: A_COMMON_DATA_FIELD.SELECT_PRODUCT,
      canEdit: canEditAffiliateProduct
    },
    { stateKey: 'affiliateInformation.offer', canEdit: canEditAffiliateSection && canEditPlayerInfo },
    { stateKey: 'affiliateInformation.subId', canEdit: canEditAffiliateSection && canEditPlayerInfo },
    {
        stateKey: 'affiliateInformation.varSelection',
        canEdit: canEditAffiliateSection && canEditPlayerInfo,
        component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
        options: options,
    }
  ], [canEditAffiliateSection, canEditAffiliateProduct, canEditPlayerInfo, options]);

    function findValidOption(options) {
        const validOption = options.find(option => option.value !== '' && option.value !== undefined);
        return validOption ? validOption.content : t('crm.noAffiliatesVars');
    }

    return (
        <div className={`sub-content ${className}`}>
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={header_icon} alt="" />
                <span className="text-uppercase">
                    {t('crm.affiliate')}
                </span>
            </div>

            <Row xs={'auto'}>
                {
                    config.map((item, index) => {
                        const noValueCount = item.stateKey === 'affiliateInformation.varSelection' &&
                            item.options.filter(option => !option.value).length;

                        return (
                            <Fragment key={item.stateKey}>
                                {player?.affiliateInformation && (
                                    <Col key={index}>
                                        {item.stateKey === 'affiliateInformation.varSelection' ? (
                                                <MuiSelect
                                                    displayOnly
                                                    onChange={() => {}}
                                                    id={'affiliateVars'}
                                                    value={findValidOption(item.options)}
                                                    label={'Affiliate vars'}
                                                    options={item.options}
                                                    disabled={noValueCount === item.options.length}
                                                />
                                        ) : (
                                            <ACommonDataField
                                                component={item.component}
                                                label={t(`crm.${item.stateKey}`)}
                                                value={safeGet(player, item.stateKey.split('.'))}
                                                handleChange={onChange(item.stateKey)}
                                                disabled={!item.canEdit}
                                            />
                                        )}
                                    </Col>
                                )}
                            </Fragment>
                        );
                    })
                }
            </Row>
        </div>
    );
}

AffiliateSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default React.memo(AffiliateSubBlock);
