import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
    resetPromotionAlertsErrors,
    closePlayerSportPromotion,
    getPlayerSportPromotionsList,
} from '../../../store/actions';
import Loader from '../../../common/components/Loader';
import useAlertService from '../../../hooks/useAlertService';
import useWindowSize from '../../../hooks/useWindowSize';
import Formatter from '../../../utils/Formatter';
import { SCREEN_SIZES } from '../../../utils/consts';
import NewCustomTable from '../../../components/NewCustomTable';
import { Link } from "react-router-dom";
import { getGuidShortFormat, preparePlayerPromoAmount } from "../../../common/utils/common";
import ClipboardButton from "../../../common/cellData/CopyButton";

import wave from '../../../assets/images/common/wave.svg';
import basket from '../../../assets/images/common/basket.svg';
import promoPlus from '../../../assets/images/common/promoPlus.svg';
import NewSportBonusModal from "./modal/NewSportBonusModal";
import CustomModal from "../../../common/components/CustomModal";
import { PROMOTION_STATUS, SPORT_PROMOTION_TYPE } from "../../Promotions/common/promotionConstants";
import { useTranslation } from "react-i18next";
import TextareaInput from "../../../common/inputs/TextareaInput";
import {useTimeContext} from "../../../context/TimeContext";
import clsx from "clsx";
import { SITE_PERMISSION } from "../../../common/constants/common";
import useUserConfig from "../../../hooks/useUserConfig";
import SportPromotionsTabTable from "./SportPromotionsTabTable";
import settings from "../../../assets/images/layout/settings.svg";


function SportPromotionsTab(props) {
    const { playerId, query, playerGeneral } = props;
    const { i18n } = useTranslation();
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const [showNewBonusModal, setShowNewBonusModal] = useState(false);
    const [showCancelBonusModal, setShowCancelBonusModal] = useState(false);
    const [showBonusDisabledModal, setBonusDisabledModal] = useState(false);
    const [currentPlayerPromoId, setCurrentPlayerPromoId] = useState('');
    const [closureReason, setClosureReason] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const isPromotionsAllowed = playerGeneral?.allowPromotions;
    const { adjustAmountWithUserCurrency } = useUserConfig();
    const [isShowTableSettings, setIsShowTableSettings] = useState(false);

    const toggleTableSettings = () => setIsShowTableSettings(prev => !prev);

    const {
        isLoading,
        error,
        sportTemplatesList,
        activeSportPromotionsList,
        isApplying,
        isClosing,
    } = useSelector(state => state.PlayerPromotions);

    const { currency: generalCurrency } = useSelector(state => state.PlayerGeneral.playerGeneral);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const canAddPromoByPermission = agentPermissions.includes(SITE_PERMISSION.Player__Apply_Bonus);

    const getPlayerPromotions = useCallback(() => {
        dispatch(
            getPlayerSportPromotionsList({
                id: playerId,
                query,
            })
        );
    }, [playerId, query])

    const [prevIsClosing, setPrevIsClosing] = useState(false);
    const [prevIsApplying, setPrevIsApplying] = useState(false);

    useEffect(() => {
        if (!isApplying && prevIsApplying) {
            if (!error) {
                getPlayerPromotions();
                alertService.showSuccess(i18n.t('crm.alerts.playersPromotionAdded'));
            }
        }
        setPrevIsApplying(isApplying);
    }, [isApplying]);

    useEffect(() => {
        if (!isClosing && prevIsClosing && !error) {
            getPlayerPromotions();
            alertService.showSuccess(i18n.t('crm.alerts.promotionDisabled'));
        }
        setPrevIsClosing(isClosing);
    }, [isClosing]);

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetPromotionAlertsErrors());
        }
    }, [error]);

    const handleAddActivePromo = () => {
        setShowWarningModal(false);
        setShowNewBonusModal(true);
    };

    const handleHideAddActivePromo = () => {
        setShowNewBonusModal(false);
    };

    const handleShowWarningModal = () => {
        setShowWarningModal(true);
    }

    const handleCloseWarningModal = () => {
        setShowWarningModal(false);
    }

    const handleOpenCancelBonusModal = (playerPromotionId) => {
        const currPromo = activeSportPromotionsList.find(promo => promo?.id === playerPromotionId);
        if (currPromo) {
            if (currPromo.status === PROMOTION_STATUS.RUNNING ||
                currPromo.status === PROMOTION_STATUS.PENDING_FOR_RELEASE
            ) {
                setCurrentPlayerPromoId(playerPromotionId);
                setShowCancelBonusModal(true);
            }
            else {
                setBonusDisabledModal(true);
            }
        }
    };

    const handleCancelBonus = () => {
        setCurrentPlayerPromoId('');
        setShowCancelBonusModal(false);

        dispatch(closePlayerSportPromotion({
            id: playerId,
            playerPromotionId: currentPlayerPromoId,
        }));
    };

    // TODO send reasonText to closePlayerSportPromotion
    const onChangeTextArea = (value) => {
        setClosureReason(value)
    }


    return (
        <>
            <div className="detailed-card__content audit">
                <div className={clsx("add-active-promo", {
                        "cannot-add-promo": !canAddPromoByPermission,
                    })}
                >
                    <div className='settings-bonuses'>
                        <button
                            className="btn settings-button"
                            onClick={toggleTableSettings}
                        >
                            <img src={settings} alt="Table's Settings"/>
                        </button>
                    </div>
                    <img
                        src={promoPlus}
                        alt="Activate promotion"
                        onClick={() => {
                            isPromotionsAllowed
                                ? handleAddActivePromo()
                                : handleShowWarningModal()
                        }}
                    />
                </div>

                {activeSportPromotionsList && !isLoading ? (
                    <>
                        <SportPromotionsTabTable
                            list={activeSportPromotionsList}
                            onCancelBonus={handleOpenCancelBonusModal}
                            generalCurrency={generalCurrency}
                            isShowTableSettings={isShowTableSettings}
                            onCloseTableSettings={toggleTableSettings}
                        />
                    </>
                ) : (
                    <Loader size={'lg'}/>
                )}
            </div>
            {showWarningModal && !isPromotionsAllowed && (
                <CustomModal
                    titleText={i18n.t('common.attention')}
                    isOpen={showWarningModal}
                    onToggle={() => handleCloseWarningModal()}
                    onClick={handleAddActivePromo}
                    onClickCancelButton={() => handleCloseWarningModal()}
                    btnText={'confirm'}
                    cancelBtnText={'cancel'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {i18n.t('promotions.sureCreditBonus')}
                            </div>
                        </>
                    )}
                />
            )}
            {showNewBonusModal && (
                <NewSportBonusModal
                    playerId={playerId}
                    isPromotionsAllowed={isPromotionsAllowed}
                    onHide={handleHideAddActivePromo}
                    fieldErrors={{}}
                    isLoading={false}
                    promotions={sportTemplatesList || []}
                />
            )}
            {showCancelBonusModal &&
            <CustomModal
                titleText={i18n.t('common.attention')}
                isOpen={showCancelBonusModal}
                onToggle={() => setShowCancelBonusModal(!showCancelBonusModal)}
                onClick={handleCancelBonus}
                onClickCancelButton={() => setShowCancelBonusModal(false)}
                btnText={'yes'}
                cancelBtnText={'no'}
                withCancelButton={true}
                bodyRender={() => (
                    <>
                        <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            {i18n.t('promotions.sureWantClosePromo')}
                        </div>
                        <TextareaInput
                            className="form-control form-control-gray mt-3"
                            rows="5"
                            value={closureReason}
                            onChange={onChangeTextArea}
                            placeholder={i18n.t('crm.closurePromoReason')}
                            disabled={false}
                        />
                    </>
                )}
            />
            }
            {showBonusDisabledModal &&
            <CustomModal
                titleText={i18n.t('common.attention')}
                isOpen={showBonusDisabledModal}
                onToggle={() => setBonusDisabledModal(!showBonusDisabledModal)}
                onClick={() => setBonusDisabledModal(false)}
                btnText={'close'}
                bodyRender={() => (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {i18n.t('promotions.promoAlreadyDisabled')}
                    </div>
                )}
            />
            }
        </>
    );
}

SportPromotionsTab.propTypes = {
    playerId: PropTypes.string.isRequired,
    query: PropTypes.object
};

export default React.memo(SportPromotionsTab);
