import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import close from "../../../assets/images/common/close 1.svg";
import braille from "../../../assets/images/layout/braille-16.svg";
import arrowLeft from "../../../assets/images/layout/arrow-left.svg";
import arrowRight from "../../../assets/images/layout/arrow-right.svg";
import arrowTop from "../../../assets/images/layout/arrow-top.svg";
import uncheckedCheckbox from "../../../assets/images/layout/unchecked-checkbox-dark.svg";
import checkedCheckbox from "../../../assets/images/layout/checked.svg";
import clsx from "clsx";
import FormSearch from "../../../UI/formSearch/FormSearch";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const SIDES = {
    USED: 'used',
    NOT_USED: 'notUsed',
};

const MIN_SIDE_HEIGHT = 100;

const calculateSideHeight = (arr1, arr2) => {
    const maxCount = arr1.length > arr2.length ? arr1.length : arr2.length;
    const sideHeight = maxCount * 24 + 20;
    return sideHeight;
};

const getFilteredColumns = (columns, searchValue, getTranslate) =>
    columns.filter((column) => getTranslate(column).toLowerCase().includes(searchValue.toLowerCase()));

const getIsAllChecked = (columns, checked, searchValue, getTranslate) => {
    if (columns.length === 0) {
        return false;
    }

    if (searchValue === '') {
        for (const column of columns) {
            if (!checked[column]) {
                return false;
            }
        }

        return true;
    }

    const filteredColumns = getFilteredColumns(columns, searchValue, getTranslate);

    if (filteredColumns.length === 0) {
        return false;
    }

    for (const column of filteredColumns) {
        if (!checked[column]) {
            return false;
        }
    }

    return true;
}

const getCheckedAllItems = (keys, checkedObj) => keys.reduce((acc, column) => acc && !!checkedObj[column], true);

function TableSettingsModal({
    onHide,
    onConfirm,
    categoryMode,
    activeColumns,
    defaultColumns,
    allColumns,
    popupTitle,
    translatesObj,
    titlesObj,
}) {
    const [usedColumns, setUsedColumns] = useState([]);
    const [possibleColumns, setPossibleColumns] = useState([]);
    const [checkedUsed, setCheckedUsed] = useState({});
    const [checkedPossible, setCheckedPossible] = useState({});

    const [draggableSide, setDraggableSide] = useState('');
    const [draggableElement, setDraggableElement] = useState('');
    const [dragSide, setDragSide] = useState('');
    const [dragElement, setDragElement] = useState('');

    const [sideHeight, setSideHeight] = useState(MIN_SIDE_HEIGHT);
    const [searchValue, setSearchValue] = useState('');
    const [isEdit, setEdit] = useState(false);
    const [isPosFixed, setPosFixed] = useState(0);

    const columnsRef = useRef([]);
    const backMovedRef = useRef(false);

    const getTranslate = (key) => titlesObj
        ? titlesObj[key]?.title || key
        : translatesObj[key] ? i18n.t(translatesObj[key]) : key;

    const showedUsedColumns = useMemo(
        () => searchValue === ''
            ? usedColumns
            : getFilteredColumns(usedColumns, searchValue, getTranslate),
        [usedColumns, searchValue],
    );

    const showedPossibleColumns = useMemo(
        () => searchValue === ''
            ? possibleColumns
            : getFilteredColumns(possibleColumns, searchValue, getTranslate),
        [possibleColumns, searchValue],
    );

    useEffect(() => {
        if (backMovedRef.current) {
            backMovedRef.current = false;
        }
        else if (usedColumns.length > 0 || possibleColumns.length > 0) {
            const historyLength = columnsRef.current.length;
            let shouldAdd = historyLength === 0;

            if (!shouldAdd) {
                const { used, possible, checkedUsed: checkedUsed_, checkedPossible: checkedPossible_ } = columnsRef.current[historyLength - 1];
                shouldAdd = JSON.stringify(usedColumns) !== JSON.stringify(used)
                    || JSON.stringify(possibleColumns) !== JSON.stringify(possible)
                    || JSON.stringify(checkedUsed) !== JSON.stringify(checkedUsed_)
                    || JSON.stringify(checkedPossible) !== JSON.stringify(checkedPossible_);
            }

            if (shouldAdd) {
                columnsRef.current.push({
                    used: [...usedColumns],
                    possible: [...possibleColumns],
                    checkedUsed: {...checkedUsed},
                    checkedPossible: {...checkedPossible},
                });
            }
        }
    }, [usedColumns, possibleColumns, checkedUsed, checkedPossible]);

    useEffect(() => {
        let sideHeight = calculateSideHeight(showedUsedColumns, showedPossibleColumns);
        if (sideHeight < MIN_SIDE_HEIGHT) {
            sideHeight = MIN_SIDE_HEIGHT;
        }
        setSideHeight(sideHeight);
    }, [showedUsedColumns, showedPossibleColumns])

    const initColumns = useCallback(() => {
        const usedColumns = [...activeColumns];
        const possibleColumns = allColumns.filter((column) => !activeColumns.includes(column));

        setUsedColumns(usedColumns);
        setPossibleColumns(possibleColumns);

        setCheckedUsed({});
        setCheckedPossible({});
    }, [activeColumns, allColumns]);

    useEffect(() => {
        initColumns();
    }, [initColumns]);

    useEffect(() => {
        let wasEdited = false;
        const usedColumnsCount = usedColumns.length;
        if (usedColumnsCount !== activeColumns.length) {
            setEdit(true);
        }
        else {
            for (let i = 0; i < usedColumnsCount; i++) {
                if (usedColumns[i] !== activeColumns[i]) {
                    wasEdited = true;
                    break;
                }
            }
            setEdit(wasEdited);
        }
    }, [usedColumns, activeColumns]);

    useEffect(() => {
        setPosFixed(sideHeight + 230 > window.innerHeight)
    }, [sideHeight, window.innerHeight]);

    const checkAll = (columns, prevCheckedColumns, searchValue) => {
        const filteredColumns = columns.filter((column) => searchValue === '' || getTranslate(column).toLowerCase()
            .includes(searchValue.toLowerCase()));

        let checkedColumns = {}
        if (columns.length === filteredColumns.length) {
            const isAllChecked = getCheckedAllItems(columns, prevCheckedColumns);
            if (!isAllChecked) {
                columns.forEach((column) => checkedColumns[column] = true);
            }
        }
        else {
            checkedColumns = {...prevCheckedColumns};
            const isAllFilteredChecked = getCheckedAllItems(filteredColumns, checkedColumns);
            filteredColumns.forEach((column) => checkedColumns[column] = !isAllFilteredChecked);
        }

        return checkedColumns;
    }

    const handleSave = useCallback(() => {
        onConfirm(usedColumns);
    }, [usedColumns]);

    const handleCheckedUsed = useCallback(() => {
        if (checkedUsed[draggableElement]) {
            let newCheckedUsed = { ...checkedUsed };
            delete newCheckedUsed[draggableElement];
            let newCheckedPossible = {
                ...checkedPossible,
                [draggableElement]: true,
            };

            setCheckedUsed(newCheckedUsed);
            setCheckedPossible(newCheckedPossible);
        }
    }, [draggableElement, checkedUsed, checkedPossible]);

    const handleCheckedPossible = useCallback(() => {
        if (checkedPossible[draggableElement]) {
            const newCheckedPossible = { ...checkedPossible };
            delete newCheckedPossible[draggableElement];

            const newCheckedUsed = {
                ...checkedUsed,
                [draggableElement]: true,
            };

            setCheckedUsed(newCheckedUsed);
            setCheckedPossible(newCheckedPossible);
        }
    }, [draggableElement, checkedUsed, checkedPossible]);

    const handleDragStart = (side, column) => {
        setDraggableSide(side);
        setDraggableElement(column);
    };

    const handleDragEnter = (side, column) => {
        setDragSide(side);
        setDragElement(column);

        if (side === draggableSide) {
            if (side === SIDES.USED) {
                const dragInd = usedColumns.findIndex((element) => element === draggableElement);
                const currInd = usedColumns.findIndex((element) => element === column);

                [usedColumns[dragInd], usedColumns[currInd]] = [usedColumns[currInd], usedColumns[dragInd]];
                setUsedColumns([...usedColumns]);
            }
            else {
                const dragInd = possibleColumns.findIndex((element) => element === draggableElement);
                const currInd = possibleColumns.findIndex((element) => element === column);

                [possibleColumns[dragInd], possibleColumns[currInd]] = [possibleColumns[currInd], possibleColumns[dragInd]];
                setPossibleColumns([...possibleColumns]);
            }
        }
        else {
            let newUsedColumns = [...usedColumns];
            let newPossibleColumns = [...possibleColumns];

            if (side === SIDES.USED) {
                const currInd = newUsedColumns.findIndex((element) => element === column);
                newUsedColumns.splice(currInd, 0, draggableElement);
                newPossibleColumns = newPossibleColumns.filter((column) => column !== draggableElement);

                handleCheckedPossible();
            }
            else {
                const currInd = newPossibleColumns.findIndex((element) => element === column);
                newPossibleColumns.splice(currInd, 0, draggableElement);
                newUsedColumns = newUsedColumns.filter((column) => column !== draggableElement);

                handleCheckedUsed();
            }

            setUsedColumns(newUsedColumns);
            setPossibleColumns(newPossibleColumns);
            setDraggableSide(side);
        }
    };

    const handleDragOver = (event) => event.preventDefault();

    const clearDragData = () => {
        setDragSide('');
        setDragElement('');
        setDraggableSide('');
        setDraggableElement('');
    };

    const handleDrop = useCallback((event, side) => {
        if (side !== draggableSide) {
            let newUsedColumns;
            let newPossibleColumns;
            if (draggableSide === SIDES.USED) {
                newUsedColumns = usedColumns.filter((column) => column !== draggableElement);
                newPossibleColumns = [...possibleColumns, draggableElement];

                handleCheckedUsed();
            }
            else {
                newPossibleColumns = possibleColumns.filter((column) => column !== draggableElement);
                newUsedColumns = [...usedColumns, draggableElement];

                handleCheckedPossible()
            }

            setPossibleColumns(newPossibleColumns);
            setUsedColumns(newUsedColumns);
        }
        event.stopPropagation();
        clearDragData();
    }, [draggableSide, draggableElement, usedColumns, possibleColumns, handleCheckedUsed, handleCheckedPossible]);

    const handleSetDefault = useCallback(() => {
        setUsedColumns([...defaultColumns]);
        const possibleColumns = allColumns.filter((column) => !defaultColumns.includes(column));
        setPossibleColumns(possibleColumns);
        setCheckedUsed({});
        setCheckedPossible({});
    }, [allColumns, defaultColumns]);

    const handleRevert = () => {
        const historyLength = columnsRef.current.length;
        if (historyLength > 1) {
            backMovedRef.current = true;
            columnsRef.current.pop();
            const { used, possible, checkedUsed, checkedPossible } = columnsRef.current[historyLength - 2];
            setUsedColumns([...used]);
            setPossibleColumns([...possible]);
            setCheckedUsed({...checkedUsed});
            setCheckedPossible({...checkedPossible});
        }
    };

    const moveCheckedToTop = useCallback(() => {
        const checkedUsedColumns = Object.keys(checkedUsed)
            .filter((column) => !!checkedUsed[column]);

        if (checkedUsedColumns.length > 0) {
            const newUsedColumns = [
                ...checkedUsedColumns,
                ...usedColumns.filter((column) => !checkedUsedColumns.includes(column)),
            ];
            setUsedColumns(newUsedColumns);
        }

        const checkedPossibleColumns = Object.keys(checkedPossible)
            .filter((column) => !!checkedPossible[column]);

        if (checkedPossibleColumns.length > 0) {
            const newPossibleColumns = [
                ...checkedPossibleColumns,
                ...possibleColumns.filter((column) => !checkedPossibleColumns.includes(column)),
            ];
            setPossibleColumns(newPossibleColumns);
        }
    }, [usedColumns, possibleColumns, checkedUsed, checkedPossible]);

    const moveCheckedToNotUsed = useCallback(() => {
        const processedColumns = searchValue === ''
            ? usedColumns
            : getFilteredColumns(usedColumns, searchValue, getTranslate);

        const checkedColumns = processedColumns.filter((column) => !!checkedUsed[column]);
        const newUsedColumns = usedColumns.filter((column) => !checkedColumns.includes(column));
        const newNotUsedColumns = [...possibleColumns, ...checkedColumns];
        checkedColumns.forEach((column) => {
            checkedPossible[column] = true;
        });

        setUsedColumns(newUsedColumns);
        setPossibleColumns(newNotUsedColumns);
        setCheckedUsed({});
        setCheckedPossible({ ...checkedPossible });
    }, [usedColumns, possibleColumns, checkedUsed, checkedPossible, searchValue]);

    const moveCheckedToUsed = useCallback(() => {
        const processedColumns = searchValue === ''
            ? possibleColumns
            : getFilteredColumns(possibleColumns, searchValue, getTranslate);

        const checkedColumns = processedColumns.filter((column) => !!checkedPossible[column]);
        const newPossibleColumns = possibleColumns.filter((column) => !checkedColumns.includes(column));
        const newUsedColumns = [...usedColumns, ...checkedColumns];
        checkedColumns.forEach((column) => {
            checkedUsed[column] = true;
        });

        setUsedColumns(newUsedColumns);
        setPossibleColumns(newPossibleColumns);
        setCheckedPossible({});
        setCheckedUsed({ ...checkedUsed });
    }, [usedColumns, possibleColumns, checkedUsed, checkedPossible, searchValue]);

    const isAllCheckedUsed = useMemo(
        () => getIsAllChecked(usedColumns, checkedUsed, searchValue, getTranslate),
        [usedColumns, checkedUsed, searchValue],
    );

    const isAllCheckedPossible = useMemo(
        () => getIsAllChecked(possibleColumns, checkedPossible, searchValue, getTranslate),
        [possibleColumns, checkedPossible, searchValue],
    );

    const handleChangeCheck = useCallback((side, column) => {
        if (side === SIDES.USED) {
            setCheckedUsed({
                ...checkedUsed,
                [column]: !checkedUsed[column],
            });
        }
        else {
            setCheckedPossible({
                ...checkedPossible,
                [column]: !checkedPossible[column],
            });
        }
    }, [checkedUsed, checkedPossible]);

    const handleCheckAllUsed = useCallback(() => {
        const checkedColumns = checkAll(usedColumns, checkedUsed, searchValue);
        setCheckedUsed(checkedColumns);
    }, [checkedUsed, usedColumns, searchValue]);

    const handleCheckAllPossible = useCallback(() => {
        const checkedColumns = checkAll(possibleColumns, checkedPossible, searchValue);
        setCheckedPossible(checkedColumns);
    }, [checkedPossible, possibleColumns, searchValue]);

    const handleChangeSearchValue = (event, side) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    return (
        <div>
            <Modal
                isOpen={true}
                role="dialog"
                autoFocus={true}
                data-toggle="modal"
                className="table-settings-modal exchange-popup"
                centered
                onOpened={setUnScrollBody}
                onExit={removeUnScrollBody}
            >
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {popupTitle || i18n.t('crm.tableSettings')}
                    </h5>
                    <button className="close" onClick={onHide} />
                </div>

                <div className="top-refresh-buttons__wrapper">
                    <div
                        className="top-refresh-buttons"
                    >
                        <button
                            className="btn btn-light btn-rounded"
                            onClick={handleRevert}
                            style={{
                                width: 'auto',
                            }}
                            disabled={columnsRef.current.length < 2}
                        >
                            {i18n.t('crm.revert')}
                        </button>

                        <button
                            className="btn btn-light btn-rounded"
                            onClick={handleSetDefault}
                            style={{
                                width: 'auto',
                                marginLeft: 10,
                            }}
                        >
                            {i18n.t('crm.set.default.values')}
                        </button>
                    </div>

                    <FormSearch
                        onChangeSearchValue={(event) => handleChangeSearchValue(event)}
                        showIconSearch={true}
                    />
                </div>

                <ModalBody>
                    <table className="exchange-table">
                        <tbody>
                            <tr>
                                <td
                                    className="column-title"
                                >
                                    {i18n.t('crm.unselected.columns')}&nbsp;
                                    {`(${possibleColumns.length}/${allColumns.length})`}
                                </td>
                                <td> </td>
                                <td
                                    className="column-title"
                                >
                                    {i18n.t('crm.selected.columns')}&nbsp;
                                    {`(${usedColumns.length}/${allColumns.length})`}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className="select-all-td"
                                >
                                    {showedPossibleColumns.length > 0 && <span
                                        onClick={handleCheckAllPossible}
                                    >
                                        <img
                                            style={{
                                                verticalAlign: -5,
                                                marginRight: 10,
                                                width: 20,
                                                height: 20,
                                            }}
                                            src={isAllCheckedPossible ? checkedCheckbox : uncheckedCheckbox}
                                            alt="All checked"
                                        />
                                        {i18n.t('crm.select.all')}
                                    </span>}
                                </td>
                                <td> </td>
                                <td
                                    className="select-all-td"
                                >
                                    {showedUsedColumns.length > 0 && <span
                                        onClick={handleCheckAllUsed}
                                    >
                                        <img
                                            style={{
                                                verticalAlign: -5,
                                                marginRight: 10,
                                                width: 20,
                                                height: 20,
                                            }}
                                            src={isAllCheckedUsed ? checkedCheckbox : uncheckedCheckbox}
                                            alt="All checked"
                                        />
                                        {i18n.t('crm.select.all')}
                                    </span>}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    key="not_used_columns"
                                    className={clsx("exchange-column", {
                                        withHover: draggableElement === '',
                                    })}
                                    style={{
                                        height: sideHeight,
                                    }}
                                    onDragEnter={(event) => event.stopPropagation()}
                                    onDragOver={(event) => handleDragOver(event)}
                                    onDrop={(event) => handleDrop(event, SIDES.NOT_USED)}
                                >
                                    {showedPossibleColumns.length === 0
                                        ? possibleColumns.length === 0
                                            ? <div className="empty-side">
                                                {i18n.t('crm.empty.columns.list')}
                                            </div>
                                            : <div className="empty-side">
                                                {i18n.t('crm.no.results.found')}
                                            </div>

                                        : showedPossibleColumns
                                            .map((column, ind) => <>
                                                <div
                                                    key={column}
                                                    className={clsx("exchange-item", {
                                                        dragEntered: dragSide === SIDES.NOT_USED && dragElement === column
                                                    })}
                                                    draggable={true}
                                                    onDragStart={() => handleDragStart(SIDES.NOT_USED, column)}
                                                    onDragEnter={() => handleDragEnter(SIDES.NOT_USED, column)}
                                                    onDragOver={(event) => handleDragOver(event)}
                                                >
                                                    <input
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                        type="checkbox"
                                                        checked={!!checkedPossible[column]}
                                                        onChange={() => handleChangeCheck(SIDES.NOT_USED, column)}
                                                    />
                                                    <span
                                                        onClick={() => handleChangeCheck(SIDES.NOT_USED, column)}
                                                    >
                                                        {getTranslate(column)}
                                                    </span>
                                                    <img
                                                        className="column-move"
                                                        src={braille}
                                                        alt="Move"
                                                    />
                                                </div>
                                        </>)
                                    }
                                </td>

                                <td
                                    style={{
                                        width: 50,
                                        textAlighn: 'center',
                                    }}
                                    onDragEnter={(event) => event.stopPropagation()}
                                    onDragLeave={(event) => event.stopPropagation()}
                                >
                                    <div
                                        className="move-button top-button"
                                        onClick={moveCheckedToTop}
                                    >
                                        <img
                                            className="column-move"
                                            src={arrowTop}
                                            alt="Move to top"
                                        />
                                    </div>
                                    <div
                                        className="move-button right-button"
                                        onClick={moveCheckedToUsed}
                                    >
                                        <img
                                            className="column-move"
                                            src={arrowRight}
                                            alt="Move to used side"
                                        />
                                    </div>
                                    <div
                                        className="move-button left-button"
                                        onClick={moveCheckedToNotUsed}
                                    >
                                        <img
                                            className="column-move"
                                            src={arrowLeft}
                                            alt="Move to not used side"
                                        />
                                    </div>
                                </td>

                                <td
                                    key="used_columns"
                                    className={clsx("exchange-column", {
                                        withHover: draggableElement === '',
                                    })}
                                    style={{
                                        height: sideHeight,
                                    }}
                                    onDragEnter={(event) => event.stopPropagation()}
                                    onDragOver={(event) => handleDragOver(event)}
                                    onDrop={(event) => handleDrop(event, SIDES.USED)}
                                >
                                    {showedUsedColumns.length === 0
                                        ? usedColumns.length === 0
                                            ? <div className="empty-side">
                                                {i18n.t('crm.empty.columns.list')}
                                            </div>
                                            : <div className="empty-side">
                                                {i18n.t('crm.no.results.found')}
                                            </div>

                                        : showedUsedColumns.map((column, ind) => <div
                                                key={column}
                                                className={clsx("exchange-item", {
                                                    dragEntered: dragSide === SIDES.USED && dragElement === column,
                                                    draggabled: dragSide === SIDES.USED && draggableElement === column,
                                                })}
                                                draggable={true}
                                                onDragStart={() => handleDragStart(SIDES.USED, column)}
                                                onDragEnter={() => handleDragEnter(SIDES.USED, column)}
                                                onDragOver={(event) => handleDragOver(event)}
                                            >
                                                <input
                                                    style={{
                                                        marginRight: 10,
                                                    }}
                                                    type="checkbox"
                                                    checked={!!checkedUsed[column]}
                                                    onChange={() => handleChangeCheck(SIDES.USED, column)}
                                                />
                                                <span
                                                    onClick={() => handleChangeCheck(SIDES.USED, column)}
                                                >
                                                    {getTranslate(column)}
                                                </span>
                                                <img
                                                    className="column-move"
                                                    src={braille}
                                                    alt="Move"
                                                />
                                            </div>
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter
                    className={clsx({
                        'is-edit': isEdit,
                    })}
                >
                    {isEdit && <div
                        className={clsx("bottom-btns", {
                            'pos-fixed': isPosFixed,
                        })}
                    >
                        <button
                            className="btn btn-light btn-rounded"
                            onClick={onHide}
                            style={{
                                width: '30%',
                            }}
                        >
                            <img src={close} alt="" />
                            {i18n.t('crm.cancel')}
                        </button>
                        <button
                            className="btn btn-primary btn-rounded"
                            onClick={handleSave}
                            disabled={usedColumns.length < 2}
                            style={{
                                width: '30%',
                                marginLeft: 20,
                            }}
                        >
                            <img src={i_51} alt="" />
                            {i18n.t('crm.save')}
                        </button>
                    </div>}
                </ModalFooter>
            </Modal>
        </div>
    );
}

TableSettingsModal.propTypes = {
    activeColumns: PropTypes.array,
    defaultColumns: PropTypes.array,
    allColumns: PropTypes.array,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    categoryMode: PropTypes.bool,
    popupTitle: PropTypes.string,
    translatesObj: PropTypes.object,
    titlesObj: PropTypes.object,
};

TableSettingsModal.defaultProps = {
    categoryMode: false,
    popupTitle: '',
    translatesObj: {},
    titlesObj: null,
}

export default React.memo(TableSettingsModal);
