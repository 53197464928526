import {
    SET_EDIT,
    GET_AFFILIATE,
    CANCEL_ERRORS,
    CHANGE_STATUS,
    AFFILIATE_ERROR,
    REMOVE_AFFILIATE,
    CHANGE_AFFILIATE,
    GET_AFFILIATE_LIST,
    ADD_AFFILIATE_AT_LIST,
    CHANGE_STATUS_SUCCESS,
    GET_AFFILIATE_SUCCESS,
    CHANGE_AFFILIATE_SUCCESS,
    REMOVE_AFFILIATE_SUCCESS,
    GET_AFFILIATE_LIST_SUCCESS,
    ADD_AFFILIATE_AT_LIST_SUCCESS,
    ADD_AFFILIATE_AT_LIST_ERROR,
    GET_AFFILIATE_LIST_FAIL,
    DOWNLOAD_AFFILIATE_LIST,
    DOWNLOAD_AFFILIATE_LIST_SUCCESS,
    DOWNLOAD_AFFILIATE_LIST_FAIL,
} from './actionTypes';


export const getAffiliatesList = payload => ({
    type: GET_AFFILIATE_LIST,
    payload
});

export const getAffiliatesListSuccess = response => ({
    type: GET_AFFILIATE_LIST_SUCCESS,
    payload: response
});

export const getAffiliatesListFail = error => ({
    type: GET_AFFILIATE_LIST_FAIL,
    payload: error
});

export const addAffiliateAtList = body => ({
    type: ADD_AFFILIATE_AT_LIST,
    payload: { body }
});

export const addAffiliateAtListSuccess = response => ({
    type: ADD_AFFILIATE_AT_LIST_SUCCESS,
    payload: response
});

export const addAffiliateAtListError = response => ({
    type: ADD_AFFILIATE_AT_LIST_ERROR,
    payload: response
})

// AFFILIATE
export const getAffiliate = id => ({
    type: GET_AFFILIATE,
    payload: { id }
});

export const getAffiliateSuccess = response => ({
    type: GET_AFFILIATE_SUCCESS,
    payload: response
});

export const changeAffiliate = body => ({
    type: CHANGE_AFFILIATE,
    payload: body
});

export const changeAffiliateSuccess = response => ({
    type: CHANGE_AFFILIATE_SUCCESS,
    payload: response
});

export const removeAffiliate = id => ({
    type: REMOVE_AFFILIATE,
    payload: { id }
});

export const removeAffiliateSuccess = response => ({
    type: REMOVE_AFFILIATE_SUCCESS,
    payload: response
});

export const affiliateError = error => ({
    type: AFFILIATE_ERROR,
    payload: error
});

export const setEdit = value => ({
    type: SET_EDIT,
    payload: value
});

export const cancelErrors = () => ({
    type: CANCEL_ERRORS
});

export const changeAffiliateStatus = body => ({
    type: CHANGE_STATUS,
    payload: body
});

export const changeAffiliateStatusSuccess = payload => ({
    type: CHANGE_STATUS_SUCCESS,
    payload
});

export const downloadAffiliateList = payload => ({
    type: DOWNLOAD_AFFILIATE_LIST,
    payload
});

export const downloadAffiliateListSuccess = payload => ({
    type: DOWNLOAD_AFFILIATE_LIST_SUCCESS,
    payload
});

export const downloadAffiliateListFail = payload => ({
    type: DOWNLOAD_AFFILIATE_LIST_FAIL,
    payload
});
