export const STATUS = {
    ACTIVE: 'Active',
    FROZEN: 'Frozen',
    CLOSED: 'Closed',
    SELF_CLOSED: 'SelfClosed',
    LEAD: 'Lead',
    BLOCKED: 'Blocked',
    COMPLETE_PROFILE: 'CompleteProfile',
    SOFT_BLOCK: 'SoftBlock',
};

export const PAYMENT_STATUS = {
    ERROR: 'ERROR',
    DECLINED: 'DECLINED',
    CANCELED: 'CANCELED',
    REFUNDED: 'REFUNDED',
    REFUNDED_IN_PROGRESS: 'REFUNDED_IN_PROGRESS',
    PARTIAL_REFUNDED: 'PARTIAL_REFUNDED',
    PENDING_FOR_PLAYER: 'PENDING_FOR_PLAYER',
    COMPLETED: 'COMPLETED',
    INITIALIZED: 'INITIALIZED',
    PENDING_FOR_APPROVAL: 'PENDING_FOR_APPROVAL',
    MANUAL_ACTION_REQUIRED: 'MANUAL_ACTION_REQUIRED',
    PENDING_FOR_WIRE_TRANSFER: 'PENDING_FOR_WIRE_TRANSFER',
    PROCESSING: 'PROCESSING',
    CHARGEBACK: 'CHARGEBACK',
};

export const ACCOUNT_STATUS = {
    Active: 'Active',
    Frozen: 'Frozen',
    Lead: 'Lead',
    Closed: 'Closed',
    SelfClosed: 'SelfClosed',
    CompleteProfile: 'CompleteProfile',
    Blocked: 'Blocked',
    SoftBlock: 'SoftBlock',
};

export const AGENT_ACTIVITY_ACCOUNT_STATUS = {
    Active: 'Active',
    OnCall: 'OnCall',
    Lunch: 'Lunch',
    HourlyBreak: 'HourlyBreak'
};

export const AGENT_ACCOUNT_STATUS = {
    Active: 'Active',
    Blocked: 'Blocked',
    Inactive: 'Inactive'
};

export const AMLCompliantStatus = {
    Declined: 'Declined',
    Approved: 'Approved',
    Pending: 'Pending',
    New: 'New',
    Review: 'Review',
    Expired: 'Expired',
};

export const DEPOSITOR_STATUS = {
    NonDepositor: 'NonDepositor',
    OneTimeDepositor: 'OneTimeDepositor',
    MultiDepositor: 'MultiDepositor',
    Test: 'Test',
};