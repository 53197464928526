import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import removeSvg from '../../assets/images/layout/remove.svg';
import updateSvg from '../../assets/images/layout/thin-edit-dark.svg';
import addSvg from '../../assets/images/layout/small-add-item.svg';

const UpdateIcon = () => {
    return (
        <>
            <img className="img-btn" src={updateSvg} alt="" width="24" />
        </>
    );
};

const AddIcon = () => {
    const [isHover, setIsHover] = useState(false);

    const color = useMemo(() => {
        if (isHover) {
            return '#454548';
        }
        return '#A4A4B8';
    }, [isHover]);

    return (
        <>
            <img className="img-btn" src={addSvg} alt="" width="24" />
        </>
    );
};

const RemoveIcon = () => {
    const [isHover, setIsHover] = useState(false);

    const color = useMemo(() => {
        if (isHover) {
            return '#454548';
        }
        return '#A4A4B8';
    }, [isHover]);

    return (
        <>
            <img className="img-btn" src={removeSvg} alt="" width="24" />
        </>
    );
};

const CopyIcon = () => {
    const [isHover, setIsHover] = useState(false);

    const color = useMemo(() => {
        if (isHover) {
            return '#454548';
        }
        return '#A4A4B8';
    }, [isHover]);

    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="20px"
            height="30px"
            onMouseDown={() => {
                setIsHover(true);
            }}
            onMouseUp={() => {
                setIsHover(false);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
            onMouseOver={() => {
                setIsHover(true);
            }}
        >
            <path d="M 15 3 C 12.053086 3 9.3294211 4.0897803 7.2558594 5.8359375 A 1.0001 1.0001 0 1 0 8.5449219 7.3652344 C 10.27136 5.9113916 12.546914 5 15 5 C 20.226608 5 24.456683 8.9136179 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.441216 7.8348596 21.297943 3 15 3 z M 4.3007812 9 L 0.30078125 15 L 3 15 C 3 21.635519 8.3644809 27 15 27 C 17.946914 27 20.670579 25.91022 22.744141 24.164062 A 1.0001 1.0001 0 1 0 21.455078 22.634766 C 19.72864 24.088608 17.453086 25 15 25 C 9.4355191 25 5 20.564481 5 15 L 8.3007812 15 L 4.3007812 9 z" />
        </svg>
    );
};

const GenerateButton = props => {
    let { style, text, onClick, icon } = props;
    if (!icon) {
        icon = 'copy';
    }

    const [isShowAlert, setShowAlert] = useState(true);

    return (
        <div
            className="app-copy-icon-generate-container"
            style={{ ...style }}
            onClick={onClick}
        >
            {icon === 'copy' && <CopyIcon />}
            {icon === 'remove' && <RemoveIcon />}
            {icon === 'add' && <AddIcon />}
            {icon === 'update' && <UpdateIcon />}

            {text && (
                <span className={`copy-tooltip ${isShowAlert ? 'show' : ''}`}>
                    {i18n.t(text)}
                </span>
            )}
        </div>
    );
};

GenerateButton.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
};

GenerateButton.defaultProps = {
    text: '',
    className: ''
};

export default GenerateButton;
