export const GET_PLAYER_JOINT = 'GET_PLAYER_JOINT';
export const GET_PLAYER_JOINT_SUCCESS = 'GET_PLAYER_JOINT_SUCCESS';
export const GET_PLAYER_JOINT_FAIL = 'GET_PLAYER_JOINT_FAIL';

export const SET_EDIT_PLAYER_JOINT = 'SET_EDIT_PLAYER_JOINT';

export const UPDATE_PLAYER_JOINT = 'UPDATE_PLAYER_JOINT';
export const UPDATE_PLAYER_JOINT_SUCCESS = 'UPDATE_PLAYER_JOINT_SUCCESS';
export const UPDATE_PLAYER_JOINT_FAIL = 'UPDATE_PLAYER_JOINT_FAIL';

export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_PLAYER_STATUS_SUCCESS = 'UPDATE_PLAYER_STATUS_SUCCESS';
export const UPDATE_PLAYER_STATUS_FAIL = 'UPDATE_PLAYER_STATUS_FAIL';

export const RESET_PLAYER_ERRORS = 'RESET_PLAYER_ERRORS';
export const RESET_PLAYER_STATE = 'RESET_PLAYER_STATE';
