import {
    SET_EDIT,
    CANCEL_ERRORS,
    CHANGE_STATUS,
    GET_NOTE,
    NOTE_ERROR,
    REMOVE_NOTE,
    CHANGE_NOTE,
    GET_NOTE_LIST,
    GET_NOTE_SUCCESS,
    CHANGE_STATUS_SUCCESS,
    ADD_NOTE,
    REMOVE_NOTE_SUCCESS,
    REMOVE_NOTE_FAIL,
    CHANGE_NOTE_SUCCESS,
    GET_NOTE_LIST_SUCCESS,
    ADD_NOTE_SUCCESS,
    GET_NOTE_LIST_FAIL,
    SEARCH_AT_NOTE_LIST
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0,
    searchText: null
};

const INITIAL_STATE = {
    query: DEFAULT_QUERY,
    total: 0,
    noteList: null,
    errorList: null,

    note: {},
    isStatusLoading: false,
    isLoading: false,
    isRemoving: false,
    errors: {},
    isEdit: false,
    isCreateNewNote: false
};

const Notes = (state = INITIAL_STATE, action) => {
    let errors = {};
    switch (action.type) {
        case GET_NOTE_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                errorList: null
            };

        case GET_NOTE_LIST_SUCCESS:
            const { result, total } = action.payload;

            return { ...state, isLoading: false, noteList: result, total };

        case GET_NOTE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                noteList: [],
                isLoading: false
            };

        case ADD_NOTE:
            return { ...state, isLoading: true, isCreateNewNote: false };
        case ADD_NOTE_SUCCESS:
            return {
                ...state,
                noteList: [action.payload, ...state.noteList],
                isLoading: false,
                errors: {},
                total: state.total + 1,
                isCreateNewNote: true
            };

        case GET_NOTE:
            return { ...state, isLoading: true };
        case GET_NOTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                note: action.payload
            };

        case CHANGE_NOTE:
            return { ...state };
        case CHANGE_NOTE_SUCCESS:
            return {
                ...state,
                note: action.payload,
                errors: {},
                isEdit: false
            };

        case REMOVE_NOTE:
            return {
                ...state,
                isRemoving: true,
            };
        case REMOVE_NOTE_SUCCESS:
            return {
                ...state,
                isRemoving: false,
            };
        case REMOVE_NOTE_FAIL:
            action.payload?.data?.errors?.map(({ constraints, property }) => {
                errors[property] = Object.values(constraints)[0];
            });

            return {
                ...state,
                isRemoving: false,
                errors,
            };

        case NOTE_ERROR:
            action.payload?.data?.errors?.map(({ constraints, property }) => {
                errors[property] = Object.values(constraints)[0];
            });

            return {
                ...state,
                isLoading: false,
                isEdit: true,
                errors,
            };

        case SET_EDIT:
            return {
                ...state,
                isEdit: action.payload
            };

        case CANCEL_ERRORS:
            return {
                ...state,
                errors: {},
                isEdit: false,
                errorList: null,
            };

        case CHANGE_STATUS:
            return {
                ...state,
                isStatusLoading: true
            };

        case CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                isStatusLoading: false,
                note: {
                    ...state.note,
                    status: action.payload.status
                },
                errors: {}
            };

        case SEARCH_AT_NOTE_LIST:
            return {
                ...state,
                query: {
                    ...state.query,
                    searchText: action.payload
                }
            };

        default:
            return { ...state };
    }
};

export default Notes;
