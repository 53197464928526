import React, {useMemo, useCallback, useState, useEffect} from 'react';
import clsx from 'clsx';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  useFilters,
} from 'react-table';
import Cell from './Cell';
import Header from './Header';
import PlusIcon from './img/Plus';
import { ActionTypes } from './utils';
import { FixedSizeList } from 'react-window';
import scrollbarWidth from './scrollbarWidth';
import { matchSorter } from 'match-sorter'
import './headerStyles.css';
import './style.css';
import { useSelector } from "react-redux";
import { SCREEN_SIZES } from 'utils/consts';
import { SITE_PERMISSION } from "../../common/constants/common";
import CopyButton from "../../common/cellData/CopyButton";
import {getGuidShortFormat} from "../../common/utils/common";
import useWindowSize from 'hooks/useWindowSize';

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

export default function Table({
  columns,
  data,
  dispatch: dataDispatch,
  skipReset,
  setIsEdit,
  showEditModal,
  showChangePassword,
}) {
    const [isShowPopUpFilters, setIsShowPopUpFilters] = useState({});
    const [activeRow, setActiveRow] = useState(false);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const size = useWindowSize();

    const canChangePasswordPermission = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Agent__Edit_Password);
    }, [agentSelf]);

    const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        if (columnId === 'brands') {
            const rowValueA = rowA.values[columnId][0]?.replace(/[\s.,%]/g, '')
            const rowValueB = rowB.values[columnId][0]?.replace(/[\s.,%]/g, '')
            return rowValueA?.localeCompare(rowValueB);
        }

          return isNaN(rowA.values[columnId])
              ? rowA.values[columnId]?.localeCompare(rowB.values[columnId])
              : rowA.values[columnId] - rowB.values[columnId];
      },
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      minWidth: 50,
      width: 100,
      Cell: Cell,
      Header: Header,
      sortType: 'alphanumericFalsyLast',
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes,
      filterTypes,
      disableSortBy: true
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
  );

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      delete style.width;
      prepareRow(row);
      const handleToggle = (index) => {
        size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
          activeRow === index ? setActiveRow(null) : setActiveRow(index)
        )
      }
      return (
        <div
          {...row.getRowProps({ style })}
          className={clsx("tr", {open: activeRow === index})}
          onClick={() => handleToggle(index)}
        >
          {row.cells.map((cell, ind) => {
              const isNameOrEmailField =
                  cell.column?.id === "firstName" ||
                  cell.column?.id === "lastName" ||
                  cell.column?.id === "email" ||
                  cell.column?.id === "department" ||
                  cell.column?.id === "phoneExtension";
              const isID = cell.column?.id === "id";
              const isFirstName = cell.column?.id === "firstName";
              const isLastName = cell.column?.id === "lastName";
              const isRole = cell.column?.id === "role";
              const isBrands = cell.column?.id === "brands";
              const isEdit = cell.column?.id === "edit";
              const isChangePassword = cell.column?.id === "changePassword";
              let value = cell?.value;
              return (
                  <div
                      style={{ height: "1000px" }}
                      {...cell.getCellProps()}
                      className={clsx(
                          "td",
                          { 
                            ['isNameField']: isNameOrEmailField,
                            ['isFirstName']: isFirstName,
                            ['isLastName']: isLastName,
                            ['isRole']: isRole,
                            ['isID']: isID,
                            ['isBrands']: isBrands,
                            ['isEdit']: isEdit,
                            ['isChangePassword']: isChangePassword,
                          } 
                      )}
                  >
                    {
                      size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT &&
                      !isID &&
                      !isEdit &&
                      !isChangePassword && (
                        <span className='cell-title'>{cell.column?.id}</span>
                      )
                    }
                      {isID &&
                          <div
                            style={{
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                hight: '100%',
                            }}
                          >
                              <CopyButton text={value} />
                              <div style={{paddingLeft: '8px'}}>
                                  {getGuidShortFormat(value)}
                              </div>
                          </div>
                      }
                      {!isID && cell.render('Cell', {
                          setIsEdit,
                          edited: false,
                          ...({showEditModal: cell.column?.id === "changePassword" ? showChangePassword : showEditModal}),
                      })}
                  </div>
              )
          })}
        </div>
      );
    },
    [prepareRow, rows, activeRow]
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  const scrollWidth = useMemo(() => {
      return headerGroups[0].getHeaderGroupProps().style.width
  },[headerGroups[0].getHeaderGroupProps()])

  return (
    <>
      <div
        {...getTableProps()}
        className={clsx('table', isTableResizing() && 'noselect')}
        style={{ overflowY: 'hidden', overflowX: 'hidden', width: '100%' }}
      >
        <div>
          {headerGroups.map((headerGroup) => {
              return (
                  <div {...headerGroup.getHeaderGroupProps() } className="tr">

                      {headerGroup.headers.map(
                          (column) => {
                              return(
                                  <React.Fragment key={column.label}>
                                      {column.render(
                                          'Header',
                                          {   enableSorting: column.enableSorting,
                                              setIsShowPopUpFilter: setIsShowPopUpFilters,
                                              isShowPopUpFilter: isShowPopUpFilters[column.label]
                                          }
                                      )}
                                  </React.Fragment>
                              )
                          }
                      )}
                  </div>
              )
          })}
        </div>
        <div
            {...getTableBodyProps()}
            className="table-body"
        >
          <FixedSizeList
            height={40 * rows.length}
            itemCount={rows.length}
            itemSize={40}
            width={totalColumnsWidth}
            style={{
                overflowX: 'hidden',
                minWidth: '100%',
            }}
          >
            {RenderRow}
          </FixedSizeList>

            {/* TODO This code will be used in future*/}
              {/*<div*/}
              {/*  className="add-row"*/}
              {/*  onClick={() => dataDispatch({ type: ActionTypes.ADD_ROW })}*/}
              {/*>*/}
              {/*  <span className="svg-icon svg-gray icon-margin">*/}
              {/*    <PlusIcon />*/}
              {/*  </span>*/}
              {/*    {i18n.t('agents.addNewRole')}*/}
              {/*</div>*/}

        </div>
      </div>
    </>
  );
}
