import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Card, Col, Row} from "reactstrap";
import i18n from "i18next";

import listIcon from '../../../assets/images/retail/list.svg';
import "../styles.scss";
import FormSearch from "../../../UI/formSearch/FormSearch";
import {resetSearchListErrors, searchPlayerList} from "../../../store/search/actions";
import {RECENT_LIST_SIZE, RECENT_LOCAL_STORAGE_NAME, SEARCH_LIMIT} from "../../../components/SearchBar";
import ListOfResultSearch from "../../../UI/listOfResultSearch/ListOfResultSearch";
import {condition} from "../../../utils/search";
import useClickOutside from "../../../common/hooks/useClickOutside";
import useHandleEsc from "../../../common/hooks/useHandleEsc";
import useAlertService from "../../../hooks/useAlertService";
import { getStorageXSiteId } from "../../../common/utils/common";

let timeout;
const activeType = "RETAIL_PLAYER"

const ManageUser = () => {
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const {
        active: activeBrand,
    } = useSelector(state => state.Brand);
    const { searchResultList, error, isLoading } = useSelector(state => state.Search);
    const areaRef = useRef();

    const [isShowSearchMenu, setShowSearchMenu] = useState(false);
    const [searchText, setSearchText] = useState('');

    useClickOutside(areaRef, () => handleEsc());
    useHandleEsc(() => handleEsc());

    const handleEsc = () => {
        setShowSearchMenu(false);
    };

    useEffect(() => {
        if (error) {
            alertService.showError(error?.message);
            dispatch(resetSearchListErrors());
        }
    }, [error]);

    const handleLoadResult = useCallback(
        value => {
            const query = { siteId: activeBrand?.id, searchText: value || ' ', limit: SEARCH_LIMIT };
            dispatch(searchPlayerList(query));
        },
        [activeBrand?.id]
    );

    const onChangeSearchValue = useCallback(
        ({ target }) => {
            setSearchText(target.value)
            clearInterval(timeout);
            if (target.value) {
                timeout = setTimeout(() => {
                    setShowSearchMenu(true);
                    handleLoadResult(target.value);
                }, 500);
            } else {
                setShowSearchMenu(false);
            }
        },
        []
    );

    const setToRecentList = useCallback(
        item => {
            const xSiteId = getStorageXSiteId();
            const recent = JSON.parse(localStorage.getItem(RECENT_LOCAL_STORAGE_NAME)) || {};
            if (!recent[xSiteId]) {
                recent[xSiteId] = {};
            }

            const recentBySiteId = recent[xSiteId];
            // Check or exist item in recent
            const recentList = (recentBySiteId[activeType] || []).filter(
                data => `${data.id}` !== `${item.id}`
            );

            recentBySiteId[activeType] = [
                { id: item.id, label: item.label },
                ...recentList
            ].slice(0, RECENT_LIST_SIZE);

            localStorage.setItem(RECENT_LOCAL_STORAGE_NAME, JSON.stringify(recent));
        },
        [localStorage.getItem(RECENT_LOCAL_STORAGE_NAME), getStorageXSiteId()]
    );

    const recentList = useMemo(() => {
        const xSiteId = getStorageXSiteId();
        const recent = JSON.parse(localStorage.getItem(RECENT_LOCAL_STORAGE_NAME)) || {};

        const recentBySiteId = recent[xSiteId] || {};

        return recentBySiteId[activeType] || [];
    }, [localStorage.getItem(RECENT_LOCAL_STORAGE_NAME), getStorageXSiteId()]);

    const resultList = useMemo(() => {
        if (searchResultList?.length) {
            const highCorrespondingResults = [];
            const lowCorrespondingResults = [];

            searchResultList.forEach(element => {
                const preparedItem = {
                    id: element.id,
                    status: element.status,
                    label: `${element.firstName} ${element.lastName} (id: ${element.id})`
                };;

                if (condition(preparedItem.label, searchText))  {
                    highCorrespondingResults.push(preparedItem);
                } else {
                    lowCorrespondingResults.push(preparedItem);
                }
            });

            return [ ...highCorrespondingResults, ...lowCorrespondingResults ] || [];
        }
    }, [searchResultList]);

    return (
        <Row>
            <Col lg="12">
                <Card className="retail-search">
                    <div className="title-section">
                        <img
                            src={listIcon}
                            alt="icon list"
                        />
                        {i18n.t('retail.manageUser')}
                    </div>
                    <div ref={areaRef} className="retail-search__form">
                        <FormSearch
                            onChangeSearchValue={onChangeSearchValue}
                            searchValue={searchText}
                            showIconSearch
                        />
                        {isShowSearchMenu && !isLoading && (
                            <ListOfResultSearch
                                setShowSearchMenu={setShowSearchMenu}
                                searchText={searchText}
                                isLoading={isLoading}
                                resultList={resultList}
                                recentList={recentList}
                                setToRecentList={setToRecentList}
                                getUrlAddress={(item) => `/retail-player-list/${item.id}`}
                            />
                        )}
                    </div>
                </Card>
            </Col>
        </Row>
    )
};

export default ManageUser;