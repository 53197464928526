import React from 'react';
import { ResponsiveContainer, Bar, BarChart, XAxis, LabelList } from 'recharts';
import PropTypes from 'prop-types';

const AppBarChart = ({ chartData, fill }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
                <Bar dataKey="value" fill={fill || '#3DCCB8'}>
                    <LabelList dataKey="topName" position="top" />
                </Bar>

                <XAxis dataKey="name" />
            </BarChart>
        </ResponsiveContainer>
    );
};

AppBarChart.propTypes = {
    chartData: PropTypes.array.isRequired,
    fill: PropTypes.string
};

export default AppBarChart;
