const languages = {
    es: {
        label: 'Spanish'
    },
    en: {
        label: 'English'
    }
};

export default languages;
