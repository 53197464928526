import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';

import PaymentIdLine from './components/PaymentIdLine';

import group from '../../assets/images/common/Tooltip.svg';

import './styles.scss';

import Formatter from '../../utils/Formatter';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';
import PropTypes from 'prop-types';
import ButtonOpenPlayerView from "../../UI/buttons/ButtonOpenPlayerView";
import {useTranslation} from "react-i18next";
import {useTimeContext} from "../../context/TimeContext";

const getPlayerInfoScheme = (data,timeFormat, t) => [
    {
        label: t('finance.playerId'),
        value: data?.player?.oid || '-',
    },
    {
        label: t('finance.name'),
        value: `${data?.player?.firstName || '-'} ${data?.player?.lastName || '-'}`
    },
    {
        label: t('finance.nationality'),
        value: data?.player?.citizenship ? t(data?.player?.citizenship) : '-'
    },
    {
        label: t('finance.registrationTime'),
        value: Formatter.displayDateTimeSeconds(timeFormat.parseTime(data?.player?.createdAt))
    },
    {
        label: t('finance.isTest'),
        value: data?.player?.isTest
    }
];

function PaymentMainCard({ payment }) {
    const { t, i18n } = useTranslation();
    const { timeFormat } = useTimeContext();

    const playerInfoScheme = useMemo(
        () => getPlayerInfoScheme(payment, timeFormat, t),
        [payment, timeFormat, i18n.language]
    );

    return (
        <Card className="page-card mh-auto">
            <CardBody className="user-info">
                <PaymentIdLine transaction={payment} />

                <div className="horizontal-separator top-separator" />

                <div className="section-content payment__player__section">
                    <div className="sub-content__header d-flex align-items-center">
                        <span className="me-2">
                            <img width="24" src={group} alt="" />
                        </span>
                        <span className="section-title">
                            {i18n.t('finance.playerInfo')}
                        </span>
                    </div>

                    <div className="d-flex flex-wrap payment__player__info">
                        {playerInfoScheme.map((item, index) => (
                            <ACommonDataField
                                key={index}
                                disabled={true}
                                label={item.label}
                                value={item.value}
                            />
                        ))}
                    </div>
                </div>
                <ButtonOpenPlayerView
                    playerOid={payment.player?.oid}
                    label={i18n.t("crm.playerView")}
                    classNameWrapper={"wrapperButtonOpenPlayerView"}
                    openInNewTab
                />
            </CardBody>
        </Card>
    );
}

PaymentMainCard.propTypes = {
    payment: PropTypes.object.isRequired
};

export default PaymentMainCard;
