import React, { useEffect, useState } from 'react';
import TableCell from './TableCell';
import { isEqual } from "lodash";
import clsx from "clsx";

const TableRow = ({
                      game: gameData,
                      gameEdit,
                      columns,
                      setEditedGame,
                      gamePictures,
                      handleGameClick,
                      isShownSelectedGames,
                      modalMode,
                      categoryMode,
                      increaseOrDecreaseGames,
                      canEditByPermission,
                      currentRowHeight,
                      handleGameCheckboxChange,
                      handleCheckCategoryGame,
                      handleRowClick,
                      className,
                      index,
                  }) => {
    const [game, setGame] = useState({});

    useEffect(() => {
        if (gameData) {
            setGame(gameData);
        }
    }, [gameData])

    useEffect(() => {
        if (game.id && gameData.id) {
            if ( !isEqual(game, gameData) ) {
                setEditedGame(prev => ({
                    ...prev,
                    [game.id]: game
                }))
            } else {
                setEditedGame(prev => {
                    delete prev[game.id]
                    return {...prev}
                })
            }
        }
    }, [game])

    const handleChange = (field, value) => {
        if (field === 'priority') {
            value = value.replace(/-/g, '');
        }
        setGame(prevState => ({
            ...prevState,
            [field]: value
        }));
    }


    return (
        <tr
            className={className}
            onClick={() => {handleRowClick(index)}}
        >
            {columns.map((column, ind) => (
                <td
                    key={column.id}
                    className={`${column.className || ''} ${
                        ind === 0 ? 'without-border' : ''
                    } ${
                        ind === columns.length - 1
                            ? 'last-cell'
                            : ''
                    } ${
                        column.id === 'check'
                            ? 'check-cell'
                            : ''
                    }`}
                    style={{
                        padding: `${currentRowHeight === 45 ? '' : '8' }px`
                    }}
                >
                    <TableCell
                        game={game}
                        isShownSelectedGames={isShownSelectedGames}
                        setEditedGame={setEditedGame}
                        categoryMode={categoryMode}
                        column={column}
                        handleChange={(field, value) => handleChange(field, value)}
                        increaseOrDecreaseGames={increaseOrDecreaseGames}
                        img={gamePictures[game.id]}
                        onCheckGame={handleGameCheckboxChange
                                    ? () => handleGameCheckboxChange(game.id)
                                    : () => handleCheckCategoryGame(game.id)}
                        onClick={
                            modalMode ? () => {} : () => handleGameClick(game)
                        }
                        modalMode={modalMode}
                        canEditByPermission={canEditByPermission}
                    />
                </td>
            ))}
        </tr>
    );
};

export default TableRow;
