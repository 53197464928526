import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import i18n from '../../../i18n';

import checked from '../../../assets/images/layout/checked.svg';
import notChecked from '../../../assets/images/layout/not-checked.svg';

function AddCommentForm(props) {
    const { isNotifyChecked, onCommentChange, onToggleNotifyPlayer } = props;

    return (
        <div className={'add-comment-form-wrapper'}>
            <Input
                className="reason-description"
                type="textarea"
                name="text"
                placeholder={i18n.t('crm.writeSomething')}
                onChange={onCommentChange}
            />

            <div
                className={`notify-checked ${isNotifyChecked ? 'checked' : ''}`}
            >
                {isNotifyChecked && (
                    <img
                        className="notify-checked-img"
                        alt=""
                        src={checked}
                        width={24}
                        height={24}
                        onClick={onToggleNotifyPlayer}
                    />
                )}
                {!isNotifyChecked && (
                    <img
                        className="notify-checked-img"
                        alt=""
                        src={notChecked}
                        width={24}
                        height={24}
                        onClick={onToggleNotifyPlayer}
                    />
                )}
                <span
                    className="notify-checked-label"
                    onClick={onToggleNotifyPlayer}
                >
                    {i18n.t('crm.notifyPlayer')}
                </span>
            </div>
        </div>
    );
}

AddCommentForm.propTypes = {
    isNotifyChecked: PropTypes.bool,
    onCommentChange: PropTypes.func,
    onToggleNotifyPlayer: PropTypes.func
};

export default React.memo(AddCommentForm);
