import {
    SAVE_FILTER,
    FILTERS_ERROR,
    SELECT_FILTER,
    GET_FILTERS_LIST,
    ADD_CURRENT_FILTER,
    SAVE_FILTER_SUCCESS,
    CLEAR_CURRENT_FILTER,
    EDIT_CURRENT_FILTER,
    REMOVE_CURRENT_FILTER,
    REMOVE_FROM_FILTER_LIST,
    GET_FILTERS_LIST_SUCCESS,
    RESET_FILTERS_ALERTS_ERRORS,
    REMOVE_CURRENT_FILTERS,
} from './actionTypes';
import i18n from '../../i18n';

export const getLocalStorageFilter = () => {
    const dynamic = localStorage.getItem('LocalStorageFilter');

    return JSON.parse(dynamic);
}

const INITIAL_STATE = {
    isLoading: false,
    list: [],
    currentFilter: getLocalStorageFilter() || [],
    error: {},
    sidebarValue: { title: i18n.t('filter.filter'), id: '' },
    successMessage: '',
    query: {
        page: 1,
        pageSize: 100
    }
};

const Filter = (state = INITIAL_STATE, action) => {
    let newFilter_;
    let idx;
    let key;
    let values;
    let prevFilter = [];
    let newFilterKey = '';
    let newFilter;

    switch (action.type) {
        case GET_FILTERS_LIST:
            return { ...state, isLoading: true };
        case GET_FILTERS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: action.payload
            };
        case REMOVE_FROM_FILTER_LIST:
            return {
                ...state,
                list: state.list.filter(item => item.id !== action.payload)
            };
        case SAVE_FILTER:
            return { ...state, isLoading: true };
        case SAVE_FILTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: [...state.list, action.payload],
                sidebarValue: state.sidebarValue,
                successMessage: i18n.t('filter.successSaved')
            };
        case ADD_CURRENT_FILTER:
            if (action.payload?.length > 0) {
                newFilterKey = action.payload[0].key;
            }
            //this is to overwrite the previous key to avoid contradictions

            if (newFilterKey === 'amount') {
                prevFilter = state.currentFilter;
            } else {
                prevFilter = state.currentFilter.filter(
                    item => item.key !== newFilterKey
                );
            }

            newFilter = [...prevFilter, ...action.payload];
            localStorage.setItem('LocalStorageFilter', JSON.stringify(newFilter));

            return {
                ...state,
                currentFilter: newFilter
            };
        case EDIT_CURRENT_FILTER:
            ({ idx, values } = action.payload);
            if (values?.length > 0) {
                newFilterKey = values[0].key;
            }

            if (newFilterKey === 'amount') {
                newFilter = [...state.currentFilter];

                if (newFilter[idx].key === newFilterKey) {
                    newFilter[idx] = values[0];
                }
            }
            else {
                prevFilter = state.currentFilter.filter(
                    item => item.key !== newFilterKey
                );

                newFilter = [...prevFilter, ...values];
            }

            localStorage.setItem('LocalStorageFilter', JSON.stringify(newFilter));

            return {
                ...state,
                currentFilter: newFilter
            };
        case REMOVE_CURRENT_FILTER:
            newFilter_ = [...state.currentFilter].
                filter((_, index) => index !== action.payload);
            localStorage.setItem('LocalStorageFilter', JSON.stringify(newFilter_));

            return {
                ...state,
                currentFilter: newFilter_,
                sidebarValue: { title: i18n.t('filter.filter'), id: '' }
            };
        case REMOVE_CURRENT_FILTERS:
            ({key, values} = action.payload);

            if (key && values.length > 0) {
                newFilter_ = [...state.currentFilter].filter((cond) => !(cond.key === key && values.includes(cond.value)));
                localStorage.setItem('LocalStorageFilter', JSON.stringify(newFilter_));

                return {
                    ...state,
                    currentFilter: newFilter_
                };
            }

            break;
        case SELECT_FILTER:
            return {
                ...state,
                currentFilter: action.payload.currentFilter,
                sidebarValue: action.payload?.sidebarValue || state.sidebarValue
            };
        case CLEAR_CURRENT_FILTER:
            const defaultFilter = action.payload;
            localStorage.setItem('LocalStorageFilter', JSON.stringify(defaultFilter || []));

            return {
                ...state,
                currentFilter: defaultFilter || [],
                sidebarValue: { title: i18n.t('filter.filter'), id: '' },
                successMessage: ''
            };
        case FILTERS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case RESET_FILTERS_ALERTS_ERRORS:
            return {
                ...state,
                error: {},
                successMessage: ''
            };
        default:
            return state;
    }
};

export default Filter;
