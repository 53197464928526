import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import i18n from '../../../../i18n';
import {A_COMMON_DATA_FIELD} from '../../../../constants/aCommonDataField';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';
import './ManualAdjustment.scss';

import { useDispatch } from 'react-redux';
import { SPORT_PROMOTION_TYPE } from "../../../Promotions/common/promotionConstants";
import { applyPlayerSportPromotion } from "../../../../store/players/promotions/actions";
import SaveCancelButtons from "../../../../components/Buttons/SaveCancelButtons";
import {COMMA_DOT_DIGITS_REG_EXP, PROMOTION_MAX_MONEY_VALUE_REG_EXP} from "../../../../constants/validations";
import { fieldsNamesForRestrict } from "../../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const prepareModel = (template) => {
    const {id, name, externalId, coupon, settings} = template;
    const {sb: sportsBook, amount: amountObj, isDeposit} = settings;
    const currency = amountObj && Object.keys(amountObj)[0] || 'MXN';
    const amount = amountObj?.[currency] || 0;

    return {
        id,
        promotionTemplate: id,
        name: name,
        bonusName: '',
        externalId,
        coupon,
        sportsBook,
        currency,
        amount,
        isDeposit,
        type: isDeposit ? SPORT_PROMOTION_TYPE.DEPOSIT : SPORT_PROMOTION_TYPE.FREE_BET,
    };
}

function NewSportBonusModal({
    onHide,
    fieldErrors,
    isLoading,
    playerId,
    isPromotionsAllowed,
    promotions = [],
}) {
    const dispatch = useDispatch();

    const [options, setOptions] = useState([{
        value: '',
        content: 'promo.name',
    }]);

    const [model, setModel] = useState({
        id: '',
        promotionTemplate: '',
        bonusName: '',
        amount: 0,
        currency: 'MXN',
        name: name,
        externalId: '',
        coupon: '',
        sportsBook: '',
        type: SPORT_PROMOTION_TYPE.DEPOSIT,
    });

    const restrictCents = model.currency === 'MXN' ||
                          model.currency === 'USD' ||
                          model.currency === 'EUR';

    const handleChange = useCallback(name => {
        return value => {
            if (name === 'amount') {
                value = value.replace(COMMA_DOT_DIGITS_REG_EXP, '')
            }
            if (restrictCents && fieldsNamesForRestrict(name)) {
                value = value.replace(',', '.')
                    .replace(/[a-zA-Z]/g, '')
                    .replace(PROMOTION_MAX_MONEY_VALUE_REG_EXP, '$1');
            }
            if (name === 'promotionTemplate') {
                const bonus = promotions.find(promo => value === promo.id);
                if (bonus) {
                    const newModel = prepareModel(bonus);
                    setModel({
                        ...newModel,
                        [name]: value,
                    });
                    return;
                }
            }

            setModel(prevState => ({...prevState, [name]: value}));
        };
    }, [promotions]);

    useEffect(() => {
        if (promotions) {
            setOptions(promotions
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                ?.map((promo, index) => ({
                    value: promo.id,
                    content: promo.name || `template ${index}`
                }))
            )
            if (promotions.length > 0) {
                const newModel = prepareModel(promotions[0]);
                setModel(newModel);
            }
        }
    }, [promotions])

    const handleSave = useCallback(() => {
        const { amount, coupon, isDeposit } = model;
        dispatch(applyPlayerSportPromotion({
            id: playerId,
            query: {
                code: coupon,
                ...(!isPromotionsAllowed && { force: true }),
                ...(isDeposit
                    ? {
                        deposit: amount,
                    }
                    : { amount }
                )
            }
        }));

        onHide();
    }, [model, isPromotionsAllowed]);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="new-bonus-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('promotions.newSportBonus')}
                </h5>
                <button className="close" onClick={onHide}/>
            </div>

            <ModalBody>
                <div
                    className="d-flex"
                >
                    <div className="col-6 promotion-select">
                        <div key={'promotionTemplate'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['promotionTemplate']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                label={i18n.t('promotions.promotionTemplate')}
                                value={model.promotionTemplate}
                                handleChange={handleChange('promotionTemplate')}
                                options={options}
                            />
                        </div>
                        <div key={'amount'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['amount']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.amount')}
                                value={model.amount}
                                handleChange={handleChange('amount')}
                            />
                        </div>
                        <div key={'type'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['externalId']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.externalId')}
                                value={model.externalId}
                                handleChange={handleChange('externalId')}
                                disabled={true}
                            />
                        </div>
                        <div key={'type'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['coupon']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.coupon')}
                                value={model.coupon}
                                handleChange={handleChange('coupon')}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div key={'name'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['bonusName']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.bonusName')}
                                value={model.bonusName}
                                handleChange={handleChange('bonusName')}
                            />
                        </div>
                        <div key={'type'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['sportsBook']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.sportsBook')}
                                value={model.sportsBook}
                                handleChange={handleChange('sportsBook')}
                                disabled={true}
                            />
                        </div>
                        <div key={'type'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['type']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.type')}
                                value={i18n.t(`promotions.constants.${model.type}`)}
                                handleChange={handleChange('type')}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <SaveCancelButtons
                    onCancel={onHide}
                    onSave={handleSave}
                    isModalButtons={true}
                    saveBtnText={i18n.t('admin.add')}
                    isDisabledSave={!model.promotionTemplate || !model.amount}
                    isDisabledCancel={false}
                />
            </ModalFooter>
        </Modal>
    );
}

NewSportBonusModal.propTypes = {
    playerId: PropTypes.any.isRequired,
    onHide: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    isLoading: PropTypes.bool,
    promotions: PropTypes.array,
};

export default React.memo(NewSportBonusModal);
