import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import {useHistory} from 'react-router-dom';

import { changeBrand, getBrands } from '../../store/brand/siteBrands/actions';
import { getPlayerKYCCount, resetPlayerQuery } from "../../store/players/list/actions";
import { clearCurrentFilter } from "../../store/filters/action"

import Loader from '../../common/components/Loader';

import useAlertService from '../../hooks/useAlertService';
import useClickOutside from '../../common/hooks/useClickOutside';

import wiztechIcon from '../../assets/images/logo/Logo.png';
import dropdown from '../../assets/images/common/brand-arrow.svg';
import check from '../../assets/images/common/check.svg';
import {DEFAULT_PLAYER_KYC_COUNT_QUERY, DEFAULT_WITHDRAW_COUNT_QUERY} from "../../constants/Filter";

import './sidebar.scss';
import { getPendingWithdrawalListCount } from "../../store/pending-withdrawals/list/actions";
import { getWithdrawalListCount } from "../../store/withdrawals/list/actions";
import { getTransferWithdrawalListCount } from "../../store/pending-transfer-withdrawals/list/actions";

const SidebarBrand = ({ isToggled }) => {
    const areaRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        data,
        active: activeBrand,
        isLoading,
        error
    } = useSelector(state => state.Brand);

    const [isOpen, setOpen] = useState(false);

    const { showError } = useAlertService();

    const toggleDropdown = useCallback(() => {
        setOpen(prev => !prev);
    }, [setOpen]);

    const onBrandChange = useCallback(
        siteId => () => {
            dispatch(changeBrand(siteId));
            dispatch(resetPlayerQuery());
            dispatch(clearCurrentFilter());
            dispatch(getWithdrawalListCount(DEFAULT_WITHDRAW_COUNT_QUERY));
            dispatch(getPendingWithdrawalListCount(DEFAULT_WITHDRAW_COUNT_QUERY));
            dispatch(getTransferWithdrawalListCount(DEFAULT_WITHDRAW_COUNT_QUERY));
            dispatch(getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY));
            history.push('/player-list');
            window.location.reload();
        },
        [activeBrand?.id]
    );

    useEffect(() => {
       dispatch(getBrands());
    }, []);

    useEffect(() => {
        if (error.message) {
            showError(error.message);
        }
    }, [error]);

    useEffect(() => {
        if (!isToggled) setOpen(false);
    }, [isToggled]);

    useClickOutside(areaRef, () => setOpen(false));

    const logo = useMemo(() => {
        return (
            <div className={'img-wrapper'}>
                <img
                    src={activeBrand?.logo}
                    className={"logo-picture"}
                    alt={'logo'}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/wiz/logo.png';
                    }}
                /></div>
        )
    }, [activeBrand?.logo]);

    return (
        <div
            className={'logo-wrapper'}
            onClick={isLoading ? () => {
            } : toggleDropdown}
            ref={areaRef}
        >
            <div className="logo logo-light">
                <span className="logo-sm">
                    {isToggled && (
                        <img src={activeBrand?.logo || wiztechIcon} alt={'logo'}/>
                    )}
                </span>
            </div>

            <div className={'dropdown'}>
                {/* {!isLoading && (
                    <>
                        <div className="logo logo-light">
                            <span className="logo-lg logo-big">
                                <div className={'img-wrapper'}>
                                    <img
                                        src={activeBrand?.logo || wiztechIcon}
                                        alt={'logo'}
                                    />
                                </div>
                            </span>
                        </div>

                        <img
                            src={dropdown}
                            alt="dropdown"
                            className={`dropdown-arrow`}
                        />
                    </>
                )} */}

                {!isLoading && (
                    <>
                        <div className="logo logo-light">
                            <span className="logo-lg logo-big">
                                {logo}

                                <h6>{activeBrand?.siteName}</h6>

                                <img
                                  src={dropdown}
                                  alt="dropdown"
                                  className={`dropdown-arrow mx-1 ${isOpen ? "open" : ""}`}
                                />
                            </span>
                        </div>
                    </>
                )}
                {isLoading && <Loader />}

                <ListGroup
                    className={`dropdown-list${isOpen ? '' : ' closed'}`}
                >
                    {data.map(({ id, logo, siteName }, index) => {
                        const isActive = id === activeBrand?.id;

                        return (
                            <ListGroupItem
                                className={'dropdown-list__item'}
                                onClick={onBrandChange(id)}
                                key={index}
                            >
                                {/*<img src={logo} alt="logo" />*/}
                                <h6 className={isActive ? 'active' : ''}>
                                    {siteName}
                                </h6>
                                {isActive && (
                                    <img
                                        src={check}
                                        alt="active"
                                        className={'check'}
                                    />
                                )}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
            </div>
        </div>
    );
};

export default React.memo(SidebarBrand);
