import { FILTER_MENU_SELECT_LIST_VALUES } from "../../constants/Filter";
import moment from "moment";
import { AttributionType } from "../../pages/Content/common/constants";

const FILTER_FIELDS_MAP = {
    [FILTER_MENU_SELECT_LIST_VALUES.GAME]: 'friendlyName',
    [FILTER_MENU_SELECT_LIST_VALUES.FRIENDLY_NAME]: 'friendlyName',
    [FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME_CATEGORY]: 'gameName',
    [FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME]: 'gameName',
    [FILTER_MENU_SELECT_LIST_VALUES.OPERATION_ID]: 'oid',
    [FILTER_MENU_SELECT_LIST_VALUES.VENDOR]: 'vendorName',
    [FILTER_MENU_SELECT_LIST_VALUES.PROVIDER]: 'provider',
    [FILTER_MENU_SELECT_LIST_VALUES.TYPE]: 'gameType',
    [FILTER_MENU_SELECT_LIST_VALUES.FREE_SPINS]: 'supportFreeSpins',
    [FILTER_MENU_SELECT_LIST_VALUES.RTP]: 'rtp',
    [FILTER_MENU_SELECT_LIST_VALUES.PRIORITY]: 'priority',
    [FILTER_MENU_SELECT_LIST_VALUES.EMPHASIZE]: 'emphasize',
    [FILTER_MENU_SELECT_LIST_VALUES.ANIMATED]: 'animated',
    [FILTER_MENU_SELECT_LIST_VALUES.ADDED_TIME]: 'createdAt',
    [FILTER_MENU_SELECT_LIST_VALUES.ENABLED_TIME]: 'enabledAt',
    [FILTER_MENU_SELECT_LIST_VALUES.BONUS_FACTOR]: 'bonusFactor',
    [FILTER_MENU_SELECT_LIST_VALUES.LINES]: 'lines',
    [FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_MOBILE_ID]: 'externalMobileId',
    [FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_DESKTOP_ID]: 'externalDesktopId',
};

const getFilteredGames = (currentFilter, games, brandVendors = []) => {
    const currentFilterObj = {};
    currentFilter.forEach((filter) => currentFilterObj[filter.key] = filter.value);
    const filterFields = Object.keys(currentFilterObj);

    if (filterFields.length > 0) {
        return filterFields.reduce((filtered, field) => {
            const filterValue = currentFilterObj[field];
            const dataField = FILTER_FIELDS_MAP[field] || AttributionType[field];
            let op;
            let val;
            let value;

            switch (field) {
                case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME_CATEGORY:
                case FILTER_MENU_SELECT_LIST_VALUES.FRIENDLY_NAME:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_MOBILE_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.EXTERNAL_DESKTOP_ID:
                case FILTER_MENU_SELECT_LIST_VALUES.GAME_NAME:
                    const lowerCaseValue = filterValue.toLowerCase();
                    return filtered
                        .filter((game) => (game[dataField] || game.friendlyName)?.toLowerCase()?.includes(lowerCaseValue));

                case FILTER_MENU_SELECT_LIST_VALUES.OPERATION_ID:
                    return filtered.filter((game) => `${game[dataField]}`.includes(filterValue));

                case FILTER_MENU_SELECT_LIST_VALUES.TYPE:
                case FILTER_MENU_SELECT_LIST_VALUES.EMPHASIZE:
                case FILTER_MENU_SELECT_LIST_VALUES.FREE_SPINS:
                case FILTER_MENU_SELECT_LIST_VALUES.ANIMATED:
                    return filtered.filter((game) => game[dataField] === filterValue);

                case FILTER_MENU_SELECT_LIST_VALUES.VENDOR:
                    if (brandVendors.length === 0) {
                        return filtered.filter((game) => game[dataField] === filterValue);
                    }
                    const vendorId = brandVendors.find((vendor) => vendor?.name === filterValue)?.id;
                    return filtered.filter((game) => game.vendor === vendorId);

                case FILTER_MENU_SELECT_LIST_VALUES.PROVIDER:
                    return filtered.filter((game) => game.providerName === filterValue);

                case FILTER_MENU_SELECT_LIST_VALUES.RTP:
                    [op, val] = filterValue.split(' ');
                    value = +val;
                    switch (op) {
                        case '<':
                            return filtered.filter((game) => parseFloat(game[dataField]) < value);
                        case '<=':
                            return filtered.filter((game) => parseFloat(game[dataField]) <= value);
                        case '>':
                            return filtered.filter((game) => parseFloat(game[dataField]) > value);
                        case '>=':
                            return filtered.filter((game) => parseFloat(game[dataField]) >= value);
                        case '=':
                            return filtered.filter((game) => parseFloat(game[dataField]) === value);
                        case '<>':
                            return filtered.filter((game) => parseFloat(game[dataField]) !== value);
                        default:
                            return filtered;
                    }

                case FILTER_MENU_SELECT_LIST_VALUES.PRIORITY:
                case FILTER_MENU_SELECT_LIST_VALUES.BONUS_FACTOR:
                case FILTER_MENU_SELECT_LIST_VALUES.LINES:
                    [op, val] = filterValue.split(' ');
                    value = +val;
                    switch (op) {
                        case '<':
                            return filtered.filter((game) => parseInt(game[dataField], 10) < value);
                        case '<=':
                            return filtered.filter((game) => parseInt(game[dataField], 10) <= value);
                        case '>':
                            return filtered.filter((game) => parseInt(game[dataField], 10) > value);
                        case '>=':
                            return filtered.filter((game) => parseInt(game[dataField], 10) >= value);
                        case '=':
                            return filtered.filter((game) => parseInt(game[dataField], 10) === value);
                        case '<>':
                            return filtered.filter((game) => parseInt(game[dataField], 10) !== value);
                        default:
                            return filtered;
                    }

                case FILTER_MENU_SELECT_LIST_VALUES.ADDED_TIME:
                case FILTER_MENU_SELECT_LIST_VALUES.ENABLED_TIME:
                    let from = moment(filterValue.from);
                    let to = moment(filterValue.to);

                    if (from.isAfter(to)) {
                        [from, to] = [to, from];
                    }
                    return filtered.filter((game) => moment(game[dataField]).isBetween(from, to));

                default:
                    if (dataField in AttributionType) {
                        return filtered.filter((game) => game[dataField] === filterValue);
                    }
                    return filtered;
            }
        }, games);
    }

    return games || [];
};

export {
    getFilteredGames,
}
