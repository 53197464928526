import {
    GET_PLAYER_GAME_PLAY,
    GET_PLAYER_GAME_PLAY_SUCCESS,
    GET_PLAYER_GAME_PLAY_FAIL,
    GET_PLAYER_GAME_SESSIONS,
    GET_PLAYER_GAME_SESSIONS_SUCCESS,
    GET_PLAYER_GAME_SESSIONS_FAIL,
    GET_PLAYER_GAME_ROUNDS,
    GET_PLAYER_GAME_ROUNDS_SUCCESS,
    GET_PLAYER_GAME_ROUNDS_FAIL,
    GET_PLAYER_BET_SLIP,
    GET_PLAYER_BET_SLIP_SUCCESS,
    GET_PLAYER_BET_SLIP_FAIL,
    GET_PLAYER_GAME_SESSION_ROUNDS,
    GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS,
    GET_PLAYER_GAME_SESSION_ROUNDS_FAIL,
    DOWNLOAD_GAME_PLAY_LIST,
    DOWNLOAD_GAME_PLAY_LIST_SUCCESS,
    DOWNLOAD_GAME_PLAY_LIST_FAIL,
    GET_UNIQUE_GAME_IDS_SUCCESS,
    GET_UNIQUE_GAME_IDS,
    GET_UNIQUE_GAME_IDS_FAIL,
    PUT_UNIQUE_GAMES,
} from './actionTypes';

export const getPlayerGamePlay = payload => ({
    type: GET_PLAYER_GAME_PLAY,
    payload
});
export const getPlayerGamePlaySuccess = payload => ({
    type: GET_PLAYER_GAME_PLAY_SUCCESS,
    payload
});
export const getPlayerGamePlayFail = payload => ({
    type: GET_PLAYER_GAME_PLAY_FAIL,
    payload
});

export const getPlayerGameSessions = payload => ({
    type: GET_PLAYER_GAME_SESSIONS,
    payload
});
export const getPlayerGameSessionsSuccess = payload => ({
    type: GET_PLAYER_GAME_SESSIONS_SUCCESS,
    payload
});
export const getPlayerGameSessionsFail = payload => ({
    type: GET_PLAYER_GAME_SESSIONS_FAIL,
    payload
});

export const getPlayerGameRounds = payload => ({
    type: GET_PLAYER_GAME_ROUNDS,
    payload
});
export const getPlayerGameRoundsSuccess = payload => ({
    type: GET_PLAYER_GAME_ROUNDS_SUCCESS,
    payload
});
export const getPlayerGameRoundsFail = payload => ({
    type: GET_PLAYER_GAME_ROUNDS_FAIL,
    payload
});

export const getPlayerBetSlip = payload => ({
    type: GET_PLAYER_BET_SLIP,
    payload
});
export const getPlayerBetSlipSuccess = payload => ({
    type: GET_PLAYER_BET_SLIP_SUCCESS,
    payload
});
export const getPlayerBetSlipFail = payload => ({
    type: GET_PLAYER_BET_SLIP_FAIL,
    payload
});

export const getPlayerGameSessionRounds = payload => ({
    type: GET_PLAYER_GAME_SESSION_ROUNDS,
    payload
});
export const getPlayerGameSessionRoundsSuccess = payload => ({
    type: GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS,
    payload
});
export const getPlayerGameSessionRoundsFail = payload => ({
    type: GET_PLAYER_GAME_SESSION_ROUNDS_FAIL,
    payload
});

export const downloadGamePlayList = payload => ({
    type: DOWNLOAD_GAME_PLAY_LIST,
    payload
});

export const downloadGamePlayListSuccess = payload => ({
    type: DOWNLOAD_GAME_PLAY_LIST_SUCCESS,
    payload
});

export const downloadGamePlayListFail = payload => ({
    type: DOWNLOAD_GAME_PLAY_LIST_FAIL,
    payload
});

export const getPlayedUniqueGameIds = payload => ({
    type: GET_UNIQUE_GAME_IDS,
    payload
});

export const getPlayedUniqueGameIdsSuccess = payload => ({
    type: GET_UNIQUE_GAME_IDS_SUCCESS,
    payload
});

export const getPlayedUniqueGameIdsFail = payload => ({
    type: GET_UNIQUE_GAME_IDS_FAIL,
    payload
});
export const putPlayedUniqueGames = payload => ({
    type:  PUT_UNIQUE_GAMES,
    payload
});