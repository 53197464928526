import { del, post, get, put } from './api_helper';
import { prepareSearch } from './SearchUtils';

export const getFilterApi = id => get(`/filter/${id}`);

export const getFiltersListApi = (query = {}) =>
    get(`/filter?${prepareSearch(query)}`);

export const saveFilterApi = data => post(`/filter`, data);

export const changeFilterApi = (id, data) => put(`/filter/${id}`, data);

export const removeFilterApi = id => del(`/filter/${id}`);
