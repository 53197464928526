import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import Formatter from '../../../utils/Formatter';
import ProviderResponseModal from '../modal/ProviderResponseModal';
import NewCustomTable from '../../../components/NewCustomTable';

const TrackingTable = ({ transaction }) => {
    const [providerResponse, setProviderResponse] = useState(null);
    const paymentCard = transaction?.paymentCard;

    const columns = [
        { label: i18n.t('finance.time') },
        { label: i18n.t('finance.action') }
    ];


    return (
        <>
            <NewCustomTable columns={columns}>
                {transaction.audit.map((item, i) => {
                    const isExistProviderResponse = !!item.providerResponse;


                    return (
                      <tr
                        style={{ color: '#686c6f' }}
                        className={'tracking-table'}
                        key={i}
                        onClick={() =>
                          setProviderResponse(item.providerResponse)
                        }
                      >
                          <td style={isExistProviderResponse ? { cursor: 'pointer' } : {}}>
                              {Formatter.displayDateTime(item.createdAt)}
                          </td>
                          <td style={isExistProviderResponse ? { cursor: 'pointer' } : {}}>
                              {item.action}
                          </td>
                      </tr>
                    )
                })}
            </NewCustomTable>

            {providerResponse && (
                <ProviderResponseModal
                    providerResponse={providerResponse}
                    paymentCardResponse={paymentCard}
                    onHide={() => setProviderResponse(null)}
                />
            )}
        </>
    );
};

TrackingTable.propTypes = {
    transaction: PropTypes.object
};

export default TrackingTable;
