import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

import '../affiliates.scss';

const HeadlineText = ({ src, label }) => (
    <Col className="headline-text d-flex align-items-center" xs={12}>
        <img className="me-3" src={src} alt={label} />

        <span className="text-uppercase">{label}</span>
    </Col>
);

HeadlineText.proptypes = {
    src: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default React.memo(HeadlineText);
