import { get, patch, post } from './api_helper';

const getRetailPlayerApi = id => {
    const url = `/retail/mx/oid/${id}`;
    return get(url);
};

const updateRetailPlayerApi = ({ id, dto }) => {
    const url = `/retail/mx/oid/${id}`;
    return patch(url, dto);
};

const createRetailPlayerApi = ({ dto }) => {
    const url = `/retail/mx/register`;
    return post(url, dto);
};

const updateRetailKYCStatusApi = ({ kycStatus, playerId }) => {
    const url = `/retail/mx/kyc`;
    return post(url, {
        kycStatus,
        playerId,
    });
};

const paymentRetailApi = ({ type, token, playerId }) => {
    const url = `/retail/mx/payment`;
    return post(url, {
        type,
        token,
        playerId,
    });
}

export {
    getRetailPlayerApi,
    updateRetailPlayerApi,
    createRetailPlayerApi,
    updateRetailKYCStatusApi,
    paymentRetailApi,
};
