import {
    GET_PLAYER_AUDIT_LOGINS_LIST,
    GET_PLAYER_AUDIT_STATUS_LIST,
    GET_PLAYER_AUDIT_CHANGES_LIST,
    RESET_PLAYER_AUDIT_LIST_ERRORS,
    GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    GET_PLAYER_AUDIT_LOGINS_LIST_FAIL,
    GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS,
    GET_PLAYER_AUDIT_STATUS_LIST_FAIL,
    GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS,
    GET_PLAYER_AUDIT_CHANGES_LIST_FAIL,
    GET_PLAYER_AUDIT_FILTERS,
    GET_PLAYER_AUDIT_FILTERS_SUCCESS,
    GET_PLAYER_AUDIT_FILTERS_FAIL,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS,
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL,
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 20,
    offset: 0
};

const INIT_STATE = {
    isDownloading: false,
    isLoading: false,
    error: null,

    loginsList: null,
    totalLoginsList: 0,
    queryLoginsList: { ...DEFAULT_QUERY },

    statusList: null,
    totalStatusList: 0,
    queryStatusList: { ...DEFAULT_QUERY },

    changesList: null,
    totalChangesList: 0,
    queryChangesList: { ...DEFAULT_QUERY },

    changesActions: [],
    changesInitiators: [],
};

const PlayerAudit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_AUDIT_LOGINS_LIST:
            return {
                ...state,
                queryLoginsList: {
                    ...state.queryLoginsList,
                    ...action.payload.query
                },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS:
            return {
                ...state,
                loginsList: action.payload.result,
                totalLoginsList: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_AUDIT_LOGINS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loginsList: [],
                isLoading: false
            };

        case DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case GET_PLAYER_AUDIT_STATUS_LIST:
            return {
                ...state,
                queryStatusList: {
                    ...state.queryStatusList,
                    ...action.payload.query
                },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS:
            return {
                ...state,
                statusList: action.payload.result,
                totalStatusList: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_AUDIT_STATUS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                statusList: [],
                isLoading: false
            };

        case GET_PLAYER_AUDIT_CHANGES_LIST:
            return {
                ...state,
                queryChangesList: {
                    ...state.queryChangesList,
                    ...action.payload.query
                },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS:
            return {
                ...state,
                changesList: action.payload.result,
                totalChangesList: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_AUDIT_CHANGES_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                changesList: [],
                isLoading: false
            };

        case GET_PLAYER_AUDIT_FILTERS:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case GET_PLAYER_AUDIT_FILTERS_SUCCESS:
            return {
                ...state,
                changesActions: action.payload.actions,
                changesInitiators: action.payload.initiators,
                isLoading: false
            };

        case GET_PLAYER_AUDIT_FILTERS_FAIL:
            return {
                ...state,
                isLoading: false
            };

        case RESET_PLAYER_AUDIT_LIST_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default PlayerAudit;
