import React, {useState, useEffect, useRef, useMemo} from "react"
import PropTypes from "prop-types"

import i18n from "../../../i18n"
import CustomInput from "./CustomInput"

import bigVioletDone from "../../../assets/images/layout/big-violet-done.svg"
import bigLightVioletDone from "../../../assets/images/layout/big-light-violet-done.svg"
import category1 from "../../../assets/images/layout/categories/category1.png"
import category2 from "../../../assets/images/layout/categories/category2.png"
import category3 from "../../../assets/images/layout/categories/category3.png"
import category4 from "../../../assets/images/layout/categories/category4.png"

import ConfirmationModal from "../../../common/prompt/ConfirmationModal";
import {confMess} from "../../../common/utils/common";
import { SCREEN_SIZES } from "../../../utils/consts";
import {SELECT_CATEGORIES} from "../../../constants/selectCategories";
import {PROMOTION_STATUS, PROMOTION_TYPE} from "../../Promotions/common/promotionConstants";
import SelectInput from "../../../common/inputs/common/SelectInput";
import TextInput from "../../../common/inputs/TextInput";
import useAlertService from "../../../hooks/useAlertService";
import useWindowSize from "../../../hooks/useWindowSize";
import {useLocation} from "react-router-dom";
import {useRouteMatch} from "react-router";

const categoryImages = [
	category1,
	category2,
	category3,
	category4,
]

const CategoryGamesModalHeader = (props) => {
	const {
		currentCategory,
		onSendCategoryData,
		siteMode,
		isIncreaseGames=[],
		isDecreaseGames=[],
	} = props;
	const size = useWindowSize();
	const location = useLocation();
	const routeConfig = useRouteMatch();
	const isSaveNeeded = routeConfig.path === '/game-categories/:id'

	const [validFormData, setValidFormData] = useState(false)
	const [categoryIcon, setCategoryIcon] = useState(null)
	const [categoryFileName, setCategoryFileName] = useState('')
	const [categoryFileData, setCategoryFileData] = useState(null)
	const [categoryName, setCategoryName] = useState('')
	const [isShowConfDialog, setIsShowConfDialog] = useState(false);

	let activeLogo
	let oid
	let categoryImg
	if (currentCategory) {
		({activeLogo, oid} = currentCategory)
		categoryImg = activeLogo === "some url" ? categoryImages[oid % 4] : activeLogo
	}

	useEffect(() => {
		setValidFormData(categoryName !== '')
	}, [categoryName])

	const handleChangeCategoryName = (event) => {
		setCategoryName(event.target.value)
	}

	const handleLoadFile = (file) => {
		if (file) {
			const fileName = file.name || '';

			const reader = new FileReader();
			reader.onload = (e) => {
				setCategoryFileName(fileName)
				setCategoryIcon(file)
				setCategoryFileData(e.target.result)
			};
			reader.readAsDataURL(file);
		}
	}

	const handleDropFile = (event) => {
		event.preventDefault()
		event.stopPropagation()

		let dt = event.dataTransfer
		let files = dt.files

		const file = files[0];
		handleLoadFile(file)
	}

	const handleSendCategoryData = (e, confirmSend = false) => {
		if (isGamesChecked && !confirmSend) {
			setIsShowConfDialog(true);
		} else if (validFormData || currentCategory || siteMode) {
			onSendCategoryData({
				categoryName,
				validFormData,
			});
		}
	}

	const isGamesChecked = useMemo(() => {
		const hasDuplicates = isIncreaseGames.some(item => isDecreaseGames.includes(item));
		return (isIncreaseGames.length || isDecreaseGames.length) && !hasDuplicates;
	}, [isIncreaseGames, isDecreaseGames]);

	const handleCloseDialog = () => {
		setIsShowConfDialog(false);
	}

	return (
		<div
			className='modal-table-header'
		>
			{(currentCategory || siteMode) &&
			<div className="add-category-modal-header w-100">
				{currentCategory &&
				<>
					<img
						className="category-img"
						src={categoryImg}
						width={40}
						height={40}
						alt=""
					/>
					<div
						className="category-title-text"
					>
						{
							size.width > SCREEN_SIZES.MOBILE_MIDDLE_VIEW && (
								<span>{i18n.t("crm.chooseGamesCategory")}: </span>
							)
						}
						<span>{currentCategory.name}</span>
					</div>
				</>
				}
			</div>
			}
			{!currentCategory && !siteMode &&
			<div className="add-category-modal-header">
				<div className='d-flex'>
					<CustomInput
						className="category-name"
						inputClassName="header__input"
						label={`${i18n.t('content.enterNameCategory')}:`}
						inputPlaceholder={i18n.t('content.enterName')}
						value={categoryName}
						onChange={handleChangeCategoryName}
					/>
				</div>
			</div>
			}

			<div className="send-button">
				{!isSaveNeeded &&
					<img
						src={validFormData || currentCategory || siteMode ? bigVioletDone : bigLightVioletDone}
						alt=""
						width={40}
						height={40}
						onClick={handleSendCategoryData}
					/>
				}
			</div>
			{isShowConfDialog &&
				<ConfirmationModal
					text={confMess(isIncreaseGames, isDecreaseGames)}
					onCancel={handleCloseDialog}
					onConfirm={(e) => handleSendCategoryData(e, true)}
				/>
			}
		</div>
	)
}

CategoryGamesModalHeader.propTypes = {
	currentCategory: PropTypes.object,
	onSendCategoryData: PropTypes.func,
	onChangeSearchValue: PropTypes.func,
	gamesCount: PropTypes.string,
	siteMode: PropTypes.bool,
}

CategoryGamesModalHeader.defaultProps = {
	currentCategory: null,
	onSendCategoryData: () => {},
	onChangeSearchValue: () => {},
	gamesCount: 0,
	siteMode: false,
}

export default CategoryGamesModalHeader
