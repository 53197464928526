import moment from "moment";
import {DATE_FORMAT} from "../common/utils/common";
import i18n from "i18next";

function filterCategory(url) {
    if (url.slice(1) === 'player-list') {
        return "player";
    }
    if (url.slice(1) === 'transactions') {
        return "payment";
    }
    if (url.slice(1) === 'withdrawals') {
        return "withdrawal";
    }
    if (url.slice(1) === 'pending-withdrawals') {
        return "pending_withdrawal";
    }
    return '';
}

function filterCategoryName(filterCategory) {
    if (filterCategory === "player") {
        return i18n.t("crm.playerList");
    }
    if (filterCategory === 'payment') {
        return i18n.t("crm.deposits");
    }
    if (filterCategory === 'withdrawal') {
        return i18n.t("crm.withdrawals");
    }
    if (filterCategory === 'pending_withdrawal') {
        return i18n.t("crm.pendingWithdrawals");
    }
    if (filterCategory === 'pending_withdrawal_for_payment') {
        return i18n.t("crm.pendingWithdrawals");
    }
    return '';
}

function redirectFilterCategory(nameFilter) {
    if (nameFilter === "player") {
        return "/player-list";
    }
    if (nameFilter === 'payment') {
        return "/deposits";
    }
    if (nameFilter === 'withdrawal') {
        return "/withdrawals";
    }
    if (nameFilter === 'pending_withdrawal') {
        return "/pending_withdrawals";
    }
    if (nameFilter === 'pending_withdrawal_for_payment') {
        return "/pending_withdrawal_for_payment";
    }
    return '';
}

const valueBetweenDate = (value) => {
    return  Object.values(value)
        .reverse()
        .reduce((previousValue, currentValue, index) => {
            const time = moment(new Date(`${currentValue}`)).format(DATE_FORMAT);
            if (index === 0) {
                return previousValue + 'from ' + time
            } else {
                return previousValue + ' to ' + time;
            }
        }, '')
}

export {
    filterCategory,
    redirectFilterCategory,
    filterCategoryName,
    valueBetweenDate
}