import {
    GET_STATES_AND_CITIES,
    GET_STATES_AND_CITIES_ERROR,
    GET_STATES_AND_CITIES_SUCCESS,
    PUT_CURRENT_CITIES
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    states: [],
    cities: [],
    currentCities: [],
    error: {}
};

const StatesAndCities = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_STATES_AND_CITIES:
            return { ...state, isLoading: true };
        case PUT_CURRENT_CITIES:
            return {
                ...state,
                ...action.payload
            };
        case GET_STATES_AND_CITIES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };

        case GET_STATES_AND_CITIES_ERROR:
            return {
                ...state,
                error: action.payload
            };

        default:
            return { ...state };
    }
};

export default StatesAndCities;
