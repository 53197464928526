import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useCallback } from "react";

function MuiSelect(props) {
    const {
        labelId,
        fullWidth = false,
        id,
        value,
        onChange,
        label,
        disabled,
        error,
        options,
        displayOnly,
    } = props;

    const handleChange_ = useCallback(
        e => {
            onChange(e.target.value);
        },
        [onChange]
    );


    const style = {
        style: {
            ...(disabled && { color: '#20262d' }),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            minWidth: '195px',
            marginTop: '6px',
        },
        wrapper: {
            position: 'relative',
        },
        label: {
            marginLeft: '12px',
            fontSize: '11px',
            color: error
                ? '#d23f2f'
                : disabled
                    ? 'rgba(0, 0, 0, 0.38)'
                    : '#495057'
        },
        nativeInput: {
            '& .MuiSelect-nativeInput': {
                fontWeight: 300,
                fontFamily: 'inherit',
                border: 'none',
                opacity: 1,
                top: '9px'
            },
            '& .MuiSelect-icon': {
                position: 'relative',
                top: '0px',
            },
        },
    };

    const MenuProps = {
        MenuListProps: {
            sx: {
                "li.MuiButtonBase-root": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "baseline",
                    padding: '6px 16px',
                    ...(displayOnly ? {
                        background: '#fff',
                        pointerEvents: 'none',
                    } : {}),
                },
            },
        },
    };

    return (
        <div style={style.wrapper} className={'wrapperMultiSelect'}>
            <div
                style={fullWidth ? { width: '100%' } : {}}
                className={`fieldContainer multiSelect ${
                    disabled ? 'disabledField' : ''
                }`}
            >
                {label && (
                    <InputLabel style={style.label}>
                        {label}
                    </InputLabel>
                )}
                <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120 }}
                    style={fullWidth ? { width: '100%' } : {}}
                >
                    <Select
                        labelId={labelId}
                        id={id}
                        value={value}
                        variant="standard"
                        disabled={disabled}
                        onChange={handleChange_}
                        label={label}
                        sx={{
                            maxWidth: '320px',
                            ...style.nativeInput,
                        }}
                        MenuProps={MenuProps}
                    >
                        {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.content}
                            </MenuItem>
                        ))}
                    </Select>
                    {error && (
                        <span
                            style={{
                                color: '#d32f2f',
                                fontSize: '13px',
                                marginTop: '3px'
                            }}
                        >
                            {error}
                        </span>
                    )}
                </FormControl>
            </div>
        </div>
    );
}

export default React.memo(MuiSelect);