import {
    GET_GGR_WIDGET,
    GET_GGR_WIDGET_FAIL,
    GET_GGR_WIDGET_SUCCESS,
    GET_NEW_CLIENTS_WIDGET,
    GET_NEW_CLIENTS_WIDGET_FAIL,
    GET_NEW_CLIENTS_WIDGET_SUCCESS,
    GET_TRANSACTIONS_WIDGET,
    GET_TRANSACTIONS_WIDGET_FAIL,
    GET_TRANSACTIONS_WIDGET_SUCCESS,
    GET_VERIFIED_WIDGET,
    GET_VERIFIED_WIDGET_FAIL,
    GET_VERIFIED_WIDGET_SUCCESS,
    RESET_OVERVIEW_ERRORS
} from './actionTypes';

const INIT_STATE = {
    isLoadingNewClients: false,
    newClients: null,

    isLoadingMarginLevel: false,
    marginLevel: null,

    isLoadingTransactions: false,
    transactions: null,

    isLoadingVerified: false,
    verified: null,

    error: null
};

const Overview = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEW_CLIENTS_WIDGET:
            return { ...state, isLoadingNewClients: true, error: null };

        case GET_NEW_CLIENTS_WIDGET_SUCCESS:
            return {
                ...state,
                newClients: action.payload,
                isLoadingNewClients: false
            };

        case GET_NEW_CLIENTS_WIDGET_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingNewClients: false
            };

        case GET_GGR_WIDGET:
            return { ...state, isLoadingMarginLevel: true, error: null };

        case GET_GGR_WIDGET_SUCCESS:
            return {
                ...state,
                marginLevel: action.payload,
                isLoadingMarginLevel: false
            };

        case GET_GGR_WIDGET_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingMarginLevel: false
            };

        case GET_TRANSACTIONS_WIDGET:
            return { ...state, isLoadingTransactions: true, error: null };

        case GET_TRANSACTIONS_WIDGET_SUCCESS:
            return {
                ...state,
                transactions: action.payload,
                isLoadingTransactions: false
            };

        case GET_TRANSACTIONS_WIDGET_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingTransactions: false
            };

        case GET_VERIFIED_WIDGET:
            return { ...state, isLoadingVerified: true, error: null };

        case GET_VERIFIED_WIDGET_SUCCESS:
            return {
                ...state,
                verified: action.payload,
                isLoadingVerified: false
            };

        case GET_VERIFIED_WIDGET_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingVerified: false
            };

        case RESET_OVERVIEW_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default Overview;
