export const GET_PLAYER_ATTRIBUTION_LIST =
    'GET_PLAYER_ATTRIBUTION_LIST';

export const GET_PLAYER_ATTRIBUTION_LIST_SUCCESS =
    'GET_PLAYER_ATTRIBUTION_LIST_SUCCESS';

export const GET_PLAYER_ATTRIBUTION_LIST_FAIL =
    'GET_PLAYER_ATTRIBUTION_LIST_FAIL';

export const RESET_PLAYER_ATTRIBUTION_LIST_ERRORS =
    'RESET_PLAYER_ATTRIBUTION_LIST_ERRORS';
