import React, { useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import clsx from "clsx";
import { getGuidShortFormat, getColumnSettingsLsKey, initTableColumns } from '../../../common/utils/common';
import Formatter from '../../../utils/Formatter';
import NewCustomTable from '../../../components/NewCustomTable';
import CopyButton from '../../../common/cellData/CopyButton';
import PropTypes from 'prop-types';
import CheckboxInput from '../../../common/inputs/common/CheckboxInput';
import useTableCheckbox from '../../../components/NewCustomTable/useTableCheckbox';
import { PAYMENT_STATUS } from '../../../constants/status';
import KycView from "../components/KycView";
import PaymentStatusView from "../../../components/Status/PaymentStatusView";
import { useTranslation } from "react-i18next";
import { useTimeContext } from "../../../context/TimeContext";
import { IdCell, PlayerCell } from "../../../components/NewCustomTable/CellsWithPermissions";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import { COLUMN_IDS, getDefaultColumns } from "../../../constants/tableSettings";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import PlayerData from 'common/cellData/PlayerData';

export const WITHDRAWALS_SORTED_FIELDS = {
    wdNumber: 'id',
    player: 'player',
    createdAt: 'createdAt',
    amount: 'amount',
    totalPaid: 'totalAmount',
    fees: 'taxAmount',
    currency: 'currency',
    status: 'status',
    lastUpdated: 'lastChangedAt',
};

const getAllColumns = () => {
    return [
        COLUMN_IDS.wdNumber,
        COLUMN_IDS.player,
        COLUMN_IDS.playerOID,
        COLUMN_IDS.txnOID,
        COLUMN_IDS.createdAt,
        COLUMN_IDS.userIp,
        COLUMN_IDS.PSP,
        COLUMN_IDS.PM,
        COLUMN_IDS.amount,
        COLUMN_IDS.totalPaid,
        COLUMN_IDS.currency,
        COLUMN_IDS.fees,
        COLUMN_IDS.KYC,
        COLUMN_IDS.status,
        COLUMN_IDS.lastUpdated,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.wdNumber]: 'finance.wdNumber',
    [COLUMN_IDS.player]: 'finance.player',
    [COLUMN_IDS.playerOID]: 'finance.playerOid',
    [COLUMN_IDS.txnOID]: 'finance.txnOID',
    [COLUMN_IDS.createdAt]: 'finance.createdAt',
    [COLUMN_IDS.userIp]: 'finance.userIp',
    [COLUMN_IDS.PSP]: 'finance.PSP',
    [COLUMN_IDS.PM]: 'finance.PM',
    [COLUMN_IDS.amount]: 'finance.amount',
    [COLUMN_IDS.totalPaid]: 'finance.totalPaid',
    [COLUMN_IDS.currency]: 'finance.currency',
    [COLUMN_IDS.fees]: 'finance.fees',
    [COLUMN_IDS.KYC]: 'finance.KYC',
    [COLUMN_IDS.status]: 'finance.status',
    [COLUMN_IDS.lastUpdated]: 'finance.lastUpdated',
};

const TdElem = ({
    item,
    field,
    timeFormat,
    i18n,
    isChecked = false,
    handleSelectRow = () => {},
}) => {
    const size = useWindowSize();

    switch (field) {
        case COLUMN_IDS.tdForCheckBox:
            return <td className={'tdForCheckBox'}>
                {size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <CheckboxInput
                        wrapperClassName="check-box-aml-unset"
                        checked={isChecked}
                        onChange={handleSelectRow(item.id)}
                        wrapperClassName={'wrapperCheckBok'}
                    />
                )}
            </td>;
        case COLUMN_IDS.wdNumber:
            return <td className='is-visible'>
               <div className='cell-row'>
                    <Link to={item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL ?
                        `/withdrawals-pending-approve/${item.id}` :
                        `/withdrawals-pending-transfer/${item.id}`}>
                        {getGuidShortFormat(item.id)}
                    </Link>
                    &nbsp;&nbsp;
                    <CopyButton
                        className="column-cpy-button"
                        onClick={() =>
                            navigator.clipboard.writeText(item.id)
                        }
                    />
                    {
                        size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <PaymentStatusView status={item.status}/>
                        )
                    }
               </div>
            </td>;
        case COLUMN_IDS.player:
            return <td style={{ overflow: 'visible' }} className='title__top'>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <Link to={item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL ?
                        `/withdrawals-pending-approve/${item.id}` :
                        `/withdrawals/${item.id}`}>
                        <PlayerData player={item.player} />
                    </Link>
                ) : (
                    <PlayerCell
                        item={item.player}
                        url={{
                            id: item.player.oid
                        }}
                    />
                )}
            </td>;
        case COLUMN_IDS.playerOID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.playerOid')}: </span>
                )}
                <IdCell
                    item={item.player}
                    url={{
                        type: 'finance',
                        id: item.player.oid
                    }}
                />
            </td>;
        case COLUMN_IDS.txnOID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.txnOID')}: </span>
                )}
                <IdCell
                    item={item}
                    url={{
                        type: 'finance',
                        id: item.player.oid
                    }}
                />
            </td>;
        case COLUMN_IDS.createdAt:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.createdAt')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>;
        case COLUMN_IDS.userIp:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.userIp')}: </span>
                )}
                {item?.userIp || "-"}
            </td>;
        case COLUMN_IDS.PSP:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.PSP')}: </span>
                )}
                {item?.provider.name || "-"}
                </td>;
        case COLUMN_IDS.PM:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.PM')}: </span>
                )}
                {item?.method || "-"}
                </td>;
        case COLUMN_IDS.amount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.amount')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.amount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.totalPaid:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalPaid')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.totalAmount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.currency:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.currency')}: </span>
                )}
                {item.currency}
            </td>;
        case COLUMN_IDS.fees:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.fees')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.taxAmount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.KYC:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.kyc')}: </span>
                )}
                <KycView kyc={item.kyc} />
            </td>;
        case COLUMN_IDS.status:
            return (
                size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <td className={"halo text-nowrap"}>
                        <PaymentStatusView status={item.status}/>
                    </td>
                )
            );
        case COLUMN_IDS.lastUpdated:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.lastUpdated')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.updatedAt))}
            </td>;
        default:
            return null;
    }
};

function PendingWithdrawalsTable({
    list,
    isLoading,
    selectedRows,
    setSelectedRows,
    isShowTableSettings,
    onCloseTableSettings,
    onSortWithdrawals,
    match,
}) {
    const { i18n } = useTranslation();
    const { timeFormat } = useTimeContext();

    const { handleSelectAll, handleSelectRow } = useTableCheckbox(
        list ? list.map(item => item.id) : [],
        selectedRows,
        setSelectedRows
    );

    const allColumns = useMemo(() => getAllColumns(), []);
    const defaultColumns = useMemo(() => getDefaultColumns('pendingWithdrawals', allColumns), []);

    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getAllColumns());
    const [activeRow, setActiveRow] = useState(false);

    useEffect(() => {
        initTableColumns(match.path, getAllColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const columnsData = [
            {
                custom: (
                    <th className={'thForCheckBox'}>
                        <CheckboxInput
                            wrapperClassName="check-box-aml-unset"
                            checked={list?.length === selectedRows?.length}
                            onChange={handleSelectAll}
                            wrapperClassName={'wrapperCheckBok'}
                        />
                    </th>
                )
            },
            ...activeColumns.map((key) => ({
                label: i18n.t(KEY_I18N_MAP[COLUMN_IDS[key]]),
                id: COLUMN_IDS[key],
                sorted: key in WITHDRAWALS_SORTED_FIELDS,
            })),
        ];
        setColumns(columnsData);
    }, [list, selectedRows, i18n.language, activeColumns]);

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <NewCustomTable
                isHeader={true}
                columns={columns}
                onSortItems={onSortWithdrawals}
                sortAfterMount={false}
                isLoading={isLoading}
                className='responsiveTable responsiveTable__xl'
            >
                {list &&
                    list.map((item, index) => {
                        const isChecked = selectedRows.includes(item.id);
                        return (
                            <tr
                                key={index}
                                className={clsx(`font-w-500 ${isChecked ? 'checked' : ''}`, {open: activeRow === index})}
                                onClick={() => handleToggle(index)}
                            >
                                <TdElem
                                    key={`tdForCheckBox_${index}`}
                                    item={item}
                                    field={COLUMN_IDS.tdForCheckBox}
                                    timeFormat={timeFormat}
                                    isChecked={isChecked}
                                    handleSelectRow={handleSelectRow}
                                    i18n={i18n}
                                />
                                {activeColumns.map((column) => <TdElem
                                    key={`${column}_${index}`}
                                    item={item}
                                    field={column}
                                    timeFormat={timeFormat}
                                    i18n={i18n}
                                />)}
                            </tr>
                        );
                    })}
            </NewCustomTable>
            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={allColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    );
}

PendingWithdrawalsTable.propTypes = {
    list: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
    onSortWithdrawals: PropTypes.func,
};

PendingWithdrawalsTable.defaultProps = {
    onCloseTableSettings: () => {},
    onSortWithdrawals: () => {},
};

export default React.memo(withRouter(PendingWithdrawalsTable));
