import {
  GET_PENDING_WITHDRAWAL_LIST,
  GET_PENDING_WITHDRAWAL_LIST_SUCCESS,
  GET_PENDING_WITHDRAWAL_LIST_FAIL,
  GET_PENDING_WITHDRAWAL_COUNT,
  GET_PENDING_WITHDRAWAL_COUNT_SUCCESS,
  GET_PENDING_WITHDRAWAL_COUNT_FAIL,
  RESET_PENDING_WITHDRAWAL_LIST_ALERTS_ERRORS,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST_SUCCESS,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST_FAIL,
} from './actionTypes';
import {FILTER_WD_PENDING_FOR_APPROVAL} from "../../../constants/Filter";

export const getPendingWithdrawalListCount = headers => ({
  type: GET_PENDING_WITHDRAWAL_COUNT,
  payload: {
    headers,
    filter: {
      filterDynamic: FILTER_WD_PENDING_FOR_APPROVAL
    }
  }
});

export const getPendingWithdrawalListCountSuccess = payload => ({
  type: GET_PENDING_WITHDRAWAL_COUNT_SUCCESS,
  payload
});

export const getPendingWithdrawalListCountFail = payload => ({
  type: GET_PENDING_WITHDRAWAL_COUNT_FAIL,
  payload
});

export const getPendingWithdrawalList = payload => ({
  type: GET_PENDING_WITHDRAWAL_LIST,
  payload
});

export const getPendingWithdrawalListSuccess = payload => ({
  type: GET_PENDING_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const getPendingWithdrawalListFail = payload => ({
  type: GET_PENDING_WITHDRAWAL_LIST_FAIL,
  payload
});

export const resetPendingWithdrawalListAlertsErrors = payload => ({
  type: RESET_PENDING_WITHDRAWAL_LIST_ALERTS_ERRORS,
  payload
});

export const downloadPendingWithdrawalList = payload => ({
  type: DOWNLOAD_PENDING_WITHDRAWAL_LIST,
  payload
});

export const downloadPendingWithdrawalListSuccess = payload => ({
  type: DOWNLOAD_PENDING_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const downloadPendingWithdrawalListFail = payload => ({
  type: DOWNLOAD_PENDING_WITHDRAWAL_LIST_FAIL,
  payload
});
