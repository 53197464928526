import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";

import ButtonsPanel from "./common/ButtonsPanel"
import AutomationContext from "./context/AutomationContext"
import { RouterPrompt } from "../../common/prompt/RouterPrompt"
import AppDatePicker from "../../common/inputs/common/AppDatePicker"
import group from "../../assets/images/common/information (1) 1.svg"
import TextInput from "../../common/inputs/TextInput"
import DragFileInput from "../../common/inputs/common/DragFileInput"
import {
  DepositWageringType,
  PROMOTION_ENTER_CONDITION,
  PROMOTION_STATUS,
  PROMOTION_STATUS_ARR,
  PROMOTION_TYPE,
  REWARD_TYPE,
} from "./common/promotionConstants"
import PromotionTypeSelect from "./common/PromotionTypeSelect";
import WageringTypeSelect from "./common/WageringTypeSelect";
import NewCustomTable from "../../components/NewCustomTable";
import {
  columns,
  configurationTable_BONUS_WAGERING,
  configurationTable_BONUS_WAGERING_ON_DEPOSIT,
  configurationTableDataMockFreeSpin,
  nameConfiguration,
} from "./ConfigurationTable";
import RewardSelect from "./common/RewardSelect";
import TooltipMUI from "../../UI/tooltip/TooltipMUI";
import { withRouter } from "react-router-dom";
import { createPromotion, updatePromotion } from "../../store/promotions/actions";
import { getGameProviders, getGames } from "../../store/games/actions";
import { SELECT_CATEGORIES } from "../../constants/selectCategories";
import ClipboardButton from "../../common/cellData/CopyButton";
import ACommonDataField from "../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../constants/aCommonDataField";
import { fieldsForCentRestrictions, SITE_PERMISSION } from "../../common/constants/common";
import { getGuidShortFormat, getJSONFromExcelData } from "../../common/utils/common";
import useAlertService from "../../hooks/useAlertService";
import prepareRequestData from "./prepareRequestData";
import useHandleEsc from "../../common/hooks/useHandleEsc";
import { useTranslation } from "react-i18next";
import MultiSelect from "../../UI/multiSelect/MultiSelect";
import SelectInput from "../../common/inputs/common/SelectInput";
import useProviders from "../../hooks/useProviders";
import TableBigModal from "../Content/TableBigModal";
import CategoryGamesModalHeader from "../Content/components/CategoryGamesModalHeader";
import { PROMOTION_MAX_MONEY_VALUE_REG_EXP } from "../../constants/validations";
import { fetchBrandSettingsPage } from "../../store/brand/brandPage/actions";
import Loader from "../../common/components/Loader";
import { useTimeContext } from "../../context/TimeContext";
import Formatter from "../../utils/Formatter";
import { SITE_BRAND } from "../../constants/siteBrands";
import { XLSXformat } from "../../common/inputs/common/FileTypes/fileTypes";
import PromotionsModalColumns from "../Content/components/PromotionsModalColumns";
import DisabledGamesModal from "./DisabledGamesModal/DisabledGamesModal"

const createInitData = () => ({
  schedule: {},
  settings: {
    type: PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT,
    configuration: {},
  },
  segmentation: {},
})

const createInitDataNotSpins = () => ({
  schedule: {},
  settings: {
    type: PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT,
    configuration: {},
  },
  segmentation: {},
})

const initialStateTimePromo = {
  startTime: '',
  endTime: '',
}

const MAX_GAMES_PER_PROMO = 100;

const getValuesOfTable = (nameOfRowTable, values = {}) => Object.keys(values)
      .reduce((previousValue = {}, currentCurrency) => {
        previousValue[`${nameOfRowTable}_${currentCurrency}`] = values[currentCurrency];
        return previousValue;
      }, {});

function PromotionCard({
  data,
  isNew,
  updatePromotion,
  createPromotion,
  isUpdating,
  playerList,
  getGames,
  games,
  isLoadingGames,
  bonusPercentage = 1,
}) {
  const { i18n } = useTranslation();
  const alertService = useAlertService();
  const dispatch = useDispatch();
  const { timeFormat } = useTimeContext();

  const [isEdit, setIsEdit] = useState(false);
  const [canEditPromotion, setCanEditPromotion] = useState(false);
  const [isDisabledAll, setDisabledAll] = useState(false);
  const [isDisabledSaveButtons, setIsDisabledSaveButtons] = useState(false);
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [listProviders, setListProviders] = useState([]);
  const [gamesModalVisibility, setGamesModalVisibility] = useState(false);
  const [isShowDisabledGamesModal, setIsShowDisabledGamesModal] = useState(false);

  const { providers } = useProviders(true);

  const {
    active: activeBrand,
  } = useSelector(state => state.Brand);
  const {
    bonusWageringSmallData,
  } = useSelector(state => state.Promotions);
  const {
    data: brandData,
  } = useSelector(state => state.BrandSettingsPage);
  const {
    agentSelf
  } = useSelector(state => state.AgentSelf);

  const agentPermissions = agentSelf?.permissions || [];
  const isShowSlugLink = activeBrand?.siteName !== SITE_BRAND.WINPOTMX;

  const {
    createdAt,
    lastChangedAt,
    id,
    name,
    oid,
    site,
    status,
    schedule,
    settings,
    segmentation,
  } = useMemo(() => isNew ? createInitData() : data, [data, isNew])

  useHandleEsc(() => setShowMenu(false));

  const [timePromo, setTimePromo] = useState(initialStateTimePromo);
  const [playerIds, setPlayerIds] =
      useState(playerList?.map(player => player.id) || []);
  const [gameIds, setGameIds] =
      useState(games?.result?.map(game => game.id) || []);
  const [configuration, setConfiguration] = useState([]);
  const [bonusWageringOptions, setBonusWageringOptions] = useState([]);
  const [initialCurrencies, setInitialCurrencies] = useState([]);

  useEffect(() => { dispatch(fetchBrandSettingsPage()) }, []);

  useEffect(() => {
    setInitialCurrencies(brandData.configurationFinancial.currencies);
  }, [brandData])

  useEffect(() => {
    const filteredProviders = providers.filter(provider => provider.siteId === activeBrand?.id);
    const mappedProviders = filteredProviders.map(({ content }) => ({ value: content, label: content }));

    setListProviders(mappedProviders);
  }, [activeBrand, providers]);


  const initialState = useMemo(() => ({
    model: {
      name: 'New promotion',
      status: PROMOTION_STATUS.PENDING,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,

      currencies: initialCurrencies,
      provider: [],
      enterCondition: [PROMOTION_ENTER_CONDITION.Workflow],
      attributions: [],
      listOfGames: [],
      listOfPlayers: [],

      promotionType: PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT,
      days_expiration: 1,
      max_winnings_FS: 1,
      bucketLocation: '',
      templateCode: '',

      postDepositWageringRequired: false,
      wageringType: DepositWageringType.Deposit_Bonus,
      wageringMultiplier: 1,
      bonusLimitMultiplier: '',
      nonRedeemableBonus: false,
      optIn: false,
      allowMultipleOptIn: false,
      bonusPercentage: +bonusPercentage,
      rewardType: REWARD_TYPE.CashMoney,
      funBonus: null,
      slugLink: '',
      agentName: '',
    }
  }), []);

  useEffect(() => {
    if (bonusWageringSmallData) {
      setBonusWageringOptions(
          bonusWageringSmallData.map(item => ({value: item.id, label: item.name}))
      );
    }
  }, [bonusWageringSmallData])

  const originalState = useMemo(() => {
    const configurationOfSetting = settings?.configuration || {};

    const {
      wageringType,
      wageringMultiplier,
      bonusLimitMultiplier,
      nonRedeemableBonus,
      optInRequired,
      bonusPercentage,
      amount,
      bonusLimit,
      maxWageringAmount,
      maxWinPerSpin,
      numberOfSpins,
      betExposureMultiplier,
      rewards,
      depositInitialMaxAmount,
      redeemableThreshold,
      spinWorth,
      templateCode,
      postDepositWageringRequired,
      provider,
      allowMultipleOptIn,
      funBonus,
      externalCampaignId,
    } = configurationOfSetting;

    const promotionType = settings?.type;

    const rewardsData = Array.isArray(rewards) ? rewards?.[0] : rewards;
    const rewardType = typeof rewardsData === 'string'
      ? rewardsData
      : rewardsData?.type || REWARD_TYPE.CashMoney;

    return ({
      model: {
        createdAt,
        lastChangedAt,
        id,
        name,
        oid,
        site,
        status,
        agentName: data?.agentName,
        slugLink: data?.slugLink,

        timeZone: schedule?.timeZone,

        currencies: segmentation?.currencies,
        enterCondition: Array.isArray(segmentation?.enterCondition)
            ? segmentation?.enterCondition
            : [segmentation?.enterCondition],
        listOfGames: Array.isArray(segmentation?.games)
            ? segmentation?.games
            : [segmentation?.games],
        listOfPlayers: Array.isArray(segmentation?.players)
            ? segmentation?.players
            : [segmentation?.players],

        promotionType,
        days_expiration: settings?.validityDays || 1,
        optIn: typeof optInRequired === "boolean" ? optInRequired : (optInRequired !== 'No'),
        allowMultipleOptIn: allowMultipleOptIn ?? false,

        ...getValuesOfTable(nameConfiguration.MaxWinPerSpin, maxWinPerSpin),

        // Format for conditional fields:
        // ...( condition && { ...fields } || {} ),
        ...((promotionType === PROMOTION_TYPE.FREE_SPINS && {
          attributions: Array.isArray(segmentation?.attributions)
              ? segmentation?.attributions
              : [segmentation?.attributions],

          provider,
          externalCampaignId,
          templateCode,
          numberOfSpins: +numberOfSpins || 1,
          betExposureMultiplier: +betExposureMultiplier || 1,
          rewardType,
          rewardPromoId: rewardsData?.configuration?.promotionId || '',
          ...getValuesOfTable(nameConfiguration.FreeSpinsWorth, spinWorth),
          ...getValuesOfTable(nameConfiguration.BonusLimitFreeSpins, bonusLimit),
        }) || {}),

        ...((promotionType !== PROMOTION_TYPE.FREE_SPINS && {
          postDepositWageringRequired,
          wageringMultiplier,
          nonRedeemableBonus,
          bonusLimitMultiplier: bonusLimitMultiplier,
          ...getValuesOfTable(nameConfiguration.MaxWinningLimit,
              (bonusLimit && Object.keys(bonusLimit).length > 0) || bonusLimitMultiplier !== ''
                  ? bonusLimit
                  : (segmentation?.currencies || []).reduce((prevValue, currency) => {
                    prevValue[currency] = 0;
                    return prevValue;
                  }, {})),
          ...getValuesOfTable(nameConfiguration.MaxContributionPerSpin, maxWageringAmount),
          ...getValuesOfTable(nameConfiguration.RedeemableThreshold, redeemableThreshold),
        }) || {}),

        ...((promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT && {
          wageringType,
          bonusPercentage: +bonusPercentage,
          ...getValuesOfTable(nameConfiguration.BonusLimit, depositInitialMaxAmount),
        }) || {}),

        ...((promotionType === PROMOTION_TYPE.BONUS_WAGERING && {
          ...getValuesOfTable(nameConfiguration.FreeMoneyAmount, amount),
          funBonus,
        }) || {}),
      },
    })
  }, [data, isNew]);

  useEffect(() => {
    setTimePromo({
      startTime: schedule.startAt,
      endTime: schedule.finishAt,
    });
  }, []);

  const [state, setState] = useState(isNew
      ? initialState
      : originalState
  );

  useEffect(() => {
    setState((prevState) => {
      const uniqueCurrencies = new Set(segmentation?.currencies || []);
      initialCurrencies?.forEach(currency => uniqueCurrencies.add(currency));

      return {
        ...prevState,
        model: {
          ...prevState?.model,
          currencies: Array.from(uniqueCurrencies),
        },
      };
    });
  }, [initialCurrencies, segmentation]);

  const toggleGamesModalVisibility = () => {
    setGamesModalVisibility(!gamesModalVisibility);
  }

  const toggleDisabledGamesModal = () => {
    setIsShowDisabledGamesModal(!isShowDisabledGamesModal);
  }

  useEffect(() => {
    if (
        state?.model.promotionType === PROMOTION_TYPE.FREE_SPINS &&
        !providers.length
    ) {
      dispatch(getGameProviders())
    }
  }, [state?.model.promotionType, providers.length])

  const [promotionType, setPromotionType] = useState(PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT);
  const [invalidGameIds, setInvalidGameIds] = useState([]);

  useEffect(() => {
    if (data?.segmentation && gameIds) {
      const invalidGamesIds = data.segmentation.games.filter(gameId => !gameIds.includes(gameId));
      setInvalidGameIds(invalidGamesIds);
    }
  }, [data?.segmentation , gameIds])

  useEffect(() => {
    getGames();
  }, []);

  const providerNotSelected = promotionType === PROMOTION_TYPE.FREE_SPINS && !state?.model?.provider?.length;

  useEffect(() => {
    const canEditPromotion = agentPermissions.includes(SITE_PERMISSION.Promotion__Edit_Promotions);
    setCanEditPromotion(canEditPromotion);
    setDisabledAll(!canEditPromotion || state?.model?.status !== PROMOTION_STATUS.PENDING);
  }, [agentPermissions, state?.model?.status])

  useEffect(() => {
    setPromotionType(state?.model?.promotionType ?? PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT)
  }, [state?.model?.promotionType])

  useEffect(() => {
    setPlayerIds(playerList?.map(player => player.id) || []);
  }, [playerList]);

  useEffect(() => {
    setGameIds(games?.result?.map(game => game.id) || []);
  }, [games]);

  const handleSave = useCallback(() => {
    const promotionInitData = (state?.model?.promotionType === PROMOTION_TYPE.FREE_SPINS)
        ? createInitData()
        : createInitDataNotSpins();

    const preparedData = prepareRequestData(
        promotionInitData,
        state?.model,
        timePromo,
    );

    const isBonusWageringType = promotionType === PROMOTION_TYPE.BONUS_WAGERING
      || promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT;
    const bonusLimitValues = Object.values(preparedData.settings?.configuration?.bonusLimit);
    const isBonusLimitEmptyOrUndefined = !bonusLimitValues.length
      || bonusLimitValues.includes(undefined);
    const isBonusLimitMultiplierEmpty = !preparedData.settings?.configuration?.bonusLimitMultiplier;

    // Limit of games per promo
    if (state?.model?.listOfGames.length > MAX_GAMES_PER_PROMO) {
      return alertService.showError(i18n.t('promotions.maxGamesInPromo'));
    }

    if (!state?.model?.provider?.length && state?.model?.promotionType === PROMOTION_TYPE.FREE_SPINS) {
      return alertService.showError(i18n.t('crm.chooseProvider'));
    }

    // if Redeemable Threshold field is empty -> throw error message
    if (state?.model?.promotionType !== PROMOTION_TYPE.FREE_SPINS &&
        Object.values(preparedData.settings.configuration.redeemableThreshold)
            .filter(value => value === undefined).length > 0) {
      return alertService.showError(i18n.t('promotions.requiredRedeemableThreshold'));
    }

    if (preparedData.segmentation.currencies.length === 0) {
      return alertService.showError(i18n.t('promotions.currencyIsRequired'));
    }

    if (
        preparedData.settings.validityDays === 0 ||
        preparedData.settings.configuration.bonusPercentage === 0 ||
        preparedData.settings.configuration.wageringMultiplier === 0
    ) {
      return alertService.showError(i18n.t('promotions.requiredFields'));
    }

    if (promotionType === PROMOTION_TYPE.FREE_SPINS && preparedData?.settings?.configuration) {
      const spinWorthObj = preparedData?.settings?.configuration?.spinWorth;
      const hasValueGreaterThanZero = Object.values(spinWorthObj).some(value => value > 0);
      if (!hasValueGreaterThanZero) {
        return alertService.showError(i18n.t('promotions.missedCoinsValues'));
      }
    }

    if (isBonusWageringType && isBonusLimitEmptyOrUndefined && isBonusLimitMultiplierEmpty) {
      return alertService.showError(i18n.t('promotions.limitFieldsRequired'));
    }

    // if not -> continue Create/Edit of promotion
    if (isNew) {
      createPromotion({ data: preparedData });
    } else {
      updatePromotion({ promotionId: data.id, data: preparedData });
    }

    setIsEdit(false);
  }, [state?.model, timePromo, promotionType]);


  const handleCancel = useCallback(() => {
    const currentState = isNew ? initialState : originalState;
    setState(currentState);
    setTimePromo({
      startTime: schedule.startAt,
        endTime: schedule.finishAt,
    })
    setIsEdit(false);
  },[data]);

  const handleChange = useCallback((name) => {
    return (value) => {
      if (name === 'bonusLimitMultiplier' || name.includes('Bonus Limit')) {
        if (value >= 1) {
          setState((prevState) => ({
            model: {
              ...prevState?.model,
              [name]: +value,
            },
          }))
        } else if (value < 1) {
          setState((prevState) => ({
            model: {
              ...prevState?.model,
              [name]: '',
            },
          }))
        }
      } else if (name === 'funBonus') {
        const changedState = {};
        if (value) {
          state?.model?.currencies?.forEach((currency) => {
            changedState[`Bonus Limit_${currency}`] = 0;
          });
        }

        setState(prevState => ({
          ...prevState,
          model: {
            ...prevState?.model,
            ...changedState,
            [name]: value,
            bonusLimitMultiplier: '',
          },
        }));
      }
      else {
        if (fieldsForCentRestrictions(name)) {
          value = value.replace(PROMOTION_MAX_MONEY_VALUE_REG_EXP, '$1').replace(',' , '.');
        }
        setState(prevState => ({
          ...prevState,
          model: {
            ...prevState?.model,
            [name]: value,
          },
        }));
      }
      setIsEdit(true);
    }
  }, [state?.model?.currencies, state?.model?.listOfGames]);

  useEffect(() => {
    const initialUniqueCurr = new Set(initialCurrencies?.concat(segmentation.currencies));
    if (!isNew && !state?.model.currencies.length) {
      setState(prevState => ({
        ...prevState,
        model: {
          ...prevState?.model,
          currencies: Array.from(initialUniqueCurr),
        },
      }));
    }
  }, [state?.model, segmentation, initialCurrencies]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      model: {
        ...prevState?.model,
        'funBonus': state?.model.funBonus,
      },
    }));
  }, [promotionType, state?.model.funBonus])

  useEffect(() => {
    if (promotionType === PROMOTION_TYPE.FREE_SPINS &&
        state?.model?.rewardType === REWARD_TYPE.PrizeWagering &&
        !state?.model?.rewardPromoId
    ) {
        setIsDisabledSaveButtons(true);
        setIsWarningShown(true);
    } else {
        setIsDisabledSaveButtons(false);
        setIsWarningShown(false);
    }
  }, [state?.model, promotionType])

  useEffect(() => {
    setIsEdit(false);
  }, [isUpdating]);

  useEffect(() => {
    if (promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT) {
      setConfiguration(configurationTable_BONUS_WAGERING_ON_DEPOSIT(state?.model.currencies))
    }
    if (promotionType === PROMOTION_TYPE.BONUS_WAGERING) {
      setConfiguration(configurationTable_BONUS_WAGERING(state?.model.currencies))
    }
    if (promotionType === PROMOTION_TYPE.FREE_SPINS) {
      setConfiguration(configurationTableDataMockFreeSpin(state?.model.currencies))
    }
  }, [promotionType, state?.model, i18n.language]);

  const parseFileData = (fileData) => {
    const arrayOfLines = getJSONFromExcelData(fileData);
    if (arrayOfLines.length === 0) {
      return [];
    }
    return arrayOfLines
        .map((record) => record.ID || record.id)
        .filter((gameId) => gameIds.includes(gameId));
  };

  const handleUploadFile = useCallback(async (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const fileData = e.target.result;
        const validGameIds = parseFileData(fileData);

        if (validGameIds.length === 0) {
          alertService.showError(i18n.t('promotions.OnlyXlsxAllowed'));
          return;
        }

        handleChange('listOfGames')(validGameIds);
      }
      catch (err) {
        console.error('Wrong file parsing');
        console.error(err);
      }
    };

    // Read file
    reader.readAsArrayBuffer(file);
  }, [gameIds, state?.model?.status]);

  const isBonusLimitDisabled = useMemo(() => {
    return state?.model.promotionType === PROMOTION_TYPE.FREE_SPINS
        ? false
        : !!state?.model.bonusLimitMultiplier || state?.model.funBonus;
  }, [state?.model.bonusLimitMultiplier, state?.model.promotionType, state?.model.funBonus]);

  const isBonusLimitMultiplayerDisabled = useMemo(() => {
    let isDisabled = false;
    state?.model.currencies?.forEach(currency => {
      if (state?.model[`Bonus Limit_${currency}`]) {
        isDisabled = true;
      }
    })
    return isDisabled;
  }, [state?.model]);

  const isPromoPage = location?.pathname?.includes('promotions');

  const tableOfConfiguration = <NewCustomTable
      columns={columns(state?.model.currencies)}
  >
    {configuration
        ?.map(({label, nameConfiguration, tooltipText, currencies}, index) => {
          return (
              <tr key={index}>
                <td className="d-flex align-items-center promo-configuration-item">
                  <TooltipMUI title={tooltipText} wrapperPadding={'0'}/>
                  {label}
                </td>
                {
                  currencies?.map((currency) => {
                    const fieldName = `${nameConfiguration}_${currency}`;
                    const isDisabled = nameConfiguration === 'Bonus Limit' && isBonusLimitDisabled;

                    return <td key={currency}>
                      <TextInput
                        className="form-control form-control-gray fullWidth currencyInput"
                        value={state?.model[fieldName] ?? ''}
                        onChange={handleChange(fieldName)}
                        type={"number"}
                        disabled={isDisabledAll || isDisabled}
                        data-type={label + currency}
                      />
                    </td>
                  })}
              </tr>
          );
        })}
  </NewCustomTable>

  return (
      <AutomationContext.Provider value={{isEdit, setIsEdit}}>
        <Card className="promotion-card">
          <CardBody>
            <div className="promotion-card__sub-content">
              <div className="sub-content__header">
                <img className="me-3" src={group} alt=""/>
                <span className="font-w-500">{i18n.t('promotions.generalInfo')}</span>
              </div>
              <div className="sub-content__content d-flex flex-wrap">
                <div className="d-flex me-5">
                  <div className='grid_desc time'>
                    <div className="font-w-500 me-2">{i18n.t('promotions.createdTime')}:</div>
                  </div>
                  <div className="text-muted grid_valueView">
                    {isNew && '--/--/--'}
                    {!isNew &&
                        <>
                          {Formatter.displayDateTimeMinutes(timeFormat.parseTime(state?.model.createdAt))}
                        </>
                    }
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className='grid_desc time'>
                    <div className="font-w-500 me-2">{i18n.t('promotions.updatedTime')}:</div>
                  </div>
                  <div className="text-muted grid_valueView">
                    {isNew && '--/--/--'}
                    {!isNew &&
                        <>
                          {Formatter.displayDateTimeMinutes(timeFormat.parseTime(state?.model.lastChangedAt))}
                        </>
                    }
                  </div>
                </div>
                {state?.model?.agentName && (
                    <div className="d-flex me-5">
                      <div className='grid_desc time'>
                        <div className="font-w-500 me-2">{i18n.t('promotions.agentName')}:</div>
                      </div>
                      <div className="text-muted grid_valueView">
                        {state?.model.agentName}
                      </div>
                    </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap promotion-card__header gap-2">
              <div className="d-flex me-4 mt-4 align-items-center" style={{width: '348px'}}>
                <div className={'grid_desc info'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t('promotions.promotion_name')}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <TextInput
                      className="form-control form-control-gray"
                        value={state?.model.name}
                        onChange={handleChange('name')}
                        placeholder={i18n.t('promotions.promotion_name')}
                        disabled={isDisabledAll}
                    />
                  </div>
                </div>

                {isShowSlugLink &&
                    <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                      <div className={'grid_desc info'}>
                        <div className="font-w-500 d-flex align-items-center">
                          {i18n.t('promotions.slugLink')}:
                        </div>
                      </div>
                      <div className={'grid_valueView'}>
                        <TextInput
                            className="form-control form-control-gray"
                            value={state?.model?.slugLink}
                            onChange={handleChange('slugLink')}
                            type={'text'}
                        />
                      </div>
                    </div>
                }

                {!isNew &&
                <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                  <div className={'grid_desc info'}>
                    <div className="font-w-500 me-2 d-flex align-items-center">
                      {i18n.t('promotions.promotionID')}:
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <span> {getGuidShortFormat(state?.model.id)} <ClipboardButton text={state?.model.id} /></span>
                  </div>
                </div>}

              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t("promotions.type")}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <PromotionTypeSelect
                      onChange={handleChange('promotionType')}
                      value={state?.model.promotionType}
                      disabled={!canEditPromotion || !isNew}
                  />
                </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.status')}:</span>
                </div>
                  <div className={'grid_valueView'}>
                    <SelectInput
                        options={PROMOTION_STATUS_ARR
                            .map(item => ({ label: i18n.t(`promotion.statuses.${item}`), value: item }))
                        }
                        value={state?.model.status}
                        onChange={handleChange('status')}
                        disabled={!canEditPromotion}
                    />
                  </div>
              </div>

              {state?.model.provider === 'Amusnet' &&
                  <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                    <div className={'grid_desc info'}>
                      <div className="font-w-500 me-2 d-flex align-items-center">
                        {i18n.t("promotions.externalCampaignId")}:
                      </div>
                    </div>
                    <div className={'grid_valueView'}>
                      <TextInput
                          placeholder={i18n.t('promotions.externalCampaignId')}
                          onChange={handleChange('externalCampaignId')}
                          value={state?.model.externalCampaignId || ''}
                          disabled={true}
                      />
                    </div>
                  </div>
              }
            </div>
          </CardBody>
        </Card>
        <Card className="promotion-card">
          <CardBody>
            <div className="sub-content__header pb-0">
              <img className="me-3" src={group} alt=""/>
              <span className="font-w-500">{i18n.t('promotions.definition')}</span>
            </div>
            <div className="d-flex flex-wrap promotion-card__sub-header mb-4">

              <div className="d-flex me-4 mt-4 align-items-center">
                <div className={'grid_desc'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t('promotions.startTime')}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <AppDatePicker
                      name='startTime'
                      onChange={setTimePromo}
                      value={timePromo.startTime}
                      setIsEdit={setIsEdit}
                      disabled={isDisabledAll}
                      allowFutureDate={true}
                  />
                </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center ">
                <div className={'grid_desc'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t('promotions.endTime')}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <AppDatePicker
                      name='endTime'
                      onChange={setTimePromo}
                      value={timePromo.endTime}
                      setIsEdit={setIsEdit}
                      disabled={isDisabledAll}
                      allowFutureDate={true}
                  />
                </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center">
                <div className={'grid_desc'}>
                  <div className="font-w-500 d-flex align-items-center" style={{ width: 'min-content' }}>
                    {i18n.t('promotions.days')}:
                    <TooltipMUI
                        title={i18n.t(promotionType === PROMOTION_TYPE.FREE_SPINS
                            ? 'promotions.days.freeSpinsTooltip'
                            : 'promotions.days.tooltip')}
                    />
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <TextInput
                      className="form-control form-control-gray"
                      value={state?.model.days_expiration}
                      onChange={handleChange('days_expiration')}
                      placeholder={i18n.t('promotions.days_placeholder')}
                      type='number'
                      disabled={isDisabledAll}
                  />
                </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center">
                <div className={'grid_desc'}>
                  <div className="font-w-500 d-flex align-items-center">
                    {i18n.t('promotions.opt_in')}:
                    <TooltipMUI
                        title={i18n.t('promotions.opt_in.tooltip')}
                    />
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <ACommonDataField
                      component={A_COMMON_DATA_FIELD.CHECK_BOX}
                      value={state?.model.optIn}
                      handleChange={handleChange('optIn')}
                      disabled={isDisabledAll}
                      className={'wrapper_checkBox'}
                  />
                </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center">
                <div className={'grid_desc'}>
                  <div className="font-w-500 d-flex align-items-center">
                    {i18n.t("promotions.currencies")}:
                    <TooltipMUI title={i18n.t("promotions.currencies.tooltip")}/>
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <MultiSelect
                      category={SELECT_CATEGORIES.CURRENCIES}
                      values={state?.model.currencies}
                      handleChange={handleChange(SELECT_CATEGORIES.CURRENCIES)}
                      isDisabled={isDisabledAll}
                      className={!isNew && 'restrictDeleteCurrency'}
                  />
                  </div>
                </div>

              {
                promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT &&
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center">
                      {i18n.t('promotions.wageringType')}:
                      <TooltipMUI
                          title={i18n.t('promotions.wageringType.tooltip')}/>
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <WageringTypeSelect
                        onChange={handleChange('wageringType')}
                        value={state?.model.wageringType}
                        disabled={isDisabledAll}
                    />
                  </div>
                </div>
              }

              {
                promotionType !== PROMOTION_TYPE.FREE_SPINS &&
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center" style={{ width: 'min-content'}}>
                      {i18n.t('promotions.wageringMultiplier')}:
                      <TooltipMUI
                          title={i18n.t('promotions.wageringMultiplier.tooltip')}/>
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <TextInput
                        className="form-control form-control-gray"
                        value={state?.model.wageringMultiplier}
                        onChange={handleChange('wageringMultiplier')}
                        type={'number'}
                        max={100}
                        disabled={isDisabledAll}
                    />
                  </div>
                </div>
              }


              {
                promotionType !== PROMOTION_TYPE.FREE_SPINS &&
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center" style={{ width: '140px'}}>
                      {i18n.t('promotions.bonusLimitMultiplier')}:
                      <TooltipMUI
                          title={i18n.t('promotions.bonusLimitMultiplier.tooltip')}/>
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <TextInput
                        className="form-control form-control-gray"
                        value={state?.model.bonusLimitMultiplier}
                        onChange={handleChange('bonusLimitMultiplier')}
                        type='text'
                        disabled={isDisabledAll || isBonusLimitMultiplayerDisabled || state?.model.funBonus}
                    />
                  </div>
                </div>
              }

              {
                promotionType === PROMOTION_TYPE.FREE_SPINS &&
                <>
                  <div className="d-flex me-4 mt-4 align-items-center">
                    <div className={'grid_desc'}>
                      <div className="font-w-500 d-flex align-items-center" style={{ width: '110px'}}>
                        {i18n.t('promotions.numberOfSpins')}:
                        <TooltipMUI
                            title={i18n.t('promotions.numberOfSpins.tooltip')}/>
                      </div>
                    </div>
                    <div className={'grid_valueView'}>
                      <TextInput
                          className="form-control form-control-gray"
                          value={state?.model.numberOfSpins}
                          onChange={handleChange('numberOfSpins')}
                          disabled={isDisabledAll}
                      />
                    </div>
                  </div>
                  <div className="d-flex me-4 mt-4 align-items-center">
                    <div className={'grid_desc'}>
                      <div className="font-w-500 d-flex align-items-center" style={{ width: 'min-content'}}>
                          {i18n.t('promotions.betExposureMultiplier')}:
                        <TooltipMUI
                            title={i18n.t('promotions.betExposureMultiplier.tooltip')}/>
                      </div>
                    </div>
                    <div className={'grid_valueView'}>
                      <TextInput
                          className="form-control form-control-gray"
                          value={state?.model.betExposureMultiplier}
                          onChange={handleChange('betExposureMultiplier')}
                          disabled={isDisabledAll}
                      />
                    </div>
                  </div>
                </>
              }

              {
                promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT &&
                  <div className="d-flex me-4 mt-4 align-items-center">
                    <div className={'grid_desc'}>
                      <div className="font-w-500 d-flex align-items-center" style={{ width: 'min-content'}}>
                        {i18n.t('promotions.bonusPercentage')}:
                        <TooltipMUI
                            title={i18n.t('promotions.bonusPercentage.tooltip')}/>
                      </div>
                    </div>
                    <div className={'grid_valueView'}>
                      <TextInput
                          className="form-control form-control-gray"
                          value={state?.model?.bonusPercentage}
                          onChange={handleChange('bonusPercentage')}
                          type={'number'}
                          disabled={isDisabledAll}
                      />
                    </div>
                  </div>
              }

              {
                promotionType !== PROMOTION_TYPE.FREE_SPINS &&
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center" style={{ width: 'min-content'}}>
                      {i18n.t('promotions.nonRedeemableBonus')}:
                      <TooltipMUI
                          title={i18n.t('promotions.nonRedeemableBonus.tooltip')}
                      />
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <ACommonDataField
                        component={A_COMMON_DATA_FIELD.CHECK_BOX}
                        value={state?.model.nonRedeemableBonus}
                        handleChange={handleChange('nonRedeemableBonus')}
                        disabled={isDisabledAll}
                        className={'wrapper_checkBox'}
                    />
                  </div>
                </div>
              }

              <div className="d-flex me-4 mt-4 align-items-center">
                <div className={'grid_desc'}>
                  <div className="font-w-500 d-flex align-items-center" style={{ width: '136px'}}>
                    {i18n.t('promotions.multiple.opt_in')}:
                    <TooltipMUI
                        title={i18n.t('promotions.multiple.opt_in.tooltip')}
                    />
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <ACommonDataField
                      component={A_COMMON_DATA_FIELD.CHECK_BOX}
                      value={state?.model.allowMultipleOptIn}
                      handleChange={handleChange('allowMultipleOptIn')}
                      disabled={isDisabledAll}
                      className={'wrapper_checkBox'}
                  />
                </div>
              </div>

              {
                promotionType === PROMOTION_TYPE.BONUS_WAGERING &&
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center" style={{ width: '124px'}}>
                      {i18n.t('promotions.funBonus')}:
                      <TooltipMUI
                          title={i18n.t('promotions.funBonus.tooltip')}
                      />
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <ACommonDataField
                        component={A_COMMON_DATA_FIELD.CHECK_BOX}
                        value={state?.model.funBonus}
                        handleChange={handleChange('funBonus')}
                        disabled={isDisabledAll}
                        className={'wrapper_checkBox'}
                    />
                  </div>
                </div>
              }

            </div>

            {tableOfConfiguration}

            {
              promotionType === PROMOTION_TYPE.FREE_SPINS &&
              <div className="m-4">
                <div className="font-w-500 w-100 mb-2 mt-4 d-flex align-items-center">
                  {i18n.t('promotions.rewards')}
                  <TooltipMUI title={i18n.t('promotions.rewards.tooltip')}/>
                  <div className="promotion-card__reward">
                    <RewardSelect
                        onChange={handleChange('rewardType')}
                        value={state?.model.rewardType}
                        disabled={isDisabledAll}
                    />
                  </div>
                </div>
                {
                  state?.model.rewardType === REWARD_TYPE.BonusWagering &&
                  <div className="d-flex flex-wrap align-items-center col-12">
                    <div className="font-w-500 mr-5 pr-5">
                      {i18n.t('promotions.rewardsCopy')}
                    </div>
                    {/*// TODO: after defined requirements create Dropdown of Deposit Promotions*/}
                    {/*// for promo with type === BonusWagering, status running*/}
                    <RewardSelect
                        onChange={handleChange('rewardType')}
                        value={state?.model.rewardType}
                        disabled={isDisabledAll}
                    />
                  </div>
                }
                {
                  state?.model.rewardType === REWARD_TYPE.PrizeWagering &&
                  <div className="d-flex flex-wrap align-items-center col-12">
                    <div className="font-w-500 mr-5 pr-5">
                      {i18n.t('promotions.constants.BonusWagering')}:
                    </div>

                    <div style={{minWidth: '300px'}}>
                      <SelectInput
                          options={bonusWageringOptions}
                          value={state?.model.rewardPromoId}
                          onChange={handleChange('rewardPromoId')}
                          disabled={isDisabledAll}
                          placeholder={"Promotions"}
                      />
                      {isWarningShown &&
                        <span className='select-promo-warning'>
                          {i18n.t('promotions.selectPromo')}
                        </span>
                      }
                    </div>
                  </div>
                }
              </div>
            }

            <div className="sub-content__content d-flex flex-wrap top-separator">
              <div className="d-flex align-items-center flex-wrap col-12 font-w-500">
                <div className={'grid_desc long'}>
                    {i18n.t("promotions.enterCondition")}:
                </div>
                <div className={'grid_valueView'}>
                  <MultiSelect
                      category={SELECT_CATEGORIES.ENTER_CONDITION}
                      values={state?.model.enterCondition}
                      handleChange={handleChange(SELECT_CATEGORIES.ENTER_CONDITION)}
                      isDisabled={isDisabledAll}
                  />
                </div>
                {promotionType === PROMOTION_TYPE.FREE_SPINS && (
                    <div className='ml-10 d-flex justify-content-center align-items-center promo-code-field-wrapper'>
                      <div className='mr-10'>
                        {i18n.t("promotions.provider")}:
                      </div>
                      <SelectInput
                          options={listProviders}
                          value={state?.model.provider}
                          onChange={handleChange(SELECT_CATEGORIES.PROVIDER)}
                          disabled={state?.model.status !== PROMOTION_STATUS.PENDING || !canEditPromotion}
                          placeholder={"Game Provider"}
                          className='w-150px z-index-30'
                      />
                      {state?.model.provider === 'Playtech' &&
                          <div className='d-flex align-items-center promo-code-field'>
                            <div className='ml-10'>
                              {i18n.t("promotions.templateCode")}:
                            </div>
                            <TextInput
                                placeholder={i18n.t('promotions.templateCode')}
                                onChange={handleChange('templateCode')}
                                value={state?.model.templateCode}
                                disabled={state?.model.status !== PROMOTION_STATUS.PENDING || !canEditPromotion}
                                className='ml-5 w-150px'
                            />
                          </div>
                      }
                    </div>
                )}
              </div>

              <div className="d-flex align-items-center flex-wrap col-12">
                <div className={'grid_desc long mt-4'}>
                  <div className="d-flex align-items-center font-w-500">
                    {i18n.t('promotions.listOfGames')}:
                    {
                        promotionType === PROMOTION_TYPE.FREE_SPINS && <TooltipMUI
                            title={i18n.t('promotions.listOfGames.tooltip')}/>
                    }
                  </div>
                </div>
                  <div className={'grid_valueView mt-4 me-3'}>
                    <button
                        className='btn btn-rounded btn-primary me-2'
                        onClick={toggleGamesModalVisibility}
                        disabled={isDisabledAll || providerNotSelected}
                    >
                      {i18n.t('crm.addGames')}
                    </button>
                </div>
                <div className="d-flex mt-4">
                  <DragFileInput
                      accept={XLSXformat}
                      typeFile={'XLSX'}
                      tooltipText={'chooseFileXLSX'}
                      withLabel={false}
                      tooltipPosition='right'
                      imgMode
                      onSelect={handleUploadFile}
                      disabled={isDisabledAll || providerNotSelected}
                  />
                </div>
              </div>
              {!isLoadingGames ? (
                <div className='grid_valueView mt-4 me-3'>
                  <TextInput
                    category={SELECT_CATEGORIES.GAMES}
                    value={
                      state?.model?.listOfGames?.length ?
                        games.result
                          .filter(game => state?.model.listOfGames.includes(game.id))
                          .map(game => game.friendlyName)
                          .join(', ') :
                        'All games'
                    }
                    handleChange={handleChange('listOfGames')}
                    disabled={true}
                    className={'multiSelect_listOfGames'}
                  />
                </div>
              ) : (
                <div className='grid_valueView mt-4 me-3'>
                  <Loader size={'sm'} />
                </div>
              )}
              <div className='d-flex col-12'>
                {invalidGameIds.length > 0 && (
                  <div className="invalid-game-ids-promo">
                    <span>{i18n.t("crm.invalidGameIdsInPromo")}</span>
                    <button
                      className="btn btn-rounded btn-primary manage-disabled-games"
                      onClick={toggleDisabledGamesModal}
                    >
                      {i18n.t("crm.manageDisabledGames")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {isShowDisabledGamesModal && (
              <DisabledGamesModal
                isOpen={isShowDisabledGamesModal}
                invalidGameIds={invalidGameIds}
                toggleModal={toggleDisabledGamesModal}
                setState={setState}
                state={state}
                setIsEdit={setIsEdit}
              />
            )}
            {gamesModalVisibility &&
              <div className="game-modal">
                <TableBigModal
                  onToggle={toggleGamesModalVisibility}
                  renderHeader={({
                                   handleChangeCategoryGames = () => {
                                   }
                                 }) => (
                          <CategoryGamesModalHeader
                              siteMode={true}
                              isPromoPage={isPromoPage}
                              onSendCategoryData={toggleGamesModalVisibility}
                              canEditPromotion={canEditPromotion}
                              state={state}
                              handleChange={handleChange}
                              listProviders={listProviders}
                          />
                      )}
                      provider={state?.model.provider}
                      siteMode={false}
                      checkedGames={state?.model?.listOfGames}
                      increaseOrDecreaseGames={() => {}}
                      tableTitleText={i18n.t("content.chooseGames")}
                      isOpen={gamesModalVisibility}
                      columns={PromotionsModalColumns}
                      setPromotionStateGames={setState}
                      isPromoPage={isPromoPage}
                      setIsEditPromotion={setIsEdit}
                      className={'addGamesModal'}
                  />
                </div>
            }

          </CardBody>
        </Card>

        {(isEdit || isNew) &&
        <ButtonsPanel
            isNew={isNew}
            onCancel={handleCancel}
            onSave={handleSave}
            isEdit={isEdit}
            onSetIsEdit={setIsEdit}
            isDisabled={isDisabledSaveButtons}
        />
        }

        {isEdit &&
        <RouterPrompt
            when={isEdit}
            onOK={() => true}
            onCancel={() => false}
        />
        }
      </AutomationContext.Provider>
  );
}

PromotionCard.propTypes = {
  data: PropTypes.object,
  isNew: PropTypes.bool,
  promotionStatus: PropTypes.string,
}

const mapStateToProps = state => {
  const {
    isUpdating,
    isCreating,
  } = state.Promotions
  const {
    playerList,
  } = state.PlayerSelectList
  const {
    games,
    isLoadingGames
  } = state.Games;
  return {
    isUpdating,
    isCreating,
    playerList,
    games,
    isLoadingGames,
  }
}

export default React.memo(withRouter(
    connect(mapStateToProps, {
      updatePromotion,
      createPromotion,
      getGames,
    })(PromotionCard)
));
