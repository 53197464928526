import {
    GET_NOTIFICATIONS_LIST,
    GET_NOTIFICATIONS_LIST_SUCCESS
} from './actionTypes';

export const getNotificationsList = query => ({
    type: GET_NOTIFICATIONS_LIST,
    payload: query
});

export const getNotificationsListSuccess = response => ({
    type: GET_NOTIFICATIONS_LIST_SUCCESS,
    payload: response
});
