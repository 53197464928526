import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import NewCustomTable from '../../../components/NewCustomTable';

import trashCan from '../../../assets/images/common/trashCan.svg';
import pencil from '../../../assets/images/common/editPencil.svg';
import moment from "moment";
import { DATE_FORMAT } from "../../../common/utils/common";
import {useDispatch, useSelector} from "react-redux";
import {getAgentsList} from "../../../store/agents/agents/actions";
import {valueBetweenDate} from "../../../utils/filterСategory";

const FilterTable = ({ onRemove }) => {
    const dispatch = useDispatch();

    const { agentsList } = useSelector(state => state.AgentList);
    const { isLoading, filtersList } =
        useSelector(state => state.FilterPage);

    useEffect(() => {
        dispatch(getAgentsList());
    }, []);

    const columns = useMemo(
        () => [
            { label: i18n.t('filter.name'), className: 'text-align-left' },
            { label: i18n.t('filter.filteredBy'), className: 'text-align-left' },
            { label: i18n.t('filter.owner'), className: 'text-align-left' },
            { label: i18n.t('filter.category'), className: 'text-align-left' },
            { label: '' }
        ],
        []
    );

    return (
        <NewCustomTable
            isLoading={isLoading}
            columns={columns}
            className={'filter-page__table'}
            isHeader={true}
        >
            {filtersList.map((item, ind) => {
                const { name = '---', filterDynamic, owner, category } = item;
                return (
                    <tr key={item.createdAt}>
                        <td className={'text-align-left'}>{name}</td>
                        <td className={'text-align-left'}>{filterDynamic.map((filter) => {
                            let filterValue;
                            if (typeof filter['value'] === 'object') {
                                if (filter['key'] === "birth_date" && !filter.value?.info) {
                                    filterValue = valueBetweenDate(filter['value'])
                                }
                                if (filter['key'] === 'created_at' || filter['key'] === "birth_date") {
                                        const { info = {} } = filter.value;
                                        const { time = '', position = '', dateRange = '' } = info;
                                        if (time) {
                                            if (position === 'last') {
                                                filterValue = `${position} ${time} ${dateRange}`;
                                            } else {
                                                filterValue = `more than ${time} ${dateRange} ${position}`;
                                            }
                                        } else {
                                            filterValue = valueBetweenDate(filter.value)
                                        }
                                }
                            } else {
                                filterValue = filter['value']
                            };
                            if (filter['key'] === 'agent_owner') {
                                const currentAgent = agentsList?.find(agent => agent.id === filter['value'])
                                if (!currentAgent?.agentName) {
                                    filterValue = 'Without owner'
                                } else {
                                    filterValue = currentAgent?.agentName
                                }
                            }
                            return (
                                <span className="d-flex align-items-center">
                                    {
                                        typeof filter['value'] === 'object' &&
                                        (filter['key'] === 'birth_date' || filter['key'] === 'created_at')
                                            ? <span className="d-flex flex-column">
                                                <span>{`${filter['key']}:`}</span>
                                                <span>{filterValue}</span>
                                            </span>
                                            : <span>{`${filter['key']}: ${filterValue}`}</span>
                                    }
                                </span>
                            )
                        })}</td>
                        <td className={'filter-page__table-owner'}>
                            {owner && (
                                <span
                                    className={
                                        'filter-page__table-owner_picture'
                                    }
                                >
                                    {owner?.oid}
                                </span>
                            )}
                            <span className={'filter-page__table-owner_text'}>
                                {owner?.name || '---'}
                            </span>
                        </td>
                        <td className={'text-align-left'}>
                            {i18n.t(`filter.constant.${category}`)}
                        </td>
                        <td className={'filter-page__table-actions'}>
                            <button onClick={onRemove(item)}>
                                <img
                                    src={trashCan}
                                    alt={i18n.t('filter.remove')}
                                />
                            </button>

                            {/*<button onClick={onEdit(item)}>*/}
                            {/*  <img src={pencil} alt={i18n.t("filter.edit")} />*/}
                            {/*</button>*/}
                        </td>
                    </tr>
                );
            })}
        </NewCustomTable>
    );
};

FilterTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default FilterTable;
