import {
    GET_PLAYER_SELECT_LIST,
    GET_PLAYER_SELECT_LIST_SUCCESS,
    GET_PLAYER_SELECT_LIST_FAIL
} from './actionTypes';

export const getPlayerSelectList = payload => ({
    type: GET_PLAYER_SELECT_LIST,
    payload
});

export const getPlayerSelectListSuccess = payload => ({
    type: GET_PLAYER_SELECT_LIST_SUCCESS,
    payload
});

export const getPlayerSelectListFail = error => ({
    type: GET_PLAYER_SELECT_LIST_FAIL,
    payload: error
});
