import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {Fade, SvgIcon} from "@material-ui/core";
import {useState} from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';

const TooltipIcon = () =>
    <SvgIcon fontSize={'small'} style={{ width: '16px' }}>
        <path d="M12 0C5.3832 0 0 5.38327 0 12.0001C0 18.6169 5.3832 24 12 24C18.6168 24 24 18.6169 24 12.0001C24 5.38327 18.6168 0 12 0ZM12 21.8182C6.58618 21.8182 2.18182 17.4138 2.18182 12.0001C2.18182 6.58633 6.58618 2.18182 12 2.18182C17.4138 2.18182 21.8182 6.58633 21.8182 12.0001C21.8182 17.4138 17.4137 21.8182 12 21.8182Z" fill="#495057"/>
        <path d="M11.9993 5.09058C11.1974 5.09058 10.545 5.74338 10.545 6.54578C10.545 7.34745 11.1974 7.99967 11.9993 7.99967C12.8011 7.99967 13.4535 7.34745 13.4535 6.54578C13.4535 5.74338 12.8011 5.09058 11.9993 5.09058Z" fill="#495057"/>
        <path d="M11.9997 10.1821C11.3972 10.1821 10.9088 10.6706 10.9088 11.273V17.8185C10.9088 18.421 11.3972 18.9094 11.9997 18.9094C12.6021 18.9094 13.0906 18.421 13.0906 17.8185V11.273C13.0906 10.6706 12.6021 10.1821 11.9997 10.1821Z" fill="#495057"/>
    </SvgIcon>

export default function TooltipMUI({ title = '', Icon = TooltipIcon, wrapperPadding }) {
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
        <div style={{ 'paddingBottom': wrapperPadding ? wrapperPadding : '12px' }}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={title}
                placement="top"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
            >
                <IconButton onClick={handleTooltipOpen}>
                    <SvgIcon component={Icon} />
                </IconButton>
            </Tooltip>
        </div>
    </ClickAwayListener>
    );
}