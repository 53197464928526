import React, { memo } from "react";
import {useTranslation} from "react-i18next";
import i_51 from "../../../assets/images/common/Frame 176 (1).svg";
import close from "../../../assets/images/common/close 1.svg";

const CategoryTableFooter = (props) => {
    const {
        changeCategoryGames,
        onCancel,
    } = props;

    const {t} = useTranslation();

    return (
        <div className='category-table-footer'>
            <button
                className='btn btn-rounded btn-primary table-footer-btn'
                onClick={changeCategoryGames}
            >
                <img src={i_51} alt=""/>
                {t('crm.save')}
            </button>
            <button
                className='btn btn-rounded btn-light table-footer-btn ml-5'
                onClick={onCancel}
            >
                <img className="me-2" src={close} alt=""/>
                {t('crm.cancel')}
            </button>
        </div>
    )
}

export default memo(CategoryTableFooter);