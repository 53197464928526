import React, { useState } from 'react';
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import "./SwipeAnimation.scss";

const SwipeAnimation = ({className}) => {
    const size = useWindowSize();
    const [showSwipeRight, setShowSwipeRight] = useState(true);

    const hideHandAnimation = () => {
        setShowSwipeRight(false);
    }

    return (
        <>
            {
                showSwipeRight && size.width < SCREEN_SIZES.MAX_MOBILE_VIEW && (
                    <div className={clsx("swiperRight__wrapper", className)}>
                        <div className="swipeRight">
                            <div className="swipe">
                                <div className="path"></div>
                                <div className="hand_icon"></div>
                            </div>
                        </div>
                        <div
                            className="swipeRight__overlay"
                            onTouchStart={hideHandAnimation}
                            onMouseDown={hideHandAnimation}
                        ></div>
                    </div>
                )
            }
        </>
    )
}

export default SwipeAnimation;