import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import Badge from './Badge';
import { usePopper } from 'react-popper';
import { grey } from './colors';
import PlusIcon from './img/Plus';
import { ActionTypes, DataTypes, randomColor } from './utils';
import { createPortal } from 'react-dom';
import './headerStyles.css';
import smallClose from "../../assets/images/layout/small-close.svg";
import editDocument from "../../assets/images/layout/edit-document.svg";

const emptyFunc = () => {};

export default function Cell({
  value: initialValue,
  row,
  column: { id, dataType, options },
  dataDispatch,
  setIsEdit,
  showEditModal = emptyFunc,
  edited = false,
  onChange = () => {},
  isMultiLine  = false,
}) {
  const { index } = row;
  const [value, setValue] = useState({ value: initialValue, update: false });
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [addSelectRef, setAddSelectRef] = useState(null);
  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: 'bottom-start',
    strategy: 'fixed',
  });

  function handleOptionKeyDown(e) {
    if (e.key === 'Enter') {
      if (e.target.value !== '') {
        dataDispatch({
          type: ActionTypes.ADD_OPTION_TO_COLUMN,
          option: e.target.value,
          backgroundColor: randomColor(),
          columnId: id,
        });
      }
      setShowAdd(false);
    }
  }

  function handleAddOption(e) {
    setShowAdd(true);
  }

  function handleOptionBlur(e) {
    if (e.target.value !== '') {
      dataDispatch({
        type: ActionTypes.ADD_OPTION_TO_COLUMN,
        option: e.target.value,
        backgroundColor: randomColor(),
        columnId: id,
      });
    }
    setShowAdd(false);
  }

  function getColor() {
    let match = options.find(option => option.label === value.value);
    return (match && match.backgroundColor) || grey(200);
  }

  function handleChange(e) {
    const newValue = e.target.value;
    setValue({ value: newValue, update: false });
    onChange(newValue);
    if (initialValue === newValue) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }

  function handleOptionClick(option) {
    if (dataType === DataTypes.SELECT) {
      setValue({ value: option.label, update: true });
      onChange(option.label);
    } else if (dataType === DataTypes.MULTISELECT) {
      setValue(prev => {
        const newValue = prev.value.includes(option.label)
            ? [ ...prev.value ]
            : [  ...prev.value, option.label];
        onChange(newValue);
        return {
          value: newValue,
          update: true
        };
      });
    }
    setShowSelect(false);
  }
  
  function handleOptionClickDelete(value) {
    setValue(prev => {
      const newValue = [ ...prev.value.filter(el => el !== value) ];
      onChange(newValue);
      return {
        value: newValue,
        update: true
      };
    });
  }

  function handleEditClick() {
    showEditModal(row);
  }

  function getCellElement() {
    switch (dataType) {
      case DataTypes.TEXT:
        return (
          <ContentEditable
            html={(value.value && value.value.toString()) || ''}
            onChange={edited ? handleChange : emptyFunc}
            onBlur={() => setValue(old => ({ value: old.value, update: true }))}
            className="data-input"
          />
        );
      case DataTypes.NUMBER:
        return (
          <ContentEditable
            html={(value.value && value.value.toString()) || ''}
            onChange={edited ? handleChange : emptyFunc}
            onBlur={() => setValue(old => ({ value: old.value, update: true }))}
            className="data-input text-align-right"
          />
        );
      case DataTypes.SELECT:
      case DataTypes.MULTISELECT:
        return (
          <>
            <div
              ref={setSelectRef}
              className="cell-padding d-flex cursor-default align-items-center flex-1"
              style={isMultiLine
                ? {
                  alignContent: 'flex-start',
                  flexWrap: 'wrap',
                }
                : {}
              }
            >
              { Array.isArray(value.value)
                ? value.value.length > 0
                  ? value.value.map(val =>
                      <div key={val} style={{ marginRight: '12px', display: 'flex' }}>
                            <Badge
                                value={val}
                                backgroundColor={'hsla(206, 100%, 64%, 1)'}
                                dataType={DataTypes.MULTISELECT}
                                setShowSelect={edited ? setShowSelect : emptyFunc}
                            />
                            {
                              edited && dataType === DataTypes.MULTISELECT &&
                                <img
                                    src={smallClose}
                                    alt=""
                                    width={24}
                                    height={24}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleOptionClickDelete(val)}
                                />
                            }
                        </div>)
                  : edited && <Badge
                      value=""
                      backgroundColor={getColor()}
                      setShowSelect={edited ? setShowSelect : emptyFunc}
                  />
                : (edited || value.value) && <Badge
                    value={value.value}
                    backgroundColor={getColor()}
                    setShowSelect={edited ? setShowSelect : emptyFunc}
                />
              }
            </div>
            {showSelect && (
              <div className="overlay" onClick={() => setShowSelect(false)} />
            )}
            {showSelect &&
              createPortal(
                <div
                  className="shadow-5 bg-white border-radius-md"
                  ref={setSelectPop}
                  {...attributes.popper}
                  style={{
                    ...styles.popper,
                    zIndex: 4,
                    minWidth: 200,
                    maxWidth: 320,
                    maxHeight: 305,
                    padding: '0.75rem',
                    overflowY: 'scroll',
                  }}
                >
                  <div
                    className="d-flex flex-wrap-wrap"
                    style={{ marginTop: '-0.5rem' }}
                  >
                    {options.map(option => (
                      <div
                        className="cursor-pointer mr-5 mt-5"
                        onClick={() => handleOptionClick(option)}
                      >
                        <Badge
                          value={option.label}
                          backgroundColor={option.backgroundColor}
                        />
                      </div>
                    ))}
                    {showAdd && (
                      <div
                        className="mr-5 mt-5 bg-grey-200 border-radius-sm"
                        style={{
                          width: 120,
                          padding: '2px 4px',
                        }}
                      >
                        <input
                          type="text"
                          className="option-input"
                          onBlur={handleOptionBlur}
                          ref={setAddSelectRef}
                          onKeyDown={handleOptionKeyDown}
                        />
                      </div>
                    )}
                  </div>
                </div>,
                document.querySelector('#popper-portal')
              )}
          </>
        );
      case DataTypes.EDIT:
        return (
          <img
              src={editDocument}
              alt="Edit row"
              width={30}
              height={30}
              style={{
                marginLeft: 12,
                marginTop: 7,
                cursor: 'pointer',
              }}
              onClick={handleEditClick}
          />
        )
      default:
        return <span/>;
    }
  }

  useEffect(() => {
    if (addSelectRef && showAdd) {
      addSelectRef.focus();
    }
  }, [addSelectRef, showAdd]);

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId: id,
        rowIndex: index,
        value: value.value,
      });
    }
  }, [value, dataDispatch, id, index]);

  return getCellElement();
}
