import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import NewCustomTable from '../../../components/NewCustomTable';
import ReadField from './readField';
import Formatter from '../../../utils/Formatter';
import { Link } from 'react-router-dom';
import { generateNotificationRedirectLink } from '../../../components/CommonForBoth/TopbarDropdown/NotificationDropdown';

const NotificationTable = ({
    triggerNotificationDispatch,
    isLoading,
    data = []
}) => {
    const columns = useMemo(
        () => [
            {
                label: i18n.t('notification.read'),
                className: 'text-align-left'
            },
            {
                label: i18n.t('notification.player'),
                className: 'text-align-left'
            },
            {
                label: i18n.t('notification.type'),
                className: 'text-align-left'
            },
            {
                label: i18n.t('notification.subject'),
                className: 'text-align-left'
            },
            {
                label: i18n.t('notification.creator'),
                className: 'text-align-left'
            },
            {
                label: i18n.t('notification.issued'),
                className: 'text-align-left'
            }
        ],
        []
    );

    return (
        <NewCustomTable
            isLoading={isLoading}
            columns={columns}
            className={'filter-page__table'}
            isHeader={true}
        >
            {data.map((item, index) => {
                let {
                    isRead,
                    createdByName,
                    category,
                    createdByOid,
                    created,
                    subject,
                    playerName,
                    playerOid,
                    oid
                } = item;

                if (subject.length > 50) {
                    subject = subject.substr(0, 50) + '...';
                }
                return (
                    <tr key={index}>
                        <td className={'text-align-left'}>
                            <span>
                                {isRead && (
                                    <i
                                        className="bx bx-check"
                                        style={{ color: '#36a57d' }}
                                    />
                                )}
                                {!isRead && (
                                    <i
                                        className="bx bx-circle"
                                        style={{ color: '#36a57d' }}
                                    />
                                )}
                            </span>
                            &nbsp;&nbsp;
                            {`#${oid}`}
                        </td>
                        <td className={'text-align-left'}>
                            <Link to={`/player-list/${playerOid}`}>
                                <span
                                    className={'filter-page__table-owner_text'}
                                >
                                    {playerName}
                                </span>
                            </Link>
                        </td>

                        <td className={'text-align-left'}>{category}</td>
                        <td className={'text-align-left'}>
                            <Link
                                to={generateNotificationRedirectLink(
                                    category,
                                    oid,
                                    playerOid
                                )}
                            >
                                <span>{subject}</span>
                            </Link>
                        </td>

                        <td className={'text-align-left'}>{createdByName}</td>
                        <td className={'text-align-left'}>
                            {Formatter.displayDateTimeMinutes(created)}
                        </td>
                    </tr>
                );
            })}
        </NewCustomTable>
    );
};

NotificationTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired
};

export default NotificationTable;
