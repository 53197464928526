import { TAB } from '../siteConstants';

export const getType = val => {
    return val === null
        ? 'null'
        : val === undefined
        ? 'undefined'
        : Object.prototype.toString.call(val).slice(8, -1).toLowerCase();
};

export const getDifferences = (obj1, obj2) =>
    _.reduce(
        obj1,
        (result, value, key) => {
            if (_.isPlainObject(value)) {
                result[key] = getDifferences(value, obj2[key]);
            } else if (getType(value) === 'array') {
                if (!_.isEqual([...value].sort(), [...obj2[key].sort()])) {
                    result[key] = value;
                }
            } else if (!_.isEqual(String(value), String(obj2[key]))) {
                result[key] = value;
            }
            return result;
        },
        {}
    );

export const removeEmptyFromObj = obj =>
    _.reduce(
        obj,
        (result, value, key) => {
            const isEmptyValue = _.isEmpty(value);

            if (!isEmptyValue && _.isPlainObject(value)) {
                result[key] = removeEmptyFromObj(value);
            } else if (getType(value) === 'object') {
                if (isEmptyValue) {
                    _.omit(result, [result[key]]);
                } else {
                    result[key] = value;
                }
            } else if (_.isNil(value) || _.isNaN(value)) {
                _.omit(result, [result[key]]);
            } else {
                result[key] = value;
            }

            return result;
        },
        {}
    );

const getCrmCommunication = ({
    kyc,
    smsProviderAccount,
    uniqueFieldsForCMS,
    emailProviderApiKey,
    requiredFieldsForCMS,
    smsProviderAuthToken
}) => ({
    crmCommunication: {
        kyc,
        smsProviderAccount,
        uniqueFieldsForCMS,
        emailProviderApiKey,
        requiredFieldsForCMS,
        smsProviderAuthToken
    }
});

const getConfigurationFinancial = ({
    cdnUrl,
    timeZone,
    currency,
    currencies,
    maxDeposit,
    publicStorage,
    maxWithdrawal,
    privateStorage,
    walletPriorities
}) => ({
    configurationFinancial: {
        cdnUrl,
        timeZone,
        currency,
        currencies,
        maxDeposit: Number(maxDeposit),
        publicStorage,
        maxWithdrawal: Number(maxWithdrawal),
        privateStorage
        // walletPriorities: [walletPriorities],
    }
});

const getSecurityLimits = ({
    token,
    domains,
    whitelistIP,
    refreshToken,
    blockedCountries,
    depositLimitLevel,
    withdrawalLimitLevel
}) => ({
    securityLimits: {
        refreshToken: {
            ...refreshToken,
            expiration: Number(refreshToken?.expiration)
        },
        token: {
            ...token,
            expiration: Number(token?.expiration)
        },
        domains,
        whitelistIP,
        withdrawalLimitLevel: withdrawalLimitLevel?.map(({ above, type }) => ({
            type,
            above: Number(above)
        })),
        depositLimitLevel: depositLimitLevel?.map(({ above, type }) => ({
            type,
            above: Number(above)
        })),
        blockedCountries
    }
});

export const brandSettingsPageDataAdapter = (currentObj, newObj, tab) => {
    const differences = getDifferences(newObj, currentObj);
    let newDifferences = {};

    if (tab === TAB.CRM_COMMUNICATION) {
        newDifferences = { ...getCrmCommunication(differences) };
    }
    if (tab === TAB.ADVANCE) {
        newDifferences = { ...getCrmCommunication(differences) };
    }
    if (tab === TAB.CONFIGURATION_FINANCIAL) {
        newDifferences = { ...getConfigurationFinancial(differences) };
    }
    if (tab === TAB.SECURITY_LIMITS) {
        newDifferences = { ...getSecurityLimits(differences) };
    }

    newDifferences = removeEmptyFromObj(newDifferences);

    if (_.isEmpty(newDifferences?.configurationFinancial?.publicStorage)) {
        delete newDifferences?.configurationFinancial?.publicStorage;
    }
    if (_.isEmpty(newDifferences?.configurationFinancial?.privateStorage)) {
        delete newDifferences?.configurationFinancial?.privateStorage;
    }
    if (_.isEmpty(newDifferences?.securityLimits?.refreshToken)) {
        delete newDifferences?.securityLimits?.refreshToken;
    }
    if (_.isEmpty(newDifferences?.securityLimits?.token)) {
        delete newDifferences?.securityLimits?.token;
    }

    return newDifferences;
};
