import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from "react-redux";
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import close from "../../../assets/images/common/close 1.svg";
import useAlertService from "../../../hooks/useAlertService";
import useProviders from "../../../hooks/useProviders";
import { AttributionType, BooleanAttributions } from "../../Content/common/constants";
import {isEqual} from "lodash";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const emptyStringInitFields = ['gameName', 'externalDesktopId', 'externalMobileId', 'gameType'];
const emptyValueInputs = ['gameName', 'rtp', 'lines', 'bonusFactor', 'externalDesktopId', 'externalMobileId', 'vendor', 'provider'];
const zeroInitFields = ['rtp', 'lines', 'bonusFactor'];
const joinInitFields = ['licenses'];

const getInitialDisabled = () =>  {
    const initialDisabled = {
        gameName: true,
        licenses: true,
        externalDesktopId: true,
        externalMobileId: true,
        rtp: true,
        lines: true,
        bonusFactor: true,
        gameType: true,
        vendor: true,
        provider: true,
    };

    Object.keys(AttributionType).forEach((key) => initialDisabled[key] = true);

    return initialDisabled;
}

const createState = (categoryMode, currentGame) => {
    const state = {
        gameName: currentGame.gameName || currentGame.friendlyName || '',
        externalDesktopId: currentGame.externalDesktopId || '',
        externalMobileId: currentGame.externalMobileId || '',
        rtp: currentGame.rtp || 0,
        lines: currentGame.lines || 0,
        licenses: currentGame.licenses?.join(',') || '',
        bonusFactor: (categoryMode
            ? currentGame.promoCoefficient
            : currentGame.bonusFactor) || 0,
        gameType: currentGame.gameType || '',
        ...(currentGame.friendlyName && {
            vendor: currentGame.vendor,
            provider: currentGame.provider,
        } || {})
    };

    Object.keys(AttributionType).forEach((key) => {
        if (key in currentGame && currentGame[key] !== undefined) {
            state[key] = currentGame[key];
        }
        else if (BooleanAttributions.includes(key)) {
            state[key] = false;
        }
        else {
            state[key] = '';
        }
    });

    return state;
}

function GameUpdateModal({
    currentGame,
    onHide,
    onConfirm,
    onUpdateGame,
    categoryMode,
}) {
    const { gameVendors, gameTypes } = useSelector(
        state => state.Games
    );

    const {providers} = useProviders();

    useHandleEsc(onHide);
    const [initialState, setInitialState] = useState(createState(categoryMode, currentGame));
    const [state, setState] = useState(createState(categoryMode, currentGame));
    const [isEdit, setIsEdit] = useState(false);

    const [disabled, setDisabled] = useState(getInitialDisabled());
    const [gameTypeOptions, setGameTypeOptions] = useState([]);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [vendors, setVendors] = useState([]);
    const [_providers, _setProviders] = useState([]);

    useEffect(() => {
        setVendors(gameVendors.map((vendor) => {
            if (vendor?.configuration?.direct) {
                return {
                    value: vendor.id,
                    content: `${vendor.name} (direct)`
                };
            }
            return {
                value: vendor.id,
                content: vendor.name
            };
        }));
        setState({
            ...state,
            vendor: state.vendor || gameVendors?.[0]?.id || '',
        });
    }, [gameVendors])

    useEffect(() => {
        _setProviders(providers);
        setState({
            ...state,
            provider: state.provider || providers?.[0]?.id || '',
        });
    }, [providers]);

    useEffect(() => {
        setGameTypeOptions(gameTypes.map((type) =>({
            value: type,
            content: type
        })));
    }, [gameTypes]);

    useEffect(() => {
        setInitialState(state)
    }, [gameVendors, providers, gameTypes]);

    useEffect(() => {
        setIsEdit(!isEqual(initialState, state));
    }, [initialState, state]);

    const handleChangeField = (field) => (value) => {
        const newState = {
            ...state,
            [field]: value,
        };
        setState(newState);
    }

    const handleSave = useCallback(() => {
        const changedFields = {};

        Object.keys(state).forEach((key) => {
            if (!isEqual(initialState[key], state[key])) {
                changedFields[key] = state[key];
            }
        });

        if (currentGame.gameName) {
            onConfirm(changedFields);
        }
        else {
            const {gameName: friendlyName, ...restFields} = changedFields;
            const attributionKeys = Object.keys(AttributionType);
            const gameFields = {};
            const details = {};

            Object.keys(restFields).forEach((field) => {
                if (attributionKeys.includes(field)) {
                    details[field] = restFields[field];
                }
                else {
                    gameFields[field] = restFields[field];
                }
            });

            zeroInitFields.forEach((field) => {
                if (gameFields[field] === '') {
                    gameFields[field] = 0;
                }
            });
            onUpdateGame({
                ...gameFields,
                friendlyName,
                details,
            });
        }
    }, [state, disabled]);

    const attributionsKeys = useMemo(() => Object.keys(AttributionType), []);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="game-update-modal two-columns-popup"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.gameUpdate')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <table className='w-100'>
                    <tr>
                        <td
                            style={{
                                width: 250,
                            }}
                        >
                            <ACommonDataField
                                label={i18n.t('content.friendlyName')}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                value={state.gameName}
                                handleChange={handleChangeField('gameName')}
                                fulWidth={true}
                            />
                        </td>
                        <td
                            style={{
                                width: 250,
                            }}
                        >
                            <ACommonDataField
                                label={i18n.t('content.rtp')}
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                value={state.rtp}
                                handleChange={handleChangeField('rtp')}
                                fulWidth={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.type')}
                                component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                value={state.gameType}
                                options={gameTypeOptions}
                                handleChange={handleChangeField('gameType')}
                                fulWidth={true}
                            />
                        </td>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.lines')}
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                value={state.lines}
                                handleChange={handleChangeField('lines')}
                                fulWidth={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.licenses')}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                value={state.licenses}
                                handleChange={handleChangeField('licenses')}
                                fulWidth={true}
                            />
                        </td>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.bonusFactor')}
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                value={state.bonusFactor}
                                handleChange={handleChangeField('bonusFactor')}
                                fulWidth={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.gameIdDesktop')}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                value={state.externalDesktopId}
                                handleChange={handleChangeField('externalDesktopId')}
                                fulWidth={true}
                            />
                        </td>
                        <td>
                            <ACommonDataField
                                label={i18n.t('content.gameIdMobile')}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                value={state.externalMobileId}
                                handleChange={handleChangeField('externalMobileId')}
                                fulWidth={true}
                            />
                        </td>
                    </tr>
                    {currentGame.friendlyName &&
                        <tr>
                            <td>
                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    label={i18n.t('content.gameVendor')}
                                    options={vendors}
                                    handleChange={handleChangeField('vendor')}
                                    value={state.vendor}
                                    fulWidth={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    label={i18n.t('content.gameProvider')}
                                    options={_providers}
                                    handleChange={handleChangeField('provider')}
                                    value={state.provider}
                                    fulWidth={true}
                                />
                            </td>
                        </tr>
                    }
                    {attributionsKeys
                        .filter((key, ind) => ind % 2 === 0)
                        .map((key, ind) => {
                                const value = state[key];
                                const typeofValue = typeof value;
                                const nextKey = attributionsKeys[ind * 2 + 1];
                                const nextValue = state[nextKey];
                                const typeofNextValue = typeof nextValue;
                                return (
                                    <tr>
                                        <td>
                                            <ACommonDataField
                                                label={i18n.t(`content.${key}`)}
                                                component={typeofValue === 'string'
                                                    ? A_COMMON_DATA_FIELD.TEXT
                                                    : typeofValue === 'number'
                                                        ? A_COMMON_DATA_FIELD.NUMBER
                                                        : A_COMMON_DATA_FIELD.TOGGLE
                                                }
                                                value={value}
                                                handleChange={handleChangeField(key)}
                                                fulWidth={true}
                                                fieldClassName='attribution-field'
                                            />
                                        </td>
                                        {nextKey && (
                                            <>
                                                <td>
                                                    <ACommonDataField
                                                        label={i18n.t(`content.${nextKey}`)}
                                                        component={typeofNextValue === 'string'
                                                            ? A_COMMON_DATA_FIELD.TEXT
                                                            : typeofNextValue === 'number'
                                                                ? A_COMMON_DATA_FIELD.NUMBER
                                                                : A_COMMON_DATA_FIELD.TOGGLE
                                                        }
                                                        value={nextValue}
                                                        handleChange={handleChangeField(nextKey)}
                                                        fulWidth={true}
                                                        fieldClassName='attribution-field'
                                                    />
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            }
                        )}
                </table>
            </ModalBody>
            <ModalFooter
                style={{
                    display: 'flex',
                }}
            >
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onHide}
                    style={{
                        width: '30%',
                    }}
                >
                    <img src={close} alt="" />
                    {i18n.t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={handleSave}
                    disabled={!isEdit}
                    style={{
                        width: '30%',
                        marginLeft: 20,
                    }}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('crm.ok')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

GameUpdateModal.propTypes = {
    selectedRows: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onUpdateGame: PropTypes.func,
    categoryMode: PropTypes.bool,
};

GameUpdateModal.defaultProps = {
    categoryMode: false,
    onConfirm: () => {},
    onUpdateGame: () => {},
}

export default React.memo(GameUpdateModal);
