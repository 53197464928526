import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from '../../../i18n';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { getSync } from '../../../helpers/api_helper';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { getAgentsList } from "../../../store/agents/agents/actions";

function AgentGeneralInfoBlock({ data, onChange }) {
    const dispatch = useDispatch();
    const { agentsList } = useSelector(state => state.AgentList);

    let agentList =
        agentsList?.map(v => {
            return {
                id: v.id,
                value: v.oid,
                content: v.agentName
            };
        }) || [];

    agentList = agentList.filter(v => v.id !== data.id);

    const [roles, setRoles] = useState([]);
    const [department, setDepartment] = useState([]);

    useEffect(() => {
        setDepartment(data?.department);
    }, [data]);

    useEffect(async () => {
        dispatch(getAgentsList());

        const rolesUrl = `/it-manager/roles`;
        const rolesResult = await getSync(rolesUrl, {});
        setRoles(
            rolesResult.data.map(key => {
                return {
                    value: key.id,
                    content: key.name
                };
            })
        );
    }, []);


    const handleChange = useCallback(
        name => {
            return res => {
                const value = res?.target?.value ? res.target.value : res;
                onChange({ ...data, [name]: value });
            };
        },
        [data, onChange]
    );

    const fields = useMemo(
        () => [
            {
                component: 'date',
                stateKey: 'createdAt',
                disabled: true
            },
            {
                component: 'date',
                stateKey: 'lastLogin',
                disabled: true
            },
            {
                agentList: true,
                component: 'text',
                stateKey: 'parent',
                disabled: true
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                stateKey: 'roleId',
                options: roles,
                disabled: true
            },
            {
                component: 'text',
                stateKey: 'department',
                options:  department || [],
                disabled: true
            }
        ],
        [data, roles, department]
    );

    return (
        <div className="info-content-block agent_general-info">
            <div className="d-flex flex-wrap">
                {fields.map((item, ind) => (
                    item?.agentList ? (
                        <ACommonDataField
                            component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                            options={agentList}
                            style={{
                                width: 'initial !important',
                                minWidth: 'initial !important',
                            }}
                            disabled={item.disabled}
                            handleChange={handleChange(item.stateKey)}
                            label={i18n.t('agent.manager')}
                            value={data[item.stateKey]}
                        />
                    ) : (
                        <ACommonDataField
                            fieldClassName={ind === 0 ? 'first-field' : ''}
                            key={item.stateKey}
                            component={item.component}
                            options={item.options}
                            style={item.stateKey === 'roleId' || item.stateKey === 'department'
                                ? {
                                    width: 'initial !important',
                                    minWidth: 'initial !important',
                                }
                                : {}
                            }
                            label={item.stateKey !== 'department' ?
                                   i18n.t(`agent.${item.stateKey}`) :
                                   i18n.t('agent.department')
                            }
                            value={
                                isArray(data[item.stateKey])
                                    ? data[item.stateKey].join(',')
                                    : data[item.stateKey]
                            }
                            handleChange={handleChange(item.stateKey)}
                            disabled={item.disabled}
                        />
                    )
                ))}
            </div>
        </div>
    );
}

AgentGeneralInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired
};

export default React.memo(AgentGeneralInfoBlock);
