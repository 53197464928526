import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import info from '../../../../assets/images/common/info.svg';
import today from '../../../../assets/images/common/Today.svg';
import yesterday from '../../../../assets/images/common/Yesterday.svg';
import month from '../../../../assets/images/common/Month.svg';
import i18n from '../../../../i18n';
import StatisticsInfoView from './StatisticsInfoView';
import BlockWrapper from './BlockWrapper';

const TimePeriodDataContent = ({ infoData }) => {
    const infoSchema = useMemo(() => {
        return [
            {
                title: i18n.t('admin.FTD'),
                content: infoData.FTD
            },
            {
                title: i18n.t('admin.deposits'),
                content: infoData.deposits
            },
            {
                title: i18n.t('admin.saleCR'),
                content: infoData.saleCR
            },
            {
                title: i18n.t('admin.average'),
                content: infoData.average
            }
        ];
    }, [infoData]);

    return (
        <>
            {infoSchema.map(item => {
                return (
                    <StatisticsInfoView
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
        </>
    );
};

const GeneralBlock = ({ infoData }) => {
    const infoSchema = useMemo(() => {
        return [
            {
                title: i18n.t('admin.loggedInToday'),
                content: infoData.loggedInToday
            },
            {
                title: i18n.t('admin.apmentsToday'),
                content: infoData.apmentsToday
            }
        ];
    }, [infoData]);

    return (
        <BlockWrapper title={i18n.t('admin.general')} srcIcon={info}>
            <StatisticsInfoView
                title={i18n.t('admin.totalLeads')}
                content={infoData.totalLeads}
                className="statistics-info-view--lg"
            />

            <div>
                {infoSchema.map(item => {
                    return (
                        <StatisticsInfoView
                            key={item.title}
                            title={item.title}
                            content={item.content}
                            size={item.size}
                        />
                    );
                })}
            </div>
        </BlockWrapper>
    );
};

const TodayBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.today')} srcIcon={today}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const YesterdayBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.yesterday')} srcIcon={yesterday}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const MTDBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.MTD')} srcIcon={month}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const ConversionInfoContainer = ({ statistics }) => {
    return (
        <div className="statistics-info-container">
            <GeneralBlock infoData={statistics} />

            <TodayBlock infoData={statistics} />

            <YesterdayBlock infoData={statistics} />

            <MTDBlock infoData={statistics} />
        </div>
    );
};

ConversionInfoContainer.propTypes = {
    statistics: PropTypes.object.isRequired
};

export default React.memo(ConversionInfoContainer);
