import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import header_icon from '../../../assets/images/common/home (1).svg';
import i18n from '../../../i18n';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { safeGet } from '../../../common/utils/common';
import { Col, Row } from 'reactstrap';
import {A_COMMON_DATA_FIELD} from "../../../constants/aCommonDataField";
import {useDispatch, useSelector} from "react-redux";
import {getStatesAndCities, putCurrentCities} from "../../../store/statesAndCities/actions";
import {isMexico} from "../../../utils/countries";

function AddressSubBlock(props) {
    const {
        player,
        onChange,
        className='',
        canEdit,
    } = props;

    const {
        address: {
            state = '',
            addressCountryAlfa2 = ''
        } = {}
    } = player;

    const dispatch = useDispatch();
    const { states, cities } = useSelector(state => state.StatesAndCities);

    const [currentCountry, setCurrentCountry] = useState('');
    const [currentState, setCurrentState] = useState('');

    const isStateOfMexico = (state) => states.find(({ text }) => text === state)

    useEffect(() => {
        if (isMexico(currentCountry) && !states.length) {
            dispatch(getStatesAndCities());
        }
    }, [currentCountry, states]);

    useEffect(() => {
        setCurrentCountry(player?.address.addressCountryAlfa2)
    }, [addressCountryAlfa2]);

    useEffect(() => {
        if (isMexico(currentCountry) && isStateOfMexico(state)) {
            setCurrentState(state);
        } else {
            setCurrentState('');
        }
    }, [currentCountry, states, state])

    useEffect(() => {
        const indexStateInArray = states.findIndex(state => state.text === currentState);
        const currentCities = [];
        if (indexStateInArray !== -1) {
            cities.forEach((city) => {
                if (city.state_code === states[indexStateInArray].state_code) {
                    currentCities.push({ text: city.name, value: city.state_code })
                }
            }, []);
            dispatch(putCurrentCities({ currentCities: currentCities }));
        } else {
            dispatch(putCurrentCities({ currentCities: [] }));
        }
        if (!isStateOfMexico(player?.address.state)) {
            dispatch(putCurrentCities({ currentCities: [] }));
        }
    }, [currentState, cities]);

    const fields = useMemo(
        () => [
                {
                    localizationKey: 'addressCountryAlfa2',
                    stateKey: 'address.addressCountryAlfa2',
                    component: A_COMMON_DATA_FIELD.SELECT_COUNTRIES,
                    disabled: !canEdit,
                },
                {
                    localizationKey: 'state',
                    stateKey: 'address.state',
                    component: isMexico(player?.address.addressCountryAlfa2)
                        ? A_COMMON_DATA_FIELD.SELECT_STATES
                        : 'text',
                    disabled: !canEdit,
                },
                {
                    localizationKey: 'city',
                    stateKey: 'address.city',
                    component: isMexico(player?.address.addressCountryAlfa2)
                        ? A_COMMON_DATA_FIELD.SELECT_CITIES
                        : 'text',
                    disabled: !canEdit,
                },
                {
                    localizationKey: 'zipCode',
                    stateKey: 'address.zipCode',
                    component: 'text',
                    disabled: !canEdit,
                },
                {
                    localizationKey: 'street',
                    stateKey: 'address.street',
                    component: 'text',
                    disabled: !canEdit,
                }
            ],
        [player, states]
    );

    return (
        <div className={`sub-content ${className}`}>
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={header_icon} alt="" />
                <span className="text-uppercase">{i18n.t('crm.address')}</span>
            </div>

            <Row xs={'auto'}>
                {fields.map((item, index) => {
                    return (
                        <Col key={index}>
                            <ACommonDataField
                                component={item.component}
                                label={i18n.t(`crm.${item.localizationKey}`)}
                                value={safeGet(player, item.stateKey.split('.'))}
                                handleChange={onChange(item.stateKey)}
                                disabled={item.disabled}
                            />
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
}

AddressSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default React.memo(AddressSubBlock);
