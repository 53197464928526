import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_REFERRALS_LIST,
} from "./actionTypes";

import {
    getPlayerReferralsListFail,
    getPlayerReferralsListSuccess,
} from "./actions";

import {
    getPlayerReferralsApi
} from '../../../helpers/playerApi';

function* fetchPlayerReferralsListSaga({ payload }) {
    try {
        const response = yield call(getPlayerReferralsApi, payload);

        yield put(getPlayerReferralsListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerReferralsListFail(error));
    }
}

function* playersReferralsSaga() {
    yield takeEvery(GET_PLAYER_REFERRALS_LIST, fetchPlayerReferralsListSaga);
}

export default playersReferralsSaga;
