import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function DataLegend({ data }) {
    return (
        <ul className="widget-legend">
            {data.map(item => {
                return (
                    <li
                        key={item.name}
                        onClick={item.onClick ? item.onClick : () => {}}
                        style={item.onClick ? { cursor: 'pointer' } : {}}
                    >
                        <div>
                            <div
                                className="legend-flag"
                                style={{ background: item.color }}
                            />
                        </div>
                        <div>
                            <div className="legend-name">{item.name}</div>
                            <div className="legend-value">{item.value}</div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

DataLegend.propTypes = {
    data: PropTypes.array.isRequired
};

export default React.memo(DataLegend);
