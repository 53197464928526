import i18n from "../i18n";

export const prepareTranslationAudit = (value) => {
    if (value === 'Player status is freeze until he explain us who is the owner of the money and make a KYC for him') {
        return i18n.t('audit.didntPassSofVerification')
    } else if (value === 'Freeze account because the player\'s funds, not his') {
        return i18n.t('audit.isntPlayerMoney')
    } else if (value === 'Player with same CURP already exists') {
        return i18n.t('audit.playerSameCurpExists')
    } else {
        return value
    }
}