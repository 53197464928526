import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import ATextField from '../../../../components/GenericComponents/InputField';

const CommonDataField = ({
    component,
    Component,
    containerValue,
    stateKey,
    isEdit,
    onEdit,
    state,
    setState,
    errorMessage,
    ...props
}) => {
    const handleChange = useCallback(
        key => value => setState({ ...state, [key]: value }),
        [state, setState]
    );

    return (
        <div className="content-row">
            {containerValue !== '-' && (
                <ATextField
                    label={i18n.t(`crm.${stateKey}`)}
                    value={state?.[stateKey]}
                    error={errorMessage}
                    handleChange={handleChange(stateKey)}
                />
            )}
        </div>
    );
};

CommonDataField.propTypes = {
    component: PropTypes.any,
    Component: PropTypes.any,
    errorMessage: PropTypes.string,
    isEdit: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    containerValue: PropTypes.any,
    stateKey: PropTypes.string.isRequired,
    setState: PropTypes.func.isRequired,
    state: PropTypes.objectOf(PropTypes.any)
};

export default React.memo(CommonDataField);
