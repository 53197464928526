import {
    GET_PLAYER_PROMOTIONS_LIST,
    GET_PLAYER_PROMOTIONS_LIST_SUCCESS,
    GET_PLAYER_PROMOTIONS_LIST_FAIL,
    APPLY_PLAYER_PROMOTION,
    APPLY_PLAYER_PROMOTION_SUCCESS,
    APPLY_PLAYER_PROMOTION_FAIL,
    CLOSE_PLAYER_PROMOTION,
    CLOSE_PLAYER_PROMOTION_SUCCESS,
    CLOSE_PLAYER_PROMOTION_FAIL,
    GET_PLAYER_SPORT_PROMOTIONS_LIST,
    GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS,
    GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL,
    APPLY_PLAYER_SPORT_PROMOTION,
    APPLY_PLAYER_SPORT_PROMOTION_SUCCESS,
    APPLY_PLAYER_SPORT_PROMOTION_FAIL,
    CLOSE_PLAYER_SPORT_PROMOTION,
    CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS,
    CLOSE_PLAYER_SPORT_PROMOTION_FAIL,
} from './actionTypes';
import {
    RESET_PROMOTION_ALERTS_ERRORS
} from "../../promotions/actionTypes";

export const getPlayerPromotionsList = payload => ({
    type: GET_PLAYER_PROMOTIONS_LIST,
    payload
});

export const getPlayerPromotionsListSuccess = payload => ({
    type: GET_PLAYER_PROMOTIONS_LIST_SUCCESS,
    payload
});

export const getPlayerPromotionsListFail = payload => ({
    type: GET_PLAYER_PROMOTIONS_LIST_FAIL,
    payload
});

export const applyPlayerPromotion = payload => ({
    type: APPLY_PLAYER_PROMOTION,
    payload
});

export const applyPlayerPromotionSuccess = payload => ({
    type: APPLY_PLAYER_PROMOTION_SUCCESS,
    payload
});

export const applyPlayerPromotionFail = payload => ({
    type: APPLY_PLAYER_PROMOTION_FAIL,
    payload
});

export const closePlayerPromotion = payload => ({
    type: CLOSE_PLAYER_PROMOTION,
    payload
});

export const closePlayerPromotionSuccess = payload => ({
    type: CLOSE_PLAYER_PROMOTION_SUCCESS,
    payload
});

export const closePlayerPromotionFail = payload => ({
    type: CLOSE_PLAYER_PROMOTION_FAIL,
    payload
});

export const resetPromotionAlertsErrors = payload => ({
    type: RESET_PROMOTION_ALERTS_ERRORS,
    payload
});

export const getPlayerSportPromotionsList = payload => ({
    type: GET_PLAYER_SPORT_PROMOTIONS_LIST,
    payload
});

export const getPlayerSportPromotionsListSuccess = payload => ({
    type: GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS,
    payload
});

export const getPlayerSportPromotionsListFail = payload => ({
    type: GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL,
    payload
});

export const applyPlayerSportPromotion = payload => ({
    type: APPLY_PLAYER_SPORT_PROMOTION,
    payload
});

export const applyPlayerSportPromotionSuccess = payload => ({
    type: APPLY_PLAYER_SPORT_PROMOTION_SUCCESS,
    payload
});

export const applyPlayerSportPromotionFail = payload => ({
    type: APPLY_PLAYER_SPORT_PROMOTION_FAIL,
    payload
});

export const closePlayerSportPromotion = payload => ({
    type: CLOSE_PLAYER_SPORT_PROMOTION,
    payload
});

export const closePlayerSportPromotionSuccess = payload => ({
    type: CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS,
    payload
});

export const closePlayerSportPromotionFail = payload => ({
    type: CLOSE_PLAYER_SPORT_PROMOTION_FAIL,
    payload
});