export const PROMOTIONS_TYPES = [
    'BonusWagering',
    'BonusWageringOnDeposit',
    'FreeSpins',
]

export const SPORT_PROMOTION_TYPES = [
    "deposit",
    "freeBet",
]
