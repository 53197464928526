import { GET_AGENT, GET_AGENT_SUCCESS, GET_AGENT_FAIL } from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    error: null,
    agent: null
};

const AgentDetailed = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AGENT:
            return { ...state, isLoading: true, error: null };

        case GET_AGENT_SUCCESS:
            return { ...state, agent: action.payload, isLoading: false };

        case GET_AGENT_FAIL:
            return { ...state, error: action.payload, isLoading: false };
        default:
            return state;
    }
};

export default AgentDetailed;
