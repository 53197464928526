import React, {useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import {Button, Container} from "reactstrap"
import ReportContainer from "./ReportContainer";
import i18n from "../../i18n"
import ACommonDataField from "../../components/GenericComponents/CommonDataField";
import {A_COMMON_DATA_FIELD} from "../../constants/aCommonDataField";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {getSync, patchSync} from "../../helpers/api_helper";
import useAlertService from "../../hooks/useAlertService";
import Loader from "../../common/components/Loader";
import {SITE_BRAND} from "../../constants/siteBrands";
import saveIcon from "../../assets/images/common/Frame 176 (1).svg";

function Dashboard(props) {
    const {agentSelf} = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];
    const {active: activeBrand} = useSelector(state => state.Brand);
    const isWinpot = activeBrand?.siteName === SITE_BRAND.WINPOTMX;
    const hasTaxPermission = agentPermissions.includes('Retail__MX_Tax') && isWinpot;
    const alertService = useAlertService();
    const [requestInProgress, setRequestInProgress] = React.useState(true);
    const [reportStatus, setReportStatus] = React.useState(false);
    const [lastStatusFromAPI, setLastStatusFromAPI] = React.useState(false);


    const handleChangeField = () => {
        setReportStatus(!reportStatus);
    }

    const handleSave = () => {
        setReportStatusAPI(reportStatus);
    }

    const getReportStatusAPI = async () => {
        if (!hasTaxPermission) return;
        setRequestInProgress(true);
        const url = `/site/mx/tax`;
        const result = await getSync(url, {});
        setRequestInProgress(false);
        setReportStatus(result?.data?.report);
        setLastStatusFromAPI(result?.data?.report);
    };

    const setReportStatusAPI = async (_reportStatus) => {
        const payload = {report: _reportStatus};
        if (!hasTaxPermission) return;
        const url = `/site/mx/tax`;
        setRequestInProgress(true);
        const result = await patchSync(alertService, url, payload);
        setRequestInProgress(false);
        const _result = result?.report;
        setReportStatus(_result);
        setLastStatusFromAPI(_result);
    };

    useEffect(() => {
        getReportStatusAPI();
    }, [hasTaxPermission]);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{i18n.t("crm.overview")}</title>
                </MetaTags>
                <Container fluid>
                    {hasTaxPermission &&
                        <>
                            {requestInProgress && <div/>}
                            {!requestInProgress &&
                                <>
                                    <ACommonDataField
                                        label={'Winpot Report Status (Do not change without CFO approval)'}
                                        component={A_COMMON_DATA_FIELD.TOGGLE}
                                        value={reportStatus}
                                        handleChange={handleChangeField}
                                        disabled={false}
                                    />

                                    <Button
                                        color={'primary modal-btn'}
                                        onClick={handleSave}
                                        disabled={reportStatus === lastStatusFromAPI}
                                    >
                                        <img src={saveIcon} alt={i18n.t('filter.save')}/>
                                        <span>{i18n.t('filter.save')}</span>
                                    </Button>

                                </>
                            }
                        </>
                    }

                    <ReportContainer/>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default React.memo(withRouter(Dashboard));
