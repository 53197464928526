import React from 'react';
import PropTypes from 'prop-types';

import './checkbox-input.scss';

function AppCheckmark({ checked, disabled }) {
    return (
        <svg
            className={`app-checkmark ${checked ? 'checked' : ''}`}
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 6.81278L4.44262 10.0421L13 1"
                stroke={disabled ? '#7d7dcc80' : '#5f5f8b'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function CheckboxInput({
    className,
    disabled,
    onChange,
    checked,
    children,
    reverse = false,
    wrapperClassName,
    ...props
}) {
    return (
        <div
            className={`${wrapperClassName ? ` ${wrapperClassName}` : ''}`}
        >
            <label
                style={{ width: '100%' }}
                className={`app-checkbox ${className ? ` ${className}` : ''}${
                    disabled ? ' disabled' : ''
                }`}
            >
                <span className="app-checkbox__control">
                    <input
                        {...props}
                        type="checkbox"
                        checked={checked || false}
                        onChange={e => onChange && onChange(e.target.checked)}
                        disabled={disabled}
                    />
                    <b className="checkmark" />
                    <AppCheckmark checked={checked} disabled={disabled} />
                </span>
                <span
                    className={`app-checkbox__label d-flex ${
                        checked ? 'label-checked' : ''
                    }`}
                >
                    {children}
                </span>
            </label>
        </div>
    );
}

CheckboxInput.propTypes = {
    checked: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    disabled: PropTypes.bool
};

export default React.memo(CheckboxInput);
