export const VENDORS = [
    'Blueprint',
    'Pariplay',
    'Amusnet Interactive',
    'Ezugi',
    'Vivo',
    'Ainsworth',
    'Kalamba',
    'Pragmatic Play',
    'Redrake',
    'Inspired',
    'Platipus',
    'Zitro',
    'Spribe',
    'Hacksaw',
    'Skywind',
    'Skywind (direct)',
    'Evolution',
    'Betsoft',
    'Booming Games',
    'Golden Race',
    'Netent',
    'Swint',
    'Spinomenal',
    'Spinomenal (direct)',
];
