export const GET_FILTERS_LIST = 'GET_FILTERS_LIST';
export const GET_FILTERS_LIST_SUCCESS = 'GET_FILTERS_LIST_SUCCESS';

export const REMOVE_FROM_FILTER_LIST = 'REMOVE_FROM_FILTER_LIST';

export const SAVE_FILTER = 'SAVE_FILTER';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';

export const ADD_CURRENT_FILTER = 'ADD_CURRENT_FILTER';
export const REMOVE_CURRENT_FILTER = 'REMOVE_CURRENT_FILTER';
export const REMOVE_CURRENT_FILTERS = 'REMOVE_CURRENT_FILTERS';
export const CLEAR_CURRENT_FILTER = 'CLEAR_CURRENT_FILTER';
export const EDIT_CURRENT_FILTER = 'EDIT_CURRENT_FILTER';

export const SELECT_FILTER = 'SELECT_FILTER';

export const FILTERS_ERROR = 'FILTERS_ERROR';
export const RESET_FILTERS_ALERTS_ERRORS = 'RESET_FILTERS_ALERTS_ERRORS';
