import { takeEvery, put, call } from 'redux-saga/effects';
import {
  DOWNLOAD_PENDING_WITHDRAWAL_LIST,
  GET_PENDING_WITHDRAWAL_LIST,
  GET_PENDING_WITHDRAWAL_COUNT,
} from './actionTypes';
import {
  getPendingWithdrawalListSuccess,
  getPendingWithdrawalListFail,
  downloadPendingWithdrawalListSuccess,
  downloadPendingWithdrawalListFail,
  getPendingWithdrawalListCountSuccess,
  getPendingWithdrawalListCountFail,
} from './actions';
import {
  downloadPaymentPendingWithdrawalListApi,
  getPaymentPendingWithdrawalListApi,
  getPaymentPendingWithdrawalListCountApi,
} from '../../../helpers/paymentApi';

function* fetchPendingWithdrawalListCountSaga({ payload }) {
  try {
    const response = yield call(getPaymentPendingWithdrawalListCountApi, payload);

    yield put(getPendingWithdrawalListCountSuccess(response.data));
  } catch (error) {
    yield put(getPendingWithdrawalListCountFail(error));
  }
}

function* fetchPendingWithdrawalListSaga({ payload }) {
  try {
    const response = yield call(getPaymentPendingWithdrawalListApi, payload);

    yield put(getPendingWithdrawalListSuccess(response.data));
  } catch (error) {
    yield put(getPendingWithdrawalListFail(error));
  }
}


function* downloadPendingWithdrawalListSaga({ payload }) {
  try {
    yield call(downloadPaymentPendingWithdrawalListApi, payload);

    yield put(downloadPendingWithdrawalListSuccess());
  } catch (error) {
    yield put(downloadPendingWithdrawalListFail(error));
  }
}


function* pendingWithdrawalsSaga() {
  yield takeEvery(GET_PENDING_WITHDRAWAL_COUNT, fetchPendingWithdrawalListCountSaga);
  yield takeEvery(GET_PENDING_WITHDRAWAL_LIST, fetchPendingWithdrawalListSaga);
  yield takeEvery(DOWNLOAD_PENDING_WITHDRAWAL_LIST, downloadPendingWithdrawalListSaga);
}

export default pendingWithdrawalsSaga;
