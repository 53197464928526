import {
    EDIT_FILTER_PAGE,
    CHANGE_FILTER_PAGE,
    GET_FILTER_PAGE_ERROR,
    GET_FILTERS_LIST_PAGE,
    REMOVE_FROM_FILTERS_PAGE,
    EDIT_FILTER_PAGE_SUCCESS,
    GET_FILTERS_LIST_PAGE_SUCCESS,
    REMOVE_FROM_FILTERS_PAGE_SUCCESS
} from './actionTypes';

export const getFiltersListPage = query => ({
    type: GET_FILTERS_LIST_PAGE,
    payload: query
});

export const getFiltersListPageSuccess = response => ({
    type: GET_FILTERS_LIST_PAGE_SUCCESS,
    payload: response
});

export const removeFromFilterPage = id => ({
    type: REMOVE_FROM_FILTERS_PAGE,
    payload: id
});

export const removeFromFilterPageSuccess = id => ({
    type: REMOVE_FROM_FILTERS_PAGE_SUCCESS,
    payload: id
});

export const editFilterPage = data => ({
    type: EDIT_FILTER_PAGE,
    payload: data
});

export const editFilterPageSuccess = response => ({
    type: EDIT_FILTER_PAGE_SUCCESS,
    payload: response
});

export const filterPageError = error => ({
    type: GET_FILTER_PAGE_ERROR,
    payload: error
});
