import {
    CREATE_MANUAL_ADJUSTMENT,
    CREATE_RETAIL_MANUAL_ADJUSTMENT,
    CREATE_MANUAL_ADJUSTMENT_SUCCESS,
    CREATE_MANUAL_ADJUSTMENT_FAIL,
    RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS,
    MASS_CASHBACK_APPLY,
    MASS_CASHBACK_APPLY_SUCCESS,
    MASS_CASHBACK_APPLY_FAIL,
    CASHBACK_APPLY,
    CASHBACK_APPLY_SUCCESS,
    CASHBACK_APPLY_FAIL,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL,
    MASS_INACTIVITY_FEE_APPLY,
    MASS_INACTIVITY_FEE_APPLY_SUCCESS,
    MASS_INACTIVITY_FEE_APPLY_FAIL, RESET_MASS_ADJUSTMENT_ERRORS,
} from './actionTypes';
import i18n from '../../../i18n';
import {RESET_AGENT_SELF_ALERTS_ERRORS} from "../../agents/self/actionTypes";

const INIT_STATE = {
    isLoading: false,
    isDownloading: false,
    isMassCashbackUploading: false,
    isCashbackUploading: false,
    error: null,
    successAlert: null,
};

const PlayerManualAdjustment = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_MANUAL_ADJUSTMENT:
            return {
                ...state,
                isLoading: true,
                error: null,
                successAlert: null,
            };

        case CREATE_RETAIL_MANUAL_ADJUSTMENT:
            return {
                ...state,
                isLoading: true,
                error: null,
                successAlert: null,
            };

        case CREATE_MANUAL_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                successAlert: i18n.t('crm.alerts.manualAdjustmentCreated')
            };

        case CREATE_MANUAL_ADJUSTMENT_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                successAlert: null,
            };

        case RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS:
            return {
                ...state,
                error: null,
                successAlert: null,
            };

        case MASS_CASHBACK_APPLY:
            return {
                ...state,
                isMassCashbackUploading: true,
                error: null,
                successAlert: null,
            };

        case MASS_CASHBACK_APPLY_SUCCESS:
            return {
                ...state,
                error: null,
                isMassCashbackUploading: false,
                successAlert: null,
            };

        case MASS_CASHBACK_APPLY_FAIL:
            return {
                ...state,
                error: action.payload,
                isMassCashbackUploading: false,
                successAlert: null,
            };

        case DOWNLOAD_INACTIVITY_FEE_TEMPLATE:
            return {
                ...state,
                isCashbackUploading: true,
                error: null,
                successAlert: null,
                isDownloading: true,
            };

        case DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isCashbackUploading: false,
                error: null,
                successAlert: 'templateDownloaded',
                isDownloading: false,
            };

        case DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL:
            return {
                ...state,
                error: null,
                successAlert: null,
                isCashbackUploading: false,
                isDownloading: false,
            };

        case MASS_INACTIVITY_FEE_APPLY:
            return {
                ...state,
                error: null,
                successAlert: null,
                isLoading: true,
            };

        case MASS_INACTIVITY_FEE_APPLY_SUCCESS:
            return {
                ...state,
                error: null,
                successAlert: 'feeInactivityApplied',
                isLoading: false,
            };

        case MASS_INACTIVITY_FEE_APPLY_FAIL:
            return {
                ...state,
                error: 'Unable to process the file due to errors',
                successAlert: null,
                isLoading: false,
            };

        case RESET_MASS_ADJUSTMENT_ERRORS:
            return { ...state, error: null, successAlert: null };

        default:
            return state;
    }
};

export default PlayerManualAdjustment;
