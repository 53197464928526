import React, { Component } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {DATE_TIME_FORMAT} from "../../common/utils/common";

const Sender = ({ openMessage, message }) => {

    const obj = JSON.parse(localStorage.getItem('authUser'));
    const name = obj && obj.username ? obj.username : message['name'];
    const momentTime = moment(message.time);

    const messagePopup = () => {
        openMessage(message.id);
    };

    return (
        <li className='right'>
            <div className='conversation-list'>
                {/*
                    <UncontrolledDropdown direction="left">
                        <DropdownToggle tag="a" className="dropdown-toggle">
                            <i className="bx bx-dots-vertical-rounded" />
                        </DropdownToggle>
                        <DropdownMenu direction="right">
                            <DropdownItem href="#">Copy</DropdownItem>
                            <DropdownItem href="#">Save</DropdownItem>
                            <DropdownItem href="#">Forward</DropdownItem>
                            <DropdownItem href="#">Delete</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
*/}
                <p className='chat-time mb-0'>
                    <i className='bx bx-time-five align-middle me-1' />{' '}
                    {momentTime.format(DATE_TIME_FORMAT)}
                </p>
                <div className='ctext-wrap'>
                    <div className='conversation-name'>{name}</div>
                    <p>{message.subject}</p>
                    <p
                        onClick={messagePopup}
                        className='chat-time mb-0'
                        style={{ fontWeight: '600', color: '#5f5f8b', textAlign: 'left', cursor: 'pointer' }}>Read
                        more</p>
                </div>
            </div>
        </li>
    );
};
export default withRouter(Sender);

