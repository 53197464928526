import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/inputs/common/SelectInput';
import i18n from '../../../i18n';
import { PAYMENT_RANGE_TYPE } from './attributionConstants';

function PaymentRangeSelect({ onChange, value, ...props }) {
    const options = useMemo(
        () =>
            Object.keys(PAYMENT_RANGE_TYPE).map(item => ({
                label: i18n.t(`attributions.constants.${item}`),
                value: item
            })),
        []
    );

    return (
        <SelectInput
            {...props}
            onChange={onChange}
            value={value}
            options={options}
        />
    );
}

PaymentRangeSelect.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(PaymentRangeSelect);
