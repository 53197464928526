import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../../../common/inputs/common/complicated-checkbox-input.scss';
import ComplicatedCheckboxSelect from '../../../common/inputs/common/ComplicatedCheckboxSelect';
import i18n from '../../../i18n';

const GAMES = [
    { id: 1, name: 'Game 1' },
    { id: 2, name: 'Game 2' },
    { id: 3, name: 'Game 3' },
    { id: 4, name: 'Game Online roulette 1' },
    { id: 5, name: 'Game Online roulette 2' },
    { id: 6, name: 'Game Online roulette 3' },
    { id: 7, name: 'Online Casino 1' },
    { id: 8, name: 'Online Casino 2' },
    { id: 9, name: 'Online Casino 3' }
];

function GameNameSelect({ onChange, value, ...props }) {
    const options = useMemo(() => {
        return GAMES.map(item => ({ value: item.id, label: item.name }));
    }, []);

    return (
        <ComplicatedCheckboxSelect
            value={value}
            onChange={onChange}
            options={options}
            placeholder={i18n.t('attributions.chooseGame')}
            {...props}
        />
    );
}

GameNameSelect.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(GameNameSelect);
