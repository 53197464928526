import React from "react"
import MetaTags from 'react-meta-tags'
import i18n from "../../i18n"

function Smartico() {
  return (
    <div className="player-list document-content page-content">
      <MetaTags>
        <title>{i18n.t("promotions.smartico")} - {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <iframe
          style={{width: '100%', height: '100vh'}}
          src={`https://drive.smartico.ai/#/login`}
          frameBorder="0"
      />
    </div>
  );
}

export default React.memo(Smartico);
