import React from 'react';
import PropTypes from 'prop-types';

import { CommonCard } from '../../CommonCard';
import close from '../../../assets/images/common/inactiveBranded.svg';

export const CommonFilter = ({ title, className, onCancel, children }) => (
    <CommonCard className={`common_filter ${className}`}>
        <div className="d-flex align-items-center common_filter-headline">
            <span>{title}</span>
            <button
                className="btn btn-rounded btn-light app-btn-only-img-xs ms-auto"
                onClick={onCancel}
            >
                <img src={close} alt="" />
            </button>
        </div>

        {children}
    </CommonCard>
);

CommonFilter.propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    className: PropTypes.string
};
