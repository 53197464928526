import {get, post, del, put, getFile, uploadFileToServer} from './api_helper';
import {prepareFilterSearch} from "./SearchUtils";

const getGamesApi = (payload) => {
    if (payload) {
        const { limit, offset } = payload;
        if (limit !== undefined && offset !== undefined) {
            const url = `/games?limit=${limit}&offset=${offset}`;
            return get(url);
        }
    }

    const url = `/games?limit=all`;
    return get(url);
};

const createGameApi = (gameData) => {
    const url = `/games`;
    return post(url, {...gameData});
};

const updateGameApi = ({gameId, ...gameData}) => {
    const url = `/games/game/${gameId}`;
    return put(url, {...gameData});
};

const bulkInsertGamesApi = ({games}) => {
    const url = `/games/bulk-insert`;
    return put(url, {games});
};

const uploadGamesDBToCreateApi = (file) => {
    const url = `/game-file-management/casino/games/create`;
    return uploadFileToServer(url, file);
};

const downloadGamesApi = ({query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `game-file-management/casino/games/export?${preparedQuery}`;
    return getFile(url);
};

const downloadGamesDBTemplateApi = () => {
    const url = `/game-file-management/casino/games/template`;
    return getFile(url);
};

const downloadGMTemplateApi = () => {
    const url = `/game-file-management/site/games/export/template`;
    return getFile(url);
};

const getAllGamesApi = () => {
    const url = `/games/all`;
    return get(url);
};

const getGameTypesApi = () => {
    const url = `/games/types`;
    return get(url);
};

const getGameCategoriesApi = () => {
    const url = `/games/categories`;
    return get(url);
};

const createGameCategoryApi = (categoryData) => {
    const url = `/games/categories`;
    return post(url, categoryData);
};

const getGameCategoryApi = (id) => {
    const url = `/games/categories/${id}`;
    return get(url);
};

const getCategoryGamesApi = (id) => {
    const url = `/games/category/${id}`;
    return get(url);
};

const changeCategoryGamesApi = ({id, games}) => {
    const url = `/games/category/${id}`;
    return put(url, {games});
};

const changeCategoryGameApi = ({id, gameId, priority, emphasize}) => {
    const url = `/games/category/${id}/games/${gameId}`;
    return put(url, {priority, emphasize});
};

const changeCategoryNamesApi = ({id, names}) => {
    const url = `/games/category/${id}/data`;
    return put(url, {...names});
};

const removeCategoryApi = (id) => {
    const url = `/games/categories/${id}`;
    return del(url);
};

const removeCategoryGameApi = ({id, gameId}) => {
    const url = `/games/category/${id}/games/${gameId}`;
    return del(url);
};

const bulkUpdateCategoryGamesApi = ({games, id}) => {
    const url = `/games/category/${id}/games/bulk-update`;
    return put(url, {games});
};

const updateGMGamesApi = (file) => {
    const url = `/game-file-management/site/games/update`;
    return uploadFileToServer(url, file);
};

const updateDBGamesApi = (file) => {
    const url = `/game-file-management/casino/games/update`;
    return uploadFileToServer(url, file);
};

const downloadCategoryGamesApi = ({id, query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/games/category/${id}/games/excel?${preparedQuery}`;
    return getFile(url);
};

const downloadGMGamesApi = ({query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `game-file-management/site/games/export?${preparedQuery}`;
    return getFile(url);
};

const purgeCacheGameCategoryApi = () => {
    const url = `/game-managment/site-games-pack/purge-cache`;
    return post(url, {});
};

const getSiteGamesApi = () => {
    const url = `/game-managment/site/games`;
    return get(url);
};

const getSiteAvailableGamesApi = () => {
    const url = `/game-managment/site/games/available`;
    return get(url);
};

const changeSiteGamesApi = ({games}) => {
    const url = `/game-managment/site/games`;
    return put(url, {games});
};

const bulkUpdateSiteGamesApi = (file) => {
    const url = `/game-file-management/site/games/add`;
    return uploadFileToServer(url, file);
};

const bulkUpdateSiteGameAttributionsApi = ({games}) => {
    const url = `/game-managment/site/games/attributions/bulk-update`;
    return put(url, {games});
};

const updateSiteGameApi = ({gameId, ...rest}) => {
    const url = `/game-managment/site/game/${gameId}`;
    return put(url, {...rest});
};

const removeSiteGameApi = ({gameId}) => {
    const url = `/game-managment/site/game/${gameId}`;
    return del(url);
};

const removeSiteGamesApi = ({siteId, config}) => {
    const url = `/game-managment/site/${siteId}/removeGames`;
    return del(url, config);
}

const downloadSiteGamesApi = ({query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/game-file-management/site/games/export?${preparedQuery}`;
    return getFile(url);
};

const downloadAvailableSiteGamesApi = ({query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/game-managment/site/games/available/excel?${preparedQuery}`;
    return getFile(url);
};

const downloadSiteGameAttributionsApi = ({query}) => {
    const preparedQuery = prepareFilterSearch(query);
    const url = `/game-managment/site/games/attributions/excel?${preparedQuery}`;
    return getFile(url);
};

const getGameVendorsApi = () => {
    const url = `/games/vendors`;
    return get(url);
};

const getGameProvidersApi = () => {
    const url = `/games/providers`;
    return get(url);
};

const getSiteProvidersApi = () => {
    const url = `/games/site-providers`;
    return get(url);
};

export {
    getGamesApi,
    createGameApi,
    updateGameApi,
    bulkInsertGamesApi,
    downloadGamesApi,
    downloadGamesDBTemplateApi,
    downloadGMTemplateApi,
    getAllGamesApi,
    getGameTypesApi,
    getGameCategoriesApi,
    getGameCategoryApi,
    getCategoryGamesApi,
    createGameCategoryApi,
    changeCategoryGamesApi,
    changeCategoryGameApi,
    changeCategoryNamesApi,
    removeCategoryApi,
    removeCategoryGameApi,
    bulkUpdateCategoryGamesApi,
    downloadCategoryGamesApi,
    purgeCacheGameCategoryApi,
    getSiteGamesApi,
    getSiteAvailableGamesApi,
    changeSiteGamesApi,
    bulkUpdateSiteGamesApi,
    bulkUpdateSiteGameAttributionsApi,
    updateSiteGameApi,
    removeSiteGameApi,
    removeSiteGamesApi,
    downloadSiteGamesApi,
    downloadAvailableSiteGamesApi,
    downloadSiteGameAttributionsApi,
    getGameVendorsApi,
    getGameProvidersApi,
    getSiteProvidersApi,
    downloadGMGamesApi,
    uploadGamesDBToCreateApi,
    updateGMGamesApi,
    updateDBGamesApi,
};
