import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_PROMOTIONS_LIST,
    APPLY_PLAYER_PROMOTION,
    CLOSE_PLAYER_PROMOTION,
    GET_PLAYER_SPORT_PROMOTIONS_LIST,
    APPLY_PLAYER_SPORT_PROMOTION,
    CLOSE_PLAYER_SPORT_PROMOTION,
} from './actionTypes';
import {
    getPlayerPromotionsListSuccess,
    getPlayerPromotionsListFail,
    applyPlayerPromotionSuccess,
    applyPlayerPromotionFail,
    closePlayerPromotionSuccess,
    closePlayerPromotionFail,
    getPlayerSportPromotionsListSuccess,
    getPlayerSportPromotionsListFail,
    applyPlayerSportPromotionSuccess,
    applyPlayerSportPromotionFail,
    closePlayerSportPromotionSuccess,
    closePlayerSportPromotionFail,
} from './actions';
import {
    getPlayerPromotionsListApi,
    applyPlayerPromotionApi,
    closePlayerPromotionApi,
    getPlayerSportPromotionsListApi,
    applyPlayerSportPromotionApi,
    closePlayerSportPromotionApi,
} from '../../../helpers/playerApi';

function* fetchPlayerPromotionsListSaga({ payload }) {
    try {
        const response = yield call(getPlayerPromotionsListApi, payload);

        yield put(getPlayerPromotionsListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerPromotionsListFail(error));
    }
}

function* applyPlayerPromotionSaga({ payload }) {
    try {
        const response = yield call(applyPlayerPromotionApi, payload);

        yield put(applyPlayerPromotionSuccess(response.data));
    } catch (error) {
        yield put(applyPlayerPromotionFail(error));
    }
}

function* closePlayerPromotionSaga({ payload }) {
    try {
        const response = yield call(closePlayerPromotionApi, payload);

        yield put(closePlayerPromotionSuccess(response.data));
    } catch (error) {
        yield put(closePlayerPromotionFail(error));
    }
}

function* fetchPlayerSportPromotionsListSaga({ payload }) {
    try {
        const response = yield call(getPlayerSportPromotionsListApi, payload);

        yield put(getPlayerSportPromotionsListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerSportPromotionsListFail(error));
    }
}

function* applyPlayerSportPromotionSaga({ payload }) {
    try {
        const response = yield call(applyPlayerSportPromotionApi, payload);

        yield put(applyPlayerSportPromotionSuccess(response.data));
    } catch (error) {
        yield put(applyPlayerSportPromotionFail(error));
    }
}

function* closePlayerSportPromotionSaga({ payload }) {
    try {
        const response = yield call(closePlayerSportPromotionApi, payload);

        yield put(closePlayerSportPromotionSuccess(response.data));
    } catch (error) {
        yield put(closePlayerSportPromotionFail(error));
    }
}

function* playersSaga() {
    yield takeEvery(GET_PLAYER_PROMOTIONS_LIST, fetchPlayerPromotionsListSaga);
    yield takeEvery(APPLY_PLAYER_PROMOTION, applyPlayerPromotionSaga);
    yield takeEvery(CLOSE_PLAYER_PROMOTION, closePlayerPromotionSaga);
    yield takeEvery(GET_PLAYER_SPORT_PROMOTIONS_LIST, fetchPlayerSportPromotionsListSaga);
    yield takeEvery(APPLY_PLAYER_SPORT_PROMOTION, applyPlayerSportPromotionSaga);
    yield takeEvery(CLOSE_PLAYER_SPORT_PROMOTION, closePlayerSportPromotionSaga);
}

export default playersSaga;
