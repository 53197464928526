import React, { useMemo } from 'react';
import * as moment from 'moment';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import parseShort from '../../../../helpers/parseShort';
import {DATE_TIME_FORMAT} from "../../../../common/utils/common";

const NoteSidebarItem = ({
    currencyTab,
    index,
    handleChangeCurrentPage,
    item,
    agentSelf
}) => {
    const isActiveTab = currencyTab === index;

    const agentName = useMemo(
        () =>
            !!item?.createdAgentName &&
            item.createdAgentName
                .split(' ')
                .map(item => item.slice(0, 1))
                .join(''),
        [item]
    );

    const getNameFromNote = (note) => {
       const { assigneeAgentName, createdAgentName, playerName } = note;

       if (assigneeAgentName === 'System' && createdAgentName === 'System') {
            return 'System'
       } else {
           return `${createdAgentName || ''} → ${playerName || ''}`;
       }
    }

    return (
        <Row
            className={`notes-sidebar-notes-item${
                isActiveTab ? ' active' : ''
            }`}
            onClick={() => handleChangeCurrentPage(index, item)}
        >
            {agentName && (
                <Col xs={3} className={'notes-sidebar-notes-item-img__wrapper'}>
                    <div className={'notes-sidebar-notes-item-img'}>
                        {agentName}
                    </div>
                </Col>
            )}
            <Col className={'p-0 d-flex flex-column'}>
                <div className={'notes-sidebar-notes-item-header'}>
                    <div className={'d-flex align-items-center'}>
                        #{item?.oid}
                        {item?.assigneeAgentOid?.toString() ===
                            agentSelf?.oid?.toString() && (
                            <i
                                className="bx bx-user"
                                style={{ color: '#366aa5' }}
                            />
                        )}
                    </div>
                </div>

                <p className={'notes-sidebar-notes-item-title'}>
                    {`${parseShort(item?.subject, 52)}`}
                </p>

                <span className={'notes-sidebar-notes-item-agent'}>
                    {getNameFromNote(item)}
                </span>

                <span className={'notes-sidebar-notes-item-createdAt'}>
                    {moment(new Date(item.createdAt))
                        .format(DATE_TIME_FORMAT)}
                </span>
            </Col>
        </Row>
    );
};

NoteSidebarItem.propTypes = {
    currencyTab: PropTypes.number,
    index: PropTypes.number,
    handleChangeCurrentPage: PropTypes.func,
    item: PropTypes.shape({
        oid: PropTypes.string,
        assigneeAgentOid: PropTypes.number,
        subject: PropTypes.string,
        assigneeAgentName: PropTypes.string,
        createdAgentName: PropTypes.string,
        createdAt: PropTypes.string
    }),
    agentSelf: PropTypes.object
};

export default React.memo(NoteSidebarItem);
