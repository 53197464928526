export const CREATE_MANUAL_ADJUSTMENT = 'CREATE_MANUAL_ADJUSTMENT';
export const CREATE_RETAIL_MANUAL_ADJUSTMENT = 'CREATE_RETAIL_MANUAL_ADJUSTMENT';
export const CREATE_MANUAL_ADJUSTMENT_SUCCESS =
    'CREATE_MANUAL_ADJUSTMENT_SUCCESS';
export const CREATE_MANUAL_ADJUSTMENT_FAIL = 'CREATE_MANUAL_ADJUSTMENT_FAIL';

export const RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS =
    'RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS';

export const MASS_CASHBACK_APPLY = 'MASS_CASHBACK_APPLY';
export const MASS_CASHBACK_APPLY_SUCCESS = 'MASS_CASHBACK_APPLY_SUCCESS';
export const MASS_CASHBACK_APPLY_FAIL = 'MASS_CASHBACK_APPLY_FAIL';

export const DOWNLOAD_INACTIVITY_FEE_TEMPLATE = 'DOWNLOAD_INACTIVITY_FEE_TEMPLATE';
export const DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS = 'DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS';
export const DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL = 'DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL';

export const MASS_INACTIVITY_FEE_APPLY = 'MASS_INACTIVITY_FEE_APPLY';
export const MASS_INACTIVITY_FEE_APPLY_SUCCESS = 'MASS_INACTIVITY_FEE_APPLY_SUCCESS';
export const MASS_INACTIVITY_FEE_APPLY_FAIL = 'MASS_INACTIVITY_FEE_APPLY_FAIL';

export const RESET_MASS_ADJUSTMENT_ERRORS =
    'RESET_MASS_ADJUSTMENT_ERRORS';
