import { useState } from 'react';

export default function useWorkflowEdit() {
    const [isEdit, setIsEdit] = useState(false);

    return {
        isEdit,
        setIsEdit
    };
}
