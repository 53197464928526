import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './status.scss';
import { STATUS } from '../../constants/status';
import { useTranslation } from "react-i18next";

function StatusView({ status, onClick }) {
    const { t } = useTranslation();

    const iconColor = useMemo(() => {
        switch (status) {
            case STATUS.ACTIVE:
                return '#37A57E';
            case STATUS.LEAD:
                return '#62A6E4';
            case STATUS.FROZEN:
            case STATUS.BLOCKED:
            case STATUS.SOFT_BLOCK:
                return '#A2A2B9';
            case STATUS.CLOSED:
                return '#E44F73';
            case STATUS.COMPLETE_PROFILE:
                return '#62A6E4';
            default:
                return '#A2A2B9';
        }
    }, [status]);

    return (
        <span
            className={`app-status ${status}`}
            onClick={onClick}
            role="button"
        >
            {t(`crm.status.${status}`)}
            &nbsp;
            <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1 1L5.5 6L10 1"
                    stroke={iconColor}
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </span>
    );
}

StatusView.propTypes = {
    status: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default React.memo(StatusView);
