import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

const CopyIcon = ({size = '24'}) => {
    const [isHover, setIsHover] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const backgroundColor = useMemo(() => {
        if (isHover || isActive) {
            return '#E1E1E9';
        }
        return '#ECECF2';
    }, [isHover, isActive]);
    const color = useMemo(() => {
        if (isActive) {
            return '#FFFFFF';
        } else if (isHover) {
            return '#8D8DA6';
        }
        return '#A4A4B8';
    }, [isHover, isActive]);

    return (
        <svg
            width={size || "24"}
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onMouseDown={() => setIsActive(true)}
            onMouseUp={() => setIsActive(false)}
            onMouseLeave={() => setIsHover(false)}
            onMouseOver={() => setIsHover(true)}
        >
            <circle cx="12" cy="12" r="12" fill={backgroundColor} />
            <g clipPath="url(#clip0)">
                <path
                    d="M14.1172 18H7.86722C7.1087 18 6.49219 17.3835 6.49219 16.6249V9.87494C6.49219 9.11642 7.1087 8.5 7.86722 8.5H8.11716C8.32416 8.5 8.49216 8.668 8.49216 8.875C8.49216 9.082 8.32416 9.25 8.11716 9.25H7.86722C7.52271 9.25 7.24219 9.53043 7.24219 9.87494V16.6249C7.24219 16.9695 7.52271 17.25 7.86722 17.25H14.1172C14.4617 17.25 14.7422 16.9695 14.7422 16.6249V16.375C14.7422 16.168 14.9102 16 15.1172 16C15.3242 16 15.4922 16.168 15.4922 16.375V16.6249C15.4922 17.3835 14.8757 18 14.1172 18Z"
                    fill={color}
                />
                <path
                    d="M16.1172 15H10.8672C10.1087 15 9.49219 14.3835 9.49219 13.625V7.37503C9.49219 6.61652 10.1087 6 10.8672 6H16.1172C16.8757 6 17.4922 6.61652 17.4922 7.37503V13.625C17.4922 14.3835 16.8757 15 16.1172 15ZM10.8672 6.75C10.5227 6.75 10.2422 7.03052 10.2422 7.37503V13.625C10.2422 13.9695 10.5227 14.25 10.8672 14.25H16.1172C16.4617 14.25 16.7422 13.9695 16.7422 13.625V7.37503C16.7422 7.03052 16.4617 6.75 16.1172 6.75H10.8672Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(6 6)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

const CopyButton = props => {
    const { text, className, onClick, size } = props;

    const [isShowAlert, setShowAlert] = useState(false);

    const handleAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 1000);
    };

    const handleCopy = event => {
        event.stopPropagation();
        event.preventDefault();

        text ? navigator.clipboard.writeText(text) : onClick();
    };

    const handleClick = async event => {
        await handleCopy(event);

        handleAlert();
    };

    return (
        <div className="app-copy-icon-container" onClick={handleClick}>
            <CopyIcon size={size} />

            <span className={`copy-tooltip ${isShowAlert ? 'show' : ''}`}>
                {i18n.t('common.copied')}
            </span>
        </div>
    );
};

CopyButton.propTypes = {
    text: PropTypes.any.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func
};

CopyButton.defaultProps = {
    text: '',
    className: ''
};

export default CopyButton;
