export const GET_CASHIER_LIST = 'GET_CASHIER_LIST';
export const GET_CASHIER_LIST_SUCCESS = 'GET_CASHIER_LIST_SUCCESS';
export const GET_CASHIER_LIST_FAIL = 'GET_CASHIER_LIST_FAIL';
export const GET_CASHIER_METHOD = 'GET_CASHIER_METHOD';
export const GET_CASHIER_METHOD_SUCCESS = 'GET_CASHIER_METHOD_SUCCESS';
export const GET_CASHIER_METHOD_FAIL = 'GET_CASHIER_METHOD_FAIL';
export const UPDATE_CASHIER_METHOD = 'UPDATE_CASHIER_METHOD';
export const UPDATE_CASHIER_METHOD_SUCCESS = 'UPDATE_CASHIER_METHOD_SUCCESS';
export const UPDATE_CASHIER_METHOD_FAIL = 'UPDATE_CASHIER_METHOD_FAIL';
export const CREATE_CASHIER_METHOD = 'CREATE_CASHIER_METHOD';
export const CREATE_CASHIER_METHOD_SUCCESS = 'CREATE_CASHIER_METHOD_SUCCESS';
export const CREATE_CASHIER_METHOD_FAIL = 'CREATE_CASHIER_METHOD_FAIL';
export const GET_SUPPLIER_BY_ID = 'GET_SUPPLIER_BY_ID';
export const GET_SUPPLIER_BY_ID_SUCCESS = 'GET_SUPPLIER_BY_ID_SUCCESS';
export const GET_SUPPLIER_BY_ID_FAIL = 'GET_SUPPLIER_BY_ID_FAIL';
export const UPDATE_CASHIER_SUPPLIER = 'UPDATE_CASHIER_SUPPLIER';
export const UPDATE_CASHIER_SUPPLIER_SUCCESS = 'UPDATE_CASHIER_SUPPLIER_SUCCESS';
export const UPDATE_CASHIER_SUPPLIER_FAIL = 'UPDATE_CASHIER_SUPPLIER_FAIL';
export const CREATE_CASHIER_SUPPLIER = 'CREATE_CASHIER_SUPPLIER';
export const CREATE_CASHIER_SUPPLIER_SUCCESS = 'CREATE_CASHIER_SUPPLIER_SUCCESS';
export const CREATE_CASHIER_SUPPLIER_FAIL = 'CREATE_CASHIER_SUPPLIER_FAIL';
export const GET_SUPPLIER_LIST = 'GET_SUPPLIER_LIST';
export const GET_SUPPLIER_LIST_SUCCESS = 'GET_SUPPLIER_LIST_SUCCESS';
export const GET_SUPPLIER_LIST_FAIL = 'GET_SUPPLIER_LIST_FAIL';
export const REMOVE_METHOD_BY_ID = 'REMOVE_METHOD_BY_ID';
export const REMOVE_METHOD_BY_ID_SUCCESS = 'REMOVE_METHOD_BY_ID_SUCCESS';
export const REMOVE_METHOD_BY_ID_FAIL = 'REMOVE_METHOD_BY_ID_FAIL';
export const REMOVE_SUPPLIER_BY_ID = 'REMOVE_SUPPLIER_BY_ID';
export const REMOVE_SUPPLIER_BY_ID_SUCCESS = 'REMOVE_SUPPLIER_BY_ID_SUCCESS';
export const REMOVE_SUPPLIER_BY_ID_FAIL = 'REMOVE_SUPPLIER_BY_ID_FAIL';