import { takeEvery, put, call } from 'redux-saga/effects';
import {
  DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST,
  GET_PENDING_TRANSFER_WITHDRAWAL_LIST,
  GET_TRANSFER_WITHDRAWAL_COUNT,
} from './actionTypes';
import {
  getPendingTransferWithdrawalListSuccess,
  getPendingTransferWithdrawalListFail,
  downloadPendingTransferWithdrawalListSuccess,
  downloadPendingTransferWithdrawalListFail,
  getTransferWithdrawalListCountSuccess,
  getTransferWithdrawalListCountFail,
} from './actions';
import {
  downloadPaymentPendingTransferWithdrawalListApi,
  getPaymentPendingTransferWithdrawalListApi,
  getTransferWithdrawalListCountApi,
} from '../../../helpers/paymentApi';

function* fetchTransferWithdrawalListCountSaga({ payload }) {
  try {
    const response = yield call(getTransferWithdrawalListCountApi, payload);

    yield put(getTransferWithdrawalListCountSuccess(response.data));
  } catch (error) {
    yield put(getTransferWithdrawalListCountFail(error));
  }
}

function* fetchPendingTransferWithdrawalListSaga({ payload }) {
  try {
    const response = yield call(getPaymentPendingTransferWithdrawalListApi, payload);

    yield put(getPendingTransferWithdrawalListSuccess(response.data));
  } catch (error) {
    yield put(getPendingTransferWithdrawalListFail(error));
  }
}


function* downloadPendingTransferWithdrawalListSaga({ payload }) {
  try {
    yield call(downloadPaymentPendingTransferWithdrawalListApi, payload);

    yield put(downloadPendingTransferWithdrawalListSuccess());
  } catch (error) {
    yield put(downloadPendingTransferWithdrawalListFail(error));
  }
}


function* pendingWithdrawalsSaga() {
  yield takeEvery(GET_TRANSFER_WITHDRAWAL_COUNT, fetchTransferWithdrawalListCountSaga);
  yield takeEvery(GET_PENDING_TRANSFER_WITHDRAWAL_LIST, fetchPendingTransferWithdrawalListSaga);
  yield takeEvery(DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST, downloadPendingTransferWithdrawalListSaga);
}

export default pendingWithdrawalsSaga;
