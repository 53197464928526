import React from 'react';
import i18n from "../../../i18n";
import FieldData from "../../../common/components/FieldData";
import moment from "moment/moment";
import { DATE_FORMAT, TIME_FORMAT_hh_mm_ss } from "../../../common/utils/common";
import { bytesToMegaBytes } from "../../../components/DynamicTable/utils";

const DocMetaDate = (props) => {
    const {currDocument} = props


    return (
       <div className='doc-meta-data'>
                   <div className='d-flex flex-column'>
                       <FieldData
                           key="documentExpirationTime"
                           name={i18n.t('crm.fileName')}
                           value={currDocument.fileName}
                           className={'ml-0'}
                           bold={true}
                       />
                       <FieldData
                           key="lastChange"
                           name={i18n.t('crm.lastChange')}
                           value={
                               <span>
                                    {moment(currDocument.lastChangedAt).format(
                                        DATE_FORMAT
                                    )}
                                   <span className="time-data ms-2">
                                        {moment(currDocument.lastChangedAt).format(
                                            TIME_FORMAT_hh_mm_ss
                                        )}
                                    </span>
                                </span>
                           }
                           className={'ml-0'}
                           bold={true}
                       />
                       <FieldData
                           key="systemId"
                           name={i18n.t('crm.systemId')}
                           value={`${currDocument.oid} / ${currDocument.id}`}
                           bold={true}
                           className={'ml-0'}
                       />
                       <FieldData
                           key="fileSize"
                           name={i18n.t('crm.fileSize')}
                           value={currDocument.fileSize > 0 ? `${bytesToMegaBytes(currDocument.fileSize)} Mb` : '-'}
                           bold={true}
                           className={'ml-0'}
                       />
                   </div>
       </div>
    )
}

export default DocMetaDate;
