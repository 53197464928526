import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import i18n from '../../i18n';
import { capitalize } from '../utils/common';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

import thinWhiteCross from '../../assets/images/layout/thin-white-cross.svg';
import thinCross from '../../assets/images/layout/thin-cross.svg';
import thinSubmit from '../../assets/images/layout/thin-submit.svg';
import thinEdit from '../../assets/images/layout/thin-edit.svg';
import garbage from '../../assets/images/layout/garbage-1.svg';
import close from '../../assets/images/layout/close-btn.svg';

import './styles.scss';

const getBtnImg = text => {
    switch (text) {
        case 'decline':
            return thinWhiteCross;
        case 'remove':
            return garbage;
        case 'edit':
            return thinEdit;
        case 'rename':
            return null;
        default:
            return thinSubmit;
    }
};

const CustomModal = props => {
    const {
        isOpen,
        onToggle,
        onClick,
        onClickCancelButton,
        className,
        titleText,
        bodyRender,
        btnText,
        cancelBtnText,
        btnDisabled,
        withCancelButton,
        withApplyButton = true,
        withFooter
    } = props;

    const closeBtn = (
        <div
            className="close"
            onClick={onToggle}
            style={{
                background: `transparent url(${close}) center`
            }}
        />
    );

    const btnImg = getBtnImg(btnText);

    const handleBtnClick = event => {
        onClick(event);
        event.target.blur();
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggle}
            className={`custom-modal ${className}`}
            backdropClassName="modal-fade-bgnd"
            // size="sm"
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <ModalHeader toggle={onToggle} close={closeBtn}>
                {titleText}
            </ModalHeader>

            <ModalBody>{bodyRender()}</ModalBody>

            {withFooter && (
                <ModalFooter>
                    {withCancelButton && (
                        <Button
                            className="cancel-btn"
                            disabled={btnDisabled}
                            color="secondary"
                            onClick={onClickCancelButton}
                            outline={true}
                        >
                            <img
                                alt=""
                                src={thinCross}
                                width={16}
                                height={16}
                            />
                            {capitalize(i18n.t(`finance.${cancelBtnText}`))}
                        </Button>
                    )}

                    {withApplyButton &&
                        <Button
                            disabled={btnDisabled}
                            color="primary"
                            onClick={handleBtnClick}
                        >
                            {btnImg && (
                                <img
                                    alt=""
                                    src={getBtnImg(btnText)}
                                    width={16}
                                    height={16}
                                />
                            )}
                            {capitalize(i18n.t(`finance.${btnText}`))}
                        </Button>
                    }
                </ModalFooter>
            )}
        </Modal>
    );
};

CustomModal.propTypes = {
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    onClickCancelButton: PropTypes.func,
    className: PropTypes.string,
    titleText: PropTypes.string,
    bodyRender: PropTypes.func,
    btnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    btnDisabled: PropTypes.bool,
    withCancelButton: PropTypes.bool,
    withFooter: PropTypes.bool
};

CustomModal.defaultProps = {
    isOpen: () => {},
    onToggle: () => {},
    onClick: () => {},
    onClickCancelButton: () => {},
    bodyRender: () => {},
    className: '',
    titleText: '',
    btnText: 'Ok',
    cancelBtnText: 'Cancel',
    btnDisabled: false,
    withCancelButton: false,
    withFooter: true
};

export default CustomModal;
