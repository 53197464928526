import queryString from 'query-string';
import { getType } from '../pages/Admin/site/dataAdapters';

function prepareQueryFilterDynamic(filterDynamic) {
    const groupedByKey = filterDynamic.reduce((acc, cur) => {
        if (acc[cur.key]) {
            acc[cur.key] = [...acc[cur.key], cur.value];
        } else {
            acc[cur.key] = [cur.value];
        }

        return acc;
    }, {});

    return Object.keys(groupedByKey).map(key => {
        let resultValue = `${key}::`;

        // array
        if (
            groupedByKey[key].length === 1 &&
            getType(groupedByKey[key][0]) === 'object'
        ) {
            const value = groupedByKey[key][0];
            if (value.hasOwnProperty('from') && value.hasOwnProperty('to')) {
                resultValue += `${value.from}||${value.to}`;
            } else {
                const valueKey = Object.keys(value);
                resultValue += `${valueKey[0]}||${value[valueKey[0]]}`;
            }
        } else if (groupedByKey[key].length > 1) {
            resultValue += groupedByKey[key].join();
        } else {
            resultValue += groupedByKey[key][0];
        }
        return resultValue;
    });
}

function trimQueryFilterDynamic(query) {
    const result = {};

    Object.keys(query).forEach(key => {
        const value = query[key];

        if (getType(value) === 'array' && key === 'filterDynamic') {
            result[key] = prepareQueryFilterDynamic(value);
        } else if (typeof value === 'number' && !isNaN(value)) {
            result[key] = value;
        } else if (typeof value === 'string') {
            if (value.trim()) {
                result[key] = value.trim();
            }
        }
    });

    return result;
}

/**
 * changeFilterConditionKeys - this function works in place
 * @param query
 */
export function changeFilterConditionKeys(query) {
    const tabPromoIdCond = query?.filterDynamic?.find((cond) => cond.key === 'tab_promotion_id');
    if (tabPromoIdCond) {
        tabPromoIdCond.key = 'promotion_id';
    }
}

export function prepareFilterSearch(...queries) {
    return queryString.stringify(
        trimQueryFilterDynamic(Object.assign({}, ...queries)),
        { arrayFormat: 'bracket' }
    );
}

function trimQuery(query) {
    const result = {};
    Object.keys(query).forEach(key => {
        const value = query[key];

        if (typeof value === 'string') {
            if (value.trim()) {
                result[key] = value.trim();
            }
        } else if (typeof value === 'number' && !isNaN(value)) {
            result[key] = value;
        } else if (getType(value) === 'array') {
            result[key] = value.map(({ key, value }) => `${key}::${value}`);
        } else if (value instanceof Object) {
            result[key] = value;
        } else if (typeof value === 'boolean') {
            result[key] = value;
        }
    });
    return result;
}

export function prepareSearch(...queries) {
    return queryString.stringify(trimQuery(Object.assign({}, ...queries)), {
        arrayFormat: 'bracket'
    });
}
