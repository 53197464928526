import {
    GET_WITHDRAWAL,
    GET_WITHDRAWAL_SUCCESS,
    GET_WITHDRAWAL_FAIL,
    RESET_WITHDRAWAL_ALERTS_ERRORS
} from './actionTypes';

export const getWithdrawal = payload => ({
    type: GET_WITHDRAWAL,
    payload
});

export const getWithdrawalSuccess = payload => ({
    type: GET_WITHDRAWAL_SUCCESS,
    payload
});

export const getWithdrawalFail = error => ({
    type: GET_WITHDRAWAL_FAIL,
    payload: error
});

export const resetWithdrawalAlertsErrors = error => ({
    type: RESET_WITHDRAWAL_ALERTS_ERRORS,
    payload: error
});
