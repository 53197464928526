import { useTranslation } from "react-i18next";
import { useTimeContext } from "../../../../context/TimeContext";
import { getLocalStorageFilter } from "../../../../store/filters/reducer";
import useWindowSize from "../../../../hooks/useWindowSize";
import useUserConfig from "../../../../hooks/useUserConfig";
import React, { memo, useEffect, useState } from "react";
import TableFilter from "../../../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_SPORT_BETS } from "../../../../constants/Filter";
import NewCustomTable from "../../../../components/NewCustomTable";
import clsx from "clsx";
import { SCREEN_SIZES } from "../../../../utils/consts";
import Formatter from "../../../../utils/Formatter";
import { getColumnSettingsLsKey, getGuidShortFormat, initTableColumns } from "../../../../common/utils/common";
import ClipboardButton from "../../../../common/cellData/CopyButton";
import { ClipboardButtonCell } from "../GamePlayTabContent";
import i18n from "../../../../i18n";
import { useLocation } from "react-router-dom";
import TableSettingsModal from "../../modal/TableSettingsModal";

const COLUMN_IDS = {
    ID: 'ID',
    status: 'status',
    startTime: 'startTime',
    endTime: 'endTime',
    openBalance: 'openBalance',
    closeBalance: 'closeBalance',
    externalRoundId: 'externalRoundId',
    provider: 'provider',
    site: 'site',
    siteProvider: 'siteProvider',
    totalBet: 'totalBet',
    totalWin: 'totalWin',
    totalPromoBet: 'totalPromoBet',
    totalPromoWin: 'totalPromoWin',
    totalRealBet: 'totalRealBet',
    totalRealWin: 'totalRealWin',
};

const getDefaultColumns = (canViewBetBalances) => {
    let defaultColumns = [
        COLUMN_IDS.ID,
        COLUMN_IDS.status,
        COLUMN_IDS.startTime,
        COLUMN_IDS.endTime,
        COLUMN_IDS.externalRoundId,
        COLUMN_IDS.provider,
        COLUMN_IDS.site,
        COLUMN_IDS.siteProvider,
        COLUMN_IDS.totalBet,
        COLUMN_IDS.totalWin,
        COLUMN_IDS.totalPromoBet,
        COLUMN_IDS.totalPromoWin,
        COLUMN_IDS.totalRealBet,
        COLUMN_IDS.totalRealWin,
    ];

    if (canViewBetBalances) {
        defaultColumns = defaultColumns.concat([
            COLUMN_IDS.openBalance,
            COLUMN_IDS.closeBalance,
        ]);
    }
    return defaultColumns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.ID]: 'crm.ID',
    [COLUMN_IDS.status]: 'crm.status',
    [COLUMN_IDS.startTime]: 'crm.startTime',
    [COLUMN_IDS.endTime]: 'crm.endTime',
    [COLUMN_IDS.externalRoundId]: 'crm.externalRoundId',
    [COLUMN_IDS.provider]: 'crm.provider',
    [COLUMN_IDS.site]: 'crm.site',
    [COLUMN_IDS.siteProvider]: 'crm.siteProvider',
    [COLUMN_IDS.totalBet]: 'crm.totalBet',
    [COLUMN_IDS.totalWin]: 'crm.totalWin',
    [COLUMN_IDS.totalPromoBet]: 'crm.totalPromoBet',
    [COLUMN_IDS.totalPromoWin]: 'crm.totalPromoWin',
    [COLUMN_IDS.totalRealBet]: 'crm.totalRealBet',
    [COLUMN_IDS.totalRealWin]: 'crm.totalRealWin',
    [COLUMN_IDS.openBalance]: 'crm.openBalance',
    [COLUMN_IDS.closeBalance]: 'crm.closeBalance',
};

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                }) => {
    const size = useWindowSize();
    const { adjustAmountWithUserCurrency } = useUserConfig();

    switch (field) {
        case COLUMN_IDS.ID:
            return <>
                <ClipboardButtonCell text={item.id} />
            </>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.status')}: </span>
                )}
                { i18n.t(`crm.status.${item.status.charAt(0) + item.status.slice(1).toLowerCase()}`)}
            </td>
        case COLUMN_IDS.startTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.startTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.createdAt))}
            </td>
        case COLUMN_IDS.endTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.endTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.completedAt))}
            </td>
        case COLUMN_IDS.externalRoundId:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.externalRoundId')}: </span>
                )}
                {item.externalRoundId}
            </td>
        case COLUMN_IDS.provider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <div className="responsiveTable__row">
                        <span>{i18n.t('crm.provider')}: </span>
                        <div>
                            {getGuidShortFormat(item?.provider) || '—'}
                            <ClipboardButton text={item?.provider} />
                        </div>
                    </div>
                ) : (
                    item.provider || '—'
                )}
            </td>
        case COLUMN_IDS.site:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <div className="responsiveTable__row">
                        <span>{i18n.t('crm.site')}: </span>
                        <div>
                            {getGuidShortFormat(item?.site) || '—'}
                            <ClipboardButton text={item?.site} />
                        </div>
                    </div>
                ) : (
                    item.site || '—'
                )}
            </td>
        case COLUMN_IDS.siteProvider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <div className="responsiveTable__row">
                        <span>{i18n.t('crm.siteProvider')}: </span>
                        <div>
                            {getGuidShortFormat(item?.siteProvider) || '—'}
                            <ClipboardButton text={item?.siteProvider} />
                        </div>
                    </div>
                ) : (
                    item.siteProvider || '—'
                )}
            </td>
        case COLUMN_IDS.totalBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalWin:
            return <td className='title__top'>
                {`${Formatter.roundToDecimalValue(item.totalWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalPromoBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalPromoBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalPromoBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalPromoWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalPromoWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalPromoWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalRealBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalRealBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalRealBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalRealWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalRealWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalRealWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.openBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.openBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.openBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.closeBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.closeBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.closeBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        default:
            return null;
    }
};

function BetSlipTable(props) {
    const {
        list,
        agentSelf,
        isShowTableSettings,
        toggleTableSettings,
        isLoadingGamePlay,
    } = props;
    const { i18n, t } = useTranslation();
    const { timeFormat } = useTimeContext();
    const filterValues = getLocalStorageFilter();
    const canViewBetBalances = agentSelf?.permissions?.includes('Player__View_Bets_Balance');
    const [activeRow, setActiveRow] = useState(false);
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns(canViewBetBalances));
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns(canViewBetBalances));
    const location = useLocation();

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);


    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        toggleTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    if (list?.length === 0 && !isLoadingGamePlay && !filterValues.length) {
        return <div className='noGamePlayData'>
            {i18n.t('tabs.NoSportBets')}
        </div>
    }

    const handleToggle = (index, activeRow) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            {(list?.length > 0 || filterValues?.length > 0) && (
                <div className="page-title-table player-view-tab mb-3">
                    <TableFilter
                        filterListOptions={FILTER_MENU_SPORT_BETS(t)}
                        isDisabled={false}
                        category={FILTER_CATEGORY.GAME_PLAY}
                    />
                </div>
            )}

            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index, activeRow)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                            />
                        ))}
                    </tr>
                ))}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={toggleTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    );
}

export default memo(BetSlipTable);