export const CATEGORISATION = [
    'Retail',
    'ElectiveRetail',
    'ProfessionalEligible',
    'ProfessionalApplicationRejected',
    'ProfessionalRequested',
    'ProfessionalApplicationSubmitted'
];

export const KYC_REQUIRED = {
    'Required': true,
    'NonRequired': false,
}

export const BOOLEAN = {
    'Yes': true,
    'No': false,
}

export const CASHIER_BOOLEAN = {
    'Yes': true,
    'No': false,
    'Empty': null,
}
