import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_PLAYER_JOINT, UPDATE_PLAYER_JOINT } from './actionTypes';
import {
    getPlayerJointSuccess,
    getPlayerJointFail,
    updatePlayerJointSuccess,
    updatePlayerJointFail
} from './actions';
import {
    getPlayerJointApi,
    updatePlayerJointApi
} from '../../../helpers/playerApi';

function* fetchPlayerJointSaga({ payload }) {
    try {
        const response = yield call(getPlayerJointApi, payload);

        yield put(getPlayerJointSuccess(response.data));
    } catch (error) {
        yield put(getPlayerJointFail(error));
    }
}

function* updatePlayerJointSaga({ payload }) {
    try {
        const response = yield call(updatePlayerJointApi, payload);

        yield put(
            updatePlayerJointSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updatePlayerJointFail(error));
    }
}

function* playerJointSaga() {
    yield takeEvery(GET_PLAYER_JOINT, fetchPlayerJointSaga);
    yield takeEvery(UPDATE_PLAYER_JOINT, updatePlayerJointSaga);
}

export default playerJointSaga;
