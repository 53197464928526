import React from 'react';
import GoogleLogin from 'react-google-login';
import {GOOGLE_AUTH_CLIENT_ID} from "../../common/constants/common";

function GoogleButton({onSuccess}) {
    const handleFailure = (...args) => {
        console.log({args});
    };

    return (
        <GoogleLogin
            style={{width: "100%"}}
            clientId={GOOGLE_AUTH_CLIENT_ID}
            buttonText="Login here with your internal account"
            onSuccess={onSuccess}
            onFailure={handleFailure}
        />
    );
}

export default GoogleButton;