import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {Button, Card, Col, Row} from "reactstrap";

import listIcon from '../../../assets/images/retail/list.svg';
import "../styles.scss";
import useAlertService from "../../../hooks/useAlertService";
import { Table } from "@mui/material";
import { patchSync } from "../../../helpers/api_helper";
import CustomModal from "../../../common/components/CustomModal";
import ModalContentCurrentShift from "./ModalContentCurrentShift";
import Formatter from "../../../utils/Formatter";
import {useTimeContext} from "../../../context/TimeContext";
import Loader from "../../../common/components/Loader";
import { useTranslation } from "react-i18next";
import useWindowSize from 'hooks/useWindowSize';
import { SCREEN_SIZES } from 'utils/consts';
import clsx from 'clsx';

const CurrentShift = (props) => {
    const { shiftTransactions, setIsEditFinance } = props;
    const alertService = useAlertService();
    const areaRef = useRef();
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const { timeFormat } = useTimeContext();
    const { t } = useTranslation();
    const size = useWindowSize();

    const [isCloseShiftLoading, setCloseShiftLoading] = useState(false);
    const [isLoadingShifts, setIsLoadingShifts] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDepositRow, setOpenDepositRow] = useState(0);
    const [openWithdrawRow, setOpenWithdrawRow] = useState(0);

    const handleToggleModal = () => setIsModalOpen(!isModalOpen);

    const closeShift = async () => {
        setCloseShiftLoading(true);
        const url = `/retail/mx/shift/close`;
        await patchSync(alertService, url, {});
        setCloseShiftLoading(false);
        setIsModalOpen(false);
        setIsEditFinance(true);
    };

    const { totalWithdrawals, totalDeposits, balance } = useMemo(() => {
        if(shiftTransactions) {
            const totalWithdrawals = shiftTransactions.reduce((a, b) => b.action === 'WITHDRAW' ? a + b.amount : a, 0);
            const totalDeposits = shiftTransactions.reduce((a, b) => b.action === 'DEPOSIT' ? a + b.amount : a, 0);
            const balance = totalDeposits - totalWithdrawals;
            return {totalWithdrawals, totalDeposits, balance};
        }
        return {totalWithdrawals: 0, totalDeposits: 0, balance: 0};

    }, [shiftTransactions]);

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleOpenDepositRow = (index) => {
        openDepositRow === index ? setOpenDepositRow(null) : setOpenDepositRow(index)
    }

    const handleOpenWithdrawRow = (index) => {
        openWithdrawRow === index ? setOpenWithdrawRow(null) : setOpenWithdrawRow(index)
    }

    return (
        <Row>
            {!agentSelf?.department || agentSelf?.department === '' || shiftTransactions?.length === 0 && <div></div>}
            {isLoadingShifts &&
                <Loader size={'sm'} />
            }
            {shiftTransactions &&
                <Col lg="12">
                    <Card className="retail-search">
                        <div className="title-section">
                            <img
                                src={listIcon}
                                alt="icon list"
                            />
                            Transactions (Casino ID: {agentSelf?.department})
                        </div>
                        <div ref={areaRef} className="retail-search__form">
                            <div className="title-section">{t('crm.deposits')}</div>
                            {shiftTransactions.filter(item => item.action === 'DEPOSIT').length === 0 &&
                                <div>{t('crm.noDeposits')}</div>
                            }
                            {shiftTransactions.filter(item => item.action === 'DEPOSIT').length > 0 &&
                            <div className="retail-search__form-item" style={{display: 'flex'}}>
                                <Table className="table">
                                    <tr className='retail-table-columns retail-table-current'>
                                        <td>{t('crm.ID')}</td>
                                        <td>{t('crm.date')}</td>
                                        <td>{t('crm.player')}</td>
                                        <td>{t('crm.amount')}</td>
                                        <td>{t('crm.action')}</td>
                                        <td>{t('crm.agent')}</td>
                                    </tr>
                                    {shiftTransactions.filter(item => item.action === 'DEPOSIT').map((item, index) => (
                                        <tr
                                            className={clsx('shift-table-data', {'shift-table-data_is-open': openDepositRow === index})}
                                            onClick={() => handleOpenDepositRow(index)}
                                        >
                                            <td>
                                                {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.ID')}</span>}
                                                {item.id}
                                            </td>
                                            <td className='shift-table-data-date'>
                                                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.date))}
                                            </td>
                                            <td className='shift-table-data-player'>
                                                {item.player}
                                            </td>
                                            <td>
                                                {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.amount')}</span>}
                                                {`${item.amount} MX$`}
                                            </td>
                                            <td className='shift-table-data-action'>
                                                {
                                                    size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW ? (
                                                        <div className='shift-table-data-sub-row'>
                                                            {item.action}
                                                            <span>{Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.date))}</span>
                                                        </div>
                                                    ) : (
                                                        item.action
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.agent')}</span>}
                                                {item.agent}
                                            </td>
                                        </tr>
                                    ))}
                                </Table>
                            </div>
                            }
                            <div style={{marginTop: '50px'}} className="title-section">Withdrawals</div>
                            <div className="retail-search__form-item" style={{display: 'flex'}}>
                                {shiftTransactions.filter(item => item.action === 'WITHDRAW').length === 0 &&
                                    <div>{t('crm.noWithdrawals')}</div>
                                }
                                {shiftTransactions.filter(item => item.action === 'WITHDRAW').length > 0 &&
                                    <Table className="table">
                                        <tr className='retail-table-columns retail-table-current'>
                                            <td>{t('crm.ID')}</td>
                                            <td>{t('crm.date')}</td>
                                            <td>{t('crm.player')}</td>
                                            <td>{t('crm.amount')}</td>
                                            <td>{t('crm.action')}</td>
                                            <td>{t('crm.agent')}</td>
                                        </tr>
                                        {shiftTransactions.filter(item => item.action === 'WITHDRAW').map((item, index) => (
                                            <tr
                                                className={clsx('shift-table-data', {'shift-table-data_is-open': openWithdrawRow === index})}
                                                onClick={() => handleOpenWithdrawRow(index)}
                                            >
                                                <td>
                                                    {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.ID')}</span>}
                                                    {item.id}
                                                </td>
                                                <td className='shift-table-data-date'>
                                                    {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.date))}
                                                </td>
                                                <td className='shift-table-data-player'>
                                                    {item.player}
                                                </td>
                                                <td>
                                                    {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.amount')}</span>}
                                                    {`${item.amount} MX$`}
                                                </td>
                                                <td className='shift-table-data-action'>
                                                    {
                                                        size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW ? (
                                                            <div className='shift-table-data-sub-row'>
                                                                {item.action}
                                                                <span>{Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.date))}</span>
                                                            </div>
                                                        ) : (
                                                            item.action
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    {size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && <span className='shift-table-data-title'>{t('crm.agent')}</span>}
                                                    {item.agent}
                                                </td>
                                            </tr>
                                        ))}
                                    </Table>
                                }
                            </div>

                            <hr/>
                            <div className="total-numbers__wrapper">
                                <div className="total-numbers">
                                    <div className="total-numbers__title">{t('crm.totalPlayerDeposits')}:</div>
                                    <div className="total-numbers__value">{`${totalDeposits} MX$`}</div>
                                </div>
                                <div className="total-numbers">
                                    <div className="total-numbers__title">{t('crm.totalWithdrawals')}:</div>
                                    <div className="total-numbers__value">{`${totalWithdrawals} MX$`}</div>
                                </div>
                                <div className="total-numbers">
                                    <div className="total-numbers__title">{t('crm.balance')}:</div>
                                    <div className="total-numbers__value">{`${balance} MX$`}</div>
                                </div>
                            </div>
                            <Button
                                disabled={isCloseShiftLoading}
                                color={'primary'}
                                onClick={handleToggleModal}
                            >
                                {t('crm.closeShift')}
                            </Button>
                            {isModalOpen &&
                                <CustomModal
                                    titleText={t('crm.shiftSummary')}
                                    isOpen={isModalOpen}
                                    onToggle={handleToggleModal}
                                    onClickCancelButton={handleCancel}
                                    onClick={closeShift}
                                    btnText={'confirm'}
                                    cancelBtnText={'cancel'}
                                    btnDisabled={isCloseShiftLoading}
                                    withCancelButton
                                    bodyRender={() => (
                                        <ModalContentCurrentShift
                                            totalDeposits={totalDeposits}
                                            totalWithdrawals={totalWithdrawals}
                                            balance={balance}
                                        />
                                    )}
                                />
                            }
                        </div>
                    </Card>
                </Col>
            }
        </Row>
    )
};

export default CurrentShift;
