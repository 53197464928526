import React, {useEffect, useRef, useState} from "react"
import { Col, Container, Row } from "reactstrap"
import {withRouter} from "react-router-dom"
import Breadcrumb from "../../common/components/Breadcrumb"
import AttributionCard from "./PromotionCard"
import {connect, useDispatch} from "react-redux"
import {
  clearCurrentPromotion,
  getPromotion,
  getBonusWageringSmallData,
  resetPromotionAlertsErrors,
} from "../../store/promotions/actions"
import useAlertService from "../../hooks/useAlertService";
import PageTitle from "../../components/PageTitle";
import MetaTags from "react-meta-tags";
import {useTranslation} from "react-i18next";


const PromotionPage = (props) => {
  const {
    isCreating, isLoading, isUpdating, promotion, error
  } = props;

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const alertService = useAlertService();
  const refWrapper = useRef(null);

  const [isNew, setIsNew] = useState(false);
  const [promotionId, setPromotionId] = useState(undefined);
  const [prevUpdating, setPrevUpdating] = useState(false);
  const [prevCreating, setPrevCreating] = useState(false);

  const preventMouseScrollFocusInput = (e) => {
    if (e.target?.type === 'number' && e.target === document.activeElement) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    if (refWrapper.current) {
        refWrapper.current.addEventListener('mousewheel', preventMouseScrollFocusInput);
    }

    return () => {
      if (refWrapper.current) {
        refWrapper.current.removeEventListener('mousewheel', preventMouseScrollFocusInput);
      }
    }
  }, [refWrapper.current])

  useEffect(() => {
    dispatch(getBonusWageringSmallData());

    const isNew = props.match.path === "/promotions/new";
    if (isNew) {
      setIsNew(isNew);
    } else {
      const id = props.match.params.id;
      props.getPromotion(id);
      setPromotionId(id)
    }
  }, [])

  useEffect(() => {
    if (!error && !isCreating && prevCreating) {
      props.clearCurrentPromotion();
      props.history.push(`/promotions`);
      alertService.showSuccess(i18n.t('crm.alerts.promotionCreated'));
    }
    if (!error && !isUpdating && prevUpdating) {
      props.getPromotion(promotionId);
      alertService.showSuccess(i18n.t('crm.alerts.promotionUpdated'));
    }
    setPrevUpdating(isUpdating);
    setPrevCreating(isCreating);
  }, [isCreating, isUpdating])

  useEffect(() => {
    if (error) {
      alertService.showError(error?.message);
      dispatch(resetPromotionAlertsErrors());
    }
  }, [error]);

    return (
      <div className="page-content promotion-page" ref={refWrapper}>
        <Container fluid>
          <MetaTags>
            <title>
              {isNew
                  ? i18n.t('promotions.promotionCreate')
                  : i18n.t('promotions.promotionView')} - {process.env.REACT_APP_APP_NAME}
            </title>
          </MetaTags>
          <Row >
            <Col lg="12">
              {(isNew || !isLoading && promotion) ?
                  <AttributionCard
                      data={promotion}
                      isNew={isNew}
                  />
                :
                <>Loading...</>
              }
            </Col>
          </Row>
        </Container>
      </div>
    )
  }


const mapStateToProps = state => {
  const {
    isLoading,
    isCreating,
    isUpdating,
    promotion,
    error,
    successAlert
  } = state.Promotions
  return {
    isLoading,
    isCreating,
    isUpdating,
    promotion,
    error
  }
}

export default React.memo(withRouter(
  connect(mapStateToProps, {
    getPromotion,
    clearCurrentPromotion
  })(PromotionPage)
));
