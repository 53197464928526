import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import { CommonFilter } from './CommonFilterPopup';
import SearchAndCheckboxSelect from '../../../common/inputs/common/SearchAndCheckboxSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentsList } from '../../../store/agents/agents/actions';
import {
    FILTER_MENU_SELECT_LIST_VALUES,
    LOGIN_LOGOUT_ACTIONS,
    PAYMENT_METHODS,
    USER_DEVICES
} from '../../../constants/Filter';
import { AUDIT_TABS } from "../../../constants/audit";
import { ACCOUNT_STATUS } from "../../../constants/status";
import {getGameProviders} from "../../../store/games/actions";
import {PROMOTIONS_TYPES, SPORT_PROMOTION_TYPES} from "../../../utils/promotions_types";
import {useTranslation} from "react-i18next";
import {BONUSES_TABS} from "../../../common/constants/common";
import { PLAYER_PROMOTION_STATUS } from "../../../pages/Promotions/common/promotionConstants";

export const FilterSearchAndCheckbox = ({
    activePopup,
    onChange,
    onCancel,
    activeTab,
    initialValue,
}) => {
    const dispatch = useDispatch();
    const { agentsList } = useSelector(state => state.AgentList);
    const { cardNetworks, paymentProviders } = useSelector(state => state.DepositList);
    const { loginsList, statusList, changesList, changesActions, changesInitiators } = useSelector(state => state.PlayerAudit);
    const { active: activeBrand } = useSelector(state => state.Brand);
    const [state, setState] = useState([]);
    const [listProviders, setListProviders] = useState([]);
    const { t } = useTranslation();
    const { gameProviders } = useSelector(
        state => state.Games
    );

    useEffect(() => {
        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.GAME_PROVIDER && !gameProviders.length) {
            dispatch(getGameProviders());
        }
        else if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER || activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.INITIATOR) {
            dispatch(getAgentsList());
        }
    }, []);

    useEffect(() => {
        const _gameProviders = []
        gameProviders.forEach(provider => {
            if (provider.site === activeBrand?.id) {
                _gameProviders.push({ key: provider.contentProvider, label: provider.name })
            }
        });
        setListProviders(_gameProviders);
    }, [activeBrand, gameProviders]);

    const prepareOptions = useCallback((list) => {
        return list.map((element) => ({
            key: element,
            label: element,
        }))
    }, []);

    const agentListOptions = useMemo(() => {
        if (agentsList) {
            return [
                { key: null, label: i18n.t('filter.withoutOwner') },
                ...agentsList.map(item => ({
                    key: item.id,
                    label: item.agentName
                }))
            ];
        }
    }, [agentsList]);

    const prepareOptionsWithTranslation = useCallback((list) => {
        return list.map((element) => ({
            key: element,
            label:  t(`filter.constant.${element}`),
        }))
    }, []);


    const preparedActions = useMemo(
        () => prepareOptions(activeTab === AUDIT_TABS.LOGIN_LOGOUT
            ? Object.values(LOGIN_LOGOUT_ACTIONS)
            : changesActions
        ),
        [changesActions, activeTab]
    );

    const preparedInitiator = useMemo(() => prepareOptions(changesInitiators), [changesInitiators]);

    const checkboxList = useMemo(() => {
        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.TYPE) {
            if (activeTab === BONUSES_TABS.CASINO_BONUSES) {
                return prepareOptionsWithTranslation(PROMOTIONS_TYPES)
            } else if (activeTab === BONUSES_TABS.SPORT_BONUSES) {
                return prepareOptionsWithTranslation(SPORT_PROMOTION_TYPES)
            }
        }

        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.STATUS) {
            if (activeTab === BONUSES_TABS.CASINO_BONUSES || activeTab === BONUSES_TABS.SPORT_BONUSES) {
                return prepareOptionsWithTranslation(PLAYER_PROMOTION_STATUS)
            }
        }

        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.GAME_PROVIDER) {
            return listProviders;
        }

        if (activeTab === AUDIT_TABS.CHANGES) {
            if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.ACTION_TYPE) {
                return preparedActions;
            }
            else { // activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.INITIATOR
                return agentListOptions;
            }
        }

        if (activeTab === AUDIT_TABS.STATUS) {
            if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.INITIATOR) {
                return agentListOptions;
            }
            else { // activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.ACCOUNT_STATUS
                return prepareOptions(Object.values(ACCOUNT_STATUS));
            }
        }
        if (activeTab === AUDIT_TABS.LOGIN_LOGOUT) {
            if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.ACTION_TYPE) {
                return preparedActions;
            }
            else { // activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.USER_DEVICE
                return prepareOptions(Object.values(USER_DEVICES));
            }
        }

        if (agentsList && activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            return [
                { key: null, label: i18n.t('filter.withoutOwner') },
                ...agentsList.map(item => ({
                    key: item.id,
                    label: item.agentName
                }))
            ];
        }

        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.PAYMENT_METHOD) {
            const paymentMethods = [
                ...Object.values(PAYMENT_METHODS),
                ...(cardNetworks || []),
            ];

            const lowerCasedMethods = {};
            paymentMethods
                .forEach((method) => lowerCasedMethods[method] = method.toLowerCase());
            paymentMethods
                .sort((m1, m2) => lowerCasedMethods[m1] < lowerCasedMethods[m2] ? -1 : 1);

            return paymentMethods.map(item => ({
                key: item,
                label: item,
            }));
        }

        if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.PROVIDER_TYPE) {
            return (paymentProviders || []).map(item => ({
                key: item,
                label: item,
            }));
        }

        if (activePopup.key !== FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            return activePopup.list;
        }

        return [];
    }, [activePopup.key, agentsList, changesList, statusList, loginsList, activeTab, listProviders]);

    const handleApplyListSelect = useCallback(
        value => {
            const result = value.map(item => {
                let value = item;
                if (activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.GAME ||
                    activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.GAME_PROVIDER
                ) {
                    const game = checkboxList.find(({key}) => key === item);
                    value = game.key;
                }
                return {
                    key: activePopup.key,
                    value
                }
            });
            onChange(result);
            setState(value);
        },
        [state, activePopup.key, checkboxList]
    );

    return (
        <CommonFilter
            title={activePopup.title}
            className={'filter-search_and_checkbox'}
            onCancel={onCancel}
        >
            <SearchAndCheckboxSelect
                checkboxList={checkboxList}
                onChange={handleApplyListSelect}
                activePopup={activePopup}
                initialValue={initialValue}
            />
        </CommonFilter>
    );
};

FilterSearchAndCheckbox.Proptypes = {
    activePopup: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValue: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
};

FilterSearchAndCheckbox.defaultProps = {
    initialValue: [],
}
