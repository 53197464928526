export const GET_PENDING_TRANSFER_WITHDRAWAL = 'GET_PENDING_TRANSFER_WITHDRAWAL';
export const GET_PENDING_TRANSFER_WITHDRAWAL_SUCCESS = 'GET_PENDING_TRANSFER_WITHDRAWAL_SUCCESS';
export const GET_PENDING_TRANSFER_WITHDRAWAL_FAIL = 'GET_PENDING_TRANSFER_WITHDRAWAL_FAIL';

export const APPROVE_PENDING_TRANSFER_WITHDRAWAL = 'APPROVE_PENDING_TRANSFER_WITHDRAWAL';
export const APPROVE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS =
    'APPROVE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS';
export const APPROVE_PENDING_TRANSFER_WITHDRAWAL_FAIL =
    'APPROVE_PENDING_TRANSFER_WITHDRAWAL_FAIL';

export const DECLINE_PENDING_TRANSFER_WITHDRAWAL = 'DECLINE_PENDING_TRANSFER_WITHDRAWAL';
export const DECLINE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS =
    'DECLINE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS';
export const DECLINE_PENDING_TRANSFER_WITHDRAWAL_FAIL =
    'DECLINE_PENDING_TRANSFER_WITHDRAWAL_FAIL';

export const RESET_PENDING_TRANSFER_WITHDRAWAL_ALERTS_ERRORS =
    'RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS';
