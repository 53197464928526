import {
    GET_NOTIFICATIONS_LIST_PAGE,
    GET_NOTIFICATIONS_LIST_PAGE_SUCCESS,
    GET_NOTIFICATIONS_PAGE_ERROR
} from './actionTypes';

export const getNotificationsListPage = query => ({
    type: GET_NOTIFICATIONS_LIST_PAGE,
    payload: query
});

export const getNotificationsListPageSuccess = response => ({
    type: GET_NOTIFICATIONS_LIST_PAGE_SUCCESS,
    payload: response
});

export const notificationPageError = error => ({
    type: GET_NOTIFICATIONS_PAGE_ERROR,
    payload: error
});
