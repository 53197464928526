import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { uniq } from 'lodash';

import DocumentView, { ViewImg } from '../DocumentView';
import DocumentData from '../documentsParts/DocumentData';
import DocumentActivity from '../documentsParts/DocumentActivity';
import AddCommentForm from '../documentsParts/AddCommentForm';
import CustomModal from '../../../common/components/CustomModal';
import {
    ACTIVITY_TABS,
    AGREEMENT_DOCUMENT_TYPES,
    COLLAPSE_OPTIONS,
    SITE_PERMISSION
} from '../../../common/constants/common';
import i18n from '../../../i18n';
import {
    addDocumentComment,
    clearCurrentDocumentContent,
    getDocument,
    getDocumentData,
    getPlayerDocuments,
    getPlayerSofAgreement,
    getPlayerSelfExclusionAgreement,
    getPlayerVerification,
    removeDocumentComment,
    uploadNewDocument,
    getPlayerAccountRecoveryAgreement,
    getPlayerGeneral,
    getBrandJurisdiction,
} from '../../../store/actions';

import uploadDocument from '../../../assets/images/layout/upload-document.svg';
import UploadFileView from './uploadFile/UploadFileView';
import clsx from "clsx";
import { SELECT_CATEGORIES } from "../../../constants/selectCategories";
import Loader from "../../../common/components/Loader";
import { withTranslation } from "react-i18next";
import { withAlert } from "react-alert";
import collapse from "../../../assets/images/layout/collapse.svg";
import MultiSelect from "../../../UI/multiSelect/MultiSelect";
import SelectInput from "../../../common/inputs/common/SelectInput";
import {SITE_BRAND} from "../../../constants/siteBrands";
import {DocumentType} from "../../../utils/KYC";

const AGREEMENT_TRANSLATE_KEYS = {
  SOF_Agreement: 'crm.document.type.SOF_Agreement',
  SelfExclusion_Agreement: 'crm.document.type.SelfExclusion_Agreement',
  AccountRecovery_Agreement: 'crm.document.type.AccountRecovery_Agreement',
};

const COLLAPSE_OPTIONS_KEYS = {
    collapse: 'crm.collapse',
    expand: 'crm.expand'
};

const agreementSelectOptions = AGREEMENT_DOCUMENT_TYPES
    .map(item => ({ label: i18n.t(AGREEMENT_TRANSLATE_KEYS[item]), value: item }));


const collapseOptions = COLLAPSE_OPTIONS
    .map(item => ({ label: i18n.t(COLLAPSE_OPTIONS_KEYS[item]), value: item }));


const playerHasDocumentType = (playerDocuments, documentType) => {
    for (const doc of playerDocuments) {
        if (doc.type === documentType && doc.status === 'Approved') {
            return true
        }
    }
    return false;
}

class DocumentsTabContent extends Component {
    constructor(props) {

        super(props);

        this.fileInputRef = null;
        this.documentUid = '';

        this.state = {
            docActivityTabs: [],
            isAddCommentModalVisible: false,
            isDocumentModalVisible: false,
            isNotifyPlayerChecked: false,
            newComment: '',
            currentDocument: null,
            documentUid: '',
            errorMessage: '',

            playerDocuments: [],
            statuses: [],
            currStatus: [],
            docTypes: [],
            currType: [],

            collapseToggle: false,
            userCollapseChoice: true,
            collapseDoc: {},

            agreementDocLabel: 'Generate agreement',
            canEditByEditPlayerInfoPerm: null,
        };
    }


    getPlayerDocuments = () => {
        const { id } = this.props.player;
        this.props.getPlayerDocuments(id);
    }

    getAgreementSelectOptions = () => {
        const { playerDocuments } = this.state;

        const res = [];
        agreementSelectOptions.forEach((item) => {
            if (
                (item.value === DocumentType.SelfExclusion_Agreement ||
                    item.value === DocumentType.AccountRecovery_Agreement) &&
                this.props.activeBrand?.siteName === SITE_BRAND.WINPOTMX
            ) {
                if (!playerHasDocumentType(playerDocuments, item.value)) {
                    res.push(item);
                }
            } else if (!playerHasDocumentType(playerDocuments, item.value)) {
                res.push(item);
            }
        });

        return res;
    }

    getPlayerVerification = () => {
        const { oid } = this.props.player;
        this.props.getPlayerVerification(oid);
    }

    getPlayerGeneral = () => {
        const { oid } = this.props.player;
        this.props.getPlayerGeneral(oid);
    }

    getBrandJurisdiction = () => {
        this.props.getBrandJurisdiction();
    }

    componentDidMount() {
        const {
            agentSelf,
        } = this.props;
        this.getPlayerDocuments();
        this.getPlayerVerification();
        this.getBrandJurisdiction();

        if (agentSelf) {
            this.setState({
                canEditByEditPlayerInfoPerm: agentSelf?.permissions.includes(SITE_PERMISSION.Player__Edit_Player_Info)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentDocument, uploadPopup } = this.state;
        const {
            currentDocumentData,
            playerDocuments,
            currentDocumentContent,
            lastCommentCreateDate,
            lastCommentRemoveDate,
            player,
            isUpdateSofAgreement,
            isGenerateAgreementDoc,
        } = this.props;

        if (this.state.isDocumentModalVisible !== prevState.isDocumentModalVisible) {
            const pageContent = document?.querySelector('.page-content.user-general');

            if (this.state.isDocumentModalVisible && pageContent) {
                    pageContent.classList.add('removeScroll');
            } else {
                    pageContent.classList.remove('removeScroll');
            }
        }

        if (prevState.agreementDocLabel !== this.state.agreementDocLabel) {
            this.setState({
                agreementDocLabel: 'Generate agreement'
            })
        }

        const { id } = player;

        if (
            lastCommentCreateDate &&
            lastCommentCreateDate !== prevProps.lastCommentCreateDate
        ) {
            this.props.getDocumentData(currentDocument.oid);
        }

        if (
            lastCommentRemoveDate &&
            lastCommentRemoveDate !== prevProps.lastCommentRemoveDate
        ) {
            this.props.getDocumentData(currentDocument.oid);
        }

        if (!uploadPopup && uploadPopup !== prevState.uploadPopup) {
            this.getPlayerDocuments();
        }

        if (isUpdateSofAgreement === false && prevProps.isUpdateSofAgreement === true
            || isGenerateAgreementDoc === false && prevProps.isGenerateAgreementDoc === true
        ) {
            this.getPlayerDocuments();
            this.getPlayerVerification();
            this.getPlayerGeneral();
        }

        if (
            currentDocumentData &&
            currentDocumentData !== prevProps.currentDocumentData
        ) {
            this.setState({
                currentDocument: currentDocumentData,
                playerDocuments: this.state.playerDocuments.map(
                    playerDocument => {
                        if (playerDocument.oid === currentDocumentData.oid) {
                            return currentDocumentData;
                        }
                        return playerDocument;
                    }
                )
            });
        }

        if (
            currentDocumentContent &&
            currentDocumentContent !== prevProps.currentDocumentContent
        ) {
            this.props.getPlayerDocuments(id);
        }

        if (playerDocuments !== prevProps.playerDocuments) {
            const docActivityTabs = playerDocuments.map(_ => ({
                activityTab: ACTIVITY_TABS.comments
            }));

            const uniqueStatuses = playerDocuments.length > 0
                ? uniq(playerDocuments?.map((doc) => doc.status))
                : [];
            const statuses = uniqueStatuses.map((status) => ({id: status, name: status}));
            const currStatus = [];

            const uniqueTypes = playerDocuments.length > 0
                ? uniq(playerDocuments?.map((doc) => doc.type))
                : [];

            const docTypes = uniqueTypes.map(type => {
                let displayName = type.replace(/_/g, ' ');
                if (displayName === 'PaymentConfirmation') {
                    displayName = 'Payment Confirmation';
                }
                return {
                    id: type,
                    name: displayName
                };
            });


            const currType = [];

            this.setState(prevState => ({
                ...prevState,
                collapseDoc: this.setCollapseState(playerDocuments,false)
            }))

            this.setState({
                playerDocuments,
                docActivityTabs,
                statuses,
                currStatus,
                docTypes,
                currType,
            }, () => {

                if (currentDocumentContent) {
                    const { id: documentUid } = currentDocumentContent;
                    if (documentUid !== '') {
                        const currentDocument = playerDocuments.find(
                            documentData => documentData.id === documentUid
                        );

                        if (currentDocument) {
                            this.setState({
                                currentDocument,
                                isDocumentModalVisible: true
                            });
                        }
                    }
                }
            });
        }
    }

    handleToggleAddCommentModal = () => {
        const { isAddCommentModalVisible } = this.state;
        this.setState({ isAddCommentModalVisible: !isAddCommentModalVisible });
    };

    handleToggleDocumentModal = docId => {
        const { isDocumentModalVisible, playerDocuments } = this.state;
        const currentDocument = docId
            ? playerDocuments.find(doc => doc.id === docId)
            : null;

        this.setState({
            isDocumentModalVisible: !isDocumentModalVisible,
            currentDocument
        });
    };

    handleClickAddComment = docId => {
        const { playerDocuments } = this.state;
        this.setState({
            isAddCommentModalVisible: true,
            isNotifyPlayerChecked: false,
            newComment: '',
            currentDocument: playerDocuments.find(doc => doc.id === docId)
        });
    };

    handleClickSaveComment = () => {
        const { currentDocument, newComment } = this.state;
        this.props.addDocumentComment({
            document: currentDocument,
            comment: newComment,
            writer: `${this.props.agentSelf.firstName} ${this.props.agentSelf.lastName}`,
            agentId: this.props.agentSelf.id,
        });

        this.setState({
            isAddCommentModalVisible: false
        });
        this.props.alert.success(i18n.t('crm.CommentAdded'));
    };

    handleCommentChange = event => {
        this.setState({
            newComment: event.target.value
        });
    };

    handleToggleNotifyPlayer = () => {
        const { isNotifyPlayerChecked } = this.state;
        this.setState({ isNotifyPlayerChecked: !isNotifyPlayerChecked });
    };

    handleChangeDocActivityTabs = (ind, curActivityTab) => {
        const { docActivityTabs } = this.state;

        docActivityTabs[ind] = {
            activityTab: curActivityTab
        };
        this.setState({ docActivityTabs: [...docActivityTabs] });
    };

    handleRemoveComment = (commentData, currentDocument) => {
        this.setState({ currentDocument });
        this.props.removeDocumentComment({
            document: currentDocument,
            commentData,
            writer: `${this.props.agentSelf.firstName} ${this.props.agentSelf.lastName}`,
            agentId: this.props.agentSelf.id,
        });
        this.props.alert.error(i18n.t('crm.CommentRemoved'));
    };

    handleChange = (optionsType) => (newValue) => {
        if (optionsType === 'docStatuses') {
            this.setState({
                currStatus: newValue,
            })
        }
        else if (optionsType === 'docTypes') {
            this.setState({
                currType: newValue,
            })
        }
        else if (optionsType === 'collapse') {
            if (newValue === 'collapse') {
                this.handleToggleCollapseAll();
            } else if (newValue === 'expand') {
                this.handleToggleExpandAll();
            }
        }
    }

    setCollapseState = (playerDocuments, stateValue) => {
        return playerDocuments.reduce((acc, doc) => {
                acc[doc.id] = stateValue;
                return acc;
            }, {})
    }

    handleToggleCollapseAll = () => {
        this.setState(prevState => ({
            collapseToggle: false,
            collapseDoc: this.setCollapseState(prevState.playerDocuments, false),
        }));
    }

    handleToggleExpandAll = () => {
        this.setState(prevState => ({
            collapseToggle: true,
            collapseDoc: this.setCollapseState(prevState.playerDocuments, true),
        }));
    }

    openOrHideDoc = (docId) => {
        this.setState(prevState => ({
            collapseDoc: {
                ...prevState.collapseDoc,
                [docId]: !prevState.collapseDoc[docId]
            }
        }))
    }

    handleAgreement = () => {
        const {player} = this.props;
        const {oid} = player;

        switch (this.state.agreementDocLabel) {
            case 'SOF_Agreement':
                this.props.getPlayerSofAgreement({oid});
                break;
            case 'SelfExclusion_Agreement':
                this.props.getPlayerSelfExclusionAgreement({oid});
                break;
            case 'AccountRecovery_Agreement':
                this.props.getPlayerAccountRecoveryAgreement({oid});
                break;
        }
    }

    handleChangeAgreementDoc = (value) => {
        const {player} = this.props;
        const {oid} = player;

        this.setState({ agreementDocLabel: value }, () => {
            this.handleAgreement();
        });
    }

    render() {
        const { expanded, isLoadingVerification, activeBrand } = this.props;
        const {
            newComment,
            docActivityTabs,
            playerDocuments,
            isAddCommentModalVisible,
            isDocumentModalVisible,
            isNotifyPlayerChecked,
            currentDocument,
            errorMessage,
            uploadPopup,
            statuses,
            currStatus,
            docTypes,
            currType,
            collapseToggle,
            collapseDoc,
            agreementDocLabel,
            canEditByEditPlayerInfoPerm,
        } = this.state;

        const {
            jurisdiction,
        } = this.props;

        const noPlayerDocs = !statuses.length && !docTypes.length;
        const isMXjurisdiction = jurisdiction === 'MX';
        const isWinpot = activeBrand?.siteName === SITE_BRAND.WINPOTMX;

        const uploadPopupShow = () =>
            this.setState({ uploadPopup: !uploadPopup });

        return (
            <div
                className={clsx('documents-wrapper', {
                    ['wrapper-expanded']: expanded
                })}
            >
                {errorMessage && (
                    <div className='alert alert-danger'>{errorMessage}</div>
                )}

                {uploadPopup && (
                    <UploadFileView
                        fileInputRef={this.fileInputRef}
                        props={this.props}
                        onClose={uploadPopupShow}
                    />
                )}

                <div
                    className="d-flex align-items-center justify-content-between flex-wrap mt-2 documents-heading"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {playerDocuments.length === 0 &&
                        <div>{i18n.t('tabs.NoDocuments')}</div>
                    }
                    <div className={clsx('d-flex align-items-center documents-filter', {
                        'd-none': noPlayerDocs
                    })}>
                        <div className="documents-filter__select">
                            <div className="label-doc-select align-items-center mt-0">
                                {i18n.t('promotions.status')}:
                            </div>
                            <MultiSelect
                                category={SELECT_CATEGORIES.DEFAULT}
                                options={statuses}
                                values={currStatus}
                                className='status-select-dropdown'
                                handleChange={this.handleChange('docStatuses')}
                            />
                        </div>

                        <div className="documents-filter__select">
                            <div className="label-doc-select d-flex align-items-center mt-0">
                                {i18n.t('promotions.type')}:
                            </div>
                            <MultiSelect
                                category={SELECT_CATEGORIES.DEFAULT}
                                options={docTypes}
                                values={currType}
                                className='docs-select-dropdown'
                                handleChange={this.handleChange('docTypes')}
                            />
                        </div>

                        <div
                            className={clsx("align-items-center", {
                                'd-none': noPlayerDocs,
                            })}
                        >
                            <SelectInput
                                options={collapseOptions}
                                value={collapseToggle}
                                isSearchable={false}
                                placeholder={'Collapse / Expand'}
                                onChange={this.handleChange('collapse')}
                                className='collapse-expand-select'
                            />
                        </div>
                    </div>

                    <div className={clsx('generate-upload-docs', {
                        ['disabled']: !canEditByEditPlayerInfoPerm
                    })}>
                        {(this.props.isUpdateSofAgreement || this.props.isGenerateAgreementDoc) &&
                                <Loader size={'sm'} className={'mr-10'} />
                        }
                        <SelectInput
                            onChange={this.handleChangeAgreementDoc}
                            placeholder={i18n.t('crm.generateAgreement')}
                            value={agreementDocLabel}
                            disabled={!isMXjurisdiction}
                            options={this.getAgreementSelectOptions()}
                            className={clsx('agreement-doc-select', {
                                'd-none': !isWinpot
                            })}
                        />
                        <img
                            className='upload-document ml-8'
                            src={uploadDocument}
                            alt="Upload user's document"
                            width={40}
                            onClick={uploadPopupShow}
                        />
                    </div>
                </div>

                {isLoadingVerification
                    ? <Loader />
                    : <div className='documents-card'>
                        {playerDocuments
                            .filter((currDocument) =>
                                currStatus.length === 0 || currStatus.includes(currDocument.status))
                            .filter((currDocument) =>
                                currType.length === 0 || currType.includes(currDocument.type))
                            .filter((currDocument) =>
                                isWinpot || currDocument.type !== "SelfExclusion_Agreement")
                            .map((currDocument, ind) => {
                                const docId = currDocument.id;

                                let commentsFromNewToOld = {};
                                if (currDocument.comments) {
                                    commentsFromNewToOld = Object.assign(currDocument.comments).reverse();
                                }

                                return (
                                    <Col
                                        className={clsx("col-12 doc-card", {
                                            "doc-card-collapsed": !collapseDoc[docId],
                                        })}
                                        key={`doc-${docId}`}
                                    >
                                        <button
                                            className={clsx("collapse-doc-arrow", {
                                                "hidden-doc-info": collapseDoc[docId],
                                            })}
                                            onClick={() => this.openOrHideDoc(docId)}
                                        >
                                            <img src={collapse} alt="collapse-doc"/>
                                        </button>

                                        <DocumentData
                                            onClick={() => {this.handleToggleDocumentModal(docId)}}
                                            currDocument={currDocument}
                                        />


                                        <div className={clsx("hidden-doc doc-data doc-comments col", {
                                            "show-doc": collapseDoc[docId],
                                        })}>
                                            <DocumentActivity
                                                activityTab={
                                                    docActivityTabs[ind].activityTab
                                                }
                                                docNumber={ind}
                                                onChangeActivityTab={
                                                    this.handleChangeDocActivityTabs
                                                }
                                                onClickAddComment={() =>
                                                    this.handleClickAddComment(docId)
                                                }
                                                comments={commentsFromNewToOld}
                                                currDocument={currDocument}
                                                onRemoveComment={
                                                    (commentData) => this.handleRemoveComment(commentData, currDocument)
                                                }

                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                    </div>
                }

                {isDocumentModalVisible && (
                    <CustomModal
                        className='document-modal documents-tab-modal'
                        titleText={i18n.t('crm.document.view.title')}
                        isOpen={isDocumentModalVisible}
                        onToggle={() => this.handleToggleDocumentModal()}
                        onClick={this.handleClickSaveComment}
                        btnText={'edit'}
                        bodyRender={() => (
                            <DocumentView
                                currentDocument={currentDocument}
                                getPlayerDocuments={this.getPlayerDocuments}
                                isPopupOpen={isDocumentModalVisible}
                                canEditByEditPlayerInfoPerm={canEditByEditPlayerInfoPerm}
                            />
                        )}
                        withFooter={false}
                    />
                )}

                {isAddCommentModalVisible && (
                    <CustomModal
                        titleText={i18n.t('crm.addComment')}
                        isOpen={isAddCommentModalVisible}
                        onToggle={() => this.handleToggleAddCommentModal()}
                        onClick={this.handleClickSaveComment}
                        btnText={'save'}
                        btnDisabled={newComment?.trim().length < 3}
                        bodyRender={() => (
                            <AddCommentForm
                                isNotifyChecked={isNotifyPlayerChecked}
                                onCommentChange={this.handleCommentChange}
                                onToggleNotifyPlayer={
                                    this.handleToggleNotifyPlayer
                                }
                            />
                        )}
                    />
                )}
                <canvas id='preview-canvas' />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        error,
        playerDocuments,
        currentDocumentContent,
        currentDocumentData,
        lastCommentCreateDate,
        lastDocumentUpdatedDate,
        lastCommentRemoveDate,
        jurisdiction,
    } = state.Documents;
    const {
        isLoadingVerification,
        isUpdateSofAgreement,
        errorSofAgreement,
        verification,
        isGenerateAgreementDoc,
        errorGenerateAgreementDoc,
    } = state.PlayerVerification;
    const { playerGeneral: player } = state.PlayerGeneral;
    const { agentSelf } = state.AgentSelf;
    const { active: activeBrand } = state.Brand;
    return {
        error,
        isLoadingVerification,
        playerDocuments,
        currentDocumentContent,
        currentDocumentData,
        lastCommentCreateDate,
        lastDocumentUpdatedDate,
        lastCommentRemoveDate,
        player,
        agentSelf,
        isUpdateSofAgreement,
        errorSofAgreement,
        verification,
        isGenerateAgreementDoc,
        errorGenerateAgreementDoc,
        activeBrand,
        jurisdiction,
    };
};

export default React.memo(
    withRouter(
        connect(mapStateToProps, {
            getPlayerSofAgreement,
            getPlayerSelfExclusionAgreement,
            getPlayerAccountRecoveryAgreement,
            getPlayerGeneral,
            uploadNewDocument,
            getPlayerDocuments,
            getDocumentData,
            getDocument,
            clearCurrentDocumentContent,
            addDocumentComment,
            removeDocumentComment,
            getPlayerVerification,
            getBrandJurisdiction,
        })(withAlert()(withTranslation()(DocumentsTabContent)))
    )
);
