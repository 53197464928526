import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, Col, Row} from "reactstrap";
import {generate} from "generate-password";

import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import {fieldsProfileCreated, fieldsProfileEdit} from "./fieldsProfile";
import {isMexico} from "../../../utils/countries";

import personalIcon from '../../../assets/images/retail/personal.svg';
import infoIcon from "../../../assets/images/common/info.svg";

import "../styles.scss";
import DeclineSubmitBtnGroup from "../../../UI/buttonsGroups/DeclineSubmitBtnGroup";
import {prepareUpdatePlayerGeneralDto} from "../../CRM/general/model/playerEditModel";
import {
    getRetailPlayer,
    createRetailPlayer,
    updateRetailPlayer,
    resetUpdateError,
    clearRetailPlayer,
} from "../../../store/players/general/actions";
import useAlertService from "../../../hooks/useAlertService";
import {EMAIL_REG_EXP, PHONE_REG_EXP, USERNAME_REG_EXP} from "../../../constants/validations";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router-dom";
import Loader from "../../../common/components/Loader";
import {DEFAULT_PASSWORD_LENGTH} from "../../../common/constants/common";
import {useTranslation} from "react-i18next";
import {SITE_BRAND} from "../../../constants/siteBrands";
import {getSync} from "../../../helpers/api_helper";


const REQUIRED_RETAIL_FIELDS = [
    'currency',
    'nickName',
    'password'
];

const Profile = ({isEditMode, isCreatMode, isEdit, setIsEdit}) => {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const refGeneralInfo = useRef(null);
    const {active: activeBrand} = useSelector(state => state.Brand);
    const {agentSelf} = useSelector(state => state.AgentSelf);

    let history = useHistory();
    const location = useLocation();
    const {state: stateLocation} = location;

    const {
        retailPlayer,
        generalUpdated,
        updateError,
        retailCreated,
    } = useSelector(state => state.PlayerGeneral);

    const [player, setPlayer] = useState({});
    const [initialPlayer, setInitialPlayer] = useState({});
    const [fields, setFields] = useState([]);
    const [isRequiredFill, setIsRequiredFill] = useState(false);
    const [isBigSizeGeneralSection, setIsBigSizeGeneralSection] = useState(false);

    const handleSetWidthGeneralSection = () => {
        setIsBigSizeGeneralSection(refGeneralInfo.current?.offsetWidth > 460);
    };

    const prevState = useRef({
        generalUpdated: false,
    })

    const clearFields = () => {
        setIsEdit(false);
        setPlayer({});
    }

    const handleSidebar = () => {
        setTimeout(() => {
            handleSetWidthGeneralSection();
        }, 100);
    }

    useEffect(() => {
        handleSidebar();
        let toggler = document.querySelector('.vertical-menu__toggler');
        if (toggler) {
            toggler.addEventListener("click", handleSidebar);
            return () => {
                toggler.removeEventListener("click", handleSidebar);
            };
        }
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleSetWidthGeneralSection);
        return () => {
            window.removeEventListener("resize", handleSetWidthGeneralSection);
        };
    }, [refGeneralInfo.current]);

    useEffect(() => {
        if (!updateError && !generalUpdated && prevState.current.generalUpdated) {
            dispatch(getRetailPlayer(retailPlayer.oid));
            alertService.showSuccess(i18n.t("crm.alerts.playersUpdated"));
            setIsEdit(false);
        }
        if (updateError) {
            if (updateError.validation) {
                updateError.validation
                    .forEach((message) => alertService.showError(i18n.t(`filter.constant.${message}`)));
            } else {
                if (updateError.message) {
                    const messageKeys = updateError.message.split('.');
                    if (messageKeys.includes('duplicate')) {
                        if (messageKeys.includes('nickName')) {
                            alertService.showError(i18n.t('crm.duplicate.nickName'));
                        }
                        if (messageKeys.includes('phone')) {
                            alertService.showError(i18n.t('crm.duplicate.phone'));
                        }
                        if (messageKeys.includes('email')) {
                            alertService.showError(i18n.t('crm.duplicate.email'));
                        }
                    } else if (messageKeys.includes('missing') && updateError?.data?.phone) {
                        alertService.showError(i18n.t(`crm.phone.${updateError?.data?.phone}`));
                    } else {
                        if (activeBrand?.siteName === SITE_BRAND.WINPOTMX && updateError?.data?.amlRFC) {
                            alertService.showError(i18n.t("crm.alerts.amlRFC.already.exists"));
                        }
                        if (activeBrand?.siteName === SITE_BRAND.WINPOTMX && updateError?.data?.amlCURP) {
                            alertService.showError(i18n.t("crm.alerts.amlCURP.already.exists"));
                        }
                        if (updateError?.message?.includes('age')) {
                            alertService.showError(i18n.t("filter.constant.UnderAge"));
                        } else if (updateError?.message?.includes('Nothing')) {
                            alertService.showError(i18n.t("filter.constant.NothingChange"));
                        }
                    }
                } else {
                    alertService.showError(i18n.t(updateError));
                }
            }
            dispatch(resetUpdateError());
        }
        prevState.current = {
            ...prevState.current,
            generalUpdated,
        }
    }, [retailPlayer, generalUpdated, updateError]);

    useEffect(() => {
        if (retailPlayer) {
            if (isEditMode) {
                const birthDateLocal = new Date(retailPlayer?.birthDate);

                setInitialPlayer({
                    firstName: retailPlayer.firstName,
                    lastName: retailPlayer.lastName,
                    // nickName: retailPlayer.nickName,
                    birthDate: retailPlayer?.birthDate
                        ? new Date(birthDateLocal.getTime() + (birthDateLocal.getTimezoneOffset() * 60 * 1000)).toISOString()
                        : null,
                    gender: retailPlayer.gender === 'other' ? "" : retailPlayer.gender,
                    ...(activeBrand?.siteName === SITE_BRAND.WINPOTMX && {amlRFC: retailPlayer.amlRFC ?? ''}),
                    ...(activeBrand?.siteName === SITE_BRAND.WINPOTMX && {amlCURP: retailPlayer.amlCURP ?? ''}),
                    phone: retailPlayer.phone,
                    email: retailPlayer.email
                });
            } else if (isCreatMode && retailPlayer.oid && !stateLocation?.isRetailProfilePrevPage) {
                setIsEdit(false);
                let redirectUrl = `/player-list/${retailPlayer.oid}`;
                if (activeBrand?.siteName === SITE_BRAND.WINPOTMX) {
                    redirectUrl = `/retail-player-list/${retailPlayer.oid}`;
                }

                setTimeout(() => {
                    history.push(redirectUrl);
                }, 300)
            } else {
                dispatch(clearRetailPlayer());
            }
        } else {
            stateLocation?.isRetailProfilePrevPage && history.replace(location.pathname, {})
        }
    }, [retailPlayer, fields]);

    useEffect(() => {
        setPlayer(prev => ({...prev, ...initialPlayer}))
    }, [initialPlayer])

    useEffect(() => {
        if (isEditMode) {
            if (JSON.stringify(initialPlayer) === JSON.stringify(player)) {
                setIsEdit(false);
            }
        } else {
            setIsEdit(!!Object.values(player).join().replace(/,/g, ''));
        }
    }, [player]);


    useEffect(async () => {
        async function fetchData() {
            const url = '/site/settings';
            try {
                const result = await getSync(url, {});
                return result.data;
            } catch (e) {
                console.error('Cannot fetch last messages!');
            }
        }

        const siteSettings = await fetchData();

        if (isCreatMode) {
            setFields(fieldsProfileCreated(isMexico(player?.country), activeBrand?.siteName === SITE_BRAND.WINPOTMX, siteSettings))
        } else {
            setFields(fieldsProfileEdit(activeBrand?.siteName === SITE_BRAND.WINPOTMX, siteSettings))
        }
    }, []);

    const handleChange = useCallback(
        (name) => value => {
            setPlayer(prevState => ({...prevState, [name]: value}));
            setIsEdit(true)
        },
        []
    );

    useEffect(() => {
        const isFill = REQUIRED_RETAIL_FIELDS
            .reduce((acc, field) => acc && player[field], true);
        setIsRequiredFill(isFill);
    }, [player]);

    const onSubmit = async (e) => {
        e.preventDefault();


        if (!player.nickName) {
            const random = Math.floor(100000000 + Math.random() * 900000000);
            player.nickName = random.toString();
        }

        if (isCreatMode) {
            const dto = prepareUpdatePlayerGeneralDto(
                {},
                player
            );

            let errorMessage = [];

            if (!USERNAME_REG_EXP.test(dto?.nickName)) {
                errorMessage.push(i18n.t('crm.invalidNickName'));
            }
            if (dto?.email && !EMAIL_REG_EXP.test(dto?.email)) {
                errorMessage.push(i18n.t('crm.alerts.emailIsIncorrect'));
            }
            if (dto?.firstName && (dto?.firstName.length < 2 || dto?.firstName.length > 50)) {
                errorMessage.push(i18n.t('crm.wrongLengthFirstName'));
            }
            if (dto?.lastName && (dto?.lastName.length < 2 || dto?.lastName.length > 50)) {
                errorMessage.push(i18n.t('crm.wrongLengthLastName'));
            }
            if (dto?.phone && (dto?.phone === '' || PHONE_REG_EXP.test(dto?.phone))) {
                errorMessage.push(i18n.t('crm.wrongPhoneInput'));
            }
            if (errorMessage.length > 0) {
                alertService.showError(errorMessage.join(', '));
                return;
            }

            dto.citizenship = dto?.address?.addressCountryAlfa2 || 'MX';
            let lang;
            switch (dto.citizenship) {
                case 'MX':
                    lang = 'es';
                    break;
                case 'AR':
                    lang = 'es';
                    break;
                default:
                    lang = 'en';
                    break;
            }
            if (!dto.password) {
                dto.password = generate({
                    length: DEFAULT_PASSWORD_LENGTH,
                    numbers: true,
                    lowercase: true,
                    uppercase: true,
                    strict: true,
                });
            }

            dispatch(createRetailPlayer({
                dto,
                alertService,
                agentOwnerId: activeBrand?.siteName === SITE_BRAND.Dealer21 ? agentSelf?.id : undefined
            }));
        } else {
            if (player.email !== retailPlayer.email && !EMAIL_REG_EXP.test(player.email)) {
                alertService.showError(i18n.t('crm.alerts.emailIsIncorrect'));

            } else if (player?.firstName.length < 2 || player?.firstName.length > 50) {
                alertService.showError(i18n.t('crm.wrongLengthFirstName'));

            } else if (player?.lastName.length < 2 || player?.lastName.length > 50) {
                alertService.showError(i18n.t('crm.wrongLengthLastName'));

            }/* else if (player?.nickName.length < 4 || player?.nickName.length > 20) {
                alertService.showError(i18n.t('crm.wrongLengthUsername'));

            } */ else if (player?.gender === '') {
                alertService.showError(i18n.t('crm.wrongGender'));

            } else if (player?.phone === '') {
                alertService.showError(i18n.t('crm.wrongPhone'));

            } else if (PHONE_REG_EXP.test(player?.phone)) {
                alertService.showError(i18n.t('crm.wrongPhoneInput'));
            } else {
                e.preventDefault();
                const dto = prepareUpdatePlayerGeneralDto(
                    retailPlayer,
                    {...retailPlayer, ...player}
                );
                dispatch(updateRetailPlayer({id: retailPlayer.oid, dto}));
            }
        }
    };

    const handleCancel = useCallback(() => {
        setPlayer({...initialPlayer});
        setIsEdit(false);
    }, [initialPlayer]);

    return (
        <div ref={refGeneralInfo}>
            <Row>
                <Col lg="12">
                    <Card
                        className={
                            clsx("retail-search",
                                {["retail-view"]: isEditMode, ['bigSize']: isBigSizeGeneralSection}
                            )}
                    >
                        <div className="title-section">
                            <img
                                src={isCreatMode ? personalIcon : infoIcon}
                                alt="icon profile"
                            />
                            {i18n.t(isCreatMode ? 'retail.profile' : 'retail.customer.title.generalInfo')}
                        </div>
                        <form onSubmit={onSubmit}>
                            <Row className={'filter-player_type-row personal-info__row'}>
                                {fields?.map((item) => (
                                    <Col key={item.stateKey}>
                                        <ACommonDataField
                                            component={item.component}
                                            filterSelection={item.filterSelection}
                                            label={i18n.t(`crm.${item.label || item.stateKey}`)}
                                            id={item.stateKey}
                                            value={player[item.stateKey]}
                                            handleChange={handleChange(item.stateKey)}
                                            datePickerComponentType='MobileDatePicker'
                                            isBirthDate={item.stateKey === 'birthDate'}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Col lg="12">
                                <div className={'d-flex justify-content-center mt-4 wrapperButtons'}>
                                    {
                                        isCreatMode && (
                                            <div
                                                className={"retail_profile_save_btns"}
                                            >
                                                <button
                                                    className={clsx("btn btn-rounded btn-light clear-btn",
                                                        {['es']: i18n.language === 'es'})}
                                                    onClick={clearFields}
                                                    disabled={!isEdit}
                                                    type={'reset'}
                                                >
                                                    {i18n.t("retail.clearFields")}
                                                </button>
                                                <button
                                                    className='btn btn-rounded btn-primary'
                                                    type={'submit'}
                                                    disabled={!isRequiredFill || retailCreated}
                                                >
                                                    {i18n.t("retail.createPerson")}
                                                </button>
                                                {retailCreated &&
                                                    <div
                                                        className={"retail_profile_loader"}
                                                    >
                                                        <Loader size={'sm'}/>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        isEditMode && isEdit && (
                                            <div
                                                className={"retail_profile_save_btns"}
                                            >
                                                <DeclineSubmitBtnGroup
                                                    onSave={onSubmit}
                                                    onCancel={handleCancel}
                                                    isDisabled={generalUpdated || !isEdit}
                                                />
                                                {generalUpdated &&
                                                    <div
                                                        className={"retail_profile_loader"}
                                                    >
                                                        <Loader size={'sm'}/>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default Profile;
