import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import PropTypes from 'prop-types';
import { STATUS } from '../../../constants/affiliates';
import i18n from '../../../i18n';
import './style.scss';
import useClickOutside from '../../hooks/useClickOutside';
import useHandleEsc from '../../hooks/useHandleEsc';
import Loader from '../Loader';

const Arrow = ({ color, width = 11, height = 7, className, onClick }) => (
    <div className={className} onClick={onClick}>
        <svg
            width={width}
            height={height}
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 1L5.5 6L10 1" stroke={color} />
        </svg>
    </div>
);

const AffiliateStatus = ({
    status,
    labelClassName = '',
    label = i18n.t('crm.status'),
    statuses,
    handleChange,
    isLoading
}) => {
    const [isShowDropdown, setShowDropdown] = useState(false);

    const areaRef = useRef();
    useClickOutside(areaRef, () => setShowDropdown(false));
    useHandleEsc(() => setShowDropdown(false));

    const iconColor = useMemo(() => {
        switch (status) {
            case STATUS.ACTIVE:
                return '#37A57E';
            case STATUS.BLOCKED:
                return '#898996';
            case STATUS.LEAD:
                return '#3BB947';
            case STATUS.FROZEN:
                return '#A2A2B9';
            case STATUS.CLOSED:
                return '#E44F73';
            default:
                return '#A2A2B9';
        }
    }, [status]);

    const onClick = useCallback(() => {
        setShowDropdown(prev => !prev);
    }, [isShowDropdown, setShowDropdown]);

    useEffect(() => {
        setShowDropdown(false);
    }, [isLoading]);

    return (
        <span className={'status'}>
            <span className={labelClassName}>{label}:</span>

            <span
                ref={areaRef}
                className={'status-wrapper'}
                style={{ borderColor: iconColor }}
                onClick={isLoading ? () => {} : onClick}
            >
                {isLoading && <Loader size={'sm'} />}
                {!isLoading && (
                    <>
                        <span
                            className={'status-wrapper__value'}
                            style={{ color: iconColor }}
                        >
                            {i18n.t(`affiliates.constants.${status}`)}
                        </span>

                        <Arrow
                            color={iconColor}
                            className={`status-wrapper__arrow${
                                isShowDropdown ? ' open' : ''
                            }`}
                        />
                    </>
                )}
            </span>

            <div className={`status-dropdown${isShowDropdown ? ' show' : ''}`}>
                {Object.keys(statuses).map(markStatus => {
                    const isActive = status === markStatus;

                    return (
                        <div
                            key={markStatus}
                            className={'mark'}
                            onClick={handleChange(markStatus)}
                        >
                            <div
                                className={`mark-wrapper${
                                    isActive ? ' active' : ''
                                }`}
                            >
                                <div
                                    className={`mark-icon ${markStatus.toLowerCase()}`}
                                ></div>
                            </div>

                            <div className={'mark-text font-w-500'}>
                                {i18n.t(`affiliates.constants.${markStatus}`)}
                            </div>
                        </div>
                    );
                })}
            </div>
        </span>
    );
};

AffiliateStatus.proptypes = {
    status: PropTypes.string.isRequired,
    labelClassName: PropTypes.string,
    label: PropTypes.string,
    statuses: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
};

export default React.memo(AffiliateStatus);
