import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './react-select-input.scss';
import {useTranslation} from "react-i18next";

function SelectInput({ onChange, classNamePrefix, options, value, disabled, placeholder, isSearchable, ...props }) {
    const { t } = useTranslation();

    const handleChange = useCallback(
        selectValue => onChange(selectValue.value),
        [onChange]
    );

    const valueOption = options?.find && options?.find(item => item.value === value);

    return (
        <Select
            {...props}
            value={valueOption ? valueOption : null}
            onChange={handleChange}
            options={options}
            className={`react-select ${props.className || ''}`}
            classNamePrefix="react-select"
            isDisabled={disabled}
            isSearchable={isSearchable}
            placeholder={placeholder ?? t("crm.select")}
        />
    );
}

SelectInput.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    classNamePrefix: PropTypes.string
};

export default React.memo(SelectInput);
