import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

function NumberInput(props) {
    const handleChange = useCallback(
        e => props.onChange(e.target.value),
        [props.onChange]
    );

    return (
        <input
            {...props}
            className={props.className ? props.className : 'form-control'}
            type="number"
            value={props.value}
            onChange={handleChange}
        />
    );
}

NumberInput.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(NumberInput);
