import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

import i18n from '../../../i18n';

import TextInput from '../../../common/inputs/TextInput';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

import closeIcon from '../../../assets/images/layout/thin-cross.svg';
import saveIcon from '../../../assets/images/common/Frame 176 (1).svg';

const SaveFilterModal = ({ onHide, onSave }) => {
    const [input, setInput] = useState('');

    const handleChange = useCallback(
        value => {
            setInput(value);
        },
        [setInput]
    );

    const handleSave = useCallback(() => {
        onSave(input);
    }, [input]);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="filter-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('filter.saveFilter')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <Row className={'save-modal__wrapper'}>
                    <Col xs={12}>
                        <label htmlFor="edit-modal__filter-name">
                            {`${i18n.t("crm.filter.name")}*:`}
                        </label>
                        <TextInput
                            id={'edit-modal__filter-name'}
                            value={input}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>

                <div className={'modal-btn-wrapper'}>
                    <Button
                        color={'outline-primary modal-btn'}
                        onClick={onHide}
                    >
                        <img src={closeIcon} alt={i18n.t('filter.cancel')} />
                        <span>{i18n.t('filter.cancel')}</span>
                    </Button>

                    <Button
                        color={'primary modal-btn'}
                        onClick={handleSave}
                        disabled={!input || !input?.trim()}
                    >
                        <img src={saveIcon} alt={i18n.t('filter.save')} />
                        <span>{i18n.t('filter.save')}</span>
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

SaveFilterModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default SaveFilterModal;
