import React from 'react';
import PropTypes from 'prop-types';
import SwitchInput from './common/SwitchInput';

function SwitchCheckboxInput({
    checked,
    onChange,
    className,
    additionalClassName,
    children,
    disabled,
    ...props
}) {

    return (
        <div className={`app-form-switch ${additionalClassName}`}>
            <label
                className="form-check-label"
                onClick={() => !disabled && onChange(!checked)}
            >
                {children}
            </label>

            <SwitchInput
                isDisabled={disabled}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
}

SwitchCheckboxInput.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    additionalClassName: PropTypes.string
};

export default React.memo(SwitchCheckboxInput);
