import React, {useCallback, useEffect, useState} from "react";
import { Select } from '@mui/material';
import { MenuItem } from "@material-ui/core";
import {allPageSize, pageSizeOptions} from "../../../common/constants/common";
import CustomPagination from "../../../common/tables/CustomPagination";
import dropDownArrow from "../../../assets/images/layout/menu-icons/dropDownArrow.svg";
import SelectedInfoView from "../../../components/NewCustomTable/SelectedInfoView";
import {useLocation} from "react-router-dom";
import { throttle } from "lodash";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import clsx from "clsx";
import './components.scss';

const SelectTableView = (props) => {
    const {
        onChange,
        recordsCount,
        pageSize,
        pageCount,
        activePage,
        isSticky = false,
        onChangePage,
        selectedCount,
    } = props;
    const location = useLocation();
    const size = useWindowSize();

    const isGameCategoryPage = location.pathname.includes('/game-categories');
    const isGameRoundsPage = location.search.includes('GAME_ROUND');

    const [open, setOpen] = useState(false);
    const [isFixedPanel, setIsFixedPanel] = useState(true);

    const handleChange = (e) => {
        onChange(e.target.value);
    };

    let lastScroll = 0
    const onScroll = () => {
        const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (currentScroll < 0) {
            currentScroll = 0;
        }
        if (document.getElementById("layout-wrapper").getBoundingClientRect().bottom <= window.innerHeight) {
            lastScroll = currentScroll;
        };

        if (currentScroll >= lastScroll) {
            setIsFixedPanel(false);
        } else {
            setIsFixedPanel(true);
        }
        lastScroll = currentScroll;
    };

    const throttledOnScroll = throttle(onScroll, 100);

    useEffect(() => {
        if (size?.width <= SCREEN_SIZES.MAX_MOBILE_VIEW && isSticky) {
            setIsFixedPanel(true);
            document.body.addEventListener('scroll', throttledOnScroll);
            document.addEventListener('scroll', throttledOnScroll);

            return () => {
                document.body.removeEventListener('scroll', throttledOnScroll);
                document.removeEventListener('scroll', throttledOnScroll);
            }
        } else {
            setIsFixedPanel(false);
        }
    }, [size.width]);

    const calcVisiblePageSizeOptions = () => {
        const visiblePageSizeOptions = pageSizeOptions.filter((value) => {
            return recordsCount >= value;
        });

        if (isGameCategoryPage) {
            visiblePageSizeOptions.push(allPageSize);
        }
        if (isGameRoundsPage) {
            visiblePageSizeOptions.shift();
        }

        return visiblePageSizeOptions;
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const removeFocus = () => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    return (
        <div
            style={{height: '40px'}}
            className={clsx(
                'd-flex',
                'align-items-center',
                'justify-content-between',
                'pagination__outer',
                {'pagination__outer_is_fixed': isFixedPanel}
            )}
        >
            <div className='d-flex align-items-center pagination__inner'>
                {recordsCount > 10 &&
                    <Select
                        value={pageSize === recordsCount ? 'All' : pageSize}
                        label="Page limit"
                        onChange={handleChange}
                        className='select-table-view ml-8'
                        open={open}
                        onOpen={handleToggle}
                        IconComponent={() => {
                            return (
                                <img
                                    onClick={handleToggle}
                                    src={dropDownArrow}
                                    style={{cursor: 'pointer'}}
                                    alt=""
                                />
                            )
                        }}
                        onClose={() => {
                            setOpen(false);
                            removeFocus();
                        }}
                    >
                        {calcVisiblePageSizeOptions().map((value, key) => (
                            <MenuItem key={key} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                }

                <div className='ml-10'>
                    <SelectedInfoView
                        offset={(activePage - 1) * pageSize}
                        total={recordsCount}
                        listCount={pageSize}
                        gamesCount={recordsCount}
                        selectedCount={selectedCount}
                    />
                </div>
            </div>

            <div>
                {(pageCount > 1) && (
                    <CustomPagination
                        activePage={activePage}
                        pageCount={pageCount}
                        onChange={onChangePage}
                    />
                )}
            </div>
        </div>
    )
}

export default SelectTableView;
