import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_DEPOSIT } from './actionTypes';
import { getDepositSuccess, getDepositFail } from './actions';
import { getPaymentDepositApi } from '../../../helpers/paymentApi';

function* fetchDepositSaga({ payload }) {
    try {
        const response = yield call(getPaymentDepositApi, payload);

        yield put(getDepositSuccess(response.data));
    } catch (error) {
        yield put(getDepositFail(error));
    }
}

function* depositSaga() {
    yield takeEvery(GET_DEPOSIT, fetchDepositSaga);
}

export default depositSaga;
