import { trimToNull } from '../../../common/utils/StringUtils';
import Formatter from '../../../utils/Formatter';

// common
export function prepareFieldErrors(data = []) {
    const fieldErrors = {};

    data.forEach(item => {
        if (item.constraints) {
            const constraintsKeys = Object.keys(item.constraints);
            let errorMessageKey = '';

            // take the first value
            if (constraintsKeys.length > 0) {
                errorMessageKey = constraintsKeys[0];
            }

            fieldErrors[item.property] = errorMessageKey;
        }

        // for complicated objects
        if (item.children && item.children.length > 0) {
            fieldErrors[item.property] = prepareFieldErrors(item.children);
        }
    });

    return fieldErrors;
}

// info
export function prepareUpdateAgentDto(oldData = {}, updatedData = {}) {
    const dto = {};

    Object.keys(oldData).forEach(fieldName => {
        if (
            oldData?.[fieldName] !== updatedData[fieldName] &&
            fieldName === 'birthDate'
        ) {
            dto[fieldName] = Formatter.stringifyDate(
                new Date(updatedData[fieldName])
            );
        } else if (
            oldData?.[fieldName] !== updatedData[fieldName] &&
            typeof updatedData[fieldName] === 'string'
        ) {
            dto[fieldName] = trimToNull(updatedData[fieldName]);
        }
    });

    return dto;
}
