import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clock from '../../assets/images/layout/clock.svg';
import Tooltip from '@mui/material/Tooltip';
import {timeValues, useTimeContext} from "../../context/TimeContext";


const ToolbarTime = () => {
    const [currentTime, setCurrentTime] = useState(null);
    const { timeFormat } = useTimeContext();

    useEffect(() => {
        setCurrentTime(timeFormat.parseTime(moment()).format('HH:mm'));
    }, [timeFormat]);


    return (
        <div className="toolbar-component toolbar-time p-0 m-0">
            <Tooltip title={timeFormat.timeValue + ' time'} placement="bottom" arrow>
                <img
                    className="component-img"
                    style={{ cursor: 'pointer'}}
                    src={clock} alt="time"
                    height="24"
                />
            </Tooltip>
            {currentTime} {timeFormat.timeValue}
        </div>
    );
};

export default ToolbarTime;
