import {get, getFile, post} from './api_helper';
import { prepareFilterSearch } from './SearchUtils';

const getPaymentDepositListApi = query => {
    const url = `/payment/deposits?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPaymentActionDepositListApi = query => {
    const url = `/payment/deposits/pending-action?${prepareFilterSearch(query)}`;
    return get(url);
};

const searchPaymentDepositListApi = query => {
    const url = `/payment/deposits/search?${prepareFilterSearch(query)}`;
    return get(url);
};

const searchPaymentWithdrawalListApi = query => {
    const url = `/payment/withdrawals/search?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPaymentDepositApi = id => {
    const url = `/payment/deposits/${id}`;
    return get(url);
};

const getPaymentWithdrawalListCountApi = ({ headers }) => {
    const url = `/payment/withdrawals`;
    return get(url, {headers});
};

const getPaymentPendingWithdrawalListCountApi = ({headers, filter}) => {
    const url = `/payment/pending?${prepareFilterSearch(filter)}`
    return get(url, { ...headers });
};

const getTransferWithdrawalListCountApi = ({headers, filter}) => {
    const url = `/payment/pending-wire/?${prepareFilterSearch(filter)}`;
    return get(url, { ...headers });
};

const getPaymentWithdrawalListApi = query => {
    const url = `/payment/withdrawals?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPaymentPendingWithdrawalListApi = query => {
    const url = `/payment/pending?${prepareFilterSearch(query)}`;
    return get(url);
};

const getPaymentWithdrawalApi = id => {
    const url = `/payment/withdrawals/${id}`;
    return get(url);
};

const approveTransactionApi = ({ id, dto }) => {
    const url = `/payment/transactions/${id}/approve`;
    return post(url, dto);
};

const declineTransactionApi = ({ id, dto }) => {
    const url = `/payment/transactions/${id}/decline`;
    return post(url, dto);
};

const cancelTransactionApi = ({ id, dto }) => {
    const url = `/payment/transactions/${id}/cancel`;
    return post(url, dto);
};

const downloadPaymentPendingTransferWithdrawalListApi = (query) => {
    const url = `/payment/withdrawals/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const getPaymentPendingTransferWithdrawalListApi = query => {
    const url = `/payment/pending-wire?${prepareFilterSearch(query)}`;
    return get(url);
};

const downloadPaymentDepositListApi = (query) => {
    const url = `/payment/deposits/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const downloadPaymentWithdrawalListApi = (query) => {
    const url = `/payment/withdrawals/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const downloadPaymentPendingWithdrawalListApi = (query) => {
    const url = `/payment/pending/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};

const getCardNetworksApi = () => {
    const url = `/payment/card-networks`;
    return get(url);
};

const getPaymentProvidersApi = () => {
    const url = `/payment/payment-providers`;
    return get(url);
};

export {
    downloadPaymentPendingTransferWithdrawalListApi,
    getPaymentPendingTransferWithdrawalListApi,
    getPaymentDepositListApi,
    getPaymentActionDepositListApi,
    searchPaymentDepositListApi,
    searchPaymentWithdrawalListApi,
    getPaymentDepositApi,
    getPaymentWithdrawalListApi,
    getPaymentWithdrawalApi,
    getPaymentPendingWithdrawalListApi,
    approveTransactionApi,
    declineTransactionApi,
    cancelTransactionApi,
    downloadPaymentDepositListApi,
    downloadPaymentWithdrawalListApi,
    downloadPaymentPendingWithdrawalListApi,
    getPaymentWithdrawalListCountApi,
    getPaymentPendingWithdrawalListCountApi,
    getTransferWithdrawalListCountApi,
    getCardNetworksApi,
    getPaymentProvidersApi,
};
