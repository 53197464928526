import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import ClipboardButton from '../../../common/cellData/CopyButton';
import PaymentStatusView from '../../../components/Status/PaymentStatusView';

const PaymentIdLine = props => {
    const { transaction } = props;

    return (
        <div className="d-flex justify-content-between payment__player__header">
            <div className="flex-1 header__payment__row">
                <span className="header__payment__title">{i18n.t('finance.paymentId')}{' '}</span>
                <span className="payment-id">{transaction.id}</span>
                &nbsp;&nbsp;
                <ClipboardButton text={transaction.id} />
            </div>
            <div className="flex-1 header__payment__row">
                <span className="status-title">
                    {i18n.t('finance.status')}:
                </span>
                &nbsp;&nbsp;
                <PaymentStatusView status={transaction.status} />
            </div>
        </div>
    );
};

PaymentIdLine.propTypes = {
    transaction: PropTypes.object
};

export default PaymentIdLine;
