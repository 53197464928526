import React, {Component} from 'react';
import './ErrorPage.scss';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props?.fallback || <ErrorPage /> ;
        }

        return this.props.children;
    }
}

const ErrorPage = (props) => {
    const {
        errorMessage,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();


    return (
        <div className='page-content app-error-page'>
            <h1 className='app-error-message'>
                {errorMessage
                    ? t(`crm.${errorMessage}`)
                    : t('crm.appError')
                }
            </h1>
            <div className='d-flex'>
                <button
                    className='btn btn-primary btn-rounded go-back-btn'
                    onClick={() => history.goBack()}
                >
                    {t('crm.goBack')}
                </button>
                <button
                    className='btn btn-primary btn-rounded refresh-page-btn'
                    onClick={() => location.reload()}
                >
                    {t('crm.refreshPage')}
                </button>
            </div>
        </div>
    );
};

ErrorBoundary.propTypes = {
    fallback: PropTypes.node,
};

export default ErrorBoundary;

