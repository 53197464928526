import React, {
    useCallback,
    useState,
    useMemo,
    useEffect
} from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import RadioInput from '../../common/inputs/RadioInput';

import { AFFILIATES_RADIO_VALUES } from '../../constants/affiliates';

import { addAffiliateAtList } from '../../store/affiliates/actions';
import { addAffiliatesDataAdapter } from './dataAdapters/addAffiliates';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';
import {EMAIL_REG_EXP} from "../../constants/validations";
import { useTranslation } from "react-i18next";
import {checkPhoneNumber} from "../../utils/consts";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const initialState = {
    contactName: '',
    name: '',
    branded: AFFILIATES_RADIO_VALUES.BRANDED,
    phone: '',
    email: ''
};

function AddAffiliateModal({ onHide }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isLoading, errors, total } = useSelector(state => state.Affiliates);
    const [state, setState] = useState(initialState);
    const isSaved = useMemo(() => total, []);

    const isFieldsFull = useMemo(
        () => state.email && state.contactName && state.name && state.phone,
        [state]
    );

    const handleChange = useCallback(
        name => value => {
            setState(prev => ({ ...prev, [name]: value }));
        },
        [state]
    );


    const isValid = (key, fieldValue) => {
        let result;
        switch (key) {
            case 'email':
                result = EMAIL_REG_EXP.test(fieldValue) && fieldValue.length > 0;
                break;
            case 'phone':
                result = (fieldValue.length > 0 && checkPhoneNumber(fieldValue));
                break;
            case 'name':
                result = fieldValue.length > 0 && fieldValue.indexOf(' ') < 0 && /^[a-zA-Z]+$/.test(fieldValue);
                break;
            default:
                result = true;
                break;
        }
        return result;
    }

    const hasError = useMemo(() => {
        for (const key of Object.keys(state)) {
            if (!isValid(key, state[key])) {
                return true;
            }
        }
        return false;
    }, [state]);

    const handleSave = useCallback(() => {
        dispatch(addAffiliateAtList(addAffiliatesDataAdapter(state)));
    }, [state]);

    const radio = useMemo(
        () => [
            {
                checked: state.branded === AFFILIATES_RADIO_VALUES.BRANDED,
                value: AFFILIATES_RADIO_VALUES.BRANDED,
                onChange: handleChange('branded'),
                label: i18n.t('affiliates.branded')
            },
            {
                checked: state.branded === AFFILIATES_RADIO_VALUES.NON_BRANDED,
                value: AFFILIATES_RADIO_VALUES.NON_BRANDED,
                onChange: handleChange('branded'),
                label: i18n.t('affiliates.nonBranded')
            }
        ],
        [state, handleChange]
    );

    const contacts = useMemo(
        () => [
            {
                label: i18n.t('affiliates.name'),
                id: 'new-affiliate-name',
                wrapperClassName: 'mb-12',
                key: 'name',
                placeholder: i18n.t('affiliates.enterName')
            },
            {
                label: i18n.t('affiliates.contactName'),
                id: 'new-affiliate-contact-name',
                wrapperClassName: 'mb-12',
                key: 'contactName',
                placeholder: i18n.t('affiliates.enterContactName')
            },
            {
                label: i18n.t('affiliates.email'),
                id: 'new-affiliate-email',
                wrapperClassName: 'mb-12',
                key: 'email',
                placeholder: i18n.t('affiliates.enterEmail')
            },
            {
                label: i18n.t('affiliates.phone'),
                id: 'new-affiliate-phone',
                wrapperClassName: '',
                key: 'phone',
                placeholder: i18n.t('affiliates.enterPhone')
            }
        ],
        [state, handleChange]
    );

    const buttons = useMemo(
        () => [
            {
                className: 'btn btn-rounded btn-primary shadow-none w-100',
                onClick: handleSave,
                disabled: isLoading || !isFieldsFull || hasError,
                label: i18n.t('crm.create')
            }
        ],
        [state, isLoading, isFieldsFull]
    );

    useEffect(() => {
        if (total !== isSaved) onHide();
    }, [total]);

    const fieldError = (id, fieldValue) => {
        if (id === 'new-affiliate-email') {
            return !isValid('email', fieldValue);
        }

        if (id === 'new-affiliate-phone') {
            return !isValid('phone', fieldValue);
        }

        if (id === 'new-affiliate-name') {
            return !isValid('name', fieldValue);
        }
        return false;
    }
    const fieldHelperText = (id, fieldValue) => {
        if (id === 'new-affiliate-email') {
            if (!isValid('email', fieldValue)) {
                return t('errors.invalidEmail');
            }
        }
        if (id === 'new-affiliate-phone') {
            if (!isValid('phone', fieldValue)) {
                return t('errors.invalidPhone');
            }
        }
        if (id === 'new-affiliate-name') {
            if (!isValid('name', fieldValue)) {
                return t('errors.invalidName');
            }
        }
        return false;
    };

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="new-affiliate-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('affiliates.newAffiliate')}
                </h5>
                <button
                    className="close"
                    onClick={onHide}
                    disabled={isLoading}
                />
            </div>

            <ModalBody>
                <Row className={'new-affiliate-form-wrapper'}>
                    <Col>
                        <Row
                            xs={'auto'}
                            className={'new-affiliate-form-wrapper_radio'}
                        >
                            {radio.map(
                                (
                                    { checked, value, onChange, label },
                                    index
                                ) => (
                                    <Col key={index}>
                                        <RadioInput
                                            checked={checked}
                                            value={value}
                                            onChange={onChange}
                                            key={index}
                                        >
                                            {label}
                                        </RadioInput>
                                    </Col>
                                )
                            )}
                        </Row>

                        <Row className={'align-items-center'}>
                            {contacts.map(
                                (
                                    {
                                        wrapperClassName,
                                        label,
                                        placeholder,
                                        id,
                                        key
                                    },
                                    index
                                ) => {
                                    let error = errors[key];
                                    let helperText = errors[key];

                                    if(key === 'email') {
                                        error = fieldError('new-affiliate-email', state['email']);
                                        helperText = fieldHelperText('new-affiliate-email', state['email']);
                                    }
                                    if(key === 'phone') {
                                        error = fieldError('new-affiliate-phone', state['phone']);
                                        helperText = fieldHelperText('new-affiliate-phone', state['phone']);
                                    }
                                    if(key === 'name') {
                                        error = fieldError('new-affiliate-name', state['name']);
                                        helperText = fieldHelperText('new-affiliate-name', state['name']);
                                    }

                                    return (
                                        <Col
                                            key={index}
                                            xs={12}
                                            className={`${wrapperClassName}`}
                                        >
                                            <ACommonDataField
                                                id={id}
                                                fulWidth
                                                label={label}
                                                value={state[key]}
                                                handleChange={handleChange(key)}
                                                error={error}
                                                helperText={helperText}
                                            />
                                        </Col>
                                    )
                                }
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className={'new-affiliate-btn-wrapper'}>
                        {buttons.map(({ label, ...props }, index) => (
                            <button key={index} {...props}>
                                {label}
                            </button>
                        ))}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

AddAffiliateModal.propTypes = {
    onHide: PropTypes.func.isRequired
};

export default React.memo(AddAffiliateModal);
