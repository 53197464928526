import { SITE_BRAND } from "../../../constants/siteBrands";

export const siteSettings = {
    restrictions: {
        fraudRestrictions: new Set([
            SITE_BRAND.WINPOTMX,
            SITE_BRAND.SPINOLOCO,
        ]),
    },
};

export const renderByConfig = (brand, restrictionType) => {
    return siteSettings.restrictions[restrictionType]?.has(brand) || false;
};
