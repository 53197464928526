import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Card } from "reactstrap"
import MetaTags from 'react-meta-tags'
import "./styles.scss"
import "../../assets/scss/pages/common.scss"
import { useDispatch, useSelector } from "react-redux"
import {
	downloadPendingWithdrawalList,
	getPendingWithdrawalList,
	resetPendingWithdrawalListAlertsErrors
} from "../../store/actions"
import TableFilter from "../../components/TableFilter";
import {
	FILTER_CATEGORY,
	FILTER_MENU_PAYMENT_LIST,
	FILTER_WD_PENDING_FOR_APPROVAL
} from "../../constants/Filter"
import useListPage from "../../hooks/useListPage";
import PendingWithdrawalsTable, { WITHDRAWALS_SORTED_FIELDS } from "./tables/PendingWithdrawalsTable";
import download from "../../assets/images/common/download.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SelectTableView from "../Content/components/SelectTableView";
import { fetchBrandSettingsPage } from "../../store/brand/brandPage/actions";
import settingsWhite from "../../assets/images/layout/settingsWhite.svg";
import Tooltip from "@mui/material/Tooltip";
import { DIRECTION } from "../../constants/layout";
import clsx from "clsx";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";


function PendingWithdrawals() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const path = location?.pathname;
	const isPendingWithdrawalPage = path.includes('/withdrawals-pending-approve');
	const [brandCurrencies, setBrandCurrencies] = useState([]);
	const { state: stateVipBrand} = useVipLevelMapping();

	const { active: activeBrand } = useSelector(state => state.Brand);
	const { data: brandData } = useSelector(state => state.BrandSettingsPage);
	const { currentFilter } = useSelector(state => state.Filter);
    const { isLoading, total, withdrawalList, query, error, isDownloading } = useSelector(
      state => state.PendingWithdrawalList
    );
    const [selectedRows, setSelectedRows] = useState([]);
	const [_query, _setQuery] = useState(query);
	const [isShowTableSettings, setShowTableSettings] = useState(false);

	const { pageCount, activePage, handleChangePage } = useListPage(
		query => {
			if (path.includes('withdrawals-pending-approve')) {
				if (!query?.filterDynamic?.some(item => item.key === "status")) {
					query.filterDynamic = query?.filterDynamic?.concat(FILTER_WD_PENDING_FOR_APPROVAL)
				}
			}
			return dispatch(getPendingWithdrawalList(query))
		},
		total,
		_query,
		error,
		() => dispatch(resetPendingWithdrawalListAlertsErrors()),
		null,
		currentFilter,
	);

	const handleDownload = useCallback(() => {
		const requestQuery = { ..._query, offset: 0 };
		if (currentFilter) {
			requestQuery.filterDynamic = [
				...(_query.filterDynamic || [])
			]
		}

		requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();
		dispatch(downloadPendingWithdrawalList(requestQuery))
	}, [_query, currentFilter, isPendingWithdrawalPage]);

	useEffect(() => {
		if (brandData) {
			setBrandCurrencies(brandData?.configurationFinancial.currencies || []);
		}
	}, [brandData]);

	useEffect(() => {
		dispatch(fetchBrandSettingsPage());
	}, [activeBrand]);

	useEffect(() => {
		handleChangePage(1);
	}, [_query.limit]);

	const onChangeRecords = (value) => {
		_setQuery((prevValue) => ({
			...prevValue,
			limit: value,
			offset: 0
		}));
	};

	const handleSortWithdrawals = useCallback((column, direction = DIRECTION.ASC) => {
		_setQuery((prevValue) => {
			const {sort, order, limit, filterDynamic} = prevValue;

			if ((sort !== WITHDRAWALS_SORTED_FIELDS[column]) || (order !== direction)) {
				const query = {
					limit,
					offset: 0,
					sort: WITHDRAWALS_SORTED_FIELDS[column],
					order: direction,
					filterDynamic,
				}

				dispatch(getPendingWithdrawalList(query));
				return query;
			}

			return prevValue;
		});
	}, [_query]);

	useEffect(() => {
		_setQuery(query);
	}, [query]);

    return (
		<div className="player-list players-list__mobile document-content page-content">
			<MetaTags>
				<title>{i18n.t('crm.pendingWithdrawalsList')} - {process.env.REACT_APP_APP_NAME}</title>
			</MetaTags>

			<div className="container-fluid">
				<Row>
					<Col className="col-12">
						<Card className="page-card players-card__mobile players-card">
							<div
								className="page-title-table page-card__header"
							>
								<TableFilter
									filterListOptions={FILTER_MENU_PAYMENT_LIST({
										t,
										brandCurrencies,
										stateVipBrand,
									})}
									isDisabled={isLoading}
									category={FILTER_CATEGORY.PENDING_WITHDRAWALS}
									buttonMode
								/>

								<div className="d-flex align-items-center page-card__settings">
									<button
										className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm ml-5 mr-5', {
											['d-none']: !withdrawalList?.length
										})}
										onClick={handleDownload}
										disabled={isDownloading}
									>
										<Tooltip title={t('crm.downloadList')} placement="top" arrow>
											<img src={download} alt=""/>
										</Tooltip>
									</button>
									<button
										className="btn btn-rounded btn-primary settings-btn settings-btn__mobile"
										onClick={() => setShowTableSettings(true)}
									>
										<span>
											{i18n.t('crm.settings')}
											<img src={settingsWhite} className='icon-settings' alt="Table's Settings"/>
										</span>
									</button>
								</div>
							</div>

							<PendingWithdrawalsTable
								list={withdrawalList}
								isLoading={isLoading}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								isShowTableSettings={isShowTableSettings}
								onCloseTableSettings={() => setShowTableSettings(false)}
								onSortWithdrawals={handleSortWithdrawals}
							/>

							<div className='mt-3'>
								<SelectTableView
									pageCount={pageCount}
									activePage={activePage}
									recordsCount={total}
									onChangePage={handleChangePage}
									pageSize={_query.limit}
									isSticky={true}
									selectedCount={selectedRows.length}
									onChange={onChangeRecords}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
  );
}

export default React.memo(PendingWithdrawals);
