import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { map } from 'lodash';

//i18n
import i18n from '../../../i18n';
import { withTranslation } from 'react-i18next';

import { LanguageContext } from '../../../context/languageContext';

import languages from '../../../common/languages';

import expandMore from '../../../assets/images/common/arrow-drop.svg';

const LanguageDropdown = () => {
    const { language, setLanguage } = useContext(LanguageContext);
    const [menu, setMenu] = useState(false);

    const changeLanguageAction = useCallback(
        lang => {
            //set language as i18n
            i18n.changeLanguage(lang);
            localStorage.setItem('I18N_LANGUAGE', lang);
            setLanguage(lang);
        },
        [setLanguage]
    );

    const toggleDropdown = useCallback(() => {
        setMenu(prev => !prev);
    }, [setMenu]);

    useEffect(() => {
        const currentLanguage = localStorage.getItem('I18N_LANGUAGE');
        setLanguage(currentLanguage);
    }, []);

    return (
        <Dropdown
            isOpen={menu}
            toggle={toggleDropdown}
            className="d-inline-block"
        >
            <DropdownToggle className="btn header-item p-0" tag="button">
                {language?.toUpperCase()}
                &nbsp;&nbsp;
                <img src={expandMore} alt="" />
            </DropdownToggle>
            <DropdownMenu className="language-switch dropdown-menu-end">
                {map(Object.keys(languages), key => (
                    <DropdownItem
                        key={key}
                        onClick={() => changeLanguageAction(key)}
                        className={`notify-item ${
                            language === key ? 'active' : 'none'
                        }`}
                    >
                        {key.toUpperCase()}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default withTranslation()(LanguageDropdown);
