import React from 'react';
import clipIcon from "../../assets/images/retail/clip-attach.svg";
import "./styles.scss";
import close from "../../assets/images/common/close 1.svg";
import i18n from "i18next";
import clsx from "clsx";


const InfoUploadLocalFile = ({ files = [], reset, typeFile = '' }) => {

    return (
        <div className="wrapper_attached_files">
            <img
                src={clipIcon}
                alt="icon profile"
            />
            {
                files.map((fileElement, ind) => {
                    const [ typeFileSide, file ] = fileElement
                    const sizeInKb = Math.round(file?.size / 1024)
                    return (
                            <div className={clsx("info", {['active']: !!file})}
                                 key={typeFileSide || ind}
                            >
                                <span>
                                   {
                                       !!sizeInKb
                                           ? `${file?.name} (${sizeInKb}KB)`
                                           : i18n.t("retail.customer.KYC.fileNotUploaded")
                                   }
                                </span>
                                {
                                    file?.size &&
                                    <img
                                        src={close}
                                        alt="close icon"
                                        onClick={() => reset(typeFile || typeFileSide)}
                                        data-file={typeFile || typeFileSide}
                                    />
                                }

                            </div>
                    )
                })
            }
        </div>
    )
};

export default InfoUploadLocalFile;
