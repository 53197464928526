import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import { apiError } from '../../store/actions';

import useAlertService from '../../hooks/useAlertService';

import i18n from '../../i18n';

import '../../assets/scss/pages/login.scss';
import { DATACENTER } from "../../common/constants/common";
import eye from '../../../src/assets/images/common/eye.svg';
import closedEye from "../../assets/images/common/closedEye.svg";
import { postSync } from "../../helpers/api_helper";
import queryString from "query-string";
import { AvFieldValidationPassword } from "../../utils/validation/schemas";
import { removeXSiteIdFromStorages } from "../../common/utils/common";


const initialState = {
    password: '',
    confirmPassword: '',
};

const SetupAccountPage = ({ apiError, error}) => {
    const {loading} = useSelector(state => state.Login);
    const alertService = useAlertService();

    const { showError, showSuccess } = useAlertService();
    const [state, setState] = useState(initialState);
    const [isShowAnimate, setShowAnimate] = useState(false);
    const [isHidePage, setHidePage] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confPasswordShown, setConfPasswordShown] = useState(false);
    const [agentToken, setAgentToken] = useState('');
    const [isValidToken, setIsValidToken] = useState(true);

    useEffect(() => {
        const queryObj = queryString.parse(window.location.search);

        if (queryObj?.token) {
            return validateToken(queryObj?.token);
        }

        setIsValidToken(false);
    }, [])

    const handleValidSubmit = async () => {
        if (state.password !== state.confirmPassword) {
            showError(i18n.t('crm.alerts.passwordsDontMatch'));
            return;
        }

        const url = `/agent/activate-account`;
        try {
            const result = await postSync(url, { password: state.password, token: agentToken });
            const {success} = result;
            if (success) {
                showSuccess(i18n.t('account.successfully.activated'));
            }
        } catch (e) {
            showError(i18n.t('account.not.activated'));
            console.log(e);
        }
    }

    const validateToken = async (token) => {
        const url = `/agent/validate-token`;
        const result = await postSync(url, { token });

        if (result?.success) {
            setAgentToken(token);
        }
        else {
            showError(i18n.t('crm.tokenAuthExpired'));
            setIsValidToken(false);
        }
    };

    useEffect(() => {
        setHidePage(loading);
    }, [loading]);

    useEffect(() => {
        removeXSiteIdFromStorages();
    }, []);

    const handleChangePassword = useCallback((event) => {
        const {name, value} = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
        }, [state])

    const fields = useMemo(
        () => {
            return [
                {
                    name: 'password',
                    value: state.password,
                    placeholder: i18n.t('authentication.enterPassword'),
                    onChange: handleChangePassword,
                    type: passwordShown ? 'text' : 'password',
                    className: 'login-page__content-form__field-input',
                    label: 'New Password',
                    validate: AvFieldValidationPassword(i18n),
                },
                {
                    name: 'confirmPassword',
                    value: state.confirmPassword,
                    placeholder: i18n.t('authentication.enterPassword'),
                    onChange: handleChangePassword,
                    type: confPasswordShown ? 'text' : 'password',
                    className: 'login-page__content-form__field-input',
                    label: 'Confirm Password',
                    validate: AvFieldValidationPassword(i18n),
                }
            ]
        },
        [state, passwordShown, confPasswordShown]
    );

    useEffect(() => {
        apiError('');

        // so that the animation has time to play
        setTimeout(() => {
            setShowAnimate(true);
        }, 1000);
    }, []);

    useEffect(() => {
        if (error) {
            error.map(item => {
                showError(item.message);
            });
        }
    }, [error]);

    const togglePassword = () => {setPasswordShown(!passwordShown)}
    const toggleConfPassword = () => {setConfPasswordShown(!confPasswordShown)}


    return (
        <div
            className={`login-page wiztech-bg${
                isShowAnimate ? ' wiztech-bg__showed' : ''
            }`}
        >
            <div
                className={`login-page__content${
                    isShowAnimate ? ' login-page__content__showed' : ''
                }${isHidePage ? ' login-page__content__hided' : ''}`}
            >
                <div className={'login-page__content-text'}>
                    <h5>Activate Your Account</h5>
                    <p>{DATACENTER}</p>
                </div>

                <AvForm
                    className={'login-page__content-form'}
                >
                    {fields.map(({type, label, ...props}, index) => (
                        <Fragment key={index}>
                            {type === 'checkbox' ? (
                                <AvField
                                    type={'checkbox'}
                                    label={label}
                                    {...props}
                                />
                            ) : (
                                <div className="login-page__content-form__field">
                                    <label className="login-page__content-form__field-label">
                                        {label}
                                    </label>

                                   <div className="wrapper" style={{position : 'relative'}} >
                                       <AvField type={type}
                                                {...props}

                                       />
                                       {props.name === 'password' &&
                                           <div onClick={togglePassword} className='password-eye-reset'>
                                               <img src={passwordShown ? eye : closedEye } />
                                           </div>
                                       }
                                       {props.name === 'confirmPassword' &&
                                           <div onClick={toggleConfPassword} className='password-eye-reset'>
                                               <img src={confPasswordShown ? eye : closedEye } />
                                           </div>
                                       }
                                   </div>
                                </div>
                            )}
                        </Fragment>
                    ))}

                    <button
                        className="btn btn-primary shadow-none"
                        type="submit"
                        onClick={handleValidSubmit}
                        disabled={!isValidToken || !state.password || !state.confirmPassword}
                    >
                        {i18n.t('crm.submit')}
                    </button>
                </AvForm>

                <div className='d-flex flex-column'>
                    <Link to={`/login`}>
                        <div
                            className="login-link"
                        >
                            {i18n.t('authentication.Login')}
                        </div>
                    </Link>

                    <Link to={`/forgot-password`}>
                        <div
                            className="forgot-password"
                        >
                            {i18n.t('authentication.send.new.email')}
                        </div>
                    </Link>
                </div>
            </div>

            <footer>© {new Date().getFullYear()} {process.env.REACT_APP_APP_NAME}</footer>
        </div>
    );
};

SetupAccountPage.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
};

const mapStateToProps = state => {
    const {error} = state.Login;
    return {error};
};

export default withRouter(
    connect(mapStateToProps, {
        apiError
    })(SetupAccountPage)
);
