import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_GAMES,
    GET_ALL_GAMES,
    CREATE_GAME,
    UPDATE_GAME,
    BULK_INSERT_GAMES,
    DOWNLOAD_GAME_LIST,
    GET_GAME_TYPES,
    GET_GAME_CATEGORIES,
    CREATE_GAME_CATEGORY,
    GET_GAME_CATEGORY,
    GET_CATEGORY_GAMES,
    BULK_UPDATE_CATEGORY_GAMES,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS,
    DOWNLOAD_CATEGORY_GAME_LIST,
    GET_SITE_GAMES,
    GET_SITE_AVAILABLE_GAMES,
    PUT_SITE_GAMES,
    PUT_CATEGORY_GAMES,
    PUT_CATEGORY_GAME,
    PUT_CATEGORY_DATA,
    BULK_UPDATE_SITE_GAMES,
    UPDATE_SITE_GAME,
    REMOVE_CATEGORY,
    REMOVE_CATEGORY_GAME,
    REMOVE_SITE_GAME,
    REMOVE_SITE_GAMES,
    DOWNLOAD_SITE_GAME_LIST,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS,
    GET_GAME_VENDORS,
    GET_GAME_PROVIDERS,
    GET_SITE_PROVIDERS,
    PURGE_CATEGORY_CACHE,
    DOWNLOAD_GAMES_DB_TEMPLATE,
    UPLOAD_GAMESDB_TO_CREATE,
    DOWNLOAD_GM_TEMPLATE,
    UPDATE_GM_GAMES,
    UPDATE_DB_GAMES,
    DOWNLOAD_GM_GAMES,
} from './actionTypes';
import {
    getGamesSuccess,
    getGamesFail,
    createGameSuccess,
    createGameFail,
    updateGameSuccess,
    updateGameFail,
    bulkInsertGamesSuccess,
    bulkInsertGamesFail,
    downloadGameListSuccess,
    downloadGameListFail,
    getGameTypesSuccess,
    getGameTypesFail,
    getGameCategoriesSuccess,
    getGameCategoriesFail,
    createGameCategorySuccess,
    createGameCategoryFail,
    getGameCategorySuccess,
    getGameCategoryFail,
    getCategoryGamesSuccess,
    getCategoryGamesFail,
    getSiteGamesSuccess,
    getSiteGamesFail,
    getSiteAvailableGamesSuccess,
    getSiteAvailableGamesFail,
    putSiteGamesSuccess,
    putSiteGamesFail,
    putCategoryGamesSuccess,
    putCategoryGamesFail,
    putCategoryGameSuccess,
    putCategoryGameFail,
    putCategoryDataSuccess,
    putCategoryDataFail,
    bulkUpdateCategoryGamesSuccess,
    bulkUpdateCategoryGamesFail,
    downloadCategoryGameListSuccess,
    downloadCategoryGameListFail,
    bulkUpdateSiteGamesSuccess,
    bulkUpdateSiteGamesFail,
    bulkUpdateSiteGameAttributionsSuccess,
    bulkUpdateSiteGameAttributionsFail,
    updateSiteGameSuccess,
    updateSiteGameFail,
    removeCategorySuccess,
    removeCategoryFail,
    removeCategoryGameSuccess,
    removeCategoryGameFail,
    removeSiteGameSuccess,
    removeSiteGameFail,
    removeSiteGamesSuccess,
    removeSiteGamesFail,
    downloadSiteGameListSuccess,
    downloadSiteGameListFail,
    downloadAvailableSiteGameListSuccess,
    downloadAvailableSiteGameListFail,
    downloadSiteGameAttributionsSuccess,
    downloadSiteGameAttributionsFail,
    getGameVendorsSuccess,
    getGameVendorsFail,
    getGameProvidersSuccess,
    getGameProvidersFail,
    getSiteProvidersSuccess,
    getSiteProvidersFail,
    purgeCacheGameCategorySuccess,
    purgeCacheGameCategoryFail,
    downloadGamesDBTemplateSuccess,
    downloadGamesDBTemplateFail,
    uploadGamesDBToCreateFail,
    uploadGamesDBToCreateSuccess,
    downloadGMTemplateFail,
    downloadGMTemplateSuccess,
    updateGMGamesSuccess,
    updateGMGamesFail,
    updateDBGamesSuccess,
    updateDBGamesFail,
    downloadGMGamesSuccess,
    downloadGMGamesFail,
} from './actions';
import {
    getGamesApi,
    getAllGamesApi,
    createGameApi,
    updateGameApi,
    bulkInsertGamesApi,
    downloadGamesApi,
    getGameTypesApi,
    getGameCategoriesApi,
    getGameCategoryApi,
    getCategoryGamesApi,
    getSiteGamesApi,
    getSiteAvailableGamesApi,
    changeSiteGamesApi,
    createGameCategoryApi,
    changeCategoryGamesApi,
    changeCategoryGameApi,
    changeCategoryNamesApi,
    removeCategoryApi,
    removeCategoryGameApi,
    downloadCategoryGamesApi,
    bulkUpdateCategoryGamesApi,
    removeSiteGameApi,
    removeSiteGamesApi,
    bulkUpdateSiteGamesApi,
    bulkUpdateSiteGameAttributionsApi,
    updateSiteGameApi,
    downloadSiteGamesApi,
    downloadAvailableSiteGamesApi,
    downloadSiteGameAttributionsApi,
    getGameVendorsApi,
    getGameProvidersApi,
    getSiteProvidersApi,
    purgeCacheGameCategoryApi,
    downloadGamesDBTemplateApi,
    uploadGamesDBToCreateApi,
    downloadGMTemplateApi,
    updateGMGamesApi,
    updateDBGamesApi,
    downloadGMGamesApi,
} from '../../helpers/gamesApi';

function* fetchGames({ payload }) {
    try {
        const response = yield call(getGamesApi, payload);

        yield put(getGamesSuccess(response.data));
    } catch (error) {
        yield put(getGamesFail(error));
    }
}

function* fetchAllGames({ payload }) {
    try {
        const response = yield call(getAllGamesApi, payload);

        yield put(getGamesSuccess(response.data));
    } catch (error) {
        yield put(getGamesFail(error));
    }
}

function* fetchGameTypes({ payload }) {
    try {
        const response = yield call(getGameTypesApi, payload);

        yield put(getGameTypesSuccess(response.data));
    } catch (error) {
        yield put(getGameTypesFail(error));
    }
}

function* createGame({ payload }) {
    try {
        const response = yield call(createGameApi, payload);

        yield put(createGameSuccess(response.data));
    } catch (error) {
        yield put(createGameFail(error));
    }
}

function* updateGame({ payload }) {
    try {
        const response = yield call(updateGameApi, payload);

        yield put(updateGameSuccess(response.data));
    } catch (error) {
        yield put(updateGameFail(error));
    }
}

function* bulkInsertGames({ payload }) {
    try {
        const response = yield call(bulkInsertGamesApi, payload);

        yield put(bulkInsertGamesSuccess(response));
    } catch (error) {
        yield put(bulkInsertGamesFail(error));
    }
}

function* uploadGamesDBToCreate({ payload }) {
    try {
        const response = yield call(uploadGamesDBToCreateApi, payload);

        yield put(uploadGamesDBToCreateSuccess(response));
    } catch (error) {
        yield put(uploadGamesDBToCreateFail(error));
    }
}

function* fetchGameCategories({ payload }) {
    try {
        const response = yield call(getGameCategoriesApi, payload);

        yield put(getGameCategoriesSuccess(response.data));
    } catch (error) {
        yield put(getGameCategoriesFail(error));
    }
}

function* fetchGameCategory({ payload }) {
    try {
        const response = yield call(getGameCategoryApi, payload);

        yield put(getGameCategorySuccess(response.data));
    } catch (error) {
        yield put(getGameCategoryFail(error));
    }
}

function* createGameCategory({ payload }) {
    try {
        const response = yield call(createGameCategoryApi, payload);

        yield put(createGameCategorySuccess(response.data));
    } catch (error) {
        yield put(createGameCategoryFail(error));
    }
}

function* fetchCategoryGames({ payload }) {
    try {
        const response = yield call(getCategoryGamesApi, payload);

        yield put(getCategoryGamesSuccess(response.data));
    } catch (error) {
        yield put(getCategoryGamesFail(error));
    }
}

function* putCategoryGames({ payload }) {
    try {
        const response = yield call(changeCategoryGamesApi, payload);

        yield put(putCategoryGamesSuccess(response.data));
    } catch (error) {
        yield put(putCategoryGamesFail(error));
    }
}

function* putCategoryGame({ payload }) {
    try {
        const response = yield call(changeCategoryGameApi, payload);

        yield put(putCategoryGameSuccess(response.data));
    } catch (error) {
        yield put(putCategoryGameFail(error));
    }
}

function* putCategoryData({ payload }) {
    try {
        const response = yield call(changeCategoryNamesApi, payload);

        yield put(putCategoryDataSuccess(response.data));
    } catch (error) {
        yield put(putCategoryDataFail(error));
    }
}

function* bulkUpdateCategoryGames({ payload }) {
    try {
        const response = yield call(bulkUpdateCategoryGamesApi, payload);

        yield put(bulkUpdateCategoryGamesSuccess(response.data));
    } catch (error) {
        yield put(bulkUpdateCategoryGamesFail(error));
    }
}

function* downloadCategoryGameList({ payload }) {
    try {
        yield call(downloadCategoryGamesApi, payload);

        yield put(downloadCategoryGameListSuccess());
    } catch (error) {
        yield put(downloadCategoryGameListFail(error));
    }
}

function* removeCategory({ payload }) {
    try {
        const response = yield call(removeCategoryApi, payload);

        yield put(removeCategorySuccess(response.data));
    } catch (error) {
        yield put(removeCategoryFail(error));
    }
}

function* removeCategoryGame({ payload }) {
    try {
        const response = yield call(removeCategoryGameApi, payload);

        yield put(removeCategoryGameSuccess(response.data));
    } catch (error) {
        yield put(removeCategoryGameFail(error));
    }
}

function* purgeCacheGameCategory({ payload }) {
    try {
        const response = yield call(purgeCacheGameCategoryApi, payload);

        yield put(purgeCacheGameCategorySuccess(response.data));
    } catch (error) {
        yield put(purgeCacheGameCategoryFail(error));
    }
}

function* fetchSiteGames({ payload }) {
    try {
        const response = yield call(getSiteGamesApi, payload);

        yield put(getSiteGamesSuccess(response.data));
    } catch (error) {
        yield put(getSiteGamesFail(error));
    }
}

function* fetchSiteAvailableGames({ payload }) {
    try {
        const response = yield call(getSiteAvailableGamesApi, payload);

        yield put(getSiteAvailableGamesSuccess(response.data));
    } catch (error) {
        yield put(getSiteAvailableGamesFail(error));
    }
}

function* putSiteGames({ payload }) {
    try {
        const response = yield call(changeSiteGamesApi, payload);

        yield put(putSiteGamesSuccess(response.data));
    } catch (error) {
        yield put(putSiteGamesFail(error));
    }
}

function* bulkUpdateSiteGames({ payload }) {
    try {
        const response = yield call(bulkUpdateSiteGamesApi, payload);

        yield put(bulkUpdateSiteGamesSuccess(response.data));
    } catch (error) {
        yield put(bulkUpdateSiteGamesFail(error));
    }
}

function* bulkUpdateSiteGameAttributions({ payload }) {
    try {
        const response = yield call(bulkUpdateSiteGameAttributionsApi, payload);

        yield put(bulkUpdateSiteGameAttributionsSuccess(response.data));
    } catch (error) {
        yield put(bulkUpdateSiteGameAttributionsFail(error));
    }
}

function* updateSiteGame({ payload }) {
    try {
        const response = yield call(updateSiteGameApi, payload);

        yield put(updateSiteGameSuccess(response.data));
    } catch (error) {
        yield put(updateSiteGameFail(error));
    }
}

function* removeSiteGame({ payload }) {
    try {
        const response = yield call(removeSiteGameApi, payload);

        yield put(removeSiteGameSuccess(response.data));
    } catch (error) {
        yield put(removeSiteGameFail(error));
    }
}

function* removeSiteGames({ payload }) {
    try {
        const response = yield call(removeSiteGamesApi, payload);

        yield put(removeSiteGamesSuccess(response.data));
    } catch (error) {
        yield put(removeSiteGamesFail(error));
    }
}

function* downloadGameList({ payload }) {
    try {
        yield call(downloadGamesApi, payload);

        yield put(downloadGameListSuccess());
    } catch (error) {
        yield put(downloadGameListFail(error));
    }
}

function* downloadGamesDBTemplate({ payload }) {
    try {
        yield call(downloadGamesDBTemplateApi, payload);

        yield put(downloadGamesDBTemplateSuccess());
    } catch (error) {
        yield put(downloadGamesDBTemplateFail(error));
    }
}

function* downloadGMTemplate({ payload }) {
    try {
        yield call(downloadGMTemplateApi, payload);

        yield put(downloadGMTemplateSuccess());
    } catch (error) {
        yield put(downloadGMTemplateFail(error));
    }
}

function* downloadSiteGameList({ payload }) {
    try {
        yield call(downloadSiteGamesApi, payload);

        yield put(downloadSiteGameListSuccess());
    } catch (error) {
        yield put(downloadSiteGameListFail(error));
    }
}

function* downloadAvailableSiteGameList({ payload }) {
    try {
        yield call(downloadAvailableSiteGamesApi, payload);

        yield put(downloadAvailableSiteGameListSuccess());
    } catch (error) {
        yield put(downloadAvailableSiteGameListFail(error));
    }
}

function* downloadSiteGameAttributions({ payload }) {
    try {
        yield call(downloadSiteGameAttributionsApi, payload);

        yield put(downloadSiteGameAttributionsSuccess());
    } catch (error) {
        yield put(downloadSiteGameAttributionsFail(error));
    }
}

function* fetchGameVendors({ payload }) {
    try {
        const response = yield call(getGameVendorsApi, payload);

        yield put(getGameVendorsSuccess(response.data));
    } catch (error) {
        yield put(getGameVendorsFail(error));
    }
}

function* fetchGameProviders() {
    try {
        const response = yield call(getGameProvidersApi);

        yield put(getGameProvidersSuccess(response.data));
    } catch (error) {
        yield put(getGameProvidersFail(error));
    }
}

function* fetchSiteProviders() {
    try {
        const response = yield call(getSiteProvidersApi);

        yield put(getSiteProvidersSuccess(response.data));
    } catch (error) {
        yield put(getSiteProvidersFail(error));
    }
}

function* updateGMGames({ payload }) {
    try {
        const response = yield call(updateGMGamesApi, payload);

        yield put(updateGMGamesSuccess(response.data));
    } catch (error) {
        yield put(updateGMGamesFail(error));
    }
}

function* updateDBGames({ payload }) {
    try {
        const response = yield call(updateDBGamesApi, payload);

        yield put(updateDBGamesSuccess(response.data));
    } catch (error) {
        yield put(updateDBGamesFail(error));
    }
}

function* downloadGMGames({ payload }) {
    try {
        yield call(downloadGMGamesApi, payload);

        yield put(downloadGMGamesSuccess());
    } catch (error) {
        yield put(downloadGMGamesFail(error));
    }
}

function* verificationSaga() {
    yield takeEvery(GET_GAMES, fetchGames);
    yield takeEvery(GET_ALL_GAMES, fetchAllGames);
    yield takeEvery(CREATE_GAME, createGame);
    yield takeEvery(UPDATE_GAME, updateGame);
    yield takeEvery(BULK_INSERT_GAMES, bulkInsertGames);
    yield takeEvery(DOWNLOAD_GAME_LIST, downloadGameList);
    yield takeEvery(GET_GAME_TYPES, fetchGameTypes);
    yield takeEvery(GET_GAME_CATEGORIES, fetchGameCategories);
    yield takeEvery(CREATE_GAME_CATEGORY, createGameCategory);
    yield takeEvery(GET_GAME_CATEGORY, fetchGameCategory);
    yield takeEvery(GET_CATEGORY_GAMES, fetchCategoryGames);
    yield takeEvery(DOWNLOAD_CATEGORY_GAME_LIST, downloadCategoryGameList);
    yield takeEvery(GET_SITE_GAMES, fetchSiteGames);
    yield takeEvery(GET_SITE_AVAILABLE_GAMES, fetchSiteAvailableGames);
    yield takeEvery(PUT_SITE_GAMES, putSiteGames);
    yield takeEvery(BULK_UPDATE_CATEGORY_GAMES, bulkUpdateCategoryGames);
    yield takeEvery(BULK_UPDATE_SITE_GAMES, bulkUpdateSiteGames);
    yield takeEvery(BULK_UPDATE_SITE_GAME_ATTRIBUTIONS, bulkUpdateSiteGameAttributions);
    yield takeEvery(UPDATE_SITE_GAME, updateSiteGame);
    yield takeEvery(PUT_CATEGORY_GAMES, putCategoryGames);
    yield takeEvery(PUT_CATEGORY_GAME, putCategoryGame);
    yield takeEvery(PUT_CATEGORY_DATA, putCategoryData);
    yield takeEvery(REMOVE_CATEGORY, removeCategory);
    yield takeEvery(REMOVE_CATEGORY_GAME, removeCategoryGame);
    yield takeEvery(PURGE_CATEGORY_CACHE, purgeCacheGameCategory);
    yield takeEvery(REMOVE_SITE_GAME, removeSiteGame);
    yield takeEvery(REMOVE_SITE_GAMES, removeSiteGames);
    yield takeEvery(DOWNLOAD_SITE_GAME_LIST, downloadSiteGameList);
    yield takeEvery(DOWNLOAD_AVAILABLE_SITE_GAME_LIST, downloadAvailableSiteGameList);
    yield takeEvery(DOWNLOAD_SITE_GAME_ATTRIBUTIONS, downloadSiteGameAttributions);
    yield takeEvery(DOWNLOAD_GAMES_DB_TEMPLATE, downloadGamesDBTemplate);
    yield takeEvery(DOWNLOAD_GM_TEMPLATE, downloadGMTemplate);
    yield takeEvery(GET_GAME_VENDORS, fetchGameVendors);
    yield takeEvery(GET_GAME_PROVIDERS, fetchGameProviders);
    yield takeEvery(GET_SITE_PROVIDERS, fetchSiteProviders);
    yield takeEvery(DOWNLOAD_GM_GAMES, downloadGMGames);
    yield takeEvery(UPLOAD_GAMESDB_TO_CREATE, uploadGamesDBToCreate);
    yield takeEvery(UPDATE_GM_GAMES, updateGMGames);
    yield takeEvery(UPDATE_DB_GAMES, updateDBGames);
}

export default verificationSaga;
