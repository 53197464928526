import {useSelector} from "react-redux";

export default function useVipLevelMapping() {
    const { brandVipLevelNameMapping: state, brandVipLevelBadgeColor: vipBadgeColors} =
        useSelector(state => state.BrandSettingsPage);

    function getVipLevelBrandName(vipLevel) {
        if (state && state[vipLevel]) {
            return state[vipLevel];
        }
        return null;
    }

    return {
        getVipLevelBrandName,
        state,
        vipBadgeColors: vipBadgeColors || []
    };
}
