import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import i18n from '../../i18n';
import UserStatusView from '../../components/Status/StatusView';
import ClipboardButton from '../../common/cellData/CopyButton';
import ButtonsPanel from './common/ButtonsPanel';
import TextInput from '../../common/inputs/TextInput';
import useAutomationEdit from './context/useAutomationEdit';
import AutomationContext from './context/AutomationContext';
import { RouterPrompt } from '../../common/prompt/RouterPrompt';
import PlayerActionSelect from './common/PlayerActionSelect';
import AmountConditionSelect from './common/AmountConditionSelect';
import { PLAYER_ACTION_TYPE } from './common/attributionConstants';
import TimeTypeSelect from './common/TimeTypeSelect';
import PaymentRangeSelect from './common/PaymentRangeSelect';
import GameNameSelect from './common/GameNameSelect';
import GameCategorySelect from './common/GameCategorySelect';
import GameTypeSelect from './common/GameTypeSelect';

import information_1_1 from '../../assets/images/common/information (1) 1.svg';
import group_11002_1 from '../../assets/images/common/Group 11002_1.svg';
import group_10825_1 from '../../assets/images/common/Frame 10825_1.svg';
import InputWrapper from '../../common/wrappers/InputWrapper';

// TODO check after api integration
const getDefaultItemModel = () => {
    return {
        id: Date.now(),
        action: '',
        amountCondition: '',
        amount: '',
        gameName: [],
        gameCategory: [],
        gameType: [],
        timeType: '',
        timeValue: '',
        affiliateId: '',
        paymentRange: ''
    };
};

function EnterConditionGroupsFragment({
    groups,
    onChange,
    isEdit,
    title,
    buttonTitle
}) {
    const handleChangeItem = useCallback(
        (groupId, itemId, name) => {
            return value => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                const itemToUpdate = groupToUpdate.items.find(
                    item => item.id === itemId
                );
                itemToUpdate[name] = value;

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleAddGroup = useCallback(() => {
        if (!isEdit) {
            return;
        }

        onChange([
            ...groups,
            { id: Date.now(), items: [{ id: Date.now(), attribution: '' }] }
        ]);
    }, [groups, isEdit]);

    const handleAddGroupItem = useCallback(
        groupId => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                const newItem = getDefaultItemModel();
                groupToUpdate.items = [...groupToUpdate.items, newItem];

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleDeleteGroupItem = useCallback(
        (groupId, itemId) => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                groupToUpdate.items = groupToUpdate.items.filter(
                    item => item.id !== itemId
                );

                if (
                    groupsToUpdate.length === 1 &&
                    groupToUpdate.items.length === 0
                ) {
                    const newItem = getDefaultItemModel();
                    return onChange([
                        {
                            id: Date.now(),
                            items: [newItem]
                        }
                    ]);
                }

                return groupToUpdate.items.length === 0
                    ? onChange(
                          groupsToUpdate.filter(item => item.id !== groupId)
                      )
                    : onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    return (
        <div className="attribution-card__groups">
            <div className="groups-header">
                <img className="me-3" src={information_1_1} alt="" />
                <span>{title}</span>
            </div>

            {groups.map((group, groupIndex) => {
                return (
                    <React.Fragment key={group.id}>
                        {groupIndex !== 0 && (
                            <div className="group-container-separator">
                                <span>{i18n.t('attributions.and')}</span>
                                <hr />
                            </div>
                        )}

                        <div className="group-container">
                            {group.items.map((item, index, array) => {
                                const arrayLength = array.length;

                                return (
                                    <React.Fragment key={item.id}>
                                        {index !== 0 && (
                                            <div className="group-item-separator">
                                                <span className="group-item-separator__label">
                                                    {i18n.t('attributions.or')}
                                                </span>
                                            </div>
                                        )}

                                        <div className="group-item">
                                            <span className="group-item__label font-w-600">
                                                {i18n.t(
                                                    'attributions.ifPlayer'
                                                )}
                                            </span>

                                            <div className="group-item__form">
                                                <InputWrapper
                                                    className="me-1"
                                                    errorMessage={''}
                                                >
                                                    <PlayerActionSelect
                                                        value={item.action}
                                                        onChange={handleChangeItem(
                                                            group.id,
                                                            item.id,
                                                            'action'
                                                        )}
                                                        isDisabled={!isEdit}
                                                    />
                                                </InputWrapper>

                                                {(PLAYER_ACTION_TYPE.BET ===
                                                    item.action ||
                                                    PLAYER_ACTION_TYPE.WIN ===
                                                        item.action ||
                                                    PLAYER_ACTION_TYPE.LOST ===
                                                        item.action) && (
                                                    <>
                                                        <InputWrapper
                                                            className="me-1"
                                                            label={i18n.t(
                                                                'attributions.amount'
                                                            )}
                                                            errorMessage={''}
                                                        >
                                                            <AmountConditionSelect
                                                                value={
                                                                    item.amountCondition
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'amountCondition'
                                                                )}
                                                                className="group-item__sub-select"
                                                                isDisabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>

                                                        <InputWrapper
                                                            className="me-3"
                                                            errorMessage={''}
                                                        >
                                                            <TextInput
                                                                className="form-control form-control-gray-2 amount-input"
                                                                value={
                                                                    item.amount
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'amount'
                                                                )}
                                                                placeholder={i18n.t(
                                                                    'attributions.enterAmount'
                                                                )}
                                                                disabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>

                                                        <div className="complicated-input-wrapper me-3">
                                                            <span className="complicated-input-wrapper__left-label font-w-500">
                                                                {i18n.t(
                                                                    'attributions.at'
                                                                )}
                                                            </span>

                                                            <InputWrapper
                                                                label={i18n.t(
                                                                    'attributions.gameName'
                                                                )}
                                                                errorMessage={
                                                                    ''
                                                                }
                                                            >
                                                                <GameNameSelect
                                                                    value={
                                                                        item.gameName
                                                                    }
                                                                    onChange={handleChangeItem(
                                                                        group.id,
                                                                        item.id,
                                                                        'gameName'
                                                                    )}
                                                                    className="group-item__sub-select game-name-select"
                                                                    isDisabled={
                                                                        !isEdit
                                                                    }
                                                                />
                                                            </InputWrapper>
                                                        </div>

                                                        <div className="complicated-input-wrapper me-3">
                                                            <span className="complicated-input-wrapper__left-label font-w-500">
                                                                {i18n.t(
                                                                    'attributions.or'
                                                                )}
                                                            </span>

                                                            <InputWrapper
                                                                label={i18n.t(
                                                                    'attributions.gameCategory'
                                                                )}
                                                                errorMessage={
                                                                    ''
                                                                }
                                                            >
                                                                <GameCategorySelect
                                                                    value={
                                                                        item.gameCategory
                                                                    }
                                                                    onChange={handleChangeItem(
                                                                        group.id,
                                                                        item.id,
                                                                        'gameCategory'
                                                                    )}
                                                                    className="group-item__sub-select game-category-select"
                                                                    isDisabled={
                                                                        !isEdit
                                                                    }
                                                                />
                                                            </InputWrapper>
                                                        </div>

                                                        <div className="complicated-input-wrapper">
                                                            <span className="complicated-input-wrapper__left-label font-w-500">
                                                                {i18n.t(
                                                                    'attributions.or'
                                                                )}
                                                            </span>

                                                            <InputWrapper
                                                                label={i18n.t(
                                                                    'attributions.gameType'
                                                                )}
                                                                errorMessage={
                                                                    ''
                                                                }
                                                            >
                                                                <GameTypeSelect
                                                                    value={
                                                                        item.gameType
                                                                    }
                                                                    onChange={handleChangeItem(
                                                                        group.id,
                                                                        item.id,
                                                                        'gameType'
                                                                    )}
                                                                    className="group-item__sub-select game-type-select"
                                                                    isDisabled={
                                                                        !isEdit
                                                                    }
                                                                />
                                                            </InputWrapper>
                                                        </div>
                                                    </>
                                                )}

                                                {PLAYER_ACTION_TYPE.NOT_LOGGED_IN ===
                                                    item.action && (
                                                    <>
                                                        <div className="complicated-input-wrapper me-2 ms-3">
                                                            <span className="complicated-input-wrapper__left-label font-w-500">
                                                                {i18n.t(
                                                                    'attributions.for'
                                                                )}
                                                            </span>

                                                            <InputWrapper
                                                                label={i18n.t(
                                                                    'attributions.time'
                                                                )}
                                                                errorMessage={
                                                                    ''
                                                                }
                                                            >
                                                                <TimeTypeSelect
                                                                    value={
                                                                        item.timeType
                                                                    }
                                                                    onChange={handleChangeItem(
                                                                        group.id,
                                                                        item.id,
                                                                        'timeType'
                                                                    )}
                                                                    className="group-item__sub-select"
                                                                    isDisabled={
                                                                        !isEdit
                                                                    }
                                                                />
                                                            </InputWrapper>
                                                        </div>

                                                        <InputWrapper
                                                            errorMessage={''}
                                                        >
                                                            <TextInput
                                                                className="form-control form-control-gray-2"
                                                                value={
                                                                    item.timeValue
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'timeValue'
                                                                )}
                                                                placeholder={i18n.t(
                                                                    'attributions.enterNumber'
                                                                )}
                                                                disabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>
                                                    </>
                                                )}

                                                {PLAYER_ACTION_TYPE.REGISTER ===
                                                    item.action && (
                                                    <InputWrapper
                                                        label={i18n.t(
                                                            'attributions.affiliateId'
                                                        )}
                                                        errorMessage={''}
                                                    >
                                                        <TextInput
                                                            className="form-control group-item__sub-select"
                                                            value={
                                                                item.affiliateId
                                                            }
                                                            onChange={handleChangeItem(
                                                                group.id,
                                                                item.id,
                                                                'affiliateId'
                                                            )}
                                                            placeholder={i18n.t(
                                                                'attributions.enterAffiliateId'
                                                            )}
                                                            disabled={!isEdit}
                                                        />
                                                    </InputWrapper>
                                                )}

                                                {(PLAYER_ACTION_TYPE.WITHDRAW ===
                                                    item.action ||
                                                    PLAYER_ACTION_TYPE.DEPOSIT ===
                                                        item.action ||
                                                    PLAYER_ACTION_TYPE.WITHDRAW_APPROVED ===
                                                        item.action ||
                                                    PLAYER_ACTION_TYPE.DEPOSIT_APPROVED ===
                                                        item.action ||
                                                    PLAYER_ACTION_TYPE.WITHDRAW_DECLINED ===
                                                        item.action ||
                                                    PLAYER_ACTION_TYPE.DEPOSIT_DECLINED ===
                                                        item.action) && (
                                                    <>
                                                        <InputWrapper
                                                            className="me-1"
                                                            label={i18n.t(
                                                                'attributions.amount'
                                                            )}
                                                            errorMessage={''}
                                                        >
                                                            <PaymentRangeSelect
                                                                value={
                                                                    item.paymentRange
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'paymentRange'
                                                                )}
                                                                className="group-item__sub-select"
                                                                isDisabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>
                                                        <InputWrapper
                                                            errorMessage={''}
                                                        >
                                                            <TextInput
                                                                className="form-control form-control-gray-2"
                                                                value={
                                                                    item.amount
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'amount'
                                                                )}
                                                                placeholder={i18n.t(
                                                                    'attributions.enterAmount'
                                                                )}
                                                                disabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>
                                                    </>
                                                )}
                                            </div>

                                            <div className="group-item__actions">
                                                <button
                                                    className={`btn btn-rounded btn-light app-btn-only-img-xs ${
                                                        isEdit
                                                            ? 'me-2'
                                                            : 'invisible'
                                                    }`}
                                                    onClick={handleDeleteGroupItem(
                                                        group.id,
                                                        item.id
                                                    )}
                                                >
                                                    <img
                                                        src={group_10825_1}
                                                        alt=""
                                                    />
                                                </button>
                                                {index === 0 && (
                                                    <button
                                                        className={`btn btn-rounded btn-primary app-btn-only-img-xs ${
                                                            isEdit
                                                                ? ''
                                                                : 'invisible'
                                                        }`}
                                                        onClick={handleAddGroupItem(
                                                            group.id
                                                        )}
                                                    >
                                                        <img
                                                            src={group_11002_1}
                                                            alt=""
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}

            <div className={`groups-footer ${isEdit ? '' : 'disabled'}`}>
                <span
                    className="app-text-primary"
                    role={isEdit ? 'button' : ''}
                    onClick={handleAddGroup}
                >
                    <button className="btn btn-rounded btn-primary app-btn-only-img-xs me-2">
                        <img src={group_11002_1} alt="" />
                    </button>
                    {buttonTitle}
                </span>
            </div>
        </div>
    );
}

function AttributionCard({ data }) {
    const { isEdit, setIsEdit } = useAutomationEdit();
    const [state, setState] = useState({
        model: JSON.parse(JSON.stringify(data)),
        mockErrorFields: { name: 'Error' }
    });

    const handleSave = useCallback(() => {
        setIsEdit(false);
    }, [state]);

    const handleCancel = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            model: JSON.parse(JSON.stringify(data))
        }));
        setIsEdit(false);
    }, [state, data]);

    const handleChange = useCallback(
        name => {
            return value => {
                setState(prevState => ({
                    ...prevState,
                    model: { ...prevState.model, [name]: value }
                }));
            };
        },
        [state]
    );

    return (
        <AutomationContext.Provider value={{ isEdit, setIsEdit }}>
            <Card className="attribution-card">
                <CardBody>
                    <div className="d-flex justify-content-between attribution-card__header">
                        <div>
                            {isEdit ? (
                                <InputWrapper
                                    errorMessage={state.mockErrorFields?.name}
                                >
                                    <TextInput
                                        className="form-control"
                                        value={state?.model.name}
                                        onChange={handleChange('name')}
                                    />
                                </InputWrapper>
                            ) : (
                                <span className="font-w-700 me-5">
                                    {state?.model.name}
                                </span>
                            )}

                            <span>
                                <span className="me-2">
                                    ID {state?.model.id}
                                </span>
                                <ClipboardButton text={state?.model.id} />
                            </span>
                        </div>

                        <span>
                            <span className="font-w-600 me-3">
                                {i18n.t('attributions.status')}:
                            </span>
                            <UserStatusView status={state?.model.status} />
                        </span>
                    </div>

                    <EnterConditionGroupsFragment
                        title={i18n.t('attributions.enterCondition')}
                        buttonTitle={i18n.t('attributions.addConditionGroup')}
                        groups={state.model.enterConditionGroups}
                        onChange={handleChange('enterConditionGroups')}
                        isEdit={isEdit}
                    />

                    <EnterConditionGroupsFragment
                        title={i18n.t('attributions.exitCondition')}
                        buttonTitle={i18n.t('attributions.addExitCondition')}
                        groups={state.model.exitConditionGroups}
                        onChange={handleChange('exitConditionGroups')}
                        isEdit={isEdit}
                    />
                </CardBody>
            </Card>

            <ButtonsPanel
                onCancel={handleCancel}
                onSave={handleSave}
                isEdit={isEdit}
                onSetIsEdit={setIsEdit}
            />

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}
        </AutomationContext.Provider>
    );
}

AttributionCard.propTypes = {
    data: PropTypes.object.isRequired
};

export default React.memo(AttributionCard);
