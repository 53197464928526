import React, {useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useLocation, withRouter} from "react-router-dom";
import PromotionsTab from "./PromotionsTab";
import SportPromotionsTab from "./SportPromotionsTab";
import { useTranslation } from "react-i18next";
import {
    FILTER_CATEGORY,
    FILTER_MENU_BONUSES,
    FILTER_MENU_SPORT_BONUSES,
} from "../../../constants/Filter";
import TableFilter from "../../../components/TableFilter";
import { clearCurrentFilter } from "../../../store/filters/action";
import useList from "../../../hooks/useList";
import { getPlayerPromotionsList, getPlayerSportPromotionsList } from "../../../store/players/promotions/actions";
import SelectTableView from "../../Content/components/SelectTableView";
import {SPORT_TAB_TO_HIDE} from "./common/playerViewConstants";
import queryString from "query-string";
import useHashNavigation from "../../../hooks/useHashNavigation";

const TABS = {
    CASINO_BONUSES: 'CASINO_BONUSES',
    SPORT_BONUSES: 'SPORT_BONUSES',
};

function PromotionsTabHeader({ activeTab, onChange }) {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const brand = activeBrand?.siteName;

    const switchTab = (key) => {
        onChange(key);
        dispatch(clearCurrentFilter());
    }

    return (
        <ul className="d-flex sub-content__tab bonuses-tabs-list">
            {SPORT_TAB_TO_HIDE.includes(brand) ? (
                <>
                    {Object.keys(TABS).map(key => (
                        <li
                            key={key}
                            className={activeTab === key ? 'active' : ''}
                            onClick={() => switchTab(key)}
                        >
                            {i18n.t(`crm.${key}`)}
                        </li>
                    ))}
                </>
            ) : (
                <li
                    key={TABS.CASINO_BONUSES}
                    className={activeTab === TABS.CASINO_BONUSES ? 'active' : ''}
                >
                    {i18n.t(`crm.${TABS.CASINO_BONUSES}`)}
                </li>
            )}
        </ul>
    );
}

function PromotionsTabContent(props) {
    const {playerId, playerGeneral} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {currentFilter} = useSelector(state => state.Filter);
    const {
        activePromotionsList,
        queryPromotionsList,
        totalActivePromotionsList,
        totalActiveSportPromotionsList
    } = useSelector(state => state.PlayerPromotions);
    const { changeHash } = useHashNavigation();
    const location = useLocation();
    const parseUrlSearch = () => {
        return queryString.parse(location.search)
    }

    const [_queryPromoList, _setQueryPromoList] = useState(queryPromotionsList);

    const [activeTab, setActiveTab] = useState(TABS.CASINO_BONUSES);

    const {
        pageCount: pageCountPromotions,
        activePage: activePagePromotions,
        handleChangePage: handleChangePromotionsPage
    } = useList(
        query => dispatch(getPlayerPromotionsList({ id: playerId, query })),
        totalActivePromotionsList,
        _queryPromoList,
        currentFilter,
        [],
        false
    );

    const {
        pageCount: pageCountSportPromotions,
        activePage: activePageSportPromotions,
        handleChangePage: handleChangeSportPromotionsPage
    } = useList(
        query => dispatch(getPlayerSportPromotionsList({ id: playerId, query })),
        totalActiveSportPromotionsList,
        _queryPromoList,
        currentFilter,
        [],
        false
    );

    useEffect(() => {
        if (activeTab === TABS.CASINO_BONUSES) {
            handleChangePromotionsPage(1);
        } else {
            handleChangeSportPromotionsPage(1);
        }
    }, [currentFilter, _queryPromoList])

    const onChangeRecords = (value) => {
        _setQueryPromoList({
            limit: value,
            offset: 0
        })
    }

    useEffect(() => {
        if (parseUrlSearch().tab === 'BONUSES') {
            if (activeTab === TABS.CASINO_BONUSES) {
                changeHash(TABS.CASINO_BONUSES)
            }
            if (activeTab === TABS.SPORT_BONUSES) {
                changeHash(TABS.SPORT_BONUSES)
            }
        }
    }, [activeTab]);


    return (
        <div className="detailed-card__content game-play">
            <div className="tab-wrapper">
                <PromotionsTabHeader activeTab={activeTab} onChange={setActiveTab} />
            </div>

            {(
                (activeTab === TABS.CASINO_BONUSES && totalActivePromotionsList > 0) ||
                (activeTab === TABS.SPORT_BONUSES && totalActiveSportPromotionsList > 0) ||
                currentFilter.length > 0) &&
                (
                    <div className="page-title-table player-view-tab mb-3 bonuses-tabs-filter">
                        <TableFilter
                            filterListOptions={activeTab === TABS.CASINO_BONUSES
                                ? FILTER_MENU_BONUSES(t)
                                : FILTER_MENU_SPORT_BONUSES(t)
                            }
                            category={FILTER_CATEGORY.BONUSES}
                            activeTab={activeTab}
                        />
                    </div>
                )
            }

            {activeTab === TABS.CASINO_BONUSES && (
                <>
                    <PromotionsTab
                        activePromotionsList={activePromotionsList}
                        playerId={playerId}
                        query={_queryPromoList}
                        playerGeneral={playerGeneral}
                    />
                    <SelectTableView
                        pageCount={pageCountPromotions}
                        activePage={activePagePromotions}
                        recordsCount={totalActivePromotionsList}
                        onChangePage={handleChangePromotionsPage}
                        pageSize={_queryPromoList.limit}
                        onChange={onChangeRecords}
                    />
                </>
            )}

            {activeTab === TABS.SPORT_BONUSES && (
                <>
                    <SportPromotionsTab
                        playerId={playerId}
                        playerGeneral={playerGeneral}
                    />
                    <SelectTableView
                        pageCount={pageCountSportPromotions}
                        activePage={activePageSportPromotions}
                        recordsCount={totalActiveSportPromotionsList}
                        onChangePage={handleChangeSportPromotionsPage}
                        pageSize={_queryPromoList.limit}
                        onChange={onChangeRecords}
                    />
                </>
            )}
        </div>
    );
}

export default React.memo(
    withRouter(PromotionsTabContent)
);
