import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CardBody, Col, Container, Row } from 'reactstrap';
import i_1 from '../../../assets/images/common/blocked 1.svg';
import i_2 from '../../../assets/images/common/close 1.svg';
import i_3 from '../../../assets/images/common/cogwheel 1.svg';
import i_4 from '../../../assets/images/common/coin 1.svg';
import i_5 from '../../../assets/images/common/copy.svg';
import i_6 from '../../../assets/images/common/player (1) 1.svg';
import i_7 from '../../../assets/images/common/doc.svg';
import i_8 from '../../../assets/images/common/enter (1) 1.svg';
import i_9 from '../../../assets/images/common/enter (1) 1 (1).svg';
import i_10 from '../../../assets/images/common/Frame 108.svg';
import i_11 from '../../../assets/images/common/Frame 108 (1).svg';
import i_12 from '../../../assets/images/common/Frame 189.svg';
import i_13 from '../../../assets/images/common/Frame 196.svg';
import i_14 from '../../../assets/images/common/Frame 196 (1).svg';
import i_15 from '../../../assets/images/common/Frame 222.svg';
import i_16 from '../../../assets/images/common/Frame 222 (1).svg';
import i_17 from '../../../assets/images/common/Frame 263.svg';
import i_18 from '../../../assets/images/common/Frame 264.svg';
import i_19 from '../../../assets/images/common/Frame 10816.svg';
import i_20 from '../../../assets/images/common/Frame 10825.svg';
import i_21 from '../../../assets/images/common/Frame 10950.svg';
import i_22 from '../../../assets/images/common/Tooltip.svg';
import i_23 from '../../../assets/images/common/Group 4752.svg';
import i_24 from '../../../assets/images/common/Group 4782.svg';
import i_25 from '../../../assets/images/common/Group 10400.svg';
import i_26 from '../../../assets/images/common/Group 10409.svg';
import i_27 from '../../../assets/images/common/Group 10431.svg';
import i_28 from '../../../assets/images/common/Group 10436.svg';
import i_29 from '../../../assets/images/common/Group 10752.svg';
import i_30 from '../../../assets/images/common/Group 10752_hover.svg';
import i_31 from '../../../assets/images/common/Group 10873.svg';
import i_32 from '../../../assets/images/common/Group 10939.svg';
import i_33 from '../../../assets/images/common/Group 11002.svg';
import i_34 from '../../../assets/images/common/Group 11800.svg';
import i_35 from '../../../assets/images/common/Group 11909.svg';
import i_36 from '../../../assets/images/common/home (1).svg';
import i_37 from '../../../assets/images/common/information (1) 1.svg';
import i_38 from '../../../assets/images/common/magnifiying-glass 1.svg';
import i_39 from '../../../assets/images/common/reload.svg';
import i_40 from '../../../assets/images/common/reload (1).svg';
import i_41 from '../../../assets/images/common/right-arrow (1) 1.svg';
import i_42 from '../../../assets/images/common/Union.svg';
import i_43 from '../../../assets/images/common/Union (1).svg';
import i_44 from '../../../assets/images/common/user (1).svg';
import i_45 from '../../../assets/images/common/vector.svg';
import i_46 from '../../../assets/images/common/document-verified.svg';
import i_47 from '../../../assets/images/common/Vector 71 (1).svg';
import i_48 from '../../../assets/images/common/Vector 76 (1).svg';
import i_49 from '../../../assets/images/common/Vector 76 (2).svg';
import i_50 from '../../../assets/images/common/Vector 76 (3).svg';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import i_52 from '../../../assets/images/common/Frame 179.svg';

class IconsAppPage extends Component {
    render() {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CardBody>
                                <div className="row">
                                    <div
                                        className="col-md-6"
                                        style={{ backgroundColor: 'white' }}
                                    >
                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_1} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/blocked
                                                1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_2} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/close
                                                1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_3} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/cogwheel
                                                1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_4} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/coin
                                                1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_5} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/copy.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_6} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/player
                                                (1) 1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_7} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/doc.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_8} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/enter
                                                (1) 1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_9} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/enter
                                                (1) 1 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_10} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                108.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_11} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                108 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_12} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                189.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_13} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                196.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_14} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                196 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_15} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                222.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_16} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                222 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_17} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                263.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_18} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                264.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_19} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                10816.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_20} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                10825.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_21} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                10950.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_22} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_24} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                4782.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_25} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10400.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_26} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10409.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_27} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10431.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_28} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10436.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_29} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10752.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_30} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10752_hover.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_31} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10873.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_32} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                10939.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_33} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                11002.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_34} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                11800.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_35} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                11909.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_36} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/home
                                                (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_37} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/information
                                                (1) 1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_38} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/magnifiying-glass
                                                1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_39} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/reload.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_40} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/reload
                                                (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_41} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/right-arrow
                                                (1) 1.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_42} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Union.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_43} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Union
                                                (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_44} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/user
                                                (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_45} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/vector.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_46} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                71.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_48} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                76 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_49} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                76 (2).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_50} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                76 (3).svg
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-6"
                                        style={{ backgroundColor: '#2d8ff07a' }}
                                    >
                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_23} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Group
                                                4752.svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_47} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                71 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_51} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Vector
                                                76 (1).svg
                                            </div>
                                        </div>

                                        <div className="row mb-1">
                                            <div className="col-md-2">
                                                <img src={i_52} alt="" />
                                            </div>
                                            <div className="col-md-10">
                                                ../../../assets/images/common/Frame
                                                179.svg
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(IconsAppPage);
