import React, {memo, useEffect, useMemo, useState} from "react";
import clsx from "clsx";
import TooltipMUI from "../../../../UI/tooltip/TooltipMUI";
import ACommonDataField from "../../../../components/GenericComponents/CommonDataField";
import {Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import CashierSupplierButtons from "../CashierSaveCancel/CashierSupplierButtons";
import {currencySupplierFields, supplierFields} from "./Fields/fields";
import {createCashierSupplier, updateCashierSupplier} from "../../../../store/cashier/actions";
import {useDispatch, useSelector} from "react-redux";
import CustomModal from "../../../../common/components/CustomModal";
import {ONLY_DIGITS_AND_COMMA_REG_EXP, ONLY_DIGITS_REG_EXP} from "../../../../constants/validations";


const regex = /(\d+(\.\d{1,2})?)\s*([A-Z]{3})/g;

const deepEqual = (a, b) => {
    if (a === b) return true;

    if (typeof a !== 'object' || typeof b !== 'object' || a == null || b == null) {
        return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (const key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
            return false;
        }
    }

    return true;
};

const initialSupplierObg = {
    type: '',
    advanceSegment: {},
    integrationSettings: {},
    integration: '',
    cashierMethodId: '',
    supplierStatus: false,
    supplierName: '',
    currency: ['EUR'],
    priority: 1,
    ftd: false,
    chargeback: false,
    kyc: false,
    vipLevel: 1,
    currencyAmounts: [
        {
            value: 'EUR',
            min: '',
            max: '',
            defAmountCashier: '',
            suggestAmounts: {},
        }
    ],
}

const updateArrayWithValue = (array, value, currency) => (
    value ? [...array, `${value}${currency}`] : array
)

const currencyFieldsMapping = {
    min: "min",
    max: "max",
    defAmountCashier: "defAmountCashier",
    suggestAmounts: "suggestAmounts"
}
const currencyFields = [
    currencyFieldsMapping.min,
    currencyFieldsMapping.max,
    currencyFieldsMapping.defAmountCashier,
    currencyFieldsMapping.suggestAmounts
]

const PaymentSuppliersSettings = (props) => {
    const {
        methodId,
        currSupplier,
        deleteSupplier,
        canEditCashier,
    } = props;

    const {
        siteSupplierList,
    } = useSelector(state => state.Cashier);
    const url = new URL(window.location.href);
    const method = url.searchParams.get('method');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [minimize, setMinimize] = useState(false);
    const supplierFieldsCopy = [...supplierFields];
    const [isShowConfModal, setIsShowConfModal] = useState(false);

    const [supplierValues, setSuppliersValues] = useState(initialSupplierObg);
    const [currSupplierInitial, setCurrSupplierInitial] = useState(initialSupplierObg);

    const normalizeCurrencyStringToObj = (str) => {
        const matches = [...str.matchAll(regex)];
        return matches.reduce((acc, match) => {
            acc[match[3]] = match[1];
            return acc;
        }, {});
    }

    useEffect(() => {
        if (currSupplier) {
            const updatedValues = {};
            const valuesByCurrency = {
                [currencyFieldsMapping.min]: null,
                [currencyFieldsMapping.max]: null,
                [currencyFieldsMapping.defAmountCashier]: null,
                [currencyFieldsMapping.suggestAmounts]: null
            }
            if (currSupplier && typeof currSupplier === 'object') {
                if (currSupplier.currency === undefined) {
                    currSupplier.currency = [];
                }
                supplierFieldsCopy.forEach((field) => {
                    if (currencyFields.includes(field.stateKey)) {
                        return;
                    }
                    if (field.stateKey === 'currency') {
                        currencyFields.forEach(currencyField => {
                            valuesByCurrency[currencyField] = normalizeCurrencyStringToObj(currSupplier[currencyField] || '');
                        });

                        updatedValues['currencyAmounts'] = currSupplier[field.stateKey].map(c => ({
                            value: c,
                            min: valuesByCurrency.min[c] || '',
                            max: valuesByCurrency.max[c] || '',
                            defAmountCashier: valuesByCurrency.defAmountCashier[c] || '',
                            suggestAmounts: valuesByCurrency.suggestAmounts[c] || ''
                        }));
                    }

                    updatedValues[field.stateKey] = currSupplier[field.stateKey];
                });
            }

            if (currSupplier?.advanceSegment?.suggestAmounts) {
                updatedValues['currencyAmounts'] = updatedValues['currencyAmounts'].map(currencyAmount => ({
                    ...currencyAmount,
                    suggestAmounts: currSupplier.advanceSegment.suggestAmounts[currencyAmount.value] || {}
                }));
            }

            setSuppliersValues(updatedValues);
            setCurrSupplierInitial(updatedValues);
        }
    }, [currSupplier]);

    const isEdit = useMemo(() => {
        if (!currSupplier) {
            return false;
        }
        const keys = Object.keys(supplierValues);

        return keys.some((key) => {
            if (supplierValues.hasOwnProperty(key) && currSupplierInitial.hasOwnProperty(key)) {
                return !deepEqual(supplierValues[key], currSupplierInitial[key]);
            }
            return false;
        });
    }, [supplierValues, currSupplier]);


    const updateCurrencyAmounts = (prev, value) => {
        const updatedCurrencyAmounts = prev.currencyAmounts.map((amount) => ({ ...amount }));

        value.forEach((currency) => {
            const existingCurrencyIndex = updatedCurrencyAmounts.findIndex((amount) => amount.value === currency);

            if (existingCurrencyIndex === -1) {
                updatedCurrencyAmounts.push({
                    value: currency,
                    min: '',
                    max: '',
                    defAmountCashier: '',
                    suggestAmounts: '',
                });
            }
        });
        return updatedCurrencyAmounts.filter((amount) => value.includes(amount.value));
    };

    const onChange = (stateKey, value, index = null) => {
        setSuppliersValues((prev) => {
            let updatedCurrencyAmounts;
            switch (stateKey) {
                case 'currency':
                    updatedCurrencyAmounts = updateCurrencyAmounts(prev, value);
                    return {
                        ...prev,
                        currencyAmounts: updatedCurrencyAmounts,
                        [stateKey]: value
                    };
                case currencyFieldsMapping.suggestAmounts:
                    updatedCurrencyAmounts = [...prev.currencyAmounts];
                    updatedCurrencyAmounts[index] = {
                        ...updatedCurrencyAmounts[index],
                        suggestAmounts: value.replace(ONLY_DIGITS_AND_COMMA_REG_EXP, '')
                    };
                    return {
                        ...prev,
                        currencyAmounts: updatedCurrencyAmounts
                    };
                case currencyFieldsMapping.min:
                case currencyFieldsMapping.max:
                case currencyFieldsMapping.defAmountCashier:
                    updatedCurrencyAmounts = [...prev.currencyAmounts];
                    updatedCurrencyAmounts[index] = {
                        ...updatedCurrencyAmounts[index],
                        [stateKey]: value.replace(ONLY_DIGITS_REG_EXP, '')
                    };
                    return {
                        ...prev,
                        currencyAmounts: updatedCurrencyAmounts
                    };
                default:
                    if (index !== null) {
                        updatedCurrencyAmounts = [...prev.currencyAmounts];
                        updatedCurrencyAmounts[index] = {
                            ...updatedCurrencyAmounts[index],
                            [stateKey]: value
                        };
                        return {
                            ...prev,
                            currencyAmounts: updatedCurrencyAmounts
                        };
                    } else {
                        return {
                            ...prev,
                            [stateKey]: value
                        };
                    }
            }
        });
    };


    const renderMethodFields = (suppliersField , outerIndex) => {
        return (
            <div className='sub-content' key={outerIndex}>
                <div
                    className={minimize ? clsx("sub-content__content row-collapse") : clsx("sub-content__content row")}>
                    <React.Fragment key={`${outerIndex}`}>
                        <div className="method-settings" key={suppliersField.stateKey}>
                            <div className='d-flex justify-content-end align-items-center'>
                                {suppliersField.info && (
                                    <TooltipMUI
                                        title={suppliersField.info}
                                    />
                                )}
                                <label className='cashier-label'>
                                    {suppliersField.label}
                                </label>
                            </div>
                            <ACommonDataField
                                fulWidth={true}
                                id={suppliersField.stateKey}
                                options={suppliersField.options || []}
                                component={suppliersField.component}
                                helperText={suppliersField.hint}
                                style={suppliersField.style}
                                value={supplierValues[suppliersField.stateKey]}
                                handleChange={(e) => onChange(suppliersField.stateKey, e)}
                                className={suppliersField.className || ''}
                                disabled={suppliersField?.disabled || !canEditCashier}
                            />
                        </div>
                    </React.Fragment>
                </div>
            </div>
        )
    };

    const methodRenderedFields = useMemo(() => {
        const limit = minimize ? 2 : undefined;

        return supplierFieldsCopy.reduce((acc, field, index) => {
            const suppliersFields = limit ? field.slice(0, limit) : field;
            if (suppliersFields.stateKey === 'integration') {

                let _siteSupplierList = siteSupplierList;
                if (method === 'withdraw') {
                    _siteSupplierList = _siteSupplierList.filter((supplier) => supplier.supportWithdrawal);
                }

                suppliersFields.options = _siteSupplierList.map((supplier) => ({
                    label: supplier.name,
                    content: supplier.name,
                    value: supplier.id,
                } || []));
            }
            return [...acc, renderMethodFields(suppliersFields, index)];
        }, []);
    }, [minimize, supplierValues, method, siteSupplierList]);

    const prepareDto = (array) => {
        return array.reduce((acc, curr) => {
            acc.min = updateArrayWithValue(acc.min, curr.min, curr.value);
            acc.max = updateArrayWithValue(acc.max, curr.max, curr.value);
            acc.defAmountCashier = updateArrayWithValue(acc.defAmountCashier, curr.defAmountCashier, curr.value);

            acc.suggestAmounts[curr.value] = curr.suggestAmounts || [];

            return acc;
        }, {
            min: [],
            max: [],
            defAmountCashier: [],
            suggestAmounts: {}
        });
    }


    const updateSupplier = () => {
        if (currSupplier?.id) {
            const updatedSupplierValues = { ...supplierValues };
            updatedSupplierValues.id = currSupplier.id;
            updatedSupplierValues.cashierMethodId = methodId;

            const reducedAmounts = prepareDto(updatedSupplierValues.currencyAmounts);
            updatedSupplierValues.min = reducedAmounts.min.join(',');
            updatedSupplierValues.max = reducedAmounts.max.join(',');
            updatedSupplierValues.defAmountCashier = reducedAmounts.defAmountCashier.join(',');
            updatedSupplierValues.advanceSegment = {
                suggestAmounts: reducedAmounts.suggestAmounts
            };

            delete updatedSupplierValues.currencyAmounts;

            dispatch(updateCashierSupplier(updatedSupplierValues));
        }
    };

    const createSupplier = () => {
        const { id, ...methodBody } = supplierValues;
        methodBody.cashierMethodId = methodId;

        const reducedAmounts = prepareDto(methodBody.currencyAmounts);
        methodBody.min = reducedAmounts.min.join(',');
        methodBody.max = reducedAmounts.max.join(',');
        methodBody.defAmountCashier = reducedAmounts.defAmountCashier.join(',');
        methodBody.advanceSegment = {
            suggestAmounts: reducedAmounts.suggestAmounts
        };

        delete methodBody.currencyAmounts;

        dispatch(createCashierSupplier(methodBody));
    };

    const _deleteSupplier = () => {
        setIsShowConfModal(true);
    }


    return (
        <>
            <Row>
                <Col lg={'auto'}>
                    <div className="main-card__content">
                        {isEdit &&
                            <CashierSupplierButtons
                                onSave={currSupplier?.id
                                    ? () => updateSupplier()
                                    : () => createSupplier()
                                }
                                onCancel={() => setSuppliersValues(currSupplierInitial)}
                                className='z-index-30'
                            />
                        }
                        <div
                            style={{cursor: 'pointer', paddingRight: '10px'}}
                            className={clsx('d-flex justify-content-center align-items-center remove-supplier', {
                                'disabledBtn': !canEditCashier
                            })}
                            onClick={_deleteSupplier}
                        >
                            <span className='mt-1'>{t('crm.deleteSupplier')}</span>
                            <button className="btn deleteRuleBtn ml-5"/>
                        </div>
                        <div className='rendered-suppliers-fields'>
                            {methodRenderedFields}
                        </div>
                        {supplierValues.currencyAmounts.map((fieldValue, index) => (
                            <div className='currencyAmounts' key={index}>
                                <p className='font-w-500'>Currency: {fieldValue.value}</p>
                                <div className='rendered-suppliers-fields'>
                                    {currencySupplierFields.map((field, idx) => (
                                        <ACommonDataField
                                            key={idx}
                                            fullWidth={true}
                                            label={field.label}
                                            id={`${field.stateKey}_${index}`}
                                            component={field.component}
                                            helperText={field.hint}
                                            style={field.style}
                                            value={fieldValue[field.stateKey]}
                                            handleChange={(value) => onChange(field.stateKey, value, index)}
                                            className={field.className || ''}
                                            disabled={!canEditCashier}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                </Col>
            </Row>
            {isShowConfModal &&
                <CustomModal
                    titleText={'attention'}
                    isOpen={isShowConfModal}
                    onToggle={() => setIsShowConfModal(!isShowConfModal)}
                    onClick={() => deleteSupplier(currSupplier?.id)}
                    onClickCancelButton={() => setIsShowConfModal(false)}
                    btnText={'yes'}
                    cancelBtnText={'no'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {t('promotions.applyChangesModal')}
                            </div>
                        </>
                    )}
                />
            }
        </>
    )
}

export default memo(PaymentSuppliersSettings);