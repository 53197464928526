import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import i18n from '../../../i18n';

import { CommonFilter } from './CommonFilterPopup';
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const DIGIT_REGEX = /[^0-9]+/g;
const UUID_REGEX = /[^0-9a-fA-F\-]+/g;
const UUID_SPACE_REGEX = /[^0-9a-fA-F\- ]+|[^0-9a-fA-F\-]{2,}/g;

const calculateWordCount = (value) => value.trim()
    ? value.split(UUID_REGEX).filter((val) => !!val).length
    : 0;

export const TextArrayPopup = ({ popupKey, title, onSave, onCancel, isDigit, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const [wordCount, setWordCount] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const { t } = useTranslation();

    const placeHolder = popupKey === 'id'
        ? t("filter.IdPlaceholder")
        : '';

    const formatIds = (e) => {
        e.preventDefault();
        const newValue = value.replace(UUID_SPACE_REGEX, ' ');
        const wordCount = calculateWordCount(newValue);
        setWordCount(wordCount);
        setValue(newValue);
    }

    const handleSave = useCallback(
        () => {
            const valueArr = value.trim()
                .split(isDigit ? DIGIT_REGEX : UUID_REGEX)
                .filter((val) => !!val);
            const uniqueValueArr = [...new Set(valueArr)];

            if (uniqueValueArr.length === 0) {
                alert(`You don't have saved data`);
            } else {
                const filterData = uniqueValueArr.map((value) => ({key: popupKey, value}));
                onSave(filterData);
            }
        },
        [onSave, value, popupKey]
    );

    const handleValueChange = (val) => {
        const newValue = val.target.value;
        const wordCount = calculateWordCount(newValue);
        setWordCount(wordCount);
        setValue(newValue)
    };

    useEffect(() => {
        if (wordCount <= 1) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [wordCount])

    return (
        <CommonFilter
            title={title}
            className="filter-text-array"
            onCancel={onCancel}
        >
            <textarea
                value={value}
                placeholder={placeHolder}
                onChange={handleValueChange}
            />

            <br/>
            <span className='filter-by-id-results'>
                {`Results: ${wordCount} ID${wordCount === 0 ? '' : 's'}`}
            </span>

            <br/>

            <div className='d-flex justify-content-between align-items-center'>
                <span onClick={(e) => formatIds(e)} className={clsx("textarea-format-btn", {
                    'disabled': isDisabled,
                })}
                >
                    <a href="">{i18n.t('filter.format')}</a>
                </span>
                <Button
                    className="textarea-apply-btn"
                    color="primary"
                    disabled={!value.trim()}
                    onClick={handleSave}>
                    {i18n.t('filter.apply')}
                </Button>
            </div>
        </CommonFilter>
    );
};

TextArrayPopup.proptypes = {
    popupKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isDigit: PropTypes.bool,
};

TextArrayPopup.defaultProps = {
    isDigit: false,
    initialValue: '',
};
