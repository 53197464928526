import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from "react-redux";
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import close from "../../../assets/images/common/close 1.svg";
import { isEqual } from "lodash";
import Formatter from "../../../utils/Formatter";
import clsx from "clsx";
import { renderGameImg } from "../../../common/constants/common";
import stubIMG from '../../../assets/images/common/stubIMG.png';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const TABS = {
    INFORMATION: 'INFORMATION',
    IMAGES: 'IMAGES',
};

function GameCategoryUpdateModal(props) {
    const {
        currentGame,
        onHide,
        onConfirm,
        onUpdateGame,
        gameName,
    } = props;
    useHandleEsc(onHide);
    const [state, setState] = useState({
        gameName: currentGame.gameName || currentGame.friendlyName || '',
        gameType: currentGame.gameType || '',
        oid: currentGame.oid || '',
        priority: currentGame.priority || '',
        emphasize: currentGame.emphasize || false,
        vendorName: currentGame.vendorName || '',
        externalDesktopId: currentGame.externalDesktopId || '',
        externalMobileId: currentGame.externalMobileId || '',
        createdAt: currentGame.createdAt,
        rtp: currentGame.rtp || 0,
        lines: currentGame.lines || 0,
    });
    const {active: activeBrand} = useSelector(state => state.Brand);
    const currBrand = activeBrand?.siteName;
    const [initialState, setInitialState] = useState(state);
    const [isEdit, setIsEdit] = useState(false);
    const [activeTab, setActiveTab] = useState(TABS.INFORMATION);

    const handleChangeField = (field) => (value) => {
        if (field === 'priority' && value === '') {
            value = 0;
        }
        const newState = {
            ...state,
            [field]: value,
        };
        setState(newState);
    }

    useEffect(() => {
        setIsEdit(!isEqual(initialState, state))
    }, [initialState, state]);

    const handleSave = useCallback(() => {
        const changedFields = {};

        ['priority', 'emphasize']
            .forEach((key) => changedFields[key] = state[key]);

        if (currentGame.gameName) {
            if (changedFields.priority < 1) {
                changedFields.priority = 1000;
            }
            onConfirm(changedFields);
        }
        else {
            const {gameName: friendlyName, ...restFields} = changedFields;
            onUpdateGame({
                ...restFields,
                friendlyName,
            });
        }
    }, [state]);


    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="game-update-modal two-columns-popup"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.gameCategoryUpdate')}
                </h5>
                <button className="close" onClick={onHide}/>
            </div>

            <ModalBody style={{minHeight: "500px"}}>
                <div className="tabs-game-category-modal">
                    <div
                        className={clsx('tab mr-10', {'activeTab': activeTab === TABS.INFORMATION})}
                        onClick={() => setActiveTab(TABS.INFORMATION)}
                    >
                        <span>{i18n.t('content.tab.information')}</span>
                    </div>
                    <div
                        className={clsx('tab', {'activeTab': activeTab === TABS.IMAGES})}
                        onClick={() => setActiveTab(TABS.IMAGES)}
                    >
                        <span>{i18n.t('content.tab.images')}</span>
                    </div>
                </div>

                {activeTab === TABS.INFORMATION && (
                    <table style={{margin: '0 auto'}}>
                        <tr>
                            <td style={{width: 250, paddingBottom: '20px'}}>
                                <ACommonDataField
                                    label={i18n.t('content.largeThumbnail')}
                                    component={A_COMMON_DATA_FIELD.TOGGLE}
                                    value={state.emphasize}
                                    handleChange={handleChangeField('emphasize')}
                                />
                            </td>
                            <td style={{width: 250, paddingBottom: '20px', opacity: 0.7}}>
                                <ACommonDataField
                                    label={i18n.t('content.animatedThumbnail')}
                                    component={A_COMMON_DATA_FIELD.TOGGLE}
                                    value={false}
                                    handleChange={handleChangeField('animatedThumbnail')}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: 250}}>
                                <ACommonDataField
                                    label={i18n.t('content.friendlyName')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state.gameName}
                                    handleChange={handleChangeField('gameName')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.priority')}
                                    component={A_COMMON_DATA_FIELD.NUMBER}
                                    value={state.priority || ''}
                                    handleChange={handleChangeField('priority')}
                                    fulWidth={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.oid')}
                                    component={A_COMMON_DATA_FIELD.NUMBER}
                                    value={state.oid}
                                    handleChange={handleChangeField('oid')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.gameType')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state.gameType}
                                    handleChange={handleChangeField('gameType')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.lines')}
                                    component={A_COMMON_DATA_FIELD.NUMBER}
                                    value={state.lines}
                                    handleChange={handleChangeField('lines')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.vendorName')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state?.vendorName}
                                    handleChange={handleChangeField('vendorName')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.createdAt')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={Formatter.displayDateTimeMinutes(state?.createdAt)}
                                    handleChange={handleChangeField('createdAt')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.rtp')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state.rtp}
                                    handleChange={handleChangeField('rtp')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.externalMobileId')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state.externalMobileId}
                                    handleChange={handleChangeField('externalMobileId')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.externalDesktopId')}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    value={state.externalDesktopId}
                                    handleChange={handleChangeField('externalDesktopId')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ACommonDataField
                                    label={i18n.t('content.bonusFactor')}
                                    component={A_COMMON_DATA_FIELD.NUMBER}
                                    value={state.bonusFactor}
                                    handleChange={handleChangeField('bonusFactor')}
                                    fulWidth={true}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                    </table>
                )}

                {activeTab === TABS.IMAGES && (
                    <div className='cdn-images-wrapper'>
                        {renderGameImg(currBrand, gameName).map(item => (
                            <div key={item.name} className='cdn-game-img'>
                                <span>{item.name}</span>
                                <img
                                    src={item.url} alt=''
                                    onError={(e) =>
                                         e.target.src = stubIMG}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </ModalBody>
            <ModalFooter
                style={{
                    display: 'flex',
                }}
            >
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onHide}
                    style={{
                        width: '30%',
                    }}
                >
                    <img src={close} alt=""/>
                    {i18n.t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={handleSave}
                    disabled={!isEdit}
                    style={{
                        width: '30%',
                        marginLeft: 20,
                    }}
                >
                    <img src={i_51} alt=""/>
                    {i18n.t('crm.ok')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

GameCategoryUpdateModal.propTypes = {
    selectedRows: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    onUpdateGame: PropTypes.func,
    categoryMode: PropTypes.bool,
};

GameCategoryUpdateModal.defaultProps = {
    categoryMode: false,
    onConfirm: () => {},
}

export default React.memo(GameCategoryUpdateModal);
