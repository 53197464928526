import {
    GET_PLAYER_LIST,
    GET_PLAYER_LIST_SUCCESS,
    GET_PLAYER_LIST_FAIL,
    GET_PLAYER_KYC_LIST,
    GET_PLAYER_KYC_LIST_SUCCESS,
    GET_PLAYER_KYC_LIST_FAIL,
    GET_PLAYER_KYC_COUNT,
    GET_PLAYER_KYC_COUNT_SUCCESS,
    GET_PLAYER_KYC_COUNT_FAIL,
    GET_PLAYER_TRACKER,
    GET_PLAYER_TRACKER_SUCCESS,
    GET_PLAYER_TRACKER_FAIL,
    RESET_PLAYER_LIST_ALERTS_ERRORS,
    RESET_PLAYER_QUERY,
    UPDATE_PLAYERS,
    UPDATE_PLAYERS_SUCCESS,
    UPDATE_PLAYERS_FAIL,
    DOWNLOAD_PLAYER_LIST,
    DOWNLOAD_PLAYER_LIST_SUCCESS,
    DOWNLOAD_PLAYER_LIST_FAIL,
    DOWNLOAD_PLAYER_PENDING_LIST,
    DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS,
    DOWNLOAD_PLAYER_PENDING_LIST_FAIL,
    UPDATE_PLAYER_IDENTITY_INFO,
} from './actionTypes';
import i18n from '../../../i18n';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0,
    order: "DESC",
    sort: "created_at",
};

const INIT_STATE = {
    isLoading: false,
    isKYCCountLoading: false,
    error: null,

    playerList: null,
    playerKYCList: null,
    invalidIds: null,
    playerKYCCount: 0,
    playerTrackerList: null,
    playerTrackerTotal: 0,
    total: 0,
    query: {
        ...DEFAULT_QUERY,
    },

    isLoadingMassUpdate: false,
    successAlert: '',

    isDownloading: false,
};

const Players = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                playerList: action.payload.result,
                total: action.payload.total,
                invalidIds: action.payload.invalidIds,
                isLoading: false
            };

        case GET_PLAYER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                playerList: [],
                isLoading: false
            };

        case GET_PLAYER_KYC_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_KYC_LIST_SUCCESS:
            return {
                ...state,
                playerKYCList: action.payload.result,
                total: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_KYC_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                playerKYCList: [],
                isLoading: false
            };

        case GET_PLAYER_KYC_COUNT:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isKYCCountLoading: true,
                error: null
            };

        case GET_PLAYER_KYC_COUNT_SUCCESS:
            return {
                ...state,
                playerKYCCount: action.payload,
                isKYCCountLoading: false
            };

        case GET_PLAYER_KYC_COUNT_FAIL:
            return {
                ...state,
                error: action.payload,
                playerKYCCount: 0,
                isKYCCountLoading: false
            };

        case GET_PLAYER_TRACKER:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_TRACKER_SUCCESS:
            return {
                ...state,
                playerTrackerList: action.payload.result,
                playerTrackerTotal: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_TRACKER_FAIL:
            return {
                ...state,
                error: action.payload,
                playerTrackerList: [],
                playerTrackerTotal: 0,
                isLoading: false
            };

        case UPDATE_PLAYERS:
            return { ...state, isLoadingMassUpdate: true, error: null };

        case UPDATE_PLAYER_IDENTITY_INFO:
            return { ...state, isLoadingMassUpdate: true, error: null };

        case UPDATE_PLAYERS_SUCCESS:
            return {
                ...state,
                successAlert: i18n.t('crm.alerts.playersUpdated'),
                isLoadingMassUpdate: false
            };

        case UPDATE_PLAYERS_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingMassUpdate: false
            };

        case RESET_PLAYER_LIST_ALERTS_ERRORS:
            return { ...state, error: null, successAlert: '' };

        case RESET_PLAYER_QUERY:
            return { ...state, query: { ...DEFAULT_QUERY } };

        case DOWNLOAD_PLAYER_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_PLAYER_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case DOWNLOAD_PLAYER_PENDING_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_PLAYER_PENDING_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Players;
