import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    GOOGLE_AUTH,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    API_ERROR,
    LOGOUT_USER,
    SOCIAL_LOGIN,
    LOGOUT_USER_SUCCESS,
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_LOADING_PERCENT
} from './actionTypes';

export const googleAuth = (user, history) => {
    return {
        type: GOOGLE_AUTH,
        payload: { user, history }
    };
};

export const loginUser = (user, history) => {
    return {
        type: LOGIN_USER,
        payload: { user, history }
    };
};

export const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    };
};

export const refreshToken = () => {
    return {
        type: REFRESH_TOKEN
    };
};

export const refreshTokenSuccess = () => {
    return {
        type: REFRESH_TOKEN_SUCCESS
    };
};

export const logoutUser = history => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    };
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    };
};

export const apiError = error => {
    return {
        type: API_ERROR,
        payload: error
    };
};

export const socialLogin = (data, history, type) => {
    return {
        type: SOCIAL_LOGIN,
        payload: { data, history, type }
    };
};

export const showLoadingScreen = () => ({
    type: SHOW_LOADING_SCREEN
});

export const hideLoadingScreen = () => ({
    type: HIDE_LOADING_SCREEN
});

export const setLoadingPercent = value => ({
    type: SET_LOADING_PERCENT,
    payload: value
});
