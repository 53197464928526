import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_EMAIL,
    GET_PLAYER_GENERAL,
    GET_PLAYER_PHONE,
    UPDATE_PLAYER_GENERAL,
    UPDATE_PLAYER_AFFILIATE_INFO,
    GET_RETAIL_PLAYER,
    UPDATE_RETAIL_PLAYER,
    CREATE_RETAIL_PLAYER,
    UPDATE_RETAIL_KYC_STATUS,
    PAYMENT_RETAIL,
    UPDATE_PLAYER_EMAIL,
    UPDATE_PLAYER_NOTE,
    UPDATE_PLAYER_PHONE,
    UPDATE_PLAYER_STATUS,
} from "./actionTypes"
import {
    getPlayerGeneralSuccess,
    getPlayerGeneralFail,
    getRetailPlayerSuccess,
    getRetailPlayerFail,
    updatePlayerGeneralSuccess,
    updatePlayerGeneralFail,
    updatePlayerAffiliateInfoSuccess,
    updatePlayerAffiliateInfoFail,
    updateRetailPlayerSuccess,
    updateRetailPlayerFail,
    createRetailPlayerSuccess,
    createRetailPlayerFail,
    updateRetailKYCStatusSuccess,
    updateRetailKYCStatusFail,
    paymentRetailSuccess,
    paymentRetailFail,
    updatePlayerStatusSuccess,
    updatePlayerStatusFail,
    getPlayerPhoneSuccess,
    getPlayerPhoneFail,
    getPlayerEmailSuccess,
    getPlayerEmailFail,
    updatePlayerNoteSuccess,
    updatePlayerNoteFail,
    updatePlayerPhoneSuccess,
    updatePlayerPhoneFail, updatePlayerEmailSuccess, updatePlayerEmailFail, updatePlayerGeneral
} from "./actions"
import {
    getPlayerGeneralApi,
    updatePlayerGeneralApi,
    updatePlayerAffiliateInfoApi,
    updatePlayerStatusApi,
    getPlayerEmailApi,
    getPlayerPhoneApi,
    updatePlayerNoteApi,
    updatePlayerPhoneApi,
    updatePlayerEmailApi,
} from "../../../helpers/playerApi";
import {
    getRetailPlayerApi,
    updateRetailPlayerApi,
    createRetailPlayerApi,
    updateRetailKYCStatusApi,
    paymentRetailApi,
} from "../../../helpers/retailApi"
import i18n from "../../../i18n";


function* fetchPlayerGeneralSaga({ payload }) {
    try {
        const response = yield call(getPlayerGeneralApi, payload);

        yield put(getPlayerGeneralSuccess(response.data));
    } catch (error) {
        yield put(getPlayerGeneralFail(error));
    }
}

function* fetchRetailPlayerSaga({ payload }) {
    try {
        const response = yield call(getRetailPlayerApi, payload);

        yield put(getRetailPlayerSuccess(response.data));
    } catch (error) {
        yield put(getRetailPlayerFail(error));
    }
}

function* updatePlayerGeneralSaga({ payload }) {
    try {
        const response = yield call(updatePlayerGeneralApi, payload);

        yield put(
            updatePlayerGeneralSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
        payload.alertService && payload.alertService.showSuccess(i18n.t("crm.alerts.playersUpdated"))
    } catch (error) {
        yield put(updatePlayerGeneralFail(error));
    }
}

function* updatePlayerAffiliateInfoSaga({ payload }) {
    try {
        const response = yield call(updatePlayerAffiliateInfoApi, payload);

        yield put(
            updatePlayerAffiliateInfoSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
        payload.alertService && payload.alertService.showSuccess(i18n.t("crm.alerts.playersUpdated"))
    } catch (error) {
        yield put(updatePlayerAffiliateInfoFail(error));
    }
}

function* updateRetailPlayerSaga({ payload }) {
    try {
        const response = yield call(updateRetailPlayerApi, payload);

        yield put(
            updateRetailPlayerSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updateRetailPlayerFail(error));
    }
}

function* createRetailPlayerSaga({ payload }) {
    try {
        const { agentOwnerId, ..._payload } = payload;
        const response = yield call(createRetailPlayerApi, _payload);

        yield put(createRetailPlayerSuccess(response));

        if (agentOwnerId && response) {
            yield call(updatePlayerGeneralApi, { id: response.oid, dto: { agentOwnerId }})
        }
        payload.alertService && payload.alertService.showSuccess(i18n.t("crm.alerts.playerCreated"))
    } catch (error) {
        yield put(createRetailPlayerFail(error));
    }
}

function* updateRetailKYCStatusSaga({ payload }) {
    try {
        const response = yield call(updateRetailKYCStatusApi, payload);

        yield put(updateRetailKYCStatusSuccess(response));
    } catch (error) {
        yield put(updateRetailKYCStatusFail(error));
    }
}

function* paymentRetailSaga({ payload }) {
    try {
        const response = yield call(paymentRetailApi, payload);

        yield put(paymentRetailSuccess(response));
    } catch (error) {
        yield put(paymentRetailFail(error));
    }
}

function* updatePlayerPhoneSaga({ payload }) {
    try {
        const response = yield call(updatePlayerPhoneApi, payload);

        yield put(
            updatePlayerPhoneSuccess({
                responseData: response.data,
                dto: payload.phone
            })
        );
        payload.alertService.showSuccess(i18n.t('crm.alerts.phoneChanged'));
    } catch (error) {
        yield put(updatePlayerPhoneFail(error));
    }
}

function* updatePlayerEmailSaga({ payload }) {
    try {
        const response = yield call(updatePlayerEmailApi, payload);

        yield put(
            updatePlayerEmailSuccess({
                responseData: response.data,
                email: payload.email
            })
        );
        payload.alertService.showSuccess(i18n.t('crm.alerts.emailChanged'));
    } catch (error) {
        yield put(updatePlayerEmailFail(error));
    }
}

function* updatePlayerStatusSaga({ payload }) {
    try {
        const response = yield call(updatePlayerStatusApi, payload);

        yield put(
            updatePlayerStatusSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updatePlayerStatusFail(error));
    }
}

function* fetchPlayerPhoneSaga({ payload }) {
    try {
        const response = yield call(getPlayerPhoneApi, payload);

        yield put(getPlayerPhoneSuccess(response.data));
    } catch (error) {
        yield put(getPlayerPhoneFail(error));
    }
}

function* fetchPlayerEmailSaga({ payload }) {
    try {
        const response = yield call(getPlayerEmailApi, payload);
        yield put(getPlayerEmailSuccess(response.data));
    } catch (error) {
        yield put(getPlayerEmailFail(error));
    }
}

function* updatePlayerNoteSaga({ payload }) {
    try {
        const response = yield call(updatePlayerNoteApi, payload);

        yield put(
          updatePlayerNoteSuccess({
              responseData: response.data,
              dto: payload.dto
          })
        );
    } catch (error) {
        yield put(updatePlayerNoteFail(error));
    }
}

function* playerGeneralSaga() {
    yield takeEvery(GET_PLAYER_GENERAL, fetchPlayerGeneralSaga);
    yield takeEvery(UPDATE_PLAYER_GENERAL, updatePlayerGeneralSaga);
    yield takeEvery(UPDATE_PLAYER_AFFILIATE_INFO, updatePlayerAffiliateInfoSaga);
    yield takeEvery(GET_RETAIL_PLAYER, fetchRetailPlayerSaga);
    yield takeEvery(UPDATE_RETAIL_PLAYER, updateRetailPlayerSaga);
    yield takeEvery(CREATE_RETAIL_PLAYER, createRetailPlayerSaga);
    yield takeEvery(UPDATE_RETAIL_KYC_STATUS, updateRetailKYCStatusSaga);
    yield takeEvery(PAYMENT_RETAIL, paymentRetailSaga);
    yield takeEvery(UPDATE_PLAYER_PHONE, updatePlayerPhoneSaga);
    yield takeEvery(UPDATE_PLAYER_EMAIL, updatePlayerEmailSaga);
    yield takeEvery(UPDATE_PLAYER_STATUS, updatePlayerStatusSaga);
    yield takeEvery(GET_PLAYER_PHONE, fetchPlayerPhoneSaga);
    yield takeEvery(GET_PLAYER_EMAIL, fetchPlayerEmailSaga);
    yield takeEvery(UPDATE_PLAYER_NOTE, updatePlayerNoteSaga);
}

export default playerGeneralSaga;
