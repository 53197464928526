import React from 'react';
import PropTypes from 'prop-types';

const StatisticsInfoView = ({ title, content, className }) => {
    return (
        <div className={`statistics-info-view ${className ? className : ''}`}>
            <div className="statistics-info-view__title">{title}</div>
            <div className="statistics-info-view__content">{content}</div>
        </div>
    );
};

StatisticsInfoView.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.any,
    className: PropTypes.string
};

export default React.memo(StatisticsInfoView);
