import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

import '../topbar.scss';
import {DATE_TIME_FORMAT} from "../../../../common/utils/common";

const NotificationDropdownItem = ({
    task: { redirect, oid, isRead, subject, playerName, created }
}) => (
    <Link to={redirect} className="notification-item">
        <div className={'notification-item__header'}>
            <p>#{oid}</p>
            {!isRead && <div className={'not-read-mark'} />}
        </div>

        <div className={'notification-item-content'}>
            <p className="notification-item-content__title mb-1">{subject}</p>
            <p className={'mb-1'}>{playerName}</p>
            <p className={'notification-item-content__createdAt mb-1'}>
                {moment(new Date(created)).format(DATE_TIME_FORMAT)}
            </p>
        </div>
    </Link>
);

NotificationDropdownItem.propTypes = {
    task: PropTypes.shape({
        redirect: PropTypes.string,
        oid: PropTypes.string,
        isRead: PropTypes.bool,
        subject: PropTypes.string,
        playerName: PropTypes.string,
        created: PropTypes.string
    })
};

export default React.memo(NotificationDropdownItem);
