import { Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import './CoolOff.scss';
import Loader from "../../../../common/components/Loader";
import i18n from "../../../../i18n";
import group from "../../../../assets/images/common/Tooltip.svg";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import close from "../../../../assets/images/common/close 1.svg";
import i_51 from "../../../../assets/images/common/Frame 176 (1).svg";
import {
    getCoolOffData,
    updateCoolOffData,
} from "../../../../store/brand/brandPage/actions";
import useAlertService from "../../../../hooks/useAlertService";
import { prepareCoolOffDto } from "./helpers/prepareCoolOffDto";
import DurationPicker from "./helpers/DurationPicker";


const CoolOff = (props) => {
    const {
        canEditCoolOffSection,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const {
        coolOffData,
        isLoadingCoolOffData,
        isCoolOffUpdated,
    } = useSelector(state => state.BrandSettingsPage);

    const [initialCoolOffData, setInitialCoolOffData] = useState([]);
    const [currentCoolOffData, setCurrentCoolOffData] = useState([]);
    const [newRule, setNewRule] = useState({
        coolOffId: '',
        timestamp: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRuleIndex, setCurrentRuleIndex] = useState(null);
    const [duration, setDuration] = useState({ days: 0, hours: 0, minutes: 0 });


    useEffect(() => {
        dispatch(getCoolOffData());
    }, [isCoolOffUpdated])

    useEffect(() => {
        if (coolOffData) {
            setInitialCoolOffData(coolOffData);
            setCurrentCoolOffData(coolOffData);
        }
    }, [coolOffData])

    const handleChange = useCallback((index, e, field) => {
        const { value } = e.target;
        setCurrentCoolOffData(prevState => {
            return prevState.map((rule, index_) =>
                index_ === index
                    ? { ...rule, [field]: value }
                    : rule
            );
        });
    }, [currentCoolOffData]);

    const isEdit = useMemo(() => {
        return !isEqual(initialCoolOffData, currentCoolOffData);
    }, [initialCoolOffData, currentCoolOffData])

    const onCancel = () => {
        setCurrentCoolOffData(initialCoolOffData);
    }

    const findDuplicateName = (rules) => {
        const ruleNames = new Set();

        for (const rule of rules) {
            if (ruleNames.has(rule.coolOffId)) {
                return rule.coolOffId;
            }

            ruleNames.add(rule.coolOffId);
        }

        return null;
    }

    const onSave = () => {
        const duplicateName = findDuplicateName(currentCoolOffData);
        const hasEmptyName = currentCoolOffData.some(rule => !rule.coolOffId.trim());

        if (duplicateName || hasEmptyName) {
            if (duplicateName) {
                alertService.showError(t('admin.duplicateCoolOff'));
            }
            if (hasEmptyName) {
                alertService.showError(t('admin.emptyCoolOffName'));
            }
        } else {
            const preparedDto = prepareCoolOffDto(currentCoolOffData);
            dispatch(updateCoolOffData(preparedDto));
        }
    }

    const handleAddRule = () => {
        setCurrentCoolOffData((prevState) => [
            ...prevState,
            { ...newRule }
        ]);
        setNewRule({
            coolOffId: '',
            timestamp: '',
        });
    }

    const handleDeleteRule = (index) => {
        setCurrentCoolOffData((prevState) => {
            const updatedRules = [...prevState];
            updatedRules.splice(index, 1);
            return updatedRules;
        });
    };

    const allRules = [...currentCoolOffData];

    if (newRule.coolOffId) {
        allRules.push(newRule);
    }

    const handleOpenModal = (index) => {
        setCurrentRuleIndex(index);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentRuleIndex(null);
    };

    const handleDurationChange = (duration) => {
        const { days = 0, hours = 0, minutes = 0 } = duration;
        const totalMilliseconds = (days * 24 * 3600 + hours * 3600 + minutes * 60) * 1000;
        setCurrentCoolOffData(prevState => {
            return prevState.map((rule, index) =>
                index === currentRuleIndex
                    ? { ...rule, timestamp: totalMilliseconds }
                    : rule
            );
        });
        handleCloseModal();
    };

    const formatTimestamp = (timestamp) => {
        const totalSeconds = Math.floor(timestamp / 1000);
        const days = Math.floor(totalSeconds / (24 * 3600));
        const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        const pluralize = (count, word) => count === 1 ? word : word + 's';

        const parts = [];
        if (days > 0) parts.push(`${days} ${pluralize(days, 'day')}`);
        if (hours > 0) parts.push(`${hours} ${pluralize(hours, 'hour')}`);
        if (minutes > 0) parts.push(`${minutes} ${pluralize(minutes, 'min')}`);

        return `Time: ${parts.join(', ')}`;
    };

    return (
        <Card className="fraud-restrictions site-main-card">
            <CardBody>
                {!isLoadingCoolOffData ?
                    <Row>
                        <Col
                            xs={12}
                            className="sub-content__header d-flex align-items-center"
                        >
                            <div className='d-flex w-100 align-items-center'>
                                <img className="me-3" src={group} alt="" />
                                <span className="font-w-500 me-3">
                                    {i18n.t('admin.coolOffSettings')}
                                </span>
                            </div>
                        </Col>
                        <Col xs={12} sm={12}>
                            {!allRules?.length &&
                                <span className='no-rules-text'>
                                    {t('admin.noRules')}
                                </span>
                            }
                            {allRules?.map((rule, index) => (
                                <div key={index} className='d-flex cool-off-wrapper'>
                                    <div className='d-flex align-items-cente cool-off-content'>
                                        <span className='cool-off-id'>
                                            {t(`Cool Off #${index + 1}`)}
                                        </span>
                                        <div className='cool-off-block'>
                                            <span>Name of cool off</span>
                                            <input
                                                value={rule.coolOffId || ''}
                                                className={'form-control cool-off-input'}
                                                disabled={!canEditCoolOffSection}
                                                onChange={(e) => handleChange(index, e, 'coolOffId')}
                                            />
                                        </div>
                                        <div className='cool-off-block'>
                                            <span>{formatTimestamp(rule.timestamp)}</span>
                                            <input
                                                value={rule.timestamp || ''}
                                                className={'form-control cool-off-input'}
                                                disabled={true}
                                                onChange={(e) => handleChange(index, e, 'timestamp')}
                                            />
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', marginTop: '15px'}}>
                                        <button
                                            className="btn btn-rounded btn-primary mr-10"
                                            onClick={() => handleOpenModal(index)}
                                            disabled={!canEditCoolOffSection}
                                        >
                                            {t('admin.selectDuration')}
                                        </button>
                                        <div className='delete-coolOff'>
                                            <button
                                                className="btn deleteRuleBtn"
                                                onClick={() => handleDeleteRule(index)}
                                                disabled={!canEditCoolOffSection}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <Col xs={12}>
                            <div className='newRulesWrapper'>
                                <button
                                    className="btn btn-primary btn-rounded add-rule-btn"
                                    onClick={handleAddRule}
                                    disabled={!canEditCoolOffSection}
                                >
                                    {t('admin.addNewRule')}
                                </button>
                            </div>
                        </Col>
                        {isEdit &&
                            <div className='fraud-buttons'>
                                <button
                                    className="btn btn-rounded btn-light me-2"
                                    onClick={onCancel}
                                >
                                    <img className="me-2" src={close} alt="" />
                                    {t('admin.cancel')}
                                </button>

                                <button
                                    className="btn btn-rounded btn-primary"
                                    onClick={onSave}
                                >
                                    <img src={i_51} alt="" />
                                    {t('admin.save')}
                                </button>
                            </div>
                        }
                    </Row>
                    : <Loader />
                }

                {isModalOpen && (
                    <Modal
                        className=""
                        isOpen={isModalOpen}
                        role="dialog"
                        autoFocus={true}
                        data-toggle="modal"
                        centered
                    >
                        <div className="modal-content">
                            <ModalBody className="modal-body">
                                <div>
                                    <DurationPicker onChange={setDuration} />
                                </div>
                                <div
                                    className="add-agent-modal__actions"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <button
                                        className="btn btn-rounded btn-light"
                                        onClick={handleCloseModal}
                                    >
                                        <img src={close} alt="" />
                                        {i18n.t('common.cancel')}
                                    </button>

                                    <button
                                        className="btn btn-rounded btn-primary"
                                        onClick={() => handleDurationChange(duration)}
                                    >
                                        <img src={i_51} alt="" />
                                        {i18n.t('common.save')}
                                    </button>
                                </div>
                            </ModalBody>
                        </div>
                    </Modal>
                )}
            </CardBody>
        </Card>
    )
}

export default React.memo(CoolOff);
