export const SOFTBLOCK_REASONS_OPTIONS = [
    "Cool Off",
    'Duplicate',
    'Gambling Issues',
    'Chargeback',
    'Deposit Threshold',
    'Bonus Abuse',
    'Fraud Prevent',
    'Minor',
    '3rd party',
    'Other'
]

export const FROZEN_REASONS_OPTIONS = [
    'SOF',
    'Duplicate',
    'Temporary A/C',
    'Chargeback',
    'Other',
]

export const CLOSED_REASONS_OPTIONS = [
    'Management Decision',
    'Gambling Issues',
    'Self-Closed',
    'Chargeback',
    'Bonus Abuse',
    'Fraud Prevent',
    'Minor',
    'Duplicate',
    '3rd party',
    'Other',
]

export const SELF_CLOSED_REASONS_OPTIONS = [
    'Gambling Issues',
    'Other'
]