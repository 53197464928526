import {
    EDIT_FILTER_PAGE,
    CHANGE_FILTER_PAGE,
    GET_FILTER_PAGE_ERROR,
    GET_FILTERS_LIST_PAGE,
    REMOVE_FROM_FILTERS_PAGE,
    EDIT_FILTER_PAGE_SUCCESS,
    GET_FILTERS_LIST_PAGE_SUCCESS,
    REMOVE_FROM_FILTERS_PAGE_SUCCESS
} from './actionTypes';

import i18n from '../../../i18n';

const INITIAL_STATE = {
    error: {},
    isLoading: false,
    filtersList: [],
    query: {
        limit: 50,
        offset: 0
    },
    total: 0,
    successMessage: ''
};

const FilterPage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FILTERS_LIST_PAGE:
            return { ...state, isLoading: true, successMessage: '' };
        case GET_FILTERS_LIST_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filtersList: action.payload.result,
                total: action.payload.total
            };
        case REMOVE_FROM_FILTERS_PAGE:
            return { ...state, isLoading: true, successMessage: '' };
        case REMOVE_FROM_FILTERS_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filtersList: state.filtersList.filter(
                    item => item.id !== action.payload
                ),
                successMessage: i18n.t('filter.removeSuccess'),
                total: state.total - 1
            };
        case EDIT_FILTER_PAGE:
            return { ...state, isLoading: true, successMessage: '' };
        // TODO add total
        case EDIT_FILTER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filtersList: action.payload
            };
        case GET_FILTER_PAGE_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};

export default FilterPage;
