import React from 'react';
import i18n from "i18next";
import iconClearInput from "../../assets/images/common/icons8-close.svg";
import Loader from "../../common/components/Loader";
import {useSelector} from "react-redux";

import "./styles.scss";

const FormSearch = ({ onChangeSearchValue, searchValue, showIconSearch, className }) => {
    const { isLoading } = useSelector(state => state.Search);

    const clearSearch = () => {
        const mockEvent = {
            target: {
                value: ''
            }
        }
        onChangeSearchValue(mockEvent)
    }

    return (
        <form onSubmit={e => e.preventDefault()}
              className={`${className} app-search d-lg-block`}
        >
            <div className="position-relative">
                <input
                    type="text"
                    className="form-control"
                    placeholder={i18n.t("common.search")}
                    onChange={onChangeSearchValue}
                    value={searchValue}
                />
                {
                    showIconSearch && (
                        <span className="bx bx-search-alt" />
                    )
                }
                <span onClick={clearSearch} className={"clear-search-wrapper"}>
                    {searchValue &&
                        <img
                            className={"clear-search-bar"}
                            src={iconClearInput}
                            alt='x'
                        />
                    }
                </span>
            </div>
        </form>
    )
};

export default FormSearch;