export const PAYMENT_SUPPLIERS = {
    MANUAL_TRANSFER: 'MANUAL_TRANSFER',
    KUSHKI: 'KUSHKI',
    WINPOT_RETAIL: 'WINPOT_RETAIL',
    PAYMENTIQ: 'PAYMENTIQ',
    CONEKTA: 'CONEKTA',
    XBO: 'XBO',
};
export const SELECT_PAYMENT_SUPPLIERS = [
    PAYMENT_SUPPLIERS.MANUAL_TRANSFER,
    PAYMENT_SUPPLIERS.KUSHKI,
    PAYMENT_SUPPLIERS.WINPOT_RETAIL,
    PAYMENT_SUPPLIERS.PAYMENTIQ,
    PAYMENT_SUPPLIERS.CONEKTA,
    PAYMENT_SUPPLIERS.XBO,
];