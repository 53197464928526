export const GET_PLAYER_GENERAL = 'GET_PLAYER_GENERAL';
export const GET_PLAYER_GENERAL_SUCCESS = 'GET_PLAYER_GENERAL_SUCCESS';
export const GET_PLAYER_GENERAL_FAIL = 'GET_PLAYER_GENERAL_FAIL';

export const SET_EDIT_PLAYER_GENERAL = 'SET_EDIT_PLAYER_GENERAL';

export const UPDATE_PLAYER_GENERAL = 'UPDATE_PLAYER_GENERAL';
export const UPDATE_PLAYER_GENERAL_SUCCESS =
    'UPDATE_PLAYER_GENERAL_SUCCESS';
export const UPDATE_PLAYER_GENERAL_FAIL = 'UPDATE_PLAYER_GENERAL_FAIL';

export const UPDATE_PLAYER_AFFILIATE_INFO = 'UPDATE_PLAYER_AFFILIATE_INFO';
export const UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS =
    'UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS';
export const UPDATE_PLAYER_AFFILIATE_INFO_FAIL = 'UPDATE_PLAYER_AFFILIATE_INFO_FAIL';

export const GET_RETAIL_PLAYER = 'GET_RETAIL_PLAYER';
export const GET_RETAIL_PLAYER_SUCCESS = 'GET_RETAIL_PLAYER_SUCCESS';
export const GET_RETAIL_PLAYER_FAIL = 'GET_RETAIL_PLAYER_FAIL';

export const UPDATE_RETAIL_PLAYER = 'UPDATE_RETAIL_PLAYER';
export const UPDATE_RETAIL_PLAYER_SUCCESS = 'UPDATE_RETAIL_PLAYER_SUCCESS';
export const UPDATE_RETAIL_PLAYER_FAIL = 'UPDATE_RETAIL_PLAYER_FAIL';

export const RESET_UPDATE_ERROR = 'RESET_UPDATE_ERROR';

export const UPDATE_RETAIL_KYC_STATUS = 'UPDATE_RETAIL_KYC_STATUS';
export const UPDATE_RETAIL_KYC_STATUS_SUCCESS = 'UPDATE_RETAIL_KYC_STATUS_SUCCESS';
export const UPDATE_RETAIL_KYC_STATUS_FAIL = 'UPDATE_RETAIL_KYC_STATUS_FAIL';

export const PAYMENT_RETAIL = 'PAYMENT_RETAIL';
export const PAYMENT_RETAIL_SUCCESS = 'PAYMENT_RETAIL_SUCCESS';
export const PAYMENT_RETAIL_FAIL = 'PAYMENT_RETAIL_FAIL';

export const CREATE_RETAIL_PLAYER = 'CREATE_RETAIL_PLAYER';
export const CREATE_RETAIL_PLAYER_SUCCESS = 'CREATE_RETAIL_PLAYER_SUCCESS';
export const CREATE_RETAIL_PLAYER_FAIL = 'CREATE_RETAIL_PLAYER_FAIL';

export const UPDATE_PLAYER_PHONE = 'UPDATE_PLAYER_PHONE';
export const UPDATE_PLAYER_PHONE_SUCCESS =
    'UPDATE_PLAYER_PHONE_SUCCESS';
export const UPDATE_PLAYER_PHONE_FAIL = 'UPDATE_PLAYER_PHONE_FAIL';

export const UPDATE_PLAYER_EMAIL = 'UPDATE_PLAYER_EMAIL';
export const UPDATE_PLAYER_EMAIL_SUCCESS =
    'UPDATE_PLAYER_EMAIL_SUCCESS';
export const UPDATE_PLAYER_EMAIL_FAIL = 'UPDATE_PLAYER_PHONE_FAIL';

export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_PLAYER_STATUS_SUCCESS = 'UPDATE_PLAYER_STATUS_SUCCESS';
export const UPDATE_PLAYER_STATUS_FAIL = 'UPDATE_PLAYER_STATUS_FAIL';

export const RESET_PLAYER_ERRORS = 'RESET_PLAYER_ERRORS';
export const RESET_PLAYER_STATE = 'RESET_PLAYER_STATE';
export const RESET_STATUS_UPDATED = 'RESET_STATUS_UPDATED';

export const GET_PLAYER_PHONE = 'GET_PLAYER_PHONE';
export const GET_PLAYER_PHONE_SUCCESS = 'GET_PLAYER_PHONE_SUCCESS';
export const GET_PLAYER_PHONE_FAIL = 'GET_PLAYER_PHONE_FAIL';

export const UPDATE_PLAYER_NOTE = 'UPDATE_PLAYER_NOTE';
export const UPDATE_PLAYER_NOTE_SUCCESS = 'UPDATE_PLAYER_NOTE_SUCCESS';
export const UPDATE_PLAYER_NOTE_FAIL = 'UPDATE_PLAYER_NOTE_FAIL';

export const GET_PLAYER_EMAIL = 'GET_PLAYER_EMAIL';
export const GET_PLAYER_EMAIL_SUCCESS = 'GET_PLAYER_EMAIL_SUCCESS';
export const GET_PLAYER_EMAIL_FAIL = 'GET_PLAYER_EMAIL_FAIL';

export const CLEAR_PLAYER_GENERAL = 'CLEAR_PLAYER_GENERAL'
export const CLEAR_RETAIL_PLAYER = 'CLEAR_RETAIL_PLAYER'
