import {get, getDocFile, post, postSync, put} from './api_helper';
import moment from 'moment';
import {DATE_TIME_FORMAT_ss} from '../common/utils/common';
import { prepareSearch } from "./SearchUtils";

// Pending Documents
const getPending = async () => {
    const response = await get('/documents/pending');
    return response.data;
};

const getFirstPending = async (query) => {
    const preparedQuery = prepareSearch(query);
    const response = await get(`/documents/pending/first?${preparedQuery}`);
    return response.data;
};

const postApproveDocument = async (documentId, address) => {
    return await postSync(`/documents/pending/${documentId}/approve`, {address});
};

const postRejectDocument = (documentId, data) => {
    const rejectUrl = `/documents/pending/${documentId}/reject`;
    return post(rejectUrl, data);
};

const getPlayerDocuments = playerId => {
    const playerDocumentsUrl = `/documents/player/${playerId}`;
    return get(playerDocumentsUrl);
};

const uploadDocument = ({ oid, documentData, file }) => {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(documentData).forEach(key =>
        formData.append(key, documentData[key])
    );

    return post(`/documents/${oid}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const uploadRetailDocumentApi = ({ oid, documentData, file }) => {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(documentData).forEach(key =>
        formData.append(key, documentData[key])
    );

    return post(`/documents/${oid}/retail`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const getDocument = async documentId => {
    const response = await get(`/documents/${documentId}`);
    const { data: body, headers } = response;

    return {
        body,
        contentType: headers['content-type']
    };
};

const getDocumentData = documentId => {
    const downloadUrl = `/documents/data/${documentId}`;
    return get(downloadUrl);
};

const updateDocumentFields = document => {
    const { oid, status, type, side, expiration, config } = document;
    const documentData = {
        status,
        type,
        side,
        expiration,
        config,
    };

    const updateUrl = `/documents/${oid}`;
    return put(updateUrl, documentData);
};

const addDocumentComments = ({ document, comment, writer, agentId }) => {
    const { oid: id } = document;

    const data = {
        date: new moment().format(DATE_TIME_FORMAT_ss),
        writer,
        agentId,
        seen: true,
        content: comment
    };

    const updateUrl = `/documents/${id}/comments`;
    return post(updateUrl, data);
};

const removeDocumentComments = ({ document, commentData, writer, agentId }) => {
    const { oid: id } = document;

    const data = {
        date: commentData.date,
        writer,
        agentId,
        seen: true,
        content: commentData.content
    };

    const updateUrl = `/documents/${id}/comments/remove`;
    return post(updateUrl, data);
};

const getHistoryDocumentsApi = documentOid => {
    const url = `/documents/history/${documentOid}`;
    return get(url);
};

const downloadDocumentApi = ({ id, rotateDegree, fileName }) => {
    const url = `/documents/${id}`;
    return getDocFile(url, rotateDegree, fileName);
};

const getBrandJurisdictionApi = async () => {
    const response = await get('/site/jurisdiction');
    return response.data;
};

export {
    getPending,
    getFirstPending,
    postApproveDocument,
    postRejectDocument,
    getPlayerDocuments,
    uploadDocument,
    uploadRetailDocumentApi,
    getDocument,
    getDocumentData,
    updateDocumentFields,
    addDocumentComments,
    removeDocumentComments,
    getHistoryDocumentsApi,
    downloadDocumentApi,
    getBrandJurisdictionApi,
};
