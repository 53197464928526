import React from 'react';
import PropTypes from 'prop-types';

function RadioInput({
    checked,
    onChange,
    className,
    additionalClassName,
    children,
    ...props
}) {
    return (
        <div
            className={`form-check ${
                className ? className : 'form-radio-outline form-radio-primary'
            } ${additionalClassName}`}
        >
            <input
                type="radio"
                className="form-check-input"
                checked={checked || false}
                onChange={e => onChange && onChange(e.target.value)}
                {...props}
            />
            <label
                className="form-check-label"
                onClick={() => onChange(props.value)}
            >
                {children}
            </label>
        </div>
    );
}

RadioInput.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    additionalClassName: PropTypes.string
};

export default React.memo(RadioInput);
