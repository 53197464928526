import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../context/languageContext';
import { useSelector } from "react-redux";
import { getSync } from "../../../helpers/api_helper";
import md5 from "md5";
import useAlertService from "../../../hooks/useAlertService";
import { SITE_BRAND } from "../../../constants/siteBrands";
import gamepad from '../../../../src/assets/images/gamepad.svg'
import userSearch from '../../../../src/assets/images/userSearch.svg'
import Tooltip from "@mui/material/Tooltip";
import i18n from "../../../i18n";
import download from "../../../assets/images/common/download.svg";

const LABEL_SALT_KEY = {
    [SITE_BRAND.WINPOTMX]: '1af7746d',
    [SITE_BRAND.GCPLAYING]: '2777f029-9e36-4305-9fca-c076664b2d98',
    [SITE_BRAND.SPINOLOCO]: '2777f029-9e36-4305-9fca-c076664b2d98',
};

function GamificationWidget({ userId, className= '' }) {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const {
        isLoading,
        active: activeBrand,
    } = useSelector(state => state.Brand);
    const { playerGeneral } = useSelector(
        state => state.PlayerGeneral
    );
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const alertService = useAlertService();

    const agentPermissions = agentSelf?.permissions;
    const canOpenGamificationWidget = agentPermissions?.includes('Player__Open_Gamification_Widget');

    useEffect(() => {
        window._smartico_user_id = userId;
        window._smartico_language = language;

        return () => {
          delete window._smartico_user_id;
          delete window._smartico_language;
        }
    }, [userId, language]);

    const fetchSmarticoKeys = async () => {
        if (!(activeBrand?.siteName in LABEL_SALT_KEY)) {
            alertService.showError(`Gamification Widget for ${activeBrand?.siteName} has not been configured`);
            return;
        }

        try {
            const url = '/site/smartico-keys';
            const result = await getSync(url, {});
            const smarticoSettings = result?.data;
            const smarticoLabelKey = smarticoSettings.labelKey;
            const smarticoBrandKey = smarticoSettings.publicKey;

            if (!smarticoLabelKey || !smarticoBrandKey) {
                alertService.showError('The server returns empty data for Smartico keys');
                return;
            }

            await openGamificationWidget(smarticoLabelKey, smarticoBrandKey);
        } catch (error) {
            console.error('Error fetching Smartico keys:', error);
        }
    };

    const openGamificationWidget = async (smarticoLabelKey, smarticoBrandKey) => {
        try {
            const label_salt_key = LABEL_SALT_KEY[activeBrand?.siteName];
            const hashValidityTimestampMs = ~~(+new Date() / 1000) * 1000 + 24 * 3600 * 1000;
            const hashInput = `${playerGeneral?.id}:${label_salt_key}:${hashValidityTimestampMs}`;
            const user_hash = md5(hashInput).toLowerCase() + `:${hashValidityTimestampMs}`;
            const widgetUrl = `https://libs.smartico.ai/wrapper-bo.html?label_key=${smarticoLabelKey}&brand_key=${smarticoBrandKey}&user_ext_id=${playerGeneral?.id}&user_hash=${user_hash}`;
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.cssText = 'position:absolute;width:90vw;height:90vh;top:5vh;left:5vw;z-index:99999';
            iframe.src = widgetUrl;

            window.addEventListener('message', function (event) {
                if (event.data === 'close-gamification-frame' && iframe) {
                    iframe.remove();
                }
            });
        } catch (error) {
            console.error('Error Gamification Widget:', error);
        }
    };

    const onClick = useCallback(async () => {
        if (!isLoading && canOpenGamificationWidget) {
            await fetchSmarticoKeys();
        }
    }, [isLoading, canOpenGamificationWidget]);

    return (
        <div className={`sub-content ${className}`}>
            <Tooltip
                title={i18n.t('crm.openGameWidget')}
                arrow
            >
                <button
                    className='btn vip-btn'
                    onClick={onClick}
                    disabled={!canOpenGamificationWidget}
                >
                    <img src={gamepad} alt="Gamification Widget"/>
                </button>
            </Tooltip>
        </div>
    );
}

GamificationWidget.propTypes = {
    userId: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default React.memo(GamificationWidget);
