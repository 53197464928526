import {SITE_BRAND} from "../constants/siteBrands";

export const isSpecificBrandDoc = (docType) => (
    isWinpotMXSpecificDoc(docType)
);

export const isWinpotMXSpecificDoc = (docType) => (
    docType === 'SelfExclusion_Agreement' ||
    docType === 'AccountRecovery_Agreement'
);
