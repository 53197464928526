export const GET_AGENT_EMPLOYEE = 'GET_AGENT_EMPLOYEE';
export const GET_AGENT_EMPLOYEE_SUCCESS = 'GET_AGENT_EMPLOYEE_SUCCESS';
export const GET_AGENT_EMPLOYEE_FAIL = 'GET_AGENT_EMPLOYEE_FAIL';

export const RESET_AGENT_EMPLOYEE = 'RESET_AGENT_EMPLOYEE';
export const SET_EDIT_AGENT_EMPLOYEE = 'SET_EDIT_AGENT_EMPLOYEE';

export const UPDATE_AGENT_EMPLOYEE = 'UPDATE_AGENT_EMPLOYEE';
export const UPDATE_AGENT_EMPLOYEE_SUCCESS = 'UPDATE_AGENT_EMPLOYEE_SUCCESS';
export const UPDATE_AGENT_EMPLOYEE_FAIL = 'UPDATE_AGENT_EMPLOYEE_FAIL';

export const RESET_AGENT_EMPLOYEE_ALERTS_ERRORS =
    'RESET_AGENT_EMPLOYEE_ALERTS_ERRORS';
