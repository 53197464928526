import {
    GET_PLAYER_PROMOTIONS_LIST,
    GET_PLAYER_PROMOTIONS_LIST_SUCCESS,
    GET_PLAYER_PROMOTIONS_LIST_FAIL,
    APPLY_PLAYER_PROMOTION,
    APPLY_PLAYER_PROMOTION_SUCCESS,
    APPLY_PLAYER_PROMOTION_FAIL,
    CLOSE_PLAYER_PROMOTION,
    CLOSE_PLAYER_PROMOTION_SUCCESS,
    CLOSE_PLAYER_PROMOTION_FAIL,
    GET_PLAYER_SPORT_PROMOTIONS_LIST,
    GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS,
    GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL,
    APPLY_PLAYER_SPORT_PROMOTION,
    APPLY_PLAYER_SPORT_PROMOTION_SUCCESS,
    APPLY_PLAYER_SPORT_PROMOTION_FAIL,
    CLOSE_PLAYER_SPORT_PROMOTION,
    CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS,
    CLOSE_PLAYER_SPORT_PROMOTION_FAIL,
    RESET_PROMOTION_ALERTS_ERRORS
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 10,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    templatesList: null,
    activePromotionsList: null,
    totalTemplatesList: 0,
    totalActivePromotionsList: 0,
    sportTemplatesList: null,
    activeSportPromotionsList: null,
    totalSportTemplatesList: 0,
    totalActiveSportPromotionsList: 0,
    queryPromotionsList: { ...DEFAULT_QUERY },
    isApplying: false,
    isClosing: false,
};

const PlayerPromotions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_PROMOTIONS_LIST:
            return {
                ...state,
                queryPromotionsList: {
                    ...state.queryPromotionsList,
                    ...action.payload.query
                },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                templatesList: action.payload.templates?.result,
                totalTemplatesList: action.payload.templates?.total,
                activePromotionsList: action.payload.active?.result,
                totalActivePromotionsList: action.payload.active?.total,
                isLoading: false
            };

        case GET_PLAYER_PROMOTIONS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                templatesList: [],
                totalTemplatesList: 0,
                activePromotionsList: [],
                totalActivePromotionsList: 0,
                isLoading: false
            };

        case APPLY_PLAYER_PROMOTION:
            return {
                ...state,
                isApplying: true,
                error: null,
            }

        case APPLY_PLAYER_PROMOTION_SUCCESS:
            return {
                ...state,
                isApplying: false,
                error: null,
            }

        case APPLY_PLAYER_PROMOTION_FAIL:
            return {
                ...state,
                isApplying: false,
                error: action.payload,
            }

        case CLOSE_PLAYER_PROMOTION:
            return {
                ...state,
                isClosing: true,
                error: null,
            }

        case CLOSE_PLAYER_PROMOTION_SUCCESS:
            return {
                ...state,
                isClosing: false,
                error: null,
            }

        case CLOSE_PLAYER_PROMOTION_FAIL:
            return {
                ...state,
                isClosing: false,
                error: action.payload,
            }

        case GET_PLAYER_SPORT_PROMOTIONS_LIST:
            return {
                ...state,
                queryPromotionsList: {
                    ...state.queryPromotionsList,
                    ...action.payload.query
                },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS:
            return {
                ...state,
                sportTemplatesList: action.payload.templates?.result,
                totalSportTemplatesList: action.payload.templates?.total,
                activeSportPromotionsList: action.payload.active?.result,
                totalActiveSportPromotionsList: action.payload.active?.total,
                isLoading: false
            };

        case GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                sportTemplatesList: [],
                totalSportTemplatesList: 0,
                activeSportPromotionsList: [],
                totalActiveSportPromotionsList: 0,
                isLoading: false
            };

        case APPLY_PLAYER_SPORT_PROMOTION:
            return {
                ...state,
                isApplying: true,
                error: null,
            }

        case APPLY_PLAYER_SPORT_PROMOTION_SUCCESS:
            return {
                ...state,
                isApplying: false,
                error: null,
            }

        case APPLY_PLAYER_SPORT_PROMOTION_FAIL:
            return {
                ...state,
                isApplying: false,
                error: action.payload,
            }

        case CLOSE_PLAYER_SPORT_PROMOTION:
            return {
                ...state,
                isClosing: true,
                error: null,
            }

        case CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS:
            return {
                ...state,
                isClosing: false,
                error: null,
            }

        case CLOSE_PLAYER_SPORT_PROMOTION_FAIL:
            return {
                ...state,
                isClosing: false,
                error: action.payload,
            }

        case RESET_PROMOTION_ALERTS_ERRORS:
            return {
                ...state,
                error: null,
            }

        default:
            return state;
    }
};

export default PlayerPromotions;
