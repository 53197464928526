import {BOOLEAN} from "../utils/categorisation";

export const SELECT_CATEGORIES = {
    DEFAULT: 'default',
    GAMES: 'games',
    GENDER: 'gender',
    PROFESSION: 'profession',
    COUNTRIES: 'countries',
    STATES: 'states',
    CITIES: 'cities',
    CURRENCIES: 'currencies',
    ENTER_CONDITION: 'enterCondition',
    PROVIDER: 'provider',
    TIMEZONE: 'timezone',
    PAYMENT_AGENT_TYPE: 'payment-agent-type',
    IDENTIFICATION_TYPE: 'identification-types',
    LIMIT_LEVEL_TYPE_SELECT: 'limit_level_type_select',
    DOCUMENT_TYPES: 'document-types',
    DOCUMENT_STATUSES: 'document-statuses',
    PROMOTION_STATUSES: 'promotion-statuses',
    PLAYER_ACCOUNT_TYPE: 'player-account-type',
    DOCUMENT_SIDES: 'document-sides',
    LANGUAGES: 'LANGUAGES',
    ACCESS_GROUP: 'ACCESS_GROUP',
    VIP_LEVEL: 'VIP_LEVEL',
    AGENT_OWNER: 'AGENT_OWNER',
    EXPERIENCE_LEVEL: 'EXPERIENCE_LEVEL',
    CATEGORISATION: 'CATEGORISATION',
    PRODUCTS: 'PRODUCTS',
    KYC_STATUS: 'KYC_STATUS',
    KYC_REQUIRED: 'KYC_REQUIRED',
    BOOLEAN: 'BOOLEAN',
    SELECT_REQUIRE_FIELDS: 'SELECT_REQUIRE_FIELDS',
    SELECT_PAYMENT_SUPPLIERS: 'SELECT_PAYMENT_SUPPLIERS',
    SELECT_PAYMENT_METHODS: 'SELECT_PAYMENT_METHODS',
    SELECT_CASHIER_BOOLEAN: 'SELECT_CASHIER_BOOLEAN',
    BANK: 'BANK',
    CASHIER_PAYMENT_METHODS: 'CASHIER_PAYMENT_METHODS',
    PROMOTIONS_TEMPLATE: 'PROMOTIONS_TEMPLATE',
};
