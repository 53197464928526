import React, {useEffect, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import GamificationWidget from './GamificationWidget';
import { Col, Row } from "reactstrap";
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import ButtonsPanel from "./ButtonsPanel";
import useAlertService from "../../../hooks/useAlertService";
import { prepareUpdatePlayerGeneralDto } from "./model/playerEditModel";
import { updatePlayerGeneral } from "../../../store/players/general/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { SITE_PERMISSION } from "../../../common/constants/common"
import { SMARTICO_DRIVE_LINKS } from "../../../constants/siteBrands";
import userSearch from "../../../assets/images/userSearch.svg";
import Tooltip from "@mui/material/Tooltip";
import i18n from "../../../i18n";

const VipTabContent = (props) => {
    const { playerId, playerModel, setPlayerModel } = props;
    const { isLoading, playerGeneral: initialState, successMessage } = useSelector(state => state.PlayerGeneral);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const alertService = useAlertService();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const brand = activeBrand?.siteName;
    const agentPermissions = agentSelf?.permissions;
    const canEditAgentOwner = agentPermissions.includes(SITE_PERMISSION.Player__Change_Agent_Owner);

    const fields = [
        {
            component: 'select-vip-level',
            stateKey: 'vipLevel',
            hasClear: false,
            defaultValue: playerModel.vipLevel,
            disabled: true,
        },
        {
            component: 'select-agent-owner',
            stateKey: 'agentOwnerId',
            hasClear: true,
            defaultValue: playerModel.agentOwnerId === null ? '' : playerModel.agentOwnerId,
            disabled: !canEditAgentOwner
        },
    ];

    const isEdit = useMemo(() => {
        return !isEqual(initialState.vipLevel, playerModel.vipLevel) ||
               !isEqual(initialState.agentOwnerId, playerModel.agentOwnerId)
    }, [initialState.vipLevel, playerModel.vipLevel, initialState.agentOwnerId, playerModel.agentOwnerId])

    const handleSave = () => {
        const dto = prepareUpdatePlayerGeneralDto(
            initialState,
            playerModel
        );
        if (dto.agentOwnerId === '') {
            dto.agentOwnerId = null;
        }
        dispatch(updatePlayerGeneral({ id: playerModel.oid, dto }));
    }

    const handleCancel = () => {
        setPlayerModel(initialState);
    }

    const handleChange = (stateKey) => (value) => {
        if (stateKey === 'agentOwnerId' && value === '') {
            value = null;
        }
        setPlayerModel(prevState => ({
            ...prevState,
            [stateKey]: value
        }));
    }

    useEffect(() => {
        if (successMessage) {
            alertService.showSuccess(t(successMessage));
        }
    }, [successMessage]);


    const openSmarticoLink = (link) => {
        window.open(link, '_blank');
    }

    return (
        <div className='detailed-card__content'>
            <div style={{borderBottom: 0}} className='content-block'>
                <Row className={'filter-player_type-row personal-info__row vip-tab-row'}>
                    {fields.map((item, index) => {
                        return (
                            <Col key={index} xs='5'>
                                <ACommonDataField
                                    component={item.component}
                                    label={t(`crm.${item.label || item.stateKey}`)}
                                    value={item.defaultValue}
                                    handleChange={handleChange(item.stateKey)}
                                    hasClear={item.hasClear}
                                    disabled={item.disabled}
                                />
                            </Col>
                        )
                    })}
                    <div className='vip-tab-smartico'>
                        <GamificationWidget
                            userId={playerId}
                        />
                        {SMARTICO_DRIVE_LINKS[brand] && (
                            <Tooltip
                                title={i18n.t('crm.openSmarticoDrive')}
                                arrow
                            >
                                <button
                                    className='btn vip-btn'
                                    onClick={() => openSmarticoLink(
                                        SMARTICO_DRIVE_LINKS[brand].replace('{{PLAYER_ID}}', playerId))
                                    }
                                >
                                    <img src={userSearch} alt="Smartico users"/>
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </Row>

                {isEdit && (
                    <ButtonsPanel
                        onSave={handleSave}
                        onCancel={handleCancel}
                        isDisabled={isLoading}
                    />
                )}
            </div>
        </div>
    )
}

export default React.memo(VipTabContent);
