import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../../../common/inputs/common/complicated-checkbox-input.scss';
import ComplicatedCheckboxSelect from '../../../common/inputs/common/ComplicatedCheckboxSelect';
import i18n from '../../../i18n';

const GAMES = [
    { id: 1, name: 'POPULAR' },
    { id: 2, name: 'HOT' },
    { id: 3, name: 'BEST ' },
    { id: 4, name: 'NEW' },
    { id: 5, name: 'POPULAR' },
    { id: 6, name: 'HOT' },
    { id: 7, name: 'BEST ' },
    { id: 8, name: 'NEW' }
];

function GameCategorySelect({ onChange, value, ...props }) {
    const options = useMemo(() => {
        return GAMES.map(item => ({ value: item.id, label: item.name }));
    }, []);

    return (
        <ComplicatedCheckboxSelect
            value={value}
            onChange={onChange}
            options={options}
            placeholder={i18n.t('attributions.chooseCategory')}
            {...props}
        />
    );
}

GameCategorySelect.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(GameCategorySelect);
