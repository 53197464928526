import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import SimpleBar from 'simplebar-react';

import i18n from '../../../i18n';

import { getSync } from '../../../helpers/api_helper';
import parseShort from '../../../helpers/parseShort';

import bell from '../../../assets/images/common/Bell.svg';
import NotificationDropdownItem from './_elements/NotificationDropdownItem';

import './topbar.scss';

export const generateNotificationRedirectLink = (
    category,
    popoupOid,
    playerOid
) => {
    switch (category) {
        case 'Notes':
            return `/player-list/${playerOid}?popup=notes&popupId=${popoupOid}`;
    }
};

const NotificationDropdown = () => {
    const { isCreateNewNote } = useSelector(state => state.Notes);
    const [menu, setMenu] = useState(false);
    const [data, setData] = useState({ unread: 0, read: 0, data: [] });

    const toggle = () => {
        setMenu(!menu);
    };

    useEffect(async () => {
        const url = `/notification`;
        try {
            const result = await getSync(url, {});
            setData(result.data);
        } catch (e) {
            console.error('Cannot fetch notifications.');
        }
    }, []);

    const unread = data.unread;
    const tasksList = useMemo(
        () =>
            data.data.map(task => ({
                category: task.category,
                created: task.created,
                playerName: task.playerName,
                isRead: task.isRead,
                redirect: generateNotificationRedirectLink(
                    task.category,
                    task.oid,
                    task.playerOid
                ),
                oid: task.oid,
                subject: parseShort(task.subject, 20)
            })),
        [data]
    );

    return (
        <>
            <Dropdown
                isOpen={menu}
                toggle={toggle}
                className="notification-dropdown dropdown d-inline-block"
                tag="li"
            >
                <DropdownToggle
                    className={`btn header-item noti-icon bx p-0 m-0 bell-icon${
                        !!unread ? ' bx-tada' : ''
                    }${isCreateNewNote ? 'bx-add-note-tada' : ''}`}
                    tag="button"
                    id="page-header-notifications-dropdown"
                >
                    <img src={bell} alt="bell" />
                    {!!unread && <div className={'bx-unread'} />}
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-end notification-dropdown-content-wrapper">
                    <h6 className="notification-dropdown-header">
                        {i18n.t('admin.notifications')}
                    </h6>

                    <SimpleBar
                        className={'notification-dropdown-content'}
                        autoHide={false}
                    >
                        {tasksList.map(task => (
                            <NotificationDropdownItem
                                key={task.redirect}
                                task={task}
                            />
                        ))}
                    </SimpleBar>

                    <div className="notification-dropdown-footer">
                        <Link
                            className="notification-dropdown-footer__link"
                            to="/notifications"
                        >
                            {i18n.t('crm.viewMore')}
                        </Link>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default NotificationDropdown;
