import React, {useState, useRef, useEffect} from "react"
import i18n from "../../../i18n"
import cloudComputing from "../../../assets/images/layout/cloud-computing.svg"
import upload from "../../../assets/images/common/Upload.svg";
import add from "../../../assets/images/common/file-add.svg";

import "./drag-file-input.scss"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Tooltip from '@mui/material/Tooltip';


// TODO need additional work at the time of integration with ap
const DragFileInput = (props) => {
	const {
		propsFileName = '',
		accept,
		typeFile,
		onSelect,
		isSelecting,
		onRestoreSelecting,
		disabled,
		tooltipText,
		tooltipPosition,
		withLabel = true,
		imgMode = false,
		addFileMode = false,
	} = props;
	const fileInputRef = useRef();
	const { t } = useTranslation();

	const [dropAreaHighlighted, setDropAreaHighlighted] = useState(false)

	const [file, setFile] = useState(null)
	const [fileName, setFileName] = useState('')
	const [fileData, setFileData] = useState(null)

	const handleLoadFile = (file) => {
		if (!file) {
			return;
		}

		onSelect(file);
	}

	const handleSelectFile = () => {
		const file = fileInputRef.current && fileInputRef.current.files[0];
		handleLoadFile(file);
		fileInputRef.current.value = '';
	}

	const handleDropFile = (event) => {
		event.preventDefault()
		event.stopPropagation()

		let dt = event.dataTransfer
		let files = dt.files

		const file = files[0];
		handleLoadFile(file)
	}

	const handleUploadFile = () => fileInputRef.current.click();

	const handleHighlight = (event) => {
		if (disabled) return;

		event.preventDefault()
		event.stopPropagation()

		setDropAreaHighlighted(true)
	}

	const handleUnhighlight = (event) => {
		event.preventDefault()
		event.stopPropagation()

		setDropAreaHighlighted(false)
	}

	useEffect(() => {
		if (isSelecting) {
			onRestoreSelecting();
			handleUploadFile();
		}
	}, [isSelecting])

	return (
		<div id="drop-area" className={`upload-file-fragment ms-0`}>
			{imgMode ? (
				<div
					className={clsx('drop-img',
						{'highlighted': dropAreaHighlighted},
						{'imgMode': imgMode})}
					onClick={handleUploadFile}
				>
					<Tooltip
						PopperProps={{
							disablePortal: true,
						}}
						arrow
						title={i18n.t(`content.${tooltipText}`)}
					>
						<button
							disabled={disabled}
							className="btn btn-rounded btn-primary app-btn-only-img-sm"
						>
							{
								addFileMode ? (
									<img src={add} alt=""/>
								) : (
									<img src={upload} width={40} alt=""/>
								)
							}
						</button>
					</Tooltip>
				</div>
				) : (
				<div
					className={`drop-img ${dropAreaHighlighted ? "highlighted" : ""}`}
					onDrop={(event) => {
						handleUnhighlight(event);
						handleDropFile(event);
					}}
					onDragEnter={handleHighlight}
					onDragOver={handleHighlight}
					onDragLeave={handleUnhighlight}
					onClick={handleUploadFile}
				>
					<div>
						{fileName === "" && !propsFileName.length ? (
							<span className="drop-text d-flex">
								<img src={cloudComputing} width={16} alt="" />
								<span className="me-auto">.{typeFile}</span>
								<span className="me-auto ms-4">{t("crm.drop.files")}</span>
              				</span>
						) : (
							fileName || propsFileName
						)}
					</div>
				</div>
			)}
			<input
				className="hidden"
				ref={fileInputRef}
				onChange={handleSelectFile}
				type="file"
				accept={accept}
				disabled={disabled}
			/>
			{withLabel && (
				<span className="add-img-file" onClick={handleUploadFile}>
          			{i18n.t("content.chooseFile")}
        		</span>
			)}
		</div>
	)
}

DragFileInput.propTypes = {
	accept: PropTypes.string,
	onSelect: PropTypes.func,
	onRestoreSelecting: PropTypes.func,
	isSelecting: PropTypes.bool,
}

DragFileInput.defaultProps = {
	onSelect: () => {},
	onRestoreSelecting: () => {},
	isSelecting: false,
};

export default DragFileInput
