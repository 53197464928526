import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_GGR_WIDGET,
    GET_NEW_CLIENTS_WIDGET,
    GET_TRANSACTIONS_WIDGET,
    GET_VERIFIED_WIDGET
} from './actionTypes';
import {
    getNewClientsWidgetSuccess,
    getNewClientsWidgetFail,
    getMarginLevelWidgetSuccess,
    getMarginLevelWidgetFail,
    getTransactionsWidgetSuccess,
    getTransactionsWidgetFail,
    getVerifiedWidgetSuccess,
    getVerifiedWidgetFail
} from './actions';
import {
    getDashboardTransactionsApi,
    getDashboardRegistrationApi,
    getDashboardVerifiedApi,
    getDashboardGGRApi
} from '../../helpers/dashboardApi';

function* fetchTransactionsWidgetSaga({ payload }) {
    try {
        const response = yield call(getDashboardTransactionsApi, payload);

        yield put(getTransactionsWidgetSuccess(response.data));
    } catch (error) {
        yield put(getTransactionsWidgetFail(error));
    }
}

function* fetchNewClientsWidgetSaga({ payload }) {
    try {
        const response = yield call(getDashboardRegistrationApi, payload);

        yield put(getNewClientsWidgetSuccess(response.data));
    } catch (error) {
        yield put(getNewClientsWidgetFail(error));
    }
}

function* fetchGGRWidgetSaga({ payload }) {
    try {
        const response = yield call(getDashboardGGRApi, payload);

        yield put(getMarginLevelWidgetSuccess(response.data));
    } catch (error) {
        yield put(getMarginLevelWidgetFail(error));
    }
}

function* fetchVerifiedWidgetSaga({ payload }) {
    try {
        const response = yield call(getDashboardVerifiedApi, payload);

        yield put(getVerifiedWidgetSuccess(response.data));
    } catch (error) {
        yield put(getVerifiedWidgetFail(error));
    }
}

function* overviewSaga() {
    yield takeEvery(GET_NEW_CLIENTS_WIDGET, fetchNewClientsWidgetSaga);
    yield takeEvery(GET_GGR_WIDGET, fetchGGRWidgetSaga);
    yield takeEvery(GET_TRANSACTIONS_WIDGET, fetchTransactionsWidgetSaga);
    yield takeEvery(GET_VERIFIED_WIDGET, fetchVerifiedWidgetSaga);
}

export default overviewSaga;
