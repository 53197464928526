import { useCallback, useEffect } from 'react';

export default function useTableCheckbox(
    valueOptions,
    selectedRows,
    setSelectedRows
) {
    useEffect(() => {
        setSelectedRows([]);
    }, [JSON.stringify(valueOptions)]);

    const handleSelectAll = useCallback(() => {
        if (selectedRows.length === valueOptions.length) {
            return setSelectedRows([]);
        }

        return setSelectedRows([...valueOptions]);
    }, [valueOptions, selectedRows]);

    const handleSelectRow = useCallback(
        value => {
            return () => {
                if (selectedRows.includes(value)) {
                    const filteredRows = selectedRows.filter(
                        item => item !== value
                    );

                    return setSelectedRows([...filteredRows]);
                }

                return setSelectedRows([...selectedRows, value]);
            };
        },
        [valueOptions, selectedRows]
    );

    return {
        handleSelectAll,
        handleSelectRow
    };
}
