import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import i18n from '../../../i18n';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import { PASSWORD_REG_EXP } from "../../../constants/validations";

import close from '../../../assets/images/common/Frame 179.svg';
import eye from '../../../assets/images/common/eye.svg';
import closedEye from '../../../assets/images/common/closedEye.svg';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';

import './styles.scss';
import CopyButton from "../../../common/cellData/CopyButton";
import Loader from "../../../common/components/Loader";
import { passwordValidate } from "../../../utils/validation/schemas";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';
import { InputAdornment } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";

function ChangeAgentPasswordModal({
    onCancel,
    onConfirm,
    onClose,
    mode='change',
    isCreating,
}) {
    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
    });

    const [edited, setEdited] = useState({
        password: false,
        confirmPassword: false,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleChange = (field) => (value) => {
        setState({
            ...state,
            [field]: value,
        })
    };

    const handleBlur = (field) => () => {
        setEdited({
            ...edited,
            [field]: true,
        })
    };

    const handleTogglePassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const visibilityIcon = showPassword ? eye : closedEye
    const visibilityConfirmIcon = showConfirmPassword ? eye : closedEye

    const isPasswordValid = useMemo(
        () => {
            if (!edited.password) {
                return false;
            }
            return passwordValidate(state.password);
        },
        [state.password, edited.password]
    );

    const isConfirmPasswordValid = useMemo(
        () => state.confirmPassword !== '' && state.confirmPassword === state.password,
        [state.password, state.confirmPassword]
    );

    const isFieldsValid = useMemo(() => {
        return isPasswordValid.isValid && isConfirmPasswordValid;
    }, [isPasswordValid, isConfirmPasswordValid]);


    const handlePasswordChange = useCallback(() => {
        const { confirmPassword, ...restFields } = state;
        onConfirm(restFields);
    }, [state]);

    return (
        <Modal
            className="add-agent-modal"
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {i18n.t('agent.change.password')}
                    </h5>
                    <button
                        className="close"
                        onClick={onCancel}
                    />
                </div>

                <ModalBody
                    className="modal-body"
                >
                    {mode === 'change' && <>
                        <div>
                            <ACommonDataField
                                label={i18n.t('agent.password') + ' *'}
                                component={A_COMMON_DATA_FIELD.PASSWORD}
                                value={state.password}
                                handleChange={handleChange('password')}
                                handleBlur={handleBlur('password')}
                                fulWidth={true}
                                type={showPassword ? "text" : "password"}
                                error={edited.password && (!isPasswordValid ||
                                      (!PASSWORD_REG_EXP.test(state.password)))
                                }
                                helperText={i18n.t(isPasswordValid?.message)}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePassword}>
                                                <img src={visibilityIcon} alt='eye-icon'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <ACommonDataField
                            label={i18n.t('agent.confirmPassword') + ' *'}
                            component={A_COMMON_DATA_FIELD.PASSWORD}
                            value={state.confirmPassword}
                            handleChange={handleChange('confirmPassword')}
                            handleBlur={handleBlur('confirmPassword')}
                            fulWidth={true}
                            type={showConfirmPassword ? "text" : "password"}
                            error={edited.confirmPassword && !isConfirmPasswordValid}
                            helperText={!isConfirmPasswordValid && edited.confirmPassword
                                        && i18n.t('errors.invalidConfPass')}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleConfirmPassword}>
                                            <img src={visibilityConfirmIcon} alt='eye-icon'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <div
                            className="add-agent-modal__actions"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                className="btn btn-rounded btn-light"
                                onClick={onCancel}
                            >
                                <img
                                    src={close}
                                    alt=""
                                />
                                {i18n.t('common.cancel')}
                            </button>

                            <button
                                className="btn btn-rounded btn-primary"
                                onClick={handlePasswordChange}
                                disabled={!isFieldsValid}
                            >
                                <img
                                    src={i_51}
                                    alt=""
                                />
                                {i18n.t('common.save')}
                            </button>

                            {isCreating && <Loader size={'sm'}/>}
                        </div>
                    </>}
                    {mode === 'result' && <>
                        <div>
                            {i18n.t('agent.password.successful.changed')}
                            <br/>
                            {i18n.t('agent.password')}:
                            &nbsp;&nbsp;
                            {state.password}
                            &nbsp;&nbsp;
                            <CopyButton
                                className="column-cpy-button"
                                onClick={() =>
                                    navigator.clipboard.writeText(state.password)
                                }
                            />
                        </div>

                        <div className="add-agent-modal__actions">
                            <button
                                className="btn btn-rounded btn-light"
                                onClick={onClose}
                            >
                                <img
                                    src={close}
                                    alt=""
                                />
                                {i18n.t('common.close')}
                            </button>
                        </div>
                    </>}
                </ModalBody>
            </div>
        </Modal>
    );
}

ChangeAgentPasswordModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default React.memo(ChangeAgentPasswordModal);
