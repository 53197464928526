import React, {useState} from 'react';
import { CardHeader } from 'reactstrap';
import {Table} from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import ClipboardButton from "../../../common/cellData/CopyButton";
import "./datatables.scss"
import employee from "../../../assets/images/common/employee.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";

function AgentSlackCard({ agent }) {
    const slackChannels = agent?.slackChannels;
    const size = useWindowSize();

    const [activeRow, setActiveRow] = useState(false);

    const handleToggle = (index) => {
        if (size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW) {
            activeRow === index ? setActiveRow(null) : setActiveRow(index)
        }
    }

    return (
        <CardHeader>
            <div className="card-body__header pt-0 d-flex align-items-center mb-3">
                <img className="me-3" src={employee} alt="" />

                <span className="font-w-500 text-uppercase">
                        Active Slack Channels
                    </span>
            </div>


            {slackChannels && slackChannels.length > 0 &&
                <>
                    <Table className="modern-table">
                        <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Title</th>
                            <th>Channel</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {slackChannels.map((data, index) => (
                            <tr
                                key={index}
                                className={clsx({open: activeRow === index})}
                                onClick={() => handleToggle(index)}
                            >
                                <td>
                                    {
                                        size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && (
                                            <span>Brand :</span>
                                        )
                                    }
                                    {data.siteName}
                                </td>
                                <td className="agent-cell">
                                    <span className="cell__cutting">{data.title}</span>
                                </td>
                                <td className="channel-cell">
                                    {
                                        size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && (
                                            <span>Channel :</span>
                                        )
                                    }
                                   <div className="channel-cell__inner">
                                        <a
                                            href={`slack://channel?team=${data.workspaceId}&id=${data.channel}`}
                                            className="channel-link cell__cutting"
                                        >
                                            {data.channel}
                                        </a>
                                        <ClipboardButton text={data.channel} />
                                   </div>
                                </td>
                                <td className="description-cell">
                                    {
                                        size.width <= SCREEN_SIZES.TABLET_PORTRAIT_MIDDLE_VIEW && (
                                            <span>Description :</span>
                                        )
                                    }
                                    <div className="description-content" dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, '<br/>') }} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
            }
            {(!slackChannels || slackChannels.length === 0) &&
                `No Slack Channels found for this agent`
            }
        </CardHeader>
    );
}

AgentSlackCard.propTypes = {
    agent: PropTypes.object,
};

export default React.memo(AgentSlackCard);
