import { GET_INFO } from './actionTypes';

import { takeEvery, call, put } from '@redux-saga/core/effects';
import { getCountersApi } from '../../helpers/backend_helper';
import { getInfoError, getInfoSuccess } from './actions';

export function* getInfoSaga() {
    try {
        const response = yield call(getCountersApi);

        if (response && response.status === 200) {
            yield put(getInfoSuccess(response));
        } else {
            yield put(getInfoError({ message: 'Cannot get info' }));
        }
    } catch (error) {
        yield put(getInfoError({ message: error }));
    }
}

function* infoSaga() {
    yield takeEvery(GET_INFO, getInfoSaga);
    // yield takeEvery(LOGIN_SUCCESS, getInfoSaga);
}

export default infoSaga;
