import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import './chatPopup.scss';
import ChatBox from '../../../../components/Chat/chat-box';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function ChatPopup({ onClose, playerGeneral }) {
    const dispatch = useDispatch();

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className={`app-status-modal notes-modal`}
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <ModalBody
                style={{
                    height: '90vh',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                }}
            >
                <div className={'notes-content-wrapper'} style={{
                    paddingLeft: '20px'
                }}>
                    <ChatBox playerGeneral={playerGeneral} onClose={onClose} />
                </div>
            </ModalBody>
        </Modal>
    );
}

ChatPopup.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default React.memo(ChatPopup);
