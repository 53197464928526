import React,  { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import user from '../../../assets/images/common/user (1).svg';
import frame_189 from '../../../assets/images/common/Frame 189.svg';
import group_10409 from '../../../assets/images/common/Group 10409.svg';
import doc from '../../../assets/images/common/doc.svg';
import right_arrow from '../../../assets/images/common/right-arrow (1) 1.svg';
import CheckboxInput from '../../../common/inputs/SwitchCheckboxInput';
import useAlertService from '../../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPlayerGeneral,
    getPlayerVerification,
    getPlayerVerificationHistoryList,
    resetPlayerVerificationAlertsErrors,
    resetPlayerVerificationHistoryListErrors,
    setEditPlayerVerification,
    updatePlayerVerification
} from '../../../store/actions';
import { prepareUpdatePlayerVerificationDto, prepareUpdatePlayerVerificationFieldErrors } from './model/playerEditModel';
import Loader from '../../../common/components/Loader';
import Formatter from '../../../utils/Formatter';
import ButtonsPanel from './ButtonsPanel';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { getSync, postSync } from '../../../helpers/api_helper';
import OnfidoReports from './onfidoReports/onfidoReports';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { Button } from "reactstrap";

import './user-general.scss';
import { useTranslation } from "react-i18next";
import clsx from "clsx";

function VerificationHistoryBox({ onLoad }) {
    const { i18n } = useTranslation();

    const {
        historyList,
        totalHistoryList,
        isLoadingHistoryList
    } = useSelector(state => state.PlayerVerification);

    const getTypeColor = type => {
        switch (type) {
            case 'PEP':
                return '#5f5f8b';
            default:
                return '#898996';
        }
    };

    const hasMore = historyList.length < totalHistoryList;

    const observer = useRef();
    const lastElementRef = useCallback(
        node => {
            if (isLoadingHistoryList) {
                return;
            }
            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    onLoad();
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoadingHistoryList, hasMore]
    );

    return (
        <div className="verification-history-box">
            <ul>
                {historyList.map((item, index) => {
                    const dayAgo = Math.floor(
                        (Date.now() - new Date(item.date).getTime()) /
                            (24 * 60 * 60 * 1000)
                    );

                    return (
                        <li
                            className="font-w-500 mb-3"
                            key={
                                item.id +
                                index +
                                item.date +
                                item.from +
                                item.to
                            }
                            ref={
                                historyList.length === index + 1
                                    ? lastElementRef
                                    : null
                            }
                        >
                            <div className="font-w-500">
                                <span className="me-2">
                                    {item.action}
                                    <span className="text-lowercase">
                                        &nbsp;{i18n.t('crm.by')}&nbsp;
                                    </span>
                                    {item.by}
                                </span>
                                {dayAgo > 0 && (
                                    <span className="text-muted">
                                        {dayAgo} {i18n.t('crm.dayAgo')}
                                    </span>
                                )}
                            </div>

                            <div className="mt-1">
                                <span
                                    className="me-2"
                                    style={{ color: getTypeColor(item.type) }}
                                >
                                    {item.type}:
                                </span>

                                {`${item.from}`}
                                <img
                                    className="me-2 ms-2"
                                    src={right_arrow}
                                    alt=""
                                />
                                {`${item.to}`}

                                <span className="text-muted ms-2">
                                    {item.date &&
                                        Formatter.displayDateTime(item.date)}
                                </span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function VerificationTabContent({ playerId, isPEPUser, playerGeneral }) {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const {
        isLoadingVerification,
        errorVerification,
        verification,
        errorHistoryList,
        historyList,
        queryHistoryList,
        successAlert
    } = useSelector(state => state.PlayerVerification);


    const [verificationModel, setVerificationModel] = useState(
        JSON.parse(JSON.stringify(verification))
    );

    const [fundsStatusList, setFundsStatusList] = useState([])
    const [fieldErrors, setFieldErrors] = useState({});
    const [isHover, setIsHover] = useState(false);
    const [showOnfidoReportPopup, setShowOnfidoReportPopup] = useState(false);
    const [kycAutomation, setKycAutomation] = useState({});

    useEffect(() => {
        async function fetchData() {
            const url = '/site/settings';
            try {
                const result = await getSync(url, {});
                setKycAutomation(result?.data?.settings?.crm?.kycAutomation);
            } catch (e) {
                console.error('Cannot fetch last messages!');
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setVerificationModel(JSON.parse(JSON.stringify(verification)))
    }, [verification, i18n.language])

    const showReportPopup = () => {
        setShowOnfidoReportPopup(!showOnfidoReportPopup);
    };

    const submitReport = async () => {
        const url = `/onfido/report/${playerId}`;
        try {
            await postSync(url, {});
            alertService.showSuccess('Report submitted');
        } catch (e) {
            if (e.message) {
                if (e.message.includes('Please refer to our documentation')) {
                    alertService.showError('Please upload documents');
                } else {
                    alertService.showError(e.message);
                }
                return;
            }
            alertService.showError(i18n.t("crm.notUpdatedDocument"));
        }
    };

    useEffect(() => {
        dispatch(getPlayerVerification(playerId));
        handleReLoadHistoryList();
    }, [playerId, successAlert]);

    useEffect(() => {
        if(verificationModel?.sourceOfFundsStatus) {
            setFundsStatusList(Object.keys(verificationModel?.sourceOfFundsStatus));
        }
    }, [verificationModel?.sourceOfFundsStatus]);

    useEffect(() => {
        if (errorVerification && errorVerification.statusCode === 400) {
            setFieldErrors(
                prepareUpdatePlayerVerificationFieldErrors(
                    errorVerification?.data
                )
            );
            alertService.showError(errorVerification?.message);
            handleReLoadHistoryList();
            dispatch(resetPlayerVerificationAlertsErrors());
        } else if (errorVerification) {
            alertService.showError(errorVerification);
            dispatch(resetPlayerVerificationAlertsErrors());
        } else if (errorHistoryList) {
            alertService.showError(errorHistoryList);
            dispatch(resetPlayerVerificationHistoryListErrors());
        }

    }, [errorVerification, errorHistoryList]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetPlayerVerificationAlertsErrors());
            dispatch(getPlayerGeneral(playerId));
            handleReLoadHistoryList();
        }
    }, [successAlert]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setEditPlayerVerification(nextIsEdit));
    };

    const handleChange = useCallback(
        (objectName, fieldName) => {
            return value => {
                setVerificationModel(prevState => ({
                    ...prevState,
                    [objectName]: {
                        ...prevState[objectName],
                        [fieldName]: value
                    }
                }));
                handleSetEdit(true);
            };
        },
        [verificationModel, handleSetEdit]
    );

    const handleSave = useCallback(() => {
        if (
            verificationModel?.kycStatus.isKYC === 'Approved'
            && Object.values(verificationModel.kycStatus).includes(false)
        ) {
            verificationModel.kyc = 'Pending'
            verificationModel.kycStatus.isKYC = 'Pending'
        }

        const dto = prepareUpdatePlayerVerificationDto(
            verification,
            verificationModel
        );
        dispatch(updatePlayerVerification({ id: playerId, dto }));
        // dispatch(getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY));
        handleSetEdit(false);
    }, [verificationModel, verification]);

    const handleCancel = useCallback(() => {
        setVerificationModel(JSON.parse(JSON.stringify(verification)));
        handleSetEdit(false);
    }, [verification, handleSetEdit]);

    const handleReLoadHistoryList = useCallback(() => {
        const newQuery = {
            ...queryHistoryList,
            offset: 0
        };

        dispatch(
            getPlayerVerificationHistoryList({
                id: playerId,
                query: newQuery,
                prevHistoryList: []
            })
        );
    }, [queryHistoryList]);

    const handleLoadHistoryList = useCallback(() => {
        const newQuery = {
            ...queryHistoryList,
            offset: queryHistoryList.offset + queryHistoryList.limit
        };

        dispatch(
            getPlayerVerificationHistoryList({
                id: playerId,
                query: newQuery,
                prevHistoryList: historyList
            })
        );
    }, [queryHistoryList]);

    const registerPlayerOnfido = async () => {
        const url = `/onfido/application/${playerId}`;
        try {
            await postSync(url, {});
            alertService.showSuccess('Player registered');
            dispatch(getPlayerVerification(playerId));
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError('Cannot complete, Contact support');
        }
    };

    const isEdit = useMemo(() => {
        if (!verification || !verificationModel) {
            return false;
        }

        const dto = prepareUpdatePlayerVerificationDto(
            verification,
            verificationModel
        );
        const isEditMode = Object.keys(dto).length > 0;

        if (!isEditMode) {
            handleSetEdit(false);
        }

        return isEditMode;
    }, [verification, verificationModel]);

    if (!verification || !verificationModel) {
        return <Loader size={'lg'}/>;
    }

    const AMLCompliantStatus = [
        'Expired',
        'Declined',
        'Approved',
        'Pending',
        'New',
        'Review',
    ];

    const AMLStatusList = AMLCompliantStatus.map(val => ({
        value: val,
        content: i18n.t(`crm.verified.${val}`)
    }));

    const priorityViewKycStatuses = [];
    Object.keys(verificationModel?.kycStatus)?.forEach(key => {
        if (key !== 'isKYC' && !priorityViewKycStatuses.includes(key)) {
            priorityViewKycStatuses.push(key);
        }
    })

    let sourceOfFundsStatus = {};
    if (Object.keys(verificationModel?.sourceOfFundsStatus).length > 0) {
        for (const key of Object.keys(verificationModel?.sourceOfFundsStatus)) {
            if (key !== 'isKYC') {
                sourceOfFundsStatus[key] = verificationModel?.sourceOfFundsStatus[key];
            }
        }
    }

    const onAutoKYCTrigger = async () => {
        const url = `/players/kyc/trigger?player_oid=${playerId}`;
        try {
            const result = await getSync(url, {});
            alertService.showSuccess('Request sent to server, result will come in few minutes to Notes')
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
    }

    const playerBirthDate = new Date(playerGeneral?.birthDate);

    return (
        <>
            {isLoadingVerification
                ? <Loader size={'lg'}/>
                : <div className="detailed-card__content">
                    <div className="content-block">
                        <div className="sub-content">
                            <div className="sub-content__header d-flex align-items-center">
                                <img className="me-3" src={frame_189} alt="" />
                                <span>{i18n.t('crm.kycVerificationStatus')}</span>
                            </div>
                            {kycAutomation &&
                                <div className={'my-4'}>
                                    <Button disabled={verification.kyc === 'Approved'} color={'primary'} onClick={onAutoKYCTrigger}>
                                        {i18n.t('crm.kycAuto')}
                                    </Button>
                                </div>
                            }

                            <div className='verification__fields'>
                                <ACommonDataField
                                    disabled={false}
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    options={AMLStatusList}
                                    label={i18n.t('crm.isKYC')}
                                    value={verificationModel?.kycStatus.isKYC}
                                    handleChange={handleChange('kycStatus', 'isKYC')}
                                />
                            </div>

                            <div className="sub-content__content d-flex flex-wrap verification__checkboxes">
                                {priorityViewKycStatuses.map(statusName => (
                                    <div
                                        className="d-flex align-items-center mt-3"
                                        style={{
                                            textAlign: 'center',
                                            width: '288px'
                                        }}
                                        key={statusName}
                                    >
                                        <CheckboxInput
                                            checked={
                                                verificationModel.kycStatus[statusName]
                                            }
                                            onChange={handleChange(
                                                'kycStatus',
                                                statusName
                                            )}
                                            additionalClassName="me-3"
                                        >
                                            {i18n.t(`admin.constants.verification.${statusName}`)}
                                        </CheckboxInput>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {fundsStatusList?.length > 0 && (
                            <>
                                <hr />
                                <div className="sub-content">
                                    <div className="sub-content__header d-flex align-items-center">
                                        <img
                                            className="me-3"
                                            src={group_10409}
                                            alt=""
                                        />
                                        <span>
                                        {i18n.t(
                                            'crm.sourceOfFundsVerificationStatus'
                                        )}
                                    </span>
                                    </div>
                                    <div className="sub-content__content d-flex">
                                        {fundsStatusList?.map(key => (
                                            <CheckboxInput
                                                checked={
                                                    verificationModel.sourceOfFundsStatus[key]
                                                }
                                                onChange={handleChange(
                                                    'sourceOfFundsStatus',
                                                    key
                                                )}
                                                additionalClassName="me-3"
                                            >
                                                {i18n.t(`admin.constants.verification.${key}`)}:
                                            </CheckboxInput>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <hr />

                        <div className="sub-content">
                            <div className="sub-content__header d-flex align-items-center">
                                <img className="me-3" src={user} alt="" />
                                <span className={clsx("", {
                                    'is-pep-user': isPEPUser,
                                })}>
                                {i18n.t('crm.politicallyExposedPerson')}
                                </span>
                            </div>
                            <div className="sub-content__content d-flex flex-wrap verification__fields">
                                <div className="content-row">
                                    <ACommonDataField
                                        fieldClassName={'birthdate-field'}
                                        disabled={true}
                                        component={'date'}
                                        label={i18n.t('crm.birthDate')}
                                        value={isPEPUser && playerBirthDate
                                            ? playerBirthDate
                                            : null
                                        }
                                        handleChange={handleChange(
                                            'pepInfo',
                                            'birthDate'
                                        )}
                                    />
                                </div>

                                <div className="content-row">
                                    <ACommonDataField
                                        disabled={true}
                                        component={'select-countries'}
                                        label={i18n.t('crm.country')}
                                        value={isPEPUser && playerGeneral?.citizenship
                                            ? playerGeneral.citizenship
                                            : null
                                        }
                                        handleChange={handleChange(
                                            'pepInfo',
                                            'country'
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vertical-separator" />

                    <div className="content-block">
                        <div className="sub-content">
                            <div className="sub-content__header d-flex align-items-center">
                                <img className="me-3" src={doc} alt="" />
                                <span>{i18n.t('crm.verificationAudit')}</span>
                            </div>
                            <div className="sub-content__content">
                                {historyList ? (
                                    <VerificationHistoryBox
                                        onLoad={handleLoadHistoryList}
                                    />
                                ) : (
                                    <Loader size={'lg'}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }

            {isEdit && (
                <ButtonsPanel
                    isDisabled={isLoadingVerification}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
            {showOnfidoReportPopup && (
                <OnfidoReports
                    playerId={playerId}
                    onClose={showReportPopup}
                />
            )}
        </>
    );
}

VerificationTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(VerificationTabContent);
