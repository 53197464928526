import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row} from "reactstrap";

import listIcon from '../../../assets/images/retail/list.svg';
import "../styles.scss";
import useAlertService from "../../../hooks/useAlertService";
import useWindowSize from "../../../hooks/useWindowSize";
import {Table} from "@mui/material";
import {getSync} from "../../../helpers/api_helper";
import moment from "moment";
import {DATE_FORMAT} from "../../../common/utils/common";
import { SCREEN_SIZES } from "../../../utils/consts";
import {useTranslation} from "react-i18next";
import Loader from "../../../common/components/Loader";

const LastShift = (props) => {
    const { shiftHistory } = props;
    const { t } = useTranslation();
    const size = useWindowSize();
    const areaRef = useRef();


    return (
        <Row>
            {/*{!shiftHistory && <Loader size={'sm'} />}*/}
            {shiftHistory &&
                <Col lg="12">
                    <Card className="retail-search">
                        <div className="title-section">
                            <img
                                src={listIcon}
                                alt="icon list"
                            />
                            {t('crm.lastThreeDaysShifts')}
                        </div>
                        <div ref={areaRef} className="retail-search__form">
                            <div className="retail-search__form-item retail-search__table d-flex">
                                <Table className="table">
                                    <tr className='retail-search__table__header retail-table-columns'>
                                        <td>{t('crm.casinoID')}</td>
                                        <td>{t('crm.closeDate')}</td>
                                        <td>{t('crm.deposits')}</td>
                                        <td>{t('crm.withdrawals')}</td>
                                        <td>{t('crm.closeBalance')}</td>
                                        <td>{t('crm.closeAgent')}</td>
                                    </tr>
                                    {shiftHistory.map((item) => (
                                        <tr className='retail-search__table__body retail-table-data'>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.casinoID')}:
                                                    </span>
                                                )}
                                                {item.casinoId}
                                            </td>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.closeDate')}:
                                                    </span>
                                                )}
                                                {moment(new Date(item.endDate)).format(DATE_FORMAT)}
                                                &nbsp;&nbsp;
                                                {moment(new Date(item.endDate)).format('HH:mm:ss')}
                                            </td>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.deposits')}:
                                                    </span>
                                                )}
                                                <span className='float-start'>{`${item.totalDeposit}`}</span>
                                                <span className='float-end pr-15'>{`MX$`}</span>
                                            </td>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.withdrawals')}:
                                                    </span>
                                                )}
                                                <span className='float-start'>{`${item.totalWithdraw}`}</span>
                                                <span className='float-end pr-15'>{`MX$`}</span>
                                            </td>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.closeBalance')}:
                                                    </span>
                                                )}
                                                <span className='float-start'>{`${item.closeBalance}`}</span>
                                                <span className='float-end pr-15'>{`MX$`}</span>
                                            </td>
                                            <td className='f-weight-500'>
                                                {size.width <= SCREEN_SIZES.TABLET_VIEW && (
                                                    <span className='table__body__title'>
                                                        {t('crm.closeAgent')}:
                                                    </span>
                                                )}
                                                {item.closeAgent || '-'}
                                            </td>
                                        </tr>
                                    ))}
                                </Table>
                            </div>
                        </div>
                    </Card>
                </Col>
            }
        </Row>
    )
};

export default LastShift;
