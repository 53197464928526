import {
    RESET_OVERVIEW_ERRORS,
    GET_NEW_CLIENTS_WIDGET,
    GET_NEW_CLIENTS_WIDGET_SUCCESS,
    GET_NEW_CLIENTS_WIDGET_FAIL,
    GET_GGR_WIDGET,
    GET_GGR_WIDGET_SUCCESS,
    GET_GGR_WIDGET_FAIL,
    GET_TRANSACTIONS_WIDGET,
    GET_TRANSACTIONS_WIDGET_SUCCESS,
    GET_TRANSACTIONS_WIDGET_FAIL,
    GET_VERIFIED_WIDGET,
    GET_VERIFIED_WIDGET_SUCCESS,
    GET_VERIFIED_WIDGET_FAIL
} from './actionTypes';

export const getNewClientsWidget = payload => ({
    type: GET_NEW_CLIENTS_WIDGET,
    payload
});

export const getNewClientsWidgetSuccess = payload => ({
    type: GET_NEW_CLIENTS_WIDGET_SUCCESS,
    payload
});

export const getNewClientsWidgetFail = payload => ({
    type: GET_NEW_CLIENTS_WIDGET_FAIL,
    payload
});

export const getMarginLevelWidget = payload => ({
    type: GET_GGR_WIDGET,
    payload
});

export const getMarginLevelWidgetSuccess = payload => ({
    type: GET_GGR_WIDGET_SUCCESS,
    payload
});

export const getMarginLevelWidgetFail = payload => ({
    type: GET_GGR_WIDGET_FAIL,
    payload
});

export const getTransactionsWidget = payload => ({
    type: GET_TRANSACTIONS_WIDGET,
    payload
});

export const getTransactionsWidgetSuccess = payload => ({
    type: GET_TRANSACTIONS_WIDGET_SUCCESS,
    payload
});

export const getTransactionsWidgetFail = payload => ({
    type: GET_TRANSACTIONS_WIDGET_FAIL,
    payload
});

export const getVerifiedWidget = payload => ({
    type: GET_VERIFIED_WIDGET,
    payload
});

export const getVerifiedWidgetSuccess = payload => ({
    type: GET_VERIFIED_WIDGET_SUCCESS,
    payload
});

export const getVerifiedWidgetFail = payload => ({
    type: GET_VERIFIED_WIDGET_FAIL,
    payload
});

export const resetOverviewErrors = payload => ({
    type: RESET_OVERVIEW_ERRORS,
    payload
});
