import {
  GET_PENDING_WITHDRAWAL_LIST,
  GET_PENDING_WITHDRAWAL_LIST_SUCCESS,
  GET_PENDING_WITHDRAWAL_LIST_FAIL,
  GET_PENDING_WITHDRAWAL_COUNT,
  GET_PENDING_WITHDRAWAL_COUNT_FAIL,
  GET_PENDING_WITHDRAWAL_COUNT_SUCCESS,
  RESET_PENDING_WITHDRAWAL_LIST_ALERTS_ERRORS,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST_SUCCESS,
  DOWNLOAD_PENDING_WITHDRAWAL_LIST_FAIL,
} from './actionTypes';

const DEFAULT_QUERY = {
  limit: 50,
  offset: 0
};

const INIT_STATE = {
  isLoading: false,
  error: null,

  withdrawalList: null,
  total: 0,
  query: DEFAULT_QUERY,

  isDownloading: false,
};

const PendingWithdrawals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PENDING_WITHDRAWAL_COUNT:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case GET_PENDING_WITHDRAWAL_COUNT_SUCCESS:
      return {
        ...state,
        withdrawalsForApproval: action.payload.total,
        isLoading: false
      };

    case GET_PENDING_WITHDRAWAL_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case GET_PENDING_WITHDRAWAL_LIST:
      return {
        ...state,
        query: { ...state.query, ...action.payload },
        isLoading: true,
        error: null
      };

    case GET_PENDING_WITHDRAWAL_LIST_SUCCESS:
      return {
        ...state,
        withdrawalList: action.payload.result,
        total: action.payload.total,
        isLoading: false
      };

    case GET_PENDING_WITHDRAWAL_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        withdrawalList: [],
        isLoading: false
      };

    case RESET_PENDING_WITHDRAWAL_LIST_ALERTS_ERRORS:
      return {
        ...state,
        error: null,
      };

    case DOWNLOAD_PENDING_WITHDRAWAL_LIST:
      return {
        ...state,
        isDownloading: true,
        error: null
      };

    case DOWNLOAD_PENDING_WITHDRAWAL_LIST_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        error: null
      };

    case DOWNLOAD_PENDING_WITHDRAWAL_LIST_FAIL:
      return {
        ...state,
        isDownloading: false,
        error: action.payload,
      };

    default:
      return state;
    }
};

export default PendingWithdrawals;
