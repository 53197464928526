import {
    GET_PLAYER_REFERRALS_LIST,
    GET_PLAYER_REFERRALS_LIST_SUCCESS,
    GET_PLAYER_REFERRALS_LIST_FAIL,
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 10,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,
    referralsList: [],
    totalReferrals: 0,
};

const PlayerReferrals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_REFERRALS_LIST:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case GET_PLAYER_REFERRALS_LIST_SUCCESS:
            return {
                ...state,
                referralsList: action.payload,
                totalReferrals: action?.payload?.length,
                isLoading: false,
            };

        case GET_PLAYER_REFERRALS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                referralsList: [],
                totalReferrals: 0,
                isLoading: false
            };

        default:
            return state;
    }
};

export default PlayerReferrals;
