import {
    GET_DEPOSIT,
    GET_DEPOSIT_SUCCESS,
    GET_DEPOSIT_FAIL,
    RESET_DEPOSIT_ALERTS_ERRORS
} from './actionTypes';

export const getDeposit = payload => ({
    type: GET_DEPOSIT,
    payload
});

export const getDepositSuccess = payload => ({
    type: GET_DEPOSIT_SUCCESS,
    payload
});

export const getDepositFail = error => ({
    type: GET_DEPOSIT_FAIL,
    payload: error
});

export const resetDepositAlertsErrors = error => ({
    type: RESET_DEPOSIT_ALERTS_ERRORS,
    payload: error
});
