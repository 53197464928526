import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import i18n from '../../../i18n';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import Cell from "../../../components/DynamicTable/Cell";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";

import close from '../../../assets/images/common/Frame 179.svg';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';

import './styles.scss';
import Loader from "../../../common/components/Loader";
import clsx from "clsx";
import { EMAIL_REG_EXP, NAME_REG_EXP, SPANISH_AND_LATIN_REG_EXP } from "../../../constants/validations";
import { digitValidate, emailValidate, firstAndLastNameValidate } from "../../../utils/validation/schemas";
import { useSelector } from "react-redux";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const CHECKED_FIELDS_LIST = [
    'firstName',
    'lastName',
    'email',
    'role',
    'brands',
    'status',
    'department',
    'phoneExtension',
];

const DEPARTMENTS = [
    "Operational",
    "Support",
    "Finance",
    "Marketing",
    "Tech",
    "Management",
];

const OTHER = 'Other';

const ALL_DEPARTMENTS = [...DEPARTMENTS, OTHER];

const DEPARTMENTS_OPTIONS = ALL_DEPARTMENTS.map((department) => ({
    label: department,
    content: department,
    value: department,
}));

function EditAgentModal({
    onCancel,
    onUpdate,
    row,
    columns,
    dispatch: dataDispatch,
    setIsEdit,
    updating,
}) {
    const { id, firstName, lastName, email, role, brands, status, department, phoneExtension } = row?.original;
    const [departmentValue, setDepartmentValue] = useState(department);
    const [isBlockedSave, setIsBlockedSave] = useState(false);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];
    const canChangeAgentRole = agentPermissions.includes(SITE_PERMISSION.IT__Change_Agent_Role);
    const [state, setState] = useState({
        id,
        firstName,
        lastName,
        email,
        role,
        status,
        brands,
        department: DEPARTMENTS.includes(department) ? department : OTHER,
        phoneExtension,
    });

    const [edited, setEdited] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        confirmPassword: false,
        phoneExtension: false,
    });

    const handleChange = (field) => (value) => {
        if (field === "phoneExtension") {
            value = value.replace(/\D/g, "");
        }
        setState({
            ...state,
            [field]: value,
        })
    };

    const isFirstNameValid = useMemo(
        () => {
            return firstAndLastNameValidate(state.firstName);
        },
        [state.firstName]
    );

    const isLastNameValid = useMemo(
        () => {
            return firstAndLastNameValidate(state.lastName);
        },
        [state.lastName]
    );

    const isEmailValid = useMemo(
        () => {
            return emailValidate(state.email);
        },
        [state.email]
    );

    const handleBlur = (field) => () => setEdited({
        ...edited,
        [field]: true,
    });

    const isFieldsValid = useMemo(() => {
        return isFirstNameValid.isValid && isLastNameValid.isValid && isEmailValid.isValid
            && state.brands?.length;
    }, [isFirstNameValid, isLastNameValid, isEmailValid, state.brands]);

    const notSpanishOrLatin = (value) => {
        return SPANISH_AND_LATIN_REG_EXP.test(value) && NAME_REG_EXP.test(value)
    }

    const hasChangedFields = CHECKED_FIELDS_LIST
        .reduce((res, field) => {
            const stateField = state[field];
            const originalField = row?.original[field];
            if (field === 'department') {
                if (DEPARTMENTS.includes(stateField) || DEPARTMENTS.includes(originalField)) {
                    return res || stateField !== originalField;
                }
                return res || departmentValue !== originalField;
            }
            else if (Array.isArray(stateField)) {
                return res || JSON.stringify(stateField) !== JSON.stringify(originalField);
            }
            return res || stateField !== originalField;
        }, false);

    useEffect(() => {
        const isDisabled = updating || !hasChangedFields || !isFieldsValid;
        setIsBlockedSave(isDisabled);
    }, [updating, hasChangedFields, isFieldsValid])

    return (
        <Modal
            className="add-agent-modal"
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {i18n.t('agent.edit.agent')}
                    </h5>
                    <button
                        className="close"
                        onClick={onCancel}
                        disabled={updating}
                    />
                </div>

                <ModalBody
                    className="modal-body"
                >
                    <ACommonDataField
                        label={i18n.t('agent.firstName') + ' *'}
                        component={A_COMMON_DATA_FIELD.TEXT}
                        value={state.firstName}
                        handleChange={handleChange('firstName')}
                        handleBlur={handleBlur('firstName')}
                        fulWidth={true}
                        disabled={updating}
                        error={edited.firstName && !isFirstNameValid
                               || (!notSpanishOrLatin(state.firstName)
                                   && edited.firstName
                               )}
                        helperText={i18n.t(isFirstNameValid?.message)}
                    />

                    <ACommonDataField
                        label={i18n.t('agent.lastName') + ' *'}
                        component={A_COMMON_DATA_FIELD.TEXT}
                        value={state.lastName}
                        handleChange={handleChange('lastName')}
                        handleBlur={handleBlur('lastName')}
                        fulWidth={true}
                        disabled={updating}
                        error={edited.lastName && !isLastNameValid
                               || (!notSpanishOrLatin(state.lastName)
                                   && edited.lastName
                               )}
                        helperText={i18n.t(isLastNameValid?.message)}
                    />

                    <ACommonDataField
                        label={i18n.t('agent.email')  + ' *'}
                        component={A_COMMON_DATA_FIELD.TEXT}
                        value={state.email}
                        handleChange={handleChange('email')}
                        handleBlur={handleBlur('email')}
                        fulWidth={true}
                        disabled={updating}
                        error={edited.email && !isEmailValid || (!EMAIL_REG_EXP.test(state.email) && edited.email)}
                        helperText={i18n.t(isEmailValid?.message)}
                    />

                    <label
                        className="edit-modal-label"
                    >{i18n.t('crm.status')}</label>

                    <Cell
                        value={state.status}
                        row={row}
                        column={columns.find((column) => column.id === 'status')}
                        dataDispatch={dataDispatch}
                        setIsEdit={setIsEdit}
                        edited={true}
                        onChange={handleChange('status')}
                    />

                    {canChangeAgentRole && <>
                        <label
                            className="edit-modal-label"
                        >{i18n.t('agent.roleId')}</label>

                        <Cell
                            value={state.role}
                            row={row}
                            column={columns.find((column) => column.id === 'role')}
                            dataDispatch={dataDispatch}
                            setIsEdit={setIsEdit}
                            edited={true}
                            onChange={handleChange('role')}
                        />
                    </>}

                    <label
                        className={clsx("edit-modal-label", {
                            "error-field": !state.brands?.length,
                        })}
                    >{i18n.t('crm.brandList')} *</label>

                    <Cell
                        value={state.brands}
                        row={row}
                        column={columns.find((column) => column.id === 'brands')}
                        dataDispatch={dataDispatch}
                        setIsEdit={setIsEdit}
                        edited={true}
                        onChange={handleChange('brands')}
                        isMultiLine={true}
                    />

                    <ACommonDataField
                        label={i18n.t('crm.department')}
                        component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                        value={state.department}
                        handleChange={handleChange('department')}
                        fulWidth={true}
                        options={DEPARTMENTS_OPTIONS}
                        hasClear={false}
                    />

                    {state.department === OTHER &&
                        <ACommonDataField
                            label={i18n.t('crm.other.department')}
                            component={A_COMMON_DATA_FIELD.TEXT}
                            value={departmentValue}
                            handleChange={setDepartmentValue}
                            fulWidth={true}
                        />
                    }

                    <ACommonDataField
                        label={i18n.t('agent.phoneExtension')}
                        component={A_COMMON_DATA_FIELD.TEXT}
                        value={state.phoneExtension}
                        handleChange={handleChange('phoneExtension')}
                        handleBlur={handleBlur('phoneExtension')}
                        fulWidth={true}
                        disabled={updating}
                    />

                    <div
                        className="add-agent-modal__actions"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <button
                            className="btn btn-rounded btn-light"
                            onClick={onCancel}
                            disabled={updating}
                        >
                            <img
                                src={close}
                                alt=""
                            />
                            {i18n.t('common.cancel')}
                        </button>

                        <button
                            className="btn btn-rounded btn-primary"
                            onClick={() => onUpdate({
                                ...state,
                                departmentValue,
                            })}
                            disabled={isBlockedSave}
                        >
                            <img
                                src={i_51}
                                alt=""
                            />
                            {i18n.t('common.save')}
                        </button>

                        {updating && <Loader size={'sm'}/>}
                    </div>
                </ModalBody>
            </div>
        </Modal>
    );
}

EditAgentModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    row: PropTypes.object,
    updating: PropTypes.bool,
};

EditAgentModal.defaultProps = {
    row: {
        original: {},
    }
}

export default React.memo(EditAgentModal);
