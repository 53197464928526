export const GET_PLAYER_RESTRICTIONS = 'GET_PLAYER_RESTRICTIONS';
export const GET_PLAYER_RESTRICTIONS_SUCCESS =
    'GET_PLAYER_RESTRICTIONS_SUCCESS';
export const GET_PLAYER_RESTRICTIONS_FAIL = 'GET_PLAYER_RESTRICTIONS_FAIL';

export const SET_EDIT_PLAYER_RESTRICTIONS = 'SET_EDIT_PLAYER_RESTRICTIONS';
export const RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS =
    'RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS';

export const UPDATE_PLAYER_RESTRICTIONS = 'UPDATE_PLAYER_RESTRICTIONS';
export const UPDATE_PLAYER_RESTRICTIONS_SUCCESS =
    'UPDATE_PLAYER_RESTRICTIONS_SUCCESS';
export const UPDATE_PLAYER_RESTRICTIONS_FAIL =
    'UPDATE_PLAYER_RESTRICTIONS_FAIL';
