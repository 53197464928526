import React, { useState } from "react";
import ZoomPan from "../../../utils/zoomPan";

export const ViewPDF = (props) => {
    const { docFrameRef, numPdfPages } = props;
    const [zoomPanInstance, setZoomPanInstance] = useState(null);

    const getZoomPanInstance = () => {
        if (zoomPanInstance !== null) {
            return zoomPanInstance;
        }

        const img = document.getElementById('document-view-wrapper');
        const newInstance = ZoomPan({
            minScale: 1,
            maxScale: 2,
            element: img.children[0],
            scaleSensitivity: 30,
        });
        setZoomPanInstance(newInstance);
        return newInstance;
    }

    const handleMove = (event) => {
        if (!event.altKey) {
            return;
        }

        event.preventDefault();

        getZoomPanInstance().panBy({
            originX: event.movementX,
            originY: event.movementY
        });
    }

    const handleRestore = () => {
        getZoomPanInstance().panTo({
            originX: 0,
            originY: 0,
            scale: 1,
        });
    }

    const handleZoom = (event) => {
        if (!event.shiftKey) {
            return;
        }

        event.preventDefault();

        getZoomPanInstance().zoom({
            deltaScale: Math.sign(event.deltaY) > 0 ? -3 : 3,
            x: event.pageX,
            y: event.pageY
        });
    };

    return (
        <div
            ref={docFrameRef}
            className="doc-iframe pdf-doc"
        >
            <div
                className="document-view-wrapper"
                id="document-view-wrapper"
                onWheel={handleZoom}
                onMouseMove={handleMove}
                onDoubleClick={handleRestore}
            >
                {new Array(numPdfPages)
                    .fill()
                    .map((_, num) => (
                        <canvas
                            key={`the-canvas-${
                                num + 1
                            }`}
                            id={`the-canvas-${
                                num + 1
                            }`}
                        />
                    ))
                }
            </div>
        </div>
    );
}
