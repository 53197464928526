import {
    GET_AGENT_EMPLOYEE_LIST,
    GET_AGENT_EMPLOYEE_LIST_SUCCESS,
    GET_AGENT_EMPLOYEE_LIST_FAIL,
    RESET_AGENT_EMPLOYEE_LIST_ERRORS
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    employeeList: null,
    total: 0,
    query: DEFAULT_QUERY
};

const AgentEmployeeList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AGENT_EMPLOYEE_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload.query },
                isLoading: true,
                error: null
            };

        case GET_AGENT_EMPLOYEE_LIST_SUCCESS:
            return {
                ...state,
                employeeList: action.payload,
                total: action.payload?.length,
                isLoading: false
            };

        case GET_AGENT_EMPLOYEE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                employeeList: [],
                isLoading: false
            };

        case RESET_AGENT_EMPLOYEE_LIST_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default AgentEmployeeList;
