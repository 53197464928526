import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Card,
    Button,
    UncontrolledTooltip
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import Reciver from './Reciver';
import Sender from './Sender';

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import useAlertService from '../../hooks/useAlertService';
import { useDispatch } from 'react-redux';
import ChatFull from './chat-full';
import { getSync } from '../../helpers/api_helper';
import Loader from '../../common/components/Loader';

const ChatBox = ({ onClose, playerGeneral }) => {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState(false);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(false);

    //.mdi-credit-card-refresh-outline::before {
    useEffect(async () => {
        loadEmails();
    }, []);


    const loadEmails = async () => {
        setLoading(true);
        const url = `/chat/message/${playerGeneral.oid}`;
        try {
            const result = await getSync(url, {});
            const emails = result?.data?.result || [];
            setMessages(emails);
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
        setLoading(false);
    };

    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [scrollEl, setScrollEl] = useState();

    useEffect(() => {
        if (scrollEl) {
            scrollEl.scrollTop = 0;
        }
    }, [scrollEl]);

    const onSendMessage = () => {
        setNewMessage(true);
        setShowMessagePopup(true);
    };

    const switchMessagePopup = (event) => {
        setNewMessage(false);
        if (event) {
            setSelectedMessage(event);
        }
        setShowMessagePopup(true);
    };

    const closeMessagePopup = (reload) => {
        if(reload) {
            loadEmails();
        }
        setShowMessagePopup(false);
    };

    return (
        <React.Fragment>
            {showMessagePopup &&
                <ChatFull onClose={closeMessagePopup} messageId={selectedMessage} playerGeneral={playerGeneral}
                          newMessage={newMessage} />
            }
            {!showMessagePopup &&
                <Col style={{ height: '100%' }}>
                    <p className='text-muted' onClick={loadEmails} style={{ cursor: 'pointer' }}>
                        <i className='mdi mdi-refresh' /> Reload
                    </p>

                    <Card style={{ height: '90%' }}>
                        <Row>
                            <Col md='4' xs='9'>
                                <h5 className='font-size-15 mb-1'>
                                    {playerGeneral.firstName} {playerGeneral.lastName}
                                </h5>
                                <p className='text-muted mb-0'>
                                    <i className={`mdi mdi-circle text-${playerGeneral.kyc ? 'success' : 'danger'} align-middle me-1`} />{' '}
                                    {playerGeneral.kyc ? 'KYC' : 'None KYC'}
                                </p>
                            </Col>
                            <Col md='8' xs='3'>
                                <ul className='list-inline user-chat-nav text-end mb-0'>
                                    <li className='list-inline-item d-none d-sm-inline-block'>
                                        <div
                                            className={
                                                'notes-content-header modal-header'
                                            }
                                            style={{ paddingLeft: '30px' }}
                                        >
                                            <button
                                                className='close'
                                                onClick={onClose}
                                            />
                                        </div>
                                    </li>
                                    {' '}
                                </ul>
                            </Col>
                        </Row>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%'
                        }}>
                            <div className='chat-conversation'>
                                <div className='chat-day-title'>
                                    <span className='title'>Conversation</span>
                                </div>

                                <PerfectScrollbar
                                    containerRef={ref => {
                                        setScrollEl(ref);
                                    }}
                                    style={{
                                        display: 'flex',
                                        overflow: 'scroll',
                                        marginBottom: '1rem',
                                        flexDirection: 'column',
                                        alignItems: 'stretch',
                                        justifyContent: 'flex-start',
                                        maxHeight: '66vh'
                                    }}
                                >
                                    <ul className='list-unstyled'>
                                        {loading && <Loader />}
                                        {!loading && (messages || []).map((message, index) => (
                                            <React.Fragment key={index}>
                                                {message.isSender ? (
                                                    <Sender message={message} openMessage={switchMessagePopup} />
                                                ) : (
                                                    <Reciver message={message} openMessage={switchMessagePopup} />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </PerfectScrollbar>
                            </div>
                            <div className='p-3 chat-input-section'>
                                <Row>
                                    <div className='col-auto'>
                                        <Button
                                            type='submit'
                                            color='primary'
                                            className='chat-send w-md'
                                            onClick={() => onSendMessage()}
                                        >
                                    <span className='d-none d-sm-inline-block me-2'>
                                        Send Message
                                    </span>{' '}
                                            <i className='mdi mdi-send' />
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Card>
                </Col>
            }
        </React.Fragment>
    );
};

export default withRouter(ChatBox);
