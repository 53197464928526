import React from 'react';
import PropTypes from 'prop-types';

import notesIcon from "../../../../assets/images/common/Notes.svg";

import i18n from "../../../../i18n";

import "./notes.scss"


function NotesView({ onClick }) {
  return (
    <span className={`notesBtn`} onClick={onClick} role="button">
      <img src={notesIcon} alt={i18n.t("crm.notes")} />
      {i18n.t("crm.notes")}
    </span>
  );
}

NotesView.propTypes = {
    status: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default React.memo(NotesView);
