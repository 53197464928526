import React from 'react';
import { Redirect } from 'react-router-dom';

// CRM Pages
import PlayerList from '../pages/CRM/PlayerList';
import DocumentView from '../pages/CRM/DocumentView';
import PendingKycPlayerList from '../pages/CRM/PendingKycPlayerList';
import SitePage from '../pages/Admin/SitePage';
import AgentViewPage from '../pages/AgentView/AgentViewPage';
import AgentEmployeesPage from '../pages/AgentView/AgentEmployeesPage';
import EmployeePage from '../pages/AgentView/EmployeePage';
import AffiliatesPage from '../pages/Affiliates/AffiliatesPage';
import AffiliatePage from '../pages/Affiliates/AffiliatePage';
import WorkflowListPage from '../pages/Workflow/WorkflowListPage';
import WorkflowPage from '../pages/Workflow/WorkflowPage';
import AttributionListPage from '../pages/Attributions/AttributionListPage';
import AttributionPage from '../pages/Attributions/AttributionPage';
import Retail from "../pages/Retail/Retail";
import RetailCustomerView from "../pages/Retail/RetailCustomerView";

// Content Pages
import GameCategories from "../pages/Content/GameCategories"
import GameCategoryView from "../pages/Content/GameCategoryView"
import GamesDBManagement from "../pages/Content/GamesDBManagement";

// User list general
import UserGeneral from '../pages/CRM/general/UserGeneral';

// Finance Pages
import Deposits from '../pages/Finance/Deposits';
import DepositView from '../pages/Finance/DepositView';
import Withdrawals from '../pages/Finance/Withdrawals';
import WithdrawView from '../pages/Finance/WithdrawView';
import PendingTransactions from '../pages/Finance/PendingTransactions';
import PendingTransactionView from '../pages/Finance/PendingTransationView';

// Engagement Pages
import PromotionsListPage from "../pages/Promotions/PromotionsListPage";
import PromotionPage from "../pages/Promotions/PromotionPage";
import Smartico from "../pages/Promotions/Smartico";
import SportPromotionsListPage from "../pages/Promotions/SportPromotionsListPage";
import SportPromotionPage from "../pages/Promotions/SportPromotionPage";
import Tracker from '../pages/CRM/Tracker';
import PlayerReport from '../pages/PlayerReport/PlayerReport';

// app ui
import IconsAppPage from '../pages/Ui/app/IconsAppPage';
import ButtonsAppPage from '../pages/Ui/app/ButtonsAppPage';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

// Filter page
import FilterPage from '../pages/FilterPage';

import Dashboard from '../pages/Dashboard/index';
import NotificationsPage from '../pages/NotificationsPage';
import RolesPage from '../pages/Admin/roles/RolesPage';
import StatisticsPage from '../pages/Admin/statisticts/StatisticsPage';
import AgentsPage from '../pages/Admin/agents/AgentsPage';
import GameManagement from "../pages/Content/GameManagement";
import PendingTransferTransationView from "../pages/Finance/PendingTransferTransationView";
import PendingTransferTransactions from "../pages/Finance/PendingTransferTransactions";
import SetupAccount from "../pages/Authentication/SetupAccount";
import Cashier from "../pages/Cashier/Cashier";

const authProtectedRoutes = [
    // CRM
    { path: '/overview', component: Dashboard, title: 'overview' },
    { path: '/player-list', component: PlayerList, title: 'playerList' },
    { path: '/player-report', component: PlayerReport, title: 'PlayerReport' },
    { path: '/pending-documents', component: DocumentView, title: 'pendingDocuments' },
    { path: '/pending-kyc-verification', component: PendingKycPlayerList, title: 'pendingKycVerification' },
    { path: '/pending-kyc-verification/:id', component: UserGeneral },
    { path: '/affiliates', component: AffiliatesPage, title: 'affiliates' },
    { path: '/audience', component: AttributionListPage, title: 'audience' },
    { path: '/workflow', component: WorkflowListPage, title: 'workflow' },

    // Filter
    { path: '/filter', component: FilterPage },

    // Notifications
    { path: '/notifications', component: NotificationsPage },

    // CRM Views
    { path: '/player-list/:id', component: UserGeneral },
    { path: '/documents/:id', component: DocumentView },
    { path: '/affiliates/:id', component: AffiliatePage },
    { path: '/workflow/:id', component: WorkflowPage },
    { path: '/audience/:id', component: AttributionPage },
    { path: "/retail-player-list", component: Retail, title: 'retail' },
    { path: "/retail-player-list/:id", component: RetailCustomerView },

    // Finance
    { path: '/deposits', component: Deposits, title: 'deposits' },
    { path: '/deposits-declines', component: Deposits, title: 'depositsDeclines' },
    { path: '/deposits-action', component: Deposits, title: 'depositsActions' },
    { path: '/withdrawals', component: Withdrawals, title: 'withdrawals' },
    { path: '/withdrawals-pending-approve', component: PendingTransactions, title: 'withdrawalsPendingApprove' },
    { path: '/withdrawals-pending-transfer', component: PendingTransferTransactions, title: 'withdrawalsPendingTransfer' },

    // Finance Views
    { path: '/deposits/:id', component: DepositView },
    { path: '/deposits-declines/:id', component: DepositView },
    { path: '/deposits-action/:id', component: DepositView },
    { path: '/withdrawals/:id', component: WithdrawView },
    { path: '/withdrawals-pending-approve/:id', component: PendingTransactionView },
    { path: '/withdrawals-pending-transfer/:id', component: PendingTransferTransationView },


    // Content
    { path: "/game-categories", component: GameCategories, title: 'gameCategories' },
    { path: "/game-management", component: GameManagement, title: 'gameManagement' },
    { path: "/games-db", component: GamesDBManagement, title: 'gamesDB' },

    // Dashboard
    { path: '/cms', component: Dashboard },

    // Content Views
    { path: "/game-categories/:id", component: GameCategoryView },

    // Engagement
    { path: "/promotions", component: PromotionsListPage, title: 'casinoPromotions' },
    { path: "/promotions/new", component: PromotionPage, title: 'newPromotion' },
    { path: "/promotions/:id", component: PromotionPage },
    { path: "/smartico", component: Smartico, title: 'smartico' },
    { path: "/marketing", component: GameCategories }, // TODO: Need to implement component
    { path: "/sport-promotions", component: SportPromotionsListPage,  title: 'sportPromotions'  },
    { path: "/sport-promotions/new", component: SportPromotionPage, title: 'newSportPromotion' },
    { path: "/sport-promotions/:id", component: SportPromotionPage },
    { path: "/tracker", component: Tracker, title: 'tracker' },
    { path: "/tracker/:id", component: UserGeneral },

    // Cashier
    { path: "/cashier/:id", component: Cashier },

    // Admin
    { path: '/statistics', component: StatisticsPage },
    { path: '/admin-agents', component: AgentsPage, title: 'agents' },
    { path: '/site', component: SitePage, title: 'brandSettings' },
    { path: '/admin-role', component: RolesPage, title: 'roles' },
    { path: '/agent-view', component: AgentViewPage, title: 'agentView' },
    { path: '/agent-view/employees', component: AgentEmployeesPage },
    { path: '/agent-view/employees/:id', component: EmployeePage },

    { path: '/icons-app', component: IconsAppPage },
    { path: '/buttons-app', component: ButtonsAppPage },

    // this route should be at the end of all other routes
    { path: '/', exact: true, component: () => <Redirect to="/overview" /> }
];

const publicRoutes = [
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPwd },
    { path: '/set-up', component: SetupAccount }
];

export { authProtectedRoutes, publicRoutes };
