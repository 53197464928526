import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../i18n';

import NotificationTable from './_elements/NotificationTable';

import Breadcrumb from '../../common/components/Breadcrumb';
import CustomPagination from '../../common/tables/CustomPagination';

import { CommonCard } from '../../components/CommonCard';

import useList from '../../hooks/useList';
import useAlertService from '../../hooks/useAlertService';

import './style.scss';
import { getNotificationsListPage } from '../../store/notifications/notificationPage/action';

const NotificationPage = () => {
    const dispatch = useDispatch();

    const { data, error, isLoading, notificationsList, query } = useSelector(
        state => state.NotificationPage
    );

    const total = data.read + data.unread;
    const { showSuccess } = useAlertService();

    const triggerNotificationDispatch = _query => {
        if (_query) {
            dispatch(getNotificationsListPage(_query));
        } else {
            dispatch(getNotificationsListPage(query));
        }
    };

    const { pageCount, activePage, handleChangePage } = useList(
        query => triggerNotificationDispatch(query),
        total,
        query
    );

    useEffect(() => {
        triggerNotificationDispatch();
    }, [total]);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumb breadcrumbItem={i18n.t('filter.filterPage')} />
                <CommonCard className={'filter-page'}>
                    <NotificationTable
                        triggerNotificationDispatch={
                            triggerNotificationDispatch
                        }
                        data={data.data}
                        isLoading={isLoading}
                    />
                </CommonCard>
                {pageCount > 2 &&
                    <CustomPagination
                        key={'notificationPage'}
                        pageCount={pageCount}
                        activePage={activePage}
                        onChange={handleChangePage}
                    />
                }
            </Container>
        </div>
    );
};

export default React.memo(NotificationPage);
