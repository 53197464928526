import { get, post, del, put } from './api_helper';
import {
    DEFAULT_LIMIT,
    DEFAULT_OFFSET
} from "../pages/Promotions/common/promotionConstants";

const getPromotionsApi = ({
    offset = DEFAULT_OFFSET,
    limit = DEFAULT_LIMIT,
    sort = 'id',
    order = 'DESC',
    status = '',
    type = null,
    name = null,
    ids = null,
    byAgent = false,
}) => {
    const url = `/promotion`;
    return get(url, {params: {
            offset,
            limit,
            sort,
            order,
            status,
            type,
            name,
            ids,
            byAgent,
        }});
};

const getSportPromotionsApi = ({
    offset = DEFAULT_OFFSET,
    limit = DEFAULT_LIMIT,
    sort = 'id',
    order = 'DESC',
    status = '',
    type = null,
    name = null,
    ids = null,
    byAgent = false,
}) => {
    const url = `/sport-promotion`;
    return get(url, {params: {
        offset,
        limit,
        sort,
        order,
        status,
        type,
        name,
        ids,
        byAgent,
    }});
};

const getPromotionTemplatesApi = () => {
    const url = `/promotion/player/templates`;
    return get(url);
};

const getPromotionApi = (promotionId) => {
    const url = `/promotion/${promotionId}`;
    return get(url);
};

const getBonusWageringSmallDataApi = () => {
    const url = `/promotion/bonus-wagering-small-data`;
    return get(url);
};

const updatePromotionApi = ({promotionId, data, file}) => {
    const url = `/promotion/${promotionId}`;

    const formData = new FormData();
    if (file) {
        formData.append('file', file);
    }

    Object.keys(data).forEach(key =>
        formData.append(key, typeof data[key] === 'object'
            ? JSON.stringify(data[key])
            : data[key]
        )
    );

    return put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const createPromotionApi = ({data}) => {
    const url = `/promotion`;

    const formData = new FormData();
    Object.keys(data).forEach(key =>
        formData.append(key, typeof data[key] === 'object'
            ? JSON.stringify(data[key])
            : data[key]
        )
    );

    return post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const getSportPromotionApi = (promotionId) => {
    const url = `/sport-promotion/${promotionId}`;
    return get(url);
};

const updateSportPromotionApi = ({promotionId, data}) => {
    const url = `/sport-promotion/${promotionId}`;
    return put(url, data);
};

const createSportPromotionApi = ({data}) => {
    const url = `/sport-promotion`;
    return post(url, data);
};

export {
    getPromotionsApi,
    getPromotionTemplatesApi,
    getPromotionApi,
    getBonusWageringSmallDataApi,
    updatePromotionApi,
    createPromotionApi,
    getSportPromotionsApi,
    getSportPromotionApi,
    updateSportPromotionApi,
    createSportPromotionApi,
};
