import {
    GET_STATISTICS,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAIL,
    RESET_STATISTICS_ERRORS,
} from './actionTypes';


const INITIAL_STATE = {
    isLoading: false,
    error: null,
    statistics: null,
};

const Statistics = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_STATISTICS:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case GET_STATISTICS_SUCCESS:
            return { ...state, isLoading: false, statistics: action.payload };

        case GET_STATISTICS_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        case RESET_STATISTICS_ERRORS:
            return { ...state, error: null };

        default:
            return { ...state };
    }
};

export default Statistics;
