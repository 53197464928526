export const GET_GAMES = 'GET_GAMES';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export const GET_ALL_GAMES = 'GET_ALL_GAMES';

export const CREATE_GAME = 'CREATE_GAME';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';

export const UPDATE_GAME = 'UPDATE_GAME';
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS';
export const UPDATE_GAME_FAIL = 'UPDATE_GAME_FAIL';

export const BULK_INSERT_GAMES = 'BULK_INSERT_GAMES';
export const BULK_INSERT_GAMES_SUCCESS = 'BULK_INSERT_GAMES_SUCCESS';
export const BULK_INSERT_GAMES_FAIL = 'BULK_INSERT_GAMES_FAIL';

export const UPLOAD_GAMESDB_TO_CREATE = 'UPLOAD_GAMESDB_TO_CREATE';
export const UPLOAD_GAMESDB_TO_CREATE_SUCCESS = 'UPLOAD_GAMESDB_TO_CREATE_SUCCESS';
export const UPLOAD_GAMESDB_TO_CREATE_FAIL = 'UPLOAD_GAMESDB_TO_CREATE_FAIL';

export const DOWNLOAD_GAME_LIST = 'DOWNLOAD_GAME_LIST';
export const DOWNLOAD_GAME_LIST_SUCCESS = 'DOWNLOAD_GAME_LIST_SUCCESS';
export const DOWNLOAD_GAME_LIST_FAIL = 'DOWNLOAD_GAME_LIST_FAIL';

export const DOWNLOAD_GAMES_DB_TEMPLATE = 'DOWNLOAD_GAMES_DB_TEMPLATE';
export const DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS = 'DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS';
export const DOWNLOAD_GAMES_DB_TEMPLATE_FAIL = 'DOWNLOAD_GAMES_DB_TEMPLATE_FAIL';

export const DOWNLOAD_GM_TEMPLATE = 'DOWNLOAD_GM_TEMPLATE';
export const DOWNLOAD_GM_TEMPLATE_SUCCESS = 'DOWNLOAD_GM_TEMPLATE_SUCCESS';
export const DOWNLOAD_GM_TEMPLATE_FAIL = 'DOWNLOAD_GM_TEMPLATE_FAIL';

export const DOWNLOAD_GM_GAMES = 'DOWNLOAD_GM_GAMES';
export const DOWNLOAD_GM_GAMES_SUCCESS = 'DOWNLOAD_GM_GAMES_SUCCESS';
export const DOWNLOAD_GM_GAMES_FAIL = 'DOWNLOAD_GM_GAMES_FAIL';

export const GET_GAME_TYPES = 'GET_GAME_TYPES';
export const GET_GAME_TYPES_SUCCESS = 'GET_GAME_TYPES_SUCCESS';
export const GET_GAME_TYPES_FAIL = 'GET_GAME_TYPES_FAIL';

export const GET_GAME_CATEGORIES = 'GET_GAME_CATEGORIES';
export const GET_GAME_CATEGORIES_SUCCESS = 'GET_GAME_CATEGORIES_SUCCESS';
export const GET_GAME_CATEGORIES_FAIL = 'GET_GAME_CATEGORIES_FAIL';

export const CREATE_GAME_CATEGORY = 'CREATE_GAME_CATEGORY';
export const CREATE_GAME_CATEGORY_SUCCESS = 'CREATE_GAME_CATEGORY_SUCCESS';
export const CREATE_GAME_CATEGORY_FAIL = 'CREATE_GAME_CATEGORY_FAIL';
export const RESET_GAME_CATEGORY = 'RESET_GAME_CATEGORY';

export const GET_GAME_CATEGORY = 'GET_GAME_CATEGORY';
export const GET_GAME_CATEGORY_SUCCESS = 'GET_GAME_CATEGORY_SUCCESS';
export const GET_GAME_CATEGORY_FAIL = 'GET_GAME_CATEGORY_FAIL';

export const PUT_CATEGORY_GAMES = 'PUT_CATEGORY_GAMES';
export const PUT_CATEGORY_GAMES_SUCCESS = 'PUT_CATEGORY_GAMES_SUCCESS';
export const PUT_CATEGORY_GAMES_FAIL = 'PUT_CATEGORY_GAMES_FAIL';

export const PUT_CATEGORY_GAME = 'PUT_CATEGORY_GAME';
export const PUT_CATEGORY_GAME_SUCCESS = 'PUT_CATEGORY_GAME_SUCCESS';
export const PUT_CATEGORY_GAME_FAIL = 'PUT_CATEGORY_GAME_FAIL';

export const PUT_CATEGORY_DATA = 'PUT_CATEGORY_DATA';
export const PUT_CATEGORY_DATA_SUCCESS = 'PUT_CATEGORY_DATA_SUCCESS';
export const PUT_CATEGORY_DATA_FAIL = 'PUT_CATEGORY_DATA_FAIL';

export const GET_CATEGORY_GAMES = 'GET_CATEGORY_GAMES';
export const GET_CATEGORY_GAMES_SUCCESS = 'GET_CATEGORY_GAMES_SUCCESS';
export const GET_CATEGORY_GAMES_FAIL = 'GET_CATEGORY_GAMES_FAIL';

export const BULK_UPDATE_CATEGORY_GAMES = 'BULK_UPDATE_CATEGORY_GAMES';
export const BULK_UPDATE_CATEGORY_GAMES_SUCCESS = 'BULK_UPDATE_CATEGORY_GAMES_SUCCESS';
export const BULK_UPDATE_CATEGORY_GAMES_FAIL = 'BULK_UPDATE_CATEGORY_GAMES_FAIL';

export const PURGE_CATEGORY_CACHE = 'PURGE_CATEGORY_CACHE';
export const PURGE_CATEGORY_CACHE_SUCCESS = 'PURGE_CATEGORY_CACHE_SUCCESS';
export const PURGE_CATEGORY_CACHE_FAIL = 'PURGE_CATEGORY_CACHE_FAIL';

export const DOWNLOAD_CATEGORY_GAME_LIST = 'DOWNLOAD_CATEGORY_GAME_LIST';
export const DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS = 'DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS';
export const DOWNLOAD_CATEGORY_GAME_LIST_FAIL = 'DOWNLOAD_CATEGORY_GAME_LIST_FAIL';

export const GET_SITE_GAMES = 'GET_SITE_GAMES';
export const GET_SITE_GAMES_SUCCESS = 'GET_SITE_GAMES_SUCCESS';
export const GET_SITE_GAMES_FAIL = 'GET_SITE_GAMES_FAIL';

export const GET_SITE_AVAILABLE_GAMES = 'GET_SITE_AVAILABLE_GAMES';
export const GET_SITE_AVAILABLE_GAMES_SUCCESS = 'GET_SITE_AVAILABLE_GAMES_SUCCESS';
export const GET_SITE_AVAILABLE_GAMES_FAIL = 'GET_SITE_AVAILABLE_GAMES_FAIL';

export const PUT_SITE_GAMES = 'PUT_SITE_GAMES';
export const PUT_SITE_GAMES_SUCCESS = 'PUT_SITE_GAMES_SUCCESS';
export const PUT_SITE_GAMES_FAIL = 'PUT_SITE_GAMES_FAIL';

export const BULK_UPDATE_SITE_GAMES = 'BULK_UPDATE_SITE_GAMES';
export const BULK_UPDATE_SITE_GAMES_SUCCESS = 'BULK_UPDATE_SITE_GAMES_SUCCESS';
export const BULK_UPDATE_SITE_GAMES_FAIL = 'BULK_UPDATE_SITE_GAMES_FAIL';

export const BULK_UPDATE_SITE_GAME_ATTRIBUTIONS = 'BULK_UPDATE_SITE_GAME_ATTRIBUTIONS';
export const BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS = 'BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS';
export const BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL = 'BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL';

export const UPDATE_SITE_GAME = 'UPDATE_SITE_GAME';
export const UPDATE_SITE_GAME_SUCCESS = 'UPDATE_SITE_GAME_SUCCESS';
export const UPDATE_SITE_GAME_FAIL = 'UPDATE_SITE_GAME_FAIL';

export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAIL = 'REMOVE_CATEGORY_FAIL';

export const REMOVE_CATEGORY_GAME = 'REMOVE_CATEGORY_GAME';
export const REMOVE_CATEGORY_GAME_SUCCESS = 'REMOVE_CATEGORY_GAME_SUCCESS';
export const REMOVE_CATEGORY_GAME_FAIL = 'REMOVE_CATEGORY_GAME_FAIL';

export const REMOVE_SITE_GAME = 'REMOVE_SITE_GAME';
export const REMOVE_SITE_GAME_SUCCESS = 'REMOVE_SITE_GAME_SUCCESS';
export const REMOVE_SITE_GAME_FAIL = 'REMOVE_SITE_GAME_FAIL';

export const REMOVE_SITE_GAMES = 'REMOVE_SITE_GAMES';
export const REMOVE_SITE_GAMES_SUCCESS = 'REMOVE_SITE_GAMES_SUCCESS';
export const REMOVE_SITE_GAMES_FAIL = 'REMOVE_SITE_GAMES_FAIL';

export const DOWNLOAD_SITE_GAME_LIST = 'DOWNLOAD_SITE_GAME_LIST';
export const DOWNLOAD_SITE_GAME_LIST_SUCCESS = 'DOWNLOAD_SITE_GAME_LIST_SUCCESS';
export const DOWNLOAD_SITE_GAME_LIST_FAIL = 'DOWNLOAD_SITE_GAME_LIST_FAIL';

export const DOWNLOAD_AVAILABLE_SITE_GAME_LIST = 'DOWNLOAD_AVAILABLE_SITE_GAME_LIST';
export const DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS = 'DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS';
export const DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL = 'DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL';

export const DOWNLOAD_SITE_GAME_ATTRIBUTIONS = 'DOWNLOAD_SITE_GAME_ATTRIBUTIONS';
export const DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS = 'DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS';
export const DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL = 'DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL';

export const GET_GAME_VENDORS = 'GET_GAME_VENDORS';
export const GET_GAME_VENDORS_SUCCESS = 'GET_GAME_VENDORS_SUCCESS';
export const GET_GAME_VENDORS_FAIL = 'GET_GAME_VENDORS_FAIL';

export const GET_GAME_PROVIDERS = 'GET_GAME_PROVIDERS';
export const GET_GAME_PROVIDERS_SUCCESS = 'GET_GAME_PROVIDERS_SUCCESS';
export const GET_GAME_PROVIDERS_FAIL = 'GET_GAME_PROVIDERS_FAIL';

export const GET_SITE_PROVIDERS = 'GET_SITE_PROVIDERS';
export const GET_SITE_PROVIDERS_SUCCESS = 'GET_SITE_PROVIDERS_SUCCESS';
export const GET_SITE_PROVIDERS_FAIL = 'GET_SITE_PROVIDERS_FAIL';
export const UPDATE_GM_GAMES = 'UPDATE_GM_GAMES';
export const UPDATE_GM_GAMES_SUCCESS = 'UPDATE_GM_GAMES_SUCCESS';
export const UPDATE_GM_GAMES_FAIL = 'UPDATE_GM_GAMES_FAIL';
export const UPDATE_DB_GAMES = 'UPDATE_DB_GAMES';
export const UPDATE_DB_GAMES_SUCCESS = 'UPDATE_DB_GAMES_SUCCESS';
export const UPDATE_DB_GAMES_FAIL = 'UPDATE_DB_GAMES_FAIL';
