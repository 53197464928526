import {get, patch, post, del, put} from './api_helper';
import * as url from './url_helper';
import { prepareSearch } from './SearchUtils';

// Login Method
const login = data => post(url.LOGIN, data);

const googleAuthentication = data => post(url.GOOGLE_AUTH, data);

const logout = _ => post(url.LOGOUT);

const getNotesListApi = async query => {
    const url = `/note?${prepareSearch(query)}`;
    return get(url);
};

const addNoteApi = async body => {
    const url = `/note`;
    return post(url, body);
};

const getNoteApi = async oid => {
    const url = `/note/${oid}`;
    return get(url);
};

const changeNoteApi = async ({ oid, ...body }) => {
    const url = `/note/${oid}`;

    return patch(url, body);
};

const removeNoteApi = async (oid) => {
    const url = `/note/${oid}`;

    return del(url);
};

const getCountersApi = () => {
    const url = `/site/info`;
    return get(url);
};

const getStatesAndApi = () => {
    const url = `/site/mx/address`;
    return get(url);
};

// Brand Settings
const getBrandSettingsApi = () => {
    const url = `/site/`;
    return get(url);
};

const getVipLevelConfig = () => {
    const url = `/site/vip-level-settings`;
    return get(url);
}

const updateBrandSettingsApi = data => {
    const url = `/site/settings`;
    return patch(url, data);
};

const getSmarticoSettingsApi = () => {
    const url = `/site/smartico-keys`;
    return get(url);
};

const getBrandTagsApi = () => {
    const url = `/site/tags`;
    return get(url);
};

const getAntiFraudRulesApi = () => {
    const url = `/anti-fraud-rules`;
    return get(url);
};

const getAntiFraudActionsApi = () => {
    const url = `/anti-fraud-rules/available-actions`;
    return get(url);
};

const updateAntiFraudRulesApi = (dto) => {
    const url = '/anti-fraud-rules';
    return patch(url, dto)
}

const getCoolOfDataApi = () => {
    const url = `/cool-off`;
    return get(url);
}

const updateCoolOffDataApi = (dto) => {
    const url = `/cool-off`;
    return put(url, dto);
}

const deleteCoolOffDataApi = (dto) => {
    const url = `/cool-off`;
    return del(url);
}

export {
    login,
    googleAuthentication,
    logout,
    getBrandSettingsApi,
    updateBrandSettingsApi,
    getSmarticoSettingsApi,
    getCountersApi,
    changeNoteApi,
    removeNoteApi,
    getNoteApi,
    addNoteApi,
    getNotesListApi,
    getStatesAndApi,
    getBrandTagsApi,
    getAntiFraudRulesApi,
    getAntiFraudActionsApi,
    updateAntiFraudRulesApi,
    getVipLevelConfig,
    getCoolOfDataApi,
    updateCoolOffDataApi,
    deleteCoolOffDataApi,
};
