import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { uniq } from "lodash";
import xlsx from "xlsx";
import clsx from "clsx";

import i18n from "../../i18n";
import GameTable from "../../common/tables/GameTable";
import CustomModal from "../../common/components/CustomModal";
import {
  getStorageXSiteId,
  handleChangePath,
} from "../../common/utils/common";
import CategoryGamesModalHeader from "./components/CategoryGamesModalHeader";
import modalTableColumns from "./components/ModalTableColumns";

import TableBigModal from "./TableBigModal";
import {
  getSiteGames,
  getSiteProviders,
  getGameVendors,
  removeSiteGame,
  getSiteAvailableGames,
  bulkUpdateSiteGames,
  downloadSiteGameList,
  downloadAvailableSiteGameList,
  putSiteGames,
  bulkUpdateSiteGameAttributions,
  downloadSiteGameAttributions,
  getGameTypes,
  removeSiteGames,
  downloadGMTemplate,
  updateGMGames,
  downloadGMGames,
  purgeCacheGameCategory,
} from "../../store/actions";
import { DIRECTION } from "../../constants/layout";
import { DEFAULT_PAGE_SIZE, SITE_PERMISSION } from "../../common/constants/common";
import DragFileInput from "../../common/inputs/common/DragFileInput";

import "./styles.scss"

import FormSearch from "../../UI/formSearch/FormSearch";
import Loader from "../../common/components/Loader";
import TableFilter from "../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_GAME_MANAGEMENT_LIST } from "../../constants/Filter";
import { getFilteredGames } from "../../common/utils/filter";
import SelectTableView from "./components/SelectTableView";
import { AttributionType } from "./common/constants";
import ButtonsPanel from "../CRM/general/ButtonsPanel";
import Tooltip from "@mui/material/Tooltip";
import GamesConfirmationModal from "../CRM/modal/GamesConfirmationModal";
import download from "../../assets/images/common/download.svg";
import settings from "../../assets/images/layout/settings.svg";
import { XLSXformat } from "../../common/inputs/common/FileTypes/fileTypes";
import WrongGamesModal from "../CRM/modal/WrongGamesModal";
import gamesDBTemplateIcon from "../../assets/images/common/gamesDBTemplateIcon.svg";
import PermissionWrapper from "../../components/PermissionWrapper";
import purgeCache from "../../assets/images/common/purgeCache.svg";


const rowHeight = 42;

const getColumns = (context) => {
  const attributionColumns = Object.keys(AttributionType)
      .map((id) => ({
        id,
        title: i18n.t(`content.${id}`),
        className: 'text-align-center',
      }));

  const { isShownSelectedGames } = context?.state || {};

  const columns = [
    {
      id: 'check',
      title: '',
    },
    {
      id: 'game',
      source: isShownSelectedGames ? 'gameName' : 'friendlyName',
      title: i18n.t('content.gameName'),
      className: 'white-space-normal bold game-name',
    },
    {
      id: 'oid',
      title: i18n.t('content.operationId'),
      className: 'text-align-center',
    },
    {
      id: 'genre',
      source: 'gameType',
      title: i18n.t('content.type'),
      className: 'text-align-center game-type',
    },
    {
      id: 'gameVendor',
      source: 'vendorName',
      title: i18n.t('content.gameVendor'),
      className: 'text-align-center width150 game-vendor',
    },
    {
      id: 'priority',
      title: i18n.t('content.priority'),
      className: 'text-align-center',
    },
    {
      id: 'animated',
      title: i18n.t('content.animated'),
      className: 'text-align-center',
    },
    {
      id: 'emphasize',
      title: i18n.t('content.emphasize'),
      className: 'text-align-center',
    },
    {
      id: 'gameProvider',
      source: 'providerName',
      title: i18n.t('content.gameProvider'),
      className: 'text-align-center width150',
    },
    {
      id: 'addedTime',
      source: 'createdAt',
      title: i18n.t('content.addedTime'),
      className: 'text-align-center width150',
    },
    {
      id: 'enabledTime',
      source: 'enabledAt',
      title: i18n.t('content.enabledTime'),
      className: 'text-align-center width150',
    },
    {
      id: 'rtp',
      title: i18n.t('content.rtp'),
      className: 'text-align-center',
    },
    {
      id: 'supportFreeSpins',
      source: 'supportFreeSpins',
      title: i18n.t('content.freeSpins'),
      className: 'text-align-center',
    },
    {
      id: 'externalDesktopId',
      title: i18n.t('content.gameIdDesktop'),
      className: 'text-align-center width150',
    },
    {
      id: 'externalMobileId',
      title: i18n.t('content.gameIdMobile'),
      className: 'text-align-center width150',
    },
    {
      id: 'bonusFactor',
      title: i18n.t('content.bonusFactor'),
      className: 'text-align-center',
    },
    ...attributionColumns,
    {
      id: 'remove',
      title: '',
      className: 'text-align-center',
      onClick: (game) => context.setState({
        removeGameModalVisibility: true,
        currentGame: game,
      })
    },
  ];

  return columns;
}

class GameManagement extends Component {
  constructor(props) {
    super(props);

    handleChangePath(props);
    this.state = {
      games: [],
      sortedGames: [],
      initialGamesIds: new Set(),
      changedGamesIds: new Set(),
      checkedAll: false,
      checkedGamesCount: 0,
      withPagination: true,
      activePage: 1,
      pageCount: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      currentRowHeight: rowHeight,
      currentGame: null,
      removeGameModalVisibility: false,
      isShowConfirmationModal: false,
      changeCategoryDialogVisibility: false,
      changeCategoryGamesModalVisibility: false,
      gamesCount: 0,
      searchValue: '',
      modalSearchValue: '',
      savedCountVisibleGameInCategory: 0,
      isIncreaseGames: [],
      isDecreaseGames: [],
      agentPermissions: props.agentSelf?.permissions || [],
      brandProviders: [],
      brandVendors: [],
      selectedCount: 0,
      deletedCount: 0,
      isShownSelectedGames: false,
      isEdit: false,
      gamesIdsToDelete: [],
      gamesIdsToAdd: [],
      isFileParsing: false,
      isShowTableSettings: false,
      showWrongGamesModal: false,
      tableColumns: [],
      isPurgeCacheDisabled: false,
    };

    this.boxRef = null;
    this.allAvailableGameIds = [];
    this.wrongLocalGames = [];
  }

  componentDidMount() {
    this.props.getSiteGames();
    this.props.getSiteAvailableGames();
    this.props.getSiteProviders();
    this.props.getGameVendors();
    this.props.getGameTypes();
    this.setState({
      savedCountVisibleGameInCategory: this.state.gamesCount,
      agentPermissions: this.props.agentSelf?.permissions || [],
      tableColumns: getColumns(this),
    });
  }

  filterGames(games = []) {
    const { currentFilter } = this.props;
    const { brandVendors } = this.state;
    return getFilteredGames(currentFilter, games, brandVendors) || [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      gameVendors,
      gameProviders,
      activeBrand,
      siteGames,
      availableSiteGames,
      isBulkUpload,
      errorGames,
      agentSelf,
      isDownloading,
      isDownloadingGMTemplate,
    } = this.props;
    const {
      games,
      isShownSelectedGames,
    } = this.state;

    if (isShownSelectedGames !== prevState.isShownSelectedGames) {
      this.setState({
        tableColumns: getColumns(this),
      });
    }

    if (!isDownloadingGMTemplate && isDownloadingGMTemplate !== prevProps.isDownloadingGMTemplate) {
      if (!errorGames) {
        this.props.alert.success(i18n.t('crm.alerts.templateDownloaded'));
      }
    }

    if (!isDownloading && isDownloading !== prevProps.isDownloading) {
      if (!errorGames) {
        this.props.alert.success(i18n.t('crm.alerts.fileDownloaded'))
      }
    }

    if (!isBulkUpload && isBulkUpload !== prevProps.isBulkUpload) {
      if (errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.errorFileProcessing'));
      }
      else {
        if (this.wrongLocalGames.length === 0) {
          this.props.alert.success(i18n.t('crm.alerts.siteGamesUpdated'));
        }
        else {
          this.props.alert.info(i18n.t('crm.alerts.gameWasUpdatedWithErrors'));
        }
      }

      if (this.wrongLocalGames.length > 0) {
        this.setState({
          showWrongGamesModal: true,
          isFileParsing: false,
        });
      }
      else {
        this.setState({ isFileParsing: false });
      }
    }

    if (prevProps.agentSelf !== agentSelf) {
      this.setState({
        agentPermissions: agentSelf?.permissions || [],
      });
    }

    if (availableSiteGames && availableSiteGames !== prevProps.availableSiteGames) {
      this.allAvailableGameIds = [];
      availableSiteGames.result.forEach((game) => {
        if (game) {
          const { id, friendlyName } = game;

          if (id) {
            this.allAvailableGameIds.push(id);
          }
        }
      });
    }

    if (gameProviders !== prevProps.gameProviders || activeBrand !== prevProps.activeBrand) {
      if (gameProviders && activeBrand) {
        const brandProviders = gameProviders.filter((provider) => provider.site === activeBrand?.id);
        this.setState({ brandProviders });
      }
    }

    if (gameVendors && gameVendors !== prevProps.gameVendors || games !== prevState.games) {
      if (Array.isArray(games)) {
        const directVendors = gameVendors.filter((vendor) => vendor.configuration?.direct);
        const uniqVendorIds = uniq(games.map((game) => game.vendor));
        const siteGameVendors = gameVendors.filter((vendor) => uniqVendorIds.includes(vendor.id));
        const siteGameVendorNames = siteGameVendors.map((vendor) => vendor.name);

        for (let directVendor of directVendors) {
          if (siteGameVendorNames.includes(directVendor.name) && !siteGameVendors.includes(directVendor)) {
            siteGameVendors.push(directVendor);
          }
        }

        const brandVendors = siteGameVendors.map((vendor) => {
          const {id, name, configuration} = vendor;
          return {
            id,
            name: configuration.direct ? `${name} (direct)` : name,
          }
        });

        brandVendors.sort((a1, a2) => a1.name < a2.name ? -1 : 1);

        this.setState({ brandVendors });
      }
    }

    if (this.state.isShownSelectedGames !== prevState.isShownSelectedGames) {
      this.setState({ activePage: 1 })
    }

    if (siteGames !== prevProps.siteGames || availableSiteGames !== prevProps.availableSiteGames ||
        this.state.isShownSelectedGames !== prevState.isShownSelectedGames
    ) {
      const { isShownSelectedGames, pageSize, searchValue } = this.state;

      let currentGames;
      if (isShownSelectedGames) {
        currentGames = [...siteGames];
      }
      else {
        const enabledData = {};
        siteGames.forEach((game) => enabledData[game.id] = game.enabledAt);

        currentGames = availableSiteGames.result.map((game) => {
          const siteGame = siteGames.find((sg) => sg.id === game.id);

          return {
            ...game,
            enabledAt: enabledData[game.id] || '',
            bonusFactor: siteGame ? siteGame.bonusFactor : game.bonusFactor,
          };
        });
      }

      const games = currentGames.map((game) => {
        const { details, ...otherFields } = game;
        return {
          ...otherFields,
          ...(details || {}),
        }
      });

      const checkedCondition = isShownSelectedGames ? false : null;
      const filteredGames = this.filterGames(games).filter((game) => {
        const gameName = isShownSelectedGames
            ? game.gameName
            : game.friendlyName;

        return (
            gameName.toLowerCase().includes(searchValue.toLowerCase()) &&
            game.checked !== checkedCondition
        );
      });

      const gamesCount = filteredGames.length ?? 0;
      const checkedGamesCount = games.filter(game => game.checked).length;

      for (const game of filteredGames) {
        if (siteGames.some(g => g.id === game.id)) {
          game.checked = true;
        }
      }

      this.setState(prevState => ({
        ...prevState,
        games,
        pageCount: Math.trunc(gamesCount / pageSize + 1),
        sortedGames: [...filteredGames],
        initialGamesIds: new Set(siteGames.map(g => g.id)),
        changedGamesIds: new Set(siteGames.map(g => g.id)),
        gamesCount,
        checkedGamesCount
      }));
    }

    if (this.props.currentFilter !== prevProps.currentFilter) {
      const filteredGames = this.filterGames(this.state.games);
      const gamesCount = filteredGames.length ?? 0;
      const { pageSize } = this.state;

      this.setState({
        pageCount: Math.trunc(gamesCount / pageSize) + (gamesCount % pageSize !== 0 ? 1 : 0),
        activePage: 1,
        sortedGames: [...filteredGames],
        gamesCount: filteredGames.length ?? 0,
      });
    }

    if (!this.props.isDeletingGame && this.props.isDeletingGame !== prevProps.isDeletingGame) {
      this.props.getSiteGames();
      this.props.alert.error(i18n.t('crm.alerts.gameRemoved'));
      if (this.props.deletingError) {
        // Has deleting error
      } else {
        // No deleting error
      }
    }
    if (!this.props.isUpdatingGames && this.props.isUpdatingGames !== prevProps.isUpdatingGames) {
      if (this.props.errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.gameWasNotUpdated'));
      }
      else {
        this.props.alert.success(i18n.t('crm.gamesWereUpdated'));
        this.props.getSiteGames();
        this.setState({isIncreaseGames: [], isDecreaseGames: []});
      }
    }

    if (this.props.errorPurgeCache
        && this.props.errorPurgeCache !== prevProps.errorPurgeCache
    )
    {
      this.props.alert.error(i18n.t('crm.errorPurgeCache'));
    }
  }

  handleGameCheckboxChange = (gameId) => {
    this.setState(prevState => {
      const { changedGamesIds, initialGamesIds, gamesIdsToAdd, gamesIdsToDelete, sortedGames } = prevState;

      let gamesIdsToAddCopy = [...gamesIdsToAdd];
      let gamesIdsToDeleteCopy = [...gamesIdsToDelete];

      const updatedGames = sortedGames.map(game => {
        if (game.id === gameId) {
          const checked = !game.checked;

          if (checked) {
            changedGamesIds.add(gameId);
            if (!gamesIdsToAddCopy.includes(gameId) && !initialGamesIds.has(gameId)) {
              gamesIdsToAddCopy.push(gameId);
            }
            gamesIdsToDeleteCopy = gamesIdsToDeleteCopy.filter(id => id !== gameId);
          } else {
            changedGamesIds.delete(gameId);
            if (!gamesIdsToDeleteCopy.includes(gameId) && initialGamesIds.has(gameId)) {
              gamesIdsToDeleteCopy.push(gameId);
            }
            gamesIdsToAddCopy = gamesIdsToAddCopy.filter(id => id !== gameId);
          }

          return {
            ...game,
            checked,
          };
        }
        return game;
      });

      let hasChanges = changedGamesIds.size !== initialGamesIds.size;
      if (!hasChanges) {
        for (const value of initialGamesIds.values()) {
          if (!changedGamesIds.has(value)) {
            hasChanges = true;
            break;
          }
        }
      }

      return {
        isEdit: hasChanges,
        sortedGames: updatedGames,
        changedGamesIds,
        gamesIdsToDelete: gamesIdsToDeleteCopy,
        gamesIdsToAdd: gamesIdsToAddCopy,
      };
    });
  };



  handleChangePage = (activePage) => this.setState({activePage});

  handleSetPageSize = (pageSize) => {
    const { gamesCount } = this.state;
    this.setState({
      pageCount: Math.trunc(gamesCount / pageSize + 1),
      pageSize,
      activePage: 1,
    });
  }

  handleSendCategoryData = ({
    handleChangeCategoryGames=()=>{},
  }) => {
    this.setState((prevState) => {
      return {
        changeCategoryGamesModalVisibility: false,
        savedCountVisibleGameInCategory: this.state.gamesCount
      };
    });
    handleChangeCategoryGames();
  };

  handleToggleCategoryGamesModalVisibility = () => {
    const {changeCategoryGamesModalVisibility, activePage, games} = this.state;
    this.setState((prevState) => {
      return {
        changeCategoryGamesModalVisibility: !changeCategoryGamesModalVisibility,
        activePage: changeCategoryGamesModalVisibility ? 1 : activePage,
        gamesCount: games?.length,
        modalSearchValue: '',
      };
    });
  };

  handleRemoveGameFromSite = () => {
    const { currentGame } = this.state;
    this.props.removeSiteGame({ gameId: currentGame.id });

    this.setState({
      removeGameModalVisibility: false,
    });
  };

  handleSortGames = (column, direction = DIRECTION.ASC) => {
    const { sortedGames } = this.state;
    const fieldName = column.source || column.id;

    const compareValues = (a, b) => {
      if (a < b) {
        return direction === DIRECTION.ASC ? -1 : 1;
      } else if (a === b) {
        return 0;
      }
      return direction === DIRECTION.ASC ? 1 : -1;
    };

    const sortFunction = (a, b) => {
      if (fieldName !== 'oid') {
        return compareValues(a[fieldName], b[fieldName]);
      } else {
        const valueA = parseFloat(a[fieldName]);
        const valueB = parseFloat(b[fieldName]);
        return compareValues(valueA, valueB);
      }
    };

    sortedGames.sort(sortFunction);
    this.setState({ sortedGames: [...sortedGames] });
  };

  handleChangeSearchValue = (e) => {
    const { games, pageSize, isShownSelectedGames } = this.state;
    const { currentFilter } = this.props;
    const searchValue = e.target.value;

    const filteredGames = currentFilter.length
        ? this.filterGames(games)
        : games;

    const sortedGames = filteredGames.filter((game) =>
        searchValue === '' ||
        (!isShownSelectedGames
            ? game.friendlyName?.toLowerCase().includes(searchValue.toLowerCase())
            : game.gameName?.toLowerCase().includes(searchValue.toLowerCase()))
    );

    this.setState({
      pageCount: Math.trunc(sortedGames.length / pageSize + 1),
      pageSize,
      searchValue,
      sortedGames,
      activePage: 1
    });
  };

  handleChangeModalSearchValue = (e) => {
    const modalSearchValue = e.target.value;
    this.setState({modalSearchValue});
  };

  handleChangeGames = (games) => {
    const checkedGamesCount = games.filter(game => game.checked).length;
    const gamesCount = games.length;

    this.setState(prevState => ({
      ...prevState,
      gamesCount,
      checkedGamesCount,
    }));
  };

  handleUpdateGamesFromFile = (file) => {
    // update games
    this.props.updateGMGames(file);
  }

  handleUploadSiteGames = (file) => {
    // add new games to the site
    this.props.bulkUpdateSiteGames(file);
  }

  handleDownload = () => {
    this.props.downloadGMGames({query: {filterDynamic: this.props.currentFilter}});
  };

  handleShowSelectedGames = () => {
    this.setState(prevState => ({
      ...prevState,
      isShownSelectedGames: !prevState.isShownSelectedGames,
    }));
  }

  increaseOrDecreaseGames = (checked, gameId) => {
    const { isIncreaseGames, isDecreaseGames } = this.state;
    let newIncreaseGames = [...isIncreaseGames];
    let newDecreaseGames = [...isDecreaseGames];

    if (checked) {
      if (!isIncreaseGames.includes(gameId)) {
        newIncreaseGames.push(gameId);
        newDecreaseGames = newDecreaseGames.filter((id) => id !== gameId);
      }
    } else {
      if (!isDecreaseGames.includes(gameId)) {
        newDecreaseGames.push(gameId);
        newIncreaseGames = newIncreaseGames.filter((id) => id !== gameId);
      }
    }

    this.setState(
        {
          isIncreaseGames: newIncreaseGames,
          isDecreaseGames: newDecreaseGames,
        },
        () => {
          this.updateCounts();
        }
    );
  }

  updateCounts = () => {
    const selectedCount = this.state.isIncreaseGames.length;
    const deletedCount = this.state.isDecreaseGames.length;
    this.setState({
      selectedCount,
      deletedCount,
    });
  };

  handleCancelChanges = () => {
    const { games, initialGamesIds } = this.state;
    games.forEach((game) => game.checked = initialGamesIds.has(game.id));

    this.setState({
      isEdit: false,
      isShowConfirmationModal: false,
      gamesIdsToAdd: [],
      gamesIdsToDelete: [],
      changedGamesIds: new Set(initialGamesIds),
      games: [...games],
    })
  }

  handleConfirmChanges = () => {
    const { gamesIdsToDelete, gamesIdsToAdd } = this.state;
    const xSiteId = getStorageXSiteId();

    if (gamesIdsToDelete.length > 0) {
      this.props.removeSiteGames({
        siteId: xSiteId,
        config: {
          data: {
            games: gamesIdsToDelete,
          }
        }
      });
    }

    if (gamesIdsToAdd.length > 0) {
      this.props.putSiteGames({
        games: gamesIdsToAdd,
      });
    }

    this.setState({
      isEdit: false,
      isShowConfirmationModal: false,
    });
    this.props.getSiteAvailableGames();
  }

  handleCheckAll = () => {
    this.setState(prevState => {
      const {
        sortedGames,
        initialGamesIds,
        changedGamesIds,
        checkedAll,
        isShownSelectedGames,
        gamesIdsToAdd,
        gamesIdsToDelete,
      } = prevState;
      const updatedCheckedAll = !checkedAll;

      let newGamesIdsToAdd = [...gamesIdsToAdd];
      let newGamesIdsToDelete = [...gamesIdsToDelete];

      const updatedGames = sortedGames.map(game => {
        if ((isShownSelectedGames && game.checked !== updatedCheckedAll) || !isShownSelectedGames) {
          if (game.checked !== updatedCheckedAll) {
            if (updatedCheckedAll) {
              newGamesIdsToAdd.push(game.id);
              newGamesIdsToDelete = newGamesIdsToDelete.filter(id => id !== game.id);
            } else {
              newGamesIdsToDelete.push(game.id);
              newGamesIdsToAdd = newGamesIdsToAdd.filter(id => id !== game.id);
            }
          }
          game.checked = updatedCheckedAll;
        }
        return game;
      });

      const hasChanges =
          newGamesIdsToAdd.length > 0 || newGamesIdsToDelete.length > 0 || changedGamesIds.size !== initialGamesIds.size;

      return {
        sortedGames: updatedGames,
        checkedAll: updatedCheckedAll,
        changedGamesIds: new Set(updatedCheckedAll ? initialGamesIds : []),
        isEdit: hasChanges,
        gamesIdsToAdd: newGamesIdsToAdd,
        gamesIdsToDelete: newGamesIdsToDelete,
      };
    });
  };

  toggleModalConfirmation = () => {
    this.setState({
      isShowConfirmationModal: !this.state.isShowConfirmationModal,
    })
  };

  downloadTemplateFile = () => {
    this.props.downloadGMTemplate();
  }

  handleToggleWrongGamesModal = () => {
    const { showWrongGamesModal } = this.state;
    if (showWrongGamesModal) {
      this.wrongLocalGames = [];
    }

    this.setState({ showWrongGamesModal: !showWrongGamesModal });
  };

  handleSaveWrongGame = async () => {
    const isSiteGames = 'Priority' in this.wrongLocalGames[0];

    const header = isSiteGames
      ? [
          'ID',
          'OID',
          'Game',
          'Type',
          'Game vendor',
          'Game provider',
          'Added time',
          'RTP',
          'Game ID Mobile',
          'Game ID Desktop',
          'Priority',
          'Emphasize',
          'Bonus factor',
          'Errors',
        ]
        : [
          'id',
          'oid',
          'Game',
          ...Object.keys(AttributionType),
          'Errors',
        ];

    const workbook = xlsx.utils.book_new();
    const workSheet = xlsx.utils.json_to_sheet(this.wrongLocalGames, { header });
    xlsx.utils.book_append_sheet(workbook, workSheet, 'WrongGames');
    xlsx.writeFile(workbook, "WrongGames.xlsx");

    this.wrongLocalGames = [];
    this.handleToggleWrongGamesModal();
  };

  purgeCache = () => {
    this.props.purgeCacheGameCategory();

    this.setState({isPurgeCacheDisabled: true});

    setTimeout(() => {
      this.setState({ isPurgeCacheDisabled: false });
    }, 60 * 1000);
  };

  render() {
    const {
      games,
      sortedGames,
      currentGame,
      activePage,
      pageCount,
      pageSize,
      currentRowHeight,
      removeGameModalVisibility,
      changeCategoryGamesModalVisibility,
      gamesCount,
      modalSearchValue,
      searchValue,
      agentPermissions,
      brandProviders,
      brandVendors,
      isShownSelectedGames,
      isShowConfirmationModal,
      isEdit,
      isFileParsing,
      isShowTableSettings,
      showWrongGamesModal,
      tableColumns,
      isPurgeCacheDisabled,
    } = this.state

    const {
      isDownloading,
      isLoadingGames,
      isLoadingSiteGames,
      isUpdatingGames,
      isDownloadingGMTemplate,
      isBulkUpload,
      gameTypes,
    } = this.props;

    const canEditSiteGames = agentPermissions.includes(SITE_PERMISSION.Game__Edit_Game_Site);
    const canEditGameCategories = agentPermissions.includes(SITE_PERMISSION.Game__Edit_Game_Categories);
    const canPurgeCash = canEditGameCategories || canEditSiteGames;
    const autoHeight = pageSize <= 10;

    return (
      <div className="page-content document-content game-category-content game-category-page game-management-page">
        <MetaTags>
          <title>{i18n.t('content.gameManagement')} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>
        <Container fluid>

          <Row>
            <Col lg="12">
              <Card className="page-card game-management-card">
                <CardBody className="game-category-info game-management-category-info">
                  <div
                      className="page-title-table"
                  >
                    {!!brandVendors?.length && !!brandProviders?.length && !!gameTypes?.length && (
                        <TableFilter
                            filterListOptions={FILTER_MENU_GAME_MANAGEMENT_LIST(brandProviders, brandVendors, gameTypes)}
                            category={FILTER_CATEGORY.GAME_MANAGEMENT}
                        />
                    )}
                  </div>

                  <div
                    className="game-category__header"
                  >
                    <div className="add-category-modal-header">
                      <div
                        className="category-title-text"
                      >
                        {!isShownSelectedGames
                            ? i18n.t('content.allGames')
                            : i18n.t('content.siteGames')
                        }
                      </div>

                      {isLoadingGames || isUpdatingGames || isBulkUpload || isFileParsing ? (
                          <div>
                            <Loader size={'sm'}/>
                          </div>
                      ) : (
                          <div className='ml-10 d-flex align-items-center games-search'>
                            <FormSearch onChangeSearchValue={this.handleChangeSearchValue} showIconSearch/>
                            <button
                                onClick={this.handleShowSelectedGames}
                                className='btn btn-rounded btn-primary ml-10 mr-5'
                                disabled={isEdit}
                                style={{width: '150px'}}
                            >
                              {isShownSelectedGames
                                  ? i18n.t('crm.showAllGames')
                                  : i18n.t('crm.showSelectedGames')
                              }
                            </button>

                            <button
                                className="btn btn-primary ml-10 purge-cache-gm"
                                onClick={this.purgeCache}
                                disabled={isLoadingGames || !canPurgeCash || isPurgeCacheDisabled}
                            >
                              <span>{i18n.t('crm.purgeCache')}</span>
                              <img style={{margin: '0 0 0 5px'}} src={purgeCache} alt=""/>
                            </button>
                          </div>
                      )}
                    </div>

                    <div className='d-flex align-items-center'>
                      <PermissionWrapper
                          accessPermissions={[SITE_PERMISSION.Game__View_Game_Site]}
                          withPermissionError={false}
                      >
                        <div className='d-flex align-items-center justify-content-end mt-2 me-3 download-button'>
                          <div className={clsx('d-flex', {['d-none']: !canEditSiteGames})}>
                            <div>
                              <Tooltip title={i18n.t('content.GameManagementFileTemplate')} arrow>
                                <button
                                    className='btn download-gamesDB-template'
                                    onClick={this.downloadTemplateFile}
                                    disabled={isDownloadingGMTemplate}
                                >
                                  <img src={gamesDBTemplateIcon} alt="Download a template"/>
                                </button>
                              </Tooltip>
                            </div>
                            <div className='mr-5 add-games-button'>
                              <DragFileInput
                                  accept={XLSXformat}
                                  typeFile={'XLSX'}
                                  onSelect={this.handleUploadSiteGames}
                                  withLabel={false}
                                  tooltipText={'uploadGamesToSite'}
                                  addFileMode={true}
                                  imgMode
                              />
                            </div>
                          </div>

                          <button
                              className={clsx("mr-5 btn btn-rounded btn-primary app-btn-only-img-sm", {
                                ['d-none']: !sortedGames?.length,
                              })}
                              onClick={this.handleDownload}
                              disabled={isDownloading}
                          >
                            <Tooltip title={i18n.t('content.downloadListOfGames')} arrow>
                              <img src={download} alt=""/>
                            </Tooltip>
                          </button>

                          <div className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm ml-5 mr-5', {
                            ['d-none']: !canEditSiteGames
                          })}>
                            <DragFileInput
                                accept={XLSXformat}
                                typeFile={'XLSX'}
                                onSelect={this.handleUpdateGamesFromFile}
                                withLabel={false}
                                tooltipText={'uploadFileGameManagement'}
                                imgMode
                            />
                          </div>

                          <button
                              className="btn settings-button game-management m-0"
                              onClick={() => this.setState({isShowTableSettings: true})}
                          >
                            <Tooltip title={i18n.t('crm.settings')} arrow>
                              <img src={settings} alt="Table's Settings"/>
                            </Tooltip>
                          </button>
                        </div>
                      </PermissionWrapper>
                    </div>
                  </div>
                  {isLoadingGames || isUpdatingGames || isLoadingSiteGames ? (
                      <div className='empty-table-block'>
                        <Loader size={'lg'}/>
                      </div>
                  ) : (
                      <>
                        {!isLoadingGames && sortedGames?.length > 0 && (
                            <>
                            <div
                                  className={clsx("game-table-wrapper", {
                                    'autoTableHeight': autoHeight,
                                  })}
                                  ref={ref => this.boxRef = ref}
                              >
                                <GameTable
                                    columns={tableColumns.length > 0 ? tableColumns : getColumns(this)}
                                    games={sortedGames}
                                    activePage={activePage}
                                    pageSize={pageSize}
                                    currentRowHeight={currentRowHeight}
                                    handleGameCheckboxChange={this.handleGameCheckboxChange}
                                    onCheckAll={this.handleCheckAll}
                                    increaseOrDecreaseGames={this.increaseOrDecreaseGames}
                                    canEditByPermission={canEditSiteGames}
                                    onChangeGames={() => {
                                      this.handleChangeGames(sortedGames)
                                    }}
                                    onSortGames={this.handleSortGames}
                                    modalMode={false}
                                    isGamePriorityUpdateModal={true}
                                    isShownSelectedGames={isShownSelectedGames}
                                    isShowTableSettings={isShowTableSettings}
                                    onCloseTableSettings={() => this.setState({isShowTableSettings: false})}
                                />
                              </div>
                              <SelectTableView
                                  recordsCount={gamesCount}
                                  pageSize={pageSize}
                                  activePage={activePage}
                                  pageCount={pageCount}
                                  onChange={this.handleSetPageSize}
                                  onChangePage={this.handleChangePage}
                              />
                            </>
                        )}
                      </>
                  )}
                  {!sortedGames.length && !isLoadingGames && !isLoadingSiteGames && (
                      <div className='no-data-game-table'>
                        {i18n.t('crm.emptyTable')}
                      </div>
                  )}
                  {isEdit && !isShowConfirmationModal &&
                      <ButtonsPanel
                          className='game-management-btns'
                          onCancel={this.handleCancelChanges}
                          onSave={this.toggleModalConfirmation}
                      />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isShowConfirmationModal &&
            <GamesConfirmationModal
                games={games}
                isShownSelectedGames={isShownSelectedGames}
                gamesToAdd={this.state.gamesIdsToAdd}
                gamesToDelete={this.state.gamesIdsToDelete}
                onSave={this.handleConfirmChanges}
                onCancel={this.toggleModalConfirmation}
            />
        }

        {changeCategoryGamesModalVisibility &&
            <TableBigModal
                onToggle={this.handleToggleCategoryGamesModalVisibility}
                renderHeader={({
                                 handleChangeCategoryGames = () => {
                                 }
                               }) => (
                    <CategoryGamesModalHeader
                        onSendCategoryData={(params) => this.handleSendCategoryData({
                          ...params,
                          handleChangeCategoryGames,
                        })}
                        isIncreaseGames={this.state.isIncreaseGames}
                        isDecreaseGames={this.state.isDecreaseGames}
                        onChangeSearchValue={this.handleChangeModalSearchValue}
                        gamesCount={gamesCount}
                        siteMode={true}
                    />
                )}
                tableTitleText={i18n.t("content.chooseGames")}
                isOpen={changeCategoryGamesModalVisibility}
                columns={modalTableColumns.slice(0, -1)}
                modalSearchValue={modalSearchValue}
                onChangeGames={this.handleChangeGames}
                siteMode={true}
                categoryGames={games.map(game => game.id)}
                increaseOrDecreaseGames={this.increaseOrDecreaseGames}
          />
        }

        {removeGameModalVisibility &&
          <CustomModal
            titleText={i18n.t('content.removeGame')}
            isOpen={removeGameModalVisibility}
            onToggle={() => this.setState({
              removeGameModalVisibility: !removeGameModalVisibility,
            })}
            onClick={this.handleRemoveGameFromSite}
            onClickCancelButton={() => this.setState({
              removeGameModalVisibility: false,
            })}
            btnText={'remove'}
            cancelBtnText={'decline'}
            withCancelButton={true}
            bodyRender={() => (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {`${i18n.t('content.sure.want.remove')}
                 '${currentGame.gameName || currentGame.friendlyName}' ${i18n.t('content.from.the.site')}`
                }
              </div>
            )}
          />
        }

        {showWrongGamesModal &&
          <WrongGamesModal
              onConfirm={this.handleSaveWrongGame}
              onHide={this.handleToggleWrongGamesModal}
              wrongLocalGames={this.wrongLocalGames}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { agentSelf } = state.AgentSelf;
  const { active: activeBrand } = state.Brand;
  const {
    siteGames,
    gameProviders,
    gameVendors,
    gameTypes,
    availableSiteGames,
    isDownloading,
    isLoadingGames,
    isUpdatingGames,
    errorGames,
    isDeletingGame,
    isDeletingGames,
    deletingError,
    isLoadingCategory,
    isLoadingSiteGames,
    isDownloadingGMTemplate,
    isBulkUpload,
    errorPurgeCache,
  } = state.Games;
  const {
    currentFilter,
  } = state.Filter;
  return {
    activeBrand,
    siteGames,
    gameProviders,
    gameVendors,
    availableSiteGames,
    isDownloading,
    isLoadingGames,
    isLoadingSiteGames,
    isUpdatingGames,
    errorGames,
    isDeletingGame,
    isDeletingGames,
    deletingError,
    isLoadingCategory,
    isBulkUpload,
    agentSelf,
    currentFilter,
    gameTypes,
    isDownloadingGMTemplate,
    errorPurgeCache,
  };
};

export default React.memo(
  withRouter(
    connect(mapStateToProps, {
      getSiteGames,
      getSiteProviders,
      getGameVendors,
      getGameTypes,
      getSiteAvailableGames,
      bulkUpdateSiteGames,
      bulkUpdateSiteGameAttributions,
      putSiteGames,
      removeSiteGame,
      removeSiteGames,
      downloadSiteGameList,
      downloadAvailableSiteGameList,
      downloadSiteGameAttributions,
      downloadGMTemplate,
      downloadGMGames,
      updateGMGames,
      purgeCacheGameCategory,
    })(withAlert()(GameManagement))
  )
);
