import {
    GET_NOTIFICATIONS_LIST,
    GET_NOTIFICATIONS_LIST_SUCCESS
} from './actionTypes';
import i18n from '../../i18n';

const INITIAL_STATE = {
    isLoading: false,
    list: [],
    currentNotification: [],
    error: {},
    sidebarValue: { title: i18n.t('notification.notification'), id: '' },
    successMessage: '',
    query: {
        page: 1,
        pageSize: 100
    }
};

const Notification = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_LIST:
            return { ...state, isLoading: true };
        case GET_NOTIFICATIONS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: action.payload
            };
        default:
            return state;
    }
};

export default Notification;
