import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAgentSelf, getInfo } from '../../store/actions';

const AGENT_SELF_TIME_INTERVAL = 40 * 1000;
const INFO_COUNT_TIME_INTERVAL = 60 * 1000;

export const SchedulerWrapper = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const agentInterval = setInterval(() => {
            dispatch(getAgentSelf());
        }, AGENT_SELF_TIME_INTERVAL);

        return () => clearInterval(agentInterval);
    }, []);

    useEffect(() => {
        const countInfoInterval = setInterval(() => {
            dispatch(getInfo());
        }, INFO_COUNT_TIME_INTERVAL);

        return () => clearInterval(countInfoInterval);
    }, []);

    return children;
};
