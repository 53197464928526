import React, { useCallback, useEffect, useRef, useState } from "react"
import {useDispatch, useSelector} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import clsx from "clsx"

import { useTranslation } from "react-i18next";

import './roles.scss';
import { CardBody, Col, Row } from 'reactstrap';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { getSync, patch, patchSync, postSync } from "../../../helpers/api_helper"
import ButtonsPanel from '../site/common/ButtonsPanel';
import useAlertService from '../../../hooks/useAlertService';
import plus_icon from '../../../assets/images/common/plus icon.svg';
import CustomList from './_elements/CustomList';
import ConfirmationModal from "../../../common/prompt/ConfirmationModal";
import { urlGetAllPermissions, urlGetRoles } from '../../../helpers/rolesApi';
import { getAgentSelf } from "../../../store/agents/self/actions";
import { RouterPrompt } from "../../../common/prompt/RouterPrompt";
import { SITE_PERMISSION } from "../../../common/constants/common";
import AdditionalRolesFields from "./components/AdditionalRolesFields"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '15px',
        paddingBottom: '40px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    roleIdTitle: {
        fontSize: '15px',
        paddingLeft: '30px',
        fontWeight: '500',
    },
    roleId: {
        fontSize: '15px',
        paddingLeft: '15px',
        fontWeight: 'normal',
        color: '#495057',
        lineHeight: '22px'
    },
    displayFlexAlignSelfEnd: {
        display: 'flex',
        alignItems: 'self-end',
        paddingBottom: '35px',
    },
}));

function RolesCard({}) {
    const { i18n } = useTranslation();
    const classes = useStyles();
    const alertService = useAlertService();
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});
    const [historyRole, setHistoryRole] = useState([]);
    const [permissionsRole, setPermissionsRole] = useState([]);
    const [allPermissions, setAllPermissions] = useState({});
    const [savedAllPermissions, setSavedAllPermissions] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [roleLabel, setRoleLabel] = useState(false);
    const [addButton, setAddButton] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [isShowAdditionalFields, setIsShowAdditionalFields] = useState(false);

    const [visibilityFields, setVisibilityFields] = useState({
        otherAgents: [''],
        affiliateIds: [''],
        selfAgentOnly: false,
    });
    const initialVisibilityFieldsRef = useRef(visibilityFields);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];
    const canEditRoles = agentPermissions.includes(SITE_PERMISSION.IT__Set_Roles);
    const canAddRole = agentPermissions.includes(SITE_PERMISSION.IT__Add_Role);

    const urlRoleForCreation = `/it-manager/role-permissions?roleId=${selectedRole?.id}`;
    const urlRolePermissions = `/it-manager/role-permissions-visibilities?roleId=${selectedRole?.id}`;
    const urlPatchPermissions = `/it-manager/role/${selectedRole?.id}/permissions`;
    const urlPatchVisibilityRoles = `/it-manager/role/${selectedRole?.id}/visibility`;

    const toggleAdditionalFields = () => {
        setIsShowAdditionalFields(!isShowAdditionalFields);
    }

    useEffect(async () => {
        const result = await getSync(urlGetAllPermissions, {});
        const permissionsToRemove = [SITE_PERMISSION.Retail__MX_Finance_A, SITE_PERMISSION.Retail__MX_Tax];
        const filteredPermissions = result.data.Retail.filter(item => !permissionsToRemove.includes(item));

        setAllPermissions({
            ...result.data,
            Retail: filteredPermissions
        });
        setSavedAllPermissions(result.data);
    }, []);


    useEffect(async () => {
        const result = await getSync(urlGetRoles, {});
        setList(result.data);
        if (result.data.length > 0) {
            setHistoryRole(result.data[0]);
            setSelectedRole(result.data[0]);
        }
    }, []);

    useEffect(async () => {
        if (selectedRole?.id) {
            const result = await getSync(urlRolePermissions, {});
            setPermissionsRole(result?.data?.permissions);
            setVisibilityFields(result?.data?.visibility);
            initialVisibilityFieldsRef.current = result?.data?.visibility;
            setIsEdit(false);
        }
    }, [selectedRole]);

    useEffect(() => {
        if (permissionsRole.length) {
            setChecked(permissionsRole);
        } else {
            setIsEdit(false);
            setChecked([]);
        }
    }, [permissionsRole]);

    useEffect(() => {
        if (checked?.join('') !== permissionsRole?.join('')) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [checked, permissionsRole])

    useEffect(() => {
        if (!_.isEqual(visibilityFields, initialVisibilityFieldsRef.current)) {
            setIsEdit(true);
        }
    }, [visibilityFields]);


    const modalText = () => {
        return i18n.t('crm.alert.rolesWarning')
    }

    useEffect(() => {
        if (addButton && selectedRole.id === '') {
            setSelectedRole(historyRole[0]);
        }
        if (selectedRole.id === '') {
            setIsEdit(true)
        }
    }, [addButton, selectedRole])

    const addRole = async () => {
        setIsEdit(true);
        setRoleLabel(true);
        setAddButton(false);
        setSelectedRole({});
        setVisibilityFields({
            affiliateIds: [''],
            otherAgents: [''],
            selfAgentOnly: false,
        });

        if (selectedRole.id === '') {
            try {
                const result = await postSync(urlRoleForCreation, {
                    name: selectedRole.name,
                    permissions: checked,
                });
                if (result) {
                    const newRole = {
                        id: result.id,
                        name: result.name
                    };
                    setSavedAllPermissions([])
                    setSelectedRole(newRole);
                    list.push(newRole);
                    setList(list);
                }
            }
            catch (err) {
                if (err.statusCode === 403 && err.message === 'roles.error.uniqueName') {
                    alertService.showError(i18n.t(err.message));
                }
                else {
                    throw err;
                }
            }
        }

        if (!selectedRole?.name) {
            setIsEdit(true)
        } else {
            setSelectedRole({
                id: '',
                name: ''
            });
            setChecked([]);
            setSavedAllPermissions([]);
            setAllPermissions(allPermissions);
        }
    };

    const handleSave = async () => {
        if (selectedRole?.name?.length === 0) {
            alertService.showError(i18n.t('roles.roleNameIsEmpty'));
            return;
        }

        // not edit mode
        if (!roleNameValidation(selectedRole.name) && !addButton) {
            alertService.showError(i18n.t('roles.error.uniqueName'));
            return;
        }

        try {
            if (selectedRole.id === '') {
                const result = await postSync(urlRoleForCreation, {
                    name: selectedRole.name,
                    permissions: checked
                });

                dispatch(getAgentSelf());

                if (result) {
                    const newRole = {
                        id: result.id,
                        name: result.name
                    };
                    setSavedAllPermissions([]);
                    list.push(newRole);
                    setList(list);
                    setSelectedRole(newRole);
                    alertService.showSuccess(i18n.t('roles.RoleCreated'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 500)
                }
            } else {
                const result = await patchSync(alertService, urlPatchPermissions, {
                    permissions: checked
                });

                dispatch(getAgentSelf());

                if (result) {
                    const updatedPermissions = await getSync(urlRolePermissions, {});
                    setPermissionsRole(updatedPermissions.data?.permissions);
                    alertService.showSuccess(i18n.t('roles.RoleUpdated', {
                        role: selectedRole.name,
                        quantity: checked.length
                    }));

                    const updatedWithVisibilityFields = await patch(urlPatchVisibilityRoles,
                        visibilityFields
                    );
                    setIsEdit(false);
                } else {
                    alertService.showError(i18n.t('roles.roleFieldIsEmpty'));
                }
            }
            setIsEdit(false)
        }
        catch (err) {
            if (err.statusCode === 403 && err.message === 'roles.error.uniqueName') {
                alertService.showError(i18n.t(err.message));
            }
            else {
                throw err;
            }
        }
    };

    const handleCancel = () =>  {
        setChecked(permissionsRole);
        setIsEdit(false);
        setAddButton(true);
        setSelectedRole(historyRole);
    }

    const handleCancelPrompt = () => {
        setShowModal(false);
        setIsEdit(true);
    }

    const handleChange = useCallback((selected) => {
        const element = list.find(e => e.id === selected) || historyRole;
        setHistoryRole(element);

        if (isEdit && (historyRole.id !== element.id)) {
            setShowModal(true);
        } else {
            setSelectedRole(element);
            setShowModal(false);
        }
    }, [list, isEdit, historyRole.id])


    const handleConfirmPrompt = () => {
        setSelectedRole(historyRole);
        setIsEdit(false);
        setShowModal(false);
    }

    const roleNameValidation = (name) => {
        const roleNameExists = list.some((e) =>
            e.name.toLowerCase() === name.toLowerCase()
        );

        return !roleNameExists;
    }

    const copyRole = () => {
        if (selectedRole && selectedRole.id) {
            const newRole = {
                ...selectedRole,
                id: '',
                name: '',
            };
            setSelectedRole(newRole);
            setChecked(permissionsRole);
            setIsEdit(true);
            setRoleLabel(true);
            setAddButton(false);
            setIsShowAdditionalFields(false);
        }
    };

    const filteredPlayerViewItems = allPermissions?.PlayerView?.filter(item => item !== SITE_PERMISSION.Player__Edit_Referral_Info);


    return (
        <div className="page-content roles-page">
            <MetaTags>
                <title>{i18n.t('roles.title.roles')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card className="site-main-card">
                            <CardBody style={{padding: '0 10px'}}>
                                <Row className="d-flex justify-content-between site-main-card__header">
                                    <Col
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            top: '9px',
                                            right: '10px',
                                        }}
                                    >
                                        {roleLabel &&
                                            <span className="label-new-role">{i18n.t('roles.newRole')}</span>
                                        }
                                        {canAddRole && addButton &&
                                          <>
                                              <button
                                                onClick={copyRole}
                                                className="btn btn-primary mr-10"
                                              >
                                                  {i18n.t('roles.copyRole')}
                                              </button>
                                              <button
                                                onClick={toggleAdditionalFields}
                                                className="btn btn-primary mr-10"
                                              >
                                                  {i18n.t(`roles.${isShowAdditionalFields ? "hide" : "show"}AdditionalFields`)}
                                              </button>
                                              <button
                                                onClick={addRole}
                                                className="btn btn-rounded btn-primary app-btn-only-img"
                                              >
                                                  <img src={plus_icon} alt="" />
                                              </button>
                                          </>
                                        }
                                    </Col>
                                </Row>
                                <hr />
                                {selectedRole && (
                                  <div>
                                      <div className={clsx(classes.displayFlexAlignSelfEnd, "roles-header")}>
                                      {selectedRole.id !== "" && (
                                            <ACommonDataField
                                              component={
                                                  A_COMMON_DATA_FIELD.SELECT_DEFAULT
                                              }
                                              hasClear={false}
                                                    label={`Role`}
                                                    value={selectedRole.id}
                                                    options={list.map(r => {
                                                        return {
                                                            content: r.name,
                                                            value: r.id
                                                        };
                                                    })}
                                                    handleChange={handleChange}
                                                />
                                            )}
                                            {selectedRole.id === '' && (
                                                <ACommonDataField
                                                    label={`Role`}
                                                    value={selectedRole.name}
                                                    handleChange={change => {
                                                        setSelectedRole({
                                                            id: selectedRole.id,
                                                            name: change
                                                        });
                                                    }}
                                                />
                                            )}
                                            <p className={clsx(classes.roleIdTitle, 'roleIdTitle')}>
                                                Role ID:
                                                <span className={classes.roleId}>{selectedRole.id}</span>
                                            </p>
                                        </div>

                                      {isShowAdditionalFields &&
                                        <AdditionalRolesFields
                                            visibilityFields={visibilityFields}
                                            setVisibilityFields={setVisibilityFields}
                                            setIsEdit={setIsEdit}
                                        />
                                      }

                                        {Object.keys(allPermissions).length &&
                                            <div className='roles-grid__wrapper'>
                                                    <span className='total-checked'>
                                                    {`${checked.length} ${i18n.t('roles.totalChecked')}`}
                                                    </span>
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    justifyContent="center"
                                                    className={classes.root}
                                                >
                                                    <Grid item>
                                                    <span className='permissions-label'>
                                                        {i18n.t('crm.roles.playerView')}
                                                    </span>
                                                        <CustomList
                                                            items={filteredPlayerViewItems}
                                                            itemsArrNames={filteredPlayerViewItems.map(item => i18n.t(`permissions.${item}`))}
                                                            checked={checked}
                                                            setChecked={setChecked}
                                                            disabled={!canEditRoles}
                                                        />

                                                        <span className='permissions-label'>
                                                        {i18n.t('crm.roles.agent')}
                                                    </span>
                                                        <CustomList
                                                            items={allPermissions.Agent}
                                                            itemsArrNames={allPermissions.Agent.map(item => i18n.t(`permissions.${item}`))}
                                                            checked={checked}
                                                            setChecked={setChecked}
                                                            disabled={!canEditRoles}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                    <span className='permissions-label'>
                                                        {i18n.t('crm.roles.general')}
                                                    </span>
                                                        <CustomList
                                                            items={allPermissions.General}
                                                            itemsArrNames={allPermissions.General.map(item => i18n.t(`permissions.${item}`))}
                                                            checked={checked}
                                                            setChecked={setChecked}
                                                            disabled={!canEditRoles}
                                                        />

                                                        <span className='permissions-label'>
                                                        {i18n.t('crm.roles.games')}
                                                    </span>
                                                        <CustomList
                                                            items={allPermissions.Game}
                                                            itemsArrNames={allPermissions.Game.map(item => i18n.t(`permissions.${item}`))}
                                                            checked={checked}
                                                            setChecked={setChecked}
                                                            disabled={!canEditRoles}
                                                        />

                                                        <span className='permissions-label'>
                                                        {i18n.t('crm.roles.finance')}
                                                    </span>
                                                        <CustomList
                                                            items={allPermissions.Finance}
                                                            itemsArrNames={allPermissions.Finance.map(item => i18n.t(`permissions.${item}`))}
                                                            checked={checked}
                                                            setChecked={setChecked}
                                                            disabled={!canEditRoles}
                                                        />

                                                        <div>
                                                        <span className='permissions-label'>
                                                        {i18n.t('crm.roles.retail')}
                                                        </span>
                                                            <CustomList
                                                                items={allPermissions.Retail}
                                                                itemsArrNames={allPermissions.Retail.map(item => i18n.t(`permissions.${item}`))}
                                                                checked={checked}
                                                                setChecked={setChecked}
                                                                disabled={!canEditRoles}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    {isEdit && (
                                                        <ButtonsPanel
                                                            onCancel={handleCancel}
                                                            onSave={handleSave}
                                                        />
                                                    )}
                                                    {showModal && (
                                                        <ConfirmationModal
                                                            onConfirm={handleConfirmPrompt}
                                                            text={modalText()}
                                                            onCancel={handleCancelPrompt}
                                                        />
                                                    )}
                                                    {isEdit && (
                                                        <RouterPrompt
                                                            when={isEdit}
                                                            onOK={() => true}
                                                            onCancel={() => false}
                                                        />
                                                    )}
                                                </Grid>
                                            </div>
                                        }
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

RolesCard.propTypes = {};

export default React.memo(RolesCard);
