import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideLoadingScreen } from '../../store/auth/login/actions';

import '../../assets/scss/pages/login.scss';

const LoadingScreen = () => {
    const { loadingPercent, isLoadingScreen } = useSelector(
        state => state.Login
    );
    const dispatch = useDispatch();
    const [isStartAnimate, setStartAnimate] = useState(false);
    const [isHidePageAnimate, setHidePageAnimate] = useState(false);

    useEffect(() => {
        // so that the animation has time to play
        if (isLoadingScreen) {
            setTimeout(() => setStartAnimate(true), 0);
        }
    }, [isLoadingScreen]);

    useEffect(() => {
        if (loadingPercent === 100) {
            setHidePageAnimate(true);
            // so that the animation has time to play
            setTimeout(() => dispatch(hideLoadingScreen()), 500);
        }
    }, [loadingPercent]);

    return (
        <div className={`loading-screen wiztech-bg wiztech-bg__showed`}>
            <div
                className={`loading-screen__container${
                    isStartAnimate ? ' loading-screen__container__showed' : ''
                }${
                    isHidePageAnimate ? ' loading-screen__container__hided' : ''
                }`}
            >
                <div className={`loading-screen__logo`} />
                <div className={`loading-screen__indicator`}>
                    <div
                        className={'loading-screen__indicator-loading'}
                        style={{ width: loadingPercent * 2 }}
                    />
                </div>
            </div>

            <footer className={'loading-screen__footer'}>
                © {new Date().getFullYear()} {process.env.REACT_APP_APP_NAME}
            </footer>
        </div>
    );
};

export default React.memo(LoadingScreen);
