import {
  GET_PROMOTIONS,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAIL,
  GET_PROMOTION_TEMPLATES,
  GET_PROMOTION_TEMPLATES_SUCCESS,
  GET_PROMOTION_TEMPLATES_FAIL,
  GET_BONUS_WAGERING_SMALL_DATA,
  GET_BONUS_WAGERING_SMALL_DATA_SUCCESS,
  GET_BONUS_WAGERING_SMALL_DATA_FAIL,
  CLEAR_CURRENT_PROMOTION,
  GET_PROMOTION,
  GET_PROMOTION_SUCCESS,
  GET_PROMOTION_FAIL,
  UPDATE_PROMOTION,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAIL,
  CREATE_PROMOTION,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAIL,
  RESET_PROMOTION_ALERTS_ERRORS,
  GET_SPORT_PROMOTIONS,
  GET_SPORT_PROMOTIONS_SUCCESS,
  GET_SPORT_PROMOTIONS_FAIL,
  GET_SPORT_PROMOTION,
  GET_SPORT_PROMOTION_SUCCESS,
  GET_SPORT_PROMOTION_FAIL,
  CREATE_SPORT_PROMOTION,
  CREATE_SPORT_PROMOTION_SUCCESS,
  CREATE_SPORT_PROMOTION_FAIL,
  UPDATE_SPORT_PROMOTION,
  UPDATE_SPORT_PROMOTION_SUCCESS,
  UPDATE_SPORT_PROMOTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  // common
  isLoading: false,
  isUpdating: false,
  isCreating: false,

  // list
  activePage: 0,
  promotions: null,
  promotionTemplates: null,
  bonusWageringSmallData: null,
  error: null,

  // detailed
  promotion: null,
}

const Promotions = (state = INIT_STATE, action) => {
  switch (action.type) {
    // list
    case GET_PROMOTIONS:
      return { ...state, activePage: action.payload, isLoading: true }
    case GET_PROMOTIONS_SUCCESS:
      return { ...state, promotions: action.payload, isLoading: false }
    case GET_PROMOTIONS_FAIL:
      return { ...state, error: action.payload, isLoading: false }
    // templates
    case GET_PROMOTION_TEMPLATES:
      return { ...state, activePage: action.payload, isLoading: true }
    case GET_PROMOTION_TEMPLATES_SUCCESS:
      return { ...state, promotionTemplates: action.payload, isLoading: false }
    case GET_PROMOTION_TEMPLATES_FAIL:
      return { ...state, error: action.payload, isLoading: false }
    // bonusWageringSmallData
    case GET_BONUS_WAGERING_SMALL_DATA:
      return { ...state, isLoading: true }
    case GET_BONUS_WAGERING_SMALL_DATA_SUCCESS:
      return { ...state, bonusWageringSmallData: action.payload, isLoading: false }
    case GET_BONUS_WAGERING_SMALL_DATA_FAIL:
      return { ...state, error: action.payload, isLoading: false }
    // detailed
    case CLEAR_CURRENT_PROMOTION:
      return { ...state, promotion: null, }
    case GET_PROMOTION:
      return { ...state, isLoading: true }
    case GET_PROMOTION_SUCCESS:
      return { ...state, promotion: action.payload, isLoading: false }
    case GET_PROMOTION_FAIL:
      return { ...state, error: action.payload, isLoading: false }
    // update
    case UPDATE_PROMOTION:
      return { ...state, isUpdating: true }
    case UPDATE_PROMOTION_SUCCESS:
      return { ...state, isUpdating: false }
    case UPDATE_PROMOTION_FAIL:
      return { ...state, error: action.payload, isUpdating: false }
    // create
    case CREATE_PROMOTION:
      return { ...state, isCreating: true }
    case CREATE_PROMOTION_SUCCESS:
      return { ...state, isCreating: false }
    case CREATE_PROMOTION_FAIL:
      return { ...state, error: action.payload, isCreating: false }

      // sport-promotions-list
    case GET_SPORT_PROMOTIONS:
      return { ...state, activePage: action.payload, isLoading: true }
    case GET_SPORT_PROMOTIONS_SUCCESS:
      return { ...state, promotions: action.payload, isLoading: false }
    case GET_SPORT_PROMOTIONS_FAIL:
      return { ...state, error: action.payload, isLoading: false }
      // detailed sport-promotion
    case GET_SPORT_PROMOTION:
      return { ...state, isLoading: true }
    case GET_SPORT_PROMOTION_SUCCESS:
      return { ...state, promotion: action.payload, isLoading: false }
    case GET_SPORT_PROMOTION_FAIL:
      return { ...state, error: action.payload, isLoading: false }
      // create sport promotion
    case CREATE_SPORT_PROMOTION:
      return { ...state, isCreating: true }
    case CREATE_SPORT_PROMOTION_SUCCESS:
      return { ...state, isCreating: false }
    case CREATE_SPORT_PROMOTION_FAIL:
      return { ...state, error: action.payload, isCreating: false }
      // update sport promotion
    case UPDATE_SPORT_PROMOTION:
      return { ...state, isUpdating: true }
    case UPDATE_SPORT_PROMOTION_SUCCESS:
      return { ...state, isUpdating: false }
    case UPDATE_SPORT_PROMOTION_FAIL:
      return { ...state, error: action.payload, isUpdating: false }

    case RESET_PROMOTION_ALERTS_ERRORS:
      return { ...state, error: null };

    default:
      return state
  }
}

export default Promotions
