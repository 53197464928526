import React from 'react';
import smallClose from '../../assets/images/layout/small-close.svg';
import {DataTypes} from "./utils";

export default function Badge({ value, backgroundColor, setShowSelect = () => ({}) }) {
  return (
      <div>
          <span
              className="font-weight-400 d-inline-block color-grey-800 border-radius-sm text-transform-capitalize"
              style={{
                  backgroundColor: backgroundColor,
                  padding: '2px 6px',
              }}
              onClick={() => setShowSelect(true)}
          >
              {value}
          </span>
      </div>
  );
}
