import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import i18n from '../../i18n';

import "./styles.scss";
import {useParams, withRouter} from "react-router-dom";
import HeaderPlayerInfo from "../../UI/headerPlayerInfo/HeaderPlayerInfo";
import useAlertService from "../../hooks/useAlertService";
import {useDispatch, useSelector} from "react-redux";
import {
    getRetailPlayer,
    resetPlayerErrors,
    resetPlayerState
} from "../../store/players/general/actions";
import Loader from "../../common/components/Loader";
import GeneralView from "./components/GeneralView";

const RetailCustomerView = () => {
    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const { retailPlayer, error } = useSelector(
        state => state.PlayerGeneral
    );

    useEffect(() => {
        if (params.id) {
            dispatch(getRetailPlayer(params.id));
        }

        return () => dispatch(resetPlayerState());
    }, [params.id]);

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetPlayerErrors());
        }
    }, [error]);

  return (
    <div className="player-list document-content page-content retail-view">
        <MetaTags>
            <title>{i18n.t("retail.title")} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>

        <div className="container-fluid">
            {retailPlayer
                ? (
                    <>
                        <HeaderPlayerInfo
                            playerGeneral={retailPlayer}
                            showOid={true}
                        />
                        <GeneralView />
                    </>
                )
                : <Loader/>
            }
        </div>
    </div>
  )
};

export default withRouter(RetailCustomerView);
