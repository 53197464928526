import { takeEvery, call, put } from '@redux-saga/core/effects';
import {
    notificationPageError,
    getNotificationsListPageSuccess
} from './action';
import { GET_NOTIFICATIONS_LIST_PAGE } from './actionTypes';
import { getNotificationsListApi } from '../../../helpers/notificationApi';

// TODO fix when ip is ready
export function* getNotificationsPageListSaga({ payload: query }) {
    try {
        const response = yield call(getNotificationsListApi, query);

        if (response.status === 200) {
            yield put(getNotificationsListPageSuccess(response.data));
        } else {
            yield put(notificationPageError({ message: response?.message }));
        }
    } catch (error) {
        yield put(notificationPageError({ message: error }));
    }
}

function* notificationPageSaga() {
    yield takeEvery(GET_NOTIFICATIONS_LIST_PAGE, getNotificationsPageListSaga);
}

export default notificationPageSaga;
