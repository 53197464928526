import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InfoBlockHeader from './InfoBlockHeader';
import { Card } from 'reactstrap';
import i18n from '../../../../i18n';
import srcIcon from '../../../../assets/images/common/Status.svg';
import AppPieChart from '../../../../components/charts/AppPieChart';
import DataLegend from '../../../../components/charts/DataLegend';

const COLORS = [
    '#FFEBC7',
    '#9EE5DB',
    '#CCD2E5',
    '#FFCC66',
    '#3DCCB8',
    '#667CCC',
    '#CC9F4B',
    '#299989',
    '#4E6099'
];

const ClientStatusesWidget = () => {
    const data = {
        new: 13,
        potential: 5,
        rechable: 3,
        renew: 16,
        highPotential: 40,
        wrongNumber: 11,
        noAnswer: 17,
        appointment: 26,
        disqualified: 10
    };

    const chartData = useMemo(() => {
        if (!data) return null;

        return Object.keys(data).map((key, index) => ({
            name: i18n.t(`admin.dataKey.${key}`),
            value: data[key],
            color: COLORS[index]
        }));
    }, [data]);

    return (
        <Card className="statistic-widget client-statuses-widget">
            <InfoBlockHeader
                title={i18n.t('admin.clientStatuses')}
                srcIcon={srcIcon}
            />

            <div className="client-statuses-widget__content">
                <AppPieChart
                    chartData={chartData}
                    width={216}
                    chartWidth={40}
                />

                <DataLegend data={chartData} />
            </div>
        </Card>
    );
};

ClientStatusesWidget.propTypes = {
    data: PropTypes.object.isRequired
};

export default React.memo(ClientStatusesWidget);
