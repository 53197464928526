import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { Card, CardBody } from 'reactstrap';
import GeneralTabContent from './GeneralTabContent';
import CommunicationTabContent from './CommunicationTabContent';
import DocumentsTabContent from './DocumentsTabContent';
import VerificationTabContent from './VerificationTabContent';
import AuditTabContent from './AuditTabContent';
import PromotionsTabContent from './PromotionsTabContent';
import GamePlayTabContent from './GamePlayTabContent';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CustomPromptModal from '../../../common/prompt/CustomPromptModal';
import { RouterPrompt } from '../../../common/prompt/RouterPrompt';
import { PLAYER_VIEW_DETAILED_TAB_TYPE } from './common/playerViewConstants';
import SwipeAnimation from 'common/components/SwipeAnimation/SwipeAnimation';
import DetailedCardTabs from './DetailedCardTabs';
import RestrictionsTabContent from './RestrictionsTabContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    setPlayerGeneralEdit,
    setEditPlayerCommunication,
    setEditPlayerVerification,
    setEditPlayerRestrictions,
    getPlayerKYCCount, getPlayerVerification,
} from '../../../store/actions';
import { getPlayerGeneral } from '../../../store/players/general/actions';
import useAlertService from '../../../hooks/useAlertService';
import { DEFAULT_PLAYER_KYC_COUNT_QUERY } from "../../../constants/Filter";
import { clearCurrentFilter } from "../../../store/filters/action"
import WalletTabContent from "./WalletTabContent";
import FinanceTabContent from "./FinanceTabContent";
import VipTabContent from "./VipTabContent";
import useHashNavigation from "../../../hooks/useHashNavigation";
import queryString from "query-string";
import PlayerReferral from "./PlayerReferral"

function DetailedCard({
    playerModel,
    setPlayerModel,
    fieldErrors,
    setFieldErrors,
    showMainCard,
}) {
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const parseUrlSearch = () => {
        return queryString.parse(location.search)
    }

    const [tabState, setTabState] = useState({
        activeTab: parseUrlSearch().tab || PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL,
        toSetActiveTab: ''
    });
    const { changeQuery, removeUrlSearch } = useHashNavigation();
    const { verification } = useSelector(state => state.PlayerVerification);

    history.listen((newLocation) => {
        if (newLocation.pathname !== location.pathname) {
            setTabState(prevState => ({ ...prevState, activeTab: PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL }));
        }
    });

    const [showJoint, setShowJoint] = useState(false);

    const { isEdit: isEditGeneral, playerGeneral } = useSelector(
        state => state.PlayerGeneral
    );
    const { isEdit: isEditRestrictions } = useSelector(
        state => state.PlayerRestrictions
    );
    const { isEdit: isEditVerification } = useSelector(
        state => state.PlayerVerification
    );
    const { isEdit: isEditCommunication } = useSelector(
        state => state.PlayerCommunication
    );

    useEffect(() => {
        setShowJoint(playerGeneral?.accountType === 'Joint');
    }, [playerModel]);

    const isEdit = useMemo(() => {
        return (
            isEditGeneral ||
            isEditRestrictions ||
            isEditVerification ||
            isEditCommunication
        );
    }, [
        isEditGeneral,
        isEditRestrictions,
        isEditVerification,
        isEditCommunication
    ]);

    const handleResetEdit = () => {
        dispatch(setPlayerGeneralEdit(false));
        dispatch(setEditPlayerCommunication(false));
        dispatch(setEditPlayerVerification(false));
        dispatch(setEditPlayerRestrictions(false));
    };

    useEffect(() => {
        const parsedTab = parseUrlSearch().tab;
        if (PLAYER_VIEW_DETAILED_TAB_TYPE[parsedTab]) {
            setTabState({
                activeTab: parsedTab,
                toSetActiveTab: ""
            })
        } else {
            setTabState({
                activeTab: PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL,
                toSetActiveTab: ""
            })
            removeUrlSearch();
        }
    }, [location.search]);


    const handleChangeTab = useCallback(
        tab => {
            changeQuery(tab);
            dispatch(clearCurrentFilter());
            if (isEdit) {
                setTabState(prevState => ({
                    ...prevState,
                    toSetActiveTab: tab
                }));
            } else {
                setTabState(prevState => ({ ...prevState, activeTab: tab }));

                if (playerGeneral?.oid) {
                    dispatch(getPlayerGeneral(playerGeneral.oid));
                }
                dispatch(getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY));
            }
        },
        [isEdit, playerGeneral?.oid, location.search]
    );

    const handleConfirmPrompt = useCallback(() => {
        const newActiveTab = tabState.toSetActiveTab;
        changeQuery(newActiveTab);

        handleResetEdit();
    }, [tabState.toSetActiveTab, isEdit]);

    useEffect(() => {
        if (localStorage.getItem('path') === '/pending-documents') {
            setTabState(prevState => ({ ...prevState, activeTab: PLAYER_VIEW_DETAILED_TAB_TYPE.DOCUMENTS }));
            localStorage.removeItem('path')
        }
    }, [])

    const handleCancelPrompt = useCallback(() => {
        setTabState(prevState => ({ ...prevState, toSetActiveTab: '' }));
    }, [tabState]);

    const isActiveTabEq = val => tabState.activeTab === val;
    const playerId = useMemo(() => params.id, [params]);
    const playerGeneralId = useMemo(() => playerGeneral?.id, [playerGeneral]);
    const alertService = useAlertService();
    const [isPEPUser, setIsPEPUser] = useState(false);

    useEffect(() => {
        dispatch(getPlayerVerification(playerId));
    }, [playerId]);

    useEffect(() => {
        setIsPEPUser(verification?.pepInfo?.isPep)
    }, [verification])

    return (
        <>
            <Card className={isEdit ? 'detailed-card' : ''}>
                <CardBody className="user-general__detailed-card">
                    <DetailedCardTabs
                        showJoint={showJoint}
                        activeTab={tabState.activeTab}
                        onClick={handleChangeTab}
                    />
                   
                    <SwipeAnimation />

                    <hr />

                    {isActiveTabEq(PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL) && (
                        <GeneralTabContent
                            alertService={alertService}
                            playerModel={playerModel}
                            setPlayerModel={setPlayerModel}
                            fieldErrors={fieldErrors}
                            setFieldErrors={setFieldErrors}
                            playerId={playerId}
                        />
                    )}

                    {isActiveTabEq(PLAYER_VIEW_DETAILED_TAB_TYPE.VIP) &&
                        <VipTabContent
                            playerId={playerGeneralId}
                            playerModel={playerModel}
                            setPlayerModel={setPlayerModel}
                        />
                    }

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.COMMUNICATION
                    ) && <CommunicationTabContent playerId={playerId} />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.DOCUMENTS
                    ) && <DocumentsTabContent
                        expanded={!showMainCard}
                    />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.VERIFICATION
                    ) && <VerificationTabContent
                            playerId={playerId}
                            playerGeneral={playerGeneral}
                            isPEPUser={isPEPUser}
                    />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.FINANCE
                    ) && <FinanceTabContent
                            playerId={playerId}
                            playerModel={playerModel}
                         />
                    }

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.RESTRICTIONS
                    ) && <RestrictionsTabContent playerId={playerId} />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.GAME_PLAY
                    ) && <GamePlayTabContent
                        playerId={playerId}
                        playerModel={playerModel}
                    />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.BONUSES
                    ) && <PromotionsTabContent
                        playerId={playerId}
                        playerGeneral={playerGeneral}
                    />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.AUDIT
                    ) && <AuditTabContent playerId={playerId} />}

                    {isActiveTabEq(
                        PLAYER_VIEW_DETAILED_TAB_TYPE.WALLET_TRANSACTION
                    ) && <WalletTabContent playerId={playerGeneral?.id} />}

                    {isActiveTabEq(
                      PLAYER_VIEW_DETAILED_TAB_TYPE.REFERRAL
                    ) && <PlayerReferral playerOid={playerGeneral?.oid} />}
                </CardBody>
            </Card>

            {tabState.toSetActiveTab && (
                <CustomPromptModal
                    onConfirm={handleConfirmPrompt}
                    onCancel={handleCancelPrompt}
                />
            )}

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}
        </>
    );
}

export default React.memo(DetailedCard);
