import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../utils/consts";

function SelectedInfoView({ offset, total, listCount, selectedCount }) {
    const { i18n } = useTranslation();
    const size = useWindowSize();

    const isCount = selectedCount || selectedCount === 0;
    let displayedOffset;
    let displayedListCount;

    if (listCount === 'All') {
        displayedOffset = 1;
        displayedListCount = total;
    } else {
        displayedOffset = offset + 1;
        displayedListCount = Math.min(offset + listCount, total);
    }

    const content = total ? (
        <div>
            {displayedOffset} - {displayedListCount} {i18n.t('crm.of')} {total}
            {isCount && size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW && <>&nbsp;&nbsp; ({selectedCount} {i18n.t('crm.selected')})</>}
        </div>
    ) : <></>;

    return content;
}


SelectedInfoView.propTypes = {
    offset: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    listCount: PropTypes.number,
    selectedCount: PropTypes.number
};

export default React.memo(SelectedInfoView);
