import {A_COMMON_DATA_FIELD} from "../../../constants/aCommonDataField";
import {SITE_BRAND} from "../../../constants/siteBrands";

export const fieldsProfileEdit = (winpot, siteSettings) => {
    const currencies = siteSettings?.settings?.financial?.currencies;
    let requireFields = siteSettings?.settings?.crm?.requireFields || [];
    requireFields = requireFields.map(field => field.includes('.') ? field.split('.')[1] : field);
    requireFields.push('currency');
    requireFields.push('nickName');
    requireFields.push('addressCountryAlfa2');
    requireFields.push('password');

    if(winpot === undefined) {
        winpot = false;
    }

    let defaultCountry;
    switch (siteSettings?.name.toLowerCase()) {
        case SITE_BRAND.WINPOTMX.toLowerCase():
            defaultCountry = 'MX';
            break;
        case SITE_BRAND.Dealer21.toLowerCase():
            defaultCountry = 'AR';
            break;
        default:
            defaultCountry = undefined;
            break;
    }


    const fieldsFullAddress = (isMexico) => [{
        component: A_COMMON_DATA_FIELD.SELECT_COUNTRIES,
        stateKey: 'addressCountryAlfa2',
        value: defaultCountry,
        selected: defaultCountry
    }, {
        component: isMexico
            ? A_COMMON_DATA_FIELD.SELECT_STATES
            : 'text',
        stateKey: "state"
    }, {
        component: isMexico
            ? A_COMMON_DATA_FIELD.SELECT_CITIES
            : 'text',
        stateKey: "city"
    }, {
        component: A_COMMON_DATA_FIELD.TEXT,
        stateKey: 'address'
    }, {
        component: A_COMMON_DATA_FIELD.TEXT,
        stateKey: "zipCode"
    }]

    const array = [
        {
            component: A_COMMON_DATA_FIELD.TEXT,
            stateKey: 'firstName'
        }, {
            component: A_COMMON_DATA_FIELD.TEXT,
            className: 'form-control form-control-sm',
            stateKey: 'lastName'
        },{
            component: A_COMMON_DATA_FIELD.SELECT_CURRENCIES,
            stateKey: 'currency',
            filterSelection: currencies?.map(s => s.toString())
        }, {
            component: A_COMMON_DATA_FIELD.DATE,
            stateKey: 'birthDate'
        }, {
            component: A_COMMON_DATA_FIELD.SELECT_GENDER,
            stateKey: 'gender'
        }, {
            component: A_COMMON_DATA_FIELD.TEXT,
            stateKey: 'email'
        }, {
            component: A_COMMON_DATA_FIELD.TEXT,
            stateKey: 'phone'
        }, {
            component: A_COMMON_DATA_FIELD.PASSWORD,
            stateKey: 'password'
        },
    ];
    array.push(...fieldsFullAddress(winpot));
    if(winpot) {
        array.push({
            component: A_COMMON_DATA_FIELD.TEXT,
            stateKey: "amlRFC"
        });
        array.push({
            component: A_COMMON_DATA_FIELD.TEXT,
            stateKey: "amlCURP"
        });
        return array;
    }

    array.push({
        component: A_COMMON_DATA_FIELD.TEXT,
        label: 'username',
        stateKey: 'nickName'
    });

    // filter the array to the required fields only
    return array.filter(field => requireFields.includes(field.stateKey));
}

export const fieldsProfileCreated = (isMexico, isWinpot, siteSettings) => [
    ...fieldsProfileEdit(isWinpot, siteSettings).slice(0, 7),
    ...fieldsProfileEdit(isWinpot, siteSettings).slice(7)
]