import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import './uploadFile.scss';

import i18n from '../../../../i18n';

import InputWrapper from '../../../../common/wrappers/InputWrapper';
import i_51 from '../../../../assets/images/common/Frame 176 (1).svg';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';
import uploadIcon from '../../../../assets/images/common/upload (4) 1.svg';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

import { A_COMMON_DATA_FIELD } from '../../../../constants/aCommonDataField';
import {
    getOptions,
    DOCUMENT_STATUSES_VALUES,
    IMAGE_TYPES,
    PDF_TYPES
} from '../../../../common/constants/common';

import useAlertService from '../../../../hooks/useAlertService';
import CheckboxInput from '../../../../common/inputs/common/CheckboxInput';

import {
    clearDocumentsError,
    uploadNewDocument
} from '../../../../store/documents/actions';
import {
    isImage,
    isPdf,
    loadPdfFromData,
    openPdfInCanvas,
    readFileAsArrayBuffer,
    readFileAsDataUrl, resizeImg
} from "../../../../common/utils/common";
import { DocumentSide } from "../../../../utils/KYC";
import moment from "moment";
import {getFileSizeMB, MAX_SIZE_IMAGE_MB} from "../../../../utils/uploadImage";
import Loader from "../../../../common/components/Loader";
import imageCompression from "browser-image-compression";

function UploadFileView({ onClose, props, fileInputRef }) {
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const { error, isLoading } = useSelector(state => state.Documents);
    const [date, setDate] = useState();
    const [hidden, setHidden] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [documentSide, setDocumentSide] = useState(DocumentSide.Front);
    const [documentStatus, setDocumentStatus] = useState('');
    const [documentPreview, setDocumentPreview] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [isFilledForm, setIsFilledForm] = useState(false);
    const [file, setFile] = useState();
    const [errors, setErrors] = useState({});

    const inputFileTypes = [...PDF_TYPES, ...IMAGE_TYPES].join(',');

    const handleUploadUserDocument = () => fileInputRef.click();

    useEffect(() => {
        if (documentType && documentSide && documentStatus && documentPreview ) {
            if (documentType !== 'INE' && documentType !== 'IFE' && documentType !== 'Passport') {
                setIsFilledForm(true)
            } else {
                if (date) {
                    setIsFilledForm(true)
                } else {
                    setIsFilledForm(false)
                }
            }
        } else {
            setIsFilledForm(false)
        }
    }, [date, documentType, documentSide, documentStatus, documentPreview]);

    const handleSelectFile = async event => {
        let file = event.target.files[0];
        const isPdfFile = isPdf(file.type);

        if (!isPdfFile && getFileSizeMB(file.size) > MAX_SIZE_IMAGE_MB) {
            const options = {
                maxSizeMB: 5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: file.type
            }
            try {
                file = await imageCompression(file, options);
                file = new File([file], file.name, { type: file.type });
            } catch (error) {
                console.log(error);
            }
        }

        if (getFileSizeMB(file.size) > MAX_SIZE_IMAGE_MB) {
            alertService.showError(i18n.t('crm.largeFile.error'));
            event.target.value = '';
            return
        }

        if (file) {
            setFileName(file.name);
            setFileSize(file.size);
            const canvas = document.getElementById('preview-canvas');
            canvas.style.display = 'block';

            let previewDataURL;

            if (isPdf(file.type)) {
                setFile(file);
                const pdfData = await readFileAsArrayBuffer(file);
                const pdf = await loadPdfFromData({ data: pdfData });
                previewDataURL = await openPdfInCanvas({ pdf, canvas, canvasWidth: 260, pageNumber: 1 });
            } else if (isImage(file.type)) {
                setFile(file);
                const imageDataURL = await readFileAsDataUrl(file);
                // @ts-ignore
                previewDataURL = await resizeImg({ canvas, canvasWidth: 260, imageDataURL });
            } else {
                event.target.value = '';
                alertService.showError(i18n.t('crm.OnlyImagesAndPdfAllowed'))
            }
            previewDataURL && setDocumentPreview(previewDataURL);
            event.target.value = null;
        }
    };

    const uploadFile = () => {
        if (!file) {
            alertService.showError(i18n.t('crm.chooseFile'));
            return;
        }

        const oid = props.player.oid;

        const documentData = {
            expiration: date && moment.utc(moment(date).format('YYYY-MM-DD')).toISOString(),
            type: documentType,
            status: documentStatus,
            side: documentSide,
            hidden: hidden,
            preview: documentPreview,
            fileName,
            fileSize,
        };

        dispatch(uploadNewDocument(oid, documentData, file));
    };

    const fields = useMemo(
        () => [
            {
                component: A_COMMON_DATA_FIELD.DATE,
                label: i18n.t('crm.document.expiration'),
                handleChange: setDate,
                value: date,
                error: errors['expiration'],
                helperText: errors['expiration']
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_TYPES,
                label: i18n.t('crm.document.type'),
                handleChange: setDocumentType,
                value: documentType,
                error: errors['type'],
                helperText: errors['type']
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                label: i18n.t('crm.document.status'),
                handleChange: setDocumentStatus,
                value: documentStatus,
                options: getOptions(DOCUMENT_STATUSES_VALUES, 'admin.document.statuses'),
                error: errors['status'],
                helperText: errors['status']
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_SIDES,
                label: i18n.t('crm.document.side'),
                handleChange: setDocumentSide,
                value: documentSide,
                error: errors['side'],
                helperText: errors['side']
            }
        ],
        [date, errors, documentType, documentStatus, documentSide]
    );

    useEffect(() => {
        if (error?.message) {
            console.log(error)
        } else {
            setErrors({});

            if (documentType) {
                alertService.showSuccess(i18n.t('crm.fileUploaded'));
                onClose();
            }
        }
    }, [error]);

    useEffect(() => {
        return () => dispatch(clearDocumentsError());
    }, []);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="app-status-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <input
                style={{ display: 'none' }}
                ref={file => (fileInputRef = file)}
                onChange={handleSelectFile}
                type="file"
                accept={inputFileTypes}
                disabled={props.disabled}
            />

            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.uploadDocument')}
                </h5>
                <button className="close" onClick={() => !isLoading && onClose()} />
            </div>

            <ModalBody>
                <InputWrapper className="d-block fields-wrapper">
                    <CheckboxInput
                        checked={hidden}
                        onChange={setHidden}
                        disabled={isLoading}
                        className={'hidden-upload-document'}
                    >
                        {i18n.t('crm.document.hidden')}
                    </CheckboxInput>

                    {fields.map((item, index) => (
                        <ACommonDataField
                            {...item}
                            key={index}
                            fulWidth={item.fulWidth || true}
                            disabled={isLoading}
                            isBirthDate={false}
                            allowFutureDate={true}
                        />
                    ))}

                    <Row
                        xs={'auto'}
                        className={'align-items-center flex-nowrap'}
                        style={{ marginTop: '12px' }}
                    >
                        <Col>
                            <button
                                className="btn btn-rounded btn-info"
                                onClick={handleUploadUserDocument}
                                disabled={isLoading}
                            >
                                <img src={uploadIcon} alt="" />
                                {i18n.t('crm.document.browse')}
                            </button>
                        </Col>
                        <Col className={'file-name'}>{file?.name}</Col>
                    </Row>
                    <div className="text-secondary mb-2 mt-2">
                        {i18n.t('common.allowed_types')}:&nbsp;
                        <span className="font-w-500">*.jpg, *.jpeg, *.png, *.pdf</span>
                    </div>
                </InputWrapper>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-rounded m-auto"
                    onClick={uploadFile}
                    disabled={isLoading ? isLoading : !isFilledForm}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('crm.submit')}
                </button>
                {isLoading && <Loader size={'sm'}/>}
            </ModalFooter>
        </Modal>
    );
}

UploadFileView.propTypes = {
    onClose: PropTypes.func.isRequired,
    fileInputRef: PropTypes.func.isRequired,
    props: PropTypes.any.isRequired
};

export default React.memo(UploadFileView);
