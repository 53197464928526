import {
    GET_SUPPLIER_LIST,
    GET_SUPPLIER_LIST_SUCCESS,
    GET_SUPPLIER_LIST_FAIL,
    GET_CASHIER_LIST,
    GET_CASHIER_LIST_SUCCESS,
    GET_CASHIER_LIST_FAIL,
    GET_CASHIER_METHOD,
    GET_CASHIER_METHOD_SUCCESS,
    GET_CASHIER_METHOD_FAIL,
    UPDATE_CASHIER_METHOD,
    UPDATE_CASHIER_METHOD_SUCCESS,
    UPDATE_CASHIER_METHOD_FAIL,
    CREATE_CASHIER_METHOD,
    CREATE_CASHIER_METHOD_SUCCESS,
    CREATE_CASHIER_METHOD_FAIL,
    GET_SUPPLIER_BY_ID,
    GET_SUPPLIER_BY_ID_FAIL,
    GET_SUPPLIER_BY_ID_SUCCESS,
    UPDATE_CASHIER_SUPPLIER_FAIL,
    UPDATE_CASHIER_SUPPLIER_SUCCESS,
    UPDATE_CASHIER_SUPPLIER,
    CREATE_CASHIER_SUPPLIER_SUCCESS,
    CREATE_CASHIER_SUPPLIER_FAIL,
    CREATE_CASHIER_SUPPLIER,
    REMOVE_SUPPLIER_BY_ID,
    REMOVE_SUPPLIER_BY_ID_SUCCESS,
    REMOVE_SUPPLIER_BY_ID_FAIL,
    REMOVE_METHOD_BY_ID, REMOVE_METHOD_BY_ID_FAIL, REMOVE_METHOD_BY_ID_SUCCESS,
} from "./actionTypes";

export const getSupplierList = payload => ({
    type: GET_SUPPLIER_LIST,
    payload
});

export const getSupplierListSuccess = payload => ({
    type: GET_SUPPLIER_LIST_SUCCESS,
    payload
});

export const getSupplierListFail = payload => ({
    type: GET_SUPPLIER_LIST_FAIL,
    payload
});

export const getCashierList = payload => ({
    type: GET_CASHIER_LIST,
    payload
});

export const getCashierListSuccess = payload => ({
    type: GET_CASHIER_LIST_SUCCESS,
    payload
});

export const getCashierListFail = payload => ({
    type: GET_CASHIER_LIST_FAIL,
    payload
});

export const getSupplierById = payload => ({
    type: GET_SUPPLIER_BY_ID,
    payload
});

export const getSupplierByIdSuccess = payload => ({
    type: GET_SUPPLIER_BY_ID_SUCCESS,
    payload
});

export const getSupplierByIdFail = payload => ({
    type: GET_SUPPLIER_BY_ID_FAIL,
    payload
});

export const getCashierMethod = payload => ({
    type: GET_CASHIER_METHOD,
    payload
});

export const getCashierMethodSuccess = payload => ({
    type: GET_CASHIER_METHOD_SUCCESS,
    payload
});

export const getCashierMethodFail = payload => ({
    type: GET_CASHIER_METHOD_FAIL,
    payload
});

export const updateCashierMethod = payload => ({
    type: UPDATE_CASHIER_METHOD,
    payload
});

export const updateCashierMethodSuccess = payload => ({
    type: UPDATE_CASHIER_METHOD_SUCCESS,
    payload
});

export const updateCashierMethodFail = payload => ({
    type: UPDATE_CASHIER_METHOD_FAIL,
    payload
});

export const createCashierMethod = payload => ({
    type: CREATE_CASHIER_METHOD,
    payload
});

export const createCashierMethodSuccess = payload => ({
    type: CREATE_CASHIER_METHOD_SUCCESS,
    payload
});

export const createCashierMethodFail = payload => ({
    type: CREATE_CASHIER_METHOD_FAIL,
    payload
});

export const updateCashierSupplier = payload => ({
    type: UPDATE_CASHIER_SUPPLIER,
    payload
});

export const updateCashierSupplierSuccess = payload => ({
    type: UPDATE_CASHIER_SUPPLIER_SUCCESS,
    payload
});

export const updateCashierSupplierFail = payload => ({
    type: UPDATE_CASHIER_SUPPLIER_FAIL,
    payload
});

export const createCashierSupplier = payload => ({
    type: CREATE_CASHIER_SUPPLIER,
    payload
});

export const createCashierSupplierSuccess = payload => ({
    type: CREATE_CASHIER_SUPPLIER_SUCCESS,
    payload
});

export const createCashierSupplierFail = payload => ({
    type: CREATE_CASHIER_SUPPLIER_FAIL,
    payload
});

export const removeCashierMethodById = payload => ({
    type: REMOVE_METHOD_BY_ID,
    payload
});

export const removeCashierMethodByIdSuccess = payload => ({
    type: REMOVE_METHOD_BY_ID_SUCCESS,
    payload
});

export const removeCashierMethodByIdFail = payload => ({
    type: REMOVE_METHOD_BY_ID_FAIL,
    payload
});

export const removeCashierSupplierById = payload => ({
    type: REMOVE_SUPPLIER_BY_ID,
    payload
});

export const removeCashierSupplierByIdSuccess = payload => ({
    type: REMOVE_SUPPLIER_BY_ID_SUCCESS,
    payload
});

export const removeCashierSupplierByIdFail = payload => ({
    type: REMOVE_SUPPLIER_BY_ID_FAIL,
    payload
});