import React from 'react';

import i18n from '../../i18n';

import NewCustomTable from '../../components/NewCustomTable';
import PropTypes from 'prop-types';
import Formatter from '../../utils/Formatter';
import AvatarIcon from './AvatarIcon';
import { useHistory } from 'react-router-dom';

const columns = [
    { label: i18n.t('agent.fullName'), className: 'text-align-left' },
    { label: i18n.t('agent.payrollID') },
    { label: i18n.t('agent.email') },
    { label: i18n.t('agent.manager') },
    { label: i18n.t('agent.jobTitle') },
    { label: i18n.t('agent.lastLogin') }
];

function EmployeesTable({ isLoading, list }) {
    const history = useHistory();

    return (
        <NewCustomTable columns={columns} isHeader={true} isLoading={isLoading}>
            {list &&
                list.map((item, index) => (
                    <tr key={index} className="font-w-500">
                        <td
                            className="text-align-left"
                            role="button"
                            onClick={() =>
                                history.push(`/agent-view/employees/${item.id}`)
                            }
                        >
                            <div className="d-flex">
                                <AvatarIcon
                                    avatarData={null}
                                    lastName={item.lastName}
                                    size="xs"
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#B473E5',
                                        fontSize: '19px'
                                    }}
                                />
                                <div className="ms-2 mt-auto mb-auto font-w-600">
                                    {item.firstName}&nbsp;{item.lastName}
                                </div>
                            </div>
                        </td>
                        <td>{item.payrollID}-</td>
                        <td>{item.email}</td>
                        <td>{item.manager}</td>
                        <td>{item.jobTitle}</td>
                        <td>{Formatter.displayDate(item.lastLogin)}</td>
                    </tr>
                ))}
        </NewCustomTable>
    );
}

EmployeesTable.propTypes = {
    isLoading: PropTypes.bool,
    list: PropTypes.array
};

export default React.memo(EmployeesTable);
