import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import i18n from '../../i18n';

import CheckboxInput from '../../common/inputs/common/CheckboxInput';
import { CommonCard } from '../../components/CommonCard';
import { A_COMMON_DATA_FIELD } from '../../constants/aCommonDataField';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';

import group_11909 from '../../assets/images/common/Group 11909.svg';

// TODO generate link
const AffiliateDetailedCard = ({
    state,
    isLoading,
    handleShowLinkModal,
    handleChange,
    onEdit
}) => {
    const onChange = useCallback(
        state => value => {
            onEdit(true);
            handleChange(state)(value);
        },
        [handleChange]
    );

    return (
        <CommonCard isLoading={isLoading} className={'affiliate-detailed-card'}>
            <Row md={'auto'}>
                <Col sm={12} className={'sub-content time-field-content'}>
                    <ACommonDataField
                        fulWidth
                        component={A_COMMON_DATA_FIELD.NUMBER}
                        value={state.cookieExpirationTimeInMinutes || ''}
                        handleChange={onChange('cookieExpirationTimeInMinutes')}
                        label={`${i18n.t(
                            'affiliates.cookieExpirationTimeMin'
                        )}:`}
                    />
                </Col>

                <Col sm={12} className={'sub-content'}>
                    <CheckboxInput
                        checked={state.priority}
                        onChange={onChange('priority')}
                        className="label-left mt-1"
                    >
                        <span className={'font-w-600'}>
                            {i18n.t('affiliates.priority')}
                        </span>
                    </CheckboxInput>
                </Col>

                <Col sm={12} className={'sub-content'}>
                    <div
                        role="button"
                        className="mt-1"
                        onClick={handleShowLinkModal}
                    >
                        <img className="me-2" src={group_11909} alt="" />
                        <span className="app-text-primary font-w-700">
                            {i18n.t('affiliates.generateLink')}
                        </span>
                    </div>
                </Col>
            </Row>
        </CommonCard>
    );
};

AffiliateDetailedCard.propTypes = {
    state: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleShowLinkModal: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired
};

export default React.memo(AffiliateDetailedCard);
