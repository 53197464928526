import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DOCUMENT_PAGE_MODES } from '../../../common/constants/common';
import { DATE_FORMAT, TIME_FORMAT_hh_mm_ss } from '../../../common/utils/common';
import trashCan from "../../../assets/images/common/trashCan.svg";

const ActivityCommentsRecord = props => {
    const { record, withActions, pageMode, onRemoveComment = _ => {} } = props;
    const { content: information, date: dateTime } = record;
    const dateMoment = moment(dateTime);
    const date = dateMoment.format(DATE_FORMAT);
    const time = dateMoment.format(TIME_FORMAT_hh_mm_ss);

    const handleDelete = () => {
        onRemoveComment(record);
    };

    return (
        <li className="comment-item">
            <span key="date">{date}</span>
            <span key="time" className="record-part">
                {time}
            </span>
            {withActions && (
                <span className="comment-actions">
                    <span className="delete-action">
                        <button className="btn btn-rounded btn-light app-btn-only-img-sm" onClick={handleDelete}>
                          <img src={trashCan} alt=""/>
                        </button>
                    </span>
                </span>
            )}
            <div key="information" className="record-information">
                {information}
            </div>
        </li>
    );
};

ActivityCommentsRecord.propTypes = {
    record: PropTypes.object,
    withActions: PropTypes.bool,
    pageMode: PropTypes.string
};

ActivityCommentsRecord.defaultProps = {
    withActions: true,
    pageMode: DOCUMENT_PAGE_MODES.pendingDocuments
};

export default ActivityCommentsRecord;
