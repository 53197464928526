import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Input,
    FormGroup,
    Label
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ActivityTab from './documentsParts/ActivityTab';
import DocInfoArea from './documentsParts/DocInfoArea';
import {
    BASE_URL,
    DOCUMENT_PAGE_MODES,
    DOCUMENT_STATUSES,
    DOC_VIEW_STAGES, SITE_PERMISSION
} from '../../common/constants/common';
import {
    handleChangePath,
    isImage,
    isPdf,
    loadPdfFromData,
    openPdfInCanvas,
    getGuidShortFormat,
    getStorageXSiteId,
} from '../../common/utils/common';

import './datatables.scss';
import './styles.scss';
import '../../assets/scss/pages/common.scss';

import thinCross from '../../assets/images/layout/thin-cross.svg';
import thinSubmit from '../../assets/images/layout/thin-submit.svg';

import arrowLeft from '../../assets/images/layout/arrow-left.svg';
import arrowRight from '../../assets/images/layout/arrow-right.svg';

import lightVioletActivity from '../../assets/images/layout/light-violet-activity.svg';
import greyActivity from '../../assets/images/layout/grey-activity.svg';
import lightVioletInformation from '../../assets/images/layout/light-violet-information.svg';
import greyInformation from '../../assets/images/layout/grey-information.svg';
import radioBackground from '../../assets/images/layout/radio-background.svg';
import {
    approveDocument,
    rejectDocument,
    getFirstPendingDocuments,
    getDocument,
    getDocumentData,
    updateDocument,
    addDocumentComment,
    removeDocumentComment,
    downloadDocument,
    getPlayerGeneral,
    clearPlayerGeneral,
    getPlayerKYCCount, getAgentSelf,
} from '../../store/actions';
import { getInfo } from '../../store/info/actions';
import { getAccessToken, getSync } from '../../helpers/api_helper';
import download from "../../assets/images/common/download.svg";
import eyePreview from "../../assets/images/common/eye-preview.svg";
import Loader from "../../common/components/Loader";
import { withAlert } from "react-alert";
import ErrorMessage from "./ErrorMessage/ErrorMessage"
import { ViewImg, setPositionRotateButtons } from "./documentsParts/ViewImg"
import { ViewPDF } from "./documentsParts/ViewPDF"
import clsx from "clsx"
import { DEFAULT_PLAYER_KYC_COUNT_QUERY } from "../../constants/Filter";
import ButtonOpenPlayerView from "../../UI/buttons/ButtonOpenPlayerView";

import "./styles.scss";
import styles from "./documentView.module.scss"
import ClipboardButton from "../../common/cellData/CopyButton";
import { withTranslation } from "react-i18next";
import CustomPromptModal from "../../common/prompt/CustomPromptModal";
import { RouterPrompt } from "../../common/prompt/RouterPrompt";
import i18n from "../../i18n";
import DocMetaDate from "./documentsParts/DocMetaDate";

const pdfJsLib = window['pdfjs-dist/build/pdf'];

pdfJsLib.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.min.js';

const HANDLED_STATUSES = {
    approved: 'approved',
    rejected: 'rejected'
};

const PAGES = {
    information: 'information',
    activity: 'activity'
};

const REASONS = {
    expired: DOCUMENT_STATUSES.expired.text,
    cancelledByPlayer: DOCUMENT_STATUSES.canceledbyplayer.text,
    otherReason: DOCUMENT_STATUSES.rejected.text
};

const initialPromptModalState = {
    open: false,
    activeHandler: ''
}

const isDisableDeclineDocumentBtn = (currentDocument) => {
  const { status } = currentDocument;
  return status === 'Rejected'
      || status === 'Expired'
      || status === 'CanceledByPlayer'
}

class DocumentView extends Component {
    constructor(props) {
        super(props);

        handleChangePath(props);
        const { match } = props;
        this.docFrameRef = React.createRef();

        this.isViewMode =
            match.path === '/player-list/:id'
            || match.path === '/withdrawals-pending-approve/:id'
            || match.path === '/withdrawals-pending-transfer/:id'
            || match.path === '/pending-kyc-verification'
            || match.path === '/pending-kyc-verification/:id'
        this.mode = this.isViewMode
            ? DOCUMENT_PAGE_MODES.documentView
            : DOCUMENT_PAGE_MODES.pendingDocuments;
        this.isKYCPending = match.path === '/pending-kyc-verification';

        let stage;
        let currentDocument;

        if (this.isViewMode) {
            ({ currentDocument } = props);
            stage = DOC_VIEW_STAGES.view;
        } else {
            currentDocument = null;
            stage = DOC_VIEW_STAGES.initial;
        }

        this.pendingDocumentList = [];

        this.state = {
            currentDocument,
            stage,
            handledStatus: '',
            rejectBtnDisabled: true,
            reason: REASONS.expired,
            reasonText: '',
            currentPage: PAGES.information,
            pendingDocuments: [],
            documentPdf: null,
            previewDataURL: '',
            previewChanged: false,
            numPdfPages: 0,
            uploadErrorMessage: '',
            userAddress: {},
            localDocCount: 0,
            currentDocumentNum: 0,
            documentsTotal: 0,
            isEditPlayerData: false,
            isApproveDisabled: false,
            docUrl: '',
            showPromptModal: initialPromptModalState,
        };
    }

    async componentDidMount() {
        if (this.isViewMode) {
            const { currentDocument } = this.state;
            const { id: documentId, contentType } = currentDocument;

            if (isPdf(contentType)) {
                await this.setPdfToCanvas(documentId);
            } else if (!this.isKYCPending) {
                const url = `${BASE_URL}/api/documents/${currentDocument.id}?token=${getAccessToken()}`;
                const options = {
                    headers: {
                        Authorization: `Bearer ${getAccessToken()}`,
                        'X-Site-Id': getStorageXSiteId(),
                    }
                };

                fetch(url, options)
                    .then(res => res.blob())
                    .then(blob => {
                        this.setState({docUrl: URL.createObjectURL(blob)});
                    });
            }
        } else {
            this.props.getFirstPendingDocuments();
        }

        if (this.isKYCPending) {
            this.props.getPlayerGeneral(this.props.documentPlayer?.id);
        }
    }

    setPdfToCanvas = async documentId => {
        const docUrl = `${BASE_URL}/api/documents/${documentId}?token=${getAccessToken()}`;
        const documentPdf = await loadPdfFromData(docUrl);

        if (documentPdf?.status === 500 || documentPdf?.name?.includes('Exception')) {
            this.setState({
                uploadErrorMessage: documentPdf.message === "No password given" ? this.props.i18n.t('crm.document.protectedByPassword') : documentPdf.message,
            })
        }
        const numPages = documentPdf?._pdfInfo?.numPages;

        this.setState(
            {
                documentPdf,
                numPdfPages: numPages
            },
            async () => {
                if (numPages) {
                    for (
                        let pageNumber = 1;
                        pageNumber <= numPages;
                        pageNumber++
                    ) {
                        const canvas = document.getElementById(
                            `the-canvas-${pageNumber}`
                        );
                        await openPdfInCanvas({
                            pdf: documentPdf,
                            canvas,
                            canvasWidth:
                                this.docFrameRef.current?.scrollWidth || 400,
                            pageNumber
                        });
                    }
                }
            }
        );
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        setPositionRotateButtons(true);
        const {
            pendingDocuments: propsPendingDocuments,
            lastDocumentUpdatedDate,
            currentDocumentData,
            lastCommentCreateDate,
            lastCommentRemoveDate,
            isChangingStatus,
        } = this.props;

        const { pendingDocuments, currentDocument } = this.state;

        if (this.props.total !== prevProps.total) {
            this.props.setIsDeclineOrApprove(true);
        }

        if (
            lastCommentCreateDate &&
            lastCommentCreateDate !== prevProps.lastCommentCreateDate
        ) {
            this.props.getDocumentData(currentDocument.oid);
            this.props.alert.success(i18n.t('crm.CommentAdded'));
        }

        if (
            lastCommentRemoveDate &&
            lastCommentRemoveDate !== prevProps.lastCommentRemoveDate
        ) {
            this.props.getDocumentData(currentDocument.oid);
            this.props.alert.error(i18n.t('crm.CommentRemoved'));
        }

        if (this.props.isPopupOpen && this.props.isPopupOpen === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }

        if (
            this.isViewMode &&
            !this.props.isLoading &&
            prevProps.isLoading !== this.props.isLoading
        ) {
            this.updateDocuments();

            const { currentDocument } = this.state;
            const { id: documentId, contentType } = currentDocument;

            if (isPdf(contentType)) {
                await this.setPdfToCanvas(documentId);
            }

        }

        if (!isChangingStatus &&
            prevProps.isChangingStatus !== isChangingStatus
        ) {
            if (this.isViewMode) {
                this.updatePlayer();
            }
            else {
                const {currentDocumentNum} = this.state;
                // 1. Remove curr doc from this.pendingDocumentList
                this.pendingDocumentList.splice(currentDocumentNum, 1);
                // 2. Change current document
                this.handleChangeDocument(currentDocumentNum);
            }
        }

        if (
            !this.isViewMode &&
            (propsPendingDocuments !== prevProps.pendingDocuments)
        ) {
            await this.updateDocumentList();
        }

        if (this.isKYCPending &&
            !isChangingStatus &&
            isChangingStatus !== prevProps.isChangingStatus) {
            this.props.getDocumentData(currentDocument.oid);
        }

        if (
            currentDocument &&
            lastDocumentUpdatedDate &&
            lastDocumentUpdatedDate !== prevProps.lastDocumentUpdatedDate
        ) {
            this.props.getDocumentData(currentDocument.oid);
        }

        if (
            currentDocumentData &&
            currentDocumentData !== prevProps.currentDocumentData
        ) {
            this.setState({ currentDocument: currentDocumentData });
        }

        if (this.props.playerDocuments !== prevProps.playerDocuments && currentDocument) {
            const currentDoc = this.props.playerDocuments.find(
              documentData => documentData.id === currentDocument.id
            );

            if (currentDoc) {
                this.setState({
                    currentDocument: currentDoc,
                });
            }
        }
    }

    componentWillUnmount() {
        const { match } = this.props;
        if (match.path !== '/player-list/:id' && match.path !== '/pending-kyc-verification/:id') {
            this.props.clearPlayerGeneral();
        }
        document.body.style.overflow = 'scroll';
    }

    updateDocumentList = () => {
        const { pendingDocuments: pendingDocumentsData } = this.props;
        const { currentDocumentNum } = this.state;

        const {result: propsPendingDocuments, total: documentsTotal} = pendingDocumentsData

        if (propsPendingDocuments.length > 0) {
            propsPendingDocuments.forEach((newDoc) => {
                if (!this.pendingDocumentList.find((doc) => doc.id === newDoc.id)) {
                    this.pendingDocumentList.push(newDoc);
                }
            });

            const localDocCount = this.pendingDocumentList.length;
            const partState = {documentsTotal};
            let changedFieldsCount = 0;

            if (localDocCount !== this.state.localDocCount) {
                partState.localDocCount = localDocCount;
                changedFieldsCount++;
            }
            if (this.state.currentDocument === null && localDocCount > currentDocumentNum) {
                partState.currentDocument = this.pendingDocumentList[currentDocumentNum];
                changedFieldsCount++;
            }

            if (changedFieldsCount > 0) {
                this.setState(partState);
            }
        }
    }

    handleReasonTextChange = event => {
        const { value } = event.target;

        this.setState({
            reasonText: value,
            rejectBtnDisabled: value.length < 10
        });
    };

    updateDocuments = () => {
        this.props.getPlayerDocuments();
    };

    updatePlayer = () => {
        if (this.isKYCPending) {
            this.props.getPlayerGeneral(this.props.documentPlayer?.id);
        }
        else {
            this.props.getPlayerGeneral(this.props.playerGeneral?.oid);
        }
        this.props.getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY);
    }

    handleSendRejectReason = async () => {
        const { rejectDocument } = this.props;
        const { currentDocument, reasonText, reason } = this.state;
        const reasonNoSpaces = reasonText.trim();

        try {
            rejectDocument(currentDocument.oid, {
                status: reason,
                ...(reasonText &&
                    !!reasonText &&
                    !!reasonNoSpaces && { reason: reasonText })
            });
            this.props.alert.success(this.props.i18n.t('crm.alerts.documentUpdated'));
            this.setState(prev => ({
                ...prev,
                reason: DOCUMENT_STATUSES.expired.text,
                reasonText: '',
                decline: false
            }));
        } catch (e) {
            alert.showError(this.props.i18n.t("crm.notUpdatedDocument"));
        }
    };

    handleSendApprove = async () => {
        const { currentDocument, userAddress } = this.state;
        const url = `/documents/pending/${currentDocument.oid}/approve`;
        try {
            this.props.approveDocument(currentDocument.oid, userAddress);
            this.props.alert.success(this.props.i18n.t('crm.alerts.documentUpdated'));
            this.setState(prev => ({
                ...prev,
                reason: DOCUMENT_STATUSES.expired.text,
                reasonText: '',
                decline: false
            }));
        } catch (e) {
            alert.showError(this.props.i18n.t("crm.notUpdatedDocument"));
        }
    };

    handleChangeDocument = currentDocumentNum => {
        const { documentsTotal } = this.state;
        const currDocumentInd =
            currentDocumentNum < 0
                ? 0
                : currentDocumentNum >= documentsTotal
                ? documentsTotal - 1
                : currentDocumentNum;

        let currentDocument = null;
        if (this.pendingDocumentList[currDocumentInd]) {
            currentDocument = this.pendingDocumentList[currDocumentInd];
        }
        else {
            this.props.getFirstPendingDocuments({offset: currDocumentInd});
        }

        this.setState(
            {
                currentDocumentNum: currDocumentInd,
                currentDocument,
                stage: DOC_VIEW_STAGES.initial,
                uploadErrorMessage: '',
            },
            async () => {
                if (currentDocument) {
                    const {contentType, id} = currentDocument;
                    this.props.getDocumentData(currentDocument.oid);
                    if (isPdf(contentType)) {
                        await this.setPdfToCanvas(id);
                    }
                }
            }
        );
    };

    handleCLickPreviousDocument = () => {
        if (this.state.isEditPlayerData) {
            this.setState({
                showPromptModal: {
                    open: true,
                    activeHandler: 'previousDocument'
                }
            })
        } else {
            this.handleOpenPreviousDocument();
        }
    }

    handleCLickNextDocument = () => {
        if (this.state.isEditPlayerData) {
            this.setState({
                showPromptModal: {
                    open: true,
                    activeHandler: 'nextDocument'
                }
            })
        } else {
            this.handleOpenNextDocument();
        }
    }

    handleCancelPrompt = () => {
        this.setState({
            showPromptModal: initialPromptModalState
        });
    };

    handleConfirmPrompt = () => {
        this.setState({
            showPromptModal: initialPromptModalState,
            isEditPlayerData: false
        });
        if (this.state.showPromptModal.activeHandler === 'previousDocument') {
            this.handleOpenPreviousDocument()
        } else {
            this.handleOpenNextDocument();
        }
    };

    handleOpenPreviousDocument = () => {
        const { currentDocumentNum } = this.state;
        if (currentDocumentNum > 0) {
            this.handleChangeDocument(currentDocumentNum - 1);
        }
    };

    handleOpenNextDocument = () => {
        const { currentDocumentNum, documentsTotal } = this.state;
        if (currentDocumentNum < documentsTotal - 1) {
            this.handleChangeDocument(currentDocumentNum + 1);
        }
    };

    handleChangeCurrentPage = currentPage => this.setState({ currentPage });

    updateCurrentDocument = currentDocument => {
        this.setState({ currentDocument: { ...currentDocument } });
        this.props.updateDocument(currentDocument);
    };

    handleSaveComment = newComment => {
        const { currentDocument } = this.state;
        this.props.addDocumentComment({
            document: currentDocument,
            comment: newComment,
            writer: `${this.props.agentSelf.firstName} ${this.props.agentSelf.lastName}`,
            agentId: this.props.agentSelf.id,
        });
    };

    handleRemoveComment = commentData => {
        const { currentDocument } = this.state;
        this.props.removeDocumentComment({
            document: currentDocument,
            commentData,
            writer: `${this.props.agentSelf.firstName} ${this.props.agentSelf.lastName}`,
            agentId: this.props.agentSelf.id,
        });
    };

    handleRadioBtnClick = reason => {
        this.setState({ reason });
    };

    handleDownload = () => {
        const { currentDocument } = this.state;

        this.props.downloadDocument({
            id: currentDocument.id,
            rotateDegree: currentDocument.config.rotateDegree,
            fileName: currentDocument.fileName,
        });
    };

    handlePreviewDocument = () => {
        const { currentDocument } = this.state;
        const { id: documentId } = currentDocument;
        const accessToken = getAccessToken();
        const docUrl = `${BASE_URL}/api/documents/${documentId}?token=${accessToken}&isPreview=true`;

        window.open(docUrl, '_blank');
    }

    handleChangeAddress = (newAddress) => {
        this.setState({userAddress: newAddress});
    };

    setIsEditPlayerData = (value) => {
        this.setState({isEditPlayerData: value});
    };

    setIsApproveDisabled = (value) => {
        this.setState({isApproveDisabled: value});
    };

    render() {
        const fixCurpImage = async () => {
            if (loading) {
                return;
            }
            setLoading(true);
            const url = `/documents/player/fix-curp/${currentDocument.player}/${currentDocument.id}`;
            try {
                const result = await getSync(url, {});
            } catch (e) {
                console.error('Cannot fetch last messages!');
            }
            setLoading(false);
        }

        const {
            stage,
            rejectBtnDisabled,
            currentPage,
            decline,
            currentDocument,
            reason,
            numPdfPages,
            uploadErrorMessage,
            userAddress,
            localDocCount,
            currentDocumentNum,
            documentsTotal,
            isEditPlayerData,
            isApproveDisabled,
            showPromptModal,
        } = this.state;

        const { isLoading, isDownloading, playerGeneral, playerOid, canEditByEditPlayerInfoPerm } = this.props;
        const isNotFirstDocument = currentDocumentNum > 0;
        const isNotLastDocument = currentDocumentNum < documentsTotal - 1;

        const {
            contentType,
            status: statusCapitalize,
            oid,
            comments,
            id,
        } = currentDocument || {};

        let commentsFromNewToOld = {};
        if (comments) {
            commentsFromNewToOld = Object.assign(comments).reverse();
        }


        return (
            <React.Fragment>
                <div
                    className={`pending-documents document-content page-content ${
                        this.isViewMode ? 'view-mode' : 'initial-mode'
                    }`}
                >
                    <MetaTags>
                        <title>Pending Documents - {process.env.REACT_APP_APP_NAME}</title>
                    </MetaTags>
                    <div className="container-fluid">
                        {isLoading ? (
                          <Loader size={'lg'}/>
                        ) : (
                          <>
                              {currentDocument && (
                                <Row className="document-row">
                                    {!this.isViewMode && (
                                      <Col
                                        className={`col ${'left-arrow-col'} ${
                                          isNotFirstDocument
                                            ? 'arrow-col'
                                            : ''
                                        }`}
                                      >
                                          <div
                                            className="left-arrow"
                                            onClick={
                                                this.handleCLickPreviousDocument
                                            }
                                          >
                                              {isNotFirstDocument && (
                                                <img
                                                  className="img-btn"
                                                  src={arrowLeft}
                                                  alt=""
                                                  width="24"
                                                />
                                              )}
                                          </div>
                                      </Col>
                                    )}
                                    {!uploadErrorMessage && (
                                      <Col className="col docs-col docs">
                                          {isImage(contentType) && (
                                            <div
                                              ref={this.docFrameRef}
                                              className="doc-iframe"
                                              id="doc-iframe"
                                            >
                                                <ViewImg
                                                  id={id}
                                                  currentDocument={currentDocument}
                                                  updateCurrentDocument={this.updateCurrentDocument}
                                                />
                                            </div>
                                          )}
                                          {isPdf(contentType) && (
                                              <ViewPDF
                                                  docFrameRef={this.docFrameRef}
                                                  numPdfPages={numPdfPages}
                                                  documentId={id}
                                              />
                                          )}
                                      </Col>
                                    )}
                                    {!this.isViewMode && this.props.history.location?.pathname.includes('pending-documents') && (
                                      <Col
                                        className={`col ${'right-arrow-col'} ${
                                          isNotLastDocument ? 'arrow-col' : ''
                                        }`}
                                      >
                                          <div
                                            className="right-arrow"
                                            onClick={
                                                this.handleCLickNextDocument
                                            }
                                          >
                                              {isNotLastDocument && (
                                                <img
                                                  className="img-btn"
                                                  src={arrowRight}
                                                  alt=""
                                                  width="24"
                                                />
                                              )}
                                          </div>
                                      </Col>
                                    )}

                                    <Col
                                      className={clsx("col docs-info", styles.col)}
                                    >
                                        <Card className="page-card right-page-card">
                                            <CardBody style={{ padding: '15px' }}>
                                                {/*<span className="broken-image" onClick={fixCurpImage}>*/}
                                                {/*        {currentDocument.type === 'CURP_Certificate' && 'Broken image?'}*/}
                                                {/*</span>*/}
                                                <Row>
                                                    <Col
                                                      className={clsx("base-tabs", styles.colTabs)}
                                                    >
                                                        <div
                                                          className={
                                                              clsx(styles.wrapperTab,
                                                                  currentPage === PAGES.information
                                                                  ? 'active'
                                                                  : '')
                                                          }
                                                          onClick={() =>
                                                            this.handleChangeCurrentPage(
                                                              PAGES.information
                                                            )
                                                          }
                                                        >
                                                            <img
                                                              src={
                                                                  currentPage ===
                                                                  PAGES.information
                                                                    ? lightVioletInformation
                                                                    : greyInformation
                                                              }
                                                              alt=""
                                                            />
                                                            <span>
                                                            {this.props.i18n.t(
                                                              'crm.information'
                                                            )}
                                                        </span>
                                                        </div>
                                                        <div
                                                            className={
                                                                clsx(styles.wrapperTab,
                                                                    currentPage === PAGES.information
                                                                        ? 'active'
                                                                        : '')
                                                            }
                                                          onClick={() =>
                                                            this.handleChangeCurrentPage(
                                                              PAGES.activity
                                                            )
                                                          }
                                                        >
                                                            <img
                                                              src={
                                                                  currentPage ===
                                                                  PAGES.activity
                                                                    ? lightVioletActivity
                                                                    : greyActivity
                                                              }
                                                              alt=""
                                                            />
                                                            <span>
                                                            {this.props.i18n.t(
                                                              'crm.activity'
                                                            )}
                                                        </span>
                                                        </div>

                                                        <div
                                                            className={
                                                                clsx(styles.wrapperTab,
                                                                    currentPage === PAGES.meta
                                                                        ? 'active'
                                                                        : '')
                                                            }
                                                            onClick={() =>
                                                                this.handleChangeCurrentPage(
                                                                    PAGES.meta
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    currentPage ===
                                                                    PAGES.meta
                                                                        ? lightVioletInformation
                                                                        : greyInformation
                                                                }
                                                                alt=""
                                                            />
                                                            <span>
                                                            {this.props.i18n.t(
                                                                'crm.meta'
                                                            )}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {
                                                    !this.props.history.location?.pathname.includes('player-list') &&
                                                        <div className={styles.wrapperInfoPlayer}>
                                                            <span className="me-2 profile-info-id">
                                                                <span className="me-2">ID {getGuidShortFormat(id)} </span>
                                                                <ClipboardButton text={id} />
                                                            </span>
                                                            <ButtonOpenPlayerView
                                                                playerOid={playerOid || playerGeneral?.oid}
                                                                label={`${this.props.i18n.t("crm.playerID")} - ${playerOid || playerGeneral?.oid}`}
                                                                classNameWrapper={"wrapperButtonOpenPlayerView"}
                                                                openInNewTab
                                                                showImage={false}
                                                            />
                                                        </div>
                                                }

                                                {currentPage ===
                                                PAGES.information && (
                                                  <>
                                                      {uploadErrorMessage && (
                                                        <ErrorMessage>
                                                            {uploadErrorMessage}
                                                        </ErrorMessage>
                                                      )}
                                                      <DocInfoArea
                                                        payment={this.props.withdrawal}
                                                        currentDocument={currentDocument}
                                                        isEditPlayerData={isEditPlayerData}
                                                        setIsEditPlayerData={this.setIsEditPlayerData}
                                                        stage={stage}
                                                        onChangeAddress={this.handleChangeAddress}
                                                        setIsApproveDisabled={this.setIsApproveDisabled}
                                                        userAddress={userAddress}
                                                        playerOid={playerOid || playerGeneral?.oid}
                                                        isPopupOpen={this.props.isPopupOpen}
                                                        canEditByEditPlayerInfoPerm={canEditByEditPlayerInfoPerm}
                                                      />
                                                      <div
                                                          className={clsx('w-100 d-flex align-items-center download-btn', {
                                                              'isEditMode': isEditPlayerData,
                                                          })}
                                                      >
                                                          <Button
                                                              className={clsx("btn outlined decline-btn", {
                                                                  "d-none": isEditPlayerData,
                                                              })}
                                                              outline
                                                              disabled={isDisableDeclineDocumentBtn(currentDocument) || !canEditByEditPlayerInfoPerm}
                                                              onClick={event => {
                                                                  this.setState(
                                                                      {
                                                                          decline:
                                                                              !decline
                                                                      }
                                                                  );
                                                              }}
                                                          >
                                                              <img
                                                                  alt=""
                                                                  src={
                                                                      thinCross
                                                                  }
                                                                  width={16}
                                                                  height={16}
                                                              />
                                                              {this.props.i18n.t(
                                                                  'crm.decline'
                                                              )}
                                                          </Button>
                                                          <Button
                                                              className={clsx("btn filled approve-btn", {
                                                                  "d-none": isEditPlayerData,
                                                              })}
                                                              disabled={isApproveDisabled || !canEditByEditPlayerInfoPerm}
                                                              onClick={
                                                                  this
                                                                      .handleSendApprove
                                                              }
                                                          >
                                                              <img
                                                                  alt=""
                                                                  src={
                                                                      thinSubmit
                                                                  }
                                                                  width={16}
                                                                  height={16}
                                                              />
                                                              {this.props.i18n.t(
                                                                  'crm.approve'
                                                              )}
                                                          </Button>
                                                          <button
                                                              className={clsx("btn btn-rounded btn-primary app-btn-only-img-sm mr-5", {
                                                                  "button-disable": isDownloading
                                                              })}
                                                              onClick={() => this.handlePreviewDocument()}
                                                          >
                                                              <img src={eyePreview} className='mb-0' alt="preview" />
                                                          </button>
                                                          <button
                                                              className={clsx("btn btn-rounded btn-primary app-btn-only-img-sm", {
                                                                  "button-disable": isDownloading
                                                              })}
                                                              onClick={() => this.handleDownload()}
                                                          >
                                                              <img src={download} alt="" />
                                                          </button>
                                                      </div>
                                                      {
                                                          !isEditPlayerData && (
                                                              <Row className="page-btns w-100">
                                                                  {decline && (
                                                                      <Col className="rejected ml-10">
                                                                          <div className="reason-title">
                                                                              {this.props.i18n.t(
                                                                                  'crm.indicateTheReason'
                                                                              )}
                                                                          </div>

                                                                          <FormGroup
                                                                              className="radio-group"
                                                                              check
                                                                          >
                                                                              <Label
                                                                                  check
                                                                              >
                                                                                  <Input
                                                                                      className="radio-button"
                                                                                      type="radio"
                                                                                      name="radio1"
                                                                                      checked={
                                                                                          reason ===
                                                                                          REASONS.expired
                                                                                      }
                                                                                      onClick={() =>
                                                                                          this.handleRadioBtnClick(
                                                                                              REASONS.expired
                                                                                          )
                                                                                      }
                                                                                      style={
                                                                                          reason ===
                                                                                          REASONS.expired
                                                                                              ? {
                                                                                                  backgroundImage: `url(${radioBackground})`,
                                                                                                  backgroundColor:
                                                                                                      'initial'
                                                                                              }
                                                                                              : {}
                                                                                      }
                                                                                  />{' '}
                                                                                  {this.props.i18n.t(
                                                                                      'crm.expired'
                                                                                  )}
                                                                              </Label>
                                                                          </FormGroup>
                                                                          <FormGroup
                                                                              className="radio-group"
                                                                              check
                                                                          >
                                                                              <Label
                                                                                  check
                                                                              >
                                                                                  <Input
                                                                                      className="radio-button"
                                                                                      type="radio"
                                                                                      name="radio1"
                                                                                      checked={
                                                                                          reason ===
                                                                                          REASONS.cancelledByPlayer
                                                                                      }
                                                                                      onClick={() =>
                                                                                          this.handleRadioBtnClick(
                                                                                              REASONS.cancelledByPlayer
                                                                                          )
                                                                                      }
                                                                                      style={
                                                                                          reason ===
                                                                                          REASONS.cancelledByPlayer
                                                                                              ? {
                                                                                                  backgroundImage: `url(${radioBackground})`,
                                                                                                  backgroundColor:
                                                                                                      'initial'
                                                                                              }
                                                                                              : {}
                                                                                      }
                                                                                  />{' '}
                                                                                  {this.props.i18n.t(
                                                                                      'crm.canceledByPlayer'
                                                                                  )}
                                                                              </Label>
                                                                          </FormGroup>
                                                                          <FormGroup
                                                                              className="radio-group"
                                                                              check
                                                                          >
                                                                              <Label
                                                                                  check
                                                                              >
                                                                                  <Input
                                                                                      className="radio-button"
                                                                                      type="radio"
                                                                                      name="radio1"
                                                                                      checked={
                                                                                          reason ===
                                                                                          REASONS.otherReason
                                                                                      }
                                                                                      onClick={() =>
                                                                                          this.handleRadioBtnClick(
                                                                                              REASONS.otherReason
                                                                                          )
                                                                                      }
                                                                                      style={
                                                                                          reason ===
                                                                                          REASONS.otherReason
                                                                                              ? {
                                                                                                  backgroundImage: `url(${radioBackground})`,
                                                                                                  backgroundColor:
                                                                                                      'initial'
                                                                                              }
                                                                                              : {}
                                                                                      }
                                                                                  />{' '}
                                                                                  {this.props.i18n.t(
                                                                                      'crm.otherReason'
                                                                                  )}
                                                                              </Label>
                                                                          </FormGroup>

                                                                          {reason ===
                                                                          REASONS.otherReason && (
                                                                              <Input
                                                                                  className="reason-text"
                                                                                  type="textarea"
                                                                                  name="text"
                                                                                  placeholder={this.props.i18n.t(
                                                                                      'crm.writeSomething'
                                                                                  )}
                                                                                  onChange={
                                                                                      this
                                                                                          .handleReasonTextChange
                                                                                  }
                                                                                  disabled={
                                                                                      reason !==
                                                                                      REASONS.otherReason
                                                                                  }
                                                                              />
                                                                          )}

                                                                          <Button
                                                                              className="btn outlined"
                                                                              outline
                                                                              onClick={event => {
                                                                                  this.handleSendRejectReason();
                                                                              }}
                                                                              disabled={
                                                                                  reason ===
                                                                                  REASONS.otherReason &&
                                                                                  !this
                                                                                      .state
                                                                                      .reasonText
                                                                              }
                                                                          >
                                                                              <img
                                                                                  alt=""
                                                                                  src={
                                                                                      thinCross
                                                                                  }
                                                                                  width={
                                                                                      16
                                                                                  }
                                                                                  height={
                                                                                      16
                                                                                  }
                                                                              />
                                                                              {this.props.i18n.t(
                                                                                  'crm.submit'
                                                                              )}
                                                                          </Button>
                                                                      </Col>
                                                                  )}
                                                              </Row>
                                                          )
                                                      }
                                                  </>
                                                )}
                                                {currentPage === PAGES.activity && (
                                                  <ActivityTab
                                                    pageMode={this.mode}
                                                    comments={commentsFromNewToOld}
                                                    onSaveComment={
                                                        this.handleSaveComment
                                                    }
                                                    onRemoveComment={
                                                        this.handleRemoveComment
                                                    }
                                                    currentDocument={
                                                        currentDocument
                                                    }
                                                  />
                                                )}
                                                {currentPage === PAGES.meta && (
                                                    <DocMetaDate
                                                        currDocument={currentDocument}
                                                    />
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                              )}
                              {!currentDocument && (
                                <Row className="document-row mx-2">
                                    Document not found
                                </Row>
                              )}
                          </>
                        )}
                    </div>
                </div>
                {showPromptModal.open && (
                    <CustomPromptModal
                        onConfirm={this.handleConfirmPrompt}
                        onCancel={this.handleCancelPrompt}
                    />
                )}
                {isEditPlayerData && (
                    <RouterPrompt
                        when={isEditPlayerData}
                        onOK={() => true}
                        onCancel={() => false}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        error,
        playerDocuments,
        pendingDocuments,
        currentDocumentData,
        lastDocumentUpdatedDate,
        lastCommentCreateDate,
        lastCommentRemoveDate,
        isLoading,
        isDownloading,
        isChangingStatus,
    } = state.Documents;

    const {
        total
    } = state.PlayerList

    const {
        playerGeneral,
        isLoading: isPlayerLoading,
    } = state.PlayerGeneral;

    const { agentSelf } = state.AgentSelf;

    let playerOid;
    const { withdrawal } = state.PendingWithdrawalDetailed;
    if (withdrawal) {
        const player = withdrawal.player;
        if (player) {
            playerOid = player.oid;
        }
    }

    return {
        playerGeneral,
        isPlayerLoading,
        error,
        playerDocuments,
        pendingDocuments,
        currentDocumentData,
        lastDocumentUpdatedDate,
        lastCommentCreateDate,
        lastCommentRemoveDate,
        isLoading,
        isDownloading,
        isChangingStatus,
        agentSelf,
        playerOid,
        total,
        withdrawal,
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getFirstPendingDocuments,
        getDocument,
        approveDocument,
        rejectDocument,
        getDocumentData,
        updateDocument,
        addDocumentComment,
        removeDocumentComment,
        getInfo,
        downloadDocument,
        getPlayerGeneral,
        clearPlayerGeneral,
        getPlayerKYCCount,
    })(withAlert()(withRouter(withTranslation()(DocumentView))))
);
