import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';

import i18n from '../../i18n';
import {
    cancelErrors,
    changeAffiliate,
    changeAffiliateStatus,
    getAffiliate,
    setEdit
} from '../../store/affiliates/actions';

import AffiliateMainCard from './AffiliateMainCard';
import AffiliateDetailedCard from './AffiliateDetailedCard';

import useToggleModal from '../../hooks/useToggleModal';
import useAlertService from '../../hooks/useAlertService';

import GenerateLinkModal from './GenerateLinkModal';
import { RouterPrompt } from '../../common/prompt/RouterPrompt';

import ButtonsPanel from '../CRM/general/ButtonsPanel';

import './affiliates.scss';
import PageTitle from "../../components/PageTitle";
import MetaTags from "react-meta-tags";

// TODO fix status
const AffiliatePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        isLoading,
        currentAffiliate,
        errors,
        errorList,
        isEdit,
        isStatusLoading,
        successMessage
    } = useSelector(state => state.Affiliates);
    const { showSuccess, showError } = useAlertService();
    const [state, setState] = useState(currentAffiliate);

    const [isShowLinkModal, handleShowLinkModal, handleHideLinkModal] =
        useToggleModal(false);

    const onEdit = useCallback(value => {
        dispatch(setEdit(value));
    }, []);

    const getErrors = useCallback(
        key => {
            return errors[key];
        },
        [errors]
    );

    const handleCancel = useCallback(() => {
        setState(currentAffiliate);
        dispatch(cancelErrors());
    }, [state]);

    const handleChangeStatusSave = useCallback(
        dto => {
            // dispatch(updatePlayerStatus({ id: state.data.id, dto }))
        },
        [state]
    );

    const handleChange = useCallback(
        name => {
            return value => {
                setState(prevState => ({ ...prevState, [name]: value }));
            };
        },
        [state]
    );

    const handleSave = useCallback(() => {
        dispatch(changeAffiliate(state));

        return true;
    }, [state]);

    const handleChangeStatus = useCallback(
        status => () => {
            dispatch(changeAffiliateStatus({ status, id }));
        },
        [state.status]
    );

    useEffect(() => {
        dispatch(getAffiliate(id));
    }, [id]);

    useEffect(() => {
        setState(currentAffiliate);
    }, [currentAffiliate]);

    useEffect(() => {
        if (successMessage) {
            showSuccess(successMessage);
        }

        return dispatch(cancelErrors());
    }, [successMessage]);

    useEffect(() => {
        if (Array.isArray(errorList)) {
            errorList.forEach((err) => {
                showError(i18n.t(`crm.alerts.${err}`));
            });
        } else if (errorList) {
            showError(error?.message);
        }
        dispatch(cancelErrors());
    }, [errorList]);

    return (
        <>
            <div className="page-content affiliates-page">
                <MetaTags>
                    <title>{i18n.t('affiliates.title.affiliateView')} - {process.env.REACT_APP_APP_NAME}</title>
                </MetaTags>
                <Container fluid>
                    <Row className={"m-0"}>
                        <Col lg="12">
                            <AffiliateMainCard
                                state={state}
                                isLoading={isLoading}
                                handleChangeStatus={handleChangeStatus}
                                handleChange={handleChange}
                                isEdit={isEdit}
                                onEdit={onEdit}
                                errors={errors}
                                getErrors={getErrors}
                                isStatusLoading={isStatusLoading}
                            />

                            <AffiliateDetailedCard
                                state={state}
                                isLoading={isLoading}
                                handleShowLinkModal={handleShowLinkModal}
                                handleChange={handleChange}
                                onEdit={onEdit}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            {isEdit && (
                <ButtonsPanel onCancel={handleCancel} onSave={handleSave} />
            )}

            {isShowLinkModal && (
                <GenerateLinkModal onHide={handleHideLinkModal} selectedAffiliate={state} />
            )}

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}
        </>
    );
};

export default withRouter(AffiliatePage);
