import { call, takeEvery, put } from '@redux-saga/core/effects';
import {
    getBrandSettingsApi,
    updateBrandSettingsApi,
    getSmarticoSettingsApi,
    getBrandTagsApi,
    getAntiFraudRulesApi,
    getAntiFraudActionsApi,
    updateAntiFraudRulesApi,
    getVipLevelConfig,
    getCoolOfDataApi,
    updateCoolOffDataApi,
    deleteCoolOffDataApi,
} from '../../../helpers/backend_helper';
import {
    brandSettingsPageError,
    fetchBrandSettingsPageSuccess,
    changeBrandSettingsPageSuccess,
    getSmarticoSettingsSuccess,
    getSmarticoSettingsError,
    getBrandTagsSuccess,
    getBrandTagsError,
    getAntiFraudRulesSuccess,
    getAntiFraudRulesError,
    getAntiFraudActionsSuccess,
    getAntiFraudActionsError,
    updateAntiFraudRulesSuccess,
    updateAntiFraudRulesFail,
    fetchVipLevelConfigSuccess,
    getCoolOffDataSuccess,
    getCoolOffDataError,
    updateCoolOffDataSuccess,
    updateCoolOffDataError,
    deleteCoolOffDataSuccess,
    deleteCoolOffDataError,
} from './actions';
import {
    CHANGE_BRAND_SETTINGS_PAGE,
    FETCH_BRAND_SETTINGS_PAGE,
    GET_SMARTICO_SETTINGS,
    GET_BRAND_TAGS,
    GET_ANTI_FRAUD_RULES,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS,
    UPDATE_ANTI_FRAUD_RULES,
    FETCH_VIP_LEVEL_CONFIG, GET_COOL_OFF_DATA, UPDATE_COOL_OFF_DATA, DELETE_COOL_OFF_DATA,
} from './actionTypes';

import i18n from '../../../i18n';

function* fetchBrandSettingsPage() {
    try {
        const response = yield call(getBrandSettingsApi);

        yield put(fetchBrandSettingsPageSuccess(response.data));
    } catch (error) {
        yield put(brandSettingsPageError({ message: error }));
    }
}

function* fetchVipLevelConfig() {
    try {
        const response = yield call(getVipLevelConfig);

        yield put(fetchVipLevelConfigSuccess(response.data));
    } catch (error) {
        yield put(brandSettingsPageError({ message: error }));
    }
}

function* fetchSmarticoSettings() {
    try {
        const response = yield call(getSmarticoSettingsApi);

        yield put(getSmarticoSettingsSuccess(response.data));
    } catch (error) {
        yield put(getSmarticoSettingsError({ message: error }));
    }
}

function* fetchBrandTags() {
    try {
        const response = yield call(getBrandTagsApi);

        yield put(getBrandTagsSuccess(response.data));
    } catch (error) {
        yield put(getBrandTagsError({ message: error }));
    }
}

function* fetchAntiFraudRules() {
    try {
        const response = yield call(getAntiFraudRulesApi);

        yield put(getAntiFraudRulesSuccess(response.data));
    } catch (error) {
        yield put(getAntiFraudRulesError({ message: error }));
    }
}

function* fetchAntiFraudActions() {
    try {
        const response = yield call(getAntiFraudActionsApi);

        yield put(getAntiFraudActionsSuccess(response.data));
    } catch (error) {
        yield put(getAntiFraudActionsError({ message: error }));
    }
}

function* updateAntiFraudRules(dto) {
    try {
        const response = yield call(updateAntiFraudRulesApi, dto.payload);

        yield put(updateAntiFraudRulesSuccess(response));
    } catch (error) {
        yield put(updateAntiFraudRulesFail(error));
    }
}

function* fetchCoolOffData() {
    try {
        const response = yield call(getCoolOfDataApi);

        yield put(getCoolOffDataSuccess(response.data));
    } catch (error) {
        yield put(getCoolOffDataError({ message: error }));
    }
}

function* updateCoolOfData(dto) {
    try {
        const response = yield call(updateCoolOffDataApi, dto.payload);

        yield put(updateCoolOffDataSuccess(response));
    } catch (error) {
        yield put(updateCoolOffDataError(error));
    }
}

function* deleteCoolOffData(dto) {
    try {
        const response = yield call(deleteCoolOffDataApi, dto.payload);

        yield put(deleteCoolOffDataSuccess(response));
    } catch (error) {
        yield put(deleteCoolOffDataError(error));
    }
}

function* changeBrandSettingsPage({ payload: { data, successMessage } }) {
    try {
        const response = yield call(updateBrandSettingsApi, data);

        if (response) {
            yield put(changeBrandSettingsPageSuccess(response, successMessage));
        } else {
            yield put(
                brandSettingsPageError({
                    message: i18n.t('admin.сhangeDataFailed')
                })
            );
        }
    } catch (error) {
        yield put(brandSettingsPageError({ message: error }));
    }
}

function* brandPageSaga() {
    yield takeEvery(FETCH_BRAND_SETTINGS_PAGE, fetchBrandSettingsPage);
    yield takeEvery(FETCH_VIP_LEVEL_CONFIG, fetchVipLevelConfig);
    yield takeEvery(CHANGE_BRAND_SETTINGS_PAGE, changeBrandSettingsPage);
    yield takeEvery(GET_SMARTICO_SETTINGS, fetchSmarticoSettings);
    yield takeEvery(GET_BRAND_TAGS, fetchBrandTags);

    yield takeEvery(GET_ANTI_FRAUD_RULES, fetchAntiFraudRules);
    yield takeEvery(GET_AVAILABLE_ANTI_FRAUD_ACTIONS, fetchAntiFraudActions);
    yield takeEvery(UPDATE_ANTI_FRAUD_RULES, updateAntiFraudRules);

    yield takeEvery(GET_COOL_OFF_DATA, fetchCoolOffData);
    yield takeEvery(UPDATE_COOL_OFF_DATA, updateCoolOfData);
    yield takeEvery(DELETE_COOL_OFF_DATA, deleteCoolOffData);
}

export default brandPageSaga;
