import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import Breadcrumb from "../../common/components/Breadcrumb";
import i18n from "../../i18n";
import CustomModal from '../../common/components/CustomModal';
import PromotionsFilter from "./PromotionsFilter/PromotionsFilter";

import "./promotions.scss";

import { connect } from "react-redux";
import { clearCurrentPromotion, getPromotions } from "../../store/promotions/actions";
import { DIRECTION } from "../../constants/layout";
import MetaTags from "react-meta-tags";
import clsx from "clsx";
import {
  ACTIVE_PROMOTIONS,
  INACTIVE_PROMOTIONS
} from "./common/promotionConstants";
import { SITE_PERMISSION } from "../../common/constants/common";
import SelectTableView from "../Content/components/SelectTableView";
import PromotionsTable from "./PromotionsTable/PromotionsTable";

import group_10873 from "../../assets/images/common/Group 10873.svg";
import settings from "../../assets/images/layout/settings.svg";
import Loader from "../../common/components/Loader";


const promotionListPageInitState = {
    activePage: 1,
    pageCount: 0,
    limit: 20,
    offset: 0,
    currentColumn: 'created_at',
    currentOrder: 'DESC',
    showPromotionFilterModal: false,
    isFilterSelected: false,
    status: ACTIVE_PROMOTIONS,
    type: null,
    name: null,
    ids: null,
    byAgent: false,
}

class PromotionsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...promotionListPageInitState,
      agentPermissions: props.agentSelf?.permissions || [],
      isShowTableSettings: false,
    };
  }

  componentDidMount() {
    this.props.clearCurrentPromotion();
    this.props.getPromotions({
      offset: this.state.offset,
      limit: this.state.limit,
      sort: 'created_at',
      order: 'DESC',
      status: this.state.status,
      byAgent: false,
    });

    this.setState({agentPermissions: this.props.agentSelf?.permissions || []})
  }

  componentDidUpdate(prevProps, prevState) {
    const {promotions, isCreating} = this.props;

    if (prevState.limit !== this.state.limit) {
      this.setState({
        pageCount: Math.ceil(promotions?.total / this.state.limit),
      })
      this.handleChangePage(1)
    }

    if (prevProps.agentSelf !== this.props.agentSelf) {
      this.setState({agentPermissions: this.props.agentSelf?.permissions || []});
    }

    if ((promotions && promotions?.total !== prevProps.promotions?.total)
          || (promotions?.total && !prevState.pageCount)) {
      this.setState({
        pageCount: Math.ceil(promotions?.total / this.state.limit),
        activePage: 1
      })
    }

    if (prevState.status !== this.state.status ||
        prevState.type !== this.state.type ||
        prevState.name !== this.state.name ||
        prevState.ids !== this.state.ids ||
        prevState.byAgent !== this.state.byAgent
    ) {
      this.props.getPromotions({
        offset: this.state.offset,
        limit: this.state.limit,
        sort: 'created_at',
        order: 'DESC',
        status: this.state.status,
        type: this.state.type,
        name: this.state.name,
        ids: this.state.ids?.split(',').map(id => id.trim()),
        byAgent: this.state.byAgent,
      })
      this.setState({isFilterSelected: this.setFilterVisibility(this.state)})
    }

    if (prevProps.isCreating && isCreating !== prevProps.isCreating) {
      this.props.getPromotions({
        offset: this.state.offset,
        limit: this.state.limit,
        sort: 'created_at',
        order: 'DESC',
        status: this.state.status,
        byAgent: this.state.byAgent,
      })
    }
  }

  setFilterVisibility = (state) => {
    return (
        promotionListPageInitState.status !== state.status ||
        promotionListPageInitState.type !== state.type ||
        promotionListPageInitState.name !== state.name ||
        promotionListPageInitState.ids !== state.ids ||
        promotionListPageInitState.byAgent !== state.byAgent
    )
  }

  setPromotionsFilters = (filters) => {
    this.setState((prevState) => ({
      ...prevState,
      ...filters,
    }))
  }

  changePromotionsStatus = () => {
    if (this.state.status === ACTIVE_PROMOTIONS) {
      this.setState({
        status: INACTIVE_PROMOTIONS
      })
    } else {
      this.setState({
        status: ACTIVE_PROMOTIONS
      })
    }
    this.setState({activePage: 1})
  }

  handleAdd = () => {
    const {agentPermissions} = this.state;
    if (agentPermissions.includes(SITE_PERMISSION.Promotion__Edit_Promotions)) {
      this.props.clearCurrentPromotion()
      this.props.history.push(`/promotions/new`)
    }
  }

  handleTogglePromotionsFilter = () => {
    const { showPromotionFilterModal } = this.state;
    this.setState({ showPromotionFilterModal: !showPromotionFilterModal });
  };

  handleResetFilter = () => {
    this.setState({...promotionListPageInitState});
  };

  onChangeRecords = (value) => {
    this.setState(prev => ({
      ...prev,
      limit: value,
      offset: 0,
    }));
  };

  handleChangePage = (page) => {
    this.setState({
      activePage: page,
    });
    this.props.getPromotions({
      offset: (page - 1) * this.state.limit,
      limit: this.state.limit,
      sort: this.state.currentColumn,
      order: this.state.currentOrder,
      status: this.state.status,
      type: this.state.type,
      name: this.state.name,
      ids: this.state.ids?.split(',').map(id => id.trim()),
      byAgent: this.state.byAgent,
    });
  }

  handleSortPromotions = (column, direction = DIRECTION.ASC) => {
    switch (column.label) {
      case ('Promotion ID'): {
        column = 'id';
        break;
      }
      case ('Created time'): {
        column = 'createdAt';
        break;
      }
      case ('Last updated'): {
        column = 'lastChangedAt';
        break;
      }
      case ('Name'): {
        column = 'name';
        break;
      }
      case ('Type'): {
        column = 'type';
        break;
      }
      case ('Status'): {
        column = 'status';
        break;
      }
      default: {
        column = 'createdAt';
      }
    }

    this.setState({
      activePage: 1,
      currentColumn: column,
      currentOrder: direction,
    })

    this.props.getPromotions({
      offset: this.state.offset,
      limit: this.state.limit,
      sort: column,
      order: direction,
      status: this.state.status,
      type: this.state.type,
      name: this.state.name,
      ids: this.state.ids?.split(',').map(id => id.trim()),
      byAgent: this.state.byAgent,
    })
  }

  render() {
    const { promotions, isLoading } = this.props;
    const {
      pageCount,
      activePage,
      status,
      showPromotionFilterModal,
      type,
      name,
      byAgent,
      isFilterSelected,
      agentPermissions,
      isShowTableSettings,
    } = this.state;

    const canCreatePromotion = agentPermissions.includes(SITE_PERMISSION.Promotion__Edit_Promotions);

    return (
      <div className="page-content promotion-page">
        <Container fluid>
          <Breadcrumb breadcrumbItem={i18n.t('promotions.promotions')}/>

          <MetaTags>
            <title>{i18n.t('promotions.promotions')} - {process.env.REACT_APP_APP_NAME}</title>
          </MetaTags>

          <Row>
            <div className="d-flex justify-content-between align-items-center page-title-center-buttons m-0">
              <div className="buttons-wrapper">
                <div className="left-buttons">
                  <img role="button"
                       className={clsx("add-button", {
                         'cannot-create-promo': !canCreatePromotion,
                       })}
                       src={group_10873}
                       alt=""
                       onClick={this.handleAdd}
                  />

                  <div className="left-buttons__row">
                    {isFilterSelected &&
                        <button onClick={this.handleResetFilter}
                                className='btn btn-rounded btn-light btn-show-filter'>
                          {i18n.t('crm.resetFilter')}
                        </button>
                    }
                    <button onClick={this.handleTogglePromotionsFilter} className='btn btn-rounded btn-primary btn-show-filter'>
                      {i18n.t('crm.addFilterByPromo')}
                    </button>
  
                    <button className="btn btn-rounded btn-primary status-button"
                            onClick={this.changePromotionsStatus}
                    >
                      {i18n.t(status === ACTIVE_PROMOTIONS ?
                          'promotions.button.outdated' :
                          'promotions.button.openPromotions'
                      )}
                    </button>
                  </div>
                </div>

                <button
                    className="btn settings-button"
                    onClick={() => this.setState({ isShowTableSettings: true })}
                >
                  <img src={settings} alt="Table's Settings" />
                </button>
              </div>
            </div>
          </Row>

          <Row>
            {promotions ?
              <Col lg="12">
                <Card>
                  <CardBody>
                    {promotions?.result?.length > 0 ? (
                        <>
                        <div className="app-table-wrapper">
                          <PromotionsTable
                              list={promotions.result}
                              onSortPromotions={this.handleSortPromotions}
                              isShowTableSettings={isShowTableSettings}
                              onCloseTableSettings={() => this.setState({ isShowTableSettings: false })}
                          />
                        </div>
                        <div className='mt-5 mobile-pagination'>
                          <SelectTableView
                              pageCount={pageCount}
                              activePage={activePage}
                              recordsCount={promotions?.total}
                              onChangePage={this.handleChangePage}
                              pageSize={this.state.limit}
                              isSticky={true}
                              onChange={this.onChangeRecords}
                          />
                        </div>
                        </>
                    ) : (
                        <div className='empty-promo-table'>
                          {i18n.t('crm.emptyTable')}
                        </div>
                    )}
                  </CardBody>
                </Card>
              </Col> :
              <Loader size='lg' />
            }
          </Row>
          {showPromotionFilterModal &&
              <CustomModal
                  className='modal-filter'
                  titleText={i18n.t('crm.title.promotionsFilter')}
                  isOpen={showPromotionFilterModal}
                  onToggle={() => this.handleTogglePromotionsFilter()}
                  btnText={'apply'}
                  bodyRender={() => (
                      <PromotionsFilter
                          filters={{type, status, name, byAgent}}
                          setFilters={this.setPromotionsFilters}
                          setOpen={this.handleTogglePromotionsFilter}
                          isSportMode={false}
                      />
                  )}
                  withFooter={false}
                  withApplyButton={false}
              />
          }
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    isLoading,
    isCreating,
    promotions,
    activePage,
    error,
  } = state.Promotions;
  const {
    agentSelf,
  } = state.AgentSelf;
  return {
    isLoading,
    isCreating,
    promotions,
    activePage,
    error,
    agentSelf,
  };
}

export default React.memo(withRouter(
  connect(mapStateToProps, {
    getPromotions,
    clearCurrentPromotion,
  })(PromotionsListPage)
));
