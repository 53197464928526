import React from 'react';
import { Col, Row } from 'reactstrap';
import AgentViewMainCard from './AgentViewMainCard';
import { useSelector } from 'react-redux';
import Loader from '../../common/components/Loader';
import AgentViewTablesCard from './AgentViewTablesCard';

function AgentView() {
    const { agentSelf } = useSelector(state => state.AgentSelf);

    if (!agentSelf) {
        return <Loader size={'sm'}/>;
    }

    return (
        <>
            <Row className={"m-0"}>
                <Col lg="12">
                    <AgentViewMainCard />
                </Col>
            </Row>
            <Row className={"m-0"}>
                <Col lg="12">
                    <AgentViewTablesCard agent={agentSelf} />
                </Col>
            </Row>
        </>
    );
}

export default React.memo(AgentView);
