import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { FIELD_TYPE } from '../../../common/constants/common';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import Formatter from "../../../utils/Formatter";
import addIcon from "../../../assets/images/common/addIcon.svg";
import i18n from "../../../i18n";
import { Button } from "reactstrap";
import AddSiteFieldModal from "../modal/AddSiteFieldModal";
import clsx from "clsx";
import remove from "../../../assets/images/layout/remove-blue.svg";
import edit from "../../../assets/images/layout/edit.svg";
import expand from "../../../assets/images/layout/expand-more.svg";
import collapse from "../../../assets/images/layout/collapse.svg";
import CustomModal from "../../../common/components/CustomModal";
import EditSiteFieldModal from "../modal/EditSiteFieldModal";
import download from "../../../assets/images/common/download.svg";
import upload from "../../../assets/images/common/Upload.svg";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

function toEnglishRules(str = '') {
    return str.replace(/([A-Z])/g, ' $1').toLowerCase().trim().replace(/^\w/, (c) => c.toUpperCase());
}

function getInputTypeFromPropertyType(propertyType) {
    switch (propertyType) {
        case 'string':
            return A_COMMON_DATA_FIELD.TEXT;
        case 'number':
            return A_COMMON_DATA_FIELD.NUMBER;
        case 'boolean':
            return A_COMMON_DATA_FIELD.CHECK_BOX;
        default:
            return A_COMMON_DATA_FIELD.TEXT;
    }
}

const PAGES_WITH_SHORT_INPUTS = ['promoSettings'];

function GenericForm({
    json,
    changes,
    property,
    onInputChange,
    onRemoveField,
    onChangeArrayElem,
    onAddArrayElem,
    onEditFieldName,
    onExportSettings = () => {},
    onImportSettings = () => {},
    onExportSettingsField = () => {},
    onImportSettingsField = () => {},
}) {
    const isShortInputs = useMemo(
        () => PAGES_WITH_SHORT_INPUTS.includes(property),
        [property]
    );

    const [parents, setParents] = useState([]);
    const [currentField, setCurrentField] = useState('');
    const [currentChain, setCurrentChain] = useState([]);
    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [showEditFieldModal, setShowEditFieldModal] = useState(false);
    const [removeFieldModalVisibility, setRemoveFieldModalVisibility] = useState(false);
    const [valueToRemove, setValueToRemove] = useState('');
    const [structure, setStructure] = useState({});
    const [isArray, setIsArray] = useState(false);

    const { t } = useTranslation();

    function setNestedValue(obj, keys, value) {
        let _obj = obj;
        const originalJSON = {
            [property]: json,
        }
        let _json = originalJSON;

        for (let i = 0; i < keys.length - 1; i++) {
            _json = _json[keys[i]];
            _obj = _obj[keys[i]] = _obj[keys[i]] || (Array.isArray(_json) ? [] : {});
        }
        _obj[keys[keys.length - 1]] = value;
        return obj;
    }

    const handleNestedInputChange = (field, value, parents) => {
        let _parents = parents;
        if(_parents.length > 0) {
            if (_parents[0] === property) {
                _parents = _parents.slice(1);
            }
            if(_parents[_parents.length - 1] === property) {
                _parents = _parents.slice(0, -1);
            }
        }

        if(_parents.length > 0 || field !== property) {
            _parents.push(field);
            _parents.reverse();
        }

        _parents.push(property);
        _parents.reverse();

        const _changes = setNestedValue(changes || {}, [..._parents], value);
        onInputChange(property, _changes);
    };

    const handleShowAddField = (parents, field, isArray = false) => {
        setParents(parents);
        setCurrentField(field);
        setIsArray(isArray);

        setShowAddFieldModal(true);
    };

    const handleShowEditField = (parents, field) => {
        setParents(parents);
        setCurrentField(field);

        setShowEditFieldModal(true);
    };

    const handleConfirmEditField = (newName) => {
        onEditFieldName([property, ...parents, currentField], newName);

        setShowEditFieldModal(false);
    }

    const handleAddField = useCallback((fieldType, fieldName) => {
        setShowAddFieldModal(false);
        const chain = [
            property,
            ...parents,
            ...(currentField ? [currentField] : []),
            fieldName,
        ];

        let defaultValue = 0;
        switch (fieldType) {
            case FIELD_TYPE.STRING:
                defaultValue = 'New value';
                break;
            case FIELD_TYPE.NUMBER:
                defaultValue = 0;
                break;
            case FIELD_TYPE.BOOLEAN:
                defaultValue = false;
                break;
            case FIELD_TYPE.ARRAY:
                defaultValue = [];
                break;
            case FIELD_TYPE.OBJECT:
                defaultValue = isArray
                    ? {
                        field: 'Value 1'
                    }
                    : {};
                break;
            default:
                defaultValue = 'New value';
                break;
        }

        if (isArray) {
            onAddArrayElem([property, ...parents, ...(currentField ? [currentField] : [])], defaultValue);
        }
        else {
            const _changes = setNestedValue(changes || {}, [...chain], defaultValue);
            onInputChange(property, _changes);
        }
    }, [parents, currentField, isArray]);

    const handleRemoveField = (fieldsChain, valueToRemove) => {
        setCurrentChain(fieldsChain);
        setValueToRemove(valueToRemove);
        setRemoveFieldModalVisibility(true);
    };

    const handleConfirmRemoveField = () => {
        onRemoveField(currentChain);
        setRemoveFieldModalVisibility(false);
    };

    const getChange = (changes, parents, currentVal) => {
        let _parents = parents.reverse();
        _parents.push(property);
        _parents = _parents.reverse();
        if (!changes) {
            return undefined;
        }
        const val = _parents.reduce((acc, key) => {
            return acc ? acc[key] : acc ?? undefined;
        }, changes);

        if(val === undefined) {
            return currentVal;
        }
        return val;
    };

    const updateStructure = useCallback((currStructureLevel, field) => {
        currStructureLevel[field] = currStructureLevel[field] ? false : {};
        setStructure(_.cloneDeep(structure));
    }, [structure]);

    const handleExpandAll = useCallback(() => {
        const structure = {};

        const prepareStructureLevel = (structure, json) => {
            Object.keys(json).forEach((key) => {
                if (typeof json[key] === 'object') {
                    structure[key] = {};
                    prepareStructureLevel(structure[key], json[key]);
                }
            })
        };
        prepareStructureLevel(structure, json);

        setStructure(structure);
    }, [json]);

    const handleCollapseAll = useCallback(() => {
        setStructure({});
    }, []);

    const handleDownloadField = (field) => {
        onExportSettingsField(field);
    };

    const handleUploadField = (field) => {
        onImportSettingsField(field);
    };

    const renderJSON = useCallback((json, level = 0, parents = [], isParentPrimitiveArray = false) => {
        const size = 20 - level * 2;
        const typeOfJSON = typeof json;
        const isJsonArray = Formatter.isValidJSONArray(json);
        if (["string", "number", "boolean"].includes(typeOfJSON)) {
            const inputType = getInputTypeFromPropertyType(typeOfJSON);
            const changesValue = getChange(changes, parents, json) ?? (json || "");
            return (
                <div style={{ marginLeft: level * 20, width: "100%" }}>
                    <ACommonDataField
                        style={{ width: `calc(100% - ${800}px)` }}
                        component={inputType}
                        label={toEnglishRules(parents[parents.length - 1])}
                        value={changesValue}
                        fulWidth={true}
                        isJson={false}
                        isArray={false}
                        handleChange={(value) => {
                            if (typeof changesValue !== 'string'
                                && typeof value !== 'boolean'
                                && value !== ''
                                && !isNaN(+value)
                            ) {
                                value = +value;
                            }

                            handleNestedInputChange(parents[parents.length - 1], value, parents.slice(0, -1));
                        }}
                    />
                </div>
            );
        }

        let currStructureLevel = structure;
        for (const field of parents) {
            currStructureLevel = currStructureLevel[field];

            if (!currStructureLevel) {
                break;
            }
        }

        if (!currStructureLevel) {
            currStructureLevel = {};
        }

        return (<>
            {json && Object.entries(json).map(([field, fieldValue]) => {
                const inputType = getInputTypeFromPropertyType(typeof fieldValue);
                const isObject = Formatter.isValidJSON(fieldValue) && !['string', 'number', 'boolean'].includes(typeof fieldValue);
                const isArray = Array.isArray(fieldValue);

                const isPrimitiveArray = isArray
                    && fieldValue.reduce((acc, el) => acc && ['string', 'number'].includes(typeof el), true);

                if (isObject && fieldValue?.hasOwnProperty('secretKey')) {
                    fieldValue = { ...fieldValue };
                    delete fieldValue.secretKey;
                }

                if (isObject || isArray) {
                    return (
                        <>
                            <div
                                className="d-flex align-items-center"
                            >
                                <div className="brandSettings__title">
                                    <span
                                        style={{
                                            marginLeft: level * 20,
                                            cursor: 'Pointer',
                                            fontSize: `${size}px`,
                                        }}
                                        onClick={() => updateStructure(currStructureLevel, field)}
                                    >
                                        {toEnglishRules(field)}
                                    </span>
    
                                    <Button
                                        color={'link'}
                                        className="add-field-btn brandSettings__arrow__btn"
                                        onClick={() => updateStructure(currStructureLevel, field)}
                                    >
                                        <img
                                            className='brandSettings__arrow'
                                            src={currStructureLevel[field] ? collapse : expand}
                                            alt={i18n.t('admin.add.field')}
                                        />
                                    </Button>
                                </div>

                                {currStructureLevel[field] && <>
                                    <Button
                                        color={'link'}
                                        className="add-field-btn"
                                        onClick={() => {
                                            if (isArray) {
                                                // Empty array
                                                if (fieldValue.length === 0) {
                                                    handleShowAddField(parents, field, true);
                                                }
                                                // With elements
                                                else {
                                                    onAddArrayElem([property, ...parents, field]);
                                                }
                                            }
                                            else {
                                                handleShowAddField(parents, field);
                                            }
                                        }}
                                    >
                                        <img src={addIcon} alt={i18n.t('admin.add.field')} />
                                    </Button>

                                    <Button
                                        color={'link'}
                                        className="edit-field-btn"
                                        onClick={() => handleShowEditField(parents, field)}
                                    >
                                        <img src={edit} alt={i18n.t('admin.add.field')} />
                                    </Button>

                                    {level === 0 && <>
                                        <Tooltip
                                            title={t('crm.import')}
                                            placement="top"
                                        >
                                            <button
                                                color={'link'}
                                                className="import-field-btn"
                                                onClick={() => handleDownloadField(field)}
                                            >
                                                <img
                                                    src={download}
                                                    alt={i18n.t('admin.import.field.data')}
                                                />
                                            </button>
                                        </Tooltip>

                                        <Tooltip
                                            title={t('crm.export')}
                                            placement="top"
                                        >
                                            <button
                                                color={'link'}
                                                className="export-field-btn"
                                                onClick={() => handleUploadField(field)}
                                            >
                                                <img
                                                    src={upload}
                                                    alt={i18n.t('admin.export.field.data')}
                                                />
                                            </button>
                                        </Tooltip>
                                    </>}

                                    <Button
                                        color={'link'}
                                        className="remove-field-btn"
                                        onClick={() => handleRemoveField([property, ...parents, field], field)}
                                    >
                                        <img
                                            className={clsx("remove")}
                                            src={remove}
                                            alt=""
                                            width={30}
                                            height={30}
                                        />
                                    </Button>
                                </>}
                            </div>

                            {currStructureLevel[field] && renderJSON(fieldValue, level + 1, [...parents, field], isPrimitiveArray)}
                        </>
                    );
                } else {
                    const fieldsChain = [...parents, field];
                    const changesValue = getChange(changes, fieldsChain, fieldValue);

                    return (
                        <div
                            className={clsx("generic-form-field", {
                                "array-element": isParentPrimitiveArray,
                            })}
                            key={field}
                            style={{
                                display: 'flex',
                                marginLeft: level * 20,
                                width: isShortInputs
                                    ? isParentPrimitiveArray
                                        ? `500px`
                                        : '580px'
                                    : `calc(100% - ${300 + level * 20}px)`,
                            }}
                        >
                            <ACommonDataField
                                style={{ width: `100%` }}
                                component={inputType}
                                label={isParentPrimitiveArray ? undefined : toEnglishRules(field)}
                                placeholder={isParentPrimitiveArray ? i18n.t('crm.enterValue') : undefined}
                                value={changesValue ?? fieldValue}
                                isJson={isObject}
                                isArray={isArray}
                                handleChange={(value) => {
                                    if (typeof value !== 'boolean' && value !== '' && !isNaN(+value)) {
                                        value = +value;
                                    }

                                    if (isParentPrimitiveArray) {
                                        onChangeArrayElem(fieldsChain, value);
                                    }
                                    else {
                                        handleNestedInputChange(field, value, parents);
                                    }
                                }}
                            />
                            <img
                                className={clsx("remove", "remove-field-img")}
                                src={remove}
                                alt=""
                                width={24}
                                height={24}
                                onClick={() => handleRemoveField(fieldsChain, isJsonArray ? fieldValue : field)}
                            />
                            {!isParentPrimitiveArray &&
                                <img
                                    className={clsx("edit", "edit-field-img")}
                                    src={edit}
                                    alt=""
                                    width={28}
                                    height={28}
                                    onClick={() => handleShowEditField(parents, field)}
                                />
                            }
                        </div>
                    );
                }
            })}

            {level === 0 &&
                <Button
                    color={'link'}
                    className="add-field-btn big-size"
                    onClick={() => {
                        if (Array.isArray(json)) {
                            // Empty array
                            if (json.length === 0) {
                                handleShowAddField([], undefined, true);
                            }
                            // With elements
                            else {
                                onAddArrayElem([property]);
                            }
                        }
                        else {
                            handleShowAddField([], undefined);
                        }
                    }}
                >
                    <img src={addIcon} alt={i18n.t('admin.add.field')} />
                </Button>
            }
        </>);
    }, [structure]);

    const isJSONPrimitiveArray = useMemo(() => Array.isArray(json)
        && json.reduce((acc, el) => acc && ['string', 'number'].includes(typeof el), true), [json]);

    const form = useMemo(() => {
        return renderJSON(json, 0, [], isJSONPrimitiveArray);
    }, [json, changes, property, structure]);

    const handleDownload = () => {
        onExportSettings();
    };

    const handleUpload = () => {
        onImportSettings();
    };

    const isSettings = useMemo(() => property === 'settings', [property]);

    return (
        <div style={{ display: 'grid' }}>
            {typeof json === 'object' && !Array.isArray(json) &&
                <div className='brandSettings__form'>
                    <Button
                        color={'link'}
                        className="add-field-btn"
                        onClick={handleExpandAll}
                    >
                        <img
                            src={expand}
                            alt={i18n.t('admin.add.field')}
                        />&nbsp;&nbsp;Expand all
                    </Button>
                    <Button
                        color={'link'}
                        className="add-field-btn"
                        onClick={handleCollapseAll}
                    >
                        <img
                            src={collapse}
                            alt={i18n.t('admin.add.field')}
                        />&nbsp;&nbsp;Collapse all
                    </Button>
                    {isSettings &&
                        <>

                            <div className='brandSettings__form__import'>
                                <Tooltip
                                    title={t('crm.import')}
                                    placement="top"
                                >
                                    <button
                                        className="btn btn-rounded btn-primary app-btn-only-img-sm"
                                        onClick={handleDownload}
                                    >
                                        <img src={download} alt=""/>
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    title={t('crm.export')}
                                    placement="top"
                                >
                                    <button
                                        className="btn btn-rounded btn-primary app-btn-only-img-sm"
                                        onClick={handleUpload}
                                    >
                                        <img src={upload} alt=""/>
                                    </button>
                                </Tooltip>
                            </div>
                        </>
                    }
                </div>
            }
            <div className="brandSettings__list">
                {form}
                {showAddFieldModal &&
                    <AddSiteFieldModal
                        onCancel={() => setShowAddFieldModal(false)}
                        onAddField={handleAddField}
                        isArrayElement={isArray}
                    />
                }
                {showEditFieldModal &&
                    <EditSiteFieldModal
                        onCancel={() => setShowEditFieldModal(false)}
                        onChangeFieldName={handleConfirmEditField}
                        previousName={currentField}
                    />
                }
                {removeFieldModalVisibility &&
                    <CustomModal
                        titleText={i18n.t('content.removeField')}
                        isOpen={removeFieldModalVisibility}
                        onToggle={() => setRemoveFieldModalVisibility(!removeFieldModalVisibility)}
                        onClick={handleConfirmRemoveField}
                        onClickCancelButton={() => setRemoveFieldModalVisibility(false)}
                        btnText={'remove'}
                        cancelBtnText={'decline'}
                        withCancelButton={true}
                        bodyRender={() => (
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {`${i18n.t('content.sure.want.remove')} '${valueToRemove}' ?`}
                            </div>
                        )}
                    />
                }
            </div>
        </div>
    );
}

export default React.memo(GenericForm);
