import React, { useEffect } from 'react';
import {Card, Col, Row} from "reactstrap";
import i18n from "i18next";

import FlagCountry from "../../common/components/FlagCountry";
import AKYCField from "../../components/GenericComponents/kycField";
import {getGuidShortFormat} from "../../common/utils/common";
import ClipboardButton from "../../common/cellData/CopyButton";
import NotesView from "../../pages/CRM/general/notesView/NotesView";
import CallView from "../../pages/CRM/general/callView/CallView";

import "./styles.scss";
import settingIcon from "../../assets/images/common/Frame 10950.svg";
import settingIconHover from "../../assets/images/common/Frame 10950_hover.svg";
import {useHistory} from "react-router-dom";
import ButtonOpenPlayerView from "../buttons/ButtonOpenPlayerView";

const HeaderPlayerInfo = (props) => {
    const {
        playerGeneral,
        switchNotes,
        switchCallDialog,
        showOid
    } = props;

    return (
        <Row>
            <Col lg="12">
                <Card className="retail-search header-player-info">
                    <div className="d-flex align-items-center flex-wrap main-card__header__row">
                        <div
                            className="flag-div"
                            style={{
                                backgroundColor: `'${playerGeneral.color}'`,
                                boxShadow: `0px 0px 0px 3px ${playerGeneral.color}`,
                                borderRadius: '50%'
                            }}
                        >
                            <FlagCountry
                                countryCode={[
                                    playerGeneral?.address?.addressCountryAlfa2, playerGeneral?.citizenship
                                ]}
                                className="flag-wrapper"
                            />
                            <span className="profile-name">
                                {playerGeneral.firstName}&nbsp;
                                {playerGeneral.lastName}
                            </span>
                        </div>
                        <div className="profile-info-bar">
                            <span className="me-4 d-flex align-items-center">
                                {playerGeneral.isTest && (
                                    <span className="badge badge-soft-gray badge-test">
                                        {i18n.t('crm.test')}
                                    </span>
                                )}
                                <AKYCField kyc={playerGeneral.kyc} />
                            </span>
    
                            {
                                playerGeneral?.id &&
                                <span className="me-2 profile-info-id">
                                    <span className="me-2">ID {getGuidShortFormat(playerGeneral.id)} </span>
                                    <ClipboardButton text={playerGeneral.id} />
                                </span>
                            }
                            {
                                switchNotes &&
                                <span className="me-4">
                                    <NotesView onClick={switchNotes} />
                                </span>
                            }
                            {
                                switchCallDialog &&
                                <span className="me-4">
                                    <CallView onClick={switchCallDialog} />
                                </span>
                            }
                            {
                                showOid &&
                                <span className="me-4 profile-info-oid">
                                    <span>No. {playerGeneral.oid}</span>
                                    &nbsp;&nbsp;
                                    <ClipboardButton text={playerGeneral.oid} />
                                </span>
                            }
                        </div>
                    </div>
                    <div className="retail-search__open_player__btn">
                        <ButtonOpenPlayerView
                            playerOid={playerGeneral?.oid}
                            label={i18n.t("retail.customer.editButton")}
                        />
                    </div>
                </Card>
            </Col>
        </Row>
    )
};

export default HeaderPlayerInfo;
