import { useTimeContext } from "../../../context/TimeContext";
import React, { memo, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import {useLocation, withRouter} from "react-router-dom";
import {
    getColumnSettingsLsKey,
    getGuidShortFormat,
    initTableColumns,
} from "../../../common/utils/common";
import ClipboardButton from "../../../common/cellData/CopyButton";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import i18n from "../../../i18n";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";
import useUserConfig from "../../../hooks/useUserConfig";
import NewCustomTable from "../../../components/NewCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaymentStatusView from "../../../components/Status/PaymentStatusView";
import TableFilter from "../../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_PLAYER_DEPOSITS_LIST } from "../../../constants/Filter";
import download from "../../../assets/images/common/download.svg";
import { downloadPlayerDepositList } from "../../../store/players/deposits/actions";
import { FINANCE_TYPES } from "./common/playerViewConstants";
import settings from "../../../assets/images/layout/settings.svg";

const COLUMN_IDS = {
    id: 'id',
    cardHolderName: 'cardHolderName',
    time: 'time',
    provider: 'provider',
    method: 'method',
    status: 'status',
    depositAmount: 'depositAmount',
    feeAmount: 'feeAmount',
    totalAmount: 'totalAmount',
    transactionReference: 'transactionReference',
    cardInfo: 'cardInfo',
    expirationDate: 'expirationDate',
    promoNameApplied: 'promoNameApplied',
    IP: 'IP'
};

const getDefaultColumns = () => {
    return [
        COLUMN_IDS.id,
        COLUMN_IDS.cardHolderName,
        COLUMN_IDS.time,
        COLUMN_IDS.provider,
        COLUMN_IDS.method,
        COLUMN_IDS.status,
        COLUMN_IDS.depositAmount,
        COLUMN_IDS.feeAmount,
        COLUMN_IDS.totalAmount,
        COLUMN_IDS.transactionReference,
        COLUMN_IDS.cardInfo,
        COLUMN_IDS.expirationDate,
        COLUMN_IDS.promoNameApplied,
        COLUMN_IDS.IP,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.id]: 'crm.ID',
    [COLUMN_IDS.cardHolderName]: 'finance.cardHolderName',
    [COLUMN_IDS.time]: 'crm.time',
    [COLUMN_IDS.provider]: 'crm.provider',
    [COLUMN_IDS.method]: 'crm.method',
    [COLUMN_IDS.status]: 'crm.status',
    [COLUMN_IDS.depositAmount]: 'crm.depositAmount',
    [COLUMN_IDS.feeAmount]: 'crm.feeAmount',
    [COLUMN_IDS.totalAmount]: 'crm.totalAmount',
    [COLUMN_IDS.transactionReference]: 'crm.transactionReference',
    [COLUMN_IDS.cardInfo]: 'crm.cardInfo',
    [COLUMN_IDS.expirationDate]: 'crm.expirationDate',
    [COLUMN_IDS.promoNameApplied]: 'crm.promoNameApplied',
    [COLUMN_IDS.IP]: 'crm.ip',
};

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                }) => {
    const size = useWindowSize();
    const { adjustAmountWithUserCurrency } = useUserConfig();
    const paymentCardMonth = item.paymentCard?.expirationMonth;
    const paymentCardYear = item.paymentCard?.expirationYear;

    const expirationCardDate = (paymentCardMonth && paymentCardYear) && `${paymentCardMonth}/${paymentCardYear}`

    switch (field) {
        case COLUMN_IDS.id:
            return <td>
                <div>
                    <a
                        href={`/deposits/${item.id}`}
                        className="me-2"
                        style={{
                            color: '#5f5f8b',
                            cursor: 'pointer'
                        }}
                    >
                        {getGuidShortFormat(item.id)}
                    </a>
                    <ClipboardButton text={item.id}/>
                </div>
            </td>
        case COLUMN_IDS.cardHolderName:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.cardHolderName')}: </span>
                )}
                {item?.paymentCard?.name
                    ? item?.paymentCard?.name
                    : '-'
                }
            </td>
        case COLUMN_IDS.time:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.time')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.createdAt))}
            </td>
        case COLUMN_IDS.provider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.provider')}: </span>
                )}
                {item?.provider?.name || '—'}
            </td>
        case COLUMN_IDS.method:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.method')}: </span>
                )}
                {item.method}
            </td>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.status')}: </span>
                )}
                <PaymentStatusView status={item.status} />
            </td>
        case COLUMN_IDS.depositAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.depositAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.amount)}
            </td>
        case COLUMN_IDS.feeAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.feeAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.taxAmount)}
            </td>
        case COLUMN_IDS.totalAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.totalAmount)}
            </td>
        case COLUMN_IDS.transactionReference:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.transactionReference')}: </span>
                )}
                {item?.reference || '—'}
            </td>
        case COLUMN_IDS.cardInfo:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.cardInfo')}: </span>
                )}
                {item?.paymentCard?.maskedAccount || '—'}
            </td>
        case COLUMN_IDS.expirationDate:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                  <span>{i18n.t('crm.expirationDate')}: </span>
                )}
                {expirationCardDate || '—'}
            </td>
        case COLUMN_IDS.promoNameApplied:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.promoNameApplied')}: </span>
                )}
                {item?.providerDetails?.promoCode || '—'}
            </td>
        case COLUMN_IDS.IP:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.ip')}: </span>
                )}
                {item?.userIp || "—"}
            </td>
        default:
            return null;
    }
};

const DepositsTabTable = (props) => {
    const {
        playerId,
        list,
        currentFilter,
        isLoading,
        brandCurrencies,
    } = props;
    const { totalAmounts } = useSelector(
        state => state.PlayerDeposits
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation()
    const { timeFormat } = useTimeContext();
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());
    const [activeRow, setActiveRow] = useState(false);
    const [isShowTableSettings, setIsShowTableSettings] = useState(false);
    const toggleTableSettings = () => setIsShowTableSettings(prev => !prev);
    const onCloseTableSettings = () => setIsShowTableSettings(false);

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({ top: 0 });
    }, 200);

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    const handleDownload = useCallback(() => {
        const requestQuery = {};
        if (currentFilter) {
            requestQuery.filterDynamic = currentFilter;
        }

        requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        dispatch(downloadPlayerDepositList({ id: playerId, query: { ...requestQuery } }))
    }, [currentFilter]);

    const renderTotals = useCallback(() => {
        const filteredAmounts = {};
        Object.keys(totalAmounts).forEach((status) => {
            if (Object.keys(totalAmounts[status]).length > 0) {
                filteredAmounts[status] = totalAmounts[status];
            }
        })

        const existedStatuses = Object.keys(filteredAmounts);
        let result;

        if (existedStatuses.length === 0) {
            result = 0;
        }
        else {
            return existedStatuses.map((status, statusInd) => {
                const totalEntriesArr = Object.entries(filteredAmounts[status]);
                return <React.Fragment key={statusInd}>
                    {i18n.t(`crm.total${FINANCE_TYPES[status]}`)}:&nbsp;&nbsp;
                    {totalEntriesArr.map((item,index)  => {
                        const [currency, amount] = item;
                        return (
                            <React.Fragment key={index}>
                                {`${new Intl.NumberFormat().format(+amount)}
                          ${currency === 'MXN'
                                    ? currency.replace('MXN', 'MX$')
                                    : currency}; `}
                            </React.Fragment>
                        )
                    })}&nbsp;&nbsp;
                </React.Fragment>
            })
        }
    }, [totalAmounts]);

    if (list?.length === 0 && !isLoading && !currentFilter.length) {
        return <div className='noGamePlayData'>
            {i18n.t('crm.noDeposits')}
        </div>
    }


    return (
        <>
            <div
                className="page-title-table player-view-tab"
            >
                {(list?.length > 0 || currentFilter?.length > 0) && (
                    <TableFilter
                        filterListOptions={FILTER_MENU_PLAYER_DEPOSITS_LIST(t, brandCurrencies)}
                        isDisabled={isLoading}
                        category={FILTER_CATEGORY.PLAYER_DEPOSITS}
                    />
                )}

                <div className={clsx('d-flex justify-content-end mt-1 ms-3 download-button', {
                    ['d-none']: !list?.length
                })}>
                    <button
                        className="btn settings-button settings-deposit"
                        onClick={toggleTableSettings}
                    >
                        <img src={settings} alt="Table's Settings"/>
                    </button>
                    <button
                        className="btn btn-rounded btn-primary app-btn-only-img-sm"
                        onClick={handleDownload}
                    >
                        <img src={download} alt=""/>
                    </button>
                </div>
            </div>
            <div className='pb-2'>
                {totalAmounts && Object.keys(totalAmounts).length !== 0 &&
                    <div className='totalAmountDeposits'>
                        <span className='total-span'>
                            {renderTotals()}
                        </span>
                    </div>
                }
            </div>

            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                            />
                        ))}
                    </tr>
                ))}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    )
}

export default memo(withRouter(DepositsTabTable));