import React, {useEffect, useState} from 'react';

import './styles.scss';
import {getSync} from "../../helpers/api_helper";
import queryString from "query-string";
import {useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import { SITE_BRAND } from "../../constants/siteBrands"

const KPIDashboardUrl = "https://bi.wiz-crm.com/public/dashboard/74e68293-0598-4e71-8f65-fb977f6b1d88";
const SpinoFollowUpUrl = "https://bi.alo-play.com/public/dashboard/fe56899d-fd86-467c-918b-29e009cd72ab";


function ReportContainer() {
    const {agentSelf} = useSelector(state => state.AgentSelf);
    const {active: activeBrand} = useSelector(state => state.Brand);
    const history = useHistory();

    useEffect(() => {
        if (agentSelf?.permissions && agentSelf?.permissions.length > 0 && !agentSelf?.permissions.includes('Dashboard__View')) {
            const redirect_url = queryString.parse(
              window.location.search
            ).redirect_url;
            history.push(
              redirect_url && redirect_url !== '/login'
                ? redirect_url
                : '/player-list'
            );
        }
    }, [agentSelf?.permissions])


    const [url, setUrl] = useState();

    useEffect(async () => {
        const url = `/dashboard/base-dashboard-url`;
        const result = await getSync(url, {});
        setUrl(result.data.url);
    }, []);

    return (
      <>
          {url &&
            <iframe
              style={{ width: '100%', height: '100vh' }}
              src={url}
              allowTransparency
            />
          }

          {activeBrand?.siteName === SITE_BRAND.WINPOTMX && (
            <iframe
              src={KPIDashboardUrl}
              style={{ width: '100%', height: '100vh', marginTop: '20px' }}
              allowTransparency
            />
          )}
      </>
    );
}

export default ReportContainer;
