import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Card, CardBody } from 'reactstrap';
import ButtonsPanel from '../../components/Buttons/ButtonsPanel';
import useAlertService from '../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import ChangePasswordModal from './modal/ChangePasswordModal';
import {
    downloadAgentSelf,
    editAgentPassword,
    getAgentSelf,
    resetAgentSelfAlertsErrors,
    setEditAgentSelf,
    updateAgentSelf
} from '../../store/agents/self/actions';
import { prepareFieldErrors, prepareUpdateAgentDto } from './model/agentModel';
import AgentMainCardHeader from './common/AgentMainCardHeader';
import AgentContactInfoBlock from './common/AgentContactInfoBlock';
import AgentGeneralInfoBlock from './common/AgentGeneralInfoBlock';
import AgentSlackCard from "./common/AgentSlackCard";


function AgentViewMainCard() {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { isLoading, error, agentSelf, successAlert, isDownloading } = useSelector(
        state => state.AgentSelf
    );

    const [agentModel, setAgentModel] = useState(
        JSON.parse(JSON.stringify(agentSelf))
    );
    const [fieldErrors, setFieldErrors] = useState({});
    const [isShowPasswordChange, setShowPasswordChange] = useState(false);

    useEffect(() => {
        dispatch(getAgentSelf());
    }, []);

    useEffect(() => {
        setAgentModel(JSON.parse(JSON.stringify(agentSelf)));
        handleSetEdit(false);
    }, [agentSelf]);

    useEffect(() => {
        if (error && error.statusCode === 400) {
            setFieldErrors(prepareFieldErrors(error?.data));
        }
    }, [error]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetAgentSelfAlertsErrors());
            setShowPasswordChange(false);
        }
    }, [successAlert]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setEditAgentSelf(nextIsEdit));
        setFieldErrors({});
    };

    const handleChange = useCallback(
        updatedModel => {
            setAgentModel(updatedModel);
            handleSetEdit(true);
        },
        [agentModel]
    );

    const handleSave = useCallback(() => {
        const dto = prepareUpdateAgentDto(agentSelf, agentModel);

        dispatch(updateAgentSelf({ id: agentSelf.id, dto }));
    }, [agentModel, agentSelf]);

    const handleCancel = useCallback(() => {
        setAgentModel(JSON.parse(JSON.stringify(agentSelf)));
        handleSetEdit(false);
    }, [agentSelf]);

    const handleSaveNewPassword = useCallback(dto => {
        dispatch(editAgentPassword(dto));
    }, []);

    const isEdit = useMemo(() => {
        if (!agentSelf || !agentModel) {
            return false;
        }

        const dto = prepareUpdateAgentDto(agentSelf, agentModel);

        const isEditMode = Object.keys(dto).length > 0;

        if (!isEditMode) {
            handleSetEdit(false);
        }

        return isEditMode;
    }, [agentSelf, agentModel]);

    const handleDownload = useCallback(() => {
        dispatch(downloadAgentSelf())
    },[]);

    return (
        <>
            <Card>
                <AgentMainCardHeader
                  agent={agentSelf}
                  onDownload={handleDownload}
                  isDownloading={isDownloading}
                />

                <CardBody className="main-card">
                    <AgentContactInfoBlock
                        data={agentModel}
                        fieldErrors={fieldErrors}
                        isEdit={isEdit}
                        onChange={handleChange}
                        onChangePassword={() => setShowPasswordChange(true)}
                    />

                    <div className="vertical-separator" />

                    <AgentGeneralInfoBlock
                        data={agentModel}
                        fieldErrors={fieldErrors}
                        isEdit={isEdit}
                        onChange={handleChange}
                    />
                </CardBody>
            </Card>

            {isShowPasswordChange && (
                <ChangePasswordModal
                    onConfirm={handleSaveNewPassword}
                    onHide={() => setShowPasswordChange(false)}
                    fieldErrors={fieldErrors}
                />
            )}
            <Card>
                <AgentSlackCard
                    agent={agentModel}/>
            </Card>

            <ButtonsPanel
                onSave={handleSave}
                onCancel={handleCancel}
                isDisabled={isLoading}
                isEdit={isEdit}
            />

        </>
    );
}

export default React.memo(AgentViewMainCard);
