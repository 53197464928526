import React, { useMemo } from "react"
import PropTypes from "prop-types"
import SelectInput from "../../../common/inputs/common/SelectInput"
import { PROMOTION_TYPE, SPORT_PROMOTION_TYPE } from "./promotionConstants"
import { useTranslation } from "react-i18next";


function PromotionTypeSelect({ onChange, value, isSportMode = false, ...props }) {
  const { i18n, t } = useTranslation();

  const options = useMemo(() => Object.values(isSportMode ? SPORT_PROMOTION_TYPE : PROMOTION_TYPE).
    map(item => ({ label: t(`promotions.constants.${item}`), value: item })),[i18n.language, isSportMode]);

  return (
    <SelectInput
      {...props}
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

PromotionTypeSelect.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(PromotionTypeSelect);
