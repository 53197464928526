import React, {useEffect} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { useTranslation } from "react-i18next";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const AuditChangesModal = ({ isOpen, toggle, currentChanges }) => {

    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'scroll'
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="audit-changes-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <ModalHeader toggle={toggle}>
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {t('audit.info')}
                    </h5>
                </div>
            </ModalHeader>
            <ModalBody className="audit-modal-body">
                <span>
                    {currentChanges}
                </span>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <button
                    className="btn btn-primary btn-rounded"
                    style={{maxWidth: '132px'}}
                    onClick={() => toggle()}
                >
                    {t('crm.ok')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default AuditChangesModal;
