import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import ButtonsPanel from "./common/ButtonsPanel";
import AutomationContext from "./context/AutomationContext";
import { RouterPrompt } from "../../common/prompt/RouterPrompt";
import group from "../../assets/images/common/information (1) 1.svg";
import TextInput from "../../common/inputs/TextInput";
import {
  PROMOTION_STATUS,
  PROMOTION_STATUS_ARR,
  SPORT_PROMOTION_TYPE,
} from "./common/promotionConstants";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateSportPromotion,
  createSportPromotion,
} from "../../store/promotions/actions";
import ClipboardButton from "../../common/cellData/CopyButton";
import {fieldsForCentRestrictions, SITE_PERMISSION} from "../../common/constants/common";
import { getGuidShortFormat } from "../../common/utils/common";
import { useTranslation } from "react-i18next";
import SelectInput from "../../common/inputs/common/SelectInput";
import MultiSelect from "../../UI/multiSelect/MultiSelect";
import { SELECT_CATEGORIES } from "../../constants/selectCategories";
import TooltipMUI from "../../UI/tooltip/TooltipMUI";
import {
  columns,
  configurationTable_BONUS_AMOUNT,
  nameConfiguration,
} from "./ConfigurationTable";
import NewCustomTable from "../../components/NewCustomTable";
import i18n from "i18next";
import {
  LATIN_REG_EXP,
  LATIN_WITHOUT_SPACES_REG_EXP, NUMBERS_AND_LETTERS_REG_EXP,
  NUMBERS_REG_EXP, ONLY_DIGITS_REG_EXP,
  PROMOTION_MAX_MONEY_VALUE_REG_EXP
} from "../../constants/validations";
import Formatter from "../../utils/Formatter";
import {useTimeContext} from "../../context/TimeContext";
import ACommonDataField from "../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../constants/aCommonDataField";
import {SportProvider} from "../Content/common/constants";
import {SITE_BRAND} from "../../constants/siteBrands";
import {fetchBrandSettingsPage} from "../../store/brand/brandPage/actions";

const getValuesOfTable = (nameOfRowTable, values = {}) => Object.keys(values)
    .reduce((previousValue = {}, currentCurrency) => {
      previousValue[`${nameOfRowTable}_${currentCurrency}`] = values[currentCurrency] ?? 0;
      return previousValue;
    }, {});

const prepareRequestData = (isNew, model) => {
  const {
    createdAt,
    lastChangedAt,
    status,
    id,
    name,
    oid,
    site,
    coupon,
    externalId,
    sportsBook,
    currencies,
    type,
    allowMultipleOptIn
  } = model;

  const amount = {};
  currencies.forEach(currency => {
      amount[`${currency}`] =
          +model[`${nameConfiguration.BonusAmount}_${currency}`] || 0;
    });

  const isDeposit = type === SPORT_PROMOTION_TYPE.DEPOSIT;

  return {
    site,
    status,
    name: name || i18n.t('promotions.newPromotion'),
    coupon,
    externalId,
    settings: {
      ...(isDeposit && {} || {amount}),
      sb: sportsBook,
      isDeposit,
      allowMultipleOptIn,
    },
    ...(!isNew && {
      createdAt,
      lastChangedAt,
      id,
      oid,
    } || {}),
  };
};

function SportPromotionCard({
  data,
  isNew,
  updateSportPromotion,
  createSportPromotion,
  isCreating,
  isUpdating,
}) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const {
    data: brandData,
  } = useSelector(state => state.BrandSettingsPage);
  const { active: activeBrand } = useSelector(state => state.Brand);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabledAll, setDisabledAll] = useState(false);
  const [isDisabledCreateBtn, setIsDisabledCreateBtn] = useState(false);
  const [configuration, setConfiguration] = useState([]);
  const [canEditPromotion, setCanEditPromotion] = useState(false);
  const [initialCurrencies, setInitialCurrencies] = useState([]);
  const [initialCurrenciesFromPromo, setInitialCurrenciesFromPromo] = useState([]);

  const { agentSelf } = useSelector(state => state.AgentSelf);
  const agentPermissions = agentSelf?.permissions || [];

  const { timeFormat } = useTimeContext();

  useEffect(() => { dispatch(fetchBrandSettingsPage()) }, []);

  const initialState = useMemo(() => ({
    model: {
      name: 'New promotion',
      status: PROMOTION_STATUS.PENDING,
      currencies: [],
      bonusAmount: 0,
      sportsBook: null,
      coupon: '',
      externalId: '',
      type: SPORT_PROMOTION_TYPE.FREE_BET,
      isDeposit: false,
      agentName: '',
    }
  }), []);

  const originalState = useMemo(() => {
    if (data) {
      const {
        createdAt,
        lastChangedAt,
        name,
        status,
        id,
        oid,
        site,
        settings,
        coupon,
        externalId,
        agentName,
      } = data || {};

      const {
        amount,
        sb: sportsBook,
        isDeposit,
        allowMultipleOptIn,
      } = settings;

      const currencies = amount ? Object.keys(amount) : initialCurrencies;

      return ({
        model: {
          createdAt,
          lastChangedAt,
          name,
          status,
          id,
          oid,
          site,
          coupon,
          externalId,
          sportsBook,
          currencies,
          isDeposit,
          allowMultipleOptIn,
          type: isDeposit ? SPORT_PROMOTION_TYPE.DEPOSIT : SPORT_PROMOTION_TYPE.FREE_BET,
          agentName,
          ...getValuesOfTable(nameConfiguration.BonusAmount, amount),
        },
      });
    }
    return ({
      model: {},
    });
  }, [data, initialCurrencies, brandData]);

  const [state, setState] = useState(isNew
      ? initialState
      : originalState
  );

  useEffect(() => {
    if (brandData) setInitialCurrencies(brandData.configurationFinancial.currencies)
  }, [brandData]);

  useEffect(() => {
    if (isNew) {
      setState((prev) => ({
        ...prev,
        model: {
          ...prev.model,
          currencies: initialCurrencies
        }
      }))
    }
  }, [initialCurrencies, initialCurrenciesFromPromo, isNew]);

  useEffect(() => {
    const initialUniqueCurr = new Set(initialCurrencies?.concat(initialCurrenciesFromPromo));
    if (!isNew && !state.model.currencies.length) {
      setState((prevState) => ({
        ...prevState,
        model: {
          ...prevState.model,
          currencies: Array.from(initialUniqueCurr)
        }
      }));
    }
  }, [state.model.currencies])

  useEffect(() => {
    let sb;
    switch (activeBrand?.siteName) {
      case SITE_BRAND.WINPOTMX:
        sb = 'Altenar';
        break;
      case SITE_BRAND.BLACK29:
      case SITE_BRAND.D20:
        sb = 'Delasport';
        break;
      default:
        sb = 'Delasport';
        break;
    }

    setState((prevState) => ({
      ...prevState,
      model: {
        ...prevState.model,
        sportsBook: sb
      }
    }));
  }, [activeBrand?.siteName]);

  const currentBrand = activeBrand?.siteName;

  useEffect(() => {
    const canEditPromotion = agentPermissions.includes(SITE_PERMISSION.Promotion__Edit_Promotions);
    setCanEditPromotion(canEditPromotion);
    setDisabledAll(!isNew && (!canEditPromotion || state.model.status !== PROMOTION_STATUS.PENDING));
  }, [agentPermissions, state.model.status])

  useEffect(() => {
    let isDisabled = false;

    switch (currentBrand) {
      case SITE_BRAND.WINPOTMX:
        isDisabled = state.model.externalId === '' || state.model.coupon === '';
        break;
      case SITE_BRAND.D20:
        isDisabled = state.model.coupon === '';
        break;
      default:
        break;
    }

    setIsDisabledCreateBtn(isDisabled);
  }, [state.model.externalId, state.model.coupon, currentBrand]);


  useEffect(() => {
    setInitialCurrenciesFromPromo(state.model.currencies || []);
  }, []);

  const handleSave = useCallback(() => {
    const preparedData = prepareRequestData(isNew, state.model);

    if (isNew) {
      createSportPromotion({data: preparedData});
    }
    else {
      updateSportPromotion({promotionId: data.id, data: preparedData});
    }

    setIsEdit(false);
  }, [state]);

  const handleCancel = useCallback(() => {
    setState(isNew ? initialState : originalState);
    setIsEdit(false);
  },[data]);

  const handleChange = useCallback((name) => {
    return (value) => {
      if (fieldsForCentRestrictions(name)) {
        value = value.replace(PROMOTION_MAX_MONEY_VALUE_REG_EXP, '$1').replace(',' , '.');
      }
      if (name === 'externalId') {
        value = value.replace(ONLY_DIGITS_REG_EXP, '');
      }
      if (name === 'coupon') {
        value = value.replace(NUMBERS_AND_LETTERS_REG_EXP, '');
      }
      setState(prevState => {
        const newState = {
          ...prevState,
          model: {
            ...prevState.model,
            [name]: value,
          },
        };
        if (name === 'currencies') {
          newState.model.currencies.forEach((currency) => {
            const fieldName = `${nameConfiguration.BonusAmount}_${currency}`;
            if (!(fieldName in newState.model)) {
              newState.model[fieldName] = 0;
            }
          });
        }
        else if (name === 'type') {
          newState.model.isDeposit = value === SPORT_PROMOTION_TYPE.DEPOSIT;
        }
        return newState;
      });
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    setIsEdit(false);
  }, [isUpdating, isCreating]);

  useEffect(() => {
    setConfiguration(configurationTable_BONUS_AMOUNT(state.model.currencies))
  }, [state.model.currencies, i18n.language]);

  const tableOfConfiguration = <NewCustomTable
      columns={columns(state.model.currencies)}
  >
    {configuration
        ?.map(({label, nameConfiguration, tooltipText, currencies}, index) => {
          return (
              <tr key={index}>
                <td className="d-flex align-items-center">
                  <TooltipMUI title={tooltipText} wrapperPadding={'0'}/>
                  {label}
                </td>
                {
                  currencies?.map((currency) => {
                      const fieldName = `${nameConfiguration}_${currency}`;
                      return <td key={currency}>
                        <TextInput
                            className="form-control form-control-gray fullWidth currencyInput"
                            value={(!state.model.isDeposit && state.model[fieldName]) ?? ''}
                            onChange={handleChange(fieldName)}
                            type={"number"}
                            min={0}
                            disabled={isDisabledAll || state.model.isDeposit}
                            data-type={label + currency}
                        />
                      </td>
                      }
                  )}
              </tr>
          );
        })}
  </NewCustomTable>

  return (
      <AutomationContext.Provider value={{isEdit, setIsEdit}}>
        <Card className="promotion-card">
          <CardBody>
            <div className="promotion-card__sub-content">
              <div className="sub-content__header">
                <img className="me-3" src={group} alt=""/>
                <span className="font-w-500">{i18n.t('promotions.generalInfo')}</span>
              </div>
              <div className="sub-content__content d-flex flex-wrap">
                <div className="d-flex me-5">
                  <div className='grid_desc time'>
                    <div className="font-w-500 me-2">{i18n.t('promotions.createdTime')}:</div>
                  </div>
                  <div className="text-muted grid_valueView">
                    {isNew && '--/--/--'}
                    {!isNew &&
                        <>
                          {Formatter.displayDateTimeMinutes(timeFormat.parseTime(state.model.createdAt))}
                        </>
                    }
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className='grid_desc time'>
                    <div className="font-w-500 me-2">{i18n.t('promotions.updatedTime')}:</div>
                  </div>
                  <div className="text-muted grid_valueView">
                    {isNew && '--/--/--'}
                    {!isNew &&
                        <>
                          {Formatter.displayDateTimeMinutes(timeFormat.parseTime(state.model.lastChangedAt))}
                        </>
                    }
                  </div>
                </div>
                {state.model?.agentName && (
                    <div className="d-flex me-5">
                      <div className='grid_desc time'>
                        <div className="font-w-500 me-2">{i18n.t('promotions.agentName')}:</div>
                      </div>
                      <div className="text-muted grid_valueView">
                        {state.model.agentName}
                      </div>
                    </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap promotion-card__header gap-2">
              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t('promotions.promotion_name')}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <TextInput
                      className="form-control form-control-gray"
                      value={state.model.name}
                      onChange={handleChange('name')}
                      placeholder={i18n.t('promotions.promotion_name')}
                      disabled={isDisabledAll}
                  />
                </div>
              </div>

              {!isNew &&
              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <div className="font-w-500 me-2 d-flex align-items-center">
                    {i18n.t('promotions.promotionID')}:
                  </div>
                </div>
                <div className={'grid_valueView'}>
                  <span> {getGuidShortFormat(state.model.id)} <ClipboardButton text={state.model.id} /></span>
                </div>
              </div>}

              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.status')}:</span>
                </div>
                  <div className={'grid_valueView'}>
                    <SelectInput
                        options={PROMOTION_STATUS_ARR
                            .map(item => ({ label: i18n.t(`promotion.statuses.${item}`), value: item }))
                        }
                        value={state.model.status}
                        onChange={handleChange('status')}
                        disabled={!canEditPromotion}
                    />
                  </div>
              </div>

              <div className="d-flex me-4 mt-4 align-items-center" style={{ width: '348px' }}>
                <div className={'grid_desc info'}>
                  <span className="font-w-500 me-3">{i18n.t('promotions.type')}:</span>
                </div>
                  <div className={'grid_valueView'}>
                    <SelectInput
                        options={Object.values(SPORT_PROMOTION_TYPE)
                            .map(item => ({ label: i18n.t(`promotions.constants.${item}`), value: item }))
                        }
                        value={state.model.type}
                        onChange={handleChange('type')}
                        disabled={isDisabledAll}
                    />
                  </div>
              </div>

            </div>
          </CardBody>
        </Card>

        <Card className="promotion-card">
          <CardBody>
            <div className="promotion-card__sub-content">
              <div className="sub-content__header">
                <img className="me-3" src={group} alt=""/>
                <span className="font-w-500">{i18n.t('promotions.definition')}</span>
              </div>
              <div className="sub-content__content d-flex flex-wrap">
                <div className="d-flex align-items-center flex-wrap font-w-500 mt-4 me-4">
                  <div className={'grid_desc long'}>
                    {i18n.t("promotions.currencies")}:
                  </div>
                  <div className={'grid_valueView'}>
                    <MultiSelect
                        category={SELECT_CATEGORIES.CURRENCIES}
                        values={state.model.currencies}
                        handleChange={handleChange('currencies')}
                        isDisabled={isDisabledAll}
                        className={!isNew && 'restrictDeleteCurrency'}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center flex-wrap font-w-500 mt-4">
                  <div className='mr-10'>
                    {i18n.t("promotions.sportsBook")}:
                  </div>
                  <div className='grid_valueView'>
                    <span className='sportsBook'>{state.model.sportsBook}</span>
                  </div>

                  <div className={'d-flex align-items-center'}>
                    <span className='w-100'>
                      {i18n.t("promotions.multiple.opt_in")}:
                    </span>
                    <div className="sport-promo__checkbox">
                      <ACommonDataField
                          component={A_COMMON_DATA_FIELD.CHECK_BOX}
                          value={state.model.allowMultipleOptIn}
                          handleChange={handleChange('allowMultipleOptIn')}
                          disabled={isDisabledAll}
                          className={'wrapper_checkBox ml-10'}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {tableOfConfiguration}

              <div className="sub-content__content d-flex flex-wrap">
                <div className="d-flex me-4 mt-4 align-items-center">
                  <div className={'grid_desc'}>
                    <div className="font-w-500 d-flex align-items-center" style={{width: 'min-content'}}>
                      *&nbsp;{i18n.t('promotions.coupon')}:
                    </div>
                  </div>
                  <div className={'grid_valueView'}>
                    <TextInput
                        className="form-control form-control-gray"
                        value={state.model.coupon}
                        onChange={handleChange('coupon')}
                        disabled={isDisabledAll}
                    />
                  </div>
                </div>

                {currentBrand === SITE_BRAND.WINPOTMX ? (
                    <div className="d-flex me-4 mt-4 align-items-center">
                      <div className={'grid_desc'}>
                        <div className="font-w-500 d-flex align-items-center">
                          *&nbsp;{i18n.t('promotions.externalId')}:
                        </div>
                      </div>
                      <div className={'grid_valueView'}>
                        <TextInput
                            className="form-control form-control-gray"
                            value={state.model.externalId}
                            onChange={handleChange('externalId')}
                            disabled={isDisabledAll}
                        />
                      </div>
                    </div>
                ) : (
                   <></>
                )}
              </div>
            </div>
          </CardBody>
        </Card>

        {(isEdit || isNew) &&
        <ButtonsPanel
            isNew={isNew}
            onCancel={handleCancel}
            onSave={handleSave}
            isEdit={isEdit}
            onSetIsEdit={setIsEdit}
            isDisabled={isDisabledCreateBtn}
        />
        }

        {isEdit &&
        <RouterPrompt
            when={isEdit}
            onOK={() => true}
            onCancel={() => false}
        />
        }
      </AutomationContext.Provider>
  );
}

SportPromotionCard.propTypes = {
  data: PropTypes.object,
  isNew: PropTypes.bool,
  promotionStatus: PropTypes.string,
}

const mapStateToProps = state => {
  const {
    isUpdating,
    isCreating,
  } = state.Promotions
  return {
    isUpdating,
    isCreating,
  }
}

export default React.memo(withRouter(
    connect(mapStateToProps, {
      updateSportPromotion,
      createSportPromotion,
    })(SportPromotionCard)
));
