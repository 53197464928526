import { DOCUMENT_ACTION } from "../constants/document"

const getRotatedBlobImage = (base64Image, rotateDegree) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.src = base64Image;

  return new Promise(resolve => {
    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;

      const swapWidthAndHeight = rotateDegree === 90 || rotateDegree === 270 || rotateDegree === -90 || rotateDegree === -270;

      if (swapWidthAndHeight) {
        canvas.width = imageHeight;
        canvas.height = imageWidth;
      } else {
        canvas.width = imageWidth;
        canvas.height = imageHeight;
      }

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(rotateDegree * Math.PI / 180);

      if (swapWidthAndHeight) {
        ctx.drawImage(img, -canvas.height / 2, -canvas.width / 2, imageWidth, imageHeight);
      } else {
        ctx.drawImage(img, -canvas.width / 2, -canvas.height / 2, imageWidth, imageHeight);
      }

      const dataUrl = canvas.toDataURL("image/png", 100);
      resolve(dataUrl);
    }
  });
}

const generateActivityMessage = (action, item, actor, t) => {
  let actionMessage = '';
  let informationMessage = '';
  switch (action) {
    case DOCUMENT_ACTION.ADDED_COMMENT:
      actionMessage = t('document.action.message.addedComment');
      informationMessage = `${item.content}`;
      break;
    case DOCUMENT_ACTION.REMOVED_COMMENT:
      actionMessage = t('document.action.message.removedComment');
      informationMessage = `${item.content}`;
      break;
    case DOCUMENT_ACTION.UPDATED:
      actionMessage = t('document.action.updated', {
        property: `${t(`audit.${item.property}`)}`.toLowerCase()
      });
      let from;
      let to;
      if (typeof item.from !== 'undefined') {
        if (item.property === 'expiration' || item.property === 'this.birthDate') {
          from = new Date(item.from).toLocaleDateString('en-gb');
          to = new Date(item.to).toLocaleDateString('en-gb');
        } else {
          from = item.from;
          to = item.to;
        }
        informationMessage = t('document.message.fromTo' , {
          from: from,
          to: to,
        });
      } else {
        if (item.property === 'expiration' || item.property === 'this.birthDate') {
          to = new Date(item.to).toLocaleDateString('en-gb');
        } else {
          to = item.to;
        }
        informationMessage = t('document.message.to', { to: to });
      }
      break;
    case DOCUMENT_ACTION.UPLOADED:
      actionMessage = t('document.action.uploaded').toLowerCase();
      informationMessage = item.type && `${item.type}`;
      break;
  }
  actionMessage = `${actor} ${actionMessage}`;
  return {
    information: informationMessage,
    activity: actionMessage,
  };
}

export {
  getRotatedBlobImage,
  generateActivityMessage,
}
