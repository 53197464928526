import i18n from "../../../i18n";

export default [
	{
		id: 'check',
		title: '',
	},
	{
		id: 'game',
		title: i18n.t('content.gameName'),
		source: 'friendlyName',
		className: 'bold game-name',
	},
	{
		id: 'genre',
		source: 'gameType',
		title: i18n.t('content.type'),
		className: 'text-align-center game-type',
	},
	{
		id: 'lines',
		title: i18n.t('content.lines'),
		className: 'text-align-center',
	},
	{
		id: 'gameVendor',
		source: 'vendorName',
		title: i18n.t('content.gameVendor'),
		className: 'text-align-center width150 game-vendor',
	},
	{
		id: 'addedTime',
		source: 'createdAt',
		title: i18n.t('content.addedTime'),
		className: 'text-align-center width150',
	},
	{
		id: 'rtp',
		title: i18n.t('content.rtp'),
		className: 'text-align-center',
	},
	{
		id: 'gameIdMobile',
		source: 'externalMobileId',
		title: i18n.t('content.gameIdMobile'),
		className: 'text-align-center',
	},
	{
		id: 'gameIdDesktop',
		source: 'externalDesktopId',
		title: i18n.t('content.gameIdDesktop'),
		className: 'text-align-center',
	},
	{
		id: 'promoCoefficient',
		title: i18n.t('content.coefficient'),
		className: 'text-align-center',
	},
]
