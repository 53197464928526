import {get, getFile, post, put} from './api_helper';
import {prepareFilterSearch, prepareSearch} from './SearchUtils';


const getAffiliatesListApi = async query => {
    const url = `/affiliates`;

    return get(url);
};

const searchAffiliatesApi = async query => {
    const url = `/affiliates/search?${prepareSearch(query)}`;

    return get(url);
};

const addAffiliatesAtListApi = async body => {
    const url = `/affiliates`;

    return post(url, body);
};

const getAffiliateApi = async id => {
    const url = `/affiliates/${id}`;

    return get(url);
};

const changeAffiliateApi = async ({ id, ...body }) => {
    const url = `/affiliates/${id}`;

    return put(url, body);
};

const changeAffiliateStatusApi = async ({ id, status }) => {
    const url = `/affiliates/${id}`;

    return put(url, { status });
};

const removeAffiliateApi = async id => {
    // TODO add request
    const request = await new Promise(res => {
        setTimeout(() => {
            res({ success: boolean });
        }, 1000);
    });

    return request;
};

const downloadAffiliatesListApi = (query) => {
    const url = `/affiliates/excel?${prepareFilterSearch(query)}`;
    return getFile(url);
};


export {
    getAffiliateApi,
    changeAffiliateApi,
    removeAffiliateApi,
    getAffiliatesListApi,
    searchAffiliatesApi,
    addAffiliatesAtListApi,
    changeAffiliateStatusApi,
    downloadAffiliatesListApi,
};
