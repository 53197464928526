import { takeEvery, put, call } from 'redux-saga/effects';

import {
  DOWNLOAD_WITHDRAWAL_LIST,
  GET_WITHDRAWAL_COUNT,
  GET_WITHDRAWAL_LIST
} from './actionTypes';
import {
  getWithdrawalListSuccess,
  getWithdrawalListFail,
  downloadWithdrawalListSuccess,
  downloadWithdrawalListFail,
  getWithdrawalListCountSuccess,
  getWithdrawalListCountFail,
} from './actions';
import {
  downloadPaymentWithdrawalListApi,
  getPaymentWithdrawalListApi,
  getPaymentWithdrawalListCountApi,
} from '../../../helpers/paymentApi';

function* fetchWithdrawalListSaga({ payload }) {
  try {
    const response = yield call(getPaymentWithdrawalListApi, payload);

    yield put(getWithdrawalListSuccess(response.data));
  } catch (error) {
    yield put(getWithdrawalListFail(error));
  }
}

function* fetchWithdrawalListCountSaga({ payload }) {
  try {
    const response = yield call(getPaymentWithdrawalListCountApi, payload);

    yield put(getWithdrawalListCountSuccess(response.data));
  } catch (error) {
    yield put(getWithdrawalListCountFail(error));
  }
}

function* downloadWithdrawalListSaga({ payload }) {
  try {
    yield call(downloadPaymentWithdrawalListApi, payload);

    yield put(downloadWithdrawalListSuccess());
  } catch (error) {
    yield put(downloadWithdrawalListFail(error));
  }
}

function* withdrawalsSaga() {
  yield takeEvery(GET_WITHDRAWAL_COUNT, fetchWithdrawalListCountSaga);
  yield takeEvery(GET_WITHDRAWAL_LIST, fetchWithdrawalListSaga);
  yield takeEvery(DOWNLOAD_WITHDRAWAL_LIST, downloadWithdrawalListSaga);
}

export default withdrawalsSaga;
