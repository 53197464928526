import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import { ACTIVITY_TABS } from '../../../common/constants/common';
import i18n from '../../../i18n';

import ActivityHistoryRecord from './ActivityHistoryRecord';
import ActivityCommentsRecord from './ActivityCommentsRecord';

import activity from '../../../assets/images/layout/grey-activity.svg'; // for history
import violetActivity from '../../../assets/images/layout/light-violet-activity.svg'; // for history
import commentsSvg from '../../../assets/images/layout/comments.svg';
import violetComments from '../../../assets/images/layout/light-violet-comments.svg';
import addItem from '../../../assets/images/layout/add-item.svg';
import { useDispatch, useSelector } from 'react-redux';
import {getDocumentHistory} from '../../../store/documents/actions';
import Loader from '../../../common/components/Loader';

function HistoryTabContent({ documentOid }) {
    const dispatch = useDispatch();
    const { documentHistoryMap } = useSelector(state => state.Documents);

    useEffect(() => {
        if (documentOid) {
            dispatch(getDocumentHistory(documentOid));
        }
    }, [documentOid]);

    if (!documentHistoryMap[documentOid]) {
        return <Loader size={'lg'}/>;
    }

    return (
        <>
            {documentHistoryMap[documentOid]?.map((record) => (
                    <ActivityHistoryRecord
                        key={record?.createdAt}
                        record={record}
                    />)
            )}
        </>
    );
}

function DocumentActivity(props) {
    const {
        activityTab,
        docNumber,
        onChangeActivityTab,
        onClickAddComment,
        comments,
        currDocument,
        onRemoveComment,
    } = props;

    return (
        <Col className="col-4 doc-data doc-comments">
            <div className="doc-data__title">
                <span className="activity-title">
                    <span
                        className={
                            activityTab === ACTIVITY_TABS.comments
                                ? 'active'
                                : ''
                        }
                        key="comments"
                        onClick={() =>
                            onChangeActivityTab(
                                docNumber,
                                ACTIVITY_TABS.comments
                            )
                        }
                    >
                        <img
                            className="title-img"
                            alt=""
                            src={
                                activityTab === ACTIVITY_TABS.comments
                                    ? violetComments
                                    : commentsSvg
                            }
                            width={16}
                            height={16}
                        />
                        {i18n.t('crm.comments')}
                    </span>
                    <span
                        className={
                            activityTab === ACTIVITY_TABS.history
                                ? 'active'
                                : ''
                        }
                        key="history"
                        onClick={() =>
                            onChangeActivityTab(
                                docNumber,
                                ACTIVITY_TABS.history
                            )
                        }
                    >
                        <img
                            className="title-img"
                            alt=""
                            src={
                                activityTab === ACTIVITY_TABS.history
                                    ? violetActivity
                                    : activity
                            }
                            width={16}
                            height={16}
                        />
                        {i18n.t('crm.history')}
                    </span>
                </span>
            </div>

            <div className="activity-section">
                <div className="activity-section-wrapper">
                    {activityTab === ACTIVITY_TABS.history ? (
                        <HistoryTabContent documentOid={currDocument.oid} />
                    ) : (
                        comments.map((record, ind) => (
                            <ActivityCommentsRecord
                                key={`comment-${ind}`}
                                record={record}
                                withActions={true}
                                onRemoveComment={onRemoveComment}
                            />
                        ))
                    )}
                </div>
                {activityTab === ACTIVITY_TABS.comments && (
                    <img
                        className="add-item"
                        alt=""
                        src={addItem}
                        width={32}
                        height={32}
                        onClick={() => onClickAddComment(docNumber)}
                    />
                )}
            </div>
        </Col>
    );
}

DocumentActivity.propTypes = {
    activityTab: PropTypes.string,
    docNumber: PropTypes.number,
    onChangeActivityTab: PropTypes.func,
    onClickAddComment: PropTypes.func,
    comments: PropTypes.array.isRequired,
    currDocument: PropTypes.object.isRequired,
    onRemoveComment: PropTypes.func.isRequired,
};

export default React.memo(DocumentActivity);
