import React, {useEffect, useState} from 'react';
import Profile from "./Profile";
import KYC from "./KYC";
import Finance from "./Finance";

import "../../../UI/headerPlayerInfo/styles.scss";
import "../styles.scss";
import {RouterPrompt} from "../../../common/prompt/RouterPrompt";

const GeneralView = () => {
    const [isEditPage, setIsEditPage] = useState(false);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [isEditFinance, setIsEditFinance] = useState(false);
    const [isEditKYC, setIsEditKYC] = useState(false);

    useEffect(() => {
        if (isEditProfile || isEditKYC || isEditFinance) {
            setIsEditPage(true)
        } else {
            setIsEditPage(false)
        }
    }, [isEditProfile, isEditKYC, isEditFinance])

    return (
        <div className="player-info">
            <div className="player-info__general">
                <Profile
                    isEditMode
                    isEdit={isEditProfile}
                    setIsEdit={setIsEditProfile}
                />
                <Finance setIsEdit={setIsEditFinance} />
            </div>
            <div className="player-info__KYC">
                <KYC
                    isEdit={isEditKYC}
                    setIsEdit={setIsEditKYC}
                />
            </div>
            <RouterPrompt
                when={isEditPage}
                onOK={() => true}
                onCancel={() => false}
            />
        </div>
    )
};

export default GeneralView;
