import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import SwitchCheckboxInput from '../../../common/inputs/SwitchCheckboxInput';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import ButtonsPanel from './ButtonsPanel';
import header_icon from '../../../assets/images/common/player-restrictions.svg';
import useAlertService from '../../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPlayerRestrictions,
    setEditPlayerRestrictions,
    resetPlayerRestrictionsAlertsErrors,
    updatePlayerRestrictions
} from '../../../store/actions';
import {
    prepareUpdatePlayerRestrictionsFieldErrors
} from './model/playerEditModel';
import Loader from '../../../common/components/Loader';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import { isEqual } from "lodash";
import Formatter from "../../../utils/Formatter";

const LimitTypes = {
    ['DailyDepositMax']: 'dailyDepositMax',
    ['DailyDepositMin']: 'dailyDepositMin',
    ['DailyWithdrawalMax']: 'dailyWithdrawalMax',
    ['DailyWithdrawalMin']: 'dailyWithdrawalMin',
    ['WeeklyDepositMax']: 'weeklyDepositMax',
    ['WeeklyDepositMin']: 'weeklyDepositMin',
    ['WeeklyWithdrawalMax']: 'weeklyWithdrawalMax',
    ['WeeklyWithdrawalMin']: 'weeklyWithdrawalMin',
    ['MonthlyDepositMax']: 'monthlyDepositMax',
    ['MonthlyDepositMin']: 'monthlyDepositMin',
    ['MonthlyWithdrawalMax']: 'monthlyWithdrawalMax',
    ['MonthlyWithdrawalMin']: 'monthlyWithdrawalMin',
}

function RestrictionsTabContent({playerId}) {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const {playerGeneral, isStatusUpdated, currency: generalCurrency} =
        useSelector(state => state.PlayerGeneral);

    const {isLoading, error, restrictions, successAlert} = useSelector(
        state => state.PlayerRestrictions
    );

    const [dailyDepositMax, setDailyDepositMax] = useState(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.max);
    const [dailyDepositMin, setDailyDepositMin] = useState(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.min);
    const [dailyWithdrawalMax, setDailyWithdrawalMax] = useState(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.max);
    const [dailyWithdrawalMin, setDailyWithdrawalMin] = useState(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.min);
    const [weeklyDepositMax, setWeeklyDepositMax] = useState(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.max);
    const [weeklyDepositMin, setWeeklyDepositMin] = useState(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.min);
    const [weeklyWithdrawalMax, setWeeklyWithdrawalMax] = useState(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.max);
    const [weeklyWithdrawalMin, setWeeklyWithdrawalMin] = useState(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.min);
    const [monthlyDepositMax, setMonthlyDepositMax] = useState(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.max);
    const [monthlyDepositMin, setMonthlyDepositMin] = useState(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.min);
    const [monthlyWithdrawalMax, setMonthlyWithdrawalMax] = useState(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.max);
    const [monthlyWithdrawalMin, setMonthlyWithdrawalMin] = useState(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.min);


    const [isBlockWithdrawals, setIsBlockWithdrawals] = useState(restrictions?.isBlockWithdrawals);
    const [isBlockAutoWithdrawals, setIsBlockAutoWithdrawals] = useState(restrictions?.isBlockAutoWithdrawals);
    const [isBlockGamePlay, setIsBlockGamePlay] = useState(restrictions?.isBlockGamePlay);
    const [isBlockDeposits, setIsBlockDeposits] = useState(restrictions?.isBlockDeposits);
    const [isBlockSport, setIsBlockSport] = useState(restrictions?.isBlockSport);
    const [isBlockLiveGames, setIsBlockLiveGames] = useState(restrictions?.isBlockLiveGames);
    const [isBlockCancelWithdrawals, setIsBlockCancelWithdrawals] = useState(restrictions?.isBlockCancelWithdrawals);


    useEffect(() => {
        setIsBlockWithdrawals(restrictions?.isBlockWithdrawals);
        setIsBlockAutoWithdrawals(restrictions?.isBlockAutoWithdrawals);
        setIsBlockGamePlay(restrictions?.isBlockGamePlay);
        setIsBlockDeposits(restrictions?.isBlockDeposits);
        setIsBlockSport(restrictions?.isBlockSport);
        setIsBlockLiveGames(restrictions?.isBlockLiveGames);
        setIsBlockCancelWithdrawals(restrictions?.isBlockCancelWithdrawals);
        setDailyDepositMax(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.max);
        setDailyDepositMin(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.min);
        setDailyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.max);
        setDailyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.min);
        setWeeklyDepositMax(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.max);
        setWeeklyDepositMin(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.min);
        setWeeklyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.max);
        setWeeklyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.min);
        setMonthlyDepositMax(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.max);
        setMonthlyDepositMin(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.min);
        setMonthlyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.max);
        setMonthlyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.min);
    }, [restrictions])

    const [restrictionsModel, setRestrictionsModel] = useState(
        JSON.parse(JSON.stringify(restrictions))
    );
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        dispatch(getPlayerRestrictions(playerId));
    }, [playerId]);

    useEffect(() => {
        setRestrictionsModel(JSON.parse(JSON.stringify(restrictions)));
        handleSetEdit(false);
    }, [restrictions]);

    useEffect(() => {
        if (error && error.statusCode === 400) {
            setFieldErrors(
                prepareUpdatePlayerRestrictionsFieldErrors(error?.data)
            );
            dispatch(resetPlayerRestrictionsAlertsErrors());
        } else if (error) {
            alertService.showError(error);
            dispatch(resetPlayerRestrictionsAlertsErrors());
        }
    }, [error]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetPlayerRestrictionsAlertsErrors());
        }
    }, [successAlert]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setEditPlayerRestrictions(nextIsEdit));
    };

    const validateModelDto = (model) => {
        const updateMinMaxValues = (obj) => {
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object') {
                        updateMinMaxValues(obj[key]);
                    } else {
                        if ((key === 'min' || key === 'max') && obj[key] === "") {
                            obj[key] = 0;
                        }
                    }
                }
            }
        };

        updateMinMaxValues(model);
        return true;
    };

    const handleSave = useCallback(() => {
        if (validateModelDto(restrictionsModel)) {
            const updatedModel = {
                ...restrictionsModel,
                cashierLimits: {
                    ...restrictionsModel.cashierLimits,
                    playerLimits: {
                        ...restrictionsModel.cashierLimits.playerLimits,
                        // TMP fix with empty array (BO-2662)
                        disabledPaymentMethods: []
                    }
                }
            };

            console.log(updatedModel);
            dispatch(updatePlayerRestrictions({ id: playerId, dto: updatedModel }));
        } else {
            alertService.showError('crm.appError');
        }
    }, [restrictionsModel, restrictions]);

    const handleCancel = useCallback(() => {
        setRestrictionsModel(restrictions);
        setIsBlockWithdrawals(restrictions?.isBlockWithdrawals);
        setIsBlockAutoWithdrawals(restrictions?.isBlockAutoWithdrawals);
        setIsBlockGamePlay(restrictions?.isBlockGamePlay);
        setIsBlockDeposits(restrictions?.isBlockDeposits);
        setIsBlockSport(restrictions?.isBlockSport);
        setIsBlockLiveGames(restrictions?.isBlockLiveGames);
        setIsBlockCancelWithdrawals(restrictions?.isBlockCancelWithdrawals);
        setDailyDepositMax(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.max);
        setDailyDepositMin(restrictions?.cashierLimits?.playerLimits?.daily?.deposit?.min);
        setDailyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.max);
        setDailyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.daily?.withdraw?.min);
        setWeeklyDepositMax(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.max);
        setWeeklyDepositMin(restrictions?.cashierLimits?.playerLimits?.weekly?.deposit?.min);
        setWeeklyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.max);
        setWeeklyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.weekly?.withdraw?.min);
        setMonthlyDepositMax(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.max);
        setMonthlyDepositMin(restrictions?.cashierLimits?.playerLimits?.monthly?.deposit?.min);
        setMonthlyWithdrawalMax(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.max);
        setMonthlyWithdrawalMin(restrictions?.cashierLimits?.playerLimits?.monthly?.withdraw?.min);
        handleSetEdit(false);
    }, [restrictions, handleSetEdit]);
    useHandleEsc(handleCancel);

    const isEdit = useMemo(() => {
        if (!restrictions || !restrictionsModel) {
            return false;
        }
        const isEditMode = !isEqual(restrictions, restrictionsModel)
        handleSetEdit(isEditMode);
        return isEditMode;
    }, [restrictions, restrictionsModel]);

    if (!restrictions || !restrictionsModel) {
        return <Loader size={'lg'}/>;
    }

    const handleLimitChange = (type) => (value) => {
        const valueToNumber = value === '' ? '' : Number(value);
        const newObj = JSON.parse(JSON.stringify(restrictionsModel));

        switch (type) {
            case LimitTypes.DailyDepositMax:
                setDailyDepositMax(valueToNumber);
                newObj.cashierLimits.playerLimits.daily.deposit.max = valueToNumber;
                break;
            case LimitTypes.DailyDepositMin:
                setDailyDepositMin(valueToNumber);
                newObj.cashierLimits.playerLimits.daily.deposit.min = valueToNumber;
                break;
            case LimitTypes.DailyWithdrawalMax:
                setDailyWithdrawalMax(valueToNumber);
                newObj.cashierLimits.playerLimits.daily.withdraw.max = valueToNumber;
                break;
            case LimitTypes.DailyWithdrawalMin:
                setDailyWithdrawalMin(valueToNumber);
                newObj.cashierLimits.playerLimits.daily.withdraw.min = valueToNumber;
                break;
            case LimitTypes.WeeklyDepositMax:
                setWeeklyDepositMax(valueToNumber);
                newObj.cashierLimits.playerLimits.weekly.deposit.max = valueToNumber;
                break;
            case LimitTypes.WeeklyDepositMin:
                setWeeklyDepositMin(valueToNumber);
                newObj.cashierLimits.playerLimits.weekly.deposit.min = valueToNumber;
                break;
            case LimitTypes.WeeklyWithdrawalMax:
                setWeeklyWithdrawalMax(valueToNumber);
                newObj.cashierLimits.playerLimits.weekly.withdraw.max = valueToNumber;
                break;
            case LimitTypes.WeeklyWithdrawalMin:
                setWeeklyWithdrawalMin(valueToNumber);
                newObj.cashierLimits.playerLimits.weekly.withdraw.min = valueToNumber;
                break;
            case LimitTypes.MonthlyDepositMax:
                setMonthlyDepositMax(valueToNumber);
                newObj.cashierLimits.playerLimits.monthly.deposit.max = valueToNumber;
                break;
            case LimitTypes.MonthlyDepositMin:
                setMonthlyDepositMin(valueToNumber);
                newObj.cashierLimits.playerLimits.monthly.deposit.min = valueToNumber;
                break;
            case LimitTypes.MonthlyWithdrawalMax:
                setMonthlyWithdrawalMax(valueToNumber);
                newObj.cashierLimits.playerLimits.monthly.withdraw.max = valueToNumber;
                break;
            case LimitTypes.MonthlyWithdrawalMin:
                setMonthlyWithdrawalMin(valueToNumber);
                newObj.cashierLimits.playerLimits.monthly.withdraw.min = valueToNumber;
                break;
            default:
                return;
        }
        setRestrictionsModel(newObj);
    }

    const handleBlockChange = (type) => (value) => {
        switch (type) {
            case 'isBlockWithdrawals':
                setIsBlockWithdrawals(value);
                break;
            case 'isBlockAutoWithdrawals':
                setIsBlockAutoWithdrawals(value);
                break;
            case 'isBlockGamePlay':
                setIsBlockGamePlay(value);
                break;
            case 'isBlockDeposits':
                setIsBlockDeposits(value);
                break;
            case 'isBlockSport':
                setIsBlockSport(value);
                break;
            case 'isBlockLiveGames':
                setIsBlockLiveGames(value);
                break;
            case 'isBlockCancelWithdrawals':
                setIsBlockCancelWithdrawals(value);
                break;
        }
        setRestrictionsModel(prev => ({
            ...prev,
            [type]: value
        }));
    }

    const amountCurrency = (value, playerCurrency) => {
        return Formatter.formatAmount(
            new Intl.NumberFormat().format(value) ?? 0,
            playerCurrency
        );
    };

    return (
        <div className="detailed-card__content responsible-gaming">
            {isLoading
                ? <Loader size={'lg'}/>
                : <div className="sub-content">
                    <div className="sub-content__header d-flex align-items-center">
                        <img className="me-3" src={header_icon} alt=""/>
                        <span>{i18n.t('crm.gamingRestrictions')}</span>
                    </div>
                    <div className="sub-content__content d-flex flex-wrap restrictions-checkboxes">
                        <div
                            className={`d-flex align-items-center ${fieldErrors?.isBlockWithdrawals ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockWithdrawals}
                                onChange={
                                    handleBlockChange('isBlockWithdrawals')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockWithdrawals')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div
                            className={`d-flex align-items-center ${fieldErrors?.isBlockAutoWithdrawals ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockAutoWithdrawals}
                                onChange={
                                    handleBlockChange('isBlockAutoWithdrawals')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockAutoWithdrawals')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div className={`d-flex align-items-center ${fieldErrors?.isBlockGamePlay ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockGamePlay}
                                onChange={handleBlockChange('isBlockGamePlay')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockGaming')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div className={`d-flex align-items-center ${fieldErrors?.isBlockDeposits ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockDeposits}
                                onChange={handleBlockChange('isBlockDeposits')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockDeposits')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div className={`d-flex align-items-center ${fieldErrors?.isBlockSport ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockSport}
                                onChange={handleBlockChange('isBlockSport')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockSport')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div
                            className={`d-flex align-items-center ${fieldErrors?.isBlockLiveGames ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockLiveGames}
                                onChange={handleBlockChange('isBlockLiveGames')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockLiveGames')}</span>
                            </SwitchCheckboxInput>
                        </div>

                        <div
                            className={`d-flex align-items-center ${fieldErrors?.isBlockCancelWithdrawals ? 'has-error' : ''}`}>
                            <SwitchCheckboxInput
                                checked={isBlockCancelWithdrawals}
                                onChange={handleBlockChange('isBlockCancelWithdrawals')}
                                additionalClassName="mb-2 me-5"
                            >
                                <span className="font-w-500">{i18n.t('crm.blockReverseWD')}</span>
                            </SwitchCheckboxInput>
                        </div>
                    </div>

                    <hr/>

                    <span
                        className="sub-content__header align-content-xxl-center limits-title">{i18n.t('crm.depositLimits')}</span>

                    <div className="limits-wrapper">
                        <div className="sub-content__content">
                            <span
                                className="sub-content__header d-flex align-items-center">{i18n.t('crm.dailyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.daily?.deposit.max -
                                    restrictionsModel?.cashierLimits?.status?.daily?.deposit,
                                    playerGeneral.currency
                                )}
                        </span>

                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={dailyDepositMin}
                                handleChange={handleLimitChange(LimitTypes.DailyDepositMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={dailyDepositMax}
                                handleChange={handleLimitChange(LimitTypes.DailyDepositMax)}
                            />
                        </div>
                        <div className="sub-content__content limits-item">
                            <span className="sub-content__header d-flex align-items-center">{i18n.t('crm.weeklyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.weekly?.deposit.max -
                                    restrictionsModel?.cashierLimits?.status?.weekly?.deposit,
                                    playerGeneral.currency
                                )}
                        </span>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={weeklyDepositMin}
                                handleChange={handleLimitChange(LimitTypes.WeeklyDepositMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={weeklyDepositMax}
                                handleChange={handleLimitChange(LimitTypes.WeeklyDepositMax)}
                            />
                        </div>
                        <div className="sub-content__content limits-item">
                            <span className="sub-content__header d-flex align-items-center">{i18n.t('crm.monthlyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.monthly?.deposit.max -
                                    restrictionsModel?.cashierLimits?.status?.monthly?.deposit,
                                    playerGeneral.currency
                                )}
                        </span>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={monthlyDepositMin}
                                handleChange={handleLimitChange(LimitTypes.MonthlyDepositMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={monthlyDepositMax}
                                handleChange={handleLimitChange(LimitTypes.MonthlyDepositMax)}
                            />
                        </div>
                    </div>

                    <hr style={{color: '#dedfe0'}}></hr>

                    <span className="sub-content__header align-content-xxl-center limits-title">
                    {i18n.t('crm.withdrawLimits')}
                </span>

                    <div className="limits-wrapper">
                        <div className="sub-content__content">
                            <span className="sub-content__header d-flex align-items-center">{i18n.t('crm.dailyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.daily?.withdraw.max -
                                    restrictionsModel?.cashierLimits?.status?.daily?.withdraw,
                                    playerGeneral.currency
                                )}
                        </span>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={dailyWithdrawalMin}
                                handleChange={handleLimitChange(LimitTypes.DailyWithdrawalMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={dailyWithdrawalMax}
                                handleChange={handleLimitChange(LimitTypes.DailyWithdrawalMax)}
                            />
                        </div>
                        <div className="sub-content__content limits-item">
                            <span className="sub-content__header d-flex align-items-center">{i18n.t('crm.weeklyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.weekly?.withdraw.max -
                                    restrictionsModel?.cashierLimits?.status?.weekly?.withdraw,
                                    playerGeneral.currency
                                )}
                        </span>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={weeklyWithdrawalMin}
                                handleChange={handleLimitChange(LimitTypes.WeeklyWithdrawalMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={weeklyWithdrawalMax}
                                handleChange={handleLimitChange(LimitTypes.WeeklyWithdrawalMax)}
                            />
                        </div>
                        <div className="sub-content__content limits-item">
                            <span className="sub-content__header d-flex align-items-center">{i18n.t('crm.monthlyLimit')}</span>
                            <span className="sub-content__header align-items-center" style={{fontSize: '11px'}}>
                                {i18n.t('crm.remaining')}:{' '}
                                {amountCurrency(
                                    restrictionsModel?.cashierLimits?.playerLimits?.monthly?.withdraw.max -
                                    restrictionsModel?.cashierLimits?.status?.monthly?.withdraw,
                                    playerGeneral.currency
                                )}
                        </span>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.min')}
                                value={monthlyWithdrawalMin}
                                handleChange={handleLimitChange(LimitTypes.MonthlyWithdrawalMin)}
                                currency={playerGeneral.currency}
                            />
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                label={i18n.t('crm.max')}
                                value={monthlyWithdrawalMax}
                                handleChange={handleLimitChange(LimitTypes.MonthlyWithdrawalMax)}
                            />
                        </div>
                    </div>
                    <hr/>
                </div>
            }

            {isEdit && (
                <ButtonsPanel
                    onSave={handleSave}
                    onCancel={handleCancel}
                    isDisabled={isLoading}
                />
            )}
        </div>
    );
};

RestrictionsTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(RestrictionsTabContent);
