export const GET_NEW_CLIENTS_WIDGET = 'GET_NEW_CLIENTS_WIDGET';
export const GET_NEW_CLIENTS_WIDGET_SUCCESS = 'GET_NEW_CLIENTS_WIDGET_SUCCESS';
export const GET_NEW_CLIENTS_WIDGET_FAIL = 'GET_NEW_CLIENTS_WIDGET_FAIL';

export const GET_GGR_WIDGET = 'GET_GGR_WIDGET';
export const GET_GGR_WIDGET_SUCCESS =
    'GET_GGR_WIDGET_SUCCESS';
export const GET_GGR_WIDGET_FAIL = 'GET_GGR_WIDGET_FAIL';

export const GET_TRANSACTIONS_WIDGET = 'GET_TRANSACTIONS_WIDGET';
export const GET_TRANSACTIONS_WIDGET_SUCCESS =
    'GET_TRANSACTIONS_WIDGET_SUCCESS';
export const GET_TRANSACTIONS_WIDGET_FAIL = 'GET_TRANSACTIONS_WIDGET_FAIL';

export const GET_VERIFIED_WIDGET = 'GET_VERIFIED_WIDGET';
export const GET_VERIFIED_WIDGET_SUCCESS = 'GET_VERIFIED_WIDGET_SUCCESS';
export const GET_VERIFIED_WIDGET_FAIL = 'GET_VERIFIED_WIDGET_FAIL';

export const RESET_OVERVIEW_ERRORS = 'RESET_OVERVIEW_ERRORS';
