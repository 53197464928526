import {
    GET_PLAYER_LIST,
    GET_PLAYER_LIST_SUCCESS,
    GET_PLAYER_LIST_FAIL,
    GET_PLAYER_KYC_LIST,
    GET_PLAYER_KYC_LIST_SUCCESS,
    GET_PLAYER_KYC_LIST_FAIL,
    GET_PLAYER_KYC_COUNT,
    GET_PLAYER_KYC_COUNT_SUCCESS,
    GET_PLAYER_KYC_COUNT_FAIL,
    GET_PLAYER_TRACKER,
    GET_PLAYER_TRACKER_SUCCESS,
    GET_PLAYER_TRACKER_FAIL,
    UPDATE_PLAYERS,
    UPDATE_PLAYERS_SUCCESS,
    UPDATE_PLAYERS_FAIL,
    RESET_PLAYER_LIST_ALERTS_ERRORS,
    RESET_PLAYER_QUERY,
    DOWNLOAD_PLAYER_LIST,
    DOWNLOAD_PLAYER_LIST_SUCCESS,
    DOWNLOAD_PLAYER_LIST_FAIL,
    DOWNLOAD_PLAYER_PENDING_LIST,
    DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS,
    DOWNLOAD_PLAYER_PENDING_LIST_FAIL,
    UPDATE_PLAYER_IDENTITY_INFO,
} from './actionTypes';

export const getPlayerList = payload => ({
    type: GET_PLAYER_LIST,
    payload
});

export const getPlayerListSuccess = payload => ({
    type: GET_PLAYER_LIST_SUCCESS,
    payload
});

export const getPlayerListFail = payload => ({
    type: GET_PLAYER_LIST_FAIL,
    payload
});

export const getPlayerKYCList = payload => ({
    type: GET_PLAYER_KYC_LIST,
    payload
});

export const getPlayerKYCListSuccess = payload => ({
    type: GET_PLAYER_KYC_LIST_SUCCESS,
    payload
});

export const getPlayerKYCListFail = payload => ({
    type: GET_PLAYER_KYC_LIST_FAIL,
    payload
});

export const getPlayerKYCCount = payload => ({
    type: GET_PLAYER_KYC_COUNT,
    payload
});

export const getPlayerKYCCountSuccess = payload => ({
    type: GET_PLAYER_KYC_COUNT_SUCCESS,
    payload
});

export const getPlayerKYCCountFail = payload => ({
    type: GET_PLAYER_KYC_COUNT_FAIL,
    payload
});

export const getPlayerTracker = payload => ({
    type: GET_PLAYER_TRACKER,
    payload
});

export const getPlayerTrackerSuccess = payload => ({
    type: GET_PLAYER_TRACKER_SUCCESS,
    payload
});

export const getPlayerTrackerFail = payload => ({
    type: GET_PLAYER_TRACKER_FAIL,
    payload
});

export const updatePlayers = payload => ({
    type: UPDATE_PLAYERS,
    payload
});

export const updatePlayerIdentityInfo = payload => ({
    type: UPDATE_PLAYER_IDENTITY_INFO,
    payload
});

export const updatePlayersSuccess = payload => ({
    type: UPDATE_PLAYERS_SUCCESS,
    payload
});

export const updatePlayersFail = payload => ({
    type: UPDATE_PLAYERS_FAIL,
    payload
});

export const resetPlayerListAlertsErrors = payload => ({
    type: RESET_PLAYER_LIST_ALERTS_ERRORS,
    payload
});

export const resetPlayerQuery = () => ({
    type: RESET_PLAYER_QUERY,
});

export const downloadPlayerList = payload => ({
    type: DOWNLOAD_PLAYER_LIST,
    payload
});

export const downloadPlayerListSuccess = payload => ({
    type: DOWNLOAD_PLAYER_LIST_SUCCESS,
    payload
});

export const downloadPlayerListFail = payload => ({
    type: DOWNLOAD_PLAYER_LIST_FAIL,
    payload
});


export const downloadPlayerPendingList = payload => ({
    type: DOWNLOAD_PLAYER_PENDING_LIST,
    payload
});

export const downloadPlayerPendingListSuccess = payload => ({
    type: DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS,
    payload
});

export const downloadPlayerPendingListFail = payload => ({
    type: DOWNLOAD_PLAYER_PENDING_LIST_FAIL,
    payload
});
