import React, { useEffect, useRef, useState } from "react";
import ZoomPan from "../../../utils/zoomPan";
import { BASE_URL } from "../../../common/constants/common";
import { getAccessToken } from "../../../helpers/api_helper";
import rotateCounterClockwise from "../../../assets/images/layout/rotateCounterClockwise.svg";
import rotateClockwise from "../../../assets/images/layout/rotateClockwise.svg";
import plusLens from "../../../assets/images/layout/plusLens.svg";
import minusLens from "../../../assets/images/layout/minusLens.svg";
import reset from "../../../assets/images/layout/restore.svg";
import { useTranslation } from "react-i18next";
import { getStorageXSiteId } from "../../../common/utils/common";
import VerticalButtonsPanel from "../../Admin/site/common/VerticalButtonsPanel";
import clsx from "clsx";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";

export const setPositionRotateButtons = (isUpdate) => {
    const imgParent = document.getElementById('kyc-image-wrapper');
    const img = document.getElementById('document-view');
    const buttons = document.getElementById('kyc-rotate-buttons');

    if (img) {
        const imgPos = img.getBoundingClientRect().top + window.pageYOffset;
        const imgParentPos = imgParent.getBoundingClientRect().top + window.pageYOffset;

        let imageRotate = imgParentPos === imgPos;
        isUpdate ? imageRotate : imageRotate = !imageRotate;
        let rotateSize = 0;
        if (imageRotate === false) {
            if (img.clientHeight > img.clientWidth) {
                rotateSize = (img.clientHeight - img.clientWidth) / 2;
            } else {
                rotateSize = (img.clientWidth - img.clientHeight) / 2;
            }
        }
        buttons.style.marginTop = img.clientHeight > img.clientWidth ? "-" + rotateSize + 'px' : "" + rotateSize + 'px';
    }
}

export function ViewImg(props) {
    const { id, currentDocument, updateCurrentDocument } = props;
    const initialRotateDegree = currentDocument.config.rotateDegree || 0;
    const ref = useRef(null);
    const { t } = useTranslation();
    const documentViewWrapperRef = useRef(null);
    const size = useWindowSize();

    const [src, setSrc] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [rotatedDegree, setRotatedDegree] = useState(initialRotateDegree || 0);
    const [zoomPanInstance, setZoomPanInstance] = useState(null);

    const prepareZoomPanInstance = (rotateDeg = 0) => {
        const img = document.getElementById('document-view-wrapper');
        const newInstance = ZoomPan({
            minScale: 1,
            maxScale: 2,
            element: img.children[0],
            scaleSensitivity: 30,
            rotateDeg,
        });
        setZoomPanInstance(newInstance);
        return newInstance;
    }

    const handleMove = (e) => {
        e.preventDefault();
        if (isEdit || e.buttons !== 1) {
            return;
        }

        const documentViewWrapper = documentViewWrapperRef.current;

        if (documentViewWrapper) {
            const { left, top, width, height } = documentViewWrapper.getBoundingClientRect();
            const maxX = left + width;
            const maxY = top + height;

            const x = e.clientX;
            const y = e.clientY;

            if (x >= left && x <= maxX && y >= top && y <= maxY) {
                (
                    zoomPanInstance === null
                        ? prepareZoomPanInstance(rotatedDegree)
                        : zoomPanInstance
                )
                    .panBy({
                        originX: e.movementX,
                        originY: e.movementY
                    });
            }
        }
    };

    const handleRestore = (initialFlag = false) => {
        (
            zoomPanInstance === null || initialFlag
                ? prepareZoomPanInstance(rotatedDegree)
                : zoomPanInstance
        )
            .panTo({
                originX: 0,
                originY: 0,
                scale: 1,
            });
    }

    const handleZoom = (event) => {
        if (isEdit || !event.shiftKey) {
            return;
        }
        const currentRotatedDegree = rotatedDegree;
        const deltaScale = Math.sign(event.deltaY) > 0 ? -3 : 3;

        (
            zoomPanInstance === null
                ? prepareZoomPanInstance(currentRotatedDegree)
                : zoomPanInstance
        )
            .zoom({
                deltaScale,
                x: event.pageX,
                y: event.pageY
            });
    }

    const handleClickZoom = (event) => {
        (
            zoomPanInstance === null
                ? prepareZoomPanInstance(rotatedDegree)
                : zoomPanInstance
        )
            .zoom({
                deltaScale: Math.sign(event.deltaY) > 0 ? -3 : 3,
                x: event.pageX,
                y: event.pageY
            });
    }

    const handleClickUnZoom = (event) => {
        (
            zoomPanInstance === null
                ? prepareZoomPanInstance(rotatedDegree)
                : zoomPanInstance
        )
            .zoom({
                deltaScale: Math.sign(event.deltaY) > 0 ? 3 : -3,
                x: event.pageX,
                y: event.pageY
            });
    }

    useEffect(() => {
        setSrc('');
        setIsEdit(false);
        const url = `${BASE_URL}/api/documents/${id}?token=${getAccessToken()}`;
        const options = {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                'X-Site-Id': getStorageXSiteId(),
            }
        };

        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                setSrc(URL.createObjectURL(blob));
            });
    },[id]);

    useEffect(() => {
        setRotatedDegree(initialRotateDegree);
    }, [currentDocument.oid]);

    const rotateImage = (degree) => {
        const newRotatedDegree = (rotatedDegree + degree) % 360;
        prepareZoomPanInstance(newRotatedDegree);
        setIsEdit(true);
        setRotatedDegree(newRotatedDegree);
        setPositionRotateButtons();
    }

    const handleCancel = () => {
        setRotatedDegree(initialRotateDegree);
        setIsEdit(false);
    };

    const handleSave = () => {
        setIsEdit(false);
        const currDoc = Object.assign(currentDocument);
        currDoc.config = { rotateDegree: rotatedDegree };
        updateCurrentDocument(currDoc);
        handleRestore(true);
    };

    return (
        <>
                <div className={"kyc-image-wrapper"} id="kyc-image-wrapper">
                    <div
                        className="document-view-wrapper"
                        id="document-view-wrapper"
                        onWheel={handleZoom}
                        onMouseMove={handleMove}
                        ref={documentViewWrapperRef}
                    >
                        <img
                            id="document-view"
                            style={{ transform: `rotate(${rotatedDegree}deg)`, width: '380px'}}
                            ref={ref}
                            src={src}
                            alt=""
                        />
                    </div>
                    <div className={clsx('kyc-image-buttons', {
                        ['d-none']: size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT,
                    })} id="kyc-rotate-buttons">
                        <div className={"kyc-image-buttons-inner"}>
                            <div className='document-view-btn upload-document kyc-button'>
                                <img
                                    className='upload-document kyc-button rotate-left'
                                    src={rotateCounterClockwise}
                                    alt="KYC document"
                                    width={40}
                                    onClick={() => rotateImage(-90)}
                                />
                            </div>
                            <div className='document-view-btn upload-document kyc-button rotate-right'>
                                <img
                                    className='upload-document kyc-button rotate-right'
                                    src={rotateClockwise}
                                    alt="KYC document"
                                    width={40}
                                    onClick={() => rotateImage(90)}
                                />
                            </div>
                            <div className='document-view-btn upload-document kyc-button'>
                                <img
                                    className='upload-document kyc-button'
                                    src={plusLens}
                                    alt="plus"
                                    width={40}
                                    onClick={handleClickZoom}
                                />
                            </div>
                            <div className='document-view-btn upload-document kyc-button'>
                                <img
                                    className='upload-document kyc-button'
                                    src={minusLens}
                                    alt="minus"
                                    width={40}
                                    onClick={handleClickUnZoom}
                                />
                            </div>
                            <div className='document-view-btn upload-document kyc-button'>
                                <img
                                    className='upload-document kyc-button'
                                    src={reset}
                                    alt="reset"
                                    width={40}
                                    onClick={handleRestore}
                                />
                            </div>
                        </div>
                        {isEdit &&
                            <VerticalButtonsPanel
                                onCancel={handleCancel}
                                onSave={handleSave}
                            />
                        }
                    </div>
                </div>
        </>
    )
}
