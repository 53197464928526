import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import i18n from '../../../i18n';

import { CommonFilter } from './CommonFilterPopup';
import NumberInput from '../../../common/inputs/NumberInput';
import { FILTER_EXCEL } from '../../../constants/Filter';
import useHandleEnter from '../../../common/hooks/useHandleEnter';

const OPERATIONS_MAP = {
    '<': FILTER_EXCEL.LESS_THAN,
    '<=': FILTER_EXCEL.LESS_OR_EQ,
    '>': FILTER_EXCEL.GREATER_THAN,
    '>=': FILTER_EXCEL.GREATER_OR_EQUAL,
    '=': FILTER_EXCEL.EQUAL,
    '<>': FILTER_EXCEL.NOT_EQUAL,
};

const initialState = {
    [FILTER_EXCEL.BLANK]: false,
    [FILTER_EXCEL.EXCLUDE_BLANK]: false,
    [FILTER_EXCEL.LESS_THAN]: '',
    [FILTER_EXCEL.LESS_OR_EQ]: '',
    [FILTER_EXCEL.GREATER_THAN]: '',
    [FILTER_EXCEL.GREATER_OR_EQUAL]: '',
    [FILTER_EXCEL.EQUAL]: '',
    [FILTER_EXCEL.NOT_EQUAL]: ''
};

const prepareValueKeyToFilter = key => {
    switch (key) {
        case FILTER_EXCEL.LESS_THAN:
            return '<';
        case FILTER_EXCEL.LESS_OR_EQ:
            return '<=';
        case FILTER_EXCEL.GREATER_THAN:
            return '>';
        case FILTER_EXCEL.GREATER_OR_EQUAL:
            return '>=';
        case FILTER_EXCEL.EQUAL:
            return '=';
        case FILTER_EXCEL.NOT_EQUAL:
            return '<>';
    }
};

const prepareInitialValue = (initialValue) => {
    const valueParts = initialValue.split(' ');
    if (valueParts.length < 2) {
        return undefined;
    }

    const [op, value] = valueParts;

    if (op in OPERATIONS_MAP) {
        return {
            ...initialState,
            [OPERATIONS_MAP[op]]: value,
        };
    }

    return undefined;
}

export const FilterExcel = ({ title, onSave, onCancel, popupKey, initialValue }) => {
    const preparedInitialValue = useMemo(() => prepareInitialValue(initialValue), [initialValue]);

    const [state, setState] = useState(preparedInitialValue ? preparedInitialValue : initialState);

    const isValueValid = (value) => {
        return value !== undefined && value !== null && value >= 0 && value !== '';
    };

    const saveFilter = () => {
        if (isValueValid(state[FILTER_EXCEL.LESS_THAN])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.LESS_THAN) } ${state[FILTER_EXCEL.LESS_THAN]}`
                }
            ]);
        }
        if (isValueValid(state[FILTER_EXCEL.LESS_OR_EQ])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.LESS_OR_EQ) } ${state[FILTER_EXCEL.LESS_OR_EQ]}`
                }
            ]);
        }
        if (isValueValid(state[FILTER_EXCEL.GREATER_THAN])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.GREATER_THAN) } ${state[FILTER_EXCEL.GREATER_THAN]}`
                }
            ]);
        }
        if (isValueValid(state[FILTER_EXCEL.GREATER_OR_EQUAL])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.GREATER_OR_EQUAL) } ${state[FILTER_EXCEL.GREATER_OR_EQUAL]}`
                }
            ]);
        }
        if (isValueValid(state[FILTER_EXCEL.EQUAL])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.EQUAL) } ${state[FILTER_EXCEL.EQUAL]}`
                }
            ]);
        }
        if (isValueValid(state[FILTER_EXCEL.NOT_EQUAL])) {
            onSave([
                {
                    key: popupKey,
                    value: `${prepareValueKeyToFilter(FILTER_EXCEL.NOT_EQUAL) } ${state[FILTER_EXCEL.NOT_EQUAL]}`
                }
            ]);
        }
    }

    const handleChange = useCallback(
        key => value => {
            const isEmptyValue = value === '';
            setState({ ...initialState, [key]: isEmptyValue ? value : Math.abs(value) });
        },
        [setState]
    );

    const handleClick = useCallback(
        key => () => {
            setState(prev => ({ ...prev, [key]: !prev[key] }));
        },
        [setState]
    );

    const isButtonActive = useCallback(() => {
        return Object.values(state).some(item => typeof item === "number" && item >= 0)
    }, [state])

    const renderElements = useMemo(
        () => [
            // { isSimpleElement: true, onChange: handleClick, elementKey: FILTER_EXCEL.BLANK },
            // { isSimpleElement: true, onChange: handleClick, elementKey: FILTER_EXCEL.EXCLUDE_BLANK },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.LESS_THAN,
                id: 'filter-deposits_wrapper-less-than',
                label: '<'
            },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.LESS_OR_EQ,
                id: 'filter-deposits_wrapper-less-or',
                label: '<='
            },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.GREATER_THAN,
                id: 'filter-deposits_wrapper-greater-than',
                label: '>'
            },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.GREATER_OR_EQUAL,
                id: 'filter-deposits_wrapper-greater-or',
                label: '>='
            },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.EQUAL,
                id: 'filter-deposits_wrapper-eq',
                label: '='
            },
            {
                isSimpleElement: false,
                onChange: handleChange,
                elementKey: FILTER_EXCEL.NOT_EQUAL,
                id: 'filter-deposits_wrapper-not-eq',
                label: '<>'
            }
        ],
        []
    );

    // useEffect(() => {
    //   if (state[FILTER_EXCEL.BLANK]) { onSave([{key: FILTER_EXCEL.BLANK, value: FILTER_SIMPLE_COMMON_VALUES.YES}]); }
    //   if (state[FILTER_EXCEL.EXCLUDE_BLANK]) { onSave([{ key: FILTER_EXCEL.EXCLUDE_BLANK, value: FILTER_SIMPLE_COMMON_VALUES.YES }]); }
    // }, [state]);

    useHandleEnter(saveFilter);

    return (
        <CommonFilter
            title={title}
            className={'filter-deposits'}
            onCancel={onCancel}
        >
            <Row className={'filter-deposits_wrapper'}>
                {renderElements.map(
                    (
                        {
                            isSimpleElement,
                            elementKey,
                            onChange,
                            placeholder,
                            label,
                            id,
                            value
                        },
                        index
                    ) => (
                        <Col
                            key={index}
                            xs={12}
                            className={'filter-deposits_item'}
                        >
                            {isSimpleElement ? (
                                <div
                                    className={`filter-deposits_item-check${
                                        state[elementKey] ? ' active' : ''
                                    }`}
                                    onClick={handleClick(elementKey)}
                                >
                                    {elementKey === FILTER_EXCEL.BLANK
                                        ? `<blank>`
                                        : `<exclude blank>`}
                                </div>
                            ) : (
                                <>
                                    <label htmlFor={id}>{label}</label>
                                    <NumberInput
                                        id={id}
                                        placeholder={i18n.t(
                                            `filter.constant.${elementKey}`
                                        )}
                                        value={state[elementKey]}
                                        onChange={onChange(elementKey)}
                                    />
                                </>
                            )}
                        </Col>
                    )
                )}
            </Row>

            <Button
                color="primary"
                disabled={!isButtonActive()}
                onClick={saveFilter}
            >
                {i18n.t('filter.apply')}
            </Button>
        </CommonFilter>
    );
};

FilterExcel.proptypes = {
    title: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    popupKey: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
};

FilterExcel.defaultProps = {
    initialValue: '',
};
