export const paymentMethods = [
    'CREDITCARD',
    'CRYPTOCURRENCY',
    'FLEXEPIN',
    'SPEI',
    'OXXO',
    'CASH',
    'A1',
    'ACCENTPAY',
    'ACCENTPAY-MONETA',
    'ACCENTPAY-QIWI',
    'ACCENTPAY-WEBMONEY',
    'ACCENTPAY-YANDEX',
    'ACCOUNTCREATION',
    'ACH',
    'AIRCASHVOUCHER',
    'AIRCASHWALLET',
    'ALIPAY',
    'APCO',
    'APCO-APG',
    'APCO-ASTROPAY',
    'APCO-DIRECTPAY',
    'APCO-ECOPAYZ',
    'APCO-ELEGRO',
    'APCO-ENTROPAY',
    'APCO-GIROPAY',
    'APCO-ICARD',
    'APCO-IDEAL',
    'APCO-INOVAPAY',
    'APCO-INSTANDEBIT',
    'APCO-NETELLER',
    'APCO-PAYDOO',
    'APCO-PAYPAL',
    'APCO-PROCESSINGCOM',
    'APCO-SKRILL',
    'APCO-XPATE',
    'APLAUZ',
    'APPLEPAY',
    'ASTROPAYCARD',
    'ASTROPAYDIRECT',
    'BANK',
    'BANK-INTERAC',
    'BANK-INTERAC_ETRANSFER',
    'BANK-INTERAC_ONLINE',
    'BANKDIRECT',
    'BANKDOMESTIC',
    'BANKIBAN',
    'BANKLATAM',
    'BANKLOCAL',
    'BESTPAY',
    'BLIK',
    'BOKU',
    'BOLETO',
    'CASHLATAM',
    'CASHLIB',
    'CEZAMVOUCHER',
    'CHANNELCREATION',
    'CHINAUNIONPAY',
    'CITIZEN',
    'CLICKANDBUY',
    'COMMUNITYBANK',
    'CRYPTOPAY',
    'DIMOCO',
    'DOTPAY',
    'EASYPAY',
    'ECOBANQ',
    'ECOMCHARGE',
    'ECOMMPAY',
    'ECOPAYZ',
    'ENTROPAY',
    'ENVOY',
    'ENVOY-EKONTO',
    'ENVOY-EPS',
    'ENVOY-FUNDSEND',
    'ENVOY-GIROPAY',
    'ENVOY-IDEAL',
    'ENVOY-PRZELEWY',
    'ENVOY-SOFORT',
    'ENVOY-TRUSTPAY',
    'ENVOY-UKASHHOSTED',
    'EPRO',
    'EPS',
    'EUTELLER',
    'EZEEWALLET',
    'FACILERO',
    'FONIX',
    'FORTUMO',
    'FUNANGA',
    'GAMERSBANKWALLET',
    'GIFTCARD',
    'GIROPAY',
    'GLOBEPAY',
    'GOOGLEPAY',
    'HIPAY',
    'IBANQ',
    'ICARD',
    'ICARDDIRECT',
    'IDEAL',
    'IDEBIT',
    'INOVAPAYVOUCHER',
    'INOVAPAYWALLET',
    'INSTADEBIT',
    'INSTADEBITECHECK',
    'INTERAC',
    'INTERAC-INTERAC_ETRANSFER',
    'INTERAC-INTERAC_ONLINE',
    'INTERSWITCH',
    'IWALLET',
    'JETON',
    'JPAY',
    'KLARNA',
    'KLARNATERMS',
    'KLUWP',
    'LAVAPAY',
    'LOADNGO',
    'LOTERICA',
    'LUXONPAY',
    'MACROPAY',
    'MBANKOMAT',
    'MIFINITY',
    'MOBILEGIRO',
    'MOBILEPAY',
    'MOBILEPAYSUBSCRIPTION',
    'MONEYPAY',
    'MUCHBETTER',
    'NEOSURFVOUCHER',
    'NETELLER',
    'OKTOPAYCASH',
    'OKTOPAYWALLET',
    'ORIGOHEREANDNOW',
    'ORIGOINVOICE',
    'PAGAVA',
    'PAGOEFECTIVO',
    'PAY4FUN',
    'PAY4FUNGO',
    'PAYBOX',
    'PAYGROUND',
    'PAYKASA',
    'PAYLEVO',
    'PAYLIVRE',
    'PAYMENTZ',
    'PAYPAL',
    'PAYSAFECARD',
    'PERFECTMONEYVOUCHER',
    'PIASTRIX',
    'PIX',
    'PMICASH',
    'POINT66',
    'PPRO',
    'PPRO-ASTROPAYCARD',
    'PPRO-ASTROPAYDIRECT',
    'PPRO-BOLETO',
    'PPRO-EPS',
    'PPRO-GIROPAY',
    'PPRO-IDEAL',
    'PPRO-MISTERCASH',
    'PPRO-OXXO',
    'PPRO-P24',
    'PPRO-PAYSAFECARD',
    'PPRO-POLI',
    'PPRO-POSTFINANCE',
    'PPRO-PUGGLEPAY',
    'PPRO-QIWI',
    'PPRO-SAFETYPAY',
    'PPRO-SEPADIRECTDEBIT',
    'PPRO-SKRILL',
    'PPRO-SOFORT',
    'PPRO-TELEINGRESO',
    'PPRO-TRUSTLY',
    'PPRO-TRUSTPAY',
    'PPRO-VERKKOPANKKI',
    'PRADEXX',
    'PREMIERPAYDIRECT',
    'PREMIERPAYWALLET',
    'PRZELEWY24',
    'PUGGLEPAY',
    'QIWI',
    'QLIRO',
    'RAMP_OPERATIONS',
    'REALDEPOSITS',
    'ROYALPAYVOUCHER',
    'RUPEEPAYMENTSCASH',
    'SANTANDERFINANCINGSWEDENBUYNOWPAYLATER',
    'SANTANDERFINANCINGSWEDENPARTIALPAYMENT',
    'SECURETRADING',
    'SECURETRADINGWALLET',
    'SEQR',
    'SIIRTO',
    'SIRU',
    'SKRILL',
    'SKRILLQCO',
    'SMSVOUCHER',
    'SOFORT',
    'SWEDBANKEXPRESS',
    'SWISH',
    'TELEINGRESO',
    'TICKETPREMIUM',
    'TICKETSURF',
    'TODITO',
    'TODITOCASH',
    'TOLAMOBILE',
    'TOTALCOINBANK',
    'TOTALCOINCARD',
    'TRUSTLY',
    'TWINT',
    'UKASH',
    'UPAYCARD',
    'VCREDITOS',
    'VCREDITOSVSI',
    'VEGA',
    'VENUSPOINT',
    'VIPPS',
    'VISAVOUCHER',
    'VLOAD',
    'VOUCHER',
    'VOUCHERAMOUNT',
    'VOUCHERCREATION',
    'VOUCHERLATAM',
    'WALLET',
    'WALLET88',
    'WALLETLATAM',
    'WEBREDIRECT',
    'WORLDPAY',
    'YOPAYMENTS',
    'YUUCOLLECT',
    'ZIMPLER',
];