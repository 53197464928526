import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import close from "../../../assets/images/common/close 1.svg";
import './styles.scss';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import {A_COMMON_DATA_FIELD} from "../../../constants/aCommonDataField";
import {FIELD_TYPE} from "../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function AddSiteFieldModal({
    onCancel,
    onAddField,
    isArrayElement,
}) {
    const [fieldType, setFieldType] = useState(FIELD_TYPE.STRING);
    const [fieldName, setFieldName] = useState('');

    const handleAddField = useCallback(() => {
        if (isArrayElement) {
            onAddField(fieldType);
        }
        else {
            onAddField(fieldType, fieldName);
        }
    }, [fieldType, fieldName, isArrayElement]);

    const handleChangeFieldType = (fieldType) => () => {
        setFieldType(fieldType)
    };

    const handleChangeFieldName = (fieldName) => {
        setFieldName(fieldName)
    };

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="add-brand-field-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('admin.add.field')}
                </h5>
                <button className="close" onClick={onCancel} />
            </div>

            <ModalBody>
                <span className='modal-title'>Field Types:</span>
                <>
                    {Object.keys(FIELD_TYPE).map((key) => (
                        <>
                            <span>
                                <input
                                    className="field-type-radio"
                                    checked={FIELD_TYPE[key] === fieldType}
                                    type="radio"
                                    name="fieldType"
                                    value={FIELD_TYPE[key]}
                                    onClick={handleChangeFieldType(FIELD_TYPE[key])}
                                />
                                {FIELD_TYPE[key]}
                            </span>
                        </>
                    ))}
                </>

                {!isArrayElement &&
                    <ACommonDataField
                        label={i18n.t('admin.field.name') + ' *'}
                        component={A_COMMON_DATA_FIELD.TEXT}
                        value={fieldName}
                        handleChange={handleChangeFieldName}
                        fulWidth={true}
                    />
                }
            </ModalBody>
            <ModalFooter
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 10,
                    marginBottom: 10,
                }}
            >
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onCancel}
                    style={{
                        width: '30%',
                    }}
                >
                    <img src={close} alt="" />
                    {i18n.t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={handleAddField}
                    style={{
                        width: '30%',
                        marginLeft: 20,
                    }}
                    disabled={fieldName === '' && !isArrayElement}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('admin.add.field')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

AddSiteFieldModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAddField: PropTypes.func.isRequired,
    isArrayElement: PropTypes.bool,
};

AddSiteFieldModal.defaultProps = {
    isArrayElement: false,
};

export default React.memo(AddSiteFieldModal);
