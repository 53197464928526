import { takeEvery, put, call } from 'redux-saga/effects';

import {
    DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST,
    GET_PLAYER_AUDIT_CHANGES_LIST,
    GET_PLAYER_AUDIT_LOGINS_LIST,
    GET_PLAYER_AUDIT_STATUS_LIST,
    GET_PLAYER_AUDIT_FILTERS,
} from './actionTypes';
import {
    getPlayerAuditLoginsListSuccess,
    getPlayerAuditLoginsListFail,
    getPlayerAuditStatusListSuccess,
    getPlayerAuditStatusListFail,
    getPlayerAuditChangesListSuccess,
    getPlayerAuditChangesListFail,
    getPlayerAuditFiltersSuccess,
    getPlayerAuditFiltersFail,
    downloadPlayerAuditLoginsListSuccess,
    downloadPlayerAuditLoginsListFail,
} from './actions';
import {
    downloadPlayerAuditLoginsListApi,
    getPlayerAuditChangesListApi,
    getPlayerAuditLoginsListApi,
    getPlayerAuditStatusListApi,
    getPlayerAuditFiltersApi,
} from '../../../helpers/playerApi';

function* fetchPlayerAuditLoginsListSaga({ payload }) {
    try {
        const response = yield call(getPlayerAuditLoginsListApi, payload);

        yield put(getPlayerAuditLoginsListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAuditLoginsListFail(error));
    }
}

function* fetchPlayerAuditStatusListSaga({ payload }) {
    try {
        const response = yield call(getPlayerAuditStatusListApi, payload);

        yield put(getPlayerAuditStatusListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAuditStatusListFail(error));
    }
}

function* fetchPlayerAuditChangesListSaga({ payload }) {
    try {
        const response = yield call(getPlayerAuditChangesListApi, payload);

        yield put(getPlayerAuditChangesListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAuditChangesListFail(error));
    }
}

function* fetchPlayerAuditFilterSaga({ payload }) {
    try {
        const response = yield call(getPlayerAuditFiltersApi, payload);

        yield put(getPlayerAuditFiltersSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAuditFiltersFail(error));
    }
}

function* downloadPlayerAuditLoginsListSaga({ payload }) {
    try {
        yield call(downloadPlayerAuditLoginsListApi, payload);

        yield put(downloadPlayerAuditLoginsListSuccess());
    } catch (error) {
        yield put(downloadPlayerAuditLoginsListFail(error));
    }
}

function* playersSaga() {
    yield takeEvery(
        GET_PLAYER_AUDIT_LOGINS_LIST,
        fetchPlayerAuditLoginsListSaga
    );
    yield takeEvery(
        GET_PLAYER_AUDIT_STATUS_LIST,
        fetchPlayerAuditStatusListSaga
    );
    yield takeEvery(
        GET_PLAYER_AUDIT_CHANGES_LIST,
        fetchPlayerAuditChangesListSaga
    );
    yield takeEvery(
        GET_PLAYER_AUDIT_FILTERS,
        fetchPlayerAuditFilterSaga
    );
    yield takeEvery(
        DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST,
        downloadPlayerAuditLoginsListSaga
    );
}

export default playersSaga;
