import React from 'react';
import PropTypes from 'prop-types';
import useHandleEsc from '../../../../common/hooks/useHandleEsc';
import close from '../../../../assets/images/common/close 1.svg';
import i18n from '../../../../i18n';
import i_51 from '../../../../assets/images/common/Frame 176 (1).svg';
import clsx from "clsx"

function ButtonsPanel({ onCancel, onSave, className }) {
    useHandleEsc(onCancel);

    return (
        <div className={clsx("buttons-row", className)}>
            {onCancel !== 'none' && (
                <button
                    className="btn btn-rounded btn-light me-2"
                    onClick={onCancel}
                >
                    <img className="me-2" src={close} alt="" />
                    {i18n.t('admin.cancel')}
                </button>
            )}

            <button className="btn btn-rounded btn-primary" onClick={onSave}>
                <img src={i_51} alt="" />
                {i18n.t('admin.save')}
            </button>
        </div>
    );
}

ButtonsPanel.propTypes = {
    onCancel: PropTypes.oneOf([PropTypes.func, 'none']).isRequired,
    onSave: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default React.memo(ButtonsPanel);
