import { takeEvery, call, put } from '@redux-saga/core/effects';

import i18n from '../../i18n';

import { GET_NOTIFICATIONS_LIST } from './actionTypes';
import { getNotificationsListSuccess } from './action';

export function* getNotificationsListSaga({ payload }) {
    try {
        const response = yield call(getNotificationsListApi, payload);

        if (response && response?.status === 200) {
            yield put(getNotificationsListSuccess(response?.data?.result));
        }
    } catch (error) {
        console.error(error);
    }
}

function* notificationSaga() {
    yield takeEvery(GET_NOTIFICATIONS_LIST, getNotificationsListSaga);
}

export default notificationSaga;
