import React from 'react';
import PropTypes from 'prop-types';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import close from '../../../assets/images/common/Frame 179.svg';
import i18n from '../../../i18n';
import group_4752 from '../../../assets/images/common/Group 4752.svg';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';

function ButtonsPanel({ onCancel, onSave, onSetIsEdit, isEdit }) {
    useHandleEsc(onCancel);

    return (
        <div className="buttons-row">
            {isEdit ? (
                <>
                    <button
                        className="btn btn-rounded btn-light me-2"
                        onClick={onCancel}
                    >
                        <img src={close} alt="" />
                        {i18n.t('common.cancel')}
                    </button>

                    <button
                        className="btn btn-rounded btn-primary"
                        onClick={onSave}
                    >
                        <img src={i_51} alt="" />
                        {i18n.t('common.save')}
                    </button>
                </>
            ) : (
                <>
                    <button
                        className="btn btn-rounded btn-primary"
                        onClick={() => onSetIsEdit(true)}
                    >
                        <img className="me-2" src={group_4752} alt="" />
                        {i18n.t('common.edit')}
                    </button>
                </>
            )}
        </div>
    );
}

ButtonsPanel.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSetIsEdit: PropTypes.func.isRequired,
    isEdit: PropTypes.bool
};

export default React.memo(ButtonsPanel);
