import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';

import i18n from '../../../i18n';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

import closeIcon from '../../../assets/images/layout/thin-cross.svg';
import saveIcon from '../../../assets/images/common/Frame 176 (1).svg';

const DeleteFilterModal = ({ onHide, currentModalData, onDelete }) => (
    <Modal
        isOpen={true}
        role="dialog"
        autoFocus={true}
        data-toggle="modal"
        className="filter-modal"
        centered
        onOpened={setUnScrollBody}
        onExit={removeUnScrollBody}
    >
        <div className="modal-header">
            <h5 className="modal-title text-uppercase">
                {i18n.t('filter.delete')} {currentModalData?.filterName}
            </h5>
            <button className="close" onClick={onHide} />
        </div>

        <ModalBody>
            <p>{i18n.t('filter.deleteText')}</p>

            <div className={'modal-btn-wrapper'} onClick={onHide}>
                <Button color={'outline-primary modal-btn'}>
                    <img src={closeIcon} alt={i18n.t('filter.cancel')} />
                    <span>{i18n.t('filter.cancel')}</span>
                </Button>

                <Button
                    color={'primary modal-btn'}
                    onClick={onDelete(currentModalData?.id)}
                >
                    <img src={saveIcon} alt={i18n.t('filter.delete')} />
                    <span>{i18n.t('filter.delete')}</span>
                </Button>
            </div>
        </ModalBody>
    </Modal>
);

DeleteFilterModal.proptypes = {
    onHide: PropTypes.func.isRequired,
    currentModalData: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default DeleteFilterModal;
