import {
    GET_SUPPLIER_LIST,
    GET_SUPPLIER_LIST_SUCCESS,
    GET_SUPPLIER_LIST_FAIL,
    GET_CASHIER_LIST,
    GET_CASHIER_LIST_SUCCESS,
    GET_CASHIER_LIST_FAIL,
    GET_CASHIER_METHOD,
    GET_CASHIER_METHOD_SUCCESS,
    GET_CASHIER_METHOD_FAIL,
    UPDATE_CASHIER_METHOD,
    UPDATE_CASHIER_METHOD_SUCCESS,
    UPDATE_CASHIER_METHOD_FAIL,
    CREATE_CASHIER_METHOD,
    CREATE_CASHIER_METHOD_FAIL,
    CREATE_CASHIER_METHOD_SUCCESS,
    GET_SUPPLIER_BY_ID,
    GET_SUPPLIER_BY_ID_SUCCESS,
    GET_SUPPLIER_BY_ID_FAIL,
    UPDATE_CASHIER_SUPPLIER,
    UPDATE_CASHIER_SUPPLIER_SUCCESS,
    UPDATE_CASHIER_SUPPLIER_FAIL,
    CREATE_CASHIER_SUPPLIER_SUCCESS,
    CREATE_CASHIER_SUPPLIER_FAIL,
    CREATE_CASHIER_SUPPLIER,
    REMOVE_SUPPLIER_BY_ID,
    REMOVE_SUPPLIER_BY_ID_SUCCESS,
    REMOVE_SUPPLIER_BY_ID_FAIL,
    REMOVE_METHOD_BY_ID_SUCCESS,
    REMOVE_METHOD_BY_ID_FAIL,
    REMOVE_METHOD_BY_ID,
} from "./actionTypes";

const INIT_STATE = {
    cashierList: [],
    cashierSuppliers: [],
    lastCreatedMethod: null,
    lastCreatedSupplier: null,
    cashierMethod: [],
    cashierListUpdated: false,
    error: null,
    successMessage: null,
    isLoading: false,
    isLoadingCashierList: false,
};

const Cashier = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SUPPLIER_LIST_SUCCESS:
            return {
                ...state,
                siteSupplierList: action.payload.data.result,
                isLoading: false,
            };

        case GET_SUPPLIER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case GET_SUPPLIER_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_CASHIER_LIST_SUCCESS:
            return {
                ...state,
                cashierList: action.payload.data.result,
                isLoadingCashierList: false,
            };

        case GET_CASHIER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoadingCashierList: false,
            };

        case GET_CASHIER_LIST:
            return {
                ...state,
                isLoadingCashierList: true,
            };

        case GET_CASHIER_METHOD_SUCCESS:
            return {
                ...state,
                cashierMethod: [action.payload.data],
                isLoading: false,
            };

        case GET_CASHIER_METHOD_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case GET_CASHIER_METHOD:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_CASHIER_METHOD_SUCCESS:
            const updatedMethod = action.payload;
            const updatedList = state.cashierList.reduce((acc, curr) => {
                if (curr.id === updatedMethod?.id) {
                    acc = [...acc, updatedMethod]
                } else {
                    acc = [...acc, curr];
                }
                return acc;
            }, [])

            return {
                ...state,
                cashierList: updatedList,
                cashierListUpdated: true,
                successMessage: 'crm.cashierMethodAUpdated',
                isLoading: false,
            };

        case UPDATE_CASHIER_METHOD_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                cashierListUpdated: false,
            };

        case UPDATE_CASHIER_METHOD:
            return {
                ...state,
                isLoading: true,
                cashierListUpdated: false,
            };

        case CREATE_CASHIER_METHOD_SUCCESS:
            return {
                ...state,
                cashierList: [...state.cashierList, action.payload],
                lastCreatedMethod: action.payload,
                successMessage: 'crm.cashierMethodAdded',
                isLoading: false,
            };

        case CREATE_CASHIER_METHOD_FAIL:
            return {
                ...state,
                successMessage: null,
                error: action.payload,
                isLoading: false,
            };

        case CREATE_CASHIER_METHOD:
            return {
                ...state,
                isLoading: true,
            };

        case GET_SUPPLIER_BY_ID_SUCCESS:
            return {
                ...state,
                cashierSuppliers: action.payload.data.result || [],
                isLoading: false,
            };

        case GET_SUPPLIER_BY_ID_FAIL:
            return {
                ...state,
                successMessage: null,
                error: action.payload,
                isLoading: false,
            };

        case GET_SUPPLIER_BY_ID:
            return {
                ...state,
                isLoading: true,
            };

        case UPDATE_CASHIER_SUPPLIER_SUCCESS:
            const updatedSupplier = action.payload;
            const updatedSuppliersList = state.cashierSuppliers.reduce((acc, curr) => {
                if (curr.id === updatedSupplier?.id) {
                    acc = [...acc, updatedSupplier]
                } else {
                    acc = [...acc, curr];
                }
                return acc;
            }, [])

            return {
                ...state,
                cashierSuppliers: updatedSuppliersList,
                isLoading: false,
                successMessage: 'crm.cashierSupplierUpdated',
            }

        case UPDATE_CASHIER_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload,
                successMessage: null,
                isLoading: false,
            };

        case UPDATE_CASHIER_SUPPLIER:
            return {
                ...state,
                isLoading: true,
            };

        case CREATE_CASHIER_SUPPLIER_SUCCESS:
            return {
                ...state,
                cashierSuppliers: [...state.cashierSuppliers, action.payload],
                lastCreatedSupplier: action.payload,
                successMessage: 'crm.cashierSupplierAdded',
                isLoading: false,
            };

        case CREATE_CASHIER_SUPPLIER_FAIL:
            return {
                ...state,
                error: action.payload,
                successMessage: null,
                isLoading: false,
            };

        case CREATE_CASHIER_SUPPLIER:
            return {
                ...state,
                isLoading: true,
            };

        case REMOVE_SUPPLIER_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successMessage: 'crm.cashier.supplierDeleted',
                error: null
            };

        case REMOVE_SUPPLIER_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                successMessage: null,
                isLoading: false,
            };

        case REMOVE_SUPPLIER_BY_ID:
            return {
                ...state,
                isLoading: true,
                error: null,
                successMessage: null,
            };

        case REMOVE_METHOD_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successMessage: 'crm.cashier.methodDeleted',
                error: null
            };

        case REMOVE_METHOD_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                successMessage: null,
                isLoading: false,
            };

        case REMOVE_METHOD_BY_ID:
            return {
                ...state,
                isLoading: true,
                error: null,
                successMessage: null,
            };
        default:
            return state;
    }
};

export default Cashier;
