import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Container, Row } from 'reactstrap';

import i18n from '../../i18n';
import Breadcrumb from '../../common/components/Breadcrumb';

import './styles.scss';

import useAlertService from '../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWithdrawal,
    resetWithdrawalAlertsErrors
} from '../../store/actions';
import Loader from '../../common/components/Loader';
import PaymentMainCard from './PaymentMainCard';
import PaymentDetailedCard from './PaymentDetailedCard';
import PageTitle from "../../components/PageTitle";

function WithdrawView() {
    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const { withdrawal, error } = useSelector(
        state => state.WithdrawalDetailed
    );

    useEffect(() => {
        if (error && error?.message) {
            alertService.showError(error?.message);
            dispatch(resetWithdrawalAlertsErrors());
        }
    }, [error]);

    useEffect(() => {
        loadPayment();
    }, [params.id]);

    const loadPayment = () => dispatch(getWithdrawal(params.id));

    return (
        <div className="page-content document-content page-content_responsive">
            <MetaTags>
                <title>{i18n.t('finance.withdrawView')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Container fluid>
                {withdrawal ?
                  <Row>
                      <Col lg="12">
                          <PaymentMainCard payment={withdrawal} />

                          <PaymentDetailedCard
                            payment={withdrawal}
                            reloadPayment={loadPayment}
                            isWithdrawal
                          />
                      </Col>
                  </Row> :
                  <Loader />
                }
            </Container>
        </div>
    );
}

export default withRouter(WithdrawView);
