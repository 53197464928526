import { takeEvery, call, put } from '@redux-saga/core/effects';
import {
    editFilterPageSuccess,
    filterPageError,
    getFiltersListPageSuccess,
    removeFromFilterPageSuccess
} from './action';
import { removeFromList } from '../action';
import {
    EDIT_FILTER_PAGE,
    REMOVE_FROM_FILTERS_PAGE,
    GET_FILTERS_LIST_PAGE
} from './actionTypes';
import {
    changeFilterApi,
    getFiltersListApi,
    removeFilterApi
} from '../../../helpers/filterApi';

// TODO fix when ip is ready
export function* getFiltersPageListSaga({ payload: query }) {
    try {
        const response = yield call(getFiltersListApi, query);

        if (response.status === 200) {
            yield put(getFiltersListPageSuccess(response.data));
        } else {
            yield put(filterPageError({ message: response?.message }));
        }
    } catch (error) {
        yield put(filterPageError({ message: error }));
    }
}

export function* removeFromFiltersListSaga({ payload: id }) {
    try {
        const response = yield call(removeFilterApi, id);

        if (response.success) {
            yield put(removeFromFilterPageSuccess(id));
            yield put(removeFromList(id));
        } else {
            yield put(filterPageError({ message: response?.message }));
        }
    } catch (error) {
        yield put(filterPageError({ message: error }));
    }
}

export function* editFilterAtFilterPageSaga({
    payload: { filterName, id, accessGroup }
}) {
    try {
        const response = yield call(changeFilterApi, id, {
            filterName,
            accessGroup
        });

        if (response.status === 200) {
            yield put(editFilterPageSuccess(response?.data));
        } else {
            yield put(filterPageError({ message: response?.message }));
        }
    } catch (error) {
        yield put(filterPageError({ message: error }));
    }
}

function* filterPageSaga() {
    yield takeEvery(GET_FILTERS_LIST_PAGE, getFiltersPageListSaga);
    yield takeEvery(REMOVE_FROM_FILTERS_PAGE, removeFromFiltersListSaga);
    yield takeEvery(EDIT_FILTER_PAGE, editFilterAtFilterPageSaga);
}

export default filterPageSaga;
