import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InfoBlockHeader from './InfoBlockHeader';
import srcIcon from '../../../../assets/images/common/FTDs.svg';
import { Card } from 'reactstrap';
import i18n from '../../../../i18n';
import StatisticsInfoView from './StatisticsInfoView';
import AppLineChart from '../../../../components/charts/AppLineChart';

const FtdsWidget = () => {
    const data = {
        widget: {
            sun: 100,
            mon: 200,
            tue: 250,
            wed: 150,
            thu: 50,
            fri: 350,
            sat: 130
        },
        today: 24,
        mtd: 235
    };

    const chartData = useMemo(() => {
        if (!data) return null;

        return Object.keys(data.widget).map((key, index) => ({
            name: i18n.t(`admin.dataKey.${key}`),
            value: data.widget[key]
        }));
    }, [data]);

    return (
        <Card className="statistic-widget clients-registered-widget">
            <InfoBlockHeader title={i18n.t('admin.FTDS')} srcIcon={srcIcon} />
            <div className="clients-registered-widget__content">
                <div>
                    <StatisticsInfoView
                        title={i18n.t('admin.today')}
                        content={data.today}
                        className="statistics-info-view--lg text-center"
                    />
                    <StatisticsInfoView
                        title={i18n.t('admin.MTD')}
                        content={data.mtd}
                        className="text-center"
                    />
                </div>

                <AppLineChart chartData={chartData} />
            </div>
        </Card>
    );
};

FtdsWidget.propTypes = {
    data: PropTypes.object.isRequired
};

export default React.memo(FtdsWidget);
