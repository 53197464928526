import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import i18n from '../../../i18n';
import {
    FILTER_DATE_RANGE_ACTIVE_INPUT,
    FILTER_DATE_RANGE_OPTIONS,
    FILTER_DATE_RANGE_VALUES,
    FILTER_MENU_SELECT_LIST_VALUES,
} from '../../../constants/Filter';
import { CommonFilter } from './CommonFilterPopup';
import RadioInput from '../../../common/inputs/RadioInput';
import ACommonDataField from '../../GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import SearchAndCheckboxSelect from "../../../common/inputs/common/SearchAndCheckboxSelect";
import clsx from "clsx";


export const FROM_DATE_FOR_MORE_THAN = new Date('1900-01-01').setHours(
    0,
    0,
    0,
    0
);

const initialState = {
    [FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST]: {},
    [FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN]: {},
    [FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN]: {}
};

const prepareDate = ({ time, dateRange }) => {
    const date = new Date();
    if (
        dateRange === FILTER_DATE_RANGE_VALUES.DAYS ||
        dateRange === FILTER_DATE_RANGE_VALUES.WEEKS ||
        dateRange === FILTER_DATE_RANGE_VALUES.MONTH ||
        dateRange === FILTER_DATE_RANGE_VALUES.YEAR
    ) {
        date.setHours(24, 0, 0, 0);
    }

    let timeDiff = 0;

    switch (dateRange) {
        case FILTER_DATE_RANGE_VALUES.MINS:
            timeDiff = time * 60 * 1000;
            break;
        case FILTER_DATE_RANGE_VALUES.HOURS:
            timeDiff = time * 60 * 60 * 1000;
            break;
        case FILTER_DATE_RANGE_VALUES.DAYS:
            timeDiff = time * 24 * 60 * 60 * 1000;
            break;
        case FILTER_DATE_RANGE_VALUES.WEEKS:
            timeDiff = time * 7 * 24 * 60 * 60 * 1000;
            break;
    }

    if (dateRange === FILTER_DATE_RANGE_VALUES.MONTH) {
        date.setMonth(date.getMonth() - time);
    } else if (dateRange === FILTER_DATE_RANGE_VALUES.YEAR) {
        date.setFullYear(date.getFullYear() - time);
    } else {
        date.setTime(date.getTime() - timeDiff);
    }

    return date.toISOString();
};

const prepareDateTo = dateRange => {
    const date = new Date();
    if (
        dateRange === FILTER_DATE_RANGE_VALUES.DAYS ||
        dateRange === FILTER_DATE_RANGE_VALUES.WEEKS ||
        dateRange === FILTER_DATE_RANGE_VALUES.MONTH ||
        dateRange === FILTER_DATE_RANGE_VALUES.YEAR
    ) {
        date.setHours(24, 0, 0, 0);
    }

    return date.toISOString();
};

const initState = (initialValue) => {
    if (initialValue) {
        const { info, from, to } = initialValue;

        if (info) {
            const { active, ...activeValue } = info;

            if (active in initialState) {
                return {
                    ...initialState,
                    [active]: activeValue,
                };
            }
        }
        else if (from && to) {
            return {
                ...initialState,
                [FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN]: {
                    from,
                    to,
                },
            };
        }
    }

    return initialState;
}

const initActive = (initialValue) =>
    initialValue?.info?.active || (
        initialValue?.from && initialValue?.to
            ? FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN
            : FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST
    );

export const FilterDateRange = ({ title, onChange, popupKey, onCancel, activePopup, initialValue }) => {
    const { promotions } = useSelector(state => state.Promotions);
    const promoDropDown = promotions?.result?.map(promo => ({
        key: promo.name,
        label: promo.name
    })) || [];

    const [active, setActive] =
        useState(initActive(initialValue));
    const [state, setState] = useState(initState(initialValue));
    const [promoType, setPromoType] = useState();
    const [promoName, setPromoName] = useState();
    const isBirthdayPopup = popupKey === 'birth_date';

    const isEnabled = useMemo(() => {
        const from = !!state[active].from || state[active].time;
        const to = !!state[active].to || state[active].dateRange;

        return from && to;
    }, [active, state]);

    const handleActiveChange = useCallback(
        value => {
            setActive(value);
        },
        [setActive]
    );

    const handleChangeState = useCallback(
        key => value => {
            if (active === FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN) {
                setState(prev => {
                    let from = key === 'from' ? value : prev[active].from;
                    let to = key === 'to' ? value : prev[active].to;

                    if (
                        (key === 'from' && prev[active].to) &&
                        (key === 'to' && prev[active].from)
                    ) {
                        const fromDate = new Date(from);
                        const toDate = new Date(to);

                        if (key === 'from' && prev[active].to) {
                            to =
                                fromDate.getTime() > toDate.getTime()
                                    ? null
                                    : prev[active].to;
                        }

                        if (key === 'to' && prev[active].from) {
                            from =
                                fromDate.getTime() > toDate.getTime()
                                    ? null
                                    : prev[active].from;
                        }
                    }

                    return {
                        ...prev,
                        [active]: {
                            from,
                            to
                        }
                    };
                });
            }

            setState(prev => ({
                ...prev,
                [active]: {
                    ...prev[active],
                    [key]: value
                }
            }));
        },
        [setState, active]
    );

    const checkboxList = useMemo(() => {
        if (activePopup.key !== FILTER_MENU_SELECT_LIST_VALUES.AGENT_OWNER) {
            return activePopup.list
        }
        return [];
    }, [activePopup.key, activePopup.list]);

    const handleSelectPromoType = useCallback(
        value => {
            setPromoType(value);
        },
        []
    );

    const handleSelectPromoName = useCallback(
        value => {
            setPromoName(value);
        },
        []
    );

    const onSave = useCallback(() => {
        let dateRange = {
            from: new Date(),
            to: new Date()
        };

        switch (active) {
            case FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST:
                dateRange = {
                    from: prepareDate(state[active]),
                    to: prepareDateTo(state[active].dateRange),
                    info: { ...state[active], active }
                };
                break;
            case FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN:
                dateRange = {
                    from: new Date(FROM_DATE_FOR_MORE_THAN).toISOString(),
                    to: prepareDate(state[active]),
                    info: { ...state[active], active }
                };
                break;
            case FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN:
                dateRange.from = new Date(
                    new Date(state[active].from).toISOString());
                dateRange.to = new Date(
                    new Date(state[active].to).toISOString());
                break;
        }

        const _query = [ { key: popupKey, value: dateRange } ]
        if (promoType) _query.push({ key: 'promoType', value: promoType })
        if (promoName) _query.push({ key: 'promoName', value: promoName })

        onChange(_query);
    }, [active, state]);

    return (
        <CommonFilter
            title={title}
            className={'filter-date_range'}
            onCancel={onCancel}
        >
            {activePopup.activeModal === 'PROMOTION_MODAL' &&
            <>
                <Row className={'align-items-center mb-2'} style={{paddingBottom: '20px'}}>
                    <SearchAndCheckboxSelect
                        placeholder={i18n.t('crm.selectTypeOfPromo')}
                        checkboxList={checkboxList}
                        onChange={handleSelectPromoType}
                        activePopup={activePopup}
                    />
                </Row>
                <Row className={'align-items-center mb-2'} style={{paddingBottom: '20px'}}>
                    <SearchAndCheckboxSelect
                        placeholder={i18n.t('crm.selectPromotions')}
                        checkboxList={promoDropDown}
                        onChange={handleSelectPromoName}
                        activePopup={activePopup}
                    />
                </Row>
            </>
            }
            {activePopup.activeModal !== 'PROMOTION_MODAL' &&
                <>
                <Row className={'align-items-center mb-2 filter-date_range__row'}>
                    <Col xs={4} className={'text-nowrap pe-0'}>
                        <RadioInput
                            className={'filter-date_range-radio_input'}
                            checked={
                                active ===
                                FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST
                            }
                            value={FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST}
                            onChange={handleActiveChange}
                        >
                            {i18n.t('filter.withinTheLast')}
                        </RadioInput>
                    </Col>

                    <div className='filter-date_range__fields'>
                        <Col xs={2}>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                inputProps={{ min: 1 }}
                                value={Math.abs(state[FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST]['time'])}
                                handleChange={handleChangeState('time')}
                                fulWidth={true}
                                disabled={
                                    active !==
                                    FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST
                                }
                                style={{ width: '60px' }}
                            />
                        </Col>
    
                        <Col xs={4}>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                options={FILTER_DATE_RANGE_OPTIONS}
                                value={
                                state[
                                FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST
                                ]['dateRange']
                                }
                                handleChange={handleChangeState('dateRange')}
                                fulWidth={true}
                                disabled={
                                active !==
                                FILTER_DATE_RANGE_ACTIVE_INPUT.WITHIN_THE_LAST
                                }
                                style={{ minWidth: '120px' }}
                            />
                        </Col>
                    </div>
                </Row>

                <Row className={'align-items-center mb-2 filter-date_range__row'}>
                    <Col xs={4}>
                        <RadioInput
                            className={'filter-date_range-radio_input'}
                            checked={
                            active === FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN
                            }
                            value={FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN}
                            onChange={handleActiveChange}
                            >
                            {i18n.t('filter.moreThan')}
                        </RadioInput>
                    </Col>

                    <div className='filter-date_range__fields'>
                        <Col xs={2} className={'d-flex align-items-center'}>
                            <ACommonDataField
                                component={A_COMMON_DATA_FIELD.NUMBER}
                                inputProps={{ min: 1 }}
                                value={Math.abs(state[FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN]['time'])}
                                handleChange={handleChangeState('time')}
                                fulWidth={true}
                                disabled={
                                active !== FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN
                                }
                                style={{ width: '60px' }}
                            />
                        </Col>
    
                        <Col xs={4} className={'d-flex align-items-center'}>
                            <div className="mt-auto">
                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                    options={FILTER_DATE_RANGE_OPTIONS}
                                    value={
                                    state[FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN][
                                    'dateRange'
                                    ]
                                    }
                                    handleChange={handleChangeState('dateRange')}
                                    fulWidth={true}
                                    disabled={
                                    active !==
                                    FILTER_DATE_RANGE_ACTIVE_INPUT.MORE_THAN
                                    }
                                    style={{ minWidth: '120px' }}
                                />
                            </div>
                            <span className={'mx-2'}>{i18n.t('filter.ago')}</span>
                        </Col>
                    </div>
                </Row>
            </>
            }

            <Row className={'align-items-center'}>
                <Col xs={12} md={3}>
                    <RadioInput
                        className={'filter-date_range-radio_input'}
                        checked={
                            active === FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN
                        }
                        value={FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN}
                        onChange={handleActiveChange}
                    >
                        {i18n.t('filter.between')}
                    </RadioInput>
                </Col>

                <div className='filter-date_range-date_input'>
                    <Col xs={5} md={4} className={clsx('p-0', {
                        'dateFieldFilter': isBirthdayPopup,
                    })}>
                        <ACommonDataField
                            component={isBirthdayPopup
                                ? A_COMMON_DATA_FIELD.DATE
                                : A_COMMON_DATA_FIELD.DATE_TIME
                            }
                            value={state[FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN]['from']}
                            handleChange={handleChangeState('from')}
                            fulWidth={true}
                            disabled={
                                active !== FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN
                            }
                            isBirthDate={activePopup.key === 'birth_date'}
                        />
                    </Col>
    
                    <Col xs={5} md={4} className={clsx('p-0 ml-10', {
                        'dateFieldFilter': isBirthdayPopup,
                    })}>
                        <ACommonDataField
                            component={isBirthdayPopup
                                ? A_COMMON_DATA_FIELD.DATE
                                : A_COMMON_DATA_FIELD.DATE_TIME
                            }
                            value={
                                state[FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN]['to']
                            }
                            handleChange={handleChangeState('to')}
                            fulWidth={true}
                            disabled={
                                active !== FILTER_DATE_RANGE_ACTIVE_INPUT.BETWEEN
                            }
                            isBirthDate={activePopup.key === 'birth_date'}
                            style={{marginLeft: '10px'}}
                        />
                    </Col>
                </div>
            </Row>

            <Row className={'filter-date_range-footer'}>
                <Col>
                    <Button
                        color={'primary'}
                        disabled={!isEnabled}
                        onClick={onSave}
                    >
                        {i18n.t('filter.apply')}
                    </Button>
                </Col>
            </Row>
        </CommonFilter>
    );
};

FilterDateRange.proptypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    popupKey: PropTypes.string.isRequired,
    initialValue: PropTypes.any,
};

FilterDateRange.defaultProps = {
    initialValue: null,
}
