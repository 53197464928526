import React, { useCallback, useState, useMemo } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import i18n from '../../../i18n';

import closeIcon from '../../../assets/images/layout/thin-cross.svg';
import saveIcon from '../../../assets/images/common/Frame 176 (1).svg';

import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

//TODO req for getting access groups?
const EditFilterModal = ({ onHide, currentModalData, onEdit }) => {
    const [data, setData] = useState({ ...currentModalData });

    const handleChange = useCallback(
        key => value => {
            if (typeof value === 'string') {
                setData(prev => ({ ...prev, [key]: value }));
            } else {
                setData(prev => ({ ...prev, [key]: value.target.value }));
            }
        },
        [setData]
    );

    const fields = useMemo(
        () => [
            {
                id: 'edit-modal__filter-name',
                label: `${i18n.t('filter.filterName')}*:`,
                value: data.name,
                handleChange: handleChange('name')
            }
        ],
        [data, handleChange]
    );

    const buttons = useMemo(
        () => [
            {
                color: 'outline-primary modal-btn',
                src: closeIcon,
                label: i18n.t('filter.cancel'),
                onClick: onHide
            },
            {
                color: 'primary modal-btn',
                src: saveIcon,
                label: i18n.t('filter.save'),
                onClick: onEdit(data)
            }
        ],
        [onHide, onEdit, data]
    );

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="filter-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('filter.editFilter')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <div className={'mb-4'}>
                    {fields.map((field, index) => (
                        <ACommonDataField
                            key={index}
                            fulWidth={true}
                            {...field}
                        />
                    ))}
                </div>

                <div className={'modal-btn-wrapper'} onClick={onHide}>
                    {buttons.map(({ label, onClick, src, color }, index) => (
                        <Button key={index} color={color} onClick={onClick}>
                            <img src={src} alt={label} />
                            <span>{label}</span>
                        </Button>
                    ))}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default EditFilterModal;
