import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import i18n from '../../../i18n';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import {
    EMAIL_REG_EXP,
    NAME_REG_EXP,
    PASSWORD_REG_EXP, PHONE_REG_EXP,
    SPANISH_AND_LATIN_REG_EXP, UPPERCASE_REG_EXP,
} from "../../../constants/validations";

import close from '../../../assets/images/common/Frame 179.svg';
import eye from '../../../assets/images/common/eye.svg';
import closedEye from '../../../assets/images/common/closedEye.svg';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';

import './styles.scss';
import CopyButton from "../../../common/cellData/CopyButton";
import Loader from "../../../common/components/Loader";
import {
    digitValidate,
    emailValidate,
    firstAndLastNameValidate,
    firstNameValidate,
    passwordValidate
} from "../../../utils/validation/schemas";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import useAlertService from "../../../hooks/useAlertService";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const GUEST_ROLE_NAME = 'Guest';

function AddAgentModal({
   onCancel,
   onCreate,
   onClose,
   rolesList,
   mode='create',
   isCreating,
}) {
    const alertService = useAlertService();

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        roleId: '',
        phoneExtension: '',
    });

    const [edited, setEdited] = useState({
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        confirmPassword: false,
        phoneExtension: false,
    });

    const [roles, setRoles] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (rolesList?.length) {
            setRoles(rolesList.map((role) => {
                const { name } = role;
                return {
                    label: name,
                    content: name,
                    value: name,
                }
            }));
            const guest = rolesList.find((role) => role.name === GUEST_ROLE_NAME);
            if (guest) {
                handleChange('roleId')(guest.name);
            }
        }
    }, [rolesList]);

    const handleChange = (field) => (value) => {
        if (field === "phoneExtension") {
            value = value.replace(/\D/g, "");
        }
        setState({
            ...state,
            [field]: value,
        })
    };

    const handleBlur = (field) => () => {
        setEdited({
            ...edited,
            [field]: true,
        })
    };

    const handleTogglePassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const visibilityIcon = showPassword ? eye : closedEye
    const visibilityConfirmIcon = showConfirmPassword ? eye : closedEye

    const isFirstNameValid = useMemo(
        () => {
            if (!edited.firstName) {
                return false;
            }
            return firstAndLastNameValidate(state.firstName);
        },
        [state.firstName, edited.firstName]
    );

    const isLastNameValid = useMemo(
        () => {
            if (!edited.lastName) {
                return false;
            }
            return firstAndLastNameValidate(state.lastName);
        },
        [state.lastName, edited.lastName]
    );

    const isEmailValid = useMemo(
        () => {
            if (!edited.email) {
                return false;
            }
            return emailValidate(state.email);
        },
        [state.email, edited.email]
    );

    const isPasswordValid = useMemo(
        () => {
            if (!edited.password) {
                return false;
            }
            return passwordValidate(state.password);
        },
        [state.password, edited.password]
    );

    const isPhoneExtensionValid = useMemo(() => {
        if (!edited.phoneExtension) return false;
        return digitValidate(state.phoneExtension);
    }, [state.phoneExtension])

    const isConfirmPasswordValid = useMemo(
        () => state.confirmPassword !== '' && state.confirmPassword === state.password,
        [state.password, state.confirmPassword]
    );

    const notSpanishOrLatin = (value) => {
        return SPANISH_AND_LATIN_REG_EXP.test(value) && NAME_REG_EXP.test(value)
    }

    const isFieldsValid = useMemo(() => {
        return isFirstNameValid.isValid && isLastNameValid.isValid && isEmailValid.isValid &&
               isPasswordValid.isValid && isConfirmPasswordValid;
    }, [isFirstNameValid, isLastNameValid, isEmailValid, isPasswordValid, isConfirmPasswordValid]);

    const handleCreate = useCallback(() => {
        const { confirmPassword, ...restFields } = state;

        if (UPPERCASE_REG_EXP.test(restFields.email)) {
            alertService.showError(i18n.t('errors.emailUppercase'));
        } else {
            onCreate(restFields);
        }
    }, [state]);

    return (
        <Modal
            className="add-agent-modal"
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {i18n.t('agent.create.agent')}
                    </h5>
                    <button
                        className="close"
                        onClick={onClose}
                    />
                </div>

                <ModalBody
                    className="modal-body"
                >
                    {mode === 'create' && <>
                        <ACommonDataField
                            label={i18n.t('agent.firstName') + ' *'}
                            component={A_COMMON_DATA_FIELD.TEXT}
                            value={state.firstName}
                            handleChange={handleChange('firstName')}
                            handleBlur={handleBlur('firstName')}
                            fulWidth={true}
                            error={edited.firstName && (!isFirstNameValid.isValid ||
                                  (!notSpanishOrLatin(state.firstName)))
                            }
                            helperText={i18n.t(isFirstNameValid?.message)}
                        />

                        <ACommonDataField
                            label={i18n.t('agent.lastName') + ' *'}
                            component={A_COMMON_DATA_FIELD.TEXT}
                            value={state.lastName}
                            handleChange={handleChange('lastName')}
                            handleBlur={handleBlur('lastName')}
                            fulWidth={true}
                            error={edited.lastName && (!isLastNameValid.isValid ||
                                  (!notSpanishOrLatin(state.lastName)))
                            }
                            helperText={i18n.t(isLastNameValid?.message)}
                        />

                        <ACommonDataField
                            label={i18n.t('agent.email') + ' *'}
                            component={A_COMMON_DATA_FIELD.TEXT}
                            value={state.email}
                            handleChange={handleChange('email')}
                            handleBlur={handleBlur('email')}
                            fulWidth={true}
                            error={edited.email && (!isEmailValid.isValid ||
                                  (!EMAIL_REG_EXP.test(state.email)))
                            }
                            helperText={i18n.t(isEmailValid?.message)}
                        />

                        <div>
                            <ACommonDataField
                                label={i18n.t('agent.password') + ' *'}
                                component={A_COMMON_DATA_FIELD.PASSWORD}
                                value={state.password}
                                handleChange={handleChange('password')}
                                handleBlur={handleBlur('password')}
                                fulWidth={true}
                                type={showPassword ? "text" : "password"}
                                error={edited.password && (!isPasswordValid ||
                                      (!PASSWORD_REG_EXP.test(state.password)))
                                }
                                helperText={i18n.t(isPasswordValid?.message)}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleTogglePassword}>
                                                <img src={visibilityIcon} alt='eye-icon'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <ACommonDataField
                            label={i18n.t('agent.confirmPassword') + ' *'}
                            component={A_COMMON_DATA_FIELD.PASSWORD}
                            value={state.confirmPassword}
                            handleChange={handleChange('confirmPassword')}
                            handleBlur={handleBlur('confirmPassword')}
                            fulWidth={true}
                            type={showConfirmPassword ? "text" : "password"}
                            error={edited.confirmPassword && !isConfirmPasswordValid}
                            helperText={!isConfirmPasswordValid && edited.confirmPassword
                                        && i18n.t('errors.invalidConfPass')}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleConfirmPassword}>
                                            <img src={visibilityConfirmIcon} alt='eye-icon'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <ACommonDataField
                            label={i18n.t('agent.roleId')}
                            component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                            value={state.roleId}
                            handleChange={handleChange('roleId')}
                            fulWidth={true}
                            options={roles}
                            hasClear={false}
                        />

                        <ACommonDataField
                            label={i18n.t('agent.phoneExtension')}
                            component={A_COMMON_DATA_FIELD.TEXT}
                            value={state.phoneExtension}
                            handleChange={handleChange('phoneExtension')}
                            handleBlur={handleBlur('phoneExtension')}
                            fulWidth={true}
                        />

                        <div
                            className="add-agent-modal__actions"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                className="btn btn-rounded btn-light"
                                onClick={onCancel}
                            >
                                <img
                                    src={close}
                                    alt=""
                                />
                                {i18n.t('common.cancel')}
                            </button>

                            <button
                                className="btn btn-rounded btn-primary"
                                onClick={handleCreate}
                                disabled={!isFieldsValid
                                          || !notSpanishOrLatin(state.firstName)
                                          || !notSpanishOrLatin(state.lastName)
                                }
                            >
                                <img
                                    src={i_51}
                                    alt=""
                                />
                                {i18n.t('common.create')}
                            </button>

                            {isCreating && <Loader size={'sm'}/>}
                        </div>
                    </>}
                    {mode === 'result' && <>
                        <div>
                            {i18n.t('agent.successful.created')}
                            <br/>
                            {i18n.t('agent.email')}:
                            &nbsp;&nbsp;
                            {state.email}
                            &nbsp;&nbsp;
                            <CopyButton
                                className="column-cpy-button"
                                onClick={() =>
                                    navigator.clipboard.writeText(state.email)
                                }
                            />
                            <br/>
                            {i18n.t('agent.password')}:
                            &nbsp;&nbsp;
                            {state.password}
                            &nbsp;&nbsp;
                            <CopyButton
                                className="column-cpy-button"
                                onClick={() =>
                                    navigator.clipboard.writeText(state.password)
                                }
                            />
                        </div>

                        <div className="add-agent-modal__actions">
                            <button
                                className="btn btn-rounded btn-light"
                                onClick={onClose}
                            >
                                <img
                                    src={close}
                                    alt=""
                                />
                                {i18n.t('common.close')}
                            </button>
                        </div>
                    </>}
                </ModalBody>
            </div>
        </Modal>
    );
}

AddAgentModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    rolesList: PropTypes.array,
};

AddAgentModal.defaultProps = {
    rolesList: [],
}

export default React.memo(AddAgentModal);
