import React from "react";
import { useTranslation } from "react-i18next";
import close from "../../../../../assets/images/common/close 1.svg";
import i_51 from "../../../../../assets/images/common/Frame 176 (1).svg";


const FraudButtonsPanel = ({onCancel, onSave}) => {
  const { t } = useTranslation();

  return (
    <div className='fraud-buttons'>
      <button
        className="btn btn-rounded btn-light me-2"
        onClick={onCancel}
      >
        <img className="me-2" src={close} alt="" />
        {t('admin.cancel')}
      </button>

      <button
        className="btn btn-rounded btn-primary"
        onClick={onSave}
      >
        <img src={i_51} alt="" />
        {t('admin.save')}
      </button>
    </div>
  )

}


export default FraudButtonsPanel;