import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import './FraudRestrictions.scss';
import Loader from "../../../../common/components/Loader";
import i18n from "../../../../i18n";
import CheckboxInput from "../../../../common/inputs/CheckboxInput";
import group from "../../../../assets/images/common/Tooltip.svg";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
    getAntiFraudRules,
    updateAntiFraudRules
} from "../../../../store/brand/brandPage/actions";
import { prepareAntiFraudDto } from "./helpers/prepareAntiFraudDto";
import useAlertService from "../../../../hooks/useAlertService";
import { LATIN_WITHOUT_SPACES_REG_EXP } from "../../../../constants/validations";
import FraudButtonsPanel from "./components/FraudButtonsPanel";
import ActionSettingsModal from "./components/ActionSettingsModal";

const checkboxActions = ["block_autowithdrawal", "block_promotions"];
const buttonActions = ["block_specific_promotions", "disable_payment_methods"];

const FraudRestrictions = (props) => {
    const {
        canEditFraudSection,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const {
        antiFraudRules,
        antiFraudActions,
        isFraudRulesLoading,
    } = useSelector(state => state.BrandSettingsPage);

    const thirdPartyServiceLabel = antiFraudRules[0]?.providerType;

    const [isShowSettingsModal, setIsShowSettingsModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [initialAntiFraudRules, setInitialAntiFraudRules] = useState([]);
    const [currentAntiFraudRules, setCurrentAntiFraudRules] = useState([]);
    const [antiFraudActions_, setAntiFraudActions_] = useState([]);
    const [newRule, setNewRule] = useState({
        externalRuleId: "",
        actions: [],
        promotionIds: [],
        paymentMethodIds: []
    });

    const [specificPromosIds, setSpecificPromosIds] = useState('');
    const [currentRuleIndex, setCurrentRuleIndex] = useState(null);
    const [currentSelectedMethods, setCurrentSelectedMethods] = useState([]);


    useEffect(() => {
        dispatch(getAntiFraudRules());
    }, []);


    const toggleSettingsModal = (action, index) => {
        setModalTitle(action);
        setCurrentRuleIndex(index);
        setIsShowSettingsModal(prev => !prev);

        if (index !== undefined && currentAntiFraudRules[index]) {
            const selectedRule = currentAntiFraudRules[index];

            const promoIds = selectedRule?.promotionIds.join(',') || '';
            let methodsIds = [...(selectedRule?.paymentMethodIds || [])];

            setSpecificPromosIds(promoIds);
            setCurrentSelectedMethods(methodsIds);
        }
    };


    useEffect(() => {
        if (antiFraudRules && antiFraudRules.length > 0) {
            const initialRules = antiFraudRules[0]?.antiFraudRules || [];

            const formattedRules = initialRules.map(rule => ({
                ...rule,
                actions: rule.actions.map(action => action.action),
                promotionIds: rule.actions.find(actionObj => actionObj.action === "block_specific_promotions")?.settings?.promotionIds || [],
                paymentMethodIds: rule.actions.find(actionObj => actionObj.action === "disable_payment_methods")?.settings?.paymentMethodIds || [],
            }));

            setInitialAntiFraudRules(formattedRules);
            setCurrentAntiFraudRules(formattedRules);
            setAntiFraudActions_(antiFraudActions);
        }
    }, [antiFraudRules, antiFraudActions]);

    const handleChangeId = useCallback((index, e) => {
        const newValue = e.target.value;
        setCurrentAntiFraudRules((prevState) => {
            return prevState.map((rule, index_) =>
              index_ === index
                ? { ...rule, externalRuleId: newValue }
                : rule
            );
        });
    }, [currentAntiFraudRules]);

    const handleCheckboxChange = useCallback((index, action) => {
        setCurrentAntiFraudRules(prevState => {
            return prevState.map((rule, index_) => {
                if (index_ === index) {
                    const actions = rule.actions.includes(action)
                      ? rule.actions.filter(a => a !== action)
                      : [...rule.actions, action];

                    return {
                        ...rule,
                        actions
                    };
                }
                return rule;
            });
        });
    }, [currentAntiFraudRules]);

    const isEdit = useMemo(() => {
        return !isEqual(initialAntiFraudRules, currentAntiFraudRules);
    }, [initialAntiFraudRules, currentAntiFraudRules]);

    const onCancel = () => {
        setCurrentAntiFraudRules(initialAntiFraudRules);
    };

    const findDuplicateId = (rules) => {
        const ruleIds = new Set();

        for (const rule of rules) {
            if (ruleIds.has(rule.externalRuleId)) {
                return rule.externalRuleId;
            }

            ruleIds.add(rule.externalRuleId);
        }

        return null;
    };

    const processRule = (rule) => {
        if (rule.promotionIds.length > 0 && !rule.actions.includes('block_specific_promotions')) {
            rule.actions.push('block_specific_promotions');
        }

        return {
            ...rule,
            promotionIds: Array.isArray(rule.promotionIds)
              ? rule.promotionIds
              : rule.promotionIds.includes(',')
                ? rule.promotionIds.split(',').map(id => id.trim())
                : [rule.promotionIds.trim()],
        };
    };

    const onSave = () => {
        const updatedRules = currentAntiFraudRules.map((rule, index) => {
            let processedRule = processRule(rule);

            if (index === currentRuleIndex) {
                if (currentSelectedMethods.length > 0 && !processedRule.actions.includes('disable_payment_methods')) {
                    processedRule.actions.push('disable_payment_methods');
                }

                return {
                    ...processedRule,
                    paymentMethodIds: currentSelectedMethods,
                };
            }

            return processedRule;
        });

        const duplicateId = findDuplicateId(updatedRules);
        const hasEmptyId = updatedRules.some(rule => !rule.externalRuleId.trim());
        const invalidRules = updatedRules.filter(rule => !validateInputRule(rule.externalRuleId));

        if (duplicateId || hasEmptyId || invalidRules.length > 0) {
            if (duplicateId) {
                alertService.showError(t('admin.duplicateId'));
            } else if (hasEmptyId) {
                alertService.showError(t('admin.emptyRuleId'));
            } else {
                alertService.showError(t('admin.invalidRuleId'));
            }
        } else {
            const preparedDto = prepareAntiFraudDto(updatedRules);
            dispatch(updateAntiFraudRules([preparedDto]));
        }
    };

    const handleAddRule = () => {
        setCurrentAntiFraudRules((prevState) => {
            const updatedRules = [...prevState, { ...newRule }];
            return updatedRules;
        });
    };

    const handleDeleteRule = (index) => {
        setCurrentAntiFraudRules((prevState) => {
            const updatedRules = [...prevState];
            updatedRules.splice(index, 1);
            return updatedRules;
        });
    };

    const validateInputRule = (value) => {
        return LATIN_WITHOUT_SPACES_REG_EXP.test(value);
    };

    const allRules = [...currentAntiFraudRules];

    if (newRule.externalRuleId) {
        allRules.push(newRule);
    }

    const onChangeSpecificPromo = (value) => {
        setSpecificPromosIds(value);
        setCurrentAntiFraudRules(prevState => {
            return prevState.map((rule, index_) => {
                if (index_ === currentRuleIndex) {
                    return {
                        ...rule,
                        promotionIds: value.split(',').map(id => id.trim())
                    };
                }
                return rule;
            });
        });
    };

    const handlePaymentMethodsChange = (selectedMethods) => {
        setCurrentSelectedMethods(selectedMethods);
        setCurrentAntiFraudRules(prevState => {
            return prevState.map((rule, index_) => {
                if (index_ === currentRuleIndex) {
                    return {
                        ...rule,
                        paymentMethodIds: selectedMethods
                    };
                }
                return rule;
            });
        });
    };


    return (
      <Card className="fraud-restrictions site-main-card">
          <CardBody>
              {!isFraudRulesLoading ?
                <Row>
                    <Col
                      xs={12}
                      className="sub-content__header d-flex align-items-center"
                    >
                        <div className='d-flex w-100 align-items-center'>
                            <img className="me-3" src={group} alt="" />
                            <span className="font-w-500 me-3">
                                    {i18n.t('admin.fraudRestrictions')}
                                </span>
                        </div>
                        <span className='ml-10 label-group'>
                                {t('admin.thirdPartyService')}:
                                <span className='service-label'>
                                    {thirdPartyServiceLabel}
                                </span>
                            </span>
                    </Col>
                    <Col xs={12} sm={12}>
                        {!allRules?.length &&
                          <span className='no-rules-text'>
                                    {t('admin.noRules')}
                                </span>
                        }
                        {allRules?.map((rule, index) => (
                          <div key={index} className='d-flex fraud-restriction'>
                              <div className='d-flex align-items-center'>
                                        <span className='fraud-name-id'>
                                            {t('admin.externalRuleId')}
                                        </span>
                                  <input
                                    value={rule.externalRuleId || ''}
                                    className={'form-control fraud-input'}
                                    disabled={!canEditFraudSection}
                                    onChange={(e) => handleChangeId(index, e)}
                                  />
                              </div>
                              <div className='fraud-toggles'>
                                  {checkboxActions.map((action) => (
                                    <CheckboxInput
                                      key={action}
                                      isDisabled={!canEditFraudSection}
                                      className='fraud-checkbox ml-10'
                                      checked={rule.actions.includes(action)}
                                      onChange={() => handleCheckboxChange(index, action)}
                                    >
                                        {t(`admin.${action}`)}
                                    </CheckboxInput>
                                  ))}
                                  {buttonActions.map((action) => (
                                    <button
                                      key={action}
                                      className="btn btn-rounded btn-primary me-2 ml-10"
                                      disabled={!canEditFraudSection}
                                      onClick={() => toggleSettingsModal(action, index)}
                                    >
                                        {t(`admin.${action}`)}
                                    </button>
                                  ))}
                                  <button
                                    className="btn deleteRuleBtn"
                                    onClick={() => handleDeleteRule(index)}
                                    disabled={!canEditFraudSection}
                                  />
                              </div>
                          </div>
                        ))}
                    </Col>
                    <Col xs={12}>
                        <div className='newRulesWrapper'>
                            <button
                              className="btn btn-primary btn-rounded add-rule-btn"
                              onClick={handleAddRule}
                              disabled={!canEditFraudSection}
                            >
                                {t('admin.addNewRule')}
                            </button>
                        </div>
                    </Col>
                    {isEdit &&
                      <FraudButtonsPanel
                        onCancel={onCancel}
                        onSave={onSave}
                      />
                    }
                </Row>
                : <Loader />
              }
          </CardBody>

          {isShowSettingsModal && (
            <ActionSettingsModal
              modalTitle={modalTitle}
              onToggle={() => setIsShowSettingsModal(false)}
              specificPromosIds={specificPromosIds}
              setSpecificPromosIds={onChangeSpecificPromo}
              disabledPaymentMethods={currentSelectedMethods || []}
              setDisabledPaymentMethods={handlePaymentMethodsChange}
            />
          )}
      </Card>
    );
}


export default React.memo(FraudRestrictions);