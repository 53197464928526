import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import ClipboardButton from '../../../common/cellData/CopyButton';
import { getGuidShortFormat } from '../../../common/utils/common';

import frame_263 from '../../../assets/images/common/Frame 263.svg';
import frame_264 from '../../../assets/images/common/Frame 264.svg';
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
    downloadGamePlayList,
    getPlayerBetSlip,
    getPlayerGameSessions,
    getPlayerGameRounds,
    getPlayerGameSessionRounds,
} from "../../../store/players/gamePlay/actions";
import download from "../../../assets/images/common/download.svg";
import { useTranslation } from "react-i18next";
import Loader from "../../../common/components/Loader";
import { clearCurrentFilter } from "../../../store/filters/action";
import useList from "../../../hooks/useList";
import SelectTableView from "../../Content/components/SelectTableView";
import { useHistory, useLocation } from "react-router-dom";
import useHashNavigation from "../../../hooks/useHashNavigation";
import queryString from "query-string";
import { SPORT_TAB_TO_HIDE } from "./common/playerViewConstants";
import SessionsTable from "./GamePlayTables/SessionsTable";
import RoundsTable from "./GamePlayTables/RoundsTable";
import BetSlipTable from "./GamePlayTables/BetSlipTable";
import settings from "../../../assets/images/layout/settings.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import Formatter from "../../../utils/Formatter";


export const TABS = {
    GAME_SESSION: 'GAME_SESSION',
    GAME_ROUND: 'GAME_ROUND',
    SPORT_BETS: 'SPORT_BETS',
};

const gamePlayState = {
    GAME_SESSION: 'sessions',
    GAME_ROUND: 'rounds',
    SPORT_BETS: 'betSlip',
};

const maxRecordsForDownload = 20000;

export const useDynamicColumns = ({list}) => {
    const [dynamicColumns, setDynamicColumns] = useState([]);
    useEffect(() => {
        const dynamicColumnsArray = new Set();
        list?.forEach(item => {
            if (item.totalJackpotWin > 0) dynamicColumnsArray.add('totalJackpotWin');
        })
        setDynamicColumns(Array.from(dynamicColumnsArray));
    }, [list])

    return dynamicColumns
}

export function getBalance(item, balanceType) {
    let balance;

    if (balanceType === 'open') {
        balance = item?.openRealBalance ?? item?.openBalance ?? 0;
    } else {
        balance = item?.closeRealBalance ?? item?.closeBalance ?? 0;
    }

    return `${balance} ${Formatter.getCurrencySign(item.currency)}`
}

export const ClipboardButtonCell = ({ text }) => (
    <td>
        <div className="d-flex">
            <span className="me-2">{getGuidShortFormat(text)}</span>
            <ClipboardButton text={text} />
        </div>
    </td>
);


function GamePlayTabHeader({ activeTab, onChange }) {
    const { i18n } = useTranslation();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const brand = activeBrand?.siteName;

    return (
        <ul className="d-flex sub-content__tab game-play-list">
            {Object.keys(TABS).map(key => {
                if (key === TABS.SPORT_BETS && !SPORT_TAB_TO_HIDE.includes(brand)) {
                    return null;
                }
                return (
                    <li
                        key={key}
                        className={activeTab === key ? 'active' : ''}
                        onClick={() => onChange(key)}
                    >
                        <img
                            className="me-2"
                            src={activeTab === key ? frame_263 : frame_264}
                            alt=""
                        />
                        {i18n.t(`crm.${key}`)}
                    </li>
                );
            })}
        </ul>
    );
}

function GamePlayTabContent(props) {
    const { playerId, playerModel } = props;
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { currentFilter } = useSelector(state => state.Filter);
    const history = useHistory();
    const location = useLocation();
    const { changeHash, removeSubTabAndSessionId } = useHashNavigation();
    const {
        gamePlay,
        isDownloading,
        isLoadingGamePlay,
        queryList,
    } = useSelector(state => state.PlayerGamePlay);

    const [activeTab, setActiveTab] = useState(TABS.GAME_SESSION);
    const [sessions, setSessions] = useState([]);
    const [roundsOffset, setRoundsOffset] = useState(0);
    const [rounds, setRounds] = useState([]);
    const [betSlip, setBetSlip] = useState([]);
    const [isRoundsMode, setRoundsMode] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [_queryList, _setQueryList] = useState(queryList);
    const totalGameRounds = gamePlay?.rounds?.total;
    const [isShowTableSettings, setIsShowTableSettings] = useState(false);

    const toggleTableSettings = () => setIsShowTableSettings(prev => !prev);

    const {
        pageCount,
        activePage,
        handleChangePage,
    } = useList(
        query => {
            isRoundsMode
                ? dispatch(getPlayerGameSessionRounds({
                    id: playerId,
                    sessionId: sessionId,
                    query: query
                }))
                : activeTab === TABS.GAME_SESSION
                    ? dispatch(getPlayerGameSessions({ id: playerId, query }))
                    : activeTab === TABS.GAME_ROUND
                        ? dispatch(getPlayerGameRounds({ id: playerId, query }))
                        : dispatch(getPlayerBetSlip({ id: playerId, query }));
        },
        gamePlay[gamePlayState[activeTab]]?.total,
        _queryList,
        currentFilter,
        [],
        false
    );

    const loadData = useCallback((query) => {
        if (activeTab === TABS.GAME_SESSION) {
            dispatch(getPlayerGameSessions({
                id: playerId,
                query
            }));
        }
        else if (activeTab === TABS.GAME_ROUND) {
            dispatch(getPlayerGameRounds({
                id: playerId,
                query
            }));
        }
        else {
            dispatch(getPlayerBetSlip({
                id: playerId,
                query
            }));
        }
    }, [activeTab, location.search]);

    useEffect(() => {
        setSessionId(parseUrlSearch().sessionId);
    }, [location.search])

    const loadSessionRounds = (query) => {
        if (sessionId) {
            dispatch(getPlayerGameSessionRounds({
                id: playerId,
                sessionId: sessionId,
                query: query
            }));
        }
    }

    useEffect(() => {
        handleChangePage(1);
    }, [currentFilter, isRoundsMode, activeTab, _queryList.limit])

    useEffect(() => {
        if (isRoundsMode) {
            setRounds(gamePlay?.rounds?.result);
        }
        else {
            if (activeTab === TABS.GAME_SESSION) {
                setSessions(gamePlay?.sessions?.result);
            }
            else if (activeTab === TABS.GAME_ROUND) {
                setRounds(gamePlay?.rounds?.result);
            }
            else {
                setBetSlip(gamePlay?.betSlip?.result);
            }
        }
    }, [gamePlay, isRoundsMode, activeTab]);

    useEffect(() => {
        if (isRoundsMode && activeTab !== TABS.GAME_ROUND) {
            setRoundsMode(false);
        }
    }, [activeTab]);

    useEffect(() => {
        if (isRoundsMode) {
            setRoundsOffset(0);
        }
    }, [isRoundsMode]);

    useEffect(() => {
        if (!isRoundsMode) {
            loadData(_queryList);
        }
    }, [isRoundsMode]);

    useEffect(() => {
        if (isRoundsMode && parseUrlSearch().sessionId) {
            changeHash(TABS.GAME_ROUND)
            if (sessionId !== null) {
                loadSessionRounds(_queryList);
            }

        }
    }, [roundsOffset, isRoundsMode]);

    const handleSessionClick = (sessionId) => {
        setSessionId(sessionId);
    }

    const handleAllRoundsClick = () => {
        setRoundsMode(false);
        const urlSearchParams = new URLSearchParams(history.location.search);

        urlSearchParams.delete('sessionId');
        urlSearchParams.delete('subTab');
        urlSearchParams.set('subTab', 'GAME_ROUNDS');

        history.replace({
            search: urlSearchParams.toString(),
        });
        loadData();
    }

    const { agentSelf } = useSelector(
        state => state.AgentSelf
    );

    const parseUrlSearch = () => {
        return queryString.parse(location.search)
    }

    const changeActiveTab = (tabName) => {
        dispatch(clearCurrentFilter());
        removeSubTabAndSessionId();
        changeHash(tabName);
        setActiveTab(tabName);
    }

    useEffect(() => {
        if (parseUrlSearch().tab === 'GAME_PLAY' && !parseUrlSearch().subTab) {
            changeHash(TABS.GAME_SESSION);
        }

        if (parseUrlSearch().sessionId) {
            setRoundsMode(true);
            setActiveTab(TABS.GAME_ROUND);
            loadSessionRounds(_queryList);
        }
    }, [location.search, isRoundsMode])

    const onChangeRecords = (value) => {
        _setQueryList({
            limit: value,
            offset: _queryList.offset
        })
    }

    useEffect(() => {
        const queryFromUrl = parseUrlSearch().subTab;

        if ((queryFromUrl) && activeTab !== queryFromUrl && !sessionId) {
            setActiveTab(queryFromUrl)
        }

        if (activeTab === TABS.GAME_ROUND && _queryList.limit !== 100) {
            onChangeRecords(100);
        }
    }, [activeTab, location.search, _queryList.limit])

    const isListEmpty = (activeTab, sessions, rounds, betSlip) => {
        switch(activeTab) {
            case TABS.GAME_SESSION:
                return !sessions?.length;
            case TABS.GAME_ROUND:
                return !rounds?.length;
            case TABS.SPORT_BETS:
                return !betSlip?.length;
            default:
                return true;
        }
    }

    const isBlockedDownloading = useMemo(() => {
        return activeTab === TABS.GAME_ROUND && totalGameRounds >= maxRecordsForDownload
    }, [activeTab, totalGameRounds, maxRecordsForDownload]);

    const handleDownload = useCallback(() => {
        if (activeTab === TABS.GAME_ROUND && isBlockedDownloading) {
            return
        }

        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        const requestQuery = { id: playerId, type: activeTab, timezoneOffsetInMinutes };
        dispatch(downloadGamePlayList(requestQuery))
    },[activeTab, isBlockedDownloading]);



    return (
        <div className="detailed-card__content game-play">
            <div className="tab-wrapper">
                <GamePlayTabHeader activeTab={activeTab} onChange={changeActiveTab} />

                <div className={clsx('d-flex justify-content-end mt-2 game-play-download', {
                    ['d-none']: isListEmpty(activeTab, sessions, rounds, betSlip)
                })}>
                    {isRoundsMode &&
                        <button
                          className="btn btn-rounded btn-primary all-rounds-button"
                          onClick={handleAllRoundsClick}
                        >
                        {i18n.t(`crm.allRounds`)}
                    </button>
                    }
                    <Fragment>
                        <div className='game-play-download__btn'>
                            <button
                                className="btn settings-button settings-deposit"
                                onClick={toggleTableSettings}
                            >
                                <img src={settings} alt="Table's Settings"/>
                            </button>
                            <button
                                className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm', {
                                    'btn-disabled': isBlockedDownloading
                                })}
                                onClick={handleDownload}
                                disabled={isDownloading}
                            >
                                <img src={download} alt=""/>
                            </button>
                            {
                                isBlockedDownloading && (
                                    <div className="game-play-download__tooltip">
                                        {i18n.t('crm.loadingProhibitedGameRounds')}
                                    </div>
                                )
                            }
                        </div>
                    </Fragment>
                </div>
            </div>

            {isLoadingGamePlay
                ? <Loader size={'lg'}/>
                : <div>
                    {activeTab === TABS.GAME_SESSION && (
                        <SessionsTable
                            agentSelf={agentSelf}
                            list={sessions}
                            onSessionClick={handleSessionClick}
                            isShowTableSettings={isShowTableSettings}
                            toggleTableSettings={toggleTableSettings}
                            isLoadingGamePlay={isLoadingGamePlay}
                        />
                    )}

                    {activeTab === TABS.GAME_ROUND && (
                        <RoundsTable
                            agentSelf={agentSelf}
                            list={rounds}
                            playerId={playerId}
                            isShowTableSettings={isShowTableSettings}
                            toggleTableSettings={toggleTableSettings}
                            isLoadingGamePlay={isLoadingGamePlay}
                        />
                    )}

                    {activeTab === TABS.SPORT_BETS && (
                        <BetSlipTable
                            list={betSlip}
                            agentSelf={agentSelf}
                            isShowTableSettings={isShowTableSettings}
                            toggleTableSettings={toggleTableSettings}
                            isLoadingGamePlay={isLoadingGamePlay}
                        />
                    )}
                </div>
            }
            <div className='mt-5'>
                <SelectTableView
                    pageCount={pageCount}
                    activePage={activePage}
                    recordsCount={gamePlay[gamePlayState[activeTab]]?.total}
                    onChangePage={handleChangePage}
                    pageSize={_queryList.limit}
                    onChange={onChangeRecords}
                />
            </div>
        </div>
    );
}

export default React.memo(
    GamePlayTabContent
);
