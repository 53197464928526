import React from 'react';
import { Card } from 'reactstrap';
import StatisticsController, {
    STATISTICS_CONTROLLER_TYPE
} from './common/StatisticsController';
import StatisticsFilter from './common/StatisticsFilter';
import ConversionInfoContainer from './common/ConversionInfoContainer';
import RetentionInfoContainer from './common/RetentionInfoContainer';
import PropTypes from 'prop-types';

const ManagerStatisticsMainCard = ({
    statistics,
    activeType,
    setActiveType,
    filter,
    setFilter,
  }) => {

  return (
    <Card>
      <StatisticsController
        onClickRetention={() =>
            setActiveType(STATISTICS_CONTROLLER_TYPE.RETENTION)
        }
        onClickConversion={() =>
            setActiveType(STATISTICS_CONTROLLER_TYPE.CONVERSION)
        }
        activeType={activeType}
      />

      <StatisticsFilter filter={filter} onChange={setFilter} />

      <hr className="info-separator" />

      {activeType === STATISTICS_CONTROLLER_TYPE.CONVERSION && (
        <ConversionInfoContainer statistics={statistics} />
      )}

      {activeType === STATISTICS_CONTROLLER_TYPE.RETENTION && (
        <RetentionInfoContainer statistics={statistics} />
      )}
    </Card>
  );
};

ManagerStatisticsMainCard.propTypes = {
  statistics: PropTypes.object.isRequired,
  activeType: PropTypes.string.isRequired,
  setActiveType: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default React.memo(ManagerStatisticsMainCard);
