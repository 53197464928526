import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import i18n from '../../../i18n';
import { CommonFilter } from './CommonFilterPopup';
import useHandleEnter from '../../../common/hooks/useHandleEnter';
import ACommonDataField from '../../GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { useLocation } from "react-router-dom";
import {
    LATIN_WITHOUT_SPACES_REG_EXP,
    NUMBERS_REG_EXP, SHORT_CARD_NUMBER,
} from "../../../constants/validations";
import {useTranslation} from "react-i18next";

export const TextPopup = ({ popupKey, title, onSave, label, onCancel, initialValue }) => {
    const location = useLocation();
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(false);
    const { t } = useTranslation();

    const handleSave = useCallback(() => {
        const isValid = validateInput();
        setError(!isValid);
        if (isValid) {
            onSave([{ key: popupKey, value: value.trim() }]);
        }
    }, [onSave, value, popupKey]);
    useHandleEnter(handleSave);

    const handleChange = (value) => {
        switch (popupKey) {
            case 'card':
                const updatedValue = value.replace(/[^0-9]/g, '').slice(0, 4);
                setValue(updatedValue);
                break;
            default:
                setValue(value);
                break;
        }
    }

    const toValidate = [
        'affiliate_id',
        'affiliate_sub_id',
        'email',
        'nick_name',
        'player_id',
        'reference',
        'phone',
        'card',
    ]

    const validateInput = useCallback(() => {
        if (toValidate.includes(popupKey)) {
            switch (popupKey) {
                case 'phone':
                    return NUMBERS_REG_EXP.test(value)
                case 'card':
                    return SHORT_CARD_NUMBER.test(value)
                default:
                    return LATIN_WITHOUT_SPACES_REG_EXP.test(value)
            }
        }

        return true;
    }, [popupKey, value]);

    const isDepositPath = location.pathname === '/deposits'
        || location.pathname === '/deposits-declines'
        || location.pathname === '/deposits-action'
        || location.pathname.startsWith('/player-list/');

    let displayLabel;

    switch (popupKey) {
        case 'player_id':
            displayLabel = t('filter.provideId');
            break;
        case 'card':
            displayLabel = isDepositPath ? t('filter.cardNumbers') : label;
            break;
        case 'affiliate_sub_id':
        case 'name':
        case 'nick_name':
        case 'reference':
        case 'tab_promotion_id':
        case 'promotion_player_id':
        case 'affiliate_id':
        case 'game_name_category':
            displayLabel = t('filter.contains');
            break;
        case 'DefaultBet':
        case 'Jackpot':
        case 'JackpotContribution':
        case 'bet_id':
        case 'game_name':
            displayLabel = t('filter.EQUALS');
            break;
        default:
            displayLabel = label;
            break;
    }

    return (
        <CommonFilter
            title={title}
            className="filter-simple-text"
            onCancel={onCancel}
        >
            <ACommonDataField
                label={displayLabel}
                component={A_COMMON_DATA_FIELD.TEXT}
                value={value}
                handleChange={handleChange}
                fulWidth={true}
                error={error}
                helperText={error && t('crm.incorrectFormat')}
            />

            <Button color="primary" disabled={!value.trim()} onClick={handleSave}>
                {i18n.t('filter.apply')}
            </Button>
        </CommonFilter>
    );
};

TextPopup.proptypes = {
    popupKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    label: PropTypes.string,
    onCancel: PropTypes.func.isRequired
};

TextPopup.defaultProps = {
    initialValue: '',
};
