import { takeEvery, put, call } from 'redux-saga/effects';

import {
    DOWNLOAD_AGENT_SELF,
    EDIT_AGENT_PASSWORD,
    GET_AGENT_SELF,
    UPDATE_AGENT_SELF,
    UPDATE_AGENT_SELF_STATUS
} from './actionTypes';
import {
    updateAgentSelfStatusSuccess,
    updateAgentSelfStatusFail,
    getAgentSelfSuccess,
    getAgentSelfFail,
    updateAgentSelfSuccess,
    updateAgentSelfFail,
    editAgentPasswordSuccess,
    editAgentPasswordFail,
    downloadAgentSelfSuccess,
    downloadAgentSelfFail,
} from './actions';
import {
    getAgentSelfApi,
    updateAgentSelfStatusApi,
    updateAgentApi,
    editAgentPasswordApi, downloadAgentSelfInfoApi
} from '../../../helpers/agentApi';


function* fetchAgentSelfSaga({ payload }) {
    try {
        const response = yield call(getAgentSelfApi, payload);

        yield put(getAgentSelfSuccess(response.data));
    } catch (error) {
        yield put(getAgentSelfFail(error));
    }
}

function* updateAgentSaga({ payload }) {
    try {
        const response = yield call(updateAgentApi, payload);

        yield put(
            updateAgentSelfSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updateAgentSelfFail(error));
    }
}

function* editAgentPasswordSaga({ payload }) {
    try {
        yield call(editAgentPasswordApi, payload);

        yield put(editAgentPasswordSuccess(payload));
    } catch (error) {
        yield put(editAgentPasswordFail(error));
    }
}

function* updateAgentSelfStatusSaga({ payload }) {
    try {
        yield call(updateAgentSelfStatusApi, payload);

        yield put(updateAgentSelfStatusSuccess(payload));
    } catch (error) {
        yield put(updateAgentSelfStatusFail(error));
    }
}

function* downloadAgentSelfSaga({ payload }) {
    try {
        yield call(downloadAgentSelfInfoApi, payload);

        yield put(downloadAgentSelfSuccess());
    } catch (error) {
        yield put(downloadAgentSelfFail(error));
    }
}

function* agentSelfSaga() {
    yield takeEvery(GET_AGENT_SELF, fetchAgentSelfSaga);
    yield takeEvery(UPDATE_AGENT_SELF_STATUS, updateAgentSelfStatusSaga);
    yield takeEvery(UPDATE_AGENT_SELF, updateAgentSaga);
    yield takeEvery(EDIT_AGENT_PASSWORD, editAgentPasswordSaga);
    yield takeEvery(DOWNLOAD_AGENT_SELF, downloadAgentSelfSaga);
}

export default agentSelfSaga;
