import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_AGENT_EMPLOYEE, UPDATE_AGENT_EMPLOYEE } from './actionTypes';
import {
    getAgentEmployeeSuccess,
    getAgentEmployeeFail,
    updateAgentEmployeeSuccess,
    updateAgentEmployeeFail
} from './actions';
import { getAgentApi, updateAgentApi } from '../../../helpers/agentApi';

function* fetchAgentEmployeeSaga({ payload }) {
    try {
        const response = yield call(getAgentApi, payload);

        yield put(getAgentEmployeeSuccess(response.data));
    } catch (error) {
        yield put(getAgentEmployeeFail(error));
    }
}

function* updateAgentEmployeeSaga({ payload }) {
    try {
        const response = yield call(updateAgentApi, payload);

        yield put(
            updateAgentEmployeeSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updateAgentEmployeeFail(error));
    }
}

function* agentEmployeeSaga() {
    yield takeEvery(GET_AGENT_EMPLOYEE, fetchAgentEmployeeSaga);
    yield takeEvery(UPDATE_AGENT_EMPLOYEE, updateAgentEmployeeSaga);
}

export default agentEmployeeSaga;
