import {createContext, useEffect, useState} from 'react';
import { PLAYER_VIEW_DETAILED_TAB_TYPE } from '../common/playerViewConstants';
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import useHashNavigation from "../../../../hooks/useHashNavigation";

export const PlayerTabContext = createContext(null);

export const PlayerTabContextProvider = ({ children }) => {
    const [tabState, setTabState] = useState({
        activeTab: PLAYER_VIEW_DETAILED_TAB_TYPE.GENERAL,
        toSetActiveTab: ''
    });

    return (
        <PlayerTabContext.Provider value={{ tabState, setTabState }}>
            {children}
        </PlayerTabContext.Provider>
    );
};
