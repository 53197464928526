import { useTranslation } from "react-i18next";
import {useDispatch } from "react-redux";
import useProviders from "../../../../hooks/useProviders";
import { useTimeContext } from "../../../../context/TimeContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import useUserConfig from "../../../../hooks/useUserConfig";
import React, {memo, useEffect, useMemo, useState} from "react";
import {getGameProviders} from "../../../../store/games/actions";
import TableFilter from "../../../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_GAME_SESSION } from "../../../../constants/Filter";
import NewCustomTable from "../../../../components/NewCustomTable";
import clsx from "clsx";
import {
    getColumnSettingsLsKey,
    getGuidShortFormat,
    initTableColumns,
} from "../../../../common/utils/common";
import ClipboardButton from "../../../../common/cellData/CopyButton";
import {SCREEN_SIZES} from "../../../../utils/consts";
import Formatter from "../../../../utils/Formatter";
import { getBalance } from "../GamePlayTabContent";
import { useLocation } from "react-router-dom";
import i18n from "../../../../i18n";
import TableSettingsModal from "../../modal/TableSettingsModal";
import {getLocalStorageFilter} from "../../../../store/filters/reducer";


const COLUMN_IDS = {
    sessionID: 'sessionID',
    startTime: 'startTime',
    endTime: 'endTime',
    gameName: 'gameName',
    gameID: 'gameID',
    gameProvider: 'gameProvider',
    gameVendor: 'gameVendor',

    openRealBalance: 'openRealBalance',
    closeRealBalance: 'closeRealBalance',
    openBonusBalance: 'openBonusBalance',
    closeBonusBalance: 'closeBonusBalance',

    totalBet: 'totalBet',
    totalWin: 'totalWin',
    totalLoss: 'totalLoss',
    status: 'status',
    ip: 'ip',
    country: 'country',
    totalJackpotWin: 'totalJackpotWin'
};

const columnsWithPermissions = [
    COLUMN_IDS.openRealBalance,
    COLUMN_IDS.closeRealBalance,
    COLUMN_IDS.openBonusBalance,
    COLUMN_IDS.closeBonusBalance
]

const getDefaultColumns = (canViewBetBalances) => {
    let defaultColumns = [
        COLUMN_IDS.sessionID,
        COLUMN_IDS.startTime,
        COLUMN_IDS.endTime,
        COLUMN_IDS.gameName,
        COLUMN_IDS.gameID,
        COLUMN_IDS.gameProvider,
        COLUMN_IDS.gameVendor,
        COLUMN_IDS.openRealBalance,
        COLUMN_IDS.closeRealBalance,
        COLUMN_IDS.openBonusBalance,
        COLUMN_IDS.closeBonusBalance,
        COLUMN_IDS.totalBet,
        COLUMN_IDS.totalWin,
        COLUMN_IDS.totalLoss,
        COLUMN_IDS.status,
        COLUMN_IDS.ip,
        COLUMN_IDS.country,
    ];

    if (!canViewBetBalances) {
        defaultColumns = defaultColumns.filter(column =>
            !columnsWithPermissions.includes(column));
    }
    return defaultColumns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.sessionID]: 'crm.sessionID',
    [COLUMN_IDS.startTime]: 'crm.startTime',
    [COLUMN_IDS.endTime]: 'crm.endTime',
    [COLUMN_IDS.gameName]: 'crm.gameName',
    [COLUMN_IDS.gameID]: 'crm.gameID',
    [COLUMN_IDS.gameProvider]: 'crm.gameProvider',
    [COLUMN_IDS.gameVendor]: 'crm.gameVendor',
    [COLUMN_IDS.openRealBalance]: 'crm.openRealBalance',
    [COLUMN_IDS.closeRealBalance]: 'crm.closeRealBalance',
    [COLUMN_IDS.openBonusBalance]: 'crm.openBonusBalance',
    [COLUMN_IDS.closeBonusBalance]: 'crm.closeBonusBalance',
    [COLUMN_IDS.totalBet]: 'crm.totalBet',
    [COLUMN_IDS.totalWin]: 'crm.totalWin',
    [COLUMN_IDS.totalLoss]: 'crm.totalLoss',
    [COLUMN_IDS.status]: 'crm.status',
    [COLUMN_IDS.ip]: 'crm.ip',
    [COLUMN_IDS.country]: 'crm.country',
    [COLUMN_IDS.totalJackpotWin]: 'crm.totalJackpotWin',
};

const generateUrl = (sessionId) => {
    return (`${window.location + '&sessionId=' + sessionId}`).toString();
}

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                }) => {
    const size = useWindowSize();
    const { getProviderNameById } = useProviders();
    const totalLoss = Number(item.totalBet - item.totalWin) || 0;

    switch (field) {
        case COLUMN_IDS.sessionID:
            return <td>
                <div>
                    <a
                        className="me-2 span-pointer underlinedGray"
                        target={'_blank'}
                        href={generateUrl(item.id)}
                    >
                        {getGuidShortFormat(item.id)}
                    </a>
                    <ClipboardButton text={item.id}/>
                </div>
            </td>
        case COLUMN_IDS.startTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.startTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.createdAt))}
            </td>
        case COLUMN_IDS.endTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.endTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.completedAt))}
            </td>
        case COLUMN_IDS.gameName:
            return <td className='title__top'>
                {item.gameName}
            </td>
        case COLUMN_IDS.gameID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.gameID')}: </span>
                )}
                <div>
                     <span className="me-2">
                        {getGuidShortFormat(item.game)}
                     </span>
                    <ClipboardButton text={item.game}/>
                </div>
            </td>
        case COLUMN_IDS.gameProvider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.gameProvider')}: </span>
                )}
                {getProviderNameById(item.provider) || '—'}
            </td>
        case COLUMN_IDS.gameVendor:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.gameVendor')}: </span>
                )}
                {item.vendorName || '—'}
            </td>
        case COLUMN_IDS.openRealBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.openRealBalance')}: </span>
                )}
                {getBalance(item, 'open')}
            </td>
        case COLUMN_IDS.closeRealBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.closeRealBalance')}: </span>
                )}
                {getBalance(item, 'close')}
            </td>
        case COLUMN_IDS.openBonusBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.openBonusBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.openBonusBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.closeBonusBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.closeBonusBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.closeBonusBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalLoss:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalLoss')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(totalLoss)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.status')}: </span>
                )}
                {i18n.t(`crm.status.${item.status.charAt(0) + item.status.slice(1).toLowerCase()}`)}
            </td>
        case COLUMN_IDS.country:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.country')}: </span>
                )}
                {item.country ? i18n.t(item.country) : '—'}
            </td>
        case COLUMN_IDS.ip:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.ip')}: </span>)}
                {item.ip || '—'}
            </td>
        case COLUMN_IDS.totalJackpotWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalJackpotWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalJackpotWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        default:
            return null;
    }
};

function SessionsTable(props) {
    const {
        list,
        agentSelf,
        isShowTableSettings = false,
        toggleTableSettings,
        isLoadingGamePlay,
    } = props;
    const canViewBetBalances = agentSelf?.permissions?.includes('Player__View_Bets_Balance');
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const { timeFormat } = useTimeContext();
    const filterValues = getLocalStorageFilter();
    const [activeRow, setActiveRow] = useState(false);
    const [isDynamicColumnAdded, setIsDynamicColumnAdded] = useState(false);

    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns(canViewBetBalances));
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns(canViewBetBalances));
    const location = useLocation();

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);

    const shouldAddDynamicColumn = (list) => {
        return list.some(item => item.totalJackpotWin > 0);
    }

    useEffect(() => {
        if (list && list?.length > 0 && shouldAddDynamicColumn(list) && !isDynamicColumnAdded) {
            setActiveColumns(prevColumns => [...prevColumns, COLUMN_IDS.totalJackpotWin]);
            setIsDynamicColumnAdded(true);
        }

    }, [list, isDynamicColumnAdded]);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        toggleTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({ top: 0 });
    }, 200);

    useEffect(() => {
        dispatch(getGameProviders())
    }, [])

    useEffect(() => {
        setActiveColumns(getDefaultColumns(canViewBetBalances));
    }, [canViewBetBalances]);


    const handleToggle = (index, activeRow) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    if (list?.length === 0 && !isLoadingGamePlay && !filterValues.length) {
        return <div className='noGamePlayData'>
            {i18n.t('tabs.NoGameSessions')}
        </div>
    }


    return (
        <>
            {(list?.length > 0 || filterValues?.length > 0) && (
                <div className="page-title-table player-view-tab mb-3 mt-2">
                    <TableFilter
                        filterListOptions={FILTER_MENU_GAME_SESSION(t)}
                        isDisabled={false}
                        category={FILTER_CATEGORY.GAME_PLAY}
                    />
                </div>
            )}

            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index, activeRow)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                            />
                        ))}
                    </tr>
                ))}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={toggleTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    );
}

export default memo(SessionsTable);