import React from 'react';
import i18n from '../../i18n';

export default function AKYCField({ kyc = false }) {
    return (
        <>
            {kyc && (
                <>
                    <span
                        className="badge badge-soft-success"
                        style={{ padding: '6px' }}
                    >
                        {i18n.t('crm.kycApprove')}
                    </span>
                </>
            )}
            {!kyc && (
                <>
                    <span
                        className="badge badge-soft-danger"
                        style={{ padding: '6px' }}
                    >
                        {i18n.t('crm.kycNotApprove')}
                    </span>
                </>
            )}
        </>
    );
}
