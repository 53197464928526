import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useCallback } from 'react';
import AppDatePicker from "../../common/inputs/common/AppDatePicker";
import clsx from "clsx";

export default function ADateField({
    label,
    value,
    helperText,
    handleChange,
    disabled = false,
    fulWidth,
    error,
    style,
    fieldClassName,
    isBirthDate,
    allowFutureDate,
}) {
    const currentStyle = {
        style: {
            ...(disabled && { color: '#20262d' }),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            background: 'none',
            ...(style ? style : {})
        }
    };

    const DialogProps = {
        style: {
            inset: 'auto !important'
        }
    };

    const onChange = useCallback(
        date => {
            let newDate = null;
            if (typeof date === 'function') {
                const dateData = date();
                const dateKeys = Object.keys(dateData);
                if (dateKeys.length > 0) {
                    newDate = dateData[dateKeys[0]];
                }
            } else {
                newDate = date;
            }

            try {
                handleChange(newDate ? new Date(newDate).toISOString() : null);
            } catch (e) {
                handleChange(null);
            }
        },
        [handleChange]
    );

    const datePickerFields = {
        views: ['day', 'month', 'year'],
        value: value || null,
        label: (<div>{label}</div>),
        InputProps: {
            ...currentStyle,
            readOnly: true
        },
        mask: "__.__.____",
        inputFormat: "dd.MM.yyyy",
        disabled,
        onChange,
        DialogProps,
        renderInput: params => (
            <TextField
                {...params}
                error={error}
                variant="filled"
                helperText={helperText}
                style={{
                    width: fulWidth
                        ? '100%'
                        : currentStyle.width
                            ? currentStyle.width
                            : '200px'
                }}
            />
        ),
    };

    return (
        <div
            className={`fieldContainer dateContainer ${
                disabled ? 'disabledField' : ''
            }`}
            style={
                currentStyle.style.width
                    ? {
                          width: currentStyle.style.width,
                          minWidth: currentStyle.style.width
                      }
                    : {}
            }
        >
            <>
                <label
                    className={clsx('a-date-field-label', {
                        ['disabled']: disabled
                    })}
                >{label}</label>
                <AppDatePicker
                    className={`${fieldClassName} a-date-field`}
                    name={label}
                    onChange={onChange}
                    value={value ? value: null}
                    disabled={disabled}
                    isBirthDate={isBirthDate}
                    allowFutureDate={allowFutureDate}
                />
            </>
            {/*{datePickerComponentType === 'MobileDatePicker'*/}
            {/*    ? <LocalizationProvider*/}
            {/*        dateAdapter={AdapterDateFns}*/}
            {/*        locale={enGB}*/}
            {/*    >*/}
            {/*        <MobileDatePicker*/}
            {/*            {...datePickerFields}*/}
            {/*        />*/}
            {/*    </LocalizationProvider>*/}
            {/*    : <>*/}
            {/*        <label*/}
            {/*            className="a-date-field-label"*/}
            {/*        >{label}</label>*/}
            {/*        <AppDatePicker*/}
            {/*            className={'a-date-field'}*/}
            {/*            name={label}*/}
            {/*            onChange={onChange}*/}
            {/*            value={value || null}*/}
            {/*            disabled={disabled}*/}
            {/*        />*/}
            {/*    </>*/}
            {/*}*/}
        </div>
    );
}
