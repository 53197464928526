import React, { useEffect, useMemo, useState } from 'react';
import clsx from "clsx";
import { Link, withRouter } from 'react-router-dom';
import { getGuidShortFormat, getColumnSettingsLsKey, initTableColumns } from '../../../common/utils/common';
import Formatter from '../../../utils/Formatter';
import NewCustomTable from '../../../components/NewCustomTable';
import CopyButton from '../../../common/cellData/CopyButton';
import PropTypes from 'prop-types';
import { PAYMENT_STATUS } from '../../../constants/status';
import KycView from "../components/KycView";
import PaymentStatusView from "../../../components/Status/PaymentStatusView";
import { useTranslation } from "react-i18next";
import { getProductName } from '../../../utils/products';
import { useSelector } from "react-redux";
import { SITE_BRAND } from "../../../constants/siteBrands";
import { useTimeContext } from "../../../context/TimeContext";
import { IdCell, PlayerCell } from "../../../components/NewCustomTable/CellsWithPermissions";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import { COLUMN_IDS, getDefaultColumns } from "../../../constants/tableSettings";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import PlayerData from 'common/cellData/PlayerData';

export const WITHDRAWALS_SORTED_FIELDS = {
    wdNumber: 'id',
    player: 'player',
    createdAt: 'createdAt',
    amount: 'amount',
    totalPaid: 'totalAmount',
    fees: 'taxAmount',
    currency: 'currency',
    status: 'status',
    lastUpdated: 'lastChangedAt',
};

const getAllColumns = (isGcPlaying = false) => {
    let columns = [
        COLUMN_IDS.wdNumber,
        COLUMN_IDS.player,
        COLUMN_IDS.txnOID,
        COLUMN_IDS.createdAt,
    ];

    if (!isGcPlaying) {
        columns.push(COLUMN_IDS.userIp);
    }

    columns = [
        ...columns,
        COLUMN_IDS.PSP,
        COLUMN_IDS.PM,
        COLUMN_IDS.email,
        COLUMN_IDS.amount,
        COLUMN_IDS.totalPaid,
        COLUMN_IDS.currency,
        COLUMN_IDS.fees,
        COLUMN_IDS.KYC,
        COLUMN_IDS.status,
        COLUMN_IDS.lastUpdated,
        COLUMN_IDS.product,
        COLUMN_IDS.totalSuccessfulWD,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.totalBonuses,
        COLUMN_IDS.bonusRatio,
        COLUMN_IDS.WDRatio,
        COLUMN_IDS.netCash,
    ];

    return columns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.wdNumber]: 'finance.wdNumber',
    [COLUMN_IDS.player]: 'finance.player',
    [COLUMN_IDS.txnOID]: 'finance.txnOID',
    [COLUMN_IDS.createdAt]: 'finance.createdAt',
    [COLUMN_IDS.PSP]: 'finance.PSP',
    [COLUMN_IDS.PM]: 'finance.PM',
    [COLUMN_IDS.email]: 'finance.email',
    [COLUMN_IDS.amount]: 'finance.amount',
    [COLUMN_IDS.totalPaid]: 'finance.totalPaid',
    [COLUMN_IDS.currency]: 'finance.currency',
    [COLUMN_IDS.fees]: 'finance.fees',
    [COLUMN_IDS.KYC]: 'finance.KYC',
    [COLUMN_IDS.status]: 'finance.status',
    [COLUMN_IDS.lastUpdated]: 'finance.lastUpdated',
    [COLUMN_IDS.product]: 'finance.product',
    [COLUMN_IDS.totalSuccessfulWD]: 'finance.totalSuccessfulWD',
    [COLUMN_IDS.totalDeposits]: 'finance.totalDeposits',
    [COLUMN_IDS.totalBonuses]: 'finance.totalBonuses',
    [COLUMN_IDS.bonusRatio]: 'finance.bonusRatio',
    [COLUMN_IDS.WDRatio]: 'finance.WDRatio',
    [COLUMN_IDS.netCash]: 'finance.netCash',
    [COLUMN_IDS.userIp]: 'finance.userIp',
};

const TdElem = ({
    item,
    field,
    timeFormat,
    i18n
}) => {
    const size = useWindowSize();

    switch (field) {
        case COLUMN_IDS.wdNumber:
            return <td>
                <div className='cell-row'>
                    <Link to={item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL ?
                        `/withdrawals-pending-approve/${item.id}` :
                        `/withdrawals/${item.id}`}>
                        {getGuidShortFormat(item.id)}
                    </Link>
                    &nbsp;&nbsp;
                    <CopyButton
                        className="column-cpy-button"
                        onClick={() =>
                            navigator.clipboard.writeText(item.id)
                        }
                    />
                    {
                        size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <PaymentStatusView status={item.status}/>
                        )
                    }
                </div>
            </td>;
        case COLUMN_IDS.player:
            return <td style={{ overflow: 'visible' }} className='title__top'>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <Link to={item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL ?
                        `/withdrawals-pending-approve/${item.id}` :
                        `/withdrawals/${item.id}`}>
                        <PlayerData player={item.player} />
                    </Link>
                ) : (
                    <PlayerCell
                        item={item.player}
                        url={{
                            id: item.player.oid
                        }}
                    />
                )}
            </td>;
        case COLUMN_IDS.txnOID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.txnOID')}: </span>
                )}
                <IdCell
                    item={item}
                    url={{
                        type: 'finance',
                        id: item.player.oid
                    }}
                />
            </td>;
        case COLUMN_IDS.createdAt:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.createdAt')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>;
        case COLUMN_IDS.PSP:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.PSP')}: </span>
                )}
                {item?.provider?.name || "-"}
            </td>;
        case COLUMN_IDS.PM:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.PM')}: </span>
                )}
                {item?.method || "-"}
            </td>;
        case COLUMN_IDS.email:
            return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.email')}: </span>
                    )}
                    <span className='cutted__text'>{item?.player?.email || "-"}</span>
                </td>;
        case COLUMN_IDS.amount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.amount')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.amount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.totalPaid:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalAmount')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.totalAmount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.currency:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.currency')}: </span>
                )}
                {item.currency}
            </td>;
        case COLUMN_IDS.fees:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.taxAmount')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.taxAmount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.KYC:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.kyc')}: </span>
                )}
                <KycView kyc={item.kyc} />
            </td>;
        case COLUMN_IDS.status:
            return (
                size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <td className={"halo text-nowrap"}>
                        <PaymentStatusView status={item.status}/>
                    </td>
                )
            );
        case COLUMN_IDS.lastUpdated:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.lastUpdated')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.updatedAt))}
            </td>;
        case COLUMN_IDS.product:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.product')}: </span>
                )}
                {item?.player?.product ? getProductName(item?.player?.product) : "-"}
            </td>;
        case COLUMN_IDS.totalSuccessfulWD:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalSuccessfulWD')}: </span>
                )}
                {item?.player?.totalSuccessfulWDAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.player?.totalSuccessfulWDAmount),
                        item.currency
                    )
                    : '-'
                }
            </td>;
        case COLUMN_IDS.totalDeposits:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalDeposits')}: </span>
                )}
                {item?.player?.totalDepositsAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.player?.totalDepositsAmount),
                        item.currency
                    )
                    : '-'
                }
            </td>;
        case COLUMN_IDS.totalBonuses:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalBonuses')}: </span>
                )}
                {item?.player?.bonusesAmount
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.player?.bonusesAmount),
                        item.currency
                    )
                    : '-'
                }
            </td>;
        case COLUMN_IDS.bonusRatio:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.bonusRatio')}: </span>
                )}
                {item?.player?.bonusRatio
                    ? (+item?.player?.bonusRatio * 100).toFixed(2) + ' %'
                    : '-'
                }
            </td>;
        case COLUMN_IDS.WDRatio:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.WDRatio')}: </span>
                )}
                {item?.player?.WDRatio
                    ? (+item?.player?.WDRatio * 100).toFixed(2) + ' %'
                    : '-'
                }
            </td>;
        case COLUMN_IDS.netCash:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.netCash')}: </span>
                )}
                {item?.player?.netCash
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.player?.netCash),
                        item.currency
                    )
                    : '-'
                }
            </td>;
        case COLUMN_IDS.userIp:
            return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.userIp')}: </span>
                    )}
                    {item?.userIp || "-"}
                </td>;
        default:
            return null;
    }
};

function WithdrawalsTable({
    list,
    isLoading,
    isShowTableSettings,
    onCloseTableSettings,
    onSortWithdrawals,
    match,
}) {
    const { i18n } = useTranslation();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const gcPlaying = activeBrand?.siteName === SITE_BRAND.GCPLAYING;
    const { timeFormat } = useTimeContext();

    const allColumns = useMemo(() => getAllColumns(), []);
    const defaultColumns = useMemo(() => getDefaultColumns('withdrawals', allColumns), []);
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState([...allColumns]);
    const [brandActiveColumns, setBrandActiveColumns] = useState([...activeColumns]);
    const [activeRow, setActiveRow] = useState(false);

    useEffect(() => {
        initTableColumns(match.path, () => defaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const columnsData = brandActiveColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[COLUMN_IDS[key]]),
            id: COLUMN_IDS[key],
            sorted: key in WITHDRAWALS_SORTED_FIELDS,
        }));
        setColumns(columnsData);
    }, [list, i18n.language, brandActiveColumns]);

    useEffect(() => {
        if (gcPlaying) {
            setBrandActiveColumns(activeColumns.filter((column) => column !== COLUMN_IDS.IP));
        }
        else {
            setBrandActiveColumns([...activeColumns]);
        }
    }, [gcPlaying, activeColumns]);

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <NewCustomTable
                isHeader={true}
                columns={columns}
                onSortItems={onSortWithdrawals}
                sortAfterMount={false}
                isLoading={isLoading}
                className='responsiveTable responsiveTable__xl'
            >
                {list &&
                    list.map((item, index) =>
                        <tr
                            key={index}
                            className={clsx('font-w-500', {open: activeRow === index})}
                            onClick={() => handleToggle(index)}
                        >
                            {brandActiveColumns.map((column) => <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                                i18n={i18n}
                            />)}
                        </tr>
                    )}
            </NewCustomTable>
            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={brandActiveColumns}
                    defaultColumns={defaultColumns}
                    allColumns={allColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    );
}

WithdrawalsTable.propTypes = {
    list: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
    onSortWithdrawals: PropTypes.func,
};

WithdrawalsTable.defaultProps = {
    onCloseTableSettings: () => {},
    onSortWithdrawals: () => {},
};

export default React.memo(withRouter(WithdrawalsTable));
