import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_METHOD_TYPE } from './automationConstants';
import i18n from '../../../i18n';
import SelectInput from '../../../common/inputs/common/SelectInput';

function PaymentMethodSelect({ onChange, value, ...props }) {
    const options = useMemo(() => {
        return Object.keys(PAYMENT_METHOD_TYPE).map(key => ({
            value: key,
            label: i18n.t(`workflow.constants.${key}`)
        }));
    }, []);

    return (
        <SelectInput
            {...props}
            onChange={onChange}
            value={value}
            options={options}
            placeholder={i18n.t('workflow.selectMethod')}
        />
    );
}

PaymentMethodSelect.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(PaymentMethodSelect);
