import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { SITE_BRAND, SITE_BRAND_METABASE } from "../../../constants/siteBrands";
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import { getSync, postSync } from "../../../helpers/api_helper";

const PlayerReportIframe = () => {
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const ref = useRef(null);
    const windowSize = useWindowSize();
    const { active: activeBrand } = useSelector((state) => state.Brand);
    const { t } = useTranslation();
    const [iframeSrc, setIframeSrc] = useState("");

    const [otherAgentsFromRole, setOtherAgentsFromRole] = useState([]);
    const [isRolePermissionsLoaded, setIsRolePermissionsLoaded] = useState(false);
    const [isSelfAgentFromRole, setIsSelfAgentFromRole] = useState(false);
    const agentRoleId = agentSelf?.roleId || '';

    const urlRolePermissions = `/it-manager/role-permissions-visibilities?roleId=${agentRoleId}`;

    useEffect(() => {
        const fetchRolePermissions = async () => {
            if (agentRoleId) {
                try {
                    const result = await getSync(urlRolePermissions, {});
                    setIsSelfAgentFromRole(result?.data?.visibility?.selfAgentOnly);
                    setOtherAgentsFromRole(result?.data?.visibility?.otherAgents || []);
                    setIsRolePermissionsLoaded(true);
                } catch (error) {
                    setIsRolePermissionsLoaded(false);
                }
            }
        };
        fetchRolePermissions();
    }, [agentRoleId, urlRolePermissions]);

    useEffect(() => {
        if (ref.current) {
            ref.current.height = windowSize.height + 90 + 'px';
        }
    }, [windowSize.height]);

    useEffect(() => {
        const generateIFrameLink = async () => {
            const params = {
                agentownerid: isSelfAgentFromRole
                  ? agentSelf?.id
                  : (otherAgentsFromRole.length > 0 ? otherAgentsFromRole : [])
            };

            if (activeBrand?.siteName === SITE_BRAND.Dealer21 && isRolePermissionsLoaded) {
                const url = `/dashboard/dashboard-url/`;
                const result = await postSync(url, {
                    id: '52',
                    params
                });
                setIframeSrc(result?.url || '');
            } else {
                setIframeSrc(`https://bi.wiz-crm.com/public/dashboard/${SITE_BRAND_METABASE[activeBrand?.siteName]}`);
            }
        };

        if (activeBrand?.siteName && isRolePermissionsLoaded) {
            generateIFrameLink();
        }
    }, [activeBrand, otherAgentsFromRole, isRolePermissionsLoaded]);

    if (!activeBrand?.siteName) {
        return (
          <div className="w-100 d-flex justify-content-center">
              {t('crm.needConfigurePlayerReport')}
          </div>
        );
    }

    return (
      <iframe
        ref={ref}
        src={iframeSrc}
        width="100%"
        height="100%"
        allowFullScreen
        allowTransparency
      ></iframe>
    );
};

export default PlayerReportIframe;
