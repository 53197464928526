export const GET_PROMOTIONS = "GET_PROMOTIONS"
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS"
export const GET_PROMOTIONS_FAIL = "GET_PROMOTIONS_FAIL"

export const GET_BONUS_WAGERING_SMALL_DATA = "GET_BONUS_WAGERING_SMALL_DATA"
export const GET_BONUS_WAGERING_SMALL_DATA_SUCCESS = "GET_BONUS_WAGERING_SMALL_DATA_SUCCESS"
export const GET_BONUS_WAGERING_SMALL_DATA_FAIL = "GET_BONUS_WAGERING_SMALL_DATA_FAIL"

export const GET_PROMOTION_TEMPLATES = "GET_PROMOTION_TEMPLATES"
export const GET_PROMOTION_TEMPLATES_SUCCESS = "GET_PROMOTION_TEMPLATES_SUCCESS"
export const GET_PROMOTION_TEMPLATES_FAIL = "GET_PROMOTION_TEMPLATES_FAIL"

export const CLEAR_CURRENT_PROMOTION = "CLEAR_CURRENT_PROMOTION"

export const GET_PROMOTION = "GET_PROMOTION"
export const GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS"
export const GET_PROMOTION_FAIL = "GET_PROMOTION_FAIL"

export const UPDATE_PROMOTION = "UPDATE_PROMOTION"
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS"
export const UPDATE_PROMOTION_FAIL = "UPDATE_PROMOTION_FAIL"

export const CREATE_PROMOTION = "CREATE_PROMOTION"
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS"
export const CREATE_PROMOTION_FAIL = "CREATE_PROMOTION_FAIL"

export const RESET_PROMOTION_ALERTS_ERRORS = "RESET_PROMOTION_ALERTS_ERRORS"

export const GET_SPORT_PROMOTIONS = "GET_SPORT_PROMOTIONS"
export const GET_SPORT_PROMOTIONS_SUCCESS = "GET_SPORT_PROMOTIONS_SUCCESS"
export const GET_SPORT_PROMOTIONS_FAIL = "GET_SPORT_PROMOTIONS_FAIL"

export const GET_SPORT_PROMOTION = "GET_SPORT_PROMOTION"
export const GET_SPORT_PROMOTION_SUCCESS = "GET_SPORT_PROMOTION_SUCCESS"
export const GET_SPORT_PROMOTION_FAIL = "GET_SPORT_PROMOTION_FAIL"

export const CREATE_SPORT_PROMOTION = "CREATE_SPORT_PROMOTION"
export const CREATE_SPORT_PROMOTION_SUCCESS = "CREATE_SPORT_PROMOTION_SUCCESS"
export const CREATE_SPORT_PROMOTION_FAIL = "CREATE_SPORT_PROMOTION_FAIL"

export const UPDATE_SPORT_PROMOTION = "UPDATE_SPORT_PROMOTION"
export const UPDATE_SPORT_PROMOTION_SUCCESS = "UPDATE_SPORT_PROMOTION_SUCCESS"
export const UPDATE_SPORT_PROMOTION_FAIL = "UPDATE_SPORT_PROMOTION_FAIL"

