import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Row } from 'reactstrap';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { capitalize, DATE_TIME_FORMAT } from '../../../common/utils/common';
import { getPlayerGeneral } from '../../../store/players/general/actions';
import { postSync } from '../../../helpers/api_helper';
import { DocumentType } from "../../../utils/KYC";
import { getStatesAndCities, putCurrentCities } from "../../../store/statesAndCities/actions";
import DeclineSubmitBtnGroup from "../../../UI/buttonsGroups/DeclineSubmitBtnGroup";
import { resetPlayerListAlertsErrors, updatePlayerIdentityInfo } from "../../../store/players/list/actions";
import useAlertService from "../../../hooks/useAlertService";
import { getDocumentData } from "../../../store/documents/actions";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SPECIAL_SYMBOLS } from "../../../constants/validations";

const DocInfoArea = (props) => {
    const {
        currentDocument,
        onChangeAddress,
        canEditByEditPlayerInfoPerm,
        payment,
    } = props;

    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { playerGeneral } = useSelector(
        state => state.PlayerGeneral
    );

    const alertService = useAlertService();
    const { isLoadingMassUpdate, successAlert } = useSelector(state => state.PlayerList);
    const { states, cities } = useSelector(state => state.StatesAndCities);

    const documentStatus = currentDocument.status;
    const docCreatedTime = moment(new Date(currentDocument?.createdAt)).format(DATE_TIME_FORMAT);
    const docUpdatedTime = moment(new Date(currentDocument?.lastChangedAt)).format(DATE_TIME_FORMAT);

    const docIdRef = useRef('');

    const {
        residentialInfo,
        address: {
            addressCountryAlfa2 = ''
        } = {}
    } = playerGeneral || {};

    const [initialPlayer, setInitialPlayer] = useState(null);
    const [initialDocument, setInitialDocument] = useState(null);
    const [player, setPlayer] = useState(null);
    const [document_, setDocument_] = useState(null);

    const [address, setAddress] = useState({
        colonyMX: '',
        state: '',
        city: '',
        street: '',
        zipCode: '',
    });

    const [currentState, setCurrentState] = useState('');
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);

    const location = useLocation();
    const isMexico = (country) => country === "MX";
    const isStateOfMexico = (state) => states.find(({ text }) => text === state);
    const isDisabledFieldsDocumentTypes =
        [DocumentType.INE, DocumentType.IFE, DocumentType.Passport].includes(currentDocument?.type);
    const isWdForApprovalOrTransfer = location.pathname.includes('withdrawals-pending-approve') ||
                                      location.pathname.includes('withdrawals-pending-transfer')

    useEffect(() => {
        if (isWdForApprovalOrTransfer) {
            dispatch(getDocumentData(currentDocument.oid));
        }
    }, [props.isPopupOpen])

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetPlayerListAlertsErrors());
            dispatch(getDocumentData(currentDocument.oid));
        }
    }, [successAlert]);

    const isChanges = useMemo(() => {
        return !isEqual(initialPlayer, player) || !isEqual(initialDocument, document_);
    }, [document_, initialDocument, player, initialPlayer])

    useEffect(() => {
        props.setIsEditPlayerData(isChanges);
        setIsDisabledSaveButton(props.isEditPlayerData && !isChanges);
    }, [isChanges])

    useEffect(() => {
        if (isMexico(addressCountryAlfa2) && !states.length) {
            dispatch(getStatesAndCities());
        }
    }, [addressCountryAlfa2]);

    useEffect(() => {
        if (isMexico(addressCountryAlfa2) && isStateOfMexico(address.state)) {
            setCurrentState(address.state);
        } else {
            setCurrentState('');
        }
    }, [addressCountryAlfa2, states, address.state])

    useEffect(() => {
        const dataPlayerFroStore = {
            firstName: playerGeneral?.firstName,
            lastName: playerGeneral?.lastName,
            birthDate: playerGeneral?.birthDate,
        }
            setPlayer({ ...dataPlayerFroStore });
            setInitialPlayer({ ...dataPlayerFroStore });
    }, [playerGeneral?.firstName, playerGeneral?.lastName, playerGeneral?.birthDate]);

    useEffect(() => {
        const dataDocumentFromStore = {
            expiration: currentDocument?.expiration,
            type: currentDocument?.type,
            side: currentDocument?.side,
        }
        setDocument_({
            ...dataDocumentFromStore
        });
        setInitialDocument({
            ...dataDocumentFromStore
        });
    }, [currentDocument?.expiration, currentDocument?.type, currentDocument?.side]);

    useEffect(() => {
        const isApproveDisabled = currentDocument?.status === 'Approved'
            || [DocumentType.INE, DocumentType.IFE, DocumentType.Passport].includes(document_?.type)
            && !document_?.expiration
            || document_?.type === DocumentType.BankStatementAsProofOfAddress
            && !(
                props.userAddress?.state
                && props.userAddress?.city
                && props.userAddress?.street
                && props.userAddress?.zipCode
            );
            props.setIsApproveDisabled(isApproveDisabled)
    }, [
        currentDocument?.status,
        document_?.type,
        document_?.expiration,
        props.userAddress

    ])

    const handleChangePlayer = useCallback(
        (name) => value => {

            if (name === "birthDate") {
                value = moment.utc(moment(value).format('YYYY-MM-DD')).toISOString();
            }
            if (name === 'firstName' || name === 'lastName') {
                if (value.length <= 1) {
                    value = value.replace(' ', '');
                }
                value = value.replace(SPECIAL_SYMBOLS, '');
            }
            setPlayer(prevState => ({ ...prevState, [name]: value}));
            props.setIsEditPlayerData(true);
        }, [props]
    );

    const handleChangeDocument = useCallback(
        (name) => value => {
            if (name === 'expiration') {
                value = moment.utc(moment(value).format('YYYY-MM-DD')).toISOString();
            }
            setDocument_(prevState => ({ ...prevState, [name]: value}));
            props.setIsEditPlayerData(true);
        }, [props]
    );

    useEffect(() => {
        const indexStateInArray = states.findIndex(state => state.text === currentState);
        const currentCities = [];
        if (indexStateInArray !== -1) {
            cities.forEach((city) => {
                if (city.state_code === states[indexStateInArray].state_code) {
                    currentCities.push({ text: city.name, value: city.state_code })
                }
            }, []);
            dispatch(putCurrentCities({ currentCities: currentCities }));
        } else {
            dispatch(putCurrentCities({ currentCities: [] }));
        }
        if (!isStateOfMexico(address.state)) {
            dispatch(putCurrentCities({ currentCities: [] }));
        }
    }, [currentState, cities]);

    const changeAddress = (newAddress) => {
        setAddress(newAddress);
        onChangeAddress(newAddress);
    };

    const isAddressDocument = useMemo(() =>
        currentDocument.type === DocumentType.BankStatementAsProofOfAddress ||
        currentDocument.type === DocumentType.UtilityBill,
        [currentDocument.type]
    );

    const needExpirationField = useMemo(() => {
        return (
            currentDocument.type !== DocumentType.CURP_Certificate &&
            currentDocument.type !== DocumentType.SOF_Agreement &&
            currentDocument.type !== DocumentType.PaymentConfirmation
        )
    }, [currentDocument.type])

    const needTypeOrSideOrBirthDateField = useMemo(() => {
        return  (
            currentDocument.type === DocumentType.IFE ||
            currentDocument.type === DocumentType.INE ||
            currentDocument.type === DocumentType.ID_front ||
            currentDocument.type === DocumentType.ID_back ||
            currentDocument.type === DocumentType.Passport
        )
    }, [currentDocument.type])

    useEffect(() => {
        if (isAddressDocument) {
            const residentialAddress = residentialInfo?.address;
            const playerAddress = playerGeneral?.address;

            if (residentialAddress || playerAddress) {
                const currentAddress = {
                    colonyMX: residentialAddress?.colonyMX || playerAddress?.colonyMX,
                    state: residentialAddress?.state || playerAddress?.state,
                    city: residentialAddress?.city || playerAddress?.city,
                    street: residentialAddress?.street || playerAddress?.street,
                    zipCode: residentialAddress?.zipCode || playerAddress?.zipCode,
                };

                changeAddress(currentAddress);
            }
        }
    }, [playerGeneral]);

    const handleChangeAddressField = useCallback((field) => (value) => {
        if (isAddressDocument) {
            const newAddress = {
                ...address,
                [field]: value,
            };

            changeAddress(newAddress);
        }
    }, [isAddressDocument, address]);

    const onSave = () => {
        let errorFieldName;

        if (!player?.firstName) {
            errorFieldName = i18n.t('crm.firstName');
        } else if (!player?.lastName) {
            errorFieldName = i18n.t('crm.lastName');
        } else if (!document_?.type) {
            errorFieldName = i18n.t('crm.documentType');
        } else if (!document_?.side) {
            errorFieldName = i18n.t('crm.documentSide');
        }

        if (errorFieldName) {
            alertService.showError(i18n.t(`${errorFieldName} must be filled in`));
        } else {
            const trimmedPlayerDto = {
                ...player,
                firstName: player.firstName.trim(),
                lastName: player.lastName.trim()
            };
            dispatch(updatePlayerIdentityInfo({
                playerOid: currentDocument?.playerOid || playerGeneral?.oid,
                docOid: currentDocument.oid,
                dto: {
                    ...trimmedPlayerDto,
                    ...document_
                }
            }));
            props.setIsEditPlayerData(false);
        }
    }

    const handleCancel = useCallback(() => {
        setPlayer(initialPlayer);
        setDocument_(initialDocument);
    }, [initialPlayer, initialDocument]);

    const fields = useMemo(
        () => {
            const fields = [
                {
                    label: i18n.t(`crm.document.status`),
                    value: i18n.t(`crm.document.status.${documentStatus}`),
                    disabled: true,
                },
                {
                    value: capitalize(playerGeneral?.status || payment?.player?.accountStatus),
                    label: i18n.t('crm.accountStatus'),
                    disabled: true
                },
                {
                    value: isWdForApprovalOrTransfer ? payment?.player?.firstName : player?.firstName,
                    label: i18n.t('crm.firstName'),
                    name: 'firstName',
                    disabled: !isDisabledFieldsDocumentTypes || !canEditByEditPlayerInfoPerm,
                    handleChange: handleChangePlayer('firstName')
                },
                {
                    value: isWdForApprovalOrTransfer ? payment?.player?.lastName : player?.lastName,
                    label: i18n.t('crm.lastName'),
                    name: 'lastName',
                    disabled: !isDisabledFieldsDocumentTypes || !canEditByEditPlayerInfoPerm,
                    handleChange: handleChangePlayer('lastName')
                },
            ];

            if (needTypeOrSideOrBirthDateField) {
                fields.push(
                    {
                        component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_TYPES,
                        label: i18n.t(`crm.document.type`),
                        value: currentDocument.type,
                        disabled: true,
                    },
                    {
                        component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_SIDES,
                        label: i18n.t(`crm.document.side`),
                        handleChange: handleChangeDocument('side'),
                        value: document_?.side,
                        disabled: !canEditByEditPlayerInfoPerm
                    },
                    {
                        component: A_COMMON_DATA_FIELD.DATE,
                        value: player?.birthDate,
                        label: i18n.t('crm.birthDate'),
                        name: 'birthDate',
                        className: 'birthdate-field',
                        disabled: !isDisabledFieldsDocumentTypes || !canEditByEditPlayerInfoPerm,
                        handleChange: handleChangePlayer('birthDate')
                    },
                );
            }

            if (needExpirationField) {
                fields.push(
                        {
                            component: A_COMMON_DATA_FIELD.DATE,
                            label: i18n.t('crm.document.expiration'),
                            name: 'expiration',
                            className: 'expiration-field',
                            handleChange: handleChangeDocument('expiration'),
                            value: document_?.expiration,
                            disabled: !canEditByEditPlayerInfoPerm
                        },
                );
            }
            else {
                // code can be used in the future
                // fields.push(
                //     {
                //         component: A_COMMON_DATA_FIELD.DATE,
                //         label: i18n.t('crm.document.expiration'),
                //         handleChange: handleChangeDocument('expiration'),
                //         value: document?.expiration,
                //     },
                //     {
                //         component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_TYPES,
                //         label: i18n.t(`crm.document.type`),
                //         handleChange: handleChangeDocument('type'),
                //         value: document?.type
                //     },
                //     {
                //         component: A_COMMON_DATA_FIELD.SELECT_DOCUMENT_SIDES,
                //         label: i18n.t(`crm.document.side`),
                //         handleChange: handleChangeDocument('side'),
                //         value: document?.side
                //     },
                // );
            }

            const isMexican = residentialInfo?.address?.addressCountryAlfa2 === 'MX';

            if (isAddressDocument) {
                fields.push(
                    {
                        value: address?.state,
                        label: i18n.t(`crm.state`),
                        handleChange: handleChangeAddressField('state'),
                        component: isMexico(addressCountryAlfa2)
                            ? A_COMMON_DATA_FIELD.SELECT_STATES
                            : 'text',
                        autoSelect: isMexico(addressCountryAlfa2),
                        disabled: !canEditByEditPlayerInfoPerm,
                    },
                    {
                        value: address?.city,
                        label: i18n.t('crm.city'),
                        handleChange: handleChangeAddressField('city'),
                        component: isMexico(addressCountryAlfa2)
                            ? A_COMMON_DATA_FIELD.SELECT_CITIES
                            : 'text',
                        autoSelect: isMexico(addressCountryAlfa2),
                        disabled: !canEditByEditPlayerInfoPerm,
                    },
                    {
                        value: address?.street,
                        label: i18n.t('crm.street'),
                        handleChange: handleChangeAddressField('street'),
                        disabled: !canEditByEditPlayerInfoPerm,
                    },
                    {
                        value: address?.zipCode,
                        label: i18n.t('crm.zipCode'),
                        handleChange: handleChangeAddressField('zipCode'),
                        disabled: !canEditByEditPlayerInfoPerm,
                    },
                )
            }
            return fields;
        },
        [
            i18n,
            documentStatus,
            player,
            currentDocument?.firstName,
            currentDocument?.lastName,
            currentDocument?.type,
            document_?.side,
            document_?.expiration,
            handleChangeAddressField,
            address?.state,
            address?.city,
            address?.street,
            address?.zipCode,
            needExpirationField,
            isAddressDocument,
            isDisabledFieldsDocumentTypes,
            handleChangePlayer,
            needTypeOrSideOrBirthDateField,
            handleChangeDocument,
            canEditByEditPlayerInfoPerm,
        ]
    );

    const reportToOnfido = async () => {
        const url = `/onfido/document/${currentDocument.oid}`;
        try {
            await postSync(url, {});
            alertService.showSuccess('Document updated');
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError(i18n.t("crm.notUpdatedDocument"));
        }
    };

    useEffect(() => {
        if (docIdRef.current !== currentDocument?.id) {
            if (currentDocument.playerOid) {
                dispatch(getPlayerGeneral(currentDocument.playerOid));
            } else if (playerGeneral?.oid) {
                dispatch(getPlayerGeneral(playerGeneral.oid));
            }
            docIdRef.current = currentDocument?.id;
        }
    }, [currentDocument]);

    return (
        <Row>
            {isWdForApprovalOrTransfer &&
                <div className='docs-info-dates'>
                    <div className='doc-info-date'>
                        <span>{i18n.t('crm.docDateCreated')}</span>
                        <span>{docCreatedTime}</span>
                    </div>
                    <div className='doc-info-date'>
                        <span>{i18n.t('crm.docDateUpdated')}</span>
                        <span>{docUpdatedTime}</span>
                    </div>
                </div>
            }
            {fields.map((item, index) => {
                return <ACommonDataField
                    fieldClassName={
                        item.className || ''
                    }
                    key={index}
                    {...item}
                    value={item?.value}
                    fulWidth
                    datePickerComponentType={'MobileDatePicker'}
                    isBirthDate={item.name === 'birthDate'}
                    allowFutureDate={item.name === 'expiration'}
                    disabled={item.disabled}
                />
            })}
            {props.isEditPlayerData && (
                <DeclineSubmitBtnGroup
                    onSave={onSave}
                    onCancel={handleCancel}
                    isDisabled={isLoadingMassUpdate}
                    isDisabledSaveButton={isDisabledSaveButton}
                />
            )}
        </Row>
    );
};

export default DocInfoArea;
