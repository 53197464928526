import React from 'react';
import PropTypes from 'prop-types';
import players from '../../assets/images/layout/Profile.svg';

const ActiveUsers = ({ className = '', count, onClick = () => {} }) => (
    <div className={`toolbar-component ${className}`} onClick={onClick}>
        <img className="component-img" src={players} alt="time" height="24" />
        {count}
    </div>
);

ActiveUsers.propTypes = {
    count: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default ActiveUsers;
