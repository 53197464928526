import {
    GET_STATISTICS,
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAIL,
    RESET_STATISTICS_ERRORS,
} from './actionTypes';


export const getStatistics = payload => ({
    type: GET_STATISTICS,
    payload
});

export const getStatisticsSuccess = payload => ({
    type: GET_STATISTICS_SUCCESS,
    payload
});

export const getStatisticsFail = payload => ({
    type: GET_STATISTICS_FAIL,
    payload
});

export const resetStatisticsErrors = payload => ({
    type: RESET_STATISTICS_ERRORS,
    payload
});
