import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../../../common/inputs/common/complicated-checkbox-input.scss';
import ComplicatedCheckboxSelect from '../../../common/inputs/common/ComplicatedCheckboxSelect';
import i18n from '../../../i18n';

const GAMES = [
    { id: 1, name: 'Slots / slots_game' },
    { id: 2, name: 'Roulette / roulette_game' },
    { id: 3, name: 'Live casino / live_casino' },
    { id: 4, name: 'Table games / table_games' },
    { id: 5, name: 'Sports / sports' }
];

function GameTypeSelect({ onChange, value, ...props }) {
    const options = useMemo(() => {
        return GAMES.map(item => ({ value: item.id, label: item.name }));
    }, []);

    return (
        <ComplicatedCheckboxSelect
            value={value}
            onChange={onChange}
            options={options}
            placeholder={i18n.t('attributions.chooseType')}
            {...props}
        />
    );
}

GameTypeSelect.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(GameTypeSelect);
