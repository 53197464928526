import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

import './styles.scss';

const Breadcrumbs = ({
    title, titleLink, breadcrumbItem, isRetailProfile = false
}) => {

    return (
        <React.Fragment>
            <Row>
                <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <span className="mb-0 font-size-1">{''}</span>
                        {title && (
                            <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    <BreadcrumbItem>
                                        <div>
                                            <Link to={{
                                                pathname: titleLink,
                                                state: { isRetailProfilePrevPage: isRetailProfile } }}
                                            >
                                                {title}
                                            </Link>
                                        </div>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link
                                            className="active-breadcrumb-item-link"
                                            to="#"
                                            style={{ cursor: 'default' }}
                                        >
                                            {breadcrumbItem}
                                        </Link>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

Breadcrumbs.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string,
    titleLink: PropTypes.string
};

Breadcrumbs.defaultProps = {
    titleLink: '#'
};

export default Breadcrumbs;
