import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Card } from "reactstrap"
import MetaTags from 'react-meta-tags'
import "./styles.scss"
import "../../assets/scss/pages/common.scss"
import { useDispatch, useSelector } from "react-redux"
import {
	downloadWithdrawalList,
	getWithdrawalList,
	resetWithdrawalListAlertsErrors
} from "../../store/actions"
import TableFilter from "components/TableFilter"
import { FILTER_CATEGORY, FILTER_MENU_PAYMENT_LIST } from "../../constants/Filter"
import WithdrawalsTable, { WITHDRAWALS_SORTED_FIELDS }  from "./tables/WithdrawalsTable"
import useListPage from "../../hooks/useListPage";
import download from "../../assets/images/common/download.svg";
import { useTranslation } from "react-i18next";
import SelectTableView from "../Content/components/SelectTableView";
import { fetchBrandSettingsPage } from "../../store/brand/brandPage/actions";
import settingsWhite from "../../assets/images/layout/settingsWhite.svg";
import Tooltip from "@mui/material/Tooltip";
import clock from "../../assets/images/layout/clock.svg";
import { DIRECTION } from "../../constants/layout";
import clsx from "clsx";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";


function Withdrawals() {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const { currentFilter } = useSelector(state => state.Filter);
	const { isLoading, total, withdrawalList, query, error, isDownloading}  = useSelector(
		state => state.WithdrawalList
	);
	const { state: stateVipBrand} = useVipLevelMapping();
	const { active: activeBrand } = useSelector(state => state.Brand);
	const { data: brandData } = useSelector(state => state.BrandSettingsPage);
	const [selectedRows, setSelectedRows] = useState([]);
	const [_query, _setQuery] = useState(query);
	const [brandCurrencies, setBrandCurrencies] = useState([]);
	const [isShowTableSettings, setShowTableSettings] = useState(false);

	useEffect(() => {
		if (brandData) {
			setBrandCurrencies(brandData?.configurationFinancial.currencies || []);
		}
	}, [brandData]);

	useEffect(() => {
		dispatch(fetchBrandSettingsPage());
	}, [activeBrand]);

	const {pageCount, activePage, handleChangePage} = useListPage(
		query => dispatch(getWithdrawalList(query)),
		total,
		_query,
		error,
		() => dispatch(resetWithdrawalListAlertsErrors()),
		null,
		currentFilter,
	);

	const handleDownload = useCallback(() => {
		const requestQuery = {..._query, offset: 0};

		if (currentFilter) {
			requestQuery.filterDynamic = currentFilter;
		}

		requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();
		dispatch(downloadWithdrawalList(requestQuery))
	}, [_query, currentFilter]);

	useEffect(() => {
		handleChangePage(1);
	}, [_query.limit]);

	const onChangeRecords = (value) => {
		_setQuery((prevValue) => ({
			...prevValue,
			limit: value,
			offset: 0,
		}));
	};

	const handleSortWithdrawals = useCallback((column, direction = DIRECTION.ASC) => {
		_setQuery((prevValue) => {
			const { sort, order, limit, filterDynamic } = prevValue;

			if ((sort !== WITHDRAWALS_SORTED_FIELDS[column]) || (order !== direction)) {
				const query = {
					limit,
					offset: 0,
					sort: WITHDRAWALS_SORTED_FIELDS[column],
					order: direction,
					filterDynamic,
				};

				dispatch(getWithdrawalList(query));
				return query;
			}

			return prevValue;
		});
	}, [query]);

	useEffect(() => {
		_setQuery(query);
	}, [query]);

	return (
		<div className="player-list players-list__mobile document-content page-content">
			<MetaTags>
				<title>{i18n.t('finance.withdrawsList')} - {process.env.REACT_APP_APP_NAME}</title>
			</MetaTags>

			<div className="container-fluid">
				<Row>
					<Col className="col-12">
						<Card className="page-card players-card__mobile players-card">
							<div
								className="page-title-table page-card__header"
							>
								<TableFilter
									filterListOptions={FILTER_MENU_PAYMENT_LIST({
										t,
										brandCurrencies,
										stateVipBrand,
									})}
									isDisabled={isLoading}
									category={FILTER_CATEGORY.WITHDRAWS}
									buttonMode
								/>

								<div className="d-flex align-items-center page-card__settings">
									<button
										className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm ml-5', {
											['d-none']: !withdrawalList?.length
										})}
										onClick={handleDownload}
										disabled={isDownloading}
									>
										<Tooltip title={t('crm.downloadList')} placement="top" arrow>
											<img src={download} alt=""/>
										</Tooltip>
									</button>
									<button
										className="btn btn-rounded btn-primary settings-btn settings-btn__mobile"
										onClick={() => setShowTableSettings(true)}
									>
										<span>
											{i18n.t('crm.settings')}
											<img src={settingsWhite} className='icon-settings' alt="Table's Settings"/>
										</span>
									</button>
								</div>
							</div>

							<WithdrawalsTable
								list={withdrawalList}
								isLoading={isLoading}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								isShowTableSettings={isShowTableSettings}
								onCloseTableSettings={() => setShowTableSettings(false)}
								onSortWithdrawals={handleSortWithdrawals}
							/>

							<div className='mt-3'>
								<SelectTableView
									pageCount={pageCount}
									activePage={activePage}
									recordsCount={total}
									onChangePage={handleChangePage}
									pageSize={_query.limit}
									isSticky={true}
									onChange={onChangeRecords}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default React.memo(Withdrawals);
