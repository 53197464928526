import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ACTION_TYPE } from './automationConstants';
import i18n from '../../../i18n';
import SelectInput from '../../../common/inputs/common/SelectInput';

function ActionSelect({ onChange, value, ...props }) {
    const options = useMemo(() => {
        return Object.keys(ACTION_TYPE).map(key => ({
            value: key,
            label: i18n.t(`workflow.constants.${key}`)
        }));
    }, []);

    return (
        <SelectInput
            {...props}
            placeholder={i18n.t('workflow.selectAction')}
            onChange={onChange}
            value={value}
            options={options}
        />
    );
}

ActionSelect.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(ActionSelect);
