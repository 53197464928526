import './CoolOffTimer.scss';
import moment from 'moment';
import { DATE_TIME_FORMAT } from "../../../../../common/utils/common"
import { useTranslation } from "react-i18next"
import { useTimeContext } from "../../../../../context/TimeContext"

const formatCoolOffEndTime = (coolOfReopenTime) => {
  const reopenDate = moment(coolOfReopenTime);
  return reopenDate.format(DATE_TIME_FORMAT);
};

const CoolOffTimer = (props) => {
  const { coolOfReopenTime } = props;
  const {timeFormat} = useTimeContext();
  const endTime = formatCoolOffEndTime(timeFormat.parseTime(coolOfReopenTime));

  const { t } = useTranslation();

  return (
    <div className='root-timer'>
      {t('crm.endOfCoolOff')}:
      <span className='remaining-time'>{endTime}</span>
    </div>
  );
};

export default CoolOffTimer;
