import React, {forwardRef, useMemo} from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Formatter from '../../utils/Formatter';
import FlagCountry from '../components/FlagCountry';
import IconTooltip from './IconTooltip';
import {arrayOfDisplayedStatuses, PLAYER_STATUSES} from '../constants/common';
import {useTranslation} from "react-i18next";
import useWindowSize from "../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../utils/consts";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";

const genderShortFormat = gender => {
    switch (gender) {
        case 'male':
            return 'M';
        case 'female':
            return 'F';
        default:
            return '';
    }
};

const PlayerData = forwardRef(({ player, hideFlag }, ref) => {
    if(hideFlag === undefined) {
        hideFlag = false;
    }
    const { getVipLevelBrandName, state: vipStateBrand, vipBadgeColors } = useVipLevelMapping();
    const { t } = useTranslation();
    const size = useWindowSize();
    const vipBadgeClass = getVipLevelBrandName(player?.vipLevel)
        ? `player__status player-list-vip-badge`
        : 'player__status';

    const playerSubInfo = useMemo(
        () =>
            (
                <>
                    <span>
                        {Formatter.displayBirthDate(player?.birthDate)}
                        {player?.gender && (
                            <>,&nbsp;{genderShortFormat(player?.gender)}</>
                        )}
                    </span>
                    {vipBadgeColors && vipBadgeColors[player?.vipLevel] && (
                      <span className={vipBadgeClass} style={{ backgroundColor: vipBadgeColors[player.vipLevel] }}>
                        {getVipLevelBrandName(player?.vipLevel) || ""}
                      </span>
                    )}
                    {player?.test && <span className="test-label">test</span>}
                </>
            ),
      [player?.birthDate, player?.gender, player?.vipLevel, t, player?.test, vipStateBrand]
    );

    const playerName = `${player?.firstName} ${player?.lastName}`;
    const displayShortPlayerName = playerName.length >= 45
                                   ? `${playerName.slice(0,43)}...`
                                   : playerName

    const kycStatus = player.KYC
        ? 'Active'
        : 'Closed';


    return (
        <div className="player-data" ref={ref}>
            {!hideFlag && (
            <div
                className="flag-div player-data__flag"
                style={{
                    backgroundColor: `'${player.color}'`,
                    boxShadow: `0px 0px 0px 3px ${player.color}`,
                    borderRadius: '50%'
                }}
            >
                <FlagCountry
                    countryCode={[player?.address?.addressCountryAlfa2, player?.citizenship]}
                    className="flag-wrapper"
                />
            </div>
                )}
            <div className="player-text-data">
                <div className="player-text-data__wrapper">
                    <span className="font-w-500 text-nowrap player-text-data__name">{displayShortPlayerName}</span>
                    {player.KYC && size.width > SCREEN_SIZES.MAX_MOBILE_VIEW && <IconTooltip
                        icon={PLAYER_STATUSES[kycStatus]?.icon}
                        tooltipText={PLAYER_STATUSES[kycStatus]?.text}
                        textColor={PLAYER_STATUSES[kycStatus]?.color}
                    />}
                    {arrayOfDisplayedStatuses.includes(player.status) && <IconTooltip
                        icon={PLAYER_STATUSES[player.status]?.icon}
                        tooltipText={PLAYER_STATUSES[player.status]?.text}
                        textColor={PLAYER_STATUSES[player.status]?.color}
                    />}
                </div>

                <div className="player-text-data__gray">
                {playerSubInfo}
                {player.KYC && size.width <= SCREEN_SIZES.MAX_MOBILE_VIEW && <IconTooltip
                        icon={PLAYER_STATUSES[kycStatus]?.icon}
                        tooltipText={PLAYER_STATUSES[kycStatus]?.text}
                        textColor={PLAYER_STATUSES[kycStatus]?.color}
                    />}
                </div>
            </div>
        </div>
    );
});

PlayerData.propTypes = {
    player: PropTypes.object,
    withTest: PropTypes.bool
};

export default PlayerData;
