const AFFILIATE_PRODUCT = {
  Casino: "Casino",
  Sport: "Sport",
  Bingo: "Bingo"
};

export const PRODUCTS = Object.values(AFFILIATE_PRODUCT);

export const getProductId = (productName) => {
  if (productName === AFFILIATE_PRODUCT.Casino) {
    return 1;
  }

  if (productName === AFFILIATE_PRODUCT.Sport) {
    return 2;
  }

  // productName === AFFILIATE_PRODUCT.Bingo
  return 3;
};

export const getProductName = (productId) => {
  if (productId === 1) {
    return AFFILIATE_PRODUCT.Casino;
  }

  if (productId === 2) {
    return AFFILIATE_PRODUCT.Sport;
  }

  if (productId === 3) {
    return AFFILIATE_PRODUCT.Bingo;
  }

  return null;
};
