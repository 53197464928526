import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import RadioInput from '../../common/inputs/common/RadioInput';
import { STATUS } from '../../constants/status';
import frame_108 from '../../assets/images/common/Frame 108 (2).svg';
import frame_185 from '../../assets/images/common/Frame 185.svg';
import frame_187 from '../../assets/images/common/Frame 187.svg';
import frame_187gray from '../../assets/images/common/Frame 187gray.svg';
import coolOff from '../../assets/images/layout/statuses/cool-off.svg';
import {useTranslation} from "react-i18next";


function StatusRadio({ onChange, value }) {
    const { t } = useTranslation();

    const handleChange = useCallback(nextStatus => onChange(nextStatus), []);

    return (
        <div className="app-status-radio flex-wrap">
            <RadioInput
                checked={value === STATUS.ACTIVE}
                onChange={handleChange}
                value={STATUS.ACTIVE}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_108} className="me-2" alt="" />
                {t(`crm.status.${STATUS.ACTIVE}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.FROZEN}
                onChange={handleChange}
                value={STATUS.FROZEN}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_185} className="me-2" alt="" />
                {t(`crm.status.${STATUS.FROZEN}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.LEAD}
                onChange={handleChange}
                value={STATUS.LEAD}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={coolOff} className="me-2" alt="" />
                {t(`crm.status.${STATUS.LEAD}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.CLOSED}
                onChange={handleChange}
                value={STATUS.CLOSED}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_187} className="me-2" alt="" />
                {t(`crm.status.${STATUS.CLOSED}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.SELF_CLOSED}
                onChange={handleChange}
                value={STATUS.SELF_CLOSED}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_187} className="me-2" alt="" />
                {t(`crm.status.${STATUS.SELF_CLOSED}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.COMPLETE_PROFILE}
                onChange={handleChange}
                value={STATUS.COMPLETE_PROFILE}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={coolOff} className="me-2" alt="" />
                {t(`crm.status.${STATUS.COMPLETE_PROFILE}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.BLOCKED}
                onChange={handleChange}
                value={STATUS.BLOCKED}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_187gray} className="me-2" alt="" />
                {t(`crm.status.${STATUS.BLOCKED}`)}
            </RadioInput>
            <RadioInput
                checked={value === STATUS.SOFT_BLOCK}
                onChange={handleChange}
                value={STATUS.SOFT_BLOCK}
                noSpacesAfterChildren={true}
                noSpacesBeforeChildren={true}
            >
                <img src={frame_185} className="me-2" alt="" />
                {t(`crm.status.${STATUS.SOFT_BLOCK}`)}
            </RadioInput>
        </div>
    );
}

StatusRadio.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default React.memo(StatusRadio);
