import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth,
    showLeftMenu
} from '../../store/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import {DEFAULT_PLAYER_KYC_COUNT_QUERY} from "../../constants/Filter";
import {SHOW_LEFT_MENU} from "../../store/layout/actionTypes";

const Layout = ({
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    changeTopbarTheme,
    children,
    isPreloader,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarThemeImage,
    leftSideBarType,
    location,
    showRightSidebar,
    toggleRightSidebar,
    topbarTheme,
    isLeftMenuShown
}) => {
    const dispatch = useDispatch();
    const { onlinePlayers } = JSON.parse(localStorage.getItem('counters'))
        ? JSON.parse(localStorage.getItem('counters'))
        : [];
    const state = useMemo(
        () => ({
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
        }),
        []
    );

    const toggleMenuCallback = useCallback(() => {
        dispatch(showLeftMenu(!isLeftMenuShown));
    }, [isLeftMenuShown]);


    useEffect(() => {
        let body = document.body;
        if (isLeftMenuShown) {
            body.classList.remove('vertical-collpsed');
            body.classList.remove('sidebar-enable');
        } else {
            body.classList.add('vertical-collpsed');
            body.classList.add('sidebar-enable');
        }
    },[isLeftMenuShown])


    useEffect(() => {
        if (isPreloader) {
            document.getElementById('preloader').style.display = 'block';
            document.getElementById('status').style.display = 'block';

            setTimeout(function () {
                document.getElementById('preloader').style.display = 'none';
                document.getElementById('status').style.display = 'none';
            }, 2500);
        } else {
            document.getElementById('preloader').style.display = 'none';
            document.getElementById('status').style.display = 'none';
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);
        // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

        // document.title =
        //   currentage + " | process.env.REACT_APP_APP_NAME"
        if (leftSideBarTheme) {
            changeSidebarTheme(leftSideBarTheme);
        }
        if (leftSideBarThemeImage) {
            changeSidebarThemeImage(leftSideBarThemeImage);
        }
        if (layoutWidth) {
            changeLayoutWidth(layoutWidth);
        }
        if (leftSideBarType) {
            changeSidebarType(leftSideBarType);
        }
        if (topbarTheme) {
            changeTopbarTheme(topbarTheme);
        }
        if (showRightSidebar) {
            toggleRightSidebar();
        }

        const localSideMenuState = JSON.parse(localStorage.getItem(SHOW_LEFT_MENU) || isLeftMenuShown);
        dispatch(showLeftMenu(localSideMenuState));
    }, []);

    return (
        <>
            <div id="preloader">
                <div id="status"></div>
            </div>

            <div id="layout-wrapper" className={'layout-wrapper'}>
                <Header
                    toggleMenuCallback={toggleMenuCallback}
                    toggleRightSidebar={toggleRightSidebar}
                    activeUsersCount={onlinePlayers}
                />
                <Sidebar
                    isLeftMenuShown={isLeftMenuShown}
                    toggleMenuCallback={toggleMenuCallback}
                    theme={leftSideBarTheme}
                    type={leftSideBarType}
                    isMobile={state.isMobile}
                />
                <div className="main-content">{children}</div>
                {/*<Footer />*/}
            </div>
        </>
    );
};

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarThemeImage: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.any,
    isPreloader: PropTypes.bool,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarThemeImage: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    toggleRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
    isLeftMenuShown: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        ...state.Layout
    };
};

export default connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    toggleRightSidebar,
    changeTopbarTheme,
    changeLayoutWidth
})(withRouter(Layout));
