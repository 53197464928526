import {
    GET_PLAYER_RESTRICTIONS,
    GET_PLAYER_RESTRICTIONS_SUCCESS,
    GET_PLAYER_RESTRICTIONS_FAIL,
    SET_EDIT_PLAYER_RESTRICTIONS,
    RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS,
    UPDATE_PLAYER_RESTRICTIONS,
    UPDATE_PLAYER_RESTRICTIONS_SUCCESS,
    UPDATE_PLAYER_RESTRICTIONS_FAIL
} from './actionTypes';
import i18n from '../../../i18n';

const INIT_STATE = {
    isLoading: false,
    error: null,
    restrictions: null,

    isEdit: false,
    successAlert: ''
};

const PlayerRestrictions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_RESTRICTIONS:
            return { ...state, isLoading: true, error: null };

        case GET_PLAYER_RESTRICTIONS_SUCCESS:
            return { ...state, restrictions: action.payload, isLoading: false };

        case GET_PLAYER_RESTRICTIONS_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case SET_EDIT_PLAYER_RESTRICTIONS:
            return { ...state, isEdit: action.payload };

        case RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS:
            return { ...state, error: null, successAlert: '' };

        case UPDATE_PLAYER_RESTRICTIONS:
            return { ...state, isLoading: true, error: null };

        case UPDATE_PLAYER_RESTRICTIONS_SUCCESS:
            return {
                ...state,
                restrictions: { ...state.restrictions, ...action.payload.dto },
                isLoading: false,
                successAlert: i18n.t('crm.alerts.restrictionsEdited')
            };

        case UPDATE_PLAYER_RESTRICTIONS_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        default:
            return state;
    }
};

export default PlayerRestrictions;
