import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_THEME_IMAGE,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME,
    TOGGLE_RIGHT_SIDEBAR,
    SHOW_RIGHT_SIDEBAR,
    CHANGE_PRELOADER,
    HIDE_RIGHT_SIDEBAR,
    SHOW_LEFT_MENU, SHOW_PLAYER_DROPDOWN
} from './actionTypes';

export const changeLayout = layout => ({
    type: CHANGE_LAYOUT,
    payload: layout
});

export const changePreloader = layout => ({
    type: CHANGE_PRELOADER,
    payload: layout
});

export const changeLayoutWidth = width => ({
    type: CHANGE_LAYOUT_WIDTH,
    payload: width
});

export const changeSidebarTheme = theme => ({
    type: CHANGE_SIDEBAR_THEME,
    payload: theme
});

export const changeSidebarThemeImage = themeimage => ({
    type: CHANGE_SIDEBAR_THEME_IMAGE,
    payload: themeimage
});

export const changeSidebarType = (sidebarType, isMobile) => {
    return {
        type: CHANGE_SIDEBAR_TYPE,
        payload: { sidebarType, isMobile }
    };
};

export const changeTopbarTheme = topbarTheme => ({
    type: CHANGE_TOPBAR_THEME,
    payload: topbarTheme
});

export const toggleRightSidebar = () => ({
    type: TOGGLE_RIGHT_SIDEBAR,
    payload: null
});

export const showRightSidebar = () => ({
    type: SHOW_RIGHT_SIDEBAR,
    payload: null
});

export const hideRightSidebar = () => ({
    type: HIDE_RIGHT_SIDEBAR,
    payload: null
});

export const showLeftMenu = (isShow) => {
    localStorage.setItem(SHOW_LEFT_MENU, isShow);
    return {
        type: SHOW_LEFT_MENU,
        payload: {
            isShow,
        }
    }
};

export const showPlayerDropdown = (isShow) => {
    return {
        type: SHOW_PLAYER_DROPDOWN,
        payload: {
            isShow,
        }
    }
};
