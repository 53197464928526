import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { PASSWORD_REG_EXP } from "../../../constants/validations";
import eye from "../../../assets/images/common/eye.svg";
import closedEye from "../../../assets/images/common/closedEye.svg";
import useAlertService from "../../../hooks/useAlertService";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import {passwordValidate} from "../../../utils/validation/schemas";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function ChangePasswordModal({ onHide, fieldErrors, onConfirm }) {
    useHandleEsc(onHide);
    const [model, setModel] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [edited, setEdited] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });
    const [currentFieldErrors, setCurrentFieldErrors] = useState({});
    const [oldPass, setOldPass] = useState(false);
    const [newPass, setNewPass] = useState(false);
    const [confirmPass, setConfirmPass] = useState(false);

    useEffect(() => {
        setCurrentFieldErrors(fieldErrors);
    }, [fieldErrors]);

    const handleChange = useCallback(field => {
        return value => {
            setModel(prevState => ({
                ...prevState,
                [field]: value
            }));
        };
    }, []);

    const toggleOldPassword = () => {
        setOldPass(!oldPass)
    }
    const toggleNewPassword = () => {
        setNewPass(!newPass)
    }
    const toggleConfirmPassword = () => {
        setConfirmPass(!confirmPass)
    }

    const isNewPasswordCorrect = useMemo(() => {
        if (!edited.newPassword) {
            return false;
        }
        return passwordValidate(model.newPassword).isValid
    }, [edited.newPassword, model.newPassword])

    const isConfPasswordCorrect = useMemo(() => {
        if (!edited.confirmPassword) {
            return false;
        }
        return passwordValidate(model.confirmPassword).isValid
    }, [edited.confirmPassword, model.confirmPassword])

    const handleBlur = useCallback(name => {
        return () => setEdited({
            ...edited,
            [name]: true,
        });
    }, [model]);

    const handleSave = useCallback(() => {
        if (!isNewPasswordCorrect) {
            setCurrentFieldErrors({
                newPassword: 'invalidPass'
            });
            return
        }

        if (model.newPassword !== model.confirmPassword) {
            setCurrentFieldErrors({
                confirmPassword: 'passMismatch'
            });
            return
        }

        onConfirm({
            oldPassword: model.oldPassword,
            newPassword: model.newPassword
        });
    }, [model]);


    const disabledChangeButton = useMemo(() => {
        return !model.oldPassword || !model.newPassword || !model.confirmPassword
    }, [model])

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="change-password-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('agent.changePassword')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                    <div className="mt-3">
                        <ACommonDataField
                            fulWidth={true}
                            component={A_COMMON_DATA_FIELD.PASSWORD}
                            label={`${i18n.t('agent.currentPassword')}*`}
                            value={model['oldPassword']}
                            type={oldPass ? 'text' : 'password'}
                            handleChange={handleChange('oldPassword')}
                            handleBlur={handleBlur('oldPassword')}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleOldPassword}>
                                            <img src={oldPass ? eye : closedEye } alt='eye-icon'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <ACommonDataField
                            fulWidth={true}
                            component={A_COMMON_DATA_FIELD.PASSWORD}
                            label={`${i18n.t('agent.newPassword')}*`}
                            value={model['newPassword']}
                            type={newPass ? 'text' : 'password'}
                            handleChange={handleChange('newPassword')}
                            handleBlur={handleBlur('newPassword')}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleNewPassword}>
                                            <img src={newPass ? eye : closedEye } alt='eye-icon'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!isNewPasswordCorrect && currentFieldErrors.newPassword}
                            helperText={!isNewPasswordCorrect &&
                                        currentFieldErrors.newPassword &&
                                        i18n.t(`errors.${currentFieldErrors.newPassword}`)
                            }
                        />

                        <ACommonDataField
                            fulWidth={true}
                            component={A_COMMON_DATA_FIELD.PASSWORD}
                            label={`${i18n.t('agent.confirmPassword')}*`}
                            value={model['confirmPassword']}
                            type={confirmPass ? 'text' : 'password'}
                            handleChange={handleChange('confirmPassword')}
                            handleBlur={handleBlur('confirmPassword')}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={toggleConfirmPassword}>
                                            <img src={confirmPass ? eye : closedEye } alt='eye-icon'/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!isConfPasswordCorrect && currentFieldErrors.confirmPassword}
                            helperText={!isConfPasswordCorrect &&
                                        currentFieldErrors.confirmPassword &&
                                        i18n.t(`errors.${currentFieldErrors.confirmPassword}`)
                            }
                        />
                    </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={handleSave}
                    disabled={disabledChangeButton}
                >
                    {i18n.t('common.change')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

ChangePasswordModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object
};

export default React.memo(ChangePasswordModal);
