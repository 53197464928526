import { useTimeContext } from "../../../context/TimeContext";
import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";
import {Link, useLocation, withRouter} from "react-router-dom";
import {
    getColumnSettingsLsKey,
    getGuidShortFormat,
    initTableColumns,
    preparePlayerPromoAmount
} from "../../../common/utils/common";
import ClipboardButton from "../../../common/cellData/CopyButton";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import i18n from "../../../i18n";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";
import { SPORT_PROMOTION_TYPE } from "../../Promotions/common/promotionConstants";
import useUserConfig from "../../../hooks/useUserConfig";
import NewCustomTable from "../../../components/NewCustomTable";
import basket from "../../../assets/images/common/basket.svg";
import {getLocalStorageFilter} from "../../../store/filters/reducer";

const COLUMN_IDS = {
    actions: 'actions',
    promotionId: 'promotionId',
    playerPromotionId: 'playerPromotionId',
    templateName: 'templateName',
    type: 'type',
    amount: 'amount',
    balance: 'balance',
    win: 'win',
    status: 'status',
    addedDate: 'addedDate',
    expiryDate: 'expiryDate',
    bonusCompletedDate: 'bonusCompletedDate',
    backOfficeUser: 'backOfficeUser',
};

const getDefaultColumns = () => {
    return [
        COLUMN_IDS.actions,
        COLUMN_IDS.promotionId,
        COLUMN_IDS.playerPromotionId,
        COLUMN_IDS.templateName,
        COLUMN_IDS.type,
        COLUMN_IDS.amount,
        COLUMN_IDS.balance,
        COLUMN_IDS.win,
        COLUMN_IDS.status,
        COLUMN_IDS.addedDate,
        COLUMN_IDS.expiryDate,
        COLUMN_IDS.bonusCompletedDate,
        COLUMN_IDS.backOfficeUser,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.actions]: 'promotions.actions',
    [COLUMN_IDS.promotionId]: 'promotions.promotionId',
    [COLUMN_IDS.playerPromotionId]: 'promotions.playerPromotionId',
    [COLUMN_IDS.templateName]: 'promotions.templateName',
    [COLUMN_IDS.type]: 'promotions.type',
    [COLUMN_IDS.amount]: 'promotions.amount',
    [COLUMN_IDS.balance]: 'promotions.balance',
    [COLUMN_IDS.win]: 'promotions.win',
    [COLUMN_IDS.status]: 'promotions.status',
    [COLUMN_IDS.addedDate]: 'promotions.addedDate',
    [COLUMN_IDS.expiryDate]: 'promotions.expiryDate',
    [COLUMN_IDS.bonusCompletedDate]: 'promotions.bonusCompletedDate',
    [COLUMN_IDS.backOfficeUser]: 'promotions.backOfficeUser',
};

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                    generalCurrency,
                    onCancelBonus,
                }) => {
    const size = useWindowSize();
    const { adjustAmountWithUserCurrency } = useUserConfig();
    const {id, promotion} = item;

    const { amountValue } = preparePlayerPromoAmount(item, generalCurrency);

    switch (field) {
        case COLUMN_IDS.actions:
            return <td className="action-btns">
                <img
                    className="action-btn"
                    src={basket}
                    alt="Basket"
                    onClick={() => onCancelBonus(id)}
                />
            </td>
        case COLUMN_IDS.promotionId:
            return <td className='title__link'>
                <div>
                    <Link
                        to={`/sport-promotions/${promotion?.id}`}
                        className="me-2 app-text-primary"
                    >
                        {getGuidShortFormat(promotion?.id)}
                    </Link>
                    <ClipboardButton text={promotion?.id}/>
                </div>
            </td>
        case COLUMN_IDS.playerPromotionId:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.player.promotionId')}: </span>
                )}
                <div>
                    {getGuidShortFormat(id)}
                    <ClipboardButton text={id} />
                </div>
            </td>
        case COLUMN_IDS.templateName:
            return <td className='title__top'>
                <span className='title__croped'>{promotion?.name}</span>
            </td>
        case COLUMN_IDS.type:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.type')}: </span>
                )}
                {promotion?.settings?.isDeposit
                    ? SPORT_PROMOTION_TYPE.DEPOSIT
                    : SPORT_PROMOTION_TYPE.FREE_BET
                }</td>
        case COLUMN_IDS.amount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.amount')}: </span>
                )}

                {adjustAmountWithUserCurrency(amountValue)}
            </td>
        case COLUMN_IDS.balance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.balance')}: </span>
                )}
                {adjustAmountWithUserCurrency(item?.balance)}
            </td>
        case COLUMN_IDS.win:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.win')}: </span>
                )}

                {adjustAmountWithUserCurrency(item?.win)}
            </td>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.status')}: </span>
                )}
                {i18n.t(`promotion.statuses.${item?.status}`)}
            </td>
        case COLUMN_IDS.addedDate:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.addedDate')}: </span>
                )}
                {item.activationDate
                    ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.activationDate))
                    : '—'
                }
            </td>
        case COLUMN_IDS.expiryDate:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.expiryDate')}: </span>
                )}
                {item.endDate
                    ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.endDate))
                    : '—'
                }
            </td>
        case COLUMN_IDS.bonusCompletedDate:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.bonusCompletedDate')}: </span>
                )}
                {item.result?.time
                    ? Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.result?.time))
                    : '—'
                }
            </td>
        case COLUMN_IDS.backOfficeUser:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.backOfficeUser')}: </span>
                )}
                {item?.backOfficeUser || '—'}
            </td>
        default:
            return null;
    }
};

const SportPromotionsTabTable = (props) => {
    const {
        list,
        isShowTableSettings = false,
        onCloseTableSettings = () => {},
        onCancelBonus,
    } = props;
    const location = useLocation();
    const {timeFormat} = useTimeContext();
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());
    const [activeRow, setActiveRow] = useState(false);
    const filterValues = getLocalStorageFilter();

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({ top: 0 });
    }, 200);

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    if (list?.length === 0 && !filterValues.length) {
        return <div className='noGamePlayData'>
            {i18n.t('tabs.NoActiveSportBonuses')}
        </div>
    }

    return (
        <>
            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                                onCancelBonus={onCancelBonus}
                            />
                        ))}
                    </tr>
                ))}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    )
}

export default memo(withRouter(SportPromotionsTabTable));