import { useTimeContext } from "../../../../context/TimeContext"
import NewCustomTable from "../../../../components/NewCustomTable"
import Formatter from "../../../../utils/Formatter"
import React from "react"
import { useTranslation } from "react-i18next"
import i18n from "../../../../i18n"

const ReferralTable = ({list = []}) => {
  const { t } = useTranslation();
  const { timeFormat } = useTimeContext();

  const columns = [
    { label: t('crm.referredPlayer') },
    { label: t('crm.registrationDate') },
    { label: t('crm.rewards') },
    { label: t('crm.status') },
  ];

  function mapReferralRewards(rewards= {}) {
    if (rewards?.referralReward?.prizes) {
      return rewards.referralReward.prizes.map((prize) => {
        const key = `${prize.coins}-${prize.merch}`;
        return (
          <div key={key}>
            {Object.entries(prize).map(([subKey, value]) => (
              <div key={subKey}>{`${subKey}: ${value}`}</div>
            ))}
          </div>
        );
      });
    }
    return '-';
  }

  return (
    <div>
      {!list || (list.length === 0 &&
        <div className="no-data-game-table">
        {i18n.t("crm.emptyTable")}
        </div>)
      }
      {list && list.length > 0 && (
        <NewCustomTable columns={columns}>
          {list.map((item, index) => (
            <tr key={index}>
              <td>{item.nickName || '-'}</td>
              <td>{item?.date ? Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.date)) : "-"}</td>
              <td>{mapReferralRewards(item?.rewards)}</td>
              <td>{item.status || "-"}</td>
            </tr>
          ))}
        </NewCustomTable>
      )}
    </div>
  );
}

export default ReferralTable;