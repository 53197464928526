import React from 'react';
import PropTypes from 'prop-types';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import close from '../../../assets/images/common/close 1.svg';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';

function ButtonsPanel({ onCancel, onSave, isDisabled, className }) {
    useHandleEsc(onCancel);

    return (
        <div className={`${className} buttons-row`}>
            <button
                className="btn btn-rounded btn-light me-2 mb-lg-2"
                onClick={onCancel}
                disabled={isDisabled}
            >
                <img className="me-2" src={close} alt="" />
                {i18n.t('admin.cancel')}
            </button>

            <button
                className="btn btn-rounded btn-primary mb-lg-2"
                onClick={onSave}
                disabled={isDisabled}
            >
                <img src={i_51} alt="" />
                {i18n.t('admin.save')}
            </button>
        </div>
    );
}

ButtonsPanel.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};

export default React.memo(ButtonsPanel);
