import {
    UPDATE_AGENT_EMPLOYEE,
    GET_AGENT_EMPLOYEE,
    GET_AGENT_EMPLOYEE_SUCCESS,
    GET_AGENT_EMPLOYEE_FAIL,
    SET_EDIT_AGENT_EMPLOYEE,
    UPDATE_AGENT_EMPLOYEE_SUCCESS,
    UPDATE_AGENT_EMPLOYEE_FAIL,
    RESET_AGENT_EMPLOYEE_ALERTS_ERRORS,
    RESET_AGENT_EMPLOYEE
} from './actionTypes';
import i18n from '../../../i18n';

const INIT_STATE = {
    isLoading: false,
    error: null,
    employee: null,
    isEdit: false,
    successAlert: ''
};

const AgentEmployee = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AGENT_EMPLOYEE:
            return { ...state, isLoading: true, error: null };

        case GET_AGENT_EMPLOYEE_SUCCESS:
            return { ...state, employee: action.payload, isLoading: false };

        case GET_AGENT_EMPLOYEE_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case RESET_AGENT_EMPLOYEE:
            return { ...state, employee: null };

        case SET_EDIT_AGENT_EMPLOYEE:
            return { ...state, isEdit: action.payload };

        case UPDATE_AGENT_EMPLOYEE:
            return { ...state, isLoading: true, error: null };

        case UPDATE_AGENT_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: { ...state.employee, ...action.payload.dto },
                isLoading: false,
                successAlert: i18n.t('crm.alerts.agentEdited')
            };

        case UPDATE_AGENT_EMPLOYEE_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case RESET_AGENT_EMPLOYEE_ALERTS_ERRORS:
            return { ...state, error: null, successAlert: '' };

        default:
            return state;
    }
};

export default AgentEmployee;
