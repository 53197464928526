import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../../src/i18n';
import { postSync } from "../../../helpers/api_helper";
import useAlertService from "../../../hooks/useAlertService";
import Formatter from "../../../utils/Formatter";
import { paymentRetail } from "../../../store/players/general/actions";
import { useDispatch } from "react-redux";
import "../styles.scss"
import { Table } from "@mui/material";
import SaveCancelButtons from "../../../components/Buttons/SaveCancelButtons";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function PaymentInfoSummaryModal({setIsShowTransactionInfo, token, type, playerId, setRetailPlayerOid}) {
    const alertService = useAlertService();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const [_playerId, _setPlayerId] = useState();
    const [_method, _setMethod] = useState();
    const [_currency, _setCurrency] = useState();
    const [_playerOId, _setPlayerOId] = useState();
    const [_firstName, _setFirstName] = useState();
    const [_lastName, _setLastName] = useState();
    const [_paymentId, _setPaymentId] = useState();
    const [_taxAmount, _setTaxAmount] = useState();
    const [_totalAmount, _setTotalAmount] = useState();

    useEffect(async () => {
        const url = `/retail/mx/payment-info`;
        try {
            const data = await postSync(url,
                {
                    type,
                    playerId,
                    token,
                }
            );
            setLoading(false);
            _setPlayerId(data?.playerId);
            _setMethod(data?.method);
            _setCurrency(data?.currency);
            _setFirstName(data?.firstName);
            _setPlayerOId(data?.playerOId);
            _setLastName(data?.lastName);
            _setPaymentId(data?.paymentId);
            _setTaxAmount(data?.taxAmount);
            _setTotalAmount(data?.totalAmount);
        } catch (e) {
            alertService.showError('Transaction not found');
            setIsShowTransactionInfo();
        }
    }, [])

    function handleApprove() {
        dispatch(paymentRetail({
            type,
            token,
            playerId,
        }));

        if (_playerOId) {
            setRetailPlayerOid(_playerOId);
        }
        setIsShowTransactionInfo();
    }

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="residential-info-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.confirmTransaction')}
                </h5>
                <button className="close" onClick={() => setIsShowTransactionInfo()}/>
            </div>

            <ModalBody>
        <pre>
            <span className="confirm-popup">
                {_paymentId &&
                    <Table className="table" style={{ display: 'inline-table'}}>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Method
                        </td>
                        <td>
                            {_method}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Player ID
                        </td>
                        <td>
                            {_playerId}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Player OID
                        </td>
                        <td>
                            {_playerOId}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Name
                        </td>
                        <td>
                            {_firstName} {_lastName}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Payment ID
                        </td>
                        <td>
                            {_paymentId}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Amount
                        </td>
                        <td>
                            {Formatter.formatAmount(new Intl.NumberFormat().format(_totalAmount), _currency)}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 500}}>
                            Fee Amount
                        </td>
                        <td>
                            {Formatter.formatAmount(new Intl.NumberFormat().format(_taxAmount), _currency)}
                        </td>
                    </tr>
                </Table>
                }
                {loading && 'Loading...'}
            </span>
        </pre>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <SaveCancelButtons
                    onCancel={() => setIsShowTransactionInfo()}
                    onSave={() => handleApprove()}
                    isModalButtons={true}
                    saveBtnText={i18n.t('admin.approve')}
                    isDisabledCancel={false}
                />
            </ModalFooter>
        </Modal>
    );
}

PaymentInfoSummaryModal.propTypes = {
    setIsShowTransactionInfo: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
};

export default React.memo(PaymentInfoSummaryModal);
