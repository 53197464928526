import {
    GET_AGENT_EMPLOYEE,
    GET_AGENT_EMPLOYEE_SUCCESS,
    GET_AGENT_EMPLOYEE_FAIL,
    SET_EDIT_AGENT_EMPLOYEE,
    UPDATE_AGENT_EMPLOYEE,
    UPDATE_AGENT_EMPLOYEE_SUCCESS,
    UPDATE_AGENT_EMPLOYEE_FAIL,
    RESET_AGENT_EMPLOYEE_ALERTS_ERRORS,
    RESET_AGENT_EMPLOYEE
} from './actionTypes';

export const getAgentEmployee = payload => ({
    type: GET_AGENT_EMPLOYEE,
    payload
});

export const getAgentEmployeeSuccess = payload => ({
    type: GET_AGENT_EMPLOYEE_SUCCESS,
    payload
});

export const getAgentEmployeeFail = error => ({
    type: GET_AGENT_EMPLOYEE_FAIL,
    payload: error
});

export const resetAgentEmployee = payload => ({
    type: RESET_AGENT_EMPLOYEE,
    payload
});

export const setEditAgentEmployee = payload => ({
    type: SET_EDIT_AGENT_EMPLOYEE,
    payload
});

export const updateAgentEmployee = payload => ({
    type: UPDATE_AGENT_EMPLOYEE,
    payload
});

export const updateAgentEmployeeSuccess = payload => ({
    type: UPDATE_AGENT_EMPLOYEE_SUCCESS,
    payload
});

export const updateAgentEmployeeFail = error => ({
    type: UPDATE_AGENT_EMPLOYEE_FAIL,
    payload: error
});

export const resetAgentEmployeeAlertsErrors = payload => ({
    type: RESET_AGENT_EMPLOYEE_ALERTS_ERRORS,
    payload
});
