import {
    API_ERROR,
    LOGIN_USER,
    GOOGLE_AUTH,
    LOGOUT_USER,
    LOGIN_SUCCESS,
    REFRESH_TOKEN,
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_LOADING_PERCENT,
    LOGOUT_USER_SUCCESS,
    REFRESH_TOKEN_SUCCESS
} from './actionTypes';
import { getStorageXSiteId, setXSiteIdToStorages } from "../../../common/utils/common";

const initialState = {
    error: '',
    loading: false,
    isLoadingScreen: false,
    loadingPercent: 0
};

const login = (state = initialState, action) => {
    const agent = action?.payload?.agent;

    switch (action.type) {
        case GOOGLE_AUTH:
            state = {
                ...state,
                loading: true,
                ...(agent && { agent })
            };
            break;
        case LOGIN_USER:
            const remember = action?.payload?.user?.remember;
            localStorage.setItem('rememberMe', remember);
            state = {
                ...state,
                loading: true,
                ...(agent && { agent })
            };
            break;
        case LOGIN_SUCCESS:
            const xSiteId = action?.payload?.sites[0]?.id;
            if (!getStorageXSiteId() && xSiteId) {
                setXSiteIdToStorages(xSiteId);
            }
            state = {
                ...state,
                loading: false,
                ...(agent && { agent })
            };
            break;
        case REFRESH_TOKEN:
            state = { ...state, loading: true, ...(agent && { agent }) };
            break;
        case REFRESH_TOKEN_SUCCESS:
            state = { ...state, loading: false, ...(agent && { agent }) };
            break;
        case LOGOUT_USER:
            state = { ...state, loading: true, ...(agent && { agent }) };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state, loading: false, ...(agent && { agent }), error: null };
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        case SHOW_LOADING_SCREEN:
            state = {
                ...state,
                isLoadingScreen: true,
                ...(agent && { agent })
            };
            break;
        case HIDE_LOADING_SCREEN:
            state = {
                ...state,
                isLoadingScreen: false,
                loadingPercent: 0,
                ...(agent && { agent })
            };
            break;
        case SET_LOADING_PERCENT:
            state = {
                ...state,
                loadingPercent: action.payload,
                ...(agent && { agent })
            };
            break;
        default:
            state = { ...state, ...(agent && { agent }) };
            break;
    }
    return state;
};

export default login;
