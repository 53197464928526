import {
    GET_WITHDRAWAL,
    GET_WITHDRAWAL_SUCCESS,
    GET_WITHDRAWAL_FAIL,
    RESET_WITHDRAWAL_ALERTS_ERRORS
} from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    error: null,
    withdrawal: null
};

const WithdrawalDetailed = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WITHDRAWAL:
            return { ...state, isLoading: true, error: null, withdrawal: null };

        case GET_WITHDRAWAL_SUCCESS:
            return { ...state, withdrawal: action.payload, isLoading: false };

        case GET_WITHDRAWAL_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case RESET_WITHDRAWAL_ALERTS_ERRORS:
            return { ...state, error: null, isLoading: false };
        default:
            return state;
    }
};

export default WithdrawalDetailed;
