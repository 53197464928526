import {
    GET_PLAYER_COMMUNICATION,
    GET_PLAYER_COMMUNICATION_SUCCESS,
    GET_PLAYER_COMMUNICATION_FAIL,
    RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS,
    SET_EDIT_PLAYER_COMMUNICATION,
    UPDATE_PLAYER_COMMUNICATION,
    UPDATE_PLAYER_COMMUNICATION_SUCCESS,
    UPDATE_PLAYER_COMMUNICATION_FAIL,
    GET_PLAYER_COMMUNICATION_PHONE_LIST,
    GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS,
    GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL,
    RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL,
    RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL
} from './actionTypes';

export const getPlayerCommunication = payload => ({
    type: GET_PLAYER_COMMUNICATION,
    payload
});

export const getPlayerCommunicationSuccess = payload => ({
    type: GET_PLAYER_COMMUNICATION_SUCCESS,
    payload
});

export const getPlayerCommunicationFail = error => ({
    type: GET_PLAYER_COMMUNICATION_FAIL,
    payload: error
});

export const resetPlayerCommunicationAlertsErrors = payload => ({
    type: RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS,
    payload
});

export const setEditPlayerCommunication = payload => ({
    type: SET_EDIT_PLAYER_COMMUNICATION,
    payload
});

export const updatePlayerCommunication = payload => ({
    type: UPDATE_PLAYER_COMMUNICATION,
    payload
});

export const updatePlayerCommunicationSuccess = payload => ({
    type: UPDATE_PLAYER_COMMUNICATION_SUCCESS,
    payload
});

export const updatePlayerCommunicationFail = error => ({
    type: UPDATE_PLAYER_COMMUNICATION_FAIL,
    payload: error
});

export const getPlayerCommunicationPhoneList = payload => ({
    type: GET_PLAYER_COMMUNICATION_PHONE_LIST,
    payload
});

export const getPlayerCommunicationPhoneListSuccess = payload => ({
    type: GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS,
    payload
});

export const getPlayerCommunicationPhoneListFail = payload => ({
    type: GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL,
    payload
});

export const resetPlayerCommunicationPhoneListErrors = payload => ({
    type: RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS,
    payload
});

export const getPlayerCommunicationEmailList = payload => ({
    type: GET_PLAYER_COMMUNICATION_EMAIL_LIST,
    payload
});

export const getPlayerCommunicationEmailListSuccess = payload => ({
    type: GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS,
    payload
});

export const getPlayerCommunicationEmailListFail = payload => ({
    type: GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL,
    payload
});

export const resetPlayerCommunicationEmailListErrors = payload => ({
    type: RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS,
    payload
});

export const sendCodePhonePlayerCommunication = payload => ({
    type: SEND_CODE_PHONE_PLAYER_COMMUNICATION,
    payload
});

export const sendCodePhonePlayerCommunicationSuccess = payload => ({
    type: SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS,
    payload
});

export const sendCodePhonePlayerCommunicationFail = error => ({
    type: SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL,
    payload: error
});

export const sendCodeEmailPlayerCommunication = payload => ({
    type: SEND_CODE_EMAIL_PLAYER_COMMUNICATION,
    payload
});

export const sendCodeEmailPlayerCommunicationSuccess = payload => ({
    type: SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS,
    payload
});

export const sendCodeEmailPlayerCommunicationFail = error => ({
    type: SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL,
    payload: error
});
