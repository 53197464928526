import {
    GET_PLAYER_DOCUMENTS,
    GET_PLAYER_DOCUMENTS_FAIL,
    GET_PLAYER_DOCUMENTS_SUCCESS,
    GET_PENDING_DOCUMENTS,
    GET_PENDING_SUCCESS,
    GET_PENDING_FAIL,
    GET_FIRST_PENDING_DOCUMENTS,
    GET_FIRST_PENDING_SUCCESS,
    GET_FIRST_PENDING_FAIL,
    APPROVE_DOCUMENT,
    APPROVE_DOCUMENT_SUCCESS,
    APPROVE_DOCUMENT_FAIL,
    REJECT_DOCUMENT,
    REJECT_DOCUMENT_SUCCESS,
    REJECT_DOCUMENT_FAIL,
    UPLOAD_NEW_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,
    UPLOAD_RETAIL_DOCUMENT,
    UPLOAD_RETAIL_DOCUMENT_SUCCESS,
    UPLOAD_RETAIL_DOCUMENT_FAIL,
    GET_DOCUMENT,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAIL,
    GET_DOCUMENT_DATA,
    GET_DOCUMENT_DATA_SUCCESS,
    GET_DOCUMENT_DATA_FAIL,
    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,
    ADD_DOCUMENT_COMMENTS,
    ADD_DOCUMENT_COMMENTS_SUCCESS,
    ADD_DOCUMENT_COMMENTS_FAIL,
    REMOVE_DOCUMENT_COMMENTS,
    REMOVE_DOCUMENT_COMMENTS_SUCCESS,
    REMOVE_DOCUMENT_COMMENTS_FAIL,
    CLEAR_CURRENT_DOCUMENT_CONTENT,
    CLEAR_DOCUMENTS_ERROR,
    GET_DOCUMENT_HISTORY,
    GET_DOCUMENT_HISTORY_SUCCESS,
    GET_DOCUMENT_HISTORY_FAIL,
    DOWNLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT_SUCCESS,
    DOWNLOAD_DOCUMENT_FAIL,
    SET_OCCUPATION,
    SET_OCCUPATION_SUCCESS,
    SET_OCCUPATION_FAIL,
    GET_BRAND_JURISDICTION,
    GET_BRAND_JURISDICTION_SUCCESS,
    GET_BRAND_JURISDICTION_FAIL,
} from './actionTypes';

export const getPlayerDocuments = playerId => ({
    type: GET_PLAYER_DOCUMENTS,
    payload: playerId
});

export const getPlayerDocumentsSuccess = documents => ({
    type: GET_PLAYER_DOCUMENTS_SUCCESS,
    payload: documents
});

export const getPlayerDocumentsFail = error => ({
    type: GET_PLAYER_DOCUMENTS_FAIL,
    payload: error
});

export const getPendingDocuments = () => ({
    type: GET_PENDING_DOCUMENTS
});

export const getBrandJurisdiction = () => ({
    type: GET_BRAND_JURISDICTION
});

export const getBrandJurisdictionSuccess = payload => ({
    type: GET_BRAND_JURISDICTION_SUCCESS,
    payload
});

export const getBrandJurisdictionFail = payload => ({
    type: GET_BRAND_JURISDICTION_FAIL,
    payload
});

export const getPendingSuccess = documents => ({
    type: GET_PENDING_SUCCESS,
    payload: documents
});

export const getPendingFail = error => ({
    type: GET_FIRST_PENDING_FAIL,
    payload: error
});

export const getFirstPendingDocuments = (query = {}) => ({
    type: GET_FIRST_PENDING_DOCUMENTS,
    payload: query
});

export const getFirstPendingSuccess = documents => ({
    type: GET_FIRST_PENDING_SUCCESS,
    payload: documents
});

export const getFirstPendingFail = error => ({
    type: GET_PENDING_FAIL,
    payload: error
});

export const approveDocument = (documentId, address) => ({
    type: APPROVE_DOCUMENT,
    payload: {documentId, address}
});

export const approveDocumentSuccess = documents => ({
    type: APPROVE_DOCUMENT_SUCCESS,
    payload: documents
});

export const approveDocumentFail = error => ({
    type: APPROVE_DOCUMENT_FAIL,
    payload: error
});

export const rejectDocument = (documentId, data) => ({
    type: REJECT_DOCUMENT,
    payload: { documentId, data }
});

export const rejectDocumentSuccess = id => ({
    type: REJECT_DOCUMENT_SUCCESS,
    payload: id
});

export const rejectDocumentFail = error => ({
    type: REJECT_DOCUMENT_FAIL,
    payload: error
});

export const uploadNewDocument = (oid, documentData, file, alertService) => ({
    type: UPLOAD_NEW_DOCUMENT,
    payload: { oid, documentData, file, alertService }
});

export const uploadDocumentSuccess = documents => ({
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload: documents
});

export const uploadDocumentFail = error => ({
    type: UPLOAD_DOCUMENT_FAIL,
    payload: error
});

export const uploadRetailDocument = (oid, documentData, file, alertService) => ({
    type: UPLOAD_RETAIL_DOCUMENT,
    payload: { oid, documentData, file, alertService }
});

export const uploadRetailDocumentSuccess = documents => ({
    type: UPLOAD_RETAIL_DOCUMENT_SUCCESS,
    payload: documents
});

export const uploadRetailDocumentFail = error => ({
    type: UPLOAD_RETAIL_DOCUMENT_FAIL,
    payload: error
});

export const getDocument = documentId => ({
    type: GET_DOCUMENT,
    payload: documentId
});

export const getDocumentSuccess = documentContent => ({
    type: GET_DOCUMENT_SUCCESS,
    payload: documentContent
});

export const getDocumentFail = error => ({
    type: GET_DOCUMENT_FAIL,
    payload: error
});

export const getDocumentData = documentId => ({
    type: GET_DOCUMENT_DATA,
    payload: documentId
});

export const getDocumentDataSuccess = documentData => ({
    type: GET_DOCUMENT_DATA_SUCCESS,
    payload: documentData
});

export const getDocumentDataFail = error => ({
    type: GET_DOCUMENT_DATA_FAIL,
    payload: error
});

export const updateDocument = document => ({
    type: UPDATE_DOCUMENT,
    payload: document
});

export const updateDocumentSuccess = documents => ({
    type: UPDATE_DOCUMENT_SUCCESS,
    payload: documents
});

export const updateDocumentFail = error => ({
    type: UPDATE_DOCUMENT_FAIL,
    payload: error
});

export const addDocumentComment = ({ document, comment, writer, agentId }) => ({
    type: ADD_DOCUMENT_COMMENTS,
    payload: { document, comment, writer, agentId }
});

export const addDocumentCommentSuccess = () => ({
    type: ADD_DOCUMENT_COMMENTS_SUCCESS
});

export const addDocumentCommentFail = error => ({
    type: ADD_DOCUMENT_COMMENTS_FAIL,
    payload: error
});

export const removeDocumentComment = ({ document, commentData, writer, agentId }) => ({
    type: REMOVE_DOCUMENT_COMMENTS,
    payload: { document, commentData, writer, agentId }
});

export const removeDocumentCommentSuccess = () => ({
    type: REMOVE_DOCUMENT_COMMENTS_SUCCESS
});

export const removeDocumentCommentFail = error => ({
    type: REMOVE_DOCUMENT_COMMENTS_FAIL,
    payload: error
});

export const clearCurrentDocumentContent = error => ({
    type: CLEAR_CURRENT_DOCUMENT_CONTENT
});

export const clearDocumentsError = () => ({
    type: CLEAR_DOCUMENTS_ERROR
});

export const getDocumentHistory = payload => ({
    type: GET_DOCUMENT_HISTORY,
    payload
});

export const getDocumentHistorySuccess = payload => ({
    type: GET_DOCUMENT_HISTORY_SUCCESS,
    payload
});

export const getDocumentHistoryFail = payload => ({
    type: GET_DOCUMENT_HISTORY_FAIL,
    payload
});

export const downloadDocument = payload => ({
    type: DOWNLOAD_DOCUMENT,
    payload
});

export const downloadDocumentSuccess = payload => ({
    type: DOWNLOAD_DOCUMENT_SUCCESS,
    payload
});

export const downloadDocumentFail = payload => ({
    type: DOWNLOAD_DOCUMENT_FAIL,
    payload
});

export const setOccupation = payload => ({
    type: SET_OCCUPATION,
    payload
});

export const setOccupationSuccess = payload => ({
    type: SET_OCCUPATION_SUCCESS,
    payload
});

export const setOccupationFail = payload => ({
    type: SET_OCCUPATION_FAIL,
    payload
});

