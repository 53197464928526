import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import { CHECKBOX_TYPE_KEYS } from '../../../constants/Filter';

import i18n from '../../../i18n';

import { CommonFilter } from './CommonFilterPopup';
import CheckboxInput from '../../../common/inputs/CheckboxInput';
import useHandleEnter from '../../../common/hooks/useHandleEnter';

const initCheckboxState = (initialValue) => {
    const checkboxState = {};
    if (Array.isArray(initialValue)) {
        initialValue.forEach((point) => checkboxState[point] = true);
    }
    else if (['string', 'number'].includes(typeof initialValue)) {
        checkboxState[initialValue] = true;
    }
    return checkboxState;
}

export const FilterCheckbox = ({ activePopup, onSelect, onCancel }) => {
    const [checkboxState, setCheckboxState] = useState(initCheckboxState(activePopup?.initialValue));

    const isEnabled = useMemo(
        () =>
            !!Object.keys(checkboxState).filter(item => checkboxState[item])
                .length,
        [activePopup.initialValue, checkboxState]
    );

    const handleChange = useCallback(
        key => value => {
            if (key === CHECKBOX_TYPE_KEYS.ALL) {
                const newCheckboxState = activePopup.list.reduce(
                    (acc, { key }) => ({
                        ...acc,
                        [key]: value
                    }),
                    {}
                );
                setCheckboxState({ ...newCheckboxState, [key]: value });
            } else {
                setCheckboxState(prev => ({
                    ...prev,
                    [key]: value,
                }));
            }
        },
        [activePopup.list]
    );

    const onSave = useCallback(() => {
        let state = Object.keys(checkboxState)
            .filter(
                item => item !== CHECKBOX_TYPE_KEYS.ALL && checkboxState[item]
            )
            .map(item => ({ key: activePopup.key, value: item }));
        // .reduce((acc, cur) => {
        //   acc.value = acc.value === '' ? cur : `${acc.value},${cur}`
        //   return acc;
        // }, { key: activePopup.key, value: '' });
        onSelect(state);

        // onSelect([state]);
    }, [checkboxState]);

    useHandleEnter(onSave);

    return (
        <CommonFilter
            title={activePopup.title}
            className={'filter-player_type'}
            onCancel={onCancel}
        >
            <Row
                className={'filter-player_type-row filter-player_type-all'}
            >
                <Col>
                    <CheckboxInput
                        checked={checkboxState[CHECKBOX_TYPE_KEYS.ALL]}
                        onChange={handleChange(CHECKBOX_TYPE_KEYS.ALL)}
                    >
                        {i18n.t(`filter.constant.${CHECKBOX_TYPE_KEYS.ALL}`)}
                    </CheckboxInput>
                </Col>
            </Row>

            {activePopup.list?.map(
                ({ label, isDisabled = false, key }, index) => (
                    <Row key={index} className={'filter-player_type-row'}>
                        <Col>
                            <CheckboxInput
                                checked={checkboxState[key]}
                                onChange={handleChange(key)}
                                disabled={isDisabled}
                            >
                                {label}
                            </CheckboxInput>
                        </Col>
                    </Row>
                )
            )}

            <Button color={'primary'} disabled={!isEnabled} onClick={onSave}>
                {i18n.t('filter.apply')}
            </Button>
        </CommonFilter>
    );
};

FilterCheckbox.propTypes = {
    activePopup: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
