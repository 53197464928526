import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import { CommonFilter } from './CommonFilterPopup';
import { FILTER_SIMPLE_BOOL_COMMON_OPTIONS } from '../../../constants/Filter';

export const FilterSimple = ({
    title,
    onSelect,
    list = FILTER_SIMPLE_BOOL_COMMON_OPTIONS,
    popupKey,
    onCancel,
    initialValue,
}) => {
    const [active, setActive] = useState({ value: initialValue });

    const handleClick = useCallback(
        option => () => {
            setActive(option.value);
            onSelect([{ key: popupKey, value: option.value }]);
        },
        [setActive]
    );

    return (
        <CommonFilter
            title={title}
            className={'filter-kyc'}
            onCancel={onCancel}
        >
            <ul className={'filter-kyc_list'}>
                {list.map((item, index) => (
                    <li
                        key={index}
                        onClick={handleClick(item)}
                        className={`filter-kyc_list-element${
                            active.value === item.value ? ' active' : ''
                        }`}
                    >
                        {i18n.t(item.label)}
                    </li>
                ))}
            </ul>
        </CommonFilter>
    );
};

FilterSimple.proptypes = {
    list: PropTypes.array,
    title: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    popupKey: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired
};
