import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from '../components/LoadingScreen';
import { RefreshWrapper } from '../components/RefreshWrapper';
import { authProtectedRoutes } from './index';
import useAlertService from '../hooks/useAlertService';
import { getRootUrlToRedirect, reloadAccessToken } from '../helpers/api_helper';
import VerticalLayout from '../components/VerticalLayout/';
import { SchedulerWrapper } from '../components/SchedulerWrapper';
import { clearCurrentFilter } from "../store/filters/action";
import {checkSessionLifetime, isLoggedIn, isRememberUser, isSessionExpired} from "../helpers/session";
import {throttle} from "lodash";
import {logoutUser} from "../store/auth/login/actions";
import {fetchVipLevelConfig} from "../store/brand/brandPage/actions";

let sessionCheckInterval;
let sessionRefreshHandler;


const ProtectedRoutes = () => {
    const dispatch = useDispatch();
    const { isLoadingScreen, error } = useSelector(state => state.Login);

    const { showError } = useAlertService();

    const startHandlePlayerActivity = () => {
        document.addEventListener("mousemove", sessionRefreshHandler);
        document.addEventListener("keydown", sessionRefreshHandler);
    }

    const stopHandlePlayerActivity = () => {
        document.removeEventListener("mousemove", sessionRefreshHandler);
        document.removeEventListener("keydown", sessionRefreshHandler);
        if (sessionRefreshHandler) {
            sessionRefreshHandler.cancel();
            sessionRefreshHandler = undefined;
        }
    }

    const checkSession = (isSessionExpired, isLoggedIn, isRememberUser) => {
        if (isSessionExpired) {
            stopHandlePlayerActivity();
            window.location = '/login';
        } else if (isLoggedIn && !isRememberUser) {
            startHandlePlayerActivity();
        } else {
            stopHandlePlayerActivity();
        }
    }

    if (!sessionRefreshHandler && isSessionExpired()) {
        sessionRefreshHandler = throttle(() => {
            reloadAccessToken()
        }, 60 * 1000); // 1 minute
    }

    useEffect(() => {
        checkSession(isSessionExpired(), isLoggedIn(), isRememberUser());
    }, [isLoggedIn(), isSessionExpired(), isRememberUser()]);

    useEffect(() => {
        if (!sessionCheckInterval && isLoggedIn() && !isRememberUser()) {
            sessionCheckInterval = setInterval(() => checkSession(isSessionExpired(),
                isLoggedIn(),
                isRememberUser()), 1000);
        }
    }, [isLoggedIn(), isSessionExpired(), isRememberUser()]);


    // TODO: Need to implement to check userAuth status on the server
    useEffect(() => {
        const authUserString = localStorage.getItem('authUser');

        if (authUserString) return;

        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
            reloadAccessToken();
        } else {
            // saved current url to redirect_url
            window.location = `/login${
                window.location.pathname !== '/'
                    ? `?redirect_url=${encodeURI(getRootUrlToRedirect())}`
                    : ''
            }`;
            dispatch(clearCurrentFilter());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchVipLevelConfig())
    }, [])

    useEffect(() => {
        if (error) {
            showError(error);
        }
    }, [error]);

    return (
        <RefreshWrapper>
            <SchedulerWrapper>
                <Route
                    path="/"
                    render={props => (
                        <>
                            {isLoadingScreen ? (
                                <LoadingScreen />
                            ) : (
                                <VerticalLayout>
                                    <Switch>
                                        {authProtectedRoutes.map(
                                            (route, idx) => (
                                                <Route
                                                    exact={true}
                                                    key={route.path}
                                                    path={route.path}
                                                    component={route.component}
                                                />
                                            )
                                        )}
                                    </Switch>
                                </VerticalLayout>
                            )}
                        </>
                    )}
                />
            </SchedulerWrapper>
        </RefreshWrapper>
    );
};

export default ProtectedRoutes;
