import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../../i18n';
import RadioInput from '../../../../common/inputs/common/RadioInput';
import { A_COMMON_DATA_FIELD } from '../../../../constants/aCommonDataField';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';
import {
    MANUAL_ADJUSTMENTS_COMMENTS,
    MANUAL_ADJUSTMENTS_COMMENTS_FINANCIAL_TYPES,
    MANUAL_ADJUSTMENTS_TYPE
} from '../../../../constants/manualAdjustment';
import './ManualAdjustment.scss';

import {prepareManualAdjustmentDto, prepareManualAdjustmentModel} from '../model/playerEditModel';
import { getType } from "../../../Admin/site/dataAdapters";
import { postSync } from "../../../../helpers/api_helper";
import SelectInput from "../../../../common/inputs/common/SelectInput";
import IconTooltip from "../../../../common/cellData/IconTooltip";
import Formatter from "../../../../utils/Formatter";
import ConfirmPopupModal from "../../../../utils/ConfirmPopup/ConfirmPopupModal";
import {isBoolean} from "lodash";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';
import { useSelector } from "react-redux"
import { SITE_BRAND } from "../../../../constants/siteBrands"

export const maxAmountDepositOrWithdraw = 1000000
export const minAmountDepositOrWithdraw = 0

const getComments = (is21Dealer = false) => {
    const specificReasons = ['ManualDeposit', 'ManualWithdrawal'];

    return MANUAL_ADJUSTMENTS_COMMENTS
      .filter(item => !is21Dealer || specificReasons.includes(item.reason))
      .map(item => ({
          value: item.reason,
          content: i18n.t(`crm.manualAbjastment.comment.${item.reason}`)
      }));
};

const getFormScheme = (comment, amountValue, commentType, promoCode, is21Dealer = false) => {
    return [
        {
            stateKey: 'amount',
            label: i18n.t('crm.amount'),
            component: A_COMMON_DATA_FIELD.NUMBER,
            value: amountValue || '',
            min: minAmountDepositOrWithdraw,
            max: maxAmountDepositOrWithdraw
        },
        {
            stateKey: 'commentType',
            component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
            options: getComments(is21Dealer),
            label: i18n.t('crm.type'),
            value: getType(commentType) === "object" ? commentType?.value : commentType,
        },
        {
            stateKey: 'comment',
            value: comment,
            component: A_COMMON_DATA_FIELD.TEXT,
            label: i18n.t('crm.comment'),
            multiline: 3,
        },
        {
            stateKey: 'promoCode',
            value: promoCode,
            label: i18n.t('crm.manual_adjustment.promo'),
        }
    ];
};

function ManualAdjustmentModal({
                                   onHide,
                                   onConfirm,
                                   fieldErrors,
                                   isLoading,
                                   playerId,
                               }) {
    const { active: activeBrand } = useSelector(state => state.Brand);

    const [walletsLists, setWalletsLists] = useState([]);
    const [selectWalletId, setSelectWalletId] = useState();
    const [selectWallet, setSelectWallet] = useState();
    const [showWarning, setShowWarning] = useState(false);

    const is21Dealer = useMemo(() => {
        return activeBrand?.siteName === SITE_BRAND.Dealer21;
    }, [activeBrand?.siteName])

    useEffect(async () => {

        const url = `/wallet/manual/wallets`;
        const response = await postSync(url, {playerId: playerId});
        setWalletsLists(response.map((w) => {
            return {
                ...w,
                label: `Real - ${w.currency}`,
                value: w.id
            }
        }));
        setSelectWalletId(response[0].id);
        setSelectWallet(response[0]);
    }, [])

    const [model, setModel] = useState(
        prepareManualAdjustmentModel(selectWalletId, playerId, getComments(is21Dealer)[0])
    );

    const handleChange = useCallback(name => {
        return value => {
            setModel(prevState => ({
                ...prevState,
                [name]: name === 'amount' ? Formatter.formatDecimalNumber(value) : value
            }));
        };
    }, []);

    const warningPopupConfirm = () => {
        setShowWarning(false);
        handleSave(true);
    };

    const warningPopupCancel = () => {
        setShowWarning(false);
    };

    const modelType = model?.commentType?.value || model?.commentType || '';

    const handleSave = useCallback((confirmWarning = false) => {
        confirmWarning = isBoolean(confirmWarning) ? confirmWarning : false;
        if(!confirmWarning && MANUAL_ADJUSTMENTS_COMMENTS_FINANCIAL_TYPES.includes(modelType)) {
            setShowWarning(true);
            return;
        }

        const dto = prepareManualAdjustmentDto(selectWalletId, model);
        onConfirm(dto);
    }, [model]);

    const scheme = useMemo(
        () => getFormScheme(model.comment, model.amount, model.commentType, model.promoCode, is21Dealer),
        [model]
    );

    const isSubmitButtonDisabled = () => {
        if(MANUAL_ADJUSTMENTS_COMMENTS_FINANCIAL_TYPES.includes(modelType)) {
            return (!model.amount || !modelType) || isLoading
        }
        return (!model.amount || !model.type || !modelType) || isLoading
    };

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="manual-adjustment-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.manualAdjustment')}
                </h5>
                <button className="close" onClick={onHide}/>
            </div>

            <ModalBody>
                <SelectInput
                    className={'selectPopup'}
                    onChange={(e) => {
                        const wallet = walletsLists.find((w) => w.value === e);
                        setSelectWalletId(wallet.value);
                        setSelectWallet(wallet);
                    }}
                    value={selectWalletId}
                    options={walletsLists}
                />
                <div className='manual-adjustment__balance' style={{paddingTop: "10px"}}>
                    <IconTooltip
                        tooltipText={`id: ${selectWallet?.id}`}
                        textColor={"#000000"}
                    />
                    <p style={{fontSize: "12px"}}>Balance {Formatter.formatAmount(selectWallet?.balance, selectWallet?.currency)}</p>
                </div>

                <hr/>
                {scheme.map(item => {
                    const isTypeComponent = item.label === 'Type';
                    const description = MANUAL_ADJUSTMENTS_COMMENTS.find(
                        comment => comment.reason === modelType
                    )?.description;
                    if(item.stateKey === 'promoCode' && modelType !== 'ManualDeposit') {
                        return;
                    }

                    return (
                        <div key={item.stateKey} className={item.stateKey === 'promoCode' ? '' : "mt-3 manual-adjustment__textarea"}>
                            <ACommonDataField
                                error={fieldErrors?.[item.stateKey]}
                                fulWidth={true}
                                component={item.component}
                                label={item.label}
                                value={item.value}
                                handleChange={handleChange(item.stateKey)}
                                options={item.options}
                                rows={item.rows}
                                multiline={item.multiline}
                                inputProps={{min: item?.min, max: item?.max}}
                                min={item?.min}
                                max={item?.max}
                            />
                            {isTypeComponent && (
                                <>
                                    <p style={{fontSize: "12px"}}>{description || ''}</p>
                                    {modelType && !MANUAL_ADJUSTMENTS_COMMENTS_FINANCIAL_TYPES.includes(modelType) && (
                                        <div className={`d-flex mb-4 ${fieldErrors?.type ? 'has-error' : ''}`}>
                                            <RadioInput
                                                checked={model.type === MANUAL_ADJUSTMENTS_TYPE.Debit}
                                                onChange={handleChange('type')}
                                                className="me-3"
                                                value={MANUAL_ADJUSTMENTS_TYPE.Debit}
                                            >
                                                {i18n.t('crm.debit')}
                                            </RadioInput>
                                            <RadioInput
                                                checked={model.type === MANUAL_ADJUSTMENTS_TYPE.Credit}
                                                onChange={handleChange('type')}
                                                className="me-3"
                                                value={MANUAL_ADJUSTMENTS_TYPE.Credit}
                                            >
                                                {i18n.t('crm.credit')}
                                            </RadioInput>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )
                })}
                {showWarning &&
                    <ConfirmPopupModal
                        title={modelType === 'ManualWithdrawal' ? 'finance.withdraw.confirm.title' : 'finance.deposit.confirm.title'}
                        content={modelType === 'ManualWithdrawal' ? 'finance.withdraw.confirm.content' : 'finance.deposit.confirm.content'}
                        onCancel={warningPopupCancel}
                        onConfirm={warningPopupConfirm}
                    />
                }
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={handleSave}
                    disabled={(isSubmitButtonDisabled())}
                >
                    {i18n.t('crm.submit')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

ManualAdjustmentModal.propTypes = {
    playerId: PropTypes.any.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    isLoading: PropTypes.bool
};

export default React.memo(ManualAdjustmentModal);
