import {useDispatch, useSelector} from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {getGameProviders} from "../store/games/actions";


export default function useProviders(isPromotionPage = false) {
    const { gameProviders } = useSelector(state => state.Games);
    const { data: brandsData } = useSelector(state => state.Brand);

    const [providers, setProviders] = useState([]);

    useEffect(() => {
        if (gameProviders && brandsData) {
            const brandNames = {};
            brandsData.forEach((brand) => brandNames[brand.id] = brand.siteName);

            const _providers = [];
            gameProviders.forEach((provider) => {
                if (!_providers.find((currProvider) => currProvider.site === provider.site)) {
                    _providers.push({
                        value: provider.contentProvider,
                        content: provider.name,
                        siteId: provider.site,
                    })
                }
            });
            setProviders(_providers);
        }
    }, [brandsData, gameProviders]);

    const getProviderNameById = useCallback((providerId) => {
        const provider = providers.find((p) => p.value === providerId);
        return provider ? provider.content : '';
    }, [providers])

    return {
        providers,
        getProviderNameById,
    }
}
