export const LIMITS = {
    minContent: 5,
    minSubject: 5,
    maxContent: 800,
    maxSubject: 100
};

export const NOTES_FIELD_KEY_NAMES = {
    SUBJECT: 'subject',
    CONTENT: 'content',
    ASSIGNEE_AGENT_OID: 'assigneeAgentOid',
    TAG: 'tag',
};
