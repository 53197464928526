import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//i18n
import { useTranslation } from 'react-i18next';

import SidebarContent from './SidebarContent';
import SidebarBrand from './SidebarBrand';

import '../../assets/scss/components/layout.scss';
import _ from 'lodash';
import { getType } from '../../pages/Admin/site/dataAdapters';
import MENU_POINTS from "./menuPoints";

const ToggleIcon = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0.146618 0.146627C-0.0488705 0.342115 -0.0488705 0.658116 0.146617 0.853604L5.29301 6.00002L0.146616 11.1464C-0.0488719 11.3419 -0.0488719 11.6579 0.146616 11.8534C0.342104 12.0489 0.658105 12.0489 0.853593 11.8534L6.35348 6.3535C6.45098 6.256 6.49999 6.12801 6.49999 5.99999C6.49999 5.87198 6.45098 5.74399 6.35348 5.64649L0.853595 0.146604C0.658107 -0.0488605 0.342106 -0.0488605 0.146618 0.146627Z" />
        </svg>
    );
};

const Sidebar = ({ toggleMenuCallback, isLeftMenuShown }) => {
    const { t, i18n } = useTranslation();
    const { counters } = useSelector(state => state.Info);
    const [menuPoints, setMenuPoints] = useState([]);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const { active: activeBrand } = useSelector(state => state.Brand);
    useEffect(() => {
        if(!agentSelf?.permissions) {
            return;
        }

        const __menuPoints = _.cloneDeep(MENU_POINTS(t, activeBrand));

        const agentPermissions = agentSelf.permissions;
        let _menuPoints = __menuPoints
            .filter((mt) => !('permissions' in mt) || agentPermissions.some(r=> mt.permissions.includes(r)))
            .map((mt) => {
                let res = mt.items.map((mi) => {
                    if (!mi?.permissions) {
                        return mi
                    }
                    const found = agentPermissions.some(r=> mi.permissions.includes(r));
                    if (found) {
                        return mi;
                    } else {
                        return undefined;
                    }
                });
                res = res.filter((v) => v);
                if (res.length === 0) {
                    return;
                }
                return {
                    menuTitle: mt.menuTitle,
                    items: res,
                };
            });

        _menuPoints = _menuPoints.filter((v) => v);
        _menuPoints = _menuPoints.filter((el) => el.length !== 0);

        setMenuPoints(_menuPoints);
    }, [agentSelf?.permissions, activeBrand, i18n.language])

    useEffect(() => {
        const localCounters = JSON.parse(localStorage.getItem('counters'));
        if (!!localCounters) {
            const newMenuPoints = menuPoints.map(point => ({
                ...point,
                items: point.items.map(item =>
                    item.key !== 'onlinePlayers'
                        ? getType(localCounters[item.key]) === 'number'
                            ? { ...item, count: localCounters[item.key] }
                            : item
                        : item
                )
            }));
            setMenuPoints(newMenuPoints);
        }
    }, [counters]);

    const sidebarContent = useMemo(() => {
        if(menuPoints.length > 0) {
            return (
                <SidebarContent agentSelf={agentSelf} menuPoints={menuPoints} isLeftMenuShown={isLeftMenuShown}
                                closeMenu={toggleMenuCallback}/>
            )
        } else {
            return null;
        }
    }, [activeBrand, menuPoints, isLeftMenuShown]);

    return (
        <div className="vertical-menu">
            <div
                className={'vertical-menu__toggler'}
                onClick={toggleMenuCallback}
            >
                <ToggleIcon
                    className={`vertical-menu__toggler-arrow${
                        isLeftMenuShown ? ' open' : ''
                    }`}
                />
            </div>
            <SidebarBrand isLeftMenuShown={isLeftMenuShown} />
            <div data-simplebar className="h-100 vertical-menu__body">
                {sidebarContent}
            </div>
            <div className="sidebar-background"> </div>
        </div>
    );
};

Sidebar.propTypes = {
    type: PropTypes.string,
    toggleMenuCallback: PropTypes.func
};

export default React.memo(Sidebar);
