import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import i18n from '../../../i18n';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import { makeJSONStringValid } from "../../../common/utils/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function ProviderResponseModal({ providerResponse, onHide, paymentCardResponse }) {
    useHandleEsc(onHide);

    let formattedProviderResponse;
    let formattedPaymentCardResponse;

    try {
        formattedProviderResponse = JSON.stringify(
            typeof providerResponse === 'string' ? JSON.parse(providerResponse) : providerResponse,
            null,
            2
        );
        formattedPaymentCardResponse = JSON.stringify(
            typeof paymentCardResponse === 'string' ? JSON.parse(paymentCardResponse) : paymentCardResponse,
            null,
            2
        );
    } catch (error) {
        formattedProviderResponse = providerResponse
        formattedPaymentCardResponse = paymentCardResponse
    }

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="provider-response-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('finance.providerResponse')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <pre className="provider-response-container">
                    {formattedProviderResponse} <br/>
                    {formattedPaymentCardResponse?.length && (
                        `PaymentCard: ${formattedPaymentCardResponse}`
                    )}
                </pre>
            </ModalBody>
        </Modal>
    );
}


ProviderResponseModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    providerResponse: PropTypes.object.isRequired
};

export default React.memo(ProviderResponseModal);
