import {
    GET_PLAYER_ATTRIBUTION_LIST,
    GET_PLAYER_ATTRIBUTION_LIST_SUCCESS,
    GET_PLAYER_ATTRIBUTION_LIST_FAIL,
    RESET_PLAYER_ATTRIBUTION_LIST_ERRORS
} from './actionTypes';

export const getPlayerAttributionList = payload => ({
    type: GET_PLAYER_ATTRIBUTION_LIST,
    payload
});

export const getPlayerAttributionListSuccess = payload => ({
    type: GET_PLAYER_ATTRIBUTION_LIST_SUCCESS,
    payload
});

export const getPlayerAttributionListFail = payload => ({
    type: GET_PLAYER_ATTRIBUTION_LIST_FAIL,
    payload
});

export const resetPlayerAttributionListErrors = payload => ({
    type: RESET_PLAYER_ATTRIBUTION_LIST_ERRORS,
    payload
});
