import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card } from "reactstrap"
import MetaTags from 'react-meta-tags'
import "./datatables.scss"
import "./styles.scss"
import "../../assets/scss/pages/common.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  downloadPlayerList,
  getPlayerList,
  resetPlayerListAlertsErrors,
  resetPlayerQuery
} from "../../store/actions"
import { getPromotions } from "../../store/promotions/actions";
import TableFilter from "../../components/TableFilter";
import {
  FILTER_CATEGORY,
  FILTER_MENU_PLAYER_LIST,
  FILTER_MENU_SELECT_LIST_VALUES,
  FILTER_SIMPLE_BOOL_COMMON_VALUES
} from "../../constants/Filter";
import PlayersTable, { PLAYER_LIST_SORTED_FIELDS } from "./tables/PlayersTable"
import PlayerListMassUpdateModal from "./modal/PlayerListMassUpdateModal"
import PlayerListMassCashbackApplyModal from "./modal/PlayerListMassCashbackApplyModal"
import useToggleModal from "../../hooks/useToggleModal"
import useListPage from "../../hooks/useListPage";
import useWindowSize from "../../hooks/useWindowSize";
import { DIRECTION } from "../../constants/layout"
import { useTranslation } from "react-i18next";
import { SITE_PERMISSION } from "../../common/constants/common";
import SelectTableView from "../Content/components/SelectTableView";
import { addCurrentFilter, removeCurrentFilters } from "../../store/filters/action";
import useAlertService from "../../hooks/useAlertService";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { handleChangePath } from "../../common/utils/common";
import { SCREEN_SIZES } from "../../utils/consts";
import {fetchVipLevelConfig, fetchBrandSettingsPage} from "../../store/brand/brandPage/actions";
import settingsWhite from "../../assets/images/layout/settingsWhite.svg";
import MassAdjustmentModal from "./modal/MassAdjustmentModal";
import clsx from "clsx";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";


function PlayerList(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const alertService = useAlertService();
  const size = useWindowSize();

  const { currentFilter } = useSelector(state => state.Filter);
  const { agentSelf } = useSelector(state => state.AgentSelf);
  const { active: activeBrand } = useSelector(state => state.Brand);
  const { data: brandData } = useSelector(state => state.BrandSettingsPage);
  const { state: stateVipBrand} = useVipLevelMapping();
  const agentPermissions = agentSelf?.permissions;
  const { isLoading, total, playerList, invalidIds, query, error, successAlert } =
      useSelector(state => state.PlayerList);

  const [selectedRows, setSelectedRows] = useState([]);
  const [_query, _setQuery] = useState(query);
  const [brandCurrencies, setBrandCurrencies] = useState([]);
  const [isShowTableSettings, setShowTableSettings] = useState(false);
  const [isShowMassUpdate, onShowMassUpdate, onHideMassUpdate] =
      useToggleModal(false);
  const [isShowMassCashbackApply, onShowMassCashbackApply, onHideMassCashbackApply] =
      useToggleModal(false);

  const currentFilterRef = useRef();
  const currentLoadingRef = useRef();

  const canBulkCashbackApplyPermission = useMemo(() => {
    return agentPermissions?.includes(SITE_PERMISSION.Transactions__Bulk_Player_Bonus_Update);
  }, [agentSelf]);

  const applyInactivityFeePerm = agentSelf?.permissions?.includes(SITE_PERMISSION.Transactions__Bulk_Transfer);

  useEffect(() => {
    if (brandData) {
      setBrandCurrencies(brandData?.configurationFinancial.currencies || []);
    }
  }, [brandData]);

  useEffect(() => {
    handleChangePath(props, dispatch);
  }, []);

  useEffect(() => {
    dispatch(fetchBrandSettingsPage());
    dispatch(fetchVipLevelConfig());
  }, [activeBrand]);

  useEffect(() => {
      dispatch(addCurrentFilter(
          [
            {
              key: FILTER_MENU_SELECT_LIST_VALUES.TEST,
              value: FILTER_SIMPLE_BOOL_COMMON_VALUES.NO
            }
          ]
      ))
  }, [activeBrand?.siteName])

  useEffect(() => {
    dispatch(getPromotions({
      sort: 'created_at',
      order: 'DESC',
      limit: 1000
    }));
    return () => dispatch(resetPlayerQuery())
  }, []);

  useEffect(() => {
    if (invalidIds?.length > 0) {
      dispatch(removeCurrentFilters('id', invalidIds));

      alertService.showError(`Invalid Ids: ${invalidIds.join(', ')}`);
    }
  }, [invalidIds]);

  const loadPlayerList = (_query) => {
    let requested = true;
    if (location.search !== '') {
      const [key, value] = location.search.substring(1).split('=');
      if (key === 'hasFilter' && value === 'true') {
        history.replace(location.pathname);
        requested = false;
      }
    }

    if (!currentLoadingRef.current && requested) {
      currentLoadingRef.current = true;
      dispatch(getPlayerList(_query));
    }
  };

  useEffect(() => {
    if (!isLoading && currentLoadingRef.current) {
      currentLoadingRef.current = false;
    }
  }, [isLoading]);

  const { pageCount, activePage, handleChangePage } = useListPage(
      query => {
        loadPlayerList(query);
      },
      total,
      _query,
      error,
      () => dispatch(resetPlayerListAlertsErrors()),
      successAlert,
      currentFilter
  );

  const handleDownload = useCallback(() => {
    const requestQuery = { ..._query, offset: 0 };

    if (currentFilter) {
      requestQuery.filterDynamic = currentFilter;
    }

    dispatch(downloadPlayerList(requestQuery))
  },[_query, currentFilter]);

  useEffect(() => {
    currentFilterRef.current = currentFilter;
  }, [JSON.stringify(currentFilter)]);

  const handleSortPlayers = (column, direction = DIRECTION.ASC) => {
    if (column === 'agentOwner') {
      return null;
    }

    if (column in PLAYER_LIST_SORTED_FIELDS) {
      _setQuery((prevValue) => {
        const { sort, order, limit } = prevValue;

        if ((sort !== PLAYER_LIST_SORTED_FIELDS[column]) || (order !== direction)) {
          const currFilter = currentFilterRef.current;

          const _query = {
            limit,
            offset: 0,
            sort: PLAYER_LIST_SORTED_FIELDS[column],
            order: direction,
          }

          if (currFilter?.length) {
            _query.filterDynamic = currFilter;
          }
          else if (_query.filterDynamic?.length) {
            _query.filterDynamic = [];
          }

          loadPlayerList(_query);
          return _query;
        }

        return prevValue;
      });
    }
  };

  useEffect(() => {
    handleChangePage(1);
  }, [_query.limit]);

  const onChangeRecords = (value) => {
    _setQuery((prevValue) => ({
      ...prevValue,
      limit: value,
      offset: 0,
    }));
  };

  const handleShowTableSettings = () => {
    setShowTableSettings(true);
  };

  const handleCloseTableSettings = () => {
    setShowTableSettings(false);
  };

  return (
      <div className="player-list players-list__mobile document-content page-content">
        <MetaTags>
          <title>{i18n.t("crm.playerList")} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>

        <div className="container-fluid">
          <Card className="page-card players-card players-card__mobile">
            <div
                className="page-title-table page-title-table__mobile"
            >
              <div className="d-flex settings-btns">
                {(canBulkCashbackApplyPermission
                    || applyInactivityFeePerm)
                    && size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW && (
                    <button
                        className={clsx('btn btn-rounded btn-primary mr-5 settings-btn', {
                          'd-none': !applyInactivityFeePerm && !canBulkCashbackApplyPermission
                        })}
                        onClick={onShowMassCashbackApply}
                    >
                      {i18n.t('content.massAdjustment')}
                    </button>
                )
                }
                {size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW && (
                    <button
                        className="btn btn-rounded btn-primary mr-5 settings-btn"
                        disabled={selectedRows.length === 0}
                        onClick={onShowMassUpdate}
                    >
                      {i18n.t('crm.mu')}
                    </button>
                )
                }
                <button
                    className="btn btn-rounded btn-primary settings-btn settings-btn__mobile"
                    onClick={handleShowTableSettings}
                >
                  <span>
							      {i18n.t('crm.settings')}
                    <img src={settingsWhite} className='icon-settings' alt="Table's Settings"/>
						      </span>
                </button>
              </div>
            </div>

            <TableFilter
                filterListOptions={FILTER_MENU_PLAYER_LIST(t, brandCurrencies, stateVipBrand)}
                isDisabled={isLoading}
                category={FILTER_CATEGORY.PLAYER}
                buttonMode
            />

            <PlayersTable
                playerList={playerList}
                isLoading={isLoading}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onSortPlayers={handleSortPlayers}
                isShowTableSettings={isShowTableSettings}
                onCloseTableSettings={handleCloseTableSettings}
            />
            <div className='mt-3'>
              <SelectTableView
                  pageCount={pageCount}
                  activePage={activePage}
                  onChangePage={handleChangePage}
                  selectedCount={selectedRows.length}
                  recordsCount={total}
                  pageSize={_query.limit}
                  isSticky={true}
                  onChange={onChangeRecords}
              />
            </div>

            {isShowMassUpdate && (
                <PlayerListMassUpdateModal
                    onConfirm={onHideMassUpdate}
                    onHide={onHideMassUpdate}
                    selectedRows={selectedRows}
                />
            )}

            {isShowMassCashbackApply && (
                <PlayerListMassCashbackApplyModal
                    applyCashbackPerm={canBulkCashbackApplyPermission}
                    applyInactivityFeePerm={applyInactivityFeePerm}
                    onHide={onHideMassCashbackApply}
                />
            )}
          </Card>
        </div>
      </div>
  );
}

export default React.memo(withRouter(PlayerList));
