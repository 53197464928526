import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import useHandleEsc from '../../../common/hooks/useHandleEsc';
import StatusRadio from '../../../components/Status/StatusRadio';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { useDispatch, useSelector } from 'react-redux';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { getAgentsList } from '../../../store/agents/agents/actions';
import { updatePlayers } from '../../../store/players/list/actions';
import {getSync} from "../../../helpers/api_helper";
import alertService from "../../../hooks/useAlertService";
import {defaultAgent} from "../../../constants/layout";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const prepareDto = model => {
    const selectedRows = model.selectedRows;

    return {
        players: selectedRows.map(id => {
            const dto = { id };

            if (model.accountStatus) {
                dto.accountStatus = model.accountStatus;
            }
            if (model.agentOwner) {
                dto.agentOwner = model.agentOwner;
            }
            if (!model.agentOwner) {
                dto.agentOwner = null;
            }

            return dto;
        })
    };
};

const prepareModel = (selectedRows) => {
    return {
        selectedRows,
        accountStatus: '',
        agentOwner: null
    }
}

function PlayerListMassUpdateModal({ selectedRows, onHide, onConfirm }) {
    useHandleEsc(onHide);
    const dispatch = useDispatch();
    const { agentsList } = useSelector(state => state.AgentList);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const { isLoadingMassUpdate } = useSelector(state => state.PlayerList);
    const [model, setModel] = useState(prepareModel(selectedRows));

    const canEditAgentOwner = agentSelf?.permissions.includes(SITE_PERMISSION.Player__Change_Agent_Owner);

    useEffect(() => {
        dispatch(getAgentsList());
    }, []);

    const handleChange = useCallback(name => {
        return value => {
            setModel(prevState => ({ ...prevState, [name]: value }));
        };
    }, []);

    const handleSave = useCallback(() => {
        const dto = prepareDto(model);

        dispatch(updatePlayers(dto));
        onConfirm();
    }, [model]);

    const agentList = useMemo(
        () =>
            agentsList?.map(agent => ({
                value: agent.id,
                content: agent.agentName
            })).concat(defaultAgent),
        [agentsList]
    )

    const formSchema = useMemo(() => {
        return [
            {
                label: i18n.t('crm.playerOwner'),
                valueName: 'agentOwner',
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                options: agentList,
                disabled: !canEditAgentOwner
            }
        ];
    }, [model, agentList]);

    const isEdited = useMemo(() => {
        const defaultModel = prepareModel(selectedRows);
        return model.accountStatus !== defaultModel.accountStatus ||
          model.agentOwner !== defaultModel.agentOwner;
    }, [selectedRows, model.accountStatus, model.agentOwner]);


    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="player-mass-update-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.playerMassUpdate')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                <div className="d-block mb-4">
                    <StatusRadio
                        onChange={handleChange('accountStatus')}
                        value={model.accountStatus}
                    />
                </div>

                {formSchema.map(item => {
                    return (
                        <ACommonDataField
                            key={item.label}
                            component={item.component}
                            label={item.label}
                            handleChange={handleChange(item.valueName)}
                            value={model[item.valueName]}
                            fulWidth={true}
                            options={item.options}
                            disabled={item.disabled}
                        />
                    );
                })}
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-rounded m-auto"
                    onClick={handleSave}
                    disabled={isLoadingMassUpdate || !isEdited}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('crm.submit')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

PlayerListMassUpdateModal.propTypes = {
    selectedRows: PropTypes.array.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default React.memo(PlayerListMassUpdateModal);
