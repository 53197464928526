import {
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GET_GAMES_FAIL,
    GET_ALL_GAMES,
    CREATE_GAME,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAIL,
    UPDATE_GAME,
    UPDATE_GAME_SUCCESS,
    UPDATE_GAME_FAIL,
    BULK_INSERT_GAMES,
    BULK_INSERT_GAMES_SUCCESS,
    BULK_INSERT_GAMES_FAIL,
    DOWNLOAD_GAME_LIST,
    DOWNLOAD_GAME_LIST_SUCCESS,
    DOWNLOAD_GAME_LIST_FAIL,
    GET_GAME_TYPES,
    GET_GAME_TYPES_SUCCESS,
    GET_GAME_TYPES_FAIL,
    GET_GAME_CATEGORIES,
    GET_GAME_CATEGORIES_SUCCESS,
    GET_GAME_CATEGORIES_FAIL,
    CREATE_GAME_CATEGORY,
    CREATE_GAME_CATEGORY_SUCCESS,
    CREATE_GAME_CATEGORY_FAIL,
    GET_GAME_CATEGORY,
    GET_GAME_CATEGORY_SUCCESS,
    GET_GAME_CATEGORY_FAIL,
    GET_CATEGORY_GAMES,
    GET_CATEGORY_GAMES_SUCCESS,
    GET_CATEGORY_GAMES_FAIL,
    GET_SITE_GAMES,
    GET_SITE_GAMES_SUCCESS,
    GET_SITE_GAMES_FAIL,
    GET_SITE_AVAILABLE_GAMES,
    GET_SITE_AVAILABLE_GAMES_SUCCESS,
    GET_SITE_AVAILABLE_GAMES_FAIL,
    PUT_CATEGORY_GAMES,
    PUT_CATEGORY_GAMES_SUCCESS,
    PUT_CATEGORY_GAMES_FAIL,
    PUT_CATEGORY_GAME,
    PUT_CATEGORY_GAME_SUCCESS,
    PUT_CATEGORY_GAME_FAIL,
    PUT_CATEGORY_DATA,
    PUT_CATEGORY_DATA_SUCCESS,
    PUT_CATEGORY_DATA_FAIL,
    BULK_UPDATE_CATEGORY_GAMES,
    BULK_UPDATE_CATEGORY_GAMES_SUCCESS,
    BULK_UPDATE_CATEGORY_GAMES_FAIL,
    DOWNLOAD_CATEGORY_GAME_LIST,
    DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS,
    DOWNLOAD_CATEGORY_GAME_LIST_FAIL,
    PUT_SITE_GAMES,
    PUT_SITE_GAMES_SUCCESS,
    PUT_SITE_GAMES_FAIL,
    BULK_UPDATE_SITE_GAMES,
    BULK_UPDATE_SITE_GAMES_SUCCESS,
    BULK_UPDATE_SITE_GAMES_FAIL,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL,
    UPDATE_SITE_GAME,
    UPDATE_SITE_GAME_SUCCESS,
    UPDATE_SITE_GAME_FAIL,
    REMOVE_CATEGORY,
    REMOVE_CATEGORY_SUCCESS,
    REMOVE_CATEGORY_FAIL,
    REMOVE_CATEGORY_GAME,
    REMOVE_CATEGORY_GAME_SUCCESS,
    REMOVE_CATEGORY_GAME_FAIL,
    REMOVE_SITE_GAME,
    REMOVE_SITE_GAME_SUCCESS,
    REMOVE_SITE_GAME_FAIL,
    REMOVE_SITE_GAMES,
    REMOVE_SITE_GAMES_SUCCESS,
    REMOVE_SITE_GAMES_FAIL,
    PURGE_CATEGORY_CACHE,
    PURGE_CATEGORY_CACHE_SUCCESS,
    PURGE_CATEGORY_CACHE_FAIL,
    DOWNLOAD_SITE_GAME_LIST,
    DOWNLOAD_SITE_GAME_LIST_SUCCESS,
    DOWNLOAD_SITE_GAME_LIST_FAIL,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL,
    RESET_GAME_CATEGORY,
    GET_GAME_VENDORS,
    GET_GAME_VENDORS_SUCCESS,
    GET_GAME_VENDORS_FAIL,
    GET_GAME_PROVIDERS,
    GET_GAME_PROVIDERS_SUCCESS,
    GET_GAME_PROVIDERS_FAIL,
    GET_SITE_PROVIDERS,
    GET_SITE_PROVIDERS_SUCCESS,
    GET_SITE_PROVIDERS_FAIL,
    DOWNLOAD_GAMES_DB_TEMPLATE,
    DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS,
    DOWNLOAD_GAMES_DB_TEMPLATE_FAIL,
    UPLOAD_GAMESDB_TO_CREATE,
    UPLOAD_GAMESDB_TO_CREATE_SUCCESS,
    UPLOAD_GAMESDB_TO_CREATE_FAIL,
    DOWNLOAD_GM_TEMPLATE,
    DOWNLOAD_GM_TEMPLATE_SUCCESS,
    DOWNLOAD_GM_TEMPLATE_FAIL,
    DOWNLOAD_GM_GAMES,
    DOWNLOAD_GM_GAMES_SUCCESS,
    DOWNLOAD_GM_GAMES_FAIL,
    UPDATE_GM_GAMES_SUCCESS,
    UPDATE_GM_GAMES_FAIL,
    UPDATE_GM_GAMES,
    UPDATE_DB_GAMES,
    UPDATE_DB_GAMES_FAIL,
    UPDATE_DB_GAMES_SUCCESS,
} from './actionTypes';

const INIT_STATE = {
    gameCategories: {
        result: [],
        total: 0,
    },
    gameTypes: [],
    gameVendors: [],
    gameProviders: [],
    gameCategory: {},
    categoryGames: {
        result: [],
        total: 0,
    },
    siteGames: [],
    games: {
        result: [],
        total: 0,
    },
    availableSiteGames: {
        result: [],
        total: 0,
    },
    isDownloading: false,
    isDownloadingTemplate: false,
    isDownloadingGMTemplate: false,
    isLoadingGames: false,
    isLoadingSiteGames: false,
    isUpdatingGames: false,
    isDeletingGame: false,
    isDeletingGames: false,
    isCreatingGame: false,
    isLoadingGameTypes: false,
    isCreatingCategory: false,
    isDeletingCategory: false,
    isUpdatingCategory: false,
    errorGames: null,
    errorGameTypes: null,
    deletingError: null,
    isLoadingCategory: false,
    isBulkUpload: false,
    bulkUploadResult: null,
    errorPurgeCache: null,
    successMessage: null,
};

const Games = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_GAMES:
            return {
                ...state,
                isLoadingGames: true,
                errorGames: null
            };

        case GET_GAMES_SUCCESS:
            return {
                ...state,
                games: action.payload,
                isLoadingGames: false
            };

        case GET_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingGames: false
            };

        case GET_ALL_GAMES:
            return {
                ...state,
                isLoadingGames: true,
                errorGames: null
            };

        case CREATE_GAME:
            return {
                ...state,
                isCreatingGame: true,
                errorGames: null,
                bulkUploadResult: false
            };

        case CREATE_GAME_SUCCESS:
            return {
                ...state,
                isCreatingGame: false,
                bulkUploadResult: true
            };

        case CREATE_GAME_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isCreatingGame: false,
                bulkUploadResult: false
            };

        case UPDATE_GAME:
            return {
                ...state,
                isUpdatingGames: true,
                errorGames: null,
                bulkUploadResult: false
            };

        case UPDATE_GAME_SUCCESS:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: null,
                bulkUploadResult: true
            };

        case UPDATE_GAME_FAIL:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: action.payload,
                bulkUploadResult: false
            };

        case BULK_INSERT_GAMES:
            return {
                ...state,
                isBulkUpload: true,
                errorGames: null,
                bulkUploadResult: null,
            };

        case BULK_INSERT_GAMES_SUCCESS:
            return {
                ...state,
                errorGames: null,
                isBulkUpload: false,
                bulkUploadResult: action.payload,
            };

        case BULK_INSERT_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isBulkUpload: false,
                bulkUploadResult: null,
            };

        case UPLOAD_GAMESDB_TO_CREATE:
            return {
                ...state,
                isBulkUpload: true,
                errorGames: null,
                bulkUploadResult: null,
            };

        case UPLOAD_GAMESDB_TO_CREATE_SUCCESS:
            return {
                ...state,
                errorGames: null,
                isBulkUpload: false,
                bulkUploadResult: action.payload,
            };

        case UPLOAD_GAMESDB_TO_CREATE_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isBulkUpload: false,
                bulkUploadResult: null,
            };

        case DOWNLOAD_GAME_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_GAME_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_GAME_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case DOWNLOAD_GAMES_DB_TEMPLATE:
            return {
                ...state,
                isDownloadingTemplate: true,
                error: null
            };

        case DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS:
            return {
                ...state,
                isDownloadingTemplate: false,
                error: null
            };

        case DOWNLOAD_GAMES_DB_TEMPLATE_FAIL:
            return {
                ...state,
                isDownloadingTemplate: false,
                error: action.payload,
            };

        case DOWNLOAD_GM_GAMES:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_GM_GAMES_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_GM_GAMES_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };


        case DOWNLOAD_GM_TEMPLATE:
            return {
                ...state,
                isDownloadingGMTemplate: true,
                error: null
            };

        case DOWNLOAD_GM_TEMPLATE_SUCCESS:
            return {
                ...state,
                isDownloadingGMTemplate: false,
                error: null
            };

        case DOWNLOAD_GM_TEMPLATE_FAIL:
            return {
                ...state,
                isDownloadingGMTemplate: false,
                error: action.payload,
            };

        case GET_GAME_CATEGORIES:
            return {
                ...state,
                isLoadingGames: true,
                errorGames: null
            };

        case GET_GAME_CATEGORIES_SUCCESS:
            return {
                ...state,
                gameCategories: action.payload,
                isLoadingGames: false
            };

        case GET_GAME_CATEGORIES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingGames: false
            };

        case GET_GAME_TYPES:
            return {
                ...state,
                isLoadingGameTypes: true,
                errorGameTypes: null,
            };

        case GET_GAME_TYPES_SUCCESS:
            return {
                ...state,
                gameTypes: action.payload,
                isLoadingGameTypes: false
            };

        case GET_GAME_TYPES_FAIL:
            return {
                ...state,
                errorGameTypes: action.payload,
                isLoadingGameTypes: false
            };

        case DOWNLOAD_CATEGORY_GAME_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_CATEGORY_GAME_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case CREATE_GAME_CATEGORY:
            return {
                ...state,
                isCreatingCategory: true,
                errorGames: null
            };

        case CREATE_GAME_CATEGORY_SUCCESS:
            return {
                ...state,
                isCreatingCategory: false
            };

        case CREATE_GAME_CATEGORY_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isCreatingCategory: false
            };

        case RESET_GAME_CATEGORY:
            return {
                ...state,
                gameCategory: {}
            };

        case GET_GAME_CATEGORY:
            return {
                ...state,
                isLoadingCategory: true,
                errorGames: null
            };

        case GET_GAME_CATEGORY_SUCCESS:
            return {
                ...state,
                gameCategory: action.payload,
                isLoadingCategory: false
            };

        case GET_GAME_CATEGORY_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingCategory: false
            };

        case GET_CATEGORY_GAMES:
            return {
                ...state,
                isLoadingGames: true,
                errorGames: null
            };

        case GET_CATEGORY_GAMES_SUCCESS:
            return {
                ...state,
                categoryGames: action.payload,
                isLoadingGames: false
            };

        case GET_CATEGORY_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingGames: false
            };

        case PUT_CATEGORY_GAMES:
            return {
                ...state,
                isUpdatingGames: true,
                errorGames: null,
            };

        case PUT_CATEGORY_GAMES_SUCCESS:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: null,
            };

        case PUT_CATEGORY_GAMES_FAIL:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: action.payload,
            };

        case PUT_CATEGORY_GAME:
            return {
                ...state,
                isUpdatingGames: true,
                errorGames: null,
            };

        case PUT_CATEGORY_GAME_SUCCESS:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: null,
            };

        case PUT_CATEGORY_GAME_FAIL:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: action.payload,
            };

        case BULK_UPDATE_CATEGORY_GAMES:
            return {
                ...state,
                isBulkUpload: true,
                errorGames: null,
            };

        case BULK_UPDATE_CATEGORY_GAMES_SUCCESS:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: null,
            };

        case BULK_UPDATE_CATEGORY_GAMES_FAIL:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: action.payload,
            };

        case PUT_CATEGORY_DATA:
            return {
                ...state,
                successMessage: null,
                isUpdatingCategory: true,
                errorGames: null
            };

        case PUT_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                isUpdatingCategory: false,
                successMessage: 'updatedSuccessfully',
            };

        case PUT_CATEGORY_DATA_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                successMessage: null,
                isUpdatingCategory: false
            };

        case REMOVE_CATEGORY:
            return {
                ...state,
                isDeletingCategory: true,
                deletingError: null
            };

        case REMOVE_CATEGORY_SUCCESS:
            return {
                ...state,
                isDeletingCategory: false
            };

        case REMOVE_CATEGORY_FAIL:
            return {
                ...state,
                deletingError: action.payload,
                isDeletingCategory: false
            };

        case REMOVE_CATEGORY_GAME:
            return {
                ...state,
                isDeletingGame: true,
                deletingError: null
            };

        case REMOVE_CATEGORY_GAME_SUCCESS:
            return {
                ...state,
                isDeletingGame: false
            };

        case REMOVE_CATEGORY_GAME_FAIL:
            return {
                ...state,
                deletingError: action.payload,
                isDeletingGame: false
            };

        case GET_SITE_GAMES:
            return {
                ...state,
                isLoadingGames: true,
                errorGames: null
            };

        case GET_SITE_GAMES_SUCCESS:
            return {
                ...state,
                siteGames: action.payload,
                isLoadingGames: false
            };

        case GET_SITE_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingGames: false
            };

        case GET_SITE_AVAILABLE_GAMES:
            return {
                ...state,
                isLoadingSiteGames: true,
                errorGames: null
            };

        case GET_SITE_AVAILABLE_GAMES_SUCCESS:
            return {
                ...state,
                availableSiteGames: action.payload,
                isLoadingSiteGames: false
            };

        case GET_SITE_AVAILABLE_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isLoadingSiteGames: false
            };

        case PUT_SITE_GAMES:
            return {
                ...state,
                isUpdatingGames: true,
                errorGames: null,
            };

        case PUT_SITE_GAMES_SUCCESS:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: null,
            };

        case PUT_SITE_GAMES_FAIL:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: action.payload,
            };

        case UPDATE_GM_GAMES:
        case BULK_UPDATE_SITE_GAMES:
            return {
                ...state,
                isBulkUpload: true,
                errorGames: null,
            };

        case UPDATE_GM_GAMES_SUCCESS:
        case BULK_UPDATE_SITE_GAMES_SUCCESS:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: null,
            };

        case UPDATE_GM_GAMES_FAIL:
        case BULK_UPDATE_SITE_GAMES_FAIL:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: action.payload,
            };

        case BULK_UPDATE_SITE_GAME_ATTRIBUTIONS:
            return {
                ...state,
                isBulkUpload: true,
                errorGames: null,
            };

        case BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: null,
            };

        case BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL:
            return {
                ...state,
                isBulkUpload: false,
                errorGames: action.payload,
            };

        case UPDATE_SITE_GAME:
            return {
                ...state,
                isUpdatingGames: true,
                errorGames: null,
            };

        case UPDATE_SITE_GAME_SUCCESS:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: null,
            };

        case UPDATE_SITE_GAME_FAIL:
            return {
                ...state,
                isUpdatingGames: false,
                errorGames: action.payload,
            };

        case REMOVE_SITE_GAME:
            return {
                ...state,
                isDeletingGame: true,
                deletingError: null
            };

        case REMOVE_SITE_GAME_SUCCESS:
            return {
                ...state,
                isDeletingGame: false
            };

        case REMOVE_SITE_GAME_FAIL:
            return {
                ...state,
                deletingError: action.payload,
                isDeletingGame: false
            };

        case REMOVE_SITE_GAMES:
            return {
                ...state,
                isDeletingGames: true,
                deletingError: null
            };

        case REMOVE_SITE_GAMES_SUCCESS:
            return {
                ...state,
                isDeletingGames: false
            };

        case REMOVE_SITE_GAMES_FAIL:
            return {
                ...state,
                deletingError: action.payload,
                isDeletingGames: false
            };

        case PURGE_CATEGORY_CACHE:
            return {
                ...state,
                errorPurgeCache: null,
                isUpdatingCategory: true,
                deletingError: null
            };

        case PURGE_CATEGORY_CACHE_SUCCESS:
            return {
                ...state,
                errorPurgeCache: null,
                isUpdatingCategory: false
            };

        case PURGE_CATEGORY_CACHE_FAIL:
            return {
                ...state,
                errorPurgeCache: action.payload.message,
                isUpdatingCategory: false
            };

        case DOWNLOAD_SITE_GAME_LIST:
        case DOWNLOAD_AVAILABLE_SITE_GAME_LIST:
        case DOWNLOAD_SITE_GAME_ATTRIBUTIONS:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_SITE_GAME_LIST_SUCCESS:
        case DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS:
        case DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_SITE_GAME_LIST_FAIL:
        case DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL:
        case DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case GET_GAME_VENDORS:
            return {
                ...state,
                errorGames: null
            };

        case GET_GAME_VENDORS_SUCCESS:
            return {
                ...state,
                gameVendors: action.payload,
            };

        case GET_GAME_VENDORS_FAIL:
            return {
                ...state,
                errorGames: action.payload,
            };

        case GET_GAME_PROVIDERS:
            return {
                ...state,
                errorGames: null
            };

        case GET_GAME_PROVIDERS_SUCCESS:
            return {
                ...state,
                gameProviders: action.payload,
            };

        case GET_GAME_PROVIDERS_FAIL:
            return {
                ...state,
                errorGames: action.payload,
            };

        case GET_SITE_PROVIDERS:
            return {
                ...state,
                errorGames: null
            };

        case GET_SITE_PROVIDERS_SUCCESS:
            return {
                ...state,
                gameProviders: action.payload,
            };

        case GET_SITE_PROVIDERS_FAIL:
            return {
                ...state,
                errorGames: action.payload,
            };

        case UPDATE_DB_GAMES:
            return {
                ...state,
                errorGames: null,
                isBulkUpload: true,
            }
        case UPDATE_DB_GAMES_SUCCESS:
            return {
                ...state,
                errorGames: null,
                isBulkUpload: false,
            };

        case UPDATE_DB_GAMES_FAIL:
            return {
                ...state,
                errorGames: action.payload,
                isBulkUpload: false,
            };

        default:
            return state;
    }
};

export default Games;
