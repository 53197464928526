import { GET_INFO, GET_INFO_ERROR, GET_INFO_SUCCESS } from './actionTypes';

export const getInfo = () => ({
    type: GET_INFO
});

export const getInfoSuccess = response => ({
    type: GET_INFO_SUCCESS,
    payload: response
});

export const getInfoError = error => ({
    type: GET_INFO_ERROR,
    payload: error
});
