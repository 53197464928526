import React from "react";
import i18n from "../../../i18n";

const ModalContentCurrentShift = (props) => {
    const {totalDeposits, totalWithdrawals, balance} = props;

    return (
        <div className='close-shift'>
            <div className='close-shift-item'>
                <span>{i18n.t('crm.totalPlayerDeposits')}</span>
                <span>{`${totalDeposits} MX$`}</span>
            </div>
            <div className='close-shift-item'>
                <span>{i18n.t('crm.totalWithdrawals')}</span>
                <span>{`${totalWithdrawals} MX$`}</span>
            </div>
            <div style={{fontWeight: 700}} className='close-shift-item'>
                <span>{i18n.t('crm.balance')}</span>
                <span>{`${balance} MX$`}</span>
            </div>
        </div>
    )
}

export default ModalContentCurrentShift;
