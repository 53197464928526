import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import calendar from '../../../assets/images/layout/calendar.svg';
import { LanguageContext } from '../../../context/languageContext';
import './app-date-picker.scss';

const LEGAL_AGE = 18;
const MIN_FULL_YEAR = "1900-01-01T00:00:00";
const MIN_FULL_YEAR_FOR_REGISTRATION = "1920-01-01T00:00:00";
const INITIAL_FOCUSED_DATE = new Date().setFullYear(new Date().getFullYear() - LEGAL_AGE);

function AppDatePicker({ value, name, allowFutureDate, onChange, className, disabled, setIsEdit = () => {}, isBirthDate = false }) {
    const { language } = useContext(LanguageContext);
    const [date, setDate] = useState(null);

    const startDateWithLegalAge = isBirthDate
        ? new Date(new Date().setFullYear(new Date().getFullYear() - LEGAL_AGE))
        : null;

    const handleChange = (date) => {
        onChange(prev => ({ ...prev, [name]: date }));
        setIsEdit(true);
    };

    useEffect(() => {
        if(value === null) {
            setDate(null)
            return;
        }
        if (!value) {
            return
        }
        const currentDate = new Date(value);
        if (currentDate.getTimezoneOffset() > 0) {
            setDate(new Date(currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset())));
        } else {
            setDate(currentDate);
        }
    }, [value, isBirthDate]);

    return (
        <DatePicker
            className={`app-date-picker ${className ? className : ''}`}
            onChange={handleChange}
            value={date || value && value !== '-' ? date : null}
            clearIcon={null}
            format="dd.MM.yyyy"
            calendarIcon={<img src={calendar} alt="" width="16" />}
            locale={language}
            disabled={disabled}
            defaultActiveStartDate={
                isBirthDate
                    ? date || new Date(INITIAL_FOCUSED_DATE)
                    : null
            }
            maxDate={
                isBirthDate
                    ? (allowFutureDate ? null : startDateWithLegalAge)
                    : (allowFutureDate ? null : new Date())
            }
            minDate={isBirthDate ? new Date(MIN_FULL_YEAR_FOR_REGISTRATION) : new Date(MIN_FULL_YEAR)}
        />
    );
}

AppDatePicker.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isBirthDate: PropTypes.bool,
};

export default React.memo(AppDatePicker);
