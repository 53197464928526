import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useTranslation } from "react-i18next";

import { CommonFilter } from './CommonFilterPopup';

import useHandleEnter from '../../../common/hooks/useHandleEnter';
import ACommonDataField from '../../GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';

export const SelectPopup = ({
    popupKey,
    title,
    onSave,
    label,
    options,
    onCancel,
    initialValue,
}) => {
    const [value, setValue] = useState(initialValue);
    const { t } = useTranslation();

    const handleSave = useCallback(() => {
        if (value || value === '') {
            const option = options.find(option => option.value === value);

            if (option) {
                onSave([{ key: popupKey, value: value }]);
            }
        }
    }, [onSave, value, popupKey, options]);

    useHandleEnter(handleSave);

    return (
        <CommonFilter
            title={title}
            className="filter-simple-text"
            onCancel={onCancel}
        >
            <ACommonDataField
                label={label}
                component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                value={value}
                handleChange={setValue}
                options={options}
            />

            <Button color="primary" onClick={handleSave}>
                {t('filter.apply')}
            </Button>
        </CommonFilter>
    );
};

SelectPopup.proptypes = {
    popupKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    label: PropTypes.string,
    onCancel: PropTypes.func.isRequired
};

SelectPopup.defaultProps = {
    initialValue: '',
};
