import React from 'react';
import negative from '../../../assets/images/common/negative.svg';
import positive from '../../../assets/images/common/positive.svg';
import PropTypes from 'prop-types';


function KycView({ kyc }) {
    return kyc ?
        <img src={positive} alt="" /> :
        <img src={negative} alt="" />;
}

KycView.propTypes = {
    kyc: PropTypes.bool,
};

export default React.memo(KycView);
