import { positions, transitions, useAlert } from 'react-alert';
import i18n from "i18next";

export default function useAlertService() {
    const alert = useAlert();

    function showSuccess(message) {
        alert.success(message);
    }

    function showError(message) {
        if (
            message?.includes &&
            message.includes("Cannot destructure property 'data' ")
        ) {
            alert.error(`Server is unavailable`);
            return;
        }
        let errorMsg;
        try {
            const msg = message?.message ? message.message : message;
            const json = JSON.parse(msg);
            if (json.missingPermissions) {
                if (json.missingPermissions.includes("Player__View_Player_Info")) {
                    return;
                } else {
                    const errorMsg = 'Not permitted';
                    alert.error(`${errorMsg}: ${json.missingPermissions}`);
                }
            }

        } catch (e) {}

        if (typeof message === 'string') {
            errorMsg = message;
        } else if (message?.data?.errors && message?.data?.errors.length > 0) {
            errorMsg = `${message?.message}: `;
            message?.data?.errors.forEach((val, index) => {
                if (index === 0) {
                    errorMsg += val.property;
                } else {
                    errorMsg += ', ' + val.property;
                }
            });
        } else {
            if (message?.message) {
                errorMsg = message.message;
            } else {
                return;
            }
        }
        if (errorMsg === 'Manual adjustment wasn\'t created') {
            alert.error(i18n.t(`crm.alert.manual.adjustment.not.created`));
        } else if (errorMsg === 'duplicate.email') {
            alert.error(i18n.t('crm.duplicate.email'));
        } else if (errorMsg === 'Password is incorrect') {
            alert.error(i18n.t('crm.alerts.passwordIsIncorrect'));
        } else {
            alert.error(errorMsg);
        }
    }

    function showNotification(message) {
        alert.info(message);
    }

    return {
        showSuccess,
        showError,
        showNotification
    };
}
