import React, { useCallback, useMemo } from 'react';
import i18n from '../../../i18n';
import group from '../../../assets/images/common/Tooltip.svg';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import PropTypes from 'prop-types';

function AgentContactInfoBlock({ data, onChange, onChangePassword }) {
    const handleChange = useCallback(
        name => {
            return res => {
                const value = res?.target?.value ? res?.target?.value : res;

                onChange({ ...data, [name]: value });
            };
        },
        [data, onChange]
    );

    const fields = useMemo(
        () => [
            {
                component: 'text',
                stateKey: 'firstName',
                disabled: true
            },
            {
                component: 'text',
                stateKey: 'lastName',
                disabled: true
            },
            {
                component: 'text',
                stateKey: 'email',
                disabled: true
            },
            {
                component: 'text',
                stateKey: 'phoneQueues',
                disabled: true
            },
            {
                component: 'text',
                stateKey: 'phoneExtension',
                disabled: true
            },
        ],
        [data]
    );

    return (
        <div className="info-content-block mt-2">
            <div className="card-body__header pt-0 d-flex align-items-center">
                <img className="me-3" src={group} alt="" />

                <span className="font-w-500 text-uppercase">
                    {i18n.t('agent.generalInfo')}
                </span>
            </div>

            <div className="d-flex flex-wrap">
                {fields.map(item => (
                    <ACommonDataField
                        key={item.stateKey}
                        disabled={item.disabled}
                        component={item.component}
                        label={i18n.t(`agent.${item.stateKey}`)}
                        value={data[item.stateKey]}
                        handleChange={handleChange(item.stateKey)}
                    />
                ))}

                {onChangePassword && (
                    <div className="d-flex align-items-center mt-3 me-4 info-block">
                        <div className="text-muted text-wrap">
                            <button
                                className='btn btn-rounded btn-primary'
                                onClick={onChangePassword}
                            >
                                {i18n.t(`crm.change.password`)}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

AgentContactInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
};

export default React.memo(AgentContactInfoBlock);
