import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Card } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import './styles.scss';
import '../../assets/scss/pages/common.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
    downloadDepositList,
    getCardNetworks, getDepositActionList,
    getDepositList,
    getPaymentProviders,
    resetDepositListAlertsErrors,
} from '../../store/actions';
import {
    FILTER_CATEGORY,
    FILTER_DECLINE_PAYMENTS_DEFAULT_VALUES,
    FILTER_MENU_PAYMENT_LIST,
    FILTER_WD_PENDING_FOR_ACTION,
    PAYMENT_METHODS_WITH_PREFIX,
} from '../../constants/Filter';
import TableFilter from '../../components/TableFilter';
import DepositsTable from './tables/DepositsTable';
import useListPage from "../../hooks/useListPage";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SelectTableView from "../Content/components/SelectTableView";
import { fetchBrandSettingsPage } from "../../store/brand/brandPage/actions";
import download from "../../assets/images/common/download.svg";
import settingsWhite from "../../assets/images/layout/settingsWhite.svg";
import Tooltip from "@mui/material/Tooltip";
import { DEPOSIT_PAGE_TYPE, DIRECTION } from "../../constants/layout";
import { DEPOSITS_SORTED_FIELDS } from "./tables/DepositsTable";
import clsx from "clsx";
import useVipLevelMapping from "../../hooks/useVipLevelMapping";


function Deposits() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { currentFilter } = useSelector(state => state.Filter);
    const { active: activeBrand } = useSelector(state => state.Brand);
    const { data: brandData } = useSelector(state => state.BrandSettingsPage);
    const { state: stateVipBrand} = useVipLevelMapping();

    const location = useLocation();
    const path = location?.pathname;
    let depositPageType = DEPOSIT_PAGE_TYPE.TRANSACTIONS;
    if(path.includes(`/${DEPOSIT_PAGE_TYPE.DEPOSITS_DECLINES}`)) {
        depositPageType = DEPOSIT_PAGE_TYPE.DEPOSITS_DECLINES;
    } else if(path.includes(`/${DEPOSIT_PAGE_TYPE.DEPOSITS_ACTION}`)) {
        depositPageType = DEPOSIT_PAGE_TYPE.DEPOSITS_ACTION
    }
    const { isLoading, total, depositList, duplicatedCardNetworks, query, error, isDownloading } = useSelector(
        state => state.DepositList
    );

    const [_query, _setQuery] = useState(query);

    const [isShowTableSettings, setShowTableSettings] = useState(false);
    const [brandCurrencies, setBrandCurrencies] = useState([]);

    useEffect(() => {
        if (brandData) {
            setBrandCurrencies(brandData?.configurationFinancial.currencies || []);
        }
    }, [brandData]);

    useEffect(() => {
        dispatch(fetchBrandSettingsPage());
    }, [activeBrand]);

    const [selectedRows, setSelectedRows] = useState([]);
    const {pageCount, activePage, handleChangePage} = useListPage(
        query => {
            if (depositPageType === DEPOSIT_PAGE_TYPE.DEPOSITS_DECLINES) {
                if (!query?.filterDynamic?.some(item => item.key === "status")) {
                    query.filterDynamic = query?.filterDynamic?.concat(FILTER_DECLINE_PAYMENTS_DEFAULT_VALUES)
                }
            }

            const duplicatedCardKeys = Object.keys(duplicatedCardNetworks);

            query?.filterDynamic
                .filter((condition) => condition.key === 'payment_method')
                .forEach((condition) => {
                    if (PAYMENT_METHODS_WITH_PREFIX.includes(condition.value)) {
                        if (condition.value === 'Oxxo') {
                            query.filterDynamic.push({
                                key: 'payment_method',
                                value: 'oxxo',
                            })
                        }
                        condition.value = `apmgw_${condition.value}`;
                    }
                    else if (duplicatedCardKeys.includes(condition.value)) {
                        duplicatedCardNetworks[condition.value]
                            .forEach((method) => {
                                query.filterDynamic.push({
                                    key: 'payment_method',
                                    value: method,
                                });
                            });
                    }
                });

            const filterDynamic = [];
            query.filterDynamic
                .forEach(({key, value}) => {
                    if (!filterDynamic.find((cond) => key === cond.key && value === cond.value)) {
                        filterDynamic.push({key, value});
                    }
                });
            query.filterDynamic = filterDynamic;
            if(depositPageType === DEPOSIT_PAGE_TYPE.DEPOSITS_ACTION) {
                return dispatch(getDepositActionList(query))
            } else {
                return dispatch(getDepositList(query))
            }
        },
        total,
        _query,
        error,
        () => dispatch(resetDepositListAlertsErrors()),
        null,
        currentFilter,
    );

    useEffect(() => {
        dispatch(getCardNetworks());
        dispatch(getPaymentProviders());
    }, []);

    const handleDownload = useCallback(() => {
        const requestQuery = { ..._query, offset: 0 };
        if (depositPageType === DEPOSIT_PAGE_TYPE.DEPOSITS_DECLINES) {
            requestQuery.filterDynamic = [
                ...(_query.filterDynamic || [])
            ]
        }

        if (depositPageType === DEPOSIT_PAGE_TYPE.DEPOSITS_ACTION) {
            requestQuery.filterDynamic = [
                ...(_query.filterDynamic || []),
                ...FILTER_WD_PENDING_FOR_ACTION
            ]
        }

        requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        dispatch(downloadDepositList(requestQuery));
    }, [_query, depositPageType]);

    useEffect(() => {
        handleChangePage(1);
    }, [_query.limit]);

    const onChangeRecords = (value) => {
        _setQuery((prevValue) => ({
            ...prevValue,
            limit: value,
            offset: 0,
        }));
    };

    const handleSortDeposits = useCallback((column, direction = DIRECTION.ASC) => {
        _setQuery((prevValue) => {
            const { sort, order, limit, filterDynamic } = prevValue;

            if ((sort !== DEPOSITS_SORTED_FIELDS[column]) || (order !== direction)) {
                const query = {
                    limit,
                    offset: 0,
                    sort: DEPOSITS_SORTED_FIELDS[column],
                    order: direction,
                    filterDynamic,
                };

                if(depositPageType === DEPOSIT_PAGE_TYPE.DEPOSITS_ACTION) {
                    return dispatch(getDepositActionList(query))
                } else {
                    return dispatch(getDepositList(query))
                }
                return query;
            }

            return prevValue;
        });
    }, [_query]);

    useEffect(() => {
        _setQuery(query);
    }, [query])

    return (
        <div className='player-list players-list__mobile document-content page-content'>
            <MetaTags>
                <title>
                    {i18n.t(`finance.${depositPageType}`)} - {process.env.REACT_APP_APP_NAME}
                </title>
            </MetaTags>

            <div className='container-fluid'>
                <Row>
                    <Col className='col-12'>
                        <Card className='page-card players-card__mobile players-card'>
                            <div className="page-title-table page-card__header">
                                <TableFilter
                                    filterListOptions={FILTER_MENU_PAYMENT_LIST({
                                        t,
                                        isDeposit: depositPageType === DEPOSIT_PAGE_TYPE.TRANSACTIONS,
                                        brandCurrencies,
                                        stateVipBrand
                                    })}
                                    isDisabled={isLoading}
                                    category={FILTER_CATEGORY.PAYMENT}
                                    buttonMode
                                />

                                <div className="d-flex align-items-center page-card__settings">
                                    <button
                                        className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm ml-5', {
                                            ['d-none']: !depositList?.length
                                        })}
                                        onClick={handleDownload}
                                        disabled={isDownloading}
                                    >
                                        <Tooltip title={t('crm.downloadList')} placement="top" arrow>
                                            <img src={download} alt=""/>
                                        </Tooltip>
                                    </button>
                                    <button
                                        className="btn btn-rounded btn-primary settings-btn settings-btn__mobile"
                                        onClick={() => setShowTableSettings(true)}
                                    >
                                        <span>
                                            {i18n.t('crm.settings')}
                                            <img src={settingsWhite} className='icon-settings' alt="Table's Settings"/>
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <DepositsTable
                                list={depositList}
                                isLoading={isLoading}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                depositPageType={depositPageType}
                                isShowTableSettings={isShowTableSettings}
                                onCloseTableSettings={() => setShowTableSettings(false)}
                                onSortDeposits={handleSortDeposits}
                            />

                            <div className='mt-3'>
                                <SelectTableView
                                    pageCount={pageCount}
                                    activePage={activePage}
                                    recordsCount={total}
                                    onChangePage={handleChangePage}
                                    pageSize={_query.limit}
                                    isSticky={true}
                                    onChange={onChangeRecords}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default React.memo(Deposits);
