import {
    GET_DEPOSIT_ACTION_LIST,
    GET_DEPOSIT_ACTION_LIST_SUCCESS,
    GET_DEPOSIT_ACTION_LIST_FAIL,
    GET_DEPOSIT_LIST,
    GET_DEPOSIT_LIST_SUCCESS,
    GET_DEPOSIT_LIST_FAIL,
    RESET_DEPOSIT_LIST_ALERTS_ERRORS,
    DOWNLOAD_DEPOSIT_LIST,
    DOWNLOAD_DEPOSIT_LIST_SUCCESS,
    DOWNLOAD_DEPOSIT_LIST_FAIL,
    GET_CARD_NETWORKS,
    GET_CARD_NETWORKS_SUCCESS,
    GET_CARD_NETWORKS_FAIL,
    GET_PAYMENT_PROVIDERS,
    GET_PAYMENT_PROVIDERS_SUCCESS,
    GET_PAYMENT_PROVIDERS_FAIL,
} from './actionTypes';


const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    depositList: null,
    total: 0,
    query: DEFAULT_QUERY,

    paymentProviders: null,
    isPaymentProvidersLoading: false,

    cardNetworks: null,
    duplicatedCardNetworks: {},

    isDownloading: false,
};

const Deposits = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DEPOSIT_ACTION_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null
            };

        case GET_DEPOSIT_ACTION_LIST_SUCCESS:
            return {
                ...state,
                depositList: action.payload.result,
                total: action.payload.total,
                isLoading: false
            };

        case GET_DEPOSIT_ACTION_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                depositList: [],
                isLoading: false
            };

        case GET_DEPOSIT_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null
            };

        case GET_DEPOSIT_LIST_SUCCESS:
            return {
                ...state,
                depositList: action.payload.result,
                total: action.payload.total,
                isLoading: false
            };

        case GET_DEPOSIT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                depositList: [],
                isLoading: false
            };

        case RESET_DEPOSIT_LIST_ALERTS_ERRORS:
            return {
                ...state,
                error: null,
            };

        case DOWNLOAD_DEPOSIT_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_DEPOSIT_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_DEPOSIT_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case GET_CARD_NETWORKS:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case GET_CARD_NETWORKS_SUCCESS:
            let cardNetworks = action.payload;
            const duplicatedCardNetworks = {};
            const cardNetworksObj = {};

            cardNetworks.forEach((card) => {
                let cardLowerCase = card.split(' ').join('').toLowerCase();
                cardLowerCase = (cardLowerCase[0].toUpperCase() + cardLowerCase.slice(1))
                    .replace('card', 'Card');
                cardNetworksObj[card] = cardLowerCase;

                if (!(cardLowerCase in duplicatedCardNetworks)) {
                    duplicatedCardNetworks[cardLowerCase] = [];
                }
                duplicatedCardNetworks[cardLowerCase].push(card);
            });

            Object.keys(duplicatedCardNetworks)
                .filter((key) => duplicatedCardNetworks[key].length === 1)
                .forEach((key) => delete duplicatedCardNetworks[key]);

            const filteredCardNames = Object.keys(duplicatedCardNetworks);

            cardNetworks = [
                ...cardNetworks.filter((card) => !filteredCardNames.includes(cardNetworksObj[card])),
                ...filteredCardNames,
            ];
            cardNetworks.sort();

            return {
                ...state,
                cardNetworks,
                duplicatedCardNetworks,
                isLoading: false
            };

        case GET_CARD_NETWORKS_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        case GET_PAYMENT_PROVIDERS:
            return {
                ...state,
                isPaymentProvidersLoading: true,
                error: null
            };

        case GET_PAYMENT_PROVIDERS_SUCCESS:
            const paymentProviders = action.payload;
            paymentProviders.sort();

            return {
                ...state,
                paymentProviders,
                isPaymentProvidersLoading: false
            };

        case GET_PAYMENT_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPaymentProvidersLoading: false
            };

        default:
            return state;
    }
};

export default Deposits;
