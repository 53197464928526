import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import close from "../../../assets/images/common/close 1.svg";
import { useDispatch, useSelector } from 'react-redux';
import clsx from "clsx";
import DragFileInput from "../../../common/inputs/common/DragFileInput";
import {
    downloadTemplateInactivityFee,
    massCashbackApply,
    massInactivityFeeApply,
    resetErrorsMassAdjustment
} from "../../../store/players/manualAdjustment/actions"
import Loader from "../../../common/components/Loader";
import moment from "moment";
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import useAlertService from "../../../hooks/useAlertService";
import { XLSXformat } from "../../../common/inputs/common/FileTypes/fileTypes";
import gamesDBTemplateIcon from "../../../assets/images/common/gamesDBTemplateIcon.svg";
import { useTranslation } from "react-i18next";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';


const MODAL_MODE = {
    CONFIRM: 'confirm',
    REPORT: 'report',
}

const TABS = {
    CASHBACK: 'CASHBACK',
    INACTIVITY_FEE: 'INACTIVITY_FEE',
};

const feeConfig = {
    type: "DEBIT",
    commentType: "FeeInactivity"
}

function PlayerListMassCashbackApplyModal({ onHide, applyCashbackPerm, applyInactivityFeePerm }) {
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const [activeTab, setActiveTab] = useState(TABS.CASHBACK);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const customAuditNote =
        activeTab === TABS.CASHBACK
            ? `Monday cashback ${moment().format('DD.MM.YYYY')}`
            : `Monday inactivity fee ${moment().format('DD.MM.YYYY')}`
    const [modalMode, setModalMode] = useState(MODAL_MODE.CONFIRM);
    const [customMessage, setCustomMessage] = useState(customAuditNote);
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const { t } = useTranslation();

    const {
        isLoading,
        isMassCashbackUploading,
        isDownloading,
        error,
        successAlert,
    } = useSelector(state => state.PlayerManualAdjustment);

    useEffect(() => {
        setCustomMessage(customAuditNote);
    }, [activeTab])

    const handleLoadFile = (file) => {
        if (!file) return;

        setSelectedFile(file);

        if (!file.name.includes('Fee_inactivity') && activeTab === TABS.INACTIVITY_FEE) {
            alertService.showError('File name must contain "Fee_inactivity"');
            setSelectedFile(null);
            setFileName('');
            return;
        }

        setFileName(file?.name);
    };

    const handleSave = useCallback(() => {
        if (activeTab === TABS.CASHBACK) {
            dispatch(massCashbackApply({
                file: selectedFile,
                comment: customMessage || '',
            }));
        }
        if (activeTab === TABS.INACTIVITY_FEE) {
            dispatch(massInactivityFeeApply({
                file: selectedFile,
                config: feeConfig,
            }));
        }
    }, [customMessage, activeTab, selectedFile]);

    const handleChangeCustomMessage = (value) => {
        setCustomMessage(value);
    }

    useEffect(() => {
        if (!selectedFile || !customMessage) {
            setConfirmDisabled(true);
        } else {
            setConfirmDisabled(false);
        }
    }, [selectedFile, customMessage, activeTab])

    const downloadTemplateInactivityFeeFile = () => {
        dispatch(downloadTemplateInactivityFee());
    }

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(t(`content.${successAlert}`));
        }
        if (error) {
            alertService.showError(error);
        }
        dispatch(resetErrorsMassAdjustment());
    }, [successAlert, error])

    const resetFileState = () => {
        setSelectedFile(null);
        setFileName('');
    }

    useEffect(() => {
        dispatch(resetErrorsMassAdjustment());
    }, [dispatch, error])

    const showPermError = () => {
        return alertService.showError(`Not permitted [${SITE_PERMISSION.Transactions__Bulk_Transfer}]`)
    }

    useEffect(() => {
        setActiveTab(applyCashbackPerm
            ? TABS.CASHBACK : TABS.INACTIVITY_FEE)
    }, [applyCashbackPerm, applyInactivityFeePerm])

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="player-mass-update-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {t('content.massAdjustmentApply')}
                </h5>
                <button className="close" onClick={!isLoading && onHide}/>
            </div>

            <div className="tabs-game-category-modal mass-adjustment">
                <div
                    className={clsx('tab mr-10', {
                        'activeTab': activeTab === TABS.CASHBACK,
                        'block-click': selectedFile,
                        'd-none': !applyCashbackPerm
                    })}
                    onClick={() => {
                        setActiveTab(TABS.CASHBACK)
                        resetFileState()
                    }}
                >
                    <span>{t('content.tab.CASHBACK')}</span>
                </div>
                <div
                    className={clsx('tab', {
                        'activeTab': activeTab === TABS.INACTIVITY_FEE,
                        'block-click': selectedFile,
                        'd-none': !applyInactivityFeePerm
                    })}
                    onClick={applyInactivityFeePerm ? () => {
                        setActiveTab(TABS.INACTIVITY_FEE)
                        resetFileState();
                    } : () => showPermError()}
                >
                    <span>{t('content.tab.INACTIVITY_FEE')}</span>
                </div>
            </div>

            <ModalBody>
                {activeTab === TABS.CASHBACK && applyCashbackPerm && (
                    <>
                        <ACommonDataField
                            label={t('crm.massCashback.auditNote')}
                            value={customMessage}
                            handleChange={handleChangeCustomMessage}
                        />
                        <div className='modal-desc d-flex justify-content-center'>
                            <span style={{fontSize: '12px'}}>
                             *{t('crm.noteMassAdjustment')}
                            </span>
                        </div>
                    </>
                )}


                <div className='d-flex justify-content-start mt-2 mb-2'>
                    <DragFileInput
                        accept={XLSXformat}
                        typeFile={'XLSX'}
                        tooltipText={'chooseFileXLSX'}
                        onSelect={handleLoadFile}
                        disabled={isLoading}
                        propsFileName={fileName}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </div>

                {activeTab === TABS.INACTIVITY_FEE && applyInactivityFeePerm && (
                    <div className='download-template-file'>
                        <button
                            className='btn download-gamesDB-template'
                            onClick={downloadTemplateInactivityFeeFile}
                            disabled={isDownloading}
                        >
                            <img src={gamesDBTemplateIcon} alt="Download a template"/>
                            <span>{t('content.downloadTemplateFile')}</span>
                        </button>
                        {isDownloading || isLoading && (
                            <div className='loader-mass-adjustment'>
                                <Loader size='sm'/>
                            </div>
                        )}
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <div className='w-100 d-flex justify-content-center'>
                    {isMassCashbackUploading && <Loader size='sm' />}
                </div>
                <div className='w-100 d-flex justify-content-between'>
                    <button
                        className="btn btn-primary btn-rounded m-auto"
                        onClick={onHide}
                        disabled={isLoading}
                    >
                        <img width={'13px'} src={close} alt=""/>
                        {i18n.t('crm.cancel')}
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-primary btn-rounded m-auto"
                        onClick={handleSave}
                        disabled={confirmDisabled || isLoading}
                    >
                        <img width={'19px'} src={i_51} alt=""/>
                        {i18n.t('crm.submit')}
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

PlayerListMassCashbackApplyModal.propTypes = {
    onHide: PropTypes.func.isRequired,
};

export default React.memo(PlayerListMassCashbackApplyModal);
