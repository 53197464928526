export const GET_PLAYER_DEPOSIT_LIST = 'GET_PLAYER_DEPOSIT_LIST';
export const GET_PLAYER_DEPOSIT_LIST_SUCCESS =
    'GET_PLAYER_DEPOSIT_LIST_SUCCESS';
export const GET_PLAYER_DEPOSIT_LIST_FAIL = 'GET_PLAYER_DEPOSIT_LIST_FAIL';

export const RESET_PLAYER_DEPOSIT_LIST_ERRORS =
    'RESET_PLAYER_DEPOSIT_LIST_ERRORS';

export const DOWNLOAD_PLAYER_DEPOSIT_LIST =
    'DOWNLOAD_PLAYER_DEPOSIT_LIST';
export const DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS =
    'DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS';
export const DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL =
    'DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL';

export const DOWNLOAD_PLAYER_WITHDRAWAL_LIST =
    'DOWNLOAD_PLAYER_WITHDRAWAL_LIST';
export const DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS =
    'DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS';
export const DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL =
    'DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL';
