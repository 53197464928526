import {
    FETCH_BRAND_SETTINGS_PAGE,
    BRAND_SETTINGS_PAGE_ERROR,
    CHANGE_BRAND_SETTINGS_PAGE,
    FETCH_BRAND_SETTINGS_PAGE_SUCCESS,
    CHANGE_BRAND_SETTINGS_PAGE_SUCCESS,
    RESET_BRAND_SETTINGS_ALERTS_ERRORS,
    SET_EDIT_BRAND_SETTINGS,
    GET_SMARTICO_SETTINGS,
    GET_SMARTICO_SETTINGS_SUCCESS,
    GET_SMARTICO_SETTINGS_ERROR,
    GET_BRAND_TAGS,
    GET_BRAND_TAGS_SUCCESS,
    GET_BRAND_TAGS_ERROR,
    GET_ANTI_FRAUD_RULES,
    GET_ANTI_FRAUD_RULES_SUCCESS,
    GET_ANTI_FRAUD_RULES_ERROR,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS,
    UPDATE_ANTI_FRAUD_RULES,
    UPDATE_ANTI_FRAUD_RULES_SUCCESS,
    UPDATE_ANTI_FRAUD_RULES_ERROR,
    FETCH_VIP_LEVEL_CONFIG,
    FETCH_VIP_LEVEL_CONFIG_SUCCESS,
    GET_COOL_OFF_DATA,
    GET_COOL_OFF_DATA_SUCCESS,
    GET_COOL_OFF_DATA_ERROR,
    UPDATE_COOL_OFF_DATA,
    UPDATE_COOL_OFF_DATA_SUCCESS,
    UPDATE_COOL_OFF_DATA_ERROR,
    DELETE_COOL_OFF_DATA,
    DELETE_COOL_OFF_DATA_SUCCESS,
    DELETE_COOL_OFF_DATA_ERROR,
} from './actionTypes';

export const fetchBrandSettingsPage = () => ({
    type: FETCH_BRAND_SETTINGS_PAGE
});

export const fetchBrandSettingsPageSuccess = response => ({
    type: FETCH_BRAND_SETTINGS_PAGE_SUCCESS,
    payload: response
});

export const fetchVipLevelConfig = () => ({
    type: FETCH_VIP_LEVEL_CONFIG,
});

export const fetchVipLevelConfigSuccess = response => ({
    type: FETCH_VIP_LEVEL_CONFIG_SUCCESS,
    payload: response
});

export const brandSettingsPageError = error => ({
    type: BRAND_SETTINGS_PAGE_ERROR,
    payload: error
});

export const changeBrandSettingsPage = (data, successMessage) => ({
    type: CHANGE_BRAND_SETTINGS_PAGE,
    payload: { data, successMessage }
});

export const changeBrandSettingsPageSuccess = (response, successMessage) => ({
    type: CHANGE_BRAND_SETTINGS_PAGE_SUCCESS,
    payload: { response, successMessage }
});

export const resetBrandSettingsAlertsErrors = response => ({
    type: RESET_BRAND_SETTINGS_ALERTS_ERRORS,
    payload: response
});

export const setEditBrandSettings = response => ({
    type: SET_EDIT_BRAND_SETTINGS,
    payload: response
});

export const getSmarticoSettings = () => ({
    type: GET_SMARTICO_SETTINGS
});

export const getSmarticoSettingsSuccess = response => ({
    type: GET_SMARTICO_SETTINGS_SUCCESS,
    payload: response
});

export const getSmarticoSettingsError = error => ({
    type: GET_SMARTICO_SETTINGS_ERROR,
    payload: error
});

export const getBrandTags = () => ({
    type: GET_BRAND_TAGS,
});

export const getBrandTagsSuccess = response => ({
    type: GET_BRAND_TAGS_SUCCESS,
    payload: response,
});

export const getBrandTagsError = error => ({
    type: GET_BRAND_TAGS_ERROR,
    payload: error,
});

export const getAntiFraudRules = () => ({
    type: GET_ANTI_FRAUD_RULES,
});

export const getAntiFraudRulesSuccess = response => ({
    type: GET_ANTI_FRAUD_RULES_SUCCESS,
    payload: response,
});

export const getAntiFraudRulesError = error => ({
    type: GET_ANTI_FRAUD_RULES_ERROR,
    payload: error,
});

export const getAntiFraudActions = () => ({
    type: GET_AVAILABLE_ANTI_FRAUD_ACTIONS,
});

export const getAntiFraudActionsSuccess = response => ({
    type: GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS,
    payload: response,
});

export const getAntiFraudActionsError = error => ({
    type: GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR,
    payload: error,
});

export const updateAntiFraudRules = payload => ({
    type: UPDATE_ANTI_FRAUD_RULES,
    payload
});

export const updateAntiFraudRulesSuccess = payload => ({
    type: UPDATE_ANTI_FRAUD_RULES_SUCCESS,
    payload
});

export const updateAntiFraudRulesFail = error => ({
    type: UPDATE_ANTI_FRAUD_RULES_ERROR,
    payload: error
});


export const getCoolOffData = () => ({
    type: GET_COOL_OFF_DATA,
});

export const getCoolOffDataSuccess = response => ({
    type: GET_COOL_OFF_DATA_SUCCESS,
    payload: response,
});

export const getCoolOffDataError = error => ({
    type: GET_COOL_OFF_DATA_ERROR,
    payload: error,
});

export const updateCoolOffData = payload => ({
    type: UPDATE_COOL_OFF_DATA,
    payload
});

export const updateCoolOffDataSuccess = response => ({
    type: UPDATE_COOL_OFF_DATA_SUCCESS,
    payload: response,
});

export const updateCoolOffDataError = error => ({
    type: UPDATE_COOL_OFF_DATA_ERROR,
    payload: error,
});

export const deleteCoolOffData = payload => ({
    type: DELETE_COOL_OFF_DATA,
    payload
});

export const deleteCoolOffDataSuccess = response => ({
    type: DELETE_COOL_OFF_DATA_SUCCESS,
    payload: response,
});

export const deleteCoolOffDataError = error => ({
    type: DELETE_COOL_OFF_DATA_ERROR,
    payload: error,
});