import { useHistory } from "react-router-dom";
import './RestrictionErrorPage.scss';
import { useTranslation } from "react-i18next";

export const RestrictionErrorPage = (props) => {
    const {
        title,
        errorMsg,
        buttonText,
    } = props;

    const history = useHistory();
    const { t } = useTranslation();

    const goBack = () => {
        history.push('/player-list')
    }


    return (
        <div className="page-content">
            <div className='restriction-error'>
                <h4 className='restriction-error-msg'>
                    {errorMsg
                        ? t(`crm.${errorMsg}`)
                        : t('crm.accessRestricted')}
                </h4>
                <button
                    className='btn btn-rounded btn-primary restriction-error-btn'
                    onClick={goBack}
                >
                    {buttonText
                        ? t(`crm.${buttonText}`)
                        : t('crm.goBack')}
                </button>
            </div>
        </div>
    )
}

export default RestrictionErrorPage;