import i18n from "../../i18n";

export const nameConfiguration = {
    MaxWinningLimit: "Bonus Limit",
    MaxWinPerSpin: "Max Win Per Spin",
    MaxContributionPerSpin: "Max Wagering Amount",
    RedeemableThreshold: "Redeemable Threshold",
    BonusLimit: "Deposit Initial Limit",
    BonusAmount: "Bonus Amount",
    FreeMoneyAmount: "Free Money Amount",
    FreeSpinsWorth: "Spins Worth",
    BonusLimitFreeSpins: "Bonus Limit",
};

const maxWinPerSpin = (currencies) => ({
    label: i18n.t('promotions.maxWinPerSpin'),
    nameConfiguration: nameConfiguration.MaxWinPerSpin,
    tooltipText: i18n.t('promotions.maxWinPerSpin.tooltip'),
    currencies: currencies
});

const maxWinningLimit = (currencies) => ({
    label:  i18n.t('promotions.maxWinningLimit'),
    nameConfiguration: nameConfiguration.MaxWinningLimit,
    tooltipText: i18n.t('promotions.maxWinningLimit.tooltip'),
    currencies: currencies
});

const maxContributionPerSpin = (currencies) => ({
    label:  i18n.t('promotions.maxContributionPerSpin'),
    nameConfiguration: nameConfiguration.MaxContributionPerSpin,
    tooltipText: i18n.t('promotions.maxContributionPerSpin.tooltip'),
    currencies: currencies
});

const redeemableThreshold = (currencies) => ({
    label:  i18n.t('promotions.redeemableThreshold'),
    nameConfiguration:  nameConfiguration.RedeemableThreshold,
    tooltipText: i18n.t('promotions.redeemableThreshold.tooltip'),
    currencies: currencies
});

const bonusLimit = (currencies) => ({
    label:  i18n.t('promotions.depositInitialLimit'),
    nameConfiguration: nameConfiguration.BonusLimit,
    tooltipText: i18n.t('promotions.depositInitialLimit.tooltip'),
    currencies: currencies,
});

const bonusAmount = (currencies) => ({
    label: i18n.t('promotions.bonusAmount'),
    nameConfiguration: nameConfiguration.BonusAmount,
    tooltipText: i18n.t('promotions.bonusAmount.tooltip'),
    currencies: currencies
});

export const configurationTable_BONUS_WAGERING_ON_DEPOSIT = (currencies = []) => [
    { ...maxWinningLimit(currencies) },
    { ...maxWinPerSpin(currencies) },
    { ...maxContributionPerSpin(currencies) },
    { ...redeemableThreshold(currencies) },
    { ...bonusLimit(currencies)}
];

export const configurationTable_BONUS_WAGERING = (currencies = []) => [
    {
        label:  i18n.t('promotions.freeMoneyAmount'),
        nameConfiguration: nameConfiguration.FreeMoneyAmount,
        tooltipText: i18n.t('promotions.freeMoneyAmount.tooltip'),
        currencies: currencies
    },

    { ...maxWinningLimit(currencies) },
    { ...maxWinPerSpin(currencies) },
    { ...maxContributionPerSpin(currencies) },
    { ...redeemableThreshold(currencies) },
];

export const configurationTableDataMockFreeSpin = (currencies = []) => [
    {
        label:  i18n.t('promotions.spinWorth'),
        nameConfiguration: nameConfiguration.FreeSpinsWorth,
        tooltipText: i18n.t('promotions.spinWorth.tooltip'),
        currencies: currencies
    },
    { ...maxWinPerSpin(currencies) },
    {
        label:  i18n.t('promotions.bonusLimit.FreeSpins'),
        nameConfiguration: nameConfiguration.BonusLimitFreeSpins,
        tooltipText: i18n.t('promotions.maxWinFS.tooltip'),
        currencies: currencies
    },
];

export const columns = (currencies = []) => [
    { label:  i18n.t('promotions.tableConfiguration') },
    ...currencies.map((currency) => ({label: currency})),
];

export const configurationTable_BONUS_AMOUNT = (currencies = []) => [
    { ...bonusAmount(currencies) },
];
