import _ from "lodash";
import moment from "moment";
import {nameConfiguration} from "./ConfigurationTable";
import {PROMOTION_TYPE, REWARD_TYPE} from "./common/promotionConstants";

const prepareRequestData = (data, model, timePromo) => {
    let {playerPromotionIds, ...preparedData} = _.cloneDeep(data);

    const {
        settings,
        segmentation,
    } = preparedData;

    let {configuration} = settings;
    const {promotionType} = model;

    configuration = {
        ...configuration,
        optInRequired: model.optIn,
        allowMultipleOptIn: model.allowMultipleOptIn,
        ...(promotionType === PROMOTION_TYPE.BONUS_WAGERING && {
            funBonus: model.funBonus,
        }),
        ...( promotionType === PROMOTION_TYPE.FREE_SPINS && {
            provider: model.provider,
            templateCode: model.templateCode,
            numberOfSpins: +model.numberOfSpins,
            betExposureMultiplier: +model.betExposureMultiplier,
            rewards: [{
                type: model.rewardType,
                configuration: {
                    ...(model.rewardType === REWARD_TYPE.PrizeWagering
                        ? {promotionId: model.rewardPromoId}
                        : {}),
                },
            }],
        } || {} ),
        ...( promotionType !== PROMOTION_TYPE.FREE_SPINS && {
            wageringMultiplier: +model.wageringMultiplier,
            postDepositWageringRequired: model.postDepositWageringRequired,
            nonRedeemableBonus: model.nonRedeemableBonus,
        } || {} ),
        ...( promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT && {
            wageringType: model.wageringType,
            bonusPercentage: +model.bonusPercentage,
        } || {} ),
    };

    {
        configuration.maxWinPerSpin = {};
        model.currencies.forEach(currency => {
            configuration.maxWinPerSpin[`${currency}`] =
                +model[`${nameConfiguration.MaxWinPerSpin}_${currency}`] || undefined;
        });
    }

    if (promotionType === PROMOTION_TYPE.FREE_SPINS) {
        configuration.spinWorth = {};
        model.currencies.forEach(currency => {
            configuration.spinWorth[`${currency}`] =
                +model[`${nameConfiguration.FreeSpinsWorth}_${currency}`] || undefined;
        });

        configuration.bonusLimit = {};
        model.currencies.forEach(currency => {
            configuration.bonusLimit[`${currency}`] =
                +model[`${nameConfiguration.BonusLimitFreeSpins}_${currency}`] || undefined;
        });
    }

    // promotionType !== PROMOTION_TYPE.FREE_SPINS
    else {
        if (model.bonusLimitMultiplier) {
            configuration.bonusLimitMultiplier = +model.bonusLimitMultiplier || undefined;
            configuration.bonusLimit = '';
        } else {
            if (model.funBonus) {
                configuration.bonusLimit = 0;
            } else {
                configuration.bonusLimit = {};
                model.currencies.forEach(currency => {
                    configuration.bonusLimit[`${currency}`] =
                        +model[`${nameConfiguration.MaxWinningLimit}_${currency}`] || undefined;
                });
            }
            configuration.bonusLimitMultiplier = '';
        }

        {
            configuration.maxWageringAmount = {};
            model.currencies.forEach(currency => {
                configuration.maxWageringAmount[`${currency}`] =
                    +model[`${nameConfiguration.MaxContributionPerSpin}_${currency}`] || undefined;
            });
        }

        {
            configuration.redeemableThreshold = {};
            model.currencies.forEach(currency => {
                const value = model[`${nameConfiguration.RedeemableThreshold}_${currency}`];
                configuration.redeemableThreshold[`${currency}`] =
                    value === '' ? undefined : +value;
            });
        }

        if (promotionType === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT) {
            configuration.depositInitialMaxAmount = {};
            model.currencies.forEach(currency => {
                configuration.depositInitialMaxAmount[`${currency}`] =
                    +model[`${nameConfiguration.BonusLimit}_${currency}`] || undefined;
            });
        }
        // promotionType === PROMOTION_TYPE.BONUS_WAGERING
        else {
            configuration.amount = {};
            model.currencies.forEach(currency => {
                configuration.amount[`${currency}`] =
                    +model[`${nameConfiguration.FreeMoneyAmount}_${currency}`] || undefined
            })
        }
    }

    preparedData = {
        ...preparedData,
        name: model.name || "New Promotion",
        status: model.status,
        slugLink: model.slugLink,
        schedule: {
            startAt: moment(timePromo.startTime).valueOf(),
            finishAt: moment(timePromo.endTime).valueOf(),
            timeZone: model.timeZone,
        },
        settings: {
            ...settings,
            type: promotionType,
            validityDays: +model.days_expiration,
            configuration,
        },
        segmentation: {
            ...segmentation,
            currencies: model.currencies,
            enterCondition: model.enterCondition,
            games: model.listOfGames,
            players: model.listOfPlayers,
        }
    };

    // TODO: Tmp code for removing this field in old promo (WSS-285 10.01.2023)
    if ('freeSpinsWorth' in preparedData) {
        delete preparedData['freeSpinsWorth'];
    }
    // TODO: Tmp code for removing this field in old promo (WSS-381 12.10.2023)
    if ('promoCode' in preparedData) {
        delete preparedData.promoCode;
    }

    delete preparedData['playerPromotions'];

    return preparedData;
};


export default prepareRequestData;
