import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import SwitchCheckboxInput from '../../../common/inputs/SwitchCheckboxInput';
import { EMAIL_REG_EXP } from '../../../constants/validations';
import ButtonsPanel from './ButtonsPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPlayerCommunication,
    getPlayerCommunicationEmailList,
    getPlayerCommunicationPhoneList,
    getPlayerEmail,
    getPlayerPhone,
    resetPlayerCommunicationAlertsErrors,
    resetPlayerCommunicationEmailListErrors,
    resetPlayerCommunicationPhoneListErrors,
    sendCodeEmailPlayerCommunication,
    sendCodePhonePlayerCommunication,
    setEditPlayerCommunication,
    updatePlayerCommunication,
} from '../../../store/actions';
import Loader from '../../../common/components/Loader';
import useAlertService from '../../../hooks/useAlertService';
import Formatter from '../../../utils/Formatter';
import {
    prepareUpdatePlayerCommunicationDto,
    prepareUpdatePlayerCommunicationFieldErrors
} from './model/playerEditModel';
import useList from '../../../hooks/useList';
import PropTypes from 'prop-types';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import NewCustomTable from '../../../components/NewCustomTable';
import { editEmail, editPhone } from '../../../utils/apiHooks';
import CustomPagination from "../../../common/tables/CustomPagination";
import { DATE_TIME_FORMAT } from "../../../common/utils/common";
import { useTranslation } from "react-i18next";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { useTimeContext } from "../../../context/TimeContext";
import saveFile from "../../../assets/images/common/saveFile.svg";


function PhoneTable({ list }) {
    const { i18n } = useTranslation();
    const { timeFormat } = useTimeContext();

    const columns = [
        { label: i18n.t('crm.date') },
        { label: i18n.t('crm.phone') },
        { label: i18n.t('crm.code') },
        { label: i18n.t('crm.by') },
        { label: i18n.t('crm.ip') }
    ];

    return (
        <>
            {!list ||
                (list.length === 0 && <div>{i18n.t('crm.noCodesSent')}</div>)}
            {list && list.length > 0 && (
                <NewCustomTable columns={columns}>
                    {list.map((item, index) => (
                        <tr key={index}>
                            <td>{Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.sentDate))}</td>
                            <td>{item.method}</td>
                            <td>{item.code}</td>
                            <td>{item.by}</td>
                            <td>{item.ip ? item.ip : '-'}</td>
                        </tr>
                    ))}
                </NewCustomTable>
            )}
        </>
    );
}

function EmailTable({ list }) {
    const { i18n } = useTranslation();
    const { timeFormat } = useTimeContext();

    const columns = [
        { label: i18n.t('crm.date') },
        { label: i18n.t('crm.email') },
        { label: i18n.t('crm.code') },
        { label: i18n.t('crm.by') },
        { label: i18n.t('crm.ip') }
    ];

    return (
        <>
            {!list ||
                (list.length === 0 && <div>{i18n.t('crm.noCodesSent')}</div>)}
            {list && list.length > 0 && (
                <NewCustomTable columns={columns}>
                    {list.map((item, index) => (
                        <tr key={index}>
                            <td>{Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.sentDate))}</td>
                            <td>{item.method}</td>
                            <td>{item.code}</td>
                            <td>{item.by}</td>
                            <td>{item.ip ? item.ip : '-'}</td>
                        </tr>
                    ))}
                </NewCustomTable>
            )}
        </>
    );
}

function CommunicationTabContent({ playerId }) {
    const { i18n } = useTranslation();
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { agentSelf } = useSelector(state => state.AgentSelf);

    const {
        isLoadingCommunication,
        errorCommunication,
        communication,

        isLoadingPhoneList,
        errorPhoneList,
        phoneList,
        totalPhoneList,
        queryPhoneList,

        isLoadingEmailList,
        errorEmailList,
        emailList,
        totalEmailList,
        queryEmailList,

        successAlert
    } = useSelector(state => state.PlayerCommunication);

    const {
        email: playerEmail,
        phone: playerPhone,
        isLoadedEmail,
        isLoadedPhone,
    } = useSelector(state => state.PlayerGeneral);
    const { baseUserPhone, otherPhone } = playerPhone;

    const {
        pageCount: pageCountPhoneList,
        activePage: activePagePhoneList,
        handleChangePage: handleChangePhoneListPage
    } = useList(
        query =>
            dispatch(
                getPlayerCommunicationPhoneList({ id: playerId, query })
            ),
        totalPhoneList,
        queryPhoneList
    );

    const {
        pageCount: pageCountEmailList,
        activePage: activePageEmailList,
        handleChangePage: handleChangeEmailListPage
    } = useList(
        query =>
            dispatch(
                getPlayerCommunicationEmailList({ id: playerId, query })
            ),
        totalEmailList,
        queryEmailList
    );

    // State to manage communication info
    const [communicationModel, setCommunicationModel] = useState(
        JSON.parse(JSON.stringify(communication))
    );
    const [fieldErrors, setFieldErrors] = useState({});

    const [phones, setPhones] = useState({
        phone: baseUserPhone,
        otherPhone: otherPhone,
        phoneChanged: false,
        otherPhoneChanged: false
    });
    const [isShowOtherPhone, setIsShowOtherPhone] = useState(false);
    const [email, setEmail] = useState('');
    const [emailChanged, setEmailChanged] = useState(false);

    const handleChangeSecure = (field) => (event) => {
        if (field === 'phone' || field === 'otherPhone') {
            setPhones(prevPhones => ({
                ...prevPhones,
                [field]: event.replace(/[^0-9\+]+/, ''),
                [`${field}Changed`]: true
            }));
            handleSetEdit(true);
        }

        if (field === "email") {
            if (event === playerEmail) {
                setEmailChanged(false);
            } else {
                setEmailChanged(true);
                handleSetEdit(true);
            }
            setEmail(event);
        }
    }

    useEffect(() => {
        setEmail(playerEmail);
    },[playerEmail]);

    useEffect(() => {
        setPhones({
            phone: baseUserPhone,
            otherPhone: otherPhone,
        });
    },[playerPhone]);

    useEffect(() => {
        dispatch(getPlayerCommunication(playerId));
        dispatch(
            getPlayerCommunicationPhoneList({
                id: playerId,
                query: queryPhoneList
            })
        );
        dispatch(
            getPlayerCommunicationEmailList({
                id: playerId,
                query: queryEmailList
            })
        );
    }, [playerId]);

    useEffect(() => {
        setCommunicationModel(JSON.parse(JSON.stringify(communication)));
    }, [communication]);

    useEffect(() => {
        if (errorCommunication && errorCommunication.statusCode === 400) {
            setFieldErrors(
                prepareUpdatePlayerCommunicationFieldErrors(
                    errorCommunication?.data
                )
            );
            dispatch(resetPlayerCommunicationAlertsErrors());
        } else if (errorCommunication) {
            alertService.showError(errorCommunication);
            dispatch(resetPlayerCommunicationAlertsErrors());
        } else if (errorPhoneList) {
            alertService.showError(errorPhoneList);
            dispatch(resetPlayerCommunicationPhoneListErrors());
        } else if (errorEmailList) {
            alertService.showError(errorEmailList);
            dispatch(resetPlayerCommunicationEmailListErrors());
        }
    }, [errorCommunication, errorPhoneList, errorEmailList]);

    useEffect(() => {
        if (successAlert) {
            setEmailChanged(false);
            alertService.showSuccess(successAlert);
            dispatch(resetPlayerCommunicationAlertsErrors());
        }
    }, [successAlert]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setEditPlayerCommunication(nextIsEdit));
    };

    const handleChange = useCallback(
        name => {
            return value => {
                setCommunicationModel(prevState => ({
                    ...prevState,
                    [name]: value
                }));
                handleSetEdit(true);
            };
        },
        [communicationModel, handleSetEdit]
    );

    const handleSave = () => {
        try {
            const dto = prepareUpdatePlayerCommunicationDto(
                communication,
                communicationModel
            );
            handleSetEdit(false);
            dispatch(updatePlayerCommunication({ id: playerId, dto }));
        } catch (error) {
            console.error('Error: ', error);
            alertService.showError(error);
        }
    }

    const hasPhoneChanged = useMemo(() => {
        return phones.phoneChanged || phones.otherPhoneChanged;
    }, [phones.phoneChanged, phones.otherPhoneChanged])

    const handleCancel = useCallback(() => {
        setCommunicationModel(JSON.parse(JSON.stringify(communication)));
        setPhones({
            phone: baseUserPhone,
            otherPhone: otherPhone,
            phoneChanged: false,
            otherPhoneChanged: false
        });
        setEmail(playerEmail);
    }, [communication, baseUserPhone, playerEmail, otherPhone]);

    const handleSendPhoneCode = () =>
        dispatch(sendCodePhonePlayerCommunication(playerId));

    const handleSendEmailCode = () =>
        dispatch(sendCodeEmailPlayerCommunication(playerId));

    const isEdit = useMemo(() => {
        if (!communication || !communicationModel) {
            return false;
        }

        const dto = prepareUpdatePlayerCommunicationDto(
            communication,
            communicationModel
        );

        if (Object.keys(dto).length <= 0) {
            handleSetEdit(false);
        }

        return Object.keys(dto).length > 0;
    }, [communication, communicationModel]);

    if (!communication || !communicationModel) {
        return <Loader size={'lg'}/>;
    }

    const agentPermissions = agentSelf.permissions;
    const canEditEmail = agentPermissions.includes(SITE_PERMISSION.Player__Edit_Email) &&
        agentPermissions.includes(SITE_PERMISSION.Player__Edit_Player_Info)
    const canEditPhone = agentPermissions.includes(SITE_PERMISSION.Player__Edit_Phone) &&
        agentPermissions.includes(SITE_PERMISSION.Player__Edit_Player_Info);
    const canEditCommunication = agentPermissions?.includes(SITE_PERMISSION.Player__Edit_Newsletters);

    const handleEditEmail = async () => {
        if (!EMAIL_REG_EXP.test(email)) {
            return alertService.showError(i18n.t('crm.alerts.emailIsIncorrect'));
        } else {
            const emailResponse = await editEmail(playerId, email, alertService);
            if (emailResponse) {
                dispatch(getPlayerEmail(playerId));
                setEmailChanged(false);
                handleSetEdit(false);
            }
        }
    }

    const handleEditPhone = async () => {
       if (phones?.phone !== '') {
           const phoneResponse = await editPhone(playerId, phones, alertService);
           if (phoneResponse) {
               dispatch(getPlayerPhone(playerId));
           }
       } else {
           return alertService.showError(i18n.t('crm.phoneRequired'));
       }
       handleSetEdit(false);
    }

    return (
        <>
            <div className='detailed-card__content communication'>
                <div className='overflow-auto'>
                    <div className='d-flex flex-wrap'>
                        <div className='me-3 mb-2 d-flex flex-column align-items-center email-field'>
                            <ACommonDataField
                                onLoadSecureField={() => {
                                    dispatch(getPlayerPhone(playerId))
                                    setIsShowOtherPhone(true);
                                }}
                                isLoadedSecureField={isLoadedPhone}
                                label={i18n.t('crm.phoneNumberShort')}
                                secureFieldLabel={i18n.t('crm.phoneNumbers')}
                                handleChange={handleChangeSecure("phone")}
                                error={
                                    fieldErrors?.phone
                                        ? i18n.t(`errors.${fieldErrors?.phone}`)
                                        : ''
                                }
                                disabled={!canEditPhone}
                                value={phones.phone}
                            />
                            {isShowOtherPhone && isLoadedPhone &&
                                <ACommonDataField
                                    label={i18n.t('crm.phoneNumberSecond')}
                                    value={phones.otherPhone || ''}
                                    handleChange={handleChangeSecure("otherPhone")}
                                    disabled={!canEditPhone}
                                />
                            }
                        </div>
                        {!hasPhoneChanged ? (
                            <div className='toggle-separator'></div>
                        ) : (
                            <div className='d-flex align-items-center'>
                                <img
                                    src={saveFile}
                                    onClick={handleEditPhone}
                                    alt='Save phones'
                                />
                            </div>
                        )}

                        <div className='communication-toggles'>
                            <div
                                className={`d-flex align-items-center ${
                                    fieldErrors?.isPhoneVerified ? 'has-error' : ''
                                }`}
                            >
                                <SwitchCheckboxInput
                                    checked={communicationModel.isPhoneVerified}
                                    onChange={handleChange('isPhoneVerified')}
                                    additionalClassName='ms-2 me-3 mb-2 d-flex align-items-center'
                                    disabled={!canEditCommunication}
                                >
                                    {i18n.t('crm.verified')}
                                </SwitchCheckboxInput>
                            </div>

                            <div
                                className={`d-flex align-items-center ${
                                    fieldErrors?.isPhoneNewsletter
                                        ? 'has-error'
                                        : ''
                                }`}
                            >
                                <SwitchCheckboxInput
                                    checked={communicationModel.isPhoneNewsletter}
                                    onChange={handleChange('isPhoneNewsletter')}
                                    additionalClassName='mb-2 d-flex align-items-center'
                                    disabled={!canEditCommunication}
                                >
                                    {i18n.t('crm.newsletter')}
                                </SwitchCheckboxInput>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap'>
                        {communicationModel?.communicationVerificationPhoneTime &&
                            <p>
                                {i18n.t('crm.phoneVerificationPhoneTime')}
                                <b>
                                    {" - "}
                                    {moment(
                                        new Date(communicationModel?.communicationVerificationPhoneTime))
                                        .format(DATE_TIME_FORMAT)
                                    }
                                </b>
                            </p>
                        }
                    </div>

                    {phoneList ? <PhoneTable list={phoneList}/> : <Loader size={'lg'}/>}

                    <br/>

                    {(pageCountPhoneList > 1) &&
                        <CustomPagination
                            isDisabled={isLoadingPhoneList}
                            pageCount={pageCountPhoneList}
                            activePage={activePagePhoneList}
                            onChange={handleChangePhoneListPage}
                        />
                    }
                </div>

                <div className='vertical-separator'/>

                <div className='overflow-auto'>
                    <div className='d-flex flex-wrap'>
                        <div className='me-3 mb-2 d-flex align-items-center email-field'>
                            <ACommonDataField
                                onLoadSecureField={() => dispatch(getPlayerEmail(playerId))}
                                isLoadedSecureField={isLoadedEmail}
                                label={i18n.t('crm.email')}
                                handleChange={handleChangeSecure("email")}
                                error={
                                    fieldErrors?.email
                                        ? i18n.t(`errors.${fieldErrors?.email}`)
                                        : ''
                                }
                                disabled={!canEditEmail}
                                value={email}
                            />
                            {emailChanged &&
                                <img
                                    src={saveFile}
                                    className='save-communic-field'
                                    onClick={handleEditEmail}
                                    alt='Save password'
                                />
                            }
                        </div>

                        <div className='communication-toggles'>
                            <div
                                className={`d-flex align-items-center ${
                                    fieldErrors?.isEmailVerified ? 'has-error' : ''
                                }`}
                            >
                                <SwitchCheckboxInput
                                    checked={communicationModel.isEmailVerified}
                                    onChange={handleChange('isEmailVerified')}
                                    additionalClassName='ms-2 me-3 mb-2 d-flex align-items-center'
                                    disabled={!canEditCommunication}
                                >
                                    {i18n.t('crm.verified')}
                                </SwitchCheckboxInput>
                            </div>
    
                            <div
                                className={`d-flex align-items-center ${
                                    fieldErrors?.isEmailNewsletter
                                        ? 'has-error'
                                        : ''
                                }`}
                            >
                                <SwitchCheckboxInput
                                    checked={communicationModel.isEmailNewsletter}
                                    onChange={handleChange('isEmailNewsletter')}
                                    additionalClassName='mb-2 d-flex align-items-center'
                                    disabled={!canEditCommunication}
                                >
                                    {i18n.t('crm.newsletter')}
                                </SwitchCheckboxInput>
                            </div>
                        </div>
                    </div>

                    {emailList ? <EmailTable list={emailList} /> : <Loader size={'lg'}/>}

                    <br />

                    {(pageCountPhoneList > 1) &&
                        <CustomPagination
                            isDisabled={isLoadingEmailList}
                            pageCount={pageCountEmailList}
                            activePage={activePageEmailList}
                            onChange={handleChangeEmailListPage}
                        />
                    }
                </div>
            </div>

            {isEdit && (
                <ButtonsPanel
                    isDisabled={isLoadingCommunication}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
}

CommunicationTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(CommunicationTabContent);
