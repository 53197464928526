export const GET_FILTERS_LIST_PAGE = 'GET_FILTERS_LIST_PAGE';
export const GET_FILTERS_LIST_PAGE_SUCCESS = 'GET_FILTERS_LIST_PAGE_SUCCESS';

export const REMOVE_FROM_FILTERS_PAGE = 'REMOVE_FROM_FILTERS_PAGE';
export const REMOVE_FROM_FILTERS_PAGE_SUCCESS =
    'REMOVE_FROM_FILTERS_PAGE_SUCCESS';

export const EDIT_FILTER_PAGE = 'EDIT_FILTER_PAGE';
export const EDIT_FILTER_PAGE_SUCCESS = 'EDIT_FILTER_PAGE_SUCCESS';

export const GET_FILTER_PAGE_ERROR = 'GET_FILTER_PAGE_ERROR';
