import {
    CREATE_MANUAL_ADJUSTMENT,
    CREATE_RETAIL_MANUAL_ADJUSTMENT,
    CREATE_MANUAL_ADJUSTMENT_SUCCESS,
    CREATE_MANUAL_ADJUSTMENT_FAIL,
    RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS,
    MASS_CASHBACK_APPLY,
    MASS_CASHBACK_APPLY_SUCCESS,
    MASS_CASHBACK_APPLY_FAIL,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL,
    MASS_INACTIVITY_FEE_APPLY,
    MASS_INACTIVITY_FEE_APPLY_SUCCESS,
    MASS_INACTIVITY_FEE_APPLY_FAIL, RESET_MASS_ADJUSTMENT_ERRORS,
} from './actionTypes';

export const createManualAdjustment = payload => ({
    type: CREATE_MANUAL_ADJUSTMENT,
    payload
});

export const createRetailManualAdjustment = payload => ({
    type: CREATE_RETAIL_MANUAL_ADJUSTMENT,
    payload
});

export const createManualAdjustmentSuccess = payload => ({
    type: CREATE_MANUAL_ADJUSTMENT_SUCCESS,
    payload
});

export const createManualAdjustmentFail = error => ({
    type: CREATE_MANUAL_ADJUSTMENT_FAIL,
    payload: error
});

export const resetManualAdjustmentAlertsErrors = payload => ({
    type: RESET_MANUAL_ADJUSTMENT_ALERTS_ERRORS,
    payload
});

export const massCashbackApply = payload => ({
    type: MASS_CASHBACK_APPLY,
    payload
});

export const massCashbackApplySuccess = payload => ({
    type: MASS_CASHBACK_APPLY_SUCCESS,
    payload
});

export const massCashbackApplyFail = error => ({
    type: MASS_CASHBACK_APPLY_FAIL,
    payload: error
});

export const cashbackApplySuccess = payload => ({
    type: CASHBACK_APPLY_SUCCESS,
    payload
});

export const cashbackApplyFail = error => ({
    type: CASHBACK_APPLY_FAIL,
    payload: error
});

export const downloadTemplateInactivityFee = payload => ({
    type: DOWNLOAD_INACTIVITY_FEE_TEMPLATE,
    payload
});

export const downloadTemplateInactivityFeeSuccess = payload => ({
    type: DOWNLOAD_INACTIVITY_FEE_TEMPLATE_SUCCESS,
    payload
});

export const downloadTemplateInactivityFeeFail = error => ({
    type: DOWNLOAD_INACTIVITY_FEE_TEMPLATE_FAIL,
    payload: error
});

export const massInactivityFeeApply = payload => ({
    type: MASS_INACTIVITY_FEE_APPLY,
    payload
});

export const massInactivityFeeApplyFeeSuccess = payload => ({
    type: MASS_INACTIVITY_FEE_APPLY_SUCCESS,
    payload
});

export const massInactivityFeeApplyFail = error => ({
    type: MASS_INACTIVITY_FEE_APPLY_FAIL,
    payload: error
});

export const resetErrorsMassAdjustment = payload => ({
    type: RESET_MASS_ADJUSTMENT_ERRORS,
    payload
});