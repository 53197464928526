import { Modal, ModalBody, ModalFooter } from "reactstrap"
import i_51 from "../../../assets/images/common/Frame 176 (1).svg"
import React from "react"
import './DisabledGamesModal.scss';
import { useTranslation } from "react-i18next"
import SwitchCheckboxInput from "../../../common/inputs/SwitchCheckboxInput"

const DisabledGamesModal = (props) => {
  const {
    isOpen,
    invalidGameIds,
    toggleModal,
    setState,
    state,
    setIsEdit,
  } = props;

  const { i18n } = useTranslation();

  const onToggleChange = (gameId, isChecked) => {
    setState(prevState => {
      let updatedListOfGames = [...prevState.model.listOfGames];

      if (isChecked) {
        if (!updatedListOfGames.includes(gameId)) {
          updatedListOfGames.push(gameId);
        }
      } else {
        updatedListOfGames = updatedListOfGames.filter(id => id !== gameId);
      }
      setIsEdit(true);

      return {
        ...prevState,
        model: {
          ...prevState.model,
          listOfGames: updatedListOfGames
        }
      };
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      data-toggle="modal"
      className="new-bonus-modal disabled-games-modal"
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title text-uppercase">
          {i18n.t('crm.manageDisabledGames')}
        </h5>
        <button className="close" onClick={toggleModal} />
      </div>

      <ModalBody>
        <div className='description-disabled-games'>
          <span>{i18n.t('crm.modalDescriptionDisabledGames')}</span>
        </div>
        <div className="disabled-games-list">
          {invalidGameIds.map((gameId, index) => (
            <SwitchCheckboxInput
              key={index}
              checked={state?.model?.listOfGames?.includes(gameId)}
              onChange={(isChecked) => onToggleChange(gameId, isChecked)}
              additionalClassName='mb-3 d-flex align-items-center switch-checkbox-reverse'
            >
              {gameId}
            </SwitchCheckboxInput>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="footer-buttons">
          <button
            className="btn btn-primary btn-rounded w-100"
            onClick={toggleModal}
          >
            <img src={i_51} alt="" />
            {i18n.t('crm.submit')}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DisabledGamesModal;