import React, { useEffect, useMemo, useState } from 'react';
import clsx from "clsx";

import PlayerData from '../../../common/cellData/PlayerData';

import NewCustomTable from '../../../components/NewCustomTable';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    getGuidShortFormat,
    getColumnSettingsLsKey,
    initTableColumns,
} from '../../../common/utils/common';
import CopyButton from '../../../common/cellData/CopyButton';
import CheckboxInput from '../../../common/inputs/common/CheckboxInput';
import useTableCheckbox from '../../../components/NewCustomTable/useTableCheckbox';

import positive from '../../../assets/images/common/positive.svg';
import negative from '../../../assets/images/common/negative.svg';
import { AMLCompliantStatus } from "../../../constants/status";
import CustomModal from "../../../common/components/CustomModal";
import DocumentView from "../DocumentView";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
import { useTimeContext } from "../../../context/TimeContext";
import { IdCell, PlayerCell } from "../../../components/NewCustomTable/CellsWithPermissions";
import { useSelector } from "react-redux";
import { SITE_PERMISSION } from "../../../common/constants/common";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";
import { getUserUrl } from '../../../components/NewCustomTable/CellsWithPermissions';

const COLUMN_IDS = {
    tdForCheckBox: 'tdForCheckBox',
    player: 'player',
    ID: 'ID',
    realBalance: 'realBalance',
    KYC: 'KYC',
    kycVerificationStatus: 'kycVerificationStatus',
    pendingReview: 'pendingReview',
};

const getColumnIds = (additionalColumns = []) => {
    const columnIds = {
        ...COLUMN_IDS,
    };

    additionalColumns.forEach((column) => columnIds[column.toLowerCase()] = column.toLowerCase());

    return columnIds;
};

const getDefaultColumns = (additionalColumns = []) => {
    return [
        COLUMN_IDS.player,
        COLUMN_IDS.ID,
        COLUMN_IDS.realBalance,
        COLUMN_IDS.KYC,
        COLUMN_IDS.kycVerificationStatus,
        COLUMN_IDS.pendingReview,
        ...additionalColumns.map((column) => column.toLowerCase()),
    ];
};

const getKeyI18NMap = (additionalColumns = []) => {
    const i18nMap = {
        [COLUMN_IDS.player]: 'crm.player',
        [COLUMN_IDS.realBalance]: 'crm.realBalance',
        [COLUMN_IDS.KYC]: 'crm.KYC',
        [COLUMN_IDS.kycVerificationStatus]: 'crm.kycVerificationStatus',
        [COLUMN_IDS.pendingReview]: 'crm.kyc.pendingReview',
    };

    additionalColumns.forEach((column) => i18nMap[column.toLowerCase()] = `crm.kyc.${column.toLowerCase()}`);

    return i18nMap;
};

const TdElem = ({
    item,
    field,
    timeFormat,
    isChecked = false,
    canViewPlayerInfo = false,
    handleSelectRow = () => {},
    handleOpenDocument = () => {},
    defaultColumns= [],
    i18n,
}) => {
    const size = useWindowSize();

    switch (field) {
        case COLUMN_IDS.tdForCheckBox:
            return <td className={'tdForCheckBox'}>
                <CheckboxInput
                    wrapperClassName="check-box-aml-unset wrapperCheckBok"
                    checked={isChecked}
                    onChange={handleSelectRow(item.id)}
                />
            </td>;
        case COLUMN_IDS.player:
            return <td style={{ overflow: 'visible' }}>
                 {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <Link to={getUserUrl('kyc', item.id)}><PlayerData player={item} /></Link>
                 ) : (
                    <PlayerCell
                        item={item}
                        url={{
                            type: 'kyc',
                            id: item.id
                        }}
                    />
                 )}
            </td>;
        case COLUMN_IDS.ID:
            return (
                size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('finance.playerId')}: </span>
                        )}
                        <IdCell
                            item={item}
                            url={{
                                type: 'kyc',
                                id: item.id
                            }}
                        />
                    </td>
                ) : (
                    <td className='kyc-id'>
                        <div className='kyc-id__item kyc-id__oid'>
                            <Link to={getUserUrl('kyc', item.id)}>{getGuidShortFormat(item.userId)}</Link>
                            <CopyButton
                                size={'19'}
                                className="column-cpy-button"
                                onClick={() =>
                                    navigator.clipboard.writeText(item.userId)
                                }
                            />
                            <div className='status-review'>{ i18n.t(`crm.verified.${item.isKYC}`) || '-'}</div>
                        </div>
                        <div className='kyc-id__item'>
                            <span>{i18n.t('finance.playerId')}: </span>
                            <div className='kyc-id__content'>
                                <Link to={getUserUrl('kyc', item.id)}>{item.id}</Link>
                                <CopyButton
                                    size={'19'}
                                    className="column-cpy-button"
                                    onClick={() =>
                                        navigator.clipboard.writeText(item.id)
                                    }
                                />
                            </div>
                        </div>
                    </td>
                )
            )
        case COLUMN_IDS.realBalance:
            return <td
                className={'text-center text-nowrap'}
                style={{ minWidth: '100px' }}
            >
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.realBalance')}: </span>
                )}
                {item.realBalance
                    ? Formatter.formatAmount(
                        new Intl.NumberFormat().format(item.realBalance),
                        item.currency)
                    : '-'
                }
            </td>;
        case COLUMN_IDS.KYC:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.kyc')}: </span>
                )}
                {item.KYC ? (
                    <img src={positive} alt="" />
                ) : (
                    <img src={negative} alt="" />
                )}
            </td>;
        case COLUMN_IDS.kycVerificationStatus:
            return (
                size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <td
                        className={canViewPlayerInfo && item.addressDoc && item.isKYC === AMLCompliantStatus.Review ? 'status-review' : ''}
                        onClick={
                            () => {
                                if (canViewPlayerInfo && item.addressDoc) {
                                    handleOpenDocument(item.addressDoc, item);
                                }
                            }
                        }
                    >
                        { i18n.t(`crm.verified.${item.isKYC}`) || '-'}
                    </td>
                )
            )
        case COLUMN_IDS.pendingReview:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.kyc.pendingReview')}: </span>
                )}
                {item.addressDoc?.createdAt
                    ? Formatter.displayDateTimeSeconds(
                        timeFormat.parseTime(item.addressDoc?.createdAt))
                    : '-'
                }
            </td>;
        default:
            if (defaultColumns.includes(field)) {
                let kycStatusValue;

                switch (field) {
                    case 'address':
                        kycStatusValue = item.kycStatus.Address;
                        break;
                    case 'age':
                        kycStatusValue = item.kycStatus.Age;
                        break;
                    case 'curp':
                        kycStatusValue = item.kycStatus.CURP;
                        break;
                    case 'identity':
                        kycStatusValue = item.kycStatus.Identity;
                        break;
                    case 'occupation':
                        kycStatusValue = item.kycStatus.Occupation;
                        break;
                    case 'rfc':
                        kycStatusValue = item.kycStatus.RFC;
                        break;
                    case 'sofagreement':
                        kycStatusValue = item.kycStatus.SOFAgreement;
                        break;
                    default:
                        kycStatusValue = false;
                }
                return (
                    <td>
                        {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                            <span>{i18n.t('crm.status')}: </span>
                        )}
                        {kycStatusValue ? (
                            <img src={positive} alt="" />
                        ) : (
                            <img src={negative} alt="" />
                        )}
                    </td>
                );
            }
            return null;
    }
};

function PlayersPendingKycTable({
    isLoading,
    playerList,
    selectedRows,
    setSelectedRows,
    getDocuments,
    onSortPlayers,
    isShowTableSettings,
    onCloseTableSettings,
    match,
}) {
    const { i18n } = useTranslation();
    const { timeFormat } = useTimeContext();

    const [currDocument, setCurrDocument] = useState(null);
    const [currPlayer, setCurrPlayer] = useState(null);
    const [isDocumentModalVisible, setDocumentModalVisible] = useState(false);
    const [isDeclineOrApprove, setIsDeclineOrApprove] = useState(false);
    const [KEY_I18N_MAP, setKEY_I18N_MAP] = useState(getKeyI18NMap());

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;

    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());
    const [columnIds, setColumnIds] = useState(getColumnIds());
    const [activeRow, setActiveRow] = useState(false);

    const brandKYCFields = useMemo(() => {
        return playerList?.length ? Object.keys(playerList[0]?.kycStatus) : [];
    }, [playerList]);

    useEffect(() => {
        initTableColumns(match.path, getDefaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const getDefaultColumnsBound = getDefaultColumns.bind(null, brandKYCFields);
        initTableColumns(match.path, getDefaultColumnsBound, setActiveColumns);
        setDefaultColumns(getDefaultColumnsBound());
        setColumnIds(getColumnIds(brandKYCFields));
    }, [brandKYCFields]);

    const canViewPlayerInfo = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Player__View_Player_Info);
    }, [agentSelf]);

    const { handleSelectAll, handleSelectRow } = useTableCheckbox(
        playerList ? playerList.map(item => item.id) : [],
        selectedRows,
        setSelectedRows
    );

    useEffect(() => {
        setKEY_I18N_MAP(getKeyI18NMap(brandKYCFields));
    }, [brandKYCFields]);

    useEffect(() => {
        const columnsData = [
            {
                custom: (
                    <th className={'thForCheckBox'}>
                        <CheckboxInput
                            wrapperClassName="check-box-aml-unset wrapperCheckBok"
                            checked={
                                playerList?.length === selectedRows?.length
                            }
                            onChange={handleSelectAll}
                        />
                    </th>
                )
            },
            ...activeColumns.map((key) => ({
                label: KEY_I18N_MAP[columnIds[key]] ? i18n.t(KEY_I18N_MAP[columnIds[key]]) : columnIds[key],
                id: columnIds[key],
            })),
        ];
        setColumns(columnsData);
    }, [playerList, i18n.language, activeColumns, columnIds, selectedRows, timeFormat]);

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleOpenDocument = (document, player) => {
        setCurrDocument(document);
        setCurrPlayer(player);
        setDocumentModalVisible(true);
        setIsDeclineOrApprove(false);
    }

    const handleCloseDocument = () => {
        setCurrDocument(null);
        setDocumentModalVisible(false);
    }

    const handleClickSaveComment = () => {
        // ...
    }

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    useEffect(() => {
        isDeclineOrApprove && setDocumentModalVisible(false);
    }, [isDeclineOrApprove])

    return (
        <>
            <NewCustomTable
                columns={columns}
                isHeader={true}
                className={'player-table responsiveTable responsiveTable__xl responsiveTable__kyc'}
                isLoading={isLoading}
                onSortItems={onSortPlayers}
            >
                {playerList &&
                    playerList.map((item, index) => {
                        const isChecked = selectedRows.includes(item.id);

                        return (
                            <tr
                                key={index}
                                className={clsx({
                                    ['checked']: isChecked,
                                    ['open']: activeRow === index
                                })}
                                onClick={() => handleToggle(index)}
                            >
                                <TdElem
                                    key={`tdForCheckBox_${index}`}
                                    item={item}
                                    field={columnIds.tdForCheckBox}
                                    isChecked={isChecked}
                                    handleSelectRow={handleSelectRow}
                                />
                                {activeColumns.map((column) => <TdElem
                                    key={`${column}_${index}`}
                                    item={item}
                                    field={column}
                                    timeFormat={timeFormat}
                                    defaultColumns={defaultColumns}
                                    handleOpenDocument={handleOpenDocument}
                                    canViewPlayerInfo={canViewPlayerInfo}
                                    i18n={i18n}
                                />)}
                            </tr>
                        );
                    })}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}

            {isDocumentModalVisible && (
                <CustomModal
                    className='document-modal documents-tab-modal'
                    titleText={i18n.t('crm.document.view.title')}
                    isOpen={isDocumentModalVisible}
                    onToggle={() => {
                        isDocumentModalVisible
                            ? handleCloseDocument()
                            : handleOpenDocument(currDocument, currPlayer);
                    }}
                    onClick={handleClickSaveComment}
                    btnText={'edit'}
                    bodyRender={() => (
                        <DocumentView
                            setIsDeclineOrApprove={setIsDeclineOrApprove}
                            currentDocument={currDocument}
                            getPlayerDocuments={getDocuments}
                            documentPlayer={currPlayer}
                            isPopupOpen={isDocumentModalVisible}
                        />
                    )}
                    withFooter={false}
                />
            )}
        </>
    );
}

PlayersPendingKycTable.propTypes = {
    isLoading: PropTypes.bool,
    playerList: PropTypes.array,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    onSortPlayers: PropTypes.func,
    getDocuments: PropTypes.func.isRequired,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
};

PlayersPendingKycTable.defaultProps = {
    onSortPlayers: () => {},
    isShowTableSettings: false,
    onCloseTableSettings: () => {},
};

export default React.memo(withRouter(PlayersPendingKycTable));
