import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import SectionTitle from './SectionTitle';
import TrackingTable from './TrackingTable';
import tracking from '../../../assets/images/layout/tracking.svg';

const Tracking = props => {
    const { transaction } = props;

    return (
        <>
            <SectionTitle
                className="tracking-title"
                sectionName={i18n.t('finance.tracking')}
                width={18}
                image={tracking}
            />

            <div className="tracking-container">
                <TrackingTable transaction={transaction} />
            </div>
        </>
    );
};

Tracking.propTypes = {
    transaction: PropTypes.object
};

export default Tracking;
