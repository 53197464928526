import React, { useCallback, useEffect, useState } from 'react';
import {Col, Container, Row} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../i18n';

import FilterTable from './_elements/FilterTable';
import DeleteFilterModal from './_elements/DeleteFilterModal';
import EditFilterModal from './_elements/EditFilterModal';

import Breadcrumb from '../../common/components/Breadcrumb';
import CustomPagination from '../../common/tables/CustomPagination';

import { CommonCard } from '../../components/CommonCard';

import useToggleModal from '../../hooks/useToggleModal';

import {
    editFilterPage,
    getFiltersListPage,
    removeFromFilterPage
} from '../../store/filters/filterPage/action';

import useList from '../../hooks/useList';
import useAlertService from '../../hooks/useAlertService';

import './style.scss';
import PageTitle from "../../components/PageTitle";

// TODO modals
const FilterPage = () => {
    const dispatch = useDispatch();
    const { error, isLoading, query, total, successMessage } =
        useSelector(state => state.FilterPage);
    const [currentModalData, setCurrentModalData] = useState(null);

    const [isShowEdit, handleShowEdit, handleHideEdit] = useToggleModal(false);
    const [isShowRemove, handleShowRemove, handleHideRemove] =
        useToggleModal(false);
    const { showSuccess } = useAlertService();

    const { pageCount, activePage, handleChangePage } = useList(
        query => dispatch(getFiltersListPage(query)),
        total,
        query
    );

    const onShowEditModal = useCallback(
        item => () => {
            setCurrentModalData(item);
            handleShowEdit();
        },
        [setCurrentModalData, handleShowEdit]
    );

    const onShowRemoveModal = useCallback(
        item => () => {
            setCurrentModalData(item);
            handleShowRemove();
        },
        [setCurrentModalData, handleShowRemove]
    );

    const onCancel = useCallback(() => {
        setCurrentModalData(null);
        handleHideEdit();
        handleHideRemove();
    }, [setCurrentModalData, handleHideEdit, handleHideRemove]);

    const onEdit = useCallback(
        data => () => {
            dispatch(editFilterPage(data));
        },
        []
    );

    const onRemove = useCallback(
        id => () => {
            dispatch(removeFromFilterPage(id));
        },
        []
    );

    useEffect(() => {
        dispatch(getFiltersListPage(query));
    }, [total]);

    useEffect(() => {
        if (successMessage) {
            showSuccess(successMessage);
        }
    }, [successMessage]);

    return (
        <div className="page-content">
            <Container fluid>
                <PageTitle title={i18n.t('filter.filterPage')}>
                    <Breadcrumb
                      titleLink={"/player-list"}
                      title={i18n.t("crm.playerList")}
                      breadcrumbItem={i18n.t('filter.filterPage')}
                    />
                </PageTitle>

                <Row className={"m-0"}>
                    <Col>
                        <CommonCard className={'filter-page'}>
                            <FilterTable
                              onRemove={onShowRemoveModal}
                            />
                        </CommonCard>
                        {(pageCount > 1) &&
                            <CustomPagination
                                key={'filterPage'}
                                pageCount={pageCount}
                                activePage={activePage}
                                onChange={handleChangePage}
                            />
                        }
                    </Col>
                </Row>
            </Container>

            {isShowEdit && (
                <EditFilterModal
                    onEdit={onEdit}
                    onHide={onCancel}
                    currentModalData={currentModalData}
                />
            )}
            {isShowRemove && (
                <DeleteFilterModal
                    onHide={onCancel}
                    onDelete={onRemove}
                    currentModalData={currentModalData}
                />
            )}
        </div>
    );
};

export default React.memo(FilterPage);
