import {
    GET_PLAYER_ATTRIBUTION_LIST,
    GET_PLAYER_ATTRIBUTION_LIST_SUCCESS,
    GET_PLAYER_ATTRIBUTION_LIST_FAIL,
    RESET_PLAYER_ATTRIBUTION_LIST_ERRORS
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    playerAttributionList: null,
    total: 0,
    query: DEFAULT_QUERY
};

const PlayerAttributions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_ATTRIBUTION_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload.query },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_ATTRIBUTION_LIST_SUCCESS:
            return {
                ...state,
                playerAttributionList: action.payload.result,
                total: action.payload.total,
                isLoading: false
            };

        case GET_PLAYER_ATTRIBUTION_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                playerAttributionList: [],
                isLoading: false
            };

        case RESET_PLAYER_ATTRIBUTION_LIST_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default PlayerAttributions;
