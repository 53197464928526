import adminENG from './admin.json';
import agentENG from './agent.json';
import affiliatesENG from './affiliates.json';
import attributionsENG from './attributions.json';
import auditENG from './audit.json';
import authenticationENG from './authentication.json';
import brandENG from './brand.json';
import commonENG from './common.json';
import contentENG from './content.json';
import countryENG from './country.json';
import crmTranslationENG from './crm.json';
import dashboardENG from './dashboard.json';
import documentENG from './document.json';
import engagementENG from './engagement.json';
import errorsENG from './errors.json';
import filterENG from './filter.json';
import financeTranslationENG from './finance.json';
import languageENG from './language.json';
import notificationENG from './notifications.json';
import promotionsENG from './promotions.json';
import permissionsENG from './permissions.json';
import reportsENG from './reports.json';
import retailENG from './retail.json';
import rolesENG from './roles.json';
import statesOfMexicoENG from './statesOfMexico.json';
import workflowENG from './workflow.json';
import pageTitlesENG from './pageTitles.json'

export default {
    ...adminENG,
    ...agentENG,
    ...affiliatesENG,
    ...attributionsENG,
    ...auditENG,
    ...authenticationENG,
    ...brandENG,
    ...commonENG,
    ...contentENG,
    ...countryENG,
    ...crmTranslationENG,
    ...dashboardENG,
    ...documentENG,
    ...engagementENG,
    ...errorsENG,
    ...filterENG,
    ...financeTranslationENG,
    ...languageENG,
    ...notificationENG,
    ...permissionsENG,
    ...promotionsENG,
    ...reportsENG,
    ...retailENG,
    ...rolesENG,
    ...statesOfMexicoENG,
    ...workflowENG,
    ...pageTitlesENG,
};
