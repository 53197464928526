import {
    GET_BRANDS,
    CHANGE_BRAND,
    GET_BRANDS_ERROR,
    GET_BRANDS_SUCCESS
} from './actionTypes';
import { getStorageXSiteId, setXSiteIdToStorages } from "../../../common/utils/common";

const INITIAL_STATE = {
    isLoading: false,
    data: [],
    active: {
        icon: '',
        isActive: false,
        name: '',
        siteId: null
    },
    error: {}
};

const Brand = (state = INITIAL_STATE, action) => {
    let result = null;
    let active = null;

    switch (action.type) {
        case GET_BRANDS:
            return { ...state, isLoading: true };
        case GET_BRANDS_SUCCESS:
            result = action.payload;
            active = result[0];
            const siteId = getStorageXSiteId();

            if (siteId) {
                active = result.find(item => String(item.id) === String(siteId));
            } else {
                setXSiteIdToStorages(active.id);
            }

            return {
                ...state,
                isLoading: false,
                data: result,
                active
            };

        case CHANGE_BRAND:
            const id = action.payload;
            active = state.data.find(item => item.id === id);

            setXSiteIdToStorages(active.id);
            return {
                ...state,
                active
            };

        case GET_BRANDS_ERROR:
            return { ...state, error: action.payload, isLoading: false };

        default:
            return { ...state };
    }
};

export default Brand;
