const layoutTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
};

const layoutWidthTypes = {
    FLUID: 'fluid',
    BOXED: 'boxed',
    SCROLLABLE: 'scrollable'
};

const topBarThemeTypes = {
    LIGHT: 'light',
    DARK: 'dark',
    COLORED: 'colored'
};

const leftBarThemeImageTypes = {
    NONE: 'none',
    IMG1: 'img1',
    IMG2: 'img2',
    IMG3: 'img3',
    IMG4: 'img4'
};

const leftSidebarTypes = {
    DEFAULT: 'default',
    COMPACT: 'compact',
    ICON: 'icon'
};

const leftSideBarThemeTypes = {
    LIGHT: 'light',
    COLORED: 'colored',
    DARK: 'dark',
    WINTER: 'winter',
    LADYLIP: 'ladylip',
    PLUMPLATE: 'plumplate',
    STRONGBLISS: 'strongbliss',
    GREATWHALE: 'greatwhale'
};

const DIRECTION = {
    ASC: 'ASC',
    DESC: 'DESC',
}

export {
    layoutTypes,
    layoutWidthTypes,
    topBarThemeTypes,
    leftBarThemeImageTypes,
    leftSidebarTypes,
    leftSideBarThemeTypes,
    DIRECTION,
};

export const DEFAULT_QUERY_MOBILE = {
    limit: 20,
    offset: 0
}

export const defaultAgent = {
    value: '',
    content: 'No Agent Owner',
};

export const DEPOSIT_PAGE_TYPE = {
    DEPOSITS_DECLINES: 'deposits-declines',
    DEPOSITS_ACTION: 'deposits-action',
    TRANSACTIONS: 'transactions',
}