import React from 'react';
import PropTypes from 'prop-types';
import header_icon from '../../../assets/images/common/cogwheel 1.svg';
import i18n from '../../../i18n';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { Col, Row } from 'reactstrap';
import { SITE_BRAND } from "../../../constants/siteBrands";

function AMLSubBlock(props) {
    const {
        player,
        onChange,
        className='',
        siteBrand,
        canEdit,
    } = props;
    const fields = [
            {
                component: A_COMMON_DATA_FIELD.SELECT_COUNTRIES,
                stateKey: 'amlCountryOfTax',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.TEXT,
                stateKey: 'amlRFC',
                type: 'text',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.TEXT,
                stateKey: siteBrand === SITE_BRAND.GCPLAYING ? 'amlCPF' : 'amlCURP',
                type: 'text',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.CHECK_BOX,
                stateKey: 'amlIsKycCall',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.CHECK_BOX,
                stateKey: 'amlIsFirstDepositEU',
                disabled: !canEdit,
            }
        ];

    return (
        <>
            <div className={`sub-content ${className}`}>
                <div className="sub-content__header d-flex align-items-center">
                    <img className="me-3" src={header_icon} alt="" />
                    <span>{i18n.t('crm.amlInfo')}</span>
                </div>

                <Row xs={'auto'}>
                    {fields.map((item, index) => (
                        <Col key={index}>
                            <ACommonDataField
                                id={item.stateKey}
                                type={item.type}
                                component={item.component}
                                label={i18n.t(`crm.${item.stateKey}`)}
                                value={player[item.stateKey] || ''}
                                handleChange={onChange(item.stateKey)}
                                disabled={item.disabled}
                                options={item.options}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
}

AMLSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default React.memo(AMLSubBlock);
