import React, {Fragment, useCallback, useEffect, useState} from "react"
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { DIRECTION } from "../../constants/layout";
import "./styles.scss";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getStorageXSiteId } from "../../common/utils/common";

const CommonTable = ({
    columns,
    children,
    className = '',
    onSortItems,
    defaultSortColumn,
    defaultOrder,
    innerRef,
    sortAfterMount,
    isLoading,
    isResponsive = false,
    ...baseProps
}) => {
    const location = useLocation();
    const path = location?.pathname;
    const isPlayerList = path.includes('/player-list/');

    const [currentColumn, setCurrentColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(DIRECTION.ASC);
    const [showArrow, setShowArrow] = useState(false);
    const [isSorted, setSorted] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [stateBrand, setStateBrand] = useState();
    const [isOpen, setIsopen] = useState(false);

    const { active: activeBrand } = useSelector(state => state.Brand);
    const currentBrand = getStorageXSiteId();

    const blockSorting = [
        'agentOwner',
        'lastLogin',
        'realBalance',
        'status',
        'amount',
        'pspCode',
        'pspMsg',
        'player',
        'txnId',
        'createdAt',
        'lastUpdated',
        'wdNumber',
        'totalPaid',
        'fees',
        'currency',
    ];

    useEffect(() => {
        if (activeBrand?.id) {
            setStateBrand(activeBrand?.id)
        }
    }, [activeBrand?.id]);

    useEffect(() => {
        if (stateBrand !== undefined && currentBrand !== stateBrand) {
            setIsBrandChanged(true);

            if (defaultSortColumn) {
                setCurrentColumn(defaultSortColumn)
                setSortDirection(DIRECTION.DESC)
            }
        }
    }, [activeBrand?.id])

    const sortColumn = useCallback((column, sorted) => {
        const newDirection = currentColumn?.id !== column.id
            ? defaultSortColumn?.id === column.id && !isSorted
                ? defaultOrder
                : DIRECTION.ASC
            : sortDirection === DIRECTION.ASC
                ? DIRECTION.DESC
                : DIRECTION.ASC;
        sorted && onSortItems(column.id || column.label, newDirection);
        setSortDirection(newDirection);
        setCurrentColumn(column);
        setShowArrow(true);
    }, [sortDirection, currentColumn]);

    useEffect(() => {
        if (defaultSortColumn) {
            sortColumn(defaultSortColumn, sortAfterMount);
        }
        setSorted(true);
    }, [])

    return (
        <Table className={className} {...baseProps} innerRef={innerRef}>
            <thead className={clsx('', {
                   'gray-header': isPlayerList,
                   'is-responsive': isResponsive
            })}
            >
            {!isLoading && (
            <tr>
                {columns.map(
                    (column, index) => {
                        const {
                            id = '',
                            label = '',
                            className = '',
                            custom = false,
                            sorted = false,
                        } = column;

                        return (
                            <Fragment key={index}>
                                {custom ? (
                                    custom
                                ) : (
                                    <th
                                        className={clsx(
                                            'text-nowrap',
                                            className,
                                            {pointer: sorted,
                                                'cursor-auto': !sorted || blockSorting.includes(column?.id),
                                            }
                                        )}
                                        onClick={() => {
                                            if (blockSorting.includes(column?.id)) {
                                                return null;
                                            } else {
                                                sorted && sortColumn(column, true);
                                            }
                                        }}
                                    >
                                        {label}
                                        {sorted &&
                                            <span className={clsx('direction__arrow',
                                                {'is-show': showArrow && id === currentColumn.id},
                                                {'is-rotate': sortDirection === DIRECTION.ASC })}
                                            >
                                            </span>
                                        }
                                    </th>
                                )}
                            </Fragment>
                        );
                    }
                )}
            </tr>
            )}
            </thead>
            <tbody>{children}</tbody>
        </Table>
    );
};


CommonTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            className: PropTypes.string,
            custom: PropTypes.any,
        })
    ).isRequired,
    className: PropTypes.string,
    onSortItems: PropTypes.func,
    defaultSortColumn: PropTypes.object,
    defaultOrder: PropTypes.string,
    sortAfterMount: PropTypes.bool,
    isLoading: PropTypes.bool,
};

CommonTable.defaultProps = {
    onSortItems: () => {},
    sortAfterMount: true,
};

export default CommonTable;
