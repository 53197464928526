import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { throttle } from "lodash";
import { useDispatch, useSelector } from "react-redux"
import {
  getPlayerTracker,
  resetPlayerQuery
} from "../../store/actions"
import { getPromotions } from "../../store/promotions/actions";
import PlayersTable, { PLAYER_TRACKER_LIST_SORTED_FIELDS } from "./tables/PlayersTable"
import SelectedInfoView from "../../components/NewCustomTable/SelectedInfoView"
import { DIRECTION } from "../../constants/layout"
import CustomPagination from "../../common/tables/CustomPagination";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize"
import { SCREEN_SIZES } from "utils/consts"

import "./datatables.scss"
import "./styles.scss"
import "../../assets/scss/pages/common.scss"

import offlineUsers from "../../assets/images/common/offlineUsers.svg";
import offlineUsersNotActive from "../../assets/images/common/offlineUsersNotActive.svg";
import onlineUsers from "../../assets/images/common/onlineUsers.svg";
import onlineUsersActive from "../../assets/images/common/onlineUsersActive.svg";
import clsx from "clsx";

const TRACKER_SCRIPT = {
  DEPOSITORS: 'Depositors',
};

const SCRIPT_TRANSLATE_KEYS = {
  [TRACKER_SCRIPT.DEPOSITORS]: 'crm.depositor.reconnect.noDeposit',
};

const NET_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

const DEFAULT_LIMIT = 50;


function Tracker() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const size = useWindowSize();
  const { currentFilter } = useSelector(state => state.Filter);
  const { isLoading, playerTrackerTotal: total, playerTrackerList: playerList, query } =
    useSelector(state => state.PlayerList);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOnline, setIsOnline] = useState(true);
  const [script, setScript] = useState(TRACKER_SCRIPT.DEPOSITORS);
  const currentFilterRef = useRef();
  const [activePage, setActivePage] = useState(1);
  const [isFixedPanel, setIsFixedPanel] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [requestQuery, setRequestQuery] = useState({
    limit: DEFAULT_LIMIT,
    offset: 0,
  });

  const scriptSelectOptions = useMemo(
      () => Object.values(TRACKER_SCRIPT)
          .map(item => ({ label: i18n.t(SCRIPT_TRANSLATE_KEYS[item]), value: item })),[]);

  useEffect(() => {
    setPageCount(Math.ceil(total / DEFAULT_LIMIT));
  }, [total]);

  useEffect(() => {
    dispatch(getPromotions({
      sort: 'created_at',
      order: 'DESC',
      limit: 1000
    }));
    loadPlayerTracker(requestQuery);
    return () => dispatch(resetPlayerQuery())
  }, [])

  const handleChangeOnline = (flag) => {
    if (isOnline !== flag) {
      const _query = {
        ...requestQuery,
        limit: DEFAULT_LIMIT,
        offset: 0,
      };

      dispatch(getPlayerTracker({
        query: _query,
        netStatus: flag ? NET_STATUS.ONLINE : NET_STATUS.OFFLINE,
      }));

      setIsOnline(flag);
      setRequestQuery(_query);
      setActivePage(1);
    }
  }

  let lastScroll = 0
  const onScroll = () => {
      const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;
      if (currentScroll < 0) {
          currentScroll = 0;
      }
      if (document.getElementById("layout-wrapper").getBoundingClientRect().bottom <= window.innerHeight) {
          lastScroll = currentScroll;
      };

      if (currentScroll >= lastScroll) {
          setIsFixedPanel(false);
      } else {
          setIsFixedPanel(true);
      }
      lastScroll = currentScroll;
  };

  const throttledOnScroll = throttle(onScroll, 100);

  useEffect(() => {
      if (size?.width <= SCREEN_SIZES.MAX_MOBILE_VIEW) {
          setIsFixedPanel(true);
          document.body.addEventListener('scroll', throttledOnScroll);
          document.addEventListener('scroll', throttledOnScroll);

          return () => {
              document.body.removeEventListener('scroll', throttledOnScroll);
              document.removeEventListener('scroll', throttledOnScroll);
          }
      } else {
          setIsFixedPanel(false);
      }
  }, [size.width]);

  const handleChangePage = useCallback((newPage) => {
    const activePage = newPage <= pageCount ? newPage : 1;
    const _query = {
      ...requestQuery,
      limit: DEFAULT_LIMIT,
      offset: (newPage - 1) * DEFAULT_LIMIT,
    };
    loadPlayerTracker(_query);
    setRequestQuery(_query);
    setActivePage(activePage);
  }, [pageCount, requestQuery]);

  const loadPlayerTracker = useCallback((query) => {
    dispatch(getPlayerTracker({
      query,
      netStatus: isOnline ? NET_STATUS.ONLINE: NET_STATUS.OFFLINE,
    }))
  }, [script, isOnline]);

  const handleChangeScript = useCallback((value) => setScript(value), []);

  useEffect(() => {
    currentFilterRef.current = currentFilter;
  }, [JSON.stringify(currentFilter)]);

  const handleSortPlayers = (column, direction = DIRECTION.ASC) => {
    const { sort, order } = query;

    if (column in PLAYER_TRACKER_LIST_SORTED_FIELDS) {
      const _query = {
        limit: DEFAULT_LIMIT,
        offset: 0,
        sort: PLAYER_TRACKER_LIST_SORTED_FIELDS[column],
        order: direction,
      }
      if ( (sort !== _query.sort) || (order !== _query.order) ) {
        loadPlayerTracker(_query);
        setRequestQuery(_query);
        setActivePage(1);
      }
    }
  };

  const preparedPlayerList = useMemo(() => {
    return playerList
      ? playerList.map((player) => {
        const {
          id: userId,
          operationId: id,
          createdAt: registered,
          nickName: username,
          affiliateInformation,
          nationality: citizenship,
          playerLastSessionTime: lastLogin,
        } = player;
        const currPlayer = {
          ...player,
          userId,
          id,
          username,
          registered,
          affiliateProduct: affiliateInformation.product,
          citizenship,
          lastLogin,
        };

        return currPlayer;
      })
      : [];
  }, [playerList]);

  return (
    <div className="player-list players-list__mobile document-content page-content">
      <MetaTags>
        <title>{i18n.t("crm.playerList")} - {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <div className="container-fluid">
        <Card className="page-card players-card__mobile players-card">
          <div className="page-title-table">
            <div className="tracker-btns">
              <button
                  className={clsx("btn", {
                    active: isOnline,
                  })}
                  onClick={() => handleChangeOnline(true)}
              >
                <img src={isOnline ? onlineUsersActive : onlineUsers} alt="" />
                {i18n.t("crm.onlineUsers")}
              </button>
              <button
                  className={clsx("btn second", {
                    active: !isOnline,
                  })}
                  onClick={() => handleChangeOnline(false)}
              >
                <img src={isOnline ? offlineUsers : offlineUsersNotActive} alt="" />
                {i18n.t("crm.offlineUsers")}
              </button>

              {/*THIS CODE WILL BE USED IN FUTURE */}
              {/*<SelectInput*/}
              {/*    onChange={handleChangeScript}*/}
              {/*    value={script}*/}
              {/*    options={scriptSelectOptions}*/}
              {/*    className='script-select'*/}
              {/*/>*/}
            </div>
          </div>

          <PlayersTable
            playerList={preparedPlayerList}
            isLoading={isLoading}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onSortPlayers={handleSortPlayers}
            trackerMode={true}
          />

          <div className={clsx('pagination-under-table',
              {'pagination__outer_is_fixed': isFixedPanel}
          )}>
            <SelectedInfoView
                offset={requestQuery.offset}
                total={total}
                listCount={playerList?.length}
                selectedCount={selectedRows.length}
            />
            {(pageCount > 1) &&
                <CustomPagination
                    isDisabled={isLoading}
                    pageCount={pageCount}
                    activePage={activePage}
                    onChange={handleChangePage}
                />
            }
          </div>
        </Card>
      </div>
    </div>
  );
}

export default React.memo(Tracker);
