import {
    GET_PLAYER_VERIFICATION,
    GET_PLAYER_VERIFICATION_SUCCESS,
    GET_PLAYER_VERIFICATION_FAIL,
    RESET_PLAYER_VERIFICATION_ALERTS_ERRORS,
    SET_EDIT_PLAYER_VERIFICATION,
    UPDATE_PLAYER_VERIFICATION,
    UPDATE_PLAYER_VERIFICATION_SUCCESS,
    UPDATE_PLAYER_VERIFICATION_FAIL,
    GET_PLAYER_VERIFICATION_HISTORY_LIST,
    GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS,
    GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL,
    RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS,
    GET_PLAYER_SOF_AGREEMENT,
    GET_PLAYER_SOF_AGREEMENT_SUCCESS,
    GET_PLAYER_SOF_AGREEMENT_FAIL,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL,
} from './actionTypes';

export const getPlayerVerification = payload => ({
    type: GET_PLAYER_VERIFICATION,
    payload
});

export const getPlayerVerificationSuccess = payload => ({
    type: GET_PLAYER_VERIFICATION_SUCCESS,
    payload
});

export const getPlayerVerificationFail = payload => ({
    type: GET_PLAYER_VERIFICATION_FAIL,
    payload
});

export const resetPlayerVerificationAlertsErrors = payload => ({
    type: RESET_PLAYER_VERIFICATION_ALERTS_ERRORS,
    payload
});

export const setEditPlayerVerification = payload => ({
    type: SET_EDIT_PLAYER_VERIFICATION,
    payload
});

export const updatePlayerVerification = payload => ({
    type: UPDATE_PLAYER_VERIFICATION,
    payload
});

export const updatePlayerVerificationSuccess = payload => ({
    type: UPDATE_PLAYER_VERIFICATION_SUCCESS,
    payload
});

export const updatePlayerVerificationFail = payload => ({
    type: UPDATE_PLAYER_VERIFICATION_FAIL,
    payload
});

export const getPlayerVerificationHistoryList = payload => ({
    type: GET_PLAYER_VERIFICATION_HISTORY_LIST,
    payload
});

export const getPlayerVerificationHistoryListSuccess = payload => ({
    type: GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS,
    payload
});

export const getPlayerVerificationHistoryListFail = payload => ({
    type: GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL,
    payload
});

export const resetPlayerVerificationHistoryListErrors = payload => ({
    type: RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS,
    payload
});

export const getPlayerSofAgreement = payload => ({
    type: GET_PLAYER_SOF_AGREEMENT,
    payload
});

export const getPlayerSofAgreementSuccess = payload => ({
    type: GET_PLAYER_SOF_AGREEMENT_SUCCESS,
    payload
});

export const getPlayerSofAgreementFail = payload => ({
    type: GET_PLAYER_SOF_AGREEMENT_FAIL,
    payload
});

export const getPlayerSelfExclusionAgreement = payload => ({
    type: GET_PLAYER_SELF_EXCLUSION_AGREEMENT,
    payload
});

export const getPlayerSelfExclusionAgreementSuccess = payload => ({
    type: GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS,
    payload
});

export const getPlayerSelfExclusionAgreementFail = payload => ({
    type: GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL,
    payload
});

export const getPlayerAccountRecoveryAgreement = payload => ({
    type: GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT,
    payload
});

export const getPlayerAccountRecoveryAgreementSuccess = payload => ({
    type: GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS,
    payload
});

export const getPlayerAccountRecoveryAgreementFail = payload => ({
    type: GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL,
    payload
});
