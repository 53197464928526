import React from 'react';
import PropTypes from 'prop-types';

import i18n from "../../../../i18n";

import "./call.scss"


const PhoneCallIcon = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.45468 3.48205L4.62213 1.5396C5.10336 1.24448 5.73332 1.40594 6.01328 1.89614L8.54445 6.32821C8.79269 6.76289 8.6813 7.31415 8.28376 7.61833L7.09107 8.5309C6.74773 8.79361 6.60661 9.25129 6.81244 9.63146C7.83064 11.512 10.3269 13.6149 12.0067 14.8336C12.4206 15.1338 12.9921 15.0501 13.3192 14.6572L14.3798 13.3838C14.6903 13.011 15.2235 12.9149 15.6446 13.1556L20.09 15.6973C20.5855 15.9806 20.7438 16.6205 20.4375 17.1021L18.3007 20.4618C18.0919 20.7902 17.7073 20.9691 17.3232 20.9065C6.15041 19.0862 1.09458 9.11011 1.00132 4.27179C0.995034 3.94594 1.17685 3.65244 1.45468 3.48205Z" />
  </svg>
);

function CallView({ onClick, isDisabled = false }) {
    const iconColor = '#3BB947';

  return (
    <span
      role="button"
      className={`callBtn${isDisabled ? " disabled" : ""}`}
      onClick={onClick}
    >
      <PhoneCallIcon color={isDisabled} />
      {i18n.t("crm.call")}
    </span>
  );
}

CallView.propTypes = {
    status: PropTypes.string,
    isDisabled: PropTypes.bool
};

export default React.memo(CallView);
