import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
  CHANGE_STATUS,
  GET_AFFILIATE,
  REMOVE_AFFILIATE,
  CHANGE_AFFILIATE,
  GET_AFFILIATE_LIST,
  ADD_AFFILIATE_AT_LIST,
  DOWNLOAD_AFFILIATE_LIST,
} from './actionTypes';
import {
  getAffiliateApi,
  removeAffiliateApi,
  changeAffiliateApi,
  getAffiliatesListApi,
  addAffiliatesAtListApi,
  changeAffiliateStatusApi,
  downloadAffiliatesListApi,
} from '../../helpers/affiliateApi';
import {
  affiliateError,
  getAffiliateSuccess,
  removeAffiliateSuccess,
  changeAffiliateSuccess,
  getAffiliatesListSuccess,
  addAffiliateAtListSuccess,
  changeAffiliateStatusSuccess,
  getAffiliatesListFail,
  downloadAffiliateListSuccess,
  downloadAffiliateListFail,
  addAffiliateAtListError,
} from './actions';
import { changeAffiliateDataAdapter } from '../../pages/Affiliates/dataAdapters';


function* fetchAffiliatesList({ payload }) {
  try {
    const response = yield call(getAffiliatesListApi, payload);

    yield put(getAffiliatesListSuccess(response.data));
  } catch (error) {
    yield put(getAffiliatesListFail(error));
  }
}

function* addAffiliatesAtList({ payload: { body } }) {
  try {
    const response = yield call(addAffiliatesAtListApi, body);

    if (response) {
      if (!!response.statusCode) {
        yield put(affiliateError(response));
      } else {
        yield put(addAffiliateAtListSuccess(response));
      }
    } else {
      yield put(affiliateError(response));
    }
  } catch (error) {
    yield put(addAffiliateAtListError(error));
  }
}

function* fetchAffiliate({ payload: { id } }) {
  try {
    const response = yield call(getAffiliateApi, id);

    if (response) {
      yield put(getAffiliateSuccess(response.data));
    } else {
      yield put(affiliateError(response));
    }
  } catch (error) {
    yield put(affiliateError(error));
  }
}

function* changeAffiliate({ payload: body }) {
  try {
    const response = yield call(changeAffiliateApi, changeAffiliateDataAdapter(body));

    if (response.success) {
      yield put(changeAffiliateSuccess(body));
      yield call(fetchAffiliate, {payload: {
          id: body.id
        }})
    } else {
      yield put(affiliateError(response));
    }
  } catch (error) {
    yield put(affiliateError(error));
  }
}

function* removeAffiliate({ payload: { id, history } }) {
  try {
    const response = yield call(removeAffiliateApi, id);

    if (response && response.success) {
      yield put(removeAffiliateSuccess(response));
      history.push('/affiliates');
    } else {
      yield put(affiliateError(response));
    }
  } catch (error) {
    yield put(affiliateError(error));
  }
}

function* changeStatusAffiliate({ payload }) {
  try {
    const response = yield call(changeAffiliateStatusApi, payload);

    if (response && response.success) {
      yield put(changeAffiliateStatusSuccess(payload));
    } else {
      yield put(affiliateError(response));
    }
  } catch (error) {
    yield put(affiliateError(error));
  }
}

function* downloadAffiliatesListSaga({ payload }) {
  try {
    yield call(downloadAffiliatesListApi, payload);

    yield put(downloadAffiliateListSuccess());
  } catch (error) {
    yield put(downloadAffiliateListFail(error));
  }
}

function* affiliatesSaga() {
    yield takeEvery(GET_AFFILIATE, fetchAffiliate);
    yield takeEvery(GET_AFFILIATE_LIST, fetchAffiliatesList);
    yield takeEvery(ADD_AFFILIATE_AT_LIST, addAffiliatesAtList);
    yield takeEvery(CHANGE_AFFILIATE, changeAffiliate);
    yield takeEvery(REMOVE_AFFILIATE, removeAffiliate);
    yield takeEvery(CHANGE_STATUS, changeStatusAffiliate);
    yield takeEvery(DOWNLOAD_AFFILIATE_LIST, downloadAffiliatesListSaga);
}

export default affiliatesSaga;
