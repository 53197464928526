import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import i18n from '../../i18n';

import { CommonCard } from '../../components/CommonCard';

import ClipboardButton from '../../common/cellData/CopyButton';
import HeadlineText from './common/HeadlineText';

import group from '../../assets/images/common/Tooltip.svg';
import details from '../../assets/images/common/Contact Details.svg';
import activeBranded from '../../assets/images/common/activeBranded.svg';
import inactiveBranded from '../../assets/images/common/inactiveBranded.svg';
import AffiliateStatus from '../../common/components/Status';
import { AFFILIATES_MARK_STATUS } from '../../constants/affiliates';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';
import {useTimeContext} from "../../context/TimeContext";
import Formatter from "../../utils/Formatter";

function AffiliateMainCard({
    state,
    isLoading,
    handleChangeStatus,
    handleChange,
    onEdit,
    getErrors,
    isStatusLoading
}) {
    const _handleChange = useCallback(
        state => value => {
            onEdit(true);
            handleChange(state)(value);
        },
        [handleChange]
    );
    const { timeFormat } = useTimeContext();

    const generalInfo = useMemo(
        () => [
            {
                label: i18n.t('affiliates.createdTime'),
                value: Formatter.displayDateTimeSeconds(timeFormat.parseTime(state.createdAt)),
                field: 'createdAt'
            },
            {
                label: i18n.t('affiliates.updatedTime'),
                value: Formatter.displayDateTimeSeconds(timeFormat.parseTime(state.lastChangedAt)),
                field: 'lastChangedAt'
            },
            {
                label: i18n.t('affiliates.branded'),
                type: 'icon',
                value: state.branded ? (
                    <img src={activeBranded} alt="active" />
                ) : (
                    <img src={inactiveBranded} alt="inactive" />
                ),
                field: 'branded'
            }
        ],
        [state, timeFormat]
    );

    const contactInfo = useMemo(
        () => [
            {
                label: i18n.t('affiliates.name'),
                value: state.name,
                field: 'name'
            },
            {
                label: i18n.t('affiliates.contactName'),
                value: state.contactName,
                field: 'contactName'
            },
            {
                label: i18n.t('affiliates.email'),
                value: state.email,
                field: 'email'
            },
            {
                label: i18n.t('affiliates.contactPhone'),
                value: state.contactPhone,
                field: 'contactPhone'
            }
        ],
        [state, _handleChange, getErrors]
    );

    return (
        <CommonCard className={'affiliate-main-card'} isLoading={isLoading}>
            <Row className="justify-content-between affiliate-main-card__header">
                <Col sm={12} md={9} className={'d-flex'}>
                    <div className={'affiliate-main-card__title-wrapper'}>
                        <span className="me-2">ID {state.id}</span>
                    </div>

                    <ClipboardButton text={state.id} />
                </Col>

                <Col
                    sm={12}
                    md={3}
                    className={
                        'd-flex align-items-center justify-content-md-end'
                    }
                >
                    <AffiliateStatus
                        status={state.status}
                        statuses={AFFILIATES_MARK_STATUS}
                        handleChange={handleChangeStatus}
                        isLoading={isStatusLoading}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={5}>
                    <Row>
                        <Col xs={12} lg={12}>
                            <HeadlineText
                                src={group}
                                label={i18n.t('affiliates.generalInfo')}
                            />
                        </Col>

                        {generalInfo.map(({ label, value, type }, index) => (
                            <Col
                                xs={12}
                                xl={6}
                                className={'content-row'}
                                key={index}
                            >
                                {type !== 'icon' && (
                                    <ACommonDataField
                                        label={label}
                                        value={value}
                                        disabled={true}
                                    />
                                )}
                                {type === 'icon' && (
                                    <>
                                        <span className={'content-row__label'}>
                                            {label}:
                                        </span>
                                        <span className={'content-row__value'}>
                                            {value}
                                        </span>{' '}
                                    </>
                                )}
                            </Col>
                        ))}
                    </Row>
                </Col>

                <Col xs={12} md={5} className={'contact-row'}>
                    <Row>
                        <Col xs={12}>
                            <HeadlineText
                                src={details}
                                label={i18n.t('affiliates.contactDetails')}
                            />
                        </Col>

                        {contactInfo.map(
                            ({ label, value, field, error }, index) => (
                                <Col
                                    xs={12}
                                    xl={6}
                                    className={'content-row'}
                                    key={index}
                                >
                                    <ACommonDataField
                                        label={label}
                                        value={value}
                                        handleChange={_handleChange(field)}
                                        error={getErrors(field)}
                                        helperText={getErrors(field)}
                                    />
                                </Col>
                            )
                        )}
                    </Row>
                </Col>
            </Row>
        </CommonCard>
    );
}

AffiliateMainCard.propTypes = {
    state: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleChangeStatus: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    getErrors: PropTypes.func.isRequired,
    isStatusLoading: PropTypes.bool.isRequired
};

export default React.memo(AffiliateMainCard);
