import {
    APPROVE_DOCUMENT,
    REJECT_DOCUMENT,
    DOCUMENT,
    DOCUMENT_DATA,
    DOCUMENT_COMMENTS,
    REMOVE_DOCUMENT_COMMENT,
    PLAYER_DOCUMENTS
} from './urlTypes';

// REAL PATHS
// LOGIN
export const LOGIN = '/agent/login';
export const GOOGLE_AUTH = '/agent/google-authentication';
export const LOGOUT = '/agent/logout';
export const REFRESH_TOKEN = '/agent/token/refresh';

export const getUrl = (urlType, id) => {
    switch (urlType) {
        case APPROVE_DOCUMENT:
            return `/documents/pending/${id}/approve`;
        case REJECT_DOCUMENT:
            return `/documents/pending/${id}/reject`;
        case DOCUMENT:
            return `/documents/${id}`;
        case DOCUMENT_DATA:
            return `/documents/data/${id}`;
        case PLAYER_DOCUMENTS:
            return `/documents/player/${id}`;
        case DOCUMENT_COMMENTS:
            return `/documents/${id}/comments`;
        case REMOVE_DOCUMENT_COMMENT:
            return `/documents/${id}/comments/remove`;
        default:
            return '';
    }
};
