import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = props => {
    const { className, sectionName, image, width } = props;
    return (
        <div className={className}>
            <div className="sub-content__header d-flex align-items-center">
                <span className="me-2">
                    <img
                        className="i-image"
                        width={width || 16}
                        src={image}
                        alt=""
                    />
                </span>
                <span className="section-title">{sectionName}</span>
            </div>
        </div>
    );
};

SectionTitle.propTypes = {
    sectionName: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string,
    width: PropTypes.number
};

export default SectionTitle;
