import { takeEvery, put, call } from 'redux-saga/effects';
import {
    APPROVE_PENDING_TRANSFER_WITHDRAWAL,
    DECLINE_PENDING_TRANSFER_WITHDRAWAL,
    GET_PENDING_TRANSFER_WITHDRAWAL
} from './actionTypes';
import {
    getPendingTransferWithdrawalSuccess,
    getPendingTransferWithdrawalFail,
    approvePendingTransferWithdrawalSuccess,
    approvePendingTransferWithdrawalFail,
    getPendingTransferWithdrawal,
    declinePendingTransferWithdrawalSuccess,
    declinePendingTransferWithdrawalFail
} from './actions';
import {
    approveTransactionApi,
    declineTransactionApi,
    getPaymentWithdrawalApi
} from '../../../helpers/paymentApi';

function* fetchPendingTransferWithdrawalSaga({ payload }) {
    try {
        const response = yield call(getPaymentWithdrawalApi, payload);

        yield put(getPendingTransferWithdrawalSuccess(response.data));
    } catch (error) {
        yield put(getPendingTransferWithdrawalFail(error));
    }
}

function* approveTransactionSaga({ payload }) {
    try {
        const response = yield call(approveTransactionApi, payload);
        yield put(approvePendingTransferWithdrawalSuccess(response.data));

        yield put(getPendingTransferWithdrawal(payload.id));
    } catch (error) {
        yield put(approvePendingTransferWithdrawalFail(error));
    }
}

function* declineTransactionSaga({ payload }) {
    try {
        const response = yield call(declineTransactionApi, payload);
        yield put(declinePendingTransferWithdrawalSuccess(response.data));

        yield put(getPendingTransferWithdrawal(payload.id));
    } catch (error) {
        yield put(declinePendingTransferWithdrawalFail(error));
    }
}

function* pendingWithdrawalSaga() {
    yield takeEvery(GET_PENDING_TRANSFER_WITHDRAWAL, fetchPendingTransferWithdrawalSaga);
    yield takeEvery(APPROVE_PENDING_TRANSFER_WITHDRAWAL, approveTransactionSaga);
    yield takeEvery(DECLINE_PENDING_TRANSFER_WITHDRAWAL, declineTransactionSaga);
}

export default pendingWithdrawalSaga;
