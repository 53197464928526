import moment from 'moment';
import React from 'react';
import _ from 'lodash';

import { IMAGE_TYPES, PDF_TYPES } from '../constants/common';
import grid from 'echarts/src/coord/cartesian/Grid';
import i18n from "i18next";
import { getAccessToken } from '../../helpers/api_helper';
import { selectFilter } from "../../store/filters/action";
import xlsx from "xlsx";

const pdfJsLib = window['pdfjs-dist/build/pdf'];
pdfJsLib.GlobalWorkerOptions.workerSrc =
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.min.js';

const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
const DATE_TIME_FORMAT_ss = 'DD.MM.YYYY HH:mm:ss';
const DATE_FORMAT = 'DD.MM.YYYY';
const TIME_FORMAT_hh_mm_ss = 'HH:mm:ss';
const TIME_FORMAT_hh_mm = 'HH:mm';
const TIME_FORMAT_DD_MM_YEAR = 'DD/MM/YYYY';

const getStorageXSiteId = () => sessionStorage.getItem('x-site-id') || localStorage.getItem('x-site-id');

const setXSiteIdToStorages = (xSiteId) => {
    sessionStorage.setItem('x-site-id', xSiteId);
    localStorage.setItem('x-site-id', xSiteId);
};

const removeXSiteIdFromStorages = () => {
    sessionStorage.removeItem('x-site-id');
    localStorage.removeItem('x-site-id');
};

const getGuidShortFormat = guid => {
    if (!grid || !guid?.length || guid?.length < 5) {
        return guid;
    }

    return `${guid.slice(0, 4)}...${guid.slice(-4)}`;
};

const getCardShortFormat = guid => `${guid.slice(0, 1)}****${guid.slice(-4)}`;

const capitalize = str =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

const PERIODS_DATA = [
    ['year', 'years'],
    ['month', 'months'],
    ['week', 'weeks'],
    ['day', 'days'],
    ['hour', 'hours'],
    ['minute', 'minutes']
];

const getTimesAgo = dateTime => {
    for (const periodData of PERIODS_DATA) {
        const periodsDiff = moment().diff(dateTime, periodData[0]);

        if (periodsDiff > 0) {
            return `${periodsDiff} ${
                periodsDiff === 1 ? periodData[0] : periodData[1]
            } ago`;
        }
    }

    return '';
};

const getDuplicates = (array) => _.uniq(array.filter((value, index, arr) => {
    return arr.indexOf(value) !== index;
})).filter((value) => !!value);

const handlePreviousFilter = (currPath, dispatch) => {
    const prevPath = localStorage.getItem('path');

    if (!!prevPath && currPath !== prevPath) {
        // From an entity to an entities table
        // For example: PlayerView -> Player list
        // Need to restore previous filter from LocalStorage
        if (prevPath.includes(currPath)) {
            const prevFilters = localStorage.getItem('PrevFilters');
            try {
                const parsedPrevFilters = JSON.parse(prevFilters);
                if (currPath in parsedPrevFilters) {
                    if (dispatch) {
                        dispatch(selectFilter({
                            currentFilter: JSON.parse(parsedPrevFilters[currPath])
                        }));
                    }
                    localStorage.setItem('LocalStorageFilter', parsedPrevFilters[currPath]);
                }
            } catch (err) {
                console.log(`JSON parse error: ${err}`)
            }
            localStorage.removeItem('PrevFilters');
        }
        // From an entities table to an entity
        // For example: Player list -> PlayerView
        // Need to save current filter to LocalStorage
        else if (currPath.includes(prevPath)) {
            const localStorageFilter = localStorage.getItem('LocalStorageFilter');
            // Previous page filter is not empty
            if (localStorageFilter !== '[]') {
                localStorage.setItem('PrevFilters', JSON.stringify({[prevPath]: localStorageFilter}));
            }
        }
        // Different paths
        // Need to remove previous filter from Local Storage
        else {
            localStorage.removeItem('PrevFilters');
        }
    }
};

const handleChangePath = (props, dispatch) => {
    const currPath = props?.match?.path;
    if (currPath) {
        handlePreviousFilter(currPath, dispatch);
        localStorage.setItem('path', currPath);
    }
};

const clearPageNumber = previousPath => {
    if (localStorage.getItem('path') !== previousPath) {
        localStorage.removeItem('pageNumber');
    }
};

const getWidthOfText = (txt, fontname, fontsize, variant = 'width') => {
    if (getWidthOfText.c === undefined) {
        getWidthOfText.c = document.createElement('canvas');
        getWidthOfText.ctx = getWidthOfText.c.getContext('2d');
    }
    getWidthOfText.ctx.font = 'normal' + fontsize + ' ' + fontname;
    return getWidthOfText.ctx.measureText(txt)[variant];
};

const getTimeValue = time => {
    const momentTime = moment(time);
    return (
        <>
            <span>{momentTime.format(DATE_FORMAT)}</span>
            <span className="margin-left-10">
                {momentTime.format(TIME_FORMAT_hh_mm_ss)}
            </span>
        </>
    );
};

const isImage = contentType => IMAGE_TYPES.includes(contentType);

const isPdf = contentType => PDF_TYPES.includes(contentType);

const loadPdfFromData = (pdfDoc) => {
    return new Promise((resolve, reject) => {
        const loadingTask = typeof pdfDoc === "string" ?
            pdfJsLib.getDocument({
                url: pdfDoc,
                httpHeaders: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    'X-Site-Id': getStorageXSiteId(),
                }
            }) :
            pdfJsLib.getDocument(pdfDoc);

        loadingTask.promise.then(
            pdf => {
                resolve(pdf)
            },
            reason => {
                resolve(reason);
            }
        ).catch((e) => {
        });
    });
};

const openPdfInCanvas = ({ pdf, canvas, canvasWidth, pageNumber }) =>
    new Promise((resolve, reject) => {
        if (canvas) {
            // Fetch a page
            pdf.getPage(pageNumber)
                .then(function (page) {
                    const [left, top, pageWidth, height] = page._pageInfo.view;
                    const scale = canvasWidth / pageWidth;
                    const viewport = page.getViewport({ scale });

                    // Prepare canvas using PDF page dimensions
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    const renderTask = page.render(renderContext);
                    renderTask.promise.then(function () {
                        // Page rendered
                        const previewDataURL = canvas.toDataURL();
                        resolve(previewDataURL);
                    });
                })
                .catch(err => reject(err));
        } else {
            resolve();
        }
    });

const createCanvasObjectURL = canvas =>
    new Promise(resolve => {
        canvas.toBlob(function (blob) {
            const url = URL.createObjectURL(blob);
            resolve(url);
        });
    });

const readFileAsArrayBuffer = file =>
    new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = async event => {
            const fileData = event.target.result;
            resolve(fileData);
        };
        reader.readAsArrayBuffer(file);
    });

const readFileAsDataUrl = file =>
    new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = async event => {
            const fileData = event.target.result;
            resolve(fileData);
        };
        reader.readAsDataURL(file);
    });

const resizeImg = ({ canvas, canvasWidth, imageDataURL }) =>
    new Promise(resolve => {
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function () {
            // set size proportional to image
            canvas.width = canvasWidth;
            canvas.height = canvas.width * (img.height / img.width);

            // step 1 - resize to 50%
            const oc = document.createElement('canvas');
            const octx = oc.getContext('2d');

            oc.width = img.width * 0.5;
            oc.height = img.height * 0.5;
            octx.drawImage(img, 0, 0, oc.width, oc.height);

            // step 2
            octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

            // step 3, resize to final size
            ctx.drawImage(
                oc,
                0,
                0,
                oc.width * 0.5,
                oc.height * 0.5,
                0,
                0,
                canvas.width,
                canvas.height
            );

            const previewDataURL = canvas.toDataURL();
            resolve(previewDataURL);
        };
        img.src = imageDataURL;
    });

const safeGet = (json, keys) => {
    if (keys.length === 1) {
        return json[keys[0]];
    }
    if (keys.length === 2) {
        return json[keys[0]][keys[1]];
    }
    if (keys.length === 3) {
        return json[keys[0]][keys[1]][keys[2]];
    }
    if (keys.length === 4) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]];
    }
    if (keys.length === 5) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]];
    }
    if (keys.length === 6) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]];
    }
    if (keys.length === 7) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][
            keys[6]
        ];
    }
    if (keys.length === 8) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][
            keys[6]
        ][keys[7]];
    }
    if (keys.length === 9) {
        return json[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][
            keys[6]
        ][keys[7]][keys[8]];
    }
    return undefined;
};

const cleanUpJson = json => {
    json = json.split("'").join('"');
    const _json = json;
    try {
        const jsonResult = JSON.parse(json);
        if (typeof jsonResult === 'string') {
            throw new Error();
        }
        return JSON.parse(json);
    } catch (e) {}
    json = json.split(',').join('",\n"');
    json = json.split(':').join('":"');
    json = json.replace('isMobile":" ', 'isMobile: ');
    json = json.replace('https":"//', 'http://');

    json = json.split('"{').join('{"');
    json = json.split('}"').join('"}');

    json = json.substring(1, json.length - 1);

    json = '{"' + json;
    json = json + '"}';
    try {
        return JSON.parse(json);
    } catch (e) {
        return _json;
    }
};

const makeJSONStringValid = (str) => {
    const cleanStr = str.slice(1, str.length - 1);
    const arr = cleanStr.split(",");
    const obj = {};

    for (let i = 0; i < arr.length; i++) {
        const [itemKey, itemValue] = arr[i].split(':');
        obj[itemKey] = itemValue;
    }

    return obj;
}

const parseCSVLineToWords = (line) => line
    .split(/[,;]/g)
    .map((str) => str.replaceAll('"', ''));


const prepareCSVColumnPositions = (csvColumns, columnTitles) => {
    const columnPositions = [];
    columnTitles
        .forEach((column, ind) => {
            if (csvColumns.includes(column)) {
                columnPositions.push({
                    field: column,
                    pos: ind,
                });
            }
        });
    return columnPositions;
}

const confMess = (inc, dec) => {
    function generateConfirmMessage(askMessage, actionMessage, gamesLength) {
        return `${i18n.t(askMessage)} ${i18n.t(actionMessage)} ${gamesLength} ${i18n.t(`crm.game${gamesLength !== 1 ? 's' : ''}`)}`;
    }
    const addedGamesCount = inc.filter(gameId => !dec.includes(gameId)).length;
    const removedGamesCount = dec.filter(gameId => !inc.includes(gameId)).length;

    if (addedGamesCount && removedGamesCount) {
        return `${generateConfirmMessage("crm.areYouSure", "crm.remove", removedGamesCount)}, ${i18n.t("crm.andAdd")} ${generateConfirmMessage("", "crm.add", addedGamesCount)}?`;
    } else if (removedGamesCount) {
        return `${generateConfirmMessage("crm.areYouSure", "crm.remove", removedGamesCount)}?`;
    } else {
        return `${generateConfirmMessage("crm.areYouSure", "crm.add", addedGamesCount)}?`;
    }
};

const preparePlayerPromoAmount = (playerPromo, generalCurrency) => {
    const {promotion} = playerPromo;
    const currencies = promotion?.segmentation?.currencies;
    const currency = currencies ? currencies[0] : "MXN";
    let amountValue;
    let amountCurrency;
    const amount = promotion?.settings?.configuration?.amount;
    const promoDetails = playerPromo.result?.details;
    if (promotion?.settings?.isDeposit && promoDetails?.deposit) {
        amountValue = promoDetails?.deposit;
        amountCurrency = generalCurrency;
    }
    else if (!promotion?.settings?.isDeposit && promoDetails?.bonusAmount) {
        amountValue = promoDetails?.bonusAmount;
        amountCurrency = generalCurrency;
    }
    else if (playerPromo.bonusAmount) {
        amountValue = playerPromo.bonusAmount;
        amountCurrency = currency;
    }
    else if (typeof amount === 'object') {
        const currencies = Object.keys(amount);
        if (currencies.length > 0) {
            amountValue = amount[currencies[0]];
            amountCurrency = currencies[0];
        }
    }
    else {
        amountValue = playerPromo.balance;
        amountCurrency = generalCurrency;
    }

    return {amountValue, amountCurrency};
};

const getJSONFromExcelData = (excelData) => {
    const data = new Uint8Array(excelData);
    const workbook = xlsx.read(data, {type:"array"});
    const sheet_name_list = workbook.SheetNames;
    const jsonData = xlsx.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
    return jsonData;
};

const getColumnSettingsLsKey = (path) => `columnSettings_${path}`;

const initTableColumns = (path, getDefaultColumns, setActiveColumns) => {
    const lsKey = getColumnSettingsLsKey(path);
    const lsColumnsData = localStorage.getItem(lsKey);
    const lsColumns = lsColumnsData ? lsColumnsData.split(',') : getDefaultColumns();
    setActiveColumns(lsColumns);

    if (!lsColumnsData) {
        localStorage.setItem(lsKey, lsColumns.join(','));
    }
};

const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const replaceNewlinesWithSpace = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((value) => replaceNewlinesWithSpace(value));
    } else if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            obj[key] = replaceNewlinesWithSpace(obj[key]);
        }
        return obj;
    } else if (typeof obj === 'string') {
        return obj.replace(/\n/g, ' ');
    }
    return obj;
};


export {
    getStorageXSiteId,
    setXSiteIdToStorages,
    removeXSiteIdFromStorages,
    cleanUpJson,
    safeGet,
    DATE_TIME_FORMAT,
    DATE_TIME_FORMAT_ss,
    DATE_FORMAT,
    TIME_FORMAT_hh_mm_ss,
    TIME_FORMAT_hh_mm,
    TIME_FORMAT_DD_MM_YEAR,
    getGuidShortFormat,
    getCardShortFormat,
    capitalize,
    getTimesAgo,
    handleChangePath,
    clearPageNumber,
    getWidthOfText,
    getTimeValue,
    isImage,
    isPdf,
    loadPdfFromData,
    openPdfInCanvas,
    createCanvasObjectURL,
    readFileAsArrayBuffer,
    readFileAsDataUrl,
    resizeImg,
    makeJSONStringValid,
    parseCSVLineToWords,
    prepareCSVColumnPositions,
    confMess,
    preparePlayerPromoAmount,
    getDuplicates,
    getJSONFromExcelData,
    getColumnSettingsLsKey,
    initTableColumns,
    generateUniqueId,
    replaceNewlinesWithSpace,
};
