import React, {useEffect, useMemo} from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MetaTags from 'react-meta-tags';

import SiteMainCard from './site/SiteMainCard';
import i18n from '../../i18n';
import SiteDetailedCard from "./site/SiteDetailedCard";
import {
  fetchBrandSettingsPage,
  resetBrandSettingsAlertsErrors,
} from "../../store/brand/brandPage/actions";
import useAlertService from "../../hooks/useAlertService";
import "./site.scss";
import { SITE_PERMISSION } from "../../common/constants/common";
import PermissionWrapper from "../../components/PermissionWrapper";
import FraudRestrictions from "./components/FraudRestrictions/FraudRestrictions";
import CoolOff from "./components/CoolOff/CoolOff";
import { renderByConfig} from "./siteSettings/settings";

const SitePage = () => {
  const dispatch = useDispatch();
  const { showError, showSuccess } = useAlertService();
  const {
    data,
    isLoading,
    error,
    successMessage,
    isEdit,
  } = useSelector(state => state.BrandSettingsPage);
  const { agentSelf } = useSelector(state => state.AgentSelf);
  const { active: activeBrand } = useSelector(state => state.Brand);

  const agentPermissions = agentSelf?.permissions || [];
  const canEditFraudSection = agentPermissions.includes(SITE_PERMISSION.IT__Edit_Fraud_Restrictions);
  const canEditCoolOffSection = agentPermissions.includes(SITE_PERMISSION.Player__Edit_Player_Info);

  useEffect(() => {
    dispatch(fetchBrandSettingsPage());
  }, []);

  useEffect(() => {
    if (error?.message) {
      showError(error?.message);
      dispatch(resetBrandSettingsAlertsErrors());
    }
  }, [error]);

  useEffect(() => {
    if (successMessage) {
      showSuccess(successMessage);
      dispatch(resetBrandSettingsAlertsErrors());
    }
  }, [successMessage]);

  const isFraudRestrictionsVisible = useMemo(() => {
    return renderByConfig(activeBrand?.siteName, 'fraudRestrictions');
  }, [activeBrand?.siteName]);


  return (
    <div className="page-content site-page">
      <MetaTags>
        <title>{i18n.t('admin.brandSettings')} - {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <Container fluid>
        <Row className={"m-0"}>
          <Col lg="12">
            <SiteMainCard
                isLoading={isLoading}
                generalInfo={data?.generalInfo}
            />

            <PermissionWrapper
                accessPermissions={[SITE_PERMISSION.IT__View_Fraud_Restrictions]}
            >
              {isFraudRestrictionsVisible &&
                  <FraudRestrictions canEditFraudSection={canEditFraudSection} />
              }
            </PermissionWrapper>

            <PermissionWrapper
                accessPermissions={[SITE_PERMISSION.Player__Edit_Player_Info]}
            >
              <CoolOff
                  canEditCoolOffSection={canEditCoolOffSection}
              />
            </PermissionWrapper>

            <SiteDetailedCard
                data={data}
                isLoading={isLoading}
                isEdit={isEdit}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default withRouter(SitePage);
