import React, {Fragment, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import header_icon from '../../../assets/images/common/user (1).svg';
import i18n from '../../../i18n';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import GenerateButton from '../../../common/cellData/GenerateButton';
import moment from "moment/moment";
import { DATE_TIME_FORMAT_ss } from "../../../common/utils/common";
import { useSelector } from "react-redux";
import { SITE_BRAND } from "../../../constants/siteBrands";
import { BANK_CODES, MEXICO_BANKS, validateClabe } from "../../../common/constants/common";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";


const getComponentTypeByStateKey = (key) => {
    switch (key) {
        case 'bankName':return A_COMMON_DATA_FIELD.SELECT_BANK;
        case 'additionalBankId':return A_COMMON_DATA_FIELD.TEXT;
        case 'beneficiaryCity':return A_COMMON_DATA_FIELD.TEXT;
        case 'beneficiaryName':return A_COMMON_DATA_FIELD.TEXT;
        case 'branch':return A_COMMON_DATA_FIELD.TEXT;
        case 'iban':return A_COMMON_DATA_FIELD.TEXT;
        case 'countryCode':return A_COMMON_DATA_FIELD.SELECT_COUNTRIES;
        case 'routingNumber':return A_COMMON_DATA_FIELD.NUMBER;
        case 'swift':return A_COMMON_DATA_FIELD.TEXT;
        case 'isVerified':return A_COMMON_DATA_FIELD.CHECK_BOX;
        default:
            return A_COMMON_DATA_FIELD.TEXT;
    }
}

function generateFields(isWinpot) {
    return isWinpot
        ? ['bankName', 'routingNumber', 'isVerified']
        : [
            'bankName',
            'beneficiaryCity',
            'beneficiaryName',
            'branch',
            'iban',
            'countryCode',
            'routingNumber',
            'swift',
            'isVerified',
        ];
}

function getLabelForKey(key, isWinpot) {
    if(key === 'routingNumber' && isWinpot) {
        return 'CLABE';
    }
    if(key === 'bankName' && isWinpot) {
        return 'Bank ID';
    }
    return i18n.t(`crm.bankInfo.${key}`);
}

const BankInfoForm = React.memo((props) => {
    const {
        isWinpot,
        bankInfo,
        onChange,
        fieldErrors,
        helpersTexts,
        canEdit,
    } = props;

    const handleChange = useCallback((key) => (event) => {
        let targetValue;
        if (event === true || event === false) {
            targetValue = event;
        } else {
            if (typeof event === 'string') {
                targetValue = event;
            } else {
                targetValue = event?.target?.value
            }
        }
        const value = targetValue || event;
        onChange({ ...bankInfo, [key]: value });
    }, [bankInfo, onChange]);

    const isMatchBank = MEXICO_BANKS
        .some(bank => bankInfo.bankName === bank.value);


    return (
        <>
            {generateFields(isWinpot).map((key, index) => {
                const uniqueFieldId = `${bankInfo.id}_${key}`;

                return (
                    <div key={index} className='player-bankInfos__item'>
                        <ACommonDataField
                            key={uniqueFieldId}
                            component={getComponentTypeByStateKey(key)}
                            label={getLabelForKey(key, isWinpot)}
                            value={bankInfo[key] || ''}
                            handleChange={handleChange(key)}
                            datePickerComponentType="MobileDatePicker"
                            error={fieldErrors[uniqueFieldId]}
                            disabled={bankInfo['isVerified'] && key !== 'isVerified' || !canEdit}
                            helperText={fieldErrors[uniqueFieldId] || helpersTexts[uniqueFieldId]}
                        />
                    </div>
                );
            })}
            <Row>
                <div className='ml-5'>
                    {!isMatchBank &&
                        <ACommonDataField
                            component={A_COMMON_DATA_FIELD.TEXT}
                            label={'Additional bank id'}
                            value={bankInfo['additionalBankId'] || bankInfo.bankName}
                            handleChange={handleChange('additionalBankId')}
                            disabled={bankInfo['isVerified'] || !canEdit}
                        />
                    }
                </div>
            </Row>
        </>
    );
});

function BankInfoSubBlock(props) {
    const {
        player,
        onChange,
        canEdit,
    } = props;
    const { agentsList } = useSelector(state => state.AgentList);
    const { active: activeBrand } = useSelector(state => state.Brand);
    const [fieldErrors, setFieldErrors] = useState({});
    const [helpersTexts, setHelpersTexts] = useState({});
    const { t } = useTranslation();
    const [uniqueKeys, setUniqueKeys] = useState([]);

    const handleChange = useCallback((bankInfo, id) => {
        const newArray = player?.bankInfo ? [...player.bankInfo] : [];
        const index = newArray.findIndex(item => item.id === id);

        newArray[index] = bankInfo;

        const newFieldErrors = { ...fieldErrors };
        const newHelperTexts = { ...helpersTexts };

        generateFields(activeBrand?.siteName === SITE_BRAND.WINPOTMX).forEach(field => {
            const uniqueFieldId = `${id}_${field}`;

            if (field === 'routingNumber' && bankInfo[field]?.length >= 1 && !bankInfo['additionalBankId']) {
                newFieldErrors[uniqueFieldId] = validateClabe(bankInfo[field]) ? null : t('crm.invalidClabe');

                const clabeFirstThreeChars = bankInfo['routingNumber']?.substring(0, 3);
                const findRealBankCode = BANK_CODES.find(bankCode =>
                    bankCode.value === clabeFirstThreeChars
                );

                if (!findRealBankCode) {
                    newFieldErrors[uniqueFieldId] = t('crm.mismatchClabe');
                }
            } else {
                newFieldErrors[uniqueFieldId] = null;
            }
        });

        setFieldErrors(newFieldErrors);
        setHelpersTexts(newHelperTexts);

        onChange('bankInfo')(newArray);
    }, [player, fieldErrors, helpersTexts, onChange, activeBrand?.siteName]);

    const handleRemove = useCallback(id => {
        const updatedArray = [...player.bankInfo].filter(item => item.id !== id);

        onChange('bankInfo')(updatedArray);
    },[player, onChange]);

    const handleAdd = useCallback(() => {
        const newArray = player?.bankInfo ? [...player.bankInfo] : [];
        const newKey = uniqueKeys.length + 1;
        setUniqueKeys([...uniqueKeys, newKey]);

        newArray.push({
            id: newKey,
            bankName: '',
            beneficiaryCity: '',
            beneficiaryName: '',
            branch: '',
            iban: '',
            countryCode: '',
            routingNumber: '',
            swift: '',
            isVerified: '',
            verifiedAgent: '',
            verifiedAgentAt: '',
        })

        onChange('bankInfo')(newArray);
    },[player, onChange]);

    const isWinpot = activeBrand?.siteName === SITE_BRAND.WINPOTMX;

    return (
        <div className="sub-content">
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={header_icon} alt="" />
                <span className="text-uppercase">
                    {i18n.t('crm.bankInfo')}
                </span>
            </div>

            <div className="sub-content__content d-flex flex-wrap">
                <GenerateButton
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        canEdit ? handleAdd() : () => {}
                    }}
                    icon={'add'}
                    text={i18n.t(`crm.bankInfo.addId`)}
                />

                {player?.bankInfo?.map((item, index) => (
                    <Fragment key={index}>
                        <hr className='dashedHr'/>
                        <GenerateButton
                            style={{ marginRight: '10px' }}
                            onClick={() => handleRemove(item.id)}
                            icon={'remove'}
                            text={i18n.t(`crm.identification.removeId`)}
                        />
                        <hr className='dashedHr'/>

                        <div className='player-bankInfos__title'>
                            {!item?.id ? 'NEW' : `${item?.oid} | ${item.id}`}
                        </div>
                        <div className='player-bankInfos__text'>
                        created: {item?.createdAt ? moment(item?.createdAt).format(DATE_TIME_FORMAT_ss) : 'NEW'}<br/>
                            last Updated: {item?.lastChangedAt ? moment(item?.lastChangedAt).format(DATE_TIME_FORMAT_ss) : 'NEW'}<br/>
                            {
                                agentsList && item?.createdAt ?
                                    item?.verifiedAgent ?
                                        `Approved By Agent: ${agentsList?.find(agent => agent.id === item?.verifiedAgent)?.agentName}` :
                                        ''
                                    :
                                    'Approved By Agent: NEW'
                            }<br/>
                        </div>
                        <div className='player-bankInfos'>
                            <BankInfoForm
                                isWinpot={isWinpot}
                                key={item.id}
                                bankInfo={item}
                                onChange={(bankInfo) => handleChange(bankInfo, item.id)}
                                fieldErrors={fieldErrors}
                                helpersTexts={helpersTexts}
                                canEdit={canEdit}
                            />
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    );
}

BankInfoSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default React.memo(BankInfoSubBlock);
