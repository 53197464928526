import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_AGENT_EMPLOYEE_LIST } from './actionTypes';
import {
    getAgentEmployeeListSuccess,
    getAgentEmployeeListFail
} from './actions';
import { getAgentsEmployeeListApi } from '../../../helpers/agentApi';

function* fetchAgentsEmployeeListSaga({ payload }) {
    try {
        const response = yield call(getAgentsEmployeeListApi, payload);

        yield put(getAgentEmployeeListSuccess(response.data));
    } catch (error) {
        yield put(getAgentEmployeeListFail(error));
    }
}

function* agentsEmployeeSaga() {
    yield takeEvery(GET_AGENT_EMPLOYEE_LIST, fetchAgentsEmployeeListSaga);
}

export default agentsEmployeeSaga;
