import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import i18n from '../../i18n';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';
import './confirm-popup-modal.scss';
import close from '../../assets/images/common/Frame 179.svg';
import i_51 from '../../assets/images/common/Frame 176 (1).svg';

function ConfirmPopupModal({ onCancel, onConfirm, title, content, children }) {
    return (
        <Modal
            className="custom-prompt-modal"
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-uppercase">
                        {i18n.t(title)}
                    </h5>
                    <button className="close" onClick={onCancel} />
                </div>

                <ModalBody>
                    <div className="custom-prompt-modal__text">
                        {i18n.t(content)}
                        {children}
                    </div>

                    <div className="custom-prompt-modal__actions">
                        <button
                            className="btn btn-rounded btn-light"
                            onClick={onCancel}
                        >
                            <img src={close} alt="" />
                            {i18n.t('common.no')}
                        </button>

                        <button
                            className="btn btn-rounded btn-primary"
                            onClick={onConfirm}
                        >
                            <img src={i_51} alt="" />
                            {i18n.t('common.yes')}
                        </button>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    );
}

ConfirmPopupModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};

export default React.memo(ConfirmPopupModal);
