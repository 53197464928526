/* DOCUMENTS */
export const GET_PLAYER_DOCUMENTS = 'GET_PLAYER_DOCUMENTS';
export const GET_PLAYER_DOCUMENTS_SUCCESS = 'GET_PLAYER_DOCUMENTS_SUCCESS';
export const GET_PLAYER_DOCUMENTS_FAIL = 'GET_PLAYER_DOCUMENTS_FAIL';

export const GET_PENDING_DOCUMENTS = 'GET_PENDING_DOCUMENTS';
export const GET_PENDING_SUCCESS = 'GET_PENDING_SUCCESS';
export const GET_PENDING_FAIL = 'GET_PENDING_FAIL';

export const GET_BRAND_JURISDICTION = 'GET_BRAND_JURISDICTION';
export const GET_BRAND_JURISDICTION_SUCCESS = 'GET_BRAND_JURISDICTION_SUCCESS';
export const GET_BRAND_JURISDICTION_FAIL = 'GET_BRAND_JURISDICTION_FAIL';

export const GET_FIRST_PENDING_DOCUMENTS = 'GET_FIRST_PENDING_DOCUMENTS';
export const GET_FIRST_PENDING_SUCCESS = 'GET_FIRST_PENDING_SUCCESS';
export const GET_FIRST_PENDING_FAIL = 'GET_FIRST_PENDING_FAIL';

export const APPROVE_DOCUMENT = 'APPROVE_DOCUMENT';
export const APPROVE_DOCUMENT_SUCCESS = 'APPROVE_DOCUMENT_SUCCESS';
export const APPROVE_DOCUMENT_FAIL = 'APPROVE_DOCUMENT_FAIL';

export const REJECT_DOCUMENT = 'REJECT_DOCUMENT';
export const REJECT_DOCUMENT_SUCCESS = 'REJECT_DOCUMENT_SUCCESS';
export const REJECT_DOCUMENT_FAIL = 'REJECT_DOCUMENT_FAIL';

export const UPLOAD_NEW_DOCUMENT = 'UPLOAD_NEW_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';

export const UPLOAD_RETAIL_DOCUMENT = 'UPLOAD_RETAIL_DOCUMENT';
export const UPLOAD_RETAIL_DOCUMENT_SUCCESS = 'UPLOAD_RETAIL_DOCUMENT_SUCCESS';
export const UPLOAD_RETAIL_DOCUMENT_FAIL = 'UPLOAD_RETAIL_DOCUMENT_FAIL';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAIL = 'GET_DOCUMENT_FAIL';

export const GET_DOCUMENT_DATA = 'GET_DOCUMENT_DATA';
export const GET_DOCUMENT_DATA_SUCCESS = 'GET_DOCUMENT_DATA_SUCCESS';
export const GET_DOCUMENT_DATA_FAIL = 'GET_DOCUMENT_DATA_FAIL';

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';

export const ADD_DOCUMENT_COMMENTS = 'ADD_DOCUMENT_COMMENTS';
export const ADD_DOCUMENT_COMMENTS_SUCCESS = 'ADD_DOCUMENT_COMMENTS_SUCCESS';
export const ADD_DOCUMENT_COMMENTS_FAIL = 'ADD_DOCUMENT_COMMENTS_FAIL';

export const REMOVE_DOCUMENT_COMMENTS = 'REMOVE_DOCUMENT_COMMENTS';
export const REMOVE_DOCUMENT_COMMENTS_SUCCESS =
    'REMOVE_DOCUMENT_COMMENTS_SUCCESS';
export const REMOVE_DOCUMENT_COMMENTS_FAIL = 'REMOVE_DOCUMENT_COMMENTS_FAIL';

export const CLEAR_CURRENT_DOCUMENT_CONTENT = 'CLEAR_CURRENT_DOCUMENT_CONTENT';

export const CLEAR_DOCUMENTS_ERROR = 'CLEAR_DOCUMENTS_ERROR';

export const GET_DOCUMENT_HISTORY = 'GET_DOCUMENT_HISTORY';
export const GET_DOCUMENT_HISTORY_SUCCESS = 'GET_DOCUMENT_HISTORY_SUCCESS';
export const GET_DOCUMENT_HISTORY_FAIL = 'GET_DOCUMENT_HISTORY_FAIL';

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAIL = 'DOWNLOAD_DOCUMENT_FAIL';

export const SET_OCCUPATION = 'SET_OCCUPATION';
export const SET_OCCUPATION_SUCCESS = 'SET_OCCUPATION_SUCCESS';
export const SET_OCCUPATION_FAIL = 'SET_OCCUPATION_FAIL';
