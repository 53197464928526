import {
  GET_WITHDRAWAL_LIST,
  GET_WITHDRAWAL_LIST_SUCCESS,
  GET_WITHDRAWAL_LIST_FAIL,
  GET_WITHDRAWAL_COUNT,
  GET_WITHDRAWAL_COUNT_SUCCESS,
  GET_WITHDRAWAL_COUNT_FAIL,
  RESET_WITHDRAWAL_LIST_ALERTS_ERRORS,
  DOWNLOAD_WITHDRAWAL_LIST,
  DOWNLOAD_WITHDRAWAL_LIST_SUCCESS,
  DOWNLOAD_WITHDRAWAL_LIST_FAIL,
} from './actionTypes';


const DEFAULT_QUERY = {
  limit: 50,
  offset: 0
};

const INIT_STATE = {
  isLoading: false,
  error: null,

  withdrawalList: null,
  total: 0,
  query: DEFAULT_QUERY,

  isDownloading: false,
};

const Withdrawals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WITHDRAWAL_COUNT:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case GET_WITHDRAWAL_COUNT_SUCCESS:
      return {
        ...state,
        totalWithdrawAll: action.payload.total,
        isLoading: false
      };

    case GET_WITHDRAWAL_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case GET_WITHDRAWAL_LIST:
      return {
        ...state,
        query: { ...state.query, ...action.payload },
        isLoading: true,
        error: null
      };

    case GET_WITHDRAWAL_LIST_SUCCESS:
      return {
        ...state,
        withdrawalList: action.payload.result,
        total: action.payload.total,
        isLoading: false
      };

    case GET_WITHDRAWAL_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        withdrawalList: [],
        isLoading: false
      };

    case RESET_WITHDRAWAL_LIST_ALERTS_ERRORS:
      return {
        ...state,
        error: null,
      };

    case DOWNLOAD_WITHDRAWAL_LIST:
      return {
        ...state,
        isDownloading: true,
        error: null
      };

    case DOWNLOAD_WITHDRAWAL_LIST_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        error: null
      };

    case DOWNLOAD_WITHDRAWAL_LIST_FAIL:
      return {
        ...state,
        isDownloading: false,
        error: action.payload,
      };

    default:
      return state;
    }
};

export default Withdrawals;
