import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import i18n from '../../../../i18n';

import { A_COMMON_DATA_FIELD } from '../../../../constants/aCommonDataField';
import { LIMITS, NOTES_FIELD_KEY_NAMES } from '../../../../constants/notes';

import Formatter from '../../../../utils/Formatter';

import ClipboardButton from '../../../../common/cellData/CopyButton';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';
import { useSelector } from "react-redux";
import { SITE_PERMISSION } from "../../../../common/constants/common";

const CurrentNoteComponent = ({
    subject,
    content,
    isEdit,
    setIsEdit,
    allowEdit,
    setSubject,
    setContent,
    currentNote,
    assigneeAgentOid,
    setAssigneeAgentOid,
    setTag = () => {},
}) => {
    const [tagOptions, setTagOptions] = useState([]);
    const [canEdit, setCanEdit] = useState(false);

    const { brandTags } = useSelector(state => state.BrandSettingsPage);
    const { agentSelf } = useSelector(state => state.AgentSelf);

    useEffect(() => {
        const canEdit = allowEdit && isEdit
            && agentSelf?.permissions.includes(SITE_PERMISSION.Player__Edit_Note);
        setCanEdit(canEdit);
    }, [agentSelf?.permissions, allowEdit, isEdit]);

    const canEditTag = useMemo(
        () => agentSelf?.permissions?.includes(SITE_PERMISSION.IT__Get_Brand_Tags),
        [agentSelf?.permissions]
    );

    useEffect(() => {
        const values = ['', ...brandTags.map((tagData) => tagData.tag)];
        setTagOptions(values.map((value) =>({
            value,
            content: value,
        })));
    }, [brandTags]);

    const handleChange = key => value => {
        switch (key) {
            case NOTES_FIELD_KEY_NAMES.SUBJECT:
                if (value.length < LIMITS.maxSubject) {
                    setSubject(value);
                }
                break;

            case NOTES_FIELD_KEY_NAMES.CONTENT:
                if (value.length < LIMITS.maxContent) {
                    setContent(value);
                }
                break;

            case NOTES_FIELD_KEY_NAMES.ASSIGNEE_AGENT_OID:
                setAssigneeAgentOid(value);
                break;

            case NOTES_FIELD_KEY_NAMES.TAG:
                setTag(value);
                break;
        }

        setIsEdit(true);
    };

    const dateTimeMinutesFields = useMemo(
        () => [
          {
              label: i18n.t('crm.notes.readyByAssignee'),
              value:
                  Formatter.displayDateTimeMinutes(
                      currentNote?.readyByAssignee
                  ) || ''
          },
          {
              label: i18n.t('crm.notes.createdAt'),
              value: Formatter.displayDateTimeMinutes(currentNote?.createdAt)
          },
        ],
        [currentNote]
    );

    const assigningRowFields = useMemo(
        () => [
            {
                label: i18n.t('crm.notes.createdAgentOid'),
                value: currentNote?.createdAgentName,
              isShowing: true,
            }
        ],
        [
          allowEdit,
          currentNote,
          handleChange
        ]
    );

    const descriptionFields = useMemo(
        () => [
            {
              handleChange: handleChange('subject'),
              label: i18n.t('crm.notes.subject'),
              value: subject,
              allowEdit: !currentNote?.oid || canEdit,
            },
            {
              handleChange: handleChange('content'),
              label: i18n.t('crm.notes.content'),
              value: content,
              multiline: true,
              style: {
                marginTop: '40px',
                overflowY: 'auto',
              },
              allowEdit: !currentNote?.oid || canEdit,
            }
        ],
        [content, subject, handleChange, currentNote]
    );

    return (
        <>
            {currentNote?.oid && (
                <div className="d-flex align-items-center">
                    <span className="me-4">
                        <span>No. {currentNote?.oid}</span>
                        &nbsp;&nbsp;
                        <ClipboardButton text={currentNote?.oid} />
                    </span>
                </div>
            )}

            <Row className="justify-content-between note-cols-wrapper">
                {dateTimeMinutesFields.map(({ label, value }, index) => (
                    <Col key={index}>
                        <ACommonDataField
                            disabled={true}
                            label={label}
                            value={value}
                        />
                    </Col>
                ))}
                {assigningRowFields.map(
                    ({ disabled = true, isShowing, ...itemProps }, index) => (
                        <Col key={index}>
                            {isShowing && (
                                <ACommonDataField
                                    disabled={true}
                                    {...itemProps}
                                />
                            )
                            }
                        </Col>
                    )
                )}
                <Col key={'tag-col'} className="note-tag-col">
                    <ACommonDataField
                        label="Tag"
                        component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                        value={currentNote?.tag || ''}
                        handleChange={handleChange(NOTES_FIELD_KEY_NAMES.TAG)}
                        options={tagOptions}
                        disabled={!canEditTag || !canEdit && currentNote?.oid}
                    />
                </Col>
            </Row>

            <Row className="note-cols-wrapper">
                {descriptionFields.map(
                    (
                        {
                            handleChange,
                            label,
                            value,
                            style = {},
                            allowEdit,
                            ...itemProps
                        },
                        index
                    ) => (
                        <Col key={index} xs={12} style={style}>
                            <ACommonDataField
                                label={label}
                                value={value}
                                fulWidth={true}
                                disabled={!allowEdit}
                                handleChange={handleChange}
                                {...itemProps}
                            />
                        </Col>
                    )
                )}
            </Row>
        </>
    );
};

CurrentNoteComponent.propTypes = {
    subject: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isEdit: PropTypes.bool,
    setIsEdit: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    setSubject: PropTypes.func.isRequired,
    setContent: PropTypes.func.isRequired,
    currentNote: PropTypes.object.isRequired,
    assigneeAgentOid: PropTypes.number,
    setAssigneeAgentOid: PropTypes.func.isRequired,
};

CurrentNoteComponent.defaultProps = {
    isEdit: false,
};

export default React.memo(CurrentNoteComponent);
