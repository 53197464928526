import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import xlsx from "xlsx";
import moment from "moment";

import i18n from "../../i18n";
import GameTable from "../../common/tables/GameTable";
import { handleChangePath } from "../../common/utils/common";
import { getFilteredGames } from "../../common/utils/filter";
import {
  getAllGames,
  createGame,
  getGameVendors,
  getGameProviders,
  bulkInsertGames,
  downloadGameList,
  getGameTypes,
  downloadGamesDBTemplate,
  uploadGamesDBToCreate,
  updateDBGames,
} from "../../store/actions";
import { DIRECTION } from "../../constants/layout";
import Loader from "../../common/components/Loader";
import WrongGamesModal from "../CRM/modal/WrongGamesModal";

import "./styles.scss"

import TableFilter from "../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_GAME_LIST } from "../../constants/Filter";
import { AttributionType, UNIQUE_GAME_FIELDS } from "./common/constants";
import clsx from "clsx";
import { DEFAULT_PAGE_SIZE, SITE_PERMISSION } from "../../common/constants/common";
import SelectTableView from "./components/SelectTableView";
import FormSearch from "../../UI/formSearch/FormSearch";
import download from "../../assets/images/common/download.svg";
import gamesDBTemplateIcon from "../../assets/images/common/gamesDBTemplateIcon.svg";
import settings from "../../assets/images/layout/settings.svg";
import DragFileInput from "../../common/inputs/common/DragFileInput";
import { XLSXformat } from "../../common/inputs/common/FileTypes/fileTypes";
import Tooltip from "@mui/material/Tooltip";

const DATE_FORMAT_REGEX = /\d{1,2}\/\d{1,2}\/\d{4}/;


const rowHeight = 42;


const prepareGamesData = (gamesData, isServerError = false) => gamesData
  .map((game) => {
    const {
      id,
      oid,
      friendlyName,
      gameType,
      lines,
      licenses,
      vendorName,
      providerName,
      createdAt,
      rtp,
      externalMobileId,
      externalDesktopId,
      bonusFactor,
      errors,
    } = game;
    return {
      'ID': id,
      'OID': oid,
      'Game': friendlyName,
      'Type': gameType,
      'Lines': lines,
      'Licenses': licenses.join(','),
      'Game vendor': vendorName,
      'Game provider': providerName,
      'Added time': moment(createdAt).format('YYYY-MM-DD HH:mm'),
      'RTP': `${rtp} %`,
      'Game ID Mobile': externalMobileId,
      'Game ID Desktop': externalDesktopId,
      'Coefficient': `${bonusFactor} %`,
      'Errors': isServerError ? 'Server errors;' : errors,
    };
  });

const getColumns = () => {
  const attributionColumns = Object.keys(AttributionType)
      .map((id) => ({
        id,
        title: i18n.t(`content.${id}`),
        className: 'text-align-center',
      }));

  const columns = [
    {
      id: 'check',
      title: '',
    },
    {
      id: 'game',
      source: 'friendlyName',
      title: i18n.t('content.gameName'),
      className: 'white-space-normal bold game-name',
    },
    {
      id: 'oid',
      source: 'oid',
      title: i18n.t('content.oid'),
      className: 'text-align-center',
    },
    {
      id: 'addedTime',
      source: 'createdAt',
      title: i18n.t('content.addedTime'),
      className: 'text-align-center width150',
    },
    {
      id: 'genre',
      source: 'gameType',
      title: i18n.t('content.type'),
      className: 'text-align-center game-type',
    },
    {
      id: 'lines',
      source: 'lines',
      title: i18n.t('content.lines'),
      className: 'text-align-center',
    },
    {
      id: 'gameVendor',
      source: 'vendorName',
      title: i18n.t('content.gameVendor'),
      className: 'text-align-center width150 game-vendor',
    },
    {
      id: 'gameProvider',
      source: 'providerName',
      title: i18n.t('content.gameProvider'),
      className: 'text-align-center width150',
    },
    {
      id: 'rtp',
      title: i18n.t('content.rtp'),
      className: 'text-align-center',
    },
    {
      id: 'externalDesktopId',
      title: i18n.t('content.gameIdDesktop'),
      className: 'text-align-center width150',
    },
    {
      id: 'externalMobileId',
      title: i18n.t('content.gameIdMobile'),
      className: 'text-align-center width150',
    },
    ...attributionColumns,
  ];

  return columns;
};

const NOT_SORTED_COLUMNS = [
  'ProgressiveSeedAmount',
  'Reels',
  'Paylines',
  'DefaultBet',
  'MinBet',
  'MaxBet',
  'MaxPayout',
  'MaxMultiplier',
  'HitFrequency',
];

class GamesDBManagement extends Component {
  constructor(props) {
    super(props);

    handleChangePath(props);
    this.state = {
      games: [],
      sortedGames: [],
      activePage: 1,
      pageCount: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      currentRowHeight: rowHeight,
      gamesCount: 0,
      searchValue: '',
      isIncreaseGames: [],
      isDecreaseGames: [],

      showWrongGamesModal: false,
      agentPermissions: props.agentSelf?.permissions || [],
      checkedAll: false,
      gameVendorsArr: [],

      isFileParsing: false,
      isShowTableSettings: false,

      tableColumns: getColumns(),
    };

    this.boxRef = null;
    this.wrongExcelData = [];
    this.wrongLocalGames = [];
    this.correctGames = [];

    this.gameVendorNames = [];
    this.gameProviderNames = [];

    this.gamesExistedData = {
      id: [],
      oid: [],
      friendlyName: [],
      externalDesktopId: [],
      externalMobileId: [],
    };
    this.gamesNotUniqData = {
      id: [],
      oid: [],
      friendlyName: [],
      externalDesktopId: [],
      externalMobileId: [],
    };
  }

  componentDidMount() {
    this.props.getAllGames();
    this.props.getGameTypes();
    this.props.getGameVendors();
    this.props.getGameProviders();
    this.setState({agentPermissions: this.props.agentSelf?.permissions || []})
  }

  filterGames(games) {
    const { currentFilter } = this.props;
    const { gameVendorsArr } = this.state;

    const preparedGames = games.map((game) => {
      let { ProviderReleaseDate } = game;

      if (DATE_FORMAT_REGEX.test(ProviderReleaseDate)) {
        let newDate = moment(ProviderReleaseDate, "MM/DD/YYYY");

        if (!newDate.isValid()) {
          newDate = moment(ProviderReleaseDate, "DD/MM/YYYY");
        }

        return {
          ...game,
          ProviderReleaseDate: newDate.isValid() ? newDate.format('YYYY-MM-DD') : '',
        };
      }

      return game;
    });

    return getFilteredGames(currentFilter, preparedGames, gameVendorsArr);
  }

  prepareGamesExistedData = (games) => {
    const dbGamesCount = games.length;

    // Actualize size of existed data arrays
    UNIQUE_GAME_FIELDS.forEach((field) => this.gamesExistedData[field].length = dbGamesCount);

    // Add game existed data
    games.forEach((game, ind) => {
      UNIQUE_GAME_FIELDS.forEach((field) =>
          this.gamesExistedData[field][ind] = field === 'oid' ? +game[field] : game[field]);
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      gameVendors,
      gameProviders,
      games: dbGames,
      errorGames,
      currentFilter,
      agentSelf,
      isCreatingGame,
      isUpdatingGames,
      isBulkUpload,
      bulkUploadResult,
      isDownloading,
      isDownloadingTemplate,
    } = this.props;

    if (errorGames && isCreatingGame !== prevProps.isCreatingGame) {
        if (errorGames.message === 'duplication.error') {
          this.props.alert.error(i18n.t('crm.alerts.duplicateGameID'));
        }
    }

    if (gameProviders && gameProviders !== prevProps.gameProviders) {
      this.gameProviderNames = new Array(gameProviders.length);
      gameProviders.map((provider, ind) => {
        const { name } = provider;
        this.gameProviderNames[ind] = name;
      });
    }

    if (gameVendors && gameVendors !== prevProps.gameVendors) {
      this.gameVendorNames = new Array(gameVendors.length);
      const gameVendorsArr = gameVendors.map((vendor, ind) => {
        const { id, name, configuration } = vendor;
        this.gameVendorNames[ind] = name;
        return {
          id,
          name: configuration.direct ? `${name} (direct)` : name,
        }
      });

      gameVendorsArr.sort((a1, a2) => a1.name < a2.name ? -1 : 1);

      this.setState({gameVendorsArr});
    }

    if (dbGames && dbGames !== prevProps.games) {
      const games = dbGames.result.map((game) => {
        const { details, ...otherFields } = game;
        return {
          ...otherFields,
          ...(details || {}),
        }
      });

      this.prepareGamesExistedData(games);

      const filteredGames = this.filterGames(games) || [];
      const gamesCount = filteredGames.length ?? 0;
      const { pageSize } = this.state;
      this.setState({
        activePage: 1,
        pageCount: Math.trunc(gamesCount / pageSize + 1),
        games,
        sortedGames: [...filteredGames],
        gamesCount,
      });
    }

    if (currentFilter !== prevProps.currentFilter) {
      const filteredGames = this.filterGames(this.state.games) || [];
      const gamesCount = filteredGames.length ?? 0;
      const { pageSize } = this.state;
      this.setState({
        activePage: 1,
        pageCount: Math.trunc(gamesCount / pageSize + 1),
        sortedGames: [...filteredGames],
        gamesCount,
      });
    }

    if (agentSelf !== prevProps.agentSelf) {
      this.setState({agentPermissions: agentSelf?.permissions || []});
    }

    if (!isCreatingGame && isCreatingGame !== prevProps.isCreatingGame
      || !isUpdatingGames && isUpdatingGames !== prevProps.isUpdatingGames) {
      if (errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.errorFileProcessing'));
      }
      else {
        this.props.getAllGames();
        this.props.getGameTypes();

        if (this.wrongLocalGames.length === 0) {
          this.props.alert.success(i18n.t('crm.alerts.gameWasUpdated'));
        } else {
          this.props.alert.info(i18n.t('crm.alerts.gameWasUpdatedWithErrors'));
        }
      }
    }

    if (!isDownloadingTemplate && isDownloadingTemplate !== prevProps.isDownloadingTemplate) {
      this.props.alert.success(i18n.t('crm.alerts.templateDownloaded'));
    }

    if (!isDownloading && isDownloading !== prevProps.isDownloading) {
      this.props.alert.success(i18n.t('crm.alerts.fileDownloaded'));
    }

    if (!isBulkUpload && isBulkUpload !== prevProps.isBulkUpload) {
      if (errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.errorFileProcessing'));
      }
      else {
        this.props.getAllGames();
        this.props.getGameTypes();

        if (this.wrongLocalGames.length === 0) {
          this.props.alert.success(i18n.t('crm.alerts.gameWasUpdated'));
        } else {
          this.props.alert.info(i18n.t('crm.alerts.gameWasUpdatedWithErrors'));
        }
      }

      this.wrongExcelData = bulkUploadResult && Array.isArray(bulkUploadResult)
          ? prepareGamesData(bulkUploadResult, true)
          : !!errorGames && this.correctGames.length > 0
              ? prepareGamesData(this.correctGames, true)
              : [];

      if (this.wrongExcelData.length !== 0 || this.wrongLocalGames.length !== 0) {
        this.setState({ showWrongGamesModal: true });
      }
    }
  }

  handleChangePage = (activePage) => this.setState({activePage});

  handleSetPageSize = (pageSize) => {
    const { gamesCount } = this.state;
    this.setState({
      pageCount: Math.trunc(gamesCount / pageSize + 1),
      pageSize,
      activePage: 1,
    });
  }

  uploadGamesToDB = (file) => {
    this.props.uploadGamesDBToCreate(file);
  };

  handleToggleWrongGamesModal = () => {
    const { showWrongGamesModal } = this.state;
    if (showWrongGamesModal) {
      this.correctGames = [];
      this.wrongLocalGames = [];
      this.wrongExcelData = [];
    }

    this.setState({ showWrongGamesModal: !showWrongGamesModal });
  };

  handleSortGames = (column, direction = DIRECTION.ASC) => {
    const { sortedGames } = this.state;
    const fieldName = column.source || column.id;

    const nullValues = [undefined, null, ''];

    const lessVal = direction === DIRECTION.ASC ? -1 : 1; // < condition (less than)
    const moreVal = direction === DIRECTION.ASC ? 1 : -1; // > condition (more than)

    const compareValues = (a, b) => {
      if (a === b) {
        return 0;
      }

      // Games with undefined, null and '' field values move to end of game list
      if (nullValues.includes(a)) {
        return 1;
      }
      if (nullValues.includes(b)) {
        return -1;
      }

      // Sort games with not null field values
      return a < b
        ? lessVal
        : moreVal;
    };

    const sortOidFunction = (a, b) => {
      const valueA = parseFloat(a[fieldName]);
      const valueB = parseFloat(b[fieldName]);
      return compareValues(valueA, valueB);
    };

    const sortFunction = (a, b) => compareValues(a[fieldName], b[fieldName]);

    if (fieldName === 'oid') {
      sortedGames.sort(sortOidFunction);
    } else {
      sortedGames.sort(sortFunction);
    }

    this.setState({ sortedGames: [...sortedGames] });
  };

  handleSaveWrongGame = async () => {
    const workbook = xlsx.utils.book_new();
    const workSheet = xlsx.utils.json_to_sheet(
      [
          ...this.wrongExcelData,
          ...this.wrongLocalGames,
      ],
      {
        header: [
          'ID',
          'OID',
          'Game',
          'Type',
          'Lines',
          'Licenses',
          'Game vendor',
          'Game provider',
          'Added time',
          'RTP',
          'Game ID Mobile',
          'Game ID Desktop',
          'Coefficient',
          'Errors',
        ],
      },
    );

    xlsx.utils.book_append_sheet(workbook, workSheet, 'WrongGames');
    xlsx.writeFile(workbook, "WrongGames.xlsx");

    this.wrongExcelData = [];
    this.wrongLocalGames = [];

    this.handleToggleWrongGamesModal();
  }

  handleUpdateDBGames = (file) => {
    this.props.updateDBGames(file);
  };

  handleDownload = () => {
    this.props.downloadGameList({
      query: {
        filterDynamic: this.props.currentFilter
      }
    });
  };

  downloadTemplateFile = () => {
    this.props.downloadGamesDBTemplate();
  }

  handleCheckAll = () => {
    const {sortedGames, checkedAll} = this.state;

    const checked = !checkedAll;
    sortedGames.forEach((game) => game.checked = checked);
    this.setState({
      sortedGames: sortedGames.map(game => ({...game})),
      checkedAll: checked,
    });
  };

  handleGameCheckboxChange = (gameId) => {
    this.setState(prevState => {
      const updatedGames = prevState.games.map(game => {
        if (game.id === gameId) {
          const checked = !game.checked;

          return {
            ...game,
            checked,
          };
        }
        return game;
      });

      return {
        games: updatedGames,
      };
    }, () => {
      const { searchValue } = this.state;
      const filteredGames = this.filterGames(this.state.games);
      const sortedGames = filteredGames.filter((game) =>
          searchValue === '' || game.friendlyName?.toLowerCase().includes(searchValue.toLowerCase()));

      this.setState({
        sortedGames,
      });
    });
  }

  handleChangeSearchValue = (e) => {
    const { games, pageSize } = this.state;
    const { currentFilter } = this.props;
    const searchValue = e.target.value;

    const filteredGames = currentFilter.length
        ? this.filterGames(games)
        : games;

    const sortedGames = filteredGames.filter((game) =>
        searchValue === '' || game.friendlyName?.toLowerCase().includes(searchValue.toLowerCase()));

    this.setState({
      pageCount: Math.trunc(sortedGames.length / pageSize + 1),
      pageSize,
      searchValue,
      sortedGames,
      activePage: 1
    });
  };

  render() {
    const {
      sortedGames,
      activePage,
      pageCount,
      pageSize,
      currentRowHeight,
      gamesCount,
      showWrongGamesModal,
      agentPermissions,
      gameVendorsArr,
      isFileParsing,
      isShowTableSettings,
      tableColumns,
    } = this.state

    const {
      gameTypes,
      isDownloading,
      isDownloadingTemplate,
      isLoadingGames,
      isBulkUpload,
    } = this.props;


    const isGamesDB = location.pathname.includes('games-db');

    const canAddByPermission = agentPermissions.includes(SITE_PERMISSION.Game__Add_DB_Game);
    const canEditByPermission = agentPermissions.includes(SITE_PERMISSION.Game__Edit_DB_Game);
    const canImport = agentPermissions.includes(SITE_PERMISSION.Game__Import_DB_Games);
    const canExport = agentPermissions.includes(SITE_PERMISSION.Game__Export_DB_Games);
    const canOnlyView = agentPermissions.includes(SITE_PERMISSION.Game__View_DB_Game) &&
        (
            !canEditByPermission ||
            !canAddByPermission ||
            !canImport ||
            !canExport
        )
    const autoHeight = pageSize <= 10;

    return (
      <div className="page-content document-content game-category-content">
        <MetaTags>
          <title>{i18n.t('content.gamesDB')} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className="page-card game-category-card game-management-card game-db-card">
                <CardBody className="game-category-info">
                  <div
                      className="page-title-table"
                  >
                    <TableFilter
                        filterListOptions={FILTER_MENU_GAME_LIST(gameVendorsArr, gameTypes)}
                        isDisabled={isLoadingGames || isBulkUpload}
                        category={FILTER_CATEGORY.GAME}
                    />
                  </div>

                  <div
                    className="game-category__header"
                  >
                    <div className="add-category-modal-header">
                      <div className='ml-10 d-flex align-items-center'>
                        <FormSearch
                            onChangeSearchValue={this.handleChangeSearchValue}
                            showIconSearch
                        />
                      </div>
                    </div>

                    <div className="d-flex csv-uploader mt-3">
                      {isLoadingGames || isBulkUpload || isFileParsing ? (
                          <div className={clsx("loader-games-db", {
                                  "loader-without-perm": canOnlyView,
                          })}>
                            <Loader size={'sm'}/>
                          </div>
                      ) : (
                          <div className="count-games-text">
                            {sortedGames.length}&nbsp;{i18n.t(`crm.game${gamesCount !== 1 ? 's' : ''}`)}
                          </div>
                      )}
                    </div>

                    <div className='d-flex justify-content-end mt-4 me-3 download-button'>
                      <div className={clsx('', {['d-none']: !canAddByPermission})}>
                        <Tooltip arrow title={i18n.t('content.GamesDBTemplate')}>
                          <button
                              className='btn download-gamesDB-template'
                              onClick={this.downloadTemplateFile}
                              disabled={isDownloadingTemplate}
                          >
                            <img src={gamesDBTemplateIcon} alt="Download a template"/>
                          </button>
                        </Tooltip>
                      </div>
                      <div className={clsx('add-games-button', {
                        ['d-none']: !canAddByPermission
                      })}>
                        <DragFileInput
                            accept={XLSXformat}
                            typeFile={"XLSX"}
                            tooltipText={'uploadToCreate'}
                            onSelect={this.uploadGamesToDB}
                            withLabel={false}
                            addFileMode={true}
                            imgMode
                        />
                      </div>
                      <Tooltip arrow title={i18n.t('content.downloadGamesDb')}>
                        <button
                            className={clsx("btn btn-rounded btn-primary app-btn-only-img-sm ml-5", {
                              ['d-none']: !sortedGames?.length || !canExport,
                            })}
                            onClick={this.handleDownload}
                            disabled={isDownloading}
                        >
                          <img src={download} alt=""/>
                        </button>
                      </Tooltip>
                    </div>

                    <div
                        className={clsx("send-button", {
                          'd-none': !canEditByPermission || !canImport,
                        })}
                        style={{marginTop: '25px'}}
                    >
                      <DragFileInput
                          accept={XLSXformat}
                          typeFile={"XLSX"}
                          tooltipText={'uploadFileDB'}
                          onSelect={this.handleUpdateDBGames}
                          withLabel={false}
                          imgMode
                      />
                    </div>


                    <Tooltip title={i18n.t('crm.settings')} arrow>
                      <button
                          className="btn settings-button"
                          style={{marginTop: '28px'}}
                          onClick={() => this.setState({isShowTableSettings: true})}
                      >
                        <img src={settings} alt="Table's Settings"/>
                      </button>
                    </Tooltip>
                  </div>

                  {isLoadingGames || isBulkUpload ? (
                      <div className='empty-table-block'>
                        <Loader size={'lg'}/>
                      </div>
                  ) : (
                      <>
                        <div
                            className={clsx("game-table-wrapper", {
                              'autoTableHeight': autoHeight,
                            })}
                            ref={ref => this.boxRef = ref}
                        >
                          <GameTable
                              columns={tableColumns}
                              notSortedColumns={NOT_SORTED_COLUMNS}
                              games={sortedGames}
                              activePage={activePage}
                              pageSize={pageSize}
                              currentRowHeight={currentRowHeight}
                              onSortGames={this.handleSortGames}
                              canEditByPermission={canEditByPermission}
                              onCheckAll={this.handleCheckAll}
                              isGamePriorityUpdateModal={false}
                              handleGameCheckboxChange={this.handleGameCheckboxChange}
                              isGamesDB={isGamesDB}
                              isShowTableSettings={isShowTableSettings}
                              onCloseTableSettings={() => this.setState({isShowTableSettings: false})}
                          />
                        </div>
                        <SelectTableView
                            recordsCount={gamesCount}
                            pageSize={pageSize}
                            activePage={activePage}
                            pageCount={pageCount}
                            onChange={this.handleSetPageSize}
                            onChangePage={this.handleChangePage}
                        />
                      </>
                  )}
                  {!sortedGames.length && !isLoadingGames && (
                      <div className='no-data-game-table'>
                        {i18n.t('crm.emptyTable')}
                      </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {showWrongGamesModal &&
            <WrongGamesModal
                onConfirm={this.handleSaveWrongGame}
                onHide={this.handleToggleWrongGamesModal}
                wrongGames={this.wrongExcelData ?? []}
                wrongLocalGames={this.wrongLocalGames}
            />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {agentSelf} = state.AgentSelf;
  const {
    games,
    gameVendors,
    gameProviders,
    gameTypes,
    isDownloading,
    isDownloadingTemplate,
    isCreatingGame,
    isLoadingGames,
    isUpdatingGames,
    errorGames,
    isBulkUpload,
    bulkUploadResult,
  } = state.Games;
  const {
    currentFilter,
  } = state.Filter;
  return {
    games,
    gameVendors,
    gameProviders,
    isDownloading,
    isDownloadingTemplate,
    isCreatingGame,
    isLoadingGames,
    isUpdatingGames,
    errorGames,
    isBulkUpload,
    bulkUploadResult,
    currentFilter,
    agentSelf,
    gameTypes,
  };
};

export default React.memo(
    withRouter(
        connect(mapStateToProps, {
          getAllGames,
          getGameTypes,
          createGame,
          getGameVendors,
          getGameProviders,
          bulkInsertGames,
          downloadGameList,
          downloadGamesDBTemplate,
          uploadGamesDBToCreate,
          updateDBGames,
    })(withAlert()(GamesDBManagement))
  )
);
