import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentsList } from '../../../../store/agents/agents/actions';
import i18n from '../../../../i18n';
import { A_COMMON_DATA_FIELD } from '../../../../constants/aCommonDataField';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';

const StatisticsFilter = ({ onChange, filter }) => {
    const dispatch = useDispatch();
    const { agentsList } = useSelector(state => state.AgentList);
    useEffect(() => {
        dispatch(getAgentsList());
    }, []);

    const handleChange = useCallback(
        name => {
            return value => {
                onChange({ ...filter, [name]: value });
            };
        },
        [filter]
    );

    const agentList = useMemo(
        () =>
            agentsList?.map(agent => ({
                value: agent.id,
                content: agent.agentName
            })) || [],
        [agentsList]
    );

    const formSchema = useMemo(() => {
        return [
            {
                label: i18n.t('admin.office'),
                valueName: 'office',
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                options: []
            },
            {
                label: i18n.t('admin.agent'),
                valueName: 'agentOwner',
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                options: agentList
            }
        ];
    }, [agentList]);

    return (
        <div className="statistics-filter">
            {formSchema.map(item => {
                return (
                    <ACommonDataField
                        key={item.label}
                        component={item.component}
                        label={item.label}
                        handleChange={handleChange(item.valueName)}
                        value={filter[item.valueName]}
                        options={item.options}
                    />
                );
            })}
        </div>
    );
};

StatisticsFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired
};

export default React.memo(StatisticsFilter);
