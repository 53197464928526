import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import {useRouteMatch} from "react-router-dom";

import i18n from '../../i18n';

import CommonTable from '../CommonTable';
import Loader from '../../common/components/Loader';

import './style.scss';
import clsx from "clsx";

const tableRowLengthWithoutScroll = 5;
const paginationHeight = 68;

const NewCustomTable = ({
    isHeader = false,
    columns,
    activeTab: financeSection,
    className = '',
    isLoading = false,
    isResponsive = false,
    onSortItems,
    defaultSortColumn,
    defaultOrder,
    children,
    sortAfterMount,
}) => {
    const [isTableShort, setIsTableShort] = useState(false);
    const tableRef = useRef(null);
    const [tableHeight, setTableHeight] = useState(null);
    const [tableOffset, setTableOffset] = useState(290);
    const scrollableNodeRef = React.createRef();
    const routeConfig = useRouteMatch();
    const currPath = routeConfig?.path;
    const autoHeightTables = currPath.includes('promotions') ||
        currPath.includes('withdrawals-pending-approve') ||
        routeConfig.path === '/player-list/:id' ||
        routeConfig.path === '/deposits/:id' ||
        routeConfig.path === '/withdrawals/:id' ||
        routeConfig.path === '/deposits-action/:id' ||
        routeConfig.path === '/deposits-declines/:id' ||
        routeConfig.path === '/withdrawals-pending-transfer/:id' ||
        routeConfig.path === '/pending-kyc-verification/:id'

    useEffect(() => {
        if (scrollableNodeRef.current && !autoHeightTables) {
            const tableHeight = window.innerHeight - scrollableNodeRef.current.getBoundingClientRect()?.top
            setTableOffset(tableHeight - paginationHeight);
        } else {
            setTableOffset('auto');
        }
    }, [scrollableNodeRef.current, isLoading, children]);

    useEffect(() => {
        setIsTableShort(children?.length <= tableRowLengthWithoutScroll);
    }, [tableRowLengthWithoutScroll, children?.length])

    useEffect(() => {
        scrollableNodeRef?.current?.scrollTo({
            top:0
        });
    }, [tableHeight])

    const tableCustomClasses = clsx('table-custom-wrapper', {
        'big-scrollbar-y': isHeader,
        'custom-border': !isHeader,
        'small-scrollbar-y': !isHeader,
        'scrollbar-x': true,
        'removeScroll': isTableShort,
        'autoHeight': autoHeightTables,
    });

    const isPlayerView = routeConfig.path === '/player-list/:id';

    return (
        <>
            {isLoading ? (
                <div className='empty-table-block'>
                    <Loader size={'lg'}/>
                </div>
            ) : (
                <>
                    {(children && children.length) ? (
                        <SimpleBar
                            scrollableNodeProps={{ ref: scrollableNodeRef }}
                            forceVisible={isTableShort ? 'x' : 'y'}
                            style={{
                                height: tableOffset,
                                overflowY: isTableShort ? 'hidden' : '',
                            }}
                            autoHide={false}
                            className={tableCustomClasses}
                        >
                            <CommonTable
                                columns={columns}
                                innerRef={tableRef}
                                className={clsx('custom-table', {
                                    'big-table': isHeader,
                                    'small-table': !isHeader,
                                    'trHoverEffect': isPlayerView
                                }, className)}
                                onSortItems={onSortItems}
                                defaultSortColumn={defaultSortColumn}
                                defaultOrder={defaultOrder}
                                sortAfterMount={sortAfterMount}
                                isResponsive={isResponsive}
                                isLoading={isLoading}
                            >
                                {isLoading && <tr className='d-flex justify-content-center'>
                                    <td className={'text-center'} colSpan={columns.length}>
                                        <Loader size={'lg'}/>
                                    </td>
                                </tr>}
                                {!isLoading && children}
                            </CommonTable>
                        </SimpleBar>
                    ) : (
                        <div className='empty-table-block'>
                            {i18n.t('crm.emptyTable')}
                        </div>
                    )}
                </>
            )}
        </>
)};

NewCustomTable.propTypes = {
    isHeader: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            className: PropTypes.string,
            custom: PropTypes.any
        })
    ).isRequired,
    maxHeight: PropTypes.number,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    onSortItems: PropTypes.func,
    defaultSortColumn: PropTypes.object,
    defaultOrder: PropTypes.string,
    sortAfterMount: PropTypes.bool,
};

NewCustomTable.defaultProps = {
    onSortItems: () => {},
    sortAfterMount: true,
};

export default React.memo(NewCustomTable);
