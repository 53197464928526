import {useCallback, useMemo, useState} from 'react';
import {getLocalStorageFilter} from "../store/filters/reducer";

export default function useList(
    apiService,
    total,
    query,
    currentFilter,
    defaultFilterDynamic = [],
    withoutFilter = false
) {
    const [activePage, setActivePage] = useState(1);

    const handleChangePage = useCallback(
        nextPage => {
            setActivePage(nextPage);
            const offset = query.limit * (nextPage - 1);
            const requestQuery = { ...query, offset };

            const localStorageFilter = getLocalStorageFilter();

            if (!withoutFilter) {
                if (currentFilter) {
                    requestQuery.filterDynamic = currentFilter;
                }
                else if (localStorageFilter) {
                    requestQuery.filterDynamic = localStorageFilter;
                }
                if (defaultFilterDynamic && localStorageFilter && requestQuery.filterDynamic) {
                    requestQuery.filterDynamic = [...requestQuery.filterDynamic, ...defaultFilterDynamic];
                } else if (defaultFilterDynamic) {
                    requestQuery.filterDynamic = [...defaultFilterDynamic];
                }
            }
            apiService(requestQuery);
        },
        [query, currentFilter, withoutFilter, defaultFilterDynamic, apiService]
    );

    const pageCount = useMemo(
        () => Math.ceil(total / query.limit),
        [total, query.limit]
    );

    return {
        pageCount,
        activePage,
        handleChangePage
    };
}
