import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CheckboxInput from '../../../common/inputs/common/CheckboxInput';
import i18n from '../../../i18n';
import { SEND_ALERT_TYPE } from './automationConstants';

function SendAlertCheckbox({ value, onChange, ...props }) {
    const handleChange = useCallback(
        key => {
            const list = [...value];
            if (!value.includes(key)) {
                list.push(key);
            } else {
                list.splice(list.indexOf(key), 1);
            }
            onChange(list);
        },
        [value, onChange]
    );

    return (
        <>
            {Object.keys(SEND_ALERT_TYPE).map(key => {
                return (
                    <div key={key} className="mb-3">
                        <CheckboxInput
                            {...props}
                            checked={value.includes(key)}
                            onChange={() => handleChange(key)}
                        >
                            {i18n.t(`workflow.constants.${key}`)}
                        </CheckboxInput>
                    </div>
                );
            })}
        </>
    );
}

SendAlertCheckbox.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default React.memo(SendAlertCheckbox);
