import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import {Col, Container, Row} from 'reactstrap';

import Breadcrumb from '../../common/components/Breadcrumb';
import BottomButtons from './components/BottomButtons';
import CustomModal from '../../common/components/CustomModal';

import './styles.scss';

import useAlertService from '../../hooks/useAlertService';
import {useDispatch, useSelector} from 'react-redux';
import {
    approvePendingWithdrawal,
    declinePendingWithdrawal,
    getPendingWithdrawal,
    resetPendingWithdrawalAlertsErrors
} from '../../store/actions';
import Loader from '../../common/components/Loader';
import {trimToNull} from '../../common/utils/StringUtils';
import {PAYMENT_STATUS} from '../../constants/status';
import PaymentMainCard from './PaymentMainCard';
import PaymentDetailedCard from './PaymentDetailedCard';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';
import {A_COMMON_DATA_FIELD} from '../../constants/aCommonDataField';
import DocumentView from "../CRM/DocumentView";
import {getDocumentData} from "../../helpers/documentApi";
import {useTranslation} from "react-i18next";
import {SITE_PERMISSION} from "../../common/constants/common";

const BTN_TYPES = {
    decline: 'decline',
    approve: 'approve'
};

function PendingTransferWithdrawView() {
    const { i18n } = useTranslation();
    const { agentSelf } = useSelector(state => state.AgentSelf);

    const agentPermissions = agentSelf?.permissions;
    const canEditByPermission = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__WDs_Approval);
    }, [agentSelf])

    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const {isLoading, withdrawal, error, successAlert} = useSelector(
        state => state.PendingWithdrawalDetailed
    );

    const [state, setState] = useState({
        isModalOpen: false,
        modalBtnType: '',
        reasonDescription: ''
    });
    const [file, setFile] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [bankStatement, setBankStatement] = useState(false);

    const switchPopupOpen = () => {
        setIsPopupOpen(!isPopupOpen);
    }

    useEffect(() => {
        if (error && error?.message) {
            alertService.showError(error?.message);
            dispatch(resetPendingWithdrawalAlertsErrors());
            handleSetDefaultState();
        }
    }, [error]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetPendingWithdrawalAlertsErrors());
            handleSetDefaultState();
        }
    }, [successAlert]);

    useEffect(() => {
        loadPayment();
    }, [params.id]);

    const loadPayment = () => dispatch(getPendingWithdrawal(params.id));

    const handleToggleModal = modalBtnType => {
        setState(prevState => ({
            isModalOpen: !prevState.isModalOpen,
            modalBtnType,
            reasonDescription: ''
        }));
    };

    const handleReasonDescriptionChange = useCallback(
        value => {
            setState(prevState => ({...prevState, reasonDescription: value}));
        },
        [state]
    );

    const handleSetDefaultState = () => {
        setState({
            isModalOpen: false,
            modalBtnType: '',
            reasonDescription: ''
        });
    };

    const handleClickModalBtn = useCallback(() => {
        const id = params.id;
        const dto = {comment: trimToNull(state.reasonDescription)};

        if (state.modalBtnType === BTN_TYPES.decline) {
            handleDecline(id, dto);
        } else if (state.modalBtnType === BTN_TYPES.approve) {
            handleApprove(id, dto);
        }
    }, [state]);

    const handleApprove = (id, dto) =>
        dispatch(approvePendingWithdrawal({id, dto}));

    const handleDecline = (id, dto) =>
        dispatch(declinePendingWithdrawal({id, dto}));

    const {isModalOpen, modalBtnType} = state;

    useEffect(() => {
        if (withdrawal?.statementDocumentId) {
            getDocumentData(withdrawal?.statementDocumentId).then((data) => {
                setFile(data.data);
                setBankStatement(true);
            })
        }
    }, [withdrawal])

    return (
        <>
            {file &&
                <CustomModal
                    className='document-modal documents-tab-modal'
                    titleText={i18n.t('crm.document.view.title')}
                    isOpen={isPopupOpen}
                    onToggle={() => switchPopupOpen()}
                    bodyRender={() => (
                        <DocumentView
                            getPlayerDocuments={() => []}
                            currentDocument={file}
                            isPopupOpen={isPopupOpen}
                        />
                    )}
                    withFooter={false}
                />
            }
            <div className="page-content document-content page-content_responsive with-bottom-buttons">
                <MetaTags>
                    <title>{i18n.t('finance.withdrawView')} - {process.env.REACT_APP_APP_NAME}</title>
                </MetaTags>
                <Container fluid>
                    {withdrawal ?
                        <Row>
                            <Col lg="12">
                                <PaymentMainCard payment={withdrawal}/>
                                <PaymentDetailedCard
                                    bankStatement={bankStatement}
                                    switchPopupOpen={switchPopupOpen}
                                    payment={withdrawal}
                                    reloadPayment={loadPayment}
                                    canEditByPermission={canEditByPermission}
                                    isWithdrawal
                                />
                                {withdrawal.status ===
                                    PAYMENT_STATUS.PENDING_FOR_APPROVAL && (
                                        <BottomButtons
                                            className=""
                                            onDecline={() =>
                                                handleToggleModal(BTN_TYPES.decline)
                                            }
                                            onApprove={() =>
                                                handleToggleModal(BTN_TYPES.approve)
                                            }
                                        />
                                    )}
                            </Col>
                            <CustomModal
                                titleText={i18n.t('finance.indicateTheReason')}
                                isOpen={isModalOpen}
                                onToggle={() => handleToggleModal(modalBtnType)}
                                onClick={handleClickModalBtn}
                                btnText={modalBtnType}
                                btnDisabled={!state.reasonDescription || isLoading}
                                bodyRender={() => (
                                    <ACommonDataField
                                        fulWidth={true}
                                        component={A_COMMON_DATA_FIELD.TEXT}
                                        value={state.reasonDescription}
                                        handleChange={handleReasonDescriptionChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                )}
                            />
                        </Row> :
                        <Loader/>
                    }
                </Container>
            </div>
        </>

    );
}

export default withRouter(PendingTransferWithdrawView);
