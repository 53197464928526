import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import FormSearch from "../../../../UI/formSearch/FormSearch";
import addIcon from '../../../../assets/images/common/addIconLight.svg';
import Loader from "../../../../common/components/Loader";
import { addFieldStates } from "../../Cashier";
import clsx from "clsx";
import CustomModal from "../../../../common/components/CustomModal";
import { SCREEN_SIZES } from 'utils/consts';
import useWindowSize from 'hooks/useWindowSize';
import TooltipMUI from "../../../../UI/tooltip/TooltipMUI";
import { shortText } from "../../../../components/DynamicTable/utils";
import i18n from "i18next";

const CashierSideBar = (props) => {
    const {
        onMethodSelect,
        selectedMethod,
        addFieldState,
        isLoading,
        addNewCashier,
        deleteCashier,
        cashierList,
        canEditCashier,
    } = props;
    const { t } = useTranslation();
    const size = useWindowSize();
    const [searchValue, setSearchValue] = useState('');
    const [isShowConfModal, setIsShowConfModal] = useState(false);
    const [openedTooltipMethodId, setOpenedTooltipMethodId] = useState(null);
    const [methodsOpen, setMethodsOpen] = useState(false);

    const onChangeSearchValue = (e) => {
        const value = e?.target?.value;
        setSearchValue(value);
    }

    const filteredMethods = (cashierList || []).filter(method => {
        if (!method.name) {
            method.name = '';
        }
        return method.name.toLowerCase().includes(searchValue.toLowerCase())
    });

    const _onMethodSelect = (id) => {
        if (addFieldState !== addFieldStates.PENDING) {
            onMethodSelect(id);
        }
    }

    const handleShowMethods = () => {
        setMethodsOpen(!methodsOpen);
    }

    const toggleTooltip = (methodId) => {
        setOpenedTooltipMethodId(openedTooltipMethodId === methodId ? null : methodId);
    };

    const getTooltipTitle = (method) => {
        const countries = method.countriesAvailable?.map(countryCode =>
            i18n.t(countryCode)
        );

        return countries?.length
            ? `${t("crm.countriesAvailable")}: ${countries.join(', ')}`
            : `${t("crm.countriesAvailable")}: ${t("crm.allCountries")}`;
    };


    return (
        <div className="cashier-sidebar">
            <FormSearch
                onChangeSearchValue={onChangeSearchValue}
                showIconSearch
                className='search'
            />
            <hr className='mt-0'/>
            <div className="new-method">
                <button
                    className={clsx("add-new-method", {
                        "disabledBtn": addFieldState === addFieldStates.PENDING || !canEditCashier,
                    })}
                    onClick={addNewCashier}
                >
                    <img src={addIcon} alt="Add field"/>
                    {t('crm.addNewMethod')}
                </button>
            </div>
            <div className="methods">
                {
                    size.width <= SCREEN_SIZES.MAX_MOBILE_VIEW && (
                        <div
                            className={clsx("methods__mobile__header", {["methods__mobile__header__is_active"]: methodsOpen})}
                            onClick={handleShowMethods}
                        >
                            {t('crm.cashierMethodsToggler')}
                        </div>
                    )
                }
                <div className={clsx("methods__wrapper", {["methods__wrapper__is_open"]: methodsOpen})}>
                    {isLoading
                        ? (
                            <Loader size={'sm'} />
                        ) : (
                            filteredMethods.length !== 0 &&
                            filteredMethods.map((method) => (
                                <div
                                    key={method.id}
                                    className={clsx('method-field', {
                                        'selected': method.id === selectedMethod?.id,
                                        'disabled': addFieldState === addFieldStates.PENDING
                                    })}
                                >
                                    <div className={'d-flex w-100 align-items-center'}>
                                        <TooltipMUI
                                            wrapperPadding={'0'}
                                            title={getTooltipTitle(method)}
                                            open={openedTooltipMethodId === method.id}
                                            onClick={() => toggleTooltip(method.id)}
                                        />
                                        <div className='method-name' onClick={() => _onMethodSelect(method.id)}>
                                            {shortText(method.name)}
                                        </div>
                                        <button
                                            className={clsx('btn deleteRuleBtn', {
                                                'disabled': !canEditCashier || method.id !== selectedMethod?.id
                                            })}
                                            onClick={() => setIsShowConfModal(true)}
                                        />
                                    </div>
                                </div>
                            ))
                        )
                    }
                    {!filteredMethods.length
                        && !isLoading
                        && <span className='text-center'>{t('crm.cashierNoMethods')}</span>
                    }
                </div>
            </div>
            {isShowConfModal &&
                <CustomModal
                    titleText={'attention'}
                    isOpen={isShowConfModal}
                    onToggle={() => setIsShowConfModal(!isShowConfModal)}
                    onClick={() => {
                        deleteCashier(selectedMethod?.id);
                        setIsShowConfModal(false);
                    }}
                    onClickCancelButton={() => setIsShowConfModal(false)}
                    btnText={'yes'}
                    cancelBtnText={'no'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {t('promotions.applyChangesModal')}
                            </div>
                        </>
                    )}
                />
            }
        </div>
    );
};

export default memo(CashierSideBar);
