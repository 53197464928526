export const GET_AGENT_SELF = 'GET_AGENT_SELF';
export const GET_AGENT_SELF_SUCCESS = 'GET_AGENT_SELF_SUCCESS';
export const GET_AGENT_SELF_FAIL = 'GET_AGENT_SELF_FAIL';

export const SET_EDIT_AGENT_SELF = 'SET_EDIT_AGENT_SELF';

export const UPDATE_AGENT_SELF = 'UPDATE_AGENT_SELF';
export const UPDATE_AGENT_SELF_SUCCESS = 'UPDATE_AGENT_SELF_SUCCESS';
export const UPDATE_AGENT_SELF_FAIL = 'UPDATE_AGENT_SELF_FAIL';

export const EDIT_AGENT_PASSWORD = 'EDIT_AGENT_PASSWORD';
export const EDIT_AGENT_PASSWORD_SUCCESS = 'EDIT_AGENT_PASSWORD_SUCCESS';
export const EDIT_AGENT_PASSWORD_FAIL = 'EDIT_AGENT_PASSWORD_FAIL';

export const UPDATE_AGENT_SELF_STATUS = 'UPDATE_AGENT_SELF_STATUS';
export const UPDATE_AGENT_SELF_STATUS_SUCCESS =
    'UPDATE_AGENT_SELF_STATUS_SUCCESS';
export const UPDATE_AGENT_SELF_STATUS_FAIL = 'UPDATE_AGENT_SELF_STATUS_FAIL';

export const RESET_AGENT_SELF_ALERTS_ERRORS = 'RESET_AGENT_SELF_ALERTS_ERRORS';

export const DOWNLOAD_AGENT_SELF = 'DOWNLOAD_AGENT_SELF';
export const DOWNLOAD_AGENT_SELF_SUCCESS = 'DOWNLOAD_AGENT_SELF_SUCCESS';
export const DOWNLOAD_AGENT_SELF_FAIL = 'DOWNLOAD_AGENT_SELF_FAIL';
