import {
    GET_PLAYER_GENERAL,
    GET_PLAYER_GENERAL_SUCCESS,
    GET_PLAYER_GENERAL_FAIL,
    UPDATE_PLAYER_GENERAL,
    UPDATE_PLAYER_GENERAL_SUCCESS,
    UPDATE_PLAYER_GENERAL_FAIL,
    UPDATE_PLAYER_AFFILIATE_INFO,
    UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS,
    UPDATE_PLAYER_AFFILIATE_INFO_FAIL,
    GET_RETAIL_PLAYER,
    GET_RETAIL_PLAYER_SUCCESS,
    GET_RETAIL_PLAYER_FAIL,
    UPDATE_RETAIL_PLAYER,
    UPDATE_RETAIL_PLAYER_SUCCESS,
    UPDATE_RETAIL_PLAYER_FAIL,
    RESET_UPDATE_ERROR,
    CREATE_RETAIL_PLAYER,
    CREATE_RETAIL_PLAYER_SUCCESS,
    CREATE_RETAIL_PLAYER_FAIL,
    UPDATE_RETAIL_KYC_STATUS,
    UPDATE_RETAIL_KYC_STATUS_SUCCESS,
    UPDATE_RETAIL_KYC_STATUS_FAIL,
    PAYMENT_RETAIL,
    PAYMENT_RETAIL_SUCCESS,
    PAYMENT_RETAIL_FAIL,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_STATUS_SUCCESS,
    UPDATE_PLAYER_STATUS_FAIL,
    SET_EDIT_PLAYER_GENERAL,
    RESET_PLAYER_ERRORS,
    RESET_PLAYER_STATE,
    GET_PLAYER_PHONE,
    GET_PLAYER_PHONE_SUCCESS,
    GET_PLAYER_PHONE_FAIL,
    GET_PLAYER_EMAIL,
    GET_PLAYER_EMAIL_SUCCESS,
    GET_PLAYER_EMAIL_FAIL,
    UPDATE_PLAYER_NOTE_FAIL,
    UPDATE_PLAYER_NOTE,
    UPDATE_PLAYER_NOTE_SUCCESS,
    UPDATE_PLAYER_PHONE,
    UPDATE_PLAYER_PHONE_SUCCESS,
    UPDATE_PLAYER_PHONE_FAIL,
    UPDATE_PLAYER_EMAIL,
    UPDATE_PLAYER_EMAIL_SUCCESS,
    UPDATE_PLAYER_EMAIL_FAIL,
    CLEAR_PLAYER_GENERAL,
    CLEAR_RETAIL_PLAYER,
} from "./actionTypes"


export const getPlayerGeneral = payload => ({
    type: GET_PLAYER_GENERAL,
    payload
});

export const getPlayerGeneralSuccess = payload => ({
    type: GET_PLAYER_GENERAL_SUCCESS,
    payload
});

export const getPlayerGeneralFail = error => ({
    type: GET_PLAYER_GENERAL_FAIL,
    payload: error
});

export const setPlayerGeneralEdit = payload => ({
    type: SET_EDIT_PLAYER_GENERAL,
    payload
});

export const updatePlayerGeneral = payload => ({
    type: UPDATE_PLAYER_GENERAL,
    payload
});

export const updatePlayerGeneralSuccess = payload => ({
    type: UPDATE_PLAYER_GENERAL_SUCCESS,
    payload
});

export const updatePlayerGeneralFail = error => ({
    type: UPDATE_PLAYER_GENERAL_FAIL,
    payload: error
});

export const updatePlayerAffiliateInfo = payload => ({
    type: UPDATE_PLAYER_AFFILIATE_INFO,
    payload
});

export const updatePlayerAffiliateInfoSuccess = payload => ({
    type: UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS,
    payload
});

export const updatePlayerAffiliateInfoFail = error => ({
    type: UPDATE_PLAYER_AFFILIATE_INFO_FAIL,
    payload: error
});

export const getRetailPlayer = payload => ({
    type: GET_RETAIL_PLAYER,
    payload
});

export const getRetailPlayerSuccess = payload => ({
    type: GET_RETAIL_PLAYER_SUCCESS,
    payload
});

export const getRetailPlayerFail = error => ({
    type: GET_RETAIL_PLAYER_FAIL,
    payload: error
});

export const updateRetailPlayer = payload => ({
    type: UPDATE_RETAIL_PLAYER,
    payload
});

export const updateRetailPlayerSuccess = payload => ({
    type: UPDATE_RETAIL_PLAYER_SUCCESS,
    payload
});

export const updateRetailPlayerFail = error => ({
    type: UPDATE_RETAIL_PLAYER_FAIL,
    payload: error
});

export const resetUpdateError = () => ({
    type: RESET_UPDATE_ERROR,
})

export const createRetailPlayer = payload => ({
    type: CREATE_RETAIL_PLAYER,
    payload
});

export const createRetailPlayerSuccess = payload => ({
    type: CREATE_RETAIL_PLAYER_SUCCESS,
    payload
});

export const createRetailPlayerFail = error => ({
    type: CREATE_RETAIL_PLAYER_FAIL,
    payload: error
});

export const updateRetailKYCStatus = payload => ({
    type: UPDATE_RETAIL_KYC_STATUS,
    payload
});

export const updateRetailKYCStatusSuccess = payload => ({
    type: UPDATE_RETAIL_KYC_STATUS_SUCCESS,
    payload
});

export const updateRetailKYCStatusFail = error => ({
    type: UPDATE_RETAIL_KYC_STATUS_FAIL,
    payload: error
});

export const paymentRetail = payload => ({
    type: PAYMENT_RETAIL,
    payload
});

export const paymentRetailSuccess = payload => ({
    type: PAYMENT_RETAIL_SUCCESS,
    payload
});

export const paymentRetailFail = error => ({
    type: PAYMENT_RETAIL_FAIL,
    payload: error
});

export const updatePlayerPhone = payload => ({
    type: UPDATE_PLAYER_PHONE,
    payload
});

export const updatePlayerPhoneSuccess = payload => ({
    type: UPDATE_PLAYER_PHONE_SUCCESS,
    payload
});

export const updatePlayerPhoneFail = error => ({
    type: UPDATE_PLAYER_PHONE_FAIL,
    payload: error
});

export const updatePlayerEmail = payload => ({
    type: UPDATE_PLAYER_EMAIL,
    payload
});

export const updatePlayerEmailSuccess = payload => ({
    type: UPDATE_PLAYER_EMAIL_SUCCESS,
    payload
});

export const updatePlayerEmailFail = error => ({
    type: UPDATE_PLAYER_EMAIL_FAIL,
    payload: error
});

export const updatePlayerStatus = payload => ({
    type: UPDATE_PLAYER_STATUS,
    payload
});

export const updatePlayerStatusSuccess = payload => ({
    type: UPDATE_PLAYER_STATUS_SUCCESS,
    payload
});

export const updatePlayerStatusFail = error => ({
    type: UPDATE_PLAYER_STATUS_FAIL,
    payload: error
});

export const resetPlayerErrors = payload => ({
    type: RESET_PLAYER_ERRORS,
    payload
});

export const resetPlayerState = payload => ({
    type: RESET_PLAYER_STATE,
    payload
});

export const getPlayerPhone = payload => ({
    type: GET_PLAYER_PHONE,
    payload
});

export const getPlayerPhoneSuccess = payload => ({
    type: GET_PLAYER_PHONE_SUCCESS,
    payload
});

export const getPlayerPhoneFail = payload => ({
    type: GET_PLAYER_PHONE_FAIL,
    payload
});

export const getPlayerEmail = payload => ({
    type: GET_PLAYER_EMAIL,
    payload
});

export const getPlayerEmailSuccess = payload => ({
    type: GET_PLAYER_EMAIL_SUCCESS,
    payload
});

export const getPlayerEmailFail = payload => ({
    type: GET_PLAYER_EMAIL_FAIL,
    payload
});

export const updatePlayerNote = payload => ({
    type: UPDATE_PLAYER_NOTE,
    payload
});

export const updatePlayerNoteSuccess = payload => ({
    type: UPDATE_PLAYER_NOTE_SUCCESS,
    payload
});

export const updatePlayerNoteFail = error => ({
    type: UPDATE_PLAYER_NOTE_FAIL,
    payload: error
});

export const clearPlayerGeneral = () => ({
    type: CLEAR_PLAYER_GENERAL,
});

export const clearRetailPlayer = () => ({
    type: CLEAR_RETAIL_PLAYER,
});
