import { useTranslation } from "react-i18next";
import { useDispatch, useSelector} from "react-redux";
import React, { memo, useEffect, useMemo, useState } from "react";
import useProviders from "../../../../hooks/useProviders";
import { useTimeContext } from "../../../../context/TimeContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import { getAllGames } from "../../../../store/games/actions";
import { putPlayedUniqueGames } from "../../../../store/players/gamePlay/actions";
import { FILTER_CATEGORY, FILTER_MENU_GAME_ROUND } from "../../../../constants/Filter";
import TableFilter from "../../../../components/TableFilter";
import NewCustomTable from "../../../../components/NewCustomTable";
import clsx from "clsx";
import { SCREEN_SIZES } from "../../../../utils/consts";
import { getColumnSettingsLsKey, getGuidShortFormat, initTableColumns } from "../../../../common/utils/common";
import ClipboardButton from "../../../../common/cellData/CopyButton";
import Formatter from "../../../../utils/Formatter";
import Loader from "../../../../common/components/Loader";
import { ClipboardButtonCell, getBalance } from "../GamePlayTabContent";
import TableSettingsModal from "../../modal/TableSettingsModal";
import { useLocation } from "react-router-dom";
import { getLocalStorageFilter } from "../../../../store/filters/reducer";

const COLUMN_IDS = {
    sessionId: 'sessionId',
    roundID: 'roundID',
    externalID: 'ExternalID',
    startTime: 'startTime',
    endTime: 'endTime',
    gameName: 'gameName',
    gameID: 'gameID',
    gameProvider: 'gameProvider',
    gameVendor: 'gameVendor',
    openRealBalance: 'openRealBalance',
    closeRealBalance: 'closeRealBalance',
    openBonusBalance: 'openBonusBalance',
    closeBonusBalance: 'closeBonusBalance',
    totalBet: 'totalBet',
    totalWin: 'totalWin',
    cashBet: 'cashBet',
    cashWin: 'cashWin',
    totalJackpotWin: 'totalJackpotWin',
    cashBonusBet: 'cashBonusBet',
    bonusWin: 'bonusWin',
    betType: 'betType',
    result: 'result',
    status: 'status',
};

const columnsWithPermissions = [
    COLUMN_IDS.openRealBalance,
    COLUMN_IDS.closeRealBalance,
    COLUMN_IDS.openBonusBalance,
    COLUMN_IDS.closeBonusBalance
]

const getDefaultColumns = (canViewBetBalances) => {
    let defaultColumns = [
        COLUMN_IDS.sessionId,
        COLUMN_IDS.roundID,
        COLUMN_IDS.externalID,
        COLUMN_IDS.startTime,
        COLUMN_IDS.endTime,
        COLUMN_IDS.gameName,
        COLUMN_IDS.gameID,
        COLUMN_IDS.gameProvider,
        COLUMN_IDS.gameVendor,
        COLUMN_IDS.openRealBalance,
        COLUMN_IDS.closeRealBalance,
        COLUMN_IDS.openBonusBalance,
        COLUMN_IDS.closeBonusBalance,
        COLUMN_IDS.totalBet,
        COLUMN_IDS.totalWin,
        COLUMN_IDS.cashBet,
        COLUMN_IDS.cashWin,
        COLUMN_IDS.totalJackpotWin,
        COLUMN_IDS.cashBonusBet,
        COLUMN_IDS.bonusWin,
        COLUMN_IDS.betType,
        COLUMN_IDS.result,
        COLUMN_IDS.status,
    ];

    if (!canViewBetBalances) {
        defaultColumns = defaultColumns.filter(column =>
            !columnsWithPermissions.includes(column));
    }
    return defaultColumns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.sessionId]: 'crm.sessionId',
    [COLUMN_IDS.roundID]: 'crm.roundID',
    [COLUMN_IDS.externalID]: 'crm.ExternalID',
    [COLUMN_IDS.startTime]: 'crm.startTime',
    [COLUMN_IDS.endTime]: 'crm.endTime',
    [COLUMN_IDS.gameName]: 'crm.gameName',
    [COLUMN_IDS.gameID]: 'crm.gameID',
    [COLUMN_IDS.gameProvider]: 'crm.gameProvider',
    [COLUMN_IDS.gameVendor]: 'crm.gameVendor',
    [COLUMN_IDS.openRealBalance]: 'crm.openRealBalance',
    [COLUMN_IDS.closeRealBalance]: 'crm.closeRealBalance',
    [COLUMN_IDS.openBonusBalance]: 'crm.openBonusBalance',
    [COLUMN_IDS.closeBonusBalance]: 'crm.closeBonusBalance',
    [COLUMN_IDS.totalBet]: 'crm.totalBet',
    [COLUMN_IDS.totalWin]: 'crm.totalWin',
    [COLUMN_IDS.cashBet]: 'crm.cashBet',
    [COLUMN_IDS.cashWin]: 'crm.cashWin',
    [COLUMN_IDS.totalJackpotWin]: 'crm.totalJackpotWin',
    [COLUMN_IDS.cashBonusBet]: 'crm.cashBonusBet',
    [COLUMN_IDS.bonusWin]: 'crm.bonusWin',
    [COLUMN_IDS.betType]: 'crm.betType',
    [COLUMN_IDS.result]: 'crm.result',
    [COLUMN_IDS.status]: 'crm.status',
};

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                }) => {
    const size = useWindowSize();
    const { getProviderNameById } = useProviders();
    const { t } = useTranslation();

    switch (field) {
        case COLUMN_IDS.sessionId:
            return <>
                <ClipboardButtonCell text={item.session} />
            </>
        case COLUMN_IDS.roundID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.roundID')}: </span>
                )}
                <div className="d-flex">
                    <span className="me-2">{getGuidShortFormat(item?.id)}</span>
                    <ClipboardButton text={item?.id} />
                </div>
            </td>
        case COLUMN_IDS.externalID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.ExternalID')}: </span>
                )}
                <div>
                    {item?.externalRoundId ? (
                        <>
                            <span className="me-2">
                                {getGuidShortFormat(item?.externalRoundId)}
                            </span>
                            <ClipboardButton text={item?.externalRoundId}/>
                        </>
                    ) : ("-")
                    }
                </div>
            </td>
        case COLUMN_IDS.startTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.startTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.createdAt))}
            </td>
        case COLUMN_IDS.endTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.endTime')}: </span>
                )}
                {Formatter.displayDateTimeSeconds(timeFormat.parseTime(item.lastChangedAt))}
            </td>
        case COLUMN_IDS.gameName:
            return <td className='title__top'>
                {item.gameName}
            </td>
        case COLUMN_IDS.gameID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.gameID')}: </span>
                )}
                <div>
                     <span className="me-2">
                         {getGuidShortFormat(item.game)}
                     </span>
                    <ClipboardButton text={item.game}/>
                </div>
            </td>
        case COLUMN_IDS.gameProvider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.gameProvider')}: </span>
                )}
                {getProviderNameById(item.provider) || '—'}
            </td>
        case COLUMN_IDS.gameVendor:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.gameVendor')}: </span>
                )}
                {item.vendorName}
            </td>
        case COLUMN_IDS.openRealBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.openRealBalance')}: </span>
                )}
                {getBalance(item, 'open')}
            </td>
        case COLUMN_IDS.closeRealBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.closeRealBalance')}: </span>
                )}
                {getBalance(item, 'close')}
            </td>
        case COLUMN_IDS.openBonusBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.openBonusBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.openBonusBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.closeBonusBalance:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.closeBonusBalance')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.closeBonusBalance)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.totalBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.totalWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.totalWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.cashBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.cashBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalRealBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.cashWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.cashWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalRealWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.cashBonusBet:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.bonusBet')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalPromoBet)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.bonusWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.bonusWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalPromoWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.betType:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.betType')}: </span>
                )}
                {item.betType
                    ? t(`crm.${item.betType}`)
                    : '-'
                }
            </td>
        case COLUMN_IDS.totalJackpotWin:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.totalJackpotWin')}: </span>
                )}

                {`${Formatter.roundToDecimalValue(item.totalJackpotWin)} ${Formatter.getCurrencySign(item.currency)}`}
            </td>
        case COLUMN_IDS.result:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.result')}: </span>
                )}
                {t(`crm.${item.result}`)}
            </td>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{t('crm.status')}: </span>
                )}
                {t(`crm.status.${item.status.charAt(0) + item.status.slice(1).toLowerCase()}`)}
            </td>
        default:
            return null;
    }
};

function RoundsTable(props) {
    const {
        list,
        agentSelf,
        playerId,
        isShowTableSettings,
        toggleTableSettings,
        isLoadingGamePlay,
    } = props;
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [uniqueGames, setUniqueGames] = useState();
    const [prevPlayerId, setPrevPlayerId] = useState();
    const { timeFormat } = useTimeContext();
    const canViewBetBalances = agentSelf?.permissions?.includes('Player__View_Bets_Balance');
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns(canViewBetBalances));
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns(canViewBetBalances));
    const [isDynamicColumnAdded, setIsDynamicColumnAdded] = useState(false);
    const location = useLocation();
    const filterValues = getLocalStorageFilter();

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);

    const shouldAddDynamicColumn = (list) => {
        return list?.some(item => item.totalJackpotWin > 0);
    }

    useEffect(() => {
        if (!isDynamicColumnAdded && shouldAddDynamicColumn(list || [])) {
            setActiveColumns(prevColumns => [...prevColumns, COLUMN_IDS.totalJackpotWin]);
            setIsDynamicColumnAdded(true);
        }
    }, [list, isDynamicColumnAdded]);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])


    useEffect(() => {
        setActiveColumns(getDefaultColumns(canViewBetBalances));
    }, [canViewBetBalances]);

    const handleChangeColumns = (columnsSequence) => {
        toggleTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({ top: 0 });
    }, 200);
    const [activeRow, setActiveRow] = useState(false);

    const {
        uniqueGameIds, isLoadingUniqueGameIds
    } = useSelector(state => state.PlayerGamePlay);
    const {
        games, isLoadingGames
    } = useSelector(state => state.Games);

    useEffect(() => {
        if(list?.length && !games?.total) {
            dispatch(getAllGames());
        }
    }, [list, games]);

    useEffect(() => {
        if (playerId !== prevPlayerId) {
            setPrevPlayerId(playerId);
        }
    }, [playerId, prevPlayerId]);

    useEffect(() => {
        if (isLoadingGames || isLoadingUniqueGameIds) {
            return;
        }
        if (!games?.total || !uniqueGameIds?.length) {
            setUniqueGames([]);
            return;
        }
        const gamesKeys = new Set();
        const _games = []
        games.result.forEach(({ friendlyName, id}) => {
            if (!gamesKeys.has(id) && uniqueGameIds.some(({game_id}) => game_id === id)) {
                gamesKeys.add(id);
                _games.push({
                    key: id,
                    label: friendlyName
                })
            }})
        setUniqueGames(_games);
        dispatch(putPlayedUniqueGames(_games));
    }, [uniqueGameIds, games, isLoadingGames, isLoadingUniqueGameIds]);


    const filterData = useMemo(() => {
        return FILTER_MENU_GAME_ROUND(t, uniqueGames)
    }, [t, uniqueGames])

    if (list?.length === 0 && !isLoadingGamePlay && !filterValues.length) {
        return <div className='noGamePlayData'>
            {i18n.t('tabs.NoGameRounds')}
        </div>
    }

    const handleToggle = (index, activeRow) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            {(list?.length > 0 || filterValues?.length > 0) && (
                <div className="page-title-table player-view-tab mb-3">
                    <TableFilter
                        filterListOptions={filterData}
                        isDisabled={false}
                        category={FILTER_CATEGORY.GAME_PLAY}
                    />
                </div>
            )}

            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {uniqueGames ? (
                  list?.map((item, index) => {
                      const isDelayedMoreThanTwoMinutes = (new Date(item.lastChangedAt) - new Date(item.createdAt)) > 2 * 60 * 1000;

                      return (
                        <tr
                          key={item.id}
                          className={clsx("text-center", {open: activeRow === index, delayedRound: isDelayedMoreThanTwoMinutes})}
                          onClick={() => handleToggle(index, activeRow)}
                        >
                            {activeColumns.map((column) => (
                              <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                              />
                            ))}
                        </tr>
                      );
                  })
                ) : (
                  <Loader size={'lg'}/>
                )}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={toggleTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    );
}

export default memo(RoundsTable);
