import React, { memo } from "react";
import PlayerReportIframe from "./components/PlayerReportIframe";
import MetaTags from "react-meta-tags";
import { SITE_PERMISSION } from "../../common/constants/common";
import PermissionWrapper from "../../components/PermissionWrapper";
import './styles.scss';

const PlayerReport = () => {
    return (
        <div className='page-content player-report'>
            <MetaTags>
                <title>Player report - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>
            <PermissionWrapper
                accessPermissions={[SITE_PERMISSION.Player__Report__View]}
                withPermissionError={true}
            >
                <PlayerReportIframe />
            </PermissionWrapper>
        </div>
    )
}

export default memo(PlayerReport);