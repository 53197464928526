const hasPositionChanged = ({ pos, prevPos }) =>
    pos !== prevPos;

const valueInRange = ({ minScale, maxScale, scale }) =>
    scale <= maxScale && scale >= minScale;

const getTranslate = ({ minScale, maxScale, scale }) =>
    ({ pos, prevPos, translate }) =>
        valueInRange({ minScale, maxScale, scale }) &&
        hasPositionChanged({ pos, prevPos })
            ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
            : translate;

const getScale = ({ scale, minScale, maxScale, scaleSensitivity, deltaScale }) => {
    let newScale = scale + (deltaScale / (scaleSensitivity / scale));
    newScale = Math.max(minScale, Math.min(newScale, maxScale));
    return [scale, newScale];
};

const getMatrix = ({ scale, translateX, translateY, rotateDeg = 0 }) =>
    `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})  rotate(${rotateDeg}deg)`;

const pan = ({ state, originX, originY }) => {
    state.transformation.translateX += originX;
    state.transformation.translateY += originY;
    state.element.style.transform = getMatrix({
        scale: state.transformation.scale,
        translateX: state.transformation.translateX,
        translateY: state.transformation.translateY,
        rotateDeg: state.rotateDeg,
    });
};

const makePan = (state) => ({
    panBy: ({ originX, originY }) =>
        pan({ state, originX, originY }),
    panTo: ({ originX, originY, scale }) => {
        state.transformation.scale = scale;
        pan({
            state,
            originX: originX - state.transformation.translateX,
            originY: originY - state.transformation.translateY,
        });
    },
});

const makeZoom = (state) => ({
    zoom: ({ x, y, deltaScale }) => {
        const { left, top } = state.element.getBoundingClientRect();
        const { minScale, maxScale, scaleSensitivity } = state;
        const [ scale, newScale ] = getScale({
            scale: state.transformation.scale,
            deltaScale,
            minScale,
            maxScale,
            scaleSensitivity,
        });
        const originX = x - left;
        const originY = y - top;
        const newOriginX = originX / scale;
        const newOriginY = originY / scale;
        const translate = getTranslate({
            scale,
            minScale,
            maxScale,
        });
        const translateX = translate({
            pos: originX,
            prevPos: state.transformation.originX,
            translate: state.transformation.translateX,
        });
        const translateY = translate({
            pos: originY,
            prevPos: state.transformation.originY,
            translate: state.transformation.translateY,
        });

        // state.element.style.transformOrigin = `${newOriginX}px ${newOriginY}px`;
        state.element.style.transform = getMatrix({
            scale: newScale,
            translateX,
            translateY,
            rotateDeg: state.rotateDeg,
        });
        state.transformation = {
            originX: newOriginX,
            originY: newOriginY,
            translateX,
            translateY,
            scale: newScale,
        };
    }
});

const ZoomPan = ({ minScale, maxScale, element, scaleSensitivity = 10, rotateDeg = 0 }) => {
    const state = {
        element,
        minScale,
        maxScale,
        scaleSensitivity,
        transformation: {
            originX: 0,
            originY: 0,
            translateX: 0,
            translateY: 0,
            scale: 1,
        },
        rotateDeg,
    };
    return Object.assign({}, makeZoom(state), makePan(state));
};

export default ZoomPan;