import { useTimeContext } from "../../../context/TimeContext";
import React, { memo, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import {Link, useLocation, withRouter} from "react-router-dom";
import {
    getColumnSettingsLsKey,
    getGuidShortFormat, initTableColumns,
} from "../../../common/utils/common";
import ClipboardButton from "../../../common/cellData/CopyButton";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import i18n from "../../../i18n";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";
import useUserConfig from "../../../hooks/useUserConfig";
import NewCustomTable from "../../../components/NewCustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PaymentStatusView from "../../../components/Status/PaymentStatusView";
import TableFilter from "../../../components/TableFilter";
import {
    FILTER_CATEGORY,
    FILTER_MENU_PLAYER_WITHDRAWALS_LIST
} from "../../../constants/Filter";
import download from "../../../assets/images/common/download.svg";
import { downloadPlayerWithdrawalList } from "../../../store/players/deposits/actions";
import { FINANCE_TYPES } from "./common/playerViewConstants";
import settings from "../../../assets/images/layout/settings.svg";
import {PAYMENT_STATUS} from "../../../constants/status";
import moment from "moment/moment";

const COLUMN_IDS = {
    id: 'id',
    time: 'time',
    lastUpdated: 'lastUpdated',
    resolutionTime: 'resolutionTime',
    provider: 'provider',
    method: 'method',
    status: 'status',
    withdrawalsAmount: 'withdrawalsAmount',
    feeAmount: 'feeAmount',
    totalAmount: 'totalAmount',
};

const getDefaultColumns = () => {
    return [
        COLUMN_IDS.id,
        COLUMN_IDS.time,
        COLUMN_IDS.lastUpdated,
        COLUMN_IDS.resolutionTime,
        COLUMN_IDS.provider,
        COLUMN_IDS.method,
        COLUMN_IDS.status,
        COLUMN_IDS.withdrawalsAmount,
        COLUMN_IDS.feeAmount,
        COLUMN_IDS.totalAmount,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.id]: 'crm.ID',
    [COLUMN_IDS.time]: 'crm.time',
    [COLUMN_IDS.lastUpdated]: 'crm.lastUpdated',
    [COLUMN_IDS.resolutionTime]: 'crm.resolutionTime',
    [COLUMN_IDS.provider]: 'crm.provider',
    [COLUMN_IDS.method]: 'crm.method',
    [COLUMN_IDS.status]: 'crm.status',
    [COLUMN_IDS.withdrawalsAmount]: 'crm.withdrawalsAmount',
    [COLUMN_IDS.feeAmount]: 'crm.feeAmount',
    [COLUMN_IDS.totalAmount]: 'crm.totalAmount',
};

const TdElem = ({
                    item,
                    field,
                    timeFormat,
                }) => {
    const size = useWindowSize();
    const { adjustAmountWithUserCurrency } = useUserConfig();

    let timeDuration = '-';
    if ([PAYMENT_STATUS.COMPLETED, PAYMENT_STATUS.DECLINED].includes(item.status)) {
        const duration = moment.duration(moment(item.updatedAt).diff(item.createdAt));
        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();
        const onlySeconds = duration.asSeconds();
        const seconds = onlySeconds < 1 ? onlySeconds : duration.seconds();

        timeDuration = `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''} `
            + `${minutes > 0 ? `${minutes}m` : ''} ${duration.asHours() < 1 && seconds > 0 ? `${seconds}s` : ''}`;
        timeDuration = timeDuration.trim();
    }

    switch (field) {
        case COLUMN_IDS.id:
            return <td>
                <div>
                    {item.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL &&
                        <Link to={`/withdrawals-pending-approve/${item.id}`}>
                            {getGuidShortFormat(item.id)}
                        </Link>
                    }
                    {item.status === PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER &&
                        <Link to={`/withdrawals-pending-transfer/${item.id}`}>
                            {getGuidShortFormat(item.id)}
                        </Link>
                    }
                    {item.status !== PAYMENT_STATUS.PENDING_FOR_APPROVAL &&
                        item.status !== PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER &&
                        <Link to={`/withdrawals/${item.id}`}>
                            {getGuidShortFormat(item.id)}
                        </Link>
                    }
                    <ClipboardButton text={item.id}/>
                </div>
            </td>
        case COLUMN_IDS.time:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.time')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>
        case COLUMN_IDS.lastUpdated:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.lastUpdated')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.updatedAt))}
            </td>
        case COLUMN_IDS.resolutionTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.resolutionTime')}: </span>
                )}
                {timeDuration}
            </td>
        case COLUMN_IDS.provider:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.provider')}: </span>
                )}
                {item?.provider?.name || '—'}
            </td>
        case COLUMN_IDS.method:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.method')}: </span>
                )}
                {item.method || '—'}
            </td>
        case COLUMN_IDS.status:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.status')}: </span>
                )}
                <PaymentStatusView status={item.status} />
            </td>
        case COLUMN_IDS.withdrawalsAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.withdrawalsAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.amount)}
            </td>
        case COLUMN_IDS.feeAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.feeAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.taxAmount)}
            </td>
        case COLUMN_IDS.totalAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('crm.totalAmount')}: </span>
                )}

                {adjustAmountWithUserCurrency(item.totalAmount)}
            </td>
        default:
            return null;
    }
};

const WithdrawalsTabTable = (props) => {
    const {
        playerId,
        list,
        currentFilter,
        isLoading,
        brandCurrencies,
    } = props;
    const location = useLocation();
    const { totalAmounts } = useSelector(
        state => state.PlayerWithdrawals
    );
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {timeFormat} = useTimeContext();
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());
    const [activeRow, setActiveRow] = useState(false);
    const [isShowTableSettings, setIsShowTableSettings] = useState(false);
    const toggleTableSettings = () => setIsShowTableSettings(prev => !prev);
    const onCloseTableSettings = () => setIsShowTableSettings(false);

    useEffect(() => {
        initTableColumns(`/${location.search}`, getDefaultColumns, setActiveColumns);
    }, [location.search]);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(`/${location.search}`);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    }

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({ top: 0 });
    }, 200);

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    const handleDownload = useCallback(() => {
        const requestQuery = {};

        if (currentFilter) {
            requestQuery.filterDynamic = currentFilter;
        }
        requestQuery.timezoneOffsetInMinutes = new Date().getTimezoneOffset();

        dispatch(downloadPlayerWithdrawalList({ id: playerId, query: { ...requestQuery } }))

    }, [currentFilter]);

    const renderTotals = useCallback(() => {
        const filteredAmounts = {};
        Object.keys(totalAmounts).forEach((status) => {
            if (Object.keys(totalAmounts[status]).length > 0) {
                filteredAmounts[status] = totalAmounts[status];
            }
        })

        const existedStatuses = Object.keys(filteredAmounts);
        let result;

        if (existedStatuses.length === 0) {
            result = 0;
        }
        else {
            return existedStatuses.map((status, statusInd) => {
                const totalEntriesArr = Object.entries(filteredAmounts[status]);
                return <React.Fragment key={statusInd}>
                    {i18n.t(`crm.total${FINANCE_TYPES[status]}`)}:&nbsp;&nbsp;
                    {totalEntriesArr.map((item,index)  => {
                        const [currency, amount] = item;
                        return (
                            <React.Fragment key={index}>
                                {Formatter.formatAmount(amount, currency)}
                            </React.Fragment>
                        )
                    })}&nbsp;&nbsp;
                </React.Fragment>
            })
        }
    }, [totalAmounts]);

    if (list?.length === 0 && !isLoading && !currentFilter.length) {
        return <div className='noGamePlayData'>
            {i18n.t('crm.noWithdrawals')}
        </div>
    }

    return (
        <>
            <div className="page-title-table player-view-tab">
                <TableFilter
                    filterListOptions={FILTER_MENU_PLAYER_WITHDRAWALS_LIST(t, brandCurrencies)}
                    isDisabled={isLoading}
                    category={FILTER_CATEGORY.PLAYER_WITHDRAWS}
                />

                <div className={clsx('d-flex justify-content-end mt-1 ms-3 download-button', {
                    ['d-none']: !list?.length
                })}>
                    <button
                        className="btn settings-button settings-withdraw"
                        onClick={toggleTableSettings}
                    >
                        <img src={settings} alt="Table's Settings"/>
                    </button>
                    <button
                        className="btn btn-rounded btn-primary app-btn-only-img-sm"
                        onClick={handleDownload}
                    >
                        <img src={download} alt=""/>
                    </button>
                </div>
            </div>
            <div className='pb-2'>
                {totalAmounts && Object.keys(totalAmounts).length !== 0 &&
                    <div className='totalAmountWithdrawals'>
                        <span className='total-span'>
                            {renderTotals()}
                        </span>
                    </div>
                }
            </div>

            <NewCustomTable
                columns={columns}
                className={'mb-1 responsiveTable'}
            >
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                            />
                        ))}
                    </tr>
                ))}
            </NewCustomTable>

            {isShowTableSettings && (
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            )}
        </>
    )
}

export default memo(withRouter(WithdrawalsTabTable));