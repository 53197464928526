import PropTypes from 'prop-types';
import React from 'react';
import {
    LineChart,
    YAxis,
    XAxis,
    ResponsiveContainer,
    Tooltip,
    Line,
    CartesianGrid
} from 'recharts';
import ChartTooltip from './ChartTooltip';

const AppLineChart = ({ chartData, stroke }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<ChartTooltip />} />
                <Line
                    type="monotone"
                    dataKey="value"
                    stroke={stroke || '#3DCCB8'}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

AppLineChart.propTypes = {
    chartData: PropTypes.array.isRequired
};

export default React.memo(AppLineChart);
