export const GET_AGENTS_LIST = 'GET_AGENTS_LIST';
export const GET_AGENTS_LIST_SUCCESS = 'GET_AGENTS_LIST_SUCCESS';
export const GET_AGENTS_LIST_FAIL = 'GET_AGENTS_LIST_FAIL';

export const GET_ROLES_LIST = 'GET_ROLES_LIST';
export const GET_ROLES_LIST_SUCCESS = 'GET_ROLES_LIST_SUCCESS';
export const GET_ROLES_LIST_FAIL = 'GET_ROLES_LIST_FAIL';

export const CREATE_AGENT = 'CREATE_AGENT';
export const CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS';
export const CREATE_AGENT_FAIL = 'CREATE_AGENT_FAIL';

export const CHANGE_AGENT_PASSWORD = 'CHANGE_AGENT_PASSWORD';
export const CHANGE_AGENT_PASSWORD_SUCCESS = 'CHANGE_AGENT_PASSWORD_SUCCESS';
export const CHANGE_AGENT_PASSWORD_FAIL = 'CHANGE_AGENT_PASSWORD_FAIL';

export const RESET_AGENTS_ERROR = 'RESET_AGENTS_ERROR';
