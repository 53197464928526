import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import "./style.scss";


const PageTitle = ({ title, className = "", children }) => (
  <Row className={"page-title__wrapper"}>
    <Col className={"d-flex align-items-center justify-content-start left-pos"}>
      <h4 className={`page-title ${className}`}>{ title }</h4>
    </Col>
    { children &&
      <Col className={"d-flex align-items-center justify-content-end right-pos"}>
        {children}
      </Col>
    }
  </Row>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.element,
};

export default React.memo(PageTitle);
