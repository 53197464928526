import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import arrowLeft from '../../assets/images/layout/arrow-left.svg';
import arrowRight from '../../assets/images/layout/arrow-right.svg';

import '../../assets/scss/components/customPagination.scss';

const CustomPagination = props => {
    const {activePage, onChange, pageCount} = props;
    const [inputValue, setInputValue] = useState(1);
    const [_pageCount, _setPageCount] = useState(1);
    const _lastPage = pageCount;

    const onChangeInput = (value) => {
        if (value < 1 || value === '') {
            setInputValue(1);
            onChange(1);
        } else if (value > _pageCount) {
            setInputValue(+_pageCount);
            onChange(_pageCount);
        } else {
            setInputValue(+value);
            onChange(value);
        }
    };

    useEffect(() => {
        if (_pageCount !== pageCount) {
            _setPageCount(pageCount)
        }
        setInputValue(+activePage)
    }, [pageCount]);

    useEffect(() => {
        if (inputValue < 1) {
            setInputValue(1)
        }
    }, [inputValue])

    const handleBlurElement = (e) => {
        const { target } = e;
        target.blur();
        target.parentElement.blur();
    };

    const onPaginationClick = (e, pageNumber) => {
        handleBlurElement(e);
        onChangeInput(pageNumber);
    }

    const firstPage = (e) => {
        if (activePage !== 1 && inputValue !== 1) {
            onPaginationClick(e, 1);
        }
    };

    const lastPage = (e) => {
        if (activePage !== _lastPage && inputValue !== _pageCount) {
            onPaginationClick(e, _pageCount);
        }
    };

    const isDisabled = props.isDisabled;

    return (
        <>
        {_pageCount > 1 &&
            <Pagination className="custom-pagination">
                <PaginationItem disabled={isDisabled}>
                    <PaginationLink className={activePage === 1 || inputValue === 1 ? "pagination-link first-page"
                        : "pagination-link" }
                                    onClick={firstPage}>
                        <img
                            className="img-btn"
                            src={arrowLeft}
                            alt=""
                            width="24"
                        />
                    </PaginationLink>
                </PaginationItem>

                {+activePage > 2 && (
                    <PaginationItem disabled={isDisabled}>
                        <PaginationLink
                            className="pagination-link"
                            onClick={(e) =>
                                onPaginationClick(e, activePage - 2)
                            }
                        >
                            {activePage - 2}
                        </PaginationLink>
                    </PaginationItem>
                )}
                {+activePage > 1 && (
                    <PaginationItem disabled={isDisabled}>
                        <PaginationLink
                            className="pagination-link"
                            onClick={(e) =>
                                onPaginationClick(e, activePage - 1)
                            }
                        >
                            {activePage - 1}
                        </PaginationLink>
                    </PaginationItem>
                )}
                <PaginationItem active disabled={isDisabled}>
                    <PaginationLink className="pagination-link active-page">
                        {activePage}
                    </PaginationLink>
                </PaginationItem>
                {+activePage + 1 <= _pageCount && (
                    <PaginationItem disabled={isDisabled}>
                        <PaginationLink
                            className="pagination-link"
                            onClick={(e) =>
                                onPaginationClick(e, +activePage + 1)
                            }
                        >
                            {+activePage + 1}
                        </PaginationLink>
                    </PaginationItem>
                )}
                {+activePage + 2 <= _pageCount && (
                    <PaginationItem disabled={isDisabled}>
                        <PaginationLink
                            className="pagination-link"
                            onClick={(e) =>
                                onPaginationClick(e, +activePage + 2)
                            }
                        >
                            {+activePage + 2}
                        </PaginationLink>
                    </PaginationItem>
                )}
                <PaginationItem disabled={isDisabled}>
                    <PaginationLink
                        className={activePage === _lastPage || inputValue === _lastPage
                        ? "pagination-link last-page"
                        : "pagination-link"}
                        onClick={lastPage}>
                        <img
                            className="img-btn"
                            src={arrowRight}
                            alt=""
                            width="24"
                        />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <input
                        onChange={(e) => onChangeInput(e.target.value)}
                        value={inputValue}
                        className='pagination-input ml-2'
                        type="number"
                    />
                </PaginationItem>
            </Pagination>
        }
        </>

    );
};

CustomPagination.propTypes = {
    pageCount: PropTypes.number,
    activePage: PropTypes.number,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool
};

CustomPagination.defaultProps = {
    pageCount: 1,
    activePage: 1,
    onChange: () => {}
};

export default memo(CustomPagination);
