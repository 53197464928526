const now = () => new Date().getTime();
export const isLoggedIn = () => (localStorage.getItem("authUser"));

export const isSessionExpired = () => isLoggedIn() && checkSessionLifetime();

export const checkSessionLifetime = () => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const nowInSeconds = Math.floor(Date.now() / 1000);
    const tokenExpirationDateMinus2Minutes = user.expiresIn.tokenExpirationDate - 120; // 2 mins before expiration
    return nowInSeconds > tokenExpirationDateMinus2Minutes;
}

export const isRememberUser = () => localStorage.getItem("rememberMe") === "true";
