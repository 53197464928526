import React from 'react';
import settingIcon from "../../assets/images/common/Frame 10950.svg";
import {Link} from "react-router-dom";

import "./styles.scss";
import clsx from "clsx";

const ButtonOpenPlayerView = ({
          playerOid,
          label,
          classNameWrapper = '',
          openInNewTab = false,
          showImage = true,
}) => {
    return showImage ?
        <div className={clsx("wrapperButton", classNameWrapper)}>
            <Link
                to={`/player-list/${playerOid}`}
                target={openInNewTab ? '_blank' : '_self' }
            >
                <button
                    className="btn btn-rounded btn-outline-secondary player-view-btn"
                >
                    <img src={settingIcon} alt="setting icon" />
                    {label}
                </button>
            </Link>
        </div> :
        <Link
            to={`/player-list/${playerOid}`}
            target={openInNewTab ? '_blank' : '_self' }
            className={'label'}
        >
            {label}
        </Link>
};

export default ButtonOpenPlayerView;
