import {
    GET_PENDING_WITHDRAWAL,
    GET_PENDING_WITHDRAWAL_SUCCESS,
    GET_PENDING_WITHDRAWAL_FAIL,
    APPROVE_PENDING_WITHDRAWAL,
    APPROVE_PENDING_WITHDRAWAL_SUCCESS,
    APPROVE_PENDING_WITHDRAWAL_FAIL,
    DECLINE_PENDING_WITHDRAWAL,
    DECLINE_PENDING_WITHDRAWAL_SUCCESS,
    DECLINE_PENDING_WITHDRAWAL_FAIL,
    RESET_PENDING_WITHDRAWAL_ALERTS_ERRORS
} from './actionTypes';

export const getPendingWithdrawal = payload => ({
    type: GET_PENDING_WITHDRAWAL,
    payload
});

export const getPendingWithdrawalSuccess = payload => ({
    type: GET_PENDING_WITHDRAWAL_SUCCESS,
    payload
});

export const getPendingWithdrawalFail = error => ({
    type: GET_PENDING_WITHDRAWAL_FAIL,
    payload: error
});

export const approvePendingWithdrawal = payload => ({
    type: APPROVE_PENDING_WITHDRAWAL,
    payload
});

export const approvePendingWithdrawalSuccess = payload => ({
    type: APPROVE_PENDING_WITHDRAWAL_SUCCESS,
    payload
});

export const approvePendingWithdrawalFail = error => ({
    type: APPROVE_PENDING_WITHDRAWAL_FAIL,
    payload: error
});

export const declinePendingWithdrawal = payload => ({
    type: DECLINE_PENDING_WITHDRAWAL,
    payload
});

export const declinePendingWithdrawalSuccess = payload => ({
    type: DECLINE_PENDING_WITHDRAWAL_SUCCESS,
    payload
});

export const declinePendingWithdrawalFail = error => ({
    type: DECLINE_PENDING_WITHDRAWAL_FAIL,
    payload: error
});

export const resetPendingWithdrawalAlertsErrors = error => ({
    type: RESET_PENDING_WITHDRAWAL_ALERTS_ERRORS,
    payload: error
});
