import { isValidPhoneNumber } from 'libphonenumber-js'

export const SCREEN_SIZES = {
    MOBILE_SMALL_VIEW: 320,
    MOBILE_VIEW: 414,
    MOBILE_MIDDLE_VIEW: 480,
    MAX_MOBILE_VIEW: 600,
    TABLET_VIEW: 700,
    TABLET_PORTRAIT_MIDDLE_VIEW: 847,
    MAX_TABLET_PORTRAIT: 992,
    TABLET_MIDDLE_VIEW: 1024,
    TABLET_LARGE_VIEW: 1156,
    MAX_TABLET_LANDSCAPE: 1200,
    LAPTOP_VIEW: 1280,
    DESKTOP_VIEW: 1366,
    BIG_DESKTOP_VIEW: 1600,
};

export function checkPhoneNumber(phoneNumber) {
    return isValidPhoneNumber(phoneNumber);
}
