import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import './onfidoReports.scss';
import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import i18n from '../../../../i18n';
import InputWrapper from '../../../../common/wrappers/InputWrapper';
import useAlertService from '../../../../hooks/useAlertService';
import { getSync, postSync } from '../../../../helpers/api_helper';
import Loader from '../../../../common/components/Loader';
import activeBranded from '../../../../assets/images/common/activeBranded.svg';
import inactiveBranded from '../../../../assets/images/common/inactiveBranded.svg';
import moment from 'moment';
import {cleanUpJson, DATE_TIME_FORMAT} from '../../../../common/utils/common';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function OnfidoReports({ onClose, queues = [], playerId, playerName }) {
    const alertService = useAlertService();
    const [results, setResults] = React.useState([]);
    const [currentResult, setCurrentResult] = React.useState(0);
    const [currentBreakdown, setCurrentBreakdown] = React.useState(undefined);
    const notFound = 'Not found';

    const parseDateValue = value => {
        if (!value) {
            return notFound;
        }
        return moment(new Date(value)).format(DATE_TIME_FORMAT);
    };

    const getPlayerOnfidoResults = async () => {
        const url = `/onfido/report/${playerId}`;
        try {
            const result = await getSync(url, {});
            setResults(result.data.results);
            if (!result.data.results || result.data.results.length === 0) {
                alertService.showError('No reports history for this player');
                onClose();
            }
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError('Cannot complete, Contact support');
        }
    };

    useEffect(async () => {
        await getPlayerOnfidoResults();
    }, []);

    const handleChangeCurrentPage = tabId => {
        setCurrentResult(tabId);
    };

    const generateView = _data => {
        const data = _data[0];
        const verify = (
            <img src={activeBranded} alt="" width={15} height={15} />
        );
        const notVerify = (
            <img src={inactiveBranded} alt="" width={15} height={15} />
        );
        const breakdownProps = [
            ['Age Validation', 'ageValidation'],
            ['Data Comparison', 'dataComparison'],
            ['Data Validation', 'dataValidation'],
            ['Image Integrity', 'imageIntegrity'],
            ['Data Consistency', 'dataConsistency'],
            ['Visual Authenticity', 'visualAuthenticity'],
            ['Compromised Document', 'compromisedDocument']
        ];

        const playerProps = [
            ['Gender', 'gender'],
            ['First Name', 'firstName'],
            ['Last Name', 'lastName'],
            ['Date Of Birth', 'dateOfBirth'],
            ['Nationality', 'nationality'],
            ['Date Of Expiry', 'dateOfExpiry'],
            ['Document Type', 'documentType'],
            ['Issuing Country', 'issuingCountry']
        ];
        //currentBreakdown, setCurrentBreakdown

        const changeCurrentBreakdown = value => () => {
            setCurrentBreakdown(value);
        };

        const checkClear = value => {
            if (value === 'clear') {
                return verify;
            }
            return notVerify;
        };
        return (
            <>
                {currentBreakdown && (
                    <Modal
                        style={{ minWidth: '50vh' }}
                        isOpen={true}
                        role="dialog"
                        autoFocus={true}
                        data-toggle="modal"
                        className="app-status-modal"
                        centered
                        onOpened={setUnScrollBody}
                        onExit={removeUnScrollBody}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title text-uppercase">
                                Breakdown
                            </h5>
                            <button
                                className="close"
                                onClick={() => setCurrentBreakdown(undefined)}
                            />
                        </div>

                        <pre className="provider-response-container">
                            {JSON.stringify(
                                cleanUpJson(currentBreakdown),
                                null,
                                2
                            )}
                        </pre>
                    </Modal>
                )}
                <hr className="mt-6" />
                <h3 className="m-0" style={{ padding: '15px' }}>
                    General
                </h3>
                <span className="content-row__value text-wrap">
                    <Row>
                        <Col>
                            <h6>{`Report time`}</h6>{' '}
                            <span>{parseDateValue(data?.createdAt)}</span>
                        </Col>
                        <Col>
                            <h6>{`Result`}</h6> <span>{data?.result}</span>
                        </Col>
                        <Col>
                            <h6>{`Report reference`}</h6>{' '}
                            <span>{data?.href}</span>
                        </Col>
                        <Col>
                            <h6>{`Report status`}</h6>{' '}
                            <span>{data?.status}</span>
                        </Col>
                    </Row>
                    <hr
                        style={{
                            width: '100%',
                            borderTop: '1px dashed #898996',
                            background: 'none'
                        }}
                    />
                    <h3 className="m-0" style={{ padding: '15px' }}>
                        Breakdown
                    </h3>
                    <Row>
                        {breakdownProps.map(array => (
                            <>
                                <Col>
                                    <h6>{array[0]}</h6>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <span>
                                            {checkClear(
                                                data?.breakdown[array[1]]
                                                    ?.result
                                            )}
                                        </span>
                                        <span
                                            style={{
                                                fontWight: '10px',
                                                fontWeight: 600,
                                                cursor: 'pointer'
                                            }}
                                            onClick={changeCurrentBreakdown(
                                                data?.breakdown[array[1]]
                                                    ?.breakdown
                                            )}
                                        >
                                            {data?.breakdown[array[1]]
                                                ?.breakdown && 'More info'}
                                        </span>
                                    </div>
                                </Col>
                            </>
                        ))}
                    </Row>
                    <hr
                        style={{
                            width: '100%',
                            borderTop: '1px dashed #898996',
                            background: 'none'
                        }}
                    />
                    <h3 className="m-0" style={{ padding: '15px' }}>
                        Properties
                    </h3>
                    <Row>
                        {playerProps.map(array => (
                            <Col>
                                <h6>{array[0]}</h6>
                                <span>
                                    {data?.properties[array[1]] || notFound}
                                </span>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col>
                            <h5>{'Document Numbers'}</h5>
                            {data?.properties.documentNumbers.map(doc => (
                                <>
                                    <Row>
                                        <Col
                                            className={'top-0 end-0'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <h6
                                                style={{ marginRight: '10px' }}
                                            >{`Type`}</h6>{' '}
                                            <span>{doc.type}</span>
                                        </Col>
                                        <Col
                                            className={'top-0 end-0'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <h6
                                                style={{ marginRight: '10px' }}
                                            >{`Value`}</h6>{' '}
                                            <span>{doc.value}</span>
                                        </Col>
                                    </Row>
                                </>
                            ))}
                        </Col>
                    </Row>
                </span>
            </>
        );
    };

    return (
        <Modal
            style={{ minWidth: '100vh' }}
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="app-status-modal"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.onfidoReportTitle')}
                </h5>
                <button className="close" onClick={onClose} />
            </div>

            <ModalBody>
                <InputWrapper className="d-block">
                    <Row>
                        {results.length === 0 && <Loader />}
                        {results.length > 0 &&
                            results.map((item, index) => (
                                <Col
                                    className="base-tabs"
                                    style={{
                                        padding: '15px',
                                        ...(currentResult === index && {
                                            background: '#8b8b98',
                                            color: 'white'
                                        })
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: '20px',
                                            paddingRight: '20px'
                                        }}
                                        className={
                                            currentResult === index
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleChangeCurrentPage(index)
                                        }
                                    >
                                        <span>
                                            {parseDateValue(
                                                item.result[0].createdAt
                                            )}
                                        </span>
                                    </div>
                                </Col>
                            ))}
                    </Row>
                    <Row>
                        {results.length === 0 && <Loader />}
                        {results.length > 0 &&
                            generateView(results[currentResult].result)}
                    </Row>
                </InputWrapper>
            </ModalBody>
        </Modal>
    );
}

OnfidoReports.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default React.memo(OnfidoReports);
