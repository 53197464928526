import React from 'react';
import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ children }) => (
  <div className={styles.error}>
    {children}
  </div>
);

export default ErrorMessage;
