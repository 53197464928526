import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import header_icon from '../../../assets/images/common/user (1).svg';
import saveFile from '../../../assets/images/common/saveFile.svg';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import TextInput from '../../../common/inputs/TextInput';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import { Col, Row } from 'reactstrap';
import { postSync } from '../../../helpers/api_helper';
import { useTranslation } from "react-i18next";
import useAlertService from '../../../hooks/useAlertService';

function PersonalInfoSubBlock(props) {
    const {
        player,
        onChange,
        className = '',
        canEdit,
    } = props;
    const [playerPass, setPlayerPass] = useState('');
    const alertService = useAlertService();
    const { t, i18n } = useTranslation();

    const handleChange = (event) => {
        setPlayerPass(event);
    };

    const changePassword = async () => {
        const url = `/players/change-password`;
        try {
            const data = {
                playerId: player.id,
                password: playerPass
            };
            await postSync(url, data);
            alertService.showSuccess(i18n.t("crm.passwordChanged"));
        } catch (e) {
            alertService.showError(i18n.t("crm.cannot.passwordChanged"));
        }
        setPlayerPass('');
    };

    const fields = useMemo(
        () => [
            {
                component: 'text',
                stateKey: 'firstName',
                disabled: !canEdit,
            },
            {
                Component: TextInput,
                className: 'form-control form-control-sm',
                stateKey: 'middleName',
                disabled: !canEdit,
            },
            {
                Component: TextInput,
                className: 'form-control form-control-sm',
                stateKey: 'lastName',
                disabled: !canEdit,
            },
            {
                component: 'select-countries',
                className: 'form-control form-control-sm',
                stateKey: 'placeOfBirth',
                disabled: !canEdit,
            },
            {
                component: 'date',
                stateKey: 'birthDate',
                disabled: !canEdit,
            },
            {
                component: 'select-gender',
                stateKey: 'gender',
                disabled: !canEdit,
            },
            {
                component: 'text',
                label: 'username',
                stateKey: 'nickName',
                disabled: !canEdit,
            },
            {
                component: 'select-profession',
                stateKey: 'occupation',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_CURRENCIES,
                stateKey: 'currency',
                disable: true,
                disabled: !canEdit,
            },
            {
                component: 'select-countries',
                stateKey: 'citizenship',
                label: 'registrationCountry',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_MULTI_COUNTRIES,
                stateKey: 'otherCitizenship',
                disabled: !canEdit,
            },
            {
                ready: true,
                html: <div className='change-pass-personal-info'>
                    <ACommonDataField
                        component={A_COMMON_DATA_FIELD.TEXT}
                        label={t(`crm.change.password`)}
                        value={playerPass}
                        handleChange={handleChange}
                        disabled={!canEdit}
                    />
                    {!!playerPass.length &&
                        <img
                            src={saveFile}
                            onClick={changePassword}
                            alt='Save password'
                        />
                    }
                </div>

            },
            {
                component: A_COMMON_DATA_FIELD.CHECK_BOX,
                stateKey: 'isTest',
                disabled: !canEdit,
            },
            {
                component: A_COMMON_DATA_FIELD.CHECK_BOX,
                stateKey: 'allowPromotions',
                disabled: !canEdit,
            },
        ],
        [player, playerPass, i18n.language]
    );

    return (
        <div className={`sub-content ${className}`}>
            <div className='sub-content__header d-flex align-items-center'>
                <img className='me-3' src={header_icon} alt='' />
                <span>{t('crm.personalInfo')}</span>
            </div>
            <Row className={'filter-player_type-row personal-info__row'}>
                {fields.map((item, index) => {
                    let currentValue = player[item.stateKey];
                    if (item.label === 'birthDate') {
                        const birthDateLocal = new Date(player?.birthDate);
                        currentValue = new Date(birthDateLocal.getTime() + (birthDateLocal.getTimezoneOffset() * 60 * 1000)).toISOString()
                    }
                    return (
                        <Col key={index}
                             className={item.customClass}
                        >
                            {item.ready && item.html}
                            {!item.ready &&
                            <ACommonDataField
                                component={item.component}
                                label={t(`crm.${item.label || item.stateKey}`)}
                                value={currentValue || item.defaultValue || ''}
                                handleChange={onChange(item.stateKey)}
                                datePickerComponentType='MobileDatePicker'
                                hasClear={item?.hasClear}
                                disabled={item.disabled}
                                isBirthDate={item.stateKey === 'birthDate'}
                                allowFutureDate={item.stateKey === 'birthDate'}
                            />
                            }
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
}

PersonalInfoSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default React.memo(PersonalInfoSubBlock);
