import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import ButtonsPanel from '../../components/Buttons/ButtonsPanel';
import useAlertService from '../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetAgentEmployeeAlertsErrors,
    setEditAgentEmployee,
    updateAgentEmployee
} from '../../store/actions';
import { prepareFieldErrors, prepareUpdateAgentDto } from './model/agentModel';
import AgentMainCardHeader from './common/AgentMainCardHeader';
import AgentContactInfoBlock from './common/AgentContactInfoBlock';
import AgentGeneralInfoBlock from './common/AgentGeneralInfoBlock';

function AgentEmployeeMainCard() {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { isLoading, error, employee, isEdit, successAlert } = useSelector(
        state => state.AgentEmployee
    );

    const [agentModel, setAgentModel] = useState(
        JSON.parse(JSON.stringify(employee))
    );
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        setAgentModel(JSON.parse(JSON.stringify(employee)));
        handleSetEdit(false);
    }, [employee]);

    useEffect(() => {
        if (error && error.statusCode === 400) {
            setFieldErrors(prepareFieldErrors(error?.data));
        }
    }, [error]);

    useEffect(() => {
        if (successAlert) {
            alertService.showSuccess(successAlert);
            dispatch(resetAgentEmployeeAlertsErrors());
        }
    }, [successAlert]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setEditAgentEmployee(nextIsEdit));
        setFieldErrors({});
    };

    const handleChange = useCallback(
        updatedModel => {
            setAgentModel(updatedModel);
            handleSetEdit(true);
        },
        [agentModel]
    );

    const handleSave = useCallback(() => {
        const dto = prepareUpdateAgentDto(employee, agentModel);

        dispatch(updateAgentEmployee({ id: employee.id, dto }));
    }, [agentModel, employee]);

    const handleCancel = useCallback(() => {
        setAgentModel(JSON.parse(JSON.stringify(employee)));
        handleSetEdit(false);
    }, [employee]);

    return (
        <>
            <Card>
                <AgentMainCardHeader agent={employee} />

                <CardBody className="main-card">
                    <AgentContactInfoBlock
                        data={agentModel}
                        fieldErrors={fieldErrors}
                        isEdit={isEdit}
                        onChange={handleChange}
                    />

                    <div className="vertical-separator" />

                    <AgentGeneralInfoBlock
                        data={agentModel}
                        fieldErrors={fieldErrors}
                        isEdit={isEdit}
                        onChange={handleChange}
                    />
                </CardBody>
            </Card>

            <ButtonsPanel
                onSave={handleSave}
                onCancel={handleCancel}
                isDisabled={isLoading}
                isEdit={isEdit}
            />
        </>
    );
}

export default React.memo(AgentEmployeeMainCard);
