import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../../../i18n';

import useAlertService from '../../../../hooks/useAlertService';

import { getSync, patchSync, postSync } from '../../../../helpers/api_helper';

import { getNotesList, removeNote } from '../../../../store/note/actions';
import { getAgentsList } from '../../../../store/agents/agents/actions';

import NotesSidebar from './NotesSidebar';
import InputWrapper from '../../../../common/wrappers/InputWrapper';

import { LIMITS } from '../../../../constants/notes';

import CurrentNoteComponent from './CurrentNote';
import ButtonsPanel from '../../../../../src/components/Buttons/ButtonsPanel';

import './notesPopup.scss';
import close from "../../../../assets/images/common/close 1.svg";
import i_51 from "../../../../assets/images/common/Frame 176 (1).svg";
import ConfirmationModal from "../../../../common/prompt/ConfirmationModal";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function NotesPopup({ onClose, playerOid, showNoteId, afterCall }) {
    const dispatch = useDispatch();
    const { noteList = [], query, isRemoving, errors } = useSelector(state => state.Notes);
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const { playerGeneral } = useSelector(state => state.PlayerGeneral);

    const [notes, setNotes] = useState([]);
    const [currencyTab, setCurrentTab] = useState();
    const [currentNote, setCurrentNote] = useState();
    const [allowEdit, setAllowEdit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [subject, setSubject] = useState('');
    const [savedResult, setSavedResult] = useState();
    const [assigneeAgentOid, setAssigneeAgentOid] = useState();
    const [content, setContent] = useState('');
    const [tag, setTag] = useState('');
    const [tabIndex, setTabIndex] = useState();
    const [item, setItem] = useState();
    const [isShowConfDialog, setShowConfDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const prevNote = useRef(null);

    useEffect(() => {
        if (!isRemoving) {
            setCurrentNote();
            prevNote.current = null;

            // Will implemented after server side
            if (errors) {
                // ...
            }
            else {
                // ...
            }
        }
    }, [isRemoving, errors]);

    const getNote = async item => {
        const url = `/note/${item.oid}`;

        try {
            const result = await getSync(url, {});
            setSavedResult(result);
            setSubject(result.data.subject);
            setAssigneeAgentOid(result.data.assigneeAgentOid);
            setContent(result.data.content);
            setTag(result.data.tag);
            setCurrentNote(result.data);
            if (!result?.data) {
                alertService.showError(i18n.t('crm.cannotFetchNote'));
                onClose();
            }
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError(i18n.t('crm.contactSupport'));
        }
    };

    const clearNoteData = () => {
        setTabIndex();
        setItem();
    };

    const getCurrentNote = (index, item) => {
        setCurrentTab(index);
        getNote(item);
        clearNoteData();
    };

    const handleChangeCurrentPage = useCallback((index, item) => {
        if (item === -1) {
            return;
        }

        if (isEdit) {
            setTabIndex(index);
            setItem(item);
            setShowConfDialog(true);
        }
        else {
            getCurrentNote(index, item);
        }
    }, [isEdit]);

    const handleEdit = () => {
        setIsEdit(true);
    }

    const handleCancel = () => {
        setSubject(currentNote.subject);
        setAssigneeAgentOid(currentNote.assigneeAgentOid);
        setContent(currentNote.content);
        setTag(currentNote.tag);
        setIsEdit(false);
    };

    const handleChangeTag = (value) => {
        setCurrentNote({
            ...currentNote,
            tag: value,
        });
        setTag(value);
    }

    const handleSave = async () => {
        setIsLoading(true)
        let result;
        try {
            if (subject.trim().length < LIMITS.minSubject) {
                alertService.showError(
                    i18n.t('crm.minSubjectsAlert', {
                        minSubject: LIMITS.minSubject
                    })
                );
                return;
            }
            if (content.trim().length < LIMITS.minContent) {
                alertService.showError(
                    i18n.t('crm.minContentAlert', {
                        minContent: LIMITS.minContent
                    })
                );
                return;
            }

            let url;
            if (!currentNote.newNote) {
                url = `/note/${currentNote.oid}`;
                result = await patchSync(alertService, url, {
                    subject: subject.trim(),
                    content: content.trim(),
                    tag,
                    assigneeAgentOid: assigneeAgentOid
                });
            } else {
                url = `/note`;
                currentNote.subject = subject.trim();
                currentNote.content = content.trim();
                currentNote.assigneeAgentOid = assigneeAgentOid;
                currentNote.playerOid = Number(playerOid);
                currentNote.oid = Number(playerOid);
                currentNote.tag = tag;
                if (afterCall) {
                    currentNote.relatedCallOid = parseInt(
                        currentNote.relatedCallOid
                    );
                }
                result = await postSync(url, currentNote);
                if (result.success) {
                    setIsLoading(false)
                }
            }
            if (!result?.success) {
                alertService.showError(i18n.t('crm.updateFail'));
                onClose();
            } else {
                alertService.showSuccess(i18n.t('crm.successfullySaved'));
                setIsEdit(false);
                if (afterCall) {
                    onClose();
                    return;
                }
            }
        } catch (e) {
            if (e.message) {
                alertService.showError(e.message);
                return;
            }
            alertService.showError(i18n.t('crm.contactSupport'));
        }
        getNote({
            oid: result?.noteOid || currentNote.oid
        });
        dispatch(getNotesList({ ...query, playerOid }));
    };

    const alertService = useAlertService();

    const addNewNote = callId => {
        if (typeof callId === 'object') {
            callId = undefined;
        }
        setSubject('');
        setContent('');
        setTag('');
        setAssigneeAgentOid(undefined);

        const currentNote = {
            content: '',
            relatedCallOid: callId?.toString(),
            createdAgentOid: agentSelf?.oid,
            playerOid: parseInt(playerOid?.toString()),
            subject: '',
            newNote: true,
            tag: '',
        };
        setCurrentTab();
        setCurrentNote(currentNote);
        prevNote.current = currentNote;
    };

    const handleAddNewNote = useCallback(() => {
        if (isEdit) {
            setTabIndex();
            setItem();
            setShowConfDialog(true);
        }
        else {
            addNewNote();
        }
    }, [isEdit]);

    useEffect(() => {
        if (!subject.length && !content.length) {
            setIsEdit(false);
        }
    }, [subject, content]);

    useEffect(() => {
        if (afterCall && afterCall?.length > 0) {
            addNewNote(afterCall[0]);
        } else {
            dispatch(getNotesList({ ...query, playerOid }));
            if (showNoteId) {
                getNote({ oid: showNoteId });
            }
        }

        return dispatch(getAgentsList());
    }, []);

    useEffect(() => {
        if (!noteList) {
            return;
        }
        let _notes = [...noteList];

        setNotes(_notes);
    }, [noteList]);

    useEffect(() => {
        setAllowEdit(currentNote?.createdAgentOid?.toString() === agentSelf?.oid ||
            currentNote?.assigneeAgentOid?.toString() === agentSelf?.oid);

        if (prevNote.current?.oid !== currentNote?.oid) {
            setIsEdit(false);
        }
        prevNote.current = currentNote;
    }, [currentNote]);

    const handleCloseDialog = () => {
        setShowConfDialog(false);
        clearNoteData();
    };

    const handleChangeCurrentNote = useCallback(() => {
        setShowConfDialog(false);

        if (item) {
            getCurrentNote(tabIndex, item);
        }
        else {
            addNewNote();
        }
    }, [tabIndex, item]);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className={`app-status-modal notes-modal${
                afterCall ? ' notes-modal__after-call' : ''
            }`}
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <button className="close close-notes-modal" onClick={onClose} />
            <ModalBody
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                }}
                className="notes-modal__outer"
            >
                {!afterCall && (
                    <NotesSidebar
                        notes={notes}
                        agentSelf={agentSelf}
                        currentNote={currentNote}
                        addNewNote={handleAddNewNote}
                        currencyTab={currencyTab}
                        playerOid={playerOid}
                        handleChangeCurrentPage={handleChangeCurrentPage}
                    />
                )}

                <div className={'notes-content-wrapper'}>
                    <div className={'notes-content-header modal-header'}>
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                width: '100%',
                                borderBottom: '1px solid #dfdfed'
                            }}
                        >
                            <h2
                                style={{
                                    borderBottom: 0,
                                }}
                            >
                                {playerGeneral?.firstName}
                                &nbsp;
                                {playerGeneral?.lastName}
                                &nbsp;(oid: {playerGeneral?.oid})
                                {i18n.t('crm.notes.title')}
                            </h2>

                            {currentNote?.oid && allowEdit && (
                                <div
                                    className="d-flex justify-content-end"
                                    style={{
                                        width: 450,
                                    }}
                                >
                                    <button
                                        className="btn btn-rounded btn-light me-2 pt-2 pb-2"
                                        style={{
                                            height: 35,
                                        }}
                                        onClick={handleEdit}
                                    >
                                        {i18n.t('admin.edit')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <InputWrapper
                        id={'summaryPopupContent'}
                        className="notes-content-notes-wrapper"
                    >
                        {!currentNote && (
                            <div className="notes-content-notes__no-content">
                                {noteList?.length === 0 ? (
                                    <span className="me-4">
                                        {i18n.t('crm.noPlayerNotes')}
                                    </span>
                                ) : (
                                    <span className="me-4">
                                        {i18n.t('crm.selectNote')}
                                    </span>
                                )}
                            </div>
                        )}
                        {!!currentNote && (
                            <CurrentNoteComponent
                                isEdit={isEdit}
                                content={content}
                                subject={subject}
                                allowEdit={allowEdit}
                                setIsEdit={setIsEdit}
                                setSubject={setSubject}
                                setContent={setContent}
                                currentNote={currentNote}
                                assigneeAgentOid={assigneeAgentOid}
                                setAssigneeAgentOid={setAssigneeAgentOid}
                                setTag={handleChangeTag}
                            />
                        )}

                    </InputWrapper>
                    {isEdit &&
                        <div className='notes-btns'>
                            <div className="notes-btns-wrapper">
                                <button
                                    className="btn btn-rounded btn-light me-2"
                                    onClick={handleCancel}
                                >
                                    <img className="me-2" src={close} alt="" />
                                    {i18n.t('admin.cancel')}
                                </button>

                                <button
                                    className="btn btn-rounded btn-primary"
                                    onClick={handleSave}
                                >
                                    <img src={i_51} alt="" />
                                    {i18n.t('admin.save')}
                                </button>
                            </div>
                        </div>
                    }
                </div>

                {isShowConfDialog &&
                    <ConfirmationModal
                        text={i18n.t('admin.you.are.editing')}
                        onCancel={handleCloseDialog}
                        onConfirm={handleChangeCurrentNote}
                    />
                }
            </ModalBody>
        </Modal>
    );
}

NotesPopup.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default React.memo(NotesPopup);
