import PropTypes from 'prop-types';
import React from 'react';

const ChartTooltip = props => {
    const { active, payload } = props;

    const tooltipStyle = {
        padding: '0px 12px',
        background: '#F9F9FD',
        borderRadius: '30px',
        fontSize: '12px',
        color: '#495057'
    };

    if (active) {
        return (
            <div
                className="custom-tooltip text-overflow text-nowrap"
                style={tooltipStyle}
            >
                {payload[0]?.payload?.payload?.tooltipLabel ? (
                    payload[0]?.payload?.payload?.tooltipLabel
                ) : (
                    <>
                        {payload[0].name}:&nbsp;{payload[0].value}
                    </>
                )}
            </div>
        );
    }
    return null;
};

ChartTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array
};

export default React.memo(ChartTooltip);
