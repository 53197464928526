import React from 'react';
import PropTypes from 'prop-types';

const InfoBlockHeader = ({ title, srcIcon }) => {
    return (
        <div className="info-block-header">
            <img src={srcIcon} alt="" />
            <span>{title}</span>
        </div>
    );
};

InfoBlockHeader.propTypes = {
    title: PropTypes.string.isRequired,
    srcIcon: PropTypes.any.isRequired
};

export default React.memo(InfoBlockHeader);
