import {
    GET_DEPOSIT_ACTION_LIST,
    GET_DEPOSIT_ACTION_LIST_SUCCESS,
    GET_DEPOSIT_ACTION_LIST_FAIL,
    GET_DEPOSIT_LIST,
    GET_DEPOSIT_LIST_SUCCESS,
    GET_DEPOSIT_LIST_FAIL,
    RESET_DEPOSIT_LIST_ALERTS_ERRORS,
    DOWNLOAD_DEPOSIT_LIST,
    DOWNLOAD_DEPOSIT_LIST_SUCCESS,
    DOWNLOAD_DEPOSIT_LIST_FAIL,
    GET_CARD_NETWORKS,
    GET_CARD_NETWORKS_SUCCESS,
    GET_CARD_NETWORKS_FAIL,
    GET_PAYMENT_PROVIDERS,
    GET_PAYMENT_PROVIDERS_SUCCESS,
    GET_PAYMENT_PROVIDERS_FAIL,
} from './actionTypes';

export const getDepositActionList = payload => ({
    type: GET_DEPOSIT_ACTION_LIST,
    payload
});

export const getDepositActionListSuccess = payload => ({
    type: GET_DEPOSIT_ACTION_LIST_SUCCESS,
    payload
});

export const getDepositActionListFail = payload => ({
    type: GET_DEPOSIT_ACTION_LIST_FAIL,
    payload
});

export const getDepositList = payload => ({
    type: GET_DEPOSIT_LIST,
    payload
});

export const getDepositListSuccess = payload => ({
    type: GET_DEPOSIT_LIST_SUCCESS,
    payload
});

export const getDepositListFail = payload => ({
    type: GET_DEPOSIT_LIST_FAIL,
    payload
});

export const resetDepositListAlertsErrors = payload => ({
    type: RESET_DEPOSIT_LIST_ALERTS_ERRORS,
    payload
});

export const downloadDepositList = payload => ({
    type: DOWNLOAD_DEPOSIT_LIST,
    payload
});

export const downloadDepositListSuccess = payload => ({
    type: DOWNLOAD_DEPOSIT_LIST_SUCCESS,
    payload
});

export const downloadDepositListFail = payload => ({
    type: DOWNLOAD_DEPOSIT_LIST_FAIL,
    payload
});

export const getCardNetworks = payload => ({
    type: GET_CARD_NETWORKS,
    payload
});

export const getCardNetworksSuccess = payload => ({
    type: GET_CARD_NETWORKS_SUCCESS,
    payload
});

export const getCardNetworksFail = payload => ({
    type: GET_CARD_NETWORKS_FAIL,
    payload
});

export const getPaymentProviders = payload => ({
    type: GET_PAYMENT_PROVIDERS,
    payload
});

export const getPaymentProvidersSuccess = payload => ({
    type: GET_PAYMENT_PROVIDERS_SUCCESS,
    payload
});

export const getPaymentProvidersFail = payload => ({
    type: GET_PAYMENT_PROVIDERS_FAIL,
    payload
});
