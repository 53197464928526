const PLAYER_ACTION_TYPE = {
    BET: 'BET',
    WIN: 'WIN',
    LOST: 'LOST',
    NOT_LOGGED_IN: 'NOT_LOGGED_IN',
    REGISTER: 'REGISTER',
    KYC_VERIFIED: 'KYC_VERIFIED',
    KYC_NOT_VERIFIED: 'KYC_NOT_VERIFIED',
    PHONE_VERIFIED: 'PHONE_VERIFIED',
    PHONE_NOT_VERIFIED: 'PHONE_NOT_VERIFIED',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
    SFO_VERIFIED: 'SFO_VERIFIED',
    SFO_NOT_VERIFIED: 'SFO_NOT_VERIFIED',
    WITHDRAW: 'WITHDRAW',
    DEPOSIT: 'DEPOSIT',
    WITHDRAW_APPROVED: 'WITHDRAW_APPROVED',
    WITHDRAW_DECLINED: 'WITHDRAW_DECLINED',
    DEPOSIT_APPROVED: 'DEPOSIT_APPROVED',
    DEPOSIT_DECLINED: 'DEPOSIT_DECLINED'
};

const AMOUNT_CONDITION_TYPE = {
    MORE_THAN: 'MORE_THAN',
    LESS_THAN: 'LESS_THAN',
    EQUALS: 'EQUALS'
};

const TIME_TYPE = {
    MINUTES: 'MINUTES',
    DAYS: 'DAYS',
    WEEKS: 'WEEKS',
    YEARS: 'YEARS'
};

const PAYMENT_RANGE_TYPE = {
    MORE_THAN: 'MORE_THAN',
    LESS_THAN: 'LESS_THAN',
    EXACTLY: 'EXACTLY'
};

export {
    PLAYER_ACTION_TYPE,
    AMOUNT_CONDITION_TYPE,
    TIME_TYPE,
    PAYMENT_RANGE_TYPE
};
