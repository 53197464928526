import {
    SEARCH_PLAYER_LIST,
    SEARCH_AFFILIATE_LIST,
    SEARCH_DEPOSIT_LIST,
    SEARCH_WITHDRAWAL_LIST,
    SEARCH_LIST_SUCCESS,
    SEARCH_LIST_FAIL,
    RESET_SEARCH_LIST_ERRORS
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    searchResultList: null,
    total: 0,
    query: DEFAULT_QUERY
};

const Search = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_PLAYER_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null,
                searchResultList: null
            };

        case SEARCH_AFFILIATE_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null,
                searchResultList: null
            };

        case SEARCH_DEPOSIT_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null,
                searchResultList: null
            };

        case SEARCH_WITHDRAWAL_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                error: null,
                searchResultList: null
            };

        case SEARCH_LIST_SUCCESS:
            return {
                ...state,
                searchResultList: action.payload.result,
                total: action.payload.total,
                isLoading: false
            };

        case SEARCH_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                searchResultList: [],
                isLoading: false
            };

        case RESET_SEARCH_LIST_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default Search;
