import { useTranslation } from "react-i18next"
import React, { useEffect } from "react"
import ReferralTable from "./ReferralTable/ReferralTable"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { getPlayerReferralsList } from "../../../store/players/referrals/actions"
import Loader from "../../../common/components/Loader"
import group from "../../../assets/images/common/Tooltip.svg"

const PlayerReferral = ({ playerOid }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlayerReferralsList(playerOid));
  }, [])

  const { referralsList, loading } = useSelector(state => state.PlayerReferral);

  return (
    loading ? (
      <Loader size='sm' />
    ) : (
      <>
        <MetaTags>
          <title>Back-office | {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>
        <div className="d-flex align-items-center referral_title">
          <img className="me-3" src={group} alt="" />
          <span className="font-w-500 info__title">{t('crm.referralLabel')}</span>
        </div>
        <ReferralTable list={referralsList} />
      </>
    )
  );
}

export default PlayerReferral;