import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import './chat-box.scss';
import GenericPopup from '../genericPopup/generic-popup';
import { getSync, postAttachedSync, postSync } from '../../helpers/api_helper';
import Loader from '../../common/components/Loader';
import ACommonDataField from '../GenericComponents/CommonDataField';
import i18n from '../../i18n';
import useAlertService from '../../hooks/useAlertService';
import { useDispatch } from 'react-redux';
import {DATE_TIME_FORMAT} from "../../common/utils/common";

const ChatFull = ({ onClose, playerGeneral, messageId, newMessage }) => {
    const alertService = useAlertService();
    const dispatch = useDispatch();

    const [sendingMessage, setSendingMessage] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [newBody, setNewBody] = useState('');
    const [newSubject, setNewSubject] = useState('');
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [attachmentPopup, setAttachmentPopup] = useState(false);
    const [attachmentList, setAttachmentList] = useState(false);
    const [attachmentListFull, setAttachmentListFull] = useState(false);
    const attachmentSize = {
        width: 200,
        height: 200
    };

    const loadEmail = async () => {
        const url = `/chat/message/${playerGeneral.oid}/${messageId}`;
        try {
            const result = await getSync(url, {});
            const email = result?.data || {};
            if (email) {
                setMessage(email);
            }
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
    };

    const loadAttachment = async (attachmentId) => {
        const url = `/chat/attachment/${playerGeneral.oid}/${messageId}/${attachmentId}`;
        try {
            const result = await getSync(url, {});
            return result?.data || {};
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
        return undefined;
    };

    useEffect(() => {
        if (!newMessage) {
            loadEmail();
            return;
        }
        setMessage({
            from: '',
            attachments: [],
            subject: '',
            body: ''
        });
    }, []);

    const downloadBase64File = (contentBase64, type, fileName) => () => {
        const linkSource = `data:${type};base64,${contentBase64}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = fileName;
        downloadLink.click();
    }


    useEffect(async () => {
        if (!message) {
            return;
        }
        const list = [];
        const fullScreenList = [];
        let index = 0;
        for (const attachment of message.attachments) {
            index++;
            const base64 = await loadAttachment(attachment);
            if (base64) {
                const imageSrc = `data:${base64.contentType};base64, ${base64.base64FileContents}`;

                if (base64.contentType.includes('image')) {
                    fullScreenList.push(
                        <img alt={`Attachment ${index}`} style={{ width: '100%', height: '100%' }}
                             src={imageSrc} />
                    );
                    list.push(
                        <img alt={`Attachment ${index}`} width={attachmentSize.width} height={attachmentSize.height}
                             src={imageSrc} />
                    );
                } else {
                    const downBtn = <button
                        className='btn btn-rounded btn-primary'
                        type='button'
                        onClick={downloadBase64File(base64.base64FileContents, base64.contentType, 'NAME')}>Download</button>;
                    fullScreenList.push(downBtn);
                    list.push(downBtn);
                }
            }
        }
        setAttachmentListFull(fullScreenList);
        setAttachmentList(list);

    }, [message]);

    const showFullImage = (index) => () => {
        setSelectedImage(index);
        setAttachmentPopup(true);
    };

    const handleSubmit = async (value) => {
        value.preventDefault();
        setSendingMessage(true);
        const newMessage = {};
        const list = value.target;
        const length = value.target.length;
        for (let i = 0; i < length; i++) {
            const obj = list[i];
            switch (obj.id) {
                case 'subject':
                    newMessage.subject = obj.value;
                    break;
                case 'body':
                    newMessage.body = obj.value;
                    break;
                case 'files':
                    const urlAttached = `/chat/attachment`;
                    newMessage.attachmentIds = [];
                    for (const file of obj.files) {
                        try {
                            const id = await postAttachedSync(urlAttached, file);
                            if (id) {
                                newMessage.attachmentIds.push(id);
                            }
                        } catch (e) {
                            setSendingMessage(false);
                            alertService.showError('Cannot upload file');
                            console.error('Cannot upload file', file);
                            return;
                        }
                    }
                    break;
            }
        }
        const url = `/chat/send-message?playerOid=${playerGeneral.oid}`;
        try {
            await postSync(url, newMessage);
            setSendingMessage(false);
            onClose(true);
            alertService.showSuccess('Sent');
        } catch (e) {
            setSendingMessage(false);
            console.error('Cannot send message!');
        }
        setSendingMessage(false);
        return undefined;
    };

    return (
        <React.Fragment>
            {!message && <Loader />}
            {message && (
                <form onSubmit={handleSubmit}>
                    <Col style={{ height: '100%' }}>
                        <Card style={{ height: '100%' }}>
                            <Row>
                                {!newMessage &&
                                    <Col>
                                        <h5 className='font-size-15 mb-1'>
                                            {`${moment(message.createdAt).format(DATE_TIME_FORMAT)} | ${message.subject}`}
                                        </h5>

                                        <p className='text-muted mb-0'>
                                            {/*<i className="mdi mdi-circle text-success align-middle me-1" />{' '}*/}
                                            <b>From: </b>{message.from}
                                        </p>
                                        <p className='text-muted mb-0'>
                                            <b>To: </b>{message?.to.join(',')}
                                        </p>
                                        <p className='text-muted mb-0'>
                                            <b>CC: </b>{message?.cc.join(',')}
                                        </p>
                                        <p className='text-muted mb-0'>
                                            <b>BCC: </b>{message?.bcc.join(',')}
                                        </p>
                                    </Col>
                                }
                                {newMessage &&
                                    <Col md='4' xs='9'>
                                        <h5 className='font-size-15 mb-1'>
                                            <ACommonDataField
                                                id={'subject'}
                                                label={`${i18n.t('chat.subject')}:`}
                                                value={newSubject}
                                                fulWidth={true}
                                                handleChange={setNewSubject}
                                            />
                                        </h5>

                                        {/*
                                        <p className='text-muted mb-0'>
                                            <ACommonDataField
                                                label={`${i18n.t('chat.from')}:`}
                                                value={message?.from}
                                                fulWidth={true}
                                                handleChange={handleChange('from')}
                                            />
                                        </p>
                                    */}
                                    </Col>
                                }
                                <Col>
                                    <ul className='list-inline user-chat-nav text-end mb-0'>
                                        <li className='list-inline-item d-none d-sm-inline-block'>
                                            <div
                                                className={
                                                    'notes-content-header modal-header'
                                                }
                                                style={{ paddingLeft: '30px' }}
                                            >
                                                <button
                                                    className='close'
                                                    onClick={onClose}
                                                />
                                            </div>
                                        </li>
                                        {' '}
                                    </ul>
                                </Col>
                            </Row>
                            <hr />

                            <div style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginTop: '10px'
                            }}>
                                <div className='chat-conversation' style={(newMessage ? { width: '100%' } : {})}>
                                    <PerfectScrollbar
                                        style={{
                                            display: 'flex',
                                            overflow: 'scroll',
                                            marginBottom: '1rem',
                                            flexDirection: 'column',
                                            alignItems: 'stretch',
                                            justifyContent: 'flex-start',
                                            maxHeight: attachmentList.length > 0 ? '40vh' : '66vh'
                                        }}
                                    >
                                        <ul className='list-unstyled'>
                                            {
                                                !newMessage && (message.isHTML ?
                                                    <div dangerouslySetInnerHTML={{ __html: message.body }} /> :
                                                    message.body)
                                            }
                                            {
                                                newMessage &&
                                                <ACommonDataField
                                                    id={'body'}
                                                    label={`${i18n.t('chat.body')}:`}
                                                    value={newBody}
                                                    rows={20}
                                                    multiline={true}
                                                    fulWidth={true}
                                                    handleChange={setNewBody}
                                                />
                                            }
                                        </ul>

                                    </PerfectScrollbar>
                                </div>
                                <ul style={{ width: '100%' }}>
                                    {!newMessage && attachmentList.length > 0 &&
                                        <>
                                            <hr />
                                            <h5 className='font-size-15 mb-1'>
                                                Attachments ({attachmentList.length})
                                            </h5>
                                            <div className='chat-day-title'>
                                                <div className='attachment-container'>
                                                    {attachmentList.map((img, index) => {
                                                        if (img.type === 'button') {
                                                            return img;
                                                        } else {
                                                            return (
                                                                <div className={'img-attachment'}
                                                                     onClick={showFullImage(index)}>
                                                                    {img}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <GenericPopup
                                        showPopup={attachmentPopup}
                                        closePopup={setAttachmentPopup}
                                        component={attachmentListFull[selectedImage]} />
                                </ul>
                            </div>
                            {newMessage &&
                                <div className='p-3 chat-input-section'>
                                    <Row>
                                        <div className='col-auto'>
                                            <input
                                                id={'files'}
                                                multiple
                                                type='file'
                                                className='btn btn-rounded btn-info' />
                                        </div>
                                    </Row>
                                </div>
                            }
                            {newMessage &&
                                <div className='p-3 chat-input-section'>
                                    <Row>
                                        <div className='col-auto'>
                                            <Button
                                                type='submit'
                                                color='primary'
                                                className='chat-send w-md'
                                                disabled={sendingMessage}
                                            >
                                                <span className='d-none d-sm-inline-block me-2'>
                                                    Send Message
                                                </span>{' '}
                                                <i className='mdi mdi-send' />
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            }
                        </Card>
                    </Col>
                </form>
            )}

        </React.Fragment>
    );
};

export default withRouter(ChatFull);
