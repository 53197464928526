import {
    GET_PENDING_TRANSFER_WITHDRAWAL,
    GET_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    GET_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    APPROVE_PENDING_TRANSFER_WITHDRAWAL,
    APPROVE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    APPROVE_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    DECLINE_PENDING_TRANSFER_WITHDRAWAL,
    DECLINE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    DECLINE_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    RESET_PENDING_TRANSFER_WITHDRAWAL_ALERTS_ERRORS
} from './actionTypes';

export const getPendingTransferWithdrawal = payload => ({
    type: GET_PENDING_TRANSFER_WITHDRAWAL,
    payload
});

export const getPendingTransferWithdrawalSuccess = payload => ({
    type: GET_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    payload
});

export const getPendingTransferWithdrawalFail = error => ({
    type: GET_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    payload: error
});

export const approvePendingTransferWithdrawal = payload => ({
    type: APPROVE_PENDING_TRANSFER_WITHDRAWAL,
    payload
});

export const approvePendingTransferWithdrawalSuccess = payload => ({
    type: APPROVE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    payload
});

export const approvePendingTransferWithdrawalFail = error => ({
    type: APPROVE_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    payload: error
});

export const declinePendingTransferWithdrawal = payload => ({
    type: DECLINE_PENDING_TRANSFER_WITHDRAWAL,
    payload
});

export const declinePendingTransferWithdrawalSuccess = payload => ({
    type: DECLINE_PENDING_TRANSFER_WITHDRAWAL_SUCCESS,
    payload
});

export const declinePendingTransferWithdrawalFail = error => ({
    type: DECLINE_PENDING_TRANSFER_WITHDRAWAL_FAIL,
    payload: error
});

export const resetPendingTransferWithdrawalAlertsErrors = error => ({
    type: RESET_PENDING_TRANSFER_WITHDRAWAL_ALERTS_ERRORS,
    payload: error
});
