import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"

import checkedCheckbox from "../../../assets/images/layout/checked-checkbox.svg"
import uncheckedCheckbox from "../../../assets/images/layout/unchecked-checkbox.svg"
import garbage from "../../../assets/images/layout/grey-garbage.svg"
import category1 from "../../../assets/images/layout/categories/category1.png";
import category2 from "../../../assets/images/layout/categories/category2.png";
import category3 from "../../../assets/images/layout/categories/category3.png";
import category4 from "../../../assets/images/layout/categories/category4.png";
import Tooltip from "@mui/material/Tooltip";
import positive from "../../../assets/images/common/positive.svg";
import negative from "../../../assets/images/common/negative.svg";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import useWindowSize from "../../../hooks/useWindowSize";
import { useTimeContext } from "../../../context/TimeContext";
import useProviders from "../../../hooks/useProviders";

const gameImages = [category1, category2, category3, category4];

const TableCell = (props) => {
	const {
			column,
			game,
			img,
			onCheckGame,
			onClick,
			modalMode,
			canEditByPermission,
			increaseOrDecreaseGames,
			handleChange,
			categoryMode,
			isShownSelectedGames,
	} = props

	const { timeFormat } = useTimeContext();
	const size = useWindowSize();
	const [canRemoveGame, setCanRemoveGame] = useState(false);
	const { getProviderNameById } = useProviders()

	const location = useLocation();
	const isGamesDB = location.pathname.includes('games-db');
	const isPromoPage = location.pathname.includes('promotions');
	const isMobileGames = (isPromoPage || isGamesDB ||
					location.pathname.includes('game-categories') ||
					location.pathname.includes('game-management')) &&
					size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT;
 
	useEffect(() => {
		if (location.pathname.includes('/game-categories')) {
			setCanRemoveGame(canEditByPermission)
		} else {
			setCanRemoveGame(true)
		}
	}, [canEditByPermission])

	const handleCheck = (e) => {
		onCheckGame(game.id)
		increaseOrDecreaseGames && increaseOrDecreaseGames(game.checked, game.id);
		e.stopPropagation();
    	e.nativeEvent.stopImmediatePropagation();
	}

	// Boolean value
	if (typeof game[column.id] === 'boolean' && !categoryMode && !isMobileGames) {
		return (
			game[column.id]
				? (
					<img src={positive} alt="" />
				)
				: (
					<img src={negative} alt="" />
				)
		);
	}
	
	if (isMobileGames) {
		let data = game[column.source] || game[column.id];
		switch (column.id) {
			case 'check':
			return (
				<span>
					<img
						src={game.checked ? checkedCheckbox : uncheckedCheckbox}
						alt=""
						onClick={handleCheck}
					/>
				</span>
			)
			case 'game':
				return <span className="game-name__title">{data}</span>
			case 'genre':
				return <span className="game-type__title">{data}</span>
			case 'gameVendor':
				return <span>{data}</span>
			case 'gameProvider':
				return <div className="game-item">
						<span className="game-item__title">{column.title}:</span>
						<span className="game-item__data">{getProviderNameById(game.provider)}</span>
					</div>
			case 'supportFreeSpins':
			case 'emphasize':
			case 'animated':
				return (
					<div className="game-item">
						<span className="game-item__title">{column.title}:</span>
						<img
							src={game[column.id] ? positive : negative}
							onClick={() => {
								if (categoryMode) {
									handleChange('emphasize', !game.emphasize);
								}
							}}
							alt=""
						/>
					</div>
				)
			case 'remove':
				return (
					<div className="game-item">
						<span className="game-item__title">{column.id}:</span>
						<img
							className={clsx("game-img pointer", {
								'cannot-edit-games': !canEditByPermission,
							})}
							src={garbage}
							alt=""
							width={16}
							height={16}
							onClick={() => {
								column.onClick(game);
							}}
						/>
					</div>
				)
			case 'promoCoefficient':
			case 'bonusFactor':
			case 'rtp':
				return <div className="game-item">
						<span className="game-item__title">{column.title}:</span>
						<span className="game-item__data">{data}%</span>
					</div>
			default:
				return <div className="game-item">
						<span className="game-item__title">{column.title}:</span>
						<span className="game-item__data">{data}</span>
					</div>
		}
	}

	switch (column.id) {
		case 'gameProvider':
			return <span>{getProviderNameById(game.provider)}</span>
		case 'BetLevels':
			const betLevels = game.BetLevels || '';
			return (betLevels ? (
					<Tooltip title={betLevels} placement="top" arrow>
						<span>{betLevels.slice(0, 15) + '...'}</span>
					</Tooltip>
				) : (
					<span>{betLevels}</span>
				)
			)
		case 'emphasize':
			return (
				<img
					src={game.emphasize ? positive : negative}
					onClick={() => {
						if (categoryMode) {
							handleChange('emphasize', !game.emphasize);
						}
					}}
					alt=""
				/>
			)
		case 'priority':
			return (categoryMode ? (
					<input
						value={game.priority || ''}
						onChange={(e) => handleChange('priority', e.target.value)}
						type='text'
						className='editable-field-game-category'
					/>
				) : (
					<span>{game.priority}</span>
				)
			)
		case 'check':
			return (
				<span>
					<img
						src={game.checked ? checkedCheckbox : uncheckedCheckbox}
						alt=""
						onClick={handleCheck}
					/>
				</span>
			)
		case 'game':
			return (
				<span
					className={clsx('white-space-normal', {
						pointer: isShownSelectedGames || categoryMode || isGamesDB
					})}
					onClick={onClick}
				>
					<img
						className='game-img'
						src={img}
						alt=""
						width={16}
						height={16}
					/>&nbsp;{game[column.source]}
				</span>
			)
		case 'addedTime':
		case 'enabledTime':
			const addedTime = game[column.source]
			return (
				<span>
					{addedTime
						? Formatter.displayDateTimeMinutes(timeFormat.parseTime(addedTime))
						: '—'
					}
				</span>
			)
		case 'remove':
			return (
				<img
					className={clsx("game-img pointer", {
						'cannot-edit-games': !canEditByPermission,
					})}
					src={garbage}
					alt=""
					width={16}
					height={16}
					onClick={() => {
						column.onClick(game);
					}}
				/>
			)
		case 'rtp': return <span>{game[column.source] || game[column.id]}%</span>
		case 'promoCoefficient':
		case 'bonusFactor':
			return <span>{game[column.source] || game[column.id]}%</span>
		default:
			return <span>{game[column.source] || game[column.id]}</span>
	}
}

TableCell.propTypes = {
	column: PropTypes.object,
	game: PropTypes.object,
	img: PropTypes.object,
	onCheckGame: PropTypes.func,
	onClick: PropTypes.func,
	modalMode: PropTypes.bool,
}

TableCell.defaultProps = {
	onCheckGame: () => {},
	onClick: () => {},
	modalMode: false,
}

export default TableCell
