import React, { useEffect, useMemo, useState } from 'react';
import clsx from "clsx";
import { getGuidShortFormat, getColumnSettingsLsKey, initTableColumns } from '../../../common/utils/common';
import PaymentStatusView from '../../../components/Status/PaymentStatusView';
import Formatter from '../../../utils/Formatter';
import { Link, withRouter } from 'react-router-dom';
import CopyButton from '../../../common/cellData/CopyButton';
import NewCustomTable from '../../../components/NewCustomTable';
import PropTypes from 'prop-types';
import KycView from "../components/KycView";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SITE_BRAND } from "../../../constants/siteBrands";
import { useTimeContext } from "../../../context/TimeContext";
import { getUserUrl, IdCell, PlayerCell } from "../../../components/NewCustomTable/CellsWithPermissions";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import { COLUMN_IDS, getDefaultColumns } from "../../../constants/tableSettings";
import useWindowSize from "../../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../../utils/consts";
import PlayerData from 'common/cellData/PlayerData';
import FlagCountry from "../../../common/components/FlagCountry";
import {DEPOSIT_PAGE_TYPE} from "../../../constants/layout";

export const DEPOSITS_SORTED_FIELDS = {
    txnId: 'id',
    player: 'player',
    createdAt: 'createdAt',
    amount: 'totalAmount',
    currency: 'currency',
    status: 'status',
    pspCode: 'pspCode',
    pspMsg: 'pspMessage',
    lastUpdated: 'lastChangedAt',
};

const getAllColumns = (isGcPlaying = false) => {
    const columns = [
        COLUMN_IDS.txnId,
        COLUMN_IDS.pspTRX,
        COLUMN_IDS.player,
        COLUMN_IDS.playerOID,
        COLUMN_IDS.createdAt,
        COLUMN_IDS.status,
        COLUMN_IDS.lastUpdated,
        COLUMN_IDS.amount,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.totalDepositsAmount,
        COLUMN_IDS.integration,
        COLUMN_IDS.PM,
        COLUMN_IDS.pspCode,
        COLUMN_IDS.pspMsg,
        COLUMN_IDS.promoCode,
        COLUMN_IDS.country,
        COLUMN_IDS.KYC,
        COLUMN_IDS.cardInfo,
        COLUMN_IDS.cardHolderName,
    ];
    return columns;
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.txnId]: 'finance.txnId',
    [COLUMN_IDS.txnOID]: 'finance.txnOID',
    [COLUMN_IDS.pspTRX]: 'finance.pspTRX',
    [COLUMN_IDS.player]: 'finance.player',
    [COLUMN_IDS.playerOID]: 'finance.playerOid',
    [COLUMN_IDS.cardHolderName]: 'finance.cardHolderName',
    [COLUMN_IDS.createdAt]: 'finance.createdAt',
    [COLUMN_IDS.status]: 'finance.status',
    [COLUMN_IDS.lastUpdated]: 'finance.lastUpdated',
    [COLUMN_IDS.amount]: 'finance.amount',
    [COLUMN_IDS.totalDeposits]: 'finance.countSuccessfulDeposits',
    [COLUMN_IDS.totalDepositsAmount]: 'finance.totalDepositsAmount',
    [COLUMN_IDS.integration]: 'finance.integration',
    [COLUMN_IDS.PSP]: 'finance.PSP',
    [COLUMN_IDS.PM]: 'finance.PM',
    [COLUMN_IDS.pspCode]: 'finance.pspCode',
    [COLUMN_IDS.pspMsg]: 'finance.pspMsg',
    [COLUMN_IDS.promoCode]: 'finance.promoCode',
    [COLUMN_IDS.country]: 'finance.country',
    [COLUMN_IDS.KYC]: 'finance.KYC',
    [COLUMN_IDS.cardInfo]: 'finance.cardInfo',
    [COLUMN_IDS.IP]: 'finance.IP',
};

const TdElem = ({
    item,
    field,
    depositPageType = DEPOSIT_PAGE_TYPE.TRANSACTIONS,
    timeFormat,
    i18n,
}) => {
    const size = useWindowSize();
    switch (field) {
        case COLUMN_IDS.txnId:
            return <td className={'blue-color left-td cursor-pointer text-align-center text-nowrap id-row'}>
                <div className='cell-row'>
                        <Link to={depositPageType === DEPOSIT_PAGE_TYPE.TRANSACTIONS ? `/deposits/${item.id}` : `/${depositPageType}/${item.id}` }>
                            {getGuidShortFormat(item.id)}
                        </Link>
                        &nbsp;&nbsp;
                        <CopyButton
                            className="column-cpy-button"
                            onClick={() => navigator.clipboard.writeText(item.id)}
                        />
                        {
                            size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <PaymentStatusView status={item.status}/>
                            )
                        }
                </div>
                {/*<div className='cell-row__id'>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.playerId')}: </span>
                    )}
                    <IdCell
                        item={item}
                        url={{
                            type: 'finance',
                            id: item.player.oid
                        }}
                    />
                </div>*/}
            </td>;
        case COLUMN_IDS.pspTRX:
            const pspTRXLimit = 15;
            const val = item?.externalTransactionId || item?.reference;
            const copy = <CopyButton
                className="column-cpy-button"
                onClick={() => navigator.clipboard.writeText(val)}
            />;
            if(val.length > pspTRXLimit) {
                return (
                    <>
                        <td className={'psp-msg'}
                            style={{
                                nowrap: 'nowrap',
                                position: 'relative',
                                maxWidth: '100px',
                                overflow: 'hidden',
                                whiteSpace: 'pre-line',
                            }}
                            onMouseOver={(e) => {
                                // put val and copy button
                                e.target.innerText = val;
                            }}
                            onMouseOut={(e) => {
                                e.target.innerText = val?.substring(0, pspTRXLimit) + '...';
                            }}
                        >
                            {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                                <span>{i18n.t('finance.pspTRX')}: </span>
                            )}
                            {val?.substring(0, pspTRXLimit)}...
                        </td>
                    </>)
            }
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.pspTRX')}: </span>
                )}
                { val ? val : '-' }
            </td>;
        case COLUMN_IDS.player:
            return <td style={{overflow: 'visible'}} className='title__top'>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <Link to={depositPageType === DEPOSIT_PAGE_TYPE.TRANSACTIONS ? `/deposits/${item.id}` : `/${depositPageType}/${item.id}`}>
                        <PlayerData player={item.player} />
                    </Link>
                ) : (
                    <PlayerCell
                        item={item.player}
                        url={{
                            id: item.player.oid
                        }}
                    />
                )}
            </td>;
        case COLUMN_IDS.playerOID:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.playerOid')}: </span>
                )}
                <IdCell
                    item={item.player}
                    url={{
                        type: 'finance',
                        id: item.player.oid
                    }}
                />
            </td>;
        case COLUMN_IDS.cardHolderName:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.cardHolderName')}: </span>
                )}
                {item?.paymentCard?.name
                    ? item?.paymentCard?.name
                    : '-'
                }
            </td>;
        case COLUMN_IDS.username:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.username')}: </span>
                )}
                {item?.player.nickName
                    ? item?.player.nickName
                    : '-'
                }
            </td>;
        case COLUMN_IDS.createdAt:
            return <td className={"text-nowrap"}>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.createdAt')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>;
        case COLUMN_IDS.lastUpdated:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.lastUpdated')}: </span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.updatedAt))}
            </td>;
        case COLUMN_IDS.status:
            return (
                size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <td className={"halo text-nowrap"}>
                        <PaymentStatusView status={item.status}/>
                    </td>
                )
            );
        case COLUMN_IDS.amount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalAmount')}: </span>
                )}
                {Formatter.formatAmount(
                    new Intl.NumberFormat().format(item.totalAmount),
                    item.currency
                )}
            </td>;
        case COLUMN_IDS.totalDeposits:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalDeposits')}: </span>
                )}
                {item?.player?.totalDeposits || "-"}
            </td>
        case COLUMN_IDS.totalDepositsAmount:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.totalDepositsAmount')}: </span>
                )}
                {item?.player?.totalDepositsAmount
                    ? Formatter.formatAmount(
                      new Intl.NumberFormat().format(item?.player?.totalDepositsAmount),
                      item.currency)
                    : '-'
                }
            </td>
        case COLUMN_IDS.pspCode:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.pspCode')}: </span>
                )}
                {item?.pspCode || "-"}
            </td>;
        case COLUMN_IDS.pspMsg:
            // if too long show only first 20 chars and on click show all
            if (item?.pspMessage?.length > 20) {
                // on hover td show text INON
                return (<td className={'psp-msg'}
                            style={{
                                nowrap: 'nowrap',
                                position: 'relative',
                                maxWidth: '100px',
                                overflow: 'hidden',
                                whiteSpace: 'pre-line',
                            }}
                            onMouseOver={(e) => {
                                e.target.innerText = item?.pspMessage;
                            }}
                            onMouseOut={(e) => {
                                e.target.innerText = item?.pspMessage.substring(0, 20) + '...';
                            }}
                >
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.pspMsg')}: </span>
                    )}
                    {item?.pspMessage.substring(0, 20)}...
                </td>)
            }
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.pspMsg')}: </span>
                )}
                {item?.pspMessage || "-"}
            </td>;
        case COLUMN_IDS.promoCode:
            const promoCode = item?.providerDetails?.promoCode || '-';
            return <td>
                {promoCode}
            </td>
        case COLUMN_IDS.integration:
            return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.integration')}: </span>
                    )}
                    {item?.provider.type.charAt(0).toUpperCase() + item?.provider.type.slice(1).toLowerCase()}
                    </td>;
        case COLUMN_IDS.PSP:
            return <td>
                 {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.integration')}: </span>
                )}
                {item?.provider.name || "-"}
                </td>;
        case COLUMN_IDS.PM:
            return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.PM')}: </span>
                    )}
                    {item?.method || "-"}
                </td>
        case COLUMN_IDS.country:
            return <td>
{/*
                {item?.paymentCard?.country &&
                    <div
                        className="flag-div player-data__flag"
                        style={{
                            backgroundColor: `white'`,
                            boxShadow: `0px 0px 0px 3px white`,
                            borderRadius: '50%'
                        }}
                    >
                        <FlagCountry
                            countryCode={item?.paymentCard?.country}
                            className="flag-wrapper"
                        />
                    </div>
                }
*/}
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.country')}: </span>
                )}
                {item?.paymentCard?.country ? i18n.t(item?.paymentCard?.country) : '-'}
            </td>;
        case COLUMN_IDS.KYC:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.KYC')}: </span>
                )}
                <KycView kyc={item.kyc}/>
            </td>;
        case COLUMN_IDS.cardInfo:
            const cardNumber = item?.paymentCard?.maskedAccount || "-";
            const count = (cardNumber.match(/-/g) || []).length;
            if (count > 1) {
                return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.cardInfo')}: </span>
                    )}
                    {"-"}
                    </td>;
            }
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('finance.cardInfo')}: </span>
                )}
                {item?.paymentCard?.maskedAccount || "-"}
                </td>;
        case COLUMN_IDS.IP:
            return <td>
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <span>{i18n.t('finance.IP')}: </span>
                    )}
                    {item?.userIp || "-"}
                </td>;
        default:
            return null;
    }
};

function DepositsTable({
   isLoading,
   list,
   depositPageType = DEPOSIT_PAGE_TYPE.TRANSACTIONS,
   isShowTableSettings,
   onCloseTableSettings,
   onSortDeposits,
   match,
}) {
    const { i18n } = useTranslation();
    const { active: activeBrand } = useSelector(state => state.Brand);
    const gcPlaying = activeBrand?.siteName === SITE_BRAND.GCPLAYING;

    const allColumns = useMemo(() => getAllColumns(), []);
    const defaultColumns = useMemo(() => getDefaultColumns('deposits', allColumns), []);
    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState([...allColumns]);
    const [brandActiveColumns, setBrandActiveColumns] = useState([...activeColumns]);
    const { timeFormat } = useTimeContext();
    const [activeRow, setActiveRow] = useState(false);

    useEffect(() => {
        initTableColumns(match.path, () => defaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const columnsData = brandActiveColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[COLUMN_IDS[key]]),
            id: COLUMN_IDS[key],
            sorted: key in DEPOSITS_SORTED_FIELDS,
        }));
        setColumns(columnsData);
    }, [list, i18n.language, brandActiveColumns]);

    useEffect(() => {
        if (gcPlaying) {
            setBrandActiveColumns(activeColumns.filter((column) => column !== COLUMN_IDS.IP));
        } else {
            setBrandActiveColumns([...activeColumns]);
        }
    }, [gcPlaying, activeColumns]);

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <NewCustomTable
                isHeader={true}
                columns={columns}
                onSortItems={onSortDeposits}
                sortAfterMount={false}
                isLoading={isLoading}
                className='responsiveTable responsiveTable__xl'
            >
                {list && list.map((item, index) =>
                    <tr
                        key={index}
                        className={clsx({open: activeRow === index})}
                        onClick={() => handleToggle(index)}
                    >
                        {brandActiveColumns.map((column) =>
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                depositPageType={depositPageType}
                                timeFormat={timeFormat}
                                i18n={i18n}
                            />
                        )}
                    </tr>
                )}
            </NewCustomTable>
            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={brandActiveColumns}
                    defaultColumns={defaultColumns}
                    allColumns={allColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    )
}

DepositsTable.propTypes = {
    list: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
    onSortDeposits: PropTypes.func,
};

DepositsTable.defaultProps = {
    onCloseTableSettings: () => {},
    onSortDeposits: () => {},
};

export default React.memo(withRouter(DepositsTable));
