import React, { memo } from "react"
import { removeUnScrollBody, setUnScrollBody } from "../../../../../utils/unScrollBody";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import i_51 from "../../../../../assets/images/common/Frame 176 (1).svg";
import { useTranslation } from "react-i18next";
import TextareaInput from "../../../../../common/inputs/TextareaInput";
import ACommonDataField from "../../../../../components/GenericComponents/CommonDataField"
import { A_COMMON_DATA_FIELD } from "../../../../../constants/aCommonDataField"


const ActionSettingsModal = (props) => {
  const { modalTitle, onToggle, specificPromosIds, setSpecificPromosIds, disabledPaymentMethods, setDisabledPaymentMethods } = props;
  const { t } = useTranslation();

  const onChangeSpecificPromo = (value) => {
    setSpecificPromosIds(value);
  }

  const handlePaymentMethodsChange = (selectedMethods) => {
    setDisabledPaymentMethods(selectedMethods);
  };


  return (
    <Modal
      isOpen={true}
      role="dialog"
      autoFocus={true}
      data-toggle="modal"
      className="audit-changes-modal fraud-settings-modal"
      centered
      onOpened={setUnScrollBody}
      onExit={removeUnScrollBody}
    >
      <div className="modal-header">
        <h5 className="modal-title text-uppercase">
          {t(`admin.modal.${modalTitle}`)}
        </h5>
        <button className="close" onClick={onToggle} />
      </div>

      <ModalBody>
        <div className='modal-body-fraud'>
          {modalTitle === 'block_specific_promotions' ? (
            <>
              <span className="modal-desc">
                {t(`admin.modal.enterIds`)}
              </span>
              {/*<ACommonDataField*/}
              {/*  component={A_COMMON_DATA_FIELD.MULTI_SELECT_PROMOTIONS_TEMPLATE}*/}
              {/*  label='Casino & Sport promotions'*/}
              {/*  value={disabledPaymentMethods}*/}
              {/*  handleChange={handlePaymentMethodsChange}*/}
              {/*/>*/}
              <TextareaInput
                className="form-control form-control-gray specific-promo"
                value={specificPromosIds}
                onChange={onChangeSpecificPromo}
              />
            </>
          ) : (
            <div className='disabled-payment-methods'>
              <ACommonDataField
                component={A_COMMON_DATA_FIELD.MULTI_SELECT_CASHIER_PAYMENT_METHODS}
                label='Deposit payment methods'
                value={disabledPaymentMethods}
                handleChange={handlePaymentMethodsChange}
              />
            </div>
          )}

        </div>

      </ModalBody>
      <ModalFooter>
        <div className="footer-fraud-modal">
          <button
            className="btn btn-primary btn-rounded"
            onClick={onToggle}
          >
          <img src={i_51} alt="" />
            {t('crm.submit')}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default memo(ActionSettingsModal);