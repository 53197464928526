import React, {useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';
import i18n from '../../../i18n';
import { Container } from 'reactstrap';
import StatisticsMainCard from './ManagerStatisticsMainCard';
import AgentStatisticsMainCard from './AgentStatisticsMainCard';

import './statistics.scss';

import {useDispatch, useSelector} from 'react-redux';
import ClientsRegisteredWidget from './common/ClientsRegisteredWidget';
import TopCountriesCRWidget from './common/TopCountriesCRWidget';
import TopCountriesPVWidget from './common/TopCountriesPVWidget';
import ClientStatusesWidget from './common/ClientStatusesWidget';
import { STATISTICS_CONTROLLER_TYPE } from './common/StatisticsController';
import FtdsWidget from './common/FtdsWidget';
import PageTitle from "../../../components/PageTitle";
import Loader from "../../../common/components/Loader";
import useAlertService from "../../../hooks/useAlertService";
import {getStatistics, resetStatisticsErrors} from "../../../store/statistics/actions";


const StatisticsPage = () => {
  const alertService = useAlertService();
  const dispatch = useDispatch();
  const { agentSelf } = useSelector(state => state.AgentSelf);
  const { isLoading, error, statistics } = useSelector(state => state.Statistics);
  const [activeType, setActiveType] = useState(
      STATISTICS_CONTROLLER_TYPE.CONVERSION
  );
  const [filter, setFilter] = useState({
      office: '',
      agentOwner: agentSelf?.id
  });

  useEffect(() => {
    if (error) {
      alertService.showError(error);
      dispatch(resetStatisticsErrors());
    }
  }, [error]);

  useEffect(() => {
    dispatch(getStatistics());
  }, []);

  useEffect(() => {
      setFilter(prevState => ({ ...prevState, agentOwner: agentSelf?.id }))
  }, [agentSelf]);

  if (!agentSelf || !statistics || isLoading) {
      return <Loader size={'lg'}/>;
  }

  return (
    <div className="page-content statistics-page">
      <MetaTags>
        <title>{i18n.t('admin.statistics')} - {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <PageTitle title={i18n.t('admin.statistics')} />
        <Container fluid>
          {agentSelf?.isManager ? (
            <StatisticsMainCard
              statistics={statistics}
              activeType={activeType}
              setActiveType={setActiveType}
              filter={filter}
              setFilter={setFilter}
            />
          ) : (
            <AgentStatisticsMainCard
              agent={agentSelf}
              statistics={statistics}
            />
          )}

          <div className="statistic-widgets-container">
            {activeType === STATISTICS_CONTROLLER_TYPE.CONVERSION ? (
              <ClientsRegisteredWidget data={statistics} />
            ) : (
              <FtdsWidget data={statistics} />
            )}

            {activeType === STATISTICS_CONTROLLER_TYPE.CONVERSION ?
              <TopCountriesCRWidget data={statistics} />
             :
              <TopCountriesPVWidget data={statistics} />
            }

            <ClientStatusesWidget data={statistics} />
          </div>
        </Container>
    </div>
  );
};

export default React.memo(StatisticsPage);
