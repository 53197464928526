import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SITE_BRAND } from "../constants/siteBrands";

export default function useSiteBrand()  {
    const { active: activeBrand } = useSelector(state => state.Brand);
    const [siteBrand, setSiteBrand] = useState(SITE_BRAND.WINPOTMX);

    useEffect(() => {
        setSiteBrand(activeBrand?.siteName);
    }, [activeBrand]);

    return siteBrand;
}