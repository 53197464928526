import { post, patch, getFile, uploadFileToServer, uploadMassCashback } from "./api_helper"

const createManualAdjustments = dto => {
    const url = `/wallet/manual/transfer`;
    return post(url, dto);
};

const createDepositManualAdjustments = dto => {
    const url = `/wallet/manual/deposit`;
    return post(url, dto);
};

const createWithdrawalManualAdjustments = dto => {
    const url = `/wallet/manual/withdraw`;
    return post(url, dto);
};

const createRetailManualAdjustments = dto => {
    const url = `/wallet/manual/retail-transfer`;
    return post(url, dto);
};

const massCashbackApply = dto => {
    const url = `/promotion/cashback`;
    return uploadMassCashback(url, dto.file, dto.comment);
};

const downloadTemplateInactivityFeeApi = dto => {
    const url = `/wallet/manual/bulk-transfer/excel/template?commentType=FeeInactivity`;
    return getFile(url);
};

const massInactivityFeeApplyApi = dto => {
    const url = `/wallet/manual/bulk-transfer/excel`;

    return uploadFileToServer(
        url,
        dto.file,
        dto.config.type,
        dto.config.commentType
    );
};

export {
    createDepositManualAdjustments,
    createWithdrawalManualAdjustments,
    createManualAdjustments,
    createRetailManualAdjustments,
    massCashbackApply,
    downloadTemplateInactivityFeeApi,
    massInactivityFeeApplyApi,
};
