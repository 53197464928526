export const MANUAL_ADJUSTMENTS_TYPE = {
    Debit: 'DEBIT',
    Credit: 'CREDIT'
};


export const MANUAL_ADJUSTMENTS_COMMENTS = [
    {
        reason: 'ManualDeposit',
        description: 'Adjustment made to deposit funds to the player\'s account.',
    },
    {
        reason: 'ManualWithdrawal',
        description: 'Adjustment made to withdraw funds from the player\'s account.',
    },
    {
        reason: 'CancelChargebacks',
        description: 'Adjustment made to cancel chargebacks on the player\'s account.',
    },
    {
        reason: 'Chargebacks',
        description: 'Adjustment made to account for chargebacks on the player\'s account.',
    },
    {
        reason: 'Fee',
        description: 'Adjustment made to deduct fees from the player\'s account.',
    },
    {
        reason: 'FeeInactivity',
        description: 'Adjustment made to deduct inactivity fees from the player\'s account.',
    },
    {
        reason: 'FeeMaintenance',
        description: 'Adjustment made to deduct maintenance fees from the player\'s account.',
    },
    {
        reason: 'FeeOther',
        description: 'Adjustment made to deduct other types of fees from the player\'s account.',
    },
    {
        reason: 'FeeReimbursement',
        description: 'Adjustment made to reimburse fees to the player\'s account.',
    },
    {
        reason: 'Agreement',
        description: 'Adjustment made due to changes in the player\'s agreement with the casino.',
    },
    {
        reason: 'CorrectionBalance',
        description: 'Adjustment made to correct the player\'s balance.',
    },
    {
        reason: 'CorrectionBO',
        description: 'Adjustment made as a correction from the back-office (BO).',
    },
    {
        reason: 'CorrectionDealing',
        description: 'Adjustment made to correct dealing errors.',
    },
    {
        reason: 'CorrectionFraud',
        description: 'Adjustment made to correct fraudulent activities on the player\'s account.',
    },
    {
        reason: 'CorrectionSwap',
        description: 'Adjustment made to correct swap-related issues.',
    },
    {
        reason: 'Promo',
        description: 'Adjustment made to account for promotional bonuses.',
    },
    {
        reason: 'Bonuses',
        description: 'Adjustment made to account for other types of bonuses.',
    },
    {
        reason: 'Rebate',
        description: 'Adjustment made to provide a rebate to the player.',
    },
    {
        reason: 'ResetBalance',
        description: 'Adjustment made to reset the player\'s balance to a specific value.',
    },
    {
        reason: 'ReferAFriend',
        description: 'Adjustment made as a referral bonus for referring a friend to the casino.',
    },
    {
        reason: 'CompetitionWinner',
        description: 'Adjustment made for players who win competitions or contests.',
    },
    {
        reason: 'Other',
        description: 'Adjustment made for reasons not covered by the above categories.',
    }
];

export const MANUAL_ADJUSTMENTS_COMMENTS_FINANCIAL_TYPES = ['ManualWithdrawal', 'ManualDeposit'];
