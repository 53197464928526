import {
    GET_PLAYER_JOINT,
    GET_PLAYER_JOINT_SUCCESS,
    GET_PLAYER_JOINT_FAIL,
    SET_EDIT_PLAYER_JOINT,
    UPDATE_PLAYER_JOINT,
    UPDATE_PLAYER_JOINT_SUCCESS,
    UPDATE_PLAYER_JOINT_FAIL,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_STATUS_SUCCESS,
    UPDATE_PLAYER_STATUS_FAIL,
    RESET_PLAYER_ERRORS,
    RESET_PLAYER_STATE
} from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    error: null,
    playerJoint: null,
    isEdit: false,
    jointUpdated: false
};

const PlayerJoint = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_JOINT:
            return {
                ...state,
                isLoading: true,
                error: null,
                jointUpdated: false
            };

        case GET_PLAYER_JOINT_SUCCESS:
            return {
                ...state,
                playerJoint: action.payload,
                isLoading: false,
                jointUpdated: false
            };

        case GET_PLAYER_JOINT_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                jointUpdated: false
            };

        case SET_EDIT_PLAYER_JOINT:
            return { ...state, isEdit: action.payload, jointUpdated: false };

        case UPDATE_PLAYER_JOINT:
            return {
                ...state,
                isLoading: true,
                error: null,
                jointUpdated: false
            };

        case UPDATE_PLAYER_JOINT_SUCCESS:
            return {
                ...state,
                playerJoint: Object.assign(
                    state.playerJoint,
                    action.payload.dto
                ),
                isLoading: false,
                jointUpdated: true
            };

        case UPDATE_PLAYER_JOINT_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                jointUpdated: false
            };

        case UPDATE_PLAYER_STATUS:
            return {
                ...state,
                isLoading: true,
                error: null,
                jointUpdated: false
            };

        case UPDATE_PLAYER_STATUS_SUCCESS:
            return {
                ...state,
                playerJoint: {
                    ...state.playerJoint,
                    ...action.payload.dto
                },
                isLoading: false,
                jointUpdated: false
            };

        case UPDATE_PLAYER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                jointUpdated: false
            };

        case RESET_PLAYER_ERRORS:
            return { ...state, error: null, jointUpdated: false };

        case RESET_PLAYER_STATE:
            return { ...INIT_STATE, jointUpdated: false };

        default:
            return state;
    }
};

export default PlayerJoint;
