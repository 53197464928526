import { takeLatest, put, call, takeEvery } from "redux-saga/effects";

import {
    DOWNLOAD_GAME_PLAY_LIST,
    GET_PLAYER_GAME_PLAY,
    GET_PLAYER_GAME_SESSIONS,
    GET_PLAYER_GAME_ROUNDS,
    GET_PLAYER_BET_SLIP,
    GET_PLAYER_GAME_SESSION_ROUNDS,
    GET_UNIQUE_GAME_IDS,
} from "./actionTypes";
import {
    getPlayerGamePlaySuccess,
    getPlayerGamePlayFail,
    getPlayerGameSessionsSuccess,
    getPlayerGameSessionsFail,
    getPlayerGameRoundsSuccess,
    getPlayerGameRoundsFail,
    getPlayerBetSlipSuccess,
    getPlayerBetSlipFail,
    getPlayerGameSessionRoundsSuccess,
    getPlayerGameSessionRoundsFail,
    downloadGamePlayListSuccess,
    downloadGamePlayListFail,
    getPlayedUniqueGameIdsSuccess,
    getPlayedUniqueGameIdsFail,
} from "./actions";
import {
    downloadGamePlayListApi,
    getPlayerGamePlayApi,
    getPlayerGameSessionsApi,
    getPlayerGameRoundsApi,
    getPlayerBetSlipApi,
    getPlayerGameSessionRoundsApi,
    getPlayerUniqueGameIdsApi,
} from "../../../helpers/playerApi";

function* downloadGamePlayListSaga({ payload }) {
    try {
        yield call(downloadGamePlayListApi, payload);

        yield put(downloadGamePlayListSuccess());
    } catch (error) {
        yield put(downloadGamePlayListFail(error));
    }
}


function* fetchPlayerGamePlay({ payload }) {
    try {
        const response = yield call(getPlayerGamePlayApi, payload);

        yield put(getPlayerGamePlaySuccess(response.data));
    } catch (error) {
        yield put(getPlayerGamePlayFail(error));
    }
}

function* fetchPlayerGameSessions({ payload }) {
    try {
        const response = yield call(getPlayerGameSessionsApi, payload);

        yield put(getPlayerGameSessionsSuccess(response.data));
    } catch (error) {
        yield put(getPlayerGameSessionsFail(error));
    }
}

function* fetchPlayerGameRounds({ payload }) {
    try {
        const response = yield call(getPlayerGameRoundsApi, payload);

        yield put(getPlayerGameRoundsSuccess(response.data));
    } catch (error) {
        yield put(getPlayerGameRoundsFail(error));
    }
}

function* fetchPlayerBetSlip({ payload }) {
    try {
        const response = yield call(getPlayerBetSlipApi, payload);

        yield put(getPlayerBetSlipSuccess(response.data));
    } catch (error) {
        yield put(getPlayerBetSlipFail(error));
    }
}

function* fetchPlayerGameSessionRounds({ payload }) {
    try {
        const response = yield call(getPlayerGameSessionRoundsApi, payload);

        yield put(getPlayerGameSessionRoundsSuccess(response.data));
    } catch (error) {
        yield put(getPlayerGameSessionRoundsFail(error));
    }
}

function* fetchPlayedUniqueGamesIds({ payload }) {
    try {
        const response = yield call(getPlayerUniqueGameIdsApi, payload);

        yield put(getPlayedUniqueGameIdsSuccess(response.data));
    } catch (error) {
        yield put(getPlayedUniqueGameIdsFail(error));
    }
}

function* verificationSaga() {
    yield takeEvery(DOWNLOAD_GAME_PLAY_LIST, downloadGamePlayListSaga);
    yield takeLatest(GET_PLAYER_GAME_PLAY, fetchPlayerGamePlay);
    yield takeLatest(GET_PLAYER_GAME_SESSIONS, fetchPlayerGameSessions);
    yield takeLatest(GET_PLAYER_GAME_ROUNDS, fetchPlayerGameRounds);
    yield takeLatest(GET_PLAYER_BET_SLIP, fetchPlayerBetSlip);
    yield takeLatest(GET_PLAYER_GAME_SESSION_ROUNDS, fetchPlayerGameSessionRounds);
}

export default verificationSaga;
