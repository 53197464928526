import { all, fork } from 'redux-saga/effects';

//public
import AuthSaga from './auth/login/saga';
import DocumentsSaga from './documents/saga';

import AffiliatesSaga from './affiliates/saga';
import NoteSaga from './note/saga';

import PlayerSelectListSaga from './players/players/saga';
import PlayerListSaga from './players/list/saga';
import PlayerGeneralSaga from './players/general/saga';
import PlayerJointSaga from './players/joint/saga';
import PlayerWithdrawalsSaga from './players/withdrawals/saga';
import PlayerDepositsSaga from './players/deposits/saga';
import PlayerCommunicationSaga from './players/сommunication/saga';
import PlayerAuditSaga from './players/audit/saga';
import PlayerVerificationSaga from './players/verification/saga';
import PlayerGamePlaySaga from './players/gamePlay/saga';
import PlayerRestrictionsSaga from './players/restrictions/saga';
import PlayerManualAdjustmentSaga from './players/manualAdjustment/saga';
import PlayerPromotionsSaga from './players/promotions/saga';
import playerAttributionsSaga from './players/attributions/saga';

import BrandSaga from './brand/siteBrands/saga';
import BrandSettingsSaga from './brand/brandPage/saga';

import DepositListSaga from './deposits/list/saga';
import DepositGeneralSaga from './deposits/detailed/saga';

import WithdrawalListSaga from './withdrawals/list/saga';
import WithdrawalGeneralSaga from './withdrawals/detailed/saga';

import PendingWithdrawalListSaga from './pending-withdrawals/list/saga';
import PendingWithdrawalGeneralSaga from './pending-withdrawals/detailed/saga';

import PendingTransferWithdrawalListSaga from './pending-transfer-withdrawals/list/saga';
import PendingTransferWithdrawalGeneralSaga from './pending-transfer-withdrawals/detailed/saga';

import agentsListSaga from './agents/agents/saga';
import AgentSelfSaga from './agents/self/saga';
import AgentDetailedSaga from './agents/detailed/saga';
import AgentEmployeesSaga from './agents/employees/saga';
import AgentEmployeeSaga from './agents/employee/saga';

import StatisticsSaga from './statistics/saga';

import InfoSaga from './info/saga';

import LayoutSaga from './layout/saga';

import FilterSaga from './filters/saga';
import FilterPageSaga from './filters/filterPage/saga';

import NotificationSaga from './notifications/saga';
import NotificationPageSaga from './notifications/notificationPage/saga';
import SearchSaga from './search/saga';

import OverviewPageSaga from './overview/saga';

import GamesSaga from './games/saga';
import Cashier from './cashier/saga';
import PromotionSaga from "./promotions/saga";
import StatesAndCitiesSaga from "./statesAndCities/saga";
import playersReferralsSaga from "./players/referrals/saga"

export default function* rootSaga() {
    yield all([
        fork(AuthSaga),
        fork(DocumentsSaga),
        fork(AffiliatesSaga),
        fork(NoteSaga),

        fork(PlayerSelectListSaga),
        fork(PlayerListSaga),
        fork(PlayerJointSaga),
        fork(PlayerGeneralSaga),
        fork(PlayerWithdrawalsSaga),
        fork(PlayerDepositsSaga),
        fork(PlayerCommunicationSaga),
        fork(PlayerAuditSaga),
        fork(PlayerVerificationSaga),
        fork(PlayerGamePlaySaga),
        fork(PlayerRestrictionsSaga),
        fork(PlayerManualAdjustmentSaga),
        fork(PlayerPromotionsSaga),
        fork(playerAttributionsSaga),

        fork(DepositListSaga),
        fork(DepositGeneralSaga),

        fork(WithdrawalListSaga),
        fork(WithdrawalGeneralSaga),

        fork(PendingWithdrawalListSaga),
        fork(PendingWithdrawalGeneralSaga),

        fork(PendingTransferWithdrawalListSaga),
        fork(PendingTransferWithdrawalGeneralSaga),

        fork(agentsListSaga),
        fork(AgentSelfSaga),
        fork(AgentDetailedSaga),
        fork(AgentEmployeesSaga),
        fork(AgentEmployeeSaga),

        fork(BrandSaga),
        fork(BrandSettingsSaga),
        fork(InfoSaga),

        fork(FilterSaga),
        fork(FilterPageSaga),

        fork(StatisticsSaga),

        fork(NotificationSaga),
        fork(NotificationPageSaga),

        fork(SearchSaga),

        fork(OverviewPageSaga),

        fork(GamesSaga),

        fork(Cashier),

        fork(PromotionSaga),

        fork(StatesAndCitiesSaga),

        fork(playersReferralsSaga),

        //public
        fork(LayoutSaga)
    ]);
}
