import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import i18n from '../../../i18n';
import UserStatusView from '../../../components/Status/StatusView';
import group from '../../../assets/images/common/Tooltip.svg';
import Loader from '../../../common/components/Loader';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';

function SiteMainCard({ generalInfo, isLoading }) {
    return (
        <Card className="site-main-card">
            <CardBody>
                {isLoading && <Loader size={'lg'}/>}
                {!isLoading && (
                    <>
                        <Row className="d-flex justify-content-between site-main-card__header">
                            <Col xs={12} sm={6}>
                                <span className="font-w-600 me-3">
                                    {i18n.t('admin.name')}:
                                </span>
                                <span>{generalInfo.name}</span>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="sub-content">
                            <Col
                                xs={12}
                                className="sub-content__header d-flex align-items-center"
                            >
                                <img className="me-3" src={group} alt="" />

                                <span className="font-w-500">
                                    {i18n.t('admin.generalInfo')}
                                </span>
                            </Col>

                            <Col className="site-main-card__info">
                                <Row xs={'auto'}>
                                    <Col className="content-row me-4 mb-0">
                                        <ACommonDataField
                                            component={A_COMMON_DATA_FIELD.DATE}
                                            label={`${i18n.t(
                                                'admin.createdTime'
                                            )}:`}
                                            value={generalInfo.createdTime}
                                            disabled={true}
                                        />
                                    </Col>

                                    <Col className="content-row me-4 mb-0">
                                        <ACommonDataField
                                            fieldClassName={'second-field'}
                                            component={A_COMMON_DATA_FIELD.DATE}
                                            label={`${i18n.t(
                                                'admin.lastChanged'
                                            )}:`}
                                            value={generalInfo.lastChanged}
                                            handleChange={() => {}}
                                            disabled={true}
                                        />
                                    </Col>

                                    <Col className="content-row mb-0">
                                        <ACommonDataField
                                            component={A_COMMON_DATA_FIELD.TEXT}
                                            label={`${i18n.t(
                                                'admin.jurisdiction'
                                            )}:`}
                                            value={generalInfo.jurisdiction}
                                            handleChange={() => {}}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </CardBody>
        </Card>
    );
}

SiteMainCard.propTypes = {
    generalInfo: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default React.memo(SiteMainCard);
