export const GET_DEPOSIT_LIST = 'GET_DEPOSIT_LIST';
export const GET_DEPOSIT_LIST_SUCCESS = 'GET_DEPOSIT_LIST_SUCCESS';
export const GET_DEPOSIT_LIST_FAIL = 'GET_DEPOSIT_LIST_FAIL';
export const GET_DEPOSIT_ACTION_LIST = 'GET_DEPOSIT_ACTION_LIST';
export const GET_DEPOSIT_ACTION_LIST_SUCCESS = 'GET_DEPOSIT_ACTION_LIST_SUCCESS';
export const GET_DEPOSIT_ACTION_LIST_FAIL = 'GET_DEPOSIT_ACTION_LIST_FAIL';

export const RESET_DEPOSIT_LIST_ALERTS_ERRORS = 'RESET_DEPOSIT_LIST_ALERTS_ERRORS';

export const DOWNLOAD_DEPOSIT_LIST = 'DOWNLOAD_DEPOSIT_LIST';
export const DOWNLOAD_DEPOSIT_LIST_SUCCESS = 'DOWNLOAD_DEPOSIT_LIST_SUCCESS';
export const DOWNLOAD_DEPOSIT_LIST_FAIL = 'DOWNLOAD_DEPOSIT_LIST_FAIL';

export const GET_CARD_NETWORKS = 'GET_CARD_NETWORKS';
export const GET_CARD_NETWORKS_SUCCESS = 'GET_CARD_NETWORKS_SUCCESS';
export const GET_CARD_NETWORKS_FAIL = 'GET_CARD_NETWORKS_FAIL';

export const GET_PAYMENT_PROVIDERS = 'GET_PAYMENT_PROVIDERS';
export const GET_PAYMENT_PROVIDERS_SUCCESS = 'GET_PAYMENT_PROVIDERS_SUCCESS';
export const GET_PAYMENT_PROVIDERS_FAIL = 'GET_PAYMENT_PROVIDERS_FAIL';
