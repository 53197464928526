export const ContentProvider = {
    Pariplay: 'Pariplay',
    Zitro: 'Zitro',
    Delasport: 'Delasport',
    Evolution: 'Evolution',
    Platipus: 'Platipus',
};

export const SportProvider = {
    Altenar: 'Altenar',
    DelaSport: 'Delasport'
};

export const UNIQUE_GAME_FIELDS = [
    'id',
    'oid',
    'friendlyName',
    'externalDesktopId',
    'externalMobileId',
];

export const REQUIRED_GAME_FIELDS = [
    'friendlyName',
    'providerName',
    'vendorName',
    'externalDesktopId',
    'externalMobileId',
];

export const NOT_IS_NAN_FIELDS = [
    'lines',
    'rtp',
    'bonusFactor',
];

export const AttributionType = {
    Subtype: 'Subtype',
    Theme: 'Theme',
    RTP: 'RTP',
    BonusFactor: 'BonusFactor',
    Branded: 'Branded',
    SideBet: 'SideBet',
    BetBehind: 'BetBehind',
    SeatLimit: 'SeatLimit',
    BonusBuy: 'BonusBuy',
    FreeSpins: 'supportFreeSpins',
    Jackpot: 'Jackpot',
    DescriptionGameInfo: 'DescriptionGameInfo',
    ProviderReleaseDate: 'ProviderReleaseDate',
    JackpotName: 'JackpotName',
    JackpotGameCode: 'JackpotGameCode',
    JackpotContribution: 'JackpotContribution',
    ProgressiveSeedAmount: 'ProgressiveSeedAmount',
    Megaways: 'Megaways',
    MultipleFSConfigurations: 'MultipleFSConfigurations',
    Replay_PragmaticOnly: 'Replay_PragmaticOnly',
    Reels: 'Reels',
    Paylines: 'Paylines',
    Volatility: 'Volatility',
    BetLevels: 'BetLevels',
    DefaultBet: 'DefaultBet',
    MinBet: 'MinBet',
    MaxBet: 'MaxBet',
    MaxPayout: 'MaxPayout',
    MaxMultiplier: 'MaxMultiplier',
    HitFrequency: 'HitFrequency',
    Certifications: 'Certifications',
    SupportedLanguages: 'SupportedLanguages',
}

export const BooleanAttributions = [
    'Branded',
    'SideBet',
    'BetBehind',
    'BonusBuy',
    'FreeSpins',
];

export const categoriesSortOptions = [
    'Latest_created',
    'Latest_updated',
    'Games_amount',
    'aZ',
    'Za',
];
