import {A_COMMON_DATA_FIELD} from "../../../../../constants/aCommonDataField";
import {useMemo} from "react";

export const currencySupplierFields = [
    {
        label: 'Min Amount',
        component: A_COMMON_DATA_FIELD.TEXT,
        value: '',
        stateKey: 'min',
        className: 'w-150',
        hint: 'ex: 300',
        required: true,
        info: 'The minimum amount that the player can use with this supplier'
    },
    {
        label: 'Max Amount',
        component: A_COMMON_DATA_FIELD.TEXT,
        value: '',
        stateKey: 'max',
        className: 'w-150',
        hint: 'ex: 3000',
        required: true,
        info: 'The maximum amount that the player can use with this supplier'
    },
    {
        label: 'Default Amount',
        component: A_COMMON_DATA_FIELD.TEXT,
        value: '',
        stateKey: 'defAmountCashier',
        className: 'w-100',
        style: {fontSize: '2px'},
        hint: 'ex: 3000',
        info: 'This amount will be displayed in the cashier as the default amount for this supplier',
        required: true,
    },
    {
        label: 'Suggest Amounts',
        component: A_COMMON_DATA_FIELD.TEXT,
        value: '',
        stateKey: 'suggestAmounts',
        className: 'w-100',
        style: {fontSize: '2px'},
        hint: 'ex: 5, 10, 20',
        info: 'This amount will be displayed in the cashier as the default amount for this supplier',
        required: true,
    },
]

export const supplierFields = [
    {
        label: 'Supplier Status',
        component: A_COMMON_DATA_FIELD.TOGGLE,
        value: false,
        stateKey: 'supplierStatus',
        info: 'If the supplier is disabled, it will not be displayed in the cashier'
    },
    {
        label: 'Supplier',
        component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
        value: '',
        options: [],
        stateKey: 'integration',
        className: 'w-200',
        info: 'Select the supplier type of this supplier (if your supplier is not in the list, please contact your account manager)',
        required: true,
    },
    {
        label: 'Supplier Name',
        component: A_COMMON_DATA_FIELD.TEXT,
        value: '',
        stateKey: 'supplierName',
        className: 'w-200',
        required: true,
        info: 'Type internal supplier name',
    },
    {
        label: 'Priority',
        component: A_COMMON_DATA_FIELD.NUMBER,
        value: '',
        stateKey: 'priority',
        className: 'w-150',
        hint: 'ex: 1, 2, 3',
        info: 'In case the player can use more than one supplier from this list, we will show only the supplier with the highest priority.\nPriority 1 is the highest priority',
        required: true,
    },
    {
        label: 'FTD',
        component: A_COMMON_DATA_FIELD.SELECT_CASHIER_BOOLEAN,
        value: null,
        stateKey: 'ftd',
        className: 'w-150',
        info: 'Choose whether this supplier will be available only for FTD players or not, leave empty if you want to make it available for all players'
    },
    {
        label: 'Chargeback',
        component: A_COMMON_DATA_FIELD.SELECT_CASHIER_BOOLEAN,
        value: null,
        stateKey: 'chargeback',
        className: 'w-150',
        info: 'Choose whether this supplier will be available only for players with chargeback or not, leave empty if you want to make it available for all players'
    },
    {
        label: 'KYC',
        component: A_COMMON_DATA_FIELD.SELECT_CASHIER_BOOLEAN,
        value: null,
        stateKey: 'kyc',
        className: 'w-150',
        info: 'Choose whether this supplier will be available only for players with KYC or not, leave empty if you want to make it available for all players'
    },
    {
        label: 'VIP Level',
        component: A_COMMON_DATA_FIELD.SELECT_VIP_LEVEL,
        value: '',
        stateKey: 'vipLevel',
        className: 'w-150',
        info: 'Choose the VIP level that will be able to see this supplier in the cashier',
    },
    {
        label: 'Currency',
        component: A_COMMON_DATA_FIELD.SELECT_MULTI_CURRENCIES,
        value: [],
        stateKey: 'currency',
        className: 'w-150',
        info: 'Only players with these currencies will be able to see this supplier'
    },
]


export const basicFields = [
        {
            component: A_COMMON_DATA_FIELD.TOGGLE,
            value: false,
            stateKey: 'status',
        },
        {
            component: A_COMMON_DATA_FIELD.TEXT,
            value: '',
            stateKey: 'name',
            info: 'Name of the method that will be displayed in the cashier',
            hint: 'ex: Visa/Mastercard',
            required: true,
        },
        {
            component: A_COMMON_DATA_FIELD.TEXT,
            value: '',
            stateKey: 'method',
            info: 'Method identifier from PSP',
            hint: 'ex: Visa/Mastercard',
            required: true,
        },
        {
            component: A_COMMON_DATA_FIELD.TEXT,
            value: '',
            stateKey: 'image',
            info: 'URL to the image that will be displayed in the cashier',
            hint: 'ex: https://winpot-cdn.mx/visa.png',
            required: true,
        },
        {
            component: A_COMMON_DATA_FIELD.TEXT,
            value: '',
            stateKey: 'players',
            info: `Here you can place list of player oid's that only them will be able to see this supplier in the cashier`,
            hint: 'ex: 34255, 34256',
        },
        {
            component: A_COMMON_DATA_FIELD.NUMBER,
            value: 1,
            stateKey: 'priority',
            className: 'w-150',
            hint: 'ex: 1, 2, 3',
            info: 'Priority sort',
        },
        {
            component: A_COMMON_DATA_FIELD.SELECT_MULTI_COUNTRIES,
            value: [],
            stateKey: 'countriesAvailable',
            className: 'w-150',
            info: `Here you can place list of countries that only them will be able to see this supplier in the cashier`,
            hint: 'ex: UA, MX',
        },
        {
            component: A_COMMON_DATA_FIELD.SELECT_MULTI_COUNTRIES,
            value: [],
            stateKey: 'countriesDisable',
            className: 'w-150',
            info: `Here you can place list of countries that will not be able to see this supplier in the cashier`,
            hint: 'ex: UA, MX',
        },
        {
            label: 'Require Fields',
            component: A_COMMON_DATA_FIELD.SELECT_REQUIRE_FIELDS,
            value: [],
            stateKey: 'requiredFields',
            className: 'w-150',
            info: 'If this supplier wil be selected the following information will be required from the player',
        }
    ]