import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_VERIFICATION,
    GET_PLAYER_VERIFICATION_HISTORY_LIST,
    UPDATE_PLAYER_VERIFICATION,
    GET_PLAYER_SOF_AGREEMENT,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT,
} from './actionTypes';
import {
    getPlayerVerificationSuccess,
    getPlayerVerificationFail,
    updatePlayerVerificationSuccess,
    updatePlayerVerificationFail,
    getPlayerVerificationHistoryListSuccess,
    getPlayerVerificationHistoryListFail,
    getPlayerSofAgreementSuccess,
    getPlayerSofAgreementFail,
    getPlayerSelfExclusionAgreementSuccess,
    getPlayerSelfExclusionAgreementFail,
    getPlayerAccountRecoveryAgreementSuccess,
    getPlayerAccountRecoveryAgreementFail,
} from './actions';
import {
    getPlayerVerificationApi,
    getPlayerVerificationHistoryListApi,
    updatePlayerVerificationApi,
    getPlayerSofAgreementApi,
    getPlayerSelfExclusionAgreementApi,
    getPlayerAccountRecoveryAgreementApi,
} from '../../../helpers/playerApi';

function* fetchPlayerVerificationSaga({ payload }) {
    try {
        const response = yield call(getPlayerVerificationApi, payload);

        yield put(getPlayerVerificationSuccess(response.data));
    } catch (error) {
        yield put(getPlayerVerificationFail(error));
    }
}

function* getPlayerSofAgreementSaga({ payload }) {
    try {
        const response = yield call(getPlayerSofAgreementApi, payload);

        yield put(getPlayerSofAgreementSuccess(response.data));
    } catch (error) {
        yield put(getPlayerSofAgreementFail(error));
    }
}

function* getPlayerSelfExclusionAgreementSaga({ payload }) {
    try {
        const response = yield call(getPlayerSelfExclusionAgreementApi, payload);

        yield put(getPlayerSelfExclusionAgreementSuccess(response.data));
    } catch (error) {
        yield put(getPlayerSelfExclusionAgreementFail(error));
    }
}

function* getPlayerAccountRecoveryAgreementSaga({ payload }) {
    try {
        const response = yield call(getPlayerAccountRecoveryAgreementApi, payload);

        yield put(getPlayerAccountRecoveryAgreementSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAccountRecoveryAgreementFail(error));
    }
}

function* updatePlayerVerificationSaga({ payload }) {
    try {
        const response = yield call(updatePlayerVerificationApi, payload);

        yield put(
            updatePlayerVerificationSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updatePlayerVerificationFail(error));
    }
}

function* fetchPlayerVerificationHistoryListSaga({ payload }) {
    try {
        const response = yield call(
            getPlayerVerificationHistoryListApi,
            payload
        );

        yield put(
            getPlayerVerificationHistoryListSuccess({
                result: response.data.result,
                prevHistoryList: payload.prevHistoryList
            })
        );
    } catch (error) {
        yield put(getPlayerVerificationHistoryListFail(error));
    }
}

function* verificationSaga() {
    yield takeEvery(GET_PLAYER_VERIFICATION, fetchPlayerVerificationSaga);
    yield takeEvery(GET_PLAYER_SOF_AGREEMENT, getPlayerSofAgreementSaga);
    yield takeEvery(GET_PLAYER_SELF_EXCLUSION_AGREEMENT, getPlayerSelfExclusionAgreementSaga);
    yield takeEvery(GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT, getPlayerAccountRecoveryAgreementSaga);
    yield takeEvery(
        UPDATE_PLAYER_VERIFICATION,
        updatePlayerVerificationSaga
    );
    yield takeEvery(
        GET_PLAYER_VERIFICATION_HISTORY_LIST,
        fetchPlayerVerificationHistoryListSaga
    );
}

export default verificationSaga;
