import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import "./agent-view.scss"

import AgentView from "./AgentView"
import MetaTags from "react-meta-tags"
import i18n from "../../i18n"

class AgentViewPage extends Component {
    render() {
        return (
            <div className="page-content agent-view-page">
                <MetaTags>
                    <title>{i18n.t('agent.agentView')} - {process.env.REACT_APP_APP_NAME}</title>
                </MetaTags>

                <Container fluid>
                    <AgentView />
                </Container>
            </div>
        );
    }
}

export default withRouter(AgentViewPage);
