export function trimToNull(str) {
    try {
        if (['boolean', 'number'].includes(typeof str)) {
            return str;
        }
        if (str?.target?.value) {
            str = str?.target?.value;
        }
        return (str && str.trim()) || '';
    } catch (e) {
        return '';
    }
}
