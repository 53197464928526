export const GET_AFFILIATE_LIST = 'GET_AFFILIATE_LIST';
export const GET_AFFILIATE_LIST_SUCCESS = 'GET_AFFILIATE_LIST_SUCCESS';
export const GET_AFFILIATE_LIST_FAIL = 'GET_AFFILIATE_LIST_FAIL';

export const ADD_AFFILIATE_AT_LIST = 'ADD_AFFILIATE_AT_LIST';
export const ADD_AFFILIATE_AT_LIST_SUCCESS = 'ADD_AFFILIATE_AT_LIST_SUCCESS';
export const ADD_AFFILIATE_AT_LIST_ERROR = 'ADD_AFFILIATE_AT_LIST_ERROR';

export const GET_AFFILIATE = 'GET_AFFILIATE';
export const GET_AFFILIATE_SUCCESS = 'GET_AFFILIATE_SUCCESS';

export const CHANGE_AFFILIATE = 'CHANGE_AFFILIATE';
export const CHANGE_AFFILIATE_SUCCESS = 'CHANGE_AFFILIATE_SUCCESS';

export const REMOVE_AFFILIATE = 'REMOVE_AFFILIATE';
export const REMOVE_AFFILIATE_SUCCESS = 'REMOVE_AFFILIATE_SUCCESS';

export const SET_EDIT = 'SET_EDIT';

export const AFFILIATE_ERROR = 'AFFILIATE_ERROR';
export const CANCEL_ERRORS = 'CANCEL_ERRORS';

export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';

export const DOWNLOAD_AFFILIATE_LIST = 'DOWNLOAD_AFFILIATE_LIST';
export const DOWNLOAD_AFFILIATE_LIST_SUCCESS = 'DOWNLOAD_AFFILIATE_LIST_SUCCESS';
export const DOWNLOAD_AFFILIATE_LIST_FAIL = 'DOWNLOAD_AFFILIATE_LIST_FAIL';
