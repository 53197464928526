import * as React from 'react';
import TextField from '@mui/material/TextField';
import {useCallback, useContext} from 'react';
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import calendar from "../../assets/images/layout/calendar.svg";
import {LanguageContext} from "../../context/languageContext";

export default function ADateTimeField({
                                       label,
                                       value,
                                       helperText,
                                       handleChange,
                                       disabled = false,
                                       fulWidth,
                                       error,
                                       style,
                                       fieldClassName,
                                       isBirthDate
                                   }) {
    const currentStyle = {
        style: {
            ...(disabled && { color: '#20262d' }),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            background: 'none',
            ...(style ? style : {})
        }
    };

    const DialogProps = {
        style: {
            inset: 'auto !important'
        }
    };

    const { language } = useContext(LanguageContext);

    const currentDate = new Date();

    const onChange = useCallback(
        date => {
            let newDate = null;
            if (typeof date === 'function') {
                const dateData = date();
                const dateKeys = Object.keys(dateData);
                if (dateKeys.length > 0) {
                    newDate = dateData[dateKeys[0]];
                }
            } else {
                newDate = date;
            }

            try {
                handleChange(newDate ? new Date(newDate).toISOString() : null);
            } catch (e) {
                handleChange(null);
            }
        },
        [handleChange]
    );

    const datePickerFields = {
        views: ['day', 'month', 'year'],
        value: value || null,
        label: (<div>{label}</div>),
        InputProps: {
            ...currentStyle,
            readOnly: true
        },
        mask: "__.__",
        inputFormat: "hh.mm",
        disabled,
        onChange,
        DialogProps,
        renderInput: params => (
            <TextField
                {...params}
                error={error}
                variant="filled"
                helperText={helperText}
                style={{
                    width: fulWidth
                        ? '100%'
                        : currentStyle.width
                            ? currentStyle.width
                            : '200px'
                }}
            />
        ),
    };

    return (
        <div
            className={`fieldContainer dateContainer dateTimeContainer ${
                disabled ? 'disabledField' : ''
            }`}
        >
            <>
                <label
                    className="a-date-field-label"
                >{label}</label>
                <div className='date-picker-wrapper a-date-field'>
                    <DateTimePicker
                        className={`${fieldClassName} app-date-picker a-date-field`}
                        name={label}
                        clearIcon={null}
                        calendarIcon={<img src={calendar} alt="calendarIcon" width="16" />}
                        onChange={onChange}
                        format="dd.MM.yyyy h:mm a"
                        isClockOpen={false}
                        disableClock={true}
                        locale={language}
                        value={value ? value: null}
                        disabled={disabled}
                        isBirthDate={isBirthDate}
                        maxDate={currentDate}
                    />
                </div>
            </>
        </div>
    );
}
