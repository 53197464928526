import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/inputs/common/SelectInput';
import i18n from '../../../i18n';
import { AMOUNT_CONDITION_TYPE } from './attributionConstants';

function AmountConditionSelect({ onChange, value, ...props }) {
    const options = useMemo(
        () =>
            Object.keys(AMOUNT_CONDITION_TYPE).map(item => ({
                label: i18n.t(`attributions.constants.${item}`),
                value: item
            })),
        []
    );

    return (
        <SelectInput
            {...props}
            onChange={onChange}
            value={value}
            options={options}
        />
    );
}

AmountConditionSelect.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(AmountConditionSelect);
