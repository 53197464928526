import {
    SAVE_FILTER,
    FILTERS_ERROR,
    SELECT_FILTER,
    GET_FILTERS_LIST,
    ADD_CURRENT_FILTER,
    SAVE_FILTER_SUCCESS,
    CLEAR_CURRENT_FILTER,
    EDIT_CURRENT_FILTER,
    REMOVE_CURRENT_FILTER,
    REMOVE_FROM_FILTER_LIST,
    GET_FILTERS_LIST_SUCCESS,
    RESET_FILTERS_ALERTS_ERRORS,
    REMOVE_CURRENT_FILTERS,
} from './actionTypes';

export const getFiltersList = query => ({
    type: GET_FILTERS_LIST,
    payload: query
});

export const getFiltersListSuccess = response => ({
    type: GET_FILTERS_LIST_SUCCESS,
    payload: response
});

export const removeFromList = id => ({
    type: REMOVE_FROM_FILTER_LIST,
    payload: id
});

export const saveFilter = data => ({
    type: SAVE_FILTER,
    payload: data
});

export const saveFilterSuccess = response => ({
    type: SAVE_FILTER_SUCCESS,
    payload: response
});

export const getFiltersError = error => ({
    type: FILTERS_ERROR,
    payload: error
});

export const addCurrentFilter = newFilter => ({
    type: ADD_CURRENT_FILTER,
    payload: newFilter
});

export const clearCurrentFilter = (defaultFilter) => ({
    type: CLEAR_CURRENT_FILTER,
    payload: defaultFilter,
});

export const editCurrentFilter = (idx, values) => ({
    type: EDIT_CURRENT_FILTER,
    payload: { idx, values }
});

export const removeCurrentFilter = idx => ({
    type: REMOVE_CURRENT_FILTER,
    payload: idx
});

export const removeCurrentFilters = (key, values) => ({
    type: REMOVE_CURRENT_FILTERS,
    payload: {key, values}
});

export const selectFilter = filterContent => ({
    type: SELECT_FILTER,
    payload: filterContent
});

export const resetFiltersAlertsErrors = filterContent => ({
    type: RESET_FILTERS_ALERTS_ERRORS,
    payload: filterContent
});
