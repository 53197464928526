import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioButtonsGroup({ name, groupButtons, handleChange, value, classNameWrapperGroup }) {

    return (
        <FormControl>
            <RadioGroup
                value={value}
                onChange={handleChange}
                name={`radio-buttons-group ${name}`}
                className={classNameWrapperGroup}
            >
                {
                    groupButtons?.map(({ value, label }) => {
                        return <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={label}
                            labelPlacement="end"
                        />
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}
