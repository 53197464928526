import React, { useCallback } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import getSymbolFromCurrency from 'currency-symbol-map';
import Formatter from "../../utils/Formatter";
import { PlainJsonEditor } from 'react-plain-json-editor';

function ATextField({
                        id,
                        fullWidth = true,
                        label = '',
                        secureFieldLabel = '',
                        value = '',
                        error,
                        isJson = false,
                        isArray = false,
                        currency,
                        helperText = '',
                        handleChange,
                        handleBlur = () => {
                        },
                        disabled = false,
                        placeholder,
                        multiline,
                        rows,
                        type,
                        style = {},
                        inputProps = {},
                        fieldClassName,
                        max,
                        min,
                    }) {
    if (isJson) {
        multiline = true;
    }
    const currentStyle = {
        style: {
            ...(disabled && {color: '#20262d', background: 'none'}),
            fontFamily: `"Montserrat", sans-serif`,
            fontSize: '14px',
            width: fullWidth ? '100%' : '195px',
            padding: multiline ? '28px 0 0' : '1px 0 0',
            ...style,
        },
    };

    const [error_, setError] = React.useState(undefined);

    const handleChange_ = (type, value_) => useCallback(
        (e) => {
            if (disabled) return;

            const finalVal = e?.target?.value;
            let inputValue = type === 'number' ? finalVal.replace(/\D\/,\./g, '') : finalVal;

            if (type === 'number') {
                inputValue = inputValue.replace(/-/g, '');
            }

            if (isJson) {
                const valid = Formatter.isValidJSON(inputValue);
                if (!valid) {
                    inputValue = value_;
                    setError("Invalid JSON");
                }
                inputValue = Formatter.parseJSON(inputValue);
            }

            if (isArray) {
                const valid = Formatter.isValidJSONArray(inputValue);
                if (!valid) {
                    inputValue = value_;
                    setError("Invalid Array");
                }
                inputValue = Formatter.parseJSON(inputValue);
            }

            if ((max && inputValue > max) || (min && inputValue < min)) return;
            setError(undefined);
            handleChange(inputValue);
        },
        [handleChange, min, max, type, disabled]
    );

    const handleKeyDown = (event) => {
        if (
            type === 'number' && ['+', '-'].includes(event.key) ||
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
        }
    };

    const handleBlur_ = () => {
        handleBlur();
    }

    const value_ = value;

    return (
        <>
            {isJson || isArray && (
                <div style={{display: 'inline-grid', height: '240px'}}>
                    <span>{label}</span>
                    <PlainJsonEditor
                        label={label}
                        value={value_}
                        onChange={handleChange_('onChange', value_)}
                        onSubmit={handleChange_('onChange', value_)}
                        styles={{
                            textarea: {
                                maxHeight: '200px',
                                borderRadius: '14px',
                                width: '75%',
                                backgroundColor: 'rgb(255 255 255)',
                                color: 'rgb(51 51 51)',
                                padding: 12,
                                fontSize: '1.2rem',
                                lineHeight: '1.5rem',
                                fontFamily: 'monospace'
                            }
                        }}
                    />
                </div>
            )}
            {!isJson && !isArray && (
                <TextField
                    id={id}
                    type={type}
                    label={label}
                    secureFieldLabel={secureFieldLabel}
                    value={value_}
                    variant="filled"
                    disabled={disabled}
                    className={`${fieldClassName} fieldContainer`}
                    onChange={handleChange_(type, value_)}
                    onBlur={handleBlur_}
                    fullWidth={fullWidth}
                    style={{
                        ...(disabled && {background: 'none'}),
                        ...(currentStyle.style.width && {
                            width: currentStyle.style.width,
                            minWidth: currentStyle.style.width,
                        }),
                    }}
                    multiline={multiline}
                    rows={rows}
                    placeholder={placeholder}
                    InputProps={{
                        ...currentStyle.style,
                        ...inputProps,
                        disableUnderline: disabled,
                        autoComplete: 'new-password',
                        startAdornment: (
                            <InputAdornment
                                position="start">{currency && getSymbolFromCurrency(currency)}
                            </InputAdornment>
                        ),
                        onKeyDown: handleKeyDown,
                    }}
                    error={error_ || error}
                    helperText={helperText}
                />
            )}
        </>
    );
}

export default React.memo(ATextField);
