import {
    GET_PLAYER_JOINT,
    GET_PLAYER_JOINT_SUCCESS,
    GET_PLAYER_JOINT_FAIL,
    UPDATE_PLAYER_JOINT,
    UPDATE_PLAYER_JOINT_SUCCESS,
    UPDATE_PLAYER_JOINT_FAIL,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_STATUS_SUCCESS,
    UPDATE_PLAYER_STATUS_FAIL,
    SET_EDIT_PLAYER_JOINT,
    RESET_PLAYER_ERRORS,
    RESET_PLAYER_STATE
} from './actionTypes';

export const getPlayerJoint = payload => ({
    type: GET_PLAYER_JOINT,
    payload
});

export const getPlayerJointSuccess = payload => ({
    type: GET_PLAYER_JOINT_SUCCESS,
    payload
});

export const getPlayerJointFail = error => ({
    type: GET_PLAYER_JOINT_FAIL,
    payload: error
});

export const setPlayerJointEdit = payload => ({
    type: SET_EDIT_PLAYER_JOINT,
    payload
});

export const updatePlayerJoint = payload => ({
    type: UPDATE_PLAYER_JOINT,
    payload
});

export const updatePlayerJointSuccess = payload => ({
    type: UPDATE_PLAYER_JOINT_SUCCESS,
    payload
});

export const updatePlayerJointFail = error => ({
    type: UPDATE_PLAYER_JOINT_FAIL,
    payload: error
});

export const updatePlayerStatus = payload => ({
    type: UPDATE_PLAYER_STATUS,
    payload
});

export const updatePlayerStatusSuccess = payload => ({
    type: UPDATE_PLAYER_STATUS_SUCCESS,
    payload
});

export const updatePlayerStatusFail = error => ({
    type: UPDATE_PLAYER_STATUS_FAIL,
    payload: error
});

export const resetPlayerErrors = payload => ({
    type: RESET_PLAYER_ERRORS,
    payload
});

export const resetPlayerState = payload => ({
    type: RESET_PLAYER_STATE,
    payload
});
