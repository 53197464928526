import {
    SET_EDIT,
    GET_NOTE,
    CANCEL_ERRORS,
    CHANGE_STATUS,
    NOTE_ERROR,
    REMOVE_NOTE,
    CHANGE_NOTE,
    GET_NOTE_LIST,
    ADD_NOTE,
    CHANGE_STATUS_SUCCESS,
    GET_NOTE_SUCCESS,
    CHANGE_NOTE_SUCCESS,
    REMOVE_NOTE_SUCCESS,
    REMOVE_NOTE_FAIL,
    GET_NOTE_LIST_SUCCESS,
    ADD_NOTE_SUCCESS,
    GET_NOTE_LIST_FAIL,
    SEARCH_AT_NOTE_LIST
} from './actionTypes';

// NOTES LIST
export const getNotesList = payload => ({
    type: GET_NOTE_LIST,
    payload
});

export const getNotesListSuccess = response => ({
    type: GET_NOTE_LIST_SUCCESS,
    payload: response
});

export const getNotesListFail = error => ({
    type: GET_NOTE_LIST_FAIL,
    payload: error
});

export const addNoteAtList = body => ({
    type: ADD_NOTE,
    payload: { body }
});

export const addNoteAtListSuccess = response => ({
    type: ADD_NOTE_SUCCESS,
    payload: response
});

// NOTE
export const getNote = id => ({
    type: GET_NOTE,
    payload: { id }
});

export const getNoteSuccess = response => ({
    type: GET_NOTE_SUCCESS,
    payload: response
});

export const changeNote = body => ({
    type: CHANGE_NOTE,
    payload: body
});

export const changeNoteSuccess = response => ({
    type: CHANGE_NOTE_SUCCESS,
    payload: response
});

export const removeNote = id => ({
    type: REMOVE_NOTE,
    payload: { id }
});

export const removeNoteSuccess = response => ({
    type: REMOVE_NOTE_SUCCESS,
    payload: response
});

export const removeNoteFail = error => ({
    type: REMOVE_NOTE_FAIL,
    payload: error
});

export const noteError = error => ({
    type: NOTE_ERROR,
    payload: error
});

export const setEdit = value => ({
    type: SET_EDIT,
    payload: value
});

export const cancelErrors = () => ({
    type: CANCEL_ERRORS
});

export const changeNoteStatus = body => ({
    type: CHANGE_STATUS,
    payload: body
});

export const changeNoteStatusSuccess = payload => ({
    type: CHANGE_STATUS_SUCCESS,
    payload
});

export const searchAtNoteList = searchText => ({
    type: SEARCH_AT_NOTE_LIST,
    payload: searchText
});
