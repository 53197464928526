import { put, takeEvery } from '@redux-saga/core/effects';

import { CHANGE_BRAND, GET_BRANDS } from './actionTypes';
import { fetchBrandError, getBrandsSuccess } from './actions';
import { getInfoSaga } from "../../info/saga";

import i18n from '../../../i18n';

function* fetchBrands() {
    try {
        const authUser = localStorage.getItem('authUser');

        if (authUser) {
            const sites = JSON.parse(authUser)?.sites;

            if (!!sites?.length) {
                yield put(getBrandsSuccess(sites));
            } else {
                yield put(
                    fetchBrandError({ message: i18n.t('brand.sitesIsEmpty') })
                );
            }
        } else {
            yield put(
                fetchBrandError({ message: i18n.t('brand.userIsEmpty') })
            );
        }
    } catch (error) {
        yield put(fetchBrandError({ message: error }));
    }
}

function* brandSaga() {
    yield takeEvery(GET_BRANDS, fetchBrands);
    yield takeEvery(CHANGE_BRAND, getInfoSaga);
}

export default brandSaga;
