import React, {memo, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import CashierSideBar from "./components/CashierSideBar/CashierSideBar";
import './styles.scss';
import {
    getCashierList,
    getSupplierById,
    getSupplierList,
    removeCashierMethodById,
    removeCashierSupplierById
} from "../../store/cashier/actions";
import Loader from "../../common/components/Loader";
import PaymentMethodSettings from "./components/CashierPayments/PaymentMethodSettings";
import PaymentSuppliersSettings from "./components/CashierPayments/PaymentSuppliersSettings";
import useAlertService from "../../hooks/useAlertService";
import {SITE_PERMISSION} from "../../common/constants/common";
import { useTranslation } from "react-i18next";
import {useHistory, useLocation, useParams} from "react-router-dom";

export const addFieldStates = {
    READY: 'READY',
    PENDING: 'PENDING'
};

export const newPaymentMethodID = 'NEWPAYMENT';

const cashierMethods = {
    deposit: 'DEPOSIT',
    withdraw: 'WITHDRAW',
    suppliers: 'SUPPLIERS'
}

const Cashier = () => {
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [localCashierList, setLocalCashierList] = useState([]);
    const [addFieldState, setAddFieldState] = useState(addFieldStates.READY);
    const [supplierSettings, setSupplierSettings] = useState([]);
    const params = useParams();
    const history = useHistory();
    const method = cashierMethods[params?.id];
    const previousSelectedMethodRef = useRef(null);
    const previousSupplierSettingsRef = useRef([]);

    const [cashierType, setCashierType] = useState(null);
    const {
        cashierList,
        cashierSuppliers,
        lastCreatedMethod,
        isLoading,
        isLoadingCashierList,
        successMessage,
    } = useSelector(state => state.Cashier);
    const { t } = useTranslation();
    const location = useLocation();

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];
    const canEditCashier = agentPermissions.includes(SITE_PERMISSION.Cashier__Edit);
    const isNotSavedSupplier = supplierSettings.some(supp => !supp.siteId);

    const initialSupplier = {
        type: '',
        siteId: '',
        cashierMethodId: selectedMethod?.id,
        supplierStatus: false,
        integration: '',
        supplierName: 'New Supplier',
        currency: ['EUR'],
        priority: undefined,
        ftd: undefined,
        chargeback: undefined,
        kyc: undefined,
        vipLevel: undefined,
        integrationSettings: {},
        advanceSegment: {
            suggestAmount: {
                min: '',
                max: '',
                defAmountCashier: '',
                suggestAmount: {}
            }
        },
    }

    useEffect(() => {
        if (!method) {
            history.push('/cashier/deposit');
        } else {
            setCashierType(method);
            dispatch(getCashierList({method}));
            dispatch(getSupplierList());
            setAddFieldState(addFieldStates.READY);
            setSupplierSettings([]);
        }

    }, [ method ]);

    useEffect(() => {
        if (cashierList.length > 0) {
            setSelectedMethod(cashierList[0]);
        }
    }, [cashierList]);

    useEffect(() => {
        setSupplierSettings(cashierSuppliers);
    }, [cashierSuppliers, dispatch]);

    useEffect(() => {
        if (selectedMethod?.type === cashierType && selectedMethod?.id) {
            if (previousSelectedMethodRef.current !== selectedMethod.id) {
                dispatch(getSupplierById(selectedMethod.id));
                previousSelectedMethodRef.current = selectedMethod.id;
            }
        }
    }, [selectedMethod?.id, cashierType]);

    useEffect(() => {
        if (lastCreatedMethod) {
            setSelectedMethod(lastCreatedMethod);
        }
    }, [lastCreatedMethod])

    useEffect(() => {
        if (!selectedMethod && cashierList.length > 0) {
            setSelectedMethod(cashierList[0]);
        } else if (selectedMethod) {
            const findMethod = cashierList.find(cashier => cashier.id === selectedMethod.id);
            setSelectedMethod(prev => findMethod || prev);
        }
    }, [cashierList, selectedMethod]);

    useEffect(() => {
        setLocalCashierList(cashierList);
    }, [cashierList]);

    useEffect(() => {
        if (successMessage) {
            alertService.showSuccess(t(`${successMessage}`));
            dispatch(getCashierList({method}));
        }
    }, [successMessage])

    const handleMethodSelect = (methodId) => {
        const selected = cashierList.find(cashier => cashier.id === methodId);
        setSelectedMethod(selected);
    };

    const addNewCashier = () => {
        const newMethod = {
            id: newPaymentMethodID,
            name: "New Method",
        };
        previousSupplierSettingsRef.current = supplierSettings;
        setLocalCashierList(prev => [...prev, newMethod]);
        setAddFieldState(addFieldStates.PENDING);
        setSelectedMethod(newMethod);
        setSupplierSettings([]);
    };

    const deleteCashier = async (fieldId) => {
        // To remove the method from the list
        let updatedMethods;
        if (fieldId === 'NEWPAYMENT') {
            updatedMethods = localCashierList.filter(method => method.id !== fieldId);
            setSupplierSettings(previousSupplierSettingsRef.current);
            setSelectedMethod(cashierList[0]);
        } else {
            await handleMethodRemove(fieldId);
            updatedMethods = localCashierList.filter(method => method.id !== fieldId);
        }
        setLocalCashierList([...updatedMethods]);
        setAddFieldState(addFieldStates.READY);
    };

    const addNewSupplierBlock = () => {
        setSupplierSettings([...supplierSettings, initialSupplier]);
    };

    const removeSupplierBlock = (index) => {
        if (!index) {
            const updatedSettings = supplierSettings.filter(obj => obj.hasOwnProperty('oid'));
            setSupplierSettings(updatedSettings);
        } else {
            handleSupplierRemove(index);
        }
    };

    const handleMethodRemove = (methodId) => {
        dispatch(removeCashierMethodById(methodId));
        setSelectedMethod(cashierList[0]);
    }

    const handleSupplierRemove = (supplierId) => {
        dispatch(removeCashierSupplierById({
            supplierId,
            methodId: selectedMethod.id
        }));
    }

    const renderCashier = () => {
        if(isLoading) {
            return <Loader size={'lg'} />
        }

        return (
            (
                <div className='cashier-content'>
                    {
                        selectedMethod &&
                        <PaymentMethodSettings
                            addNewSupplier={addNewSupplierBlock}
                            setAddFieldState={setAddFieldState}
                            selectedMethod={selectedMethod}
                            isLoading={isLoading}
                            canEditCashier={canEditCashier}
                            removeMethod={deleteCashier}
                            isNotSavedSupplier={isNotSavedSupplier}
                        />
                    }
                    <div className='supplier-wrapper'>
                        {supplierSettings?.map(supplier => (
                            <PaymentSuppliersSettings
                                methodId={selectedMethod?.id}
                                key={supplier.id}
                                currSupplier={supplier}
                                deleteSupplier={removeSupplierBlock}
                                canEditCashier={canEditCashier}
                                alertService={alertService}
                            />
                        ))}
                    </div>
                </div>
            )
        );
    }

    return (
            <div className='page-content cashier-page'>
                <>
                    <CashierSideBar
                        selectedMethod={selectedMethod}
                        onMethodSelect={handleMethodSelect}
                        cashierList={localCashierList}
                        addNewCashier={addNewCashier}
                        deleteCashier={deleteCashier}
                        addFieldState={addFieldState}
                        setAddFieldState={setAddFieldState}
                        isLoading={isLoadingCashierList}
                        canEditCashier={canEditCashier}
                    />
                    {renderCashier()}
                </>
            </div>
    )
}

export default memo(Cashier);