import { useCallback, useState } from 'react';

export default function useToggleModal(initialState) {
    const [isShow, setShow] = useState(initialState);

    const handleShow = useCallback(() => setShow(true), []);
    const handleHide = useCallback(() => setShow(false), []);

    return [isShow, handleShow, handleHide];
}
