import {
    GET_PENDING_WITHDRAWAL,
    GET_PENDING_WITHDRAWAL_SUCCESS,
    GET_PENDING_WITHDRAWAL_FAIL,
    APPROVE_PENDING_WITHDRAWAL,
    APPROVE_PENDING_WITHDRAWAL_SUCCESS,
    APPROVE_PENDING_WITHDRAWAL_FAIL,
    DECLINE_PENDING_WITHDRAWAL,
    DECLINE_PENDING_WITHDRAWAL_SUCCESS,
    DECLINE_PENDING_WITHDRAWAL_FAIL,
    RESET_PENDING_WITHDRAWAL_ALERTS_ERRORS
} from './actionTypes';
import i18n from '../../../i18n';

const INIT_STATE = {
    isLoading: false,
    error: null,
    withdrawal: null,
    successAlert: ''
};

const PendingWithdrawalDetailed = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PENDING_WITHDRAWAL:
            return { ...state, isLoading: true, error: null, withdrawal: null };

        case GET_PENDING_WITHDRAWAL_SUCCESS:
            return { ...state, withdrawal: action.payload, isLoading: false };

        case GET_PENDING_WITHDRAWAL_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case APPROVE_PENDING_WITHDRAWAL:
            return { ...state, isLoading: true, error: null };

        case APPROVE_PENDING_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successAlert: i18n.t('crm.alerts.withdrawalApproved')
            };

        case APPROVE_PENDING_WITHDRAWAL_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case DECLINE_PENDING_WITHDRAWAL:
            return { ...state, isLoading: true, error: null };

        case DECLINE_PENDING_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successAlert: i18n.t('crm.alerts.withdrawalDeclined')
            };

        case DECLINE_PENDING_WITHDRAWAL_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        case RESET_PENDING_WITHDRAWAL_ALERTS_ERRORS:
            return { ...state, error: null, successAlert: '' };

        default:
            return state;
    }
};

export default PendingWithdrawalDetailed;
