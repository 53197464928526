import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import close from "../../../assets/images/common/close 1.svg";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function WrongGamesModal({
    onHide,
    onConfirm,
    wrongGames= [],
    wrongLocalGames= [],
}) {
    const games = [...(wrongGames === true ? [] : wrongGames), ...wrongLocalGames];

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="game-update-modal two-columns-popup"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('crm.wrongGames')}
                </h5>
                <button className="close" onClick={onHide} />
            </div>

            <ModalBody>
                {games.slice(0, 10).map((game, index) => {
                    return <>
                        {game.friendlyName || game.Game}
                        <br/>
                    </>
                })}
                {games.length > 10 && '...'}
            </ModalBody>
            <ModalFooter
                style={{
                    display: 'flex',
                }}
            >
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onHide}
                    style={{
                        width: '30%',
                    }}
                >
                    <img src={close} alt="" />
                    {i18n.t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={onConfirm}
                    style={{
                        width: '30%',
                        marginLeft: 20,
                    }}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('crm.load.more')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

WrongGamesModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    wrongGames: PropTypes.array,
    wrongLocalGames: PropTypes.array,
};

WrongGamesModal.defaultProps = {
    wrongGames: [],
    wrongLocalGames: [],
}

export default React.memo(WrongGamesModal);
