import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import _ from 'lodash';
import i18n from '../../../i18n';
import Loader from '../../../common/components/Loader';
import ButtonsPanel from '../../../components/Buttons/ButtonsPanel';
import { useDispatch } from 'react-redux';
import { setEditBrandSettings } from '../../../store/brand/brandPage/actions';
import CustomPromptModal from '../../../common/prompt/CustomPromptModal';
import { RouterPrompt } from '../../../common/prompt/RouterPrompt';
import { getSync, patchSync } from '../../../helpers/api_helper';
import GenericForm from './GenericForm';
import Formatter from "../../../utils/Formatter";
import useAlertService from "../../../hooks/useAlertService";
import { FIELD_TYPE } from "../../../common/constants/common";
import CustomModal from "../../../common/components/CustomModal";
import SwipeAnimation from 'common/components/SwipeAnimation/SwipeAnimation';

const prepareDefaultValues = (object) => {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === 'object') {
            prepareDefaultValues(object[key]);
        }
        else if (Array.isArray(object) && typeof key !== 'number') {
            // Change only for array elements with key which have type number
            // or object fields
        }
        else if (typeof object[key] === 'number') {
            object[key] = 0;
        }
        else if (typeof object[key] === 'boolean') {
            object[key] = false;
        }
        else {
            object[key] = `Value_${key}`;
        }
    });
};

const prepareFormData = (respData) => {
    let formData = respData;

    // Get data for mirror fields
    const promoSettings = formData?.settings?.promoSettings;
    const communication = formData?.settings?.communication;

    // Remove original settings fields
    delete formData?.settings?.promoSettings;
    delete formData?.settings?.communication;

    // Add mirror fields
    formData = {
        ...formData,
        promoSettings,
        communication,
    };

    const formState = _.cloneDeep(formData);
    const originalFormState = _.cloneDeep(formData);

    return { formState, originalFormState };
};

const prepareNewBrandData = (formState) => {
    const newBrandData = _.cloneDeep(formState);

    // Get data from mirror fields
    const { promoSettings, communication } = newBrandData;

    if (!Array.isArray(newBrandData.blockedCountries)) {
        newBrandData.blockedCountries = [newBrandData.blockedCountries];
    } else {
        newBrandData.blockedCountries = newBrandData.blockedCountries.map(value => String(value));
    }

    // Remove mirror fields
    delete newBrandData.promoSettings;
    delete newBrandData.communication;

    // Prepare data to be saved
    newBrandData.settings = {
        ...newBrandData.settings,
        communication,
        promoSettings,
    };

    return newBrandData;
};

function SiteDetailedCard({ data, isEdit, isLoading }) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        activeTab: '',
        toSetActiveTab: '',
    });
    const [saveChangesModalVisibility, setSaveChangesModalVisibility] = useState(false);
    const [updateFormStateModalVisibility, setUpdateFormStateModalVisibility] = useState(false);
    const [warningModalVisibility, setWarningModalVisibility] = useState(false);
    const fileInputRef = useRef();

    const alertService = useAlertService();

    const handleChangeTab = useCallback(
        (tab) => {
            if (isEdit) {
                setState((prevState) => ({ ...prevState, toSetActiveTab: tab }));
            } else {
                setState((prevState) => ({ ...prevState, activeTab: tab }));
            }
        },
        [state, isEdit]
    );

    const handleResetEdit = () => {
        dispatch(setEditBrandSettings(false));
    };

    const showEditButtons = () => {
        dispatch(setEditBrandSettings(true));
    };

    const handleConfirmPrompt = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            toSetActiveTab: '',
            activeTab: prevState.toSetActiveTab,
        }));
        handleResetEdit();
    }, [state]);

    const handleCancelPrompt = useCallback(() => {
        setState((prevState) => ({ ...prevState, toSetActiveTab: '' }));
    }, [state]);

    const [formState, setFormState] = useState(undefined);
    const [newSettings, setNewSettings] = useState(undefined);
    const [changedField, setChangedField] = useState('');
    const [originalFormState, setOriginalFormState] = useState(undefined);
    const [formStateChanges, setFormStateChanges] = useState(undefined);
    const [tabs, setTabs] = useState(undefined);

    const setEditedForm = (key, value) => {
        showEditButtons();
        setFormState((prevState) => {
            if (!prevState) {
                return value;
            } else {
                return {...Formatter.mergeWithPriority(value, prevState)};
            }
        });
    };

    async function fetchData() {
        const url = '/site/settings';
        try {
            const result = await getSync(url, {});
            const { formState, originalFormState } = prepareFormData(result?.data);

            setFormState(formState);
            setOriginalFormState(originalFormState);
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
    }

    const handleSave = useCallback(async () => {
        setSaveChangesModalVisibility(false);

        // Prepare changed data
        const data = prepareNewBrandData(formState);
        const url = `/site/advance/settings`;

        if (typeof data.domains === 'object' && !Array.isArray(data.domains)) {
            data.domains = Object.values(data.domains);
        }

        // validate promocodes names
        if (data?.settings?.promoSettings?.promoCodeList?.some(name => name === '')) {
            return alertService.showError('Enter a value in the field')
        }

        // Send changed data to the server
        try {
            const _result = await patchSync(alertService, url, data);
            if (_result) {
                // Hide buttons panel
                handleResetEdit();
                alertService.showSuccess('Brand settings updated');
                await fetchData();
            }
        } catch (e) {
            alertService.showError('Cannot fetch last messages!');
        }

        // Remove changes from changes object
        setFormStateChanges(null);
    }, [formStateChanges, formState, originalFormState]);

    const handleCancel = useCallback(() => {
        // Hide buttons panel
        handleResetEdit();

        setFormState(_.cloneDeep(originalFormState));
        // Remove changes from changes object
        setFormStateChanges(null);
    }, [formStateChanges, originalFormState]);

    const handleRemoveField = useCallback((chaine) => {
        showEditButtons();
        if (chaine.length > 0) {
            setFormState((prevState) => {
                let currField = prevState;

                // Get parent field
                for (let i = 0; i < chaine.length - 1; i++) {
                    const fieldName = chaine[i];
                    currField = currField[fieldName];

                    if (!currField) {
                        break;
                    }
                }
                if (Array.isArray(currField)) {
                    const index = +chaine[chaine.length - 1];
                    currField.splice(index, 1);
                }
                else if (currField && typeof currField === 'object') {
                    delete currField[chaine[chaine.length - 1]];
                }

                return _.cloneDeep(prevState);
            });
        }
    }, [formStateChanges, formState]);

    const handleAddArrayElem = useCallback((chaine, defaultValue = 'New value') => {
        showEditButtons();

        setFormState((prevState) => {
            let currField = prevState;
            for (let i = 0; i < chaine.length; i++) {
                const fieldName = chaine[i];
                currField = currField[fieldName];

                if (!currField) {
                    break;
                }
            }

            if (Array.isArray(currField)) {
                let newValue;
                if (currField.length > 0) {
                    const elemType = typeof currField[0];
                    switch (elemType) {
                        case FIELD_TYPE.STRING:
                            newValue = '';
                            break;
                        case FIELD_TYPE.NUMBER:
                            newValue = 0;
                            break;
                        case FIELD_TYPE.BOOLEAN:
                            newValue = false;
                            break;
                        case FIELD_TYPE.OBJECT:
                            newValue = _.cloneDeep(currField[0]);
                            prepareDefaultValues(newValue);
                            break;
                        default:
                            newValue = defaultValue;
                            break;
                    }
                }
                else {
                    newValue = defaultValue;
                }

                if (chaine.includes('promoCodeList')) {
                    currField.unshift(newValue);
                } else {
                    currField.push(newValue);
                }

                return _.cloneDeep(prevState);
            }

            else {
                return prevState;
            }
        });
    }, [formState]);

    const handleChangeArrayElem = useCallback((chaine, newValue) => {
        showEditButtons();

        setFormState((prevState) => {
            let currField = prevState;
            for (let i = 0; i < chaine.length - 1; i++) {
                const fieldName = chaine[i];
                currField = currField[fieldName];

                if (fieldName.includes('promoCodeList')) {
                    newValue = String(newValue).slice(0, 50).replace(/^(.{50}).*$/, '$1');
                }

                if (!currField) {
                    break;
                }
            }

            if (Array.isArray(currField)) {
                const fieldName = chaine[chaine.length - 1];
                currField[fieldName] = newValue;
                return _.cloneDeep(prevState);
            }

            return prevState;
        });
    }, [formState]);

    const handleEditFieldName = useCallback((chaine, newName) => {
        showEditButtons();

        setFormState((prevState) => {
            let currField = prevState;

            for (let i = 0; i < chaine.length - 1; i++) {
                const fieldName = chaine[i];
                currField = currField[fieldName];

                if (!currField) {
                    break;
                }
            }

            const prevName = chaine[chaine.length - 1];
            currField[newName] = currField[prevName];
            delete currField[prevName];
            return _.cloneDeep(prevState);
        })
    }, [formState]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (formState) {
            const tabs = Object.keys(formState);
            setTabs(tabs);
            setState((prevState) => ({
                ...prevState,
                activeTab: state.activeTab || tabs[0] || '',
            }));
        }
    }, [formState]);

    const handleExportSettings = useCallback((changedField = '') => {
        // Prepare exported data
        const exportedData = changedField === ''
            ? {
                settings: formState.settings,
            }
            : {
                settings: {
                    [changedField]: formState.settings[changedField],
                }
            };
        const dataStr = 'data:application/json;charset=UTF-8,'
            + encodeURIComponent(JSON.stringify(exportedData, null, '\t'));

        // Save exported data to the file
        const defaultFileName = changedField === ''
            ? `${formState.name}_siteSettings.json`
            : `${formState.name}_siteSettings_${changedField}.json`;

        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute("download", defaultFileName);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }, [formState]);

    const handleImportSettings = () => {
        fileInputRef.current.click();
    };

    const handleExportSettingsField = (field) => {
        handleExportSettings(field);
    };

    const handleImportSettingsField = (field) => {
        setChangedField(field);
        handleImportSettings();
    };

    const handleReadFile = (file) => {
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const jsonString = e.target.result;

            try {
                const jsonData = JSON.parse(jsonString);

                if ('settings' in jsonData) {
                    setNewSettings(jsonData.settings);
                    setUpdateFormStateModalVisibility(true);
                } else {
                    alertService.showError('Selected file contains invalid JSON data')
                    setChangedField('');
                }
            } catch (err) {
                alertService.showError('Selected file contains invalid JSON string')
                setChangedField('');
            }
        };
        reader.readAsText(file);
    };

    const handleUpdateFormState = useCallback(() => {
        if (newSettings && typeof newSettings === 'object') {
            const settings = changedField === ''
                ? newSettings
                : {
                    ...formState.settings,
                    [changedField]: newSettings[changedField],
                }

            const newFormState = {
                ...formState,
                settings,
            };
            setFormState(newFormState);
            setNewSettings(null);
            showEditButtons();
            setUpdateFormStateModalVisibility(false);
            setChangedField('');
            setWarningModalVisibility(true);
        }
    }, [formState, newSettings, changedField]);

    const handleCancelUpdateFormState = () => {
        setNewSettings(null);
        setUpdateFormStateModalVisibility(false);
    };

    const handleSelectFile = () => {
        const file = fileInputRef.current && fileInputRef.current.files[0];
        handleReadFile(file);
        fileInputRef.current.value = '';
    };

    return (
        <Card className="site-detailed-card">
            <input
                className="hidden"
                ref={fileInputRef}
                onChange={handleSelectFile}
                type="file"
                accept=".json"
            />

            <CardBody>
                {tabs && (
                    <ul className="site-detailed-card__tabs">
                        {tabs.map((key) => (
                            <li
                                key={key}
                                className={`${
                                    state.activeTab === key ? 'active' : ''
                                }`}
                                onClick={() => handleChangeTab(key)}
                            >
                                {i18n.t(`admin.${key}`)}
                            </li>
                        ))}
                    </ul>
                )}

                <hr className="site-detailed-card__hr" />

                <SwipeAnimation className="brandSettings" />

                {isLoading && !tabs && <Loader size={'lg'}/>}
                {!isLoading &&
                    tabs &&
                    tabs.map((key) => (
                        <React.Fragment key={key}>
                            {state.activeTab === key && (
                                <GenericForm
                                    property={key}
                                    json={formState[key]}
                                    changes={formStateChanges}
                                    onInputChange={setEditedForm}
                                    onRemoveField={handleRemoveField}
                                    onChangeArrayElem={handleChangeArrayElem}
                                    onAddArrayElem={handleAddArrayElem}
                                    onEditFieldName={handleEditFieldName}
                                    onExportSettings={handleExportSettings}
                                    onImportSettings={handleImportSettings}
                                    onExportSettingsField={handleExportSettingsField}
                                    onImportSettingsField={handleImportSettingsField}
                                />
                            )}
                        </React.Fragment>
                    ))}
            </CardBody>

            {state.toSetActiveTab && (
                <CustomPromptModal
                    onConfirm={handleConfirmPrompt}
                    onCancel={handleCancelPrompt}
                />
            )}

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}

            {isEdit && (
                <ButtonsPanel
                    isEdit={isEdit}
                    onCancel={handleCancel}
                    onSave={() => setSaveChangesModalVisibility(true)}
                />
            )}
            {saveChangesModalVisibility &&
            <CustomModal
                titleText={i18n.t('content.saveChanges')}
                isOpen={setSaveChangesModalVisibility}
                onToggle={() => setSaveChangesModalVisibility(!saveChangesModalVisibility)}
                onClick={handleSave}
                onClickCancelButton={() => setSaveChangesModalVisibility(false)}
                btnText={'save'}
                cancelBtnText={'cancel'}
                withCancelButton={true}
                bodyRender={() => (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {i18n.t('content.sure.want.save.changes')}
                    </div>
                )}
            />
            }
            {updateFormStateModalVisibility &&
            <CustomModal
                titleText={i18n.t('content.loadChanges')}
                isOpen={setUpdateFormStateModalVisibility}
                onToggle={() => setUpdateFormStateModalVisibility(!updateFormStateModalVisibility)}
                onClick={handleUpdateFormState}
                onClickCancelButton={handleCancelUpdateFormState}
                btnText={'load'}
                cancelBtnText={'cancel'}
                withCancelButton={true}
                bodyRender={() => (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {i18n.t('content.sure.want.load.changes')}
                    </div>
                )}
            />
            }
            {warningModalVisibility &&
            <CustomModal
                titleText={i18n.t('content.warning.dataWasChanged')}
                isOpen={setWarningModalVisibility}
                onToggle={() => setWarningModalVisibility(!warningModalVisibility)}
                onClick={() => setWarningModalVisibility(false)}
                btnText={'ok'}
                bodyRender={() => (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {i18n.t('content.warning.checkChangedData.beforeSaving')}
                    </div>
                )}
            />
            }
        </Card>
    );
}

SiteDetailedCard.propTypes = {
    data: PropTypes.object.isRequired,
    isEdit: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
};

export default React.memo(SiteDetailedCard);