import React, {useMemo} from "react"
import PropTypes from "prop-types"
import MultiSelect from "../../../UI/multiSelect/MultiSelect";
import { SELECT_CATEGORIES } from "../../../constants/selectCategories";
import {KYC_STATUS_ARR} from "./promotionConstants";
import {useTranslation} from "react-i18next";


function KycStatusSelect({ onChange, value, disabled, ...props }) {
  const { t } = useTranslation();

    const options = useMemo(() => {
        let dynamicOptions = [...KYC_STATUS_ARR];

        const specifiedValues = ['Declined', 'Approved', 'Pending', 'New', 'Review'];

        if (specifiedValues.every(val => value.includes(val))) {
            dynamicOptions = dynamicOptions.filter(key => key !== 'All');
        }

        return dynamicOptions.map(key => ({
            value: key,
            label: t(`filter.constant.${key.toString()}`),
        }));
    }, [value, onChange]);


    return (
    <MultiSelect
      {...props}
      handleChange={onChange}
      values={value}
      disabled={disabled}
      isSearchable={false}
      options={options}
      className='kyc__select'
    />
  );
}

KycStatusSelect.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(KycStatusSelect);
