import { takeEvery, put, call, select } from 'redux-saga/effects';

import {
    DOWNLOAD_PLAYER_LIST,
    DOWNLOAD_PLAYER_PENDING_LIST,
    GET_PLAYER_LIST,
    GET_PLAYER_KYC_LIST,
    GET_PLAYER_KYC_COUNT,
    GET_PLAYER_TRACKER,
    UPDATE_PLAYERS,
    UPDATE_PLAYER_IDENTITY_INFO,
} from './actionTypes';
import {
    getPlayerListSuccess,
    getPlayerListFail,
    getPlayerKYCListSuccess,
    getPlayerKYCListFail,
    getPlayerKYCCountSuccess,
    getPlayerKYCCountFail,
    getPlayerTrackerSuccess,
    getPlayerTrackerFail,
    updatePlayersSuccess,
    updatePlayersFail,
    getPlayerList,
    downloadPlayerListSuccess,
    downloadPlayerListFail,
    downloadPlayerPendingListSuccess,
    downloadPlayerPendingListFail
} from './actions';
import {
    downloadPlayersApi,
    downloadPlayersPendingApi,
    getPlayerListApi,
    getPlayerKYCListApi,
    getPlayerKYCCountApi,
    getPlayerTrackerListApi,
    updatePlayersApi,
    updatePlayerIdentityInfoApi,
} from '../../../helpers/playerApi';

function* fetchPlayerListSaga({ payload }) {
    try {
        const response = yield call(getPlayerListApi, payload);

        yield put(getPlayerListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerListFail(error));
    }
}

function* fetchPlayerKYCListSaga({ payload }) {
    try {
        const response = yield call(getPlayerKYCListApi, payload);

        yield put(getPlayerKYCListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerKYCListFail(error));
    }
}

function* fetchPlayerKYCCountSaga({ payload }) {
    try {
        const response = yield call(getPlayerKYCCountApi, payload);

        yield put(getPlayerKYCCountSuccess(response.data));
    } catch (error) {
        yield put(getPlayerKYCCountFail(error));
    }
}

function* fetchPlayerTrackerListSaga({ payload }) {
    try {
        const response = yield call(getPlayerTrackerListApi, payload);

        yield put(getPlayerTrackerSuccess(response.data));
    } catch (error) {
        yield put(getPlayerTrackerFail(error));
    }
}

const getPlayerListQuery = state => state.PlayerList.query;

function* updatePlayersSaga({ payload }) {
    try {
        const response = yield call(updatePlayersApi, payload);

        const query = yield select(getPlayerListQuery);

        yield put(getPlayerList(query));

        yield put(updatePlayersSuccess(response.data));
    } catch (error) {
        yield put(updatePlayersFail(error));
    }
}

function * updatePlayerIdentityInfoSaga({ payload }) {
    try {
        const response = yield call(updatePlayerIdentityInfoApi, payload);

        const query = yield select(getPlayerListQuery);

        yield put(getPlayerList(query));

        yield put(updatePlayersSuccess(response.data));
    } catch (error) {
        yield put(updatePlayersFail(error));
    }
}

function* downloadPlayerListSaga({ payload }) {
    try {
        yield call(downloadPlayersApi, payload);

        yield put(downloadPlayerListSuccess());
    } catch (error) {
        yield put(downloadPlayerListFail(error));
    }
}

function* downloadPlayerPendingListSaga({ payload }) {
    try {
        yield call(downloadPlayersPendingApi, payload);

        yield put(downloadPlayerPendingListSuccess());
    } catch (error) {
        yield put(downloadPlayerPendingListFail(error));
    }
}

function* playersSaga() {
    yield takeEvery(GET_PLAYER_LIST, fetchPlayerListSaga);
    yield takeEvery(GET_PLAYER_KYC_LIST, fetchPlayerKYCListSaga);
    yield takeEvery(GET_PLAYER_KYC_COUNT, fetchPlayerKYCCountSaga);
    yield takeEvery(GET_PLAYER_TRACKER, fetchPlayerTrackerListSaga);
    yield takeEvery(UPDATE_PLAYERS, updatePlayersSaga);
    yield takeEvery(UPDATE_PLAYER_IDENTITY_INFO, updatePlayerIdentityInfoSaga);
    yield takeEvery(DOWNLOAD_PLAYER_LIST, downloadPlayerListSaga);
    yield takeEvery(DOWNLOAD_PLAYER_PENDING_LIST, downloadPlayerPendingListSaga);
}

export default playersSaga;
