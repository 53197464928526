import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_DEPOSIT_LIST,
    DOWNLOAD_PLAYER_DEPOSIT_LIST,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST,
} from './actionTypes';
import {
    getPlayerDepositListSuccess,
    getPlayerDepositListFail,
    downloadPlayerDepositListSuccess,
    downloadPlayerDepositListFail,
    downloadPlayerWithdrawalListSuccess,
    downloadPlayerWithdrawalListFail,
} from './actions';
import {
    getPlayerDepositListApi,
    downloadPlayerDepositListApi,
    downloadPlayerWithdrawalListApi,
} from '../../../helpers/playerApi';

function* fetchPlayerDepositListSaga({ payload }) {
    try {
        const response = yield call(getPlayerDepositListApi, payload);

        yield put(getPlayerDepositListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerDepositListFail(error));
    }
}

function* downloadPlayerDepositListSaga({ payload }) {
    try {
        yield call(downloadPlayerDepositListApi, payload);

        yield put(downloadPlayerDepositListSuccess());
    } catch (error) {
        yield put(downloadPlayerDepositListFail(error));
    }
}

function* downloadPlayerWithdrawalListSaga({ payload }) {
    try {
        yield call(downloadPlayerWithdrawalListApi, payload);

        yield put(downloadPlayerWithdrawalListSuccess());
    } catch (error) {
        yield put(downloadPlayerWithdrawalListFail(error));
    }
}

function* playerDepositsSaga() {
    yield takeEvery(GET_PLAYER_DEPOSIT_LIST, fetchPlayerDepositListSaga);
    yield takeEvery(DOWNLOAD_PLAYER_DEPOSIT_LIST, downloadPlayerDepositListSaga);
    yield takeEvery(DOWNLOAD_PLAYER_WITHDRAWAL_LIST, downloadPlayerWithdrawalListSaga);
}

export default playerDepositsSaga;
