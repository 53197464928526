import React from "react"
import PropTypes from "prop-types"

const CustomInput = (props) => {
	const {className, inputClassName, label, inputPlaceholder, value, onChange} = props
	return (
		<div className={className}>
			<span>{label}</span>
			<input
				className={inputClassName}
				placeholder={inputPlaceholder}
				onChange={onChange}
				value={value}
			/>
		</div>
	)
}

CustomInput.propTypes = {
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	label: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func
}

CustomInput.defaultProps = {
	className: '',
	inputClassName: '',
	label: '',
	inputPlaceholder: '',
	value: '',
	onChange: () => {},
}

export default CustomInput
