import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_STATISTICS,
} from './actionTypes';
import {
    getStatisticsSuccess,
    getStatisticsFail,
} from './actions';
import {getStatisticsApi} from "../../helpers/statisticsApi";


function* fetchStatisticsSaga({ payload }) {
    try {
        const response = yield call(getStatisticsApi, payload);

        yield put(getStatisticsSuccess(response.data));
    } catch (error) {
        yield put(getStatisticsFail(error));
    }
}



function* statisticsSaga() {
    yield takeEvery(GET_STATISTICS, fetchStatisticsSaga);
}

export default statisticsSaga;
