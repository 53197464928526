import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function AvatarIcon({
    avatarData,
    firstName = '',
    lastName = '',
    size = 'md',
    style
}) {
    if (avatarData) {
        return <div className="avatar-icon"></div>;
    }

    const initials = useMemo(() => {
        const firstLetter = firstName ? firstName.split('')[0] : '';
        const secondLetter = lastName ? lastName.split('')[0] : '';

        return `${firstLetter.toUpperCase()} ${secondLetter.toUpperCase()}`;
    }, [firstName, lastName]);

    return (
        <div className={`avatar-icon ${size}`} style={style ? style : {}}>
            {initials}
        </div>
    );
}

AvatarIcon.propTypes = {
    avatarData: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md']),
    style: PropTypes.object
};

export default React.memo(AvatarIcon);
