import {
    GET_PLAYER_RESTRICTIONS,
    GET_PLAYER_RESTRICTIONS_SUCCESS,
    GET_PLAYER_RESTRICTIONS_FAIL,
    SET_EDIT_PLAYER_RESTRICTIONS,
    RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS,
    UPDATE_PLAYER_RESTRICTIONS,
    UPDATE_PLAYER_RESTRICTIONS_SUCCESS,
    UPDATE_PLAYER_RESTRICTIONS_FAIL
} from './actionTypes';

export const getPlayerRestrictions = payload => ({
    type: GET_PLAYER_RESTRICTIONS,
    payload
});

export const getPlayerRestrictionsSuccess = payload => ({
    type: GET_PLAYER_RESTRICTIONS_SUCCESS,
    payload
});

export const getPlayerRestrictionsFail = error => ({
    type: GET_PLAYER_RESTRICTIONS_FAIL,
    payload: error
});

export const setEditPlayerRestrictions = payload => ({
    type: SET_EDIT_PLAYER_RESTRICTIONS,
    payload
});

export const resetPlayerRestrictionsAlertsErrors = payload => ({
    type: RESET_PLAYER_RESTRICTIONS_ALERTS_ERRORS,
    payload
});

export const updatePlayerRestrictions = payload => ({
    type: UPDATE_PLAYER_RESTRICTIONS,
    payload
});

export const updatePlayerRestrictionsSuccess = payload => ({
    type: UPDATE_PLAYER_RESTRICTIONS_SUCCESS,
    payload
});

export const updatePlayerRestrictionsFail = error => ({
    type: UPDATE_PLAYER_RESTRICTIONS_FAIL,
    payload: error
});
