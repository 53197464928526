export const LANGUAGES = [
    'AF',
    'SQ',
    'AR',
    'EU',
    'BE',
    'BG',
    'CA',
    'HR',
    'CS',
    'DA',
    'NL',
    'EN',
    'ET',
    'FO',
    'FA',
    'FI',
    'FR',
    'GD',
    'DE',
    'EL',
    'HE',
    'HI',
    'HU',
    'IS',
    'ID',
    'GA',
    'IT',
    'JA',
    'KO',
    'KU',
    'LV',
    'LT',
    'MK',
    'ML',
    'MS',
    'MT',
    'NO',
    'NB',
    'NN',
    'PL',
    'PT',
    'PA',
    'RO',
    'RU',
    'SR',
    'SK',
    'SL',
    'SB',
    'ES',
    'SV',
    'TH',
    'TS',
    'TN',
    'TR',
    'UK',
    'UR',
    'VE',
    'VI',
    'CY',
    'XH',
    'JI',
    'ZU'
];
