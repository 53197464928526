export const GET_PLAYER_AUDIT_LOGINS_LIST = 'GET_PLAYER_AUDIT_LOGINS_LIST';
export const GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS =
    'GET_PLAYER_AUDIT_LOGINS_LIST_SUCCESS';
export const GET_PLAYER_AUDIT_LOGINS_LIST_FAIL =
    'GET_PLAYER_AUDIT_LOGINS_LIST_FAIL';

export const GET_PLAYER_AUDIT_STATUS_LIST = 'GET_PLAYER_AUDIT_STATUS_LIST';
export const GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS =
    'GET_PLAYER_AUDIT_STATUS_LIST_SUCCESS';
export const GET_PLAYER_AUDIT_STATUS_LIST_FAIL =
    'GET_PLAYER_AUDIT_STATUS_LIST_FAIL';

export const GET_PLAYER_AUDIT_CHANGES_LIST =
    'GET_PLAYER_AUDIT_CHANGES_LIST';
export const GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS =
    'GET_PLAYER_AUDIT_CHANGES_LIST_SUCCESS';
export const GET_PLAYER_AUDIT_CHANGES_LIST_FAIL =
    'GET_PLAYER_AUDIT_CHANGES_LIST_FAIL';

export const GET_PLAYER_AUDIT_FILTERS =
    'GET_PLAYER_AUDIT_FILTERS';
export const GET_PLAYER_AUDIT_FILTERS_SUCCESS =
    'GET_PLAYER_AUDIT_FILTERS_SUCCESS';
export const GET_PLAYER_AUDIT_FILTERS_FAIL =
    'GET_PLAYER_AUDIT_FILTERS_FAIL';

export const RESET_PLAYER_AUDIT_LIST_ERRORS =
    'RESET_PLAYER_AUDIT_LIST_ERRORS';

export const DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST =
    'DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST';
export const DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS =
    'DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_SUCCESS';
export const DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL =
    'DOWNLOAD_PLAYER_AUDIT_LOGINS_LIST_FAIL';
