import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import { getWidthOfText } from '../utils/common';
import tooltipIcon from '../../assets/images/common/Tooltip.svg'
import './styles.scss';

const IconTooltip = props => {
    const { icon = tooltipIcon, tooltipText, textColor, className, top } = props;

    let widthOfText = getWidthOfText(tooltipText, 'Poppins', '12px');
    widthOfText *= 1.2;
    const leftPosition = `${-24 - widthOfText / 2 }px`;

    return (
            <div className={`tooltip-wrapper ${className}`}>
                <img src={icon} alt="" width={16} height={16} />
                <p
                    className="tooltip-i"
                    style={{
                        color: textColor,
                        top:  top ? top : `-${widthOfText / 96 * 30 + 8}px`,
                        leftPosition: leftPosition,
                    }}
                >
                    {tooltipText}
                </p>
            </div>
    );
};

IconTooltip.propTypes = {
    icon: PropTypes.any,
    tooltipText: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    top: PropTypes.number
};

IconTooltip.defaultProps = {
    tooltipText: '',
    textColor: 'black',
    className: ''
};

export default IconTooltip;
