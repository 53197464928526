import {
  GET_AGENT_SELF,
  GET_AGENT_SELF_SUCCESS,
  GET_AGENT_SELF_FAIL,
  UPDATE_AGENT_SELF_STATUS,
  UPDATE_AGENT_SELF_STATUS_SUCCESS,
  UPDATE_AGENT_SELF_STATUS_FAIL,
  SET_EDIT_AGENT_SELF,
  UPDATE_AGENT_SELF,
  UPDATE_AGENT_SELF_SUCCESS,
  UPDATE_AGENT_SELF_FAIL,
  RESET_AGENT_SELF_ALERTS_ERRORS,
  EDIT_AGENT_PASSWORD,
  EDIT_AGENT_PASSWORD_SUCCESS,
  EDIT_AGENT_PASSWORD_FAIL,
  DOWNLOAD_AGENT_SELF,
  DOWNLOAD_AGENT_SELF_SUCCESS,
  DOWNLOAD_AGENT_SELF_FAIL,
} from './actionTypes';
import i18n from '../../../i18n';


const INIT_STATE = {
  isLoading: false,
  error: null,
  agentSelf: null,
  isEdit: false,
  successAlert: '',
  isDownloading: false,
};

const AgentSelf = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENT_SELF:
      return { ...state, isLoading: true, error: null };

    case GET_AGENT_SELF_SUCCESS:
      return { ...state, agentSelf: action.payload, isLoading: false };

    case GET_AGENT_SELF_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case SET_EDIT_AGENT_SELF:
      return { ...state, isEdit: action.payload };

    case UPDATE_AGENT_SELF:
      return { ...state, isLoading: true, error: null };

    case UPDATE_AGENT_SELF_SUCCESS:
      return {
        ...state,
        agentSelf: { ...state.agentSelf, ...action.payload.dto },
        isLoading: false,
        successAlert: i18n.t('crm.alerts.agentEdited')
      };

    case UPDATE_AGENT_SELF_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case EDIT_AGENT_PASSWORD:
      return { ...state, isLoading: true, error: null };

    case EDIT_AGENT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successAlert: i18n.t('crm.alerts.passwordEdited')
      };

    case EDIT_AGENT_PASSWORD_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case UPDATE_AGENT_SELF_STATUS:
      return { ...state, isLoading: true, error: null };

    case UPDATE_AGENT_SELF_STATUS_SUCCESS:
      return {
        ...state,
        agentSelf: { ...state.agentSelf, ...action.payload },
        isLoading: false
      };

    case UPDATE_AGENT_SELF_STATUS_FAIL:
      return { ...state, error: action.payload, isLoading: false };

    case RESET_AGENT_SELF_ALERTS_ERRORS:
      return { ...state, error: null, successAlert: '' };

    case DOWNLOAD_AGENT_SELF:
      return {
        ...state,
        isDownloading: true,
        error: null
      };

    case DOWNLOAD_AGENT_SELF_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        error: null
      };

    case DOWNLOAD_AGENT_SELF_FAIL:
      return {
        ...state,
        isDownloading: false,
        error: action.payload,
      };

    default:
      return state;
    }
};

export default AgentSelf;
