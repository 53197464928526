const REWARD_TYPE = {
	CashMoney: "CashMoney",
	PrizeWagering: "PrizeWagering",
}

const YES_NO_OPTIONS = {
	YES: true,
	NO: false,
}

const BASE_CONDITION_TYPE = {
	NONE: "NONE",
	DEPOSIT: "DEPOSIT",
	WITHDRAWAL: "WITHDRAWAL",
	REGISTRATION: "REGISTRATION",
}

const CALCULATION_TYPE = {
	BONUS: "BONUS",
	DEPOSIT_BONUS: "DEPOSIT_BONUS",
}

const CURRENCIES_TYPE = {
	NONE: "None",
	USD: "USD",
	EUR: "EUR",
	MXN: "MXN"
}

const WAGERING_TYPE = {
	WAGERING_BONUS: "BonusMoney",
	WAGERING_DEPOSIT_BONUS: "Deposit+BonusMoney",
}

const PROMOTION_TYPE = {
	BONUS_WAGERING_ON_DEPOSIT: "BonusWageringOnDeposit",
	BONUS_WAGERING: "BonusWagering",
	FREE_SPINS: "FreeSpins",
}

const SPORT_PROMOTION_TYPE = {
	DEPOSIT: "Deposit",
	FREE_BET: "FreeBet",
}

const PROMOTION_STATUS = {
	PENDING: "Pending",
	RUNNING: "Running",
	DISABLED: "Disabled",
	EXPIRED: "Expired",
	PENDING_FOR_RELEASE: "PendingForRelease",
}

const PLAYER_PROMOTION_STATUS = [
	"Running",
	"Completed",
	"PendingForRelease",
	"Disabled",
]

const PROMOTION_STATUS_ARR = [
	"Pending",
	"Running",
	"Disabled",
	"Expired"
]

const KYC_STATUS_ARR = [
	'All',
	'Expired',
	'Declined',
	'Approved',
	'Pending',
	'New',
	'Review',
]

const PROMOTION_ENTER_CONDITION = {
	OptInByPlayer: 'OptInByPlayer', // Player clicks to activate
	OptInByAgent: 'OptInByAgent', // Agent clicked for the player
	Workflow: 'Workflow', // Available via smartico
}

const DepositWageringType = {
	Deposit_Bonus: 'Deposit+BonusMoney',
	Bonus: 'BonusMoney',
}

const PRODUCT = {
	Sport: 'sport',
	Casino: 'casino',
	Bingo: 'bingo'
}

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 20;

const ACTIVE_PROMOTIONS = `${PROMOTION_STATUS.PENDING}, ${PROMOTION_STATUS.RUNNING}`;
const INACTIVE_PROMOTIONS = `${PROMOTION_STATUS.DISABLED}, ${PROMOTION_STATUS.EXPIRED}`;

export {
	REWARD_TYPE,
	BASE_CONDITION_TYPE,
	CALCULATION_TYPE,
	PROMOTION_TYPE,
	SPORT_PROMOTION_TYPE,
	PROMOTION_STATUS,
	PROMOTION_STATUS_ARR,
	KYC_STATUS_ARR,
	PROMOTION_ENTER_CONDITION,
	DepositWageringType,
	CURRENCIES_TYPE,
	WAGERING_TYPE,
	YES_NO_OPTIONS,
	PLAYER_PROMOTION_STATUS,
	PRODUCT,
	DEFAULT_OFFSET,
	DEFAULT_LIMIT,
	ACTIVE_PROMOTIONS,
	INACTIVE_PROMOTIONS,
}
