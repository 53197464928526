import {
  GET_PLAYER_GENERAL,
  GET_PLAYER_GENERAL_SUCCESS,
  GET_PLAYER_GENERAL_FAIL,
  SET_EDIT_PLAYER_GENERAL,
  UPDATE_PLAYER_GENERAL,
  UPDATE_PLAYER_GENERAL_SUCCESS,
  UPDATE_PLAYER_GENERAL_FAIL,
  UPDATE_PLAYER_AFFILIATE_INFO,
  UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS,
  UPDATE_PLAYER_AFFILIATE_INFO_FAIL,
  GET_RETAIL_PLAYER,
  GET_RETAIL_PLAYER_SUCCESS,
  GET_RETAIL_PLAYER_FAIL,
  UPDATE_RETAIL_PLAYER,
  UPDATE_RETAIL_PLAYER_SUCCESS,
  UPDATE_RETAIL_PLAYER_FAIL,
  RESET_UPDATE_ERROR,
  CREATE_RETAIL_PLAYER,
  CREATE_RETAIL_PLAYER_SUCCESS,
  CREATE_RETAIL_PLAYER_FAIL,
  UPDATE_RETAIL_KYC_STATUS,
  UPDATE_RETAIL_KYC_STATUS_SUCCESS,
  UPDATE_RETAIL_KYC_STATUS_FAIL,
  PAYMENT_RETAIL,
  PAYMENT_RETAIL_SUCCESS,
  PAYMENT_RETAIL_FAIL,
  UPDATE_PLAYER_STATUS,
  UPDATE_PLAYER_STATUS_SUCCESS,
  UPDATE_PLAYER_STATUS_FAIL,
  RESET_PLAYER_ERRORS,
  RESET_PLAYER_STATE,
  GET_PLAYER_PHONE,
  GET_PLAYER_PHONE_SUCCESS,
  GET_PLAYER_PHONE_FAIL,
  GET_PLAYER_EMAIL,
  GET_PLAYER_EMAIL_SUCCESS,
  GET_PLAYER_EMAIL_FAIL,
  UPDATE_PLAYER_NOTE,
  UPDATE_PLAYER_NOTE_SUCCESS,
  UPDATE_PLAYER_NOTE_FAIL,
  RESET_STATUS_UPDATED,
  UPDATE_PLAYER_PHONE,
  UPDATE_PLAYER_PHONE_SUCCESS,
  UPDATE_PLAYER_PHONE_FAIL,
  CLEAR_PLAYER_GENERAL,
  CLEAR_RETAIL_PLAYER,
} from "./actionTypes"


// to work with an array
export const preparePlayer = (playerGeneral) => {
  const model = JSON.parse(JSON.stringify(playerGeneral));

  model.personalIdentifiers = (model.personalIdentifiers || []).map((item, i) => ({
    ...item,
    tempId: `${Date.now()}_${i}`
  }))

  return model;
}


const INIT_STATE = {
  isLoading: false,
  error: null,
  updateError: null,
  successMessage: null,
  playerGeneral: null,
  retailPlayer: null,
  isEdit: false,
  retailCreated: false,
  generalUpdated: false,
  isStatusUpdated: false,
  isPaymentRetail: false,

  isDownloading: false,

  isLoadingEmail: false,
  isLoadedEmail: false,
  email: '',
  isLoadingPhone: false,
  isLoadedPhone: false,
  phone: {
    baseUserPhone: '',
    otherPhone: '',
  },
  isLoadingNote: false,
  note: '',
};

const PlayerGeneral = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAYER_GENERAL:
      return {
        ...state,
        isLoading: true,
        error: null,
        generalUpdated: false,
        isStatusUpdated: false,
        successMessage: null
      };

    case GET_PLAYER_GENERAL_SUCCESS:
      return {
        ...state,
        playerGeneral: preparePlayer(action.payload),
        isLoading: false,
        generalUpdated: false
      };

    case GET_PLAYER_GENERAL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        generalUpdated: false
      };

    case GET_RETAIL_PLAYER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_RETAIL_PLAYER_SUCCESS:
      return {
        ...state,
        retailPlayer: action.payload,
        isLoading: false,
      };

    case GET_RETAIL_PLAYER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case SET_EDIT_PLAYER_GENERAL:
      return {
        ...state,
        isEdit: action.payload,
        generalUpdated: false
      };

    case UPDATE_PLAYER_GENERAL:
      return {
        ...state,
        isLoading: true,
        error: null,
        successMessage: null,
        generalUpdated: false
      };

    case UPDATE_PLAYER_GENERAL_SUCCESS:
      return {
        ...state,
        playerGeneral: Object.assign(
          state.playerGeneral,
          action.payload.dto
        ),
        isLoading: false,
        generalUpdated: true,
        successMessage: 'crm.alerts.updatedSuccessfully'
      };

    case UPDATE_PLAYER_GENERAL_FAIL:
      return {
        ...state,
        error: action.payload,
        successMessage: null,
        isLoading: false,
        generalUpdated: false
      };

    case UPDATE_PLAYER_AFFILIATE_INFO:
      return {
        ...state,
        isLoading: true,
        error: null,
        generalUpdated: false
      };

    case UPDATE_PLAYER_AFFILIATE_INFO_SUCCESS:
      return {
        ...state,
        playerGeneral: Object.assign(
          state.playerGeneral,
          action.payload.dto
        ),
        isLoading: false,
        generalUpdated: true
      };

    case UPDATE_PLAYER_AFFILIATE_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        generalUpdated: false
      };

    case UPDATE_RETAIL_PLAYER:
      return {
        ...state,
        generalUpdated: true,
        updateError: null,
      };

    case UPDATE_RETAIL_PLAYER_SUCCESS:
      return {
        ...state,
        generalUpdated: false,
        updateError: null,
      };

    case UPDATE_RETAIL_PLAYER_FAIL:
      return {
        ...state,
        generalUpdated: false,
        updateError: action.payload,
      };

    case RESET_UPDATE_ERROR:
      return {
        ...state,
        updateError: null,
      }

    case CREATE_RETAIL_PLAYER:
      return {
        ...state,
        updateError: null,
        retailCreated: true
      };

    case CREATE_RETAIL_PLAYER_SUCCESS:
      return {
        ...state,
        retailPlayer: action.payload,
        updateError: null,
        retailCreated: false
      };

    case CREATE_RETAIL_PLAYER_FAIL:
      return {
        ...state,
        updateError: action.payload,
        retailCreated: false
      };

    case UPDATE_RETAIL_KYC_STATUS:
      return {
        ...state,
        isStatusUpdated: true,
        error: null,
      };

    case UPDATE_RETAIL_KYC_STATUS_SUCCESS:
      return {
        ...state,
        isStatusUpdated: false,
        error: null,
      };

    case UPDATE_RETAIL_KYC_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        isStatusUpdated: false,
      };

    case PAYMENT_RETAIL:
      return {
        ...state,
        isPaymentRetail: true,
        error: null,
      };

    case PAYMENT_RETAIL_SUCCESS:
      return {
        ...state,
        isPaymentRetail: false,
        error: action.payload?.success === false
          ? {
            success: false,
          }
          : null,
      };

    case PAYMENT_RETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        isPaymentRetail: false,
      };

    case UPDATE_PLAYER_PHONE:
      return {
        ...state,
        isLoading: true,
        error: null,
        generalUpdated: false
      };

    case UPDATE_PLAYER_PHONE_SUCCESS:
      return {
        ...state,
        phone: {
          baseUserPhone: action.payload.phone,
          otherPhone: action.payload?.additionalPlayerPhones?.WhatsApp
        },
        isLoading: false,
        generalUpdated: true
      };

    case UPDATE_PLAYER_PHONE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        generalUpdated: false
      };

    case UPDATE_PLAYER_STATUS:
      return {
        ...state,
        isLoading: true,
        error: null,
        generalUpdated: false,
        isStatusUpdated: false
      };

    case UPDATE_PLAYER_STATUS_SUCCESS:
      return {
        ...state,
        playerGeneral: {
          ...state.playerGeneral,
          status: action.payload.dto.accountStatus
        },
        isLoading: false,
        generalUpdated: false,
        isStatusUpdated: true
      };

    case UPDATE_PLAYER_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        generalUpdated: false
      };

    case RESET_PLAYER_ERRORS:
      return { ...state, error: null, generalUpdated: false };

    case RESET_PLAYER_STATE:
      return { ...INIT_STATE };

    case GET_PLAYER_PHONE:
      return {
        ...state,
        isLoadingPhone: true,
        error: null,
      };

    case GET_PLAYER_PHONE_SUCCESS:
      return {
        ...state,
        isLoadingPhone: false,
        isLoadedPhone: true,
        phone: {
          baseUserPhone: action.payload?.phone,
          otherPhone: action.payload?.additionalPlayerPhones?.WhatsApp
        },
      };

    case GET_PLAYER_PHONE_FAIL:
      return {
        ...state,
        isLoadingPhone: false,
        error: action.payload,
      };

    case GET_PLAYER_EMAIL:
      return {
        ...state,
        isLoadingEmail: true,
        error: null,
      };

    case GET_PLAYER_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingEmail: false,
        isLoadedEmail: true,
        email: action.payload?.email,
      };

    case GET_PLAYER_EMAIL_FAIL:
      return {
        ...state,
        isLoadingEmail: false,
        error: action.payload,
      };

    case UPDATE_PLAYER_NOTE:
      return {
        ...state,
        isLoadingNote: true,
        error: null,
        noteUpdated: false
      };

    case UPDATE_PLAYER_NOTE_SUCCESS:
      return {
        ...state,
        note: action.payload.dto.note,
        isLoading: false,
        noteUpdated: true
      };

    case UPDATE_PLAYER_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        noteUpdated: false
      };

    case RESET_STATUS_UPDATED:
      return {
        ...state,
        generalUpdated: false
      };

    case CLEAR_PLAYER_GENERAL:
      return {
        ...state,
        playerGeneral: null,
      };

    case CLEAR_RETAIL_PLAYER:
      return {
        ...state,
        retailPlayer: null,
      };

    default:
      return state;
  }
};

export default PlayerGeneral;
