import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ATextField from '../../components/GenericComponents/InputField';
import i18n from '../../i18n';
//      <ATextField disabled={false} label={i18n.t(`crm.${stateKey}`)} value={state[stateKey]} error={errorMessage} onChange={handleChange(stateKey)} />

import './style.scss';

function TextInput(props) {
    const {className, max, min, type, value, disabled, onChange, placeholder} = props;

    const handleChange = useCallback(
        e => {
            let value = e.target.value;
            if ((max && value > max) || (min && value < min)) return;

            if (type === 'number') {
                value = value.replace(/-/g, '');
            }
            return onChange(type === 'number' ? value.replace(/\D\/,\./g,'') : value)
        },
        [onChange]
    );

    const handleKeyDown = () => {
        if (type === 'number') {
            if(['+','-'].includes(event.key)) {
                event.preventDefault()
            }
        }
    }

    return (
        <input
            {...props}
            className={`${className} form-control`}
            type={type || 'text'}
            value={value || ''}
            onChange={handleChange}
            max={max}
            min={min}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            placeholder={placeholder}
        />
    );
}

TextInput.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default React.memo(TextInput);
