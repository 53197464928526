import {
    GET_AGENT_EMPLOYEE_LIST,
    GET_AGENT_EMPLOYEE_LIST_SUCCESS,
    GET_AGENT_EMPLOYEE_LIST_FAIL,
    RESET_AGENT_EMPLOYEE_LIST_ERRORS
} from './actionTypes';

export const getAgentEmployeeList = payload => ({
    type: GET_AGENT_EMPLOYEE_LIST,
    payload
});

export const getAgentEmployeeListSuccess = payload => ({
    type: GET_AGENT_EMPLOYEE_LIST_SUCCESS,
    payload
});

export const getAgentEmployeeListFail = payload => ({
    type: GET_AGENT_EMPLOYEE_LIST_FAIL,
    payload
});

export const resetAgentEmployeeListErrors = payload => ({
    type: RESET_AGENT_EMPLOYEE_LIST_ERRORS,
    payload
});
