import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../i18n';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import close from "../../../assets/images/common/close 1.svg";
import './styles.scss';
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import useHandleEsc from "../../../common/hooks/useHandleEsc";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function EditSiteFieldModal({
    onCancel,
    onChangeFieldName,
    previousName,
}) {
    useHandleEsc(onCancel);
    const [fieldName, setFieldName] = useState(previousName);

    const confirmChangeFieldName = useCallback(() => {
        onChangeFieldName(fieldName);
    }, [fieldName]);

    const handleChangeFieldName = (fieldName) => {
        setFieldName(fieldName)
    };

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="add-brand-field-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('admin.edit.field')}
                </h5>
                <button className="close" onClick={onCancel} />
            </div>

            <ModalBody>
                <ACommonDataField
                    label={i18n.t('admin.field.name') + ' *'}
                    component={A_COMMON_DATA_FIELD.TEXT}
                    value={fieldName}
                    handleChange={handleChangeFieldName}
                    fulWidth={true}
                />
            </ModalBody>
            <ModalFooter
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 10,
                    marginBottom: 10,
                }}
            >
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onCancel}
                    style={{
                        width: '30%',
                    }}
                >
                    <img src={close} alt="" />
                    {i18n.t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded"
                    onClick={confirmChangeFieldName}
                    style={{
                        width: '30%',
                        marginLeft: 20,
                    }}
                    disabled={fieldName === '' || fieldName === previousName}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('admin.save')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

EditSiteFieldModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onChangeFieldName: PropTypes.func.isRequired,
    previousName: PropTypes.string.isRequired,
};

export default React.memo(EditSiteFieldModal);
