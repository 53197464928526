import {
    GET_PLAYER_REFERRALS_LIST,
    GET_PLAYER_REFERRALS_LIST_FAIL,
    GET_PLAYER_REFERRALS_LIST_SUCCESS,
} from "./actionTypes"


export const getPlayerReferralsList = payload => ({
    type: GET_PLAYER_REFERRALS_LIST,
    payload
});

export const getPlayerReferralsListSuccess = payload => ({
    type: GET_PLAYER_REFERRALS_LIST_SUCCESS,
    payload
});

export const getPlayerReferralsListFail = payload => ({
    type: GET_PLAYER_REFERRALS_LIST_FAIL,
    payload
});

