import { takeEvery, put, call } from 'redux-saga/effects';

// Login Redux States
import {
    GOOGLE_AUTH,
    LOGIN_USER,
    LOGOUT_USER,
    REFRESH_TOKEN,
    LOGIN_SUCCESS
} from './actionTypes';
import {
    apiError,
    loginSuccess,
    logoutUserSuccess,
    showLoadingScreen,
    hideLoadingScreen,
    setLoadingPercent,
    refreshTokenSuccess
} from './actions';
import { login, googleAuthentication, logout } from '../../../helpers/backend_helper';
import { reloadAccessToken } from '../../../helpers/api_helper';
import { getInfoSaga } from '../../info/saga';
import { getAgentSelfSuccess } from '../../agents/self/actions';
import queryString from 'query-string';
import {fetchVipLevelConfig} from "../../brand/brandPage/actions";

const DEFAULT_PATH = '/overview';
const LOGIN = '/login';

function* googleAuth({ payload: { user, history } }) {
    try {
        if ('accessToken' in user) {
            const {accessToken, profileObj} = user;
            const response = yield call(googleAuthentication, {
                token: accessToken,
                profile: profileObj,
                remember: true,
            });
            const {
                refreshToken,
                ...otherRespData
            } = response;

            const responseData = {
                ...otherRespData,
                email: profileObj.email
            };

            localStorage.setItem('authUser', JSON.stringify(responseData));
            localStorage.setItem('refreshToken', refreshToken);
            yield put(loginSuccess(responseData));
            yield put(getAgentSelfSuccess(responseData.agent));

            const redirect_url = queryString.parse(
                window.location.search
            ).redirect_url;
            history.push(
                redirect_url && redirect_url !== '/login'
                    ? redirect_url
                    : DEFAULT_PATH
            );
        }
    } catch (error) {
        // msg: Username and password are invalid. Please enter correct username and password
        yield put(apiError([error]));
    }
}

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(login, {
            email: user.email.trim(),
            password: user.password,
            remember: user.remember
        });
        const { refreshToken, ...otherRespData } = response;

        const responseData = {
            ...otherRespData,
            email: user.email
        };

        localStorage.setItem('authUser', JSON.stringify(responseData));
        localStorage.setItem('refreshToken', refreshToken);
        yield put(loginSuccess(responseData));
        yield put(fetchVipLevelConfig());
        yield put(getAgentSelfSuccess(responseData.agent));

        const redirect_url = queryString.parse(
            window.location.search
        ).redirect_url;
        history.push(
            redirect_url && redirect_url !== '/login'
                ? redirect_url
                : DEFAULT_PATH
        );
    } catch (error) {
        // msg: Username and password are invalid. Please enter correct username and password
        yield put(apiError([error]));
    }
}

function* showLoadingScreenSaga() {
    try {
        yield put(showLoadingScreen());
        yield call(getInfoSaga);
        yield put(setLoadingPercent(100));
    } catch (error) {
        yield put(apiError([error]));
        yield put(hideLoadingScreen());
    }
}

function* refreshAuthToken() {
    try {
        const response = yield call(reloadAccessToken);

        if (response) {
            yield put(refreshTokenSuccess());
        } else {
            yield put(apiError(response));
        }
    } catch (error) {
        yield put(apiError([error]));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        const response = yield call(logout);

        if (response.success) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('authUser');
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('counters');
            yield put(logoutUserSuccess());
            history.push(LOGIN);
        }
    } catch (error) {
        yield put(apiError([error]));
    }
}

function* authSaga() {
    yield takeEvery(GOOGLE_AUTH, googleAuth);
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(REFRESH_TOKEN, refreshAuthToken);
    yield takeEvery(LOGOUT_USER, logoutUser);
    yield takeEvery(LOGIN_SUCCESS, showLoadingScreenSaga);
}

export default authSaga;
