import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../../common/components/Breadcrumb';
import mockData from './automation-mock.json';
import i18n from '../../i18n';
import WorkflowCard from './WorkflowCard';

class WorkflowPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: mockData
        };
    }

    render() {
        const { data } = this.state;

        return (
            <div className="page-content automation-page">
                <Container fluid>
                    <Breadcrumb
                        breadcrumbItem={i18n.t('workflow.workflowView')}
                        title={i18n.t('workflow.workflow')}
                        titleLink="/workflow"
                    />

                    <Row>
                        <Col lg="12">
                            <WorkflowCard data={data} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(WorkflowPage);
