import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import i18n from "../../i18n";
import GameTable from "../../common/tables/GameTable";
import CustomModal from "../../common/components/CustomModal";
import {
  handleChangePath,
  getJSONFromExcelData
} from "../../common/utils/common";
import CategoryGamesModalHeader from "./components/CategoryGamesModalHeader";
import RenameGroupModal from "./components/RenameGroupModal";
import modalTableColumns from "./components/ModalTableColumns";
import {
  getSiteGames,
  getGameCategory,
  getCategoryGames,
  putCategoryData,
  removeCategoryGame,
  bulkUpdateCategoryGames,
  downloadCategoryGameList,
  getGameVendors,
} from "../../store/actions";
import { DIRECTION } from "../../constants/layout";
import { SITE_PERMISSION, DEFAULT_PAGE_SIZE } from "../../common/constants/common";
import DragFileInput from "../../common/inputs/common/DragFileInput";

import "./styles.scss";

import addItem from "../../assets/images/layout/big-add-item.svg";
import pencil from "../../assets/images/layout/pencil.svg";
import download from "../../assets/images/common/download.svg";
import settings from "../../assets/images/layout/settings.svg";
import category1 from "../../assets/images/layout/categories/category1.png";
import category2 from "../../assets/images/layout/categories/category2.png";
import category3 from "../../assets/images/layout/categories/category3.png";
import category4 from "../../assets/images/layout/categories/category4.png";
import TableBigModal from "./TableBigModal";
import FormSearch from "../../UI/formSearch/FormSearch";
import clsx from "clsx";
import TableFilter from "../../components/TableFilter";
import { FILTER_CATEGORY, FILTER_MENU_CATEGORY_GAME_LIST } from "../../constants/Filter";
import { getFilteredGames } from "../../common/utils/filter";
import SelectTableView from "./components/SelectTableView";
import Loader from "../../common/components/Loader";
import { uniq } from "lodash";
import { clearCurrentFilter } from "../../store/filters/action";
import Tooltip from "@mui/material/Tooltip";
import { XLSXformat } from "../../common/inputs/common/FileTypes/fileTypes";
import xlsx from "xlsx";
import WrongGamesModal from "../CRM/modal/WrongGamesModal";
import { COLUMN_IDS, COLUMNS_WITHOUT_TITLE, KEY_I18N_MAP } from "../../constants/tableSettings";
import PermissionWrapper from "../../components/PermissionWrapper";

const categoryImages = [
  category1,
  category2,
  category3,
  category4,
];

const rowHeight = 42;
const headerHeight = 30;

const ALL_COLUMNS = [
  COLUMN_IDS.game,
  COLUMN_IDS.oid,
  COLUMN_IDS.genre,
  COLUMN_IDS.lines,
  COLUMN_IDS.gameVendor,
  COLUMN_IDS.priority,
  COLUMN_IDS.emphasize,
  COLUMN_IDS.addedTime,
  COLUMN_IDS.rtp,
  COLUMN_IDS.gameIdMobile,
  COLUMN_IDS.gameIdDesktop,
  COLUMN_IDS.promoCoefficient,
  COLUMN_IDS.remove,
];

const COLUMN_SOURCES = {
  [COLUMN_IDS.game]: 'gameName',
  [COLUMN_IDS.oid]: 'oid',
  [COLUMN_IDS.genre]: 'gameType',
  [COLUMN_IDS.lines]: 'lines',
  [COLUMN_IDS.gameVendor]: 'vendorName',
  [COLUMN_IDS.priority]: 'priority',
  [COLUMN_IDS.emphasize]: 'emphasize',
  [COLUMN_IDS.addedTime]: 'createdAt',
  [COLUMN_IDS.rtp]: 'rtp',
  [COLUMN_IDS.gameIdMobile]: 'externalMobileId',
  [COLUMN_IDS.gameIdDesktop]: 'externalDesktopId',
  [COLUMN_IDS.promoCoefficient]: 'promoCoefficient',
};

const getColumnData = (id, context) => {
  const isWithoutTitle = COLUMNS_WITHOUT_TITLE.includes(id);
  const baseObj = isWithoutTitle
    ? {
        id,
        title: '',
        className: 'text-align-center',
      }
    : {
        id,
        title: i18n.t(KEY_I18N_MAP[id]),
        source: COLUMN_SOURCES[id],
        className: 'text-align-center',
    };

  switch (id) {
    case COLUMN_IDS.game:
      return {
        ...baseObj,
        className: 'white-space-normal bold game-name',
      };
    case COLUMN_IDS.genre:
      return {
        ...baseObj,
        className: 'text-align-center game-type',
      };
    case COLUMN_IDS.oid:
    case COLUMN_IDS.lines:
    case COLUMN_IDS.priority:
    case COLUMN_IDS.emphasize:
    case COLUMN_IDS.rtp:
    case COLUMN_IDS.promoCoefficient:
      return baseObj;
    case COLUMN_IDS.gameVendor:
      return {
        ...baseObj,
        className: 'text-align-center width150 game-vendor',
      };
    case COLUMN_IDS.addedTime:
    case COLUMN_IDS.gameIdMobile:
    case COLUMN_IDS.gameIdDesktop:
      return {
        ...baseObj,
        className: 'text-align-center width150',
      };
    case COLUMN_IDS.remove:
      return {
        ...baseObj,
        onClick: (game) => context.setState({
          removeGameModalVisibility: true,
          currentGame: game,
        })
      };
    default:
      return baseObj;
  }
};

const getColumns = (context) => ALL_COLUMNS.map((column) => getColumnData(column, context));

class GameCategoryView extends Component {
  constructor(props) {
    super(props)

    handleChangePath(props)
    this.state = {
      games: [],
      sortedGames: [],
      checkedAll: false,
      withPagination: true,
      activePage: 1,
      pageCount: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      currentRowHeight: rowHeight,
      currentGame: null,
      removeGameModalVisibility: false,
      changeCategoryDialogVisibility: false,
      changeCategoryGamesModalVisibility: false,
      gameCategory: null,
      categoryImg: categoryImages[1],
      gamesCount: 0,
      searchValue: '',
      modalSearchValue: '',
      isUpdatingCategory: false,
      savedCountVisibleGameInCategory: 0,
      agentPermissions: props.agentSelf?.permissions || [],
      gameVendorsArr: [],
      withFilter: true,
      sorting: {
        direction: null,
        column: null,
      },
      isShowTableSettings: false,
      isFileParsing: false,
      showWrongGamesModal: false,
      tableColumns: getColumns(this),
    }

    this.boxRef = null;
    this.siteGamesData = {};
    this.siteGamesFriendlyNames = [];
    this.wrongLocalGames = [];
  }

  componentDidMount() {
    const {match} = this.props
    this.categoryId = match.params.id

    this.props.getGameCategory(this.categoryId);
    this.props.getCategoryGames(this.categoryId);
    this.props.getSiteGames();
    this.props.getGameVendors();
    this.setState({
      savedCountVisibleGameInCategory: this.state.gamesCount,
      agentPermissions: this.props.agentSelf?.permissions || []
    });
  }

  sortGames() {
    const { currentFilter, categoryGames } = this.props;
    const { gameVendorsArr, sorting } = this.state;

    const games = (sorting.column && sorting.direction)
        ? this.gameSortingWithValues(categoryGames.result, sorting.column, sorting.direction)
        : this.defaultGameSorting(categoryGames.result)

    return getFilteredGames(currentFilter, games, gameVendorsArr);
  }

  defaultGameSorting (games) {
    return games.sort((a, b) => b.priority - a.priority);
  }

  gameSortingWithValues (games, column, direction) {
    const fieldName = column.source || column.id;

    const convertValue = (value) => {
      return typeof value === 'string' ? parseFloat(value) || value : value;
    };

    if (fieldName) {
      games.sort((a, b) => {
        const valueA = convertValue(a[fieldName]);
        const valueB = convertValue(b[fieldName]);

        if (valueA < valueB) {
          return direction === DIRECTION.ASC ? -1 : 1;
        } else if (valueA === valueB) {
          return 0;
        }
        return direction === DIRECTION.ASC ? 1 : -1;
      });
    }

    return games;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      agentSelf,
      gameVendors,
      siteGames,
      gameCategory,
      categoryGames,
      currentFilter,
      isBulkUpload,
      isDeletingGame,
      isUpdatingCategory,
      isUpdatingGames,
      errorGames,
      successMessage,
    } = this.props;

    if (!isBulkUpload && isBulkUpload !== prevProps.isBulkUpload) {
      if (errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.gameWasNotUpdated'));
      }
      else {
        if (this.wrongLocalGames.length === 0) {
          this.props.alert.success(i18n.t('crm.alerts.categoryHasBeenUpdated'));
          this.props.getCategoryGames(this.categoryId);
        }
        else {
          this.props.alert.info(i18n.t('crm.alerts.gameWasUpdatedWithErrors'));
        }
      }

      if (this.wrongLocalGames.length > 0) {
        this.setState({
          showWrongGamesModal: true,
          isFileParsing: false,
        });
      }
      else {
        this.setState({ isFileParsing: false });
      }
    }

    if (this.state.sortedGames !== prevState.sortedGames) {
        this.getUniqueGameTypes();
    }

    if (this.state.changeCategoryGamesModalVisibility !== prevState.changeCategoryGamesModalVisibility) {
      this.props.clearCurrentFilter();
    }

    if (agentSelf !== prevProps.agentSelf) {
      this.setState({agentPermissions: agentSelf?.permissions || []});
    }

    if (gameVendors !== prevProps.gameVendors || siteGames !== prevProps.siteGames) {
      if (gameVendors && Array.isArray(siteGames)) {
        const directVendors = gameVendors.filter((vendor) => vendor.configuration?.direct);
        const uniqVendorIds = uniq(siteGames.map((game) => game.vendor));
        const siteGameVendors = gameVendors.filter((vendor) => uniqVendorIds.includes(vendor.id));
        const siteGameVendorNames = siteGameVendors.map((vendor) => vendor.name);

        for (let directVendor of directVendors) {
          if (siteGameVendorNames.includes(directVendor.name) && !siteGameVendors.includes(directVendor)) {
            siteGameVendors.push(directVendor);
          }
        }

        const gameVendorsArr = siteGameVendors.map((vendor) => {
          const { id, name, configuration } = vendor;
          return {
            id,
            name: configuration.direct ? `${name} (direct)` : name,
          }
        });

        gameVendorsArr.sort((a1, a2) => a1.name < a2.name ? -1 : 1);

        this.setState({ gameVendorsArr });
      }
    }

    if (siteGames !== prevProps.siteGames) {
      this.siteGamesData = {};
      this.siteGamesFriendlyNames = [];

      siteGames
        .forEach((game) => {
          if (game) {
            const { id, gameName } = game;
            if (id && gameName) {
              this.siteGamesData[id] = {
                id,
                friendlyName: gameName,
              };
              this.siteGamesFriendlyNames.push(gameName);
            }
          }
        });
    }

    if (gameCategory !== prevProps.gameCategory
      || categoryGames !== prevProps.categoryGames) {
      let changedState = {}
      if (gameCategory !== prevProps.gameCategory) {
        const {activeLogo, oid, games} = gameCategory;

        changedState = {
          ...changedState,
          gameCategory,
          categoryImg: activeLogo === "some url" ? categoryImages[oid % 4] : activeLogo,
        }
      }

      if (categoryGames !== prevProps.categoryGames) {
        const games = categoryGames.result;

        const filteredGames = this.sortGames() || [];
        const gamesCount = filteredGames.length ?? 0;
        const { pageSize } = this.state;
        changedState = {
          ...changedState,
          activePage: 1,
          pageCount: Math.ceil(gamesCount / pageSize),
          games,
          sortedGames: [...filteredGames],
          gamesCount,
        };
      }
      this.setState({
        ...changedState
      });
    }

    if (currentFilter !== prevProps.currentFilter) {
      const filteredGames = this.sortGames() || [];
      const gamesCount = filteredGames.length ?? 0;
      const { pageSize } = this.state;
      this.setState({
        activePage: 1,
        pageCount: Math.ceil(gamesCount / pageSize),
        sortedGames: [...filteredGames],
        gamesCount,
      });
    }

    if (!isDeletingGame && isDeletingGame !== prevProps.isDeletingGame) {
      this.props.getCategoryGames(this.categoryId);
      this.props.getGameCategory(this.categoryId); // current category
      this.props.alert.error(i18n.t('crm.alerts.gameRemoved'));
    }

    if (!isUpdatingCategory && isUpdatingCategory !== prevProps.isUpdatingCategory) {
      this.props.getGameCategory(this.categoryId);
      this.setState({activePage: 1});
    }

    if (this.state.isUpdatingCategory && this.state.isUpdatingCategory !== prevState.isUpdatingCategory) {
      this.props.alert.success(i18n.t('crm.alerts.categoryUpdated'));
      this.setState({
        isUpdatingCategory: false,
      })
    }

    if (successMessage && successMessage !== prevProps.successMessage) {
      this.props.alert.success(i18n.t(`crm.alerts.${successMessage}`));
    }

    if (!isUpdatingGames && isUpdatingGames !== prevProps.isUpdatingGames) {
      if (errorGames) {
        // Request's error
        this.props.alert.error(i18n.t('crm.alerts.gameWasNotUpdated'));
      }
      else {
        this.props.getGameCategory(this.categoryId);
        this.props.getCategoryGames(this.categoryId);

        this.props.alert.success(i18n.t('crm.alerts.gameWasUpdated'));
      }
    }
  }

  handleChangePage = (activePage) => this.setState({activePage});

  getUniqueGameTypes = () => {
    const { sortedGames } = this.state;

    const uniqueGameTypes = sortedGames.reduce((acc, game) => {
      const gameType = game.gameType || "No type";
      if (!acc.includes(gameType)) {
        acc.push(gameType);
      }
      return acc;
    }, []);

    this.setState({uniqueGameTypes});
  }

  handleSetPageSize = (pageSize) => {
    const { gamesCount } = this.state;
    const pageCategoriesCount = pageSize === 'All' ? gamesCount : pageSize;

    this.setState({
      pageCount: Math.ceil(gamesCount / pageCategoriesCount),
      pageSize: pageCategoriesCount,
      activePage: 1,
    });
  }

  handleSendCategoryData = ({
    handleChangeCategoryGames=()=>{},
  }) => {
    this.setState((prevState) => {
      return {
        changeCategoryGamesModalVisibility: false,
        isUpdatingCategory: true,
        savedCountVisibleGameInCategory: this.state.gamesCount
      };
    });
    handleChangeCategoryGames();
  };

  handleSaveCategoryNames = ({categoryName}) => {
    const categoryData = {
      label: categoryName
    };
    this.props.putCategoryData({
      id: this.categoryId,
      names: categoryData
    });
    this.setState({ changeCategoryDialogVisibility: false });
  };

  handleToggleCategoryDialog = () => {
    const { changeCategoryDialogVisibility } = this.state;
    this.setState({ changeCategoryDialogVisibility: !changeCategoryDialogVisibility });
  };

  handleToggleCategoryGamesModalVisibility = () => {
    const { changeCategoryGamesModalVisibility, activePage, games } = this.state
    this.setState((prevState) => {
      return {
        changeCategoryGamesModalVisibility: !changeCategoryGamesModalVisibility,
        activePage: changeCategoryGamesModalVisibility ? 1 : activePage,
        gamesCount: games?.length,
        modalSearchValue: '',
      };
    });
  };

  handleRemoveGameFromCategory = () => {
    const { currentGame } = this.state
    const { gameCategory } = this.props
    this.props.removeCategoryGame({ id: gameCategory.id, gameId: currentGame.id })

    this.setState({ removeGameModalVisibility: false })
  };

  handleSortGames = (column, direction = DIRECTION.ASC) => {
    const { sortedGames } = this.state;

    const games = this.gameSortingWithValues(sortedGames, column, direction);
    this.setState({
      sortedGames: [...games],
      sorting: {
        direction,
        column
      }
    });
  };

  handleChangeSearchValue = (e) => {
    const {games, pageSize} = this.state;
    const searchValue = e.target.value;
    const sortedGames = games
      .filter(game => searchValue === ''
        || game.gameName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      );
    this.setState({
      pageCount: Math.ceil(sortedGames.length / pageSize),
      pageSize,
      searchValue,
      sortedGames,
      activePage: 1
    });
  };

  handleChangeModalSearchValue = (e) => {
    const modalSearchValue = e.target.value;
    this.setState({ modalSearchValue, activePage: 1 });
  };

  handleChangeGames = (games) => {
    const gamesCount = games.filter(game => game.checked).length
    this.setState((prevState) => {
      if (this.state.savedCountVisibleGameInCategory !== gamesCount) {
        return {
          gamesCount,
        }
      } else {
        return {
          gamesCount,
          isUpdatingCategory: false
        }
      }
    });
  };

  parseJSONData = (arrayOfLines) => {
    const parsedResult = [];
    const badRecords = [];

    for (const record of arrayOfLines) {
      if (this.siteGamesFriendlyNames?.includes(record.Game)
          && this.siteGamesData[record.ID]?.friendlyName === record.Game) {
        record.id = record.ID;
        delete record.ID;
        delete record.Game;
        parsedResult.push(record);
      }
      else {
        badRecords.push({
          ...record,
          Errors: `Site games don't contain this game;`,
        });
      }
    }

    return {
      parsedResult,
      badRecords,
    };
  };

  parseFileData = (fileData) => {
    let parsedResult = [];
    let badRecords = [];

    // .xlsx
    const gamesJSON = getJSONFromExcelData(fileData);
    if (gamesJSON.length > 0) {
      ({ parsedResult, badRecords } = this.parseJSONData(gamesJSON));
    }

    return { parsedResult, badRecords };
  };

  handleUploadFile = async (file) => {
    const { gameCategory } = this.props
    this.setState({ isFileParsing: true });

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const fileData = e.target.result;
        const { parsedResult, badRecords } = this.parseFileData(fileData);

        if (badRecords.length > 0) {
          this.wrongLocalGames = badRecords;
        }

        if (parsedResult.length > 0) {
          // Send parsed result on the server
          this.props.bulkUpdateCategoryGames({ games: parsedResult, id: gameCategory.id });
        }
        else {
          if (badRecords.length === 0) {
            this.props.alert.info(i18n.t('crm.alerts.gamesNotFound'));
          }
          else {
            this.props.alert.error(i18n.t('crm.alerts.gameWasNotUpdated'));
            this.setState({
              showWrongGamesModal: true,
              isFileParsing: false,
            });
          }
        }

        this.setState({ isFileParsing: false });
      }
      catch (err) {
        console.error('Wrong file parsing');
        console.error(err);
        this.setState({ isFileParsing: false });
      }
    };

    // Read file
    reader.readAsArrayBuffer(file);
  };

  handleDownload = async () => {
    const { gameCategory } = this.props;
    this.props.downloadCategoryGameList({ id: gameCategory.id, query: {} });
  };

  handleToggleWrongGamesModal = () => {
    const { showWrongGamesModal } = this.state;
    if (showWrongGamesModal) {
      this.wrongLocalGames = [];
    }

    this.setState({ showWrongGamesModal: !showWrongGamesModal });
  };

  handleSaveWrongGame = async () => {
    const header = [
      'ID',
      'OID',
      'Game',
      'Type',
      'Game vendor',
      'Added time',
      'RTP',
      'Game ID Mobile',
      'Game ID Desktop',
      'Lines',
      'Priority',
      'Emphasize',
      'BonusFactor',
      'Errors',
    ];

    const workbook = xlsx.utils.book_new();
    const workSheet = xlsx.utils.json_to_sheet(this.wrongLocalGames, { header });
    xlsx.utils.book_append_sheet(workbook, workSheet, 'WrongGames');
    xlsx.writeFile(workbook, "WrongGames.xlsx");

    this.wrongLocalGames = [];
    this.handleToggleWrongGamesModal();
  };

  render() {
    const {
      sortedGames,
      currentGame,
      activePage,
      pageCount,
      pageSize,
      currentRowHeight,
      removeGameModalVisibility,
      changeCategoryDialogVisibility,
      changeCategoryGamesModalVisibility,
      gameCategory,
      categoryImg,
      gamesCount,
      modalSearchValue,
      agentPermissions,
      gameVendorsArr,
      uniqueGameTypes,
      isShowTableSettings,
      isFileParsing,
      showWrongGamesModal,
      tableColumns,
    } = this.state;

    const {
      isDownloading,
      isLoadingGames,
      isUpdatingGames,
      isBulkUpload,
    } = this.props;

    const canEditByPermission = agentPermissions.includes(SITE_PERMISSION.Game__Edit_Game_Categories);

    return (
      <div className="page-content document-content game-category-content game-category-page">
        <MetaTags>
          <title>{i18n.t('content.categoryView')} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card className={clsx('page-card game-category-card', {
                'auto-height': pageSize > 10
              })}>
                <CardBody className="game-category-card-info">
                  <div className="page-title-table">
                    <TableFilter
                        filterListOptions={FILTER_MENU_CATEGORY_GAME_LIST(gameVendorsArr, uniqueGameTypes)}
                        category={FILTER_CATEGORY.GAME_CATEGORY}
                    />
                  </div>

                  <div className="game-category__header">
                    <div className={clsx("add-category-modal-header", {
                                  'drag-download': !canEditByPermission,
                    })}>
                      <div
                        className="category-img"
                        style={{
                          background: `transparent url(${categoryImg}) center`,
                          backgroundSize: 'cover',
                        }}
                      >
                        <img
                          className="pencil"
                          onClick={this.handleToggleCategoryDialog}
                          src={pencil}
                          width={16}
                          height={16}
                          alt=""
                        />
                      </div>
                      <div
                        className="category-title-text pointer"
                        onClick={this.handleToggleCategoryDialog}
                      >
                        {gameCategory?.label}
                      </div>
                      <div className="count-games-text ml-0">
                          {isLoadingGames || isUpdatingGames || isFileParsing || isBulkUpload ? (
                              <Loader size={'sm'}/>
                          ) : (
                              <PermissionWrapper accessPermissions={[SITE_PERMISSION.Game__Edit_Game_Categories]}>
                                <div className="send-button">
                                  <Tooltip title={i18n.t('content.addGames')} placement="top" arrow>
                                    <img
                                        className={clsx("", {
                                          'd-none': !canEditByPermission,
                                        })}
                                        src={addItem}
                                        alt="Add games"
                                        width={40}
                                        onClick={this.handleToggleCategoryGamesModalVisibility}
                                    />
                                  </Tooltip>
                                </div>
                              </PermissionWrapper>
                          )}
                      </div>
                    </div>

                    <div className="game-category-header__settings">
                      <div className="d-flex csv-uploader align-items-center game-category-search">
                        <FormSearch
                            onChangeSearchValue={this.handleChangeSearchValue}
                            showIconSearch
                        />
                      </div>

                      <div className={clsx("ml-10", {
                        'd-none': !canEditByPermission,
                      })}>
                        <DragFileInput
                            accept={XLSXformat}
                            typeFile={'XLSX'}
                            tooltipText={'chooseFileXLSX'}
                            onSelect={this.handleUploadFile}
                            withLabel={false}
                            imgMode
                          />
                        </div>

                      <PermissionWrapper accessPermissions={[SITE_PERMISSION.Game__View_Game_Categories]}>
                        <div
                            className={clsx("d-flex align-items-center mt-2 me-3 download-button", {
                              ['d-none']: !sortedGames?.length,
                            })}
                        >
                          <button
                              className="btn btn-rounded btn-primary app-btn-only-img-sm"
                              onClick={this.handleDownload}
                              disabled={isDownloading}
                          >
                            <Tooltip
                                title={i18n.t('content.downloadListOfGames')}
                                placement="top"
                                arrow
                            >
                              <img src={download} alt=""/>
                            </Tooltip>
                          </button>
                        </div>
                      </PermissionWrapper>

                      <button
                          className="btn settings-button"
                          onClick={() => this.setState({isShowTableSettings: true})}
                      >
                        <Tooltip title={i18n.t('content.settings')} placement="top" arrow>
                          <img src={settings} alt="Table's Settings"/>
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                  {isLoadingGames || isUpdatingGames ? (
                      <div className='empty-table-block'>
                        <Loader size={'lg'}/>
                      </div>
                  ) : (
                      <>
                        <div
                            className='game-table-wrapper autoTableHeight'
                            ref={ref => this.boxRef = ref}
                        >
                          <GameTable
                              columns={tableColumns}
                              games={sortedGames}
                              activePage={activePage}
                              pageSize={pageSize}
                              currentRowHeight={currentRowHeight}
                              onSortGames={this.handleSortGames}
                              categoryMode={true}
                              gameCategory={this.categoryId}
                              canEditByPermission={canEditByPermission}
                              isGamePriorityUpdateModal={true}
                              isShowTableSettings={isShowTableSettings}
                              onCloseTableSettings={() => this.setState({isShowTableSettings: false})}
                          />
                        </div>
                        <SelectTableView
                            recordsCount={gamesCount}
                            pageSize={pageSize}
                            activePage={activePage}
                            pageCount={pageCount}
                            onChange={this.handleSetPageSize}
                            onChangePage={this.handleChangePage}
                        />
                      </>
                  )}
                  {!sortedGames.length && !isLoadingGames && (
                      <div className='no-data-game-table'>
                        {i18n.t('crm.emptyTable')}
                      </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {changeCategoryGamesModalVisibility &&
            <TableBigModal
                onToggle={this.handleToggleCategoryGamesModalVisibility}
                renderHeader={({
                                 handleChangeCategoryGames = () => {
                                 }
                               }) => (
                    <CategoryGamesModalHeader
                        currentCategory={gameCategory}
                        onSendCategoryData={(params) => this.handleSendCategoryData({
                          ...params,
                          handleChangeCategoryGames,
                        })}
                        onChangeSearchValue={this.handleChangeModalSearchValue}
                        gamesCount={gamesCount}
                    />
                )}
                tableTitleText={i18n.t("content.chooseGames")}
                isOpen={changeCategoryGamesModalVisibility}
                columns={modalTableColumns}
                modalSearchValue={modalSearchValue}
                onChangeGames={this.handleChangeGames}
                withFilter
                gameVendorsArr={gameVendorsArr}
                currentFilter={this.props.currentFilter}
                sorting={this.state.sorting}
                gameSortingWithValues={this.gameSortingWithValues}
                withPagination
            />
        }

        {changeCategoryDialogVisibility &&
          <RenameGroupModal
            dialogVisibility={changeCategoryDialogVisibility}
            onToggle={this.handleToggleCategoryDialog}
            onSaveNames={this.handleSaveCategoryNames}
            category={gameCategory}
          />
        }

        {removeGameModalVisibility &&
          <CustomModal
            titleText={i18n.t('content.removeGame')}
            isOpen={removeGameModalVisibility}
            onToggle={() => this.setState({
              removeGameModalVisibility: !removeGameModalVisibility,
            })}
            onClick={this.handleRemoveGameFromCategory}
            onClickCancelButton={() => this.setState({
              removeGameModalVisibility: false,
            })}
            btnText={'remove'}
            cancelBtnText={'decline'}
            withCancelButton={true}
            bodyRender={() => (
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {`${i18n.t('content.sure.want.remove')} '${currentGame.gameName}' ${i18n.t('content.from.category')}`}
              </div>
            )}
          />
        }

        {showWrongGamesModal &&
          <WrongGamesModal
              onConfirm={this.handleSaveWrongGame}
              onHide={this.handleToggleWrongGamesModal}
              wrongLocalGames={this.wrongLocalGames}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { agentSelf } = state.AgentSelf;
  const {
    siteGames,
    gameCategory,
    categoryGames,
    isDownloading,
    isLoadingGames,
    isUpdatingGames,
    isUpdatingCategory,
    errorGames,
    isDeletingGame,
    deletingError,
    isLoadingCategory,
    isBulkUpload,
    gameVendors,
    successMessage,
  } = state.Games;
  const {
    currentFilter,
  } = state.Filter;
  return {
    siteGames,
    gameCategory,
    categoryGames,
    isDownloading,
    isLoadingGames,
    isUpdatingGames,
    isUpdatingCategory,
    errorGames,
    isDeletingGame,
    deletingError,
    isLoadingCategory,
    isBulkUpload,
    agentSelf,
    currentFilter,
    gameVendors,
    successMessage,
  };
};

export default React.memo(
  withRouter(
    connect(mapStateToProps, {
      getSiteGames,
      getGameVendors,
      getGameCategory,
      getCategoryGames,
      putCategoryData,
      bulkUpdateCategoryGames,
      removeCategoryGame,
      downloadCategoryGameList,
      clearCurrentFilter,
    })(withAlert()(GameCategoryView))
  )
);
