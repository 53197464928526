export const COLUMN_IDS = {
    accountStatus: 'accountStatus',
    affiliateName: 'affiliateName',
    affiliateSubId: 'affiliateSubId',
    agentOwner: 'agentOwner',
    casinoBonusBalance: 'casinoBonusBalance',
    ftdAmount: 'ftdAmount',
    ftdDate: 'ftdDate',
    id: 'id',
    language: 'language',
    lastLogin: 'lastLogin',
    ltdAmount: 'ltdAmount',
    ltdDate: 'ltdDate',
    player: 'player',
    playerOID: 'playerOID',
    product: 'product',
    realBalance: 'realBalance',
    reconnectAfter: 'reconnectAfter',
    registrationTime: 'registrationTime',
    sessionTime: 'sessionTime',
    sportBonusBalance: 'sportBonusBalance',
    stdAmount: 'stdAmount',
    stdDate: 'stdDate',
    tdForCheckBox: 'tdForCheckBox',
    totalDeposits: 'totalDeposits',
    totalWithdrawal: 'totalWithdrawal',
    username: 'username',
    depositorStatus: 'depositorStatus',

    check: 'check',
    game: 'game',
    oid: 'oid',
    genre: 'genre',
    gameVendor: 'gameVendor',
    gameProvider: 'gameProvider',
    priority: 'priority',
    emphasize: 'emphasize',
    addedTime: 'addedTime',
    enabledTime: 'enabledTime',
    rtp: 'rtp',
    supportFreeSpins: 'supportFreeSpins',
    externalDesktopId: 'externalDesktopId',
    externalMobileId: 'externalMobileId',
    bonusFactor: 'bonusFactor',
    remove: 'remove',

    lines: 'lines',
    gameIdMobile: 'gameIdMobile',
    gameIdDesktop: 'gameIdDesktop',
    promoCoefficient: 'promoCoefficient',

    Subtype: 'Subtype',
    Theme: 'Theme',
    RTP: 'RTP',
    BonusFactor: 'BonusFactor',
    Branded: 'Branded',
    SideBet: 'SideBet',
    BetBehind: 'BetBehind',
    SeatLimit: 'SeatLimit',
    BonusBuy: 'BonusBuy',
    FreeSpins: 'supportFreeSpins',
    Jackpot: 'Jackpot',
    DescriptionGameInfo: 'DescriptionGameInfo',
    ProviderReleaseDate: 'ProviderReleaseDate',
    JackpotName: 'JackpotName',
    JackpotGameCode: 'JackpotGameCode',
    JackpotContribution: 'JackpotContribution',
    ProgressiveSeedAmount: 'ProgressiveSeedAmount',
    Megaways: 'Megaways',
    MultipleFSConfigurations: 'MultipleFSConfigurations',
    Replay_PragmaticOnly: 'Replay_PragmaticOnly',
    Reels: 'Reels',
    Paylines: 'Paylines',
    Volatility: 'Volatility',
    BetLevels: 'BetLevels',
    DefaultBet: 'DefaultBet',
    MinBet: 'MinBet',
    MaxBet: 'MaxBet',
    MaxPayout: 'MaxPayout',
    MaxMultiplier: 'MaxMultiplier',
    HitFrequency: 'HitFrequency',
    Certifications: 'Certifications',
    SupportedLanguages: 'SupportedLanguages',

    txnId: 'txnId',
    pspTRX: 'pspTRX',
    createdAt: 'createdAt',
    status: 'status',
    amount: 'amount',
    totalDepositsAmount: 'totalDepositsAmount',
    pspCode: 'pspCode',
    integration: 'integration',
    PM: 'PM',
    pspMsg: 'pspMsg',
    country: 'country',
    KYC: 'KYC',
    cardInfo: 'cardInfo',
    cardHolderName: 'cardHolderName',
    PSP: 'PSP',
    txnOID: 'txnOID',
    IP: 'IP',
    promoCode: 'promoCode',

    wdNumber: 'wdNumber',
    userIp: 'userIp',
    totalPaid: 'totalPaid',
    currency: 'currency',
    fees: 'fees',
    lastUpdated: 'lastUpdated',
    email: 'email',
    totalSuccessfulWD: 'totalSuccessfulWD',
    totalBonuses: 'totalBonuses',
    bonusRatio: 'bonusRatio',
    WDRatio: 'WDRatio',
    netCash: 'netCash',
};

export const COLUMNS_WITHOUT_TITLE = [
    COLUMN_IDS.tdForCheckBox,
    COLUMN_IDS.check,
    COLUMN_IDS.remove,
];

export const KEY_I18N_MAP = {
    [COLUMN_IDS.registrationTime]: 'crm.createdAt',
    [COLUMN_IDS.id]: 'crm.OIDorID',
    [COLUMN_IDS.player]: 'crm.playerName',
    [COLUMN_IDS.reconnectAfter]: 'crm.reconnectAfter',
    [COLUMN_IDS.sessionTime]: 'crm.sessionTime',
    [COLUMN_IDS.username]: 'crm.username',
    [COLUMN_IDS.realBalance]: 'crm.realBalance',
    [COLUMN_IDS.lastLogin]: 'crm.lastLogin',
    [COLUMN_IDS.product]: 'crm.affiliateProduct',
    [COLUMN_IDS.agentOwner]: 'crm.playerOwnerName',
    [COLUMN_IDS.ftdDate]: 'crm.ftdDate',
    [COLUMN_IDS.ftdAmount]: 'crm.ftdAmount',
    [COLUMN_IDS.stdDate]: 'crm.stdDate',
    [COLUMN_IDS.stdAmount]: 'crm.stdAmount',
    [COLUMN_IDS.ltdDate]: 'crm.ltdDate',
    [COLUMN_IDS.ltdAmount]: 'crm.ltdAmount',
    [COLUMN_IDS.sportBonusBalance]: 'crm.sportBonusBalance',
    [COLUMN_IDS.casinoBonusBalance]: 'crm.casinoBonusBalance',
    [COLUMN_IDS.totalDeposits]: 'crm.totalDeposit',
    [COLUMN_IDS.totalWithdrawal]: 'crm.totalWithdrawal',
    [COLUMN_IDS.language]: 'crm.language',
    [COLUMN_IDS.affiliateName]: 'crm.affiliateAffiliateId',
    [COLUMN_IDS.affiliateSubId]: 'crm.affiliateSubId',

    [COLUMN_IDS.game]: 'content.gameName',
    [COLUMN_IDS.oid]: 'content.operationId',
    [COLUMN_IDS.genre]: 'content.type',
    [COLUMN_IDS.gameVendor]: 'content.gameVendor',
    [COLUMN_IDS.gameProvider]: 'content.gameProvider',
    [COLUMN_IDS.priority]: 'content.priority',
    [COLUMN_IDS.emphasize]: 'content.emphasize',
    [COLUMN_IDS.addedTime]: 'content.addedTime',
    [COLUMN_IDS.enabledTime]: 'content.enabledTime',
    [COLUMN_IDS.rtp]: 'content.rtp',
    [COLUMN_IDS.supportFreeSpins]: 'content.freeSpins',
    [COLUMN_IDS.externalDesktopId]: 'content.gameIdDesktop',
    [COLUMN_IDS.externalMobileId]: 'content.gameIdMobile',
    [COLUMN_IDS.bonusFactor]: 'content.bonusFactor',

    [COLUMN_IDS.lines]: 'content.lines',
    [COLUMN_IDS.gameIdMobile]: 'content.gameIdMobile',
    [COLUMN_IDS.gameIdDesktop]: 'content.gameIdDesktop',
    [COLUMN_IDS.promoCoefficient]: 'content.bonusFactor',

    [COLUMN_IDS.Subtype]: 'content.Subtype',
    [COLUMN_IDS.Theme]: 'content.Theme',
    [COLUMN_IDS.RTP]: 'content.RTP',
    [COLUMN_IDS.BonusFactor]: 'content.BonusFactor',
    [COLUMN_IDS.Branded]: 'content.Branded',
    [COLUMN_IDS.SideBet]: 'content.SideBet',
    [COLUMN_IDS.BetBehind]: 'content.BetBehind',
    [COLUMN_IDS.SeatLimit]: 'content.SeatLimit',
    [COLUMN_IDS.BonusBuy]: 'content.BonusBuy',
    [COLUMN_IDS.FreeSpins]: 'content.FreeSpins',
    [COLUMN_IDS.Jackpot]: 'content.Jackpot',
    [COLUMN_IDS.DescriptionGameInfo]: 'content.DescriptionGameInfo',
    [COLUMN_IDS.ProviderReleaseDate]: 'content.ProviderReleaseDate',
    [COLUMN_IDS.JackpotName]: 'content.JackpotName',
    [COLUMN_IDS.JackpotGameCode]: 'content.JackpotGameCode',
    [COLUMN_IDS.JackpotContribution]: 'content.JackpotContribution',
    [COLUMN_IDS.ProgressiveSeedAmount]: 'content.ProgressiveSeedAmount',
    [COLUMN_IDS.Megaways]: 'content.Megaways',
    [COLUMN_IDS.MultipleFSConfigurations]: 'content.MultipleFSConfigurations',
    [COLUMN_IDS.Replay_PragmaticOnly]: 'content.Replay_PragmaticOnly',
    [COLUMN_IDS.Reels]: 'content.Reels',
    [COLUMN_IDS.Paylines]: 'content.Paylines',
    [COLUMN_IDS.Volatility]: 'content.Volatility',
    [COLUMN_IDS.BetLevels]: 'content.BetLevels',
    [COLUMN_IDS.DefaultBet]: 'content.DefaultBet',
    [COLUMN_IDS.MinBet]: 'content.MinBet',
    [COLUMN_IDS.MaxBet]: 'content.MaxBet',
    [COLUMN_IDS.MaxPayout]: 'content.MaxPayout',
    [COLUMN_IDS.MaxMultiplier]: 'content.MaxMultiplier',
    [COLUMN_IDS.HitFrequency]: 'content.HitFrequency',
    [COLUMN_IDS.Certifications]: 'content.Certifications',
    [COLUMN_IDS.SupportedLanguages]: 'content.SupportedLanguages',
};

export const DEFAULT_COLUMNS = {
    playerList: [
        COLUMN_IDS.registrationTime,
        COLUMN_IDS.id,
        COLUMN_IDS.player,
        COLUMN_IDS.username,
        COLUMN_IDS.realBalance,
        COLUMN_IDS.lastLogin,
        COLUMN_IDS.product,
        COLUMN_IDS.agentOwner,
        COLUMN_IDS.ftdDate,
        COLUMN_IDS.ftdAmount,
        COLUMN_IDS.stdDate,
        COLUMN_IDS.stdAmount,
        COLUMN_IDS.ltdDate,
        COLUMN_IDS.ltdAmount,
        COLUMN_IDS.sportBonusBalance,
        COLUMN_IDS.casinoBonusBalance,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.totalWithdrawal,
    ],
    tracker: [
        COLUMN_IDS.registrationTime,
        COLUMN_IDS.id,
        COLUMN_IDS.player,
        COLUMN_IDS.reconnectAfter,
        COLUMN_IDS.sessionTime,
        COLUMN_IDS.username,
        COLUMN_IDS.realBalance,
        COLUMN_IDS.lastLogin,
        COLUMN_IDS.product,
        COLUMN_IDS.agentOwner,
        COLUMN_IDS.ftdDate,
        COLUMN_IDS.ftdAmount,
        COLUMN_IDS.stdDate,
        COLUMN_IDS.stdAmount,
        COLUMN_IDS.ltdDate,
        COLUMN_IDS.ltdAmount,
        COLUMN_IDS.sportBonusBalance,
        COLUMN_IDS.casinoBonusBalance,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.totalWithdrawal,
    ],
    deposits: [
        COLUMN_IDS.txnId,
        COLUMN_IDS.player,
        COLUMN_IDS.createdAt,
        COLUMN_IDS.status,
        COLUMN_IDS.amount,
        COLUMN_IDS.PM,
        COLUMN_IDS.pspCode,
        COLUMN_IDS.pspMsg,
        COLUMN_IDS.country,
        COLUMN_IDS.cardInfo,
        COLUMN_IDS.cardHolderName,
    ],
    pendingWithdrawals: [
        COLUMN_IDS.wdNumber,
        COLUMN_IDS.player,
        COLUMN_IDS.createdAt,
        COLUMN_IDS.PSP,
        COLUMN_IDS.PM,
        COLUMN_IDS.amount,
        COLUMN_IDS.totalPaid,
        COLUMN_IDS.fees,
        COLUMN_IDS.KYC,
        COLUMN_IDS.status,
        COLUMN_IDS.lastUpdated,
    ],
    withdrawals: [
        COLUMN_IDS.wdNumber,
        COLUMN_IDS.player,
        COLUMN_IDS.createdAt,
        COLUMN_IDS.PSP,
        COLUMN_IDS.PM,
        COLUMN_IDS.email,
        COLUMN_IDS.amount,
        COLUMN_IDS.totalPaid,
        COLUMN_IDS.fees,
        COLUMN_IDS.KYC,
        COLUMN_IDS.status,
        COLUMN_IDS.lastUpdated,
        COLUMN_IDS.totalSuccessfulWD,
        COLUMN_IDS.totalDeposits,
        COLUMN_IDS.WDRatio,
        COLUMN_IDS.netCash,
    ],
};

export const getDefaultColumns = (page, allColumns) => {
    if (page in DEFAULT_COLUMNS) {
        return allColumns
            ? DEFAULT_COLUMNS[page].filter((column) => allColumns.includes(column))
            : DEFAULT_COLUMNS[page];
    }

    return allColumns;
};
