import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
    resetPromotionAlertsErrors,
    closePlayerPromotion,
    getPlayerPromotionsList,
} from '../../../store/actions';
import Loader from '../../../common/components/Loader';
import useAlertService from '../../../hooks/useAlertService';

import promoPlus from '../../../assets/images/common/promoPlus.svg';
import NewBonusModal from "./modal/NewBonusModal";
import CustomModal from "../../../common/components/CustomModal";
import { PROMOTION_STATUS } from "../../Promotions/common/promotionConstants";
import { useTranslation } from "react-i18next";
import TextareaInput from "../../../common/inputs/TextareaInput";
import clsx from "clsx";
import { SITE_PERMISSION } from "../../../common/constants/common";
import settings from "../../../assets/images/layout/settings.svg";
import PromotionsTabTable from "./PromotionsTabTable";

function PromotionsTab(props) {
    const { playerId, query, playerGeneral } = props;
    const {
        isLoading,
        error,
        templatesList,
        activePromotionsList,
        isApplying,
        isClosing,
    } = useSelector(state => state.PlayerPromotions);
    const { i18n } = useTranslation();
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const [showNewBonusModal, setShowNewBonusModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showCancelBonusModal, setShowCancelBonusModal] = useState(false);
    const [showBonusDisabledModal, setBonusDisabledModal] = useState(false);
    const [currentPlayerPromoId, setCurrentPlayerPromoId] = useState('');
    const [closureReason, setClosureReason] = useState('');
    const [isShowTableSettings, setIsShowTableSettings] = useState(false);

    const toggleTableSettings = () => setIsShowTableSettings(prev => !prev);

    const { currency: generalCurrency } = useSelector(state => state.PlayerGeneral.playerGeneral);

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const canAddPromoByPermission = agentPermissions.includes(SITE_PERMISSION.Player__Apply_Bonus);

    const getPlayerPromotions = useCallback(() => {
        dispatch(
            getPlayerPromotionsList({
                id: playerId,
                query,
            })
        );
    }, [playerId, query])

    const [prevIsClosing, setPrevIsClosing] = useState(false);
    const [prevIsApplying, setPrevIsApplying] = useState(false);
    const isPromotionsAllowed = playerGeneral?.allowPromotions;

    useEffect(() => {
        if (!isApplying && prevIsApplying) {
            if (!error) {
                getPlayerPromotions();
                alertService.showSuccess(i18n.t('crm.alerts.playersPromotionAdded'));
            }
        }
        setPrevIsApplying(isApplying);
    }, [isApplying]);

    useEffect(() => {
        if (!isClosing && prevIsClosing && !error) {
            getPlayerPromotions();
            alertService.showSuccess(i18n.t('crm.alerts.promotionDisabled'));
        }
        setPrevIsClosing(isClosing);
    }, [isClosing]);

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetPromotionAlertsErrors());
        }
    }, [error]);

    const handleShowWarningModal = () => {
        setShowWarningModal(true);
    }

    const handleCloseWarningModal = () => {
        setShowWarningModal(false);
    }

    const handleAddActivePromo = () => {
        setShowWarningModal(false);
        setShowNewBonusModal(true);
    };

    const handleHideAddActivePromo = () => {
        setShowNewBonusModal(false);
    };

    const handleOpenCancelBonusModal = (playerPromotionId) => {
        const currPromo = activePromotionsList.find(promo => promo?.id === playerPromotionId);
        if (currPromo) {
            if (currPromo.status === PROMOTION_STATUS.RUNNING ||
                currPromo.status === PROMOTION_STATUS.PENDING_FOR_RELEASE
            ) {
                setCurrentPlayerPromoId(playerPromotionId);
                setShowCancelBonusModal(true);
            }
            else {
                setBonusDisabledModal(true);
            }
        }
    };

    const handleCancelBonus = () => {
        setCurrentPlayerPromoId('');
        setShowCancelBonusModal(false);

        dispatch(closePlayerPromotion({
            id: playerId,
            playerPromotionId: currentPlayerPromoId,
        }));
    };

    const onChangeTextArea = (value) => {
        setClosureReason(value);
    }

    return (
        <>
            <div className="detailed-card__content audit">
                <div
                    className={clsx("add-active-promo", {
                        "cannot-add-promo": !canAddPromoByPermission,
                    })}
                >
                    <div className='settings-bonuses'>
                        <button
                            className="btn settings-button"
                            onClick={toggleTableSettings}
                        >
                            <img src={settings} alt="Table's Settings"/>
                        </button>
                    </div>
                    <img
                        src={promoPlus}
                        alt="Activate bonus"
                        onClick={() => {
                            isPromotionsAllowed
                                ? handleAddActivePromo()
                                : handleShowWarningModal()
                        }}
                    />
                </div>

                {activePromotionsList && !isLoading ? (
                    <>
                        <PromotionsTabTable
                            list={activePromotionsList}
                            onCancelBonus={handleOpenCancelBonusModal}
                            generalCurrency={generalCurrency}
                            isShowTableSettings={isShowTableSettings}
                            onCloseTableSettings={toggleTableSettings}
                        />
                    </>
                ) : (
                    <Loader size={'lg'}/>
                )}
            </div>
            {showWarningModal && !isPromotionsAllowed && (
                <CustomModal
                    titleText={i18n.t('common.attention')}
                    isOpen={showWarningModal}
                    onToggle={() => handleCloseWarningModal()}
                    onClick={handleAddActivePromo}
                    onClickCancelButton={() => handleCloseWarningModal()}
                    btnText={'confirm'}
                    cancelBtnText={'cancel'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {i18n.t('promotions.sureCreditBonus')}
                            </div>
                        </>
                    )}
                />
            )}
            {showNewBonusModal && (
                <NewBonusModal
                    playerId={playerId}
                    isPromotionsAllowed={isPromotionsAllowed}
                    onHide={handleHideAddActivePromo}
                    fieldErrors={{}}
                    isLoading={false}
                    promotions={templatesList || []}
                />
            )}
            {showCancelBonusModal &&
                <CustomModal
                    titleText={i18n.t('common.attention')}
                    isOpen={showCancelBonusModal}
                    onToggle={() => setShowCancelBonusModal(!showCancelBonusModal)}
                    onClick={handleCancelBonus}
                    onClickCancelButton={() => setShowCancelBonusModal(false)}
                    btnText={'yes'}
                    cancelBtnText={'no'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {i18n.t('promotions.sureWantClosePromo')}
                            </div>
                            <TextareaInput
                                className="form-control form-control-gray mt-3"
                                rows="5"
                                value={closureReason}
                                onChange={onChangeTextArea}
                                placeholder={i18n.t('crm.closurePromoReason')}
                                disabled={false}
                            />
                        </>
                    )}
                />
            }
            {showBonusDisabledModal &&
                <CustomModal
                    titleText={i18n.t('common.attention')}
                    isOpen={showBonusDisabledModal}
                    onToggle={() => setBonusDisabledModal(!showBonusDisabledModal)}
                    onClick={() => setBonusDisabledModal(false)}
                    btnText={'close'}
                    bodyRender={() => (
                        <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            {i18n.t('promotions.promoAlreadyDisabled')}
                        </div>
                    )}
                />
            }
        </>
    );
}

PromotionsTab.propTypes = {
    playerId: PropTypes.string.isRequired,
    query: PropTypes.object
};

export default React.memo(PromotionsTab);
