import {
    GET_PLAYER_WITHDRAWAL_LIST,
    GET_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    GET_PLAYER_WITHDRAWAL_LIST_FAIL,
    RESET_PLAYER_WITHDRAWAL_ERRORS
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    playerWithdrawalList: null,
    total: 0,
    query: DEFAULT_QUERY
};

const PlayerWithdrawals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_WITHDRAWAL_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload.query },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_WITHDRAWAL_LIST_SUCCESS:
            return {
                ...state,
                playerWithdrawalList: action.payload.result,
                total: action.payload.total,
                totalAmounts: action.payload.totalAmounts,
                isLoading: false
            };

        case GET_PLAYER_WITHDRAWAL_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                playerWithdrawalList: [],
                isLoading: false
            };

        case RESET_PLAYER_WITHDRAWAL_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

export default PlayerWithdrawals;
