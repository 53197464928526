import React from 'react';
import PropTypes from 'prop-types';
import './react-select-input.scss';
import icon from '../../../assets/images/common/magnifiying-glass 1.svg';
import TextInput from '../TextInput';

import './app-search-input.scss';

function SearchInput({ onChange, className, value, placeholder, ...props }) {
    return (
        <div
            className={`form-search-fragment ${className ? className : ''}`}
            {...props}
        >
            <img className="app-search-icon" src={icon} alt="" />
            <TextInput
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
        </div>
    );
}

SearchInput.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default React.memo(SearchInput);
