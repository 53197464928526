export const changeAffiliateDataAdapter = ({
    id,
    name,
    email,
    priority,
    contactName,
    contactPhone,
    cookieExpirationTimeInMinutes
}) => ({
    id,
    name,
    email,
    priority,
    contactName,
    contactPhone,
    cookieExpirationTimeInMinutes: Number(cookieExpirationTimeInMinutes)
});
