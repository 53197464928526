import {
    GET_PLAYER_DEPOSIT_LIST,
    GET_PLAYER_DEPOSIT_LIST_SUCCESS,
    GET_PLAYER_DEPOSIT_LIST_FAIL,
    RESET_PLAYER_DEPOSIT_LIST_ERRORS,
    DOWNLOAD_PLAYER_DEPOSIT_LIST,
    DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS,
    DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL,
} from './actionTypes';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoading: false,
    error: null,

    playerDepositList: null,
    total: 0,
    query: DEFAULT_QUERY,

    isDownloading: false,
};

const PlayerDeposits = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_DEPOSIT_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload.query },
                isLoading: true,
                error: null
            };

        case GET_PLAYER_DEPOSIT_LIST_SUCCESS:
            return {
                ...state,
                playerDepositList: action.payload.result,
                total: action.payload.total,
                totalAmounts: action.payload.totalAmounts,
                isLoading: false
            };

        case GET_PLAYER_DEPOSIT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                playerDepositList: [],
                isLoading: false
            };

        case RESET_PLAYER_DEPOSIT_LIST_ERRORS:
            return { ...state, error: null };

        case DOWNLOAD_PLAYER_DEPOSIT_LIST:
        case DOWNLOAD_PLAYER_WITHDRAWAL_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS:
        case DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL:
        case DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default PlayerDeposits;
