import React, {useState, useEffect, memo, useRef} from 'react';
import { usePopper } from 'react-popper';
import { grey } from './colors';
import ArrowUpIcon from './img/ArrowUp';
import ArrowDownIcon from './img/ArrowDown';
import TextIcon from './img/Text';
import MultiIcon from './img/Multi';
import HashIcon from './img/Hash';
import PlusIcon from './img/Plus';

import './headerStyles.css';

import { ActionTypes, DataTypes, shortId } from './utils';

function getPropertyIcon(dataType) {
  switch (dataType) {
    case DataTypes.NUMBER:
      return <HashIcon />;
    case DataTypes.TEXT:
      return <TextIcon />;
    case DataTypes.SELECT:
      return <MultiIcon />;
    case DataTypes.MULTISELECT:
      return  <MultiIcon />
    default:
      return null;
  }
}

export default function Header({
  column: { id, created, label, dataType, getResizerProps, getHeaderProps, enableSorting, filterValue, setFilter, ...column },
  setSortBy,
  dataDispatch,
  isShowPopUpFilter,
  setIsShowPopUpFilter
}) {
  const textInput = useRef(null);
  const [expanded, setExpanded] = useState(created || false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'absolute',
  });
  const [header, setHeader] = useState(label);
  const [typeReferenceElement, setTypeReferenceElement] = useState(null);
  const [typePopperElement, setTypePopperElement] = useState(null);
  const typePopper = usePopper(typeReferenceElement, typePopperElement, {
    placement: 'right',
    strategy: 'fixed',
  });
  const [showType, setShowType] = useState(false);
  const [currentFilterValue, setCurrentFilterValue] = useState(null);

  useEffect(() => {
      if(currentFilterValue !== null) {
        setFilter(currentFilterValue)
      }
  }, [currentFilterValue])

  const buttons = [
    {
      onClick: e => {
        dataDispatch({
          type: ActionTypes.UPDATE_COLUMN_HEADER,
          columnId: id,
          label: header,
        });
        setSortBy([{ id: id, desc: false }]);
        setExpanded(false);
        setIsShowPopUpFilter(false);
      },
      icon: <ArrowUpIcon />,
      label: 'Sort ascending',
    },
    {
      onClick: e => {
        dataDispatch({
          type: ActionTypes.UPDATE_COLUMN_HEADER,
          columnId: id,
          label: header,
        });
        setSortBy([{ id: id, desc: true }]);
        setExpanded(false);
        setIsShowPopUpFilter(false);
      },
      icon: <ArrowDownIcon />,
      label: 'Sort descending',
    },
  ];
  const propertyIcon = getPropertyIcon(dataType);

  function getHeader() {
    if (id !== 999999) {
      return (
        <>
          <div {...getHeaderProps()} className="th noselect d-inline-block">
            <div
              className="th-content"
              onClick={() => enableSorting && setExpanded(true)}
              ref={setReferenceElement}
            >
              <span className="svg-icon svg-gray icon-margin">
                {propertyIcon}
              </span>
              {label}
            </div>
            <div {...getResizerProps()} className="resizer" />
          </div>
          {expanded && (
            <div className="overlay" onClick={() => {
              setExpanded(false);
              setIsShowPopUpFilter(false);
            }} />
          )}
          {expanded && (
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 9999 }}
              {...attributes.popper}
            >
              <div
                className="bg-white shadow-5 border-radius-md"
                style={{
                  width: 250,
                }}
              >
                <div
                  style={{
                    paddingTop: '0.75rem',
                    paddingLeft: '0.75rem',
                    paddingRight: '0.75rem',
                    paddingBottom: '0.75rem',
                  }}
                >
                  <div>
                    {column.isFilter &&
                      <input
                        ref={textInput}
                        className="form-input"
                        type="text"
                        placeholder={'Search'}
                        style={{ width: '100%' }}
                        value={currentFilterValue ?? filterValue}
                        onChange={e => setCurrentFilterValue(e.target.value)}
                      />
                    }
                  </div>
                  <span className="sorting-agents-label">
                    Sorting
                  </span>
                </div>
                <div
                  className="list-padding"
                  key={shortId()}
                  style={{
                    borderTop: `1px solid ${grey(200)}`,
                  }}
                >
                  {buttons.map((button, index) => (
                    <button
                      type="button"
                      className="sort-button"
                      onMouseDown={button.onClick}
                      key={button.label}
                    >
                      <span className="svg-icon svg-text icon-margin">
                        {button.icon}
                      </span>
                      {button.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    return (
      <div {...getHeaderProps()} className="th noselect d-inline-block">
        <div
          className="th-content d-flex justify-content-center"
          onClick={e =>
            dataDispatch({
              type: 'add_column_to_left',
              columnId: 999999,
              focus: true,
            })
          }
        >
          <span className="svg-icon-sm svg-gray">
            <PlusIcon />
          </span>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (created) {
      setExpanded(true);
    }
  }, [created]);

  useEffect(() => {
    if (filterValue) {
      setExpanded(true);
      setIsShowPopUpFilter(true);
    }
  }, [filterValue])

  useEffect(() => {
    if (isShowPopUpFilter) {
      setExpanded(true);
    }
  }, [isShowPopUpFilter])

  useEffect(() => {
    if (expanded && textInput.current) {
      textInput.current?.focus();
    }
  }, [expanded, textInput.current])

  useEffect(() => {
    setHeader(label);
  }, [label]);

  return getHeader();
}
