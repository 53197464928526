import React, {Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import header_icon from '../../../assets/images/common/user (1).svg';
import i18n from '../../../i18n';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import GenerateButton from '../../../common/cellData/GenerateButton';


const getComponentTypeByStateKey = (key) => {
    switch (key) {
        case 'authority':
            return A_COMMON_DATA_FIELD.SELECT_COUNTRIES;
        case 'dateOfExpiry':
        case 'dateOfIssued':
            return A_COMMON_DATA_FIELD.DATE;
        case 'type':
            return A_COMMON_DATA_FIELD.SELECT_IDENTIFICATION_TYPE;
        default:
            return A_COMMON_DATA_FIELD.TEXT;
    }
}

const IdentificationForm = React.memo((props) => {
    const {
        identification,
        onChange,
        canEdit,
    } = props;
    const handleChange = useCallback((key) => {
        return (event) => {
            const value = event?.target?.value ? event?.target?.value : event;
            onChange({ ...identification, [key]: value});
        }
    },[identification, onChange]);

  return (
    <>
      {['authority', 'dateOfExpiry', 'dateOfIssued', 'number', 'type'].map(key => {
        return (
          <div className='player-identifications__item'>
              <ACommonDataField
                key={`${key}_${identification.tempId}`}
                component={getComponentTypeByStateKey(key)}
                label={i18n.t(`crm.identification.${key}`)}
                value={identification[key] || ''}
                handleChange={handleChange(key)}
                datePickerComponentType="MobileDatePicker"
                disabled={!canEdit}
              />
          </div>
        )
      })}
    </>
  )
});


function IdentificationsSubBlock(props) {
    const {
        player,
        onChange,
        canEdit,
    } = props;
    const handleChange = useCallback((identification, tempId) => {
        const newArray = [...player.personalIdentifiers];
        const index = newArray.findIndex(item => item.tempId === tempId);

        newArray[index] = identification;

        onChange('personalIdentifiers')(newArray);
    },[player, onChange]);

    const handleRemove = useCallback(tempId => {
        const updatedArray = [...player.personalIdentifiers].filter(item => item.tempId !== tempId);

        onChange('personalIdentifiers')(updatedArray);
    },[player, onChange]);

    const handleAdd = useCallback(() => {
        const newArray = [...player.personalIdentifiers];
        newArray.push({
            tempId: `${Date.now()}__${Math.random()}`,
            authority: '',
            dateOfExpiry: '',
            dateOfIssued: '',
            number: '',
            type: '',
        })

        onChange('personalIdentifiers')(newArray);
    },[player, onChange]);

    return (
        <div className="sub-content">
            <div className="sub-content__header d-flex align-items-center">
                <img className="me-3" src={header_icon} alt="" />
                <span className="text-uppercase">
                    {i18n.t('crm.identifications')}
                </span>
            </div>

            <div className="sub-content__content d-flex flex-wrap">
                <GenerateButton
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                        canEdit ? handleAdd() : () => {}
                    }}
                    icon={'add'}
                    text={i18n.t(`crm.identification.addId`)}
                />

                {player.personalIdentifiers.map(item => (
                    <Fragment key={item.tempId}>
                        <hr className='dashedHr'/>
                        <GenerateButton
                            style={{ marginRight: '10px' }}
                            onClick={() => handleRemove(item.tempId)}
                            icon={'remove'}
                            text={i18n.t(`crm.identification.removeId`)}
                        />
                        <hr className='dashedHr'/>

                        <div className='player-identifications'>
                            <IdentificationForm
                            key={item.tempId}
                            identification={item}
                            onChange={(identification) => handleChange(identification, item.tempId)}
                            canEdit={canEdit}
                            />
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    );
}

IdentificationsSubBlock.propTypes = {
    player: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default React.memo(IdentificationsSubBlock);
