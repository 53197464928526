export const GET_PENDING_TRANSFER_WITHDRAWAL_LIST = 'GET_PENDING_TRANSFER_WITHDRAWAL_LIST';
export const GET_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS =
    'GET_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS';
export const GET_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL =
    'GET_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL';

export const RESET_PENDING_TRANSFER_WITHDRAWAL_LIST_ALERTS_ERRORS =
    'RESET_PENDING_TRANSFER_WITHDRAWAL_LIST_ALERTS_ERRORS';

export const DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST = 'DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST';
export const DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS = 'DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS';
export const DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL = 'DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL';

export const GET_TRANSFER_WITHDRAWAL_COUNT = 'GET_TRANSFER_WITHDRAWAL_COUNT';
export const GET_TRANSFER_WITHDRAWAL_COUNT_SUCCESS = 'GET_TRANSFER_WITHDRAWAL_COUNT_SUCCESS';
export const GET_TRANSFER_WITHDRAWAL_COUNT_FAIL = 'GET_TRANSFER_WITHDRAWAL_COUNT_FAIL';
