import {
    FETCH_BRAND_SETTINGS_PAGE,
    BRAND_SETTINGS_PAGE_ERROR,
    CHANGE_BRAND_SETTINGS_PAGE,
    FETCH_BRAND_SETTINGS_PAGE_SUCCESS,
    CHANGE_BRAND_SETTINGS_PAGE_SUCCESS,
    RESET_BRAND_SETTINGS_ALERTS_ERRORS,
    SET_EDIT_BRAND_SETTINGS,
    GET_SMARTICO_SETTINGS,
    GET_SMARTICO_SETTINGS_SUCCESS,
    GET_SMARTICO_SETTINGS_ERROR,
    GET_BRAND_TAGS,
    GET_BRAND_TAGS_SUCCESS,
    GET_BRAND_TAGS_ERROR,
    GET_ANTI_FRAUD_RULES,
    GET_ANTI_FRAUD_RULES_SUCCESS,
    GET_ANTI_FRAUD_RULES_ERROR,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS,
    GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR,
    UPDATE_ANTI_FRAUD_RULES,
    UPDATE_ANTI_FRAUD_RULES_SUCCESS,
    UPDATE_ANTI_FRAUD_RULES_ERROR,
    FETCH_VIP_LEVEL_CONFIG,
    FETCH_VIP_LEVEL_CONFIG_SUCCESS,
    GET_COOL_OFF_DATA,
    GET_COOL_OFF_DATA_SUCCESS,
    GET_COOL_OFF_DATA_ERROR,
    UPDATE_COOL_OFF_DATA,
    UPDATE_COOL_OFF_DATA_SUCCESS,
    UPDATE_COOL_OFF_DATA_ERROR,
    DELETE_COOL_OFF_DATA,
    DELETE_COOL_OFF_DATA_SUCCESS,
    DELETE_COOL_OFF_DATA_ERROR,
} from './actionTypes';
import * as moment from 'moment';

const INITIAL_STATE = {
    isLoading: false,
    isSmarticoSettingsLoading: false,
    isBrandTagsLoading: false,
    isFraudRulesLoading: false,
    isFraudRestrictionsUpdated: false,
    brandVipLevelNameMapping: {},
    brandVipLevelBadgeColor: {},
    coolOffData: [],
    isLoadingCoolOffData: false,
    isCoolOffUpdated: false,
    data: {
        generalInfo: {
            name: '-',
            status: 'Active',
            createdTime: moment(),
            lastChanged: moment(),
            jurisdiction: '-'
        },
        crmCommunication: {
            requiredFieldsForCMS: [],
            uniqueFieldsForCMS: [],
            kyc: [],
            smsProviderAccount: '',
            smsProviderAuthToken: '',
            emailProviderApiKey: '',
        },
        configurationFinancial: {
            timeZone: '',
            cdnUrl: '',
            currency: '',
            maxDeposit: 0,
            maxWithdrawal: 0,
            walletPriorities: [],
            privateStorage: {
                bucketName: '',
                secretAccessKey: '',
                accessKeyId: ''
            },
            publicStorage: {
                bucketName: '',
                secretAccessKey: '',
                accessKeyId: ''
            }
        },
        securityLimits: {
            currency: '',
            token: {
                secretKey: '',
                expiration: 0
            },
            refreshToken: {
                secretKey: '',
                expiration: 0
            },
            domains: [],
            whitelistIP: [],
            blockedCountries: [],
            depositLimitLevel: [],
            withdrawalLimitLevel: []
        }
    },
    error: {},
    successMessage: '',
    isEdit: false,
    smarticoSettings: {
        labelKey: '',
        publicKey: '',
    },
    brandTags: [],
    antiFraudActions: [],
    antiFraudRules: [],
};

const BrandSettingsPage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_BRAND_SETTINGS_PAGE:
            return { ...state, isLoading: true, successMessage: '' };
        case FETCH_BRAND_SETTINGS_PAGE_SUCCESS:
            return { ...state, isLoading: false, data: action.payload };
        case FETCH_VIP_LEVEL_CONFIG:
            return { ...state, isLoading: true, successMessage: '' };
        case FETCH_VIP_LEVEL_CONFIG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brandVipLevelNameMapping: action.payload?.brandVipLevelNameMapping,
                brandVipLevelBadgeColor: action.payload?.brandVipLevelBadgeColor,
            };
        case CHANGE_BRAND_SETTINGS_PAGE:
            return { ...state, isLoading: true };
        case CHANGE_BRAND_SETTINGS_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.response,
                successMessage: action.payload.successMessage
            };
        case BRAND_SETTINGS_PAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                successMessage: ''
            };

        case SET_EDIT_BRAND_SETTINGS:
            return { ...state, isEdit: action.payload };

        case RESET_BRAND_SETTINGS_ALERTS_ERRORS:
            return { ...state, error: {}, successMessage: '' };

        case GET_SMARTICO_SETTINGS:
            return {
                ...state,
                isSmarticoSettingsLoading: true,
            };

        case GET_SMARTICO_SETTINGS_SUCCESS:
            const smarticoSettings = action.payload;

            return {
                ...state,
                isSmarticoSettingsLoading: false,
                smarticoSettings,
            };

        case GET_SMARTICO_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload,
                isSmarticoSettingsLoading: false,
            };

        case GET_BRAND_TAGS:
            return {
                ...state,
                isBrandTagsLoading: true,
            };

        case GET_BRAND_TAGS_SUCCESS:
            const brandTags = action.payload.tags;

            return {
                ...state,
                isBrandTagsLoading: false,
                brandTags,
            };

        case GET_BRAND_TAGS_ERROR:
            return {
                ...state,
                error: action.payload,
                isBrandTagsLoading: false,
            };

        case GET_ANTI_FRAUD_RULES:
            return {
                ...state,
                isFraudRulesLoading: true,
            };

        case GET_ANTI_FRAUD_RULES_SUCCESS:

            return {
                ...state,
                antiFraudRules: action.payload,
                isFraudRulesLoading: false,
            };

        case GET_ANTI_FRAUD_RULES_ERROR:
            return {
                ...state,
                error: action.payload,
                isFraudRulesLoading: false,
            };

        case GET_AVAILABLE_ANTI_FRAUD_ACTIONS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS:

            return {
                ...state,
                antiFraudActions: action.payload,
                isLoading: false,
            };

        case GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case UPDATE_ANTI_FRAUD_RULES:
            return {
                ...state,
                isFraudRestrictionsUpdated: true,
                error: null,
            };

        case UPDATE_ANTI_FRAUD_RULES_SUCCESS:
            return {
                ...state,
                antiFraudRules: action.payload,
                isFraudRestrictionsUpdated: false,
                successMessage: 'Updated',
                error: null,
            };

        case UPDATE_ANTI_FRAUD_RULES_ERROR:
            return {
                ...state,
                error: action.payload,
                isFraudRestrictionsUpdated: false,
            };


        case GET_COOL_OFF_DATA:
            return {
                ...state,
                isLoadingCoolOffData: true,
            };

        case GET_COOL_OFF_DATA_SUCCESS:

            return {
                ...state,
                coolOffData: action.payload,
                isLoadingCoolOffData: false,
            };

        case GET_COOL_OFF_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoadingCoolOffData: false,
            };

        case UPDATE_COOL_OFF_DATA:
            return {
                ...state,
                isCoolOffUpdated: true,
                isLoadingCoolOffData: true,
                error: null,
            };

        case UPDATE_COOL_OFF_DATA_SUCCESS:
            return {
                ...state,
                isLoadingCoolOffData: false,
                isCoolOffUpdated: false,
                successMessage: 'Updated',
                error: null,
            };

        case UPDATE_COOL_OFF_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoadingCoolOffData: false,
                isCoolOffUpdated: false,
            };

        case DELETE_COOL_OFF_DATA:
            return {
                ...state,
                isLoadingCoolOffData: true,
                error: null,
            };

        case DELETE_COOL_OFF_DATA_SUCCESS:
            return {
                ...state,
                isLoadingCoolOffData: false,
                successMessage: 'Updated',
                error: null,
            };

        case DELETE_COOL_OFF_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                isLoadingCoolOffData: false,
            };

        default:
            return { ...state };
    }
};

export default BrandSettingsPage;
