import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, Col, Row} from "reactstrap";
import i18n from '../../../i18n';

import useAlertService from "../../../hooks/useAlertService";
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";

import financeIcon from "../../../assets/images/retail/finance.svg";

import "../styles.scss";
import DeclineSubmitBtnGroup from "../../../UI/buttonsGroups/DeclineSubmitBtnGroup";
import {getRetailPlayer} from "../../../store/players/general/actions";
import Loader from "../../../common/components/Loader";
import PaymentInfoSummaryModal from "./PaymentInfoSummaryModal";

const Finance = ({setIsEdit}) => {
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const {retailPlayer, isPaymentRetail, error} = useSelector(state => state.PlayerGeneral);

    const [showConfirmTransaction, setShowConfirmTransaction] = useState(false);
    const [retailPlayerOid, setRetailPlayerOid] = useState(0);

    const switchConfirmModal = () => {
        setShowConfirmTransaction(!showConfirmTransaction);
    }

    const prevState = useRef({
        isPaymentRetail: false,
    })

    const [transactionToken, setTransactionToken] = useState(null);
    useEffect(() => {
        if (isPaymentRetail === false && prevState.current.isPaymentRetail) {
            if (error) {
                if (error?.success === false) {
                    alertService.showError(i18n.t('crm.updateFail'));
                } else {
                    alertService.showError(i18n.t('crm.updateFail'));
                }
            } else {
                setTransactionToken(null);
                dispatch(getRetailPlayer(retailPlayerOid));
                alertService.showSuccess(i18n.t('crm.successfullySaved'));
            }
        }
        prevState.current = {
            ...prevState.current,
            isPaymentRetail,
        }
    }, [isPaymentRetail, error, retailPlayerOid]);

    const handleChangeAmount = () => value => {
        setTransactionToken(value);
        setIsEdit(!!value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (transactionToken) {
            switchConfirmModal()
        }
    }

    const handleCancel = useCallback(() => {
        setTransactionToken(null);
    }, []);

    return (
        <>
            {showConfirmTransaction &&
                <PaymentInfoSummaryModal
                    playerId={retailPlayer?.id}
                    token={transactionToken} setIsShowTransactionInfo={switchConfirmModal}
                    setRetailPlayerOid={setRetailPlayerOid}
                />
            }
            <Row>
                <Col lg="12">
                    <Card className="retail-finance">
                        <div className="title-section">
                            <img
                                src={financeIcon}
                                alt="icon finance"
                            />
                            {i18n.t('retail.customer.title.finance')}
                        </div>
                        <div className={'my-4 finance-token-input'}>
                            <ACommonDataField
                                label={i18n.t("retail.customer.finance.token")}
                                value={transactionToken || ''}
                                handleChange={handleChangeAmount('token')}
                            />
                        </div>
                        <Col lg="12">
                            <div>
                                {transactionToken && (
                                    <div
                                        className={"finance_save_btns"}
                                    >
                                        <DeclineSubmitBtnGroup
                                            onSave={onSubmit}
                                            onCancel={handleCancel}
                                            isDisabled={!transactionToken || isPaymentRetail}
                                            saveMsg={i18n.t("admin.submit")}
                                        />
                                        {isPaymentRetail &&
                                            <div
                                                className={"finance_loader"}
                                            >
                                                <Loader size={'sm'}/>
                                            </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

export default Finance;
