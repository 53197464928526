import {
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GET_GAMES_FAIL,
    CREATE_GAME,
    CREATE_GAME_SUCCESS,
    CREATE_GAME_FAIL,
    UPDATE_GAME,
    UPDATE_GAME_SUCCESS,
    UPDATE_GAME_FAIL,
    BULK_INSERT_GAMES,
    BULK_INSERT_GAMES_SUCCESS,
    BULK_INSERT_GAMES_FAIL,
    DOWNLOAD_GAME_LIST,
    DOWNLOAD_GAME_LIST_SUCCESS,
    DOWNLOAD_GAME_LIST_FAIL,
    GET_ALL_GAMES,
    GET_GAME_TYPES,
    GET_GAME_TYPES_SUCCESS,
    GET_GAME_TYPES_FAIL,
    GET_GAME_CATEGORIES,
    GET_GAME_CATEGORIES_SUCCESS,
    GET_GAME_CATEGORIES_FAIL,
    CREATE_GAME_CATEGORY,
    CREATE_GAME_CATEGORY_SUCCESS,
    CREATE_GAME_CATEGORY_FAIL,
    GET_GAME_CATEGORY,
    GET_GAME_CATEGORY_SUCCESS,
    GET_GAME_CATEGORY_FAIL,
    GET_CATEGORY_GAMES,
    GET_CATEGORY_GAMES_SUCCESS,
    GET_CATEGORY_GAMES_FAIL,
    GET_SITE_GAMES,
    GET_SITE_GAMES_SUCCESS,
    GET_SITE_GAMES_FAIL,
    GET_SITE_AVAILABLE_GAMES,
    GET_SITE_AVAILABLE_GAMES_SUCCESS,
    GET_SITE_AVAILABLE_GAMES_FAIL,
    PUT_CATEGORY_GAMES,
    PUT_CATEGORY_GAMES_SUCCESS,
    PUT_CATEGORY_GAMES_FAIL,
    PUT_CATEGORY_GAME,
    PUT_CATEGORY_GAME_SUCCESS,
    PUT_CATEGORY_GAME_FAIL,
    BULK_UPDATE_CATEGORY_GAMES,
    BULK_UPDATE_CATEGORY_GAMES_SUCCESS,
    BULK_UPDATE_CATEGORY_GAMES_FAIL,
    DOWNLOAD_CATEGORY_GAME_LIST,
    DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS,
    DOWNLOAD_CATEGORY_GAME_LIST_FAIL,
    PUT_SITE_GAMES,
    PUT_SITE_GAMES_SUCCESS,
    PUT_SITE_GAMES_FAIL,
    BULK_UPDATE_SITE_GAMES,
    BULK_UPDATE_SITE_GAMES_SUCCESS,
    BULK_UPDATE_SITE_GAMES_FAIL,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL,
    UPDATE_SITE_GAME,
    UPDATE_SITE_GAME_SUCCESS,
    UPDATE_SITE_GAME_FAIL,
    PUT_CATEGORY_DATA,
    PUT_CATEGORY_DATA_SUCCESS,
    PUT_CATEGORY_DATA_FAIL,
    REMOVE_CATEGORY,
    REMOVE_CATEGORY_SUCCESS,
    REMOVE_CATEGORY_FAIL,
    REMOVE_CATEGORY_GAME,
    REMOVE_CATEGORY_GAME_SUCCESS,
    REMOVE_CATEGORY_GAME_FAIL,
    REMOVE_SITE_GAME,
    REMOVE_SITE_GAME_SUCCESS,
    REMOVE_SITE_GAME_FAIL,
    REMOVE_SITE_GAMES,
    REMOVE_SITE_GAMES_SUCCESS,
    REMOVE_SITE_GAMES_FAIL,
    DOWNLOAD_SITE_GAME_LIST,
    DOWNLOAD_SITE_GAME_LIST_SUCCESS,
    DOWNLOAD_SITE_GAME_LIST_FAIL,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS,
    DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL,
    RESET_GAME_CATEGORY,
    GET_GAME_VENDORS,
    GET_GAME_VENDORS_SUCCESS,
    GET_GAME_VENDORS_FAIL,
    GET_GAME_PROVIDERS,
    GET_GAME_PROVIDERS_SUCCESS,
    GET_GAME_PROVIDERS_FAIL,
    GET_SITE_PROVIDERS,
    GET_SITE_PROVIDERS_SUCCESS,
    GET_SITE_PROVIDERS_FAIL,
    PURGE_CATEGORY_CACHE,
    PURGE_CATEGORY_CACHE_SUCCESS,
    PURGE_CATEGORY_CACHE_FAIL,
    DOWNLOAD_GAMES_DB_TEMPLATE,
    DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS,
    DOWNLOAD_GAMES_DB_TEMPLATE_FAIL,
    UPLOAD_GAMESDB_TO_CREATE,
    UPLOAD_GAMESDB_TO_CREATE_SUCCESS,
    UPLOAD_GAMESDB_TO_CREATE_FAIL,
    DOWNLOAD_GM_TEMPLATE,
    DOWNLOAD_GM_TEMPLATE_SUCCESS,
    DOWNLOAD_GM_TEMPLATE_FAIL,
    UPDATE_GM_GAMES,
    UPDATE_GM_GAMES_SUCCESS,
    UPDATE_GM_GAMES_FAIL,
    UPDATE_DB_GAMES,
    UPDATE_DB_GAMES_SUCCESS,
    UPDATE_DB_GAMES_FAIL,
    DOWNLOAD_GM_GAMES,
    DOWNLOAD_GM_GAMES_SUCCESS,
    DOWNLOAD_GM_GAMES_FAIL,
} from './actionTypes';

export const createGame = payload => ({
    type: CREATE_GAME,
    payload
});
export const createGameSuccess = payload => ({
    type: CREATE_GAME_SUCCESS,
    payload
});
export const createGameFail = payload => ({
    type: CREATE_GAME_FAIL,
    payload
});

export const updateGame = payload => ({
    type: UPDATE_GAME,
    payload
});
export const updateGameSuccess = payload => ({
    type: UPDATE_GAME_SUCCESS,
    payload
});
export const updateGameFail = payload => ({
    type: UPDATE_GAME_FAIL,
    payload
});

export const bulkInsertGames = payload => ({
    type: BULK_INSERT_GAMES,
    payload
});
export const bulkInsertGamesSuccess = payload => ({
    type: BULK_INSERT_GAMES_SUCCESS,
    payload
});
export const bulkInsertGamesFail = payload => ({
    type: BULK_INSERT_GAMES_FAIL,
    payload
});

export const uploadGamesDBToCreate = payload => ({
    type: UPLOAD_GAMESDB_TO_CREATE,
    payload
});
export const uploadGamesDBToCreateSuccess = payload => ({
    type: UPLOAD_GAMESDB_TO_CREATE_SUCCESS,
    payload
});
export const uploadGamesDBToCreateFail = payload => ({
    type: UPLOAD_GAMESDB_TO_CREATE_FAIL,
    payload
});

export const downloadGameList = payload => ({
    type: DOWNLOAD_GAME_LIST,
    payload
});

export const downloadGameListSuccess = payload => ({
    type: DOWNLOAD_GAME_LIST_SUCCESS,
    payload
});

export const downloadGameListFail = payload => ({
    type: DOWNLOAD_GAME_LIST_FAIL,
    payload
});

export const getGameTypes = payload => ({
    type: GET_GAME_TYPES,
    payload
});
export const getGameTypesSuccess = payload => ({
    type: GET_GAME_TYPES_SUCCESS,
    payload
});
export const getGameTypesFail = payload => ({
    type: GET_GAME_TYPES_FAIL,
    payload
});

export const getGameCategories = payload => ({
    type: GET_GAME_CATEGORIES,
    payload
});
export const getGameCategoriesSuccess = payload => ({
    type: GET_GAME_CATEGORIES_SUCCESS,
    payload
});
export const getGameCategoriesFail = payload => ({
    type: GET_GAME_CATEGORIES_FAIL,
    payload
});

export const createGameCategory = payload => ({
    type: CREATE_GAME_CATEGORY,
    payload
});
export const createGameCategorySuccess = payload => ({
    type: CREATE_GAME_CATEGORY_SUCCESS,
    payload
});
export const createGameCategoryFail = payload => ({
    type: CREATE_GAME_CATEGORY_FAIL,
    payload
});
export const resetGameCategory = () => ({
    type: RESET_GAME_CATEGORY,
});

export const purgeCacheGameCategory = () => ({
    type: PURGE_CATEGORY_CACHE,
});

export const purgeCacheGameCategorySuccess = () => ({
    type: PURGE_CATEGORY_CACHE_SUCCESS,
});

export const purgeCacheGameCategoryFail = (payload) => ({
    type: PURGE_CATEGORY_CACHE_FAIL,
    payload
});

export const getGameCategory = payload => ({
    type: GET_GAME_CATEGORY,
    payload
});
export const getGameCategorySuccess = payload => ({
    type: GET_GAME_CATEGORY_SUCCESS,
    payload
});
export const getGameCategoryFail = payload => ({
    type: GET_GAME_CATEGORY_FAIL,
    payload
});

export const downloadCategoryGameList = payload => ({
    type: DOWNLOAD_CATEGORY_GAME_LIST,
    payload
});

export const downloadCategoryGameListSuccess = payload => ({
    type: DOWNLOAD_CATEGORY_GAME_LIST_SUCCESS,
    payload
});

export const downloadCategoryGameListFail = payload => ({
    type: DOWNLOAD_CATEGORY_GAME_LIST_FAIL,
    payload
});

export const getGames = payload => ({
    type: GET_GAMES,
    payload
});
export const getGamesSuccess = payload => ({
    type: GET_GAMES_SUCCESS,
    payload
});
export const getGamesFail = payload => ({
    type: GET_GAMES_FAIL,
    payload
});

export const getAllGames = payload => ({
    type: GET_ALL_GAMES,
    payload
});

export const getCategoryGames = payload => ({
    type: GET_CATEGORY_GAMES,
    payload
});
export const getCategoryGamesSuccess = payload => ({
    type: GET_CATEGORY_GAMES_SUCCESS,
    payload
});
export const getCategoryGamesFail = payload => ({
    type: GET_CATEGORY_GAMES_FAIL,
    payload
});

export const putCategoryGames = payload => ({
    type: PUT_CATEGORY_GAMES,
    payload
});
export const putCategoryGamesSuccess = payload => ({
    type: PUT_CATEGORY_GAMES_SUCCESS,
    payload
});
export const putCategoryGamesFail = payload => ({
    type: PUT_CATEGORY_GAMES_FAIL,
    payload
});

export const putCategoryGame = payload => ({
    type: PUT_CATEGORY_GAME,
    payload
});
export const putCategoryGameSuccess = payload => ({
    type: PUT_CATEGORY_GAME_SUCCESS,
    payload
});
export const putCategoryGameFail = payload => ({
    type: PUT_CATEGORY_GAME_FAIL,
    payload
});

export const putCategoryData = payload => ({
    type: PUT_CATEGORY_DATA,
    payload
});
export const putCategoryDataSuccess = payload => ({
    type: PUT_CATEGORY_DATA_SUCCESS,
    payload
});
export const putCategoryDataFail = payload => ({
    type: PUT_CATEGORY_DATA_FAIL,
    payload
});

export const bulkUpdateCategoryGames = payload => ({
    type: BULK_UPDATE_CATEGORY_GAMES,
    payload
});
export const bulkUpdateCategoryGamesSuccess = payload => ({
    type: BULK_UPDATE_CATEGORY_GAMES_SUCCESS,
    payload
});
export const bulkUpdateCategoryGamesFail = payload => ({
    type: BULK_UPDATE_CATEGORY_GAMES_FAIL,
    payload
});

export const removeCategory = payload => ({
    type: REMOVE_CATEGORY,
    payload
});
export const removeCategorySuccess = payload => ({
    type: REMOVE_CATEGORY_SUCCESS,
    payload
});
export const removeCategoryFail = payload => ({
    type: REMOVE_CATEGORY_FAIL,
    payload
});

export const removeCategoryGame = payload => ({
    type: REMOVE_CATEGORY_GAME,
    payload
});
export const removeCategoryGameSuccess = payload => ({
    type: REMOVE_CATEGORY_GAME_SUCCESS,
    payload
});
export const removeCategoryGameFail = payload => ({
    type: REMOVE_CATEGORY_GAME_FAIL,
    payload
});

export const getSiteGames = payload => ({
    type: GET_SITE_GAMES,
    payload
});
export const getSiteGamesSuccess = payload => ({
    type: GET_SITE_GAMES_SUCCESS,
    payload
});
export const getSiteGamesFail = payload => ({
    type: GET_SITE_GAMES_FAIL,
    payload
});

export const getSiteAvailableGames = payload => ({
    type: GET_SITE_AVAILABLE_GAMES,
    payload
});
export const getSiteAvailableGamesSuccess = payload => ({
    type: GET_SITE_AVAILABLE_GAMES_SUCCESS,
    payload
});
export const getSiteAvailableGamesFail = payload => ({
    type: GET_SITE_AVAILABLE_GAMES_FAIL,
    payload
});

export const bulkUpdateSiteGames = payload => ({
    type: BULK_UPDATE_SITE_GAMES,
    payload
});
export const bulkUpdateSiteGamesSuccess = payload => ({
    type: BULK_UPDATE_SITE_GAMES_SUCCESS,
    payload
});
export const bulkUpdateSiteGamesFail = payload => ({
    type: BULK_UPDATE_SITE_GAMES_FAIL,
    payload
});

export const bulkUpdateSiteGameAttributions = payload => ({
    type: BULK_UPDATE_SITE_GAME_ATTRIBUTIONS,
    payload
});
export const bulkUpdateSiteGameAttributionsSuccess = payload => ({
    type: BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    payload
});
export const bulkUpdateSiteGameAttributionsFail = payload => ({
    type: BULK_UPDATE_SITE_GAME_ATTRIBUTIONS_FAIL,
    payload
});

export const updateSiteGame = payload => ({
    type: UPDATE_SITE_GAME,
    payload
});
export const updateSiteGameSuccess = payload => ({
    type: UPDATE_SITE_GAME_SUCCESS,
    payload
});
export const updateSiteGameFail = payload => ({
    type: UPDATE_SITE_GAME_FAIL,
    payload
});

export const putSiteGames = payload => ({
    type: PUT_SITE_GAMES,
    payload
});
export const putSiteGamesSuccess = payload => ({
    type: PUT_SITE_GAMES_SUCCESS,
    payload
});
export const putSiteGamesFail = payload => ({
    type: PUT_SITE_GAMES_FAIL,
    payload
});

export const removeSiteGame = payload => ({
    type: REMOVE_SITE_GAME,
    payload
});
export const removeSiteGameSuccess = payload => ({
    type: REMOVE_SITE_GAME_SUCCESS,
    payload
});
export const removeSiteGameFail = payload => ({
    type: REMOVE_SITE_GAME_FAIL,
    payload
});

export const removeSiteGames = payload => ({
    type: REMOVE_SITE_GAMES,
    payload
});
export const removeSiteGamesSuccess = payload => ({
    type: REMOVE_SITE_GAMES_SUCCESS,
    payload
});
export const removeSiteGamesFail = payload => ({
    type: REMOVE_SITE_GAMES_FAIL,
    payload
});

export const downloadSiteGameList = payload => ({
    type: DOWNLOAD_SITE_GAME_LIST,
    payload
});

export const downloadSiteGameListSuccess = payload => ({
    type: DOWNLOAD_SITE_GAME_LIST_SUCCESS,
    payload
});

export const downloadSiteGameListFail = payload => ({
    type: DOWNLOAD_SITE_GAME_LIST_FAIL,
    payload
});

export const downloadAvailableSiteGameList = payload => ({
    type: DOWNLOAD_AVAILABLE_SITE_GAME_LIST,
    payload
});

export const downloadAvailableSiteGameListSuccess = payload => ({
    type: DOWNLOAD_AVAILABLE_SITE_GAME_LIST_SUCCESS,
    payload
});

export const downloadAvailableSiteGameListFail = payload => ({
    type: DOWNLOAD_AVAILABLE_SITE_GAME_LIST_FAIL,
    payload
});

export const downloadSiteGameAttributions = payload => ({
    type: DOWNLOAD_SITE_GAME_ATTRIBUTIONS,
    payload
});

export const downloadSiteGameAttributionsSuccess = payload => ({
    type: DOWNLOAD_SITE_GAME_ATTRIBUTIONS_SUCCESS,
    payload
});

export const downloadSiteGameAttributionsFail = payload => ({
    type: DOWNLOAD_SITE_GAME_ATTRIBUTIONS_FAIL,
    payload
});

export const getGameVendors = payload => ({
    type: GET_GAME_VENDORS,
    payload
});
export const getGameVendorsSuccess = payload => ({
    type: GET_GAME_VENDORS_SUCCESS,
    payload
});
export const getGameVendorsFail = payload => ({
    type: GET_GAME_VENDORS_FAIL,
    payload
});

export const getGameProviders = () => ({
    type: GET_GAME_PROVIDERS
});
export const getGameProvidersSuccess = payload => ({
    type: GET_GAME_PROVIDERS_SUCCESS,
    payload
});
export const getGameProvidersFail = payload => ({
    type: GET_GAME_PROVIDERS_FAIL,
    payload
});

export const getSiteProviders = () => ({
    type: GET_SITE_PROVIDERS
});
export const getSiteProvidersSuccess = payload => ({
    type: GET_SITE_PROVIDERS_SUCCESS,
    payload
});
export const getSiteProvidersFail = payload => ({
    type: GET_SITE_PROVIDERS_FAIL,
    payload
});

export const downloadGamesDBTemplate = payload => ({
    type: DOWNLOAD_GAMES_DB_TEMPLATE,
    payload
});

export const downloadGamesDBTemplateSuccess = payload => ({
    type: DOWNLOAD_GAMES_DB_TEMPLATE_SUCCESS,
    payload
});

export const downloadGamesDBTemplateFail = payload => ({
    type: DOWNLOAD_GAMES_DB_TEMPLATE_FAIL,
    payload
});

export const downloadGMTemplate = payload => ({
    type: DOWNLOAD_GM_TEMPLATE,
    payload
});

export const downloadGMTemplateSuccess = payload => ({
    type: DOWNLOAD_GM_TEMPLATE_SUCCESS,
    payload
});

export const downloadGMTemplateFail = payload => ({
    type: DOWNLOAD_GM_TEMPLATE_FAIL,
    payload
});

export const downloadGMGames = payload => ({
    type: DOWNLOAD_GM_GAMES,
    payload
});

export const downloadGMGamesSuccess = payload => ({
    type: DOWNLOAD_GM_GAMES_SUCCESS,
    payload
});

export const downloadGMGamesFail = payload => ({
    type: DOWNLOAD_GM_GAMES_FAIL,
    payload
});

export const updateGMGames = payload => ({
    type: UPDATE_GM_GAMES,
    payload
});

export const updateGMGamesSuccess = payload => ({
    type: UPDATE_GM_GAMES_SUCCESS,
    payload
});

export const updateGMGamesFail = payload => ({
    type: UPDATE_GM_GAMES_FAIL,
    payload
});

export const updateDBGames = payload => ({
    type: UPDATE_DB_GAMES,
    payload
});

export const updateDBGamesSuccess = payload => ({
    type: UPDATE_DB_GAMES_SUCCESS,
    payload
});

export const updateDBGamesFail = payload => ({
    type: UPDATE_DB_GAMES_FAIL,
    payload
});