export const GET_PLAYER_VERIFICATION = 'GET_PLAYER_VERIFICATION';
export const GET_PLAYER_VERIFICATION_SUCCESS =
    'GET_PLAYER_VERIFICATION_SUCCESS';
export const GET_PLAYER_VERIFICATION_FAIL = 'GET_PLAYER_VERIFICATION_FAIL';

export const RESET_PLAYER_VERIFICATION_ALERTS_ERRORS =
    'RESET_PLAYER_VERIFICATION_ALERTS_ERRORS';

export const SET_EDIT_PLAYER_VERIFICATION = 'SET_EDIT_PLAYER_VERIFICATION';
export const UPDATE_PLAYER_VERIFICATION = 'UPDATE_PLAYER_VERIFICATION';
export const UPDATE_PLAYER_VERIFICATION_SUCCESS =
    'UPDATE_PLAYER_VERIFICATION_SUCCESS';
export const UPDATE_PLAYER_VERIFICATION_FAIL =
    'UPDATE_PLAYER_VERIFICATION_FAIL';

export const GET_PLAYER_VERIFICATION_HISTORY_LIST =
    'GET_PLAYER_VERIFICATION_HISTORY_LIST';
export const GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS =
    'GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS';
export const GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL =
    'GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL';
export const RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS =
    'RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS';

export const GET_PLAYER_SOF_AGREEMENT = 'GET_PLAYER_SOF_AGREEMENT';
export const GET_PLAYER_SOF_AGREEMENT_SUCCESS = 'GET_PLAYER_SOF_AGREEMENT_SUCCESS';
export const GET_PLAYER_SOF_AGREEMENT_FAIL = 'GET_PLAYER_SOF_AGREEMENT_FAIL';

export const GET_PLAYER_SELF_EXCLUSION_AGREEMENT =
    'GET_PLAYER_SELF_EXCLUSION_AGREEMENT';
export const GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS =
    'GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS';
export const GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL =
    'GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL';

export const GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT =
    'GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT';
export const GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS =
    'GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS';
export const GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL =
    'GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL';
