export const GET_PLAYER_GAME_PLAY = 'GET_PLAYER_GAME_PLAY';
export const GET_PLAYER_GAME_PLAY_SUCCESS =
	'GET_PLAYER_GAME_PLAY_SUCCESS';
export const GET_PLAYER_GAME_PLAY_FAIL = 'GET_PLAYER_GAME_PLAY_FAIL';

export const GET_PLAYER_GAME_SESSIONS = 'GET_PLAYER_GAME_SESSIONS';
export const GET_PLAYER_GAME_SESSIONS_SUCCESS =
	'GET_PLAYER_GAME_SESSIONS_SUCCESS';
export const GET_PLAYER_GAME_SESSIONS_FAIL = 'GET_PLAYER_GAME_SESSIONS_FAIL';

export const GET_PLAYER_GAME_ROUNDS = 'GET_PLAYER_GAME_ROUNDS';
export const GET_PLAYER_GAME_ROUNDS_SUCCESS =
	'GET_PLAYER_GAME_ROUNDS_SUCCESS';
export const GET_PLAYER_GAME_ROUNDS_FAIL = 'GET_PLAYER_GAME_ROUNDS_FAIL';

export const GET_PLAYER_BET_SLIP = 'GET_PLAYER_BET_SLIP';
export const GET_PLAYER_BET_SLIP_SUCCESS =
	'GET_PLAYER_BET_SLIP_SUCCESS';
export const GET_PLAYER_BET_SLIP_FAIL = 'GET_PLAYER_BET_SLIP_FAIL';

export const GET_PLAYER_GAME_SESSION_ROUNDS = 'GET_PLAYER_GAME_SESSION_ROUNDS';
export const GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS =
	'GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS';
export const GET_PLAYER_GAME_SESSION_ROUNDS_FAIL = 'GET_PLAYER_GAME_SESSION_ROUNDS_FAIL';

export const DOWNLOAD_GAME_PLAY_LIST = 'DOWNLOAD_GAME_PLAY_LIST';
export const DOWNLOAD_GAME_PLAY_LIST_SUCCESS = 'DOWNLOAD_GAME_PLAY_LIST_SUCCESS';
export const DOWNLOAD_GAME_PLAY_LIST_FAIL = 'DOWNLOAD_GAME_PLAY_LIST_FAIL';

export const GET_UNIQUE_GAME_IDS = 'DOWNLOAD_UNIQUE_GAMES_IDS';
export const GET_UNIQUE_GAME_IDS_SUCCESS = 'DOWNLOAD_UNIQUE_GAMES_IDS_SUCCESS';
export const GET_UNIQUE_GAME_IDS_FAIL = 'DOWNLOAD_UNIQUE_GAMES_IDS_FAIL';
export const PUT_UNIQUE_GAMES = 'PUT_UNIQUE_GAMES'
