export const GET_WITHDRAWAL_LIST = 'GET_WITHDRAWAL_LIST';
export const GET_WITHDRAWAL_LIST_SUCCESS = 'GET_WITHDRAWAL_LIST_SUCCESS';
export const GET_WITHDRAWAL_LIST_FAIL = 'GET_WITHDRAWAL_LIST_FAIL';

export const RESET_WITHDRAWAL_LIST_ALERTS_ERRORS = 'RESET_WITHDRAWAL_LIST_ALERTS_ERRORS';

export const DOWNLOAD_WITHDRAWAL_LIST = 'DOWNLOAD_WITHDRAWAL_LIST';
export const DOWNLOAD_WITHDRAWAL_LIST_SUCCESS = 'DOWNLOAD_WITHDRAWAL_LIST_SUCCESS';
export const DOWNLOAD_WITHDRAWAL_LIST_FAIL = 'DOWNLOAD_WITHDRAWAL_LIST_FAIL';

export const GET_WITHDRAWAL_COUNT = 'GET_WITHDRAWAL_COUNT';
export const GET_WITHDRAWAL_COUNT_SUCCESS = 'GET_WITHDRAWAL_COUNT_SUCCESS';
export const GET_WITHDRAWAL_COUNT_FAIL = 'GET_WITHDRAWAL_COUNT_FAIL';


