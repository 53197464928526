import {
    GET_PLAYER_DEPOSIT_LIST,
    GET_PLAYER_DEPOSIT_LIST_SUCCESS,
    GET_PLAYER_DEPOSIT_LIST_FAIL,
    RESET_PLAYER_DEPOSIT_LIST_ERRORS,
    DOWNLOAD_PLAYER_DEPOSIT_LIST,
    DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS,
    DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL,
} from './actionTypes';

export const getPlayerDepositList = payload => ({
    type: GET_PLAYER_DEPOSIT_LIST,
    payload
});

export const getPlayerDepositListSuccess = payload => ({
    type: GET_PLAYER_DEPOSIT_LIST_SUCCESS,
    payload
});

export const getPlayerDepositListFail = payload => ({
    type: GET_PLAYER_DEPOSIT_LIST_FAIL,
    payload
});

export const resetPlayerDepositListErrors = payload => ({
    type: RESET_PLAYER_DEPOSIT_LIST_ERRORS,
    payload
});

export const downloadPlayerDepositList = payload => ({
    type: DOWNLOAD_PLAYER_DEPOSIT_LIST,
    payload
});

export const downloadPlayerDepositListSuccess = payload => ({
    type: DOWNLOAD_PLAYER_DEPOSIT_LIST_SUCCESS,
    payload
});

export const downloadPlayerDepositListFail = payload => ({
    type: DOWNLOAD_PLAYER_DEPOSIT_LIST_FAIL,
    payload
});

export const downloadPlayerWithdrawalList = payload => ({
    type: DOWNLOAD_PLAYER_WITHDRAWAL_LIST,
    payload
});

export const downloadPlayerWithdrawalListSuccess = payload => ({
    type: DOWNLOAD_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    payload
});

export const downloadPlayerWithdrawalListFail = payload => ({
    type: DOWNLOAD_PLAYER_WITHDRAWAL_LIST_FAIL,
    payload
});
