import React, { Component } from 'react';

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Sender from './Sender';
import {DATE_TIME_FORMAT} from "../../common/utils/common";

const Reciver = ({ openMessage, message }) => {
    const momentTime = moment(message.time);

    const messagePopup = () => {
        openMessage(message.id);
    };

    return (
        <li>
            <div className='conversation-list'>
                {/*
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle">
                            <i className="bx bx-dots-vertical-rounded" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="#">Copy</DropdownItem>
                            <DropdownItem href="#">Save</DropdownItem>
                            <DropdownItem href="#">Forward</DropdownItem>
                            <DropdownItem href="#">Delete</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
*/}
                <p className='chat-time mb-0'>
                    <i className='bx bx-time-five align-middle me-1' />{' '}
                    {momentTime.format(DATE_TIME_FORMAT)}
                </p>

                <div className='ctext-wrap'>
                    <div className='conversation-name'>
                        {message.name}
                    </div>
                    <p>{message.subject}</p>
{/*
                    <p
                        onClick={messagePopup}
                        className='chat-time mb-0'
                        style={{ fontWeight: '600', color: '#5f5f8b', textAlign: 'right', cursor: 'pointer' }}>Read
                        more</p>
*/}
                </div>
            </div>
        </li>
    );
};

export default withRouter(Reciver);
