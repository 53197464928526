import React, { useCallback, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CommonCard } from '../../CommonCard';
import {
    FILTER_MENU_SELECT_LIST_DISABLE_VALUES,
    FILTER_MENU_SELECT_LIST_DISABLE_TAB_VALUES,
} from "../../../constants/Filter";

export const FilterMenuSelect = ({ list, onSelect, activeTab }) => {
    const [active, setActive] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [filterList, setFilterList] = useState(list);
    const { t } = useTranslation();

    const location = useLocation();
    const playerListPage = location?.pathname?.includes('/player-list');

    const handleClick = useCallback(
        value => () => {
            setActive(value);
        },
        []
    );

    useEffect(() => {
        if (active && Object.keys(active).length) {
            onSelect(active);
        }
    }, [active]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        const filteredList = list.filter(item => item.label.toLowerCase().includes(value.toLowerCase()));
        setSearchValue(value);
        setFilterList(filteredList);
    };


    return (
        <CommonCard className={'filter_card'}>
            <div className='player-list-filter-search'>
                <input
                    type="text"
                    placeholder='Search'
                    className='filter-list-search form-control'
                    value={searchValue}
                    onChange={handleSearchChange}
                />
            </div>
            <SimpleBar
                style={{ maxHeight: 300 }}
                autoHide={false}
                className={`${list.length > 8 ? 'scrollbar' : ''}`}
            >
                <div>
                    <ul className={'filter_list'}>
                        {filterList.map((item, index) => {
                            const isDisabled = playerListPage &&
                                FILTER_MENU_SELECT_LIST_DISABLE_VALUES.hasOwnProperty(item?.value.toUpperCase())
                                || activeTab !== ''
                                    && FILTER_MENU_SELECT_LIST_DISABLE_TAB_VALUES[activeTab]?.includes(item?.value);
                            return (
                                <li
                                    key={index}
                                    onClick={!isDisabled && handleClick(item)}
                                    className={`filter_list-element${
                                        active.value === item.value
                                            ? ' filter_list-active'
                                            : ''
                                    }${
                                        isDisabled
                                            ? ' filter_list-disabled'
                                            : ''
                                    }`}
                                >
                                    {item.label}
                                </li>
                            )
                        })}
                        {!filterList.length &&
                            <div style={{padding: '10px'}}>
                                {t('filter.noFilters')}
                            </div>
                        }
                    </ul>
                </div>
            </SimpleBar>
        </CommonCard>
    );
};

FilterMenuSelect.proptypes = {
    list: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
};

FilterMenuSelect.defaultProps = {
    activeTab: '',
};
