const ACTION_TYPE = {
    APPROVE_PAYMENT: 'APPROVE_PAYMENT',
    BLOCK_PLAYER: 'BLOCK_PLAYER',
    BLOCK_DEPOSIT: 'BLOCK_DEPOSIT',
    BLOCK_WITHDRAWAL: 'BLOCK_WITHDRAWAL',
    BLOCK_GAME_PLAY: 'BLOCK_GAME_PLAY',
    BLOCK_PAYMENT_METHOD: 'BLOCK_PAYMENT_METHOD',
    COMMUNICATE_TO_PLAYER: 'COMMUNICATE_TO_PLAYER',
    CANCEL_KYC_TO_PLAYER: 'CANCEL_KYC_TO_PLAYER',
    CANCEL_PHONE_VERIFICATION: 'CANCEL_PHONE_VERIFICATION',
    CANCEL_EMAIL_VERIFICATION: 'CANCEL_EMAIL_VERIFICATION'
};

const PAYMENT_METHOD_TYPE = {
    MOBILE_PAYMENT: 'MOBILE_PAYMENT',
    CREDIT_CARD: 'CREDIT_CARD'
};

const SEND_ALERT_TYPE = {
    SEND_AS_PLAYER_INBOX: 'SEND_AS_PLAYER_INBOX',
    SEND_AS_EMAIL: 'SEND_AS_EMAIL',
    SEND_AS_SMS: 'SEND_AS_SMS'
};

export { ACTION_TYPE, PAYMENT_METHOD_TYPE, SEND_ALERT_TYPE };
