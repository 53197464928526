import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Label, Modal, ModalBody} from 'reactstrap';

import i18n from '../../i18n';
import ClipboardButton from '../../common/cellData/CopyButton';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import {postSync} from "../../helpers/api_helper";
import Loader from "../../common/components/Loader";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function GenerateLinkModal({onHide, selectedAffiliate}) {
    useHandleEsc(onHide);
    const [link, setLink] = useState(undefined);

    useEffect(async () => {
        const link = await generateAffiliateLink();
        const QRCode = require("davidshimjs-qrcodejs");
        new QRCode(document.getElementById("qrcode"), `https://${link}`);
    }, []);


    const generateAffiliateLink = async () => {
        try {
            const url = `/affiliates/generateLink`;
            const result = await postSync(url, {id: selectedAffiliate.id});
            const generatedLink = result?.url || undefined;
            setLink(generatedLink);
            return generatedLink;
        } catch (e) {
            console.error('Cannot fetch last messages!');
        }
    };

    const handleSave = useCallback(() => {
        onHide();
    }, [link]);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="generate-link-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('affiliates.generateLink')}
                </h5>
                <button className="close" onClick={onHide}/>
            </div>

            <ModalBody>
                <div className="mb-4">
                    <Label className="form-label">
                        {i18n.t('affiliates.generateLink')}:
                    </Label>
                    {link &&
                        <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                            <div className="generate-link-modal__link-container">
                                <a
                                    href={link}
                                    className="me-2 text-decoration-underline font-w-500"
                                >
                                    {link}
                                </a>
                                <ClipboardButton text={link}/>
                            </div>
                            <div>
                                <div id="qrcode"></div>
                            </div>
                        </div>
                    }
                    {!link &&
                        <Loader/>
                    }
                </div>

                <div className="generate-link-modal__button-container">
                    <button
                        className="btn btn-primary btn-rounded"
                        onClick={handleSave}
                    >
                        {i18n.t('affiliates.ok')}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}

GenerateLinkModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    selectedAffiliate: PropTypes.func.isRequired
};

export default React.memo(GenerateLinkModal);
