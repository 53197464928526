import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import union from '../../../../helpers/union';
import not from '../../../../helpers/not';
import intersection from '../../../../helpers/intersection';
import '../../../../common/inputs/common/checkbox-input.scss';
import SwitchCheckboxInput from "../../../../common/inputs/SwitchCheckboxInput";
import SwitchInput from "../../../../common/inputs/common/SwitchInput"


const useStyles = makeStyles(theme => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        display: 'none',
    },
    list: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        overflowX: 'hidden',
    },
    root: {
        color: 'transparent !important',
        borderRadius: '4px',
        padding: '8px',
        width: '15px',
        height: '15px',
        '&:hover': {
            backgroundColor: 'transparent !important',
            borderColor: '#564ab1 !important',
        }
    }
}));

function AppCheckmark({ checked, disabled }) {
    return (
        <svg
            style={
                checked ? {
                    position: 'absolute',
                    left: '1px',
                    top: '2px',
                    display: 'block'
                } : {}
            }
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 6.81278L4.44262 10.0421L13 1"
                stroke={disabled ? '#7d7dcc80' : '#5f5f8b'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const CustomList = ({ title, items = [], checked = [], setChecked, itemsArrNames = [], disabled = false}) => {
    const classes = useStyles();
    const length = items?.length;

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = items => intersection(checked, items).length;

    const handleToggleAll = items => () => {
        if (numberOfChecked(items) === items?.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                subheader={`${numberOfChecked(items)}/${length} selected`}
            />

            <Divider />

            <List className='permissions-list' dense component="div" role="list">
                {items.map((value, index) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            disabled={disabled}
                        >
                            <ListItemIcon>
                                <SwitchCheckboxInput
                                    className={classes.root}
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    onChange={!disabled && handleToggle(value)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    checkedIcon={<SwitchInput checked={checked.indexOf(value) !== -1} disabled={disabled}/>}
                                    disabled={disabled}
                                />
                            </ListItemIcon>
                            <ListItemText
                                onClick={handleToggle(value)}
                                style={{paddingLeft: '10px'}}
                                id={labelId}
                                primary={itemsArrNames[index] || value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
};

CustomList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    checked: PropTypes.array,
    setChecked: PropTypes.func
};

export default React.memo(CustomList);
