import { takeEvery, put, call } from 'redux-saga/effects';

import {
    SEARCH_AFFILIATE_LIST,
    SEARCH_PLAYER_LIST,
    SEARCH_DEPOSIT_LIST,
    SEARCH_WITHDRAWAL_LIST
} from './actionTypes';
import { searchListSuccess, searchListFail } from './actions';
import { searchPlayersApi } from '../../helpers/playerApi';
import { searchAffiliatesApi } from '../../helpers/affiliateApi';
import {
    searchPaymentDepositListApi,
    searchPaymentWithdrawalListApi,
} from '../../helpers/paymentApi';

function* searchPlayersSaga({ payload }) {
    try {
        const response = yield call(searchPlayersApi, payload);

        yield put(searchListSuccess(response.data));
    } catch (error) {
        yield put(searchListFail(error));
    }
}

function* fetchAffiliateListSaga({ payload }) {
    try {
        const response = yield call(searchAffiliatesApi, payload);

        yield put(searchListSuccess(response.data));
    } catch (error) {
        yield put(searchListFail(error));
    }
}

function* searchDepositsSaga({ payload }) {
    try {
        const response = yield call(searchPaymentDepositListApi, payload);

        yield put(searchListSuccess(response.data));
    } catch (error) {
        yield put(searchListFail(error));
    }
}

function* searchWithdrawalsSaga({ payload }) {
    try {
        const response = yield call(searchPaymentWithdrawalListApi, payload);

        yield put(searchListSuccess(response.data));
    } catch (error) {
        yield put(searchListFail(error));
    }
}

function* searchSaga() {
    yield takeEvery(SEARCH_PLAYER_LIST, searchPlayersSaga);
    yield takeEvery(SEARCH_AFFILIATE_LIST, fetchAffiliateListSaga);
    yield takeEvery(SEARCH_DEPOSIT_LIST, searchDepositsSaga);
    yield takeEvery(SEARCH_WITHDRAWAL_LIST, searchWithdrawalsSaga);
}

export default searchSaga;
