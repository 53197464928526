import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../common/components/Breadcrumb';

import './agent-view.scss';

import MetaTags from 'react-meta-tags';
import i18n from '../../i18n';
import AgentViewTablesCard from './AgentViewTablesCard';
import { useDispatch, useSelector } from 'react-redux';
import useAlertService from '../../hooks/useAlertService';
import Loader from '../../common/components/Loader';
import {
    getAgentEmployee,
    resetAgentEmployee,
    resetAgentEmployeeAlertsErrors
} from '../../store/agents/employee/actions';
import AgentEmployeMainCard from './AgentEmployeeMainCard';

function EmployeePage() {
    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const { employee, error } = useSelector(state => state.AgentEmployee);

    useEffect(() => {
        if (params.id) {
            dispatch(getAgentEmployee(params.id));
        }

        return () => dispatch(resetAgentEmployee());
    }, [params.id]);

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetAgentEmployeeAlertsErrors());
        }
    }, [error]);

    return (
        <div className="page-content agent-view-page">
            <MetaTags>
                <title>{i18n.t('agent.agentView')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Container fluid>
                <Breadcrumb
                    title={i18n.t('agent.agentView')}
                    titleLink={'/agent-view'}
                    breadcrumbItem={`${i18n.t('agent.employeeList')} / ${i18n.t(
                        'agent.employee'
                    )}`}
                />

                {employee ? (
                    <>
                        <Row>
                            <Col lg="12">
                                <AgentEmployeMainCard />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <AgentViewTablesCard agent={employee} />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Loader />
                )}
            </Container>
        </div>
    );
}

export default React.memo(EmployeePage);
