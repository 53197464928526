import moment from 'moment';

import {
    GET_PLAYER_DOCUMENTS_SUCCESS,
    GET_PLAYER_DOCUMENTS_FAIL,
    GET_PENDING_DOCUMENTS,
    GET_PENDING_SUCCESS,
    GET_PENDING_FAIL,
    GET_FIRST_PENDING_DOCUMENTS,
    GET_FIRST_PENDING_SUCCESS,
    GET_FIRST_PENDING_FAIL,
    APPROVE_DOCUMENT_SUCCESS,
    APPROVE_DOCUMENT_FAIL,
    REJECT_DOCUMENT_SUCCESS,
    REJECT_DOCUMENT_FAIL,
    UPLOAD_NEW_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,
    UPLOAD_RETAIL_DOCUMENT,
    UPLOAD_RETAIL_DOCUMENT_SUCCESS,
    UPLOAD_RETAIL_DOCUMENT_FAIL,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_FAIL,
    GET_DOCUMENT_DATA_SUCCESS,
    GET_DOCUMENT_DATA_FAIL,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,
    ADD_DOCUMENT_COMMENTS_SUCCESS,
    ADD_DOCUMENT_COMMENTS_FAIL,
    REMOVE_DOCUMENT_COMMENTS_SUCCESS,
    REMOVE_DOCUMENT_COMMENTS_FAIL,
    CLEAR_CURRENT_DOCUMENT_CONTENT,
    CLEAR_DOCUMENTS_ERROR,
    GET_DOCUMENT_HISTORY,
    GET_DOCUMENT_HISTORY_SUCCESS,
    GET_DOCUMENT_HISTORY_FAIL,
    DOWNLOAD_DOCUMENT,
    DOWNLOAD_DOCUMENT_SUCCESS,
    DOWNLOAD_DOCUMENT_FAIL,
    APPROVE_DOCUMENT,
    REJECT_DOCUMENT,
    SET_OCCUPATION_SUCCESS,
    SET_OCCUPATION_FAIL,
    SET_OCCUPATION,
    GET_BRAND_JURISDICTION_SUCCESS,
    GET_BRAND_JURISDICTION_FAIL,
} from "./actionTypes"


const INIT_STATE = {
    pendingDocuments: [],
    playerDocuments: [],
    lastCommentCreateDate: null,
    lastCommentRemoveDate: null,
    lastDocumentUpdatedDate: null,
    error: null,
    isLoading: false,
    isChangingStatus: false,
    currentDocumentContent: null,
    currentDocumentData: null,
    jurisdiction: null,

    isLoadingDocumentHistory: false,
    // used 'map' {[documentOid]: [...history]} since there can be several documents on one page so that there are no conflicts with history
    documentHistoryMap: {},
    isDownloading: false,
    isOccupationUploading: false,
};

const Documents = (state = INIT_STATE, action) => {
    const documentId = action?.payload?.documentId;
    switch (action.type) {
        case GET_PLAYER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                playerDocuments: action.payload
            };

        case GET_PLAYER_DOCUMENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_PENDING_DOCUMENTS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_PENDING_SUCCESS:
            return {
                ...state,
                pendingDocuments: action.payload,
                isLoading: false,
            };

        case GET_PENDING_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case GET_BRAND_JURISDICTION_SUCCESS:
            return {
                ...state,
                jurisdiction: action.payload.jurisdiction,
                isLoading: false,
            };

        case GET_BRAND_JURISDICTION_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case GET_FIRST_PENDING_DOCUMENTS:
            return {
                ...state,
                isLoading: true,
            };

        case GET_FIRST_PENDING_SUCCESS:
            return {
                ...state,
                pendingDocuments: action.payload,
                isLoading: false,
            };

        case GET_FIRST_PENDING_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };

        case APPROVE_DOCUMENT:
            return {
                ...state,
                isLoading: true,
                isChangingStatus: true,
            };

        case APPROVE_DOCUMENT_SUCCESS:
            return {
                ...state,
                pendingDocuments: state.pendingDocuments.filter(
                    document => document.id !== documentId
                ),
                lastDocumentUpdatedDate: moment(),
                isLoading: false,
                isChangingStatus: false,
            };

        case APPROVE_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isChangingStatus: false,
            };

        case REJECT_DOCUMENT:
            return {
                ...state,
                isLoading: true,
                isChangingStatus: true,
            };

        case REJECT_DOCUMENT_SUCCESS:
            return {
                ...state,
                pendingDocuments: state.pendingDocuments.filter(
                    document => document.id !== action.payload
                ),
                lastDocumentUpdatedDate: moment(),
                isLoading: false,
                isChangingStatus: false,
            };

        case REJECT_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                isChangingStatus: false,
            };

        case UPLOAD_NEW_DOCUMENT:
            return {
                ...state,
                isLoading: true
            };

        case UPLOAD_DOCUMENT_SUCCESS:
            const documentData = action.payload;
            return {
                ...state,
                pendingDocuments: [...state.pendingDocuments, documentData],
                currentDocumentContent: documentData,
                error: {},
                isLoading: false
            };

        case UPLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };

        case UPLOAD_RETAIL_DOCUMENT:
            return {
                ...state,
                isLoading: true
            };

        case UPLOAD_RETAIL_DOCUMENT_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false
            };

        case UPLOAD_RETAIL_DOCUMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };

        case GET_DOCUMENT_SUCCESS:
            return {
                ...state,
                currentDocumentContent: action.payload
            };

        case GET_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case GET_DOCUMENT_DATA_SUCCESS:
            return {
                ...state,
                currentDocumentData: action.payload
            };

        case GET_DOCUMENT_DATA_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                lastDocumentUpdatedDate: moment()
            };

        case UPDATE_DOCUMENT_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case ADD_DOCUMENT_COMMENTS_SUCCESS:
            return {
                ...state,
                lastCommentCreateDate: moment()
            };

        case ADD_DOCUMENT_COMMENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case REMOVE_DOCUMENT_COMMENTS_SUCCESS:
            return {
                ...state,
                lastCommentRemoveDate: moment()
            };

        case REMOVE_DOCUMENT_COMMENTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case CLEAR_CURRENT_DOCUMENT_CONTENT:
            return {
                ...state,
                currentDocumentContent: null
            };

        case CLEAR_DOCUMENTS_ERROR:
            return { ...state, error: {} };

        case GET_DOCUMENT_HISTORY:
            return {
                ...state,
                isLoadingDocumentHistory: true,
                documentHistoryMap: {
                    ...state.documentHistoryMap,
                    [action.payload]: null
                }
            };

        case GET_DOCUMENT_HISTORY_SUCCESS:
            return {
                ...state,
                isLoadingDocumentHistory: false,
                documentHistoryMap: {
                    ...state.documentHistoryMap,
                    [action.payload.documentOid]: action.payload.documentHistory
                }
            };

        case GET_DOCUMENT_HISTORY_FAIL:
            return {
                ...state,
                isLoadingDocumentHistory: false,
                error: action.payload
            };

        case DOWNLOAD_DOCUMENT:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case SET_OCCUPATION:
            return {
                ...state,
                isOccupationUploading: true,
                error: null
            };

        case SET_OCCUPATION_SUCCESS:
            return {
                ...state,
                isOccupationUploading: false,
                error: null
            };

        case SET_OCCUPATION_FAIL:
            return {
                ...state,
                isOccupationUploading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Documents;
