import {
    SET_EDIT,
    CANCEL_ERRORS,
    CHANGE_STATUS,
    GET_AFFILIATE,
    AFFILIATE_ERROR,
    REMOVE_AFFILIATE,
    CHANGE_AFFILIATE,
    GET_AFFILIATE_LIST,
    GET_AFFILIATE_SUCCESS,
    CHANGE_STATUS_SUCCESS,
    ADD_AFFILIATE_AT_LIST,
    REMOVE_AFFILIATE_SUCCESS,
    CHANGE_AFFILIATE_SUCCESS,
    GET_AFFILIATE_LIST_SUCCESS,
    ADD_AFFILIATE_AT_LIST_SUCCESS,
    ADD_AFFILIATE_AT_LIST_ERROR,
    GET_AFFILIATE_LIST_FAIL,
    DOWNLOAD_AFFILIATE_LIST,
    DOWNLOAD_AFFILIATE_LIST_SUCCESS,
    DOWNLOAD_AFFILIATE_LIST_FAIL
} from './actionTypes';
import { STATUS } from '../../constants/affiliates';
import i18n from '../../i18n';


const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INITIAL_STATE = {
    query: DEFAULT_QUERY,
    total: 0,
    affiliateList: null,
    errorList: null,

    currentAffiliate: {
        id: '---',
        name: '---',
        cookieExpirationTimeInMinutes: null,
        utmSourceId: '---',
        priority: null,
        status: STATUS.BLOCKED,
        branded: false,
        contactName: '---',
        contactPhone: '---',
        createdAt: new Date(),
        email: '---',
        lastChangedAt: new Date(),
        oid: '0'
    },
    isStatusLoading: false,
    isLoading: false,
    errors: {},
    isEdit: false,
    successMessage: '',
    isDownloading: false,
};

const Affiliates = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_AFFILIATE_LIST:
            return {
                ...state,
                query: { ...state.query, ...action.payload },
                isLoading: true,
                errorList: null
            };

        case GET_AFFILIATE_LIST_SUCCESS:
            const { result, total } = action.payload;

            return { ...state, isLoading: false, affiliateList: result, total };

        case GET_AFFILIATE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                affiliateList: [],
                isLoading: false
            };

        case ADD_AFFILIATE_AT_LIST:
            return { ...state, isLoading: true };

        case ADD_AFFILIATE_AT_LIST_SUCCESS:
            return {
                ...state,
                affiliateList: [action.payload, ...state.affiliateList],
                isLoading: false,
                errors: {},
                total: state.total + 1,
                successMessage: i18n.t('crm.successfullySaved')
            };

        case ADD_AFFILIATE_AT_LIST_ERROR:
            const filteredErrors = action.payload.validation.filter(error => error !== "invalid.email");
            return {
                ...state,
                isLoading: false,
                errorList: filteredErrors,
            };

        case GET_AFFILIATE:
            return { ...state, isLoading: true };

        case GET_AFFILIATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                currentAffiliate: action.payload
            };

        case CHANGE_AFFILIATE:
            return { ...state };

        case CHANGE_AFFILIATE_SUCCESS:
            return {
                ...state,
                currentAffiliate: action.payload,
                errors: {},
                isEdit: false,
                successMessage: i18n.t('crm.successfullyChanged')
            };

        case REMOVE_AFFILIATE:
            return { ...state, isLoading: true };
        case REMOVE_AFFILIATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                successMessage: i18n.t('crm.successfullyRemoved')
            };

        case AFFILIATE_ERROR:
            const errors = {};

            action.payload?.data?.errors?.map(({ constraints, property }) => {
                errors[property] = Object.values(constraints)[0];
            });

            return {
                ...state,
                isLoading: false,
                isEdit: true,
                errors,
                errorList: action.payload.validation,
            };

        case SET_EDIT:
            return {
                ...state,
                isEdit: action.payload
            };

        case CANCEL_ERRORS:
            return {
                ...state,
                errors: {},
                isEdit: false,
                successMessage: ''
            };

        case CHANGE_STATUS:
            return {
                ...state,
                isStatusLoading: true
            };

        case CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                isStatusLoading: false,
                currentAffiliate: {
                    ...state.currentAffiliate,
                    status: action.payload.status
                },
                errors: {},
                successMessage: i18n.t('crm.successfullyChanged')
            };

        case DOWNLOAD_AFFILIATE_LIST:
            return {
                ...state,
                isDownloading: true,
                errorList: null
            };

        case DOWNLOAD_AFFILIATE_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                errorList: null
            };

        case DOWNLOAD_AFFILIATE_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                errorList: action.payload,
            };

        default:
            return { ...state };
    }
};

export default Affiliates;
