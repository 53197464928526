import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './generic-popup.scss';

const GenericPopup = ({ showPopup, closePopup, component }) => {

    const close = () => {
        closePopup(false);
    }

    return <>
        {showPopup &&
            <div className='overlay'>
                <div className='generic-popup'>
                    <button
                        className='generic-popup-close'
                        onClick={close}
                    />
                    <div className='content'>
                        {component}
                    </div>
                </div>
            </div>
        }
    </>;
};

export default withRouter(GenericPopup);
