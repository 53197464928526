import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomPagination from '../../../../common/tables/CustomPagination';
import useList from '../../../../hooks/useList';
import useDebouncedEffect from '../../../../hooks/useDebouncedEffect';
import { getNotesList, searchAtNoteList } from '../../../../store/note/actions';

import i18n from '../../../../i18n';

import addItem from '../../../../assets/images/common/addIcon.svg';
import notificationsIcon from '../../../../assets/images/common/notifications.svg';
import SimpleBar from 'simplebar-react';
import NoteSidebarItem from './NoteSidebarItem';
import SearchInput from '../../../../common/inputs/common/SearchInput';
import { SITE_PERMISSION } from "../../../../common/constants/common";
import PermissionWrapper from "../../../../components/PermissionWrapper";

const NotesSidebar = ({
    notes,
    agentSelf,
    addNewNote,
    playerOid,
    currencyTab,
    currentNote,
    handleChangeCurrentPage
}) => {
    const dispatch = useDispatch();
    const { query, total, noteList = [] } = useSelector(state => state.Notes);
    const { pageCount, activePage, handleChangePage } = useList(
        query => dispatch(getNotesList({ ...query, playerOid })),
        total,
        query
    );

    const handleChange = value => dispatch(searchAtNoteList(value));

    const firstCounterPart = useMemo(
        () => `${activePage * query.limit - query.limit + 1}`,
        [activePage, query.limit]
    );
    const secondCounterPart = useMemo(
        () => `${activePage * query.limit}`,
        [activePage, query.limit]
    );

    const counter = useMemo(
        () =>
            `${firstCounterPart}-${
                total < secondCounterPart ? total : secondCounterPart
            }`,
        [activePage, query.limit, total]
    );

    useDebouncedEffect(
        () => {
            if (query.searchText !== null) {
                dispatch(getNotesList({ ...query, playerOid }));
            }
        },
        [query.searchText],
        500
    );

    useEffect(() => {
        return () => dispatch(searchAtNoteList(null));
    }, []);

    return (
        <div
            className="d-block notes-sidebar-outer"
        >
            <Col className={'notes-sidebar-wrapper'}>
                <div className={'notes-sidebar-header'}>
                    <img
                        src={notificationsIcon}
                        alt={i18n.t('admin.notifications')}
                    />
                    {i18n.t('admin.notifications')}
                </div>

                <div className={'notes-sidebar-search'}>
                    <SearchInput
                        value={query.searchText}
                        onChange={handleChange}
                        placeholder={i18n.t('common.search')}
                    />
                </div>

                <SimpleBar
                    autoHide={false}
                    className={'notes-sidebar-notes-wrapper'}
                    style={{ height: `50vh` }}
                >
                    {notes?.length > 0 &&
                        notes.map((item, index) => (
                            <NoteSidebarItem
                                key={index}
                                currentNote={currentNote}
                                item={item}
                                index={index}
                                currencyTab={currencyTab}
                                handleChangeCurrentPage={handleChangeCurrentPage}
                            />
                        ))}

                    {noteList?.length > 0 && (
                        <Row className={'notes-sidebar-pagination'}>
                            <CustomPagination
                                notStorage={true}
                                agentSelf={agentSelf}
                                pageCount={pageCount}
                                activePage={activePage}
                                onChange={handleChangePage}
                            />
                        </Row>
                    )}
                </SimpleBar>

                <div className='notes-sidebar-added-list'>
                    <hr />

                    <div className={'total-info'}>
                        <div className={'total-info-count'}>
                            <span>{i18n.t('crm.list')}</span>
                            <span className={'total-info-counter'}>
                                {counter} / <span>{total}</span>
                            </span>
                        </div>

                        <PermissionWrapper
                            accessPermissions={[SITE_PERMISSION.Player__Edit_Note]}
                            withPermissionError={false}
                        >
                            <img
                                src={addItem}
                                onClick={addNewNote}
                                alt={i18n.t('crm.addNew')}
                                className={'total-info_add-button'}
                            />
                        </PermissionWrapper>
                    </div>
                </div>
            </Col>
        </div>
    );
};

NotesSidebar.propTypes = {
    notes: PropTypes.array.isRequired,
    agentSelf: PropTypes.object.isRequired,
    addNewNote: PropTypes.func.isRequired,
    playerOid: PropTypes.string.isRequired,
    currencyTab: PropTypes.number,
    handleChangeCurrentPage: PropTypes.func.isRequired
};

export default React.memo(NotesSidebar);
