import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser, refreshToken } from '../../store/auth/login/actions';
import { getRefreshToken } from '../../helpers/api_helper';

const getUser = () => JSON.parse(localStorage.getItem('authUser'));
const reloadInterval = 1000 * 60 * 10; // 10 minutes

export const RefreshWrapper = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const refreshCallback = () => {
            const authUser = getUser();
            const refresh = getRefreshToken();

            if (refresh) {
                dispatch(refreshToken());
            } else {
                if (authUser) {
                    dispatch(logoutUser(history));
                } else {
                    history.push('/login');
                }
            }
        }
        const refreshInterval = setInterval(refreshCallback, reloadInterval);

        return () => clearInterval(refreshInterval);
    }, []);

    return <>{children}</>;
};
