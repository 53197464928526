import React from 'react';
import { CardHeader } from 'reactstrap';
import i18n from '../../../i18n';
import ClipboardButton from '../../../common/cellData/CopyButton';
import AvatarIcon from './../AvatarIcon';
import AgentStatusView from '../../../components/Status/AgentStatusView';
import PropTypes from 'prop-types';
import download from "../../../assets/images/common/download.svg";

function AgentMainCardHeader({ agent, onDownload, isDownloading }) {
    return (
        <CardHeader>
            <div className="d-flex flex-wrap card-header__row">
                <AvatarIcon
                    avatarData={null}
                    firstName={agent.firstName}
                    lastName={agent.lastName}
                />

                <div className="ms-4 mb-auto mt-auto card-header__info">
                    <div className="card-header__name">{`${agent.firstName} ${agent.lastName}`}</div>
                    <div>
                        <span className="me-2">ID {agent.id}</span>
                        <ClipboardButton text={agent.id} />

                        <button
                          className="btn btn-rounded btn-primary app-btn-only-img-sm"
                          onClick={onDownload}
                          disabled={isDownloading}
                        >
                            <img src={download} alt="" />
                        </button>
                    </div>
                </div>

                <div className="ms-auto mb-auto card-header__status">
                    <span className="me-3 font-w-500">
                        {i18n.t('common.status')}:
                    </span>
                    <AgentStatusView status={agent.status} />
                </div>
            </div>
        </CardHeader>
    );
}

AgentMainCardHeader.propTypes = {
    agent: PropTypes.object,
    onDownload: PropTypes.func.isRequired,
    isDownloading: PropTypes.bool,
};

export default React.memo(AgentMainCardHeader);
