import { useEffect } from 'react';

export default function useHandleEnter(callback) {
    useEffect(() => {
        function onClick(e) {
            if (e.code === 'Enter') {
                callback();
            }
        }

        document.addEventListener('keydown', onClick, { passive: true });
        return () => {
            document.removeEventListener('keydown', onClick, { passive: true });
        };
    }, [callback]);
}
