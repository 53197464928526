export const SELECT_REQUIRE_FIELDS = [
    { value: 'First name', key: 'firstName' },
    { value: 'Last Name', key: 'lastName' },
    { value: 'Date of Birth', key: 'birthDate' },
    { value: 'Phone', key: 'phone' },
    { value: 'Email', key: 'email' },
    { value: 'Address', key: 'street' },
    { value: 'City', key: 'city' },
    { value: 'Country', key: 'addressCountryAlfa2' },
    { value: 'State', key: 'state' },
    { value: 'Zip Code', key: 'zipCode' }
]