import {useSelector} from "react-redux";
import {useMemo} from "react";
import RestrictionErrorPage from "./RestrictionErrorPage/RestrictionErrorPage";

const PermissionWrapper = (props) => {
    const {
        accessPermissions = [],
        children,
        withPermissionError = false,
    } = props;

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions || [];

    const accessChild = useMemo(() => {
        const agentPermissionsSet = new Set(agentPermissions);
        return accessPermissions.every(permission => agentPermissionsSet.has(permission));
    }, [agentPermissions, accessPermissions])

    return (
        <>
            {agentPermissions.length
                ? (
                    accessChild
                        ? (
                            children
                        ) : (
                            withPermissionError && <RestrictionErrorPage />
                        )
                ) : (
                    <>
                    </>
                )
            }
        </>
    )
}

export default PermissionWrapper;