import React, {useContext} from 'react';
import moment from "moment";


const dateToMomentInstance = (date) => {
    if (date instanceof moment) {
        return date;
    } else {
        return moment(new Date(date));
    }
}

const parseTimeToUTC = (date) => {
    return dateToMomentInstance(date).utc()
}

const parseTimeToLocal = (date) => {
    return dateToMomentInstance(date).local();
}

const parseTimeToUTCOffset = (utcOffset) => {
    const offsetInMinutes = utcOffset * 60;
    return (date) => dateToMomentInstance(date).utcOffset(offsetInMinutes);
}

export const timeValues = {
    'LOCAL': {
        name: 'LOCAL',
        UTC_offset: 'local'
    },
    'UTC': {
        name: 'UTC',
        UTC_offset: 0
    },
    'UTC+1': {
        name: 'UTC+1',
        UTC_offset: 1
    },
    'UTC+2': {
        name: 'UTC+2',
        UTC_offset: 2
    },
    'UTC+3': {
        name: 'UTC+3',
        UTC_offset: 3
    },
    'UTC+4': {
        name: 'UTC+4',
        UTC_offset: 4
    },
    'UTC+5': {
        name: 'UTC+5',
        UTC_offset: 5
    },
    'UTC+6': {
        name: 'UTC+6',
        UTC_offset: 6
    },
    'UTC+7': {
        name: 'UTC+7',
        UTC_offset: 7
    },
    'UTC+8': {
        name: 'UTC+8',
        UTC_offset: 8
    },
    'UTC+9': {
        name: 'UTC+9',
        UTC_offset: 9
    },
    'UTC+10': {
        name: 'UTC+10',
        UTC_offset: 10
    },
    'UTC+11': {
        name: 'UTC+11',
        UTC_offset: 11
    },
    'UTC-1': {
        name: 'UTC-1',
        UTC_offset: -1
    },
    'UTC-2': {
        name: 'UTC-2',
        UTC_offset: -2
    },
    'UTC-3': {
        name: 'UTC-3',
        UTC_offset: -3
    },
    'UTC-4': {
        name: 'UTC-4',
        UTC_offset: -4
    },
    'UTC-5': {
        name: 'UTC-5',
        UTC_offset: -5
    },
    'UTC-6': {
        name: 'UTC-6',
        UTC_offset: -6
    },
    'UTC-7': {
        name: 'UTC-7',
        UTC_offset: -7
    },
    'UTC-8': {
        name: 'UTC-8',
        UTC_offset: -8
    },
    'UTC-9': {
        name: 'UTC-9',
        UTC_offset: -9
    },
    'UTC-10': {
        name: 'UTC-10',
        UTC_offset: -10
    },
    'UTC-11': {
        name: 'UTC-11',
        UTC_offset: -11
    }
};

const getTimeContextObj = (_timeValues) => {
    const timeContextObj = {};

    for (const value in _timeValues) {
        switch(value) {
            case 'LOCAL':
                timeContextObj[value] = {
                    timeValue: _timeValues[value].name,
                    parseTime: parseTimeToLocal,
                }
                break;
            case 'UTC':
                timeContextObj[value] = {
                    timeValue: _timeValues[value].name,
                    parseTime: parseTimeToUTC,
                }
                break;
            default:
                timeContextObj[value] = {
                    timeValue: _timeValues[value].name,
                    parseTime: parseTimeToUTCOffset(_timeValues[value].UTC_offset),
                }
                break;
        }
    }

    return timeContextObj;
}

export const timeContextObj = getTimeContextObj(timeValues);

export const TimeContext = React.createContext(timeContextObj[timeValues.LOCAL.name]);

export const useTimeContext = () => useContext(TimeContext);

export const TimeContextProvider = ({children}) => {
    const defaultTimeFormat = timeContextObj[timeValues.LOCAL.name];
    const [timeFormat, setTimeFormat] = React.useState(defaultTimeFormat);
    const options = Object.keys(timeContextObj).map((timeZone) => {

        return {
            value: timeContextObj[timeZone].timeValue,
            label: timeContextObj[timeZone].timeValue,
        }
    });

    const updateTimeFormat = (timeValue) => {
        const newTimeContext = timeContextObj[timeValue];
        setTimeFormat(newTimeContext || defaultTimeFormat);

        if (newTimeContext) {
            localStorage.setItem('timeZone', timeValue);
        }
    };

    React.useEffect(() => {
        const storedTimeZone = localStorage.getItem('timeZone');
        updateTimeFormat(storedTimeZone)
    }, [])

    return (
        <>
            <TimeContext.Provider value={{timeFormat, updateTimeFormat, timeFormatList: options}}>
                {children}
            </TimeContext.Provider>
        </>
    )
}



