import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_RESTRICTIONS,
    UPDATE_PLAYER_RESTRICTIONS
} from './actionTypes';
import {
    updatePlayerRestrictionsSuccess,
    updatePlayerRestrictionsFail,
    getPlayerRestrictionsFail,
    getPlayerRestrictionsSuccess
} from './actions';
import {
    updatePlayerRestrictionsApi,
    getPlayerRestrictionsApi
} from '../../../helpers/playerApi';

function* fetchPlayerRestrictionsSaga({ payload }) {
    try {
        const response = yield call(getPlayerRestrictionsApi, payload);

        yield put(getPlayerRestrictionsSuccess(response.data));
    } catch (error) {
        yield put(getPlayerRestrictionsFail(error));
    }
}

function* updatePlayerRestrictionsSaga({ payload }) {
    try {
        const response = yield call(updatePlayerRestrictionsApi, payload);

        yield put(
            updatePlayerRestrictionsSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updatePlayerRestrictionsFail(error));
    }
}

function* playerRestrictionsSaga() {
    yield takeEvery(GET_PLAYER_RESTRICTIONS, fetchPlayerRestrictionsSaga);
    yield takeEvery(
        UPDATE_PLAYER_RESTRICTIONS,
        updatePlayerRestrictionsSaga
    );
}

export default playerRestrictionsSaga;
