import React from "react";

const SimpleInfoLine = ({ label, info }) => {
    return (
        <div className="d-flex mt-4 justify-content-between" >
            <div className="font-w-500 me-3 d-flex align-items-center">
                {label}:
            </div>
            <div className="font-w-500 me-3 d-flex align-items-center">
                {info}
            </div>
        </div>
    )
}

export default SimpleInfoLine