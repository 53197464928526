import React from 'react';
import PropTypes from 'prop-types';
import close from "../../../../assets/images/common/close 1.svg";
import i_51 from '../../../../assets/images/common/Frame 176 (1).svg';
import './styles.css';
import useHandleEsc from "../../../../common/hooks/useHandleEsc";
import {useTranslation} from "react-i18next";

function CashierMethodButtons(props) {
    const {
        onCancel,
        onSave,
        onSaveText,
        isDisabled,
        className
    } = props;
    useHandleEsc(onCancel);
    const { t } = useTranslation();

    return (
        <div className={`${className} method-buttons`}>
            <button
                className="btn btn-rounded btn-light me-2 mb-lg-2"
                onClick={onCancel}
                disabled={isDisabled}
            >
                <img className="me-2" src={close} alt="" />
                {t('admin.cancel')}
            </button>

            <button
                className="btn btn-rounded btn-primary mb-lg-2"
                onClick={onSave}
                disabled={isDisabled}
            >
                <img src={i_51} alt="" />
                {t('admin.save')}
            </button>
        </div>
    );
}

CashierMethodButtons.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
};

export default React.memo(CashierMethodButtons);
