import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Loader = ({ size = 'sm', className }) => (
    <div
        className={`d-flex align-items-center justify-content-center pt-2 pb-2 ${className || ''}`}
    >
        <div className={`loader ${size}`}>

        </div>
    </div>
);

Loader.proptypes = {
    size: PropTypes.oneOf(['lg', 'sm'])
};

export default memo(Loader);
