import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import i18n from '../../../../src/i18n';
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

function ResidentialInfoModal({ residentialInfo, setIsShowResidentialInfo }) {
  const propsJson = JSON.stringify(residentialInfo).split("\",\"").join("\",\n\"");
  const residentialInfoStr = residentialInfo ? propsJson : i18n.t('crm.noResidentialInfo');

  return (
    <Modal
      isOpen={true}
      role="dialog"
      autoFocus={true}
      data-toggle="modal"
      className="residential-info-modal"
      centered
      onOpened={setUnScrollBody}
      onExit={removeUnScrollBody}
    >
      <div className="modal-header">
        <h5 className="modal-title text-uppercase">
          {i18n.t('crm.residentialInfo')}
        </h5>
        <button className="close" onClick={() => setIsShowResidentialInfo(false)}/>
      </div>

      <ModalBody>
        <pre>
          {residentialInfoStr}
        </pre>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <button
          className="btn btn-primary btn-rounded"
          style={{maxWidth: '132px'}}
          onClick={() => setIsShowResidentialInfo(false)}
        >
          {i18n.t('crm.ok')}
        </button>
      </ModalFooter>
    </Modal>
  );
}

ResidentialInfoModal.propTypes = {
  residentialInfo: PropTypes.any.isRequired,
  setIsShowResidentialInfo: PropTypes.func.isRequired,
};

export default React.memo(ResidentialInfoModal);
