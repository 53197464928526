import React from 'react';
import i18n from '../../../../i18n';
import PropTypes from 'prop-types';

export const STATISTICS_CONTROLLER_TYPE = {
    CONVERSION: 'CONVERSION',
    RETENTION: 'RETENTION'
};

const StatisticsController = ({
    onClickConversion,
    onClickRetention,
    activeType
}) => {
    return (
        <div className="statistics-controller">
            <button
                className={`btn btn-rounded ${
                    activeType === STATISTICS_CONTROLLER_TYPE.CONVERSION
                        ? 'btn-primary'
                        : 'btn-light'
                }`}
                onClick={onClickConversion}
            >
                {i18n.t('admin.conversion')}
            </button>

            <button
                className={`btn btn-rounded ${
                    activeType === STATISTICS_CONTROLLER_TYPE.RETENTION
                        ? 'btn-primary'
                        : 'btn-light'
                }`}
                onClick={onClickRetention}
            >
                {i18n.t('admin.retention')}
            </button>
        </div>
    );
};

StatisticsController.propTypes = {
    onClickConversion: PropTypes.func.isRequired,
    onClickRetention: PropTypes.func.isRequired,
    activeType: PropTypes.string.isRequired
};

export default React.memo(StatisticsController);
