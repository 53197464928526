import { Link } from "react-router-dom";
import CopyButton from "../../../common/cellData/CopyButton";
import { getGuidShortFormat } from "../../../common/utils/common";
import React from "react";
import { useSelector } from "react-redux";
import {SITE_PERMISSION} from "../../../common/constants/common";
import useAlertService from "../../../hooks/useAlertService";
import { useTranslation } from "react-i18next";
import {getUserUrl} from "./PlayerCell";

const IdCell = (props) => {
    const {
        item,
        url: {
            type = 'list',
            id
        },
    } = props;

    const alertService = useAlertService();
    const { t } = useTranslation();

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const hasPlayerViewAccess = agentPermissions?.includes(SITE_PERMISSION.Player__View_Player_Info);
    const showId = props.url.type === 'finance';

    const showRestrictedMsg = () => {
        alertService.showError(t('crm.accessRestricted'));
    }

    const idField = (displayId, clipBoardId) => (
        <div>
            {hasPlayerViewAccess
                ? (
                    <Link to={getUserUrl(type, id)}>
                        {displayId}
                    </Link>
                ) : (
                    <span style={{cursor: 'pointer'}} onClick={showRestrictedMsg}>
                            {displayId}
                    </span>
                )
            }
            &nbsp;&nbsp;
            <CopyButton
                size={'19'}
                className="column-cpy-button"
                onClick={() =>
                    navigator.clipboard.writeText(clipBoardId)
                }
            />
        </div>
    )

    return (
        <div style={{fontSize: '12px', display: 'flex', flexDirection: 'column'}}>
            {idField(
                !showId ? item.id : item.oid,
                !showId ? item.id : item.oid)
            }
            {!showId && idField(getGuidShortFormat(item.userId), item.userId)}
        </div>
    )
}

export default IdCell;