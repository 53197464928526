import Formatter from "../utils/Formatter";
import { useCallback } from "react";
import { useSelector } from "react-redux";


export default function useUserConfig() {
    const { playerGeneral } = useSelector(state => state.PlayerGeneral);

    const adjustAmountWithUserCurrency = useCallback(
        (value) => Formatter.formatAmount(
            value ?? 0,
            (playerGeneral?.totalBalance?.currency
                ? playerGeneral.totalBalance.currency
                : (playerGeneral && playerGeneral.currency) || '')
        ), [playerGeneral]
    );

    return {
        adjustAmountWithUserCurrency,
    };
}

