import { useEffect, useMemo } from 'react';
import useAlertService from "./useAlertService";
import { useHistory } from "react-router-dom";
import * as queryString from "query-string";
import useList from "./useList";
import { getLocalStorageFilter } from "../store/filters/reducer";
import i18n from "../i18n"
import { getStorageXSiteId } from "../common/utils/common";


export default function useListPage(
  apiService,
  total,
  query,
  error,
  resetErrors,
  successAlert,
  currentFilter,
  defaultFilterDynamic,
) {
  const alertService = useAlertService();
  const history = useHistory();

  const queryParams = useMemo(
    () => queryString.parse(history.location.search),
    [history.location.search]
  );

  const { pageCount, activePage, handleChangePage } = useList(
    apiService,
    total,
    query,
    currentFilter,
    defaultFilterDynamic
  );

  useEffect(() => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        alertService.showError(i18n.t(`crm.alerts.${err}`));
      });
    } else if (error) {
      alertService.showError(error?.message);
    }
    resetErrors();
  }, [error]);

  useEffect(() => {
    if (successAlert) {
      alertService.showSuccess(successAlert);
      resetErrors();
    }
  }, [successAlert]);

  useEffect(() => {
    const requestQuery = { ...query, offset: 0 };
    const localStorageFilter = getLocalStorageFilter();

    if (currentFilter || localStorageFilter) {
      // TODO here we need to get the local storage filter and present it in the filter tab
      // const dynamic = localStorage.getItem('LocalStorageFilter');
      // console.log(dynamic)
      // localStorage.removeItem('LocalStorageFilter');
      requestQuery.filterDynamic = localStorageFilter;
    }

    if (defaultFilterDynamic && currentFilter && requestQuery.filterDynamic) {
      requestQuery.filterDynamic = [...requestQuery.filterDynamic, ...defaultFilterDynamic];
    } else if (defaultFilterDynamic) {
      requestQuery.filterDynamic = [...defaultFilterDynamic];
    }

    apiService(requestQuery);
  }, [
      JSON.stringify(currentFilter),
      getStorageXSiteId(),
      queryParams,
  ]);

  return {
    pageCount,
    activePage,
    handleChangePage
  };
}
