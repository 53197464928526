import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import i18n from '../../../i18n';
import CustomModal from '../../../common/components/CustomModal';
import ActivityHistoryRecord from './ActivityHistoryRecord';
import ActivityCommentsRecord from './ActivityCommentsRecord';
import AddCommentForm from './AddCommentForm';
import activity from '../../../assets/images/layout/dark-grey-activity.svg'; // for history
import commentsSvg from '../../../assets/images/layout/comments.svg';
import addItem from '../../../assets/images/layout/add-item.svg';
import Loader from '../../../common/components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {getDocumentData, getDocumentHistory} from '../../../store/documents/actions';

const ActivityTab = props => {
    const dispatch = useDispatch();
    const { isLoadingDocumentHistory, documentHistoryMap } = useSelector(
        state => state.Documents
    );

    const { pageMode, comments, onSaveComment, onRemoveComment } = props;

    const [isModalVisible, setModalVisible] = React.useState(false);
    const [newComment, setNewComment] = React.useState('');
    const [isNotifyPlayerChecked, checkNotifyPlayer] = React.useState(false);

    useEffect(() => {
        if (props.currentDocument?.oid) {
            dispatch(getDocumentHistory(props.currentDocument?.oid));
        }
    }, [props.currentDocument]);

    useEffect(() => {
        if (props.currentDocument?.oid) {
            dispatch(getDocumentData(props.currentDocument?.oid));
        }
    }, [props.currentDocument.oid]);

    const handleToggleModal = () => setModalVisible(!isModalVisible);

    const handleClickAddComment = () => {
        setModalVisible(true);
    };

    const handleClickSaveComment = () => {
        // Send comment on the server
        onSaveComment(newComment);
        setModalVisible(false);
    };

    const handleCommentChange = event => {
        setNewComment(event.target.value);
    };

    const handleToggleNotifyPlayer = () =>
        checkNotifyPlayer(!isNotifyPlayerChecked);

    return (
        <Row className="activities">
            <Col key="history-title" className="col-12 activity-section-title">
                <img
                    className="title-img"
                    alt=""
                    src={activity}
                    width={16}
                    height={16}
                />
                {i18n.t('crm.history')}
            </Col>
            <Col key="history-section" className="col-12 activity-section">
                {isLoadingDocumentHistory ||
                !documentHistoryMap[props.currentDocument?.oid] ? (
                    <Loader size={'lg'}/>
                ) : (
                    <>
                        {documentHistoryMap[props.currentDocument?.oid]?.map(
                            (record) => (
                                <ActivityHistoryRecord
                                    key={record?.createdAt}
                                    record={record}
                                />
                            )
                        )}
                    </>
                )}
            </Col>

            <hr className="horizontal-separator" />

            <Col key="comment-title" className="col-12 activity-section-title">
                <img
                    className="title-img"
                    alt=""
                    src={commentsSvg}
                    width={16}
                    height={16}
                />
                {i18n.t('crm.comments')}
            </Col>
            <Col
                key="comment-section"
                className="col-12 activity-section comment-section"
            >
                <img
                    className="add-item"
                    alt=""
                    src={addItem}
                    width={40}
                    height={40}
                    onClick={handleClickAddComment}
                />
                <div className="activity-section-wrapper">
                    {comments.map((record, ind) => (
                        <ActivityCommentsRecord
                            key={`comment-${ind}`}
                            record={record}
                            withActions={true}
                            pageMode={pageMode}
                            onRemoveComment={onRemoveComment}
                        />
                    ))}
                </div>
            </Col>

            <CustomModal
                titleText={i18n.t('crm.addComment')}
                isOpen={isModalVisible}
                onToggle={() => handleToggleModal()}
                onClick={handleClickSaveComment}
                btnText={'save'}
                btnDisabled={newComment?.trim().length < 3}
                bodyRender={() => (
                    <AddCommentForm
                        isNotifyChecked={isNotifyPlayerChecked}
                        onCommentChange={handleCommentChange}
                        onToggleNotifyPlayer={handleToggleNotifyPlayer}
                    />
                )}
            />
        </Row>
    );
};

export default ActivityTab;
