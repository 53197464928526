import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const HeaderAgentStatisticsMainCard = ({ agent }) => {
    if (!agent) return null;

    const { initials, firstName, lastName } = useMemo(() => {
        const firstName = agent?.firstName || 'First';
        const lastName = agent?.lastName || 'Last';
        const firstLetter = firstName.split('')[0];
        const secondLetter = lastName.split('')[0];

        return {
            initials: `${firstLetter.toUpperCase()} ${secondLetter.toUpperCase()}`,
            firstName,
            lastName
        };
    }, [agent]);

    return (
        <div className="agent-statistics-header">
            <div className="agent-statistics-header__avatar">{initials}</div>

            <div className="agent-statistics-header__name">
                {firstName}&nbsp;{lastName}
            </div>
        </div>
    );
};

HeaderAgentStatisticsMainCard.propTypes = {
    agent: PropTypes.object.isRequired
};

export default React.memo(HeaderAgentStatisticsMainCard);
