export const FETCH_BRAND_SETTINGS_PAGE = 'FETCH_BRAND_SETTINGS_PAGE';
export const FETCH_BRAND_SETTINGS_PAGE_SUCCESS =
    'FETCH_BRAND_SETTINGS_PAGE_SUCCESS';

export const FETCH_VIP_LEVEL_CONFIG = 'FETCH_VIP_LEVEL_CONFIG';
export const FETCH_VIP_LEVEL_CONFIG_SUCCESS =
    'FETCH_VIP_LEVEL_CONFIG_SUCCESS';

export const BRAND_SETTINGS_PAGE_ERROR = 'BRAND_SETTINGS_PAGE_ERROR';

export const CHANGE_BRAND_SETTINGS_PAGE = 'CHANGE_BRAND_SETTINGS_PAGE';
export const CHANGE_BRAND_SETTINGS_PAGE_SUCCESS =
    'CHANGE_BRAND_SETTINGS_PAGE_SUCCESS';

export const SET_EDIT_BRAND_SETTINGS = 'SET_EDIT_BRAND_SETTINGS';
export const RESET_BRAND_SETTINGS_ALERTS_ERRORS =
    'RESET_BRAND_SETTINGS_ALERTS_ERRORS';

export const GET_SMARTICO_SETTINGS = 'GET_SMARTICO_SETTINGS';
export const GET_SMARTICO_SETTINGS_SUCCESS = 'GET_SMARTICO_SETTINGS_SUCCESS';
export const GET_SMARTICO_SETTINGS_ERROR = 'GET_SMARTICO_SETTINGS_ERROR';

export const GET_BRAND_TAGS = 'GET_BRAND_TAGS';
export const GET_BRAND_TAGS_SUCCESS = 'GET_BRAND_TAGS_SUCCESS';
export const GET_BRAND_TAGS_ERROR = 'GET_BRAND_TAGS_ERROR';

export const GET_ANTI_FRAUD_RULES = 'GET_ANTI_FRAUD_RULES';
export const GET_ANTI_FRAUD_RULES_SUCCESS = 'GET_ANTI_FRAUD_RULES_SUCCESS';
export const GET_ANTI_FRAUD_RULES_ERROR = 'GET_ANTI_FRAUD_RULES_ERROR';

export const GET_AVAILABLE_ANTI_FRAUD_ACTIONS = 'GET_AVAILABLE_ANTI_FRAUD_ACTIONS';
export const GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS = 'GET_AVAILABLE_ANTI_FRAUD_ACTIONS_SUCCESS';
export const GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR = 'GET_AVAILABLE_ANTI_FRAUD_ACTIONS_ERROR';

export const UPDATE_ANTI_FRAUD_RULES = 'UPDATE_ANTI_FRAUD_RULES';
export const UPDATE_ANTI_FRAUD_RULES_SUCCESS = 'UPDATE_ANTI_FRAUD_RULES_SUCCESS';
export const UPDATE_ANTI_FRAUD_RULES_ERROR = 'UPDATE_ANTI_FRAUD_RULES_ERROR';

export const GET_COOL_OFF_DATA = 'GET_COOL_OFF_DATA';
export const GET_COOL_OFF_DATA_SUCCESS = 'GET_COOL_OFF_DATA_SUCCESS';
export const GET_COOL_OFF_DATA_ERROR = 'GET_COOL_OFF_DATA_ERROR';

export const UPDATE_COOL_OFF_DATA = 'UPDATE_COOL_OFF_DATA';
export const UPDATE_COOL_OFF_DATA_SUCCESS = 'UPDATE_COOL_OFF_DATA_SUCCESS';
export const UPDATE_COOL_OFF_DATA_ERROR = 'UPDATE_COOL_OFF_DATA_ERROR';

export const DELETE_COOL_OFF_DATA = 'DELETE_COOL_OFF_DATA';
export const DELETE_COOL_OFF_DATA_SUCCESS = 'DELETE_COOL_OFF_DATA_SUCCESS';
export const DELETE_COOL_OFF_DATA_ERROR = 'DELETE_COOL_OFF_DATA_ERROR';