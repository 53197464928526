export const GET_PLAYER_LIST = 'GET_PLAYER_LIST';
export const GET_PLAYER_LIST_SUCCESS = 'GET_PLAYER_LIST_SUCCESS';
export const GET_PLAYER_LIST_FAIL = 'GET_PLAYER_LIST_FAIL';

export const GET_PLAYER_KYC_LIST = 'GET_PLAYER_KYC_LIST';
export const GET_PLAYER_KYC_LIST_SUCCESS = 'GET_PLAYER_KYC_LIST_SUCCESS';
export const GET_PLAYER_KYC_LIST_FAIL = 'GET_PLAYER_KYC_LIST_FAIL';

export const GET_PLAYER_TRACKER = 'GET_PLAYER_TRACKER';
export const GET_PLAYER_TRACKER_SUCCESS = 'GET_PLAYER_TRACKER_SUCCESS';
export const GET_PLAYER_TRACKER_FAIL = 'GET_PLAYER_TRACKER_FAIL';

export const GET_PLAYER_KYC_COUNT = 'GET_PLAYER_KYC_COUNT';
export const GET_PLAYER_KYC_COUNT_SUCCESS = 'GET_PLAYER_KYC_COUNT_SUCCESS';
export const GET_PLAYER_KYC_COUNT_FAIL = 'GET_PLAYER_KYC_COUNT_FAIL';

export const UPDATE_PLAYERS = 'UPDATE_PLAYERS';
export const UPDATE_PLAYER_IDENTITY_INFO = 'UPDATE_PLAYER_IDENTITY_INFO';
export const UPDATE_PLAYERS_SUCCESS = 'UPDATE_PLAYERS_SUCCESS';
export const UPDATE_PLAYERS_FAIL = 'UPDATE_PLAYERS_FAIL';

export const RESET_PLAYER_LIST_ALERTS_ERRORS =
    'RESET_PLAYER_LIST_ALERTS_ERRORS';

export const RESET_PLAYER_QUERY = "RESET_PLAYER_QUERY";

export const DOWNLOAD_PLAYER_LIST = 'DOWNLOAD_PLAYER_LIST';
export const DOWNLOAD_PLAYER_LIST_SUCCESS = 'DOWNLOAD_PLAYER_LIST_SUCCESS';
export const DOWNLOAD_PLAYER_LIST_FAIL = 'DOWNLOAD_PLAYER_LIST_FAIL';

export const DOWNLOAD_PLAYER_PENDING_LIST = 'DOWNLOAD_PLAYER_PENDING_LIST';
export const DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS = 'DOWNLOAD_PLAYER_PENDING_LIST_SUCCESS';
export const DOWNLOAD_PLAYER_PENDING_LIST_FAIL = 'DOWNLOAD_PLAYER_PENDING_LIST_FAIL';
