import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';
import './complicated-checkbox-input.scss';
import magnifiying_glass_1 from '../../../assets/images/common/magnifiying-glass 1.svg';
import CheckboxInput from './CheckboxInput';
import i18n from '../../../i18n';
import useClickOutside from '../../hooks/useClickOutside';
import useHandleEsc from '../../hooks/useHandleEsc';

function Checkmark({ color }) {
    return (
        <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
        >
            <path
                fill={color}
                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
            ></path>
        </svg>
    );
}

function SearchInput({ value, onChange, ...props }) {
    return (
        <div className="app-search-input">
            <img src={magnifiying_glass_1} alt="" />
            <TextInput
                className="form-control"
                value={value}
                onChange={onChange}
                placeholder={i18n.t('attributions.search')}
                {...props}
            />
        </div>
    );
}

let updateStateTimeout = null;

function ComplicatedCheckboxSelect({
    onChange,
    value,
    options,
    isDisabled,
    ...props
}) {
    const areaRef = useRef();
    useClickOutside(areaRef, () => setShowMenu(false));
    useHandleEsc(() => setShowMenu(false));

    const [isShowMenu, setShowMenu] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isOnlySelected, setOnlySelected] = useState(false);
    const [optionsToDisplay, setOptionsToDisplay] = useState(options);

    useEffect(() => {
        clearTimeout(updateStateTimeout);

        updateStateTimeout = setTimeout(() => {
            prepareOptionsToDisplay();
        }, 250);
    }, [searchValue]);

    useEffect(() => prepareOptionsToDisplay(), [isOnlySelected]);

    const prepareOptionsToDisplay = useCallback(() => {
        let newOptionsToDisplay = options;

        if (isOnlySelected) {
            newOptionsToDisplay = newOptionsToDisplay.filter(item =>
                value.includes(item.value)
            );
        }

        if (searchValue) {
            newOptionsToDisplay = newOptionsToDisplay.filter(item =>
                item.label.includes(searchValue)
            );
        }

        setOptionsToDisplay(newOptionsToDisplay);
    }, [isOnlySelected, searchValue, options, value]);

    const handleChange = useCallback(
        key => {
            const list = [...value];
            if (!value.includes(key)) {
                list.push(key);
            } else {
                list.splice(list.indexOf(key), 1);
            }
            onChange(list);
        },
        [value, onChange]
    );

    const handleControlClick = useCallback(() => {
        if (!isDisabled) {
            setShowMenu(prevState => !prevState);
        }
    }, [isDisabled]);

    const controlValue = useMemo(() => {
        let result = '';
        if (value.length === 1) {
            result = options.find(item => item.value === value[0])?.label;
        }
        if (value.length > 1) {
            result = `${i18n.t('attributions.multipleSelection')} (${
                value.length
            })`;
        }

        return result;
    }, [value]);

    return (
        <div
            ref={areaRef}
            className={`complicated-checkbox-input ${
                props.className ? props.className : ''
            }`}
        >
            <div
                className="complicated-checkbox-input__control"
                onClick={handleControlClick}
            >
                <TextInput
                    className="form-control"
                    value={controlValue}
                    onChange={() => {}}
                    placeholder={props.placeholder}
                    disabled={isDisabled}
                />
                <Checkmark color={isShowMenu ? '#6c6c6c' : '#cccccc'} />
            </div>

            {isShowMenu && (
                <div className="complicated-checkbox-input__menu">
                    <SearchInput
                        onChange={setSearchValue}
                        value={searchValue}
                    />

                    <div className="main-checkbox">
                        <CheckboxInput
                            checked={isOnlySelected}
                            onChange={() =>
                                setOnlySelected(prevState => !prevState)
                            }
                        >
                            {i18n.t('attributions.showOnlySelected')}
                        </CheckboxInput>
                    </div>
                    <div className="list-checkbox">
                        {optionsToDisplay.map(item => {
                            return (
                                <div key={item.value}>
                                    <CheckboxInput
                                        checked={value.includes(item.value)}
                                        onChange={() =>
                                            handleChange(item.value)
                                        }
                                    >
                                        {item.label}
                                    </CheckboxInput>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

ComplicatedCheckboxSelect.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired
};

export default React.memo(ComplicatedCheckboxSelect);
