import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Row } from "reactstrap";
import i18n from "i18next";

import "../../../UI/headerPlayerInfo/styles.scss";
import listIcon from '../../../assets/images/retail/list.svg';
import plusIcon from "../../../assets/images/retail/plusIcon.svg";

import "../styles.scss";

import RadiosButtons from "../../../UI/radiosButtons/RadioButtonsGroup";
import { DocumentSide, DocumentType } from "../../../utils/KYC";
import {
    isImage,
    isPdf,
    loadPdfFromData,
    openPdfInCanvas,
    readFileAsArrayBuffer,
    readFileAsDataUrl,
    resizeImg,
} from "../../../common/utils/common";
import { DOCUMENT_STATUSES_VALUES, IMAGE_TYPES, PDF_TYPES } from "../../../common/constants/common";
import useAlertService from "../../../hooks/useAlertService";
import { useDispatch, useSelector } from "react-redux";
import InfoUploadLocalFile from "../../../UI/infoUploadLocalFile/InfoUploadLocalFile";
import ACommonDataField from "../../../components/GenericComponents/CommonDataField";
import { A_COMMON_DATA_FIELD } from "../../../constants/aCommonDataField";
import DeclineSubmitBtnGroup from "../../../UI/buttonsGroups/DeclineSubmitBtnGroup";
import {
    setOccupation,
    uploadRetailDocument,
    getPlayerSofAgreement,
    getRetailPlayer,
    updateRetailKYCStatus,
} from '../../../store/actions';
import {getFileSizeMB, MAX_SIZE_IMAGE_MB} from "../../../utils/uploadImage";
import imageCompression from "browser-image-compression";
import clsx from "clsx";
import Loader from "../../../common/components/Loader";

const initialVerificationData = {
    [DocumentSide.Front]: null,
    [DocumentSide.Back]: null,
};

const initialAddressData = {
    [DocumentSide.Front]: null,
};

const KYC = ({ isEdit, setIsEdit }) => {
    const dispatch = useDispatch();
    const alertService = useAlertService();
    const { retailPlayer, isStatusUpdated } = useSelector(state => state.PlayerGeneral);
    const { isUpdateSofAgreement, errorSofAgreement } = useSelector(state => state.PlayerVerification);
    const {
        isLoading,
        isOccupationUploading,
        error,
    } = useSelector(state => state.Documents);

    const [currVerificationDocType, setCurrVerificationDocType] = useState(DocumentType.INE);
    const [currAddressDocType, setCurrAddressDocType] = useState(DocumentType.UtilityBill);
    const [currProfession, setCurrProfession] = useState( "");
    const [verificationFiles, setVerificationFiles] = useState(initialVerificationData);
    const [addressFiles, setAddressFiles] = useState(initialAddressData);
    const [addressPreview, setAddressPreview] = useState('');
    const [verificationPreview, setVerificationPreview] = useState(initialVerificationData);
    const [showSaveVerificationBtns, setShowSaveVerificationBtns] = useState(false);
    const [showSaveAddressBtns, setShowSaveAddressBtns] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [verificationConfirmed, setVerificationConfirmed] = useState(false);
    const [addressConfirmed, setAddressConfirmed] = useState(false);
    const [sofConfirmed, setSofConfirmed] = useState(false);
    const [occupationConfirmed, setOccupationConfirmed] = useState(false);
    const [occupationIsEdit, setOccupationIsEdit] = useState(false);
    const [verificationUploaded, setVerificationUploaded] = useState({
        [DocumentType.INE]: false,
        [DocumentType.IFE]: false,
        [DocumentType.Passport]: false,
    });


    const verificationMethods = [
        {
            value: DocumentType.INE,
            label: i18n.t("retail.customer.KYC.INE"),
        },
        {
            value: DocumentType.IFE,
            label: i18n.t("retail.customer.KYC.IFE"),
        },
        {
            value: DocumentType.Passport,
            label: i18n.t("retail.customer.KYC.passport"),
        },
    ];

    const verificationAddress = [
        {
            value: DocumentType.UtilityBill,
            label: i18n.t("retail.customer.KYC.CFEorTELMEX"),
        },
        {
            value: DocumentType.BankStatementAsProofOfAddress,
            label: i18n.t("retail.customer.KYC.bank"),
        },
    ];

    const prevState = useRef({
        occupationUploading: false,
        loading: false,
        isStatusUpdated: false,
        isUpdateSofAgreement: false,
    });

    useEffect(() => {
        if (addressFiles.Front || verificationFiles.Front || verificationFiles.Back) {
            setIsEdit(true)
        } else {
            setIsEdit(false)
        }
    }, [addressFiles, verificationFiles])

    useEffect(() => {
        if (currVerificationDocType === DocumentType.Passport &&
            DocumentSide.Back in verificationFiles
        ) {
            setVerificationFiles({ [DocumentSide.Front]: null });
        }

        if ([DocumentType.INE, DocumentType.IFE].includes(currVerificationDocType) &&
            !(DocumentSide.Back in verificationFiles)
        ) {
            setVerificationFiles(initialVerificationData);
        }
    }, [currVerificationDocType]);

    useEffect(() => {
        if ([DocumentType.INE, DocumentType.IFE].includes(currVerificationDocType)) {
            setShowSaveVerificationBtns(
                !!(verificationFiles[DocumentSide.Front] && verificationFiles[DocumentSide.Back])
            );
        }
        else {
            setShowSaveVerificationBtns(!!verificationFiles[DocumentSide.Front]);
        }
    }, [
        currVerificationDocType,
        verificationFiles[DocumentSide.Front],
        verificationFiles[DocumentSide.Back],
    ]);

    useEffect(() => {
        setShowSaveAddressBtns(!!addressFiles[DocumentSide.Front]);
    }, [currAddressDocType, addressFiles[DocumentSide.Front]]);

    useEffect(() => {
        if (isOccupationUploading === false && prevState.current.occupationUploading) {
            if (error) {
                alertService.showError(i18n.t('retail.customer.KYC.noUpdated'));
            }
            else {
                alertService.showSuccess(i18n.t('retail.customer.KYC.updated'));
                setIsEdit(false);
                dispatch(getRetailPlayer(retailPlayer.oid));
                setOccupationConfirmed(true);
            }
        }

        prevState.current = {
            ...prevState.current,
            occupationUploading: isOccupationUploading,
        };
    }, [isOccupationUploading, retailPlayer]);

    useEffect(() => {
        if (isStatusUpdated === false && prevState.current.isStatusUpdated) {
            if (error) {
                alertService.showError(i18n.t('retail.customer.KYC.status.noUpdated'));
            }
            else {
                alertService.showSuccess(i18n.t('retail.customer.KYC.status.updated'));
                dispatch(getRetailPlayer(retailPlayer.oid));
            }
        }

        prevState.current = {
            ...prevState.current,
            isStatusUpdated: isStatusUpdated,
        };
    }, [isStatusUpdated, retailPlayer]);

    useEffect(() => {
        if (isUpdateSofAgreement === false && prevState.current.isUpdateSofAgreement) {
            if (errorSofAgreement) {
                alertService.showError(i18n.t('retail.customer.KYC.noUpdated'));
            }
            else {
                alertService.showSuccess(i18n.t('retail.customer.KYC.updated'));
                dispatch(getRetailPlayer(retailPlayer.oid));
                setSofConfirmed(true);
            }
        }

        prevState.current = {
            ...prevState.current,
            isUpdateSofAgreement: isUpdateSofAgreement,
        };
    }, [isUpdateSofAgreement, retailPlayer]);

    useEffect(() => {
        if (isLoading === false && prevState.current.loading) {
            if (error) {
                alertService.showError(i18n.t('retail.customer.KYC.noUpdated'));
            }
            else {
                alertService.showSuccess(i18n.t('retail.customer.KYC.updated'));
                if ([DocumentType.INE, DocumentType.IFE, DocumentType.Passport].includes(documentType)) {
                    setVerificationFiles(initialVerificationData);
                    setVerificationConfirmed(true);
                }
                else {
                    setAddressFiles(initialAddressData);
                    setAddressConfirmed(true);
                }
                setDocumentType('');
            }
        }

        prevState.current = {
            ...prevState.current,
            loading: isLoading,
        };
    }, [isLoading, retailPlayer]);

    useEffect(() => {
        setCurrProfession(retailPlayer?.occupation);
    }, [retailPlayer]);

    const handleChangeVerificationMethods = (event) => {
        const newVerificationDocType = event.target.value;
        const hasUploaded = verificationUploaded[newVerificationDocType];

        setCurrVerificationDocType(newVerificationDocType);
        setVerificationConfirmed(hasUploaded);
    };

    const handleChangeVerificationAddress = (event) => {
        setCurrAddressDocType(event.target.value);
        setAddressFiles(initialAddressData);
    };

    const handleChangeProfession = (keyOfValue) => {
        if (keyOfValue) {
            setCurrProfession(keyOfValue);
            setIsEdit(true);
            setOccupationIsEdit(true);
        }
        if (!keyOfValue && retailPlayer?.occupation) {
            setCurrProfession(keyOfValue);
            setIsEdit(true);
        }
    }

    const handleCancel = useCallback((typeFile) => {
        setOccupationIsEdit(false);
        if (typeFile && typeof typeFile === 'string') {
            if (typeFile === "Address") {
                setAddressFiles(initialAddressData);
                setAddressPreview('');
            } else {
                setVerificationFiles(prev => ({ ...prev, [typeFile]: null }));
                setVerificationPreview(prev => ({ ...prev, [typeFile]: null }));
            }
            return
        }

        setCurrProfession(retailPlayer?.occupation);
        setIsEdit(false);
    },[retailPlayer?.occupation]);

    const supportedFormats = [...PDF_TYPES, ...IMAGE_TYPES].join(',');

    const uploadLocalFiles = async (e) => {
        const fileName = e.target.name;
        let file = event.target.files[0];
        const isPdfFile = isPdf(file.type);

        if (file && !isPdfFile && getFileSizeMB(file.size) > MAX_SIZE_IMAGE_MB) {
            const options = {
                maxSizeMB: 5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: file.type
            }
            try {
                file = await imageCompression(file, options);
                file = new File([file], file.name, { type: file.type });
            } catch (error) {
                console.log(error);
            }
        }

        if (getFileSizeMB(file.size) > MAX_SIZE_IMAGE_MB) {
            alertService.showError(i18n.t('crm.largeFile.error'));
            event.target.value = '';
            return
        }

        if (file) {
            const canvas = document.createElement("canvas");
            canvas.setAttribute('id', `preview-canvas-${fileName}`);
            let previewDataURL;

            if (isPdfFile) {
                if (fileName === 'Address') {
                    setAddressFiles({ [DocumentSide.Front]: file });
                } else {
                    setVerificationFiles(prev => ({ ...prev, [fileName]: file }));
                }
                const pdfData = await readFileAsArrayBuffer(file);
                const pdf = await loadPdfFromData({ data: pdfData });
                previewDataURL = await openPdfInCanvas({
                    pdf,
                    canvas,
                    canvasWidth: 260,
                    pageNumber: 1,
                });
            } else if (isImage(file.type)) {
                if (fileName === 'Address') {
                    setAddressFiles({ [DocumentSide.Front]: file });
                } else {
                    setVerificationFiles(prev => ({ ...prev, [fileName]: file }));
                }
                const imageDataURL = await readFileAsDataUrl(file);
                previewDataURL = await resizeImg({
                    canvas,
                    canvasWidth: 260,
                    imageDataURL,
                });
            } else {
                event.target.value = '';
                alertService.showError(i18n.t('crm.OnlyImagesAndPdfAllowed'));
            }
            if (previewDataURL) {
                if (fileName === 'Address') {
                    setAddressPreview(previewDataURL);
                } else {
                    setVerificationPreview(prev => ({ ...prev, [fileName]: previewDataURL }));
                }
            }
        }
        e.target.value = null;
    };

    const onSubmit = () => {
        if (currProfession !== retailPlayer?.occupation) {
            dispatch(setOccupation({
                oid: retailPlayer?.oid,
                occupation: currProfession,
            }));
            prevState.current = {
                ...prevState.current,
                occupationUploading: true,
            };
        }
        setOccupationIsEdit(false);
    }

    const filesVerificationMethods = useMemo(() => {
        return Object.entries(verificationFiles)
    }, [verificationFiles, currVerificationDocType]);

    const uploadFile = (documentData, file) => {
        dispatch(uploadRetailDocument(
            retailPlayer.oid,
            documentData,
            file,
            alertService)
        );
    };

    const prepareFileData = (type, side) => {
        const status = DOCUMENT_STATUSES_VALUES[1];
        const isAddress =
            [DocumentType.UtilityBill, DocumentType.BankStatementAsProofOfAddress].includes(type);
        const files = isAddress ? addressFiles : verificationFiles;
        return {
            type,
            status,
            side,
            preview: isAddress ? addressPreview : verificationPreview[side],
            fileName: files[side].name,
            fileSize: files[side].size,
        };
    }

    const handleUploadVerificationFiles = () => {
        const frontData = prepareFileData(currVerificationDocType, DocumentSide.Front);
        uploadFile(frontData, verificationFiles[DocumentSide.Front]);

        setVerificationUploaded(prevState => ({
            ...prevState,
            [currVerificationDocType]: true,
        }));

        if ([DocumentType.INE, DocumentType.IFE].includes(currVerificationDocType)) {
            const backData = prepareFileData(currVerificationDocType, DocumentSide.Back);
            uploadFile(backData, verificationFiles[DocumentSide.Back]);
        }

        prevState.current = {
            ...prevState.current,
            loading: true,
        };

        setDocumentType(currVerificationDocType);
    };


    const handleUploadAddressFiles = () => {
        const frontData = prepareFileData(currAddressDocType, DocumentSide.Front);
        uploadFile(frontData, addressFiles[DocumentSide.Front]);

        prevState.current = {
            ...prevState.current,
            loading: true,
        };

        setDocumentType(currAddressDocType);
    };

    const withSaveIfeIne = showSaveVerificationBtns &&
        [DocumentType.INE, DocumentType.IFE].includes(currVerificationDocType);

    const handleDeclineKYC = () => {
        dispatch(updateRetailKYCStatus({
            kycStatus: false,
            playerId: retailPlayer.id,
        }));
    };

    const handleApproveeKYC = () => {
        dispatch(updateRetailKYCStatus({
            kycStatus: true,
            playerId: retailPlayer.id,
        }));

    };

    const handleSofAgreement = () => {
        dispatch(getPlayerSofAgreement({
            oid: retailPlayer.oid,
        }));
    };

    return (
        <Row>
            <Col lg="12">
                <Card className="retail-KYC">
                    <div className="title-section">
                        <img
                            src={listIcon}
                            alt="icon profile"
                        />
                        {i18n.t("retail.customer.title.KYC")}
                    </div>

                    <div className="title-section subtitle">
                        {i18n.t("retail.customer.title.KYC.identity")}
                    </div>

                    <RadiosButtons
                        value={currVerificationDocType}
                        name={'verificationMethods'}
                        groupButtons={verificationMethods}
                        handleChange={handleChangeVerificationMethods}
                        classNameWrapperGroup={'radiosButtons_row'}
                    />

                    <div className={clsx('upload_buttons', {
                        with_save_button: withSaveIfeIne,
                    })}>
                        <label
                            htmlFor={'fileControlFrontSide'}
                            className='btn btn-rounded btn-primary label'
                        >
                            {verificationConfirmed
                                ? <span
                                    key={'verification_confirmed_1'}
                                    className={"uploading_confirmed"}
                                >V</span>
                                : <img
                                    key={'verification_confirmed_1'}
                                    src={plusIcon}
                                    alt="setting icon"
                                />
                            }
                            <span className='retail-doc-side'>
                                {i18n.t("retail.customer.KYC.buttonFront")}
                            </span>
                            <input
                                className={'input_type'}
                                name={DocumentSide.Front}
                                type="file"
                                id={'fileControlFrontSide'}
                                onChange={uploadLocalFiles}
                                accept={supportedFormats}
                                disabled={isLoading}
                            />
                        </label>
                        {currVerificationDocType !== DocumentType.Passport &&
                            <>
                                <label
                                    htmlFor={'fileControlBackSide'}
                                    className='btn btn-rounded btn-primary label'
                                >
                                    {verificationConfirmed
                                        ? <span
                                            key={'verification_confirmed_2'}
                                            className={"uploading_confirmed"}
                                        >V</span>
                                        : <img
                                            key={'verification_confirmed_2'}
                                            src={plusIcon}
                                            alt="setting icon"
                                        />
                                    }
                                    <span className='retail-doc-side'>
                                         {i18n.t("retail.customer.KYC.buttonBack")}
                                    </span>
                                    <input
                                        className={'input_type'}
                                        name={DocumentSide.Back}
                                        type="file"
                                        id={'fileControlBackSide'}
                                        onChange={uploadLocalFiles}
                                        accept={supportedFormats}
                                        disabled={isLoading}
                                    />
                                </label>
                            </>
                        }
                        {showSaveVerificationBtns &&
                            <Button
                                className='btn btn-rounded btn-primary label'
                                disabled={isLoading}
                                color={'primary'}
                                onClick={handleUploadVerificationFiles}
                            >
                                {i18n.t("retail.customer.KYC.save.upload")}
                            </Button>
                        }
                        {isLoading &&
                            <Loader size='sm' />
                        }
                    </div>
                    <InfoUploadLocalFile
                        files={filesVerificationMethods}
                        reset={handleCancel}
                    />

                    <div className={'divider'} />

                    <div className="title-section subtitle">
                        {i18n.t("retail.customer.title.KYC.address")}
                    </div>
                    <RadiosButtons
                        value={currAddressDocType}
                        name={'verificationAddress'}
                        groupButtons={verificationAddress}
                        handleChange={handleChangeVerificationAddress}
                        classNameWrapperGroup={'radiosButtons_row'}
                    />
                    <div className={'upload_buttons kyc_address'}>
                        <label
                            htmlFor={'fileControlUploadAddress'}
                            className='btn btn-rounded btn-primary label label__uploadAddress'
                        >
                            {addressConfirmed
                                ? <span
                                    key={'address_confirmed'}
                                    className={"uploading_confirmed"}
                                >V</span>
                                : <img
                                    key={'address_confirmed'}
                                    src={plusIcon}
                                    alt="setting icon"
                                />
                            }
                            {i18n.t("retail.customer.KYC.buttonUploadDoc")}
                            <input
                                className={'input_type'}
                                name={'Address'}
                                type="file"
                                id={'fileControlUploadAddress'}
                                onChange={uploadLocalFiles}
                                accept={supportedFormats}
                                disabled={isLoading}
                            />
                        </label>

                        {showSaveAddressBtns &&
                            <Button
                                className='btn btn-rounded btn-primary label'
                                onClick={handleUploadAddressFiles}
                                disabled={isLoading}
                                color={'primary'}
                            >
                                {i18n.t("retail.customer.KYC.save.upload")}
                            </Button>
                        }
                    </div>
                    <InfoUploadLocalFile
                        files={Object.entries(addressFiles)}
                        reset={handleCancel}
                        typeFile={"Address"}
                    />

                    <div className={'divider'} />

                    <div
                        className={"occupation_sof_section"}
                    >
                        <div
                            className={"occupation_column"}
                        >
                            <div className="title-section subtitle">
                                {i18n.t("retail.customer.title.KYC.profession")}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {occupationConfirmed &&
                                <span className={"occupation_confirmed"} >V</span>
                                }
                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.SELECT_PROFESSION}
                                    label={i18n.t("retail.customer.KYC.profession")}
                                    value={currProfession}
                                    handleChange={handleChangeProfession}
                                />
                            </div>

                            <Col lg="12">
                                <div className={'d-flex justify-content-center mt-4 wrapperButtons'}>
                                    {occupationIsEdit && (
                                        <>
                                            <DeclineSubmitBtnGroup
                                                onSave={onSubmit}
                                                onCancel={handleCancel}
                                                isDisabled={isLoading}
                                            />
                                            {isOccupationUploading &&
                                                <div
                                                    className={"occupation_loader"}
                                                >
                                                    <Loader size={'sm'}/>
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </Col>
                        </div>

                        <div
                            className={"sof_column"}
                        >
                            <div className="title-section subtitle">
                                {i18n.t("crm.sofAgreement")}
                            </div>

                            <div className={clsx('upload_buttons', {
                                with_loader: isUpdateSofAgreement,
                            })}>
                                <button
                                    className="btn btn-rounded btn-primary mt-3"
                                    onClick={handleSofAgreement}
                                    disabled={retailPlayer.SOFAgreement}
                                >
                                    {sofConfirmed &&
                                        <span className={"uploading_confirmed sof_button"} >V</span>
                                    }
                                    {i18n.t('crm.sofAgreement')}
                                </button>

                                {isUpdateSofAgreement &&
                                    <div
                                        className={"sof_loader"}
                                    >
                                        <Loader size={'sm'}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={'divider'} />

                    <div className="title-section subtitle">
                        {i18n.t("retail.customer.title.KYC")}
                    </div>

                    <div className={clsx('upload_buttons', {
                        with_loader: isStatusUpdated
                    })}>
                        <Button
                            className='btn btn-rounded btn-primary'
                            disabled={!retailPlayer.kyc}
                            color={'primary'}
                            onClick={handleDeclineKYC}
                        >
                            {i18n.t("retail.customer.KYC.decline.KYC")}
                        </Button>

                        <Button
                            className='btn btn-rounded btn-primary'
                            disabled={retailPlayer.kyc}
                            color={'primary'}
                            onClick={handleApproveeKYC}
                        >
                            {i18n.t("retail.customer.KYC.approve.KYC")}
                        </Button>

                        {isStatusUpdated &&
                            <div
                                className={"kyc_loader"}
                            >
                                <Loader size={'sm'}/>
                            </div>
                        }
                    </div>
                </Card>
            </Col>
        </Row>
    )
};

export default KYC;
