import React, {useEffect} from 'react';
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import { useTranslation } from "react-i18next";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

// Images
import close from "../../../assets/images/common/close 1.svg";
import i_51 from "../../../assets/images/common/Frame 176 (1).svg";
import clsx from "clsx";


const GamesConfirmationModal = (props) => {
    const {
        onCancel,
        onSave,
        games,
        gamesToAdd,
        gamesToDelete,
        isShownSelectedGames,
    } = props;

    function getFriendlyNameAndOidForGames(gameIds, games) {
        return gameIds.map(gameId => {
            const game = games.find(game => game.id === gameId);
            if (game) {
                return `${isShownSelectedGames 
                    ? game.gameName
                    : game.friendlyName} (${game.oid})`;
            }
            return '';
        });
    }

    const gamesToAddNames = getFriendlyNameAndOidForGames(gamesToAdd, games);
    const gamesToDeleteNames = getFriendlyNameAndOidForGames(gamesToDelete, games);

    const { t } = useTranslation();


    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="game-confirmation-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {t('crm.confirmationHeader')}
                </h5>
                <button className="close" onClick={onCancel} />
            </div>

            <ModalBody className='game-confirmation-modal-body d-flex justify-content-around'>
                <div
                    className={clsx("d-flex flex-column", {
                        'd-none': !gamesToAdd.length,
                    })}
                >
                    <div className='d-flex justify-content-center'>
                        <h5>
                            {`${t('crm.Add')} ${gamesToAdd.length} ${t(`crm.game${gamesToAdd.length !== 1 ? 's' : ''}`)}`}
                        </h5>
                    </div>
                    <div className='d-flex flex-column'>
                        {gamesToAddNames.map((gameName, index) => (
                            <div className='mt-1' key={index}>{gameName}</div>
                        ))}
                    </div>
                </div>

                <div
                    className={clsx("d-flex flex-column", {
                        'd-none': !gamesToDelete.length,
                    })}>
                    <div className='d-flex justify-content-center'>
                        <h5>
                            {`${t('crm.Remove')} ${gamesToDelete.length} ${t(`crm.game${gamesToDelete.length !== 1 ? 's' : ''}`)}`}
                        </h5>
                    </div>
                    <div>
                        {gamesToDeleteNames.map((gameName, index) => (
                            <div className='mt-1' key={index}>{gameName}</div>
                        ))}
                    </div>
                </div>
            </ModalBody>


            <ModalFooter className='game-confirmation-footer'>
                <button
                    className="btn btn-light btn-rounded"
                    onClick={onCancel}
                >
                    <img src={close} alt="" />
                    {t('crm.cancel')}
                </button>
                <button
                    className="btn btn-primary btn-rounded btn"
                    onClick={onSave}
                >
                    <img src={i_51} alt="" />
                    {t('crm.confirm')}
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default React.memo(GamesConfirmationModal);