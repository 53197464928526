import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../../../i18n';
import { A_COMMON_DATA_FIELD } from '../../../../constants/aCommonDataField';
import ACommonDataField from '../../../../components/GenericComponents/CommonDataField';
import './ManualAdjustment.scss';
import * as moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import Formatter from "../../../../utils/Formatter";
import { PROMOTION_TYPE } from "../../../Promotions/common/promotionConstants";
import { applyPlayerPromotion } from "../../../../store/players/promotions/actions";
import { DATE_TIME_FORMAT } from "../../../../common/utils/common";
import SaveCancelButtons from "../../../../components/Buttons/SaveCancelButtons";
import {
    COMMA_DOT_DIGITS_REG_EXP,
    PROMOTION_MAX_MONEY_VALUE_REG_EXP
} from "../../../../constants/validations";
import { fieldsNamesForRestrict } from "../../../../common/constants/common";
import { removeUnScrollBody, setUnScrollBody } from 'utils/unScrollBody';

const DefaultValidityDays = 7;

const prepareModel = (template, playerCurrency) => {
    const {segmentation = {}, settings} = template;
    const {currencies} = segmentation;
    const {configuration = {}, type, validityDays = DefaultValidityDays} = settings;
    const currency = currencies.includes(playerCurrency)
        ? playerCurrency
        : (currencies[0] || 'MXN');
    const bonusLimit = Formatter.formatAmount(
        configuration?.bonusLimit && configuration?.bonusLimit[currency] || 0,
        currency
    );
    const amount = configuration?.amount && configuration?.amount[currency] || 0;
    const amountFreeSpins = configuration?.numberOfSpins;
    const bonusLimitMultiplier = configuration.bonusLimitMultiplier;
    const wageringMultiplier = configuration.wageringMultiplier;
    const depositInitialMaxAmount = Formatter.formatAmount(
        configuration?.depositInitialMaxAmount && configuration?.depositInitialMaxAmount[currency] || 0,
        currency
    );

    const startTime = moment();
    const endTime = moment().add(+validityDays, 'd');

    const newModel = {
        id: template.id,
        promotionTemplate: template.id,
        name: template.name,
        bonusName: '',
        amount,
        currency,
        wageringMultiplier,
        depositInitialMaxAmount,
        bonusLimit,
        bonusLimitMultiplier,
        startTime: startTime.format(DATE_TIME_FORMAT), // Start time (utc):
        endTime: endTime.format(DATE_TIME_FORMAT), // End time (utc):
        type,
    };

    if (type === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT) {
        newModel.depositAmount = 0;
    }

    if (type === PROMOTION_TYPE.FREE_SPINS && amountFreeSpins !== undefined) {
        newModel.amount = amountFreeSpins;
    }

    return newModel;
}

function NewBonusModal({
    onHide,
    fieldErrors,
    playerId,
    isPromotionsAllowed,
    promotions = [],
}) {
    const dispatch = useDispatch();
    const [playerCurrency, setPlayerCurrency] = useState('MXN');

    const { playerGeneral } = useSelector(
        state => state.PlayerGeneral
    );
    useEffect(() => {
        if (playerGeneral.currency) {
            setPlayerCurrency(playerGeneral.currency);
        }
    }, [playerGeneral]);

    const [options, setOptions] = useState([{
        value: '',
        content: 'promo.name',
    }]);

    const [model, setModel] = useState({
        id: '',
        promotionTemplate: '',
        bonusName: '',
        amount: 0,
        currency: 'MXN',
        wageringMultiplier: 0,
        depositInitialMaxAmount: 0,
        bonusLimit: 0,
        bonusLimitMultiplier: 0,
        startTime: moment().format(), // Start time (utc):
        endTime: moment().format(), // End time (utc):
    });

    const restrictCents = model.currency === 'MXN' ||
                          model.currency === 'USD' ||
                          model.currency === 'EUR'

    const handleChange = useCallback(name => {
        return value => {
            if (name === 'depositAmount') {
                value = value.replace(COMMA_DOT_DIGITS_REG_EXP, '')
            }
            if (restrictCents && fieldsNamesForRestrict(name)) {
                value = value.replace(',', '.')
                    .replace(/[a-zA-Z]/g, '')
                    .replace(PROMOTION_MAX_MONEY_VALUE_REG_EXP, '$1');
            }
            if (name === 'promotionTemplate') {
                const bonus = promotions.find(promo => value === promo.id);
                if (bonus) {
                    const newModel = prepareModel(bonus, playerCurrency);
                    setModel(newModel);
                }
                setModel(prevState => ({...prevState, [name]: value}));
            } else {
                setModel(prevState => ({...prevState, [name]: value}));
            }
        };
    }, [promotions, playerCurrency]);

    useEffect(() => {
        if (promotions) {
            setOptions(promotions
                ?.map(promo => ({
                    value: promo.id,
                    content: promo.name
                }))
            )
            if (promotions.length > 0) {
                const newModel = prepareModel(promotions[0], playerCurrency);
                setModel(newModel);
            }
        }
    }, [promotions, playerCurrency])

    const handleSave = useCallback(() => {
        dispatch(applyPlayerPromotion({
            id: playerId,
            promotionId: model.id,
            query: {
                name: model.bonusName ? model.bonusName : model.name,
                bonus_amount: model.amount,
                deposit_amount: model.depositAmount,
                ...(!isPromotionsAllowed && { force: true }),
            }
        }));

        onHide();
    }, [model, isPromotionsAllowed]);

    return (
        <Modal
            isOpen={true}
            role="dialog"
            autoFocus={true}
            data-toggle="modal"
            className="new-bonus-modal"
            centered
            onOpened={setUnScrollBody}
            onExit={removeUnScrollBody}
        >
            <div className="modal-header">
                <h5 className="modal-title text-uppercase">
                    {i18n.t('promotions.newBonus')}
                </h5>
                <button className="close" onClick={onHide}/>
            </div>

            <ModalBody>
                <div
                    className="d-flex"
                >
                    <div className="col-6 promotion-select">
                        <div key={'promotionTemplate'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['promotionTemplate']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.SELECT_DEFAULT}
                                label={i18n.t('promotions.promotionTemplate')}
                                value={model.promotionTemplate}
                                handleChange={handleChange('promotionTemplate')}
                                options={options}
                            />
                        </div>
                        {model.type !== PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT &&
                            <div key={'amount'} className="mt-3">
                                <ACommonDataField
                                    error={fieldErrors?.['amount']}
                                    fulWidth={true}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    label={i18n.t('promotions.amount')}
                                    value={model.amount}
                                    handleChange={handleChange('amount')}
                                    disabled={model.type === PROMOTION_TYPE.FREE_SPINS}
                                />
                            </div>
                        }
                        {model.type === PROMOTION_TYPE.BONUS_WAGERING_ON_DEPOSIT &&
                            <div key={'depositAmount'} className="mt-3">
                                <ACommonDataField
                                    error={fieldErrors?.['depositAmount']}
                                    fulWidth={true}
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    label={i18n.t('promotions.depositAmount')}
                                    value={model.depositAmount}
                                    handleChange={handleChange('depositAmount')}
                                />
                            </div>
                        }
                        <div key={'wageringMultiplier'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['wageringMultiplier']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.wageringMultiplier')}
                                value={model.wageringMultiplier || ''}
                                handleChange={handleChange('wageringMultiplier')}
                                disabled={true}
                            />
                        </div>
                        <div key={'bonusLimit'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['bonusLimit']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.bonusLimit')}
                                value={model?.bonusLimit}
                                handleChange={handleChange('bonusLimit')}
                                disabled={true}
                            />
                        </div>
                        <div key={'startTime'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['startTime']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.startTime')}
                                value={model.startTime}
                                handleChange={handleChange('startTime')}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div key={'name'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['bonusName']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.bonusName')}
                                value={model.bonusName}
                                handleChange={handleChange('bonusName')}
                            />
                        </div>
                        <div key={'type'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['type']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.type')}
                                value={i18n.t(`promotions.constants.${model.type}`)}
                                handleChange={handleChange('type')}
                                disabled={true}
                            />
                        </div>
                        <div key={'depositInitialMaxAmount'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['depositInitialMaxAmount']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.depositInitialMaxAmount')}
                                value={model.depositInitialMaxAmount}
                                handleChange={handleChange('depositInitialMaxAmount')}
                                disabled={true}
                            />
                        </div>
                        <div key={'bonusLimitMultiplier'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['bonusLimitMultiplier']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.bonusLimitMultiplier')}
                                value={model.bonusLimitMultiplier || ''}
                                handleChange={handleChange('bonusLimitMultiplier')}
                                disabled={true}
                            />
                        </div>
                        <div key={'endTime'} className="mt-3">
                            <ACommonDataField
                                error={fieldErrors?.['endTime']}
                                fulWidth={true}
                                component={A_COMMON_DATA_FIELD.TEXT}
                                label={i18n.t('promotions.endTime')}
                                value={model.endTime}
                                handleChange={handleChange('endTime')}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <SaveCancelButtons
                    onCancel={onHide}
                    onSave={handleSave}
                    isModalButtons={true}
                    saveBtnText={i18n.t('admin.add')}
                    isDisabledSave={!model.promotionTemplate
                        || (!model?.amount && !model?.depositAmount)
                        || (+model?.amount <= 0 && (model?.depositAmount === undefined || model?.depositAmount <= 0))
                        || ((model?.amount === undefined || model?.amount <= 0) && +model?.depositAmount <= 0)
                    }
                    isDisabledCancel={false}
                />
            </ModalFooter>
        </Modal>
    );
}

NewBonusModal.propTypes = {
    playerId: PropTypes.any.isRequired,
    onHide: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object,
    isLoading: PropTypes.bool,
    promotions: PropTypes.array,
};

export default React.memo(NewBonusModal);
