import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import './status.scss';
import { PAYMENT_STATUS } from '../../constants/status';
import i18n from '../../i18n';

function PaymentStatusView({ status }) {
    const iconColor = useMemo(() => {
        switch (status) {
            case PAYMENT_STATUS.ERROR:
                return '#E44F73';
            case PAYMENT_STATUS.DECLINED:
                return '#E44F73';
            case PAYMENT_STATUS.CANCELED:
                return '#A2A2B9';
            case PAYMENT_STATUS.COMPLETED:
                return '#37A57E';
            case PAYMENT_STATUS.PENDING_FOR_APPROVAL:
                return '#4F8DE4';
            case PAYMENT_STATUS.PROCESSING:
                return '#E3C54F';
            case PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER:
            case PAYMENT_STATUS.PENDING_FOR_PLAYER:
                return '#E3C54F';
            case PAYMENT_STATUS.CHARGEBACK:
            case PAYMENT_STATUS.REFUNDED:
                return '#744FE4';
            case PAYMENT_STATUS.PARTIAL_REFUNDED:
                return '#37A57E';
            default:
                return '#A2A2B9';
        }
    }, [status]);

    return (
        <div className={`payment-status ${status}`}>
            {i18n.t(`finance.deposit.status.${status}`)}
        </div>
    );
}

PaymentStatusView.propTypes = {
    status: PropTypes.string.isRequired
};

export default React.memo(PaymentStatusView);
