import { takeEvery, put, call } from 'redux-saga/effects';
import {
    APPROVE_PENDING_WITHDRAWAL,
    DECLINE_PENDING_WITHDRAWAL,
    GET_PENDING_WITHDRAWAL
} from './actionTypes';
import {
    getPendingWithdrawalSuccess,
    getPendingWithdrawalFail,
    approvePendingWithdrawalSuccess,
    approvePendingWithdrawalFail,
    getPendingWithdrawal,
    declinePendingWithdrawalSuccess,
    declinePendingWithdrawalFail
} from './actions';
import {
    approveTransactionApi,
    declineTransactionApi,
    getPaymentWithdrawalApi
} from '../../../helpers/paymentApi';

function* fetchPendingWithdrawalSaga({ payload }) {
    try {
        const response = yield call(getPaymentWithdrawalApi, payload);

        yield put(getPendingWithdrawalSuccess(response.data));
    } catch (error) {
        yield put(getPendingWithdrawalFail(error));
    }
}

function* approveTransactionSaga({ payload }) {
    try {
        const response = yield call(approveTransactionApi, payload);
        yield put(approvePendingWithdrawalSuccess(response.data));

        yield put(getPendingWithdrawal(payload.id));
    } catch (error) {
        yield put(approvePendingWithdrawalFail(error));
    }
}

function* declineTransactionSaga({ payload }) {
    try {
        const response = yield call(declineTransactionApi, payload);
        yield put(declinePendingWithdrawalSuccess(response.data));

        yield put(getPendingWithdrawal(payload.id));
    } catch (error) {
        yield put(declinePendingWithdrawalFail(error));
    }
}

function* pendingWithdrawalSaga() {
    yield takeEvery(GET_PENDING_WITHDRAWAL, fetchPendingWithdrawalSaga);
    yield takeEvery(APPROVE_PENDING_WITHDRAWAL, approveTransactionSaga);
    yield takeEvery(DECLINE_PENDING_WITHDRAWAL, declineTransactionSaga);
}

export default pendingWithdrawalSaga;
