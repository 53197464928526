
const breadcrumbsPages = (path) => {
    if (/^\/player-list\/.+$/.test(path)) {
        return (
            <>
                <a href="/player-list"
                   className='breadcrumb-header-link mr-2'
                > Player list </a> / Player view
            </>
        );
    } else if (/^\/pending-kyc-verification\/.+$/.test(path)) {
        return (
            <>
                <a href="/pending-kyc-verification"
                   className='breadcrumb-header-link mr-2'
                > Player Verifications </a> / KYC view
            </>
        );
    } else if (/^\/tracker\/.+$/.test(path)) {
        return (
            <>
                <a href="/tracker"
                   className='breadcrumb-header-link mr-2'
                > Tracker </a> / Tracker view
            </>
        );
    } else if (/^\/deposits\/.+$/.test(path)) {
        return (
            <>
                <a href="/deposits"
                   className='breadcrumb-header-link mr-2'
                > Deposits </a> / Deposits view
            </>
        );
    } else if (/^\/deposits-declines\/.+$/.test(path)) {
        return (
            <>
                <a href="/deposits-declines"
                   className='breadcrumb-header-link mr-2'
                > Decline Payments </a> / Deposits view
            </>
        );
    } else if (/^\/deposits-action\/.+$/.test(path)) {
        return (
            <>
                <a href="/deposits-action"
                   className='breadcrumb-header-link mr-2'
                > Pending for action </a> / Deposits view
            </>
        );
    } else if (/^\/withdrawals\/.+$/.test(path)) {
        return (
            <>
                <a href="/withdrawals"
                   className='breadcrumb-header-link mr-2'
                > Withdrawals list </a> / Withdraw view
            </>
        );
    } else if (/^\/withdrawals-pending-approve\/.+$/.test(path)) {
        return (
            <>
                <a href="/withdrawals-pending-approve"
                   className='breadcrumb-header-link mr-2'
                > WD for approval </a> / Withdraw view
            </>
        );
    } else if (/^\/withdrawals-pending-transfer\/.+$/.test(path)) {
        return (
            <>
                <a href="/withdrawals-pending-transfer"
                   className='breadcrumb-header-link mr-2'
                > WD for transfer </a> / Withdraw view
            </>
        );
    } else if (/^\/affiliates\/.+$/.test(path)) {
        return (
            <>
                <a href="/affiliates"
                   className='breadcrumb-header-link mr-2'
                > Affiliate List </a> / Affiliate View
            </>
        );
    } else if (/^\/retail-player-list\/.+$/.test(path)) {
        return (
            <>
                <a href="/retail-player-list"
                   className='breadcrumb-header-link mr-2'
                > Retail </a> / Retail player view
            </>
        );
    } else if (/^\/game-categories\/.+$/.test(path)) {
        return (
            <>
                <a href="/game-categories"
                   className='breadcrumb-header-link mr-2'
                > Game categories list </a> / Category view
            </>
        );
    } else if (/^\/promotions\/.+$/.test(path)) {
        return (
            <>
                <a href="/promotions"
                   className='breadcrumb-header-link mr-2'
                > Promotions </a> / Promotions view
            </>
        );
    } else if (/^\/sport-promotions\/.+$/.test(path)) {
        return (
            <>
                <a href="/sport-promotions"
                   className='breadcrumb-header-link mr-2'
                > Sport Promotions </a> / Promotions view
            </>
        );
    } else {
        return null;
    }
}

export default breadcrumbsPages;
