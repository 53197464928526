import {
    GET_PLAYER_SELECT_LIST,
    GET_PLAYER_SELECT_LIST_FAIL,
    GET_PLAYER_SELECT_LIST_SUCCESS
} from './actionTypes';

const INIT_STATE = {
    isLoading: false,
    error: null,
    playerList: null,
    successAlert: ''
};

const PlayerSelectList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_SELECT_LIST:
            return { ...state, isLoading: true, error: null };

        case GET_PLAYER_SELECT_LIST_SUCCESS:
            return { ...state, playerList: action.payload, isLoading: false };

        case GET_PLAYER_SELECT_LIST_FAIL:
            return { ...state, error: action.payload, isLoading: false };

        default:
            return state;
    }
};

export default PlayerSelectList;
