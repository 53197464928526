import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PersonalInfoSubBlock from './PersonalInfoSubBlock';
import AddressSubBlock from './AddressSubBlock';
import AffiliateSubBlock from './AffiliateSubBlock';
import ButtonsPanel from './ButtonsPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
    updatePlayerGeneral,
    updatePlayerAffiliateInfo,
    setPlayerGeneralEdit,
    getPlayerGeneral,
    getAgentsList,
} from '../../../store/actions';
import {
    prepareUpdatePlayerGeneralDto,
    prepareUpdatePlayerGeneralFieldErrors
} from './model/playerEditModel';
import AMLSubBlock from './AMLSubBlock';
import i18n from '../../../i18n';
import IdentificationsSubBlock from './IdentificationsSubBlock';
import ResidentialInfoModal from "../modal/ResidentialInfoModal"
import { useTranslation } from "react-i18next";
import moment from 'moment';
import Loader from "../../../common/components/Loader";
import BankInfoSubBlock from "./BankInfoSubBlock";
import { getProductName } from "../../../utils/products";
import CustomModal from "../../../common/components/CustomModal";
import { isEqual } from "lodash";
import useSiteBrand from "../../../hooks/useSiteBrand";
import { SITE_BRAND } from "../../../constants/siteBrands";
import { NAME_REG_EXP, MIDDLE_NAME_REG_EXP, USERNAME_REG_EXP } from "../../../constants/validations";
import {SITE_PERMISSION} from "../../../common/constants/common";

const CHECKED_FIELDS = [
    'firstName',
    'lastName',
    'middleName',
    'nickName',
];

const CHECKED_ADDRESS_FIELDS = [
    'addressCountryAlfa2',
    'city',
    'state',
    'street',
];

const ADDITIONAL_AFFILIATE_INFO_FIELDS = [
    'product',
];

const ButtonResidentialInfo = ({ onClick }) => (
  <div className="align-self-center flex-1">
      <button
        className="btn btn-outline-light btn-rounded"
        style={{ boxShadow: 'none' }}
        onClick={onClick}
      >
          {i18n.t('crm.residentialInfo')}
      </button>
  </div>
);

function GeneralTabContent({
    alertService,
    playerModel,
    setPlayerModel,
    setFieldErrors
}) {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading, playerGeneral, error, generalUpdated } = useSelector(
        state => state.PlayerGeneral
    );
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const [isShowResidentialInfo, setIsShowResidentialInfo] = useState(false);
    const siteBrand = useSiteBrand();
    const [affiliateChanges, setAffiliateChanges] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const isPlayerEditInfoPerm = agentSelf?.permissions.includes(SITE_PERMISSION.Player__Edit_Player_Info);

    const playerNickName = playerModel?.nickName;
    const playerBirthDate = playerModel?.birthDate;
    const playerCurrency = playerModel?.currency;
    const playerFirstName = playerModel?.firstName;
    const playerLastName = playerModel?.lastName;
    const playerMiddleName = playerModel?.middleName;
    const playerCitizenship = playerModel?.citizenship;
    const playerGender = playerModel?.gender;
    const playerProduct = playerModel?.affiliateInformation.product;

    const isTestFlag = playerModel?.isTest;

    const currentDate = new Date().toISOString();
    const is18yearsOld = moment().diff(moment(playerBirthDate), 'years') >= 18;

    useEffect(() => {
        setPlayerModel(JSON.parse(JSON.stringify(playerGeneral)));
        handleSetEdit(false);
    }, [playerGeneral]);

    useEffect(() => {
        if (!isLoading && affiliateChanges) {
            // Send player affiliate info changes
            dispatch(updatePlayerAffiliateInfo({
                id: playerGeneral.oid,
                dto: affiliateChanges,
            }));

            setAffiliateChanges(null);
        }
    }, [isLoading, affiliateChanges]);

    useEffect(() => {
        if (error && error.statusCode === 400) {
            setFieldErrors(
                prepareUpdatePlayerGeneralFieldErrors(error?.data)
            );
        }
    }, [error]);

    const handleSetEdit = nextIsEdit => {
        dispatch(setPlayerGeneralEdit(nextIsEdit));
    };

    useEffect(() => {
        dispatch(getAgentsList());
    }, []);

    useEffect(() => {
        if (generalUpdated) {
            alertService.showSuccess(i18n.t('crm.alerts.generalEdited'));
            dispatch(getPlayerGeneral(playerGeneral.oid));
        }
    }, [generalUpdated]);

    const handleChange = useCallback(
        (model, immediate) => change => {
            if (change?.target?.value) {
                change = change.target.value;
            }
            if (immediate) {
                immediate(change);
            }
            if (model === 'amlCURP' || model === 'amlRFC') {
                change = change.replace(/[^a-zA-Z0-9]/g, '');
            }
            if (model.includes('.')) {
                const array = model.split('.');
                if (array.length === 1) {
                    playerModel[array[0]] = change;
                } else if (array.length === 2) {
                    playerModel[array[0]][array[1]] = change;
                } else if (array.length === 3) {
                    playerModel[array[0]][array[1]][array[2]] = change;
                }
            }
            else {
                playerModel[model] = change;
            }
            setPlayerModel({ ...playerModel });
            handleSetEdit(true);
        },
        [playerModel, setPlayerModel]
    );

    const updatePlayerGeneralData = useCallback(() => {
        const dto = prepareUpdatePlayerGeneralDto(
            playerGeneral,
            playerModel
        );

        if (!('affiliateInformation' in dto)) {
            dto.bankInfo?.forEach(item => {
                if (!item['bankName'] && item['additionalBankId']) {
                    item['bankName'] = item['additionalBankId'];
                }

                if (typeof item['id'] !== "string") {
                    item['id'] = undefined;
                }
                item['additionalBankId'] = undefined;
            });
            dispatch(updatePlayerGeneral({ id: playerGeneral.oid, dto }));
        }
        else {
            let affiliateFields = {}
            let notAffiliateFields = {}
            const { affiliateInformation } = dto;
            Object.keys(affiliateInformation).forEach((key) => {
                if (ADDITIONAL_AFFILIATE_INFO_FIELDS.includes(key)) {
                    notAffiliateFields[key] = affiliateInformation[key];
                }
                else {
                    affiliateFields[key] = affiliateInformation[key];
                }
            });

            if (Object.keys(notAffiliateFields).length === 0) {
                delete dto.affiliateInformation;
            }
            else {
                dto.affiliateInformation = notAffiliateFields;
            }

            if (Object.keys(dto).length > 0) {
                // Send player general info changes
                dispatch(updatePlayerGeneral({ id: playerGeneral.oid, dto }));
            }

            if (Object.keys(affiliateFields).length > 0) {
                // Set player affiliate info changes to state for following sending
                setAffiliateChanges(affiliateFields);
            }
        }

        handleSetEdit(false);
    }, [playerModel, handleSetEdit, playerGeneral]);

    const handleSave = useCallback(() => {
        if (isTestFlag) {
            return updatePlayerGeneralData();
        }

        const hasAnyVerificationChanged = playerModel?.bankInfo.some((item, index) => {
            return item?.isVerified !== playerGeneral?.bankInfo[index]?.isVerified;
        });
        if (hasAnyVerificationChanged) {
            return setShowConfirmationModal(hasAnyVerificationChanged);
        }
        if (!isEqual(playerGeneral.bankInfo, playerModel.bankInfo)) {
            for (const bankInfoItem of playerModel.bankInfo) {
                if (!bankInfoItem.routingNumber) {
                    return alertService.showError(i18n.t('crm.EmptyClabe'));
                }
            }
        }
        if (siteBrand === SITE_BRAND.GCPLAYING) {
            if (playerCitizenship === '') {
                alertService.showError(i18n.t('crm.wrongCitizenship'));

            } else {
                updatePlayerGeneralData();
            }

        } else {
            if (playerNickName === '') {
                alertService.showError(i18n.t('сrm.wrongNickName'));

            } else if (playerNickName?.length < 4 || playerNickName?.length > 20) {
                alertService.showError(i18n.t('crm.wrongLengthUsername'));

            } else if (playerBirthDate > currentDate) {
                alertService.showError(i18n.t('crm.wrongDate'));

            } else if (!is18yearsOld) {
                alertService.showError(i18n.t('crm.alerts.minAge'));

            } else if (playerCurrency === '') {
                alertService.showError(i18n.t('crm.wrongCurrency'));

            } else if (playerFirstName === '') {
                alertService.showError(i18n.t('crm.wrongFirstName'));

            } else if (playerFirstName?.length < 2 || playerFirstName?.length > 50) {
                alertService.showError(i18n.t('crm.wrongLengthFirstName'));

            } else if (playerLastName === '') {
                alertService.showError(i18n.t('crm.wrongLastName'));

            } else if (playerLastName?.length < 2 || playerLastName?.length > 50) {
                alertService.showError(i18n.t('crm.wrongLengthLastName'));

            } else if (!NAME_REG_EXP.test(playerFirstName)) {
                alertService.showError(i18n.t('crm.invalidFirstName'));

            } else if (!NAME_REG_EXP.test(playerLastName)) {
                alertService.showError(i18n.t('crm.invalidLastName'));

            } else if (playerMiddleName?.length > 50) {
                alertService.showError(i18n.t('crm.wrongLengthMiddleName'));

            } else if (!MIDDLE_NAME_REG_EXP.test(playerMiddleName)) {
                alertService.showError(i18n.t('crm.invalidMiddleName'));

            } else if (!USERNAME_REG_EXP.test(playerNickName)) {
                alertService.showError(i18n.t('crm.invalidNickName'));

            } else if (playerCitizenship === '') {
                alertService.showError(i18n.t('crm.wrongCitizenship'));

            } else if (playerProduct === '' || getProductName(playerProduct) === null) {
                alertService.showError(i18n.t('crm.emptyProduct'));

            } else {
                updatePlayerGeneralData();
            }
        }
    }, [updatePlayerGeneralData, i18n.language, siteBrand]);

    const handleCancel = useCallback(() => {
        setPlayerModel(JSON.parse(JSON.stringify(playerGeneral)));
        handleSetEdit(false);
    }, [playerModel, handleSetEdit]);

    const isEdit = useMemo(() => {
        if (!playerGeneral || !playerModel) {
            return false;
        }

        const dto = prepareUpdatePlayerGeneralDto(
            playerGeneral,
            playerModel
        );
        const isEditMode = Object.keys(dto).length > 0;

        if (!isEditMode) {
            handleSetEdit(false);
        }
        setShowConfirmationModal(false);
        return isEditMode;
    }, [playerGeneral, playerModel]);


    return (
        <>
            {isLoading
                ? <Loader />
                : <div className="detailed-card__content">
                    <div className="content-block">
                        <PersonalInfoSubBlock
                            player={playerModel}
                            onChange={handleChange}
                            className='contend-field__row'
                            canEdit={isPlayerEditInfoPerm}
                        />
                        <hr />
                        <AMLSubBlock
                            alertService={alertService}
                            player={playerModel}
                            onChange={handleChange}
                            siteBrand={siteBrand}
                            className='contend-field__row'
                            canEdit={isPlayerEditInfoPerm}
                        />
                    </div>

                    <div className="vertical-separator" />

                    <div className="content-block">
                        <AddressSubBlock
                            player={playerModel}
                            onChange={handleChange}
                            className='contend-field__row'
                            canEdit={isPlayerEditInfoPerm}
                        />
                        <hr />
                        <AffiliateSubBlock
                            player={playerModel}
                            onChange={handleChange}
                            isEdit={isEdit}
                            onEdit={handleSetEdit}
                            className='contend-field__row'
                            canEdit={isPlayerEditInfoPerm}
                        />
                        <hr />
                        <IdentificationsSubBlock
                            player={playerModel}
                            onChange={handleChange}
                            canEdit={isPlayerEditInfoPerm}
                        />
                        <hr />
                        <BankInfoSubBlock
                            player={playerModel}
                            onChange={handleChange}
                            canEdit={isPlayerEditInfoPerm}
                        />
                        <hr />
                        <ButtonResidentialInfo
                            onClick={() => setIsShowResidentialInfo(true)}
                        />
                    </div>
                </div>
            }

            {showConfirmationModal &&
                <CustomModal
                    titleText={i18n.t('common.attention')}
                    isOpen={showConfirmationModal}
                    onToggle={() => setShowConfirmationModal(!showConfirmationModal)}
                    onClick={updatePlayerGeneralData}
                    onClickCancelButton={() => setShowConfirmationModal(false)}
                    btnText={'yes'}
                    cancelBtnText={'no'}
                    withCancelButton={true}
                    bodyRender={() => (
                        <>
                            <div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {i18n.t('promotions.applyChangesModal')}
                            </div>
                        </>
                    )}
                />
            }

            {isEdit && (
                <ButtonsPanel
                    onSave={handleSave}
                    onCancel={handleCancel}
                    isDisabled={isLoading}
                />
            )}

            {(isShowResidentialInfo) && (
              <ResidentialInfoModal
                residentialInfo={playerGeneral?.residentialInfo}
                setIsShowResidentialInfo={setIsShowResidentialInfo}
              />
            )}
        </>
    );
}

export default React.memo(GeneralTabContent);
