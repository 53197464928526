import { combineReducers } from 'redux';

// Authentication
import Login from './auth/login/reducer';

// Documents
import Documents from './documents/reducer';

// Players
import PlayerSelectList from './players/players/reducer';
import PlayerList from './players/list/reducer';
import PlayerJoint from './players/joint/reducer';
import PlayerGeneral from './players/general/reducer';
import PlayerWithdrawals from './players/withdrawals/reducer';
import PlayerDeposits from './players/deposits/reducer';
import PlayerCommunication from './players/сommunication/reducer';
import PlayerAudit from './players/audit/reducer';
import PlayerGamePlay from './players/gamePlay/reducer';
import PlayerVerification from './players/verification/reducer';
import PlayerRestrictions from './players/restrictions/reducer';
import PlayerManualAdjustment from './players/manualAdjustment/reducer';
import PlayerPromotions from './players/promotions/reducer';
import PlayerAttributions from './players/attributions/reducer';
import PlayerReferral from './players/referrals/reducer';

// Deposits
import DepositList from './deposits/list/reducer';
import DepositDetailed from './deposits/detailed/reducer';

// Withdrawals
import WithdrawalList from './withdrawals/list/reducer';
import WithdrawalDetailed from './withdrawals/detailed/reducer';

import PendingWithdrawalList from './pending-withdrawals/list/reducer';
import PendingWithdrawalDetailed from './pending-withdrawals/detailed/reducer';

import PendingTransferWithdrawalList from './pending-transfer-withdrawals/list/reducer';
import PendingTransferWithdrawalDetailed from './pending-transfer-withdrawals/detailed/reducer';

// Brand
import Brand from './brand/siteBrands/reducer';
import BrandSettingsPage from './brand/brandPage/reducer';

import Info from './info/reducer';

// Agents
import AgentList from './agents/agents/reducer';
import AgentDetailed from './agents/detailed/reducer';
import AgentSelf from './agents/self/reducer';
import AgentEmployees from './agents/employees/reducer';
import AgentEmployee from './agents/employee/reducer';

// Old code
// Front
import Layout from './layout/reducer';

import Statistics from './statistics/reducers';

//Affiliates
import Affiliates from './affiliates/reducers';

//Notes
import Notes from './note/reducers';

//Filters
import Filter from './filters/reducer';
import FilterPage from './filters/filterPage/reducer';

//Filters
import Notification from './notifications/reducer';
import NotificationPage from './notifications/notificationPage/reducer';

import Search from './search/reducer';

import Overview from './overview/reducer';

//Games
import Games from './games/reducer';

//Cashier
import Cashier from './cashier/reducer';

//Promotions
import Promotions from "./promotions/reducer";

// States and Cities
import StatesAndCities from './statesAndCities/reducer';

const rootReducer = combineReducers({
    Login,
    Documents,
    Affiliates,
    Notes,

    PlayerJoint,
    PlayerSelectList,
    PlayerList,
    PlayerGeneral,
    PlayerWithdrawals,
    PlayerDeposits,
    PlayerCommunication,
    PlayerAudit,
    PlayerGamePlay,
    PlayerVerification,
    PlayerRestrictions,
    PlayerManualAdjustment,
    PlayerPromotions,
    PlayerAttributions,

    DepositList,
    DepositDetailed,

    WithdrawalList,
    WithdrawalDetailed,

    PendingWithdrawalList,
    PendingWithdrawalDetailed,

    PendingTransferWithdrawalList,
    PendingTransferWithdrawalDetailed,

    AgentList,
    AgentSelf,
    AgentDetailed,
    AgentEmployees,
    AgentEmployee,

    Brand,
    BrandSettingsPage,
    Info,
    Filter,
    FilterPage,
    Notification,
    NotificationPage,
    Overview,

    Search,

    Statistics,

    Games,

    Cashier,

    Promotions,

    StatesAndCities,

    PlayerReferral,

    // public
    Layout
});

export default rootReducer;
