import React, {useMemo, useState} from "react"
import PropTypes from "prop-types"

import i18n from "../../../i18n"
import CustomInput from "./CustomInput"
import CustomModal from "../../../common/components/CustomModal"

const RenameGroupModal = (props) => {
	const { category, dialogVisibility, onToggle, onSaveNames } = props;
	const { label } = category;

	const [categoryName, setCategoryName] = useState(label);

	const handleChangeCategoryName = (event) => setCategoryName(event.target.value)

	const handleSaveNames = () => {
		onSaveNames({
			categoryName,
		})
		onToggle()
	}

	const isAnyInputEmpty = () => {
		return !categoryName.length;
	};

	return (
		<CustomModal
			className="rename-group"
			titleText={i18n.t('content.renameCategory')}
			isOpen={dialogVisibility}
			onToggle={onToggle}
			btnDisabled={isAnyInputEmpty()}
			onClick={handleSaveNames}
			btnText={'rename'}
			bodyRender={() => (
				<>
					<CustomInput
						key="category-name"
						className="category-name"
						inputClassName="header__input"
						label={'Name:'}
						inputPlaceholder={i18n.t('content.enterName')}
						value={categoryName}
						onChange={handleChangeCategoryName}
					/>
				</>
			)}
		/>
	)
}

RenameGroupModal.propTypes = {
	dialogVisibility: PropTypes.bool,
	onToggle: PropTypes.func,
	onSaveNames: PropTypes.func,
	category: PropTypes.object,
}

RenameGroupModal.defaultProps = {
	dialogVisibility: false,
	onToggle: () => {},
	onSaveNames: () => {},
	category: {},
}

export default RenameGroupModal
