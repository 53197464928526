import {
    GET_PLAYER_GAME_PLAY,
    GET_PLAYER_GAME_PLAY_SUCCESS,
    GET_PLAYER_GAME_PLAY_FAIL,
    GET_PLAYER_GAME_SESSIONS,
    GET_PLAYER_GAME_SESSIONS_SUCCESS,
    GET_PLAYER_GAME_SESSIONS_FAIL,
    GET_PLAYER_GAME_ROUNDS,
    GET_PLAYER_GAME_ROUNDS_SUCCESS,
    GET_PLAYER_GAME_ROUNDS_FAIL,
    GET_PLAYER_BET_SLIP,
    GET_PLAYER_BET_SLIP_SUCCESS,
    GET_PLAYER_BET_SLIP_FAIL,
    GET_PLAYER_GAME_SESSION_ROUNDS,
    GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS,
    GET_PLAYER_GAME_SESSION_ROUNDS_FAIL,
    DOWNLOAD_GAME_PLAY_LIST,
    DOWNLOAD_GAME_PLAY_LIST_SUCCESS,
    DOWNLOAD_GAME_PLAY_LIST_FAIL,
    GET_UNIQUE_GAME_IDS_SUCCESS,
    GET_UNIQUE_GAME_IDS_FAIL,
    PUT_UNIQUE_GAMES,
    GET_UNIQUE_GAME_IDS,
} from './actionTypes';

export const DEFAULT_QUERY = {
    limit: 20,
    offset: 0
};

const INIT_STATE = {
    gamePlay: {
        rounds: {
            result: [],
            total: 0,
        },
        sessions: {
            result: [],
            total: 0,
        },
        betSlip: {
            result: [],
            total: 0,
        },
    },
    queryList: { ...DEFAULT_QUERY },
    isLoadingGamePlay: false,
    isLoadingUniqueGameIds: false,
    uniqueGameIds: [],
    errorGamePlay: null,
};

const GamePlay = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_GAME_PLAY:
            return {
                ...state,
                isLoadingGamePlay: true,
                errorVerification: null
            };

        case GET_PLAYER_GAME_PLAY_SUCCESS:
            return {
                ...state,
                gamePlay: action.payload,
                isLoadingGamePlay: false
            };

        case GET_PLAYER_GAME_PLAY_FAIL:
            return {
                ...state,
                errorGamePlay: action.payload,
                isLoadingGamePlay: false
            };

        case GET_PLAYER_GAME_SESSIONS:
            return {
                ...state,
                isLoadingGamePlay: true,
                errorVerification: null
            };

        case GET_PLAYER_GAME_SESSIONS_SUCCESS:
            return {
                ...state,
                gamePlay: {
                    ...state.gamePlay,
                    sessions: action.payload,
                },
                isLoadingGamePlay: false
            };

        case GET_PLAYER_GAME_SESSIONS_FAIL:
            return {
                ...state,
                errorGamePlay: action.payload,
                isLoadingGamePlay: false
            };

        case GET_PLAYER_GAME_ROUNDS:
        case GET_PLAYER_GAME_SESSION_ROUNDS:
            return {
                ...state,
                isLoadingGamePlay: true,
                errorVerification: null
            };

        case GET_PLAYER_GAME_ROUNDS_SUCCESS:
        case GET_PLAYER_GAME_SESSION_ROUNDS_SUCCESS:
            return {
                ...state,
                gamePlay: {
                    rounds: action.payload,
                    sessions: {
                        result: [],
                        total: 0,
                    },
                },
                isLoadingGamePlay: false
            };

        case GET_PLAYER_GAME_ROUNDS_FAIL:
        case GET_PLAYER_GAME_SESSION_ROUNDS_FAIL:
            return {
                ...state,
                errorGamePlay: action.payload,
                isLoadingGamePlay: false
            };

        case GET_PLAYER_BET_SLIP:
            return {
                ...state,
                isLoadingGamePlay: true,
                errorVerification: null
            };

        case GET_PLAYER_BET_SLIP_SUCCESS:
            return {
                ...state,
                gamePlay: {
                    ...state.gamePlay,
                    betSlip: action.payload,
                },
                isLoadingGamePlay: false
            };

        case GET_PLAYER_BET_SLIP_FAIL:
            return {
                ...state,
                errorGamePlay: action.payload,
                isLoadingGamePlay: false
            };

        case DOWNLOAD_GAME_PLAY_LIST:
            return {
                ...state,
                isDownloading: true,
                error: null
            };

        case DOWNLOAD_GAME_PLAY_LIST_SUCCESS:
            return {
                ...state,
                isDownloading: false,
                error: null
            };

        case DOWNLOAD_GAME_PLAY_LIST_FAIL:
            return {
                ...state,
                isDownloading: false,
                error: action.payload,
            };

        case GET_UNIQUE_GAME_IDS:
            return {
                ...state,
                isLoadingUniqueGameIds: true,
                error: null
            };

        case GET_UNIQUE_GAME_IDS_SUCCESS:
            return {
                ...state,
                uniqueGameIds: action.payload,
                isLoadingUniqueGameIds: false,
                error: null
            };

        case GET_UNIQUE_GAME_IDS_FAIL:
            return {
                ...state,
                isLoadingUniqueGameIds: false,
                error: action.payload,
            };

        case PUT_UNIQUE_GAMES:
            return {
                ...state,
                uniqueGames: action.payload,
            };

        default:
            return state;
    }
};

export default GamePlay;
