import React, { useEffect, useMemo, useState } from 'react';
import clsx from "clsx";
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DATE_FORMAT, getGuidShortFormat, getColumnSettingsLsKey, initTableColumns } from '../../../common/utils/common';
import CopyButton from '../../../common/cellData/CopyButton';
import NewCustomTable from '../../../components/NewCustomTable';
import useTableCheckbox from '../../../components/NewCustomTable/useTableCheckbox';
import { useTranslation } from "react-i18next";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import { useTimeContext } from "../../../context/TimeContext";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";

const PAGE_PATH = '/affiliates';

const COLUMN_IDS = {
    affiliateID: 'affiliateID',
    createdTime: 'createdTime',
    lastChange: 'lastChange',
    name: 'name',
    usedMargin: 'usedMargin',
    leverage: 'leverage',
};

const getDefaultColumns = () => {
    return [
        COLUMN_IDS.affiliateID,
        COLUMN_IDS.createdTime,
        COLUMN_IDS.lastChange,
        COLUMN_IDS.name,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.affiliateID]: 'affiliates.affiliateID',
    [COLUMN_IDS.createdTime]: 'affiliates.createdTime',
    [COLUMN_IDS.lastChange]: 'affiliates.lastChange',
    [COLUMN_IDS.name]: 'affiliates.name',
    [COLUMN_IDS.usedMargin]: 'affiliates.usedMargin',
    [COLUMN_IDS.leverage]: 'affiliates.leverage',
};

const TdElem = ({
    item,
    field,
    timeFormat,
    i18n,
}) => {
    const size = useWindowSize();

    switch (field) {
        case COLUMN_IDS.affiliateID:
            return <td>
                <div>
                    <Link
                        to={`/affiliates/${item.id}`}
                        className="me-2 app-text-primary"
                    >
                        {getGuidShortFormat(item.id)}
                    </Link>
                    <CopyButton text={item.id} />
                </div>
            </td>;
        case COLUMN_IDS.createdTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('affiliates.createdTime')}</span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>;
        case COLUMN_IDS.lastChange:
            return <td>
                 {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('affiliates.lastChange')}</span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.lastChangedAt))}
            </td>;
        case COLUMN_IDS.name:
            return (
                size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <Link
                        to={`/affiliates/${item.id}`}
                        className="me-2 app-text-primary"
                    >
                        <td className="title__top">{item.name || "-"}</td>
                    </Link>
                ) : (
                    <td className="title__top">{item.name || "-"}</td>
                )
            )
        default:
            return null;
    }
};


export const AffiliatesTable = ({
    list,
    isLoading,
    selectedRows,
    setSelectedRows,
    isShowTableSettings,
    onCloseTableSettings,
}) => {
    const { i18n } = useTranslation();
    const { timeFormat } = useTimeContext();
    const { handleSelectAll, handleSelectRow } = useTableCheckbox(
        list ? list.map(item => item.id) : [],
        selectedRows,
        setSelectedRows
    );

    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [activeRow, setActiveRow] = useState(false);
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());

    useEffect(() => {
        initTableColumns(PAGE_PATH, getDefaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const columnsData = [
            // Will use in the future
            // {
            //     custom: (
            //         <th>
            //             <CheckboxInput
            //                 wrapperClassName="check-box-aml-unset"
            //                 checked={list?.length === selectedRows?.length}
            //                 onChange={handleSelectAll}
            //             />
            //         </th>
            //     )
            // },
            ...activeColumns.map((key) => ({
                label: i18n.t(KEY_I18N_MAP[COLUMN_IDS[key]]),
                id: COLUMN_IDS[key],
            })),
            // Will use in the future
            // { label: i18n.t('affiliates.usedMargin') },
            // { label: i18n.t('affiliates.leverage') },
        ];
        setColumns(columnsData);
    }, [list, i18n.language, activeColumns]);

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(PAGE_PATH);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <NewCustomTable
                columns={columns}
                isLoading={isLoading}
                className='responsiveTable'
            >
                {!!list &&
                    list.map((item, index) => {
                        const isChecked = selectedRows.includes(item.id);

                        return (
                            <tr
                                key={index}
                                className={clsx({
                                    ['checked'] : isChecked,
                                    ['open']: activeRow === index})}
                                    onClick={() => handleToggle(index)}
                                >
                                {activeColumns.map((column) => <TdElem
                                    key={`${column}_${index}`}
                                    item={item}
                                    field={column}
                                    timeFormat={timeFormat}
                                    i18n={i18n}
                                />)}
                            </tr>
                        );
                    })}
            </NewCustomTable>
            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    );
};

AffiliatesTable.proptypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            createdTime: PropTypes.string,
            lastChange: PropTypes.string,
            name: PropTypes.string
        })
    ).isRequired,
    isLoading: PropTypes.bool.isRequired,
    isShowTableSettings: PropTypes.bool,
    onCloseTableSettings: PropTypes.func,
};

AffiliatesTable.defaultProps = {
    onCloseTableSettings: () => {},
};
