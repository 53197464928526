export const GET_NOTE_LIST = 'GET_NOTE_LIST';
export const GET_NOTE_LIST_SUCCESS = 'GET_NOTE_LIST_SUCCESS';
export const GET_NOTE_LIST_FAIL = 'GET_NOTE_LIST_FAIL';

export const ADD_NOTE = 'ADD_NOTE';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';

export const GET_NOTE = 'GET_NOTE';
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS';

export const CHANGE_NOTE = 'CHANGE_NOTE';
export const CHANGE_NOTE_SUCCESS = 'CHANGE_NOTE_SUCCESS';

export const REMOVE_NOTE = 'REMOVE_NOTE';
export const REMOVE_NOTE_SUCCESS = 'REMOVE_NOTE_SUCCESS';
export const REMOVE_NOTE_FAIL = 'REMOVE_NOTE_FAIL';

export const SET_EDIT = 'SET_EDIT';

export const NOTE_ERROR = 'NOTE_ERROR';
export const CANCEL_ERRORS = 'CANCEL_ERRORS';

export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';

export const SEARCH_AT_NOTE_LIST = 'SEARCH_AT_NOTE_LIST';
