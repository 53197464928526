import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import filterIcon from '../../assets/images/common/filterIcon.svg';
import arrows from '../../assets/images/common/arrows.svg';
import sortIcon from '../../assets/images/common/filterOrSortIcon.svg';
import searchIcon from '../../assets/images/common/magnifiying-glass 1.svg';

import {getFiltersList, selectFilter} from '../../store/filters/action';

import Loader from '../../common/components/Loader';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import useClickOutside from '../../common/hooks/useClickOutside';
import useWindowSize from "../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../utils/consts";
import SimpleBar from 'simplebar-react';
import {filterCategoryName} from "../../utils/filterСategory";
import {useTranslation} from "react-i18next";
import {showLeftMenu} from "../../store/layout/actions";


export const CategoryTableEnum = [
    {key: 'player', redirect: '/player-list'},
    // {key: 'agent', redirect: ''},
    // {key: 'deposit', redirect: '/deposits'},
    {key: 'withdrawal', redirect: '/withdrawals'},
    {key: 'pendingWithdrawals', redirect: '/withdrawals-pending-approve'},
    {key: 'pendingWireWithdrawals', redirect: '/withdrawals-pending-transfer'},
    {key: 'payment', redirect: '/deposits'},
];

const PATHS_WITH_FLAG = [
    '/player-list',
];

const SidebarFilter = ({ isLeftMenuShown }) => {
    const { i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const size = useWindowSize();

    const { isLoading, list, query, sidebarValue, currentFilter } = useSelector(
        state => state.Filter
    );

    const {
        active: activeBrand,
    } = useSelector(state => state.Brand)

    const [isOpen, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [showingList, setShowingList] = useState([]);
    const [filteredItems, setFilteredItems] = useState(null);
    const [showByScreenWidth, setShowByScreenWidth] = useState(size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT);

    const filterRef = useRef();

    const handleClose = useCallback(() => {
        setSearch('');
        setOpen(false);
    }, []);

    const handleOpen = () => {
        setOpen(prev => !prev);
        if (!isLeftMenuShown && showByScreenWidth) {
            dispatch(showLeftMenu(true));
        }
    };

    const handleSearch = useCallback(
        event => {
            setSearch(event.target.value);
        },
        [setSearch]
    );

    useEffect(() => {
        setShowByScreenWidth(size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT);
    },[size])

    useEffect(() => {
        setFilteredItems(() => showingList.filter(item =>
            item?.name.toLowerCase().includes(search.toLowerCase())));
    }, [search])

    useEffect(() => {
            setFilteredItems(() =>
                showingList.sort((a, b) => a.category?.length - b.category?.length))
    }, [showingList])

    const handleChange = useCallback(
        item => () => {
            const find = CategoryTableEnum.find((v) => v.key === item.category);

            let filter = {
                ...item.filterDynamic,
            };
            if (find) {
                filter = {
                    ...filter,
                    nameFilter: find.key,
                    redirectTo: find.redirect
                }
            }
            item?.filterDynamic.forEach(filter => filter['nameFilter'] = item.category);
            localStorage.setItem('LocalStorageFilter', JSON.stringify(item.filterDynamic));
            localStorage.setItem('LocalStorageFilterCategory', item.category);

            find && history.push(
                `${find.redirect}${PATHS_WITH_FLAG.includes(find.redirect) ? `?hasFilter=true` : ''}`
            );

            dispatch(selectFilter({ currentFilter: item.filterDynamic }));

            setOpen(false);
        },
        []
    );

    useClickOutside(filterRef, handleClose);
    useHandleEsc(handleClose);

    useEffect(() => {
        dispatch(getFiltersList({ ...query, siteId: activeBrand?.id }));
    }, [activeBrand]);

    // useEffect(() => {
    //     const currentCategory = filterCategory(location.pathname);
    //     setShowingList(() => currentCategory
    //         ? list.filter(item => item?.category === currentCategory)
    //         : list
    //     )
    // }, [location.pathname, list]);

    useEffect(() => {
        setShowingList(list);
    }, [location.pathname, list]);

    const activeCategoriesFilter = [];
    let isNewCategory = false;

    const sidebarTitle = useMemo(() => {
        return sidebarValue.title === "Filter"
                ? i18n.t(`filter.${sidebarValue.title.toLowerCase()}`)
                : sidebarValue.title
    }, [i18n.language])

    return (
        <>
            {!isLeftMenuShown && showByScreenWidth &&
                <div className='closed-filter-icon' onClick={handleOpen}>
                    <img
                        className={'sidebar-filter__filter'}
                        src={filterIcon}
                        alt={i18n.t('filter.filter')}
                    />
                </div>
            }
            {(isLeftMenuShown || !showByScreenWidth) && (
                <div className={'sidebar-filter__wrapper'} ref={filterRef}>
                    {isLoading ? (
                        <div className={'sidebar-filter__value-loader'}>
                            <Loader size={'sm'}/>
                        </div>
                    ) : (
                        <div className={'sidebar-filter'} onClick={handleOpen}>
                            <img
                                className={'sidebar-filter__filter'}
                                src={filterIcon}
                                alt={i18n.t('filter.filter')}
                            />

                            <span className={'sidebar-filter__value'}>
                                {sidebarTitle}
                        </span>

                            <img
                                className={'sidebar-filter__arrows'}
                                src={arrows}
                                alt={i18n.t('filter.filter')}
                            />
                        </div>
                    )}

                    {!isLoading && isOpen && (
                        <div className={'sidebar-filter__list'}>
                            <div className={'sidebar-filter__list-search'}>
                                <img
                                    src={searchIcon}
                                    alt={i18n.t('filter.search')}
                                />

                                <input
                                    type={'text'}
                                    value={search}
                                    onChange={handleSearch}
                                    placeholder={i18n.t('filter.search')}
                                />
                            </div>


                            <SimpleBar
                                style={{
                                    maxHeight: 200,
                                    width: '100%',
                                    paddingBottom: 12
                                }}
                            >
                                <ul className={'sidebar-filter__list-recent'}>
                                    {!isLoading &&
                                        !!showingList?.length &&
                                    filteredItems.map((item, index) => {
                                        isNewCategory = !activeCategoriesFilter.includes(item.category);
                                        isNewCategory && activeCategoriesFilter.push(item.category);
                                        return (
                                            <React.Fragment key={item.createdAt}>
                                                {
                                                    isNewCategory && <h5>{filterCategoryName(item.category)}</h5>
                                                }
                                                <li
                                                    key={index}
                                                    className={`sidebar-filter__list-recent-item${
                                                        sidebarValue.id === item.oid
                                                            ? ' selected'
                                                            : ''
                                                    }`}
                                                    onClick={handleChange(item)}
                                                >
                                                    <img
                                                        src={sortIcon}
                                                        alt={i18n.t('filter.sort')}
                                                    />

                                                    <span title={item.name}>
                                                    {item.name}
                                                </span>
                                                </li>
                                            </React.Fragment>
                                        )
                                    })}
                                    {!isLoading && !showingList?.length && (
                                        <div className={'mt-4'}>
                                            {i18n.t('filter.emptyFilters')}
                                        </div>
                                    )}
                                </ul>
                                {!isLoading && !filteredItems?.length &&
                                    <span className='no-results-filter'>
                                        {i18n.t('crm.noResults')}
                                    </span>
                                }
                            </SimpleBar>

                            <Link
                                to={'/filter'}
                                className={'sidebar-filter__list-link'}
                            >
                                {i18n.t('filter.viewAllFilters')}
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

SidebarFilter.propTypes = {
    isLeftMenuShown: PropTypes.bool
};

export default React.memo(SidebarFilter);
