import PlayerData from "../../../common/cellData/PlayerData";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { SITE_PERMISSION } from "../../../common/constants/common";
import useAlertService from "../../../hooks/useAlertService";
import { useTranslation } from "react-i18next";

export const urlTypes = {
    tracker: 'tracker',
    list: 'player-list',
    kyc: 'pending-kyc-verification',
}

export const getUserUrl = (type, id) => {
    const _type = urlTypes[type] || urlTypes.list;
    return `/${_type}/${id}`
}

const PlayerCell = (props) => {
    const {
        hideFlag,
        item,
        url: {
            type = 'list',
            id
        },
    } = props;

    const alertService = useAlertService();
    const { t } = useTranslation();

    const { agentSelf } = useSelector(state => state.AgentSelf);
    const agentPermissions = agentSelf?.permissions;
    const hasPlayerViewAccess = agentPermissions?.includes(SITE_PERMISSION.Player__View_Player_Info);

    const showRestrictedMsg = () => {
        alertService.showError(t('crm.accessRestricted'));
    }

    return (
        <>
            {hasPlayerViewAccess
                ? (
                    <Link to={getUserUrl(type, id)}>
                        <PlayerData player={item} hideFlag={hideFlag} />
                    </Link>
                )
                : (
                    <div style={{cursor: 'pointer'}} onClick={showRestrictedMsg}>
                        <PlayerData player={item} hideFlag={hideFlag} />
                    </div>
                )
            }
        </>
    )
}

export default PlayerCell;
