import { GET_INFO, GET_INFO_ERROR, GET_INFO_SUCCESS } from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    counters: {},
    siteTimeZone: null,
    error: {}
};

const Info = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_INFO:
            return { ...state, isLoading: true };
        case GET_INFO_SUCCESS:
            const {
                data: { siteTimeZone, ...counters }
            } = action.payload;
            localStorage.setItem('siteTimeZone', siteTimeZone);
            localStorage.setItem('counters', JSON.stringify(counters));

            return {
                ...state,
                isLoading: false,
                counters,
                siteTimeZone
            };

        case GET_INFO_ERROR:
            return {
                ...state,
                error: action.payload
            };

        default:
            return { ...state };
    }
};

export default Info;
