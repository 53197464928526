import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_AGENTS_LIST,
    GET_ROLES_LIST,
    CREATE_AGENT,
    CHANGE_AGENT_PASSWORD,
} from './actionTypes';

import {
    getAgentsListSuccess,
    getAgentsListFail,
    createAgentSuccess,
    createAgentFail,
    changeAgentPasswordSuccess,
    changeAgentPasswordFail, getRolesListSuccess, getRolesListFail,
} from './actions';

import {
    getAgentsListApi,
    changeAgentPasswordApi,
    createAgentApi, getRolesListApi,
} from '../../../helpers/agentApi';

function* fetchAgentsListSaga({ payload }) {
    try {
        const response = yield call(getAgentsListApi, payload);

        yield put(getAgentsListSuccess(response.data));
    } catch (error) {
        yield put(getAgentsListFail(error));
    }
}

function* fetchRolesListSaga({ payload }) {
    try {
        const response = yield call(getRolesListApi, payload);

        yield put(getRolesListSuccess(response.data));
    } catch (error) {
        yield put(getRolesListFail(error));
    }
}

function* createAgentSaga({ payload }) {
    try {
        const response = yield call(createAgentApi, payload);

        yield put(createAgentSuccess());
    } catch (error) {
        yield put(createAgentFail(error));
    }
}

function* changeAgentPasswordSaga({ payload }) {
    try {
        const response = yield call(changeAgentPasswordApi, payload);

        yield put(changeAgentPasswordSuccess());
    } catch (error) {
        yield put(changeAgentPasswordFail(error));
    }
}

function* agentsListSaga() {
    yield takeEvery(GET_AGENTS_LIST, fetchAgentsListSaga);
    yield takeEvery(GET_ROLES_LIST, fetchRolesListSaga);
    yield takeEvery(CREATE_AGENT, createAgentSaga);
    yield takeEvery(CHANGE_AGENT_PASSWORD, changeAgentPasswordSaga);
}

export default agentsListSaga;
