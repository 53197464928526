import { get } from './api_helper';

const getDashboardVerifiedApi = () => {
    const url = `/dashboard/verified`;

    return get(url);
};

const getDashboardRegistrationApi = () => {
    const url = `/dashboard/registration`;

    return get(url);
};

const getDashboardTransactionsApi = () => {
    const url = `/dashboard/deposits`;

    return get(url);
};

const getDashboardGGRApi = () => {
    const url = `/dashboard/ggr`;

    return get(url);
};

export {
    getDashboardVerifiedApi,
    getDashboardRegistrationApi,
    getDashboardTransactionsApi,
    getDashboardGGRApi
};
