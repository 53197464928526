import {takeEvery, put, call} from "redux-saga/effects"

import {
  GET_PROMOTIONS,
  GET_PROMOTION,
  GET_BONUS_WAGERING_SMALL_DATA,
  UPDATE_PROMOTION,
  CREATE_PROMOTION,
  GET_PROMOTION_TEMPLATES,
  GET_SPORT_PROMOTIONS,
  GET_SPORT_PROMOTION,
  CREATE_SPORT_PROMOTION,
  UPDATE_SPORT_PROMOTION,
} from "./actionTypes"
import {
  getPromotionsSuccess,
  getPromotionsFail,
  getPromotionTemplatesSuccess,
  getPromotionTemplatesFail,
  getPromotionSuccess,
  getPromotionFail,
  getBonusWageringSmallDataSuccess,
  getBonusWageringSmallDataFail,
  updatePromotionSuccess,
  updatePromotionFail,
  createPromotionSuccess,
  createPromotionFail,
  getSportPromotionsSuccess,
  getSportPromotionsFail,
  getSportPromotionSuccess,
  getSportPromotionFail,
  createSportPromotionSuccess,
  createSportPromotionFail,
  updateSportPromotionSuccess,
  updateSportPromotionFail,
} from "./actions"
import {
  getPromotionsApi,
  getPromotionTemplatesApi,
  getPromotionApi,
  getBonusWageringSmallDataApi,
  updatePromotionApi,
  createPromotionApi,
  getSportPromotionsApi,
  getSportPromotionApi,
  updateSportPromotionApi,
  createSportPromotionApi,
} from '../../helpers/promotionsApi';
import {
  getPromotionTemplatesListApi,
} from '../../helpers/playerApi';


function* fetchPromotions({payload}) {
  try {
    const response = yield call(getPromotionsApi, payload);
    yield put(getPromotionsSuccess(response.data))
  } catch (error) {
    yield put(getPromotionsFail(error))
  }
}

function* fetchPromotionTemplates({payload}) {
  try {
    const response = yield call(getPromotionTemplatesListApi, payload);
    yield put(getPromotionTemplatesSuccess(response.data))
  } catch (error) {
    yield put(getPromotionTemplatesFail(error))
  }
}

function* fetchBonusWageringSmallData() {
  try {
    const response = yield call(getBonusWageringSmallDataApi);
    yield put(getBonusWageringSmallDataSuccess(response.data))
  } catch (error) {
    yield put(getBonusWageringSmallDataFail(error))
  }
}

function* fetchPromotion({payload: id}) {
  try {
    const {data} = yield call(getPromotionApi, id);
    yield put(getPromotionSuccess(data))
  } catch (error) {
    yield put(getPromotionFail(error))
  }
}

function* updatePromotion({payload}) {
  try {
    const {data} = yield call(updatePromotionApi, payload);
    yield put(updatePromotionSuccess(data))
  } catch (error) {
    yield put(updatePromotionFail(error))
  }
}

function* createPromotion({payload}) {
  try {
    const {data} = yield call(createPromotionApi, payload);
    yield put(createPromotionSuccess(data))
  } catch (error) {
    yield put(createPromotionFail(error))
  }
}

function* fetchSportPromotions({payload}) {
  try {
    const response = yield call(getSportPromotionsApi, payload);
    yield put(getSportPromotionsSuccess(response.data))
  } catch (error) {
    yield put(getSportPromotionsFail(error))
  }
}

function* fetchSportPromotion({payload: id}) {
  try {
    const {data} = yield call(getSportPromotionApi, id);
    yield put(getSportPromotionSuccess(data))
  } catch (error) {
    yield put(getSportPromotionFail(error))
  }
}

function* updateSportPromotion({payload}) {
  try {
    const {data} = yield call(updateSportPromotionApi, payload);
    yield put(updateSportPromotionSuccess(data))
  } catch (error) {
    yield put(updateSportPromotionFail(error))
  }
}

function* createSportPromotion({payload}) {
  try {
    const {data} = yield call(createSportPromotionApi, payload);
    yield put(createSportPromotionSuccess(data))
  } catch (error) {
    yield put(createSportPromotionFail(error))
  }
}

function* promotionSaga() {
  yield takeEvery(GET_PROMOTIONS, fetchPromotions);
  yield takeEvery(GET_PROMOTION_TEMPLATES, fetchPromotionTemplates);
  yield takeEvery(GET_BONUS_WAGERING_SMALL_DATA, fetchBonusWageringSmallData);
  yield takeEvery(GET_PROMOTION, fetchPromotion);
  yield takeEvery(UPDATE_PROMOTION, updatePromotion);
  yield takeEvery(CREATE_PROMOTION, createPromotion);
  yield takeEvery(GET_SPORT_PROMOTIONS, fetchSportPromotions);
  yield takeEvery(GET_SPORT_PROMOTION, fetchSportPromotion);
  yield takeEvery(UPDATE_SPORT_PROMOTION, updateSportPromotion);
  yield takeEvery(CREATE_SPORT_PROMOTION, createSportPromotion);
}

export default promotionSaga
