import React from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import Loader from '../../common/components/Loader';

export const CommonCard = ({ isLoading, className, children }) => (
    <Card className={className}>
        {isLoading && <Loader size={'lg'}/>}
        {!isLoading && <CardBody>{children}</CardBody>}
    </Card>
);

CommonCard.proptypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string
};
