import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PENDING_DOCUMENTS,
    GET_FIRST_PENDING_DOCUMENTS,
    APPROVE_DOCUMENT,
    REJECT_DOCUMENT,
    UPLOAD_NEW_DOCUMENT,
    UPLOAD_RETAIL_DOCUMENT,
    GET_PLAYER_DOCUMENTS,
    GET_DOCUMENT,
    GET_DOCUMENT_DATA,
    UPDATE_DOCUMENT,
    ADD_DOCUMENT_COMMENTS,
    REMOVE_DOCUMENT_COMMENTS,
    REJECT_DOCUMENT_SUCCESS,
    GET_DOCUMENT_HISTORY,
    DOWNLOAD_DOCUMENT,
    SET_OCCUPATION,
    GET_BRAND_JURISDICTION,
} from './actionTypes';

import {
    getPlayerDocumentsSuccess,
    getPlayerDocumentsFail,
    getPendingSuccess,
    getPendingFail,
    approveDocumentSuccess,
    approveDocumentFail,
    rejectDocumentSuccess,
    rejectDocumentFail,
    uploadDocumentSuccess,
    uploadDocumentFail,
    uploadRetailDocumentSuccess,
    uploadRetailDocumentFail,
    getDocumentSuccess,
    getDocumentFail,
    getDocumentDataSuccess,
    getDocumentDataFail,
    updateDocumentSuccess,
    updateDocumentFail,
    addDocumentCommentSuccess,
    addDocumentCommentFail,
    removeDocumentCommentSuccess,
    removeDocumentCommentFail,
    getDocumentHistorySuccess,
    getDocumentHistoryFail,
    downloadDocumentSuccess,
    downloadDocumentFail,
    setOccupationSuccess,
    setOccupationFail,
    getBrandJurisdictionFail,
    getBrandJurisdictionSuccess,
} from "./actions"

import {
    getPending,
    getFirstPending,
    postApproveDocument,
    postRejectDocument,
    getPlayerDocuments,
    uploadDocument,
    uploadRetailDocumentApi,
    getDocument,
    getDocumentData,
    updateDocumentFields,
    addDocumentComments,
    removeDocumentComments,
    getHistoryDocumentsApi,
    downloadDocumentApi,
    getBrandJurisdictionApi,
} from '../../helpers/documentApi';

import { getInfoSaga } from '../info/saga';
import { getInfo } from '../info/actions';
import i18n from "i18next";
import { getPlayerOccupationApi } from "../../helpers/playerApi";

function* fetchPending() {
    try {
        const response = yield call(getPending);
        yield put(getPendingSuccess(response));
    } catch (error) {
        yield put(getPendingFail(error));
    }
}

function* fetchFirstPending({payload}) {
    try {
        const response = yield call(getFirstPending, payload);
        yield put(getPendingSuccess(response));
    } catch (error) {
        yield put(getPendingFail(error));
    }
}

function* approveDocument({ payload: { documentId: id, address } }) {
    try {
        const response = yield call(postApproveDocument, id, address);
        yield put(approveDocumentSuccess(response));
        yield put(getInfo());
    } catch (error) {
        yield put(approveDocumentFail(error));
    }
}

function* rejectDocument({ payload: { documentId, data } }) {
    try {
        const response = yield call(postRejectDocument, documentId, data);

        if (response.success) {
            yield put(rejectDocumentSuccess(documentId));
        } else {
            yield put(rejectDocumentFail({ message: 'Cannot get response' }));
        }
    } catch (error) {
        yield put(rejectDocumentFail(error));
    }
}

function* fetchPlayerDocuments({ payload: playerId }) {
    try {
        const response = yield call(getPlayerDocuments, playerId);
        yield put(getPlayerDocumentsSuccess(response?.data?.result));
    } catch (error) {
        yield put(getPlayerDocumentsFail(error));
    }
}

function* uploadNewDocument({ payload: { oid, documentData, file, alertService } }) {
    try {
        const response = yield call(uploadDocument, {
            oid,
            documentData,
            file
        });

        // TODO discussed about response from server

        if (response.statusCode < 400) {
            alertService.showSuccess(i18n.t("crm.uploadDocument"));
            yield put(
                uploadDocumentSuccess({
                    ...documentData,
                    id: response
                })
            );
            yield put(getInfo());
        } else {
            yield put(uploadDocumentFail(response?.data?.errors));
        }
    } catch (error) {
        yield put(uploadDocumentFail(error));
    }
}

function* uploadRetailDocument({ payload: { oid, documentData, file, alertService } }) {
    try {
        const response = yield call(uploadRetailDocumentApi, {
            oid,
            documentData,
            file
        });

        // TODO discussed about response from server

        if (response.statusCode < 400) {
            alertService.showSuccess(i18n.t("crm.uploadDocument"));
            yield put(uploadRetailDocumentSuccess());
        } else {
            yield put(uploadRetailDocumentFail(response?.data?.errors));
        }
    } catch (error) {
        yield put(uploadRetailDocumentFail(error));
    }
}

function* fetchDocument({ payload: documentId }) {
    try {
        const response = yield call(getDocument, documentId);
        yield put(getDocumentSuccess(response));
    } catch (error) {
        yield put(getDocumentFail(error));
    }
}

function* fetchDocumentData({ payload: documentId }) {
    try {
        const response = yield call(getDocumentData, documentId);
        yield put(getDocumentDataSuccess(response.data));
    } catch (error) {
        yield put(getDocumentDataFail(error));
    }
}

function* updateDocument({ payload: document }) {
    try {
        const response = yield call(updateDocumentFields, document);
        yield put(updateDocumentSuccess(response));
    } catch (error) {
        yield put(updateDocumentFail(error));
    }
}

function* addComment({ payload: { document, comment, writer, agentId } }) {
    try {
        const response = yield call(addDocumentComments, { document, comment, writer, agentId });
        yield put(addDocumentCommentSuccess(response));
    } catch (error) {
        yield put(addDocumentCommentFail(error));
    }
}

function* removeComment({ payload: { document, commentData, writer, agentId } }) {
    try {
        const response = yield call(removeDocumentComments, {
            document,
            commentData,
            writer,
            agentId,
        });
        yield put(removeDocumentCommentSuccess(response));
    } catch (error) {
        yield put(removeDocumentCommentFail(error));
    }
}

function* getDocumentHistorySaga({ payload }) {
    try {
        const response = yield call(getHistoryDocumentsApi, payload);

        yield put(
            getDocumentHistorySuccess({
                documentOid: payload,
                documentHistory: response.data
            })
        );
    } catch (error) {
        yield put(getDocumentHistoryFail(error));
    }
}

function* downloadDocumentSaga({ payload }) {
    try {
        const response = yield call(downloadDocumentApi, payload);

        yield put(downloadDocumentSuccess(response.data));
    } catch (error) {
        yield put(downloadDocumentFail(error));
    }
}

function* setOccupationSaga({ payload }) {
    try {
        const response = yield call(getPlayerOccupationApi, payload);
        yield put(setOccupationSuccess(response.data));
    } catch (error) {
        yield put(setOccupationFail(error));
    }
}

function* getBrandJurisdictionSaga() {
    try {
        const response = yield call(getBrandJurisdictionApi);
        yield put(getBrandJurisdictionSuccess(response));
    } catch (error) {
        yield put(getBrandJurisdictionFail(error));
    }
}

function* documentSaga() {
    yield takeEvery(GET_PENDING_DOCUMENTS, fetchPending);
    yield takeEvery(GET_FIRST_PENDING_DOCUMENTS, fetchFirstPending);
    yield takeEvery(APPROVE_DOCUMENT, approveDocument);
    yield takeEvery(REJECT_DOCUMENT, rejectDocument);
    yield takeEvery(REJECT_DOCUMENT_SUCCESS, getInfoSaga);
    yield takeEvery(UPLOAD_NEW_DOCUMENT, uploadNewDocument);
    yield takeEvery(UPLOAD_RETAIL_DOCUMENT, uploadRetailDocument);
    yield takeEvery(GET_PLAYER_DOCUMENTS, fetchPlayerDocuments);
    yield takeEvery(GET_DOCUMENT, fetchDocument);
    yield takeEvery(GET_DOCUMENT_DATA, fetchDocumentData);
    yield takeEvery(UPDATE_DOCUMENT, updateDocument);
    yield takeEvery(ADD_DOCUMENT_COMMENTS, addComment);
    yield takeEvery(REMOVE_DOCUMENT_COMMENTS, removeComment);
    yield takeEvery(GET_DOCUMENT_HISTORY, getDocumentHistorySaga);
    yield takeEvery(DOWNLOAD_DOCUMENT, downloadDocumentSaga);
    yield takeEvery(SET_OCCUPATION, setOccupationSaga);
    yield takeEvery(GET_BRAND_JURISDICTION, getBrandJurisdictionSaga);
}

export default documentSaga;
