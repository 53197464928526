import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_WITHDRAWAL } from './actionTypes';
import { getWithdrawalSuccess, getWithdrawalFail } from './actions';
import { getPaymentWithdrawalApi } from '../../../helpers/paymentApi';

function* fetchWithdrawalSaga({ payload }) {
    try {
        const response = yield call(getPaymentWithdrawalApi, payload);

        yield put(getWithdrawalSuccess(response.data));
    } catch (error) {
        yield put(getWithdrawalFail(error));
    }
}

function* withdrawalSaga() {
    yield takeEvery(GET_WITHDRAWAL, fetchWithdrawalSaga);
}

export default withdrawalSaga;
