import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CardBody, Col, Container, Row } from 'reactstrap';
import i_51 from '../../../assets/images/common/Frame 176 (1).svg';
import i18n from '../../../i18n';
import close from '../../../assets/images/common/Frame 179.svg';
import close_2 from '../../../assets/images/common/Group 10873_1.svg';
import group_11002_1 from '../../../assets/images/common/Group 11002_1.svg';
import group_10825_1 from '../../../assets/images/common/Frame 10825_1.svg';
import CopyButton from '../../../common/cellData/CopyButton';
import useAlertService from '../../../hooks/useAlertService';
import ACommonDataField from '../../../components/GenericComponents/CommonDataField';
import { A_COMMON_DATA_FIELD } from '../../../constants/aCommonDataField';
import AMultiSelectField from '../../../components/GenericComponents/MultiSelectField';

function ButtonsAppPage() {
    // for test
    const [state, setState] = useState({
        text: '123',
        date: new Date(),
        currency: 'USD',
        country: 'BY',
        multiCurrency: ['AOA'],
        multiCountries: [],
        isDisabled: false,
        isError: false
    });
    const alertService = useAlertService();

    const handleClickError = () => alertService.showError('Test');
    const handleClickSuccess = () => alertService.showSuccess('Test');
    const handleClickNotification = () => alertService.showNotification('Test');

    const changeValue = name => value =>
        setState(prev => ({ ...prev, [name]: value }));

    const onToggleAll = () =>
        setState(prev => ({ ...prev, isDisabled: !prev.isDisabled }));
    const onErrorAll = () =>
        setState(prev => ({ ...prev, isError: !prev.isError ? 'error' : '' }));

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <CardBody
                            style={{
                                background: 'white',
                                padding: '15px',
                                minHeight: '50px'
                            }}
                        >
                            <button onClick={handleClickError}>
                                alert Error
                            </button>
                            <button onClick={handleClickSuccess}>
                                alert Success
                            </button>
                            <button onClick={handleClickNotification}>
                                alert Notification
                            </button>

                            <button className="btn btn-rounded btn-primary me-2">
                                <img src={i_51} alt="" />
                                {i18n.t('common.save')}
                            </button>
                            <button
                                className="btn btn-rounded btn-primary me-2"
                                disabled={true}
                            >
                                <img src={i_51} alt="" />
                                {i18n.t('common.save')}
                            </button>

                            <button className="btn btn-rounded btn-light me-2">
                                <img src={close} alt="" />
                                {i18n.t('common.cancel')}
                            </button>

                            <button
                                className="btn btn-rounded btn-light me-2"
                                disabled={true}
                            >
                                <img src={close} alt="" />
                                {i18n.t('common.cancel')}
                            </button>

                            <button className="btn btn-rounded btn-outline-primary me-2">
                                {i18n.t('common.cancel')}
                            </button>

                            <CopyButton text={'dfgdf'} />

                            <button className="btn btn-rounded btn-primary app-btn-only-img me-2">
                                <img src={close_2} alt="" />
                            </button>

                            <button className="btn btn-rounded btn-primary app-btn-only-img-xs me-2">
                                <img src={group_11002_1} alt="" />
                            </button>

                            <button className="btn btn-rounded btn-light app-btn-only-img-xs me-2">
                                <img src={group_10825_1} alt="" />
                            </button>
                        </CardBody>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <CardBody
                            style={{
                                background: 'white',
                                padding: '15px',
                                minHeight: '100vh'
                            }}
                        >
                            <button
                                className="btn btn-rounded btn-primary me-2"
                                onClick={onToggleAll}
                            >
                                {state.isDisabled ? 'Enabled' : 'Disabled'}{' '}
                                fields
                            </button>

                            <button
                                className="btn btn-rounded btn-primary me-2"
                                onClick={onErrorAll}
                            >
                                Error
                            </button>

                            <div className="sub-content__content d-flex flex-wrap mt-2">
                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.TEXT}
                                    label={'Label'}
                                    value={state.text}
                                    handleChange={changeValue('text')}
                                    disabled={state.isDisabled}
                                    error={state.isError}
                                    helperText={state.isError}
                                />

                                <ACommonDataField
                                    component={A_COMMON_DATA_FIELD.DATE}
                                    label={'Label'}
                                    value={state.date}
                                    handleChange={changeValue('date')}
                                    disabled={state.isDisabled}
                                    error={state.isError}
                                    helperText={state.isError}
                                />

                                <ACommonDataField
                                    component={
                                        A_COMMON_DATA_FIELD.SELECT_CURRENCIES
                                    }
                                    label={'Label'}
                                    value={state.currency}
                                    handleChange={changeValue('currency')}
                                    disabled={state.isDisabled}
                                    error={state.isError}
                                />

                                <ACommonDataField
                                    component={
                                        A_COMMON_DATA_FIELD.SELECT_COUNTRIES
                                    }
                                    label={'Label'}
                                    value={state.country}
                                    handleChange={changeValue('country')}
                                    disabled={state.isDisabled}
                                    error={state.isError}
                                />

                                <div className={"content-row"}>
                                    <AMultiSelectField
                                      category={'currencies'}
                                      label={'Label'}
                                      value={state.multiCurrency}
                                      handleChange={changeValue('multiCurrency')}
                                      disabled={state.isDisabled}
                                      error={state.isError}
                                    />
                                </div>

                                <div className={"content-row"}>
                                    <AMultiSelectField
                                        category={'countries'}
                                        label={'Label'}
                                        value={state.multiCountries}
                                        handleChange={changeValue('multiCountries')}
                                        disabled={state.isDisabled}
                                        error={state.isError}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default withRouter(ButtonsAppPage);
