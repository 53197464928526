import {
    EMAIL_REG_EXP,
    PASSWORD_REG_EXP,
    PASSWORD_REG_EXP_GLOBAL,
    PHONE_REG_EXP,
    SPANISH_AND_LATIN_REG_EXP
} from "../../constants/validations";

export const firstAndLastNameValidate = (value) => {
    const result = {
        isValid: true,
    };
    if (value === '' || value.length < 2 || value.length > 50) {
        result.isValid = false;
        result.message = 'errors.invalidLastName'
    }

    if (!SPANISH_AND_LATIN_REG_EXP.test(value) && value !== '') {
        result.isValid = false;
        result.message = 'errors.enterSpanishOrLatin'
    }

    return result;
}

export const emailValidate = (email) => {
    const result = {
        isValid: false,
    };
    if (email.length <= 7 || email.length >= 50 && email !== '') {
        result.message = 'errors.invalidEmailLength'
    } else if (!EMAIL_REG_EXP.test(email) && email !== ''){
        result.message = 'errors.invalidEmail'
    } else {
        result.isValid = true;
    }

    return result;
}

export const passwordValidate = (password) => {
    const result = {
        isValid: false,
    };
    if (password === '' || !PASSWORD_REG_EXP.test(password)) {
        result.message = 'errors.invalidPass'
    } else {
        result.isValid = true;
    }

    return result;
}

export const digitValidate = (value) => {
    return !PHONE_REG_EXP.test(value)
}

export const AvFieldValidationPassword = (i18n) => {
    return {
        required: {
            value: true,
            errorMessage: i18n.t('authentication.passwordInvalid')
        },
        minLength: {value: 8, errorMessage: i18n.t('authentication.minPassLength')},
        maxLength: {value: 30, errorMessage: i18n.t('authentication.maxPassLength')},
        pattern: {
            value: PASSWORD_REG_EXP_GLOBAL,
            errorMessage: i18n.t('authentication.passwordInvalid')
        },
    }
}

export const AvFieldValidationEmail = (i18n) => {
    return {
        required: {
            value: true
        },
        pattern: {
            value: EMAIL_REG_EXP,
            errorMessage: i18n.t('authentication.emailInvalid')
        }
    }
}

