import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./DurationPicker.css";
import DurationPickerColumn from "./DurationPickerColumn";

DurationPicker.propTypes = {
    onChange: PropTypes.func,
    initialDuration: PropTypes.shape({
        days: PropTypes.number,
        hours: PropTypes.number,
        minutes: PropTypes.number,
    }),
    maxHours: PropTypes.number,
    noHours: PropTypes.bool,
};

DurationPicker.defaultProps = {
    maxHours: 10,
    onChange: () => {},
    initialDuration: { days: 0, hours: 0, minutes: 0 },
    noHours: false,
};

function DurationPicker(props) {
    const { onChange, maxHours, initialDuration, noHours } = props;
    const [isSmallScreen, setIsSmallScreen] = useState(undefined);
    const [duration, setDuration] = useState(initialDuration);

    // column onChange handlers
    const onChangeHours = useCallback(days => {
        setDuration(prevDuration => ({ ...prevDuration, days }));
    }, []);
    const onChangeMinutes = useCallback(hours => {
        setDuration(prevDuration => ({ ...prevDuration, hours }));
    }, []);
    const onChangeSeconds = useCallback(minutes => {
        setDuration(prevDuration => ({ ...prevDuration, minutes }));
    }, []);

    // add/remove resize listener and measure screen size
    useEffect(() => {
        const resizeHandler = () => {
            if (window.innerWidth <= 400) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    // execute callback prop
    useEffect(() => {
        onChange(duration);
    }, [duration, onChange]);

    return (
        <div className="rdp-picker">
            {!noHours && (
                <DurationPickerColumn
                    onChange={onChangeHours}
                    unit="D"
                    maxHours={maxHours}
                    isSmallScreen={isSmallScreen}
                    initial={initialDuration.days}
                />
            )}
            <DurationPickerColumn
                onChange={onChangeMinutes}
                unit="H"
                isSmallScreen={isSmallScreen}
                initial={initialDuration.hours}
            />
            <DurationPickerColumn
                onChange={onChangeSeconds}
                unit="M"
                isSmallScreen={isSmallScreen}
                initial={initialDuration.minutes}
            />
        </div>
    );
}

export default DurationPicker;