import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import Breadcrumb from '../../common/components/Breadcrumb';
import i18n from '../../i18n';
import ClipboardButton from '../../common/cellData/CopyButton';
import {DATE_FORMAT, getGuidShortFormat} from '../../common/utils/common';
import CustomPagination from '../../common/tables/CustomPagination';

import './attributions.scss';

import mockData from './attribution-mock-list.json';
import close_2 from '../../assets/images/common/Group 10873_1.svg';
import NewCustomTable from '../../components/NewCustomTable';
import MetaTags from 'react-meta-tags';

function AttributionStatus({ status }) {
    return (
        <span className={`attribution-list-status ${status}`}>{status}</span>
    );
}

function AttributionsTable({ list }) {
    const columns = [
        { label: i18n.t('attributions.attributionID') },
        { label: i18n.t('attributions.addedTime') },
        { label: i18n.t('attributions.name') },
        { label: i18n.t('attributions.players') },
        { label: i18n.t('attributions.status') }
    ];

    return (
        <NewCustomTable columns={columns}>
            {list.map((item, index) => (
                <tr key={index} className="text-center">
                    <td>
                        <Link
                            to={`/audience/${item.id}`}
                            className="me-2 app-text-primary"
                        >
                            {getGuidShortFormat(item.id)}
                        </Link>
                        <ClipboardButton text={item.id} />
                    </td>
                    <td>
                        {moment(new Date(item.addedTime)).format(DATE_FORMAT)}
                        &nbsp;&nbsp;
                        {moment(new Date(item.addedTime)).format('HH:mm')}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.playersCount}</td>
                    <td>
                        <AttributionStatus status={item.status} />
                    </td>
                </tr>
            ))}
        </NewCustomTable>
    );
}

class AutomationListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: mockData
        };
    }

    handleAdd = () => {
        // TODO fix after added api
        this.props.history.push(`/audience/dfgsdfgdf`);
    };

    render() {
        const { data } = this.state;

        return (
            <div className="page-content attribution-page">
                <MetaTags>
                    <title>{i18n.t('attributions.audience')} - {process.env.REACT_APP_APP_NAME}</title>
                </MetaTags>

                <Container fluid>
                    <button
                        className="btn btn-rounded btn-primary app-btn-only-img add-button"
                        onClick={this.handleAdd}
                    >
                        <img src={close_2} alt="" />
                    </button>
                    <Breadcrumb
                        breadcrumbItem={i18n.t('attributions.audience')}
                    />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AttributionsTable list={data.list} />
                                </CardBody>
                            </Card>

                            {data.pages > 1 && (
                                <CustomPagination
                                    key={'attribution'}
                                    pageCount={data.pages}
                                    activePage={data.page}
                                    onChange={() => {}}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(AutomationListPage);
