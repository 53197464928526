import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InfoBlockHeader from './InfoBlockHeader';
import { Card } from 'reactstrap';
import i18n from '../../../../i18n';
import srcIcon from '../../../../assets/images/common/countries.svg';
import AppBarChart from '../../../../components/charts/AppBarChart';

const TopCountriesCRWidget = () => {
    const data = {
        DE: 1500,
        IT: 1200,
        UK: 1100,
        SV: 900,
        ES: 600
    };

    const chartData = useMemo(() => {
        if (!data) return null;

        return Object.keys(data).map((key, index) => ({
            name: key,
            topName: `${data[key]}$`,
            value: data[key]
        }));
    }, [data]);

    return (
        <Card className="statistic-widget top-countries-widget">
            <InfoBlockHeader
                title={i18n.t('admin.top5CountriesPV')}
                srcIcon={srcIcon}
            />

            <AppBarChart chartData={chartData} />
        </Card>
    );
};

TopCountriesCRWidget.propTypes = {
    data: PropTypes.object.isRequired
};

export default React.memo(TopCountriesCRWidget);
