import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Col, Container, Row } from 'reactstrap';

import i18n from '../../i18n';

import './styles.scss';

import useAlertService from '../../hooks/useAlertService';
import { useDispatch, useSelector } from 'react-redux';
import { getDeposit, resetDepositAlertsErrors } from '../../store/actions';
import Loader from '../../common/components/Loader';
import PaymentMainCard from './PaymentMainCard';
import PaymentDetailedCard from './PaymentDetailedCard';

function DepositView() {
    const alertService = useAlertService();
    const params = useParams();
    const dispatch = useDispatch();
    const { deposit, error } = useSelector(state => state.DepositDetailed);

    useEffect(() => {
        if (error && error?.message) {
            alertService.showError(error?.message);
            dispatch(resetDepositAlertsErrors());
        }
    }, [error]);

    useEffect(() => {
        loadPayment();
    }, [params.id]);

    const loadPayment = () => dispatch(getDeposit(params.id));

    return (
        <div className="page-content page-content_responsive document-content">
            <MetaTags>
                <title>{i18n.t('finance.transactionsView')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Container fluid>
                {deposit ?
                  <Row>
                      <Col lg="12">
                          <PaymentMainCard payment={deposit} />

                          <PaymentDetailedCard
                            payment={deposit}
                            reloadPayment={loadPayment}
                          />
                      </Col>
                  </Row> :
                  <Loader />
                }
            </Container>
        </div>
    );
}

export default withRouter(DepositView);
