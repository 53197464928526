import { get, getFile, patch, post } from './api_helper';
import { prepareFilterSearch, prepareSearch } from './SearchUtils';


const getAgentsListApi = () => {
    const url = `/agent/list`;
    return get(url);
};

const getRolesListApi = () => {
    const url = `/it-manager/roles`;
    return get(url);
};

const createAgentApi = (agentData) => {
    const url = `/agent/new`;
    return post(url, agentData);
};

const getAgentsEmployeeListApi = ({ id, query }) => {
    const url = `/agent/children/${id}?${prepareSearch(query)}`;
    return get(url);
};

const getAgentSelfApi = () => {
    const url = `/agent/self/info`;
    return get(url);
};

const updateAgentSelfStatusApi = dto => {
    const url = `/agent/self/status`;

    return patch(url, dto);
};

const updateAgentApi = ({ id, dto }) => {
    const url = `/agent/${id}`;
    return patch(url, dto);
};

const changeAgentPasswordApi = ({ id, data }) => {
    const url = `/management/${id}/password`;
    return patch(url, data);
};

const editAgentPasswordApi = dto => {
    const url = `/agent/change-password`;
    return post(url, dto);
};

const getAgentApi = id => {
    const url = `/agent/${id}`;
    return get(url);
};

const downloadAgentSelfInfoApi = () => {
    const url = `/agent/self/info/excel`;
    return getFile(url);
};

export {
    getAgentApi,
    getAgentsListApi,
    getRolesListApi,
    createAgentApi,
    getAgentSelfApi,
    updateAgentSelfStatusApi,
    updateAgentApi,
    changeAgentPasswordApi,
    editAgentPasswordApi,
    getAgentsEmployeeListApi,
    downloadAgentSelfInfoApi,
};
