
const DocumentType = {
  "INE": "INE",
  "IFE": "IFE",
  "Passport": "Passport",
  'UtilityBill': 'Utility Bill',
  'CURP_Certificate': 'CURP_Certificate',
  'PaymentConfirmation': 'PaymentConfirmation',
  'SOF_Agreement': 'SOF_Agreement',
  'BankStatement': 'Bank Statement',
  'BankStatementAsProofOfAddress': 'Bank Statement as Proof of Address',
  'Other': 'Other',
  'ID_front': 'ID - Front',
  'ID_back': 'ID - Back',
  'RFC': 'RFC',
  'CC_front': 'CC - Front',
  'CC_back': 'CC - Back',
  'SelfExclusion_Agreement': 'SelfExclusion_Agreement',
  'AccountRecovery_Agreement': 'AccountRecovery_Agreement',
};

const DOCUMENTS_TYPES = Object.values(DocumentType);

const DocumentSide = {
  "Front": "Front",
  "Back": "Back",
  "Both": "Both",
};

const DOCUMENTS_SIDES = Object.values(DocumentSide);

const DocumentStatus = {
  Uploaded: "Uploaded",
  Approved: "Approved",
  Rejected: "Rejected",
  Expired: "Expired",
  CanceledByPlayer: "CanceledByPlayer",
  Deleted: "Deleted",
};

export {
  DocumentType,
  DOCUMENTS_TYPES,
  DocumentSide,
  DOCUMENTS_SIDES,
  DocumentStatus,
}
