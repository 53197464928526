import PropTypes from 'prop-types';
import React, {Component, useEffect, useMemo, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

//Simple bar
import SimpleBar from 'simplebar-react';

// MetisMenu
import {Link, matchPath, useLocation, withRouter} from 'react-router-dom';

import {
    clearRetailPlayer,
    getPendingWithdrawalListCount,
    getPlayerKYCCount,
    getTransferWithdrawalListCount,
    getWithdrawalListCount,
} from '../../store/actions';

//i18n
import {useTranslation, withTranslation} from 'react-i18next';

import SidebarFilter from './SidebarFilter';

import { SCREEN_SIZES } from 'utils/consts';
import useWindowSize from 'hooks/useWindowSize';

import './sidebar.scss';
import {Button, Tooltip} from "@mui/material";
import {DEFAULT_PLAYER_KYC_COUNT_QUERY, DEFAULT_WITHDRAW_COUNT_QUERY,} from "../../constants/Filter";
import {clearCurrentFilter} from "../../store/filters/action";
import clsx from "clsx";

const ArrowRight = ({className = '', size = 20}) => (
    <svg
        width={size}
        height={size}
        className={className}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.5 13L11 8.5L6.5 4"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
const SidebarContent = ({
                            agentSelf,
                            menuPoints,
                            isLeftMenuShown,
                            closeMenu
                        }) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const size = useWindowSize();

    const [menuElements, setMenuElements] = useState(menuPoints);
    const {totalWithdrawAll, total: totalWithdrawAllList} = useSelector(state => state.WithdrawalList);
    const {
        withdrawalsForApproval,
        total: withdrawalsForApprovalList
    } = useSelector(state => state.PendingWithdrawalList);
    const {
        withdrawalsForTransfer,
        total: withdrawalsForTransferList
    } = useSelector(state => state.PendingTransferWithdrawalList);
    const {playerKYCCount} = useSelector(state => state.PlayerList);

    const {active} = useSelector(state => state.Brand);

    const refDiv = React.createRef();

    const isMobile = useMemo(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent), []);

    useEffect(() => {
        if (agentSelf?.permissions.includes("Transactions__View")) {
            updateWithdrawCount();
            dispatch(getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY));
        }

        const _menuElements = menuElements;
        // change all isCollapsed to false
        _menuElements.forEach((menu) => {
            menu.items.forEach((item) => {
                if (item.isCollapsed !== undefined) {
                    item.isCollapsed = false;
                }
            });
        });
        setMenuElements(_menuElements);
    }, []);


    const updateWithdrawCount = () => {
        dispatch(getPendingWithdrawalListCount(DEFAULT_WITHDRAW_COUNT_QUERY));
        dispatch(getTransferWithdrawalListCount(DEFAULT_WITHDRAW_COUNT_QUERY));
    }

    const disabledClick = event => {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();
    };

    const onLinkClick = (title) => {
        if (title === 'crm.pendingKycVerification') {
            dispatch(getPlayerKYCCount(DEFAULT_PLAYER_KYC_COUNT_QUERY));
        }
    };

    const currentLinkContent = (item, isActive) => {
        const {activeImg, img, title, withArrow} = item;
        const countOfPendingWDs = withdrawalsForApproval + withdrawalsForTransfer
        return (
            <>
                <img
                    src={
                        isActive ? activeImg : img
                    }
                    alt=""
                    width={20}
                />
                <span className="item-data">
                    <span className="badge rounded-pill bg-info float-end">
                        {title === 'crm.pendingKycVerification' && (
                            playerKYCCount ? playerKYCCount : ''
                        )}
                        {title === 'finance.allWithdrawals' && countOfPendingWDs
                            ? countOfPendingWDs
                            : ''
                        }
                        {title === 'finance.pendingWithdrawals' && (
                            withdrawalsForApproval &&
                            withdrawalsForApproval
                        )}
                        {title === 'finance.pendingWireWithdrawals' && (
                            withdrawalsForTransfer &&
                            withdrawalsForTransfer
                        )}
                    </span>
                    <span className="item-data__title">
                        {t(title)}
                        {" "}
                        {title === "content.retail" && active?.siteName.split('.')[0] || ''}
                    </span>
                    {withArrow && (
                        <ArrowRight className="arrow"/>
                    )}
                    </span>
            </>
        );
    };

    const currentLink = (key, item, isActive, isChild, menuIndex, itemIndex, isCollapsed) => {
        const {link, subMenu, disabled, comingSoon, isLink} = item;
        let onClick = () => {
            onLinkClick(item.title);
            size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && closeMenu();
        };
        if (disabled || comingSoon) {
            onClick = disabledClick;
        }
        if (subMenu) {
            onClick = () => {
                collapseMenu(item, menuIndex, itemIndex);
            };
        }

        return (isLink && !subMenu)
            ? <a
                id={key}
                style={{
                    ...(isChild && {display: 'flex'})
                }}
                href={link}
                target="_blank"
            >
                {currentLinkContent(item, isActive)}
            </a>
            : <Link
                id={key}
                style={{
                    ...(isChild && {display: 'flex'})
                }}
                to={{
                    pathname: link,
                }}
                className={clsx({
                    'has-arrow': subMenu && !isCollapsed,
                    'has-arrow-upsidedown': subMenu && isCollapsed,
                })}
                onClick={onClick}
            >
                {currentLinkContent(item, isActive)}
            </Link>;
    };

    function renderSubMenu(item, isLinkEqualPage, menuIndex, itemIndex, sitem, sind) {
        const isCollapsed = item?.isCollapsed;
        if (!item.subMenu || !item.subMenu.length) {
            return;
        }
        if (isCollapsed) {
            return;
        }

        const element = ((sitem, sind, isActive) => (
            <li
                key={`menu-item-sub${-sind}`}
                className={`
                           ${(sitem.disabled || sitem.comingSoon) ? 'disabled-menu_item disabled-link' : ''}  
                           ${isActive ? 'mm-active' : ''}`
                }
            >

                {sitem.comingSoon && (
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <div className="coming-soon">
                            {'Coming soon'}
                        </div>
                    </div>

                )}
                {
                    <Tooltip
                        title={t(sitem.title)}
                        placement="right"
                        classes={{tooltip: 'side-menu__tooltip'}}
                        style={{pointerEvents: (isLinkEqualPage || sitem.comingSoon || sitem.disabled) ? 'none' : 'auto'}}
                    >
                        <Button
                            disabled={sitem.disabled || sitem.comingSoon}
                            onClick={() => clearCurrentFilter()}>
                            {currentLink(`menu-item-sub-${-sind}`, sitem, isActive, true, menuIndex, itemIndex, isCollapsed)}
                        </Button>
                    </Tooltip>
                }
            </li>
        ));

        return (
            <>
                {!isMobile && (
                    <ul className="sub-menu" aria-expanded="false">
                        {item.subMenu.map((_sitem, _sind) => {
                            const isActive = checkIsActive(_sitem);
                            return (
                                element(_sitem, _sind, isActive)
                            );
                        })}
                    </ul>
                )}
                {isMobile && (
                    <div
                        style={{marginLeft: '10px'}}
                    >
                        {element(sitem, sind, (sitem.link === location.pathname))}
                    </div>
                )}
            </>
        );
    }

    const checkIsActive = (item) => {
        const isActive = item.link === `/${location.pathname.split('/')[1]}`;
        return isActive;
    }

    /**
     *
     * @param item
     * @param menuIndex
     * @param itemIndex
     * @param forceAction - 'open' | 'close'
     *
     */
    const collapseMenu = (item, menuIndex, itemIndex, forceAction) => {
        let active = checkIsActive(item);
        const newMenuElements = [...menuElements];

        if (newMenuElements[menuIndex]?.items[itemIndex]?.subMenu) {
            newMenuElements[menuIndex].items[itemIndex].subMenu.forEach((subItem) => {
                if (!active) {
                    active = checkIsActive(subItem);
                }
            });
        }
        if (active) {
            return;
        }

        let isCollapsed = newMenuElements[menuIndex].items[itemIndex]?.isCollapsed;
        if (isCollapsed === undefined) {
            newMenuElements[menuIndex].items[itemIndex].isCollapsed = false;
        } else {
            newMenuElements[menuIndex].items[itemIndex].isCollapsed = !isCollapsed;
        }
        if (forceAction === 'open') {
            newMenuElements[menuIndex].items[itemIndex].isCollapsed = false;
        } else if (forceAction === 'close') {
            newMenuElements[menuIndex].items[itemIndex].isCollapsed = true;
        }

        setMenuElements(newMenuElements);
    };

    return (
        <SimpleBar className={'h-100'} ref={refDiv}>
            <SidebarFilter isLeftMenuShown={isLeftMenuShown}/>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    {menuElements?.map((menu, index) => {
                        return (
                            <React.Fragment key={menu?.menuTitle}>
                                <li className="menu-title">
                                    {t(menu?.menuTitle)}
                                </li>
                                {menu.items.map((item, ind) => {
                                    const isActive = checkIsActive(item);
                                    const isLinkEqualPage = item.link === location.pathname;
                                    return (
                                        <>
                                            <li
                                                key={`menu-item${-ind}`}
                                                className={`
                                                    ${(item.disabled || item.comingSoon) ? 'disabled-menu_item disabled-link' : ''}  
                                                    ${isActive ? 'mm-active' : ''}`
                                                }
/*
                                                onMouseEnter={() => {
                                                    if (!isMobile) {
                                                        collapseMenu(item, index, ind);
                                                    }
                                                }}
*/
                                            >

                                                {item.comingSoon && (
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                    }}>
                                                        <div className="coming-soon">
                                                            {'Coming soon'}
                                                        </div>
                                                    </div>

                                                )}
                                                {
                                                    <Tooltip
                                                        title={t(item.title)}
                                                        placement="right"
                                                        classes={{tooltip: 'side-menu__tooltip'}}
                                                        style={{pointerEvents: (isLinkEqualPage || item.comingSoon || item.disabled) ? 'none' : 'auto'}}
                                                    >
                                                        <Button
                                                            disabled={item.disabled || item.comingSoon}
                                                            onClick={() => clearCurrentFilter()}>
                                                            {currentLink(`menu-item-${-ind}`, item, isActive, false, index, ind, item.isCollapsed)}
                                                        </Button>
                                                    </Tooltip>
                                                }

                                                {item.isCollapsed}
                                                {!isMobile && renderSubMenu(item, isLinkEqualPage, index, ind)}
                                            </li>
                                            <>
                                                {isMobile && item.subMenu && item.subMenu.length > 0 && item.subMenu.map((sitem, sind) => renderSubMenu(item, isLinkEqualPage, index, ind, sitem, sind))}
                                            </>
                                        </>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </div>
        </SimpleBar>
    );
}

export default SidebarContent;