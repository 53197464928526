import {
  GET_PENDING_TRANSFER_WITHDRAWAL_LIST,
  GET_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS,
  GET_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL,
  RESET_PENDING_TRANSFER_WITHDRAWAL_LIST_ALERTS_ERRORS,
  DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST,
  DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS,
  DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL,
  GET_TRANSFER_WITHDRAWAL_COUNT,
  GET_TRANSFER_WITHDRAWAL_COUNT_SUCCESS,
  GET_TRANSFER_WITHDRAWAL_COUNT_FAIL,
} from './actionTypes';

import {
  FILTER_PENDING_FOR_WIRE_TRANSFER_DEFAULT_VALUES
} from "../../../constants/Filter";

export const getTransferWithdrawalListCount = headers => ({
  type: GET_TRANSFER_WITHDRAWAL_COUNT,
  payload: {
    headers,
    filter: {
      filterDynamic: FILTER_PENDING_FOR_WIRE_TRANSFER_DEFAULT_VALUES
    }
  }
});

export const getTransferWithdrawalListCountSuccess = payload => ({
  type: GET_TRANSFER_WITHDRAWAL_COUNT_SUCCESS,
  payload
});

export const getTransferWithdrawalListCountFail = payload => ({
  type: GET_TRANSFER_WITHDRAWAL_COUNT_FAIL,
  payload
});

export const getPendingTransferWithdrawalList = payload => ({
  type: GET_PENDING_TRANSFER_WITHDRAWAL_LIST,
  payload
});

export const getPendingTransferWithdrawalListSuccess = payload => ({
  type: GET_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const getPendingTransferWithdrawalListFail = payload => ({
  type: GET_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL,
  payload
});

export const resetPendingTransferWithdrawalListAlertsErrors = payload => ({
  type: RESET_PENDING_TRANSFER_WITHDRAWAL_LIST_ALERTS_ERRORS,
  payload
});

export const downloadPendingTransferWithdrawalList = payload => ({
  type: DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST,
  payload
});

export const downloadPendingTransferWithdrawalListSuccess = payload => ({
  type: DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const downloadPendingTransferWithdrawalListFail = payload => ({
  type: DOWNLOAD_PENDING_TRANSFER_WITHDRAWAL_LIST_FAIL,
  payload
});
