import React from 'react';
import { Card } from 'reactstrap';
import ConversionInfoContainer from './common/ConversionInfoContainer';
import HeaderAgentStatisticsMainCard from './common/HeaderAgentStatisticsMainCard';
import PropTypes from 'prop-types';

const AgentStatisticsMainCard = ({ agent, statistics }) => {
    return (
        <Card>
            <HeaderAgentStatisticsMainCard agent={agent} />

            <hr className="info-separator" />

            <ConversionInfoContainer statistics={statistics} />
        </Card>
    );
};

AgentStatisticsMainCard.propTypes = {
    agent: PropTypes.object.isRequired,
    statistics: PropTypes.object.isRequired
};

export default React.memo(AgentStatisticsMainCard);
