import {
    GET_STATES_AND_CITIES,
    GET_STATES_AND_CITIES_ERROR,
    GET_STATES_AND_CITIES_SUCCESS,
    PUT_CURRENT_CITIES
} from './actionTypes';

export const getStatesAndCities = () => ({
    type: GET_STATES_AND_CITIES
});

export const putCurrentCities = (listOfCities) => ({
    type: PUT_CURRENT_CITIES,
    payload: listOfCities
});

export const getStatesAndCitiesSuccess = response => ({
    type: GET_STATES_AND_CITIES_SUCCESS,
    payload: response
});

export const getStatesAndCitiesError = error => ({
    type: GET_STATES_AND_CITIES_ERROR,
    payload: error
});
