import {
    GET_NOTIFICATIONS_LIST_PAGE,
    GET_NOTIFICATIONS_LIST_PAGE_SUCCESS
} from './actionTypes';

import i18n from '../../../i18n';

const INITIAL_STATE = {
    error: {},
    isLoading: false,
    notificationsList: [],
    query: {
        limit: 10,
        offset: 0
    },
    data: {}
};

const NotificationPage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_LIST_PAGE:
            return { ...state, isLoading: true, successMessage: '' };
        case GET_NOTIFICATIONS_LIST_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        default:
            return state;
    }
};

export default NotificationPage;
