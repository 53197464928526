import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import info from '../../../../assets/images/common/info.svg';
import today from '../../../../assets/images/common/Today.svg';
import yesterday from '../../../../assets/images/common/Yesterday.svg';
import month from '../../../../assets/images/common/Month.svg';
import i18n from '../../../../i18n';
import StatisticsInfoView from './StatisticsInfoView';
import BlockWrapper from './BlockWrapper';

const TimePeriodDataContent = ({ infoData }) => {
    const infoSchema = useMemo(() => {
        return [
            {
                title: i18n.t('admin.grossDeposits'),
                content: infoData.grossDeposits
            },
            {
                title: i18n.t('admin.withdrawals'),
                content: infoData.withdrawals
            },
            {
                title: i18n.t('admin.netDeposits'),
                content: infoData.netDeposits
            },
            {
                title: i18n.t('admin.volume'),
                content: infoData.volume
            }
        ];
    }, [infoData]);

    return (
        <>
            {infoSchema.map(item => {
                return (
                    <StatisticsInfoView
                        key={item.title}
                        title={item.title}
                        content={item.content}
                    />
                );
            })}
        </>
    );
};

const GeneralBlock = ({ infoData }) => {
    const infoSchema = useMemo(() => {
        return {
            left: [
                {
                    title: i18n.t('admin.loggedIn'),
                    content: infoData.loggedIn
                },
                {
                    title: i18n.t('admin.deposited'),
                    content: infoData.deposited
                }
            ],
            right: [
                {
                    title: i18n.t('admin.marginCalls'),
                    content: infoData.marginCalls
                },

                {
                    title: i18n.t('admin.appointments'),
                    content: infoData.appointments
                }
            ]
        };
    }, [infoData]);

    return (
        <BlockWrapper
            title={i18n.t('admin.general')}
            srcIcon={info}
            className="wide-block"
        >
            <StatisticsInfoView
                title={i18n.t('admin.totalClients')}
                content={infoData.totalClients}
                className="statistics-info-view--lg"
            />

            <div>
                {infoSchema.left.map(item => {
                    return (
                        <StatisticsInfoView
                            key={item.title}
                            title={item.title}
                            content={item.content}
                            size={item.size}
                        />
                    );
                })}
            </div>
            <div>
                {infoSchema.right.map(item => {
                    return (
                        <StatisticsInfoView
                            key={item.title}
                            title={item.title}
                            content={item.content}
                            size={item.size}
                        />
                    );
                })}
            </div>
        </BlockWrapper>
    );
};

const TodayBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.today')} srcIcon={today}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const YesterdayBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.yesterday')} srcIcon={yesterday}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const MTDBlock = ({ infoData }) => {
    return (
        <BlockWrapper title={i18n.t('admin.MTD')} srcIcon={month}>
            <TimePeriodDataContent infoData={infoData} />
        </BlockWrapper>
    );
};

const RetentionInfoContainer = ({ statistics }) => {
    return (
        <div className="statistics-info-container retention">
            <GeneralBlock infoData={statistics} />

            <TodayBlock infoData={statistics} />

            <YesterdayBlock infoData={statistics} />

            <MTDBlock infoData={statistics} />
        </div>
    );
};

RetentionInfoContainer.propTypes = {
    statistics: PropTypes.object.isRequired
};

export default React.memo(RetentionInfoContainer);
