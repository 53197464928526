import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../i18n';
import addIcon from '../../assets/images/common/addIcon.svg';
import saveIcon from '../../assets/images/common/saveFilterBtn.svg';

import useToggleModal from '../../hooks/useToggleModal';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import {
    clearCurrentFilter,
    removeCurrentFilter,
    resetFiltersAlertsErrors
} from '../../store/filters/action';
import useAlertService from '../../hooks/useAlertService';

import FilterModalsAndPopaps from './_elements/FilterModalsAndPopaps';
import FilterBadge from './_elements/FilterBadge';

import { FILTER_POPUPS, INITIAL_FILTER_POPUP } from '../../constants/Filter';

import './style.scss';
import useClickOutside from '../../common/hooks/useClickOutside';
import { useHistory, useLocation } from "react-router-dom";
import { redirectFilterCategory } from "../../utils/filterСategory";
import { getLocalStorageFilter } from "../../store/filters/reducer";
import useWindowSize from "../../hooks/useWindowSize";
import { SCREEN_SIZES } from "../../utils/consts";

const NUMBER_OF_ACTIVE_FILTERS_THAT_CANNOT_HIDDEN = 5;

const TableFilter = (props) => {
    const {
        filterListOptions,
        isDisabled = false,
        category,
        activeTab,
        buttonMode = false,
        defaultFilter = undefined,
    } = props;
    const inputRef = useRef(null);
    const areaRef = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const size = useWindowSize();
    const [_filterList, _setFilterList] = useState(filterListOptions || []);

    const { showSuccess, showError } = useAlertService();
    const { currentFilter, successMessage, error } = useSelector(
        state => state.Filter
    );

    useEffect(() => {
        const localStorageFilterCategory = localStorage.getItem('LocalStorageFilterCategory')
        if (localStorageFilterCategory !== category) {
            localStorage.setItem('LocalStorageFilterCategory', category);
            if (defaultFilter) {
                dispatch(clearCurrentFilter(defaultFilter));
            }
            else if (currentFilter.length > 0) {
                dispatch(clearCurrentFilter());
            }
        }
    }, [currentFilter, category])

    const [isSaveModalShow, onSaveModalShow, onSaveModalHide] =
        useToggleModal();

    const [isUsed, setUsed] = useState(false);
    const [isShowFilters, setShowFilters] = useState(true);
    const [isAddNewFilter, setAddNewFilter] = useState(false);
    const [filterFromLocalStore, setFilterFromLocalStore] = useState([]);
    const [search, setSearch] = useState('');
    const [activePopup, setActivePopup] = useState(INITIAL_FILTER_POPUP);

    const onAddFilter = useCallback(() => {
        setAddNewFilter(true);
        setShowFilters(false);
    }, []);

    const onOpenNewFilter = useCallback(() => {
        setUsed(true);
        if (!currentFilter.length) {
            onAddFilter()
        }
    }, [currentFilter, setUsed, setAddNewFilter]);

    const redirectAfterChooseFilter = useMemo(() => {
        return redirectFilterCategory(currentFilter?.nameFilter);
    }, [currentFilter?.nameFilter])

    const onClear = useCallback(() => {
        const { nameFilter } = filterFromLocalStore.length > 0 ? filterFromLocalStore[0] : {};
        if (nameFilter) {
            history.block(navigate => {
                if (location.pathname === '/deposits' && navigate.pathname === '/deposits') {
                    if (nameFilter !== 'payment') {
                        dispatch(clearCurrentFilter(defaultFilter));
                    }
                } else {
                    if (!navigate.pathname.includes(nameFilter)) {
                        dispatch(clearCurrentFilter(defaultFilter));
                    }
                }
            });
        }
        if (localStorage.getItem('LocalStorageFilter').length) {
            history.block(navigate => {
                if (navigate.pathname === '/filter') {
                    dispatch(clearCurrentFilter(defaultFilter));
                }
            })
        }

        setUsed(false);
        setAddNewFilter(false);
        setActivePopup(INITIAL_FILTER_POPUP);
    }, [filterFromLocalStore]);

    const onClearCurrentFilter = () => {
        dispatch(clearCurrentFilter(defaultFilter));
        setUsed(false);
        setAddNewFilter(false);
        setActivePopup(INITIAL_FILTER_POPUP);
    };

    const onCancelPopups = useCallback(() => {
        setAddNewFilter(false);
        setActivePopup(INITIAL_FILTER_POPUP);
    }, []);

    const onToggleFilters = useCallback(() => {
        setShowFilters(prev => !prev);
    }, [setShowFilters]);

    const onEditFilter = useCallback(
        idx => () => {
            const currFilterEl = currentFilter[idx];

            if (currFilterEl) {
                const { key: currKey, value } = currFilterEl;
                let filterValue;
                const currOption = filterListOptions.find(({ value }) => value === currKey);

                if (currOption) {
                    let filterValue;
                    if (currOption.modal === FILTER_POPUPS.EXCEL) {
                        filterValue = currentFilter[idx].value;
                    }
                    else {
                        filterValue = currentFilter
                            .filter(({ key }) => key === currKey)
                            .map(({ value }) => value);

                        if (filterValue.length === 1) {
                            filterValue = filterValue[0];
                        }
                        else if (filterValue.length === 0) {
                            filterValue = undefined;
                        }
                    }

                    setActivePopup({
                        activeModal: currOption.modal,
                        title: currOption.title || currOption.label,
                        key: currOption.value,
                        data: null,
                        list: currOption.list || undefined,
                        initialValue: filterValue ?? (currOption.initialValue || undefined),
                        isEditMode: true,
                        idx,
                    });
                }
            }
        },
        [currentFilter, filterListOptions]
    );

    const onRemoveFilter = useCallback(
        idx => () => {
            if (currentFilter.length === 1) {
                setUsed(false);
            }
            dispatch(removeCurrentFilter(idx));
        },
        [currentFilter]
    );

    const onSearch = useCallback(
        event => {
            setSearch(event.target.value);
        },
        []
    );

    useHandleEsc(onCancelPopups);
    useClickOutside(areaRef, () => {
        // to avoid close popup when clicking on the datepicker
        if (activePopup?.activeModal !== FILTER_POPUPS.DATE_RANGE) {
            onCancelPopups();
            if (currentFilter.length === 0) {
                setUsed(false);
            }
        }
    });

    useEffect(() => {
        if (isUsed && isAddNewFilter) {
            inputRef.current?.focus();
        }
    }, [isUsed, isAddNewFilter]);

    useEffect(() => {
        if (!!currentFilter.length) {
            setUsed(true);
        } else {
            setUsed(false);
        }
    }, [currentFilter]);

    useEffect(() => {
        if (successMessage) {
            showSuccess(successMessage);
            dispatch(resetFiltersAlertsErrors());
        }
    }, [successMessage]);

    useEffect(() => {
        if (error && error?.message) {
            showError(error?.message);
            dispatch(resetFiltersAlertsErrors());
        }
    }, [error]);

    useEffect(() => {
        if (getLocalStorageFilter()?.length === 0 && defaultFilter) {
            dispatch(clearCurrentFilter(defaultFilter));
        }

        const handleChangeStorage = () => {
            setFilterFromLocalStore(getLocalStorageFilter());
        }
        window.addEventListener('storage', handleChangeStorage);

        return () => {
            onClear();
            window.removeEventListener('storage', handleChangeStorage)
        };
    }, []);

    const setVisibleFilterValue = () => {
        return size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW
            ? NUMBER_OF_ACTIVE_FILTERS_THAT_CANNOT_HIDDEN
            : currentFilter.length;
    }

    useEffect(() => {
        const seenLabels = new Set();
        const filteredOptions = filterListOptions.filter(option => {
            const lowercaseLabel = option.label.toLowerCase();
            if (!seenLabels.has(lowercaseLabel) && /[A-Z]/.test(option.label)) {
                seenLabels.add(lowercaseLabel);
                return true;
            }
            return false;
        });
        _setFilterList(filteredOptions);
    }, [filterListOptions]);

    return (
        <>
            <Row
                className={`filter_wrapper${
                    isUsed ? ' filter_wrapper-used' : ''
                }`}
            >
                {!isUsed && !isDisabled && !buttonMode && (
                  <Col xs={12} className={'filter_settings'}>
                    <Button color={'link'} className="d-flex align-items-center">
                      <div onClick={onOpenNewFilter}>
                          <img src={addIcon} alt={i18n.t('filter.addFilter')} />
                          <span>{i18n.t('filter.addFilter')}</span>
                      </div>
                    </Button>
                  </Col>
                )}

                {!isUsed && !isDisabled && buttonMode &&
                    <Col xs={12} className={'filter_settings'}>
                        <button onClick={onOpenNewFilter} className="filter-button-mode btn btn-primary btn-rounded">
                            <img src={addIcon} alt={i18n.t('filter.addFilter')}/>
                            <span>{i18n.t('filter.addFilter')}</span>
                        </button>
                    </Col>
                }

                {isUsed && (
                    <Col xs={12}>
                        <div className="filter-used-container">
                            <Row
                                sm={'auto'}
                                className={
                                    'filter_badges-wrapper d-flex align-items-center'
                                }
                            >
                                {(isShowFilters
                                    ? currentFilter
                                    : currentFilter.slice(0, setVisibleFilterValue())
                                ).map((filter, index) => (
                                    <FilterBadge
                                        key={`${filter?.key}-${filter?.value}`}
                                        value={filter?.value}
                                        index={index}
                                        fieldKey={filter?.key}
                                        onEditFilter={onEditFilter}
                                        onRemoveFilter={onRemoveFilter}
                                    />
                                ))}

                                {
                                    !isShowFilters && size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW &&
                                    (currentFilter.length - NUMBER_OF_ACTIVE_FILTERS_THAT_CANNOT_HIDDEN > 0) &&
                                    <Col className={'filter_counter'}>
                                        (+{currentFilter.length - NUMBER_OF_ACTIVE_FILTERS_THAT_CANNOT_HIDDEN}){' '}
                                        {i18n.t('filter.filters')}
                                    </Col>
                                }

                                {isShowFilters && isAddNewFilter
                                    ? <Col className={'input_wrapper'}>
                                        {/*Don't use search for filter now*/}
                                        {/*<input*/}
                                        {/*    value={search}*/}
                                        {/*    onChange={onSearch}*/}
                                        {/*    className={'search_input'}*/}
                                        {/*    ref={inputRef}*/}
                                        {/*/>*/}
                                      </Col>
                                    : <Col className='d-flex align-items-center p-0 filter__add-more__btn'>
                                        <img
                                            src={addIcon}
                                            alt={i18n.t('filter.addFilter')}
                                            onClick={onAddFilter}
                                            className={'add_filter-icon'}
                                        />
                                    </Col>}
                            </Row>

                            {!!currentFilter.length && (
                                <div
                                    className={`filter_buttons${
                                        !isShowFilters ? ' center' : ''
                                    }`}
                                >
                                    <Button color={'link'} onClick={onClearCurrentFilter}>
                                        {i18n.t('filter.clearFilters')}
                                    </Button>

                                    <img
                                        src={saveIcon}
                                        style={{cursor: 'pointer'}}
                                        onClick={onSaveModalShow}
                                        width={'35px'}
                                        height={'35px'}
                                        alt={i18n.t('filter.save')}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                )}

                {isUsed && currentFilter?.length > NUMBER_OF_ACTIVE_FILTERS_THAT_CANNOT_HIDDEN
                        && size?.width > SCREEN_SIZES.MAX_MOBILE_VIEW && (
                    <div className={'filter-toggler-outer'}>
                        <div
                            className={'filter-toggler-wrapper'}
                            onClick={onToggleFilters}
                        >
                            <div
                                className={`filter-toggler-icon${
                                    isShowFilters ? ' show' : ''
                                }`}
                            />
                        </div>
                    </div>
                )}
            </Row>

            <div ref={areaRef} style={{ display: 'contents' }}>
                <FilterModalsAndPopaps
                    category={category}
                    activePopup={activePopup}
                    setActivePopup={setActivePopup}
                    isAddNewFilter={isAddNewFilter}
                    onSaveModalHide={onSaveModalHide}
                    isSaveModalShow={isSaveModalShow}
                    setAddNewFilter={setAddNewFilter}
                    filterListOptions={_filterList}
                    onOpenNewFilter={onOpenNewFilter}
                    activeTab={activeTab}
                />
            </div>
        </>
    );
};

TableFilter.propTypes = {
    filterListOptions: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
    category: PropTypes.string,
    defaultFilter: PropTypes.array,
};

export default React.memo(TableFilter);
