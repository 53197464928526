/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_SIDEBAR_THEME_IMAGE = 'CHANGE_SIDEBAR_THEME_IMAGE';
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE';

// topbar
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR';
export const HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR';

/* LEFT MENU */
export const SHOW_LEFT_MENU = 'SHOW_LEFT_MENU';

/* PLAYER MAIN CARD */
export const SHOW_PLAYER_DROPDOWN = 'SHOW_PLAYER_DROPDOWN';
// Preloader
export const CHANGE_PRELOADER = 'CHANGE_PRELOADER';
