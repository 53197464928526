import i18n from "../../../i18n";

export default [
    {
        id: 'check',
        title: '',
    },
    {
        id: 'game',
        title: i18n.t('content.gameName'),
        source: 'friendlyName',
        className: 'bold game-name',
    },
    {
        id: 'gameProvider',
        title: i18n.t('content.gameProvider'),
        className: 'text-align-center',
    },
    {
        id: 'gameVendor',
        source: 'vendorName',
        title: i18n.t('content.gameVendor'),
        className: 'text-align-center width150 game-vendor',
    },
    {
        id: 'addedTime',
        source: 'createdAt',
        title: i18n.t('content.addedTime'),
        className: 'text-align-center width150',
    },
    {
        id: 'rtp',
        title: i18n.t('content.rtp'),
        className: 'text-align-center',
    },
    {
        id: 'gameIdMobile',
        source: 'externalMobileId',
        title: i18n.t('content.gameIdMobile'),
        className: 'text-align-center',
    },
    {
        id: 'gameIdDesktop',
        source: 'externalDesktopId',
        title: i18n.t('content.gameIdDesktop'),
        className: 'text-align-center',
    },
]
