import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';

import i18n from '../../../i18n';

import './search-and-checkbox-select.scss';
import SearchInput from './SearchInput';
import CheckboxInput from '../CheckboxInput';
import SimpleBar from 'simplebar-react';
import useHandleEnter from '../../hooks/useHandleEnter';

const initState = (initialValue) => Array.isArray(initialValue)
    ? initialValue
    : ['string', 'number'].includes(typeof initialValue)
        ? [initialValue]
        : [];

const SearchAndCheckboxSelect = ({
    placeholder = i18n.t('crm.selectOption'),
    checkboxList,
    onChange,
    activePopup,
    initialValue,
}) => {
    const [state, setState] = useState(initState(initialValue));
    const [search, setSearch] = useState('');
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [showingCheckboxList, setShowingCheckboxList] =
        useState(checkboxList);

    useEffect(() => {
        setShowingCheckboxList(checkboxList);
    }, [checkboxList]);

    const handleSave = useCallback(() => {
        onChange(state);
        setToggleSelect(false);
    }, [state]);

    const handleClick = useCallback(() => {
        setToggleSelect(prev => !prev);
    }, [setToggleSelect]);

    const handleSearch = useCallback(
        value => {
            if (activePopup.key === 'agent_owner') {
                value = value.replace(/[^a-zA-Z\s]/g, '');
            }

            setSearch(value);
            setShowingCheckboxList(
                showOnlySelected
                    ? checkboxList
                        .filter(item => state.includes(item.key))
                        .filter(item =>
                            item.label?.toLowerCase().startsWith(value.toLowerCase())
                        )
                    : checkboxList.filter(item =>
                        item.label?.toLowerCase().startsWith(value.toLowerCase())
                    )
            );
        },
        [showOnlySelected, checkboxList, activePopup.key]
    );


    const handleShowSelected = useCallback(() => {
        setSearch('');
        setShowOnlySelected(!showOnlySelected);

        if (showOnlySelected) {
            setShowingCheckboxList(checkboxList);
        } else {
            setShowingCheckboxList(
                checkboxList.filter(
                    ({ key }) => state.findIndex(item => item === key) !== -1
                )
            );
        }
    }, [showOnlySelected, checkboxList, state]);

    const handleCheckboxClick = useCallback(
        (key) => value => {
            let newValue = [...state];

            if (value) {
                newValue = [...newValue, key];
            } else {
                newValue = newValue.filter(elem => elem !== key);
            }
            setState(newValue);

            if (showOnlySelected) {
                setShowingCheckboxList(
                    checkboxList.filter(
                        ({ key }) =>
                            newValue.findIndex(item => item === key) !== -1
                    )
                );
            }
        },
        [state, showOnlySelected]
    );

    const renderSelectValue = useMemo(
        () =>
            state && !!state.length
                ? state.reduce((acc, cur) => {
                      const value = checkboxList.find(item => item.key === cur);

                      return `${acc}${!!acc ? ', ' : ''}${
                          value?.label ? value?.label : cur
                      }`;
                  }, '')
                : placeholder,
        [state, placeholder]
    );

    useHandleEnter(handleSave);

    return (
        <Row className={'search_and_checkbox_select'}>
            <Col
                xs={12}
                onClick={handleClick}
                className={`search_and_checkbox_select-wrapper${
                    toggleSelect ? ' active' : ''
                }`}
            >
                <span>{renderSelectValue}</span>
            </Col>

            {toggleSelect && (
                <Col className={'search_and_checkbox_select-toggle_menu'}>
                    <SearchInput
                        onChange={handleSearch}
                        value={search}
                        placeholder={i18n.t('crm.search')}
                    />

                    {showingCheckboxList?.length || checkboxList?.length ? (
                        <>
                            <Row className="search_and_checkbox_select-toggle_menu-show_only">
                                <Col>
                                    <CheckboxInput
                                        onChange={handleShowSelected}
                                        checked={showOnlySelected}
                                        className={`search_and_checkbox_select-toggle_menu-checkbox${
                                            showOnlySelected ? ' active' : ''
                                        }`}
                                    >
                                        {i18n.t('crm.showOnlySelected')}
                                    </CheckboxInput>
                                </Col>
                            </Row>
                            <SimpleBar
                                forceVisible="y"
                                autoHide={false}
                                className="search_and_checkbox_select-toggle_menu-list"
                            >
                                {showingCheckboxList && showingCheckboxList.map(({ key, label }, index) => {
                                    const selected = state.findIndex(item => item === key) !== -1;
                                    return (
                                        <CheckboxInput
                                            key={index}
                                            onChange={handleCheckboxClick(key)}
                                            checked={selected}
                                            className={`search_and_checkbox_select-toggle_menu-checkbox${
                                                selected ? ' active' : ''
                                            }`}
                                        >
                                            {label}
                                        </CheckboxInput>
                                    );
                                })}
                            </SimpleBar>
                        </>
                    ) : i18n.t('crm.select.no.options')}

                    <Row className={'filter-search_and_checkbox-footer'}>
                        <Col>
                            <Button
                                color={'primary'}
                                disabled={!state.length}
                                onClick={handleSave}
                            >
                                {i18n.t('filter.apply')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

SearchAndCheckboxSelect.Proptypes = {
    placeholder: PropTypes.string,
    checkboxList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
};

SearchAndCheckboxSelect.defaultProps = {
    initialValue: [],
};

export default React.memo(SearchAndCheckboxSelect);
