import i18n from "../../../i18n";
import {
    ACTIVE_PROMOTIONS,
    INACTIVE_PROMOTIONS,
    SPORT_PROMOTION_TYPE
} from "../common/promotionConstants";
import React, { memo, useCallback, useEffect, useState } from "react";
import { DIRECTION } from "../../../constants/layout";
import { useTimeContext } from "../../../context/TimeContext";
import { Table } from "reactstrap";
import clsx from "clsx";
import { Link, withRouter } from "react-router-dom";
import { getColumnSettingsLsKey, getGuidShortFormat, initTableColumns } from "../../../common/utils/common";
import ClipboardButton from "../../../common/cellData/CopyButton";
import Formatter from "../../../utils/Formatter";
import { SCREEN_SIZES } from "../../../utils/consts";
import TableSettingsModal from "../../CRM/modal/TableSettingsModal";
import useWindowSize from "../../../hooks/useWindowSize";

const COLUMN_IDS = {
    promotionId: 'promotionId',
    createdTime: 'createdTime',
    lastUpdated: 'lastUpdated',
    name: 'name',
    type: 'type',
    sportsBook: 'sports.book',
    status: 'status',
    agentName: 'agentName',
};

const getDefaultColumns = () => {
    return [
        COLUMN_IDS.promotionId,
        COLUMN_IDS.createdTime,
        COLUMN_IDS.lastUpdated,
        COLUMN_IDS.name,
        COLUMN_IDS.type,
        COLUMN_IDS.sportsBook,
        COLUMN_IDS.status,
        COLUMN_IDS.agentName,
    ];
};

const KEY_I18N_MAP = {
    [COLUMN_IDS.promotionId]: 'promotions.promotionId',
    [COLUMN_IDS.createdTime]: 'promotions.createdTime',
    [COLUMN_IDS.lastUpdated]: 'promotions.lastUpdated',
    [COLUMN_IDS.name]: 'promotions.name',
    [COLUMN_IDS.type]: 'promotions.type',
    [COLUMN_IDS.sportsBook]: 'promotions.sports.book',
    [COLUMN_IDS.status]: 'promotions.status',
    [COLUMN_IDS.agentName]: 'promotions.agentName',
};

const ALL_PROMOTION_TYPES = `${ACTIVE_PROMOTIONS}, ${INACTIVE_PROMOTIONS}`;

const TdElem = ({
    item,
    field,
    timeFormat,
}) => {
    const size = useWindowSize();

    switch (field) {
        case COLUMN_IDS.promotionId:
            return <td>
                <div className="promo-id__item">
                    <Link to={`/sport-promotions/${item.id}`} className="me-2 app-text-primary">
                        {getGuidShortFormat(item.id)}
                    </Link>
                    <ClipboardButton text={item.id} />
                    {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                        <Link to={`/sport-promotions/${item.id}`} className="me-2 app-text-primary">
                            <span>{i18n.t(`promotion.statuses.${item.status}`)}</span>
                        </Link>
                    )}
                </div>
            </td>;
        case COLUMN_IDS.createdTime:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.createdTime')}</span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.createdAt))}
            </td>;
        case COLUMN_IDS.lastUpdated:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.lastUpdated')}</span>
                )}
                {Formatter.displayDateTimeMinutes(timeFormat.parseTime(item.lastChangedAt))}
            </td>;
        case COLUMN_IDS.name:
            return (
                size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT ? (
                    <td className="title__top">
                        <Link to={`/sport-promotions/${item.id}`} className="me-2 app-text-primary">
                            {item.name}
                        </Link>
                    </td>
                ) : (
                    <td>{item.name}</td>
                )
            )
        case COLUMN_IDS.type:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.type')}</span>
                )}
                {item.settings?.isDeposit
                    ? SPORT_PROMOTION_TYPE.DEPOSIT
                    : SPORT_PROMOTION_TYPE.FREE_BET
                }
            </td>;
        case COLUMN_IDS.sportsBook:
            return <td>
                {size.width <= SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                    <span>{i18n.t('promotions.sports.book')}</span>
                )}
                {item.settings?.sb}
            </td>;
        case COLUMN_IDS.status:
            return (size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                <td>
                    {i18n.t(`promotion.statuses.${item.status}`)}
                </td>
            ))
        case COLUMN_IDS.agentName:
            return (size.width > SCREEN_SIZES.MAX_TABLET_PORTRAIT && (
                <td>
                    {item.agentName || '-'}
                </td>
            ))
        default:
            return null;
    }
};


const SportPromotionsTable = (props) => {
    const {
        list,
        onSortPromotions,
        isShowTableSettings = false,
        onCloseTableSettings = () => {},
        match,
    } = props;
    const { timeFormat } = useTimeContext();
    const [sortInfo, setSortInfo] = useState({
        column: COLUMN_IDS.createdTime,
        direction: DIRECTION.DESC,
    });

    const [columns, setColumns] = useState([]);
    const [activeColumns, setActiveColumns] = useState(getDefaultColumns());
    const [activeRow, setActiveRow] = useState(false);
    const [defaultColumns, setDefaultColumns] = useState(getDefaultColumns());

    useEffect(() => {
        initTableColumns(match.path, getDefaultColumns, setActiveColumns);
    }, []);

    useEffect(() => {
        const columnsData = activeColumns.map((key) => ({
            label: i18n.t(KEY_I18N_MAP[key]),
            id: key,
        }));

        setColumns(columnsData);
    }, [activeColumns])

    const handleChangeColumns = (columnsSequence) => {
        onCloseTableSettings();

        // Apply columnsSequence
        if (columnsSequence.length > 1) {
            setActiveColumns(columnsSequence);

            const lsKey = getColumnSettingsLsKey(match.path);
            localStorage.setItem(lsKey, columnsSequence.join(','));
        }
    };

    const sortColumn = useCallback((column) => {
        let newDirection = DIRECTION.ASC;

        if (sortInfo.column === column.id) {
            newDirection =
                sortInfo.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
        }

        onSortPromotions(column, newDirection);
        setSortInfo({
            column: column.id,
            direction: newDirection,
        });
    }, [sortInfo, list])

    setTimeout(() => {
        document.querySelector('.app-table-wrapper')?.scrollTo({top:0});
    },200);

    const tableStyles = {
        display: 'inline-table',
        marginBottom: '0',
    };

    const handleToggle = (index) => {
        activeRow === index ? setActiveRow(null) : setActiveRow(index)
    }

    return (
        <>
            <Table className="table responsiveTable responsiveTable__promo" style={tableStyles}>
                <thead style={{top: '-1px'}}>
                <tr className="text-center">
                    {columns.map(item =>
                        <th
                            className={`text-nowrap pointer`}
                            onClick={() => {
                                sortColumn(item);
                            }}
                            key={item.label}
                        >
                            {item.label}
                            <span
                                className={clsx(
                                    'direction__arrow',
                                    {
                                        'is-show': sortInfo.column === item.id,
                                    },
                                    {
                                        'is-rotate':
                                            sortInfo.direction === DIRECTION.ASC,
                                    }
                                )}
                            />
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {list?.map((item, index) => (
                    <tr
                        key={item.id}
                        className={clsx("text-center", {open: activeRow === index})}
                        onClick={() => handleToggle(index)}
                    >
                        {activeColumns.map((column) => (
                            <TdElem
                                key={`${column}_${index}`}
                                item={item}
                                field={column}
                                timeFormat={timeFormat}
                            />
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>

            {isShowTableSettings &&
                <TableSettingsModal
                    onHide={onCloseTableSettings}
                    onConfirm={handleChangeColumns}
                    activeColumns={activeColumns}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    popupTitle={i18n.t('crm.tableSettings')}
                    translatesObj={KEY_I18N_MAP}
                />
            }
        </>
    )
}

export default memo(withRouter(SportPromotionsTable));