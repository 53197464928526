import React from 'react';
import PropTypes from 'prop-types';
import InfoBlockHeader from './InfoBlockHeader';

const BlockWrapper = ({ title, srcIcon, className, children }) => {
    return (
        <div className={`statistics-info-block ${className ? className : ''}`}>
            <InfoBlockHeader title={title} srcIcon={srcIcon} />

            <div className="statistics-info-block__content">{children}</div>
        </div>
    );
};

BlockWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    srcIcon: PropTypes.any.isRequired,
    className: PropTypes.string
};

export default React.memo(BlockWrapper);
