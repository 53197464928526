import { GET_STATES_AND_CITIES } from './actionTypes';

import { takeEvery, call, put } from '@redux-saga/core/effects';
import { getStatesAndApi } from '../../helpers/backend_helper';
import {getStatesAndCitiesError, getStatesAndCitiesSuccess} from './actions';

export function* getStatesAndCitiesSaga() {
    try {
        const response = yield call(getStatesAndApi);

        if (response && response.status === 200) {
            yield put(getStatesAndCitiesSuccess(response.data));
        } else {
            yield put(getStatesAndCitiesError({ message: 'Cannot get states and cities of Mexico' }));
        }
    } catch (error) {
        yield put(getStatesAndCitiesError({ message: error }));
    }
}

function* statesAndCitiesSaga() {
    yield takeEvery(GET_STATES_AND_CITIES, getStatesAndCitiesSaga);
}

export default statesAndCitiesSaga;
