export const A_COMMON_DATA_FIELD = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    DATE_TIME: 'date_time',
    CHECK_BOX: 'check-box',
    TOGGLE: 'toggle',
    SELECT_DOCUMENT_TYPES: 'select-document-types',
    SELECT_DOCUMENT_STATUSES: 'select-document-statuses',
    SELECT_DOCUMENT_SIDES: 'select-document-sides',
    SELECT_IDENTIFICATION_TYPE: 'select-identification-types',
    SELECT_GENDER: 'select-gender',
    SELECT_PROFESSION: 'select-profession',
    SELECT_COUNTRIES: 'select-countries',
    SELECT_STATES: 'select-states',
    SELECT_CITIES: 'select-cities',
    SELECT_LANGUAGES: 'select-languages',
    SELECT_PLAYER_ACCOUNT_TYPE: 'select-player-account-type',
    SELECT_MULTI_CURRENCIES: 'select-multi-currencies',
    SELECT_CURRENCIES: 'select-currencies',
    SELECT_DEFAULT: 'select-default',
    SELECT_PAYMENT_AGENT_TYPE: 'select-agent-payment-type',
    SELECT_VIP_LEVEL: 'select-vip-level',
    SELECT_AGENT_OWNER: 'select-agent-owner',
    MULTI_SELECT_AGENT_OWNER: 'multi-select-agent-owner',
    SELECT_EXPERIENCE_LEVEL: 'select-experience-level',
    SELECT_MULTI_COUNTRIES: 'select-multi-countries',
    SELECT_CATEGORISATION: 'select-categorisation',
    SELECT_PRODUCT: 'select-product',
    SELECT_BANK: 'select-bank',
    KYC_REQUIRED: 'select-kyc-required',
    BOOLEAN: 'boolean',
    PASSWORD: 'password',
    SELECT_REQUIRE_FIELDS: 'select-require-fields',
    SELECT_PAYMENT_SUPPLIERS: 'select-payment-suppliers',
    SELECT_PAYMENT_METHODS: 'select-payment-methods',
    SELECT_CASHIER_BOOLEAN: 'select-cashier-boolean',
    MULTI_SELECT_CASHIER_PAYMENT_METHODS: 'multi-select-cashier-payment-methods',
    MULTI_SELECT_PROMOTIONS_TEMPLATE: 'multi-select-promos-template',
};
