import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DepositsTabContent from "./DepositsTabContent";
import WithdrawalsTabContent from "./WithdrawalsTabContent";
import PropTypes from "prop-types";
import {clearCurrentFilter} from "../../../store/filters/action";
import {useDispatch, useSelector} from "react-redux";
import {fetchBrandSettingsPage} from "../../../store/brand/brandPage/actions";
import useHashNavigation from "../../../hooks/useHashNavigation";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

const TABS = {
    DEPOSITS: 'DEPOSITS',
    WITHDRAWALS: 'WITHDRAWALS',
};

function FinanceTabHeader({ activeTab, onChange }) {
    const { i18n } = useTranslation();
    return (
        <ul className="d-flex sub-content__tab">
            {Object.keys(TABS).map(key => {
                return (
                    <li
                        key={key}
                        className={activeTab === key ? 'active' : ''}
                        onClick={() => onChange(key)}
                    >
                        {i18n.t(`crm.${key}`)}
                    </li>
                );
            })}
        </ul>
    );
}

function FinanceTabContent(props) {
    const dispatch = useDispatch();
    const { playerId, playerModel } = props;
    const [activeTab, setActiveTab] = useState(TABS.DEPOSITS);
    const [brandCurrencies, setBrandCurrencies] = useState([]);
    const location = useLocation();
    const { changeHash } = useHashNavigation();
    const parseUrlSearch = () => {
        return queryString.parse(location.search)
    }

    const { active: activeBrand } = useSelector(state => state.Brand);
    const { data: brandData } = useSelector(state => state.BrandSettingsPage);

    useEffect(() => {
        if (brandData) {
            setBrandCurrencies(brandData?.configurationFinancial.currencies || []);
        }
    }, [brandData]);

    useEffect(() => {
        dispatch(fetchBrandSettingsPage());
    }, [activeBrand]);

    const changeActiveTab = (tabName) => {
        dispatch(clearCurrentFilter());
        setActiveTab(tabName);
    }

    useEffect(() => {
        if (parseUrlSearch().tab === 'FINANCE') {
            if (activeTab === TABS.DEPOSITS) {
                changeHash(TABS.DEPOSITS)
            }
            if (activeTab === TABS.WITHDRAWALS) {
                changeHash(TABS.WITHDRAWALS)
            }
        }
    }, [activeTab]);

    return (
        <div className="detailed-card__content game-play">
            <div className="tab-wrapper">
                <FinanceTabHeader activeTab={activeTab} onChange={changeActiveTab} />
            </div>

            {activeTab === TABS.DEPOSITS && (
                <DepositsTabContent
                    playerId={playerId}
                    activeTab={activeTab}
                    brandCurrencies={brandCurrencies}
                />
            )}

            {activeTab === TABS.WITHDRAWALS && (
                <WithdrawalsTabContent
                    playerId={playerId}
                    activeTab={activeTab}
                    brandCurrencies={brandCurrencies}
                />
            )}
        </div>
    );
}

FinanceTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(FinanceTabContent);
