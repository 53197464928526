import React from 'react';
import PropTypes from 'prop-types';

import './radio-input.scss';

function RadioInput({
    checked,
    value,
    disabled,
    onChange,
    className,
    noSpacesAfterChildren,
    noSpacesBeforeChildren,
    children,
    ...props
}) {
    const radioClassName = `app-radio ${className ? ` ${className}` : ''}${
        disabled ? ' disabled' : ''
    }`;

    return (
        <label className={radioClassName}>
            {children && !noSpacesBeforeChildren && '\xa0\xa0\xa0'}
            {children}
            {children && !noSpacesAfterChildren && '\xa0\xa0\xa0\xa0\xa0'}

            <input
                {...props}
                type="radio"
                value={value}
                checked={checked || false}
                onChange={e => onChange && onChange(e.target.value)}
                disabled={disabled}
            />
            <span className="checkmark" />
        </label>
    );
}

RadioInput.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    noSpacesAfterChildren: PropTypes.bool,
    noSpacesBeforeChildren: PropTypes.bool
};

export default React.memo(RadioInput);
