
const getStatisticsApi = () => {
    return new Promise(resolve => {
        resolve({
            data: {
                totalLeads: 289,
                loggedInToday: 25,
                apmentsToday: 4,
                FTD: '12',
                deposits: '$1,200',
                saleCR: '48%',
                average: '$100',

                grossDeposits: '$4,600',
                withdrawals: '$100',
                netDeposits: '$4,600',
                volume: '$130K',
                totalClients: '318',
                loggedIn: '25',
                marginCalls: '3',
                deposited: '13',
                appointments: '4',
                traded: '32',
                widgets: {

                }
            }
        });
    });
};

export {
    getStatisticsApi,
};
