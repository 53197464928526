import {
    GET_PLAYER_WITHDRAWAL_LIST,
    GET_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    GET_PLAYER_WITHDRAWAL_LIST_FAIL,
    RESET_PLAYER_WITHDRAWAL_ERRORS
} from './actionTypes';

export const getPlayerWithdrawalList = payload => ({
    type: GET_PLAYER_WITHDRAWAL_LIST,
    payload
});

export const getPlayerWithdrawalListSuccess = payload => ({
    type: GET_PLAYER_WITHDRAWAL_LIST_SUCCESS,
    payload
});

export const getPlayerWithdrawalListFail = payload => ({
    type: GET_PLAYER_WITHDRAWAL_LIST_FAIL,
    payload
});

export const resetPlayerWithdrawalErrors = payload => ({
    type: RESET_PLAYER_WITHDRAWAL_ERRORS,
    payload
});
