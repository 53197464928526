export const condition = (label, searchText) => {
  const words = label.toLowerCase().split(' ')
  let flag = false;
  for (const word of words) {
    if (word.startsWith(searchText.toLowerCase())) {
      flag = true
      break;
    }
  }
  return flag;
};
