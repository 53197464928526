import {
    GET_AGENTS_LIST,
    GET_AGENTS_LIST_SUCCESS,
    GET_AGENTS_LIST_FAIL,

    GET_ROLES_LIST,
    GET_ROLES_LIST_SUCCESS,
    GET_ROLES_LIST_FAIL,

    CREATE_AGENT,
    CREATE_AGENT_SUCCESS,
    CREATE_AGENT_FAIL,

    CHANGE_AGENT_PASSWORD,
    CHANGE_AGENT_PASSWORD_SUCCESS,
    CHANGE_AGENT_PASSWORD_FAIL,

    RESET_AGENTS_ERROR,
} from './actionTypes';

export const getAgentsList = payload => ({
    type: GET_AGENTS_LIST,
    payload
});

export const getAgentsListSuccess = payload => ({
    type: GET_AGENTS_LIST_SUCCESS,
    payload
});

export const getAgentsListFail = error => ({
    type: GET_AGENTS_LIST_FAIL,
    payload: error
});

export const getRolesList = payload => ({
    type: GET_ROLES_LIST,
    payload
});

export const getRolesListSuccess = payload => ({
    type: GET_ROLES_LIST_SUCCESS,
    payload
});

export const getRolesListFail = error => ({
    type: GET_ROLES_LIST_FAIL,
    payload: error
});

export const createAgent = payload => ({
    type: CREATE_AGENT,
    payload
});

export const createAgentSuccess = () => ({
    type: CREATE_AGENT_SUCCESS,
});

export const createAgentFail = error => ({
    type: CREATE_AGENT_FAIL,
    payload: error
});

export const changeAgentPassword = payload => ({
    type: CHANGE_AGENT_PASSWORD,
    payload
});

export const changeAgentPasswordSuccess = () => ({
    type: CHANGE_AGENT_PASSWORD_SUCCESS,
});

export const changeAgentPasswordFail = error => ({
    type: CHANGE_AGENT_PASSWORD_FAIL,
    payload: error
});

export const resetAgentsError = () => ({
    type: RESET_AGENTS_ERROR,
});
