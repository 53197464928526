import {
    GET_WITHDRAWAL_LIST,
    GET_WITHDRAWAL_LIST_SUCCESS,
    GET_WITHDRAWAL_LIST_FAIL,
    RESET_WITHDRAWAL_LIST_ALERTS_ERRORS,
    DOWNLOAD_WITHDRAWAL_LIST,
    DOWNLOAD_WITHDRAWAL_LIST_SUCCESS,
    DOWNLOAD_WITHDRAWAL_LIST_FAIL,
    GET_WITHDRAWAL_COUNT,
    GET_WITHDRAWAL_COUNT_SUCCESS,
    GET_WITHDRAWAL_COUNT_FAIL,
} from './actionTypes';

export const getWithdrawalListCount = payload => ({
    type: GET_WITHDRAWAL_COUNT,
    payload
});

export const getWithdrawalListCountSuccess = payload => ({
    type: GET_WITHDRAWAL_COUNT_SUCCESS,
    payload
});

export const getWithdrawalListCountFail = payload => ({
    type: GET_WITHDRAWAL_COUNT_FAIL,
    payload
});

export const getWithdrawalList = payload => ({
    type: GET_WITHDRAWAL_LIST,
    payload
});

export const getWithdrawalListSuccess = payload => ({
  type: GET_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const getWithdrawalListFail = payload => ({
  type: GET_WITHDRAWAL_LIST_FAIL,
  payload
});

export const resetWithdrawalListAlertsErrors = payload => ({
  type: RESET_WITHDRAWAL_LIST_ALERTS_ERRORS,
  payload
});

export const downloadWithdrawalList = payload => ({
  type: DOWNLOAD_WITHDRAWAL_LIST,
  payload
});

export const downloadWithdrawalListSuccess = payload => ({
  type: DOWNLOAD_WITHDRAWAL_LIST_SUCCESS,
  payload
});

export const downloadWithdrawalListFail = payload => ({
  type: DOWNLOAD_WITHDRAWAL_LIST_FAIL,
  payload
});
