import React, {useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';

import i18n from '../../i18n';

import "./styles.scss";
import {withRouter} from "react-router-dom";
import ManageUser from "./components/ManageUser";
import Profile from "./components/Profile";
import {RouterPrompt} from "../../common/prompt/RouterPrompt";
import Finance from "./components/Finance";
import CurrentShift from "./components/CurrentShift";
import LastShift from "./components/LastShifts";
import {getSync} from "../../helpers/api_helper";
import {useSelector} from "react-redux";
import {SITE_BRAND} from "../../constants/siteBrands";

const Retail = () => {
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditFinance, setIsEditFinance] = useState(false);
    const [shiftHistory, setShiftHistory] = useState(undefined);
    const [shiftTransactions, setShiftTransactions] = useState(undefined);
    const { active: activeBrand } = useSelector(state => state.Brand);

    const getShiftHistory = async () => {
        const url = `/retail/mx/shift/history`;
        const result = await getSync(url, {});
        setShiftHistory(result.data);
    };

    const getShiftTransactions = async () => {
        const url = `/retail/mx/shift/transactions`;
        const result = await getSync(url, {});
        setShiftTransactions(result.data);
    };

    useEffect(() => {
        if(agentSelf?.department) {
            getShiftTransactions();
            getShiftHistory();
        }
    }, [agentSelf]);

    useEffect(() => {
        if (isEditFinance) {
            getShiftTransactions();
            getShiftHistory();
        } else {
            setIsEditFinance(false)
        }
    }, [isEditFinance])



    return (
    <div className="player-list players-list__mobile document-content page-content">
        <MetaTags>
            <title>{i18n.t("retail.title")} - {process.env.REACT_APP_APP_NAME}</title>
        </MetaTags>

        <div className="container-fluid">
            {activeBrand?.siteName === SITE_BRAND.WINPOTMX && <ManageUser/> }
            {activeBrand?.siteName === SITE_BRAND.WINPOTMX && <Finance setIsEdit={setIsEditFinance} /> }
            <Profile isCreatMode isEdit={isEdit} setIsEdit={setIsEdit}  />
            {activeBrand?.siteName === SITE_BRAND.WINPOTMX && <CurrentShift shiftTransactions={shiftTransactions} setIsEditFinance={setIsEditFinance} /> }
            {activeBrand?.siteName === SITE_BRAND.WINPOTMX && <LastShift shiftHistory={shiftHistory} setIsEditFinance={setIsEditFinance} /> }
        </div>
        {
            isEdit && <RouterPrompt
                when={isEdit}
                onOK={() => true}
                onCancel={() => false}
            />
        }
    </div>
  )
};

export default withRouter(Retail);
