import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../../common/components/Breadcrumb';
import mockData from './attribution-mock.json';
import i18n from '../../i18n';
import AttributionCard from './AttributionCard';

class AttributionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: mockData
        };
    }

    render() {
        const { data } = this.state;

        return (
            <div className="page-content attribution-page">
                <Container fluid>
                    <Breadcrumb
                        breadcrumbItem={i18n.t('attributions.attributionView')}
                        title={i18n.t('attributions.playerAttribution')}
                        titleLink="/audience"
                    />

                    <Row>
                        <Col lg="12">
                            <AttributionCard data={data} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(AttributionPage);
