import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CustomPromptModal from './CustomPromptModal';
import {useLocation} from "react-router-dom";

export function RouterPrompt(props) {
    const { when, onOK, onCancel } = props;

    const location = useLocation();
    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState({});

    useEffect(() => {
        if (when) {
            history.block(prompt => {
                setCurrentPath({ ...prompt });
                if (location.pathname !== prompt.pathname) {
                    setShowPrompt(true);
                }
                return 'true';
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath.pathname, { ...currentPath?.state });
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            } else {
                setCurrentPath('');
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <CustomPromptModal onCancel={handleCancel} onConfirm={handleOK} />
    ) : null;
}
