const occupationOptions = [
    'employee',
    'noEconomicActivity',
    'student',
    'independentProfessional',
    'entrepreneur',
    'lessee',
    'retiredOrPensioned',
    'publicServerOrPolitician'
];

export default occupationOptions
