import React, { useMemo } from "react"
import PropTypes from "prop-types"
import SelectInput from "../../../common/inputs/common/SelectInput"
import i18n from "../../../i18n"
import { REWARD_TYPE } from "./promotionConstants"


function RewardSelect({ onChange, value, ...props }) {
  const options = useMemo(() => Object.keys(REWARD_TYPE).
    map(item => ({ label: i18n.t(`promotions.constants.${item}`), value: item })),[]);

  return (
    <SelectInput
      {...props}
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

RewardSelect.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(RewardSelect);
