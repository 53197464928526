import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import {Link, useHistory, withRouter} from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import { apiError } from '../../store/actions';
import useAlertService from '../../hooks/useAlertService';
import i18n from '../../i18n';

import '../../assets/scss/pages/login.scss';
import { DATACENTER } from "../../common/constants/common";
import { removeXSiteIdFromStorages } from '../../common/utils/common';

import { postSync } from "../../helpers/api_helper";
import {AvFieldValidationEmail} from "../../utils/validation/schemas";
import {EMAIL_REG_EXP} from "../../constants/validations";


const initialState = {
    email: '',
};

const ForgotPasswordPage = ({ apiError, error}) => {
    const {loading} = useSelector(state => state.Login);
    const history = useHistory();

    const { showError, showSuccess } = useAlertService();
    const [state, setState] = useState(initialState);
    const [isShowAnimate, setShowAnimate] = useState(false);
    const [isHidePage, setHidePage] = useState(false);

    const handleValidSubmit = async () => {
        const url = `/agent/forgot-password`;

        try {
            const result = await postSync(url, { email: state.email });
            const {success} = result;
            if (success) {
                showSuccess(i18n.t('server.sent.email'));
                setTimeout(() => {
                    history.push(`/login`);
                }, 1000)
            }
        } catch (e) {
            showError(i18n.t('server.agentNotFound'));
            console.log(e);
        }
    };

    useEffect(() => {
        setHidePage(loading);
    }, [loading]);

    useEffect(() => {
        removeXSiteIdFromStorages();
    }, []);

    const handleChangeEmail = event => {
        setState({email: event.target.value});
    };

    const fields = useMemo(
        () => [
            {
                name: 'email',
                value: state.email,
                errorMessage: i18n.t('authentication.emailInvalid'),
                placeholder: i18n.t('authentication.enterEmail'),
                onChange: handleChangeEmail,
                type: 'text',
                className: 'login-page__content-form__field-input',
                label: i18n.t('authentication.email'),
                validate: AvFieldValidationEmail(i18n)
            },
        ],
        []
    );

    useEffect(() => {
        apiError('');

        // so that the animation has time to play
        setTimeout(() => {
            setShowAnimate(true);
        }, 1000);
    }, []);

    useEffect(() => {
        if (error) {
            error.map(item => {
                showError(item.message);
            });
        }
    }, [error]);

    const isDisabled = useMemo(() => {
        return !EMAIL_REG_EXP.test(state.email)
    }, [state.email])

    return (
        <div
            className={`login-page wiztech-bg${
                isShowAnimate ? ' wiztech-bg__showed' : ''
            }`}
        >
            <div
                className={`login-page__content${
                    isShowAnimate ? ' login-page__content__showed' : ''
                }${isHidePage ? ' login-page__content__hided' : ''}`}
            >
                <div className={'login-page__content-text'}>
                    <h5>Forgot Password</h5>
                    <p>{DATACENTER}</p>
                </div>

                <AvForm className={'login-page__content-form'}>
                    {fields.map(({type, label, ...props}, index) => (
                        <Fragment key={index}>
                            {type === 'checkbox' ? (
                                <AvField
                                    type={'checkbox'}
                                    label={label}
                                    {...props}
                                />
                            ) : (
                                <div className="login-page__content-form__field">
                                    <label className="login-page__content-form__field-label">
                                        {label}
                                    </label>

                                    <AvField type={type}
                                             {...props}
                                    />
                                </div>
                            )}
                        </Fragment>
                    ))}

                    <button
                        className="btn btn-primary shadow-none"
                        type="submit"
                        disabled={isDisabled}
                        onClick={handleValidSubmit}
                    >
                        {i18n.t('crm.submit')}
                    </button>

                    <Link to={`/login`}>
                        <div
                            className="login-link"
                        >
                            {i18n.t('authentication.Login')}
                        </div>
                    </Link>
                </AvForm>
            </div>

            <footer>© {new Date().getFullYear()} {process.env.REACT_APP_APP_NAME}</footer>
        </div>
    );
};

ForgotPasswordPage.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
};

const mapStateToProps = state => {
    const {error} = state.Login;
    return {error};
};

export default withRouter(
    connect(mapStateToProps, {
        apiError
    })(ForgotPasswordPage)
);
