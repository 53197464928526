import React from 'react';
import PropTypes from 'prop-types';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import close from '../../assets/images/common/close 1.svg';
import i18n from '../../i18n';
import i_51 from '../../assets/images/common/Frame 176 (1).svg';
import useHandleEnter from '../../common/hooks/useHandleEnter';

import './app-buttons.scss';
import group_4752 from '../../assets/images/common/Group 4752.svg';
import clsx from "clsx";

function SaveCancelButtons(props) {
    const {
        onCancel,
        onSave,
        isDisabledCancel,
        isDisabledSave,
        onEdit,
        isEdit,
        isModalButtons,
        cancelBtnText,
        saveBtnText,
    } = props;
    useHandleEsc(onCancel);
    useHandleEnter(onSave);

    if (onEdit) {
        return (
            <div className="app-buttons-panel">
                <button
                    className="btn btn-rounded btn-light me-2"
                    style={{ display: isEdit ? 'inline-block' : 'none' }}
                    onClick={onCancel}
                    disabled={isDisabledCancel}
                >
                    <img className="me-2" src={close} alt="" />
                    {i18n.t('admin.cancel')}
                </button>

                <button
                    className="btn btn-rounded btn-primary"
                    style={{ display: isEdit ? 'inline-block' : 'none' }}
                    onClick={onSave}
                    disabled={isDisabledSave}
                >
                    <img src={i_51} alt="" />
                    {i18n.t('admin.save')}
                </button>

                <button
                    className="btn btn-rounded btn-primary"
                    style={{ display: isEdit ? 'none' : 'block' }}
                    onClick={() => onEdit(true)}
                >
                    <img className="me-2" src={group_4752} alt="" />
                    {i18n.t('common.edit')}
                </button>
            </div>
        );
    }

    return (
        <div
            className={clsx("app-buttons-panel", {
                "modal-buttons-panel": isModalButtons,
            })}
            style={{ display: isEdit
                    ? 'block'
                    : isModalButtons
                        ? 'flex'
                        : 'none'
            }}
        >
            <button
                className="btn btn-rounded btn-light me-2"
                onClick={onCancel}
                disabled={isDisabledCancel}
            >
                <img className="me-2" src={close} alt="" />
                {cancelBtnText || i18n.t('admin.cancel')}
            </button>

            <button
                className="btn btn-rounded btn-primary"
                onClick={onSave}
                disabled={isDisabledSave}
            >
                <img src={i_51} alt="" />
                {saveBtnText || i18n.t('admin.save')}
            </button>
        </div>
    );
}

SaveCancelButtons.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    isEdit: PropTypes.bool,
    isDisabledCancel: PropTypes.bool,
    isDisabledSave: PropTypes.bool,
    isModalButtons: PropTypes.bool,
    cancelBtnText: PropTypes.bool,
    saveBtnText: PropTypes.bool,
};

export default React.memo(SaveCancelButtons);
