import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {postSync} from "../../../helpers/api_helper";
import {DATACENTER_CODE, DATACENTER_CODE_TYPE} from "../../../common/constants/common";

function WalletTabContent({ playerId }) {
    const [url, setUrl] = useState();
    useEffect(async () => {
        let walletDashboardId = 0;
        switch (DATACENTER_CODE) {
            case DATACENTER_CODE_TYPE.wiz_sp:
                walletDashboardId = 360;
                break;
            case DATACENTER_CODE_TYPE.ds_ir:
                walletDashboardId = 8;
                break;
            default:
                walletDashboardId = 0;
        }

        const url = `/dashboard/dashboard-url`;
        const result = await postSync(url, {
            id: walletDashboardId.toString(),
            params: {
                player: [
                    playerId
                ]
            }
        });

        let _url = result?.url;
        _url = _url.replace('titled=true', 'titled=false');
        _url = _url.replace('bordered=true', 'bordered=false');
        setUrl(_url);
    }, [])

    return (
        <>
            {url &&
                <iframe
                    style={{width: '100%', height: '100vh'}}
                    src={url}
                    frameBorder="0"
                    allowTransparency
                />
            }
        </>
    );
}

WalletTabContent.propTypes = {
    playerId: PropTypes.string.isRequired
};

export default React.memo(WalletTabContent);
