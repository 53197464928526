import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_AGENT } from './actionTypes';
import { getAgentSuccess, getAgentFail } from './actions';

function* fetchAgentSaga({ payload }) {
    try {
        const response = {
            data: {
                id: '123e4567-e89b-12d3-a456-426614174000',
                status: 'Active',
                role: 'MANAGER',

                firstName: 'Porfyrios',
                lastName: 'Kourkoutas',
                birthDate: '1988-08-10T03:24:00',
                email: 'email@email.com',
                mobile: '+1 212 121 12121',
                phoneExtension: '7270',

                startDate: '1988-08-10T03:24:00',
                payrollID: 'PI43234325523',
                lastLogin: '1988-08-10T03:24:00',
                nationality: 'DE',
                manager: 'Eleana Michael'
            }
        };

        yield put(getAgentSuccess(response.data));
    } catch (error) {
        yield put(getAgentFail(error));
    }
}

function* agentSaga() {
    yield takeEvery(GET_AGENT, fetchAgentSaga);
}

export default agentSaga;
