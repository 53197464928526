import {useHistory} from "react-router-dom";

const useHashNavigation = () => {
    const history = useHistory();


    const changeQuery = (query) => {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.set('tab', query);

        history.replace({
            search: urlSearchParams.toString(),
        });
    }

    const changeHash = (hash) => {
        const urlSearchParams = new URLSearchParams(history.location.search);

        urlSearchParams.delete('subTab');
        urlSearchParams.set('subTab', hash);

        history.replace({
            search: urlSearchParams.toString(),
        });
    }

    const removeSubTabAndSessionId = () => {
        const urlSearchParams = new URLSearchParams(history.location.search);

        urlSearchParams.delete('subTab');
        urlSearchParams.delete('sessionId');


        history.replace({
            search: urlSearchParams.toString(),
        });
    }

    const removeUrlSearch = (queries = []) => {
        const r = new URL(location.href);

        if (queries.length) {
            queries.forEach(q => r.searchParams.delete(q))
        } else {
            r.search = "";
        }

        window.history.replaceState(null, null, r.href);
    }

    return {
        changeHash,
        changeQuery,
        removeSubTabAndSessionId,
        removeUrlSearch
    }
}


export default useHashNavigation;