import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Formatter from "../../utils/Formatter";
import {COUNTRY_FLAG_CDN} from "../../constants/siteBrands";

const FlagCountry = ({ countryCode, className }) => {
    const [isHovered, setIsHovered] = useState(false);

    let country = 'MX';
    if(countryCode.length > 0) {
        // find the value of country from countryCode when value is not undefined or "--" and not ""
        country = countryCode.find((country) => country !== "--" && country !== "" && country !== undefined);
    }

    return (
        <div
            className={className}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {!!country ? (
                <>
                    <img src={COUNTRY_FLAG_CDN(country)} alt=""/>
                    {isHovered &&
                        <span className='hovered-country'>
                            {Formatter.getCountryNameByCode(country)}
                        </span>
                    }
                </>
            ) : (
                <></>
            )}
        </div>
    );
};


FlagCountry.propTypes = {
    countryCode: PropTypes.string,
    className: PropTypes.string
};

export default React.memo(FlagCountry);
