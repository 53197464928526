import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SaveFilterModal from '../../../pages/FilterPage/_elements/SaveFilterModal';
import { FilterMenuSelect } from './FilterMenuSelect';
import {
    FILTER_MENU_SELECT_LIST_VALUES,
    FILTER_POPUPS,
    FILTER_SIMPLE_BOOL_COMMON_OPTIONS,
    INITIAL_FILTER_POPUP
} from '../../../constants/Filter';
import { FilterSimple } from './FilterSimple';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterExcel } from './FilterExcel';
import { FilterDateRange } from './DateRangeFilter';
import { FilterSearchAndCheckbox } from './FilterSearchAndCheckbox';
import { addCurrentFilter, editCurrentFilter, saveFilter } from '../../../store/filters/action';
import { getType } from '../../../pages/Admin/site/dataAdapters';
import { TextPopup } from './TextPopup';
import { SelectPopup } from './SelectPopup';
import {useTranslation} from "react-i18next";
import { TextArrayPopup } from "./TextArrayPopup";

export const getObjectWithoutDuplicates = array => {
    const resultObject = {};

    array.forEach(element => {
        if (resultObject.hasOwnProperty(element.key)) {
            resultObject[element.key] =
                getType(resultObject[element.key]) === 'array'
                    ? [...resultObject[element.key], element.value]
                    : [resultObject[element.key], element.value];
        } else {
            resultObject[element.key] = element.value;
        }
    });

    return resultObject;
};

const FilterModalsAndPopaps = ({
    category,
    activePopup,
    setActivePopup,
    isAddNewFilter,
    isSaveModalShow,
    onSaveModalHide,
    setAddNewFilter,
    filterListOptions,
    onOpenNewFilter,
    activeTab
}) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { currentFilter } = useSelector(state => state.Filter);
    const {
        active: activeBrand,
    } = useSelector(state => state.Brand);

    const onPopupChange = useCallback(
        value => {
            const { idx, isEditMode } = activePopup;

            if (isEditMode) {
                dispatch(editCurrentFilter(idx, value));
            }
            else {
                dispatch(addCurrentFilter(value));
            }

            setActivePopup(INITIAL_FILTER_POPUP);
        },
        [setActivePopup, currentFilter, activePopup]
    );

    const onSelectAtFilterList = useCallback(option => {
        setActivePopup({
            activeModal: option.modal,
            title: option.title || option.label,
            key: option.value,
            data: null,
            list: option.list || undefined,
            initialValue: option.initialValue || undefined
        });
        setAddNewFilter(false);
    }, []);

    const onSaveFilter = useCallback(
        name => {
            const result = {
                name,
                siteId: activeBrand?.id,
                category,
                filterDynamic: currentFilter
            };

            dispatch(saveFilter(result));
            onSaveModalHide();
        },
        [currentFilter]
    );

    const handleCancel = useCallback(() => {
        setActivePopup({
            data: null,
            activeModal: '',
            key: null,
            title: ''
        });
        onOpenNewFilter();
    }, [onOpenNewFilter]);

    return (
        <>
            {isSaveModalShow && (
                <SaveFilterModal
                    onSave={onSaveFilter}
                    onHide={onSaveModalHide}
                />
            )}

            {isAddNewFilter && (
                <FilterMenuSelect
                    onSelect={onSelectAtFilterList}
                    list={filterListOptions}
                    activeTab={activeTab}
                />
            )}

            {(activePopup.activeModal === FILTER_POPUPS.STARTS_WITH ||
                activePopup.activeModal === FILTER_POPUPS.CONTAINS ||
                activePopup.activeModal === FILTER_POPUPS.EQUAL) && (
                <TextPopup
                    label={i18n.t(`filter.${activePopup.activeModal}`)}
                    onSave={onPopupChange}
                    title={activePopup.title}
                    popupKey={activePopup.key}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.SIMPLE_SELECT && (
                <SelectPopup
                    onSave={onPopupChange}
                    title={activePopup.title}
                    options={activePopup.list}
                    popupKey={activePopup.key}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.SIMPLE_BOOL && (
                <FilterSimple
                    onSelect={onPopupChange}
                    title={activePopup.title}
                    list={FILTER_SIMPLE_BOOL_COMMON_OPTIONS}
                    popupKey={activePopup.key}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.CHECKBOX && (
                <FilterCheckbox
                    onSelect={onPopupChange}
                    activePopup={activePopup}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.EXCEL && (
                <FilterExcel
                    onSave={onPopupChange}
                    title={activePopup.title}
                    popupKey={activePopup.key}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.DATE_RANGE && (
                <FilterDateRange
                    onChange={onPopupChange}
                    title={activePopup.title}
                    activePopup={activePopup}
                    popupKey={activePopup.key}
                    onCancel={handleCancel}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.CHECKBOX_AND_SEARCH && (
                <FilterSearchAndCheckbox
                    onChange={onPopupChange}
                    activePopup={activePopup}
                    onCancel={handleCancel}
                    activeTab={activeTab}
                    initialValue={activePopup.initialValue}
                />
            )}

            {activePopup.activeModal === FILTER_POPUPS.PROMOTION_MODAL && (
                <>
                    <FilterSearchAndCheckbox
                        onChange={onPopupChange}
                        activePopup={activePopup}
                        onCancel={handleCancel}
                    />
                    <FilterDateRange
                        onChange={onPopupChange}
                        title={activePopup.title}
                        popupKey={activePopup.key}
                        onCancel={handleCancel}
                        activePopup={activePopup}
                    />
                </>
            )}

            {activePopup.activeModal === FILTER_POPUPS.LIST && (
                <TextArrayPopup
                    label={i18n.t(`filter.${activePopup.activeModal}`)}
                    onSave={onPopupChange}
                    title={activePopup.title}
                    popupKey={activePopup.key}
                    initialValue={Array.isArray(activePopup.initialValue)
                        ? activePopup.initialValue.join(' ')
                        : activePopup.initialValue || ''}
                    onCancel={handleCancel}
                    isDigit={activePopup.key === FILTER_MENU_SELECT_LIST_VALUES.OID}
                />
            )}
        </>
    );
};

FilterModalsAndPopaps.propTypes = {
    category: PropTypes.string.isRequired,
    activePopup: PropTypes.object.isRequired,
    setActivePopup: PropTypes.func.isRequired,
    isAddNewFilter: PropTypes.bool.isRequired,
    onSaveModalHide: PropTypes.func.isRequired,
    isSaveModalShow: PropTypes.bool,
    setAddNewFilter: PropTypes.func.isRequired,
    filterListOptions: PropTypes.array.isRequired,
    onOpenNewFilter: PropTypes.func.isRequired
};

export default React.memo(FilterModalsAndPopaps);
