import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useHistory } from 'react-router-dom';

import i18n from '../../i18n';

import './style.scss';
import { logoutUser } from '../../store/auth/login/actions';
import { useDispatch, useSelector } from 'react-redux';
import vector_103 from '../../assets/images/common/Vector 103.svg';
import drop from '../../assets/images/common/Drop.svg';
import useClickOutside from '../../common/hooks/useClickOutside';
import useHandleEsc from '../../common/hooks/useHandleEsc';
import { AGENT_ACTIVITY_ACCOUNT_STATUS } from '../../constants/status';
import {
    getAgentSelf,
    getRolesList,
    resetAgentSelfAlertsErrors,
    updateAgentSelfStatus
} from '../../store/actions';
import useAlertService from '../../hooks/useAlertService';
import { clearCurrentFilter } from "../../store/filters/action";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ActiveUsers from "../CommonForBoth/ActiveUsers";
import ToolbarTime from "../CommonForBoth/ToolbarTime";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import { useTimeContext } from "../../context/TimeContext";
import SelectInput from "../../common/inputs/common/SelectInput";

export const AgentDropdown = (props) => {
    const {
        activeUsersCount,
        openActiveUsers,
    } = props;
    const areaRef = useRef();
    const alertService = useAlertService();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading, error, agentSelf } = useSelector(
        state => state.AgentSelf
    );
    const { active: activeBrand } = useSelector(state => state.Brand);
    const [isShowMenu, setShowMenu] = useState(false);
    const { rolesList } = useSelector(state => state.AgentList);
    const [roleName, setRoleName] = useState('');
    const timeContext = useTimeContext();

    const [activeStatus, setActiveStatus] = useState(
        agentSelf?.activityStatus || AGENT_ACTIVITY_ACCOUNT_STATUS.Active
    );

    useEffect(() => {
        dispatch(getRolesList());
    }, [activeBrand?.siteName]);

    const changeTimeFormat = (timeValue) => {
        timeContext.updateTimeFormat(timeValue);
    };

    useClickOutside(areaRef, () => setShowMenu(false));
    useHandleEsc(() => setShowMenu(false));

    useEffect(() => {
        dispatch(getAgentSelf());
    }, [isShowMenu]);

    useEffect(() => {
        setActiveStatus(
            agentSelf?.activityStatus || AGENT_ACTIVITY_ACCOUNT_STATUS.Active
        );
        const agentRoleObj = rolesList?.find(item => item.id === agentSelf?.roleId);
        if (agentRoleObj) {
            setRoleName(agentRoleObj.name)
        }
    }, [agentSelf, rolesList]);

    useEffect(() => {
        if (error) {
            alertService.showError(error?.message);
            dispatch(resetAgentSelfAlertsErrors());
        }
    }, [error]);

    const handleLogout = () => {
        dispatch(clearCurrentFilter());
        dispatch(logoutUser(history));
    }

    const handleChangeStatus = useCallback(
        activityStatus => {
            return () => {
                if (!isLoading) {
                    dispatch(updateAgentSelfStatus({ activityStatus }));
                    setShowMenu(false);
                }
            };
        },
        [isLoading]
    );

    const { initials, firstName, lastName } = useMemo(() => {
        const firstName = agentSelf?.firstName || ' ';
        const lastName = agentSelf?.lastName || ' ';
        const firstLetter = firstName.split('')[0];
        const secondLetter = lastName.split('')[0];

        return {
            initials: `${firstLetter.toUpperCase()} ${secondLetter.toUpperCase()}`,
            firstName,
            lastName
        };
    }, [agentSelf]);

    return (
        <div ref={areaRef} className="agent-dropdown">
            <div
                className="agent-dropdown__avatar"
                onClick={() => setShowMenu(true)}
            >
                {initials ? (
                    <div className="initials-wrap">{initials}</div>
                ) : (
                    <div className="avatar-img-wrap">
                        {/* TODO */}
                        {/*<img src={test} alt="test"/>*/}
                    </div>
                )}

                <div className={`status-mark ${activeStatus}`}>
                    <img src={drop} alt="vector" />
                </div>

                <div className="avatar-tooltip">
                    <img src={vector_103} alt="vector" />
                    {firstName}&nbsp;{lastName}
                </div>
            </div>

            {isShowMenu && (
                <div className="agent-dropdown__menu">
                    <div className="text-wrap d-flex align-items-center justify-content-between" style={{marginLeft: "9px"}}>
                        {i18n.t(`crm.${agentSelf?.agentGlobalPermission}.permissions`)}
                        <NotificationDropdown />
                    </div>

                    <div
                        className="menu-header"
                        onClick={() => history.push('/agent-view')}
                    >
                        {initials ? (
                            <div className="initials-wrap">{initials}</div>
                        ) : (
                            <div className="avatar-img-wrap">
                                {/* TODO */}
                                {/*<img src={test} alt="test"/>*/}
                            </div>
                        )}
                        <div className="menu-header__name text-wrap">
                            {firstName}&nbsp;{lastName}
                        </div>
                    </div>
                    <span className='menu-header__role_name_wrapper'>
                        <span className='role-name-label'>
                            {roleName || '[invalid name]'}
                        </span>
                    </span>
                    <span className='time-format-label'>
                            {i18n.t('common.changeTimeFormat')}
                    </span>
                    <div className='toggle-time-format'>
                        <br/>   <br/>
                        <SelectInput
                            options={timeContext.timeFormatList}
                            value={timeContext.timeFormat.timeValue}
                            onChange={changeTimeFormat}
                            className='time-format-dropdown'
                        />
                    </div>
                    <div className='tools-components'>
                        <LanguageDropdown />
                        <ActiveUsers
                            count={activeUsersCount}
                            className='active-users d-inline-block p-0 m-0'
                            onClick={openActiveUsers}
                        />
                        <ToolbarTime />
                    </div>
                    <div className="status-select">
                        {Object.values(AGENT_ACTIVITY_ACCOUNT_STATUS).map(
                            status => {
                                const isActive = status === activeStatus;

                                return (
                                    <div
                                        key={status}
                                        className={`status-select__item ${
                                            isActive ? 'active' : ''
                                        }`}
                                        onClick={handleChangeStatus(status)}
                                    >
                                        <div className="status-mark-wrap">
                                            <div
                                                className={`status-mark ${status}`}
                                            />
                                        </div>

                                        {i18n.t(
                                            `agent.constants.agentActivityAccountStatus.${status}`
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <div className="menu-footer">
                        <div className="log-out-button" onClick={handleLogout}>
                            {i18n.t('common.logOut')}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
