import React, {useEffect, useMemo, useState} from 'react';
import {Card, CardBody} from 'reactstrap';

import SectionTitle from './components/SectionTitle';
import Tracking from './components/Tracking';
import useAlertService from '../../hooks/useAlertService';

import iIconLight from '../../assets/images/layout/i-icon-light.svg';
import card from '../../assets/images/layout/card.svg';
import crypto from '../../assets/images/layout/crypto.svg';
import information from '../../assets/images/layout/grey-information.svg';
import amount from '../../assets/images/layout/amount.svg';

import './styles.scss';

import Formatter from '../../utils/Formatter';
import ACommonDataField from '../../components/GenericComponents/CommonDataField';
import PropTypes from 'prop-types';
import {A_COMMON_DATA_FIELD} from '../../constants/aCommonDataField';
import {useDispatch, useSelector} from 'react-redux';
import {getAgentsList} from '../../store/agents/agents/actions';
import ButtonsPanel from '../CRM/general/ButtonsPanel';
import { getSync, patchSync, postSync } from "../../helpers/api_helper"
import {RouterPrompt} from '../../common/prompt/RouterPrompt';
import ConfirmPopupModal from '../../utils/ConfirmPopup/ConfirmPopupModal';
import {PAYMENT_STATUS} from '../../constants/status';
import GenerateButton from "../../common/cellData/GenerateButton";
import {useTranslation} from "react-i18next";
import {SITE_PERMISSION} from "../../common/constants/common";
import clsx from "clsx";
import {useTimeContext} from "../../context/TimeContext";
import {SITE_BRAND} from "../../constants/siteBrands";
import Loader from "../../common/components/Loader";
import useWindowSize from "../../hooks/useWindowSize";
import {SCREEN_SIZES} from "../../utils/consts";
import {PAYMENT_SUPPLIERS} from "../../utils/payment-suppliers";


const getInfoScheme = (data, timeFormat, i18n, isWinpot) => {
    const result = {
        agentInfo: [
            {
                component: A_COMMON_DATA_FIELD.SELECT_DEFAULT,
                label: i18n.t('finance.agent'),
                dynamicValue: 'agentId',
                options: true,
                disabled: 'false'
            },
            {
                component: A_COMMON_DATA_FIELD.SELECT_PAYMENT_AGENT_TYPE,
                label: i18n.t('finance.agentType'),
                dynamicValue: 'agentType',
                disabled: 'false'
            }
        ],
        paymentInfoBottom: [
            {
                label: i18n.t('finance.time'),
                // value: Formatter.displayDateTime(data.createdAt)
                value: Formatter.displayDateTimeSeconds(timeFormat.parseTime(data.createdAt))
            },
            {
                label: i18n.t('finance.integration'),
                value: data?.provider.type.charAt(0).toUpperCase() + data?.provider.type.slice(1).toLowerCase()
            },
            {
                label: i18n.t('finance.paymentMethod'),
                value: data.method
            },
            {
                label: i18n.t('finance.paymentProcessor'),
                value: data.paymentMethod || 'default'
            }
        ],
        amountInfo: [
            {
                label: i18n.t('finance.totalAmount'),
                value: new Intl.NumberFormat().format(data.totalAmount),
                currency: data.currency
            },
            {
                label: i18n.t('finance.amount'),
                value: new Intl.NumberFormat().format(data.amount),
                currency: data.currency
            },
            {
                label: i18n.t('finance.taxAmount'),
                value: new Intl.NumberFormat().format(data.taxAmount),
                currency: data.currency
            },
            {
                label: i18n.t('finance.cardAmount'),
                value: new Intl.NumberFormat().format(isNaN(data.cardAmount)
                    ? '0'
                    : data?.cardRefundedAmount),
                currency: data.currency
            },
            {
                label: i18n.t('finance.cardRefundedAmount'),
                value: new Intl.NumberFormat().format(isNaN(data?.cardRefundedAmount)
                    ? '0'
                    : data?.cardRefundedAmount),
                currency: data.currency
            },
            {
                label: i18n.t('finance.amountForWireTransfer'),
                value: data.amountForWire || "",
                currency: data.currency
            },
        ],
        cardInfo: []
    }

    if (data.cardInfo && data.paymentCard?.maskedAccount) {
        result.cardInfo.push({
            label: i18n.t('finance.cardNumber'),
            value: data.paymentCard?.maskedAccount || "-"
        })
        result.cardInfo.push({
            removeSign: '0/0',
            label: i18n.t('finance.expirationDate'),
            value: (data?.paymentCard?.expirationMonth && data?.paymentCard?.expirationYear)
                ? `${data?.paymentCard?.expirationMonth}/${data?.paymentCard?.expirationYear}`
                : '-'
        })
    }

    if (data.reference) {
        result.paymentInfoBottom.push(
            {
                label: i18n.t('finance.referenceNumber'),
                value: data.reference
            },
            {
                label: i18n.t('crm.promoNameApplied'),
                value: data?.providerDetails?.promoCode || '-'
            },
            {
                label: i18n.t('crm.expirationDate'),
                value: (data?.paymentCard?.expirationMonth && data?.paymentCard?.expirationYear)
                  ? `${data?.paymentCard?.expirationMonth}/${data?.paymentCard?.expirationYear}`
                  : '-'
            },
        );
    }

    const getLabel = (key) => {
        if (isWinpot) {
            if (key === 'routingNumber') return 'CLABE';
            if (key === 'bankName') return 'Bank ID';
            if (key === 'id') return 'Reference ID';
            if (key === 'verified') return 'Is Verified';
        }
        return i18n.t(`finance.bank_${key}`);
    };

    if (data?.bankInfo) {
        Object.entries(data?.bankInfo).forEach(([key, value]) => {
            if (value) {
                result.paymentInfoBottom.push({
                    label: getLabel(key),
                    value,
                });
            }
        });
    }

    return result;
};

function PaymentDetailedCard(props) {
    const {bankStatement, switchPopupOpen, payment, reloadPayment, isWithdrawal} = props;
    const alertService = useAlertService();
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const {agentsList} = useSelector(state => state.AgentList);
    const {agentSelf} = useSelector(state => state.AgentSelf);
    const {timeFormat} = useTimeContext();
    const [supportingDocumentsList, setSupportingDocumentsList] = useState([]);
    const size = useWindowSize();

    const integration = useMemo(() => {
        let selected;
        Object.keys(PAYMENT_SUPPLIERS).forEach(key => {
            if (payment?.provider?.type?.toLowerCase() === PAYMENT_SUPPLIERS[key].toLowerCase()) {
                selected = PAYMENT_SUPPLIERS[key];
            }
        });
        return selected;
    }, [payment]);

    const agentPermissions = agentSelf?.permissions;
    const canEditByPermission = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__WDs_Approval);
    }, [agentSelf]);
    const canRunAutoApproval = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__WDs_Run_AutoApproval);
    }, [agentSelf]);
    const canWireTransferCompleted = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__WDs_WireTransfer_Completed);
    }, [agentSelf]);
    const canChangePendingForApproval = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__Change_PendingForApproval);
    }, [agentSelf]);

    const [showXBOAction, setShowXBOAction] = useState(undefined);
    const [showXBOConfirmPopup, setShowXBOConfirmPopup] = useState(false);
    const [isLoadingXBO, setIsLoadingXBO] = useState(false);
    const [showChargeBack, setShowChargeBack] = useState(false);
    const [showRefundPopup, setShowRefundPopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [agentType, setAgentType] = useState(payment.agentType);
    const [newBankInfoId, setNewBankInfoId] = useState(null);
    const [agentId, setAgentId] = useState(payment.agentId);
    const [changedAmount, setChangedAmount] = useState(payment.totalAmount);
    const [isLoadingAutoApproval, setIsLoadingAutoApproval] = useState(false);
    const [referenceId, setReferenceId] = useState(undefined);
    const [cancelWithdrawReason, setCancelWithdrawReason] = useState(undefined);
    const [isOpenWithdrawCancellation, setIsOpenWithdrawCancellation] = useState(false);

    const dynamicValues = {
        agentType: {
            value: agentType,
            setValue: setAgentType
        },
        agentId: {
            value: agentId,
            setValue: setAgentId
        }
    };

    const {active: activeBrand} = useSelector(state => state.Brand);

    const isWinpot = activeBrand?.siteName === SITE_BRAND.WINPOTMX;

    const handleChange = type => change => {
        setIsEdit(true);
        if (change?.target?.value) {
            change = change.target.value;
        }
        switch (type) {
            case 'agentType':
                setAgentType(change);
                break;
            case 'agentId':
                setAgentId(change);
                break;
            case 'referenceId':
                setReferenceId(change);
                break;
            case 'agentCancellationDetails':
                setCancelWithdrawReason(change);
                break;
        }
    };

    const handleSave = async () => {
        setIsEdit(false);
        const url = `/payment/payment/${payment.id}`;
        try {
            const result = await patchSync(alertService, url, {
                agentType,
                agentId
            });
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.paymentInfoChanged"));
        } catch (e) {
            const message =
                e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
    };
    const markAsChargeBack = async () => {
        const url = `/payment/payment/${payment.id}/charge-back?${referenceId ? `referenceId=${referenceId}` : ''}`;
        try {
            const result = await patchSync(alertService, url, {});
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.changedChargeBackStatus"));
        } catch (e) {
            const message =
                e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
        switchChargeBack();
    };

    const sendCancelWithdraw = async () => {
        const url = `/payment/withdrawals/${payment.id}/roll-back?cancellationReason=${cancelWithdrawReason}`;
        try {
            const result = await postSync(url, {});
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.paymentInfoChanged"));
        } catch (e) {
            const message =
              e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
        switchWithdrawCancellation();
    };

    const [isSupportingDocumentsButtonActive, setIsSupportingDocumentsButtonActive] = useState(true);

    const mimeToExt = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'image/webp': '.webp',
        'image/tiff': '.tif',
        'image/bmp': '.bmp',
        'application/pdf': '.pdf',
    };
    const openDocumentInNewTab = (documentLink) => {
        const encodedLink = encodeURIComponent(documentLink);
        window.open(documentLink, '_blank');
    };

    const downloadSupportingDocuments = async () => {
        if (supportingDocumentsList.length > 0) {
            return;
        }
        if (!isSupportingDocumentsButtonActive) return;

        setIsSupportingDocumentsButtonActive(false); // Disable the button

        const url = `/payment/payment/${payment.id}/supporting-documents`;

        try {
            const result = await getSync(url, {});

            let array = result.data;
            array = array.filter(item => item.documentType !== 'PaymentConfirmation');

            if (!array || array.length === 0) {
                throw new Error(`No supporting documents found for payment ${payment.id}`);
            }

            if (array.length === 1) {
                // If only one document, download it directly
                const item = array[0];
                const fileName = `${item.documentOid}_${item.documentType}.${mimeToExt[item.documentFileType]}`;
                openDocumentInNewTab(item.documentLink);
            } else {
                // If multiple documents, show a modal or overlay for user to select
                setSupportingDocumentsList(array); // This could set the state to show a modal with the list of documents
            }

            alertService.showSuccess(i18n.t("crm.alert.downloadSupportingDocuments"));
        } catch (e) {
            const message = e?.message || i18n.t("crm.alert.failToDownloadSupportingDocuments");
            alertService.showError(message);
        } finally {
            setIsSupportingDocumentsButtonActive(true);
        }
    };

    const markAsRefund = async () => {
        const url = `/payment/payment/${payment.id}/refund?${referenceId ? `referenceId=${referenceId}` : ''}`;
        try {
            const result = await patchSync(alertService, url, {});
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.changedRefundStatus"));
        } catch (e) {
            const message =
                e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
        switchChargeBack();
    };

    const handleCancel = () => {
        setIsEdit(false);
        setAgentType(payment.agentType);
        setAgentId(payment.agentId);
    };

    const agentList =
        agentsList?.map(v => {
            return {
                value: v.id,
                content: v.agentName
            };
        }) || [];

    const infoScheme = useMemo(
        () => getInfoScheme(payment, timeFormat, i18n, isWinpot),
        [payment, timeFormat, i18n.language]
    );

    const switchXBOConfirmPopup = (action) => {
        setShowXBOConfirmPopup(!showXBOConfirmPopup);
        if (!showXBOConfirmPopup || !action) {
            setShowXBOAction(action);
        }
    };

    const switchChargeBack = () => {
        setShowChargeBack(!showChargeBack);
    };

    const switchWithdrawCancellation = () => {
        setIsOpenWithdrawCancellation(!isOpenWithdrawCancellation);
    };

    const switchRefundPopup = () => {
        setShowRefundPopup(!showRefundPopup);
    };

    const completeWire = async () => {
        const url = `/payment/payment/${payment.id}/complete-wire`;
        try {
            const result = await patchSync(alertService, url, {});
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.changedPaymentCompleted"));
        } catch (e) {
            const message =
                e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
    };

    const changeForPending = async () => {
        const url = `/payment/payment/${payment.id}/change-for-pending`;
        try {
            const result = await patchSync(alertService, url, {});
            reloadPayment();
            alertService.showSuccess(i18n.t("crm.alert.changedPaymentAMLCompleted"));
        } catch (e) {
            const message =
                e?.message || i18n.t("crm.alert.cannot.paymentInfoChanged");
            alertService.showError(message);
        }
    };

    const autoCheckTransaction = async () => {
        setIsLoadingAutoApproval(true);

        const url = `/payment/transactions/${payment.id}/run-auto-approval-check`;
        try {
            const result = await postSync(url, {});
            reloadPayment();
            if (result?.success) {
                setIsLoadingAutoApproval(false);
                alertService.showSuccess(result?.details || 'Success');
            } else {
                alertService.showError(result?.details || 'Error');
            }
        } catch (e) {
            const message =
                e?.message || 'Error'
            setIsLoadingAutoApproval(false);
            alertService.showError(message);
        }
    };

    const changeBankInfo = async () => {
        setIsLoadingAutoApproval(true);

        const url = `/payment/transactions/${payment.id}/change-bank-info`;
        try {
            const result = await postSync(url, {
                id: newBankInfoId
            });
            reloadPayment();
            if (result?.success) {
                setIsLoadingAutoApproval(false);
                alertService.showSuccess(result?.details || 'Success');
            } else {
                alertService.showError(result?.details || 'Error');
            }
        } catch (e) {
            const message =
                e?.message || 'Error'
            setIsLoadingAutoApproval(false);
            alertService.showError(message);
        }
    };

    const changeWithdrawAmount = async () => {
        const url = `/payment/payment/${payment.id}/change-withdraw-amount`;
        try {
            const result = await patchSync(alertService, url, {changedAmount: Number(changedAmount)});
            reloadPayment();
            alertService.showSuccess(i18n.t('crm.alerts.amountChanged'));
        } catch (e) {
            const message =
                e?.message || alertService.showError(message);
        }

    };

    useEffect(() => {
        return dispatch(getAgentsList());
    }, []);

    const canDownloadSupportingDocuments = useMemo(() => {
        return agentPermissions?.includes(SITE_PERMISSION.Transactions__SupportingDocuments);
    }, [agentSelf]);


    /** Transaction Info */
    function buildDescription(json) {
        let status;
        let description = [];
        switch (integration) {
            case PAYMENT_SUPPLIERS.XBO:
                status = json?.status;
                if (json?.statusDescription) {
                    description.push(json?.statusDescription);
                }
                if (json?.apiCall) {
                    description.push(json?.apiCall);
                }
                if (json.receivedAmount !== undefined && json.executedAmountTo !== undefined) {
                    description.push(`Received amount: ${json.receivedAmount}, Executed amount: ${json.executedAmountTo}`);
                }
                break;
            case PAYMENT_SUPPLIERS.KUSHKI:
                status = json.status;
                if (json?.description) {
                    description.push(json?.description);
                }
                if (json?.processorName) {
                    description.push(`Processor: ${json?.processorName}`);
                }
                break;
            case PAYMENT_SUPPLIERS.CONEKTA:
                if (json?.id) {
                    description.push(`Ticket with ID: ${json?.id}`);
                }
                break;
            case PAYMENT_SUPPLIERS.PAYMENTIQ:
                status = json?.pspStatusMessage?.replace(/\s/g, '');
                if(status === '') {
                    status = json?.statusCode.replace(/\s/g, '');
                }
                if (json?.internalWallet) {
                    description.push(json?.internalWallet);
                }
                break;
        }

        if(json.internal) {
            description.push(json.internal);
        }
        if (json?.system) {
            description.push(json?.system);
        }
        if (json?.message) {
            description.push(json?.message);
        }

        return {status, description: description.join('\n')};
    }

    const [lastStatus, lastStatusDescription] = useMemo(() => {
        try {
            let lastStatus;
            let lastStatusDescription;
            if (payment?.audit) {
                const audit = payment.audit[0];
                if (lastStatusDescription || lastStatus) return;
                if (audit?.providerResponse) {
                    let json;
                    try {
                        json = JSON.parse(audit.providerResponse);
                    } catch (e) {
                        json = audit.providerResponse;
                    }
                    const {status, description} = buildDescription(json);
                    lastStatus = status;
                    lastStatusDescription = description;
                }
            }

            if (lastStatus === null || (lastStatus && lastStatus.replace(/\s/g, '') === '')) {
                lastStatus = undefined;
            }
            if (lastStatusDescription === null) {
                lastStatusDescription = undefined;
            }

            if (!lastStatusDescription || (lastStatusDescription && lastStatusDescription.replace(/\s/g, '') === '')) {
                lastStatusDescription = lastStatus;
                lastStatus = undefined;
            }
            return [lastStatus, lastStatusDescription];
        } catch (e) {
            return [undefined, undefined];
        }
    }, [payment])


    const XBOActions = useMemo(() => {
        function xboAction(_action) {
            let apiPath;
            switch (_action) {
                case 'refund':
                    apiPath = 'refund';
                    break;
                case 'retry':
                    apiPath = 'retry';
                    break;
                case 'accept':
                    apiPath = 'accept-received-amount';
                    break;
            }
            return async () => {
                switchXBOConfirmPopup();
                setIsLoadingXBO(true);
                const url = `payment/xbo/transactions/${payment.id}/${apiPath}`;
                try {
                    const response = await getSync(url, {});
                    const result = response.data;
                    if (result.succeeded === true || result.success === true) {
                        alertService.showSuccess(result?.details || 'Success');
                        setTimeout(() => {
                            reloadPayment();
                            setIsLoadingXBO(false);
                        }, 7000);
                    }
                    if (result.succeeded === false || result.success === false) {
                        alertService.showError(result?.details || 'Could not complete the action');
                        setIsLoadingXBO(false);
                    }
                } catch (e) {
                    const message =
                        e?.message || 'Error'
                    alertService.showError(message);
                    setIsLoadingXBO(false);
                }
            };
        }

        return (
            <div className={clsx("", {
                "d-none": !canEditByPermission,
            })}
            >
                <SectionTitle
                    sectionName={'XBO Actions'}
                    image={crypto}
                    width={25}
                />
                {showXBOConfirmPopup &&
                    <ConfirmPopupModal
                        title={`Confirm ${showXBOAction.replace(/^\w/, c => c.toUpperCase())}`}
                        content={`Are you sure you want to ${showXBOAction.replace(/^\w/, c => c.toUpperCase())} this transaction?`}
                        onCancel={switchXBOConfirmPopup}
                        onConfirm={xboAction(showXBOAction)}
                    />
                }
                {!isLoadingXBO &&
                    <div className='d-flex flex-wrap'
                         style={{
                             display: 'flex !important',
                             gap: '8px',
                             marginTop: '10px',
                             flexDirection: 'column',
                             alignItems: 'stretch',
                             alignContent: 'flex-start',
                         }}>
                        <button
                            className='btn btn-rounded btn-primary'
                            onClick={() => switchXBOConfirmPopup('refund')}//xboAction('refund')}
                        >
                            Refund
                        </button>
                        <button
                            className='btn btn-rounded btn-primary'
                            onClick={() => switchXBOConfirmPopup('retry')}//xboAction('retry')}
                        >
                            Retry
                        </button>
                        <button
                            className='btn btn-rounded btn-primary'
                            onClick={() => switchXBOConfirmPopup('accept')}//xboAction('accept')}
                        >
                            Accept received amount
                        </button>
                    </div>
                }
                {isLoadingXBO && <Loader size={'sm'} className='ml-10'/>}
                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
            </div>
        )
    }, [showXBOConfirmPopup, showXBOAction, payment, isLoadingXBO]);


    return (
        <Card className='page-card'>
            <CardBody className='payment-info'>
                <div className='payment-content'>
                    <div className='column'>
                        <SectionTitle
                            sectionName={i18n.t('finance.amount')}
                            image={amount}
                        />

                        <div className='d-flex flex-wrap'>
                            {infoScheme.amountInfo.map((item, index) => (
                                <ACommonDataField
                                    key={index}
                                    disabled={true}
                                    label={item.label}
                                    value={item.value || ""}
                                    currency={item.currency}
                                />
                            ))}
                        </div>

                        {payment.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL &&
                            <div className={clsx("pending-approve-sec", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <ACommonDataField
                                    label={i18n.t('finance.changeAmount')}
                                    component={A_COMMON_DATA_FIELD.NUMBER}
                                    value={changedAmount}
                                    handleChange={setChangedAmount}
                                />
                                <GenerateButton
                                    style={{marginRight: '10px'}}
                                    onClick={changeWithdrawAmount}
                                    icon={'update'}
                                    text={i18n.t(`finance.submitChange`)}
                                />
                            </div>
                        }

                        {!isWithdrawal && (PAYMENT_STATUS.REFUNDED === payment.status) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <SectionTitle
                                    sectionName={i18n.t('finance.changePaymentStatusTitle')}
                                    image={card}
                                />
                                <div className='middle-horizontal' style={{marginBottom: '10px'}}/>

                                {showChargeBack &&
                                    <ConfirmPopupModal
                                        title={'finance.chargeBack.confirm.title'}
                                        content={'finance.chargeBack.confirm.content'}
                                        onCancel={switchChargeBack}
                                        onConfirm={markAsChargeBack}
                                        children={<ACommonDataField
                                            component={A_COMMON_DATA_FIELD.TEXT}
                                            label={'Reference Id'}
                                            value={referenceId || ''}
                                            handleChange={handleChange('referenceId')}
                                        />}
                                    />
                                }

                                <div className='d-flex flex-wrap'>
                                    <button
                                        className='btn btn-rounded btn-primary btn-payment-status'
                                        onClick={switchChargeBack}
                                    >
                                        {i18n.t('finance.chargeBackDetails')}
                                    </button>
                                </div>
                                <div className='middle-horizontal' style={{marginBottom: '10px'}}/>
                            </div>
                        }

                        {!isWithdrawal && (PAYMENT_STATUS.COMPLETED === payment.status) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <SectionTitle
                                    sectionName={i18n.t('finance.changePaymentStatusTitle')}
                                    image={card}
                                />
                                <div className='middle-horizontal' style={{marginBottom: '10px'}}/>

                                {showChargeBack &&
                                    <ConfirmPopupModal
                                        title={'finance.chargeBack.confirm.title'}
                                        content={'finance.chargeBack.confirm.content'}
                                        onCancel={switchChargeBack}
                                        onConfirm={markAsChargeBack}
                                        children={<ACommonDataField
                                            component={A_COMMON_DATA_FIELD.TEXT}
                                            label={'Reference Id (Optional)'}
                                            value={referenceId || ''}
                                            handleChange={handleChange('referenceId')}
                                        />}
                                    />
                                }

                                <div className='d-flex flex-wrap'>
                                    <button
                                        className='btn btn-rounded btn-primary btn-payment-status'
                                        onClick={switchChargeBack}
                                    >
                                        {i18n.t('finance.chargeBackDetails')}
                                    </button>
                                </div>
                                <div className='middle-horizontal' style={{marginBottom: '10px'}}/>
                            </div>
                        }

                        {!isWithdrawal && ([PAYMENT_STATUS.COMPLETED].includes(payment.status)) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                {showRefundPopup &&
                                    <ConfirmPopupModal
                                        title={'finance.refundPayment.confirm.title'}
                                        content={'finance.refundPayment.confirm.content'}
                                        onCancel={switchRefundPopup}
                                        onConfirm={markAsRefund}
                                        children={<ACommonDataField
                                            component={A_COMMON_DATA_FIELD.TEXT}
                                            label={'Reference Id'}
                                            value={referenceId || ''}
                                            handleChange={handleChange('referenceId')}
                                        />}
                                    />
                                }

                                <div className='d-flex flex-wrap'>
                                    <button
                                        className='btn btn-rounded btn-primary btn-payment-status'
                                        onClick={switchRefundPopup}
                                    >
                                        {i18n.t('finance.refundPaymentDetails')}
                                    </button>
                                </div>
                            </div>
                        }

                        {([PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER, PAYMENT_STATUS.PROCESSING].includes(payment.status)) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <SectionTitle
                                    sectionName={
                                        payment.status === PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER ?
                                            i18n.t('finance.wireTransfer') :
                                            i18n.t('finance.wireTransferProcessing')
                                    }
                                    image={card}
                                />
                                <div className='d-flex flex-wrap'>
                                    <button
                                        className='btn btn-rounded btn-primary'
                                        onClick={completeWire}
                                        disabled={!canWireTransferCompleted}
                                    >
                                        {
                                            payment.status === PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER ?
                                                i18n.t('finance.wireTransferDetails') :
                                                i18n.t('finance.wireTransferProcessingDetails')
                                        }
                                    </button>
                                </div>
                                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                            </div>
                        }
                        {([PAYMENT_STATUS.PENDING_FOR_WIRE_TRANSFER].includes(payment.status)) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <SectionTitle
                                    sectionName={i18n.t('finance.changePaymentStatusTitle')}
                                    image={card}
                                />
                                <div className='d-flex flex-wrap'>
                                    <button
                                        className='btn btn-rounded btn-primary'
                                        onClick={changeForPending}
                                        disabled={!canChangePendingForApproval}
                                    >
                                        {i18n.t('finance.backToPendingDetails')}
                                    </button>
                                </div>
                                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                            </div>
                        }

                        {([PAYMENT_STATUS.PENDING_FOR_APPROVAL].includes(payment.status)) &&
                            <div className={clsx("", {
                                "d-none": !canEditByPermission,
                            })}
                            >
                                <SectionTitle
                                    sectionName={i18n.t('finance.runAutoCheck')}
                                    image={card}
                                />
                                <div className='d-flex align-items-center flex-wrap mt-3'>
                                    <button
                                        className='btn btn-rounded btn-primary'
                                        disabled={isLoadingAutoApproval || !canRunAutoApproval}
                                        onClick={autoCheckTransaction}
                                    >
                                        {i18n.t('finance.runAutoCheckScript')}
                                    </button>
                                    {isLoadingAutoApproval &&
                                        <div>
                                            <Loader size={'sm'} className='ml-10'/>
                                        </div>
                                    }
                                </div>
                                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                            </div>
                        }
                        {(lastStatus !== undefined || lastStatusDescription !== undefined) &&
                            <div style={{marginTop: '30px', marginBottom: '30px'}}>
                                <SectionTitle
                                    sectionName={'Transaction Updated Info'}
                                    image={amount}
                                />

                                {lastStatus &&
                                    <ACommonDataField
                                        key={'xbo-status'}
                                        disabled={true}
                                        label={'Transaction status'}
                                        value={lastStatus}
                                    />
                                }
                                {lastStatusDescription &&
                                    <ACommonDataField
                                        key={'xbo-status-description'}
                                        disabled={true}
                                        label={'Transaction status description'}
                                        value={lastStatusDescription}
                                        multiline={true}
                                    />
                                }
                                {isWithdrawal && ([PAYMENT_STATUS.COMPLETED].includes(payment.status)) && (
                                  <div className='d-flex flex-column gap-3'>
                                      <SectionTitle
                                        sectionName={i18n.t("finance.changePaymentStatusTitle")}
                                        image={card}
                                      />
                                      <button
                                        className="btn btn-rounded btn-primary"
                                        style={{width: 'fit-content'}}
                                        onClick={() => setIsOpenWithdrawCancellation(true)}
                                        disabled={!canChangePendingForApproval}
                                      >
                                          {i18n.t("crm.cancel")}
                                      </button>
                                  </div>
                                )}
                            </div>
                        }
                        {payment.status === PAYMENT_STATUS.MANUAL_ACTION_REQUIRED && integration === PAYMENT_SUPPLIERS.XBO && XBOActions}

                    </div>

                    <div className="vertical-separator" />

                    <div className="column">

                        {Object.keys(infoScheme.cardInfo) > 0 && (
                            <>
                                <SectionTitle
                                    sectionName={isWithdrawal ? i18n.t('finance.cardWithdraw') : i18n.t('finance.card')}
                                    image={card}
                                />

                                <div className='d-flex flex-wrap'>
                                    {infoScheme.cardInfo.map(
                                        (item, index) =>
                                            item.removeSign !== item.value && (
                                                <ACommonDataField
                                                    key={index}
                                                    disabled={true}
                                                    label={item.label}
                                                    value={item.value || ""}
                                                />
                                            )
                                    )}
                                </div>
                            </>
                        )}
                        <SectionTitle
                            sectionName={i18n.t('finance.paymentInfo')}
                            image={iIconLight}
                        />
                        {infoScheme.paymentInfoBottom.map((item, index) => (
                            <ACommonDataField
                                key={index}
                                disabled={true}
                                label={item.label}
                                value={item.value || ""}
                                fulWidth={true}
                            />
                        ))}
                        {bankStatement &&
                            <>
                                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                                <div
                                    onClick={switchPopupOpen} style={{cursor: 'pointer'}}>
                                    <SectionTitle
                                        sectionName={i18n.t("crm.ShowBankStatement")}
                                        image={card}
                                    />
                                </div>
                            </>
                        }


                        {canDownloadSupportingDocuments && size?.width > SCREEN_SIZES.TABLET_VIEW &&
                            <>
                                <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                                <div
                                    onClick={downloadSupportingDocuments}
                                    className={`download-section ${isSupportingDocumentsButtonActive ? '' : 'inactive'}`}
                                    style={supportingDocumentsList.length > 0 ? {} : {cursor: 'pointer'}}
                                >
                                    <SectionTitle

                                        sectionName={i18n.t("finance.downloadSupportingDocument")}
                                        image={information}
                                    />
                                    <ul className={'download-section'}>
                                        {supportingDocumentsList.map(doc => (
                                            <li key={doc.id} className={'download-section'}>
                                                <a className={'download-section'}
                                                   onClick={() => openDocumentInNewTab(doc.documentLink)}
                                                   download={`${doc.documentOid}_${doc.documentType}.${mimeToExt[doc.documentFileType]}`}>
                                                    {`(${doc.documentOid}) ${doc.documentType} | ${doc.documentSide}`}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </>
                        }

                    </div>
                </div>


                {payment?.status === PAYMENT_STATUS.PENDING_FOR_APPROVAL &&
                    ((payment?.playerBankInfos?.length > 1)
                        || (!payment?.bankInfo?.id && payment?.playerBankInfos?.length > 0)) &&
                    <>
                        <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <ACommonDataField
                                component={
                                    A_COMMON_DATA_FIELD.SELECT_DEFAULT
                                }
                                hasClear={false}
                                style={{
                                    marginRight: '22px',
                                    fontSize: '2px',
                                    fontWeight: 'normal',
                                    color: '#000000',
                                    lineHeight: '14px',
                                    height: '40px',
                                    padding: '0 10px',
                                    boxSizing: 'border-box',
                                }}
                                className='change-bank-info-field'
                                label={`Change Payment Bank Info`}
                                value={payment?.bankInfo?.id}
                                options={payment?.playerBankInfos?.map(r => {
                                    return {
                                        content: r.id,
                                        value: r.id
                                    };
                                })}
                                handleChange={(data) => {
                                    setNewBankInfoId(data);
                                }}
                            />
                            <button
                                style={{marginRight: '22px'}}
                                className='btn btn-rounded btn-primary'
                                onClick={changeBankInfo}
                            >Change
                            </button>
                        </div>
                        <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                    </>
                }

                {canDownloadSupportingDocuments && size?.width <= SCREEN_SIZES.TABLET_VIEW &&
                    <>
                        <div className='middle-horizontal-separator' style={{marginBottom: '10px'}}/>
                        <div
                            onClick={downloadSupportingDocuments}
                            className={`download-section ${isSupportingDocumentsButtonActive ? '' : 'inactive'}`}
                            style={supportingDocumentsList.length > 0 ? {} : {cursor: 'pointer'}}
                        >
                            <SectionTitle

                                sectionName={i18n.t("finance.downloadSupportingDocument")}
                                image={information}
                            />
                            <ul className={'download-section'}>
                                {supportingDocumentsList.map(doc => (
                                    <li key={doc.id} className={'download-section'}>
                                        <a className={'download-section'}
                                           onClick={() => openDocumentInNewTab(doc.documentLink)}
                                           download={`${doc.documentOid}_${doc.documentType}.${mimeToExt[doc.documentFileType]}`}>
                                            {`(${doc.documentOid}) ${doc.documentType} | ${doc.documentSide}`}
                                        </a>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </>
                }

                {agentSelf?.permissions &&
                    <>
                        <Tracking transaction={payment}/>
                    </>
                }
            </CardBody>

            {isOpenWithdrawCancellation && (
              <ConfirmPopupModal
                title={'finance.cancelWithdraw.confirm.title'}
                content={'finance.cancelWithdraw.confirm.content'}
                onCancel={switchWithdrawCancellation}
                onConfirm={sendCancelWithdraw}
                children={<ACommonDataField
                  component={A_COMMON_DATA_FIELD.TEXT}
                  label={'Provide a reason'}
                  value={cancelWithdrawReason || ''}
                  handleChange={handleChange('agentCancellationDetails')}
                />}
              />
            )}

            {isEdit && (
                <ButtonsPanel onSave={handleSave} onCancel={handleCancel}/>
            )}

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}
        </Card>
    );
}

PaymentDetailedCard.propTypes = {
    payment: PropTypes.object.isRequired,
    reloadPayment: PropTypes.func.isRequired,
    isWithdrawal: PropTypes.bool,
};

PaymentDetailedCard.defaultProps = {
    isWithdrawal: false,
};

export default PaymentDetailedCard;
