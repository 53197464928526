import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import rightArrow from '../../../assets/images/layout/right-arrow.svg';

import { getTimesAgo } from '../../../common/utils/common';
import i18n from "i18next";
import { generateActivityMessage } from "../../../helpers/document"
import { useTranslation } from "react-i18next"

const ActivityHistoryRecord = props => {
    const { record } = props;
    const {
        actor,
        action,
        info,
        comment,
        createdAt,
    } = record;

    moment.locale(i18n.language);
    const timesAgo = getTimesAgo(createdAt);

  const { t } = useTranslation();

  const { activity, information } = generateActivityMessage(action, info, actor, t);

    return (
        <li>
            <span key="activity" className="record-title">
                {activity}
            </span>
            <span key="time-ago" className="record-time-ago">
                {timesAgo}
            </span>
            <br />
           <div className="record-wrapper">
                <span key="actor" className="actor">
                    {actor}
                </span>
                <img
                    className="right-arrow"
                    alt=""
                    src={rightArrow}
                    width={16}
                    height={16}
                />
                <span className="record-part">
                    {information}
                </span>
                <span className="record-part">
                    {comment}
                </span>
                <span className="record-part">
                    {moment(createdAt).format('DD.MM.YYYY')}
                </span>
                <span className="record-part">
                    {moment(createdAt).format('HH:mm')}
                </span>
           </div>
        </li>
    );
};

ActivityHistoryRecord.propTypes = {
    record: PropTypes.object
};

export default ActivityHistoryRecord;
