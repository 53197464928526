import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_PLAYER_WITHDRAWAL_LIST } from './actionTypes';
import {
    getPlayerWithdrawalListSuccess,
    getPlayerWithdrawalListFail
} from './actions';
import { getPlayerWithdrawalListApi } from '../../../helpers/playerApi';

function* fetchPlayerWithdrawalListSaga({ payload }) {
    try {
        const response = yield call(getPlayerWithdrawalListApi, payload);

        yield put(getPlayerWithdrawalListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerWithdrawalListFail(error));
    }
}

function* playerWithdrawalsSaga() {
    yield takeEvery(
        GET_PLAYER_WITHDRAWAL_LIST,
        fetchPlayerWithdrawalListSaga
    );
}

export default playerWithdrawalsSaga;
