import {
    CHANGE_BRAND,
    GET_BRANDS,
    GET_BRANDS_ERROR,
    GET_BRANDS_SUCCESS
} from './actionTypes';

export const getBrands = () => ({
    type: GET_BRANDS
});

export const getBrandsSuccess = response => ({
    type: GET_BRANDS_SUCCESS,
    payload: response
});

export const changeBrand = brandId => ({
    type: CHANGE_BRAND,
    payload: brandId
});

export const fetchBrandError = error => ({
    type: GET_BRANDS_ERROR,
    payload: error
});
