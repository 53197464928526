import React from 'react';

import PropTypes from 'prop-types';
import IconTooltip from '../cellData/IconTooltip';
import { CONTACT_STATUSES } from '../constants/common';

const FieldData = ({ className, name, value, isConfirmed, bold = false }) => {
    const contact = CONTACT_STATUSES[isConfirmed ? 'verified' : 'notVerified'];

    return (
        <div className={`field-data ${className}`}>
            <span className="field-name" style={bold ? {fontWeight: '600'} : {}}>
                {name}:
            </span>
            <span
                style={{
                    marginLeft: '5px',
                    whiteSpace: 'nowrap'
                }}
            >
                <span className="field-value">{value}</span>

                {isConfirmed !== undefined && (
                    <IconTooltip
                        icon={contact.icon}
                        tooltipText={contact.text}
                        textColor={contact.color}
                    />
                )}
            </span>
        </div>
    );
};

FieldData.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    isConfirmed: PropTypes.bool
};

export default FieldData;
