import { GET_AGENT, GET_AGENT_SUCCESS, GET_AGENT_FAIL } from './actionTypes';

export const getAgent = payload => ({
    type: GET_AGENT,
    payload
});

export const getAgentSuccess = payload => ({
    type: GET_AGENT_SUCCESS,
    payload
});

export const getAgentFail = error => ({
    type: GET_AGENT_FAIL,
    payload: error
});
