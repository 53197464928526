import { getSync, patchSync } from '../helpers/api_helper';
import i18n from "../i18n";

export const loadEmail = (oid) => async () => {
    const url = `/players/oid/${oid}/email`;
    try {
        const result = await getSync(url, {});
        return result?.data || {};
    } catch (e) {
        console.error('Cannot fetch last messages!');
    }
};

export const editEmail = async (playerId, email, alertService) => {
    const url = `/players/oid/${playerId}/email`;
    try {
        const result = await patchSync(alertService, url, { email: email });
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(email)){
            alertService.showError(i18n.t('crm.emailInvalid'));
            return result?.data || {};
        }

        alertService.showSuccess(i18n.t('crm.alerts.emailChanged'));
        return result?.data || {};
    } catch (e) {}
};


export const loadPhone = (oid) => async () => {
    const url = `/players/oid/${oid}/phone`;
    try {
        const result = await getSync(url, {});
        return result?.data || {};
    } catch (e) {
        console.error('Cannot fetch last messages!');
    }
};

export const editPhone = async (playerId, phone, alertService) => {
    const url = `/players/oid/${playerId}/phone`;
    try {
        let dataToSend = {};

        if (phone.phoneChanged) {
            dataToSend.phone = phone.phone;
        }
        if (phone.otherPhoneChanged) {
            dataToSend.additionalPlayerPhones = {
                WhatsApp: phone.otherPhone
            };
        }

        const result = await patchSync(alertService, url, dataToSend);
        alertService.showSuccess(i18n.t('crm.alerts.phoneChanged'));
        return result?.data || {};
    } catch (e) {
        console.error(e)
    }
};

