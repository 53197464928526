import React, { useState } from 'react';
import {
    Cell,
    Sector,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip
} from 'recharts';
import PropTypes from 'prop-types';
import ChartTooltip from './ChartTooltip';
import i18n from '../../i18n';

const COLORS = ['#667CCC', '#3DCCB8', '#FFCC66', '#F2688B', '#5CCEE5'];

const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
        props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius - 1}
                outerRadius={outerRadius + 8}
                fill={fill}
            />
        </g>
    );
};

const AppPieChart = ({ chartData, width, isShowCenterText, chartWidth }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const containerWidth = width || 400;
    const outerRadius = containerWidth / 2 - 8;
    // 32 width pie, 8 for ActiveShape
    const innerRadius = outerRadius - (chartWidth || 32) - 8;

    const count = chartData.reduce((acc, cur) => {
        return acc + cur.value;
    }, 0);

    return (
        <ResponsiveContainer width={containerWidth} height={containerWidth}>
            <PieChart>
                <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    fill="#8884d8"
                    dataKey="value"
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    activeShape={renderActiveShape}
                    activeIndex={activeIndex}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                    onClick={e => {
                        if (e.onClick) {
                            e.onClick();
                        }
                    }}
                >
                    {chartData.map((entry, index) => {
                        const color =
                            entry.color || COLORS[index % COLORS.length];

                        return <Cell key={`cell-${index}`} fill={color} />;
                    })}
                </Pie>
                <Tooltip content={<ChartTooltip />} />

                {isShowCenterText && (
                    <>
                        <text
                            x="50%"
                            y="40%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={'#495057'}
                            style={{ fontWeight: 600, fontSize: '20px' }}
                        >
                            {i18n.t('dashboard.total')}:
                        </text>
                        <text
                            x="50%"
                            y="55%"
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill={'#495057'}
                            style={{
                                fontWeight: 600,
                                fontSize: count > 999 ? '32px' : '72px'
                            }}
                        >
                            {count}
                        </text>
                    </>
                )}
            </PieChart>
        </ResponsiveContainer>
    );
};

AppPieChart.propTypes = {
    chartData: PropTypes.array.isRequired,
    width: PropTypes.number,
    chartWidth: PropTypes.number,
    isShowCenterText: PropTypes.bool
};

export default AppPieChart;
