export const AFFILIATES_RADIO_VALUES = {
    BRANDED: 'BRANDED',
    NON_BRANDED: 'NON_BRANDED'
};

export const AFFILIATES_MARK_STATUS = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED'
};

export const STATUS = {
    ACTIVE: 'ACTIVE',
    FROZEN: 'FROZEN',
    CLOSED: 'CLOSED',
    LEAD: 'LEAD',
    BLOCKED: 'BLOCKED'
};
