import { takeEvery, put, call } from 'redux-saga/effects';

import {
    GET_PLAYER_COMMUNICATION,
    GET_PLAYER_COMMUNICATION_PHONE_LIST,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST,
    UPDATE_PLAYER_COMMUNICATION,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION
} from './actionTypes';
import {
    getPlayerCommunicationSuccess,
    getPlayerCommunicationFail,
    getPlayerCommunicationPhoneListSuccess,
    getPlayerCommunicationPhoneListFail,
    getPlayerCommunicationEmailListSuccess,
    getPlayerCommunicationEmailListFail,
    updatePlayerCommunicationSuccess,
    updatePlayerCommunicationFail,
    sendCodePhonePlayerCommunicationSuccess,
    sendCodePhonePlayerCommunicationFail,
    getPlayerCommunicationPhoneList,
    getPlayerCommunicationEmailList,
    sendCodeEmailPlayerCommunicationSuccess,
    sendCodeEmailPlayerCommunicationFail
} from './actions';
import {
    getPlayerCommunicationApi,
    getPlayerCommunicationPhoneListApi,
    getPlayerCommunicationEmailListApi,
    updatePlayerCommunicationApi,
    sendPlayerCommunicationPhoneApi,
    sendPlayerCommunicationEmailApi
} from '../../../helpers/playerApi';
import { DEFAULT_QUERY } from './reducer';

function* fetchPlayerCommunicationSaga({ payload }) {
    try {
        const response = yield call(getPlayerCommunicationApi, payload);

        yield put(getPlayerCommunicationSuccess(response.data));
    } catch (error) {
        yield put(getPlayerCommunicationFail(error));
    }
}

function* updatePlayerCommunicationSaga({ payload }) {
    try {
        const response = yield call(updatePlayerCommunicationApi, payload);

        yield put(
            updatePlayerCommunicationSuccess({
                responseData: response.data,
                dto: payload.dto
            })
        );
    } catch (error) {
        yield put(updatePlayerCommunicationFail(error));
    }
}

function* fetchPlayerCommunicationPhoneListSaga({ payload }) {
    try {
        const response = yield call(
            getPlayerCommunicationPhoneListApi,
            payload
        );

        yield put(getPlayerCommunicationPhoneListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerCommunicationPhoneListFail(error));
    }
}

function* fetchPlayerCommunicationEmailListSaga({ payload }) {
    try {
        const response = yield call(
            getPlayerCommunicationEmailListApi,
            payload
        );

        yield put(getPlayerCommunicationEmailListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerCommunicationEmailListFail(error));
    }
}

function* sendCodePhonePlayerCommunicationSaga({ payload }) {
    try {
        const response = yield call(sendPlayerCommunicationPhoneApi, payload);
        yield put(sendCodePhonePlayerCommunicationSuccess(response.data));

        yield put(
            getPlayerCommunicationPhoneList({
                id: payload,
                queryPhoneList: DEFAULT_QUERY
            })
        );
    } catch (error) {
        yield put(sendCodePhonePlayerCommunicationFail(error));
    }
}

function* sendCodeEmailPlayerCommunicationSaga({ payload }) {
    try {
        const response = yield call(sendPlayerCommunicationEmailApi, payload);
        yield put(sendCodeEmailPlayerCommunicationSuccess(response.data));

        yield put(
            getPlayerCommunicationEmailList({
                id: payload,
                queryEmailList: DEFAULT_QUERY
            })
        );
    } catch (error) {
        yield put(sendCodeEmailPlayerCommunicationFail(error));
    }
}

function* playerCommunicationSaga() {
    yield takeEvery(GET_PLAYER_COMMUNICATION, fetchPlayerCommunicationSaga);
    yield takeEvery(
        UPDATE_PLAYER_COMMUNICATION,
        updatePlayerCommunicationSaga
    );
    yield takeEvery(
        GET_PLAYER_COMMUNICATION_PHONE_LIST,
        fetchPlayerCommunicationPhoneListSaga
    );
    yield takeEvery(
        GET_PLAYER_COMMUNICATION_EMAIL_LIST,
        fetchPlayerCommunicationEmailListSaga
    );
    yield takeEvery(
        SEND_CODE_PHONE_PLAYER_COMMUNICATION,
        sendCodePhonePlayerCommunicationSaga
    );
    yield takeEvery(
        SEND_CODE_EMAIL_PLAYER_COMMUNICATION,
        sendCodeEmailPlayerCommunicationSaga
    );
}

export default playerCommunicationSaga;
