import {
    GET_PLAYER_VERIFICATION,
    GET_PLAYER_VERIFICATION_SUCCESS,
    GET_PLAYER_VERIFICATION_FAIL,
    RESET_PLAYER_VERIFICATION_ALERTS_ERRORS,
    SET_EDIT_PLAYER_VERIFICATION,
    UPDATE_PLAYER_VERIFICATION,
    UPDATE_PLAYER_VERIFICATION_SUCCESS,
    UPDATE_PLAYER_VERIFICATION_FAIL,
    GET_PLAYER_VERIFICATION_HISTORY_LIST,
    GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS,
    GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL,
    RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS,
    GET_PLAYER_SOF_AGREEMENT,
    GET_PLAYER_SOF_AGREEMENT_SUCCESS,
    GET_PLAYER_SOF_AGREEMENT_FAIL,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS,
    GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS,
    GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL,
} from './actionTypes';
import i18n from '../../../i18n';

const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoadingVerification: false,
    errorVerification: null,
    verification: null,
    isEdit: false,

    isLoadingHistoryList: false,
    errorHistoryList: null,
    // used like infinite scroll list
    historyList: [],
    totalHistoryList: 0,
    queryHistoryList: { ...DEFAULT_QUERY },

    successAlert: '',

    isUpdateSofAgreement: false,
    errorSofAgreement: false,

    isGenerateAgreementDoc: false,
    errorGenerateAgreementDoc: false,
};

const PlayerVerification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_VERIFICATION:
            return {
                ...state,
                isLoadingVerification: true,
                errorVerification: null
            };

        case GET_PLAYER_VERIFICATION_SUCCESS:
            return {
                ...state,
                verification: action.payload,
                isLoadingVerification: false
            };

        case GET_PLAYER_VERIFICATION_FAIL:
            return {
                ...state,
                errorVerification: action.payload,
                isLoadingVerification: false
            };

        case RESET_PLAYER_VERIFICATION_ALERTS_ERRORS:
            return { ...state, errorVerification: null, successAlert: '', isLoadingVerification: false };

        case SET_EDIT_PLAYER_VERIFICATION:
            return { ...state, isEdit: action.payload };

        case UPDATE_PLAYER_VERIFICATION:
            return {
                ...state,
                isLoadingVerification: true,
                errorVerification: null
            };

        case UPDATE_PLAYER_VERIFICATION_SUCCESS:
            return {
                ...state,
                verification: {
                    ...state.verification,
                    ...action.payload.dto,
                    kycStatus: {
                        ...state.verification.kycStatus,
                        ...action.payload.dto.kycStatus
                    }
                },
                isLoadingVerification: false,
                successAlert: i18n.t('crm.alerts.verificationEdited')
            };

        case UPDATE_PLAYER_VERIFICATION_FAIL:
            return {
                ...state,
                errorVerification: action.payload,
                isLoadingVerification: false
            };

        case GET_PLAYER_VERIFICATION_HISTORY_LIST:
            return {
                ...state,
                queryHistoryList: {
                    ...state.queryHistoryList,
                    ...action.payload.query
                },
                isLoadingHistoryList: true,
                errorHistoryList: null
            };

        case GET_PLAYER_VERIFICATION_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                historyList: [
                    ...action.payload.prevHistoryList,
                    ...action.payload.result
                ],
                totalHistoryList: action.payload.total,
                isLoadingHistoryList: false
            };

        case GET_PLAYER_VERIFICATION_HISTORY_LIST_FAIL:
            return {
                ...state,
                errorHistoryList: action.payload,
                isLoadingHistoryList: false,
                isLoadingVerification: false,
            };

        case RESET_PLAYER_VERIFICATION_HISTORY_LIST_ERRORS:
            return { ...state, errorHistoryList: null, isLoadingVerification: false };

        case GET_PLAYER_SOF_AGREEMENT:
            return {
                ...state,
                isUpdateSofAgreement: true,
                errorSofAgreement: null,
            };

        case GET_PLAYER_SOF_AGREEMENT_SUCCESS:
            return {
                ...state,
                isUpdateSofAgreement: false,
                isLoadingVerification: false
            };

        case GET_PLAYER_SOF_AGREEMENT_FAIL:
            return {
                ...state,
                errorSofAgreement: action.payload,
                isUpdateSofAgreement: false,
                isLoadingVerification: false
            };

        case GET_PLAYER_SELF_EXCLUSION_AGREEMENT:
            return {
                ...state,
                isGenerateAgreementDoc: true,
                errorGenerateAgreementDoc: null
            };

        case GET_PLAYER_SELF_EXCLUSION_AGREEMENT_SUCCESS:
            return {
                ...state,
                isGenerateAgreementDoc: false
            };

        case GET_PLAYER_SELF_EXCLUSION_AGREEMENT_FAIL:
            return {
                ...state,
                errorGenerateAgreementDoc: action.payload,
                isGenerateAgreementDoc: false,
                isLoadingVerification: false,
            };

        case GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT:
            return {
                ...state,
                isGenerateAgreementDoc: true,
                errorGenerateAgreementDoc: null
            };

        case GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_SUCCESS:
            return {
                ...state,
                isGenerateAgreementDoc: false,
                isLoadingVerification: false
            };

        case GET_PLAYER_ACCOUNT_RECOVERY_AGREEMENT_FAIL:
            return {
                ...state,
                errorGenerateAgreementDoc: action.payload,
                isGenerateAgreementDoc: false,
                isLoadingVerification: false
            };

        default:
            return state;
    }
};

export default PlayerVerification;
