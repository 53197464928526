import React, { useEffect } from 'react';
import { Card, Container } from 'reactstrap';
import Breadcrumb from '../../common/components/Breadcrumb';

import './agent-view.scss';

import MetaTags from 'react-meta-tags';
import i18n from '../../i18n';
import EmployeesTable from './EmployeesTable';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/components/Loader';
import AvatarIcon from './AvatarIcon';
import ClipboardButton from '../../common/cellData/CopyButton';
import useAlertService from '../../hooks/useAlertService';
import useList from '../../hooks/useList';
import {
    getAgentEmployeeList,
    resetAgentEmployeeListErrors
} from '../../store/actions';
import CustomPagination from "../../common/tables/CustomPagination";

function AgentEmployeesPage() {
    const { agentSelf } = useSelector(state => state.AgentSelf);
    const alertService = useAlertService();
    const dispatch = useDispatch();
    const { isLoading, error, employeeList, total, query } = useSelector(
        state => state.AgentEmployees
    );

    const { pageCount, activePage, handleChangePage } = useList(
        query => dispatch(getAgentEmployeeList({ id: agentSelf.id, query })),
        total,
        query
    );

    useEffect(() => {
        if (error) {
            alertService.showError(error);
            dispatch(resetAgentEmployeeListErrors());
        }
    }, [error]);

    useEffect(() => {
        if (agentSelf?.id) {
            dispatch(getAgentEmployeeList({ id: agentSelf.id, query }));
        }
    }, [agentSelf?.id]);



    return (
        <div className="page-content agent-view-page">
            <MetaTags>
                <title>{i18n.t('admin.agentView')} - {process.env.REACT_APP_APP_NAME}</title>
            </MetaTags>

            <Container fluid>
                <Breadcrumb
                    title={i18n.t('agent.agentView')}
                    titleLink={'/agent-view'}
                    breadcrumbItem={i18n.t('agent.employeeList')}
                />

                {agentSelf ? (
                    <>
                        <Card className="page-card agent-list-card-header">
                            <div className="d-flex flex-wrap">
                                <AvatarIcon
                                    avatarData={null}
                                    firstName={agentSelf.firstName}
                                    lastName={agentSelf.lastName}
                                    size="sm"
                                />
                                <div className="ms-3 me-3 mt-auto mb-auto font-w-600 font-size-15">{`${agentSelf.firstName} ${agentSelf.lastName}`}</div>

                                <div className="mt-auto mb-auto d-flex font-size-15">
                                    <div className="me-2 mt-auto mb-auto">
                                        ID {agentSelf.id}
                                    </div>
                                    <ClipboardButton text={agentSelf.id} />
                                </div>
                            </div>
                        </Card>
                        <Card className="page-card">
                            <EmployeesTable
                                isLoading={isLoading}
                                list={employeeList}
                            />
                        </Card>
                    </>
                ) : (
                    <Loader />
                )}

                {(pageCount > 1) &&
                    <CustomPagination
                        isDisabled={isLoading}
                        pageCount={pageCount}
                        activePage={activePage}
                        onChange={handleChangePage}
                    />
                }
            </Container>
        </div>
    );
}

export default React.memo(AgentEmployeesPage);
