import { useEffect } from 'react';

export default function useClickOutside(ref, callback) {
    useEffect(() => {
        function onClickOutside(e) {
            if (
                callback &&
                ref.current !== null &&
                !ref.current?.contains(e.target)
            ) {
                callback();
            }
        }

        document.addEventListener('click', onClickOutside, true);
        return () => {
            document.removeEventListener('click', onClickOutside, true);
        };
    }, [callback, ref]);
}
