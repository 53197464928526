import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'reactstrap';

import i18n from '../../../i18n';
import thinCross from '../../../assets/images/layout/grey-close.svg';
import thinSubmit from '../../../assets/images/layout/thin-submit.svg';

const BottomButtons = props => {
    const { onDecline, onApprove, className } = props;

    return (
            <Col className=" col-12 two-btns className" style={{paddingBottom: '30px'}}>
                <Button className="btn outlined" outline onClick={onDecline}>
                    <img src={thinCross} width={12} height={12} />
                    {i18n.t('finance.decline')}
                </Button>
                <Button className="btn filled" onClick={onApprove}>
                    <img src={thinSubmit} width={16} height={16} />
                    {i18n.t('finance.approve')}
                </Button>
            </Col>
    );

};

BottomButtons.propTypes = {
    onDecline: PropTypes.func,
    onApprove: PropTypes.func,
    className: PropTypes.string
};

BottomButtons.defaultProps = {
    onDecline: () => {},
    onApprove: () => {},
    className: ''
};

export default BottomButtons;
