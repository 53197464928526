import {
    SEARCH_PLAYER_LIST,
    SEARCH_AFFILIATE_LIST,
    SEARCH_DEPOSIT_LIST,
    SEARCH_WITHDRAWAL_LIST,
    SEARCH_LIST_SUCCESS,
    SEARCH_LIST_FAIL,
    RESET_SEARCH_LIST_ERRORS
} from './actionTypes';

export const searchPlayerList = payload => ({
    type: SEARCH_PLAYER_LIST,
    payload
});

export const searchAffiliateList = payload => ({
    type: SEARCH_AFFILIATE_LIST,
    payload
});

export const searchDepositList = payload => ({
    type: SEARCH_DEPOSIT_LIST,
    payload
});

export const searchWithdrawalList = payload => ({
    type: SEARCH_WITHDRAWAL_LIST,
    payload
});

export const searchListSuccess = payload => ({
    type: SEARCH_LIST_SUCCESS,
    payload
});

export const searchListFail = payload => ({
    type: SEARCH_LIST_FAIL,
    payload
});

export const resetSearchListErrors = payload => ({
    type: RESET_SEARCH_LIST_ERRORS,
    payload
});
