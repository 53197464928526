import { call, put, takeEvery } from '@redux-saga/core/effects';

import { GET_NOTE, CHANGE_NOTE, GET_NOTE_LIST, ADD_NOTE, REMOVE_NOTE } from './actionTypes';

import {
    changeNoteApi,
    removeNoteApi,
    getNoteApi,
    addNoteApi,
    getNotesListApi
} from '../../helpers/backend_helper';

import {
    noteError,
    getNoteSuccess,
    changeNoteSuccess,
    removeNoteSuccess,
    removeNoteFail,
    getNotesListSuccess,
    addNoteAtListSuccess,
    getNotesListFail
} from './actions';

function* fetchNotesList({ payload }) {
    try {
        const response = yield call(getNotesListApi, payload);

        yield put(getNotesListSuccess(response.data));
    } catch (error) {
        yield put(getNotesListFail(error));
    }
}

function* addNote({ payload: { body } }) {
    try {
        const response = yield call(addNoteApi, body);

        if (response) {
            if (!!response.statusCode) {
                yield put(noteError(response));
            } else {
                yield put(addNoteAtListSuccess(response));
            }
        } else {
            yield put(noteError(response));
        }
    } catch (error) {
        yield put(noteError(error));
    }
}

function* fetchNote({ payload: { id } }) {
    try {
        const response = yield call(getNoteApi, id);

        if (response) {
            yield put(getNoteSuccess(response.data));
        } else {
            yield put(noteError(response));
        }
    } catch (error) {
        yield put(noteError(error));
    }
}

function* changeNote({ payload: body }) {
    try {
        const response = yield call(changeNoteApi, body);

        if (response.success) {
            yield put(changeNoteSuccess(body));
        } else {
            yield put(noteError(response));
        }
    } catch (error) {
        yield put(noteError(error));
    }
}

function* removeNote({ payload: { id } }) {
    try {
        const response = yield call(removeNoteApi, id);

        if (response.success) {
            yield put(removeNoteSuccess(id));
        } else {
            yield put(removeNoteFail(response));
        }
    } catch (error) {
        yield put(noteError(error));
    }
}

function* notesSaga() {
    yield takeEvery(GET_NOTE, fetchNote);
    yield takeEvery(GET_NOTE_LIST, fetchNotesList);
    yield takeEvery(ADD_NOTE, addNote);
    yield takeEvery(CHANGE_NOTE, changeNote);
    yield takeEvery(REMOVE_NOTE, removeNote);
}

export default notesSaga;
