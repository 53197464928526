import { takeEvery, put, call } from 'redux-saga/effects';

import {
    CREATE_MANUAL_ADJUSTMENT,
    CREATE_RETAIL_MANUAL_ADJUSTMENT,
    MASS_CASHBACK_APPLY,
    CASHBACK_APPLY,
    DOWNLOAD_INACTIVITY_FEE_TEMPLATE,
    MASS_INACTIVITY_FEE_APPLY,
} from './actionTypes';
import {
    createManualAdjustmentSuccess,
    createManualAdjustmentFail,
    massCashbackApplySuccess,
    massCashbackApplyFail,
    downloadTemplateInactivityFeeSuccess,
    downloadTemplateInactivityFeeFail, massInactivityFeeApplyFeeSuccess, massInactivityFeeApplyFail,
} from './actions';
import {
    createManualAdjustments,
    createRetailManualAdjustments,
    massCashbackApply,
    createDepositManualAdjustments,
    createWithdrawalManualAdjustments,
    downloadTemplateInactivityFeeApi, massInactivityFeeApplyApi,
} from '../../../helpers/walletManualApi';

function* createManualAdjustmentsSaga({ payload }) {
    try {
        let response;
        switch (payload.commentType) {
            case 'ManualWithdrawal':
                response = yield call(createWithdrawalManualAdjustments, payload);
                break;
            case 'ManualDeposit':
                response = yield call(createDepositManualAdjustments, payload);
                break;
            default:
                response = yield call(createManualAdjustments, payload);
        }

        yield put(createManualAdjustmentSuccess(response.data));
    } catch (error) {
        yield put(createManualAdjustmentFail(error));
    }
}

function* createRetailManualAdjustmentsSaga({ payload }) {
    try {
        const response = yield call(createRetailManualAdjustments, payload);

        yield put(createManualAdjustmentSuccess(response.data));
    } catch (error) {
        yield put(createManualAdjustmentFail(error));
    }
}

function* massCashbackApplySaga({ payload }) {
    try {
        const response = yield call(massCashbackApply, payload);

        yield put(massCashbackApplySuccess(response.data));
    } catch (error) {
        yield put(massCashbackApplyFail(error));
    }
}

function* downloadTemplateInactivityFeeSaga({ payload }) {
    try {
        const response = yield call(downloadTemplateInactivityFeeApi);

        yield put(downloadTemplateInactivityFeeSuccess(response.data));
    } catch (error) {
        yield put(downloadTemplateInactivityFeeFail(error));
    }
}

function* massInactivityFeeApplySaga({ payload }) {
    try {
        const response = yield call(massInactivityFeeApplyApi, payload);

        yield put(massInactivityFeeApplyFeeSuccess(response.data));
    } catch (error) {
        yield put(massInactivityFeeApplyFail(error));
    }
}

function* playerManualAdjustmentsSaga() {
    yield takeEvery(CREATE_MANUAL_ADJUSTMENT, createManualAdjustmentsSaga);
    yield takeEvery(CREATE_RETAIL_MANUAL_ADJUSTMENT, createRetailManualAdjustmentsSaga);
    yield takeEvery(MASS_CASHBACK_APPLY, massCashbackApplySaga);
    yield takeEvery(MASS_INACTIVITY_FEE_APPLY, massInactivityFeeApplySaga);
    yield takeEvery(DOWNLOAD_INACTIVITY_FEE_TEMPLATE, downloadTemplateInactivityFeeSaga);
}

export default playerManualAdjustmentsSaga;
