const REQUIRED_FIELDS_FOR_CMS = {
    email: 'email',
    firstName: 'firstName',
    gender: 'gender',
    language: 'language',
    phone: 'phone',
    lastName: 'lastName',
    birth_date: 'birthDate',
    currency: 'currency',
    address: 'address',
    nickname: 'nickName',
    citizenship: 'citizenship'
};

const UNIQUE_FIELDS_FOR_CMS = {
    email: 'email',
    phone: 'phone',
    nickname: 'nickName'
};

const KYC = {
    Identity: 'Identity',
    Address: 'Address',
    WorldCheck: 'WorldCheck',
};

const TAB = {
    SECURITY_LIMITS: 'SECURITY_LIMITS',
    CRM_COMMUNICATION: 'CRM_COMMUNICATION',
    ADVANCE: 'ADVANCE',
    CONFIGURATION_FINANCIAL: 'CONFIGURATION_FINANCIAL'
};

const PLAYERS_COLORS = [
    { name: 'NoColor', value: '#FFFFFF' },
    { name: 'DarkBlue', value: '#00008b' },
    { name: 'Blue', value: '#1515cf' },
    { name: 'Purple', value: '#6a0dad' },
    { name: 'Green', value: '#008000' },
    { name: 'Carmine', value: '#960018' },
    { name: 'Gray', value: '#808080' },
    { name: 'Pink', value: '#f8adbb' },
    { name: 'Red', value: '#FF0000' },
    { name: 'Magenta', value: '#FF00FF' },
    { name: 'Orange', value: '#FFA500' },
    { name: 'Yellow', value: '#FFFF00' }
];

export {
    PLAYERS_COLORS,
    REQUIRED_FIELDS_FOR_CMS,
    UNIQUE_FIELDS_FOR_CMS,
    KYC,
    TAB
};
