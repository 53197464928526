export const GET_PLAYER_PROMOTIONS_LIST = 'GET_PLAYER_PROMOTIONS_LIST';
export const GET_PLAYER_PROMOTIONS_LIST_SUCCESS =
    'GET_PLAYER_PROMOTIONS_LIST_SUCCESS';
export const GET_PLAYER_PROMOTIONS_LIST_FAIL =
    'GET_PLAYER_PROMOTIONS_LIST_FAIL';

export const RESET_PLAYER_PROMOTIONS_LIST_ERRORS =
    'RESET_PLAYER_PROMOTIONS_LIST_ERRORS';

export const APPLY_PLAYER_PROMOTION = 'APPLY_PLAYER_PROMOTION';
export const APPLY_PLAYER_PROMOTION_SUCCESS =
    'APPLY_PLAYER_PROMOTION_SUCCESS';
export const APPLY_PLAYER_PROMOTION_FAIL =
    'APPLY_PLAYER_PROMOTION_FAIL';

export const CLOSE_PLAYER_PROMOTION = 'CLOSE_PLAYER_PROMOTION';
export const CLOSE_PLAYER_PROMOTION_SUCCESS =
    'CLOSE_PLAYER_PROMOTION_SUCCESS';
export const CLOSE_PLAYER_PROMOTION_FAIL =
    'CLOSE_PLAYER_PROMOTION_FAIL';

export const GET_PLAYER_SPORT_PROMOTIONS_LIST = 'GET_PLAYER_SPORT_PROMOTIONS_LIST';
export const GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS =
    'GET_PLAYER_SPORT_PROMOTIONS_LIST_SUCCESS';
export const GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL =
    'GET_PLAYER_SPORT_PROMOTIONS_LIST_FAIL';

export const APPLY_PLAYER_SPORT_PROMOTION = 'APPLY_PLAYER_SPORT_PROMOTION';
export const APPLY_PLAYER_SPORT_PROMOTION_SUCCESS =
    'APPLY_PLAYER_SPORT_PROMOTION_SUCCESS';
export const APPLY_PLAYER_SPORT_PROMOTION_FAIL =
    'APPLY_PLAYER_SPORT_PROMOTION_FAIL';

export const CLOSE_PLAYER_SPORT_PROMOTION = 'CLOSE_PLAYER_SPORT_PROMOTION';
export const CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS =
    'CLOSE_PLAYER_SPORT_PROMOTION_SUCCESS';
export const CLOSE_PLAYER_SPORT_PROMOTION_FAIL =
    'CLOSE_PLAYER_SPORT_PROMOTION_FAIL';

export const RESET_PROMOTION_ALERTS_ERRORS =
    "RESET_PROMOTION_ALERTS_ERRORS"
