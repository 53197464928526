import React from 'react';
import PropTypes from 'prop-types';
import './wrappers.scss';
import union_5 from '../../assets/images/common/Union (5).svg';

function InputWrapper({
    label,
    errorMessage,
    className,
    noErrorMessage,
    children,
    style
}) {
    return (
        <div
            className={`input-wrapper ${errorMessage ? 'has-error' : ''} ${
                className ? className : ''
            }`}
            style={style}
        >
            <span
                className={`input-wrapper__label ${
                    label && !errorMessage ? '' : 'hidden'
                }`}
            >
                {label}
            </span>

            <span
                className={`input-wrapper__error ${
                    errorMessage && !noErrorMessage ? '' : 'hidden'
                }`}
            >
                <img src={union_5} alt="" />
                <span>{errorMessage}</span>
            </span>

            {children}
        </div>
    );
}

InputWrapper.propTypes = {
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    noErrorMessage: PropTypes.bool
};

export default React.memo(InputWrapper);
