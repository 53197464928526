import React from 'react';
import PropTypes from 'prop-types';
import useHandleEsc from '../../../../common/hooks/useHandleEsc';
import close from '../../../../assets/images/common/close 1.svg';
import i_51 from '../../../../assets/images/common/Frame 176 (1).svg';
import clsx from "clsx"

function VerticalButtonsPanel({ onCancel, onSave, className }) {
    useHandleEsc(onCancel);

    return (
        <div className={clsx("buttons-column", className)}>
            {onCancel !== 'none' && (
                <button
                    className="btn btn-rounded btn-light"
                    onClick={onCancel}
                >
                    <img className='button-icon' src={close} alt="cancel" />
                </button>
            )}

            <button className="btn btn-rounded btn-primary" onClick={onSave}>
                <img className='button-icon' src={i_51} alt="save" />
            </button>
        </div>
    );
}

VerticalButtonsPanel.propTypes = {
    onCancel: PropTypes.oneOf([PropTypes.func, 'none']).isRequired,
    onSave: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default React.memo(VerticalButtonsPanel);
