import {
  GET_PROMOTIONS,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAIL,
  GET_PROMOTION_TEMPLATES,
  GET_PROMOTION_TEMPLATES_SUCCESS,
  GET_PROMOTION_TEMPLATES_FAIL,
  GET_BONUS_WAGERING_SMALL_DATA,
  GET_BONUS_WAGERING_SMALL_DATA_SUCCESS,
  GET_BONUS_WAGERING_SMALL_DATA_FAIL,
  CLEAR_CURRENT_PROMOTION,
  GET_PROMOTION,
  GET_PROMOTION_SUCCESS,
  GET_PROMOTION_FAIL,
  UPDATE_PROMOTION,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAIL,
  CREATE_PROMOTION,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAIL,
  RESET_PROMOTION_ALERTS_ERRORS,
  GET_SPORT_PROMOTIONS,
  GET_SPORT_PROMOTIONS_SUCCESS,
  GET_SPORT_PROMOTIONS_FAIL,
  GET_SPORT_PROMOTION,
  GET_SPORT_PROMOTION_SUCCESS,
  GET_SPORT_PROMOTION_FAIL,
  CREATE_SPORT_PROMOTION,
  CREATE_SPORT_PROMOTION_SUCCESS,
  CREATE_SPORT_PROMOTION_FAIL,
  UPDATE_SPORT_PROMOTION,
  UPDATE_SPORT_PROMOTION_SUCCESS,
  UPDATE_SPORT_PROMOTION_FAIL,
} from "./actionTypes";

export const getPromotions = (page = 1) => ({
  type: GET_PROMOTIONS,
  payload: page,
});

export const getPromotionsSuccess = data => ({
  type: GET_PROMOTIONS_SUCCESS,
  payload: data,
});

export const getPromotionsFail = error => ({
  type: GET_PROMOTIONS_FAIL,
  payload: error,
});

export const getBonusWageringSmallData = () => ({
  type: GET_BONUS_WAGERING_SMALL_DATA,
});

export const getBonusWageringSmallDataSuccess = data => ({
  type: GET_BONUS_WAGERING_SMALL_DATA_SUCCESS,
  payload: data,
});

export const getBonusWageringSmallDataFail = error => ({
  type: GET_BONUS_WAGERING_SMALL_DATA_FAIL,
  payload: error,
});

export const getPromotionTemplates = (page = 1) => ({
  type: GET_PROMOTION_TEMPLATES,
  payload: page,
});

export const getPromotionTemplatesSuccess = data => ({
  type: GET_PROMOTION_TEMPLATES_SUCCESS,
  payload: data,
});

export const getPromotionTemplatesFail = error => ({
  type: GET_PROMOTION_TEMPLATES_FAIL,
  payload: error,
});

export const clearCurrentPromotion = () => ({
  type: CLEAR_CURRENT_PROMOTION,
});

export const getPromotion = (id) => ({
  type: GET_PROMOTION,
  payload: id,
});

export const getPromotionSuccess = data => ({
  type: GET_PROMOTION_SUCCESS,
  payload: data,
});

export const getPromotionFail = error => ({
  type: GET_PROMOTION_FAIL,
  payload: error,
});

export const updatePromotion = (payload) => ({
  type: UPDATE_PROMOTION,
  payload,
});

export const updatePromotionSuccess = data => ({
  type: UPDATE_PROMOTION_SUCCESS,
  payload: data,
});

export const updatePromotionFail = error => ({
  type: UPDATE_PROMOTION_FAIL,
  payload: error,
});

export const createPromotion = (payload) => ({
  type: CREATE_PROMOTION,
  payload,
});

export const createPromotionSuccess = data => ({
  type: CREATE_PROMOTION_SUCCESS,
  payload: data,
});

export const createPromotionFail = error => ({
  type: CREATE_PROMOTION_FAIL,
  payload: error,
});

export const resetPromotionAlertsErrors = payload => ({
  type: RESET_PROMOTION_ALERTS_ERRORS,
  payload
});

export const getSportPromotions = (page = 1) => ({
  type: GET_SPORT_PROMOTIONS,
  payload: page,
});

export const getSportPromotionsSuccess = data => ({
  type: GET_SPORT_PROMOTIONS_SUCCESS,
  payload: data,
});

export const getSportPromotionsFail = error => ({
  type: GET_SPORT_PROMOTIONS_FAIL,
  payload: error,
});

export const getSportPromotion = (id) => ({
  type: GET_SPORT_PROMOTION,
  payload: id,
});

export const getSportPromotionSuccess = data => ({
  type: GET_SPORT_PROMOTION_SUCCESS,
  payload: data,
});

export const getSportPromotionFail = error => ({
  type: GET_SPORT_PROMOTION_FAIL,
  payload: error,
});

export const updateSportPromotion = (payload) => ({
  type: UPDATE_SPORT_PROMOTION,
  payload,
});

export const updateSportPromotionSuccess = data => ({
  type: UPDATE_SPORT_PROMOTION_SUCCESS,
  payload: data,
});

export const updateSportPromotionFail = error => ({
  type: UPDATE_SPORT_PROMOTION_FAIL,
  payload: error,
});

export const createSportPromotion = (payload) => ({
  type: CREATE_SPORT_PROMOTION,
  payload,
});

export const createSportPromotionSuccess = data => ({
  type: CREATE_SPORT_PROMOTION_SUCCESS,
  payload: data,
});

export const createSportPromotionFail = error => ({
  type: CREATE_SPORT_PROMOTION_FAIL,
  payload: error,
});
