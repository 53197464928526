import React from "react";

const MarkedText = ({ text, mark }) => {

    const markIndexStart = text?.toLowerCase()?.indexOf(mark.toLowerCase());
    const originalMark = text?.slice(markIndexStart, markIndexStart + mark.length);
    const textArray = text?.toLowerCase().split(mark.toLowerCase());

    return (
        <>
            {textArray.map((item, index) => {
                if (index === textArray.length - 1) {
                    return <React.Fragment key={index}><span className="white-space">{item}</span></React.Fragment>;
                } else {
                    return (
                        <React.Fragment key={index}>
                            <span className="white-space">{item}</span>
                            <span
                                key={index}
                                className="font-w-600 white-space"
                            >{`${originalMark}`}</span>
                        </React.Fragment>
                    );
                }
            })}
        </>
    );
};

export default MarkedText;
