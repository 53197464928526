import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import './alerts.scss';
import i18n from '../../i18n';

function CloseIcon({ color, onClick }) {
    return (
        <svg
            onClick={onClick}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.81538 12.6669C3.69244 12.6669 3.5695 12.6201 3.47609 12.526C3.28857 12.3385 3.28857 12.0344 3.47609 11.8469L11.8489 3.47414C12.0364 3.28662 12.3404 3.28662 12.5279 3.47414C12.7155 3.66166 12.7155 3.96567 12.5279 4.15331L4.15526 12.526C4.06115 12.6195 3.93821 12.6669 3.81538 12.6669Z"
                fill={color}
            />
            <path
                d="M12.1888 12.6669C12.0658 12.6669 11.943 12.6201 11.8495 12.526L3.47609 4.15331C3.28857 3.96567 3.28857 3.66166 3.47609 3.47414C3.66361 3.28662 3.96763 3.28662 4.15526 3.47414L12.5279 11.8469C12.7155 12.0344 12.7155 12.3385 12.5279 12.526C12.4338 12.6195 12.311 12.6669 12.1888 12.6669Z"
                fill={color}
            />
        </svg>
    );
}

export default function AlertTemplate({ style, options, message, close }) {
    const { color, cssClass } = useMemo(() => {
        switch (options.type) {
            case 'success':
                return {
                    color: '#37A57E',
                    cssClass: 'app-alert app-alert-success'
                };
            case 'error':
                return {
                    color: '#E44F73',
                    cssClass: 'app-alert app-alert-error'
                };
            default:
                return {
                    color: '#5f5f8b',
                    cssClass: 'app-alert app-alert-info'
                };
        }
    }, [options.type]);

    let title = '';
    let content = message;
    const titleIndex = message.indexOf(':');
    if (titleIndex >= 0) {
        title = message.substring(0, titleIndex + 1);
        content = message.substring(titleIndex + 2);
    }

    return (
        <div style={style} className={cssClass}>
            {title && <div className="app-alert__title">{title}</div>}

            <div className="app-alert__message">{content}</div>

            <CloseIcon color={color} onClick={close} />
        </div>
    );
}

AlertTemplate.propTypes = {
    style: PropTypes.object,
    options: PropTypes.object,
    message: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired
};
