import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_PLAYER_ATTRIBUTION_LIST } from './actionTypes';
import {
    getPlayerAttributionListSuccess,
    getPlayerAttributionListFail
} from './actions';
import { getPlayerAttributionListApi } from '../../../helpers/playerApi';

function* fetchPlayerAttributionListSaga({ payload }) {
    try {
        const response = yield call(getPlayerAttributionListApi, payload);

        yield put(getPlayerAttributionListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerAttributionListFail(error));
    }
}

function* playerAttributionsSaga() {
    yield takeEvery(GET_PLAYER_ATTRIBUTION_LIST, fetchPlayerAttributionListSaga);
}

export default playerAttributionsSaga;
