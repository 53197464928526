import adminES from './admin.json';
import agentES from './agent.json';
import affiliatesES from './affiliates.json';
import attributionsES from './attributions.json';
import auditES from './audit.json';
import authenticationES from './authentication.json';
import brandES from './brand.json';
import commonES from './common.json';
import contentES from './content.json';
import countryES from './country.json';
import crmTranslationES from './crm.json';
import dashboardES from './dashboard.json';
import documentES from './document.json';
import engagementES from './engagement.json';
import errorsES from './errors.json';
import filterES from './filter.json';
import financeES from './finance.json';
import languageES from './language.json';
import notificationsES from './notifications.json';
import permissionsES from './permissions.json';
import promotionsES from './promotions.json';
import reportsES from './reports.json';
import retailES from './retail.json';
import rolesES from './roles.json';
import statesOfMexicoES from './statesOfMexico.json';
import workflowES from './workflow.json';
import pageTitlesES from './pageTitles.json'

export default {
    ...adminES,
    ...agentES,
    ...affiliatesES,
    ...attributionsES,
    ...auditES,
    ...authenticationES,
    ...brandES,
    ...commonES,
    ...contentES,
    ...countryES,
    ...crmTranslationES,
    ...dashboardES,
    ...documentES,
    ...engagementES,
    ...errorsES,
    ...filterES,
    ...financeES,
    ...languageES,
    ...notificationsES,
    ...permissionsES,
    ...promotionsES,
    ...reportsES,
    ...retailES,
    ...rolesES,
    ...statesOfMexicoES,
    ...workflowES,
    ...pageTitlesES,
};
