import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toggleRightSidebar } from '../../store/actions';
import { addCurrentFilter } from '../../store/filters/action';
import { AgentDropdown } from '../AgentDropdown';
import { SearchBar } from '../SearchBar';

import collapseSideBar from '../../assets/images/layout/collapse-side-bar.svg';
import '../../assets/scss/components/layout.scss';
import { withRouter } from 'react-router-dom';
import {
    FILTER_MENU_SELECT_LIST_VALUES,
    FILTER_SIMPLE_BOOL_COMMON_VALUES
} from '../../constants/Filter';
import { authProtectedRoutes } from "../../routes";
import breadcrumbsPages from "../../pages/BreadcrumbsPages/breadcrumbsPages";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: '',
            isSearch: false,
            open: false,
            position: 'right',
            width: window?.innerWidth,
            showSearch: window?.innerWidth <= 600 ? false : true,
            screenOrientation: 'portrait',
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.openActiveUsers = this.openActiveUsers.bind(this);
        this.menuBtnRef = createRef();
        this.updateDimensions = this.updateDimensions.bind(this);
        this.updatedScreenOrientation = this.updatedScreenOrientation.bind(this);
    }
    componentDidMount() {
        this.findTitleByRoute();
        window.addEventListener('resize', this.updateDimensions);
        window.matchMedia("(orientation: portrait)").addEventListener("change", this.updatedScreenOrientation);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.findTitleByRoute();
        }
        if (prevState.screenOrientation !== this.state.screenOrientation) {
            this.setState({ showSearch: window.innerWidth > 600 });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.matchMedia("(orientation: portrait)").removeEventListener("change", this.updatedScreenOrientation);
      }

    findTitleByRoute() {
        const defineRoute = authProtectedRoutes.find(r => r.path === this.props.location.pathname);
        this.setState((prev) => ({
            ...prev,
            pageTitle: defineRoute ? defineRoute.title : ''
        }))
    }

    updatedScreenOrientation = (e) => {
        this.setState({screenOrientation: e.matches});
    }

    updateDimensions = () => {
        const { width } = this.state;
        const currentWidth = window.innerWidth;

        if (currentWidth !== width) {
            this.state.width <= 600 ? this.setState({ showSearch: false }) : this.setState({ showSearch: true });
            this.setState({ width: window.innerWidth });
        }
    };

    toggleMenu() {
        this.props.toggleMenuCallback();
        this.menuBtnRef?.current && this.menuBtnRef.current.blur();
    }

    toggleRightDrawer() {
        this.setState({ position: 'right' });
        this.setState({ open: !this.state.open });
    }
    closeDrawer() {
        this.setState({ open: false });
    }
    onDrawerClose() {
        this.setState({ open: false });
    }

    toggleRightbar() {
        this.props.toggleRightSidebar();
    }

    toggleSearch() {
        this.setState({ showSearch: !this.state.showSearch });

        if (window.innerWidth <= 380) {
            const pageInner = document.getElementById("layout-wrapper");
            pageInner.classList.toggle("mobile-search-isOpen");
        }
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    openActiveUsers() {
        this.props.addCurrentFilter([
            {
                key: FILTER_MENU_SELECT_LIST_VALUES.IS_ONLINE,
                value: FILTER_SIMPLE_BOOL_COMMON_VALUES.YES
            }
        ]);

        this.props.history.push('/player-list');
    }

    render() {
        const { activeUsersCount } = this.props;
        const { pageTitle } = this.state;

        let lastScrollTop = 0;

        window.addEventListener("scroll", function() {
            let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScroll > lastScrollTop) {
                // Scroll down
                document.querySelector(".navbar-header").classList.add("page-topbar");
            } else {
                // Scroll up
                document.querySelector(".navbar-header").classList.remove("page-topbar");
            }
            lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        }, false);

        return (
            <React.Fragment>
                <header id="page-topbar" className="page-topbar">
                    <div className="navbar-header" id={'navbar-header'}>
                        <button
                            type="button"
                            onClick={this.toggleMenu}
                            className="btn vertical-menu-btn shadow-none"
                            id="vertical-menu-btn"
                            ref={this.menuBtnRef}
                        >
                            <img
                                src={collapseSideBar}
                                alt=""
                                className="img-fluid"
                            />
                        </button>

                        <div className='page-title ml-8 page-header-title'>
                            {pageTitle
                                ? this.props.i18n.t(`pageTitles.${pageTitle}`)
                                : breadcrumbsPages(this.props.location.pathname)
                            }
                        </div>

                        <div className="d-flex align-items-center">
                            {
                                this.state.showSearch && (
                                    <div className="search-bar">
                                        <SearchBar />
                                    </div>
                                )
                            }
                            {
                                this.state.width <= 600 && (
                                    <button className="search-bar__toggle" onClick={this.toggleSearch}></button>
                                )
                            }
                            <div className="right-part ml-10">
                                <AgentDropdown
                                    activeUsersCount={activeUsersCount}
                                    openActiveUsers={this.openActiveUsers}
                                />
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, {
    toggleRightSidebar,
    addCurrentFilter
})(withRouter(withTranslation()(Header)));

Header.propTypes = {
    t: PropTypes.any,
    toggleMenuCallback: PropTypes.any,
    toggleRightSidebar: PropTypes.func,
    addCurrentFilter: PropTypes.func,
    activeUsersCount: PropTypes.number
};

Header.defaultPros = {
    activeUsersCount: 0
};
