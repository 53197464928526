import {
    GET_AGENT_SELF,
    GET_AGENT_SELF_SUCCESS,
    GET_AGENT_SELF_FAIL,
    UPDATE_AGENT_SELF_STATUS,
    UPDATE_AGENT_SELF_STATUS_SUCCESS,
    UPDATE_AGENT_SELF_STATUS_FAIL,
    RESET_AGENT_SELF_ALERTS_ERRORS,
    SET_EDIT_AGENT_SELF,
    UPDATE_AGENT_SELF,
    UPDATE_AGENT_SELF_SUCCESS,
    UPDATE_AGENT_SELF_FAIL,
    EDIT_AGENT_PASSWORD,
    EDIT_AGENT_PASSWORD_SUCCESS,
    EDIT_AGENT_PASSWORD_FAIL, DOWNLOAD_AGENT_SELF, DOWNLOAD_AGENT_SELF_SUCCESS, DOWNLOAD_AGENT_SELF_FAIL
} from './actionTypes';

export const getAgentSelf = payload => ({
    type: GET_AGENT_SELF,
    payload
});

export const getAgentSelfSuccess = payload => ({
    type: GET_AGENT_SELF_SUCCESS,
    payload
});

export const getAgentSelfFail = error => ({
    type: GET_AGENT_SELF_FAIL,
    payload: error
});

export const setEditAgentSelf = payload => ({
    type: SET_EDIT_AGENT_SELF,
    payload
});

export const updateAgentSelf = payload => ({
    type: UPDATE_AGENT_SELF,
    payload
});

export const updateAgentSelfSuccess = payload => ({
    type: UPDATE_AGENT_SELF_SUCCESS,
    payload
});

export const updateAgentSelfFail = error => ({
    type: UPDATE_AGENT_SELF_FAIL,
    payload: error
});

export const editAgentPassword = payload => ({
    type: EDIT_AGENT_PASSWORD,
    payload
});

export const editAgentPasswordSuccess = payload => ({
    type: EDIT_AGENT_PASSWORD_SUCCESS,
    payload
});

export const editAgentPasswordFail = error => ({
    type: EDIT_AGENT_PASSWORD_FAIL,
    payload: error
});

export const updateAgentSelfStatus = payload => ({
    type: UPDATE_AGENT_SELF_STATUS,
    payload
});

export const updateAgentSelfStatusSuccess = payload => ({
    type: UPDATE_AGENT_SELF_STATUS_SUCCESS,
    payload
});

export const updateAgentSelfStatusFail = error => ({
    type: UPDATE_AGENT_SELF_STATUS_FAIL,
    payload: error
});

export const resetAgentSelfAlertsErrors = error => ({
    type: RESET_AGENT_SELF_ALERTS_ERRORS,
    payload: error
});

export const downloadAgentSelf = payload => ({
    type: DOWNLOAD_AGENT_SELF,
    payload
});

export const downloadAgentSelfSuccess = payload => ({
    type: DOWNLOAD_AGENT_SELF_SUCCESS,
    payload
});

export const downloadAgentSelfFail = payload => ({
    type: DOWNLOAD_AGENT_SELF_FAIL,
    payload
});
