import { takeEvery, put, call } from 'redux-saga/effects';

import { GET_PLAYER_SELECT_LIST } from './actionTypes';
import {
    getPlayerSelectListFail,
    getPlayerSelectListSuccess
} from './actions';
import { getPlayerSelectListApi } from '../../../helpers/playerApi';

function* fetchPlayerSelectListSaga({ payload }) {
    try {
        const response = yield call(getPlayerSelectListApi, payload);

        yield put(getPlayerSelectListSuccess(response.data));
    } catch (error) {
        yield put(getPlayerSelectListFail(error));
    }
}

function* playerSelectListSaga() {
    yield takeEvery(GET_PLAYER_SELECT_LIST, fetchPlayerSelectListSaga);
}

export default playerSelectListSaga;
