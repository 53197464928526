import { takeEvery, call, put } from '@redux-saga/core/effects';

import i18n from '../../i18n';

import { GET_FILTERS_LIST, SAVE_FILTER } from './actionTypes';
import { getFiltersListApi, saveFilterApi } from '../../helpers/filterApi';
import {
    getFiltersError,
    getFiltersListSuccess,
    saveFilterSuccess
} from './action';

export function* getFiltersListSaga({ payload }) {
    try {
        const response = yield call(getFiltersListApi, payload);

        if (response && response?.status === 200) {
            yield put(getFiltersListSuccess(response?.data?.result));
        } else {
            yield put(
                getFiltersError({ message: i18n.t('filter.failedGetFilters') })
            );
        }
    } catch (error) {
        yield put(getFiltersError({ message: error }));
    }
}

export function* saveFilterSaga({ payload: data }) {
    try {
        const response = yield call(saveFilterApi, data);

        if (response && response.hasOwnProperty('id')) {
            yield put(saveFilterSuccess(response));
        } else {
            yield put(
                getFiltersError({ message: i18n.t('filter.failedSave') })
            );
        }
    } catch (error) {
        yield put(getFiltersError({ message: error }));
    }
}

function* filterSaga() {
    yield takeEvery(GET_FILTERS_LIST, getFiltersListSaga);
    yield takeEvery(SAVE_FILTER, saveFilterSaga);
}

export default filterSaga;
