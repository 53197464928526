import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import i18n from '../../i18n';
import UserStatusView from '../../components/Status/StatusView';
import ClipboardButton from '../../common/cellData/CopyButton';
import ButtonsPanel from './common/ButtonsPanel';
import TextInput from '../../common/inputs/TextInput';
import AttributionSelect from './common/AttributionSelect';
import ActionSelect from './common/ActionSelect';
import PaymentMethodSelect from './common/PaymentMethodSelect';
import { ACTION_TYPE } from './common/automationConstants';
import TextareaInput from '../../common/inputs/TextareaInput';
import SendAlertCheckbox from './common/SendAlertCheckbox';
import useWorkflowEdit from './context/useWorkflowEdit';
import WorkflowContext from './context/WorkflowContext';
import { RouterPrompt } from '../../common/prompt/RouterPrompt';

import information_1_1 from '../../assets/images/common/information (1) 1.svg';
import InputWrapper from '../../common/wrappers/InputWrapper';
import group_10825_1 from '../../assets/images/common/Frame 10825_1.svg';
import group_11002_1 from '../../assets/images/common/Group 11002_1.svg';
import CommonDataField from '../CRM/general/common/CommonDataField';

function ConditionGroupsFragment({ groups, onChange, isEdit }) {
    const handleChangeItem = useCallback(
        (groupId, itemId, name) => {
            return value => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                const itemToUpdate = groupToUpdate.items.find(
                    item => item.id === itemId
                );
                itemToUpdate[name] = value;

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleAddGroup = useCallback(() => {
        if (!isEdit) {
            return;
        }

        onChange([
            ...groups,
            { id: Date.now(), items: [{ id: Date.now(), attribution: '' }] }
        ]);
    }, [groups, isEdit]);

    const handleAddGroupItem = useCallback(
        groupId => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                groupToUpdate.items = [
                    ...groupToUpdate.items,
                    { id: Date.now(), attribution: '' }
                ];

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleDeleteGroupItem = useCallback(
        (groupId, itemId) => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                groupToUpdate.items = groupToUpdate.items.filter(
                    item => item.id !== itemId
                );

                if (
                    groupsToUpdate.length === 1 &&
                    groupToUpdate.items.length === 0
                ) {
                    return onChange([
                        {
                            id: Date.now(),
                            items: [{ id: Date.now(), attribution: null }]
                        }
                    ]);
                }

                return groupToUpdate.items.length === 0
                    ? onChange(
                          groupsToUpdate.filter(item => item.id !== groupId)
                      )
                    : onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    return (
        <div className="automation-card__groups">
            <div className="groups-header">
                <img className="me-3" src={information_1_1} alt="" />
                <span>{i18n.t('workflow.condition')}</span>
            </div>

            {groups.map((group, groupIndex) => {
                return (
                    <React.Fragment key={group.id}>
                        {groupIndex !== 0 && (
                            <div className="group-container-separator">
                                <span>{i18n.t('workflow.and')}</span>
                                <hr />
                            </div>
                        )}

                        <div className="group-container">
                            {group.items.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {index !== 0 && (
                                            <div className="group-item-separator">
                                                <span className="group-item-separator__label">
                                                    {i18n.t('workflow.or')}
                                                </span>
                                            </div>
                                        )}

                                        <div className="group-item">
                                            <span className="group-item__label font-w-500">
                                                {i18n.t('workflow.attribution')}
                                            </span>

                                            <div className="group-item__form">
                                                <InputWrapper
                                                    className="me-3"
                                                    errorMessage={''}
                                                >
                                                    <AttributionSelect
                                                        className="action-select"
                                                        value={item.action}
                                                        onChange={handleChangeItem(
                                                            group.id,
                                                            item.id,
                                                            'action'
                                                        )}
                                                        isDisabled={!isEdit}
                                                    />
                                                </InputWrapper>
                                            </div>

                                            <div className="group-item__actions">
                                                <button
                                                    className={`btn btn-rounded btn-light app-btn-only-img-xs ${
                                                        isEdit
                                                            ? 'me-2'
                                                            : 'invisible'
                                                    }`}
                                                    onClick={handleDeleteGroupItem(
                                                        group.id,
                                                        item.id
                                                    )}
                                                >
                                                    <img
                                                        src={group_10825_1}
                                                        alt=""
                                                    />
                                                </button>
                                                {index === 0 && (
                                                    <button
                                                        className={`btn btn-rounded btn-primary app-btn-only-img-xs ${
                                                            isEdit
                                                                ? ''
                                                                : 'invisible'
                                                        }`}
                                                        onClick={handleAddGroupItem(
                                                            group.id
                                                        )}
                                                    >
                                                        <img
                                                            src={group_11002_1}
                                                            alt=""
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}

            <div className={`groups-footer ${isEdit ? '' : 'disabled'}`}>
                <span
                    className="app-text-primary"
                    role={isEdit ? 'button' : ''}
                    onClick={handleAddGroup}
                >
                    <button className="btn btn-rounded btn-primary app-btn-only-img-xs me-2">
                        <img src={group_11002_1} alt="" />
                    </button>
                    {i18n.t('workflow.addAttributionGroup')}
                </span>
            </div>
        </div>
    );
}

function ActionGroupsFragment({ groups, onChange, isEdit }) {
    const handleChangeItem = useCallback(
        (groupId, itemId, name) => {
            return value => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                let itemToUpdate = groupToUpdate.items.find(
                    item => item.id === itemId
                );

                if (name === 'action') {
                    itemToUpdate.paymentMethod = '';
                    itemToUpdate.note = '';
                    itemToUpdate.sendAs = [];
                }
                itemToUpdate[name] = value;

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleAddGroup = useCallback(() => {
        if (!isEdit) {
            return;
        }

        onChange([
            ...groups,
            {
                id: Date.now(),
                items: [
                    {
                        id: Date.now(),
                        action: '',
                        paymentMethod: '',
                        note: '',
                        sendAs: []
                    }
                ]
            }
        ]);
    }, [groups, isEdit]);

    const handleAddGroupItem = useCallback(
        groupId => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                groupToUpdate.items = [
                    ...groupToUpdate.items,
                    {
                        id: Date.now(),
                        action: '',
                        paymentMethod: '',
                        note: '',
                        sendAs: []
                    }
                ];

                onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    const handleDeleteGroupItem = useCallback(
        (groupId, itemId) => {
            return () => {
                const groupsToUpdate = [...groups];
                const groupToUpdate = groupsToUpdate.find(
                    item => item.id === groupId
                );
                groupToUpdate.items = groupToUpdate.items.filter(
                    item => item.id !== itemId
                );

                if (
                    groupsToUpdate.length === 1 &&
                    groupToUpdate.items.length === 0
                ) {
                    return onChange([
                        {
                            id: Date.now(),
                            items: [
                                {
                                    id: Date.now(),
                                    action: null,
                                    paymentMethod: null,
                                    note: '',
                                    sendAs: []
                                }
                            ]
                        }
                    ]);
                }

                return groupToUpdate.items.length === 0
                    ? onChange(
                          groupsToUpdate.filter(item => item.id !== groupId)
                      )
                    : onChange(groupsToUpdate);
            };
        },
        [groups]
    );

    return (
        <div className="automation-card__groups">
            <div className="groups-header">
                <img className="me-3" src={information_1_1} alt="" />
                <span>{i18n.t('workflow.action')}</span>
            </div>

            {groups.map((group, groupIndex) => {
                return (
                    <React.Fragment key={group.id}>
                        {groupIndex !== 0 && (
                            <div className="group-container-separator">
                                <span>{i18n.t('workflow.and')}</span>
                                <hr />
                            </div>
                        )}

                        <div className="group-container">
                            {group.items.map((item, index) => {
                                return (
                                    <React.Fragment key={item.id}>
                                        {index !== 0 && (
                                            <div className="group-item-separator">
                                                <span className="group-item-separator__label">
                                                    {i18n.t('workflow.or')}
                                                </span>
                                            </div>
                                        )}

                                        <div className="group-item">
                                            <span className="group-item__label font-w-500">
                                                {i18n.t('workflow.action')}
                                            </span>

                                            <div className="group-item__form">
                                                <InputWrapper
                                                    className="me-3"
                                                    errorMessage={''}
                                                >
                                                    <ActionSelect
                                                        className="action-select"
                                                        value={item.action}
                                                        onChange={handleChangeItem(
                                                            group.id,
                                                            item.id,
                                                            'action'
                                                        )}
                                                        isDisabled={!isEdit}
                                                    />
                                                </InputWrapper>

                                                {ACTION_TYPE.BLOCK_PAYMENT_METHOD ===
                                                    item.action && (
                                                    <InputWrapper
                                                        label={i18n.t(
                                                            'workflow.paymentMethod'
                                                        )}
                                                        errorMessage={''}
                                                    >
                                                        <PaymentMethodSelect
                                                            className="payment-method-select"
                                                            value={
                                                                item.paymentMethod
                                                            }
                                                            onChange={handleChangeItem(
                                                                group.id,
                                                                item.id,
                                                                'paymentMethod'
                                                            )}
                                                            disabled={!isEdit}
                                                        />
                                                    </InputWrapper>
                                                )}

                                                {ACTION_TYPE.COMMUNICATE_TO_PLAYER ===
                                                    item.action && (
                                                    <>
                                                        <InputWrapper
                                                            className="me-3"
                                                            errorMessage={''}
                                                        >
                                                            <TextareaInput
                                                                rows="4"
                                                                className="form-control note-input"
                                                                value={
                                                                    item.note
                                                                }
                                                                placeholder={i18n.t(
                                                                    'workflow.writeSomething'
                                                                )}
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'note'
                                                                )}
                                                                disabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>

                                                        <InputWrapper
                                                            className="me-3"
                                                            errorMessage={''}
                                                        >
                                                            <SendAlertCheckbox
                                                                value={
                                                                    item.sendAs
                                                                }
                                                                onChange={handleChangeItem(
                                                                    group.id,
                                                                    item.id,
                                                                    'sendAs'
                                                                )}
                                                                disabled={
                                                                    !isEdit
                                                                }
                                                            />
                                                        </InputWrapper>
                                                    </>
                                                )}
                                            </div>

                                            <div className="group-item__actions">
                                                <button
                                                    className={`btn btn-rounded btn-light app-btn-only-img-xs ${
                                                        isEdit
                                                            ? 'me-2'
                                                            : 'invisible'
                                                    }`}
                                                    onClick={handleDeleteGroupItem(
                                                        group.id,
                                                        item.id
                                                    )}
                                                >
                                                    <img
                                                        src={group_10825_1}
                                                        alt=""
                                                    />
                                                </button>
                                                {index === 0 && (
                                                    <button
                                                        className={`btn btn-rounded btn-primary app-btn-only-img-xs ${
                                                            isEdit
                                                                ? ''
                                                                : 'invisible'
                                                        }`}
                                                        onClick={handleAddGroupItem(
                                                            group.id
                                                        )}
                                                    >
                                                        <img
                                                            src={group_11002_1}
                                                            alt=""
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}

            <div className={`groups-footer ${isEdit ? '' : 'disabled'}`}>
                <span
                    className="app-text-primary"
                    role={isEdit ? 'button' : ''}
                    onClick={handleAddGroup}
                >
                    <button className="btn btn-rounded btn-primary app-btn-only-img-xs me-2">
                        <img src={group_11002_1} alt="" />
                    </button>
                    {i18n.t('workflow.addActionGroup')}
                </span>
            </div>
        </div>
    );
}

function WorkflowCard({ data }) {
    const { isEdit, setIsEdit } = useWorkflowEdit();
    const [state, setState] = useState({
        model: data
    });

    const handleSave = useCallback(() => {
        setIsEdit(false);
    }, [state]);

    const handleCancel = useCallback(() => {
        setState(prevState => ({ ...prevState, model: data }));
        setIsEdit(false);
    }, [state]);

    const handleChange = useCallback(
        name => {
            return value => {
                setState(prevState => ({
                    ...prevState,
                    model: { ...prevState.model, [name]: value }
                }));
            };
        },
        [state]
    );

    return (
        <WorkflowContext.Provider value={{ isEdit, setIsEdit }}>
            <Card className="automation-card">
                <CardBody>
                    <div className="d-flex justify-content-between automation-card__header">
                        <div>
                            <CommonDataField
                                value={
                                    <span
                                        role="button"
                                        className="font-w-600 me-5"
                                    >
                                        {state.model.name}
                                    </span>
                                }
                                isEdit={isEdit}
                            >
                                <InputWrapper errorMessage={''}>
                                    <TextInput
                                        className="form-control form-control-sm"
                                        value={state.model.name}
                                        onChange={handleChange('name')}
                                    />
                                </InputWrapper>
                            </CommonDataField>

                            <span>
                                <span className="me-2">
                                    ID {state.model.id}
                                </span>
                                <ClipboardButton text={state.model.id} />
                            </span>
                        </div>

                        <span>
                            <span className="font-w-500 me-3">
                                {i18n.t('workflow.status')}:
                            </span>
                            <UserStatusView status={state.model.status} />
                        </span>
                    </div>

                    <ConditionGroupsFragment
                        groups={state.model.conditionGroups}
                        onChange={handleChange('conditionGroups')}
                        isEdit={isEdit}
                    />

                    <ActionGroupsFragment
                        groups={state.model.actionGroups}
                        onChange={handleChange('actionGroups')}
                        isEdit={isEdit}
                    />
                </CardBody>
            </Card>

            <ButtonsPanel
                onCancel={handleCancel}
                onSave={handleSave}
                isEdit={isEdit}
                onSetIsEdit={setIsEdit}
            />

            {isEdit && (
                <RouterPrompt
                    when={isEdit}
                    onOK={() => true}
                    onCancel={() => false}
                />
            )}
        </WorkflowContext.Provider>
    );
}

WorkflowCard.propTypes = {
    data: PropTypes.object.isRequired
};

export default React.memo(WorkflowCard);
