import React, {useMemo} from "react";
import PropTypes from "prop-types";
import Select from 'react-select';

import {useTranslation} from "react-i18next";
import { SELECT_CATEGORIES } from "../../constants/selectCategories";
import { CURRENCIES } from "../../utils/currencies";
import { KYC_STATUS_ARR, PROMOTION_ENTER_CONDITION } from "../../pages/Promotions/common/promotionConstants";
import binIcon from '../../assets/images/layout/grey-garbage.svg'

function MultiSelect({ category, handleChange, values, options, isSearchable, ...props }) {
    const { i18n } = useTranslation();

    const onChange = selectValues => {
        const values = selectValues?.map(item => item.value);
        handleChange(values);
    };

    const selectedValues = useMemo(() => {
            const isValueObject = [SELECT_CATEGORIES.DEFAULT, SELECT_CATEGORIES.GAMES].includes(category)
            return isValueObject
                ? Array.isArray(options)
                    ? options
                        .filter(({id}) => values.includes(id))
                        .map(({ id, friendlyName }) => {
                            let displayName = id?.replace(/_/g, ' ');
                            if (displayName === 'PaymentConfirmation') {
                                displayName = 'Payment Confirmation';
                            }
                            return {
                                value: id,
                                label: friendlyName || displayName
                            };
                        })
                    : []
                : values?.map(item => ( {value: item, label: i18n.t(item)} ))
        },
        [values, options]
    );

    const getOptions = category => {
        switch (category) {
            case SELECT_CATEGORIES.CURRENCIES:
                return CURRENCIES.map(key => {
                    return {
                        value: key,
                        label: key
                    };
                });
            case SELECT_CATEGORIES.KYC_STATUS:
                return KYC_STATUS_ARR.map(key => {
                    return {
                        value: key,
                        label: i18n.t(`filter.constant.${key.toString()}`)
                    };
                });
            case SELECT_CATEGORIES.ENTER_CONDITION:
                return Object.values(PROMOTION_ENTER_CONDITION).map(key => {
                    return {
                        value: key,
                        label: key
                    };
                });
            case SELECT_CATEGORIES.GAMES:
                return options.map(({ id, friendlyName }) => {
                    return {
                        value: id,
                        label: friendlyName
                    };
                });
            case SELECT_CATEGORIES.DEFAULT:
                return options?.map(({ id, name }) => {
                    return {
                        value: id,
                        label: name
                    };
                });
        }
    }

    const CustomClearIndicator = (props) => {
        const {
            getStyles,
            innerProps: {ref, ...restInnerProps},
        } = props;

        return (
            <div
                {...restInnerProps}
                ref={ref}
                style={getStyles('clearIndicator', props)}
            >
                <img src={binIcon} alt=""/>
            </div>
        );
    };

    return (
        <Select
            isMulti
            closeMenuOnSelect={false}
            value={selectedValues}
            onChange={onChange}
            options={getOptions(category) || options}
            isSearchable={isSearchable}
            className={`react-select multiselect ${props.className || ''}`}
            classNamePrefix="react-select multiselect"
            components={{
                ClearIndicator: CustomClearIndicator,
            }}
            { ...props }
        />
    );
}

MultiSelect.propTypes = {
    category: PropTypes.string,
    values: PropTypes.any,
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.number.isRequired,
    }))
};

export default React.memo(MultiSelect);
