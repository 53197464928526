import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  cancelErrors,
  downloadAffiliateList,
  getAffiliatesList,
} from '../../store/affiliates/actions';

import useToggleModal from '../../hooks/useToggleModal';
import { AffiliatesTable } from './common/AffiliatesTable';
import AddAffiliateModal from './AddAffiliateModal';
import MetaTags from "react-meta-tags"

import "./affiliates.scss";
import useListPage from "../../hooks/useListPage";
import {useTranslation} from "react-i18next";
import SelectTableView from "../Content/components/SelectTableView";
import addAffiliate from "../../assets/images/common/Group 10873.svg";
import download from "../../assets/images/common/download.svg";
import settings from "../../assets/images/layout/settings.svg";
import clsx from "clsx";


const AffiliatesPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { isLoading, query, total, affiliateList, successMessage, errorList, isDownloading } = useSelector(state => state.Affiliates);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ isShowAddAffiliate, handleShowAddAffiliate, handleHideAddAffiliate ] = useToggleModal(false);
  const [_query, _setQuery] = useState(query);
  const [isShowTableSettings, setShowTableSettings] = useState(false);

  const { pageCount, activePage, handleChangePage } = useListPage(
    query => dispatch(getAffiliatesList(query)),
    total,
    _query,
    errorList,
    () => dispatch(cancelErrors()),
    successMessage,
  );

  const handleAddAffiliate = useCallback(() => {
    handleShowAddAffiliate();
  }, []);

  const handleDownload = useCallback(() => {
    dispatch(downloadAffiliateList({ ..._query, offset: 0 }))
  },[_query]);

  const onChangeRecords = (value) => {
    _setQuery((prevValue) => ({
      ...prevValue,
        limit: value,
        offset: 0
      }));
  };

  useEffect(() => {
    handleChangePage(1);
  }, [_query.limit])


  return (
    <div className="page-content affiliates-page">
      <MetaTags>
        <title>{i18n.t('affiliates.title.affiliates')} - {process.env.REACT_APP_APP_NAME}</title>
      </MetaTags>

      <Container fluid>
        <Row className={"m-0"}>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between mt-2 mb-3">
                  <img role="button"
                       className="add-affiliate-button"
                       src={addAffiliate}
                       alt="add-affiliate"
                       onClick={handleAddAffiliate}
                  />

                  <div>
                    <button
                      className={clsx('btn btn-rounded btn-primary app-btn-only-img-sm', {
                        ['d-none']: !affiliateList?.length
                      })}
                      onClick={handleDownload}
                      disabled={isDownloading}
                    >
                      <img src={download} alt="" />
                    </button>

                    <button
                        className="btn settings-button"
                        onClick={() => setShowTableSettings(true)}
                    >
                      <img src={settings} alt="Table's Settings" />
                    </button>
                  </div>
                </div>

                <AffiliatesTable
                  list={affiliateList}
                  isLoading={isLoading}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  isShowTableSettings={isShowTableSettings}
                  onCloseTableSettings={() => setShowTableSettings(false)}
                />

                <div className='mt-5 mobile-pagination'>
                  <SelectTableView
                      pageCount={pageCount}
                      activePage={activePage}
                      recordsCount={total}
                      onChangePage={handleChangePage}
                      pageSize={_query.limit}
                      isSticky={true}
                      onChange={onChangeRecords}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {isShowAddAffiliate && (
        <AddAffiliateModal onHide={handleHideAddAffiliate} />
      )}
    </div>
  );
};

export default withRouter(AffiliatesPage);
