import React from 'react';
import PropTypes from 'prop-types';

import './switch-input.scss';

function SwitchInput({ checked, onChange, children, isDisabled, ...props }) {
    return (
        <label
            className={`app-switch ${props.className ? props.className : ''} ${
                isDisabled ? 'disabled' : ''
            }`}
        >
            <input
                {...props}
                type="checkbox"
                checked={checked || false}
                onChange={e => onChange && onChange(e.target.checked)}
                disabled={isDisabled ? 'disabled' : ''}
            />
            <span className="slider round" />
        </label>
    );
}

SwitchInput.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export default React.memo(SwitchInput);
