import React from 'react';
import PropTypes from 'prop-types';

import './status.scss';

function AgentStatusView({ status }) {
    return <span className={`app-agent-status ${status}`}>{status}</span>;
}

AgentStatusView.propTypes = {
    status: PropTypes.string.isRequired
};

export default React.memo(AgentStatusView);
