import {
    GET_PLAYER_COMMUNICATION,
    GET_PLAYER_COMMUNICATION_SUCCESS,
    GET_PLAYER_COMMUNICATION_FAIL,
    RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS,
    SET_EDIT_PLAYER_COMMUNICATION,
    GET_PLAYER_COMMUNICATION_PHONE_LIST,
    GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS,
    GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL,
    RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS,
    GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL,
    RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS,
    UPDATE_PLAYER_COMMUNICATION,
    UPDATE_PLAYER_COMMUNICATION_SUCCESS,
    UPDATE_PLAYER_COMMUNICATION_FAIL,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS,
    SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS,
    SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL
} from './actionTypes';
import i18n from '../../../i18n';

export const DEFAULT_QUERY = {
    limit: 50,
    offset: 0
};

const INIT_STATE = {
    isLoadingCommunication: false,
    errorCommunication: null,
    communication: null,
    isEdit: false,

    isLoadingPhoneList: false,
    errorPhoneList: null,
    phoneList: null,
    totalPhoneList: 0,
    queryPhoneList: { ...DEFAULT_QUERY },

    isLoadingEmailList: false,
    errorEmailList: null,
    emailList: null,
    totalEmailList: 0,
    queryEmailList: { ...DEFAULT_QUERY },

    successAlert: ''
};

const PlayerCommunication = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYER_COMMUNICATION:
            return {
                ...state,
                isLoadingCommunication: true,
                errorCommunication: null
            };

        case GET_PLAYER_COMMUNICATION_SUCCESS:
            return {
                ...state,
                communication: action.payload,
                isLoadingCommunication: false
            };

        case GET_PLAYER_COMMUNICATION_FAIL:
            return {
                ...state,
                errorCommunication: action.payload,
                isLoadingCommunication: false
            };

        case RESET_PLAYER_COMMUNICATION_ALERTS_ERRORS:
            return { ...state, errorCommunication: null, successAlert: '' };

        case SET_EDIT_PLAYER_COMMUNICATION:
            return { ...state, isEdit: action.payload };

        case UPDATE_PLAYER_COMMUNICATION:
            return {
                ...state,
                isLoadingCommunication: true,
                errorCommunication: null
            };

        case UPDATE_PLAYER_COMMUNICATION_SUCCESS:
            return {
                ...state,
                communication: {
                    ...state.communication,
                    ...action.payload.dto
                },
                isLoadingCommunication: false,
                successAlert: i18n.t('crm.alerts.communicationEdited')
            };

        case UPDATE_PLAYER_COMMUNICATION_FAIL:
            return {
                ...state,
                errorCommunication: action.payload,
                isLoadingCommunication: false
            };

        case GET_PLAYER_COMMUNICATION_PHONE_LIST:
            return {
                ...state,
                queryPhoneList: {
                    ...state.queryPhoneList,
                    ...action.payload.query
                },
                isLoadingPhoneList: true,
                errorPhoneList: null
            };

        case GET_PLAYER_COMMUNICATION_PHONE_LIST_SUCCESS:
            return {
                ...state,
                phoneList: action.payload.result,
                totalPhoneList: action.payload.total,
                isLoadingPhoneList: false
            };

        case GET_PLAYER_COMMUNICATION_PHONE_LIST_FAIL:
            return {
                ...state,
                errorPhoneList: action.payload,
                phoneList: [],
                isLoadingPhoneList: false
            };

        case RESET_PLAYER_COMMUNICATION_PHONE_LIST_ERRORS:
            return { ...state, errorPhoneList: null };

        case GET_PLAYER_COMMUNICATION_EMAIL_LIST:
            return {
                ...state,
                queryEmailList: {
                    ...state.queryEmailList,
                    ...action.payload.query
                },
                isLoadingEmailList: true,
                errorEmailList: null
            };

        case GET_PLAYER_COMMUNICATION_EMAIL_LIST_SUCCESS:
            return {
                ...state,
                emailList: action.payload.result,
                totalEmailList: action.payload.total,
                isLoadingEmailList: false
            };

        case GET_PLAYER_COMMUNICATION_EMAIL_LIST_FAIL:
            return {
                ...state,
                errorEmailList: action.payload,
                emailList: [],
                isLoadingEmailList: false
            };

        case RESET_PLAYER_COMMUNICATION_EMAIL_LIST_ERRORS:
            return { ...state, errorEmailList: null };

        case SEND_CODE_PHONE_PLAYER_COMMUNICATION:
            return {
                ...state,
                isLoadingCommunication: true,
                errorCommunication: null
            };

        case SEND_CODE_PHONE_PLAYER_COMMUNICATION_SUCCESS:
            return {
                ...state,
                isLoadingCommunication: false,
                successAlert: i18n.t('crm.alerts.phoneCodeSent')
            };

        case SEND_CODE_PHONE_PLAYER_COMMUNICATION_FAIL:
            return {
                ...state,
                errorCommunication: action.payload,
                isLoadingCommunication: false
            };

        case SEND_CODE_EMAIL_PLAYER_COMMUNICATION:
            return {
                ...state,
                isLoadingCommunication: true,
                errorCommunication: null
            };

        case SEND_CODE_EMAIL_PLAYER_COMMUNICATION_SUCCESS:
            return {
                ...state,
                isLoadingCommunication: false,
                successAlert: i18n.t('crm.alerts.emailCodeSent')
            };

        case SEND_CODE_EMAIL_PLAYER_COMMUNICATION_FAIL:
            return {
                ...state,
                errorCommunication: action.payload,
                isLoadingCommunication: false
            };

        default:
            return state;
    }
};

export default PlayerCommunication;
