import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { getWidthOfText } from "../../../common/utils/common"

import remove from "../../../assets/images/layout/remove.svg";
import category1 from "../../../assets/images/layout/categories/category1.png"
import category2 from "../../../assets/images/layout/categories/category2.png"
import category3 from "../../../assets/images/layout/categories/category3.png"
import category4 from "../../../assets/images/layout/categories/category4.png"
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { SITE_PERMISSION } from "../../../common/constants/common";
import { useSelector } from "react-redux";

const categoryImages = [
	category1,
	category2,
	category3,
	category4,
]

const CategoryBox = (props) => {
	const {
		category,
		categoryWidth,
		categoryHeight,
		onRemoveCategory,
		onOpenCategory,
	} = props;

	const { i18n } = useTranslation();
	const { agentSelf } = useSelector(state => state.AgentSelf);
	const agentPermissions = agentSelf?.permissions;
	const canEditByPermission = agentPermissions?.includes(SITE_PERMISSION.Game__Edit_Game_Categories);


	const { label, activeLogo, oid } = category;
	const categoryImg = activeLogo === "some url" ? categoryImages[oid % 4] : activeLogo;
	const gamesCount = category.gameCount;

	const splits = label.split(/([ -_])/)

	const categoryLength = getWidthOfText(label, 'Poppins', '13px')

	const categoryMaxLength = categoryWidth - (categoryWidth > 330 ? 270 : 230)
	let nameParts = []

	if (categoryLength > categoryMaxLength) {
		if (splits.length === 3) {
			nameParts = [splits.slice(0, splits[1] === '-' ? 2 : 1).join(''), splits.slice(2).join('')]
		}
		else if (splits.length === 5) {
			nameParts = [splits.slice(0, splits[3] === '-' ? 4 : 3).join(''), splits.slice(4).join('')]
		}
		else {
			let currentPart = []
			let currentSplitPartNum = 0

			while (currentSplitPartNum < splits.length) {
				if (currentSplitPartNum === splits.length - 1) {
					nameParts.push(splits[currentSplitPartNum])
					currentSplitPartNum++
				}
				else {
					while (currentSplitPartNum < splits.length) {
						currentPart.push(...splits.slice(currentSplitPartNum, currentSplitPartNum + 2))

						const currentPartStr = currentPart.join('')
						const currentPartLength = getWidthOfText(currentPartStr, 'Poppins', '13px')

						if (currentPartLength <= categoryMaxLength) {
							currentSplitPartNum += 2
						}
						else {
							if (currentPart.length > 2) {
								currentPart.pop()
								currentPart.pop()
							}
							else {
								currentSplitPartNum += 2
							}
							break
						}
					}
					nameParts.push(currentPart.join(''))
				}
				currentPart = []
			}
		}
	}
	else {
		nameParts = [label]
	}

	return (
		<div
			className='category-card'
			style={{
				width: `${categoryWidth}px`,
				height: `${categoryHeight}px`,
				paddingTop: `${categoryHeight / 2 - 10}px`,
				marginBottom: '5px',
			}}
			onClick={onOpenCategory}
		>
			<div className={`category-left-part ${
				nameParts.length === 1
					? ''
					: 'two-line'
			}`}>
				<img
					className={clsx("category-img", {
						withImage: activeLogo !== "some url",
					})}
					src={categoryImg}
					alt=""
					width={32}
					height={32}
				/>
				{nameParts.length === 1
					? nameParts[0]
					: (
						<div className="category-title">
							{nameParts.map((part, ind) => (
								<>
									{nameParts[ind]}
									{ind < nameParts.length - 1 && (
										<br/>
									)}
								</>
							))}
						</div>
					)
				}
			</div>
			<div className="category-right-part">
				{`${gamesCount} ${i18n.t(`crm.game${gamesCount !== 1 ? 's' : ''}`)}`}
				<img
					className={clsx("remove", {
						'cannot-edit-games': !canEditByPermission,
					})}
					src={remove}
					alt=""
					width={24}
					height={24}
					onClick={(event) => {
						event.preventDefault()
						event.stopPropagation()
						onRemoveCategory(category)
					}}
				/>
			</div>
		</div>
	)
}

CategoryBox.propTypes = {
	category: PropTypes.object,
	categoryWidth: PropTypes.number,
	categoryHeight: PropTypes.number,
	onChangeCategory: PropTypes.func,
	onRemoveCategory: PropTypes.func,
	onOpenCategory: PropTypes.func,
}

CategoryBox.defaultProps = {
	onChangeCategory: () => {},
	onRemoveCategory: () => {},
	onOpenCategory: () => {},
}

export default withRouter(CategoryBox)
