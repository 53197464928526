import React, { useEffect } from "react"
import '../roles.scss';
import { A_COMMON_DATA_FIELD } from "../../../../constants/aCommonDataField";
import ACommonDataField from "../../../../components/GenericComponents/CommonDataField";
import { useDispatch } from "react-redux"
import { getAgentsList } from "../../../../store/agents/agents/actions"

const AdditionalRolesFields = (props) => {
  const { visibilityFields, setVisibilityFields } = props;

  const handleChange = (field, value) => {
    setVisibilityFields(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleCheckboxChange = (field, value) => {
    setVisibilityFields(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleArrayChange = (field, value) => {
    handleChange(field, value.split(',').map(item => item.trim()));
  };

  return (
    <div className='additional-fields'>
      <div className='roles-agent-owner-select'>
        <ACommonDataField
          component={A_COMMON_DATA_FIELD.MULTI_SELECT_AGENT_OWNER}
          label='Other Agents'
          value={visibilityFields.otherAgents}
          handleChange={(e) => handleChange('otherAgents', e)}
        />
      </div>
      <ACommonDataField
        component={A_COMMON_DATA_FIELD.TEXT}
        label='Affiliate IDs'
        value={visibilityFields.affiliateIds}
        handleChange={(e) => handleArrayChange('affiliateIds', e)}
      />
      <ACommonDataField
        component={A_COMMON_DATA_FIELD.CHECK_BOX}
        label='Self agent only'
        value={visibilityFields.selfAgentOnly}
        handleChange={(e) => handleCheckboxChange('selfAgentOnly', e)}
      />
    </div>
  );
}

export default AdditionalRolesFields;
