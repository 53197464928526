import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import FieldData from '../../../common/components/FieldData';
import greyInformation from '../../../assets/images/layout/grey-information.svg';
import { displayType, DOCUMENT_STATUSES } from '../../../common/constants/common';
import { DATE_FORMAT, TIME_FORMAT_hh_mm_ss } from "../../../common/utils/common";
import { useTranslation } from "react-i18next";


function DocumentData(props) {
    const {currDocument, onClick} = props;
    const { i18n } = useTranslation();

    const checkIsDocumentExpired = useCallback(() => {
        const today = new Date();
        const nextDate = new Date(currDocument.expiration);
        return today.getTime() > nextDate.getTime();
    }, [currDocument])

    const documentStatus = DOCUMENT_STATUSES[currDocument.status.toLowerCase()];

    return (
        <Col className="doc-data doc-data__info">
            <div className="doc-data__title">
                <img style={{cursor: "pointer"}} src={greyInformation} width={16} alt="" onClick={onClick}/>
                <span className="document-type" onClick={onClick} >
                    {i18n.t(`crm.document.type.${displayType(currDocument.type)}`)}
                </span>
                <span
                    onClick={onClick}
                    className="document-status"
                    style={{
                        color: documentStatus.color,
                        backgroundColor: documentStatus.backgroundColor
                    }}
                >
                    {i18n.t(`crm.document.status.${documentStatus.text}`)}
                </span>
            </div>

            <Row className='doc-data__content'>
                <Col key="left-col" className="d-flex flex-column align-items-baseline">
                    <FieldData
                        key="documentSide"
                        name={i18n.t('crm.documentSide')}
                        value={i18n.t(`crm.document.side.${currDocument.side}`)}
                        className={'mr-5 mt-0 pb-2'}
                    />
                    <FieldData
                        key="uploadDate"
                        name={i18n.t('crm.uploadDate')}
                        className={'mr-5 pb-2'}
                        value={
                            <span>
                                {moment(currDocument.createdAt).format(
                                    DATE_FORMAT
                                )}
                                <span className="time-data">
                                    {moment(currDocument.createdAt).format(
                                        TIME_FORMAT_hh_mm_ss
                                    )}
                                </span>
                            </span>
                        }
                    />
                    <FieldData
                        key="uploadedBy"
                        name={i18n.t('crm.uploadedBy')}
                        className={'mr-5'}
                        value={`${currDocument?.firstName} ${currDocument?.lastName}`}
                    />
                </Col>
            </Row>
        </Col>
    );
}

DocumentData.propTypes = {
    currDocument: PropTypes.object.isRequired
};

export default React.memo(DocumentData);
