import { delSync, get, patch, post } from "./api_helper";

const getSupplierListApi = () => {
    const url = `/cashier/site/supplier/list`;
    return get(url);
};

const getCashierListApi = (method) => {
    const url = `/cashier/method/list/${method}`;
    return get(url);
};

function getSupplierByIdApi (id) {
    const url = `/cashier/supplier/list?methodId=${id}`;
    return get(url);
};

const getCashierMethodApi = (id) => {
    const url = `/cashier/method?id=${id}`;
    return get(url);
};

const updateCashierMethodApi = (dto) => {
    const url = `/cashier/method`;
    return patch(url, dto);
};

const updateCashierSupplierApi = (dto) => {
    const url = `/cashier/supplier`;
    return patch(url, dto);
};

const createCashierSupplierApi = (dto) => {
    const url = `/cashier/supplier`;
    return post(url, dto);
};

const createCashierMethodApi = (dto) => {
    const url = `/cashier/method`;
    return post(url, dto);
}

const removeCashierMethodApi = (methodId) => {
    const url = `/cashier/method?id=${methodId}`;
    return delSync(url);
}

const removeCashierSupplierApi = (supplierId) => {
    const url = `/cashier/supplier?id=${supplierId}`;
    return delSync(url);
}

export {
    getSupplierListApi,
    getCashierListApi,
    getCashierMethodApi,
    updateCashierMethodApi,
    createCashierMethodApi,
    getSupplierByIdApi,
    updateCashierSupplierApi,
    createCashierSupplierApi,
    removeCashierMethodApi,
    removeCashierSupplierApi,
}