import { takeEvery, put, call } from 'redux-saga/effects';

import {
    CREATE_CASHIER_METHOD,
    CREATE_CASHIER_SUPPLIER,
    GET_SUPPLIER_LIST,
    GET_CASHIER_LIST,
    GET_CASHIER_METHOD,
    GET_SUPPLIER_BY_ID,
    UPDATE_CASHIER_METHOD,
    UPDATE_CASHIER_SUPPLIER,
    REMOVE_METHOD_BY_ID,
    REMOVE_SUPPLIER_BY_ID,
} from './actionTypes';

import {
    getSupplierListSuccess,
    getSupplierListFail,
    getCashierListSuccess,
    getCashierListFail,
    getCashierMethodSuccess,
    getCashierMethodFail,
    updateCashierMethodFail,
    updateCashierMethodSuccess,
    createCashierMethodSuccess,
    createCashierMethodFail,
    getSupplierByIdSuccess,
    getSupplierByIdFail,
    updateCashierSupplierFail,
    updateCashierSupplierSuccess,
    createCashierSupplierSuccess,
    createCashierSupplierFail,
    removeCashierMethodByIdFail,
    removeCashierMethodByIdSuccess,
    removeCashierSupplierById,
    removeCashierSupplierByIdSuccess,
    removeCashierSupplierByIdFail,
} from "./actions";

import {
    getSupplierListApi,
    createCashierMethodApi,
    createCashierSupplierApi,
    getCashierListApi,
    getCashierMethodApi,
    getSupplierByIdApi,
    updateCashierMethodApi,
    updateCashierSupplierApi,
    removeCashierMethodApi,
    removeCashierSupplierApi,
} from '../../helpers/cashierApi';

function* fetchSupplierList() {
    try {
        const response = yield call(getSupplierListApi);
        yield put(getSupplierListSuccess(response));
    } catch (error) {
        yield put(getSupplierListFail(error));
    }
}

function* fetchCashierList({ payload }) {
    try {
        const response = yield call(getCashierListApi, payload?.method);
        yield put(getCashierListSuccess(response));
    } catch (error) {
        yield put(getCashierListFail(error));
    }
}

function* fetchCashierMethod({ payload }) {
    try {
        const { id, data } = yield call(getCashierMethodApi, payload);
        yield put(getCashierMethodSuccess({ id, data }));
    } catch (error) {
        yield put(getCashierMethodFail(error));
    }
}

function* updateCashierMethod({ payload }) {
    try {
        const response = yield call(updateCashierMethodApi, payload);
        yield put(updateCashierMethodSuccess(response));
    } catch (error) {
        yield put(updateCashierMethodFail(error));
    }
}

function* createCashierMethod({ payload }) {
    try {
        const response = yield call(createCashierMethodApi, payload);
        yield put(createCashierMethodSuccess(response));
    } catch (error) {
        yield put(createCashierMethodFail(error));
    }
}

function* fetchSupplierById( payload ) {
    try {
        const response = yield call(getSupplierByIdApi, payload.payload);
        yield put(getSupplierByIdSuccess(response));
    } catch (error) {
        yield put(getSupplierByIdFail(error));
    }
}

function* updateCashierSupplier({ payload }) {
    try {
        const response = yield call(updateCashierSupplierApi, payload);
        yield put(updateCashierSupplierSuccess(response));
    } catch (error) {
        yield put(updateCashierSupplierFail(error));
    }
}

function* createCashierSupplier({ payload }) {
    try {
        const response = yield call(createCashierSupplierApi, payload);
        yield put(createCashierSupplierSuccess(response));
    } catch (error) {
        yield put(createCashierSupplierFail(error));
    }
}

function* removeCashierMethod({ payload }) {
    try {
        const response = yield call(removeCashierMethodApi, payload);
        yield put(removeCashierMethodByIdSuccess(response));
    } catch (error) {
        yield put(removeCashierMethodByIdFail(error));
    }
}

function* removeCashierSupplier({ payload }) {
    try {
        const response = yield call(removeCashierSupplierApi, payload.supplierId);
        yield put(removeCashierSupplierByIdSuccess(response));

        if (payload.methodId) {
            yield call(fetchSupplierById, {
                payload: payload.methodId
            });
        }

    } catch (error) {
        yield put(removeCashierSupplierByIdFail(error));
    }
}

function* cashierSaga() {
    yield takeEvery(GET_SUPPLIER_LIST, fetchSupplierList);
    yield takeEvery(GET_CASHIER_LIST, fetchCashierList);
    yield takeEvery(GET_CASHIER_METHOD, fetchCashierMethod);
    yield takeEvery(UPDATE_CASHIER_METHOD, updateCashierMethod);
    yield takeEvery(CREATE_CASHIER_METHOD, createCashierMethod);
    yield takeEvery(GET_SUPPLIER_BY_ID, fetchSupplierById);
    yield takeEvery(UPDATE_CASHIER_SUPPLIER, updateCashierSupplier);
    yield takeEvery(CREATE_CASHIER_SUPPLIER, createCashierSupplier);
    yield takeEvery(REMOVE_METHOD_BY_ID, removeCashierMethod);
    yield takeEvery(REMOVE_SUPPLIER_BY_ID, removeCashierSupplier);
}

export default cashierSaga;
